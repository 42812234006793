import type * as Types from './types/graphql.generated';

import { gql } from '@apollo/client';
import { Open_Slots_CountFragmentDoc, Total_Slots_CountFragmentDoc, Leaving_SlotsFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
export type AllPositionsQueryVariables = Types.Exact<Record<string, never>>;


export type AllPositionsQuery = { recruiting_position:Array<{ id:number; position_group_id?:number | null; alias?:string | null; quantity?:number | null; ui_position_groups_order:number; customer_team:{ id:number; customer:{ id:number; }; }; position_template?:{ name:string; } | null; open_slots_count:{ aggregate?:{ count:number; } | null; }; leaving_slots:Array<{ status:Types.Staffing_Customer_Team_Slot_Status_Enum; leaving_date?:any | null; }>; }>; recruiting_position_group:Array<{ id:number; name:string; is_active:boolean; ui_order:number; interview_flow_id?:number | null; positions:Array<{ id:number; }>; position_group_priorities:Array<{ user:{ first_name:string; last_name:string; }; }>; }>; staffing_customer_team:Array<{ id:number; name:string; customer_id:number; is_active:boolean; }>; common_customer:Array<{ id:number; alias:string; status:Types.Common_Customer_Status_Enum; }>; staffing_customer_team_tag:Array<{ id:number; name:string; customer_team_id:number; }>; recruiting_interview_flow:Array<{ id:number; name:string; }>; };

export type GetPositionsQueryVariables = Types.Exact<Record<string, never>>;


export type GetPositionsQuery = { common_customer:Array<{ id:number; alias:string; customer_teams:Array<{ id:number; name:string; positions:Array<{ id:number; position_group_id?:number | null; alias?:string | null; quantity?:number | null; is_ready:boolean; position_template?:{ name:string; staff_role:{ name:string; }; } | null; open_slots_count:{ aggregate?:{ count:number; } | null; }; total_slots_count:{ aggregate?:{ count:number; } | null; }; }>; }>; }>; recruiting_position_group:Array<{ id:number; name:string; is_active:boolean; ui_order:number; }>; };

export type GetPositionQueryVariables = Types.Exact<{
  id:Types.Scalars['Int'];
}>;


export type GetPositionQuery = { recruiting_position_by_pk?:{ id:number; alias?:string | null; google_doc_ref?:string | null; is_ready:boolean; position_template?:{ id:number; name:string; staff_role:{ name:string; }; } | null; customer_team:{ id:number; name:string; customer:{ id:number; alias:string; }; }; } | null; };

export type UpdatePositionUiOrderMutationVariables = Types.Exact<{
  id:Types.Scalars['Int'];
  ui_position_groups_order:Types.Scalars['Int'];
}>;


export type UpdatePositionUiOrderMutation = { update_recruiting_position_by_pk?:{ id:number; ui_position_groups_order:number; } | null; };

export type CreatePositionMutationVariables = Types.Exact<{
  customerTeamId:Types.Scalars['Int'];
  positionTemplateId:Types.Scalars['Int'];
  quantity:Types.Scalars['Int'];
  alias?:Types.InputMaybe<Types.Scalars['String']>;
  positionGroupId?:Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type CreatePositionMutation = { action_position_create?:{ id:number; } | null; };

export type DeletePositionMutationVariables = Types.Exact<{
  positionId:Types.Scalars['Int'];
}>;


export type DeletePositionMutation = { action_position_delete?:{ success?:boolean | null; } | null; };

export type UpdatePositionQuantityMutationVariables = Types.Exact<{
  positionId:Types.Scalars['Int'];
  quantity:Types.Scalars['Int'];
}>;


export type UpdatePositionQuantityMutation = { action_position_update_quantity?:{ success?:boolean | null; } | null; };

export type UpdatePositionAliasMutationVariables = Types.Exact<{
  id:Types.Scalars['Int'];
  alias?:Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdatePositionAliasMutation = { update_recruiting_position_by_pk?:{ id:number; alias?:string | null; } | null; };

export type UpdatePositionIsReadyMutationVariables = Types.Exact<{
  id:Types.Scalars['Int'];
  isReady?:Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type UpdatePositionIsReadyMutation = { update_recruiting_position_by_pk?:{ id:number; is_ready:boolean; } | null; };


export const AllPositionsDocument = gql`
    query AllPositions {
  recruiting_position(where: {deleted_at: {_is_null: true}}) {
    id
    position_group_id
    alias
    quantity
    ui_position_groups_order
    customer_team {
      id
      customer {
        id
      }
    }
    position_template {
      name
    }
    ...open_slots_count
    ...leaving_slots
  }
  recruiting_position_group(where: {deleted_at: {_is_null: true}}) {
    id
    name
    is_active
    ui_order
    positions {
      id
    }
    position_group_priorities {
      user {
        first_name
        last_name
      }
    }
    interview_flow_id
  }
  staffing_customer_team {
    id
    name
    customer_id
    is_active
  }
  common_customer {
    id
    alias
    status
  }
  staffing_customer_team_tag {
    id
    name
    customer_team_id
  }
  recruiting_interview_flow {
    id
    name
  }
}
    ${Open_Slots_CountFragmentDoc}
${Leaving_SlotsFragmentDoc}`;
export type AllPositionsQueryResult = Apollo.QueryResult<AllPositionsQuery, AllPositionsQueryVariables>;
export const GetPositionsDocument = gql`
    query GetPositions {
  common_customer(order_by: {alias: asc}, where: {status: {_eq: current}}) {
    id
    alias
    customer_teams(
      order_by: {ui_order: asc}
      where: {is_active: {_eq: true}, deleted_at: {_is_null: true}}
    ) {
      id
      name
      positions(
        order_by: {ui_customer_team_order: asc}
        where: {deleted_at: {_is_null: true}}
      ) {
        id
        position_group_id
        alias
        quantity
        is_ready
        position_template {
          name
          staff_role {
            name
          }
        }
        ...open_slots_count
        ...total_slots_count
      }
    }
  }
  recruiting_position_group(
    where: {deleted_at: {_is_null: true}, is_active: {_eq: true}}
    order_by: {ui_order: asc}
  ) {
    id
    name
    is_active
    ui_order
  }
}
    ${Open_Slots_CountFragmentDoc}
${Total_Slots_CountFragmentDoc}`;
export type GetPositionsQueryResult = Apollo.QueryResult<GetPositionsQuery, GetPositionsQueryVariables>;
export const GetPositionDocument = gql`
    query GetPosition($id: Int!) {
  recruiting_position_by_pk(id: $id) {
    id
    alias
    google_doc_ref
    is_ready
    position_template {
      id
      name
      staff_role {
        name
      }
    }
    customer_team {
      id
      name
      customer {
        id
        alias
      }
    }
  }
}
    `;
export type GetPositionQueryResult = Apollo.QueryResult<GetPositionQuery, GetPositionQueryVariables>;
export const UpdatePositionUiOrderDocument = gql`
    mutation UpdatePositionUiOrder($id: Int!, $ui_position_groups_order: Int!) {
  update_recruiting_position_by_pk(
    pk_columns: {id: $id}
    _set: {ui_position_groups_order: $ui_position_groups_order}
  ) {
    id
    ui_position_groups_order
  }
}
    `;
export type UpdatePositionUiOrderMutationFn = Apollo.MutationFunction<UpdatePositionUiOrderMutation, UpdatePositionUiOrderMutationVariables>;
export type UpdatePositionUiOrderMutationResult = Apollo.MutationResult<UpdatePositionUiOrderMutation>;
export type UpdatePositionUiOrderMutationOptions = Apollo.BaseMutationOptions<UpdatePositionUiOrderMutation, UpdatePositionUiOrderMutationVariables>;
export const CreatePositionDocument = gql`
    mutation CreatePosition($customerTeamId: Int!, $positionTemplateId: Int!, $quantity: Int!, $alias: String, $positionGroupId: Int = null) {
  action_position_create(
    customerTeamId: $customerTeamId
    positionTemplateId: $positionTemplateId
    quantity: $quantity
    alias: $alias
    positionGroupId: $positionGroupId
  ) {
    id
  }
}
    `;
export type CreatePositionMutationFn = Apollo.MutationFunction<CreatePositionMutation, CreatePositionMutationVariables>;
export type CreatePositionMutationResult = Apollo.MutationResult<CreatePositionMutation>;
export type CreatePositionMutationOptions = Apollo.BaseMutationOptions<CreatePositionMutation, CreatePositionMutationVariables>;
export const DeletePositionDocument = gql`
    mutation DeletePosition($positionId: Int!) {
  action_position_delete(positionId: $positionId) {
    success
  }
}
    `;
export type DeletePositionMutationFn = Apollo.MutationFunction<DeletePositionMutation, DeletePositionMutationVariables>;
export type DeletePositionMutationResult = Apollo.MutationResult<DeletePositionMutation>;
export type DeletePositionMutationOptions = Apollo.BaseMutationOptions<DeletePositionMutation, DeletePositionMutationVariables>;
export const UpdatePositionQuantityDocument = gql`
    mutation UpdatePositionQuantity($positionId: Int!, $quantity: Int!) {
  action_position_update_quantity(positionId: $positionId, quantity: $quantity) {
    success
  }
}
    `;
export type UpdatePositionQuantityMutationFn = Apollo.MutationFunction<UpdatePositionQuantityMutation, UpdatePositionQuantityMutationVariables>;
export type UpdatePositionQuantityMutationResult = Apollo.MutationResult<UpdatePositionQuantityMutation>;
export type UpdatePositionQuantityMutationOptions = Apollo.BaseMutationOptions<UpdatePositionQuantityMutation, UpdatePositionQuantityMutationVariables>;
export const UpdatePositionAliasDocument = gql`
    mutation UpdatePositionAlias($id: Int!, $alias: String) {
  update_recruiting_position_by_pk(pk_columns: {id: $id}, _set: {alias: $alias}) {
    id
    alias
  }
}
    `;
export type UpdatePositionAliasMutationFn = Apollo.MutationFunction<UpdatePositionAliasMutation, UpdatePositionAliasMutationVariables>;
export type UpdatePositionAliasMutationResult = Apollo.MutationResult<UpdatePositionAliasMutation>;
export type UpdatePositionAliasMutationOptions = Apollo.BaseMutationOptions<UpdatePositionAliasMutation, UpdatePositionAliasMutationVariables>;
export const UpdatePositionIsReadyDocument = gql`
    mutation UpdatePositionIsReady($id: Int!, $isReady: Boolean) {
  update_recruiting_position_by_pk(
    pk_columns: {id: $id}
    _set: {is_ready: $isReady}
  ) {
    id
    is_ready
  }
}
    `;
export type UpdatePositionIsReadyMutationFn = Apollo.MutationFunction<UpdatePositionIsReadyMutation, UpdatePositionIsReadyMutationVariables>;
export type UpdatePositionIsReadyMutationResult = Apollo.MutationResult<UpdatePositionIsReadyMutation>;
export type UpdatePositionIsReadyMutationOptions = Apollo.BaseMutationOptions<UpdatePositionIsReadyMutation, UpdatePositionIsReadyMutationVariables>;
export const namedOperations = {
  Query: {
    AllPositions: 'AllPositions',
    GetPositions: 'GetPositions',
    GetPosition: 'GetPosition'
  },
  Mutation: {
    UpdatePositionUiOrder: 'UpdatePositionUiOrder',
    CreatePosition: 'CreatePosition',
    DeletePosition: 'DeletePosition',
    UpdatePositionQuantity: 'UpdatePositionQuantity',
    UpdatePositionAlias: 'UpdatePositionAlias',
    UpdatePositionIsReady: 'UpdatePositionIsReady'
  }
}