import { Dialog as BPDialog } from '@blueprintjs/core';
import { CommonState } from '@flow/common/CommonState';
import type { ButtonSize } from '@flow/common/components/form/Button';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { Intent } from '@flow/common/components/types/Types';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';

import classNames from 'classnames/bind';
import React from 'react';
import styles from './Dialog.module.less';

const cx = classNames.bind(styles);

export interface DialogProps
{
  title?:string;
  titleTextClassName?:string;
  titleDivider?:boolean;
  titleAdditionalContent?:JSX.Element;

  isOpen:boolean;
  isLoading?:boolean;
  onOpened?:() => void;
  onOpening?:() => void;
  onClose:() => void;
  className?:string;

  secondaryButtonText?:string;
  secondaryButtonIntent?:Intent;
  secondaryButtonClassName?:string;
  isSecondaryButtonOutlined?:boolean;
  secondaryButtonOnClick?:() => void;

  primaryButtonText?:string;
  primaryButtonIntent?:Intent;
  primaryButtonClassName?:string;
  primaryButtonOnClick?:() => void;
  isPrimaryButtonDisabled?:boolean;
  buttonsSize?:ButtonSize;

  canEscapeKeyClose?:boolean;
  canOutsideClickClose?:boolean;
}

@component
export class Dialog extends React.Component<DialogProps>
{
  @di private _commonState!:CommonState;

  private _primaryButtonRef:HTMLButtonElement | null = null;

  @bind
  private _onClickBtnClose():void
  {
    const { onClose } = this.props;

    onClose?.();
  }

  public render():React.ReactNode
  {
    const {
      title, titleTextClassName, titleDivider = true, titleAdditionalContent,
      isOpen,
      isLoading,
      onOpened,
      onOpening,
      onClose,
      canEscapeKeyClose,
      canOutsideClickClose,
      className,
      secondaryButtonText,
      secondaryButtonIntent,
      secondaryButtonClassName,
      secondaryButtonOnClick,
      isSecondaryButtonOutlined,
      primaryButtonText,
      primaryButtonIntent,
      primaryButtonClassName,
      primaryButtonOnClick,
      isPrimaryButtonDisabled,
      buttonsSize,
      children
    } = this.props;

    return (
      <BPDialog
        isOpen={isOpen}
        onOpened={onOpened}
        onOpening={onOpening}
        onClose={onClose}

        className={cx(styles.dialogWrapper, className)}

        canEscapeKeyClose={typeof (canEscapeKeyClose) !== 'undefined' ? canEscapeKeyClose : true}
        canOutsideClickClose={typeof (canOutsideClickClose) !== 'undefined' ? canOutsideClickClose : true}
        backdropClassName={styles.dialogOverlay}
        portalContainer={this._commonState.dialogsContainer}
      >
        <form
          className={styles.dialog}
          onSubmit={(event):void =>
          {
            event.preventDefault();

            if( primaryButtonOnClick )
              primaryButtonOnClick();
          }}
        >
          {
            title &&
            <div className={styles.dialogTitle}>
              <span className={cx(styles.dialogTitleText, titleTextClassName)}>{title}</span>
              {
                titleAdditionalContent &&
                <div className={styles.titleAdditionalContent}>
                  {titleAdditionalContent}
                </div>
              }
            </div>
          }

          {
            titleDivider &&
            <div className={styles.dialogTitleDivider} />
          }

          {children}

          {
            (primaryButtonText || secondaryButtonText) &&
            <div className={styles.dialogFooter}>
              {
                secondaryButtonText &&
                <Button
                  className={cx(styles.dialogBtn, secondaryButtonClassName)}
                  type="button"
                  text={secondaryButtonText}
                  intent={secondaryButtonIntent || Intent.NONE}
                  size={buttonsSize}
                  outlined={isSecondaryButtonOutlined}
                  onClick={secondaryButtonOnClick ? secondaryButtonOnClick : onClose}
                />
              }
              {
                primaryButtonText &&
                <Button
                  className={cx(styles.dialogBtn, primaryButtonClassName)}
                  type="submit"
                  text={primaryButtonText}
                  intent={primaryButtonIntent || Intent.PRIMARY}
                  size={buttonsSize}
                  disabled={isLoading || isPrimaryButtonDisabled}
                  loading={isLoading}
                />
              }
            </div>
          }
        </form>

        <Button
          className={styles.btnClose}
          minimal={true}
          icon={IconNames.CROSS}
          iconClassName={styles.btnCloseIcon}
          onClick={this._onClickBtnClose}
        />

      </BPDialog>
    );
  }
}

