import type { IFilter, IFilterValue } from '@flow/common/components/filters/Filter';
import { Filter, FilterType } from '@flow/common/components/filters/Filter';
import type { IFilterMultiSelectValue } from '@flow/common/components/filters/multiSelect/FilterMultiSelect';
import { FilterMultiSelectSettings } from '@flow/common/components/filters/multiSelect/FilterMultiSelect';
import {
  FilterSiteSubsiteSettings,
  IFilterSiteSubsiteValue
} from '@flow/common/components/filters/siteSubsite/FilterSiteSubsite';
import type { IFilterStatusValue } from '@flow/common/components/filters/staffed/FilterStatus';
import { FilterStatusSettings } from '@flow/common/components/filters/staffed/FilterStatus';
import type { IFilterFlowStageValue } from '@flow/common/components/filters/status/FilterFlowStage';
import { FilterFlowStageSettings } from '@flow/common/components/filters/status/FilterFlowStage';
import type { IFilterTimeValue } from '@flow/common/components/filters/time/FilterTime';
import { FilterTimeSettings } from '@flow/common/components/filters/time/FilterTime';
import { observable, toJS } from 'mobx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CandidatesFiltersState:Array<IFilter<any>> = observable.array([

  new Filter<IFilterTimeValue>(
    {
      ...FilterTimeSettings,
      name: 'Date Created',
      type: FilterType.DATE_CREATED
    }),

  new Filter<IFilterMultiSelectValue>(
    {
      ...FilterMultiSelectSettings,
      name: 'Position Group',
      type: FilterType.POSITION_GROUP,
      graphQLTemplate: `
      {
        "candidate_position_groups": {
          "position_group_id": {
            "_in": {{items}}
          }
        }
      }`
    }),

  // new Filter<IFilterSiteSubsiteValue>(FilterSiteSubsiteSettings),

  new Filter<IFilterFlowStageValue>(FilterFlowStageSettings),

  new Filter<IFilterMultiSelectValue>(
    {
      ...FilterMultiSelectSettings,
      name: 'Assigned To',
      type: FilterType.ASSIGNED_TO,
      graphQLTemplate: `
      {
        "assigned_user_id": {
          "_in": {{items}}
        }
      }`
    }),

  new Filter<IFilterMultiSelectValue>(
    {
      ...FilterMultiSelectSettings,
      name: 'Created By',
      type: FilterType.CREATED_BY,
      graphQLTemplate: `
      {
        "created_by_user_id": {
          "_in": {{items}}
        }
      }`
    }),

  new Filter<IFilterStatusValue>(FilterStatusSettings),

  // new Filter<IFilterSiteSubsiteValue>(FilterSiteSubsiteSettings)

  // new Filter({ ...FilterTodoSettings, name: 'Staff Role', type: FilterType.STAFF_ROLE }),
  // new Filter({ ...FilterTodoSettings, name: 'Position', type: FilterType.POSITION }),
  // new Filter({ ...FilterTodoSettings, name: 'Customer', type: FilterType.CUSTOMER }),
  // new Filter({ ...FilterTodoSettings, name: 'Customer Team', type: FilterType.CUSTOMER_TEAM }),
  // new Filter({ ...FilterTodoSettings, name: 'Modified By', type: FilterType.MODIFIED_BY })
]);

export const CandidatesFiltersDefaultState:Array<IFilter<IFilterValue>> = CandidatesFiltersState.map(f => toJS(f));

// ----------------------------------------------------

