import type {
  Common_Customer,
  Staffing_Customer_Team
} from '@flow/data-access/lib/types/graphql.generated';
import type {
  Common_User,
  Recruiting_Position_Group,
  Recruiting_Position_Group_Priority
} from '@flow/data-access/lib/types/graphql.generated';
import { state } from '@flow/dependency-injection';
import { computed, observable } from 'mobx';
import { computedFn } from 'mobx-utils';
import type {  Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';

export enum PrioritiesMode
{
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export enum PrioritiesMobileViewMode
{
  BY_USERS = 'BY_USERS',
  BY_PRIORITIES = 'BY_PRIORITIES',
}

export const PRIORITIES_NUM_OF_COLUMNS:Array<number> = [1, 2, 3, 4, 5];
export const PRIORITIES_USER_COLUMN:string = 'user';
export const PRIORITIES_COLUMNS:Array<number | string> = [PRIORITIES_USER_COLUMN, ...PRIORITIES_NUM_OF_COLUMNS];

@state
export class PrioritiesState
{
  // ----------------------------------------------------

  @observable public isPrioritiesLoaded:boolean = false;

  // ----------------------------------------------------

  @observable public users:Array<Common_User> = [];
  @observable public positionGroups:Array<Recruiting_Position_Group> = [];

  @observable public positions:Array<Recruiting_Position_Ex> = [];
  @observable public customers:Array<Common_Customer> = [];
  @observable public customerTeams:Array<Staffing_Customer_Team> = [];

  public oldUsers:Array<Common_User> = [];

  @observable public mode:PrioritiesMode = PrioritiesMode.VIEW;
  @observable public mobileViewMode:PrioritiesMobileViewMode = PrioritiesMobileViewMode.BY_PRIORITIES;

  @observable public expanded:string = '';

  // ----------------------------------------------------

  @observable public draggableUserId:number | null = null;
  @observable public draggableIndexFrom:number = -1;
  @observable public draggableIndexOver:number = -1;

  // ----------------------------------------------------

  @computed
  public get isViewMode():boolean
  {
    return this.mode === PrioritiesMode.VIEW;
  }

  @computed
  public get isEditMode():boolean
  {
    return this.mode === PrioritiesMode.EDIT;
  }

  // ----------------------------------------------------

  public positionGroupById:(id:number) => Recruiting_Position_Group | undefined =
    computedFn((id:number):Recruiting_Position_Group | undefined =>
    {
      return this.positionGroups.find((positionGroup:Recruiting_Position_Group) => positionGroup.id === id);
    });

  // ----------------------------------------------------

  public userById:(id:number) => Common_User | undefined =
    computedFn((id:number):Common_User | undefined =>
    {
      return this.users.find((user:Common_User) => user.id === id);
    });

  // ----------------------------------------------------

  public userPrioritiesByUserId:(userId:number) => Array<Recruiting_Position_Group_Priority> | undefined =
    computedFn((userId:number):Array<Recruiting_Position_Group_Priority> | undefined =>
    {
      return this.userById(userId)?.position_group_priorities;
    });

  // ----------------------------------------------------

  public positionById:(id:number) => Recruiting_Position_Ex | undefined =
    computedFn((id:number):Recruiting_Position_Ex | undefined =>
    {
      return this.positions.find((position:Recruiting_Position_Ex) => position.id === id);
    });

  // ----------------------------------------------------

  public customerById:(id:number) => Common_Customer | undefined =
    computedFn((id:number):Common_Customer | undefined =>
    {
      return this.customers.find((customer:Common_Customer) => customer.id === id);
    });

  // ----------------------------------------------------

  public customerTeamById:(id:number) => Staffing_Customer_Team | undefined =
    computedFn((id:number):Staffing_Customer_Team | undefined =>
    {
      return this.customerTeams.find((customerTeam:Staffing_Customer_Team) => customerTeam.id === id);
    });

  // ----------------------------------------------------
}
