import type * as Types from './types/graphql.generated';

import { gql } from '@apollo/client';
import { Open_Slots_CountFragmentDoc, Total_Slots_CountFragmentDoc, Leaving_SlotsFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
export type AllPrioritiesQueryVariables = Types.Exact<Record<string, never>>;


export type AllPrioritiesQuery = { common_user:Array<{ id:number; first_name:string; last_name:string; avatar?:string | null; role_names?:string | null; position_group_priorities:Array<{ user_id:number; priority:number; position_group_id:number; secondary_position_group_id?:number | null; }>; }>; recruiting_position_group:Array<{ id:number; name:string; positions:Array<{ id:number; }>; }>; recruiting_position:Array<{ id:number; alias?:string | null; quantity?:number | null; customer_team_id:number; position_template?:{ name:string; } | null; open_slots_count:{ aggregate?:{ count:number; } | null; }; total_slots_count:{ aggregate?:{ count:number; } | null; }; leaving_slots:Array<{ status:Types.Staffing_Customer_Team_Slot_Status_Enum; leaving_date?:any | null; }>; }>; staffing_customer_team:Array<{ id:number; name:string; customer_id:number; }>; common_customer:Array<{ id:number; alias:string; }>; };

export type ApplyPrioritiesChangesMutationVariables = Types.Exact<{
  deleteUserIds:Array<Types.Scalars['Int']> | Types.Scalars['Int'];
  objects:Array<Types.Recruiting_Position_Group_Priority_Insert_Input> | Types.Recruiting_Position_Group_Priority_Insert_Input;
}>;


export type ApplyPrioritiesChangesMutation = { delete_recruiting_position_group_priority?:{ affected_rows:number; } | null; insert_recruiting_position_group_priority?:{ affected_rows:number; } | null; };


export const AllPrioritiesDocument = gql`
    query AllPriorities {
  common_user(
    where: {roles: {user_role: {_or: [{id: {_eq: 8}}, {id: {_eq: 9}}, {id: {_eq: 5}}]}}}
    order_by: [{first_name: asc}, {last_name: asc}]
  ) {
    id
    first_name
    last_name
    avatar
    role_names
    position_group_priorities(order_by: {priority: asc}) {
      user_id
      priority
      position_group_id
      secondary_position_group_id
    }
  }
  recruiting_position_group(
    where: {deleted_at: {_is_null: true}, is_active: {_eq: true}}
    order_by: {ui_order: asc}
  ) {
    id
    name
    positions(where: {deleted_at: {_is_null: true}}) {
      id
    }
  }
  recruiting_position(where: {deleted_at: {_is_null: true}}) {
    id
    alias
    quantity
    customer_team_id
    position_template {
      name
    }
    ...open_slots_count
    ...total_slots_count
    ...leaving_slots
  }
  staffing_customer_team {
    id
    name
    customer_id
  }
  common_customer {
    id
    alias
  }
}
    ${Open_Slots_CountFragmentDoc}
${Total_Slots_CountFragmentDoc}
${Leaving_SlotsFragmentDoc}`;
export type AllPrioritiesQueryResult = Apollo.QueryResult<AllPrioritiesQuery, AllPrioritiesQueryVariables>;
export const ApplyPrioritiesChangesDocument = gql`
    mutation ApplyPrioritiesChanges($deleteUserIds: [Int!]!, $objects: [recruiting_position_group_priority_insert_input!]!) {
  delete_recruiting_position_group_priority(
    where: {user_id: {_in: $deleteUserIds}}
  ) {
    affected_rows
  }
  insert_recruiting_position_group_priority(objects: $objects) {
    affected_rows
  }
}
    `;
export type ApplyPrioritiesChangesMutationFn = Apollo.MutationFunction<ApplyPrioritiesChangesMutation, ApplyPrioritiesChangesMutationVariables>;
export type ApplyPrioritiesChangesMutationResult = Apollo.MutationResult<ApplyPrioritiesChangesMutation>;
export type ApplyPrioritiesChangesMutationOptions = Apollo.BaseMutationOptions<ApplyPrioritiesChangesMutation, ApplyPrioritiesChangesMutationVariables>;
export const namedOperations = {
  Query: {
    AllPriorities: 'AllPriorities'
  },
  Mutation: {
    ApplyPrioritiesChanges: 'ApplyPrioritiesChanges'
  }
}