import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  type MutationOptions,
  type QueryOptions,
  type SubscriptionOptions,
  type ApolloQueryResult,
  type DefaultContext,
  type NormalizedCacheObject,
  type OperationVariables
} from '@apollo/client';
// import type { ApolloCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import type { FetchResult } from '@apollo/client/link/core';
import { from, split } from '@apollo/client/link/core';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import { CommonState } from '@flow/common/CommonState';
import { RouteName } from '@flow/common/models/routing/RouteName';
import generatedIntrospection from '@flow/data-access/lib/types/fragment-matcher.json';
import { controller, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import type { To } from 'history';
import { action } from 'mobx';
import type { NavigateOptions } from 'react-router';
import type { NavigateFunction } from 'react-router-dom';
import { environment } from '../environments/environment';
import { AuthState } from './controllers/AuthState';
import { ConfigUtil } from './utils/ConfigUtil';
import { CookieUtil } from './utils/CookieUtil';
import { WindowLocationUtil } from './utils/WindowLocationUtil';

export type StaffOrCandidateUrl = string | undefined;

@controller
export class CommonController
{
  @di private readonly _commonController!:CommonController;
  @di private _commonState!:CommonState;
  @di private _authState!:AuthState;

  private _cache:InMemoryCache = new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes
  });

  private _httpLink = createHttpLink({
    uri: environment.apiBaseUrl
  });

  private _wsLink = new WebSocketLink({
    uri: environment.wsBaseUrl,
    options: {
      lazy: true,
      reconnect: true,
      // connectionCallback: (error:Array<Error>):void =>
      // {
      // },
      connectionParams: ():unknown =>
      {
        if( this._authState.user && this._authState.user.accessToken )
        {
          return {
            headers: {
              ['authorization']: `Bearer ${this._authState.user.accessToken}`
            }
          };
        }
        else if( ConfigUtil.isGoogleLoginDisabled() )
        {
          return {
            headers: {
              ['authorization']: `Bearer ${ConfigUtil.getDevJWTToken()}`,
              ['x-hasura-user-id']: ConfigUtil.getLocalUserId(),
              ['x-hasura-role']: ConfigUtil.getHasuraRole()
            }
          };
        }
        else
          return null;
      }
    }
  });

  private _splitLink = split(
    ({ query }) =>
    {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    this._wsLink,
    this._httpLink
  );

  private _authLink = setContext((_, { headers }) =>
  {
    const newHeaders = {
      ...headers,
      'accept-encoding': 'gzip'
    };

    if( ConfigUtil.isGoogleLoginDisabled() )
    {
      newHeaders['x-hasura-admin-secret'] = ConfigUtil.getHasuraAdminSecret();
      newHeaders['x-hasura-role'] = ConfigUtil.getHasuraRole();

      if( ConfigUtil.isGoogleLoginDisabled() )
        newHeaders['x-hasura-user-id'] = ConfigUtil.getLocalUserId();

      newHeaders['authorization'] = `Bearer ${ConfigUtil.getDevJWTToken()}`;
    }
    else
    {
      const user = this._authState.user;

      if( user && user.accessToken )
        newHeaders['authorization'] = `Bearer ${user.accessToken}`;

      if( user && user.activeRole )
        newHeaders['x-hasura-role'] = user.activeRole;
    }

    return {
      headers: newHeaders
    };
  });

  private _errorLink = onError(({ graphQLErrors, networkError }) =>
  {
    if( graphQLErrors )
    {
      graphQLErrors.forEach(({ message, locations, path }) =>
      {
        console.log(`[GraphQL error]: Message: ${message}, Locations: ${locations?.join(', ')}, Path: ${JSON.stringify(path)}`);

        if( message.includes('JWTExpired') )   // More cases?
        {
          CookieUtil.clearCookie(WindowLocationUtil.getRootDomain(), CookieUtil.FLOW_AUTH_COOKIE_KEY);

          // runInAction(() => {
          //   this._authState.user = null;
          //   this._authState.isLoggedIn = false;
          // });

          WindowLocationUtil.fullRedirectTo(window.location.host, '/login');
        }
      });
    }

    if( networkError )
      console.log(`[Network error]: ${networkError.message}`);
  });

  private _client:ApolloClient<NormalizedCacheObject> =
    new ApolloClient<NormalizedCacheObject>({
      link: from([this._errorLink, this._authLink, this._splitLink]),
      cache: this._cache
    });

  @bind
  public query<T, TVariables = OperationVariables>(
    options:QueryOptions<TVariables, T>
  ):Promise<ApolloQueryResult<T>>
  {
    return this._client.query({
      ...options,
      fetchPolicy: 'network-only'
    });
  }

  @bind
  public mutate<TData,
    TVariables = OperationVariables,
    TContext = DefaultContext,
    // TCache extends ApolloCache<any> = ApolloCache<any>
    >(
    options:MutationOptions<TData, TVariables, TContext>
  ):Promise<FetchResult<TData>>
  {
    return this._client.mutate(options);
  }

  @bind
  public subscribe<TData,
    TVariables = OperationVariables>(
    options:SubscriptionOptions<TVariables, TData>
  ):Observable<FetchResult<TData>>
  {
    return this._client.subscribe(options);
  }

  public registerNavigate(navigateFunction:NavigateFunction | null):void
  {
    this._commonState.navigateFunction = navigateFunction;
  }

  public navigate(to:To | null, options?:NavigateOptions):void
  {
    if( !to ) return;

    if( this._commonState.navigateFunction )
      this._commonState.navigateFunction(to, options);
  }

  public async navigateOneLevelUp():Promise<void>
  {
    // It cannot be statically imported due to some di conflict
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const RoutesConfig = await require('../pages/RoutesConfig').RoutesConfig;

    const parentRoute = RoutesConfig.getCurrentRoute(undefined, true);

    if( !parentRoute )
      return;

    const params = RoutesConfig.getCurrentRouteParams();

    const parentRoutePath = RoutesConfig.getRoutePath(parentRoute, params);

    if( !parentRoutePath )
      return;

    if( parentRoutePath )
      this.navigate(String(parentRoutePath));
  }

  // ----------------------------------------------------

  public openInNewTab(url:string):void
  {
    window.open(url, '_blank')?.focus();
  }

  @action.bound
  public toggleCollapseItem(componentId:string, itemId:string | number, isCollapsed?:boolean):void
  {
    const { collapseState } = this._commonState;

    if( !collapseState.has(componentId) )
    {
      collapseState.set(componentId, new Map<string | number, boolean>());
    }

    // collapseState.get(componentId)!.set(itemId, !collapseState.get(componentId)!.get(itemId));

    const state = collapseState.get(componentId);

    if( state )
      state.set(itemId, isCollapsed !== undefined ? isCollapsed : !collapseState.get(componentId)?.get(itemId));
  }

  // ----------------------------------------------------

  public isCollapsed(id:string, itemId:string | number):boolean
  {
    const { collapseState } = this._commonState;

    return collapseState?.get(id)?.get(itemId) || false;
  }

  // ----------------------------------------------------

  @action.bound
  public setContentBackground(newContentBackground:string):void
  {
    this._commonState.contentBackground = newContentBackground;
  }

  @action.bound
  public setIsContentScrollable(newValue:boolean):void
  {
    this._commonState.isContentScrollable = newValue;
  }

  // ----------------------------------------------------

  @bind
  public getStaffMemberUrl(staffId:number | undefined | null):StaffOrCandidateUrl
  {
    if( !staffId ) return;

    // It cannot be statically imported due to some di conflict
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const RoutesConfig = require('../pages/RoutesConfig').RoutesConfig;

    return RoutesConfig.getRoutePath(
      RouteName.STAFFING_STAFF_MEMBER, { staffId: String(staffId) }) as string || undefined;
  }

  @bind
  public goToStaffMember(staffId:number | undefined | null):void
  {
    if( !staffId ) return;

    this.navigate(this.getStaffMemberUrl(staffId) as To || null);
  }

  // ----------------------------------------------------

  @bind
  public getCandidateUrl(candidateId:number | undefined | null):StaffOrCandidateUrl
  {
    if( !candidateId ) return;

    // It cannot be statically imported due to some di conflict
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const RoutesConfig = require('../pages/RoutesConfig').RoutesConfig;

    return RoutesConfig.getRoutePath(
      RouteName.RECRUITING_CANDIDATE, { candidateId: String(candidateId) }) as string || undefined;
  }

  @bind
  public goToCandidate(candidateId:number | undefined | null):void
  {
    if( !candidateId ) return;

    this.navigate(this.getCandidateUrl(candidateId) as To || null);
  }

  // ----------------------------------------------------
}
