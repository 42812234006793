import { Position } from '@blueprintjs/core';
import { FiltersPopover } from '@flow/common/components/filters/components/FiltersPopover';
import type { IFilter, IFilterValue } from '@flow/common/components/filters/Filter';
import { FilterType } from '@flow/common/components/filters/Filter';
import { Button } from '@flow/common/components/form/Button';
import { Checkbox } from '@flow/common/components/form/Checkbox';
import { Intent } from '@flow/common/components/types/Types';
import { component, di } from '@flow/dependency-injection';
import { StaffFiltersController } from '@flow/modules/staffing/staff/StaffFiltersController';
import { StaffFiltersState } from '@flow/modules/staffing/staff/StaffFiltersState';
import bind from 'bind-decorator';
// import classNames from 'classnames/bind';
import React from 'react';
import { StaffState } from '../../../StaffState';

import styles from './StaffFiltersSelector.module.less';

// const cx = classNames.bind(styles);

@component
export class StaffFiltersSelector extends React.Component
{
  @di private _staffState!:StaffState;
  @di private _staffFiltersState!:StaffFiltersState;
  @di private _staffFiltersController!:StaffFiltersController;

  // ---------------------------------------------------------

  @bind
  private _onChangeFilter(filterType:FilterType):(isSelected:boolean) => void
  {
    return (isSelected:boolean):void =>
    {
      this._staffFiltersController.selectFilter(filterType, isSelected);
    };
  }

  @bind
  private _renderFilters():React.ReactElement
  {
    return (
      <div className={styles.content}>
        {this._staffFiltersState.filters.map((filter:IFilter<IFilterValue>):React.ReactNode =>
        {
          const isSelected:boolean = filter.isSelected;

          return (
            <Checkbox
              key={filter.name + filter.type}
              className={styles.checkbox}
              label={filter.name}
              checked={isSelected}
              onChange={this._onChangeFilter(filter.type)}
            />
          );
        })}
      </div>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {

    return (
      <FiltersPopover
        content={this._renderFilters()}
        contentButtonClearText={'Clear all'}
        onClickButtonClear={this._staffFiltersController.unselectAllFilters}
        position={Position.BOTTOM_RIGHT}
        customButton={(
          <Button
            className={styles.btnFilter}
            text={'Filters...'}
            intent={Intent.NONE}
            outlined={true}
          />
        )}
      />
    );
  }
}
