import type { TagInputProps } from '@blueprintjs/core';
import { TagInput as BPTagInput } from '@blueprintjs/core';
import bind from 'bind-decorator';
// import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import { PureComponent } from 'react';
import type { Intent } from '../types/Types';
import { getBPIntent } from '../types/Types';

// import styles from './TagInput.module.less';

// const cx = classNames.bind(styles);

export interface ITagInputProps extends Omit<TagInputProps, 'intent' | 'onChange'>
{
  className?:string;
  intent?:Intent;
  onChange?:(newValues:Array<string>) => void;
}

export class TagInput extends PureComponent<ITagInputProps>
{
  @bind
  private _onChange(values:Array<ReactNode>):void
  {
    const { onChange } = this.props;

    if( onChange )
      onChange(values as Array<string>);
  }

  public render():ReactNode
  {
    const {
      className,
      intent
    } = this.props;

    return (
      <BPTagInput
        addOnBlur={true}
        {...this.props}
        className={className}
        intent={getBPIntent(intent)}
        onChange={this._onChange}
      />
    );
  }
}
