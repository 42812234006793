import { Radio } from '@blueprintjs/core';
import { ButtonSize } from '@flow/common/components/form/Button';
import { TextInput } from '@flow/common/components/form/TextInput';
import { Dialog } from '@flow/common/components/page/Dialog';
import { DialogRow } from '@flow/common/components/page/DialogRow';
import { Intent } from '@flow/common/components/types/Types';
import { component, di } from '@flow/dependency-injection';
import { SaveFilterMode } from '@flow/modules/recruiting/candidates/CandidatesState';
import { StaffSavedFiltersSuggest } from '@flow/modules/staffing/staff/components/list/filters/StaffSavedFiltersSuggest';
import { StaffFiltersController } from '@flow/modules/staffing/staff/StaffFiltersController';
import { StaffState } from '@flow/modules/staffing/staff/StaffState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import { AppToaster } from '../../../../../../pages/App';

import styles from './StaffSaveFilterDialog.module.less';

const cx = classNames.bind(styles);

export interface IStaffSaveFilterDialogProps
{
  onSave?:(customerId:number | null) => Promise<void>;
}

@component
export class StaffSaveFilterDialog extends Component<IStaffSaveFilterDialogProps>
{
  @di private _staffState!:StaffState;
  @di private _staffFiltersController!:StaffFiltersController;

  // ---------------------------------------------------------

  @bind
  private async _saveFilter():Promise<void>
  {
    const { onSave } = this.props;

    const id:number | null = await this._staffFiltersController.saveFilter();

    if( !id )
    {
      AppToaster.show({
        intent: 'danger',
        message: (
          <>
            <div>ERROR: Save Filter</div>
            <div style={{ color: '#ff0' }}>TODO: form errors</div>
          </>
        )
      });
    }

    if( onSave )
      return onSave(id);
  }

  // ---------------------------------------------------------

  @bind
  private _onChangeRadio(event:React.FormEvent<HTMLInputElement>):void
  {
    const value:string = (event.target as HTMLInputElement).value;

    this._staffFiltersController.setSaveFilterMode(value as SaveFilterMode);
  }

  // ---------------------------------------------------------

  @bind
  private _onClickInput():void
  {
    this._staffFiltersController.setSaveFilterMode(SaveFilterMode.NEW);
  }

  // ---------------------------------------------------------

  public render():ReactNode
  {
    const {
      isSaveFilterDialogOpen,
      isSaveFilterLoading,
      saveFilterMode,
      saveFilterName,
      userFilters,
      // selectedFilterId,
      overwriteFilterId
    } = this._staffState;

    const isFirstFilter:boolean = userFilters.length === 0;

    const isButtonDisabled:boolean =
      (saveFilterMode === SaveFilterMode.NEW && saveFilterName.trim() === '') ||
      (saveFilterMode === SaveFilterMode.OVERWRITE && !overwriteFilterId);

    return (
      <Dialog
        title={'Save filter'}
        isOpen={isSaveFilterDialogOpen}
        isLoading={isSaveFilterLoading}
        onClose={this._staffFiltersController.hideSaveFilterDialog}
        className={styles.dialog}

        buttonsSize={ButtonSize.LARGE}

        primaryButtonText={'Apply'}
        primaryButtonIntent={Intent.PRIMARY}
        primaryButtonOnClick={this._saveFilter}
        isPrimaryButtonDisabled={isButtonDisabled}

        secondaryButtonText={'Cancel'}
        secondaryButtonIntent={Intent.PRIMARY}
        isSecondaryButtonOutlined={true}
      >
        <DialogRow className={styles.radioWrapper}>
          <Radio
            className={styles.radio}
            name={'saveFilterRadio'}
            label={'Save as a new filter'}
            value={SaveFilterMode.NEW}
            checked={saveFilterMode === SaveFilterMode.NEW}
            onChange={this._onChangeRadio}
          />
        </DialogRow>

        <DialogRow className={styles.inputWrapper}>
          <TextInput
            value={saveFilterName}
            large={true}
            onChange={this._staffFiltersController.setSaveFilterName}
            autoFocus={true}
            onClick={this._onClickInput}
          />
        </DialogRow>

        <DialogRow className={styles.radioWrapper}>
          <Radio
            className={styles.radio}
            name={'saveFilterRadio'}
            label={'Overwrite existing'}
            value={SaveFilterMode.OVERWRITE}
            checked={saveFilterMode === SaveFilterMode.OVERWRITE}
            onChange={this._onChangeRadio}
            disabled={isFirstFilter}
          />
        </DialogRow>

        <DialogRow className={styles.inputWrapper}>
          <StaffSavedFiltersSuggest />
        </DialogRow>

      </Dialog>
    );
  }

}
