import { OrderByType } from '@flow/common/components/elements/ButtonOrderBy';
import { toJS } from 'mobx';

export const QUERY_STRING_COLUMNS_NAME = 'columns';
export const QUERY_STRING_ORDER_BY_NAME = 'orderBy';

export enum StaffColumnType
{
  NAME = 1,
  POSITIONS = 2,
  STATUS = 3,
  SITE_SUBSITE = 4,

  // SITE_SUBSITE = '7',

  // STAFF_ROLE = 'xx',
  // POSITION = 'xx',
  // CUSTOMER = 'xx',
  // CUSTOMER_TEAM = 'xx',
  // MODIFIED_BY = 'xx',
}

export interface IStaffColumn
{
  type:StaffColumnType;
  name:string;
  isVisible:boolean;
  orderBy:OrderByType | null;
  alwaysVisible?:boolean;
}

// ----------------------------------------------------

export const StaffColumnsState:Array<IStaffColumn> = [
  {
    type: StaffColumnType.NAME,
    name: 'Employee',
    isVisible: true,
    orderBy: OrderByType.ASC,
    alwaysVisible: true
  },
  {
    type: StaffColumnType.POSITIONS,
    name: 'Positions',
    isVisible: true,
    orderBy: null
  },
  {
    type: StaffColumnType.STATUS,
    name: 'Status',
    isVisible: true,
    orderBy: null
  },
  {
    type: StaffColumnType.SITE_SUBSITE,
    name: 'Site/Subsite',
    isVisible: true,
    orderBy: null
  }
];

export const StaffColumnsDefaultState:Array<IStaffColumn> = StaffColumnsState.map(f => toJS(f));

// ----------------------------------------------------

