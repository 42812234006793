export enum MoveTo
{
  UP = 'UP',
  DOWN = 'DOWN',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',

  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  FIRST = 'FIRST',
  LAST = 'LAST',
}

export type MovedElement = {
  id:number;
  ui_order:number;
};
