import type { IPopoverProps } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { InlineEditor, InlineEditorType } from '@flow/common/components/form/InlineEditor';
import type { Common_User } from '@flow/data-access/lib/types/graphql.generated';
import classNames from 'classnames/bind';
import React from 'react';

import styles from '../eventForm/CommunicationEventForm.module.less';

const cx = classNames.bind(styles);

interface Props
{
  scheduledEventAttendeesList:Array<Common_User>;
  candidateEmail:string;
  userId:number;
  allUsers:Array<Common_User>;
  popoverProps?:(Partial<IPopoverProps> & object);
  onChange?:(userEmails:Array<string>) => void;
  isChanged?:boolean;
  readonly?:boolean;
}

export const EventAttendeesListEditor = (props:Props):JSX.Element =>
{
  const {
    scheduledEventAttendeesList,
    candidateEmail,
    userId,
    allUsers,
    popoverProps,
    onChange,
    isChanged,
    readonly
  } = props;

  return <InlineEditor
    type={InlineEditorType.MULTISELECT}
    isPills={false}
    applyWhenSelect={false}
    icon={IconNames.PEOPLE}
    textClassName={styles.participantsText}
    text={'Choose participants'}
    popoverProps={{
      usePortal: true,
      ...popoverProps
    }}
    readonly={readonly}
    iconClassName={cx(styles.iconField, { isChanged })}
    // isChanged={isChanged}
    fields={[
      {
        name: 'attendees',
        // isRequired: false,
        value: scheduledEventAttendeesList.filter(user => !!user && user.email).map(user => user.email || ''),
        values: allUsers.filter(user => user.email).map(user =>
        {
          // const emptyEmail:boolean = !user.email;

          return {
            title: `${user.first_name} ${user.last_name}`.replace(/ /g, '\u00a0') + `${
              user.id === userId
                ? ' (organizer)'
                : user.email === candidateEmail
                ? ' (candidate)'
                : ''
            }`,
            element: (
              <div className={styles.userItem}>
                <Avatar
                  className={styles.userAvatar}
                  url={user.staffs.length && user.staffs[0].avatar_url || user.avatar}
                />
                <div className={styles.userDetails}>
                  <div className={styles.userName}>
                    {`${user.first_name} ${user.last_name}`}
                  </div>
                  <div className={styles.userEmail}>
                    {user.email}
                    {
                      // emptyEmail &&
                      // <div className={styles.emptyEmail}>
                      //   <Icon
                      //     className={styles.iconWarning}
                      //     icon={IconNames.WARNING_SIGN}
                      //     size={10}
                      //   />
                      //   no email
                      // </div>
                    }
                  </div>
                </div>
              </div>
            ),
            value: user.email
          };
        })
      }
    ]}
    tagClassName={styles.tag}
    onChange={(value):void =>
    {
      const userEmails = value['attendees'].value;

      if( !Array.isArray(userEmails) )
        return;

      if( onChange )
        onChange(userEmails as Array<string>);
    }}
  />;
};
