import { MenuDivider } from '@blueprintjs/core';
import type {
  IFilterCheckboxesDataItem,
  IFilterCheckboxesDataItems,
  IFilterCheckboxesValue
} from '@flow/common/components/filters/checkboxes/FilterCheckboxes';
import { FiltersPopover } from '@flow/common/components/filters/components/FiltersPopover';
import type { IFilterComponentProps } from '@flow/common/components/filters/Filter';
import { Checkbox } from '@flow/common/components/form/Checkbox';
import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';
// import classNames from 'classnames/bind';
import type React from 'react';
import { Component } from 'react';

import styles from './FilterCheckboxesPopover.module.less';

// const cx = classNames.bind(styles);

@component
export class FilterCheckboxesPopover extends Component<IFilterComponentProps<IFilterCheckboxesValue>>
{
  // ---------------------------------------------------------

  // @bind
  // private _onClick(item:IFilterCheckboxesDataItem):void
  // {
  //   console.log('%c item =', 'background:#0f0;color:#000;', item);
  //
  //   const { filter } = this.props;
  //
  //   const { items: selectedIds } = filter.value;
  //
  //   const isSelected:boolean = selectedIds.includes(item.value);
  //
  //   let newItems:Array<any> = [];
  //
  //   if( isSelected )
  //   {
  //     newItems = selectedIds.filter(id => id !== item.value);
  //   }
  //   else
  //   {
  //     newItems = selectedIds.slice();
  //     newItems.push(item.value);
  //   }
  //
  //   const { onChangeFilter } = this.props;
  //
  //   onChangeFilter({ items: newItems });
  // }

  // ---------------------------------------------------------

  @bind
  private _onChangeFilter(item:IFilterCheckboxesDataItem):(isSelected:boolean) => void
  {
    return (isSelected:boolean):void =>
    {
      const { filter, onChangeFilter } = this.props;

      if( isSelected )
      {
        onChangeFilter({ items: [...filter.value.items, item.value] });
      }
      else
      {
        onChangeFilter({ items: filter.value.items.filter(id => id !== item.value) });
      }
    };
  }

  // ---------------------------------------------------------

  @bind
  private _renderFilterContent():React.ReactElement | null
  {
    const { filter, getFilterData } = this.props;

    const { items: selectedIds } = filter.value;

    const filterData:IFilterCheckboxesDataItems = getFilterData?.();

    if( !filterData || !filterData.items ) return null;

    return (
      <>
        <div className={styles.topOverlay}/>
        <div className={styles.content}>

          {filterData.items.map((item:IFilterCheckboxesDataItem, index:number) =>
          {
            if( item.isMenuDivider ) return <MenuDivider key={`${index}-${item.value}`} />;

            const isSelected:boolean = selectedIds.includes(item.value);

            return (
              <Checkbox
                className={styles.checkbox}
                key={item.value}
                // label={item.name}
                labelElement={<span className={styles.checkboxTitle}>{item.name}</span>}
                checked={isSelected}
                onChange={this._onChangeFilter(item)}
                // onClick={():void => this._onClick(item)}
              />

              // <MenuItem
              //   key={item.value}
              //   text={item.name}
              //   icon={isSelected ? IconNames.BLANK : null}
              //   onClick={():void => this._onClick(item)}
              // />
            );
          })}

          {/*<Menu>*/}
          {/*  {filterData.items.map((item:IFilterCheckboxesDataItem) =>*/}
          {/*  {*/}
          {/*    const isSelected:boolean = selectedIds.includes(item.value);*/}

          {/*    return (*/}
          {/*      <MenuItem*/}
          {/*        key={item.value}*/}
          {/*        text={item.name}*/}
          {/*        icon={isSelected ? IconNames.BLANK : null}*/}
          {/*        onClick={():void => this._onClick(item)}*/}
          {/*      />*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</Menu>*/}

        </div>
      </>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _getValuesStr():string
  {
    const { filter, getFilterData } = this.props;

    const { items } = filter.value;

    const filterData:IFilterCheckboxesDataItems = getFilterData?.();

    const isValueSelected:boolean = items && !!items.length;

    if( !filterData || !filterData.items || !isValueSelected )
    {
      return '';
    }

    const names:string = filterData.items
      .filter((item) => items.includes(item.value))
      .map((item) => item.name)
      .join(', ');

    return names;
  }

  @bind
  private _getButtonText():JSX.Element
  {
    const { filter } = this.props;
    const { selectedTitle, unselectedTitle } = filter;

    const values:string = this._getValuesStr();

    const showColon:boolean = !!(selectedTitle || filter.name);

    return values
      ? <span><b>{selectedTitle || filter.name}{showColon && ': '}</b>{values}</span>
      : <span>{unselectedTitle || filter.name}</span>;
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { filter, onRemoveFilter, wrapperClassName, buttonClearText } = this.props;

    const { items } = filter.value;

    const isValueSelected:boolean = items && !!items.length;

    return (
      <FiltersPopover
        wrapperClassName={wrapperClassName}
        title={filter.name}
        content={this._renderFilterContent()}
        contentButtonClearText={buttonClearText}
        onClickButtonClear={onRemoveFilter}
        buttonText={this._getButtonText()}
        isValueSelected={isValueSelected}
      />
    );
  }

  // ----------------------------------------------------
}
