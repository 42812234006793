import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { component } from '@flow/dependency-injection';
import React from 'react';

@component
export class EmptyMenu extends React.Component
{
  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    return (
      <Menu>
        <MenuItem
          text={'Empty Menu'}
        />
        <MenuDivider />
        <MenuItem
          text={'TODO: '}
        />
      </Menu>
    );
  }

  // ---------------------------------------------------------
}
