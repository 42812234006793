import type { ApolloQueryResult } from '@apollo/client';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import type {
  GetScheduledEventQuery,
  GetScheduledEventQueryVariables
} from '@flow/data-access/lib/candidate.generated';
import { GetScheduledEventDocument } from '@flow/data-access/lib/candidate.generated';
import type { Common_Scheduled_Event } from '@flow/data-access/lib/types/graphql.generated';

import { controller, di } from '@flow/dependency-injection';
import { FeedbackFormState } from '@flow/modules/recruiting/feedbackForm/FeedbackFormState';
import { action, runInAction } from 'mobx';

@controller
export class FeedbackFormController
{
  @di private _commonState!:CommonState;
  @di private _commonController!:CommonController;
  @di private _feedbackFormState!:FeedbackFormState;

  // ----------------------------------------------------

  @action.bound
  public initFeedbackFromEvent(scheduleEvent:Common_Scheduled_Event):void
  {
    runInAction(() =>
    {
      this._feedbackFormState.feedbackFormEvent = scheduleEvent;
    });
  }

  // ----------------------------------------------------

  public async initFeedbackFromEventId(eventId:number):Promise<void>
  {
    console.log('%c initFeedbackFromEventId eventId =', 'background:#0f0;color:#000;', eventId);

    runInAction(() =>
    {
      this._feedbackFormState.isFeedbackFormLoaded = false;
    });

    const result:ApolloQueryResult<GetScheduledEventQuery> =
      await this._commonController.query<GetScheduledEventQuery,
        GetScheduledEventQueryVariables>({
        query: GetScheduledEventDocument,
        variables: {
          eventId
        }
      });

    console.log('%c initFeedbackFromEventId result =', 'background:#f60;color:#000;', result);

    runInAction(() =>
    {
      this._feedbackFormState.feedbackFormEvent = result.data.common_scheduled_event_by_pk as Partial<Common_Scheduled_Event>;
      this._feedbackFormState.isFeedbackFormLoaded = true;
    });
  }

  // ----------------------------------------------------
}
