import { RouteName } from '@flow/common/models/routing/RouteName';

export interface IMenuItem
{
  title:string;
  routeName:RouteName;
  subMenu?:Array<IMenuItem>;
  disabled?:boolean;
  activeRoutes?:Array<RouteName>;
}

export const menuItems:Array<IMenuItem> = [
  {
    title: 'Dashboard',
    routeName: RouteName.HOME
    // subMenu: [
    //   {
    //     title: 'Dashboard',
    //     routeName: RouteName.HOME
    //   },
    // ]
  },
  {
    title: 'Customers',
    routeName: RouteName.CUSTOMERS
    // subMenu: [
    //   // {
    //   //   title: 'Home',
    //   //   routeName: RouteName.CUSTOMERS_HOME
    //   // },
    //   {
    //     title: 'Teams',
    //     routeName: RouteName.CUSTOMERS_TEAMS
    //   }
    // ]
  },
  {
    title: 'Recruiting',
    routeName: RouteName.RECRUITING,
    subMenu: [
      {
        title: 'Positions',
        routeName: RouteName.RECRUITING_POSITIONS
      },
      {
        title: 'Priorities',
        routeName: RouteName.RECRUITING_PRIORITIES
      },
      {
        title: 'Candidates',
        routeName: RouteName.RECRUITING_CANDIDATES
      },
      {
        title: 'Boards',
        routeName: RouteName.RECRUITING_BOARDS
      },
    ]
  },
  {
    title: 'Staffing',
    routeName: RouteName.STAFFING,
    subMenu: [
      {
        title: 'Staff',
        routeName: RouteName.STAFFING_STAFF
      },
      {
        title: 'Staff Board',
        routeName: RouteName.STAFFING_BOARD
      },
    ]
  }
];

export const settingsMenuItems:Array<IMenuItem> = [
  {
    title: 'Recruiting',
    routeName: RouteName.SETTINGS,
    subMenu: [
      {
        title: 'Position Groups',
        routeName: RouteName.SETTINGS_POSITION_GROUPS
      },
      {
        title: 'Position Templates',
        routeName: RouteName.SETTINGS_POSITION_TEMPLATES,
        disabled: true
      },
      {
        title: 'Pipelines',
        routeName: RouteName.SETTINGS_PIPELINES,
        disabled: true
      }
    ]
  },
  {
    title: 'Users',
    routeName: RouteName.SETTINGS,
    subMenu: [
      {
        title: 'Users',
        routeName: RouteName.SETTINGS_USERS,
        disabled: true
      },
      {
        title: 'Permissions',
        routeName: RouteName.SETTINGS_PERMISSIONS,
        disabled: true
      },
      {
        title: 'Interviewers',
        routeName: RouteName.SETTINGS_INTERVIEWERS,
        disabled: true
      }
    ]
  }
];
