import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { ButtonMore } from '@flow/common/components/elements/ButtonMore';
import { NeedsPermission } from '@flow/common/components/NeedsPermission';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { MoveTo } from '@flow/common/models/MoveTo';
import { StringUtil } from '@flow/common/utils/StringUtil';
import type { Recruiting_Position_Group_Priority } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import type { Recruiting_Position_Group_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { SettingsPositionGroupsController } from '@flow/modules/settings/settingsPositionGroups/SettingsPositionGroupsController';
import {
  SettingsPositionGroupsState,
  SettingsPositionGroupsStatus
} from '@flow/modules/settings/settingsPositionGroups/SettingsPositionGroupsState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './SettingsPositionGroupsList.module.less';

const cx = classNames.bind(styles);

@component
export class SettingsPositionGroupsList extends React.Component
{
  @di private _settingsPositionGroupsState!:SettingsPositionGroupsState;
  @di private _settingsPositionGroupsController!:SettingsPositionGroupsController;

  // ---------------------------------------------------------

  public componentDidMount():void
  {
    // const { isLoaded } = this._settingsPageState;
    //
    // if( !isLoaded )
    // {
    //   this._settingsPageController.init();
    // }
  }

  // ---------------------------------------------------------

  @bind
  private _renderPositionGroupMenu(positionGroup:Recruiting_Position_Group_Ex, indexPositionGroup:number):React.ReactElement
  {
    const {
      filteredPositionGroups,
      filterByPositionGroupStatus,
      openAndLeavingSlots
    } = this._settingsPositionGroupsState;

    const isActiveGroups:boolean = filterByPositionGroupStatus === SettingsPositionGroupsStatus.ACTIVE;

    const isDisabled:boolean = openAndLeavingSlots(positionGroup) > 0 || positionGroup.position_group_priorities?.length > 0;

    return (
      <Menu>
        <MenuItem
          text="Edit position group"
          onClick={this._onClickEditPositionGroup(positionGroup)}
        />
        {
          isActiveGroups &&
          <>
            <MenuDivider />
            <MenuItem
              text="Move up" disabled={indexPositionGroup === 0}
              onClick={this._onClickMovePositionGroup(positionGroup, MoveTo.UP)}
            />
            <MenuItem
              text="Move down" disabled={indexPositionGroup == filteredPositionGroups.length - 1}
              onClick={this._onClickMovePositionGroup(positionGroup, MoveTo.DOWN)}
            />
            <MenuItem
              text="Move to top" disabled={indexPositionGroup === 0}
              onClick={this._onClickMovePositionGroup(positionGroup, MoveTo.TOP)}
            />
            <MenuItem
              text="Move to bottom" disabled={indexPositionGroup == filteredPositionGroups.length - 1}
              onClick={this._onClickMovePositionGroup(positionGroup, MoveTo.BOTTOM)}
            />
            <MenuDivider />
            <MenuItem
              text={'Archive'}
              onClick={this._onClickActivatePositionGroup(positionGroup)}
              disabled={isDisabled}
            />
          </>
        }
        {
          !isActiveGroups &&
          <>
            <MenuItem
              text={'Make active'}
              onClick={this._onClickActivatePositionGroup(positionGroup)}
            />
            <MenuDivider />
            <MenuItem
              text="Delete Group"
              onClick={this._onClickDeletePositionGroup(positionGroup)}
              // disabled={isDisabled}
            />
          </>
        }
      </Menu>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _onClickActivatePositionGroup(positionGroup:Recruiting_Position_Group_Ex):(e:React.MouseEvent<HTMLElement>) => void
  {
    return (/* e:React.MouseEvent<HTMLElement> */):void =>
    {
      this._settingsPositionGroupsController.activatePositionGroup(positionGroup);
    };
  }

  // ---------------------------------------------------------

  @bind
  private _onClickEditPositionGroup(positionGroup:Recruiting_Position_Group_Ex):(e:React.MouseEvent<HTMLElement>) => void
  {
    return (/* e:React.MouseEvent<HTMLElement> */):void =>
    {
      this._settingsPositionGroupsController.showNewEditPositionGroupDialog(false, positionGroup);
    };
  }

  // ---------------------------------------------------------

  @bind
  private _onClickMovePositionGroup(positionGroup:Recruiting_Position_Group_Ex, to:MoveTo):(e:React.MouseEvent<HTMLElement>) => void
  {
    return (/* e:React.MouseEvent<HTMLElement> */):void =>
    {
      this._settingsPositionGroupsController.movePositionGroup(positionGroup, to);
    };
  }

  // ---------------------------------------------------------

  @bind
  private _onClickDeletePositionGroup(positionGroup:Recruiting_Position_Group_Ex):(e:React.MouseEvent<HTMLElement>) => void
  {
    return (/* e:React.MouseEvent<HTMLElement> */):void =>
    {
      this._settingsPositionGroupsController.deletePositionGroup(positionGroup);

      // AppToaster.show({
      //   intent: 'danger',
      //   message: (
      //     <>
      //       <div>Delete: {positionGroup.name}</div>
      //       <div style={{ color: '#ff0' }}><b>TODO:</b> Delete Position Group</div>
      //     </>
      //   )
      // });
    };
  }

  // ---------------------------------------------------------

  @bind
  private _getPositionGroupUsers(positionGroup:Recruiting_Position_Group_Ex):string
  {
    const { position_group_priorities } = positionGroup;

    if( !position_group_priorities?.length ) return '';

    const users:string = position_group_priorities.map((userItem:Recruiting_Position_Group_Priority) =>
    {
      return StringUtil.getUserName(userItem.user);
    }).join(', ');

    return users;
  }

  // ---------------------------------------------------------

  @bind
  private _renderPositionGroup(positionGroup:Recruiting_Position_Group_Ex, index:number):React.ReactNode
  {
    const { flowById, openAndLeavingSlots } = this._settingsPositionGroupsState;

    const { name, interview_flow_id } = positionGroup;

    const openPositions:number = openAndLeavingSlots(positionGroup);

    const pipelineName:string = flowById(interview_flow_id || -1)?.name || '';
    const users:string = this._getPositionGroupUsers(positionGroup);

    return (
      <div
        key={name}
        className={cx(styles.row, styles.positionGroup)}
      >
        <div className={cx(styles.pgName, styles.col1)}>
          {name}
        </div>

        <div className={cx(styles.pgFlow, styles.col2)}>
          {
            pipelineName &&
            <div className={styles.secondLineMobile}>
              {pipelineName}
            </div>
          }
        </div>

        <div className={cx(styles.pgOpen, styles.col3)}>
          <div className={cx(styles.openSlotsCount, { isZero: openPositions === 0 })}>
            {openPositions}
          </div>
        </div>

        <div className={cx(styles.pgUsers, styles.col4)}>
          {
            users &&
            <div className={styles.secondLineMobile}>
              {users}
            </div>
          }
        </div>

        <div className={cx(styles.pgMenu, styles.col5)}>
          <NeedsPermission
            name={FlowPermissions.EditPositionGroup}
            disallowedElement={<div className={styles.spacer} />}
          >
            <ButtonMore
              className={styles.btnPositionGroupMenu}
              menuContent={this._renderPositionGroupMenu(positionGroup, index)}
            />
          </NeedsPermission>
        </div>

      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderHeader():React.ReactNode
  {
    return (
      <div className={cx(styles.row, styles.header)}>
        <div className={cx(styles.headerTitle, styles.col1, styles.mobile)}>
          Position Groups
        </div>
        <div className={cx(styles.headerTitle, styles.col1, styles.desktop)}>
          Name
        </div>
        <div className={cx(styles.headerTitle, styles.col2, styles.desktop)}>
          Pipeline
        </div>
        <div className={cx(styles.headerTitle, styles.col3)}>
          Open Positions
        </div>
        <div className={cx(styles.headerTitle, styles.col4, styles.desktop)}>
          Recruiters
        </div>
        <div className={cx(styles.headerTitle, styles.col5, styles.desktop)}>

        </div>
      </div>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { filteredPositionGroups } = this._settingsPositionGroupsState;

    return (
      <div className={styles.listWrapper}>

        {this._renderHeader()}

        <div className={styles.groupsWrapper}>
          {filteredPositionGroups.map(this._renderPositionGroup)}
        </div>

      </div>
    );
  }

  // ---------------------------------------------------------
}
