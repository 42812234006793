import type { ApolloQueryResult, FetchResult } from '@apollo/client';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import { MoveTo } from '@flow/common/models/MoveTo';
import { IPositionsCount, PositionsUtil } from '@flow/common/utils/PositionsUtil';
import type {
  AllPrioritiesQuery,
  AllPrioritiesQueryVariables,
  ApplyPrioritiesChangesMutation,
  ApplyPrioritiesChangesMutationVariables
} from '@flow/data-access/lib/priorities.generated';
import { AllPrioritiesDocument, ApplyPrioritiesChangesDocument } from '@flow/data-access/lib/priorities.generated';
import type {
  Common_Customer,
  Common_User,
  Recruiting_Position_Group,
  Recruiting_Position_Group_Priority,
  Recruiting_Position_Group_Priority_Insert_Input,
  Staffing_Customer_Team
} from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import bind from 'bind-decorator';
import _omit from 'lodash/omit';
import { action, runInAction, toJS } from 'mobx';
import type { DragStart, DragUpdate, DropResult } from 'react-beautiful-dnd';
import {
  PRIORITIES_NUM_OF_COLUMNS,
  PrioritiesMobileViewMode,
  PrioritiesMode,
  PrioritiesState
} from './PrioritiesState';

type ApplyItem = Recruiting_Position_Group_Priority;

@controller
export class PrioritiesController
{
  @di private _commonState!:CommonState;
  @di private _commonController!:CommonController;
  @di private _prioritiesState!:PrioritiesState;

  // ----------------------------------------------------

  @action.bound
  public async initPriorities():Promise<void>
  {
    this._prioritiesState.isPrioritiesLoaded = false;

    const result:ApolloQueryResult<AllPrioritiesQuery> =
      await this._commonController.query<AllPrioritiesQuery,
        AllPrioritiesQueryVariables>({
        query: AllPrioritiesDocument,
        variables: {}
      });

    console.log('%c initPriorities result =', 'background:#0f0;color:#000;', result);

    runInAction(() =>
    {
      const {
        common_user,
        recruiting_position_group,
        recruiting_position,
        common_customer,
        staffing_customer_team
      } = result.data;

      this._prioritiesState.users = common_user as Array<Common_User>;
      this._prioritiesState.positionGroups = recruiting_position_group as Array<Recruiting_Position_Group>;
      this._prioritiesState.customers = common_customer as Array<Common_Customer>;
      this._prioritiesState.customerTeams = staffing_customer_team as Array<Staffing_Customer_Team>;

      this._prioritiesState.positions = (recruiting_position as Array<Recruiting_Position_Ex>)
        .filter((position) =>
        {
          return position.open_slots_count.aggregate.count > 0
            || position.leaving_slots?.some(slot => slot.leaving_date != null);
        });

      this._prioritiesState.isPrioritiesLoaded = true;
    });
  }

  // ----------------------------------------------------

  @action.bound
  public setFilterMobileViewMode(mode:PrioritiesMobileViewMode):void
  {
    this._prioritiesState.mobileViewMode = mode;
  }

  // ----------------------------------------------------

  @action.bound
  public goToEditMode():void
  {
    const { users } = this._prioritiesState;

    this._prioritiesState.oldUsers = toJS(users);

    this._prioritiesState.mode = PrioritiesMode.EDIT;

    this.setFilterMobileViewMode(PrioritiesMobileViewMode.BY_USERS);
  }

  @action.bound
  public cancelEditMode():void
  {
    const { oldUsers } = this._prioritiesState;

    this._prioritiesState.users = oldUsers;
    this._prioritiesState.oldUsers = [];

    this._prioritiesState.mode = PrioritiesMode.VIEW;
  }

  @action.bound
  public applyChanges():void
  {
    const { oldUsers } = this._prioritiesState;
    const newUsers:Array<Common_User> = toJS(this._prioritiesState.users);

    const added:Array<ApplyItem> = [];
    const removed:Array<ApplyItem> = [];
    const changed:Array<ApplyItem> = [];

    const newItems:Record<string, ApplyItem> = {};
    const oldItems:Record<string, ApplyItem> = {};

    newUsers.forEach((user) =>
    {
      user.position_group_priorities.forEach((priority) =>
      {
        newItems[`${user.id}:${priority.position_group_id}`] = priority;
      });
    });

    oldUsers.forEach((user) =>
    {
      user.position_group_priorities.forEach((priority) =>
      {
        oldItems[`${user.id}:${priority.position_group_id}`] = priority;
      });
    });

    Object.keys(newItems).forEach((newItemKey) =>
    {
      const oldItem:ApplyItem = oldItems[newItemKey];
      const newItem:ApplyItem = newItems[newItemKey];

      if( !oldItem )
      {
        added.push(newItem);
      }
      else
      {
        if( oldItem.priority !== newItem.priority ||
          oldItem.secondary_position_group_id !== newItem.secondary_position_group_id )
        {
          changed.push(newItem);
        }
        delete oldItems[newItemKey];
      }
    });

    Object.keys(oldItems).forEach((oldItemKey) =>
    {
      removed.push(oldItems[oldItemKey]);
    });

    console.log('%c -------------------------- =', 'background:#ff0;color:#000;');
    console.log('%c added =', 'background:#ccf;color:#000;', added);
    console.log('%c removed =', 'background:#ccf;color:#000;', removed);
    console.log('%c changed =', 'background:#ccf;color:#000;', changed);
    console.log('%c -------------------------- =', 'background:#ff0;color:#000;');

    const allChangedItems:Array<ApplyItem> = added.concat(changed, removed);
    const deletedUserIds:Array<number> = [];

    allChangedItems.forEach((item) =>
    {
      const userId = item.user_id;

      if( !deletedUserIds.includes(userId) )
      {
        deletedUserIds.push(userId);
      }
    });

    const insertedItems:Array<ApplyItem> = Object.keys(newItems)
      .map((itemKey) => _omit(newItems[itemKey], '__typename') as Recruiting_Position_Group_Priority)
      .filter((item) => deletedUserIds.includes(item.user_id));

    console.log('%c deleteUserIds =', 'background:#ccf;color:#000;', deletedUserIds);
    console.log('%c insertedItems =', 'background:#ccf;color:#000;', insertedItems);
    console.log('%c -------------------------- =', 'background:#ff0;color:#000;');

    if( deletedUserIds.length || insertedItems.length )
    {
      this.applyChangesMutation(deletedUserIds, insertedItems);
    }
    else
    {
      this._prioritiesState.mode = PrioritiesMode.VIEW;
    }
  }

  @action.bound
  public async applyChangesMutation(deletedUserIds:Array<number>, insertedItems:Array<ApplyItem>):Promise<void>
  {
    const result:FetchResult<ApplyPrioritiesChangesMutation> =
      await this._commonController.mutate<ApplyPrioritiesChangesMutation,
        ApplyPrioritiesChangesMutationVariables>({
        mutation: ApplyPrioritiesChangesDocument,
        variables: {
          deleteUserIds: deletedUserIds,
          objects: insertedItems as Recruiting_Position_Group_Priority_Insert_Input
        }
      });

    console.log('%c applyChangesMutation result =', 'background:#0f0;color:#000;', result);

    const returning = result?.data;
    console.log('%c --- returning =', 'background:#080;color:#000;', returning);

    if( !returning ) return; // !!! ? TODO error message?

    runInAction(() =>
    {
      this._prioritiesState.mode = PrioritiesMode.VIEW;
    });
  }

  // ----------------------------------------------------

  @action.bound
  public inStateMoveTo(to:MoveTo, priority:Recruiting_Position_Group_Priority):void
  {
    console.log('%c moveTo =', 'background:#ff0;color:#000;', to);
    // console.log('%c -- user =', 'background:#080;color:#000;', toJS(user));
    console.log('%c -- priority =', 'background:#080;color:#000;', toJS(priority));

    const { userPrioritiesByUserId } = this._prioritiesState;

    const priorities:Array<Recruiting_Position_Group_Priority> | undefined = userPrioritiesByUserId(priority.user_id);

    if( !priorities || !priorities.length ) return; // !!! ?

    priorities
      .sort((p1, p2) => p1.priority - p2.priority)
      .forEach((priority, index) =>
      {
        priority.priority = index + 1;
      });

    const currentPriority:number = priority.priority;

    if( currentPriority === 1 && to === MoveTo.LEFT ) return; // !!! ?

    if( currentPriority === priorities.length && to === MoveTo.RIGHT ) return; // !!! ?

    const currentPriorityIndex:number = currentPriority - 1;

    let fromIndex:number = 0;
    let toIndex:number = 0;
    let delta:number = 0; // from...to priorities priority += delta
    let newCurrentPriorityValue:number = 0;

    switch( to )
    {
      case MoveTo.LEFT:
        delta = 1;
        fromIndex = toIndex = currentPriorityIndex - 1;
        newCurrentPriorityValue = currentPriority - 1;
        break;

      case MoveTo.RIGHT:
        delta = -1;
        fromIndex = toIndex = currentPriorityIndex + 1;
        newCurrentPriorityValue = currentPriority + 1;
        break;

      case MoveTo.FIRST:
        delta = 1;
        fromIndex = 0;
        toIndex = currentPriorityIndex - 1;
        newCurrentPriorityValue = 1;
        break;

      case MoveTo.LAST:
        delta = -1;
        fromIndex = currentPriorityIndex + 1;
        toIndex = priorities.length - 1;
        newCurrentPriorityValue = priorities.length;
        break;
    }

    console.log('%c -- =', 'background:#080;color:#000;', currentPriority, '->', newCurrentPriorityValue);

    priorities[currentPriorityIndex].priority = newCurrentPriorityValue;

    for( let i = fromIndex; i <= toIndex; i++ )
    {
      priorities[i].priority += delta;
    }

    priorities.sort((a, b) => a.priority - b.priority);

    return;
  }

  // ----------------------------------------------------

  @action.bound
  public inStateAddPriority(positionGroupId:number, userId:number):void
  {
    const { userPrioritiesByUserId } = this._prioritiesState;
    if( !userId ) return;

    const priorities:Array<Recruiting_Position_Group_Priority> | undefined = userPrioritiesByUserId(userId);

    if( !priorities || priorities.length === PRIORITIES_NUM_OF_COLUMNS.length )
    {
      console.log('%c ERROR: max length of priorities =', 'background:#f00;color:#ff0;', PRIORITIES_NUM_OF_COLUMNS.length);
      return;
    }

    const newPriority:Partial<Recruiting_Position_Group_Priority> = {
      user_id: userId,
      priority: priorities.length + 1,
      position_group_id: positionGroupId
    };

    priorities.push(newPriority as Recruiting_Position_Group_Priority);

    // console.log('%c ADD priorities =', 'background:#0f0;color:#000;', toJS(priorities));
  }

  // ----------------------------------------------------

  @action.bound
  public inStateReplacePriority(priority:Recruiting_Position_Group_Priority, positionGroupId:number):void
  {
    priority.position_group_id = positionGroupId;
  }

  // ----------------------------------------------------

  @action.bound
  public inStateDeletePriority(priority:Recruiting_Position_Group_Priority):void
  {
    const { userPrioritiesByUserId } = this._prioritiesState;
    const priorities:Array<Recruiting_Position_Group_Priority> | undefined = userPrioritiesByUserId(priority.user_id);

    if( !priorities ) return;

    this.inStateMoveTo(MoveTo.LAST, priority);

    priorities.pop();

    // console.log('%c DEL priorities =', 'background:#0f0;color:#000;', toJS(priorities));
  }

  // ----------------------------------------------------

  @bind
  public isDuplicate(priority:Recruiting_Position_Group_Priority):boolean
  {
    const { userPrioritiesByUserId } = this._prioritiesState;

    return !!userPrioritiesByUserId(priority.user_id)?.some((userPriority:Recruiting_Position_Group_Priority) =>
    {
      if( userPriority.priority === priority.priority ) return false;

      return userPriority.position_group_id === priority.position_group_id;
    });
  }

  @bind
  public hasDuplicates():boolean
  {
    const { userPrioritiesByUserId, users } = this._prioritiesState;

    return users.some((user:Common_User) => userPrioritiesByUserId(user.id)?.some(this.isDuplicate));
  }

  // ----------------------------------------------------

  public getExpandId(priority:Recruiting_Position_Group_Priority):string
  {
    return `${priority.user_id}:${priority.priority}`;
  }

  @action.bound
  public expandToggler(priority:Recruiting_Position_Group_Priority):void
  {
    const { expanded } = this._prioritiesState;

    const expandId:string = this.getExpandId(priority);

    this._prioritiesState.expanded = expanded && expanded === expandId ? '' : expandId;
  }

  // ----------------------------------------------------

  @bind
  public positionsCount(positions:Array<Recruiting_Position_Ex>):IPositionsCount
  {
    const { positionById } = this._prioritiesState;

    // positions[some_position] = {id: only}

    return PositionsUtil.getPositionsCount(positions, positionById);
  }

  // @bind
  // public getQuantityCountOfPositions(positions:Array<Recruiting_Position_Ex>):number
  // {
  //   const { positionById } = this._prioritiesState;
  //
  //   return positions.reduce((sum, pos) =>
  //   {
  //     console.log('%c pos =', 'background:#0f0;color:#000;', toJS(pos));
  //     const position:Recruiting_Position_Ex | undefined = positionById(pos.id);
  //     const openSlotsCount = Number(position?.open_slots_count.aggregate.count) + Number(position?.leaving_slots?.length);
  //
  //     return sum + (position ? openSlotsCount || 0 : 0);
  //   }, 0);
  // }

  @bind
  public getQuantityCountOfPriority(priority:Recruiting_Position_Group_Priority):number
  {
    if( !priority ) return -1; // !!!

    const { positionGroupById } = this._prioritiesState;

    const positionGroup:Recruiting_Position_Group | undefined = positionGroupById(priority.position_group_id);

    if( !positionGroup ) return -1; // !!!

    // return this.getQuantityCountOfPositions((positionGroup.positions || []) as Array<Recruiting_Position_Ex>);
    const positionsCount:IPositionsCount = this.positionsCount((positionGroup.positions || []) as Array<Recruiting_Position_Ex>);

    return positionsCount.open;
  }

  // ----------------------------------------------------

  @action.bound
  public reorderPriorities(userId:number, indexFrom:number, indexTo:number):void
  {
    // console.log('%c userId =', 'background:#0f0;color:#000;', userId, indexFrom, indexTo);

    const { userPrioritiesByUserId } = this._prioritiesState;
    const priorities:Array<Recruiting_Position_Group_Priority> | undefined = userPrioritiesByUserId(userId);

    if( !priorities || priorities.length === 1 || indexFrom === indexTo || indexTo >= priorities.length ) return;

    // const indexToOrMaxIndex:number = indexTo >= priorities.length ? priorities.length - 1 : indexTo;

    const [removed] = priorities.splice(indexFrom, 1);
    priorities.splice(indexTo, 0, removed);

    priorities.forEach((priority:Recruiting_Position_Group_Priority, index:number) =>
    {
      priority.priority = index + 1;
    });

    // priorities.sort((a, b) => a.priority - b.priority);

    // console.log('%c REORDER priorities =', 'background:#0f0;color:#000;', toJS(priorities));

  }

  // ----------------------------------------------------

  @action.bound
  public onDragStart(result:DragStart/*, provided:ResponderProvided */):void
  {
    // console.log('%c _onDragStart result =', 'background:#0f0;color:#000;', result);

    this._prioritiesState.draggableUserId = parseFloat(result.source.droppableId);
    this._prioritiesState.draggableIndexFrom = result.source.index;
    this._prioritiesState.draggableIndexOver = result.source.index;

    // const { userPrioritiesByUserId } = this._prioritiesState;
    // const priorities:Array<Recruiting_Position_Group_Priority> | undefined = userPrioritiesByUserId(this._prioritiesState.draggableUserId);
    // console.log('%c onDragStart priorities =', 'background:#0f0;color:#000;', toJS(priorities));
  }

  @action.bound
  public onDragUpdate(result:DragUpdate/*, provided:ResponderProvided */):void
  {
    // console.log('%c _onDragUpdate result =', 'background:#080;color:#000;', result);
    // console.log('%c _onDragUpdate  index =', 'background:#080;color:#000;', result.destination?.index);

    this._prioritiesState.draggableIndexOver = result.destination ? result.destination.index : -1;
  }

  @action.bound
  public onDragEnd(result:DropResult/*, provided:ResponderProvided */):void
  {
    // console.log('%c _onDragEnd result =', 'background:#ffc;color:#000;', result);
    this.resetDragState();

    if( !result.destination ) return;

    const { source: { droppableId: userId, index: indexFrom }, destination: { index: indexTo } } = result;

    this.reorderPriorities(parseFloat(userId), indexFrom, indexTo);
  }

  @action.bound
  public resetDragState():void
  {
    this._prioritiesState.draggableUserId = null;
    this._prioritiesState.draggableIndexFrom = -1;
    this._prioritiesState.draggableIndexOver = -1;
  }

  // ----------------------------------------------------
}
