import type { IReactComponent } from 'mobx-react/dist/types/IReactComponent';
import { Component } from 'react';

export function isClassComponent(component:IReactComponent):boolean
{
  return (
    typeof component === 'function' &&
    component.prototype &&
    !!component.prototype.isReactComponent
  );
}

export function isFunctionComponent(component:IReactComponent):boolean
{
  return (
    typeof component === 'function' &&
    (!component.prototype || !component.prototype.render) &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    !(component as any)['isReactClass'] &&
    !Object.prototype.isPrototypeOf.call(Component, component)
  );
}

export function isReactComponent(component:IReactComponent):boolean
{
  return isFunctionComponent(component) || isClassComponent(component);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasMobXDecorators(target:any):boolean
{
  for( const propertySymbol of Object.getOwnPropertySymbols(target) )
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const symbolValue:Record<string, any> = target[propertySymbol];

    if( symbolValue )
    {
      for( const propertyKey of Object.getOwnPropertyNames(symbolValue) )
      {
        if( symbolValue[propertyKey] && symbolValue[propertyKey].annotationType_ )
          return true;
      }
    }
  }

  const prototype:unknown = Object.getPrototypeOf(target);

  if( prototype )
    return hasMobXDecorators(prototype);

  return false;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function log(...args:Array<any>):void
{
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  // console.log(...args);
}
