import { Icon as BPIcon, IconSize as BPIconSize } from '@blueprintjs/core';
import type { IconName as BPIconName } from '@blueprintjs/icons';
import { IconNames as BPIconNames } from '@blueprintjs/icons';
import classNames from 'classnames/bind';
import * as React from 'react';

import styles from './Icon.module.less';

const cx = classNames.bind(styles);

export type IconName = BPIconName | null;
// export const IconNames:Record<string, IconName> = BPIconNames;
export const IconNames:(typeof BPIconNames) = BPIconNames;

export enum IconSize
{
  SMALL = 13,
  STANDARD = BPIconSize.STANDARD,
  LARGE = BPIconSize.LARGE,
}

export interface IconProps
{
  className?:string;

  icon:IconName;
  size?:IconSize | number;

  style?:React.CSSProperties;
}

export class Icon extends React.PureComponent<IconProps>
{
  public render():React.ReactNode
  {
    const {
      icon,
      className,
      style,
      size// = IconSize.STANDARD
    } = this.props;

    return (
      <BPIcon
        className={cx(styles.icon, className)}
        icon={icon}
        size={size}
        style={style}
      />
    );
  }
}
