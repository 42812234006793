import type { IReactionDisposer } from 'mobx';

export class PageUtil
{
  public static disposeReactions(disposers?:Array<IReactionDisposer>):void
  {
    console.log('%c disposeReactions: disposers =', 'background:#ff0;color:#000;', disposers?.length);

    if( !disposers || !disposers.length ) return;

    disposers.forEach((disposer) => disposer());

    disposers.length = 0;
  }
}

