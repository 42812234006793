import type { FetchResult } from '@apollo/client';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import type { Maybe } from '@flow/common/models/Types';
import {
  LinkCandidateToStaffDocument,
  LinkCandidateToStaffMutation,
  LinkCandidateToStaffMutationVariables,
  SlotsMoveCandidateToStaffDocument,
  SlotsMoveCandidateToStaffMutation,
  SlotsMoveCandidateToStaffMutationVariables,
  UpdateStaffFieldsDocument,
  UpdateStaffFieldsMutation,
  UpdateStaffFieldsMutationVariables
} from '@flow/data-access/lib/candidate.generated';
import {
  AddNewStaffUserDocument,
  AddNewStaffUserMutation,
  AddNewStaffUserMutationVariables
} from '@flow/data-access/lib/staff.generated';
import type { Recruiting_Candidate, Staffing_Customer_Team_Slot } from '@flow/data-access/lib/types/graphql.generated';
import {
  Recruiting_Interview_Flow,
  Staffing_Customer_Team_Slot_Insert_Input,
  Staffing_Customer_Team_Slot_Status_Enum
} from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import { ConvertToStaffState } from '@flow/modules/recruiting/common/convertToStaff/ConvertToStaffState';
import bind from 'bind-decorator';
import { action, toJS } from 'mobx';

@controller
export class ConvertToStaffController
{
  @di private _commonState!:CommonState;
  @di private _convertToStaffState!:ConvertToStaffState;

  @di private _commonController!:CommonController;

  // ----------------------------------------------------

  @action.bound
  public showConvertToStaffDialog(
    isShow:boolean,
    candidate?:Maybe<Recruiting_Candidate>,
    interviewFlows?:Array<Recruiting_Interview_Flow>,
    onClose?:() => void,
    onConfirm?:() => void
  ):void
  {
    if( isShow )
    {
      console.log('%c showConvertToStaffDialog: =', 'background:#0f0;color:#000;');
      console.log('%c --> candidate             =', 'background:#080;color:#000;', toJS(candidate));
    }

    this._convertToStaffState.candidate = candidate;
    this._convertToStaffState.interviewFlows = interviewFlows || [];
    this._convertToStaffState.onClose = onClose;
    this._convertToStaffState.onConfirm = onConfirm;
    this._convertToStaffState.isShowConvertToStaffDialog = isShow;
  }

  // ----------------------------------------------------

  @bind
  public async convertToStaff():Promise<void>
  {
    const { candidate, interviewFlows } = this._convertToStaffState;

    console.group('convertToStaff');
    console.log('%c convertToStaff:      =', 'background:#f0f;color:#000;');
    console.log('%c --> candidate        =', 'background:#0f0;color:#000;', toJS(candidate));
    console.log('%c --> interviewFlows   =', 'background:#0f0;color:#000;', toJS(interviewFlows));

    const currentFinalSlot:Maybe<Staffing_Customer_Team_Slot> = candidate?.customer_team_slots?.[0];
    console.log('%c --> currentFinalSlot =', 'background:#0f0;color:#000;', toJS(currentFinalSlot));

    if( !candidate || !currentFinalSlot || !interviewFlows.length )
    {
      !candidate && console.log('%c --> ERROR: =', 'background:#f00;color:#ff0;', 'NO CANDIDATE');
      !currentFinalSlot && console.log('%c --> ERROR: =', 'background:#f00;color:#ff0;', 'NO currentFinalSlot');
      !interviewFlows.length && console.log('%c --> ERROR: =', 'background:#f00;color:#ff0;', 'NO interviewFlows');
      console.groupEnd();
      return;
    }

    const result:FetchResult<AddNewStaffUserMutation> =
      await this._commonController.mutate<AddNewStaffUserMutation, AddNewStaffUserMutationVariables>
      ({
        mutation: AddNewStaffUserDocument,
        variables: {
          first_name: candidate.first_name,
          last_name: candidate.last_name,
          email: null
        }
      });

    console.log('%c --> AddNewStaffUser result =', 'background:#f60;color:#000;', result);

    if( result.errors && result.errors?.length > 0 )
      throw result.errors[0];

    const newStaffId = result.data?.insert_staffing_staff?.returning[0].id;

    if( !newStaffId )
      throw new Error('No ID for the new staff');

    // candidate.staff_id = newStaffId
    await this.linkStaffToCandidate(newStaffId);

    const hireDate:string = currentFinalSlot.next_candidate_start_date;
    const staffRoleId:number | undefined = currentFinalSlot.position.position_template?.staff_role_id;

    console.log('%c --> hireDate    =', 'background:#0f0;color:#000;', toJS(hireDate));
    console.log('%c --> staffRoleId =', 'background:#0f0;color:#000;', toJS(staffRoleId));

    const resultUpdateStaffFields:FetchResult<UpdateStaffFieldsMutation> =
      await this._commonController.mutate<UpdateStaffFieldsMutation, UpdateStaffFieldsMutationVariables>
      ({
        mutation: UpdateStaffFieldsDocument,
        variables: {
          staffId: newStaffId,
          staff: {
            hire_date: hireDate,
            staff_role_id: staffRoleId,
            first_name_i18n: candidate.first_name_i18n,
            last_name_i18n: candidate.last_name_i18n,
            middle_name_i18n: candidate.middle_name_i18n,
            avatar_url: candidate.avatar_url,
            phone: candidate.phone,
            country_id: candidate.country_id,
            city_id: candidate.city_id,
            linkedin_profile_url: candidate.linkedin_profile_url
          }
        }
      });

    console.log('%c --> UpdateStaffFields result =', 'background:#f60;color:#000;', resultUpdateStaffFields);

    const slotInputs:Array<Partial<Staffing_Customer_Team_Slot>> = [];

    const slotInput:Partial<Staffing_Customer_Team_Slot> = {
      id: currentFinalSlot.id,
      status: currentFinalSlot.status,
      position_id: currentFinalSlot.position_id,

      staff_id: currentFinalSlot.staff_id,
      start_date: currentFinalSlot.start_date,
      leaving_date: null,

      next_staff_id: null,
      next_staff_start_date: null,

      next_candidate_id: null,
      next_candidate_start_date: null,
      next_candidate_allocation_id: null
    };

    if( currentFinalSlot.staff_id === null )
    {
      slotInput.status = Staffing_Customer_Team_Slot_Status_Enum.CurrentStaff;
      slotInput.staff_id = newStaffId;
      slotInput.start_date = currentFinalSlot.next_candidate_start_date;
    }
    else
    {
      slotInput.next_staff_id = newStaffId;
      slotInput.next_staff_start_date = currentFinalSlot.next_candidate_start_date;
    }

    slotInputs.push(slotInput);

    console.log('%c --> slotInput =', 'background:#0f0;color:#000;', toJS(slotInput));

    const resultSlotsMoveCandidateToStaff:FetchResult<SlotsMoveCandidateToStaffMutation> =
      await this._commonController.mutate<SlotsMoveCandidateToStaffMutation, SlotsMoveCandidateToStaffMutationVariables>
      ({
        mutation: SlotsMoveCandidateToStaffDocument,
        variables: {
          slots: slotInputs as Staffing_Customer_Team_Slot_Insert_Input
        }
      });

    console.log('%c --> SlotsMoveCandidateToStaff result =', 'background:#f60;color:#000;', resultSlotsMoveCandidateToStaff);
    console.groupEnd();
  }

  // ----------------------------------------------------

  @bind
  public async linkStaffToCandidate(staffId:number):Promise<void>
  {
    const { candidate, candidateFlow } = this._convertToStaffState;

    if( !candidate || !candidateFlow )
    {
      !candidate && console.log('%c ERROR linkStaffToCandidate -> candidate =', 'background:#f00;color:#ff0;', toJS(candidate));
      !candidateFlow && console.log('%c ERROR linkStaffToCandidate -> candidateFlow =', 'background:#f00;color:#ff0;', toJS(candidateFlow));
      throw new Error('No candidate in state.');
    }

    console.log('%c link Staff -> Candidate =', 'background:#f0f;color:#000;', staffId, '->', candidate.id);

    const flowStageId:number = candidateFlow.stages[candidateFlow.stages.length - 1].id;

    const result:FetchResult<LinkCandidateToStaffMutation> =
      await this._commonController.mutate<LinkCandidateToStaffMutation, LinkCandidateToStaffMutationVariables>({
        mutation: LinkCandidateToStaffDocument,
        variables: {
          candidateId: candidate.id,
          staffId,
          flowStageId
        }
      });

    console.log('%c --> linkStaffToCandidate result =', 'background:#f60;color:#000;', result);
  }

  // ----------------------------------------------------
}
