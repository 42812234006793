import { CommonController } from '@flow/common/CommonController';
import { FullscreenPage } from '@flow/common/components/page/FullscreenPage';
import { PageTitle } from '@flow/common/components/page/PageTitle';
import type { Maybe } from '@flow/common/models/Types';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di } from '@flow/dependency-injection';
import { ScheduleCalendar } from '@flow/modules/recruiting/schedule/components/ScheduleCalendar';
import { ScheduleToolbar } from '@flow/modules/recruiting/schedule/components/ScheduleToolbar';
import React from 'react';
import { ScheduleController } from './ScheduleController';
import { ScheduleEventForEdit, ScheduleState } from './ScheduleState';

@componentWithRouter
export class Schedule extends React.Component<IWithRouterProps>
{
  @di private _scheduleState!:ScheduleState;
  @di private _scheduleController!:ScheduleController;
  @di private _commonController!:CommonController;

  // ----------------------------------------------------

  public componentDidMount():void
  {
    // const { currentScheduledEvent } = this._scheduleState;

    // if( !currentScheduledEvent )
    //   this._commonController.navigateOneLevelUp();

    const { params } = this.props;

    console.log('%c Schedule PAGE params =', 'background:#0f0;color:#000;', params);

    if( !params || !params.eventId )
      return;

    this._scheduleController.initSchedule(parseInt(params.eventId as string));
  }

  // ----------------------------------------------------

  public render():React.ReactNode | null
  {
    const { btnBackUrl, sidebarElement, callDetailsEventId, eventsForEdit } = this._scheduleState;

    if( !callDetailsEventId ) return null;

    const eventForEdit:Maybe<ScheduleEventForEdit> = eventsForEdit.get(callDetailsEventId);

    if( !eventForEdit ) return null;

    return (
      <>
        <PageTitle title={'Schedule a call'} />

        <FullscreenPage
          title={eventForEdit.event.is_scheduled ? 'Upcoming call' : 'Schedule a call'}
          btnBackTitle={'Back to Candidate'}
          btnBackUrl={btnBackUrl}
          sidebarElements={sidebarElement}
          contentElements={
            <>
              <ScheduleToolbar />
              <ScheduleCalendar />
            </>
          }
        />
      </>
    );
  }

  // ----------------------------------------------------
}
