import { Button, ButtonSize } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import type { IItemsCountProps } from '@flow/common/components/page/ItemsCount';
import { Intent } from '@flow/common/components/types/Types';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './Pagination.module.less';

const cx = classNames.bind(styles);

// TODO: remove
const __DEBUG:boolean = false;

interface PaginationProps extends IItemsCountProps
{
  className?:string;
  onChange:(newCurrentPage:number) => void;
}

interface PaginationItem
{
  pageNumber?:number;
  isEllipsis?:boolean;
}

const NUMBER_OF_MOBILE_PAGES = 7; // min 5
const NUMBER_OF_DESKTOP_PAGES = 11; // min 5
// < prev  1   2   [ 3]  ...  99  next >
// < prev  1  ...  [ 4]  ...  99  next >
// < prev  1  ...  [97]   98  99  next >

export class Pagination extends React.PureComponent<PaginationProps>
{
  // ---------------------------------------------------------

  @bind
  private _getPagesCount():number
  {
    const { itemsPerPage, itemsTotal } = this.props;

    return Math.ceil(itemsTotal / itemsPerPage);
  }

  @bind
  private _getPages(viewPages:number):Array<PaginationItem>
  {
    const { currentPage, onChange } = this.props;

    const pagesCount:number = this._getPagesCount();

    __DEBUG && console.log('%c pagesCount =', 'background:#080;color:#000;', pagesCount);

    const pages:Array<PaginationItem> = new Array(pagesCount <= viewPages ? pagesCount : viewPages)
      .fill({});

    // wait for currentPage in props = 1
    // < prev  ...  ...  ...  ...  ...  ...  ...  next >
    if( currentPage > pagesCount )
    {
      onChange(1);
      pages.map((item:PaginationItem) => item.isEllipsis = true);
      return pages;
    }

    // < prev  [1]  2  3  next >
    if( pagesCount <= viewPages )
    {
      return pages.map((item:PaginationItem, i:number) =>
      {
        return { pageNumber: i + 1 };
      });
    }

    pages[0] = { pageNumber: 1 };
    pages[viewPages - 1] = { pageNumber: pagesCount };

    const middlePoint:number = Math.floor(viewPages / 2);
    __DEBUG && console.log('%c middlePoint =', 'background:#080;color:#000;', middlePoint);

    // < prev  1  2  3  [4]  5  ...  99  next >
    if( currentPage <= middlePoint + 1 )
    {
      __DEBUG && console.log('%c #1 =', 'background:#080;color:#000;');
      pages[viewPages - 2] = { isEllipsis: true };

      for( let i = 1; i < viewPages - 2; i++ )
      {
        pages[i] = { pageNumber: i + 1 };
      }
    }
    // < prev  1  ...  95  [96]  97  98  99  next >
    else if( (pagesCount - currentPage) < middlePoint + 1 )
    {
      __DEBUG && console.log('%c #2 =', 'background:#080;color:#000;');
      pages[1] = { isEllipsis: true };

      for( let i = viewPages - 1; i >= 2; i-- )
      {
        pages[i] = { pageNumber: pagesCount - viewPages + i + 1 };
      }
    }
    // < prev  1  ...  4  [5]  6  ...  99  next >
    else
    {
      __DEBUG && console.log('%c #3 =', 'background:#080;color:#000;');
      pages[1] = { isEllipsis: true };
      pages[viewPages - 2] = { isEllipsis: true };
      pages[middlePoint] = { pageNumber: currentPage };

      for( let i = 1; i <= (viewPages - 5) / 2; i++ )
      {
        pages[middlePoint + i] = { pageNumber: Number(currentPage) + i };
        pages[middlePoint - i] = { pageNumber: Number(currentPage) - i };
      }
    }

    return pages;
  }

  // ---------------------------------------------------------

  @bind
  private _renderPage(page:PaginationItem, index:number):React.ReactNode
  {
    const { currentPage, onChange } = this.props;

    __DEBUG && console.log('%c page, index =', 'background:#080;color:#000;', page, index);

    const isCurrent:boolean = currentPage === page.pageNumber;

    return (
      <Button
        key={page.pageNumber}
        className={cx(styles.btn, {
          isCurrent,
          isDisabled: page.isEllipsis,
          isEllipsis: page.isEllipsis
        }, 'test-pagination-btns')}
        minimal
        size={ButtonSize.SMALL}
        intent={Intent.PRIMARY}
        text={page.isEllipsis ? '...' : page.pageNumber}
        disabled={page.isEllipsis}
        onClick={():void => onChange(page.pageNumber || 1) as void}
      />
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode | null
  {
    const {
      className,
      currentPage, itemsPerPage, itemsTotal,
      onChange
    } = this.props;
    const pagesCount:number = this._getPagesCount();

    __DEBUG && console.log('---------------------------------- =');
    __DEBUG && console.log('%c currentPage =', 'background:#0f0;color:#000;', currentPage);
    __DEBUG && console.log('%c itemsPerPage, itemsCount =', 'background:#0f0;color:#000;', itemsPerPage, itemsTotal);

    if( itemsTotal < itemsPerPage )
    {
      if( currentPage > 1 )
      {
        onChange(1); // !!!
      }
      return null;
    }

    const pagesMobile:Array<PaginationItem> = this._getPages(NUMBER_OF_MOBILE_PAGES);
    const pagesDesktop:Array<PaginationItem> = this._getPages(NUMBER_OF_DESKTOP_PAGES);

    __DEBUG && console.log('%c pages =', 'background:#080;color:#000;', pagesMobile);
    __DEBUG && console.log('%c pages =', 'background:#080;color:#000;', pagesDesktop);

    const prevDisabled:boolean = currentPage === 1;
    const nextDisabled:boolean = currentPage === pagesCount;

    return (
      <div className={cx(styles.wrapper, className)}>

        <Button
          className={cx(styles.btn, { isDisabled: prevDisabled })}
          icon={IconNames.CHEVRON_LEFT}
          text={'Prev'}
          size={ButtonSize.SMALL}
          intent={Intent.PRIMARY}
          minimal
          disabled={prevDisabled}
          onClick={():void => onChange(Number(currentPage) - 1) as void}
        />

        <div className={styles.mobile}>
          {pagesMobile.map(this._renderPage)}
        </div>
        <div className={styles.desktop}>
          {pagesDesktop.map(this._renderPage)}
        </div>

        <Button
          className={cx(styles.btn, { isDisabled: nextDisabled })}
          rightIcon={IconNames.CHEVRON_RIGHT}
          text={'Next'}
          size={ButtonSize.SMALL}
          intent={Intent.PRIMARY}
          minimal
          disabled={nextDisabled}
          onClick={():void => onChange(Number(currentPage) + 1) as void}
        />

      </div>
    );
  }

  // ---------------------------------------------------------
}
