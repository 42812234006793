import { controller, di } from '@flow/dependency-injection';
import { environment } from '../../environments/environment';
import { AuthState } from '../controllers/AuthState';

@controller
export class FlowApiController
{
  @di private readonly _authState!:AuthState;

  public async filesUpload(file:File, name:string, path:string, progressCallback?:EventListenerOrEventListenerObject):Promise<string>
  {
    const { user } = this._authState;

    if( !user )
      throw new Error('Logged out!');

    const formData = new FormData();

    const namedFile = new File([file], name, { type: file.type });

    formData.append('file', namedFile);
    formData.append('path', path);

    const request = new XMLHttpRequest();

    return new Promise((resolve) =>
    {
      request.open('POST',`${environment.flowApiBaseUrl}/files/upload`);
      request.setRequestHeader('Authorization', `Bearer ${user.accessToken}`)

      if( progressCallback )
        request.upload.addEventListener('progress', progressCallback, false);

      request.addEventListener('load', (event:ProgressEvent) =>
      {
        if( event && event.target )
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          resolve(String(JSON.parse((event.target as any).responseText as string).url));
      });

      request.addEventListener('error', (event:ProgressEvent) =>
      {
        if( event && event.target )
          throw new Error('Upload failed');
      });

      request.send(formData);
    })
  }

  public async filesDownload(path:string, asName?:string):Promise<void>
  {
    const { user } = this._authState;

    if( !user )
      throw new Error('Logged out!');

    const params = new URLSearchParams([
      ['fileName', path]
    ])

    const response = await fetch(`${environment.flowApiBaseUrl}/files?` + params.toString(), {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${user.accessToken}`
      })
    });

    const blob = await response.blob();
    const data = URL.createObjectURL(blob);

    const a = document.createElement('a');
    const href = data;
    a.href = href;

    if( asName )
      a.download = asName;

    a.click();
    a.remove();
  }
}
