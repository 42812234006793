import type { TabId } from '@blueprintjs/core';
import { Tab, Tabs } from '@blueprintjs/core';

import styles from '@flow/common/styles/TabsPanel.module.less';
import { component, di } from '@flow/dependency-injection';
import { StaffMemberPanelAttachments } from '@flow/modules/staffing/staff/components/member/StaffMemberPanelAttachments';
import { StaffMemberPanelInfo } from '@flow/modules/staffing/staff/components/member/StaffMemberPanelInfo';
import { StaffMemberState, StaffMemberTabName } from '@flow/modules/staffing/staff/StaffMemberState';
import classNames from 'classnames/bind';
import { runInAction } from 'mobx';
import React from 'react';

const cx = classNames.bind(styles);

@component
export class StaffMemberProfileTabs extends React.Component
{
  @di private _staffMemberState!:StaffMemberState;

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    return (
      <Tabs
        className={cx(styles.tabs)}
        animate={false}
        selectedTabId={this._staffMemberState.tabId}
        defaultSelectedTabId={StaffMemberTabName.INFO}
        onChange={(tabId:TabId):void =>
        {
          runInAction(() => this._staffMemberState.tabId = tabId);
        }}
      >

        <Tab
          className={cx({ activeTab: this._staffMemberState.tabId == StaffMemberTabName.INFO })}
          panelClassName={styles.tabPanel}
          id={StaffMemberTabName.INFO}
          title={'Info'}
          panel={<StaffMemberPanelInfo />}
        />

        <Tab
          className={cx({ activeTab: this._staffMemberState.tabId == StaffMemberTabName.ATTACHMENTS })}
          panelClassName={styles.tabPanel}
          id={StaffMemberTabName.ATTACHMENTS}
          title={(
            <div className={styles.tabTitle}>
              Attachments
              <span className={styles.titleBadge}>0</span>
            </div>
          )}
          panel={<StaffMemberPanelAttachments />}
        />

      </Tabs>
    );
  }

  // ----------------------------------------------------
}
