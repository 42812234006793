import type { Maybe } from '@flow/common/models/Types';
import type { Common_Scheduled_Event, Common_User } from '@flow/data-access/lib/types/graphql.generated';
import { BaseState, state } from '@flow/dependency-injection';
import { observable } from 'mobx';
import type React from 'react';

export interface ScheduleEvent
{
  id?:number;
  start_time:string | number;
  end_time:string | number;
  selected?:boolean;
  isFreeBusy:boolean;
}

export interface ScheduleCallUser
{
  user:Partial<Common_User>;
  timezone:string; // ???
  events:Array<ScheduleEvent>;
  // busy:Array<ScheduleEvent>;
}

export type ScheduleEventForEdit = {
  event:Partial<Common_Scheduled_Event>;
  snapshot:Partial<Common_Scheduled_Event>;
}

@state
export class ScheduleState extends BaseState
{
  // ----------------------------------------------------

  @observable public isScheduleLoaded:boolean = false;

  // @observable public users:Array<ScheduleCallUser> = [];

  // ----------------------------------------------------

  @observable public viewDate:number | string | null = null;

  // @observable public scheduleDate:number | string | null = null;

  // @observable public eventStartTime:number | string | null = null;
  // @observable public eventEndTime:number | string | null = null;

  // ----------------------------------------------------

  @observable public scrollBarWidth:number = 0;

  // ----------------------------------------------------

  @observable public eventsForEdit:Map<number, ScheduleEventForEdit> = new Map<number, ScheduleEventForEdit>();

  @observable public draftEvent:Partial<Common_Scheduled_Event> | null = null;
  @observable public callDetailsEventId:Maybe<number> = null;

  @observable public allUsers:Array<Common_User> = [];
  @observable public scheduledEventStartTimeQuery:string = '';
  @observable public scheduledEventEndTimeQuery:string = '';
  @observable public scheduledEventFreeBusy:Array<ScheduleCallUser> = [];

  @observable public scheduledEventGoogleMeetLink:string | null = null;

  @observable public sidebarElement:() => React.ReactNode | null = () => null;

  @observable public btnBackUrl:string | null = null;
}
