import { component, di } from '@flow/dependency-injection';
import React from 'react';
import { RoleController } from '../controllers/RoleController';
import type { IFlowPermission } from '../models/FlowPermissions';

export interface IPermissionProps
{
  name?:IFlowPermission;
  anyOf?:Array<IFlowPermission>;
  disallowedElement?:JSX.Element | string;
}

@component
export class NeedsPermission extends React.Component<IPermissionProps>
{
  @di private _roleController!:RoleController;

  public render():React.ReactNode
  {
    const { name, anyOf, children, disallowedElement } = this.props;

    if( anyOf )
      return (this._roleController.hasAnyPermission(anyOf as Array<IFlowPermission>)
        ? children
        : disallowedElement || null) as JSX.Element;

    if( typeof(name) === 'undefined' )
      throw new Error('Unable to check permissions.');

    return (this._roleController.hasPermission(name as IFlowPermission)
      ? children
      : disallowedElement || null) as JSX.Element;
  }
}
