import { component, di } from '@flow/dependency-injection';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';
import { CustomersState } from '../../CustomersState';

import styles from './CustomersListHeader.module.less';

const cx = classNames.bind(styles);

export interface ICustomersListHeaderProps
{
  isInternal?:boolean;
}

@component
export class CustomersListHeader extends React.Component<ICustomersListHeaderProps>
{
  @di private _customersState!:CustomersState;

  public render():ReactNode
  {
    const { isInternal } = this.props;

    if( isInternal )
    {
      return (
        <div className={styles.internal}>
          <div className={styles.headerTitle}>
            Internal
          </div>
        </div>
      );
    }

    return (
      <div className={styles.header}>

        <div className={cx(styles.headerTitle, styles.name)}>
          <span className={styles.desktop}>
            Name
          </span>
          <span className={styles.mobile}>
            Customer & Teams
          </span>
        </div>

        <div className={cx(styles.headerTitle, styles.alias)}>
          Nickname
        </div>

        <div className={cx(styles.headerTitle, styles.total)}>
          Staffed/Total
        </div>

        <div className={cx(styles.headerTitle, styles.teams)}>
          Teams
        </div>

      </div>
    );
  }
}
