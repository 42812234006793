import { CommonController } from '@flow/common/CommonController';
import { RouteName } from '@flow/common/models/routing/RouteName';
import { PageUtil } from '@flow/common/utils/PageUtil';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di, diModule } from '@flow/dependency-injection';
import { CustomersController } from '@flow/modules/customers/teams/CustomersController';
import { CustomersState } from '@flow/modules/customers/teams/CustomersState';
import { StaffBoardContent } from '@flow/modules/staffing/board/components/StaffBoardContent';
import { runInAction } from 'mobx';
import React from 'react';
import type { Params } from 'react-router-dom';
import { PageNotFound } from '../../../pages/404';
import { IMatchedRouteRoute, RoutesConfig } from '../../../pages/RoutesConfig';
import { StaffBoardToolbar } from './components/StaffBoardToolbar';
import { StaffBoardController } from './StaffBoardController';
import { StaffBoardState } from './StaffBoardState';

// TODO: remove
const __DEBUG:boolean = true;

@diModule({
  providers: [
    CustomersController,
    CustomersState,
    StaffBoardController,
    StaffBoardState
  ]
})
@componentWithRouter
export class StaffBoardPage extends React.Component<IWithRouterProps>
{
  @di private _commonController!:CommonController;
  @di private _staffBoardState!:StaffBoardState;
  @di private _staffBoardController!:StaffBoardController;

  // ----------------------------------------------------

  public componentDidMount():void
  {
    this._staffBoardController.initFromLocalStorage();

    // this.checkCustomerIdPathParam(this.props.params as Params<string>);
    this._staffBoardController.initCustomers(this.props.params as Params<string>);
  }

  // ----------------------------------------------------

  // @bind
  // public checkCustomerIdPathParam(params:Params<string>):void
  // {
  //   __DEBUG && console.log('%c checkCustomerIdPathParam params =', 'background:#ff0;color:#000;', params);
  //   __DEBUG && console.log('%c --> this._staffBoardState.customers =', 'background:#aa0;color:#000;', toJS(this._staffBoardState.customers));
  //
  //   this._staffBoardController.initCustomers(params);
  // }

  // ----------------------------------------------------

  public UNSAFE_componentWillReceiveProps(nextProps:Readonly<IWithRouterProps>, nextContext:any):void
  {
    __DEBUG && console.log('%c componentWillReceiveProps =', 'background:#f0f;color:#000;', nextProps);

    const { pageNotFound } = this._staffBoardState;

    const { params } = nextProps;

    const customerId:number = parseInt(params?.customerId || '');

    const routeConfig:IMatchedRouteRoute | null = RoutesConfig.getRouteConfig(RouteName.STAFFING_BOARD);

    __DEBUG && console.log('%c --> params =', 'background:#080;color:#000;', params);
    __DEBUG && console.log('%c --> routeConfig =', 'background:#080;color:#000;', routeConfig);

    if( params && routeConfig && params['*'] )
    {
      const paramsPath:string = (params?.['*'] || '').replace(/\//g, '');
      const routeConfigPath:string = routeConfig.fullPath.replace(/\//g, '');

      __DEBUG && console.log('%c --> paramsPath =', 'background:#080;color:#000;', paramsPath);
      __DEBUG && console.log('%c --> routeConfigPath =', 'background:#080;color:#000;', routeConfigPath);

      // STAFFING_BOARD home = /staffing/board/
      if( paramsPath !== routeConfigPath ) return;

      __DEBUG && console.log('%c STAFFING_BOARD home =', 'background:#ff0;color:#000;');

      if( pageNotFound )
      {
        __DEBUG && console.log('%c --> pageNotFound =', 'background:#080;color:#000;', pageNotFound);
        runInAction(() =>
        {
          this._staffBoardState.selectedCustomerId = null;
          this._staffBoardState.pageNotFound = false;
          this._staffBoardState.isCustomersLoading = false;
          this._staffBoardState.isCustomersLoaded = false;
        });
      }

      const { selectedCustomerId } = this._staffBoardState;

      if( customerId || selectedCustomerId )
      {
        __DEBUG && console.log('%c --> customerId =', 'background:#080;color:#000;', customerId);
        __DEBUG && console.log('%c --> selectedCustomerId =', 'background:#080;color:#000;', selectedCustomerId);
        this._staffBoardController.selectCustomer(customerId || selectedCustomerId || -1);
      }
      else
      {
        this._staffBoardController.initCustomers(params);
      }
    }
  }

  // ----------------------------------------------------

  public componentWillUnmount():void
  {
    PageUtil.disposeReactions(this._staffBoardState.disposers);
  }

  // ----------------------------------------------------

  public render():React.ReactNode | null
  {
    const { pageNotFound, isCustomersLoaded } = this._staffBoardState;

    __DEBUG && console.log('%c pageNotFound =', 'background:#0f0;color:#000;', pageNotFound);
    if( pageNotFound ) return <PageNotFound />;

    __DEBUG && console.log('%c isCustomersLoaded =', 'background:#0f0;color:#000;', isCustomersLoaded);
    if( !isCustomersLoaded ) return null;

    return (
      <>
        <StaffBoardToolbar />
        <StaffBoardContent />
      </>
    );
  }

  // ---------------------------------------------------------
}
