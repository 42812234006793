import { MenuItem } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { DialogRow } from '@flow/common/components/page/DialogRow';
import { DialogSelectButton } from '@flow/common/components/page/DialogSelectButton';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { Staffing_Staff_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { StaffFiltersController } from '@flow/modules/staffing/staff/StaffFiltersController';
import { StaffMemberController } from '@flow/modules/staffing/staff/StaffMemberController';
import { StaffMemberState, StaffStatusTitle } from '@flow/modules/staffing/staff/StaffMemberState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './StaffStatusSelector.module.less';

const cx = classNames.bind(styles);

interface IStatusItem
{
  value:Staffing_Staff_Status_Enum;
  title:string;
}

const StatusItem:Record<string, IStatusItem> = {
  [Staffing_Staff_Status_Enum.Newcomer]: {
    value: Staffing_Staff_Status_Enum.Newcomer,
    title: StaffStatusTitle[Staffing_Staff_Status_Enum.Newcomer]
  },
  [Staffing_Staff_Status_Enum.Staffed]: {
    value: Staffing_Staff_Status_Enum.Staffed,
    title: StaffStatusTitle[Staffing_Staff_Status_Enum.Staffed]
  },
  [Staffing_Staff_Status_Enum.Leaving]: {
    value: Staffing_Staff_Status_Enum.Leaving,
    title: StaffStatusTitle[Staffing_Staff_Status_Enum.Leaving]
  },
  [Staffing_Staff_Status_Enum.Archived]: {
    value: Staffing_Staff_Status_Enum.Archived,
    title: StaffStatusTitle[Staffing_Staff_Status_Enum.Archived]
  }
};

@component
export class StaffStatusSelector extends React.Component
{
  @di private _staffMemberState!:StaffMemberState;
  @di private _staffMemberController!:StaffMemberController;

  @di private _staffFiltersController!:StaffFiltersController;
  @di private _roleController!:RoleController;

  // ---------------------------------------------------------

  @bind
  private _itemRenderer(item:IStatusItem,
                        { handleClick, modifiers }:IItemRendererProps):JSX.Element | null
  {
    if( !modifiers.matchesPredicate ) return null;

    const { staffMemberSlotsIds } = this._staffMemberState;

    const warning:boolean = item.value === Staffing_Staff_Status_Enum.Archived && !!staffMemberSlotsIds.length;

    return (
      <MenuItem
        key={item.value}
        className={styles.menuItem}
        text={item.title}
        active={modifiers.active}
        onClick={handleClick}
        // selected={flowStageItem.id === candidateFlowStageId}

        // right icon
        labelElement={<Icon className={styles.iconWarning} icon={warning ? IconNames.WARNING_SIGN : null} />}
      />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _onItemSelect(item:IStatusItem):void
  {
    this._staffMemberController.setStaffStatus(item.value);
  }

  // ---------------------------------------------------------

  @bind
  private _getSelectItems():Array<IStatusItem>
  {
    const { staffMember, newStaffStatus } = this._staffMemberState;

    const result:Array<IStatusItem> = [];

    if( !staffMember ) return result;

    const { status: currentStatus } = staffMember;

    if( currentStatus === Staffing_Staff_Status_Enum.Newcomer )
    {
      result.push(StatusItem[Staffing_Staff_Status_Enum.Staffed]);
      result.push(StatusItem[Staffing_Staff_Status_Enum.Leaving]);
    }
    else if( currentStatus === Staffing_Staff_Status_Enum.Staffed )
    {
      result.push(StatusItem[Staffing_Staff_Status_Enum.Leaving]);
    }
    else if( currentStatus === Staffing_Staff_Status_Enum.Leaving )
    {
      if( newStaffStatus === Staffing_Staff_Status_Enum.Leaving )
      {
        result.push(StatusItem[Staffing_Staff_Status_Enum.Staffed]);
        result.push(StatusItem[Staffing_Staff_Status_Enum.Archived]);
      }
      else if( newStaffStatus === Staffing_Staff_Status_Enum.Staffed )
      {
        result.push(StatusItem[Staffing_Staff_Status_Enum.Leaving]);
        result.push(StatusItem[Staffing_Staff_Status_Enum.Archived]);
      }
      else if( newStaffStatus === Staffing_Staff_Status_Enum.Archived )
      {
        result.push(StatusItem[Staffing_Staff_Status_Enum.Staffed]);
        result.push(StatusItem[Staffing_Staff_Status_Enum.Leaving]);
      }
    }
    // else if( currentStatus === Staffing_Staff_Status_Enum.Archived )
    // {
    //
    // }

    return result;
  }

  // ---------------------------------------------------------

  public render():ReactNode | null
  {
    const { staffMemberName, newStaffStatus } = this._staffMemberState;

    if( !staffMemberName ) return null;

    const isDisabled:boolean = !this._roleController.hasPermission(FlowPermissions.EditStaff);

    const buttonText:string = newStaffStatus ? StaffStatusTitle[newStaffStatus] : '?';

    return (
      <DialogRow
        label={'Choose status'}
        labelOnSingleLine
      >
        <Select
          className={cx(styles.select, { isDisabled: isDisabled })}
          items={this._getSelectItems()}
          itemRenderer={this._itemRenderer}
          onItemSelect={this._onItemSelect}
          filterable={false}
          disabled={isDisabled}
          popoverProps={{
            minimal: true,
            usePortal: false
          }}
        >
          <DialogSelectButton
            text={buttonText}
            disabled={isDisabled}
          />
        </Select>

      </DialogRow>
    );
  }

  // ---------------------------------------------------------
}
