import type { TagProps as BPTagProps } from '@blueprintjs/core';
import { Tag as BPTag } from '@blueprintjs/core';
// import classNames from 'classnames/bind';
import * as React from 'react';
import type { Intent } from '../types/Types';
import { getBPIntent } from '../types/Types';

// import styles from './Tag.module.less';

// const cx = classNames.bind(styles);

export interface TagProps extends Omit<BPTagProps, 'intent'>
{
  className?:string;
  intent?:Intent;
}

export class Tag extends React.PureComponent<TagProps>
{
  public render():React.ReactNode
  {
    const {
      className,
      intent
    } = this.props;

    return (
      <BPTag
        {...this.props}
        className={className}
        intent={getBPIntent(intent)}
      />
    );
  }
}
