import { ConfirmationDialog } from '@flow/common/components/page/ConfirmationDialog';
import { Intent } from '@flow/common/components/types/Types';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { component, di } from '@flow/dependency-injection';
import { ConvertToStaffController } from '@flow/modules/recruiting/common/convertToStaff/ConvertToStaffController';
import { ConvertToStaffState } from '@flow/modules/recruiting/common/convertToStaff/ConvertToStaffState';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './ConvertToStaffDialog.module.less';

const cx = classNames.bind(styles);

@component
export class ConvertToStaffDialog extends React.Component
{
  @di private _roleController!:RoleController;

  @di private _convertToStaffState!:ConvertToStaffState;
  @di private _convertToStaffController!:ConvertToStaffController;

  // ---------------------------------------------------------

  public render():ReactNode | null
  {
    const { isShowConvertToStaffDialog, onClose, onConfirm } = this._convertToStaffState;

    const isDisabled:boolean = !this._roleController.hasPermission(FlowPermissions.ChangeCandidateFlowStage);

    if( isDisabled ) return null;

    return (
      <ConfirmationDialog
        title={'Convert candidate to staff?'}
        confirmButtonText={'Convert'}
        primaryButtonIntent={Intent.PRIMARY}
        isOpen={isShowConvertToStaffDialog}
        onClose={():void =>
        {
          onClose?.();
          this._convertToStaffController.showConvertToStaffDialog(false);
        }}
        onConfirm={():void =>
        {
          this._convertToStaffController.convertToStaff().then(() =>
          {
            onConfirm?.();
            this._convertToStaffController.showConvertToStaffDialog(false);
          });
        }}
      />
    );
  }

  // ---------------------------------------------------------
}
