import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { CommonController } from '@flow/common/CommonController';
import { SvgIcon, SvgIconName } from '@flow/common/components/elements/SvgIcon';
import { AuthState } from '@flow/common/controllers/AuthState';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { RouteName } from '@flow/common/models/routing/RouteName';
import type { Common_Scheduled_Event } from '@flow/data-access/lib/types/graphql.generated';
import { di } from '@flow/dependency-injection';
import { CandidateState } from '@flow/modules/recruiting/candidates/CandidateState';
import { FeedbackFormController } from '@flow/modules/recruiting/feedbackForm/FeedbackFormController';
import { FeedbackFormState } from '@flow/modules/recruiting/feedbackForm/FeedbackFormState';
import { RoutesConfig } from 'apps/flow/src/pages/RoutesConfig';
import classNames from 'classnames/bind';
import { computed, runInAction } from 'mobx';
import React from 'react';
import styles from '../eventForm/CommunicationEventView.module.less';

const cx = classNames.bind(styles);

interface Props
{
  event:Common_Scheduled_Event;
  historyView?:boolean;
}

export class FeedbackLink extends React.Component<Props>
{
  @di private _candidateState!:CandidateState;
  @di private readonly _commonController!:CommonController;
  @di private readonly _feedbackFormState!:FeedbackFormState;
  @di private readonly _feedbackFormController!:FeedbackFormController;

  @di private readonly _authState!:AuthState;
  @di private readonly _roleController!:RoleController;

  // ---------------------------------------------------------

  @computed
  private get _hasPermissions():boolean
  {
    return this._roleController.hasPermission(FlowPermissions.CompleteEvent);
    // && this.props.event.user_id == this._authState.user?.id;
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { candidate } = this._candidateState;
    const { event, historyView } = this.props;

    return (
      <a
        className={cx(styles.feedbackLink, { historyView, gray: (!event.is_done && !event.is_feedback_done) })}
        onClick={():void =>
        {
          if( candidate )
          {
            const btnBackUrl:string | null = this._commonController.getCandidateUrl(candidate.id) || null;

            runInAction(() =>
            {
              this._feedbackFormState.btnBackUrl = btnBackUrl;
            });

            this._feedbackFormController.initFeedbackFromEvent(event as Common_Scheduled_Event);

            const routePath = RoutesConfig.getRoutePath(RouteName.RECRUITING_CANDIDATE_FEEDBACK_EVENT_ID, {
              candidateId: String(candidate.id),
              eventId: String(event.id)
            });
            this._commonController.navigate(String(routePath));
          }
        }}
      >
        {
          historyView &&
          <>
            <Icon className={cx(styles.feedbackLinkIcon, styles.gray)} icon={IconNames.DOCUMENT} />
            Feedback form
          </>
        }
        {
          !historyView &&
          <>
            {
              event.is_done && event.is_feedback_done &&
              <Icon className={styles.feedbackLinkIcon} icon={IconNames.DOCUMENT} />
            }
            {
              event.is_done && !event.is_feedback_done &&
              <SvgIcon className={cx(styles.feedbackLinkIcon, { orange: true })} icon={SvgIconName.DocumentQuestion} />
            }
            {
              !event.is_done && !event.is_feedback_done &&
              <Icon className={cx(styles.feedbackLinkIcon, styles.gray)} icon={IconNames.DOCUMENT} />
            }
            {!event.is_done && !event.is_feedback_done && 'Edit feedback form'}
            {event.is_done && !event.is_feedback_done && this._hasPermissions && 'Complete feedback form'}
            {event.is_done && !event.is_feedback_done && !this._hasPermissions && 'Feedback form'}
            {event.is_done && event.is_feedback_done && 'Feedback form'}
          </>
        }
      </a>
    );
  }

  // ---------------------------------------------------------
}
