import { MenuItem } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { DialogSelectButton } from '@flow/common/components/page/DialogSelectButton';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import type { Recruiting_Candidate_Status_Reason } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { CandidateFlowController } from '@flow/modules/recruiting/candidates/CandidateFlowController';
import { CandidateFlowState } from '@flow/modules/recruiting/candidates/CandidateFlowState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './ArchiveReasonSelector.module.less';

const cx = classNames.bind(styles);

@component
export class ArchiveReasonSelector extends React.Component
{
  @di private _candidateFlowState!:CandidateFlowState;
  @di private _candidateFlowsController!:CandidateFlowController;
  @di private _roleController!:RoleController;

  // ---------------------------------------------------------

  @bind
  private _itemRenderer(reason:Recruiting_Candidate_Status_Reason,
                        { handleClick, modifiers }:IItemRendererProps):JSX.Element | null
  {
    if( !modifiers.matchesPredicate ) return null;

    return (
      <MenuItem
        key={reason.id}
        text={reason.name}
        onClick={handleClick}
        active={modifiers.active}
        // selected={flowStageItem.id === candidateFlowStageId}
      />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _onItemSelect(reason:Recruiting_Candidate_Status_Reason):void
  {
    this._candidateFlowsController.selectArchiveReasonId(reason.id);
  }

  // ---------------------------------------------------------

  public render():ReactNode | null
  {
    const {
      isCandidateOnFlow,
      selectedArchiveStatus, selectedArchiveReasonId, getReasonsByStatus, getReasonsById
    } = this._candidateFlowState;

    if( !isCandidateOnFlow ) return null;

    const isDisabled:boolean = !this._roleController.hasPermission(FlowPermissions.ChangeCandidateFlowStage);

    const reason:Recruiting_Candidate_Status_Reason | undefined = getReasonsById(selectedArchiveReasonId);

    const buttonText:string = reason ? reason.name : 'Choose a reason...';

    return (
      <Select
        className={cx(styles.select, { isDisabled: isDisabled || !selectedArchiveStatus })}
        items={getReasonsByStatus(selectedArchiveStatus) || []}
        itemRenderer={this._itemRenderer}
        onItemSelect={this._onItemSelect}
        filterable={false}
        disabled={isDisabled || !selectedArchiveStatus}
        popoverProps={{
          usePortal: false,
          minimal: true,
        }}
      >
        <DialogSelectButton
          text={buttonText}
          disabled={isDisabled || !selectedArchiveStatus}
        />
      </Select>
    );
  }

  // ---------------------------------------------------------
}
