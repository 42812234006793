import { Tooltip2 } from '@blueprintjs/popover2';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type React from 'react';
import { Component, ReactNode } from 'react';

import styles from './Avatar.module.less';

const cx = classNames.bind(styles);

export interface IPillProps
{
  className?:string;
  url?:string | null;
  size?:number;

  text?:string;
  textClassName?:string;
  tooltip?:string | JSX.Element;

  isCandidate?:boolean;
}

export class Avatar extends Component<IPillProps>
{
  // ---------------------------------------------------------

  @bind
  public _renderAvatar(insideTooltip:boolean):ReactNode
  {
    const { className, url, size = 32, text, textClassName, isCandidate } = this.props;

    const style:React.CSSProperties = {
      width: size,
      minWidth: size,
      maxWidth: size,
      height: size,
      minHeight: size,
      maxHeight: size
    };

    return (
      <div
        className={cx(styles.wrapper, !insideTooltip && className, { isCandidate })}
        style={style}
      >
        <div className={styles.circle}>
          {
            (url && !text) &&
            <img
              className={styles.img}
              src={url}
            />
          }
          {
            (!url && text) &&
            <span className={cx(styles.text, textClassName)}>
            {text}
          </span>
          }
          {
            (!url && !text) &&
            <Icon
              className={styles.icon}
              icon={IconNames.Person}

              // if avatar 36 -> icon 32
              size={size * 32 / 36}

              // if avatar 36 -> transform 3
              style={{ transform: `translateY(${size * 3 / 36}px)` }}
            />
          }
        </div>
      </div>
    );
  }

  // ---------------------------------------------------------

  public render():ReactNode
  {
    const { tooltip, className } = this.props;

    if( tooltip ) return (
      <Tooltip2
        className={className}
        placement={'top'}
        content={tooltip}
      >
        {this._renderAvatar(true)}
      </Tooltip2>
    );

    return this._renderAvatar(false);
  }

  // ---------------------------------------------------------
}
