import { FilterCheckboxesSettings } from '@flow/common/components/filters/checkboxes/FilterCheckboxes';
import type { IFilterCheckboxesValue } from '@flow/common/components/filters/checkboxes/FilterCheckboxes';
import type { IFilter, IFilterValue } from '@flow/common/components/filters/Filter';
import { Filter, FilterType } from '@flow/common/components/filters/Filter';
import type { IFilterMultiSelectValue } from '@flow/common/components/filters/multiSelect/FilterMultiSelect';
import { FilterMultiSelectSettings } from '@flow/common/components/filters/multiSelect/FilterMultiSelect';
import { state } from '@flow/dependency-injection';
import { observable } from 'mobx';
import { computedFn } from 'mobx-utils';

// ----------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InterviewFlowFiltersStateItems:Array<IFilter<any>> = observable.array([

  new Filter<IFilterCheckboxesValue | IFilterMultiSelectValue>(
    {
      // ...FilterMultiSelectSettings,
      ...FilterCheckboxesSettings,
      type: FilterType.FLOWS_CUSTOMER,
      isSelected: true,
      name: '', // -> remove title in popover
      unselectedTitle: 'All Customers',
      selectedTitle: 'Customer'
    }),

  new Filter<IFilterCheckboxesValue | IFilterMultiSelectValue>(
    {
      // ...FilterMultiSelectSettings,
      ...FilterCheckboxesSettings,
      type: FilterType.FLOWS_POSITION_GROUP,
      isSelected: true,
      name: '', // -> remove title in popover
      unselectedTitle: 'All Position Groups',
      selectedTitle: 'Position Group'
    }),

  new Filter<IFilterCheckboxesValue | IFilterMultiSelectValue>(
    {
      // ...FilterMultiSelectSettings,
      ...FilterCheckboxesSettings,
      type: FilterType.FLOWS_RECRUITER,
      isSelected: true,
      name: '', // -> remove title in popover
      unselectedTitle: 'All Recruiters',
      selectedTitle: 'Recruiter'
    })

]);

// export const InterviewFlowFiltersDefaultState:Array<IFilter<IFilterValue>> = InterviewFlowFiltersStateItems.map(f => toJS(f));

// ----------------------------------------------------

@state
export class InterviewFlowsFiltersState
{
  public filters:Array<IFilter<IFilterValue>> = InterviewFlowFiltersStateItems;
  @observable public isApplyingFromLocalStorage:boolean = false;

  // ---------------------------

  public filterByType:(filterType:FilterType) => IFilter<IFilterValue> =
    computedFn((filterType:FilterType):IFilter<IFilterValue> =>
    {
      let filter:IFilter<IFilterValue> | null = null;

      this.filters.forEach((f:IFilter<IFilterValue>) =>
      {
        if( f.type === filterType ) filter = f;
      });

      if( !filter )
      {
        console.log('%c !!! filterByType ERROR: =', 'background:#f00;color:#ff0;', `filter '${filterType}' not found`);
        throw new Error(`!!! filterByType ERROR: filter '${filterType}' not found`);
      }

      return filter;
    });

  // ---------------------------
}

// ----------------------------------------------------
