import { component, di } from '@flow/dependency-injection';
import { InterviewFlowsController } from '@flow/modules/recruiting/status/InterviewFlowsController';
import debounce from 'lodash/debounce';
import { action, runInAction } from 'mobx';
import type { ReactNode } from 'react';
import React from 'react';
import type { DragStart, DragUpdate, DropResult } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import { InterviewFlowsState } from '../InterviewFlowsState';
import type { IInterviewFlowGroup } from '../models/InterviewFlowGroup';
import { InterviewFlowColumnsHeader } from './InterviewFlowColumnsHeader';
import styles from './InterviewFlowContent.module.less';
import { InterviewFlowGroup } from './InterviewFlowGroup';

@component
export class InterviewFlowContent extends React.Component
{
  @di private _interviewFlowsState!:InterviewFlowsState;
  @di private _interviewFlowsController!:InterviewFlowsController;

  private _scrollableRef:HTMLDivElement | null = null;
  private _scrollableYref:HTMLDivElement | null = null;

  private _resizeObserver:ResizeObserver | null = null;

  // ----------------------------------------------------

  @action.bound
  private _onDragStart(result:DragStart/*, provided:ResponderProvided */):void
  {
    const ids:Array<string> = result.draggableId.split('-');

    this._interviewFlowsState.draggingInterviewFlowGroupId = ids[0];
    this._interviewFlowsState.draggingCandidateId = parseFloat(ids[1]);
  }

  // ----------------------------------------------------

  @action.bound
  private _onDragUpdate(result:DragUpdate/* , provided:ResponderProvided */):void
  {
    console.log('%c _onDragUpdate result =', 'background:#0f0;color:#000;', result);
    console.log('%c --> result.destination =', 'background:#080;color:#000;', result.destination);
    if( result.destination )
    {
      const ids:Array<string> = result.destination.droppableId.split('-');

      console.log('%c --> ids =', 'background:#080;color:#000;', ids);
      console.log('%c --> draggingInterviewFlowGroupId =', 'background:#080;color:#000;', this._interviewFlowsState.draggingInterviewFlowGroupId);
      if( ids[0] === this._interviewFlowsState.draggingInterviewFlowGroupId )
      {
        this._interviewFlowsState.dropInterviewFlowStageId = parseFloat(ids[1]);
        console.log('%c --> dropInterviewFlowStageId =', 'background:#080;color:#000;', ids[1]);
        return;
      }
    }

    this._interviewFlowsState.dropInterviewFlowStageId = -1;
  }

  // ----------------------------------------------------

  @action.bound
  private _onDragEnd(result:DropResult/*, provided:ResponderProvided */):void
  {
    if( result.destination )
    {
      // const ids:Array<string> = result.draggableId.split('-');
      const destIds:Array<string> = result.destination.droppableId.split('-');

      this._interviewFlowsState.dropInterviewFlowStageId = parseFloat(destIds[1]);
      this._interviewFlowsState.dropInterviewFlowIndex = result.destination.index;

      this._interviewFlowsController.onDropCandidate();
    }
    else
    {
      this._interviewFlowsController.resetDrag();
    }
  }

  // ----------------------------------------------------

  @action.bound
  private _onScroll():void
  {
    this._interviewFlowsState.scrollLeftX = this._scrollableRef?.scrollLeft || 0;
  }

  private _debouncedOnScroll = debounce(this._onScroll, 100).bind(this);

  @action.bound
  private _setWidth():void
  {
    if( !this._scrollableYref )
      return;

    this._interviewFlowsState.scrollBarWidth = this._scrollableYref.offsetWidth - this._scrollableYref.clientWidth;
  }

  // ----------------------------------------------------

  public componentDidUpdate():void
  {
    if( !this._scrollableYref || this._resizeObserver != null )
      return;

    this._resizeObserver = new ResizeObserver(() =>
    {
      runInAction(this._setWidth);
    });

    this._resizeObserver.observe(this._scrollableYref as Element);
  }

  // ----------------------------------------------------

  public render():ReactNode
  {
    const { selectedInterviewFlow, interviewFlowGroups, scrollBarWidth } = this._interviewFlowsState;

    if( !selectedInterviewFlow )
      return null;

    const minWidth:number | undefined = interviewFlowGroups.length > 0
      ? 180 * interviewFlowGroups[0].stages.length + 10 * (interviewFlowGroups[0].stages.length + 1)
      : undefined;

    return (
      <div
        className={styles.scrollableX}
        onScroll={this._debouncedOnScroll}
        ref={(ref):void =>
        {
          this._scrollableRef = ref;
        }}
      >
        <div className={styles.wrapper} style={{ minWidth }}>
          <InterviewFlowColumnsHeader scrollBarWidth={scrollBarWidth} />
          <div
            className={styles.scrollableY}
            ref={(ref):void =>
            {
              this._scrollableYref = ref;
            }}
          >
            <DragDropContext
              onDragStart={this._onDragStart}
              onDragUpdate={this._onDragUpdate}
              onDragEnd={this._onDragEnd}
            >
              <div className={styles.groupsWrapper}>
                {interviewFlowGroups.map((ifg:IInterviewFlowGroup) => (
                  <React.Fragment key={ifg.id}>
                    <InterviewFlowGroup interviewFlowGroup={ifg} />
                  </React.Fragment>
                ))}
              </div>
            </DragDropContext>
          </div>
        </div>
      </div>
    );
  }

  // ----------------------------------------------------
}
