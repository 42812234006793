import { Tooltip2 } from '@blueprintjs/popover2';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { Intent } from '@flow/common/components/types/Types';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { component, di } from '@flow/dependency-injection';
import { CandidateController } from '@flow/modules/recruiting/candidates/CandidateController';
import { ScheduleState } from '@flow/modules/recruiting/schedule/ScheduleState';
import classNames from 'classnames/bind';
import { runInAction } from 'mobx';
import React from 'react';
import { CandidateState } from '../../../CandidateState';

import styles from './CommunicationToolbar.module.less';

const cx = classNames.bind(styles);

interface Props
{
  scrollTop:() => void;
}

@component
export class CommunicationToolbar extends React.Component<Props>
{
  @di private readonly _candidateState!:CandidateState;
  @di private readonly _scheduleState!:ScheduleState;
  @di private readonly _candidateController!:CandidateController;

  @di private readonly _roleController!:RoleController;

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { isCandidateOnFlow, isDraftEventOpen, candidate } = this._candidateState;

    const btnCallDisabled:boolean = !isCandidateOnFlow ||
      !candidate?.email ||
      !this._roleController.hasPermission(FlowPermissions.CreateScheduledEvent);

    const tooltipText:string = !isCandidateOnFlow ? 'Start pipeline to schedule a call'
      : !candidate?.email ? 'Enter email to schedule a call' : '';

    return (
      <div
        className={styles.toolbarWrapper}
      >
        <div
          className={styles.toolbar}
        >
          <Tooltip2
            content={tooltipText}
            placement={'top'}
            disabled={!tooltipText}
          >
            <Button
              className={cx(styles.btn, { pressed: isDraftEventOpen, btnCallDisabled })}
              iconClassName={styles.btnIcon}
              text="Schedule call"
              intent={Intent.NONE}
              icon={IconNames.CALENDAR}
              minimal={true}
              outlined={false}
              disabled={btnCallDisabled}
              onClick={():void =>
              {
                this.props.scrollTop();
                this._candidateController.updateDraftScheduleCall(true);
              }}
            />
          </Tooltip2>

          {/* <div className={styles.spacer} /> */}

          <Button
            className={styles.btn}
            iconClassName={styles.btnIcon}
            text="Note"
            intent={Intent.NONE}
            icon={IconNames.MANUALLY_ENTERED_DATA}
            minimal={true}
            outlined={false}
            disabled={!this._candidateState.candidate}
            onClick={():void =>
            {
              runInAction(() => this._candidateState.isAddingNote = !this._candidateState.isAddingNote);
            }}
          />

          {/* Out of MVP scope */}
          {/*<Button*/}
          {/*  className={styles.btn}*/}
          {/*  iconClassName={styles.btnIcon}*/}
          {/*  text="Salary"*/}
          {/*  intent={Intent.NONE}*/}
          {/*  icon={IconNames.DOLLAR}*/}
          {/*  minimal={true}*/}
          {/*  outlined={false}*/}
          {/*/>*/}

        </div>
      </div>
    );
  }

  // ---------------------------------------------------------
}
