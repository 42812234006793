import { controller, di } from '@flow/dependency-injection';
import { CommonController } from '../CommonController';
import { AuthState } from './AuthState';
import type { IHasuraTablePermissions } from './RoleState';
import { RoleState } from './RoleState';
import type {
  HasuraActionPermissionsQuery,
  HasuraActionPermissionsQueryVariables,
  HasuraInheritedRolesQuery,
  HasuraInheritedRolesQueryVariables,
  HasuraPermissionsQuery,
  HasuraPermissionsQueryVariables
} from '@flow/data-access/lib/permissions.generated';
import {
  HasuraInheritedRolesDocument
} from '@flow/data-access/lib/permissions.generated';
import {
  HasuraActionPermissionsDocument
} from '@flow/data-access/lib/permissions.generated';
import { HasuraPermissionsDocument } from '@flow/data-access/lib/permissions.generated';
import { runInAction } from 'mobx';
import { CommonState } from '../CommonState';
import type { IFlowPermission } from '../models/FlowPermissions';
import { HasuraOperation } from '../models/FlowPermissions';

@controller
export class RoleController
{
  @di private _commonController!:CommonController;
  @di private _commonState!:CommonState;
  @di private _authState!:AuthState;
  @di private _roleState!:RoleState;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _roleFilterForRoleName(role:string):any
  {
    return [
      { select_permissions: { _contains: [{ role }] } },
      { update_permissions: { _contains: [{ role }] } },
      { insert_permissions: { _contains: [{ role }] } },
      { delete_permissions: { _contains: [{ role }] } }
    ]
  }

  public async initPermissions():Promise<void>
  {
    runInAction(() =>
    {
      this._commonState.loadersCount++;
    });

    const role:string = this._authState.user?.activeRole || 'anonymous';

    const roleFilter = this._roleFilterForRoleName(role);

    const inheritedRolesResponse = await this._commonController.query<HasuraInheritedRolesQuery, HasuraInheritedRolesQueryVariables>({
      query: HasuraInheritedRolesDocument,
      variables: {
        role
      },
    });

    const inheritedRoles:Array<string> = [];

    if( inheritedRolesResponse.data && inheritedRolesResponse.data.hasura_inherited_roles_view.length > 0 )
    {
      inheritedRolesResponse.data.hasura_inherited_roles_view[0].role_set.forEach((inheritedRole:string) =>
      {
        inheritedRoles.push(inheritedRole);
        roleFilter.push(...this._roleFilterForRoleName(inheritedRole));
      });
    }

    const permissionsResponse = await this._commonController.query<HasuraPermissionsQuery, HasuraPermissionsQueryVariables>({
      query: HasuraPermissionsDocument,
      variables: {
        roleFilter
      },
    });

    if( permissionsResponse.errors )
    {
      throw new Error(permissionsResponse.errors[0].message);
    }

    if( permissionsResponse.data )
    {
      runInAction(() => this._roleState.permissions = permissionsResponse.data.hasura_permissions_view as Array<IHasuraTablePermissions>);

      for( const tablePermissions of this._roleState.permissions )
      {
        const fullyQualifiedTableName = `${tablePermissions.schema_name}.${tablePermissions.table_name}`;

        runInAction(() =>
        {
          const selectColumns:Array<string> = [];
          const updateColumns:Array<string> = [];
          const insertColumns:Array<string> = [];
          // const deleteColumns:Array<string> = [];

          const { select_permissions, update_permissions, insert_permissions, delete_permissions } = tablePermissions;

          if( select_permissions )
            select_permissions
              .filter(permission => (permission.role === role || inheritedRoles.includes(permission.role)) && Array.isArray(permission.permission.columns))
              .forEach(permission =>
              {
                permission.permission.columns?.forEach(column =>
                {
                  if( !selectColumns.includes(column) )
                    selectColumns.push(column);
                });
              });

          this._roleState.selectPermissions.set(fullyQualifiedTableName, { columns: selectColumns });

          if( update_permissions )
            update_permissions
              .filter(permission => (permission.role === role || inheritedRoles.includes(permission.role)) && Array.isArray(permission.permission.columns))
              .forEach(permission =>
              {
                permission.permission.columns?.forEach(column =>
                {
                  if( !updateColumns.includes(column) )
                    updateColumns.push(column);
                });
              });

          this._roleState.updatePermissions.set(fullyQualifiedTableName, { columns: updateColumns });

          if( insert_permissions )
            insert_permissions
              .filter(permission => (permission.role === role || inheritedRoles.includes(permission.role)) && Array.isArray(permission.permission.columns))
              .forEach(permission =>
              {
                permission.permission.columns?.forEach(column =>
                {
                  if( !insertColumns.includes(column) )
                    insertColumns.push(column);
                });
              });

          this._roleState.insertPermissions.set(fullyQualifiedTableName, { columns: insertColumns });

          if( delete_permissions && delete_permissions
            .some(permission => (permission.role === role || inheritedRoles.includes(permission.role))) )
          {
            this._roleState.deletePermissions.set(fullyQualifiedTableName, { allowed: true });
          }

        });

        // TODO: soft-delete permissions = update allowed on 'deleted_at' column
      }
    }

    // TODO: what if no errors and no data?

    runInAction(() =>
    {
      this._roleState.isLoaded = true;
      this._commonState.loadersCount--;
    });

    const actionPermissionsResponse = await this._commonController.query<HasuraActionPermissionsQuery, HasuraActionPermissionsQueryVariables>({
      query: HasuraActionPermissionsDocument,
      variables: {
        roleFilter: [
          {
            role: this._authState.user?.activeRole
          }
        ]
      },
    });

    if( actionPermissionsResponse.errors )
    {
      throw new Error(actionPermissionsResponse.errors[0].message);
    }

    if( permissionsResponse.data )
    {
      runInAction(() =>
      {
        actionPermissionsResponse.data?.hasura_action_permissions_view.forEach(action =>
        {
          if( action && action.action_name )
            this._roleState.allowedActionNames.push(action.action_name);
        });
      });
    }
  }

  public hasPermission(permission:IFlowPermission):boolean
  {
    if( permission.kind === 'action_permission' )
      return this._roleState.allowedActionNames.includes(permission.action);

    const { operation, table, column } = permission;

    if( this._authState.user?.activeRole === 'admin' )
      return true;

    if( operation == HasuraOperation.Delete && this._roleState.deletePermissions.get(table)?.allowed )
      return true;

    let permissionsMap;

    switch( operation )
    {
      case HasuraOperation.Select:
        permissionsMap = this._roleState.selectPermissions;
        break;
      case HasuraOperation.Update:
        permissionsMap = this._roleState.updatePermissions;
        break;
      case HasuraOperation.Insert:
        permissionsMap = this._roleState.insertPermissions;
        break;
    }

    const columns:Array<string> | undefined = permissionsMap?.get(table)?.columns;

    if( !columns || columns.length == 0 )   // no table or no permissions at all
      return false;

    if( !column )   // any permission is ok
      return permissionsMap?.get(table) != null;

    if( Array.isArray(column) )
    {
      const requestedColumns:Array<string> = column as Array<string>;

      if( !columns )
        return false;

      // can select all requested columns?
      return columns.every(col => requestedColumns.includes(col));
    }

    return columns.includes(column);   // can select requested column?
  }

  public hasAnyPermission(permissions:Array<IFlowPermission>):boolean
  {
    return permissions.some(permission => this.hasPermission(permission));
  }
}
