import type { FetchResult } from '@apollo/client';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import { OrderByType } from '@flow/common/components/elements/ButtonOrderBy';
import type { IFilterValue } from '@flow/common/components/filters/Filter';
import { FilterType, IFilter } from '@flow/common/components/filters/Filter';
import type { IFilterSiteSubsiteValue } from '@flow/common/components/filters/siteSubsite/FilterSiteSubsite';
import { AuthState } from '@flow/common/controllers/AuthState';
import { LocalStorageUtil } from '@flow/common/utils/LocalStorageUtil';
import type { AnyObject } from '@flow/common/utils/WindowLocationUtil';
import { WindowLocationUtil } from '@flow/common/utils/WindowLocationUtil';
import type {
  NewFilterMutation,
  NewFilterMutationVariables,
  UpdateFilterMutation,
  UpdateFilterMutationVariables
} from '@flow/data-access/lib/candidates.generated';
import { NewFilterDocument, UpdateFilterDocument } from '@flow/data-access/lib/candidates.generated';
import type { Common_Filter } from '@flow/data-access/lib/types/graphql.generated';
import { Common_Filter_Type_Enum, Staffing_Staff_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import { SaveFilterMode } from '@flow/modules/recruiting/candidates/CandidatesState';
import type { IStaffColumn } from '@flow/modules/staffing/staff/StaffColumnsState';
import {
  QUERY_STRING_COLUMNS_NAME,
  StaffColumnsDefaultState,
  StaffColumnType
} from '@flow/modules/staffing/staff/StaffColumnsState';
import { StaffFiltersDefaultState, StaffFiltersState } from '@flow/modules/staffing/staff/StaffFiltersState';
import { StaffStatusTitle } from '@flow/modules/staffing/staff/StaffMemberState';
import { StaffSiteSubsiteState } from '@flow/modules/staffing/staff/StaffSiteSubsiteState';
import { StaffListUrl_LS_KEY, StaffState } from '@flow/modules/staffing/staff/StaffState';
import bind from 'bind-decorator';
import { action, toJS } from 'mobx';

@controller
export class StaffFiltersController
{
  @di private _authState!:AuthState;
  @di private _commonState!:CommonState;
  @di private _staffState!:StaffState;
  @di private _staffFiltersState!:StaffFiltersState;
  @di private _staffSiteSubsiteState!:StaffSiteSubsiteState;

  @di private _commonController!:CommonController;

  // ----------------------------------------------------

  public setQueryString():void
  {
    const queryObject:AnyObject = {};

    // filters
    if( this._staffState.someFilterIsSelected )
    {
      this._staffFiltersState.filters.forEach((filter:IFilter<IFilterValue>) =>
      {
        if( !filter.isSelected ) return;

        if( typeof filter.value === 'object' && !Array.isArray(filter.value) )
        {
          Object.keys(filter.value).forEach((valueKey:string) =>
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const value:any = (filter.value as any)[valueKey];

            queryObject[`${filter.type}_${valueKey}`] = value;
          });
        }
        else
        {
          queryObject[filter.type] = WindowLocationUtil.makeQueryStringFromObject(filter.value);
        }
      });
    }

    // columns
    if( this.columnsIsChanged() )
    {
      const columns:Array<StaffColumnType> = [];

      this._staffState.columns.forEach((column:IStaffColumn) =>
      {
        if( column.isVisible || column.alwaysVisible )
        {
          columns.push(column.type);
        }
      });

      queryObject['columns'] = columns;

      // orderBy
      if( this._staffState.orderedBy )
      {
        const orderDirection:string = this._staffState.orderedBy.orderBy === OrderByType.DESC ? '-' : '';
        queryObject['orderBy'] = `${orderDirection}${this._staffState.orderedBy.type}`;
      }
    }

    const str:string = WindowLocationUtil.makeQueryStringFromObject(queryObject);
    console.log('%c setQueryString =', 'background:#f0f;color:#000;', str);
    console.log('%c ---     decode =', 'background:#f0f;color:#000;', WindowLocationUtil.getObjectFromQueryString(str));

    WindowLocationUtil.setQueryString(queryObject);

    const { isApplyingFromLocalStorage } = this._staffState;

    if( !isApplyingFromLocalStorage )
    {
      LocalStorageUtil.setItem(StaffListUrl_LS_KEY, queryObject);
    }
  }

  // ----------------------------------------------------

  @action.bound
  public setUserFilter(userFilterId:number | null):void
  {
    this.unselectAllFilters();

    if( !userFilterId ) return;

    const { userFilterById } = this._staffState;
    const userFilter:Common_Filter | undefined = userFilterById(userFilterId);

    if( !userFilter?.content?.length ) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userFilters:any = JSON.parse(String(userFilter.content));

    this._applyUserFilter(userFilters);

    this._staffState.isFiltersDirty = false;
  }

  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _applyUserFilter(userFilters:any):void
  {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Object.keys(userFilters).forEach((filterType:string) =>
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filter:IFilter<any> | undefined = this._staffState.filterByType(filterType as FilterType);

      if( !filter ) return;

      console.log('%c userFilters[filterType] =', 'background:#0f0;color:#000;', userFilters[filterType]);

      this.selectFilter(filter.type, true);
      this.changeFilterValue(filter.type, userFilters[filterType]);
    });
  }

  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public applyQueryStringFilters(userFilters:any):boolean
  {
    let someDataFromQueryStringIsApplied:boolean = false;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Object.keys(userFilters).forEach((_filterType:string) =>
    {
      // STATUS_status[]=1,2,3
      if( _filterType.indexOf('_') === -1 ) return;

      // POSITION_GROUP_items -> filterType = POSITION_GROUP
      const filterString:Array<string> = _filterType.split('_');

      // POSITION_GROUP_items -> filterValueKey = items
      const filterValueKey:string | undefined = filterString.pop();

      if( !filterValueKey ) return;

      const filterType:string = filterString.join('_');

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filter:IFilter<any> | undefined = this._staffState.filterByType(filterType as FilterType);

      if( !filter ) return;

      someDataFromQueryStringIsApplied = true;

      console.log('%c userFilters[filterType] =', 'background:#0f0;color:#000;', userFilters[_filterType]);
      console.log('%c --- _filterType =', 'background:#080;color:#000;', _filterType);
      console.log('%c --- filter =', 'background:#080;color:#000;', filter);

      this.selectFilter(filter.type, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value:any = userFilters[_filterType];

      // this.changeFilterValue(filter.type, WindowLocationUtil.getObjectFromQueryString(userFilters[filterType]));
      this.changeFilterValue(filter.type,
        {
          ...filter.value,
          // [filterValueKey]: WindowLocationUtil.getObjectFromQueryString(userFilters[_filterType])
          [filterValueKey]: value
        });
    });

    return someDataFromQueryStringIsApplied;
  }

  // ----------------------------------------------------

  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public applyQueryStringColumns(queryObject:any):boolean
  {
    let someDataFromQueryStringIsApplied:boolean = false;

    console.log('%c ---_applyQueryStringColumns =', 'background:#f0f;color:#000;', queryObject[QUERY_STRING_COLUMNS_NAME]);
    const columns:Array<number> = queryObject[QUERY_STRING_COLUMNS_NAME];
    console.log('%c columns =', 'background:#0f0;color:#000;', columns);

    if( !columns || !columns.length ) return false;

    someDataFromQueryStringIsApplied = true;

    // const columns:Array<number> = WindowLocationUtil.getObjectFromQueryString(queryObject[QUERY_STRING_COLUMNS_NAME]);

    this._staffState.columns.forEach((column:IStaffColumn) =>
    {
      if( column.alwaysVisible ) return;

      this.selectColumn(column.type, columns.includes(column.type));
    });

    if( queryObject.orderBy )
    {
      const orderColumn:number = queryObject.orderBy;
      const columnType:number = orderColumn < 0 ? orderColumn * -1 : orderColumn;

      if( columns.includes(columnType) )
      {
        this.clearAllOrders();

        const orderBy:OrderByType = orderColumn < 0 ? OrderByType.DESC : OrderByType.ASC;
        this.orderBy(columnType, orderBy);
      }
    }

    return someDataFromQueryStringIsApplied;
  }

  // ----------------------------------------------------

  @action.bound
  public selectFilter(filterType:FilterType, isSelected:boolean):void
  {
    console.log('%c selectFilter =', 'background:#0f0;color:#000;', filterType, isSelected);

    const filter:IFilter<IFilterValue> = this._staffState.filterByType(filterType);

    filter.isSelected = isSelected;

    if( !isSelected )
    {
      this.changeFilterValue(filterType, this.defaultFilterByType(filterType).value);

      if( !this._staffState.someFilterIsSelected )
      {
        this._staffState.selectedFilterId = null;
      }
    }
  }

  @action.bound
  public removeFilter(filterType:FilterType):void
  {
    console.log('%c removeFilter =', 'background:#0f0;color:#000;', filterType);
    this.selectFilter(filterType, false);
  }

  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public changeFilterValue(filterType:FilterType, newValue:any):void
  {
    console.log('%c changeFilter =', 'background:#0f0;color:#000;', filterType, newValue);
    this._staffState.filterByType(filterType).value = newValue;
  }

  @action.bound
  public unselectAllFilters():void
  {
    this._staffFiltersState.filters.forEach((filter:IFilter<IFilterValue>) =>
    {
      this.selectFilter(filter.type, false);
    });

    this._staffState.selectedFilterId = null;

    // Object.keys(this._staffFiltersState.filters).forEach((filterType:string) =>
    // {
    //   this.selectFilter(filterType as FilterType, false);
    // });
  }

  @bind
  public defaultFilterByType(filterType:FilterType):IFilter<IFilterValue>
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let filter:any = null;

    console.log('%c StaffFiltersDefaultState =', 'background:#0f0;color:#000;', StaffFiltersDefaultState);

    StaffFiltersDefaultState.forEach((f:IFilter<IFilterValue>) =>
    {
      if( f.type === filterType ) filter = f;
    });

    if( !filter )
    {
      throw new Error(`!!! filterByType ERROR: filter '${filterType}' not found`);
    }

    return filter as IFilter<IFilterValue>;
  };

  // ----------------------------------------------------

  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getFilterData(filterType:FilterType):any
  {
    console.log('%c getFilterData =', 'background:#0f0;color:#000;', filterType);

    const { siteSubsiteValuesForSelect } = this._staffSiteSubsiteState;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let filterData:IFilterSiteSubsiteValue | any = null;

    switch( filterType )
    {
      //     case FilterType.POSITION_GROUP:
      //       filterData = {
      //         items: this._staffState.positionGroups.map((pg:Recruiting_Position_Group) =>
      //         {
      //           const ret:IFilterMultiSelectDataItem = { name: pg.name, value: pg.id };
      //           return ret;
      //         })
      //       };
      //       break;
      //
      //     case FilterType.STATUS:
      //       filterData = { interviewFlowStages: this._staffState.interviewFlowStages };
      //       break;

      case FilterType.SITE_SUBSITE:
        filterData = {
          items: siteSubsiteValuesForSelect,
          siteById: this._staffSiteSubsiteState.countryById,
          subsiteById: this._staffSiteSubsiteState.cityById
        };
        break;

      //     case FilterType.ASSIGNED_TO:
      //     case FilterType.CREATED_BY:
      //       filterData = {
      //         items: this._staffState.users.map((user:Common_User) =>
      //         {
      //           const ret:IFilterMultiSelectDataItem = {
      //             name: this._staffState.userNameByUserId(user.id),
      //             value: user.id
      //           };
      //           return ret;
      //         })
      //       };
      //       break;

      case FilterType.STATUS:
        filterData = {
          statuses: [
            {
              value: Staffing_Staff_Status_Enum.Newcomer,
              title: StaffStatusTitle[Staffing_Staff_Status_Enum.Newcomer]
            },
            {
              value: Staffing_Staff_Status_Enum.Staffed,
              title: StaffStatusTitle[Staffing_Staff_Status_Enum.Staffed]
            },
            {
              value: Staffing_Staff_Status_Enum.Leaving,
              title: StaffStatusTitle[Staffing_Staff_Status_Enum.Leaving]
            }
            // Staffing_Staff_Status_Enum.Archived,
          ]
        };
        break;
    }
    //
    console.log('%c ---           =', 'background:#080;color:#000;', toJS(filterData));
    return filterData;
  }

  // ----------------------------------------------------

  @action.bound
  public selectColumn(columnType:StaffColumnType, isVisible:boolean):void
  {
    console.log('%c selectColumn =', 'background:#0f0;color:#000;', columnType, isVisible);
    this._staffState.columnByType(columnType).isVisible = isVisible;

    if( !isVisible && columnType === this._staffState.orderedBy?.type )
    {
      this.orderBy(columnType, null);
    }
    this.checkOrdersAndSetDefault();
    this.setQueryString();
  }

  @action.bound
  public unselectAllColumns():void
  {
    this._staffState.columns.forEach((column:IStaffColumn) =>
    {
      if( column.alwaysVisible ) return;

      this.selectColumn(column.type, false);
    });
  }

  // ----------------------------------------------------

  @action.bound
  public orderByNextState(columnType:StaffColumnType):void
  {
    const currentOrder = this._staffState.columnByType(columnType).orderBy;

    const nextOrderBy:OrderByType | null = !currentOrder
      ? OrderByType.ASC
      : currentOrder === OrderByType.ASC ? OrderByType.DESC : OrderByType.ASC;

    this.clearAllOrders();
    this.orderBy(columnType, nextOrderBy);
  }

  @action.bound
  public orderBy(columnType:StaffColumnType, orderBy:OrderByType | null):void
  {
    this._staffState.columnByType(columnType).orderBy = orderBy;
    this.setQueryString();
  }

  @action.bound
  public clearAllOrders():void
  {
    this._staffState.columns.forEach((column:IStaffColumn) =>
    {
      this.orderBy(column.type, null);
    });
  }

  @action.bound
  public checkOrdersAndSetDefault():void
  {
    const isSomeOrdered:boolean = this._staffState.columns.some((column:IStaffColumn) =>
    {
      return column.isVisible && column.orderBy !== null;
    });

    if( isSomeOrdered ) return;

    const defaultOrderedColumn:number = StaffColumnsDefaultState.findIndex((defaultColumn:IStaffColumn) =>
    {
      return defaultColumn.orderBy !== null;
    }) + 1;

    if( defaultOrderedColumn )
    {
      this.orderBy(defaultOrderedColumn, OrderByType.ASC);
    }
  }

  // ----------------------------------------------------

  @action.bound
  public showSaveFilterDialog():void
  {
    this._staffState.isSaveFilterDialogOpen = true;
    this._staffState.isSaveFilterLoading = false;

    this._staffState.saveFilterMode = SaveFilterMode.NEW;
    this._staffState.saveFilterName = '';
    this._staffState.overwriteFilterId = this._staffState.selectedFilterId;
  }

  @action.bound
  public hideSaveFilterDialog():void
  {
    this._staffState.isSaveFilterDialogOpen = false;
    this._staffState.isSaveFilterLoading = false;
  }

  @action.bound
  public setSaveFilterName(value:string):void
  {
    this._staffState.saveFilterName = value;
  }

  @action.bound
  public setSaveFilterMode(saveFilterMode:SaveFilterMode):void
  {
    this._staffState.saveFilterMode = saveFilterMode;
  }

  @action.bound
  public selectUserFilter(filterId:number | null):void
  {
    this.setUserFilter(filterId);

    this._staffState.selectedFilterId = filterId;
  }

  @action.bound
  public selectOverwriteFilter(filterId:number | null):void
  {
    this._staffState.overwriteFilterId = filterId;
  }

  @action.bound
  public async saveFilter():Promise<number | null>
  {
    const { user } = this._authState;

    if( !user || !user.id )
    {
      console.log('%c user =', 'background:#f00;color:#ff0;', user);
      return null;
    }

    // --------------------------------------------------------------
    if( this._staffState.saveFilterMode === SaveFilterMode.NEW )
    {
      const filterName:string = this._staffState.saveFilterName.trim();
      console.log('%c ---       filterName =', 'background:#080;color:#000;', filterName);

      if( !filterName )
      {
        !filterName && console.log('%c ERROR filterName =', 'background:#f00;color:#ff0;', filterName);
        return null;
      }

      const content:string = this.getFiltersJSONString();
      console.log('%c getFiltersJSONString 1 =', 'background:#ff0;color:#000;', content);

      const result:FetchResult<NewFilterMutation> =
        await this._commonController.mutate<NewFilterMutation,
          NewFilterMutationVariables>({
          mutation: NewFilterDocument,
          variables: {
            name: filterName,
            type: Common_Filter_Type_Enum.Staffing, // TODO staffing !!!
            user_id: user.id,
            content
          }
        });

      console.log('%c NewFilterMutation result =', 'background:#f60;color:#000;', result);

      let newFilterId:number | null = null;

      if( result && result.data )
      {
        const newFilter:Common_Filter = result.data.insert_common_filter_one as Common_Filter;

        this._staffState.userFilters.push(newFilter);

        newFilterId = newFilter.id;

        if( newFilterId )
        {
          this._staffState.selectedFilterId = newFilterId;
          this._staffState.isFiltersDirty = false;
        }
      }

      if( !newFilterId )
      {
        // TODO: show ERRORS
      }
      this.hideSaveFilterDialog();

      return newFilterId;
    }
    // --------------------------------------------------------------
    else // SaveFilterMode.OVERWRITE
    {
      const { overwriteFilterId } = this._staffState;

      if( !overwriteFilterId )
      {
        // TODO error
        return null;
      }

      const content:string = this.getFiltersJSONString();
      console.log('%c getFiltersJSONString 2 =', 'background:#ff0;color:#000;', content);

      const result:FetchResult<UpdateFilterMutation> =
        await this._commonController.mutate<UpdateFilterMutation,
          UpdateFilterMutationVariables>({
          mutation: UpdateFilterDocument,
          variables: {
            id: overwriteFilterId,
            content
          }
        });

      console.log('%c UpdateFilterMutation result =', 'background:#f60;color:#000;', result);

      let newFilterId:number | null = null;

      if( result && result.data )
      {
        const newFilter:Common_Filter = result.data.update_common_filter_by_pk as Common_Filter;

        newFilterId = newFilter.id;

        const updatedFilter:Common_Filter | undefined = this._staffState.userFilterById(newFilterId);

        if( newFilterId && updatedFilter )
        {
          updatedFilter.content = newFilter.content;

          this._staffState.selectedFilterId = newFilterId;
          this._staffState.isFiltersDirty = false;
        }
      }

      if( !newFilterId )
      {
        // TODO: show ERRORS
      }
      this.hideSaveFilterDialog();

      return newFilterId;
    }
  }

  @action.bound
  public async onFilterSaved():Promise<void>
  {
    return new Promise(resolve => resolve());
  }

  public getFiltersJSONString():string
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const returnedValue:any = {};

    this._staffFiltersState.filters.forEach((filter:IFilter<IFilterValue>) =>
    {
      if( !filter.isSelected ) return;

      returnedValue[filter.type] = filter.value;
    });

    return JSON.stringify(returnedValue);
  }

  // ----------------------------------------------------

  public columnsIsChanged():boolean
  {
    return StaffColumnsDefaultState.some((defaultColumn:IStaffColumn) =>
    {
      const column:IStaffColumn = this._staffState.columnByType(defaultColumn.type);

      return column.isVisible !== defaultColumn.isVisible || column.orderBy !== defaultColumn.orderBy;
    });
  }

  // ----------------------------------------------------
}
