import type { IFilter, IFilterValue } from '@flow/common/components/filters/Filter';
import { Toolbar } from '@flow/common/components/page/Toolbar';
import { component, di } from '@flow/dependency-injection';
import { InterviewFlowsFiltersController } from '@flow/modules/recruiting/status/InterviewFlowsFiltersController';
import { InterviewFlowsFiltersState } from '@flow/modules/recruiting/status/InterviewFlowsFiltersState';
import bind from 'bind-decorator';
import classNames from 'classnames';
// import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';
import { InterviewFlowsController } from '../../InterviewFlowsController';
import { InterviewFlowsState } from '../../InterviewFlowsState';
import styles from './InterviewFlowHeader.module.less';
import { SelectCustomer } from './SelectCustomer';
import { SelectFlow } from './SelectFlow';
import { SelectPositionGroup } from './SelectPositionGroup';
import { SelectUser } from './SelectUser';

const cx = classNames.bind(styles);

@component
export class InterviewFlowHeader extends React.Component
{
  @di private _interviewFlowsState!:InterviewFlowsState;
  @di private _interviewFlowsController!:InterviewFlowsController;
  @di private _interviewFlowsFiltersState!:InterviewFlowsFiltersState;
  @di private _interviewFlowsFiltersController!:InterviewFlowsFiltersController;

  // ---------------------------------------------------------

  @bind
  private _onRemoveFilter(filter:IFilter<IFilterValue>):() => void
  {
    return ():void =>
    {
      this._interviewFlowsFiltersController.clearFilter(filter.type);
    };
  }

  @bind
  private _onChangeFilter(filter:IFilter<IFilterValue>):(newValue:IFilterValue) => void
  {
    return (newValue:IFilterValue):void =>
    {
      console.log('%c !!! _onChangeFilter newValue =', 'background:#ff0;color:#000;', newValue);
      this._interviewFlowsFiltersController.changeFilterValue(filter.type, newValue);
    };
  }

  @bind
  private _getFilterData(filter:IFilter<IFilterValue>):() => unknown
  {
    return ():unknown =>
    {
      // console.log('%c !!! _getFilterData filter =', 'background:#ff0;color:#000;', filter.type);
      return this._interviewFlowsFiltersController.getFilterData(filter.type);
    };
  }

  // ----------------------------------------------------

  public render():ReactNode
  {
    const { customers, positionGroups, recruiters } = this._interviewFlowsState;

    const showFilters:boolean = !!customers.length && !!positionGroups.length && !!recruiters.length;

    return (
      <>
        <Toolbar
          className={styles.toolbar}
          title={''}

          leftClassName={styles.positionLeft}
          leftElements={
            <>
              <SelectFlow />
            </>
          }

          centerClassName={styles.positionCenter}
          centerElements={
            <>
            </>
          }

          rightClassName={styles.positionRight}
          rightElements={
            <div className={styles.filtersWrapper}>

              {
                showFilters &&
                <div className={styles.filters}>
                  {this._interviewFlowsFiltersState.filters.map((filter:IFilter<IFilterValue>):React.ReactNode | null =>
                  {
                    if( !filter.isSelected ) return null;
                    // console.log('%c filter %s =', 'background:#f0f;color:#fff;', filter.type, filter);
                    const Component = filter.component;

                    return (
                      <React.Fragment key={filter.name + filter.type}>
                        <Component
                          wrapperClassName={styles.filterComponentWrapper}
                          filter={filter}
                          buttonClearText={'Clear all'}
                          getFilterData={this._getFilterData(filter)}
                          onChangeFilter={this._onChangeFilter(filter)}
                          onRemoveFilter={this._onRemoveFilter(filter)}
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              }

              {/*<div className={cx(styles.filters, styles._old)}>*/}
              {/*  <SelectCustomer />*/}
              {/*  <SelectPositionGroup />*/}
              {/*  <SelectUser />*/}
              {/*  /!*<InterviewFlowGroupBySelector />*!/*/}
              {/*</div>*/}

            </div>
          }
        />
      </>
    );
  }

  // ----------------------------------------------------
}
