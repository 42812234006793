import bind from 'bind-decorator';
import { makeObservable } from 'mobx';
import type { IReactComponent } from 'mobx-react/dist/types/IReactComponent';
import { v4 } from 'uuid';
import type { Controller, ControllerConstructor } from './controller';
import type { IModuleOptions } from './diModule';
import type { State, StateConstructor } from './state';
import { hasMobXDecorators, log } from './utils';

export const deletedInstanceSymbol = Symbol('deletedInstance');

export interface IDeletedProvider
{
  [deletedInstanceSymbol]?:boolean;
}

export type Provider = (State | Controller) & IDeletedProvider;
export type ProviderConstructor = StateConstructor | ControllerConstructor;

export interface IProvider
{
  readonly typeName:string;
  readonly qualifier:string;
  readonly type:ProviderConstructor;
  instances:Set<Provider>;
}

export interface IModule
{
  readonly name:string;
  parent:IModule | null;
  readonly submodules:Set<IModule>;
  readonly providers:Array<IProvider>;
}

const deletedItemProxy = new Proxy(
  function()
  {
    //
  },
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars
    get(target:any, propertyKey:PropertyKey, receiver:any):any
    {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      log(`%cproxy get ${typeof propertyKey === 'string' ? propertyKey : 'symbol'} from ${target?.constructor?.name}`, 'color: aquamarine');

      return deletedItemProxy;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars
    set(target:any, propertyKey:PropertyKey, value:any, receiver:any):boolean
    {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      log(`%cproxy set ${typeof propertyKey === 'string' ? propertyKey : 'symbol'} from ${target?.constructor?.name}`, 'color: aquamarine');
      return true;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars
    apply(target:any, context:any, args:Array<any>):any
    {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      log(`%cproxy apply from ${target?.constructor?.name}`, 'color: aquamarine');
      return null;
    }
  }
);

export class Registry
{
  private readonly _rootModules:Set<IModule> = new Set<IModule>();
  private readonly _providers:Set<IProvider> = new Set<IProvider>();

  public createModule<T extends IReactComponent>(component:T,
                                                 parentModule:IModule | null,
                                                 options:IModuleOptions):IModule
  {
    const moduleName:string = component.displayName || component.name || v4();

    log(`%ccreate module ${moduleName} with parent module ${parentModule?.name}`, 'color: olive');

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const providers:Array<IProvider> = this._registerProviders(options.providers);

    const module:IModule = {
      name: moduleName,
      parent: parentModule,
      submodules: new Set<IModule>(),
      providers
    };

    if( parentModule )
      parentModule.submodules.add(module);
    else
      this._rootModules.add(module);

    return module;
  }

  @bind
  public deleteModule(module:IModule | null):void
  {
    log(`%cdelete module ${module?.name}`, 'color: blueviolet');

    if( module )
    {
      module.submodules.forEach(this.deleteModule);
      module.providers.forEach((provider:IProvider) =>
      {
        provider.instances.forEach((instance:Provider) =>
        {
          instance[deletedInstanceSymbol] = true;

          if( instance.destroy )
            instance.destroy();
        });

        this._providers.delete(provider);
      });

      if( module && module.parent )
      {
        module.parent.submodules.delete(module);
        module.parent = null;
      }
      else
        this._rootModules.delete(module);
    }
  }

  private _registerProviders(providerConstructors:Array<ProviderConstructor>):Array<IProvider>
  {
    const providersToRegister:string = providerConstructors.map((providerConstructor:ProviderConstructor) =>
    {
      const typeName:string = providerConstructor.prototype.constructor.name;
      const qualifier:string = typeName; // TODO: add qualifier support

      return `${typeName}_${qualifier}`;
    }).join(', ');

    log(`%cproviders to register ${providersToRegister}`, 'color: cornflowerblue');

    return providerConstructors.map((providerConstructor:ProviderConstructor) =>
    {
      const typeName:string = providerConstructor.prototype.constructor.name;
      const qualifier:string = typeName; // TODO: add qualifier support

      log(`%cprocess provider with type ${typeName} and qualifier ${qualifier}`, 'color: darkcyan');

      const provider:IProvider = {
        typeName,
        qualifier,
        type: providerConstructor,
        instances: new Set<Provider>()
      };

      if( this._findProvider(typeName, qualifier) )
      {
        throw new Error(
          `Provider of type: ${typeName} with qualifier: ${qualifier} was registered already`
        );
      }

      log(`%cadd provider with type ${typeName} and qualifier ${qualifier}`, 'color: coral');

      this._providers.add(provider);

      return provider;
    });
  }

  private _findProvider(typeName:string, qualifier:string):IProvider | null
  {
    log(`%cfind provider with type ${typeName} and qualifier ${qualifier}`, 'color: darksalmon');

    for( const provider of this._providers )
    {
      log(`%ccheck provider with type ${provider.typeName} and qualifier ${provider.qualifier}`, 'color: darkturquoise');

      if( provider.typeName === typeName && provider.qualifier === qualifier )
      {
        log(`%cprovider found`, 'color: greenyellow');

        return provider;
      }
    }

    return null;
  }

  @bind
  public getInstance(injectTarget:Provider, typeName:string, qualifier:string):Provider
  {
    if( injectTarget[deletedInstanceSymbol] )
    {
      const instanceId = `${typeName}_${qualifier}`;

      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      log(`%cinject from deleted instance ${injectTarget.constructor.name} of ${instanceId}`, 'color: chocolate');
      return deletedItemProxy;
    }

    const provider:IProvider | null = this._findProvider(typeName, qualifier);

    if( !provider )
      throw new Error(
        `Provider of type: ${typeName} with qualifier: ${qualifier} not found`
      );

    let instance:Provider = [...provider.instances][0];

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    log(`%cFind instance ${typeName}_${qualifier} for ${injectTarget.constructor.name}`, 'color: lime', instance);

    if( !instance )
    {
      instance = new provider.type();

      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      log(`%cCreate instance ${typeName}_${qualifier} for ${injectTarget.constructor.name}`, 'color: yellow');

      if( hasMobXDecorators(instance) )
        makeObservable(instance);

      if( instance.init )
        instance.init();

      provider.instances.add(instance);
    }

    return instance;
  }
}

export const registry:Registry = new Registry();
