import { CommonController } from '@flow/common/CommonController';
import { RouteName } from '@flow/common/models/routing/RouteName';
import { componentWithRouter, di, diModule } from '@flow/dependency-injection';
import { ConvertToStaffController } from '@flow/modules/recruiting/common/convertToStaff/ConvertToStaffController';
import { ConvertToStaffState } from '@flow/modules/recruiting/common/convertToStaff/ConvertToStaffState';
import { StaffBoardController } from '@flow/modules/staffing/board/StaffBoardController';
import { StaffBoardState } from '@flow/modules/staffing/board/StaffBoardState';
import { StaffController } from '@flow/modules/staffing/staff/StaffController';
import { StaffState } from '@flow/modules/staffing/staff/StaffState';
import { RoutesConfig } from 'apps/flow/src/pages/RoutesConfig';
import type React from 'react';
import { Component } from 'react';
import { Outlet } from 'react-router-dom';
import { PageNotFound } from '../../../pages/404';
import { FeedbackFormController } from '../feedbackForm/FeedbackFormController';
import { FeedbackFormState } from '../feedbackForm/FeedbackFormState';
import { ScheduleController } from '../schedule/ScheduleController';
import { ScheduleState } from '../schedule/ScheduleState';
import { InterviewFlowsController } from '../status/InterviewFlowsController';
import { CandidateAllocationController } from './CandidateAllocationController';
import { CandidateAllocationState } from './CandidateAllocationState';
import { CandidateController } from './CandidateController';
import { CandidateFlowController } from './CandidateFlowController';
import { CandidateFlowState } from './CandidateFlowState';
import { CandidatePositionGroupsState } from './CandidatePositionGroupsState';
import { CandidateState } from './CandidateState';
import { CandidateLayout } from './components/candidate/CandidateLayout';
import { CandidateScheduleSidebar } from './components/candidate/communication/event/CandidateScheduleSidebar';

@diModule({
  providers: [
    CandidateState,
    CandidateAllocationState,
    // CandidateSiteSubsiteState, // provided in Candidates.tsx
    CandidatePositionGroupsState,
    CandidateFlowState,

    CandidateController,
    CandidateAllocationController,
    CandidateFlowController,

    StaffController,
    StaffState,
    StaffBoardController,
    StaffBoardState,

    ScheduleController,
    ScheduleState,

    FeedbackFormController,
    FeedbackFormState,

    ConvertToStaffState,
    ConvertToStaffController,

    InterviewFlowsController
  ]
})
@componentWithRouter
export class Candidate extends Component
{
  @di private readonly _commonController!:CommonController;
  @di private _candidateState!:CandidateState;
  @di private _candidateController!:CandidateController;
  @di private _scheduleState!:ScheduleState;
  @di private _feedbackFormState!:FeedbackFormState;
  @di private _candidateFlowsController!:CandidateFlowController;

  // ----------------------------------------------------

  public componentDidMount():void
  {
    this._candidateController.initCandidate().then(() =>
    {
      const { candidate } = this._candidateState;

      this._scheduleState.btnBackUrl = this._commonController.getCandidateUrl(candidate?.id) || null;
      this._feedbackFormState.btnBackUrl = this._commonController.getCandidateUrl(candidate?.id) || null;

      this._candidateFlowsController.initFlows();

      if( RoutesConfig.isCurrentRoute(RouteName.RECRUITING_CANDIDATE_SCHEDULE_EVENT_ID) )
      {
        // TODO: ?
      }
    });

    this._scheduleState.sidebarElement = ():JSX.Element => <CandidateScheduleSidebar />;
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { isCandidateLoaded, pageNotFound } = this._candidateState;

    if( pageNotFound ) return <PageNotFound />;

    if( !isCandidateLoaded ) return null;

    if( RoutesConfig.isCurrentRoute(RouteName.RECRUITING_CANDIDATE_SCHEDULE_EVENT_ID) )
    {
      return <Outlet />;
    }

    if( RoutesConfig.isCurrentRoute(RouteName.RECRUITING_CANDIDATE_FEEDBACK_EVENT_ID) )
    {
      return <Outlet />;
    }

    return (
      <>
        <CandidateLayout />
      </>
    );
  }

  // ----------------------------------------------------
}
