import type { IFlowEnvironment } from './types';

export const environment:IFlowEnvironment = {
  production: true,
  apiBaseUrl: 'https://api.staging.mint-flow.com/v1/graphql',
  flowApiBaseUrl: 'https://api.staging.mint-flow.com/v1/flow',
  wsBaseUrl: 'wss://api.staging.mint-flow.com/v1/graphql',
  cdnBaseUrl: 'https://cdn.mint-flow.com/staging',
  recruiting: {
    careersEmail: 'dev-flow@siliconmint.com'
  }
};
