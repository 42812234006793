import { PageUtil } from '@flow/common/utils/PageUtil';
import { component, di } from '@flow/dependency-injection';
import { CustomersState } from '@flow/modules/customers/teams/CustomersState';
import type { ReactNode } from 'react';
import React from 'react';
import { CustomersList } from './components/customers/CustomersList';
import { CustomersToolbar } from './components/customers/CustomersToolbar';
import { CustomersController } from './CustomersController';

@component
export class CustomersTeams extends React.Component
{
  @di private _customersState!:CustomersState;
  @di private _customersController!:CustomersController;

  // ----------------------------------------------------

  public componentDidMount():void
  {
    this._customersController.initFromLocalStorage();
    this._customersController.initCustomers();
  }

  // ----------------------------------------------------

  public componentWillUnmount():void
  {
    PageUtil.disposeReactions(this._customersState.disposers);
  }

  // ----------------------------------------------------

  public render():ReactNode
  {
    return (
      <>
        <CustomersToolbar />
        <CustomersList />
      </>
    );
  }
}
