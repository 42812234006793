import { Tooltip2 } from '@blueprintjs/popover2';
import { IconNames } from '@flow/common/components/form/Icon';
import { type IMenuItem } from '@flow/common/models/routing/MenuItems';
import { menuItems, settingsMenuItems } from '@flow/common/models/routing/MenuItems';
import { RouteName } from '@flow/common/models/routing/RouteName';
import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';
import { RoutesConfig } from '../../../pages/RoutesConfig';
import { AnchorButton } from '../form/AnchorButton';

import styles from './PageSidebar.module.less';

const cx = classNames.bind(styles);

interface State
{
  backUrl:string;
}

interface PageSidebarProps
{
  isMobileMenuVisible:boolean;
  setIsMobileMenuVisible:(value:boolean) => void;
  isSettingsRoute:boolean;
}

@component
export class PageSidebar extends React.Component<PageSidebarProps, State>
{
  // ---------------------------------------------------------

  public constructor(props:PageSidebarProps)
  {
    super(props);

    this.state = {
      backUrl: ''
    };
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { backUrl } = this.state;
    const { isMobileMenuVisible, setIsMobileMenuVisible, isSettingsRoute } = this.props;

    // const location = useLocation();
    // const currentRoute:string = location.pathname;

    console.log('%c --- PageSidebar: =', 'background:#080;color:#000;');
    // console.log('%c --- PageSidebar: currentRoute =', 'background:#080;color:#000;', currentRoute);

    const settingsHref:string | undefined = RoutesConfig.getRoutePath(RouteName.SETTINGS_POSITION_GROUPS) || undefined;
    const mainMenuHref:string | undefined = RoutesConfig.getRoutePath(RouteName.HOME) || undefined;

    return (
      <div className={styles.wrapper}>
        <div
          className={cx(styles.mobileGlass, { isMobileMenuVisible })}
          onClick={():void => setIsMobileMenuVisible(!isMobileMenuVisible) as void}
        />
        <div className={cx(styles.sidebar, { isMobileMenuVisible })}>

          {
            isSettingsRoute &&
            <div className={styles.header}>
              <div className={styles.headerTitle}>
                Settings
              </div>
              <Tooltip2
                content={'Back to Flow'}
                placement={'top'}
                className={styles.tooltipWrapper}
              >
                <AnchorButton
                  wrapperClassName={styles.btnBackWrapper}
                  className={styles.btnBack}
                  iconClassName={styles.btnBackIcon}
                  href={backUrl ?? mainMenuHref}
                  text={'Back'}
                  icon={IconNames.DOUBLE_CHEVRON_LEFT}
                  minimal={true}
                  onClick={():void =>
                  {
                    this.setState({ backUrl: '' });
                  }}
                />
              </Tooltip2>
            </div>
          }

          {this._renderMenu()}

          {
            !isSettingsRoute &&
            <AnchorButton
              wrapperClassName={styles.menuItemSettingsWrapper}
              className={styles.menuItemSettings}
              iconClassName={styles.menuItemSettingsIcon}
              href={settingsHref}
              icon={IconNames.COG}
              iconSize={15}
              text={'Settings'}
              minimal={true}
              onClick={():void =>
              {
                this.setState({ backUrl: window.location.pathname + window.location.search });
              }}
            />
          }
        </div>
      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderMenu():JSX.Element
  {
    const { isSettingsRoute } = this.props;

    return (
      <ul className={styles.menu}>
        {(isSettingsRoute ? settingsMenuItems : menuItems).map(this._renderMenuItem).filter(item => item !== null)}
      </ul>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderMenuItem(menuItem:IMenuItem):JSX.Element | null
  {
    // const href:string | null = RoutesConfig.getRoutePath(menuItem.routeName);
    const hasAccessFn = RoutesConfig.getAccessFn(menuItem.routeName);

    if( hasAccessFn && !hasAccessFn() )
      return null;

    if( !menuItem.subMenu )
      return this._renderSubMenuItem(menuItem);

    return (
      <li key={menuItem.title} className={styles.menuItem}>
        {/*{href*/}
        {/*  ? <AnchorButton*/}
        {/*    className={cx(styles.menuItemLink, styles.level1)}*/}
        {/*    activeClassName={styles.isActive}*/}
        {/*    text={menuItem.title}*/}
        {/*    href={href}*/}
        {/*    minimal={true}*/}
        {/*    // icon={IconNames.CARET_RIGHT}*/}
        {/*  />*/}
        {/*  : `Route ${menuItem.routeName} not found`*/}
        {/*}*/}
        <span
          className={cx(styles.menuItemLink, styles.level1)}
        >
          {menuItem.title}
        </span>
        {this._renderSubMenu(menuItem.subMenu || [])}
      </li>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderSubMenu(subMenu:Array<IMenuItem>):JSX.Element
  {
    if( !subMenu || !subMenu.length ) return <></>; // TODO ???

    return (
      <ul className={styles.subMenu}>
        {subMenu.map(this._renderSubMenuItem).filter(item => item !== null)}
      </ul>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderSubMenuItem(menuItem:IMenuItem):JSX.Element | null
  {
    const href:string | null = RoutesConfig.getRoutePath(menuItem.routeName);
    const hasAccessFn = RoutesConfig.getAccessFn(menuItem.routeName);

    if( hasAccessFn && !hasAccessFn() )
      return null;

    const disabled:boolean = menuItem.disabled || false;

    return (
      <li key={menuItem.title} className={styles.menuItem}>
        {href
          ? <AnchorButton
            className={cx(styles.menuItemLink, styles.level2)}
            activeClassName={styles.isActive}
            text={menuItem.title}
            href={disabled ? '' : href}
            minimal={true}
            disabled={disabled}
            onClick={():void => this.props.setIsMobileMenuVisible(false) as void}
            activePaths={menuItem.activeRoutes?.map(routeName => String(RoutesConfig.getRoutePath(routeName)))}
            // icon={IconNames.BLANK}
          />
          : `Route ${menuItem.routeName} not found`
        }
      </li>
    );
  }

  // ---------------------------------------------------------
}
