import { InlineEditor, InlineEditorType } from '@flow/common/components/form/InlineEditor';
import { DateUtil } from '@flow/common/utils/DateUtil';
import classNames from 'classnames/bind';
import type { Moment } from 'moment';
import moment from 'moment';

import styles from '../eventForm/CommunicationEventForm.module.less';

const cx = classNames.bind(styles);

interface Props
{
  timeStr:string;
  time:Date;
  timeQuery?:string;
  onChange?:(value:Moment) => void;
  isChanged?:boolean;
  onQueryChange?:(query:string) => void;
  onTimeUpDown?:(newTime:Date) => void;
  readonly?:boolean;
}

function timeQueryMatch(time:Date, query:string):boolean
{
  const valueMoment = moment(time);

  return Boolean(valueMoment.format('HH:mm').startsWith(query) || valueMoment.format('h:mm A').startsWith(query));
}

export const EventStartTimeEditor = (props:Props):JSX.Element =>
{
  const {
    timeStr,
    time,
    timeQuery,
    onChange,
    isChanged,
    onQueryChange,
    onTimeUpDown,
    readonly
  } = props;

  const timeStr24 = moment(time).format('HH:mm');

  const values = time
    ? DateUtil.generateTimeList(15, moment(time).set({ hours: 0, minutes: 0 }).toDate() as Date)
      .map(date =>
      {
        if( !date )
          return { isMenuDivider: true };

        const itemTimeStr = String(moment(date).format('h:mm A'));
        const itemTimeStr24 = String(moment(date).format('HH:mm'));

        const compareToTime = String(timeQuery && timeQuery.length > 0 ? timeQuery : timeStr24);

        return {
          value: date.toUTCString(),
          element: <div>{itemTimeStr}</div>,
          title: `${itemTimeStr}${itemTimeStr24}`,
          isActive: timeQueryMatch(date, compareToTime)
        };
      })
    : [];

  // TODO
  let activeValue:Date = time;

  for( const value of values )
  {
    const date = moment(value.value).toDate();

    const compareToTime = String(timeQuery && timeQuery.length > 0 ? timeQuery : timeStr);
    if( timeQueryMatch(date as Date, compareToTime) )
    {
      value.isActive = true;
      activeValue = date;
      break;
    }
  }

  return <InlineEditor
    type={InlineEditorType.TIME}
    applyWhenSelect
    viewClassName={cx(styles.endTimeEditor, { readonly })}
    menuClassName={styles.startTimePopover}
    inputClassName={styles.timeInput}
    text={timeStr}
    query={timeQuery}
    readonly={readonly}
    isChanged={isChanged}
    onKeyDown={(event):void =>
    {
      if( event.key === 'ArrowUp' )
      {
        const tryMoveUpTime = moment(activeValue).subtract(15, 'minutes');

        if( onTimeUpDown )
          onTimeUpDown(tryMoveUpTime.toDate() as Date);

        if( onQueryChange )
          onQueryChange(String(tryMoveUpTime.format('h:mm A')));
      }

      if( event.key === 'ArrowDown' )
      {
        const tryMoveDownTime = moment(activeValue).add(15, 'minutes');

        if( onTimeUpDown )
          onTimeUpDown(tryMoveDownTime.toDate() as Date);

        if( onQueryChange )
          onQueryChange(String(tryMoveDownTime.format('h:mm A')));
      }
    }}
    fields={[
      {
        name: 'time',
        value: time.toUTCString(),
        values: values,
        placeholder: 'Enter time'
      }
    ]}
    onQueryChange={(query):void =>
    {
      if( onQueryChange )
        onQueryChange(String(query));
    }}
    onChange={(item):void =>
    {
      const timeMoment = moment(item['time'].value) as Moment;

      if( onChange )
        onChange(timeMoment);

      if( onQueryChange )
        onQueryChange(timeMoment.format('h:mm A'));
    }}
    noButtons
  />;
};
