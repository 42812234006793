import { component } from '@flow/dependency-injection';

import classNames from 'classnames/bind';
import * as React from 'react';
import type { IconName } from '../form/Icon';
import { Icon } from '../form/Icon';
import styles from './Toolbar.module.less';

const cx = classNames.bind(styles);

// ----------------------------------------------------

interface ToolbarTitleProps
{
  title:string | JSX.Element;
}

export class ToolbarTitle extends React.PureComponent<ToolbarTitleProps>
{
  public render():React.ReactNode | null
  {
    const { title } = this.props;

    if( !title ) return null;

    return (
      <div className={styles.title}>
        {title}
      </div>
    );
  }
}

// ----------------------------------------------------

interface Props
{
  icon?:IconName;
  title:string | JSX.Element;

  leftElements?:JSX.Element;
  rightElements?:JSX.Element;
  centerElements?:JSX.Element;

  className?:string;
  leftClassName?:string;
  rightClassName?:string;
  centerClassName?:string;
}

@component
export class Toolbar extends React.Component<Props>
{
  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const {
      icon, title, className,
      leftElements, centerElements, rightElements,
      leftClassName, centerClassName, rightClassName
    } = this.props;

    return (
      <div className={cx(styles.wrapper, className)}>

        {/* -------------------------------------------- */}

        <div className={cx(styles.left, leftClassName)}>
          {
            icon &&
            <Icon
              className={styles.icon}
              icon={icon}
            />
          }
          <ToolbarTitle title={title} />
          {leftElements}
        </div>

        {/* -------------------------------------------- */}

        {
          centerElements &&
          <div className={cx(styles.center, centerClassName)}>
            {centerElements}
          </div>
        }

        {/* -------------------------------------------- */}

        {
          rightElements &&
          <div className={cx(styles.right, rightClassName)}>
            {rightElements}
          </div>
        }

        {/* -------------------------------------------- */}

      </div>
    );
  }

  // ----------------------------------------------------
}
