import { Button } from '@flow/common/components/form/Button';
import { Intent } from '@flow/common/components/types/Types';
import styles from './CandidateCreatedToast.module.less';

export const CandidateCreatedToast = (
  props:{
    candidateId:number;
    onNavigate:() => void;
  }
):JSX.Element =>
{
  return (
    <div className={styles.wrapper}>

      <div className={styles.messageRow}>
        New candidate is created.
      </div>

      <div className={styles.actionRow}>
        <Button
          onClick={props.onNavigate}
          minimal
          outlined
          intent={Intent.SUCCESS}
          text={'Go to candidate'}
        />
      </div>

    </div>
  )
}
