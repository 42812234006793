import type * as Types from './types/graphql.generated';

import { gql } from '@apollo/client';
import { Open_Slots_CountFragmentDoc, Total_Slots_CountFragmentDoc, Leaving_SlotsFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
export type CreateTeamMutationVariables = Types.Exact<{
  customerId:Types.Scalars['Int'];
  name?:Types.InputMaybe<Types.Scalars['String']>;
  isActive?:Types.InputMaybe<Types.Scalars['Boolean']>;
  notes?:Types.InputMaybe<Types.Scalars['String']>;
  tags:Array<Types.Staffing_Customer_Team_Tag_Insert_Input> | Types.Staffing_Customer_Team_Tag_Insert_Input;
  uiOrder:Types.Scalars['Int'];
}>;


export type CreateTeamMutation = { insert_staffing_customer_team_one?:{ id:number; } | null; };

export type UpdateTeamMutationVariables = Types.Exact<{
  teamId:Types.Scalars['Int'];
  name?:Types.InputMaybe<Types.Scalars['String']>;
  isActive?:Types.InputMaybe<Types.Scalars['Boolean']>;
  notes?:Types.InputMaybe<Types.Scalars['String']>;
  tags:Array<Types.Staffing_Customer_Team_Tag_Insert_Input> | Types.Staffing_Customer_Team_Tag_Insert_Input;
}>;


export type UpdateTeamMutation = { update_staffing_customer_team_by_pk?:{ id:number; } | null; delete_staffing_customer_team_tag?:{ affected_rows:number; } | null; insert_staffing_customer_team_tag?:{ affected_rows:number; } | null; };

export type DeleteTeamMutationVariables = Types.Exact<{
  id:Types.Scalars['Int'];
  deleted_at:Types.Scalars['timestamptz'];
}>;


export type DeleteTeamMutation = { update_staffing_customer_team_by_pk?:{ id:number; deleted_at?:any | null; } | null; };

export type GetTeamQueryVariables = Types.Exact<{
  teamId:Types.Scalars['Int'];
}>;


export type GetTeamQuery = { staffing_customer_team_by_pk?:{ id:number; name:string; is_active:boolean; notes?:string | null; customer_id:number; positions:Array<{ id:number; alias?:string | null; quantity?:number | null; notes?:string | null; position_group?:{ id:number; name:string; } | null; position_template?:{ id:number; name:string; staff_role:{ id:number; name:string; }; } | null; open_slots_count:{ aggregate?:{ count:number; } | null; }; total_slots_count:{ aggregate?:{ count:number; } | null; }; }>; customer_team_tags:Array<{ id:number; name:string; }>; } | null; };


export const CreateTeamDocument = gql`
    mutation CreateTeam($customerId: Int!, $name: String, $isActive: Boolean, $notes: String, $tags: [staffing_customer_team_tag_insert_input!]!, $uiOrder: Int!) {
  insert_staffing_customer_team_one(
    object: {customer_id: $customerId, name: $name, is_active: $isActive, notes: $notes, customer_team_tags: {data: $tags}, ui_order: $uiOrder}
  ) {
    id
  }
}
    `;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const UpdateTeamDocument = gql`
    mutation UpdateTeam($teamId: Int!, $name: String, $isActive: Boolean, $notes: String, $tags: [staffing_customer_team_tag_insert_input!]!) {
  update_staffing_customer_team_by_pk(
    pk_columns: {id: $teamId}
    _set: {name: $name, is_active: $isActive, notes: $notes}
  ) {
    id
  }
  delete_staffing_customer_team_tag(where: {customer_team_id: {_eq: $teamId}}) {
    affected_rows
  }
  insert_staffing_customer_team_tag(objects: $tags) {
    affected_rows
  }
}
    `;
export type UpdateTeamMutationFn = Apollo.MutationFunction<UpdateTeamMutation, UpdateTeamMutationVariables>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const DeleteTeamDocument = gql`
    mutation DeleteTeam($id: Int!, $deleted_at: timestamptz!) {
  update_staffing_customer_team_by_pk(
    pk_columns: {id: $id}
    _set: {deleted_at: $deleted_at}
  ) {
    id
    deleted_at
  }
}
    `;
export type DeleteTeamMutationFn = Apollo.MutationFunction<DeleteTeamMutation, DeleteTeamMutationVariables>;
export type DeleteTeamMutationResult = Apollo.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<DeleteTeamMutation, DeleteTeamMutationVariables>;
export const GetTeamDocument = gql`
    query GetTeam($teamId: Int!) {
  staffing_customer_team_by_pk(id: $teamId) {
    id
    name
    is_active
    notes
    customer_id
    positions(where: {deleted_at: {_is_null: true}}) {
      id
      alias
      quantity
      notes
      position_group {
        id
        name
      }
      position_template {
        id
        name
        staff_role {
          id
          name
        }
      }
      ...open_slots_count
      ...total_slots_count
    }
    customer_team_tags {
      id
      name
    }
  }
}
    ${Open_Slots_CountFragmentDoc}
${Total_Slots_CountFragmentDoc}`;
export type GetTeamQueryResult = Apollo.QueryResult<GetTeamQuery, GetTeamQueryVariables>;
export const namedOperations = {
  Query: {
    GetTeam: 'GetTeam'
  },
  Mutation: {
    CreateTeam: 'CreateTeam',
    UpdateTeam: 'UpdateTeam',
    DeleteTeam: 'DeleteTeam'
  }
}