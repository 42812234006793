import { state } from '@flow/dependency-injection';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { observable } from 'mobx';

@state
export class PositionState
{
  // ----------------------------------------------------

  @observable public position:Recruiting_Position_Ex | null = null;

  // TODO: !!! REMOVE after implementation -> position.isReady
  @observable public positionIsReady:boolean = false;

  @observable public pageNotFound:boolean = false;
  @observable public isPositionLoaded:boolean = false;

  // ----------------------------------------------------

  @observable public showRenamePositionDialog:boolean = false;

  @observable public inputPositionNameValue:string = '';

  // ----------------------------------------------------

  @observable public routePropCustomerId?:string;

  // ----------------------------------------------------

  @observable public isEditMode:boolean = false;

  // ----------------------------------------------------
}
