import { ButtonGroup, Menu, MenuItem } from '@blueprintjs/core';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { NeedsPermission } from '@flow/common/components/NeedsPermission';
import { Toolbar } from '@flow/common/components/page/Toolbar';
import { Intent } from '@flow/common/components/types/Types';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { component, di } from '@flow/dependency-injection';
import { SettingsPositionGroupsController } from '@flow/modules/settings/settingsPositionGroups/SettingsPositionGroupsController';
import {
  SettingsPositionGroupsState,
  SettingsPositionGroupsStatus
} from '@flow/modules/settings/settingsPositionGroups/SettingsPositionGroupsState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { action } from 'mobx';
import React from 'react';

import styles from './SettingsPositionGroupsToolbar.module.less';

const cx = classNames.bind(styles);

@component
export class SettingsPositionGroupsToolbar extends React.Component
{
  @di private _settingsPositionGroupsState!:SettingsPositionGroupsState;
  @di private _settingsPositionGroupsController!:SettingsPositionGroupsController;

  // ---------------------------------------------------------

  @action.bound
  private _onClickPositionGroupStatusFilter(filter:SettingsPositionGroupsStatus):(e:React.MouseEvent<HTMLElement>) => void
  {
    return (e:React.MouseEvent<HTMLElement>):void =>
    {
      e.preventDefault();
      this._settingsPositionGroupsController.setFilterByPositionStatus(filter);
    };
  }

  // ---------------------------------------------------------

  @bind
  private _renderMenu():React.ReactElement
  {
    return (
      <Menu>
        <MenuItem
          text={'New Position Group'}
          onClick={():void => this._settingsPositionGroupsController.showNewEditPositionGroupDialog(true)}
        />
        {/*<MenuDivider />*/}
      </Menu>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { filterByPositionGroupStatus } = this._settingsPositionGroupsState;

    const positionStatusIsActive:boolean = filterByPositionGroupStatus === SettingsPositionGroupsStatus.ACTIVE;

    return (
      <>
        <Toolbar
          className={styles.toolbar}
          title={'Position Groups'}

          leftClassName={styles.positionLeft}
          leftElements={
            <>
              <NeedsPermission name={FlowPermissions.CreatePositionGroup}>
                <Button
                  className={styles.btnAdd}
                  text={'New Position Group'}
                  icon={IconNames.ADD}
                  minimal={true}
                  onClick={():void => this._settingsPositionGroupsController.showNewEditPositionGroupDialog(true)}
                />
              </NeedsPermission>
            </>
          }

          centerClassName={styles.positionCenter}
          centerElements={
            <>
              <ButtonGroup className={styles.buttonGroup}>
                <Button
                  className={cx(styles.buttonGroupBtn, { isActive: positionStatusIsActive })}
                  text={'Active'}
                  // minimal={true}
                  onClick={this._onClickPositionGroupStatusFilter(SettingsPositionGroupsStatus.ACTIVE)}
                  intent={Intent.PRIMARY}
                  outlined={!positionStatusIsActive}
                />
                <Button
                  className={cx(styles.buttonGroupBtn, { isActive: !positionStatusIsActive })}
                  text={'Archived'}
                  // minimal={true}
                  onClick={this._onClickPositionGroupStatusFilter(SettingsPositionGroupsStatus.ARCHIVED)}
                  intent={Intent.PRIMARY}
                  outlined={positionStatusIsActive}
                />
              </ButtonGroup>
            </>
          }

          rightClassName={styles.positionRight}
          rightElements={
            <>
              {/*<Popover2*/}
              {/*  className={styles.viewMobile}*/}
              {/*  content={this._renderMenu()}*/}
              {/*  position={Position.BOTTOM_RIGHT}*/}
              {/*  popoverClassName={Classes.POPOVER2_DISMISS}*/}
              {/*>*/}
              {/*  <Button*/}
              {/*    icon={IconNames.MORE}*/}
              {/*    minimal={true}*/}
              {/*  />*/}
              {/*</Popover2>*/}
            </>
          }
        />
      </>
    );
  }

  // ---------------------------------------------------------
}
