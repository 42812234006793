import type * as Types from './types/graphql.generated';

import { gql } from '@apollo/client';
export type Open_Slots_CountFragment = { open_slots_count:{ aggregate?:{ count:number; } | null; }; };

export type Total_Slots_CountFragment = { total_slots_count:{ aggregate?:{ count:number; } | null; }; };

export type Leaving_SlotsFragment = { leaving_slots:Array<{ status:Types.Staffing_Customer_Team_Slot_Status_Enum; leaving_date?:any | null; }>; };

export type Scheduled_Event_FragmentFragment = { id:number; user_id:number; google_event_id?:string | null; is_draft:boolean; is_scheduled:boolean; is_done:boolean; start_time:any; end_time:any; attendees_list:string; summary:string; description:string; conference_link?:string | null; result?:string | null; created_at?:any | null; updated_at?:any | null; deleted_at?:any | null; is_feedback_done:boolean; feedback_form_ref?:string | null; };

export type CustomerTeamSlotFragmentFragment = { id:number; status:Types.Staffing_Customer_Team_Slot_Status_Enum; staff_id?:number | null; start_date?:any | null; leaving_date?:any | null; next_staff_id?:number | null; next_staff_start_date?:any | null; next_candidate_id?:number | null; next_candidate_start_date?:any | null; next_candidate_allocation_id?:number | null; position_id:number; staff?:{ id:number; avatar_url?:string | null; user:{ avatar?:string | null; id:number; first_name:string; last_name:string; }; } | null; next_staff?:{ id:number; avatar_url?:string | null; user:{ avatar?:string | null; id:number; first_name:string; last_name:string; }; } | null; candidate?:{ id:number; first_name:string; last_name:string; avatar_url?:string | null; } | null; position:{ id:number; alias?:string | null; position_template?:{ id:number; name:string; staff_role_id:number; } | null; customer_team:{ id:number; name:string; is_active:boolean; deleted_at?:any | null; customer:{ id:number; alias:string; status:Types.Common_Customer_Status_Enum; }; }; }; };

export type StaffUserIdNameDataFragmentFragment = { id:number; first_name:string; last_name:string; };

export type StaffUserDataFragmentFragment = { email?:string | null; avatar?:string | null; id:number; first_name:string; last_name:string; };

export type StaffStaffUserDataFragmentFragment = { id:number; avatar_url?:string | null; user:{ avatar?:string | null; id:number; first_name:string; last_name:string; }; };

export type StaffMemberDataFragmentFragment = { id:number; first_name_i18n?:string | null; last_name_i18n?:string | null; middle_name_i18n?:string | null; avatar_url?:string | null; phone?:string | null; linkedin_profile_url?:string | null; country_id?:number | null; city_id?:number | null; status?:Types.Staffing_Staff_Status_Enum | null; status_date?:any | null; hire_date?:any | null; leaving_date?:any | null; user:{ email?:string | null; avatar?:string | null; id:number; first_name:string; last_name:string; }; role?:{ id:number; name:string; } | null; candidate?:{ id:number; candidate_position_groups:Array<{ position_group:{ id:number; name:string; }; }>; } | null; };

export const Open_Slots_CountFragmentDoc = gql`
    fragment open_slots_count on recruiting_position {
  open_slots_count: customer_team_slots_aggregate(
    where: {_and: [{deleted_at: {_is_null: true}}, {status: {_eq: open}}, {next_candidate_id: {_is_null: true}}, {next_staff_id: {_is_null: true}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const Total_Slots_CountFragmentDoc = gql`
    fragment total_slots_count on recruiting_position {
  total_slots_count: customer_team_slots_aggregate(
    where: {deleted_at: {_is_null: true}, status: {_neq: closed_staff_left}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const Leaving_SlotsFragmentDoc = gql`
    fragment leaving_slots on recruiting_position {
  leaving_slots: customer_team_slots(
    where: {_and: [{deleted_at: {_is_null: true}}, {status: {_eq: current_staff}}, {leaving_date: {_is_null: false}}, {next_candidate_id: {_is_null: true}}, {next_staff_id: {_is_null: true}}]}
  ) {
    status
    leaving_date
  }
}
    `;
export const Scheduled_Event_FragmentFragmentDoc = gql`
    fragment scheduled_event_fragment on common_scheduled_event {
  id
  user_id
  google_event_id
  is_draft
  is_scheduled
  is_done
  start_time
  end_time
  attendees_list
  summary
  description
  conference_link
  result
  created_at
  updated_at
  deleted_at
  is_feedback_done
  feedback_form_ref
}
    `;
export const StaffUserIdNameDataFragmentFragmentDoc = gql`
    fragment staffUserIdNameDataFragment on common_user {
  id
  first_name
  last_name
}
    `;
export const StaffStaffUserDataFragmentFragmentDoc = gql`
    fragment staffStaffUserDataFragment on staffing_staff {
  id
  avatar_url
  user {
    ...staffUserIdNameDataFragment
    avatar
  }
}
    ${StaffUserIdNameDataFragmentFragmentDoc}`;
export const CustomerTeamSlotFragmentFragmentDoc = gql`
    fragment customerTeamSlotFragment on staffing_customer_team_slot {
  id
  status
  staff {
    ...staffStaffUserDataFragment
  }
  staff_id
  start_date
  leaving_date
  next_staff {
    ...staffStaffUserDataFragment
  }
  next_staff_id
  next_staff_start_date
  candidate {
    id
    first_name
    last_name
    avatar_url
  }
  next_candidate_id
  next_candidate_start_date
  next_candidate_allocation_id
  position_id
  position {
    id
    alias
    position_template {
      id
      name
      staff_role_id
    }
    customer_team {
      id
      name
      is_active
      deleted_at
      customer {
        id
        alias
        status
      }
    }
  }
}
    ${StaffStaffUserDataFragmentFragmentDoc}`;
export const StaffUserDataFragmentFragmentDoc = gql`
    fragment staffUserDataFragment on common_user {
  ...staffUserIdNameDataFragment
  email
  avatar
}
    ${StaffUserIdNameDataFragmentFragmentDoc}`;
export const StaffMemberDataFragmentFragmentDoc = gql`
    fragment staffMemberDataFragment on staffing_staff {
  id
  first_name_i18n
  last_name_i18n
  middle_name_i18n
  avatar_url
  phone
  linkedin_profile_url
  country_id
  city_id
  user {
    ...staffUserDataFragment
  }
  status
  status_date
  hire_date
  leaving_date
  role {
    id
    name
  }
  candidate {
    id
    candidate_position_groups {
      position_group {
        id
        name
      }
    }
  }
}
    ${StaffUserDataFragmentFragmentDoc}`;
export const namedOperations = {
  Fragment: {
    open_slots_count: 'open_slots_count',
    total_slots_count: 'total_slots_count',
    leaving_slots: 'leaving_slots',
    scheduled_event_fragment: 'scheduled_event_fragment',
    customerTeamSlotFragment: 'customerTeamSlotFragment',
    staffUserIdNameDataFragment: 'staffUserIdNameDataFragment',
    staffUserDataFragment: 'staffUserDataFragment',
    staffStaffUserDataFragment: 'staffStaffUserDataFragment',
    staffMemberDataFragment: 'staffMemberDataFragment'
  }
}