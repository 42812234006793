import { IFilterCheckboxesValue } from '@flow/common/components/filters/checkboxes/FilterCheckboxes';
import type { IFilterMultiSelectValue } from '@flow/common/components/filters/multiSelect/FilterMultiSelect';
import type { IFilterSiteSubsiteValue } from '@flow/common/components/filters/siteSubsite/FilterSiteSubsite';
import type { IFilterStatusValue } from '@flow/common/components/filters/staffed/FilterStatus';
import type { IFilterTimeValue } from '@flow/common/components/filters/time/FilterTime';
import type { IFilterTodoValue } from '@flow/common/components/filters/todo/FilterTodo';
import { makeObservable, observable } from 'mobx';
import type { IReactComponent } from 'mobx-react/dist/types/IReactComponent';
import type { IFilterFlowStageValue } from './status/FilterFlowStage';

export enum FilterType
{
  TODO = 'TODO', // filter template/example

  // Common templates
  TIME = 'TIME',
  MULTI_SELECT = 'MULTI_SELECT',
  CHECKBOXES = 'CHECKBOXES',

  // Time:
  DATE_CREATED = 'DATE_CREATED',

  // Multi Select:
  POSITION_GROUP = 'POSITION_GROUP',
  ASSIGNED_TO = 'ASSIGNED_TO',
  CREATED_BY = 'CREATED_BY',

  FLOWS_CUSTOMER = 'FLOWS_CUSTOMER',
  FLOWS_POSITION_GROUP = 'FLOWS_POSITION_GROUP',
  FLOWS_RECRUITER = 'FLOWS_RECRUITER',

  // some other
  FLOW_STAGE = 'FLOW_STAGE',
  SITE_SUBSITE = 'SITE_SUBSITE',

  STATUS = 'STATUS'

  // STAFF_ROLE = 'STAFF_ROLE',
  // POSITION = 'POSITION',
  // CUSTOMER = 'CUSTOMER',
  // CUSTOMER_TEAM = 'CUSTOMER_TEAM',
  // MODIFIED_BY = 'MODIFIED_BY',
}

// IFilterValue -> always an Object with any properties
// { anyProperty: value, ... }
// value = Array | string | number | boolean

// value = NOT Object - needed for pretty stringify & parse Query String
// example IFilterTimeValue:
// (x) { type: 'RANGE', range: {from: 'str', to: 'str'} }
// (v) { type: 'RANGE', rangeFrom: 'str', rangeTo: 'str'}
//
// DATE_CREATED_type=RANGE & DATE_CREATED_rangeFrom=Jan_9__2022 & DATE_CREATED_rangeTo=Jan_31__2022

export type IFilterValue = IFilterTodoValue | IFilterMultiSelectValue | IFilterCheckboxesValue |
  IFilterTimeValue | IFilterSiteSubsiteValue | IFilterStatusValue | IFilterFlowStageValue;

export interface IFilterComponentProps<T>
{
  filter:IFilter<T>;
  onChangeFilter:(newValue:T) => void;
  onRemoveFilter:() => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFilterData?:() => any;
  wrapperClassName?:string;
  buttonClearText?:string;
}

export interface IFilter<T>
{
  type:FilterType;
  name:string; // unselected button title & filter popover title
  selectedTitle?:string | JSX.Element;
  unselectedTitle?:string | JSX.Element;

  isSelected:boolean;
  component:IReactComponent<IFilterComponentProps<T>>;
  value:T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterFunction?:(value:any) => boolean;
  graphQLTemplate?:string;
  graphQLFilter?:() => string;
}

export class Filter<T> implements IFilter<T>
{
  @observable public type:FilterType;
  @observable public name:string;
  @observable public isSelected:boolean;
  @observable public value:T;
  public selectedTitle?:string | JSX.Element;
  public unselectedTitle?:string | JSX.Element;
  public component:IReactComponent<IFilterComponentProps<T>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public filterFunction?:(value:any) => boolean;
  public graphQLTemplate?:string;
  public graphQLFilter?:() => string;

  public constructor(props:IFilter<T>)
  {
    const {
      type, name, isSelected, component, value, filterFunction, graphQLTemplate, graphQLFilter,
      selectedTitle, unselectedTitle
    } = props;

    this.type = type;
    this.name = name;
    this.isSelected = isSelected;
    this.value = value;
    this.selectedTitle = selectedTitle;
    this.unselectedTitle = unselectedTitle;
    this.component = component;

    this.filterFunction = filterFunction?.bind(this);
    this.graphQLTemplate = graphQLTemplate;
    this.graphQLFilter = graphQLFilter;

    makeObservable(this);
  }
}
