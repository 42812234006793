import { CommonController } from '@flow/common/CommonController';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { ButtonMore } from '@flow/common/components/elements/ButtonMore';
import { EmptyMenu } from '@flow/common/components/elements/EmptyMenu';
import { Button } from '@flow/common/components/form/Button';
import { Spinner } from '@flow/common/components/form/Spinner';
import { NeedsPermission } from '@flow/common/components/NeedsPermission';
import { ItemsCount } from '@flow/common/components/page/ItemsCount';
import { List } from '@flow/common/components/page/List';
import { Pagination } from '@flow/common/components/page/Pagination';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { Maybe } from '@flow/common/models/Types';
import type { DateStrOrNum } from '@flow/common/utils/DateUtil';
import { DateUtil } from '@flow/common/utils/DateUtil';
import { DebugUtil } from '@flow/common/utils/DebugUtil';
import type {
  Recruiting_Candidate,
  Recruiting_Candidate_Position_Group
} from '@flow/data-access/lib/types/graphql.generated';
import {
  Common_City,
  Common_Country,
  Recruiting_Candidate_Status_Enum
} from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { CandidateSiteSubsiteState } from '@flow/modules/recruiting/candidates/CandidateSiteSubsiteState';
import { CandidatesState, CandidateStatusTitles } from '@flow/modules/recruiting/candidates/CandidatesState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { action, runInAction } from 'mobx';
import React, { Component } from 'react';
import { CandidatesColumnType } from '../../CandidatesColumnsState';
import { CandidatesController } from '../../CandidatesController';

import styles from './CandidatesList.module.less';
import { CandidatesListHeader } from './CandidatesListHeader';

const cx = classNames.bind(styles);

@component
export class CandidatesList extends Component
{
  @di private _commonController!:CommonController;
  @di private _candidatesState!:CandidatesState;
  @di private _candidateSiteSubsiteState!:CandidateSiteSubsiteState;
  @di private _candidatesController!:CandidatesController;

  // ---------------------------------------------------------

  @action.bound
  public _onClickRow(candidate:Recruiting_Candidate)
  {
    return (e:React.MouseEvent<HTMLElement>):void =>
    {
      e.preventDefault();
      this._candidatesController.setViewCandidateId(candidate.id);
      this._commonController.goToCandidate(candidate.id);
    };
  }

  // ---------------------------------------------------------

  @action.bound
  public _onClickMoreMenu(e:React.MouseEvent<HTMLElement>):void
  {
    e.stopPropagation();
  }

  // ---------------------------------------------------------

  @bind
  private _onScroll(event:React.UIEvent<HTMLDivElement>):void
  {
    if( this._candidatesState.candidatesListScrollYPosition != Number(event.currentTarget.scrollTop) )
      runInAction(() => this._candidatesState.candidatesListScrollYPosition = Number(event.currentTarget.scrollTop));
  }

  // ---------------------------------------------------------

  private _renderCandidateStatus(candidate:Recruiting_Candidate):JSX.Element | string
  {
    if( candidate.status == Recruiting_Candidate_Status_Enum.Staffed && candidate.staff_id )
      return (
        <div>
          <Button
            className={styles.btnStaffed}
            text={'Staffed'}
            onClick={(e):void =>
            {
              e.stopPropagation();
              this._commonController.goToStaffMember(candidate?.staff_id);
            }}
            minimal={true}
          />
        </div>
      );

    return String(CandidateStatusTitles.get(candidate.status as string));
  }

  // ---------------------------------------------------------

  @bind
  private _renderPositionGroups(positionGroups:Array<Recruiting_Candidate_Position_Group>):React.ReactNode | null
  {
    if( !positionGroups.length ) return null;

    return (
      <span className={styles.pgMulti}>
        <span className={styles.pgMultiName}>
          {positionGroups[0].position_group.name}
        </span>
        {
          positionGroups.length > 1 &&
          <>&nbsp;
            <span className={styles.pgMultiCounter}>
              +{positionGroups.length - 1}
            </span>
          </>
        }
      </span>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderCandidate(candidate:Recruiting_Candidate):React.ReactNode
  {
    const {
      first_name, last_name, avatar_url,
      assigned_to, created_at, created_by,
      country_id, city_id
    } = candidate;

    const { columnByType, userNameByUserId } = this._candidatesState;

    const assignedToName:string = userNameByUserId(assigned_to?.id);
    const createdByName:string = userNameByUserId(created_by?.id);

    const dateString:string = DateUtil.formattedDate(created_at as DateStrOrNum);

    const flowStageName:string = candidate.interview_flow_stage?.name || '---';

    const hasFinal:boolean = !!candidate.candidate_allocations.find((allocation) => allocation.is_final);
    const hasProposed:boolean = candidate.candidate_allocations.length > (hasFinal ? 1 : 0);

    const country:Maybe<Common_Country> = this._candidateSiteSubsiteState.countryById(country_id);
    const city:Maybe<Common_City> = this._candidateSiteSubsiteState.cityById(city_id);

    return (
      <div
        className={styles.rowWrapper}
        onClick={this._onClickRow(candidate)}
      >
        <div className={cx(styles.col, styles.colName)}>

          <Avatar
            className={styles.avatar}
            url={avatar_url}
            size={36}
          />
          <Avatar
            className={styles.avatarMobile}
            url={avatar_url}
          />

          <div className={styles.name}>
            {
              (DebugUtil.isEnabled && (hasProposed || hasFinal)) &&
              <span style={{ color: '#00f', fontSize: 13, fontWeight: 400 }}>
                <span style={{ color: '#f00' }}>{candidate.id}</span> [
                {
                  hasProposed &&
                  <span style={{ color: '#00f' }}> {candidate.candidate_allocations.length}</span>
                }
                {
                  hasFinal &&
                  <span style={{ color: '#f00' }}> F</span>
                }
                &nbsp;] </span>
            }
            {first_name} {last_name}
            <div className={cx(styles.mobile, styles.mobilePositionGroups)}>
              {this._renderPositionGroups(candidate.candidate_position_groups)}
            </div>
          </div>
        </div>

        {
          columnByType(CandidatesColumnType.DATE_CREATED).isVisible &&
          <div className={cx(styles.col, styles.desktop, styles.colDateCreated)}>
            <span className={styles.dateCreated}>{dateString}</span>
            {/*<span className={styles.dateCreated}>{dateCreated}</span>*/}
            {/*<span className={styles.timeCreated}>{timeCreated}</span>*/}
          </div>
        }

        <NeedsPermission name={FlowPermissions.ViewPositionGroups}>
          {
            columnByType(CandidatesColumnType.POSITION_GROUPS).isVisible &&
            <div className={cx(styles.col, styles.desktop, styles.colPositionGroups)}>
              {this._renderPositionGroups(candidate.candidate_position_groups)}
            </div>
          }
        </NeedsPermission>

        {
          columnByType(CandidatesColumnType.STATUS).isVisible &&
          <div className={cx(styles.col, styles.colStatus)}>
            {this._renderCandidateStatus(candidate)}
            <div className={cx(styles.mobile, styles.mobileAssignedTo, styles.ellipsis)}>
              {assignedToName}
            </div>
          </div>
        }

        {
          columnByType(CandidatesColumnType.FLOW_STAGE).isVisible &&
          <div className={cx(styles.col, styles.desktop, styles.colFlowStage)}>
            {flowStageName}
          </div>
        }

        {
          columnByType(CandidatesColumnType.ASSIGNED_TO).isVisible &&
          <div className={cx(styles.col, styles.desktop, styles.colAssignedTo, styles.ellipsis)}>
            {assignedToName}
          </div>
        }

        {
          columnByType(CandidatesColumnType.CREATED_BY).isVisible &&
          <div className={cx(styles.col, styles.desktop, styles.colCreatedBy)}>
            {createdByName}
          </div>
        }

        {
          columnByType(CandidatesColumnType.SITE_SUBSITE).isVisible &&
          <div className={cx(styles.col, styles.desktop, styles.colSiteSubsite)}>
            {country?.name}
            {
              city &&
              <>
                {/*<Icon*/}
                {/*  className={styles.iconDot}*/}
                {/*  icon={IconNames.DOT}*/}
                {/*  size={8}*/}
                {/*/>*/}
                , {city.name}
              </>
            }
          </div>
        }

        <div
          className={cx(styles.col, styles.colMore)}
          onClick={this._onClickMoreMenu}
        >
          <ButtonMore
            className={cx(styles.mobile, styles.btnMore)}
            menuContent={<EmptyMenu />}
            disabled={true}
          />
          <ButtonMore
            className={cx(styles.desktop, styles.btnMore)}
            menuContent={<EmptyMenu />}
            disabled={true}
          />
        </div>

        {/*<div*/}
        {/*  className={styles.positionGroup}*/}
        {/*>*/}
        {/*  {*/}
        {/*    position &&*/}
        {/*    <Tag minimal={true} round={true}>*/}
        {/*      {position?.name}*/}
        {/*    </Tag>*/}
        {/*  }*/}
        {/*</div>*/}
      </div>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const {
      candidates, isCandidatesLoaded, isCandidatePageLoaded,
      currentPage, itemsPerPage, itemsTotal, candidatesListScrollYPosition
    } = this._candidatesState;

    if( !isCandidatesLoaded )
    {
      return (
        <Spinner
          noIcon
          withWave
          withWrapper
          wrapperClassName={styles.spinnerWrapper}
        />
      );
    }

    return (
      <>
        <div className={styles.listWrapper}>
          <List
            header={<CandidatesListHeader />}
            headerClassName={styles.headerWrapper}
            items={candidates}
            itemRenderer={this._renderCandidate}
            noItemsTitle={'No candidates'}
            isLoading={!isCandidatePageLoaded}
            className={styles.listWrapper2}
            onScroll={this._onScroll}
            scrollPosition={candidatesListScrollYPosition}
          />
        </div>

        <ItemsCount
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          itemsTotal={itemsTotal}
        />

        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          itemsTotal={itemsTotal}
          onChange={this._candidatesController.setCurrentPage}
        />
      </>
    );
  }

  // ---------------------------------------------------------
}
