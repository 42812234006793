import { BlankState } from '@flow/common/components/form/BlankState';
import { ListWrapper } from '@flow/common/components/page/ListWrapper';
import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';

import classNames from 'classnames/bind';
import * as React from 'react';
import { Spinner } from '../form/Spinner';
import styles from './List.module.less';

const cx = classNames.bind(styles);

interface Props<T>
{
  className?:string;
  items:Array<T>;
  itemRenderer:(item:T, index:number, items:Array<T>) => React.ReactNode | null;
  itemClassName?:string;

  header?:React.ReactNode;
  headerClassName?:string;

  noItemsTitle?:string;
  noItemsClassName?:string;

  isLoading?:boolean;

  onScroll?:React.UIEventHandler<HTMLDivElement>;
  scrollPosition?:number;
}

@component
export class List<T> extends React.Component<Props<T>>
{
  // ----------------------------------------------------

  @bind
  private _renderItem(item:T, index:number, items:Array<T>):React.ReactNode
  {
    const { itemRenderer, itemClassName } = this.props;

    return (
      <div className={cx(styles.item, itemClassName)} key={index}>

        {itemRenderer(item, index, items)}

      </div>
    );
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const {
      className,
      items,
      header,
      headerClassName,
      noItemsTitle,
      noItemsClassName,
      isLoading,
      onScroll,
      scrollPosition
    } = this.props;

    return (
      <ListWrapper
        className={className}
        header={header}
        headerClassName={headerClassName}
        onScroll={onScroll}
        scrollPosition={scrollPosition}
        contentClassName={cx({ isLoading })}
        content={(
          <>
            {
              (items.length === 0 && noItemsTitle) &&
              <BlankState title={noItemsTitle} className={cx(styles.noItemsTitle, noItemsClassName)} />
            }
            <div>
              {items.map(this._renderItem)}
            </div>
            {
              isLoading &&
              <Spinner
                noIcon
                withWave
                withWrapper
                wrapperClassName={styles.spinnerWrapper}
              />
            }
          </>
        )}
      />
    );
  }

  // ----------------------------------------------------
}
