import { Toolbar } from '@flow/common/components/page/Toolbar';
import { component } from '@flow/dependency-injection';
import React from 'react';
import { SelectCustomer } from './SelectCustomer';
import styles from './StaffBoardToolbar.module.less';

// const cx = classNames.bind(styles);

@component
export class StaffBoardToolbar extends React.Component
{
  public render():React.ReactNode
  {
    return (
      <Toolbar
        className={styles.wrapper}
        title={''}

        leftClassName={styles.positionLeft}
        leftElements={
          <>
            <SelectCustomer />
          </>
        }
      />
    );
  }
}
