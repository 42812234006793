import type { IFilter, IFilterValue } from '@flow/common/components/filters/Filter';
import { Filter } from '@flow/common/components/filters/Filter';
import type { IFilterSiteSubsiteValue } from '@flow/common/components/filters/siteSubsite/FilterSiteSubsite';
import { FilterSiteSubsiteSettings } from '@flow/common/components/filters/siteSubsite/FilterSiteSubsite';
import { FilterStatusSettings, IFilterStatusValue } from '@flow/common/components/filters/staffed/FilterStatus';
import { state } from '@flow/dependency-injection';
import { toJS } from 'mobx';

// ----------------------------------------------------

@state
export class StaffFiltersState
{
  public filters:Array<IFilter<IFilterValue>> = StaffFiltersStateItems;
}

// ----------------------------------------------------

// export const StaffFiltersState:Array<IFilter<IFilterValue>> = [
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StaffFiltersStateItems:Array<IFilter<any>> = [

  // new Filter<IFilterTimeValue>(
  //   {
  //     ...FilterTimeSettings,
  //     name: 'Date Created',
  //     type: FilterType.DATE_CREATED
  //   }),

  // new Filter<IFilterMultiSelectValue>(
  //   {
  //     ...FilterMultiSelectSettings,
  //     name: 'Position Group',
  //     type: FilterType.POSITION_GROUP
  //   }),

  new Filter<IFilterSiteSubsiteValue>(FilterSiteSubsiteSettings),

  new Filter<IFilterStatusValue>(FilterStatusSettings)

  // new Filter<IFilterMultiSelectValue>(
  //   {
  //     ...FilterMultiSelectSettings,
  //     name: 'Assigned To',
  //     type: FilterType.ASSIGNED_TO
  //   }),

  // new Filter<IFilterMultiSelectValue>(
  //   {
  //     ...FilterMultiSelectSettings,
  //     name: 'Created By',
  //     type: FilterType.CREATED_BY
  //   })

  // new Filter({ ...FilterTodoSettings, name: 'Staff Role', type: FilterType.STAFF_ROLE }),
  // new Filter({ ...FilterTodoSettings, name: 'Position', type: FilterType.POSITION }),
  // new Filter({ ...FilterTodoSettings, name: 'Customer', type: FilterType.CUSTOMER }),
  // new Filter({ ...FilterTodoSettings, name: 'Customer Team', type: FilterType.CUSTOMER_TEAM }),
  // new Filter({ ...FilterTodoSettings, name: 'Modified By', type: FilterType.MODIFIED_BY })
];

export const StaffFiltersDefaultState:Array<IFilter<IFilterValue>> = StaffFiltersStateItems.map(f => toJS(f));

// ----------------------------------------------------

