import { controller, di } from '@flow/dependency-injection';
import {
  SettingsPageState
} from '@flow/modules/settings/settingsTemplatePage/SettingsPageState';
import { action, runInAction } from 'mobx';

@controller
export class SettingsPageController
{
  @di private _settingsPageState!:SettingsPageState;

// ----------------------------------------------------

  @action.bound
  public initFromLocalStorage():void
  {
    // const lsState:Partial<SettingsPageState> = LocalStorageUtil.getItem(SettingsPageState_LS_KEY);

    // this._settingsPageState.xxx = lsState?.xxx || xxx.default;

    // const { disposers } = this._settingsPageState;

    // Array.isArray(disposers) && disposers.push(reaction(
    //   () =>
    //   {
    //     const {
    //       xxx
    //     } = this._settingsPageState;
    //
    //     return {
    //       xxx
    //     };
    //   },
    //   (lsValue:Partial<SettingsPageState>) =>
    //   {
    //     console.log('%c PositionsState: lsValue =', 'background:#0ff;color:#000;', lsValue);
    //     LocalStorageUtil.setItem(SettingsPageState_LS_KEY, lsValue);
    //   }
    // ));
  }

  // ----------------------------------------------------

  @action.bound
  public init():void
  {
    this._settingsPageState.isLoaded = false;

    // const result:ApolloQueryResult<xxxQuery> =
    //   await this._commonController.query<xxxQuery,
    //     xxxQueryVariables>({
    //     query: xxxDocument,
    //     variables: {}
    //   });

    // console.log('%c init result =', 'background:#0f0;color:#000;', result);

    runInAction(() =>
    {
      // const {
      //   xxx
      // } = result.data;

      // this._settingsPageState.xxx = xxx as Array<X_X_X>;

      this._settingsPageState.isLoaded = true;
    });
  }

  // ----------------------------------------------------
}
