import { component } from '@flow/dependency-injection';

import classNames from 'classnames/bind';
import React from 'react';
import styles from './Dialog.module.less';

const cx = classNames.bind(styles);

interface Props
{
  className?:string;

  label?:string;
  labelClassName?:string;

  labelOnSingleLine?:boolean;
}

@component
export class DialogRow extends React.Component<Props>
{
  public render():React.ReactNode
  {
    const {
      className, label, labelClassName, children,
      labelOnSingleLine = false
    } = this.props;

    return (
      <div className={cx(styles.row, className, { labelOnSingleLine }, 'test-dialog-row')}>

        {
          label &&
          <div className={cx(styles.label, labelClassName)}>
            {label}
          </div>
        }

        <div className={cx(styles.inputWrapper, 'test-input-field')}>
          {children}
        </div>

      </div>
    );
  }
}

