import type * as Types from './types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type LoginMutationVariables = Types.Exact<{
  code:Types.Scalars['String'];
}>;


export type LoginMutation = { action_login?:{ id?:number | null; accessToken?:string | null; email:string; firstName:string; lastName:string; avatar?:string | null; roles:Array<string>; hasRefreshToken:boolean; } | null; };

export type TestLoginMutationVariables = Types.Exact<{
  code:Types.Scalars['String'];
}>;


export type TestLoginMutation = { action_login_test?:{ id?:number | null; accessToken?:string | null; email:string; firstName:string; lastName:string; avatar?:string | null; roles:Array<string>; hasRefreshToken:boolean; } | null; };

export type GetUserProfileQueryVariables = Types.Exact<Record<string, never>>;


export type GetUserProfileQuery = { action_profile?:{ id?:number | null; email:string; firstName:string; lastName:string; avatar?:string | null; roles:Array<string>; } | null; };

export type CandidateFetchLinkedInImageMutationVariables = Types.Exact<{
  candidateId:Types.Scalars['Int'];
}>;


export type CandidateFetchLinkedInImageMutation = { action_candidate_fetch_linkedin_image?:{ avatar_url:string; id:number; } | null; };

export type CandidateUploadCvMutationVariables = Types.Exact<{
  candidateId:Types.Scalars['Int'];
  mimeType:Types.Scalars['String'];
  fileContent:Types.Scalars['String'];
}>;


export type CandidateUploadCvMutation = { action_candidate_upload_cv?:{ id:number; cv_google_doc_ref:string; } | null; };


export const LoginDocument = gql`
    mutation Login($code: String!) {
  action_login(code: $code) {
    id
    accessToken
    email
    firstName
    lastName
    avatar
    roles
    hasRefreshToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const TestLoginDocument = gql`
    mutation TestLogin($code: String!) {
  action_login_test(code: $code) {
    id
    accessToken
    email
    firstName
    lastName
    avatar
    roles
    hasRefreshToken
  }
}
    `;
export type TestLoginMutationFn = Apollo.MutationFunction<TestLoginMutation, TestLoginMutationVariables>;
export type TestLoginMutationResult = Apollo.MutationResult<TestLoginMutation>;
export type TestLoginMutationOptions = Apollo.BaseMutationOptions<TestLoginMutation, TestLoginMutationVariables>;
export const GetUserProfileDocument = gql`
    query GetUserProfile {
  action_profile {
    id
    email
    firstName
    lastName
    avatar
    roles
  }
}
    `;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const CandidateFetchLinkedInImageDocument = gql`
    mutation CandidateFetchLinkedInImage($candidateId: Int!) {
  action_candidate_fetch_linkedin_image(candidateId: $candidateId) {
    avatar_url
    id
  }
}
    `;
export type CandidateFetchLinkedInImageMutationFn = Apollo.MutationFunction<CandidateFetchLinkedInImageMutation, CandidateFetchLinkedInImageMutationVariables>;
export type CandidateFetchLinkedInImageMutationResult = Apollo.MutationResult<CandidateFetchLinkedInImageMutation>;
export type CandidateFetchLinkedInImageMutationOptions = Apollo.BaseMutationOptions<CandidateFetchLinkedInImageMutation, CandidateFetchLinkedInImageMutationVariables>;
export const CandidateUploadCvDocument = gql`
    mutation CandidateUploadCV($candidateId: Int!, $mimeType: String!, $fileContent: String!) {
  action_candidate_upload_cv(
    candidateId: $candidateId
    mimeType: $mimeType
    fileContentBase64: $fileContent
  ) {
    id
    cv_google_doc_ref
  }
}
    `;
export type CandidateUploadCvMutationFn = Apollo.MutationFunction<CandidateUploadCvMutation, CandidateUploadCvMutationVariables>;
export type CandidateUploadCvMutationResult = Apollo.MutationResult<CandidateUploadCvMutation>;
export type CandidateUploadCvMutationOptions = Apollo.BaseMutationOptions<CandidateUploadCvMutation, CandidateUploadCvMutationVariables>;
export const namedOperations = {
  Query: {
    GetUserProfile: 'GetUserProfile'
  },
  Mutation: {
    Login: 'Login',
    TestLogin: 'TestLogin',
    CandidateFetchLinkedInImage: 'CandidateFetchLinkedInImage',
    CandidateUploadCV: 'CandidateUploadCV'
  }
}