import { ButtonOrderBy } from '@flow/common/components/elements/ButtonOrderBy';
import { component, di } from '@flow/dependency-injection';
import { StaffFiltersController } from '@flow/modules/staffing/staff/StaffFiltersController';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { action } from 'mobx';
import type React from 'react';
import { Component } from 'react';
import type { IStaffColumn } from '../../StaffColumnsState';
import { StaffColumnType } from '../../StaffColumnsState';
import { StaffState } from '../../StaffState';
import { StaffColumnsSelector } from './filters/StaffColumnsSelector';

import styles from './StaffList.module.less';

const cx = classNames.bind(styles);

@component
export class StaffListHeader extends Component
{
  @di private _staffState!:StaffState;
  @di private _staffFiltersController!:StaffFiltersController;

  // ---------------------------------------------------------

  @action.bound
  public _onClickOrderBy(columnType:StaffColumnType)
  {
    return (/* e:React.MouseEvent<HTMLElement> */):void =>
    {
      this._staffFiltersController.orderByNextState(columnType);
    };
  }

  // ---------------------------------------------------------

  @bind
  private _renderColumn(columnType:StaffColumnType, className:string):React.ReactNode | null
  {
    const { columnByType } = this._staffState;

    const column:IStaffColumn = columnByType(columnType);

    if( !column.alwaysVisible && !column.isVisible ) return null;

    return (
      <div className={cx(styles.col, className)}>
        <ButtonOrderBy
          text={column.name}
          isOrderable
          orderBy={column.orderBy}
          onClick={this._onClickOrderBy(columnType)}
        />
      </div>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    return (
      <div className={styles.header}>

        {this._renderColumn(StaffColumnType.NAME, String(styles.colName))}
        {this._renderColumn(StaffColumnType.POSITIONS, String(styles.colCustomer))}
        {this._renderColumn(StaffColumnType.STATUS, String(styles.colStatus))}
        {this._renderColumn(StaffColumnType.SITE_SUBSITE, String(styles.colSiteSubsite))}

        <div className={cx(styles.col, styles.colMore)}>
          <StaffColumnsSelector />
        </div>
      </div>
    );
  }

  // ---------------------------------------------------------
}
