import { MenuItem, Position } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { CommonController } from '@flow/common/CommonController';
import { Button, ButtonSize } from '@flow/common/components/form/Button';
import { RoleController } from '@flow/common/controllers/RoleController';
import { component, di } from '@flow/dependency-injection';
import { StaffMemberController } from '@flow/modules/staffing/staff/StaffMemberController';
import { StaffMemberState } from '@flow/modules/staffing/staff/StaffMemberState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './MoreActionsSelector.module.less';

const cx = classNames.bind(styles);

enum MoreActionType
{
  CHANGE_STATUS = 'CHANGE_STATUS',
  CHANGE_STAFF_ROLE = 'CHANGE_STAFF_ROLE',
  CANDIDATE_PROFILE = 'CANDIDATE_PROFILE',
}

interface MoreActionItem
{
  id:MoreActionType;
  title:string;
  disabled?:boolean;
}

const moreActionItems:Array<MoreActionItem> = [
  {
    id: MoreActionType.CHANGE_STATUS,
    title: 'Change status'
  },
  {
    id: MoreActionType.CHANGE_STAFF_ROLE,
    title: 'Change staff role',
    disabled: true
  },
  {
    id: MoreActionType.CANDIDATE_PROFILE,
    title: 'Candidate profile'
  }
];

@component
export class MoreActionsSelector extends React.Component
{
  @di private _staffMemberState!:StaffMemberState;
  @di private _staffMemberController!:StaffMemberController;

  @di private _roleController!:RoleController;
  @di private _commonController!:CommonController;

  // ----------------------------------------------------

  @bind
  private _getMenuItems():Array<MoreActionItem>
  {
    return moreActionItems.filter((item:MoreActionItem) =>
    {
      const { isLeaving, staffMember } = this._staffMemberState;

      if( isLeaving && item.id === MoreActionType.CHANGE_STAFF_ROLE ) return false;

      if( item.id === MoreActionType.CANDIDATE_PROFILE && !staffMember?.candidate )
      {
        return false;
      }

      return true;
    });
  }

  // ----------------------------------------------------

  @bind
  private _itemRenderer(
    item:MoreActionItem,
    { handleClick, modifiers }:IItemRendererProps):JSX.Element | null
  {
    if( !modifiers.matchesPredicate ) return null;

    const { isLeaving, staffMember } = this._staffMemberState;

    if( isLeaving && item.id === MoreActionType.CHANGE_STAFF_ROLE ) return null;

    const isDisabled:boolean = item.disabled || false;

    if( item.id === MoreActionType.CANDIDATE_PROFILE && !staffMember?.candidate )
    {
      // isDisabled = isDisabled || !staffMember?.candidate;
      return null;
    }

    return (
      <MenuItem
        key={item.id}
        className={styles.menuItemMoreActions}
        text={item.title}
        onClick={handleClick}
        active={modifiers.active}
        disabled={isDisabled}
      />
    );
  }

  // ----------------------------------------------------

  @bind
  private _onItemSelect(moreAcction:MoreActionItem):void
  {
    const { staffMember } = this._staffMemberState;

    switch( moreAcction.id )
    {
      case MoreActionType.CHANGE_STATUS:
        this._staffMemberController.setChangeStatusDialog(true);
        break;

      case MoreActionType.CHANGE_STAFF_ROLE:
        break;

      case MoreActionType.CANDIDATE_PROFILE:
        this._commonController.goToCandidate(staffMember?.candidate?.id);
        break;
    }
  }

  // ----------------------------------------------------

  public render():ReactNode | null
  {
    const { isArchived } = this._staffMemberState;

    if( isArchived ) return null;

    // const isDisabled:boolean = !this._roleController.hasPermission(FlowPermissions.ChangeCandidateFlowStage);

    const isDisabled:boolean = false;

    return (
      <Select
        className={styles.selectMore}
        items={this._getMenuItems()}
        itemRenderer={this._itemRenderer}
        onItemSelect={this._onItemSelect}
        filterable={false}
        disabled={isDisabled}
        popoverProps={{
          minimal: true,
          // portalClassName: styles.popoverOverlay,
          // popoverClassName: styles.popover,
          usePortal: false,
          // fill: true
          position: Position.BOTTOM_RIGHT
        }}
      >
        <Button
          className={cx(styles.btnMoreActions, { isDisabled })}
          text={'Actions'}
          disabled={isDisabled}
          minimal={true}
          outlined={true}
          // intent={Intent.NONE}
          // intent={isDisabled ? Intent.NONE : Intent.PRIMARY}
          size={ButtonSize.LARGE}
        />
      </Select>
    );
  }

  // ----------------------------------------------------
}
