import type { TabId } from '@blueprintjs/core';
import { InfoPanel } from '@flow/common/components/elements/InfoPanel';
import { ConfirmationDialog } from '@flow/common/components/page/ConfirmationDialog';
import { DialogWarningMessage } from '@flow/common/components/page/DialogWarningMessage';
import { Intent } from '@flow/common/components/types/Types';
import { AuthState } from '@flow/common/controllers/AuthState';
import type { Maybe } from '@flow/common/models/Types';
import type { Common_Scheduled_Event } from '@flow/data-access/lib/types/graphql.generated';
import { Recruiting_Candidate_Note_Type_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { CommunicationHistoryTabs } from '@flow/modules/recruiting/candidates/components/candidate/communication/CommunicationHistoryTabs';
import { CommunicationEventForm } from '@flow/modules/recruiting/candidates/components/candidate/communication/eventForm/CommunicationEventForm';
import { CommunicationEventView } from '@flow/modules/recruiting/candidates/components/candidate/communication/eventForm/CommunicationEventView';
import { ScheduleController } from '@flow/modules/recruiting/schedule/ScheduleController';
import type { ScheduleEventForEdit } from '@flow/modules/recruiting/schedule/ScheduleState';
import { ScheduleState } from '@flow/modules/recruiting/schedule/ScheduleState';
import bind from 'bind-decorator';
import { runInAction } from 'mobx';
import React from 'react';
import { CandidateController } from '../../CandidateController';
import { CandidateState } from '../../CandidateState';
import styles from './CandidatePanelCommunication.module.less';
import { CommunicationContent } from './communication/CommunicationContent';
import { CommunicationNoteForm } from './communication/CommunicationNoteForm';
import { CommunicationToolbar } from './communication/CommunicationToolbar';

@component
export class CandidatePanelCommunication extends React.Component
{
  @di private _candidateController!:CandidateController;
  @di private _candidateState!:CandidateState;
  @di private _scheduleState!:ScheduleState;
  @di private _scheduleController!:ScheduleController;
  @di private _authState!:AuthState;

  // ---------------------------------------------------------

  private _panelRef:React.RefObject<InfoPanel> = React.createRef();

  // ---------------------------------------------------------

  @bind
  private _renderNoteForm():React.ReactNode | null
  {
    if( !this._candidateState.isAddingNote ) return null;

    return (
      <CommunicationNoteForm
        content={''}
        type={Recruiting_Candidate_Note_Type_Enum.General}
        onSave={(type, content):void =>
        {
          this._candidateController.addNote(type as Recruiting_Candidate_Note_Type_Enum, content as string);
        }}
        onCancel={():void =>
        {
          runInAction(() => this._candidateState.isAddingNote = false);
        }}
      />
    );
  }

  // ----------------------------------------------------

  @bind
  private _renderEventForm(eventForEdit:Maybe<ScheduleEventForEdit>):React.ReactNode | null
  {
    if( !eventForEdit ) return null;

    const isUpcoming:boolean = eventForEdit.event.is_scheduled || false;
    const isDraft:boolean = !isUpcoming;

    return (
      <CommunicationEventForm
        eventForEdit={eventForEdit}
        onSave={():void =>
        {
          this._candidateController.scheduleEvent(eventForEdit.event.id, isUpcoming);
        }}
        onUpdate={():void =>
        {
          if( isDraft )
          {
            this._candidateController.updateDraftEvent();
          }
        }}
        onCancel={():void =>
        {
          this._scheduleController.removeEventForEdit(eventForEdit.event.id);

          if( isDraft )
          {
            this._candidateController.updateDraftScheduleCall(false);
          }
        }}
        onRevertChanges={():void =>
        {
          if( isUpcoming )
          {
            this._scheduleController.removeEventForEdit(eventForEdit.event.id);
          }
        }}
      />
    );
  }

  // ----------------------------------------------------

  @bind
  private _renderDraftEvent():React.ReactNode | null
  {
    const { draftEvent, eventsForEdit } = this._scheduleState;

    if( !this._candidateState.isDraftEventOpen || !draftEvent || !draftEvent.id ) return null;

    const eventForEdit:Maybe<ScheduleEventForEdit> = eventsForEdit.get(draftEvent.id);

    if( !eventForEdit ) return null;

    return (
      <React.Fragment key={draftEvent.id}>
        {this._renderEventForm(eventForEdit)}
        {
          // <CommunicationEventForm
          //   eventForEdit={eventForEdit}
          //   onSave={():void =>
          //   {
          //     this._candidateController.scheduleEvent(eventForEdit.event.id, false);
          //   }}
          //   onUpdate={():void =>
          //   {
          //     this._candidateController._updateDraftEvent();
          //   }}
          //   onCancel={():void =>
          //   {
          //     this._scheduleController.removeEventForEdit(eventForEdit.event.id);
          //     this._candidateController.updateDraftScheduleCall(false);
          //   }}
          // />
        }
      </React.Fragment>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderOtherEvents():React.ReactNode
  {
    return this._candidateState.callsToRender.map((event:Common_Scheduled_Event) =>
    {
      const { eventsForEdit } = this._scheduleState;

      const eventForEdit:Maybe<ScheduleEventForEdit> = eventsForEdit.get(event.id);

      return (
        <React.Fragment key={event.id}>
          {this._renderEventForm(eventForEdit)}
          {
            // <CommunicationEventForm
            //   eventForEdit={eventForEdit}
            //   onSave={():void =>
            //   {
            //     this._candidateController.scheduleEvent(event.id, event.is_scheduled || false);
            //   }}
            //   onCancel={():void =>
            //   {
            //     this._scheduleController.removeEventForEdit(event.id);
            //   }}
            //   onRevertChanges={():void =>
            //   {
            //     this._scheduleController.removeEventForEdit(event.id);
            //   }}
            // />
          }
          {
            !eventForEdit &&
            <CommunicationEventView
              event={event}
              scrollTop={():unknown => this._panelRef.current?.scrollTop()}
            />
          }
        </React.Fragment>
      );
    });
  }

  // ---------------------------------------------------------

  @bind
  private _renderRemoveCallConfirmDialog():React.ReactNode
  {
    const { isDeletingCall } = this._candidateState;

    return (
      <ConfirmationDialog
        title={'Remove the scheduled call?'}
        confirmButtonText={'Confirm'}
        isOpen={this._candidateState.isDeleteCallDialogOpen}
        primaryButtonIntent={Intent.PRIMARY}
        isPrimaryButtonDisabled={isDeletingCall}
        isLoading={isDeletingCall}
        onConfirm={async ():Promise<void> =>
        {
          const { deletingEventId, isDeletingCall } = this._candidateState;
          if( !deletingEventId || isDeletingCall ) return;

          runInAction(() =>
          {
            this._candidateState.isDeletingCall = true;
          });

          await this._candidateController.cancelScheduledEvent(deletingEventId);

          runInAction(() =>
          {
            this._candidateState.isDeleteCallDialogOpen = false;
            this._candidateState.deletingEventId = null;
            this._candidateState.isDeletingCall = false;
          });
        }}
        onClose={():void =>
        {
          runInAction(() =>
          {
            this._candidateState.isDeleteCallDialogOpen = false;
            this._candidateState.deletingEventId = null;
          });
        }}
      >
        <DialogWarningMessage
          text={'All the call details will be lost'}
        />
      </ConfirmationDialog>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    return (
      <InfoPanel
        panelId={'scrollableDiv'}
        className={styles.panel}
        ref={this._panelRef}
      >
        {/* div -> wrapper for sticky schedule toolbar & history tabs */}
        <div>

          <CommunicationToolbar
            scrollTop={():unknown => this._panelRef.current?.scrollTop()}
          />
          {this._renderNoteForm()}

          {this._renderDraftEvent()}
          {this._renderOtherEvents()}

          <CommunicationHistoryTabs
            communicationTabId={this._candidateState.communicationTabId}
            onChange={(tabId:TabId):void =>
            {
              runInAction(() => this._candidateState.communicationTabId = tabId);
            }}
          />

          <CommunicationContent />

        </div>

        {this._renderRemoveCallConfirmDialog()}

      </InfoPanel>
    );
  }

  // ---------------------------------------------------------
}
