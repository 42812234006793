import type { ApolloQueryResult, FetchResult } from '@apollo/client';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import type { IInlineEditorField } from '@flow/common/components/form/InlineEditor';
import { AuthState } from '@flow/common/controllers/AuthState';
import { DateUtil } from '@flow/common/utils/DateUtil';
import { SortUtil } from '@flow/common/utils/SortUtil';
import type {
  StaffMemberAllocationPositionsQuery,
  StaffMemberAllocationPositionsQueryVariables,
  StaffMemberQuery,
  StaffMemberQueryVariables,
  UpdateStaffMemberMutation,
  UpdateStaffMemberMutationVariables,
  UpdateStaffUserMutation,
  UpdateStaffUserMutationVariables
} from '@flow/data-access/lib/staffMember.generated';
import {
  StaffMemberAllocationPositionsDocument,
  StaffMemberDocument,
  UpdateStaffMemberDocument,
  UpdateStaffUserDocument
} from '@flow/data-access/lib/staffMember.generated';
import type {
  Common_City,
  Common_Country,
  Common_User,
  Common_User_Set_Input,
  Staffing_Customer_Team_Slot,
  Staffing_Staff,
  Staffing_Staff_Set_Input
} from '@flow/data-access/lib/types/graphql.generated';
import { Staffing_Staff_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { StaffMemberAllocationState } from '@flow/modules/staffing/staff/StaffMemberAllocationState';
import { StaffMemberState } from '@flow/modules/staffing/staff/StaffMemberState';
import { StaffSiteSubsiteState } from '@flow/modules/staffing/staff/StaffSiteSubsiteState';
import { StaffState } from '@flow/modules/staffing/staff/StaffState';
import { action, runInAction, toJS } from 'mobx';

// ----------------------------------------------------

export enum StaffMemberFieldName
{
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',

  FIRST_NAME_I18N = 'FIRST_NAME_I18N',
  LAST_NAME_I18N = 'LAST_NAME_I18N',
  MIDDLE_NAME_I18N = 'MIDDLE_NAME_I18N',

  LINKED_IN = 'LINKED_IN',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  SITE_SUBSITE = 'SITE_SUBSITE',

  HIRE_DATE = 'HIRE_DATE',

  // ASSIGNED_TO = 'ASSIGNED_TO',
  // POSITION_GROUPS = 'POSITION_GROUPS',
}

// ----------------------------------------------------

@controller
export class StaffMemberController
{
  @di private _authState!:AuthState;
  @di private _commonState!:CommonState;
  @di private _staffState!:StaffState;
  @di private _staffMemberState!:StaffMemberState;
  @di private _staffSiteSubsiteState!:StaffSiteSubsiteState;

  @di private _staffMemberAllocationState!:StaffMemberAllocationState;
  // @di private _staffMemberAllocationController!:StaffMemberAllocationController;

  @di private _commonController!:CommonController;

// ----------------------------------------------------

  @action.bound
  public async initStaffMember():Promise<void>
  {
    // this.setViewCandidateId(null);
    // this.setUserFilter(null);

    const { viewStaffId } = this._staffState;

    if( !viewStaffId ) return;

    this._staffMemberState.pageNotFound = false;

    const result:ApolloQueryResult<StaffMemberQuery> =
      await this._commonController.query<StaffMemberQuery,
        StaffMemberQueryVariables>({
        query: StaffMemberDocument,
        variables: {
          staffId: viewStaffId
        }
      });

    console.log('%c initStaffMember result =', 'background:#f60;color:#000;', result);

    if( !result.data.staffing_staff_by_pk )
    {
      runInAction(() => this._staffMemberState.pageNotFound = true);
      return;
    }

    runInAction(() =>
    {
      const {
        staffing_staff_by_pk,
        common_country,
        common_city,
        staffing_customer_team_slot
      } = result.data;

      this._staffMemberState.staffMember = staffing_staff_by_pk as Staffing_Staff;
      this._staffMemberState.staffMemberSlots = (staffing_customer_team_slot as Array<Staffing_Customer_Team_Slot>)
        .slice()
        .sort((a:Staffing_Customer_Team_Slot, b:Staffing_Customer_Team_Slot) =>
        {
          return SortUtil.byCustomerAndTeam(a.position, b.position);
        });

      this._staffSiteSubsiteState.countries = common_country as Array<Common_Country>;
      this._staffSiteSubsiteState.cities = common_city as Array<Common_City>;

      this._staffMemberState.isStaffMemberLoaded = true;

      this.initStaffMemberAllocationPositions();
    });
  }

  // ----------------------------------------------------

  @action.bound
  public async initStaffMemberAllocationPositions():Promise<void>
  {
    const { viewStaffId } = this._staffState;

    if( !viewStaffId ) return;

    const result:ApolloQueryResult<StaffMemberAllocationPositionsQuery> =
      await this._commonController.query<StaffMemberAllocationPositionsQuery,
        StaffMemberAllocationPositionsQueryVariables>({
        query: StaffMemberAllocationPositionsDocument
      });

    console.log('%c initStaffMemberAllocationPositions result =', 'background:#f60;color:#000;', result);

    runInAction(() =>
    {
      const {
        recruiting_position
      } = result.data;

      this._staffMemberAllocationState.positions = recruiting_position as unknown as Array<Recruiting_Position_Ex>;

      this._staffMemberAllocationState.positions.forEach((position:Recruiting_Position_Ex) =>
      {
        position.customer_team_slots.forEach((slot:Staffing_Customer_Team_Slot) =>
        {
          if( !slot.position ) slot.position = position;

          // if( slot.next_candidate_id === viewCandidateId )
          //   this._candidateAllocationState.currentFinalSlots.push(slot);
        });
      });

      // this._staffMemberAllocationState.isStaffMemberAllocationPositionsLoaded = true;
    });
  }

  // ----------------------------------------------------

  public async addNote(content:string):Promise<void>
  {
    // const userId = this._authState.user?.id;
    const staffMemberId = this._staffState.viewStaffId;

    console.log('%c addNote for staffMemberId =', 'background:#0f0;color:#000;', staffMemberId, content);

    return new Promise(() =>
    {
      return;
    });

    // await this._commonController.mutate<AddCandidateNoteMutation, AddCandidateNoteMutationVariables>({
    //   mutation: AddCandidateNoteDocument,
    //   variables: {
    //     object: {
    //       user_id: userId,
    //       content: content,
    //       candidate_id: staffMemberId
    //     }
    //   }
    // });
    //
    // runInAction(() => this._staffMemberState.isAddingNote = false);
  }

  // ----------------------------------------------------

  @action.bound
  public async onChangeStaffMemberField(newValues:Record<string, IInlineEditorField>):Promise<void>
  {
    const { staffMember } = this._staffMemberState;
    console.log('%c onChangeStaffMemberField newValue =', 'background:#0f0;color:#000;', newValues);

    if( !staffMember ) return;

    const newValuesKeys:Array<string> = Object.keys(newValues);

    if( !newValuesKeys.length ) return; // TODO ERROR ?

    const firstNameInNewValues:string = Object.keys(newValues)[0];

    const staffMemberFields:Staffing_Staff_Set_Input = {};
    const staffUserFields:Common_User_Set_Input = {};

    const getStrValue = (name:string):string => String(newValues[name]?.value || '').toString().trim();
    // const getNumValue = (name:string):number | null => (newValues[name]?.value as number) || null;
    // const getArrayValue = (name:string):Array<number> => (newValues[name]?.value as Array<number>) || [];

    let countryId:string = '';
    let cityId:string = '';

    let isStaffTable:boolean = false;
    let isUserTable:boolean = false;

    switch( firstNameInNewValues )
    {
      case StaffMemberFieldName.FIRST_NAME:
      case StaffMemberFieldName.LAST_NAME:
        staffUserFields.first_name = getStrValue(StaffMemberFieldName.FIRST_NAME);
        staffUserFields.last_name = getStrValue(StaffMemberFieldName.LAST_NAME);
        isUserTable = true;
        break;

      case StaffMemberFieldName.FIRST_NAME_I18N:
      case StaffMemberFieldName.LAST_NAME_I18N:
      case StaffMemberFieldName.MIDDLE_NAME_I18N:
        staffMemberFields.first_name_i18n = getStrValue(StaffMemberFieldName.FIRST_NAME_I18N);
        staffMemberFields.last_name_i18n = getStrValue(StaffMemberFieldName.LAST_NAME_I18N);
        staffMemberFields.middle_name_i18n = getStrValue(StaffMemberFieldName.MIDDLE_NAME_I18N);
        isStaffTable = true;
        break;

      case StaffMemberFieldName.LINKED_IN:
        staffMemberFields.linkedin_profile_url = getStrValue(StaffMemberFieldName.LINKED_IN);
        isStaffTable = true;
        break;

      case StaffMemberFieldName.PHONE:
        staffMemberFields.phone = getStrValue(StaffMemberFieldName.PHONE);
        isStaffTable = true;
        break;

      // case StaffMemberFieldName.HIRE_DATE:
      //   staffMemberFields.hire_date = getStrValue(StaffMemberFieldName.HIRE_DATE);
      //   isStaffTable = true;
      //   break;

      case StaffMemberFieldName.EMAIL:
        staffUserFields.email = getStrValue(StaffMemberFieldName.EMAIL);
        isUserTable = true;
        break;

      case StaffMemberFieldName.SITE_SUBSITE:
        [countryId, cityId] = getStrValue(StaffMemberFieldName.SITE_SUBSITE).split(':');
        staffMemberFields.country_id = parseInt(countryId) || null;
        staffMemberFields.city_id = parseInt(cityId) || null;
        isStaffTable = true;
        break;
    }

    if( isStaffTable )
    {
      const variables:UpdateStaffMemberMutationVariables = {
        staffId: staffMember.id,
        staffMemberFields: staffMemberFields
      };

      console.log('%c !!! variables MEMBER =', 'background:#ff0;color:#000;', variables);
      return this._updateStaffMember(variables);
    }
    else if( isUserTable )
    {
      const variables:UpdateStaffUserMutationVariables = {
        userId: staffMember.user.id,
        staffUserFields: staffUserFields
      };

      console.log('%c !!! variables USER =', 'background:#ff0;color:#000;', variables);
      return this._updateStaffUser(variables);
    }
  }

  @action.bound
  private async _updateStaffMember(variables:UpdateStaffMemberMutationVariables):Promise<void>
  {
    // return new Promise(() =>
    // {
    //   return;
    // });

    const result:FetchResult<UpdateStaffMemberMutation> =
      await this._commonController.mutate<UpdateStaffMemberMutation,
        UpdateStaffMemberMutationVariables>({
        mutation: UpdateStaffMemberDocument,
        variables
      });

    console.log('%c result =', 'background:#f60;color:#000;', result);

    runInAction(() =>
    {
      if( result && result.data )
      {
        // if( this._staffMemberState.staffMember !== null && variables.staffMemberFields.linkedin_profile_url )
        // {
        //   var newLinkedInUrl = variables.staffMemberFields.linkedin_profile_url;
        //
        //   if( this._staffMemberState.staffMember.linkedin_profile_url !== newLinkedInUrl )
        //   {
        //     const avatarUrl = await this._candidatesController.fetchCandidateProfilePicture(variables.id, newLinkedInUrl);
        //     this._staffMemberState.staffMember.avatar_url = avatarUrl;
        //   }
        // }

        this._staffMemberState.staffMember = result.data.update_staffing_staff_by_pk as Staffing_Staff;
      }
    });
  }

  @action.bound
  private async _updateStaffUser(variables:UpdateStaffUserMutationVariables):Promise<void>
  {
    // return new Promise(() =>
    // {
    //   return;
    // });

    const result:FetchResult<UpdateStaffUserMutation> =
      await this._commonController.mutate<UpdateStaffUserMutation,
        UpdateStaffUserMutationVariables>({
        mutation: UpdateStaffUserDocument,
        variables
      });

    console.log('%c result =', 'background:#f60;color:#000;', result);

    runInAction(() =>
    {
      if( result && result.data )
      {
        if( this._staffMemberState.staffMember )
        {
          this._staffMemberState.staffMember.user = result.data.update_common_user_by_pk as Common_User;
        }
      }
    });
  }

  // ----------------------------------------------------

  public getStaffMemberSlotById(slotId:number):Staffing_Customer_Team_Slot | undefined
  {
    const { staffMemberSlots } = this._staffMemberState;

    return staffMemberSlots?.find((slot:Staffing_Customer_Team_Slot) => slot.id === slotId);
  }

  // ----------------------------------------------------

  @action
  public setMoveToStaffDialog(isOpen:boolean):void
  {
    const { staffMember } = this._staffMemberState;

    if( !staffMember ) return;

    this._staffMemberState.isShowMoveToStaffDialog = isOpen;
  }

  @action.bound
  public async moveToStaff():Promise<void>
  {
    const { staffMember } = this._staffMemberState;
    console.log('%c moveToStaff =', 'background:#0f0;color:#000;', toJS(staffMember));

    if( !staffMember ) return;

    const staffMemberFields:Staffing_Staff_Set_Input = {};

    staffMemberFields.status_date = 'now()';
    staffMemberFields.status = Staffing_Staff_Status_Enum.Staffed;
    staffMemberFields.leaving_date = null;

    const variables:UpdateStaffMemberMutationVariables = {
      staffId: staffMember.id,
      staffMemberFields: staffMemberFields
    };

    this.setMoveToStaffDialog(false);

    console.log('%c !!! variables MEMBER =', 'background:#ff0;color:#000;', variables);
    return this._updateStaffMember(variables);
  }

  // ----------------------------------------------------

  @action
  public setChangeStatusDialog(isOpen:boolean):void
  {
    const { staffMember } = this._staffMemberState;

    if( !staffMember ) return;

    this._staffMemberState.isShowChangeStatusDialog = isOpen;

    if( isOpen )
    {
      const { status, leaving_date } = staffMember;

      this._staffMemberState.newStaffStatus = status;
      this._staffMemberState.newLeavingDate = leaving_date;
    }
  }

  @action
  public setStaffStatus(newStatus:Staffing_Staff_Status_Enum):void
  {
    this._staffMemberState.newStaffStatus = newStatus;
  }

  @action
  public setStaffLeavingDate(leavingDate:Date):void
  {
    this._staffMemberState.newLeavingDate = leavingDate
      // ? String(moment(leavingDate).format('MMMM DD, YYYY'))
      ? DateUtil.getISODay(leavingDate)
      : null;
  }

  @action.bound
  public async changeStaffStatus():Promise<void>
  {
    const { staffMember, newStaffStatus, newLeavingDate } = this._staffMemberState;
    console.log('%c changeStaffStatus =', 'background:#0f0;color:#000;', toJS(staffMember));
    console.log('%c -> newStaffStatus =', 'background:#080;color:#000;', toJS(newStaffStatus));
    console.log('%c -> newLeavingDate =', 'background:#080;color:#000;', toJS(newLeavingDate));

    if( !staffMember ) return;

    const staffMemberFields:Staffing_Staff_Set_Input = {};

    staffMemberFields.status_date = 'now()';
    staffMemberFields.status = newStaffStatus;

    // staffMemberFields.leaving_date = newStaffStatus === Staffing_Staff_Status_Enum.Leaving ? newLeavingDate : null;
    if( newStaffStatus === Staffing_Staff_Status_Enum.Leaving )
    {
      staffMemberFields.leaving_date = newLeavingDate;
    }

    const variables:UpdateStaffMemberMutationVariables = {
      staffId: staffMember.id,
      staffMemberFields: staffMemberFields
    };

    this.setChangeStatusDialog(false);

    console.log('%c !!! variables MEMBER =', 'background:#ff0;color:#000;', variables);
    return this._updateStaffMember(variables);
  }

  // ----------------------------------------------------
}
