import { Spinner } from '@flow/common/components/form/Spinner';
import { type Common_User } from '@flow/data-access/lib/types/graphql.generated';
import { type Recruiting_Position_Group_Priority } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';
import {
  type DraggableProvided,
  type DraggableStateSnapshot,
  type DroppableProvided,
} from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { PrioritiesController } from '../../PrioritiesController';
import {
  PRIORITIES_COLUMNS,
  PRIORITIES_NUM_OF_COLUMNS,
  PRIORITIES_USER_COLUMN,
  PrioritiesState
} from '../../PrioritiesState';
import { PrioritiesListUser } from '../common/PrioritiesListUser';

import styles from './PrioritiesList.module.less';
import { PrioritiesListCell } from './PrioritiesListCell';

const cx = classNames.bind(styles);

@component
export class PrioritiesList extends React.Component
{
  @di private _prioritiesState!:PrioritiesState;
  @di private _prioritiesController!:PrioritiesController;

  // ---------------------------------------------------------

  public componentDidMount():void
  {
    const { isPrioritiesLoaded } = this._prioritiesState;

    if( !isPrioritiesLoaded )
    {
      this._prioritiesController.initPriorities();
    }
  }

  // ---------------------------------------------------------

  @bind
  private _renderHeader(columns:Array<number | string>):React.ReactNode
  {
    return (
      <div className={styles.header}>
        {
          columns.map((col:number | string) =>
          {
            const isUser:boolean = col === PRIORITIES_USER_COLUMN;
            return (
              <div
                key={col}
                className={styles.colWrapper}
              >
                <span className={cx(styles.headerTitle, { isUser })}>
                  {isUser ? 'Name' : `Priority ${col}`}
                </span>
              </div>
            );
          })}
      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderDropPlaceholders(priorities:Array<Recruiting_Position_Group_Priority>, draggableIndexOver?:number):React.ReactNode
  {
    return (
      <div className={styles.dropRow}>

        <div className={styles.colWrapper} />

        {PRIORITIES_NUM_OF_COLUMNS.map((pr:number, index:number) =>
        {
          const isCanDrop:boolean = index <= priorities.length - 1;

          const isDragOver:boolean = isCanDrop && index === draggableIndexOver;

          // const isDragOver:boolean = index === draggableIndexOver;
          const isDropOverError:boolean = !!draggableIndexOver && draggableIndexOver >= priorities.length;

          return (
            <div
              key={index}
              className={cx(styles.colWrapper, { isCanDrop, isDragOver, isDropOverError })}
            />
          );
        })}
      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderRow(user:Common_User):React.ReactNode
  {
    const { isViewMode, draggableUserId, draggableIndexOver } = this._prioritiesState;

    return (
      <DragDropContext
        key={user.id}
        onDragStart={this._prioritiesController.onDragStart}
        onDragUpdate={this._prioritiesController.onDragUpdate}
        onDragEnd={this._prioritiesController.onDragEnd}
      >
        <Droppable
          direction={'horizontal'}
          droppableId={`${user.id}`}
          isDropDisabled={isViewMode}
        >
          {(provided:DroppableProvided/*, snapshot:DroppableStateSnapshot */):React.ReactElement => (
            <div
              // key={user.id}
              className={cx(styles.row)}

              ref={provided.innerRef}
              {...provided.droppableProps}
              // style={{ backgroundColor: snapshot.isDraggingOver ? '#fcc' : '#cfc' }}
            >
              <div className={styles.colWrapper}>
                <PrioritiesListUser user={user} className={styles.user} />
              </div>
              {
                user.id === draggableUserId &&
                this._renderDropPlaceholders(user.position_group_priorities, draggableIndexOver)
              }
              {/*{user.position_group_priorities.map((priority:Recruiting_Position_Group_Priority, index:number) =>*/}
              {PRIORITIES_NUM_OF_COLUMNS.map((pr:number, index:number) =>
              {
                // if( index === user.position_group_priorities.length )
                // {
                //   // if( user.id === draggableUserId )
                //   // {
                //   //   // empty cell
                //   //   return <div key={index} className={styles.colWrapper} />;
                //   // }
                //
                //   // button (+)
                //   return (
                //     <div key={index} className={styles.colWrapper}>
                //       <PrioritiesListCell
                //         user={user}
                //         priorityIndex={index}
                //       />
                //     </div>
                //   );
                // }

                // if( index >= user.position_group_priorities.length )
                // {
                //   // empty cell
                //   return <div key={index} className={styles.colWrapper} />;
                // }

                const isDragDisabled:boolean = isViewMode;// || index >= user.position_group_priorities.length;

                return (
                  <Draggable
                    key={index}
                    // key={`${user.position_group_priorities.length}:${priority.priority}`}
                    draggableId={`${index}`}
                    index={index}
                    isDragDisabled={isDragDisabled}
                  >
                    {(provided:DraggableProvided, snapshot:DraggableStateSnapshot):React.ReactElement => (
                      <div
                        className={cx(styles.colWrapper, { isDragging: snapshot.isDragging })}

                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        // ...provided.dragHandleProps moved to -> PositionGroupSelector
                        style={{ ...provided.draggableProps.style }}
                      >
                        <PrioritiesListCell
                          user={user}
                          priorityIndex={index}
                          provided={provided}
                          snapshot={snapshot}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {
                // user.position_group_priorities.length < PRIORITIES_NUM_OF_COLUMNS.length &&
                // <div className={cx(styles.colWrapper, styles.btnAdd)}>
                //   <PrioritiesListCell
                //     user={user}
                //     priorityIndex={user.position_group_priorities.length}
                //   />
                // </div>
              }
              <div className={styles.spacer} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { isPrioritiesLoaded } = this._prioritiesState;

    if( !isPrioritiesLoaded )
    {
      return (
        <Spinner
          // size={20}
          noIcon
          withWave
          withWrapper
          wrapperClassName={styles.spinnerWrapper}
        />
      );
    }

    const { users, isEditMode } = this._prioritiesState;

    return (
      <div className={cx(styles.wrapper, { isEditMode })}>

        {this._renderHeader(PRIORITIES_COLUMNS)}

        <div className={styles.rowsWrapper}>
          {users.map(this._renderRow)}
        </div>

      </div>
    );
  }

  // ---------------------------------------------------------
}
