import { Button, ButtonSize } from '@flow/common/components/form/Button';
import type { IconName } from '@flow/common/components/form/Icon';
import { Icon } from '@flow/common/components/form/Icon';
import { Intent } from '@flow/common/components/types/Types';
import React from 'react';
import classNames from 'classnames/bind';

import styles from './DashboardCard.module.less';

const cx = classNames.bind(styles);

interface Props
{
  title:string;
  iconName?:IconName;
  content:string | JSX.Element;
  buttonText?:string;
  onButtonClick?:() => void;
}

export class DashboardCard extends React.Component<Props, unknown>
{
  public render():React.ReactNode
  {
    const { title, iconName, content, buttonText, onButtonClick } = this.props;

    return (
      <div className={styles.card}>

        <div className={styles.header}>
          {title}
        </div>

        <div className={styles.contentWrapper}>

          {
            iconName &&
            <div className={styles.iconWrapper}>
              <Icon icon={iconName} className={styles.icon} />
            </div>
          }

          <div className={styles.content}>
            {content}
          </div>

        </div>

        <div className={styles.buttonBar}>
          <Button
            className={cx(styles.button,'test-dashboard-pages-navigation')}
            intent={Intent.PRIMARY}
            text={buttonText}
            onClick={onButtonClick}
            size={ButtonSize.LARGE}
          />
        </div>

      </div>
    )
  }
}
