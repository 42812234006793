import { MenuItem } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { DialogSelectButton } from '@flow/common/components/page/DialogSelectButton';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import type { Recruiting_Interview_Flow_Stage } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { CandidateAllocationState } from '@flow/modules/recruiting/candidates/CandidateAllocationState';
import { CandidateFlowController } from '@flow/modules/recruiting/candidates/CandidateFlowController';
import { CandidateFlowState } from '@flow/modules/recruiting/candidates/CandidateFlowState';
import { CandidateState } from '@flow/modules/recruiting/candidates/CandidateState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './PipelineStageSelector.module.less';

const cx = classNames.bind(styles);

@component
export class PipelineStageSelector extends React.Component
{
  @di private _candidateState!:CandidateState;
  @di private _candidateFlowState!:CandidateFlowState;
  @di private _candidateAllocationState!:CandidateAllocationState;

  @di private _candidateFlowsController!:CandidateFlowController;
  @di private _roleController!:RoleController;

  // ---------------------------------------------------------

  @bind
  private _itemRenderer(flowStageItem:Recruiting_Interview_Flow_Stage,
                        { handleClick, modifiers, index }:IItemRendererProps):JSX.Element | null
  {
    if( !modifiers.matchesPredicate ) return null;

    const { candidateFlowStageId, candidateFlow } = this._candidateFlowState;

    if( !candidateFlow ) return null;

    const { stages } = candidateFlow;
    const { isFinalized } = this._candidateAllocationState;

    const isSelected:boolean = flowStageItem.id === candidateFlowStageId;

    const cantConvertToStaff:boolean = index === stages.length - 1 && !isFinalized;
    const disabledIfMoreThenNext:boolean = flowStageItem.id > candidateFlowStageId + 1;
    const isDisabled:boolean = cantConvertToStaff || disabledIfMoreThenNext;

    return (
      <MenuItem
        key={flowStageItem.id}
        className={cx(styles.menuItemFlowStage, { isDisabled, isSelected })}
        text={flowStageItem.name}
        onClick={handleClick}
        // selected={isSelected}
        disabled={isDisabled}
        active={modifiers.active}
        onKeyDown={(e):void =>
        {
          if( e.key !== 'Enter' ) return;

          this._onItemSelect(flowStageItem, e);
        }}

        // icon={isStaffedMenuItem ? IconNames.BLANK : null}
        labelElement={<Icon icon={isDisabled ? IconNames.LOCK : null} />}
      />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _onItemSelect(flow:Recruiting_Interview_Flow_Stage, event?:React.SyntheticEvent<HTMLElement>):void
  {
    const { candidateFlowStageId, candidateFlow } = this._candidateFlowState;

    // console.log('%c flow =', 'background:#0f0;color:#000;', toJS(flow));
    // console.log('%c event =', 'background:#0f0;color:#000;', toJS(event));
    if( !candidateFlow ) return;

    if( candidateFlowStageId === flow.id ) return;

    const { stages } = candidateFlow;
    const { isFinalized } = this._candidateAllocationState;

    const index:number = stages.findIndex((stage:Recruiting_Interview_Flow_Stage) => stage.id === flow.id);
    const cantConvertToStaff:boolean = index === stages.length - 1 && !isFinalized;
    const disabledIfMoreThenNext:boolean = flow.id > candidateFlowStageId + 1;
    const isDisabled:boolean = cantConvertToStaff || disabledIfMoreThenNext;

    // console.log('%c stages =', 'background:#0f0;color:#000;', toJS(stages));
    // console.log('%c isFinalized =', 'background:#0f0;color:#000;', isFinalized);
    // console.log('%c index =', 'background:#0f0;color:#000;', index);
    // console.log('%c cantConvertToStaff =', 'background:#0f0;color:#000;', cantConvertToStaff);
    // console.log('%c disabledIfMoreThenNext =', 'background:#0f0;color:#000;', disabledIfMoreThenNext);
    // console.log('%c isDisabled =', 'background:#0f0;color:#000;', isDisabled);

    if( isDisabled )
    {
      // event?.preventDefault();
      // event?.stopPropagation();
      // console.log('%c STOP =', 'background:#f00;color:#ff0;', isDisabled);
      return;
    }

    this._candidateFlowsController.selectCandidateFlowStage(flow.id);
  }

  // ---------------------------------------------------------

  public render():React.ReactNode | null
  {
    const {
      candidateFlowStages,
      candidateFlowStage,
      isCandidateOnFlow
    } = this._candidateFlowState;

    if( !isCandidateOnFlow ) return null;

    const isDisabled:boolean = !this._roleController.hasPermission(FlowPermissions.ChangeCandidateFlowStage);

    const buttonText = candidateFlowStage?.name;

    // const buttonText = status == Recruiting_Candidate_Status_Enum.Active
    //   ? candidateFlowStage?.name
    //   : CandidateStatusTitles.get(String(status));

    return (
      <Select
        className={styles.selectStage}
        items={candidateFlowStages}
        itemRenderer={this._itemRenderer}
        onItemSelect={this._onItemSelect}
        filterable={false}
        disabled={isDisabled}
        popoverProps={{
          minimal: true,
          usePortal: false
        }}
      >
        <DialogSelectButton
          text={buttonText}
          disabled={isDisabled}
        />
      </Select>
    );
  }

  // ---------------------------------------------------------
}
