import type { IconName } from '@flow/common/components/form/Icon';
import { Icon } from '@flow/common/components/form/Icon';
import classNames from 'classnames/bind';
import React from 'react';
import styles from './PositionGroupPill.module.less';

const cx = classNames.bind(styles);

export interface IPositionGroupPillProps
{
  name:string;
  className?:string;

  icon?:IconName;
  iconSize?:number;
  iconClassName?:string;
}

export class PositionGroupPill extends React.PureComponent<IPositionGroupPillProps>
{
  public render():React.ReactNode
  {
    const { name, className, icon, iconClassName, iconSize = 16 } = this.props;

    return (
      <div className={cx(styles.positionGroupPill, className)}>
        {name}
        {
          icon &&
          <Icon
            className={cx(styles.icon, iconClassName)}
            icon={icon}
            size={iconSize}
          />
        }
      </div>
    );
  }
}
