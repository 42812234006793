import { Popover, Position } from '@blueprintjs/core';
import { Classes } from '@blueprintjs/popover2';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React, { Component } from 'react';

import styles from './ButtonWithMenu.module.less';

const cx = classNames.bind(styles);

interface Props
{
  button:React.ReactElement;
  menuContent:React.ReactElement;

  className?:string;
  portalClassName?:string;
  position?:Position;
  disabled?:boolean;

  usePortal?:boolean;
}

export class ButtonWithMenu extends Component<Props>
{
  @bind
  private _onClickBtn(e:React.MouseEvent<HTMLElement>):void
  {
    e.stopPropagation();
  }

  public render():React.ReactNode
  {
    const {
      className, button, menuContent, portalClassName, disabled,
      position = Position.BOTTOM_RIGHT,
      usePortal = false
    } = this.props;

    return (
      <div
        className={cx(styles.wrapper, className)}
        onClick={this._onClickBtn}
      >
        <Popover
          className={styles.popover}
          content={menuContent}
          position={position}
          popoverClassName={Classes.POPOVER2_DISMISS}
          portalClassName={portalClassName}
          disabled={disabled}
          usePortal={usePortal}
          minimal={true}
        >
          {button}
        </Popover>
      </div>
    );
  }
}
