import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { Spinner } from '@flow/common/components/form/Spinner';
import { NeedsPermission } from '@flow/common/components/NeedsPermission';
import { ButtonGroupFilter } from '@flow/common/components/page/ButtonGroupFilter';
import { Toolbar } from '@flow/common/components/page/Toolbar';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { component, di } from '@flow/dependency-injection';
import classNames from 'classnames/bind';
import React from 'react';
import { CandidatesController } from '../../CandidatesController';
import { CandidatesState, CandidatesStatusFilter, CandidatesStatusFilterTitles } from '../../CandidatesState';
import { CandidatesSearch } from './CandidatesSearch';

import styles from './CandidatesToolbar.module.less';
import { CandidatesCreateDialog } from './newCandidate/CandidatesCreateDialog';

const cx = classNames.bind(styles);

@component
export class CandidatesToolbar extends React.Component
{
  @di private _candidatesState!:CandidatesState;
  @di private _candidatesController!:CandidatesController;

  // ---------------------------------------------------------

  private _renderCandidatesStatusFilter(isDesktop:boolean):JSX.Element
  {
    const { candidatesStatusFilterValue, isCandidatesLoaded } = this._candidatesState;

    if( !isCandidatesLoaded )
    {
      return (
        <Spinner
          // size={20}
          noIcon
          withWave
          withWrapper
          wrapperClassName={cx(styles.spinnerWrapper, isDesktop ? 'desktop' : 'mobile')}
        />
      );
    }

    return (
      <div className={cx(styles.statusSwitcher, isDesktop ? 'desktop' : 'mobile')}>
        <ButtonGroupFilter
          buttonClassName={styles.statusSwitcherBtn}
          buttonGroupClassName={styles.statusSwitcherGroupBtn}
          values={[
            CandidatesStatusFilter.Initial,
            CandidatesStatusFilter.InWork,
            CandidatesStatusFilter.Archived
          ]}
          titles={CandidatesStatusFilterTitles}
          value={candidatesStatusFilterValue}
          onClick={this._candidatesController.setFilterByCandidateStatus}
        />
      </div>
    )
  }

  public render():React.ReactNode
  {
    return (
      <>
        <Toolbar
          className={styles.toolbar}
          title={'Candidates'}

          leftClassName={styles.positionLeft}
          leftElements={
            <NeedsPermission name={FlowPermissions.CreateCandidate}>
              <Button
                className={cx(styles.btnAdd, 'test-add-candidate')}
                text={'New Candidate'}
                icon={IconNames.ADD}
                minimal={true}
                onClick={this._candidatesController.showNewCandidateDialog}
              />
              <CandidatesCreateDialog />
            </NeedsPermission>
          }

          centerClassName={styles.positionCenter}
          centerElements={this._renderCandidatesStatusFilter(true)}

          rightClassName={styles.positionRight}
          rightElements={
            <>
              <CandidatesSearch />
            </>
          }
        />
        <div className={styles.secondRow}>
          {this._renderCandidatesStatusFilter(false)}
        </div>
      </>
    );
  }
}
