import React from 'react';

import { component, di } from '@flow/dependency-injection';
import { ToastsController } from '../toasts/ToastsController';
import * as uncaught from 'uncaught';
import { onReactionError } from 'mobx';
import bind from 'bind-decorator';
import { mapStackTrace } from 'sourcemapped-stacktrace';

@component
export class GlobalExceptionHandler extends React.Component
{
  @di private readonly _toastsController!:ToastsController;

  @bind
  private _handleUncaughtError(error:Error):void
  {
    mapStackTrace(error.stack || '', (mappedStack) =>
    {
      this._toastsController.showError(error.message, mappedStack.join('\n'));
    });
  }

  @bind
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _handleMobxReactionError(error:Error, errorEvent:any):void
  {
    mapStackTrace(error.stack || '', (mappedStack) =>
    {
      this._toastsController.showError(error.message || errorEvent.toString(), mappedStack.join('\n'));
    });
  }

  public componentDidMount():void
  {
    uncaught.start();
    uncaught.addListener(this._handleUncaughtError);
    onReactionError(this._handleMobxReactionError);
  }

  public componentWillUnmount():void
  {
    uncaught.removeListener(this._handleUncaughtError);
  }

  public render():React.ReactNode
  {
    return null;
  }
}
