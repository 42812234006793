import type { Maybe } from '@flow/common/models/Types';
import type { Common_User } from '@flow/data-access/lib/types/graphql.generated';

export class StringUtil
{
  // ----------------------------------------------------

  /* eslint-disable @typescript-eslint/no-explicit-any */
  public static format(str:string, values:Record<string, any>):string
  {
    for( const key of Object.keys(values) )
    {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      str = str.replaceAll(`{${key}}`, values[key]);
    }
    return str;
  }

  // ----------------------------------------------------

  public static getUserName(user:Maybe<Common_User>):string
  {
    // const lastNameLetter:string = user.last_name ? `${user.last_name.substring(0, 1)}.` : '';
    // const name:string = `${user.first_name || ''}${lastNameLetter && user.first_name ? ' ' : ''}${lastNameLetter}`;

    return user ? `${user.first_name} ${user.last_name}` : '';
  }

  // ----------------------------------------------------

  public static isStringALinkedinUrl(str:Maybe<string>):boolean
  {
    if( !str ) return false;

    const reLinkedin:RegExp = /(https?:\/\/)(www\.)?(linkedin\.com\/in\/).*/;

    return reLinkedin.test(str);
  }

  // ----------------------------------------------------

}
