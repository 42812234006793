import type { IFilter } from '@flow/common/components/filters/Filter';
import { FilterType } from '@flow/common/components/filters/Filter';
import { FilterTimeSelector } from '@flow/common/components/filters/time/FilterTimeSelector';
import type { Moment } from 'moment';
import moment from 'moment';

// ---------------------------------------------------------

export const FilterTimeDateFormat = 'MMM D, YYYY';

export function FilterTimeParseDate(str:string):Date
{
  return moment(str, FilterTimeDateFormat).toDate() as Date;
}

export function FilterTimeFormatDate(date:Date):string
{
  return moment(date).format(FilterTimeDateFormat) as string;
}

// ---------------------------------------------------------

export enum FilterTimeValueType
{
  ANY = 'ANY',
  THIS_WEEK = 'THIS_WEEK',
  LAST_WEEK = 'LAST_WEEK',
  RANGE = 'RANGE',
}

export interface IFilterTimeValue
{
  type:FilterTimeValueType;
  rangeFrom?:string;
  rangeTo?:string;
}

export interface IFilterTimeItem
{
  type:FilterTimeValueType;
  value:IFilterTimeValue;
}

export const FilterTimeNames:Record<FilterTimeValueType, string> =
  {
    [FilterTimeValueType.ANY]: 'Any',
    [FilterTimeValueType.THIS_WEEK]: 'This week',
    [FilterTimeValueType.LAST_WEEK]: 'Last week',
    [FilterTimeValueType.RANGE]: ''
  };

export const FilterTimeItems:Array<IFilterTimeItem> = [
  {
    type: FilterTimeValueType.ANY,
    value: {
      type: FilterTimeValueType.ANY
    }
  },
  {
    type: FilterTimeValueType.THIS_WEEK,
    value: {
      type: FilterTimeValueType.THIS_WEEK
    }
  },
  {
    type: FilterTimeValueType.LAST_WEEK,
    value: {
      type: FilterTimeValueType.LAST_WEEK
    }
  },
  {
    type: FilterTimeValueType.RANGE,
    value: {
      type: FilterTimeValueType.RANGE,
      rangeFrom: '',
      rangeTo: ''
    }
  }
];

// ---------------------------------------------------------

export const FilterTimeSettings:IFilter<IFilterTimeValue> = {
  type: FilterType.TIME,
  name: 'Time',
  isSelected: false,
  value: { type: FilterTimeValueType.ANY },
  component: FilterTimeSelector,
  filterFunction: filterFunction,
  graphQLTemplate: `{
    "_and": [
      {
        "created_at": {
          "_gte": "{{fromDate}}"
        }
      },
      {
        "created_at": {
          "_lte": "{{toDate}}"
        }
      }
    ]
  }`,
  graphQLFilter: graphQLFilter
};

// ---------------------------------------------------------

function filterFunction(this:IFilter<IFilterTimeValue>, createdAt:string):boolean
{
  const dateNow:Moment = moment(); // == new Date()

  const { type, rangeFrom, rangeTo } = this.value;

  const startOfWeek:Moment = moment(dateNow).startOf('isoWeek');
  const startOfLastWeek:Moment = moment(startOfWeek).subtract(1, 'week');

  // console.log('%c --------------------------------------- =', 'background:#0f0;color:#000;');
  // console.log('%c ------- dateNow =', 'background:#080;color:#000;', dateNow);
  // console.log('%c --- startOfWeek =', 'background:#080;color:#000;', startOfWeek);
  // console.log('%c startOfLastWeek =', 'background:#080;color:#000;', startOfLastWeek);

  switch( type )
  {
    // case FilterTimeValueType.ANY: // !isSelected
    //     return false;

    case FilterTimeValueType.THIS_WEEK:

      if( !moment(createdAt).isBetween(startOfWeek, moment(dateNow)) ) return false;
      break;

    case FilterTimeValueType.LAST_WEEK:

      if( !moment(createdAt).isBetween(startOfLastWeek, startOfWeek) ) return false;
      break;

    case FilterTimeValueType.RANGE:

      if( !rangeFrom || !rangeTo ) return true;
      break;
  }

  if( type === FilterTimeValueType.RANGE )
  {
    const rangeFromM:Moment = moment(rangeFrom);
    const rangeToM:Moment = moment(rangeTo);

    const diff:number = rangeFromM.diff(rangeToM);
    const minDate:Moment = diff <= 0 ? rangeFromM : rangeToM;
    const maxDate:Moment = diff <= 0 ? rangeToM : rangeFromM;

    if( !moment(createdAt).isBetween(minDate, maxDate, 'day', '[]') )
      return false;
  }

  return true;
}

// ---------------------------------------------------------

function graphQLFilter(this:IFilter<IFilterTimeValue>):string
{
  if( !this.graphQLTemplate )
    throw new Error(`Cannot apply filter ${this.name}: graphQLTemplate is not defined`);

  const nowDate:Moment = moment();

  const { type, rangeFrom, rangeTo } = this.value;

  const startOfWeek:Moment = moment(nowDate).startOf('isoWeek');
  const startOfLastWeek:Moment = moment(startOfWeek).subtract(1, 'week');

  let fromDate:string = '';
  let toDate:string = '';

  switch( type )
  {
    case FilterTimeValueType.ANY:
      return '';

    case FilterTimeValueType.THIS_WEEK:

      fromDate = startOfWeek.toISOString();
      toDate = nowDate.toISOString();
      break;

    case FilterTimeValueType.LAST_WEEK:

      fromDate = startOfLastWeek.toISOString();
      toDate = startOfWeek.toISOString();
      break;

    case FilterTimeValueType.RANGE:

      if( !rangeFrom || !rangeTo ) return '';

      fromDate = moment(rangeFrom).toISOString();
      toDate = moment(rangeTo).toISOString();
      break;
  }

  return this.graphQLTemplate
    .replace('{{fromDate}}', fromDate)
    .replace('{{toDate}}', toDate);
}
