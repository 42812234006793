import { PageTitle } from '@flow/common/components/page/PageTitle';
import { component, di } from '@flow/dependency-injection';
import { CandidateState } from '@flow/modules/recruiting/candidates/CandidateState';
import { ConstraintsWarnings } from '@flow/modules/recruiting/candidates/components/candidate/status/constraints/ConstraintsWarnings';
import { Archived } from '@flow/modules/recruiting/candidates/components/candidate/status/tabArchived/Archived';
import { SelectCandidatePipeline } from '@flow/modules/recruiting/candidates/components/candidate/status/tabInitial/SelectCandidatePipeline';
import { DialogArchiveCandidate } from '@flow/modules/recruiting/candidates/components/candidate/status/tabInWork/DialogArchiveCandidate';
import { DialogMoveCandidateToInitial } from '@flow/modules/recruiting/candidates/components/candidate/status/tabInWork/DialogMoveCandidateToInitial';
import { SelectCandidateFlowStage } from '@flow/modules/recruiting/candidates/components/candidate/status/tabInWork/SelectCandidateFlowStage';
import { ConvertToStaffDialog } from '@flow/modules/recruiting/common/convertToStaff/components/ConvertToStaffDialog';
// import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';
import { CandidatePanelAllocation } from './allocation/CandidatePanelAllocation';
import styles from './CandidateLayout.module.less';
import { CandidateRightTabs } from './CandidateRightTabs';
import { CandidateTabs } from './CandidateTabs';
import { CandidateToolbar } from './CandidateToolbar';

// const cx = classNames.bind(styles);

@component
export class CandidateLayout extends React.Component
{
  @di private _candidateState!:CandidateState;

  // ---------------------------------------------------------

  public render():ReactNode
  {
    const { candidateName } = this._candidateState;

    if( !candidateName ) return null;

    return (
      <div className={styles.layout}>

        <PageTitle title={candidateName} />

        <div className={styles.left}>

          <CandidateToolbar />

          <CandidateTabs />

        </div>

        <div className={styles.right}>

          <div className={styles.flowWrapper}>
            <SelectCandidatePipeline />
            <SelectCandidateFlowStage />
            <Archived />
          </div>

          <ConstraintsWarnings />

          <CandidateRightTabs />

          <CandidatePanelAllocation />

        </div>

        <DialogMoveCandidateToInitial />
        <DialogArchiveCandidate />
        <ConvertToStaffDialog />

      </div>
    );
  }

  // ---------------------------------------------------------
}
