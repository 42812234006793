import type {
  Common_Customer,
  Recruiting_Position,
  Recruiting_Position_Group,
  Staffing_Customer_Team,
  Staffing_Customer_Team_Slot
} from '@flow/data-access/lib/types/graphql.generated';
import { Common_Customer_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';

export enum CustomerMoveTo
{
  UP = 'UP',
  DOWN = 'DOWN',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export const CUSTOMER_GROUP_TITLE = {
  [Common_Customer_Status_Enum.Current]: 'Active',
  [Common_Customer_Status_Enum.Prospective]: 'Prospective',
  [Common_Customer_Status_Enum.Archived]: 'Archived',
  Internal: 'Internal'
};

type Recruiting_Position_Slots_Count = {
  newQuantity:number | null;
  open_slots_count:{
    aggregate:{
      count:number;
    };
  };
  total_slots_count:{
    aggregate:{
      count:number;
    };
  };
  leaving_slots?:Array<Staffing_Customer_Team_Slot>;
}

export type Recruiting_Position_Ex = Recruiting_Position & Recruiting_Position_Slots_Count;

export type Staffing_Customer_Team_Ex = Staffing_Customer_Team & {
  positions:Array<Recruiting_Position_Ex>;
}

export type Common_Customer_Ex = Common_Customer & {
  customer_teams:Array<Staffing_Customer_Team_Ex>;
}

export type Recruiting_Position_Group_Ex = Recruiting_Position_Group & {
  positions:Array<Recruiting_Position_Ex>;
}
