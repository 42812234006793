import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import {
  CHECKBOXES_FILTER_EMPTY_VALUE,
  IFilterCheckboxesDataItem,
  IFilterCheckboxesDataItems
} from '@flow/common/components/filters/checkboxes/FilterCheckboxes';
import type { IFilter, IFilterValue } from '@flow/common/components/filters/Filter';
import { FilterType } from '@flow/common/components/filters/Filter';
import { IFilterMultiSelectDataItems } from '@flow/common/components/filters/multiSelect/FilterMultiSelect';
import { AuthState } from '@flow/common/controllers/AuthState';
import { Maybe } from '@flow/common/models/Types';
import { LocalStorageUtil } from '@flow/common/utils/LocalStorageUtil';
import { StringUtil } from '@flow/common/utils/StringUtil';
import { AnyObject, WindowLocationUtil } from '@flow/common/utils/WindowLocationUtil';
import type {
  Common_Customer,
  Common_User,
  Recruiting_Position_Group
} from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import { InterviewFlowsFiltersState } from '@flow/modules/recruiting/status/InterviewFlowsFiltersState';
import { InterviewFlowsState, InterviewFlowsUrl_LS_KEY } from '@flow/modules/recruiting/status/InterviewFlowsState';
import { action } from 'mobx';

@controller
export class InterviewFlowsFiltersController
{
  @di private _authState!:AuthState;
  @di private _commonState!:CommonState;
  @di private _commonController!:CommonController;

  @di private _interviewFlowsState!:InterviewFlowsState;
  @di private _interviewFlowsFiltersState!:InterviewFlowsFiltersState;

  // ----------------------------------------------------

  public setLocalStorage():void
  {
    console.log('%c setLocalStorage =', 'background:#f0f;color:#ff0;');
    this._setQueryStringOrLocalStorage(true);
  }

  public setQueryString():void
  {
    console.log('%c setQueryString =', 'background:#f0f;color:#ff0;');
    this._setQueryStringOrLocalStorage(false);
  }

  // ----------------------------------------------------

  private _setQueryStringOrLocalStorage(isLS:boolean):void
  {
    const queryObject:AnyObject = {};

    // filters
    this._interviewFlowsFiltersState.filters.forEach((filter:IFilter<IFilterValue>) =>
    {
      // if( !filter.isSelected ) return;

      if( typeof filter.value === 'object' && !Array.isArray(filter.value) )
      {
        Object.keys(filter.value).forEach((valueKey:string) =>
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const value:any = (filter.value as any)[valueKey];

          if( value && Array.isArray(value) && value.length )
          {
            queryObject[`${filter.type}_${valueKey}`] = value;
          }
        });
      }
      else
      {
        queryObject[filter.type] = WindowLocationUtil.makeQueryStringFromObject(filter.value);
      }
    });

    if( !isLS )
    {
      const str:string = WindowLocationUtil.makeQueryStringFromObject(queryObject);
      console.log('%c --> setQueryStringOrLocalStorage 1 =', 'background:#f0f;color:#000;', isLS, str);
      console.log('%c --- decode =', 'background:#f0f;color:#000;', WindowLocationUtil.getObjectFromQueryString(str));

      WindowLocationUtil.setQueryString(queryObject);
    }
    else // if( isLS )
    {
      console.log('%c --> setQueryStringOrLocalStorage 2 =', 'background:#f0f;color:#000;', queryObject);
      LocalStorageUtil.setItem(InterviewFlowsUrl_LS_KEY, queryObject);
    }
  }

  // ----------------------------------------------------

  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public applyQueryStringFilters(userFilters:any):boolean
  {
    let someDataFromQueryStringIsApplied:boolean = false;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Object.keys(userFilters).forEach((_filterType:string) =>
    {
      // STATUS_status[]=1,2,3
      if( _filterType.indexOf('_') === -1 ) return;

      // POSITION_GROUP_items -> filterType = POSITION_GROUP
      const filterString:Array<string> = _filterType.split('_');

      // POSITION_GROUP_items -> filterValueKey = items
      const filterValueKey:string | undefined = filterString.pop();

      if( !filterValueKey ) return;

      const filterType:string = filterString.join('_');

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filter:IFilter<any> | undefined = this._interviewFlowsFiltersState.filterByType(filterType as FilterType);

      if( !filter ) return;

      someDataFromQueryStringIsApplied = true;

      console.log('%c userFilters[filterType] =', 'background:#0f0;color:#000;', userFilters[_filterType]);
      console.log('%c --- _filterType =', 'background:#080;color:#000;', _filterType);
      console.log('%c --- filter =', 'background:#080;color:#000;', filter);

      // this.selectFilter(filter.type, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value:any = userFilters[_filterType];

      // this.changeFilterValue(filter.type, WindowLocationUtil.getObjectFromQueryString(userFilters[filterType]));
      this.changeFilterValue(filter.type,
        {
          ...filter.value,
          // [filterValueKey]: WindowLocationUtil.getObjectFromQueryString(userFilters[_filterType])
          [filterValueKey]: value
        });
    });

    return someDataFromQueryStringIsApplied;
  }

  // ----------------------------------------------------

  @action.bound
  public applyFromLocalStorage():void
  {
    // const lsState:Partial<StaffState> = LocalStorageUtil.getItem(StaffListState_LS_KEY);
    const lsUrl:object = LocalStorageUtil.getItem(InterviewFlowsUrl_LS_KEY);

    this._interviewFlowsFiltersState.isApplyingFromLocalStorage = true;

    console.log('%c applyFromLocalStorage:   lsUrl =', 'background:#0ff;color:#000;', lsUrl);
    if( lsUrl )
    {
      WindowLocationUtil.setQueryString(lsUrl);
      this.applyQueryString();
    }

    this._interviewFlowsFiltersState.isApplyingFromLocalStorage = false;
  }

  // ----------------------------------------------------

  @action.bound
  public applyQueryString():void
  {
    const { isApplyingFromLocalStorage } = this._interviewFlowsFiltersState;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryObject:any = WindowLocationUtil.getObjectFromQueryString();
    console.log('%c applyQueryString =', 'background:#f0f;color:#fff;', queryObject);

    const lsUrl:object = LocalStorageUtil.getItem(InterviewFlowsUrl_LS_KEY);
    console.log('%c -->        lsUrl =', 'background:#f00;color:#fff;', lsUrl);

    const aQSF:boolean = this.applyQueryStringFilters(queryObject);

    if( isApplyingFromLocalStorage ) return;

    if( aQSF )
    {
      this.setLocalStorage();
    }
    else // if( !aQSF )
    {
      this.applyFromLocalStorage();
    }
  }

  // ----------------------------------------------------

  @action.bound
  public getFilterData(filterType:FilterType):Maybe<IFilterMultiSelectDataItems | IFilterCheckboxesDataItems>
  {
    // console.log('%c getFilterData =', 'background:#0f0;color:#000;', filterType);

    let filterData:Maybe<IFilterMultiSelectDataItems | IFilterCheckboxesDataItems> = null;

    switch( filterType )
    {
      case FilterType.FLOWS_CUSTOMER:
        filterData = {
          items: this._interviewFlowsState.customersForFilter.map((customer:Common_Customer | null) =>
          {
            if( !customer ) return { isMenuDivider: true, name: '', value: -1 };

            const ret:IFilterCheckboxesDataItem = { name: customer.alias, value: customer.id };
            return ret;
          })
        };
        break;

      case FilterType.FLOWS_POSITION_GROUP:
        filterData = {
          items: this._interviewFlowsState.positionGroups.map((pg:Recruiting_Position_Group) =>
          {
            const ret:IFilterCheckboxesDataItem = { name: pg.name, value: pg.id };
            return ret;
          })
        };
        break;

      case FilterType.FLOWS_RECRUITER:
        filterData = {
          items: this._interviewFlowsState.recruiters.map((user:Common_User) =>
          {
            const ret:IFilterCheckboxesDataItem = {
              name: StringUtil.getUserName(user),
              value: user.id
            };
            return ret;
          })
        };
        break;
    }

    // console.log('%c ---           =', 'background:#080;color:#000;', toJS(filterData));
    return filterData;
  }

  // ----------------------------------------------------

  @action.bound
  public changeFilterValue(filterType:FilterType, newValue:IFilterValue):void
  {
    console.log('%c changeFilter =', 'background:#0f0;color:#000;', filterType, newValue);

    this._interviewFlowsFiltersState.filterByType(filterType).value = newValue;

    this.setQueryString();
    this.setLocalStorage();
  }

  // ----------------------------------------------------

  @action.bound
  public clearFilter(filterType:FilterType):void
  {
    console.log('%c clearFilter =', 'background:#0f0;color:#000;', filterType);

    this._interviewFlowsFiltersState.filterByType(filterType).value = CHECKBOXES_FILTER_EMPTY_VALUE;

    this.setQueryString();
    this.setLocalStorage();
  }

  // ----------------------------------------------------
}
