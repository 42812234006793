import type { ApolloQueryResult } from '@apollo/client';
import type { FetchResult } from '@apollo/client/link/core';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import { RouteName } from '@flow/common/models/routing/RouteName';
import {
  type SetPositionGroupOfPositionMutation,
  type SetPositionGroupOfPositionMutationVariables
} from '@flow/data-access/lib/positionGroups.generated';
import { SetPositionGroupOfPositionDocument } from '@flow/data-access/lib/positionGroups.generated';
import {
  type GetPositionsQuery,
  type GetPositionsQueryVariables
} from '@flow/data-access/lib/positions.generated';
import { GetPositionsDocument } from '@flow/data-access/lib/positions.generated';
import {
  type Recruiting_Position,
  type Recruiting_Position_Group
} from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import type { Common_Customer_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { RoutesConfig } from 'apps/flow/src/pages/RoutesConfig';
import { action, runInAction } from 'mobx';
import { PositionsFilter, PositionsState } from './PositionsState';

@controller
export class PositionsController
{
  @di private _commonState!:CommonState;
  @di private _commonController!:CommonController;
  @di private _positionsState!:PositionsState;

// ----------------------------------------------------

  @action.bound
  public initTabsFromRoute(isPositionsRoute:boolean):void
  {
    if( isPositionsRoute )
      this.setPositionsFilter(PositionsFilter.POSITIONS);
    else
      this.setPositionsFilter(PositionsFilter.PRIORITIES);
  }

  // ----------------------------------------------------

  // @action.bound
  // public initFromLocalStorage():void
  // {
  //   const lsState:Partial<PositionsState> = LocalStorageUtil.getItem(PositionsState_LS_KEY);

  //   this.setPositionsFilter(lsState?.positionsFilterValue || PositionsFilter.POSITIONS);

  //   const { disposers } = this._positionsState;

  //   Array.isArray(disposers) && disposers.push(reaction(
  //     () =>
  //     {
  //       const {
  //         positionsFilterValue
  //       } = this._positionsState;

  //       return {
  //         positionsFilterValue
  //       };
  //     },
  //     (lsValue:Partial<PositionsState>) =>
  //     {
  //       console.log('%c PositionsState: lsValue =', 'background:#0ff;color:#000;', lsValue);
  //       LocalStorageUtil.setItem(PositionsState_LS_KEY, lsValue);
  //     }
  //   ));
  // }

  // ----------------------------------------------------

  @action.bound
  public async initPositions():Promise<void>
  {
    this._positionsState.isPositionsLoaded = false;

    const result:ApolloQueryResult<GetPositionsQuery> =
      await this._commonController.query<GetPositionsQuery,
        GetPositionsQueryVariables>({
        query: GetPositionsDocument,
        variables: {}
      });

    console.log('%c initPositions result =', 'background:#0f0;color:#000;', result);

    runInAction(() =>
    {
      const {
        recruiting_position_group,
        common_customer
      } = result.data;

      this._positionsState.positionGroups = recruiting_position_group as Array<Recruiting_Position_Group>;
      this._positionsState.customers = common_customer as unknown as Array<Common_Customer_Ex>;

      this._positionsState.isPositionsLoaded = true;
    });
  }

  // ----------------------------------------------------

  @action.bound
  public setPositionsFilter(value:PositionsFilter):void
  {
    this._positionsState.positionsFilterValue = value;

    const path:string | null = value == PositionsFilter.POSITIONS
      ? RoutesConfig.getRoutePath(RouteName.RECRUITING_POSITIONS)
      : RoutesConfig.getRoutePath(RouteName.RECRUITING_PRIORITIES);

    if( path )
      this._commonController.navigate(path);
  }

  // ----------------------------------------------------

  @action.bound
  public async applyPositionGroupForPosition(position:Recruiting_Position, positionGroupId?:number):Promise<void>
  {
    const result:FetchResult<SetPositionGroupOfPositionMutation> =
      await this._commonController.mutate<SetPositionGroupOfPositionMutation,
        SetPositionGroupOfPositionMutationVariables>({
        mutation: SetPositionGroupOfPositionDocument,
        variables: {
          id: position.id,
          position_group_id: positionGroupId
        }
      });

    console.log('%c setPositionGroupForPosition result =', 'background:#0f0;color:#000;', result);
    const returning = result?.data?.update_recruiting_position_by_pk;
    console.log('%c --- returning =', 'background:#080;color:#000;', returning);

    if( returning )
    {
      runInAction(() =>
      {
        position.position_group_id = positionGroupId;
      });
    }
  }

  // ----------------------------------------------------
}
