import { MenuDivider, MenuItem } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { ButtonSize } from '@flow/common/components/form/Button';
import { TextInput } from '@flow/common/components/form/TextInput';
import { Dialog } from '@flow/common/components/page/Dialog';
import { DialogRow } from '@flow/common/components/page/DialogRow';
import { DialogSelectButton } from '@flow/common/components/page/DialogSelectButton';
import { Intent } from '@flow/common/components/types/Types';
import { FilesUtil } from '@flow/common/utils/FilesUtil';
import { Common_Attachment_Type_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { runInAction } from 'mobx';
import React from 'react';
import { CandidateAttachmentTitles, CandidateController } from '../../../CandidateController';
import { CandidateState } from '../../../CandidateState';

import styles from './UploadAttachmentDialog.module.less';

const cx = classNames.bind(styles);

interface Props
{
  file:File | null;
  onUpload:() => Promise<void>;
  onRename:() => Promise<void>;
  onChooseFile:() => void;
  onDropFile:(file:File) => void;
}

interface State
{
  isDraggingOver:boolean;
}

@component
export class UploadAttachmentDialog extends React.Component<Props, State>
{
  @di private _candidateState!:CandidateState;
  @di private _candidateController!:CandidateController;

  public constructor(props:Props)
  {
    super(props);

    this.state = {
      isDraggingOver: false
    };
  }

  @bind
  private _dropHandler(event:React.DragEvent<HTMLDivElement>):void
  {
    if( !event.dataTransfer?.items.length )
      return;

    event.preventDefault();

    const file = event.dataTransfer?.items[0].getAsFile();

    if( file )
      this.props.onDropFile(file);
  }

  @bind
  private _attachmentTypeItemRenderer(item:Common_Attachment_Type_Enum,
                                      { index, handleClick, modifiers }:IItemRendererProps):JSX.Element
  {
    const { uploadFileType } = this._candidateState;

    if( !item )
      return <MenuDivider key={index} />;

    return (
      <MenuItem
        key={item}
        text={CandidateAttachmentTitles.get(item)}
        onClick={handleClick}
        // selected={uploadFileType == item}
        active={modifiers.active}
      />
    );
  }

  public render():React.ReactNode
  {
    const {
      isAddAttachmentDialogOpen,
      isAttachmentDialogInEditMode,
      uploadFileName,
      uploadFileType,
      fileToUpload
    } = this._candidateState;
    const { setUploadFileName } = this._candidateController;

    const { onUpload, onRename, file } = this.props;

    const { isDraggingOver } = this.state;

    const SelectType = Select.ofType<Common_Attachment_Type_Enum>();

    const fileSize:number = file ? Number(file.size) : -1;
    const fileSizeStr:string = file ? FilesUtil.formatBytes(Number(file.size)) : '';

    const isSizeLimit:boolean = fileSize > 50 * 1024 * 1024;

    return (
      <Dialog
        title={isAttachmentDialogInEditMode ? 'Rename Attachment' : 'Add Attachment'}
        isOpen={isAddAttachmentDialogOpen}
        canEscapeKeyClose
        canOutsideClickClose
        onClose={():void =>
        {
          runInAction(() => this._candidateState.isAddAttachmentDialogOpen = false);
        }}

        buttonsSize={ButtonSize.LARGE}

        primaryButtonText={isAttachmentDialogInEditMode ? 'Rename' : 'Add'}
        primaryButtonIntent={Intent.PRIMARY}
        primaryButtonOnClick={isAttachmentDialogInEditMode ? onRename : onUpload}

        isPrimaryButtonDisabled={!uploadFileName || !isAttachmentDialogInEditMode && (!uploadFileName.length || !fileToUpload || isSizeLimit)}

        secondaryButtonText={'Cancel'}
        secondaryButtonIntent={Intent.PRIMARY}
        isSecondaryButtonOutlined={true}
      >

        {
          !isAttachmentDialogInEditMode &&
          <DialogRow label={'File'}>

            <div
              className={cx(styles.dropZone, { isSizeLimit, isDraggingOver })}
              onClick={():void =>
              {
                this.props.onChooseFile();
              }}
              onDrop={this._dropHandler}
              onDragOver={(event):void =>
              {
                this.setState({ isDraggingOver: true });
                event.preventDefault();
              }}
              onDragLeave={():void =>
              {
                this.setState({ isDraggingOver: false });
              }}
              onDropCapture={():void =>
              {
                this.setState({ isDraggingOver: false });
              }}
            >
              <div className={styles.dropZoneBorder}>

                <div className={cx(styles.content, { isDraggingOver }, 'test-attach-window')}>

                  <div className={styles.circle}>
                    <img src="assets/images/common/dropzone.svg" />
                  </div>

                  <div className={styles.label}>
                    {
                      !file &&
                      <div>
                        <span>Choose a file</span>
                        <span className={styles.gray}> or drag it here</span>
                      </div>
                    }
                    {
                      (file && !isSizeLimit) &&
                      <div>
                        <span>File: {file.name}</span>
                        , <span className={cx(styles.fileSize, styles.gray)}>{fileSizeStr}</span>
                      </div>
                    }
                    {
                      (file && isSizeLimit) &&
                      <span className={styles.error}>File size is out of 50 Mb limit</span>
                    }
                  </div>

                </div>

              </div>
            </div>

          </DialogRow>
        }

        <DialogRow label={'Name'}>

          <TextInput
            value={uploadFileName || ''}
            onChange={setUploadFileName}
            autoFocus={true}
            large={true}
          />

        </DialogRow>

        {
          !isAttachmentDialogInEditMode &&
          <DialogRow label={'Type'}>

            <SelectType
              className={styles.select}
              items={Object.values(Common_Attachment_Type_Enum) as Array<Common_Attachment_Type_Enum>}
              itemRenderer={this._attachmentTypeItemRenderer}
              onItemSelect={(item):void =>
              {
                runInAction(() =>
                {
                  this._candidateState.uploadFileType = item;
                });
              }}
              filterable={false}
              popoverProps={{
                minimal: true,
                usePortal: false
              }}
            >
              <DialogSelectButton
                text={CandidateAttachmentTitles.get(String(uploadFileType))}
              />
            </SelectType>

          </DialogRow>
        }

      </Dialog>
    );
  }
}
