import { InfoPanel } from '@flow/common/components/elements/InfoPanel';
import { component } from '@flow/dependency-injection';
import { CommunicationContent } from '@flow/modules/recruiting/candidates/components/candidate/communication/CommunicationContent';
// import classNames from 'classnames/bind';
import React from 'react';

import styles from './CandidatePanelExecSummary.module.less';

// const cx = classNames.bind(styles);

@component
export class CandidatePanelExecSummary extends React.Component
{
  // ----------------------------------------------------

  public render():React.ReactNode
  {
    return (
      <InfoPanel
        className={styles.panel}
      >
        <div className={styles.content}>
          <CommunicationContent />
        </div>

      </InfoPanel>
    );
  }

  // ----------------------------------------------------
}
