import { Menu, MenuItem, Position } from '@blueprintjs/core';
import { Classes } from '@blueprintjs/popover2';
import type { IItemListRendererProps, IItemRendererProps } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import type {
  IInlineEditorField,
  IInlineEditorMultiSelectValue,
  IInlineEditorProps
} from '@flow/common/components/form/InlineEditor';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import * as React from 'react';

import styles from './InlineEditor.module.less';

const cx = classNames.bind(styles);

// interface Props extends IInlineEditorProps
// {
// }

export class InlineEditorSelect extends React.PureComponent<IInlineEditorProps>
{
  // ---------------------------------------------------------

  @bind
  private _onItemSelect(item:IInlineEditorMultiSelectValue):void
  {
    // console.log('%c _onItemSelect item =', 'background:#0f0;color:#000;', item);
    const { fields, onChangeInput } = this.props;
    const currentField:IInlineEditorField = fields[0];
    const { name } = currentField;

    const newValues:Record<string, IInlineEditorField> = {};
    newValues[name] = { name: name, value: item.value || null };

    // if( applyWhenSelect )
    // {
    //   onChange(newValues);
    // }
    // else
    // {
    onChangeInput?.(name)(item.value as string);
    // }

    // onClose?.(); // !!!
  }

  // @bind
  // private _onActiveItemChange(item:IInlineEditorMultiSelectValue | null):void
  // {
  //   console.log('%c _onActiveItemChange item =', 'background:#0f0;color:#000;', item);
  // }

  // ---------------------------------------------------------

  // @bind
  // private _onKeyDown(item:IInlineEditorMultiSelectValue):(e:React.KeyboardEvent<HTMLAnchorElement>) => void
  // {
  //   return (e:React.KeyboardEvent<HTMLAnchorElement>):void =>
  //   {
  //     console.log('%c _onKeyDown e =', 'background:#0f0;color:#000;', item, e);
  //     if( e.key === 'Enter' )
  //     {
  //       this._onItemSelect(item);
  //     }
  //   };
  // }

  // ---------------------------------------------------------

  @bind
  private _itemRenderer(item:IInlineEditorMultiSelectValue, {
    modifiers,
    index
  }:IItemRendererProps):JSX.Element | null
  {
    // if( item.isMenuDivider )
    // {
    //   return <MenuDivider />;
    // }

    const { fields } = this.props;
    const currentField:IInlineEditorField = fields[0];
    const { value: currentValue } = currentField;

    const { title, value, isGroupItem, isMenuDivider, isGroupHeader } = item;

    const isSelected:boolean = value === currentValue;

    return (
      <MenuItem
        key={`${String(title)}:${String(value)}:${index?.toString()}`}
        className={cx(styles.menuItem, { isMenuDivider, isGroupHeader })}
        active={modifiers.active}
        text={title}
        // shouldDismissPopover={true}
        // disabled={isSelected}
        icon={isGroupItem ? IconNames.BLANK : null}
        labelElement={<Icon icon={isSelected ? IconNames.TICK : null} />}

        // onKeyDown={this._onKeyDown(item)}
        // onClick={handleClick}
        // onMouseDown={handleClick}
        onMouseDown={():void => this._onItemSelect(item)}
      />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _itemListRenderer(itemListProps:IItemListRendererProps<IInlineEditorMultiSelectValue>):JSX.Element
  {
    const {
      items, itemsParentRef, renderItem
      // activeItem, filteredItems, query, renderCreateItem
    } = itemListProps;

    const renderedItems = items.map(renderItem).filter(item => item != null);

    return (
      <Menu
        ulRef={itemsParentRef}
        className={styles.selectMenu}
      >
        {renderedItems}
      </Menu>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { onClosed, onOpening, isOpen, onClose, fields } = this.props;

    const currentField:IInlineEditorField = fields[0];

    const { values, resetValue } = currentField;

    const viewedItems:Array<IInlineEditorMultiSelectValue> = (values || []).slice();

    if( resetValue )
    {
      viewedItems.splice(0, 0, { ...resetValue, isMenuDivider: true });
      // viewedItems.splice(1, 0, { isMenuDivider: true });
    }

    const InlineSelect = Select.ofType<IInlineEditorMultiSelectValue>();

    return (
      <InlineSelect
        // className={styles.selectPopoverWrapper}
        items={viewedItems}
        filterable={false}
        itemListRenderer={this._itemListRenderer}
        itemRenderer={this._itemRenderer}
        onItemSelect={this._onItemSelect}
        // onActiveItemChange={this._onActiveItemChange}
        // scrollToActiveItem={false}
        // resetOnClose={true}
        // resetOnSelect={true}
        popoverProps={{
          isOpen,
          // autoFocus: true, // (x) !!!
          className: styles.selectPopoverWrapper,
          popoverClassName: Classes.POPOVER2_DISMISS_OVERRIDE,
          // popoverClassName: Classes.POPOVER2_DISMISS,
          targetClassName: styles.selectPopoverTarget,

          shouldReturnFocusOnClose: true,
          position: Position.BOTTOM_LEFT,
          onClose: onClose,
          onClosed: onClosed,
          onOpening: onOpening,
          minimal: true,
          usePortal: false
        }}
      >
        {this.props.children}
      </InlineSelect>
    );
  }
}
