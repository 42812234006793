import { component, di } from '@flow/dependency-injection';
import { CandidateFlowState } from '@flow/modules/recruiting/candidates/CandidateFlowState';
import { ConstraintsButton } from '@flow/modules/recruiting/candidates/components/candidate/status/constraints/ConstraintsButton';
import { MoreActionsSelector } from '@flow/modules/recruiting/candidates/components/candidate/status/tabInWork/MoreActionsSelector';
import { PipelineStageSelector } from '@flow/modules/recruiting/candidates/components/candidate/status/tabInWork/PipelineStageSelector';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './SelectCandidateFlowStage.module.less';

const cx = classNames.bind(styles);

@component
export class SelectCandidateFlowStage extends React.Component
{
  @di private _candidateFlowState!:CandidateFlowState;

  // ---------------------------------------------------------

  public render():ReactNode | null
  {
    const {
      isCandidateOnFlow
    } = this._candidateFlowState;

    if( !isCandidateOnFlow ) return null;

    return (
      <div className={styles.wrapper}>

        <PipelineStageSelector />

        <ConstraintsButton />

        <MoreActionsSelector />

      </div>
    );
  }

  // ---------------------------------------------------------
}
