import { Avatar } from '@flow/common/components/elements/Avatar';
import type {
  HistoryData,
  HistoryItem,
  HistoryItemUsers,
  HistoryObject
} from '@flow/common/controllers/ObjectHistoryController';
import { ObjectHistoryController } from '@flow/common/controllers/ObjectHistoryController';
import { HasuraTableColumns, HasuraTables } from '@flow/common/models/HasuraTables';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import moment from 'moment';
import React from 'react';
import styles from './History.module.less';

interface State
{
  schemaNames:Array<string>;
  object:HasuraTables;
  linkedObjects:Array<HasuraTables>;
  objectId:number;
  linkedObjectIdName:string;
  historyData:HistoryData | null;
}

@component
export class HistoryExample extends React.Component<unknown, State>
{
  @di private _historyController!:ObjectHistoryController;

  // ----------------------------------------------------

  public constructor(props:unknown)
  {
    super(props);

    this.state = {
      schemaNames: ['recruiting'],
      object: HasuraTables.RecruitingCandidate,
      linkedObjects: [
        HasuraTables.RecruitingCandidatePositionGroup,
        HasuraTables.RecruitingCandidateAllocation,
        HasuraTables.RecruitingCandidateNote
      ],
      objectId: 1,
      linkedObjectIdName: 'candidate_id',
      historyData: null
    };
  }

  // ----------------------------------------------------

  public componentDidMount():void
  {
    this._fetch();
  }

  // ----------------------------------------------------

  @bind
  private async _fetch():Promise<void>
  {
    const historyObjects:Array<HistoryObject> = [];

    historyObjects.push({
      table: this.state.object,
      idFieldName: HasuraTableColumns.ID,
      ids: [this.state.objectId]
    });

    this.state.linkedObjects.forEach(table =>
    {
      historyObjects.push({
        table: table,
        idFieldName: this.state.linkedObjectIdName,
        ids: [this.state.objectId]
      });
    });

    // const finalData:Array<HistoryItem> = await this._historyController.getObjectOptimizedHistory(historyObjects, 0);
    const finalData:Array<HistoryItem> = await this._historyController.getObjectsHistory(historyObjects, 0);

    this.setState({ historyData: finalData });
  }

  // ----------------------------------------------------

  private _renderForm():React.ReactNode
  {
    return <div className={styles.form}>
      <div className={styles.inputs}>

        <div className={styles.fieldWrapper}>
          <label>Object table name:</label>
          <input
            className={styles.input} id={'object'}
            value={this.state.object}
            onChange={(e):void => this.setState({ object: e.target.value as HasuraTables }) as void}
          />
        </div>

        <div className={styles.fieldWrapper}>
          <label>Table names:</label>
          <textarea
            className={styles.input} id={'linkedObjects'}
            value={this.state.linkedObjects}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e):void => this.setState({ linkedObjects: e.target.value.split(',') as any }) as void}
          />
        </div>

        <div className={styles.fieldWrapper}>
          <label>Object ID:</label>
          <input
            className={styles.input} id={'objectId'}
            value={this.state.objectId}
            onChange={(e):void => this.setState({ objectId: parseInt(e.target.value) }) as void}
          />
        </div>

        <div className={styles.fieldWrapper}>
          <label>Alternative ID name:</label>
          <input
            className={styles.input} id={'alternativeIdName'}
            value={this.state.linkedObjectIdName}
            onChange={(e):void => this.setState({ linkedObjectIdName: e.target.value }) as void}
          />
        </div>

        <div className={styles.fieldWrapper}>
          <button title={'Fetch'} onClick={():unknown => this._fetch()}>Fetch</button>
        </div>
      </div>

    </div>;
  }

  // ----------------------------------------------------

  private _renderItem(item:HistoryItem):React.ReactNode
  {
    return (
      <div key={item.action_tstamp_clk} className={styles.historyItem}>
        {item.user && this._renderUserData(item.user)}
        <div className={styles.historyItemContainer}>

          <div className={styles.eventId}>
            id: {item.event_id}
          </div>

          {item.action === 'I'
            ? this._renderCreatedItem(item)
            : item.action === 'U'
              ? this._renderUpdatedItem(item)
              : this._renderDeletedItem(item)}
        </div>
      </div>
    );
  }

  // ----------------------------------------------------

  private _renderUserData(user:HistoryItemUsers):React.ReactNode
  {
    return (
      <div className={styles.userData}>
        <Avatar
          url={user?.[0]?.avatar}
        />
      </div>
    );
  }

  // ----------------------------------------------------

  private _formatValue(value:unknown):string
  {
    if( value === null )
      return '<null>';

    if( typeof (value) == 'undefined' )
      return '<undefined>';

    if( typeof (value) === 'boolean' )
      return value ? 'true' : 'false';

    if( value === '' )
      return 'empty';

    return String(value);
  }

  // ----------------------------------------------------

  private _renderCreatedItem(item:HistoryItem):React.ReactNode
  {
    const user = item.user && item.user[0];

    return <>
      <div className={styles.historyItemHeader}>
        <span>
          <b>
            {user ? `${user.first_name} ${user.last_name}` : 'admin'}
          </b> <span className={styles.actionCreated}>created</span> the <b>
            {item.full_qualified_table_name}
          </b>
          &nbsp;
          with <b>ID</b>: {item.row_data.id}
        </span>
        <div className={styles.timeFromNow}>
          {moment(Date.parse(item.action_tstamp_clk as string)).fromNow()}
        </div>
      </div>

      <table cellSpacing={0} className={styles.dataRows}>
        <tbody>
        {
          Object.keys(item.row_data as Record<string, string>)
            .filter(name => name !== 'updated_at')
            .map(insertedFieldName =>
            {
              return (
                <tr key={insertedFieldName} className={styles.historyItemFields}>
                  <td className={styles.historyItemFieldName}>
                    <b>{insertedFieldName}</b>
                  </td>
                  <td className={styles.historyItemFieldValues}>
                  <span className={styles.after}>
                    {this._formatValue(item.row_data[insertedFieldName])}
                  </span>
                  </td>
                </tr>
              );
            })
        }
        </tbody>
      </table>
    </>;
  }

  // ----------------------------------------------------

  private _renderUpdatedItem(item:HistoryItem):React.ReactNode
  {
    const user = item.user && item.user[0];

    if( !item.changed_fields )
      return;

    return <>
      <div className={styles.historyItemHeader}>
        <span>
          <b>
            {user ? `${user.first_name} ${user.last_name}` : 'admin'}
          </b> <span className={styles.actionUpdated}>updated</span> the <b>
            {item.full_qualified_table_name}
          </b>
          &nbsp;
          with <b>ID</b>: {item.row_data.id}
        </span>
        <div className={styles.timeFromNow}>
          {moment(Date.parse(item.action_tstamp_clk as string)).fromNow()}
        </div>
      </div>

      <table cellSpacing={0} className={styles.dataRows}>
        <tbody>
        {
          Object.keys(item.changed_fields as Record<string, string>)
            .filter(name => name !== 'updated_at')
            .map(changedFieldName =>
            {
              return (
                <tr key={changedFieldName} className={styles.historyItemFields}>
                  <td className={styles.historyItemFieldName}>
                    <b>{changedFieldName}</b>
                  </td>
                  <td className={styles.historyItemFieldValues}>
                  <span className={styles.before}>
                    {this._formatValue(item.row_data[changedFieldName])}
                  </span>
                    <span className={styles.arrow}>
                    →
                  </span>
                    <span className={styles.after}>
                    {this._formatValue(item.changed_fields[changedFieldName])}
                  </span>
                  </td>
                </tr>
              );
            })
        }
        </tbody>
      </table>

    </>;
  }

  // ----------------------------------------------------

  private _renderDeletedItem(item:HistoryItem):React.ReactNode
  {
    const user = item.user && item.user[0];

    return <>
      <div className={styles.historyItemHeader}>
        <span>
          <b>
            {user ? `${user.first_name} ${user.last_name}` : 'admin'}
          </b> <span className={styles.actionDeleted}>deleted</span> the <b>
            {item.full_qualified_table_name}
          </b>
          &nbsp;
          with <b>ID</b>: {item.row_data.id}
        </span>
        <div className={styles.timeFromNow}>
          {moment(Date.parse(item.action_tstamp_clk as string)).fromNow()}
        </div>
      </div>

      <table cellSpacing={0} className={styles.dataRows}>
        <tbody>
        {
          Object.keys(item.row_data as Record<string, string>)
            .filter(name => name !== 'updated_at')
            .map(deletedFieldName =>
            {
              return (
                <tr key={deletedFieldName} className={styles.historyItemFields}>
                  <td className={styles.historyItemFieldName}>
                    <b>{deletedFieldName}</b>
                  </td>
                  <td className={styles.historyItemFieldValues}>
                  <span className={styles.before}>
                    {this._formatValue(item.row_data[deletedFieldName])}
                  </span>
                  </td>
                </tr>
              );
            })
        }
        </tbody>
      </table>
    </>;
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    return (
      <div className={styles.page}>
        {this._renderForm()}
        {this.state.historyData?.map(item => this._renderItem(item as HistoryItem) as JSX.Element)}
      </div>
    );
  }

  // ----------------------------------------------------
}
