import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import styles from './ItemsCount.module.less';
import type { ReactNode } from 'react';
import React, { Component } from 'react';

const cx = classNames.bind(styles);

export interface IItemsCountProps
{
  currentPage:number;
  itemsTotal:number;
  itemsPerPage:number;
}

@component
export class ItemsCount extends Component<IItemsCountProps>
{

  @bind
  private calculateRange():[number, number]
  {
    const from = (this.props.currentPage - 1) * this.props.itemsPerPage + 1;
    let till = this.props.currentPage * this.props.itemsPerPage;
    if( till > this.props.itemsTotal )
    {
      till = this.props.itemsTotal;
    }
    return [from, till];
  }

  public render():ReactNode
  {
    return (<div
      className={cx(styles.paginationQuantity)}
    >{`${this.calculateRange()[0]} - ${this.calculateRange()[1]} of ${this.props.itemsTotal} employees`}</div>);
  }

}
