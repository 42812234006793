import type { IWithRouterProps } from '@flow/dependency-injection';
import { component } from '@flow/dependency-injection';
import { diModule } from '@flow/dependency-injection';
import { PrioritiesList } from '@flow/modules/recruiting/priorities/components/desktop/PrioritiesList';
import { PrioritiesListMobile } from '@flow/modules/recruiting/priorities/components/mobile/PrioritiesListMobile';
import React from 'react';
import { PositionsController } from '../positions/PositionsController';
import { PositionsState } from '../positions/PositionsState';
import { PrioritiesToolbar } from '../priorities/components/PrioritiesToolbar';
import { PrioritiesController } from './PrioritiesController';
import { PrioritiesState } from './PrioritiesState';

@diModule({
  providers: [
    PositionsState,
    PositionsController,
    PrioritiesController,
    PrioritiesState,
  ]
})
@component
export class Priorities extends React.Component<IWithRouterProps>
{
  public render():React.ReactNode
  {
    return (
      <>
        {/* <PositionsToolbar /> */}
        <PrioritiesToolbar />
        <PrioritiesList />
        <PrioritiesListMobile />
      </>
    );
  }
}
