import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';

import { controller, di } from '@flow/dependency-injection';
import type { ScheduleCallUser, ScheduleEvent } from '@flow/modules/_demo/schedule/ScheduleState';
import { ScheduleState } from '@flow/modules/_demo/schedule/ScheduleState';
import bind from 'bind-decorator';
import { action, runInAction } from 'mobx';
import { type Moment } from 'moment';
import moment from 'moment';

@controller
export class ScheduleController
{
  @di private _commonState!:CommonState;
  @di private _commonController!:CommonController;
  @di private _scheduleState!:ScheduleState;

  // ----------------------------------------------------

  @action.bound
  public initSchedule(scheduleId:number):void
  {
    this._scheduleState.isScheduleLoaded = false;

    console.log(`%c initSchedule ${scheduleId} result =`, 'background:#0f0;color:#000;', '???');

    runInAction(() =>
    {
      this._scheduleState.scheduleDate = Date.now();

      // TODO: remove
      this.setRandomEventTime();

      this._scheduleState.viewDate = Date.now();

      this._scheduleState.users = usersDemo;

      this._scheduleState.isScheduleLoaded = true;
    });
  }

  // ----------------------------------------------------

  @action.bound
  public setViewDate(newDate:Moment):void
  {
    this._scheduleState.viewDate = newDate.toISOString();
  }

  // ----------------------------------------------------

  @bind
  public isSelectedEventNotValid():boolean
  {
    const { users, eventStartTime, eventEndTime } = this._scheduleState;

    const selectedStartTime:Moment = moment(eventStartTime);
    const selectedEndTime:Moment = moment(eventEndTime);

    return users.some((user:ScheduleCallUser) =>
    {
      return user.events.some((event:ScheduleEvent) =>
      {
        const evStartTime:Moment = moment(event.start_time);
        const evEndTime:Moment = moment(event.end_time);

        return evStartTime.isBetween(selectedStartTime, selectedEndTime, undefined, '[)') ||
          evEndTime.isBetween(selectedStartTime, selectedEndTime, undefined, '(]') ||
          selectedStartTime.isBetween(evStartTime, evEndTime, undefined, '[)') ||
          selectedEndTime.isBetween(evStartTime, evEndTime, undefined, '(]');
      });
    });
  }

  // ----------------------------------------------------

  // TODO: remove
  @action.bound
  public setRandomEventTime():void
  {
    if( currentEventIndex < testSomeEvents.length )
    {
      this._scheduleState.eventStartTime = testSomeEvents[currentEventIndex].start_time;
      this._scheduleState.eventEndTime = testSomeEvents[currentEventIndex].end_time;
      currentEventIndex++;
    }
    else
    {
      let dayDelta:number = -moment().diff(this._scheduleState.viewDate, 'hours');
      dayDelta += dayDelta > 0 ? 1 : 0;
      dayDelta /= 24;
      // console.log('%c dayDelta =', 'background:#0f0;color:#000;', dayDelta);

      const startHour:number = Math.floor(Math.random() * 23);
      this._scheduleState.eventStartTime = setTime(startHour, Math.floor(Math.random() * 4) * 15, dayDelta);
      this._scheduleState.eventEndTime = setTime(startHour + 1 + Math.floor(Math.random() * 3), Math.floor(Math.random() * 4) * 15, dayDelta);
    }
  }

  // ----------------------------------------------------
}

// TODO: remove
let currentEventIndex:number = 0;

const testSomeEvents:Array<ScheduleEvent> = [
  {
    start_time: setTime(16, 15),
    end_time: setTime(16, 30)
  },
  {
    start_time: setTime(21, 0),
    end_time: setTime(22, 0)
  },
  {
    start_time: setTime(22, 45),
    end_time: setTime(23, 0)
  },
  {
    start_time: setTime(8, 45),
    end_time: setTime(9, 15)
  }
];

function setTime(h:number, m:number, d?:number):string
{
  const date:Moment = moment().set('hour', h).set('minute', m).set('second', 0).set('millisecond', 0);
  if( d && d > 0 ) date.add(d, 'd');
  if( d && d < 0 ) date.subtract(d * -1, 'd');
  return date.format();
}

const usersDemo:Array<ScheduleCallUser> = [
  {
    user: {
      first_name: 'Maria',
      last_name: 'M.',
      avatar: ''
    },
    time: '2.00',
    events: [
      {
        start_time: setTime(18, 45, -3),
        end_time: setTime(10, 15, -1)
      },
      {
        start_time: setTime(8, 15),
        end_time: setTime(9, 15)
      },
      {
        start_time: setTime(22, 15, -1),
        end_time: setTime(0, 15)
      },
      {
        start_time: setTime(23, 15),
        end_time: setTime(0, 15, 1)
      },
      {
        start_time: setTime(15, 45, -1),
        end_time: setTime(16, 45, -1)
      },
      {
        start_time: setTime(19, 15, -1),
        end_time: setTime(20, 15, -1)
      },
      {
        start_time: setTime(11, 45, -1),
        end_time: setTime(12, 0, -1)
      },
      {
        start_time: setTime(13, 0, -1),
        end_time: setTime(13, 15, -1)
      },
      {
        start_time: setTime(13, 30, -1),
        end_time: setTime(13, 45, -1)
      },
      {
        start_time: setTime(11, 45, 1),
        end_time: setTime(1, 15, 2)
      },
      {
        start_time: setTime(7, 15, 1),
        end_time: setTime(11, 30, 1)
      },
      {
        start_time: setTime(10, 15),
        end_time: setTime(10, 45)
      },
      {
        start_time: setTime(15, 0),
        end_time: setTime(15, 45)
      },
      {
        start_time: setTime(17, 15),
        end_time: setTime(18, 0)
      },
      {
        start_time: setTime(3, 0),
        end_time: setTime(4, 0)
      },
      {
        start_time: setTime(22, 0),
        end_time: setTime(22, 15)
      },
      {
        start_time: setTime(22, 45),
        end_time: setTime(23, 0)
      },
      {
        start_time: setTime(20, 0),
        end_time: setTime(20, 30)
      },
      {
        start_time: setTime(20, 30),
        end_time: setTime(21, 0)
      }
    ],
    busy: [
      {
        start_time: setTime(15, 0, -1),
        end_time: setTime(16, 0, -1)
      },
      {
        start_time: setTime(15, 0, -1),
        end_time: setTime(16, 0, -1)
      },
      {
        start_time: setTime(1, 0),
        end_time: setTime(6, 0)
      }
    ]
  },
  {
    user: {
      first_name: 'Brooklyn',
      last_name: 'Simmons',
      avatar: ''
    },
    time: '3.00',
    events: [
      {
        start_time: setTime(23, 0, 1),
        end_time: setTime(1, 0, 2)
      },
      {
        start_time: setTime(8, 30),
        end_time: setTime(9, 15)
      },
      {
        start_time: setTime(22, 30, -1),
        end_time: setTime(0, 15)
      },
      {
        start_time: setTime(23, 30),
        end_time: setTime(0, 15, 1)
      },
      {
        start_time: setTime(11, 45, -1),
        end_time: setTime(12, 15, -1)
      },
      {
        start_time: setTime(12, 15, -1),
        end_time: setTime(12, 30, -1)
      },
      {
        start_time: setTime(13, 15, -1),
        end_time: setTime(13, 30, -1)
      },
      {
        start_time: setTime(13, 45, -1),
        end_time: setTime(14, 0, -1)
      },
      {
        start_time: setTime(13, 0, 1),
        end_time: setTime(14, 15, 1)
      },
      {
        start_time: setTime(15, 0, 1),
        end_time: setTime(16, 30, 1)
      },
      {
        start_time: setTime(17, 0, 1),
        end_time: setTime(18, 45, 1)
      },
      {
        start_time: setTime(3, 0),
        end_time: setTime(5, 0)
      },
      {
        start_time: setTime(10, 45),
        end_time: setTime(11, 0)
      },
      {
        start_time: setTime(12, 15),
        end_time: setTime(12, 30)
      },
      {
        start_time: setTime(13, 30),
        end_time: setTime(13, 45)
      },
      {
        start_time: setTime(14, 0),
        end_time: setTime(17, 0)
      },
      {
        start_time: setTime(18, 0),
        end_time: setTime(18, 30)
      },
      {
        start_time: setTime(19, 15),
        end_time: setTime(23, 0)
      }
    ],
    busy: [
      {
        start_time: setTime(6, 0, 1),
        end_time: setTime(9, 0, 1)
      }
    ]
  },
  {
    user: {
      first_name: 'Ralph',
      last_name: 'Edwards',
      avatar: ''
    },
    time: '4.00',
    events: [
      {
        start_time: setTime(23, 0, 1),
        end_time: setTime(2, 0, 2)
      },
      {
        start_time: setTime(8, 45),
        end_time: setTime(9, 15)
      },
      {
        start_time: setTime(22, 45, -1),
        end_time: setTime(0, 15)
      },
      {
        start_time: setTime(23, 45),
        end_time: setTime(0, 15, 1)
      },
      {
        start_time: setTime(13, 15, -1),
        end_time: setTime(14, 0, -1)
      },
      {
        start_time: setTime(12, 0, -1),
        end_time: setTime(12, 15, -1)
      },
      {
        start_time: setTime(14, 0, 1),
        end_time: setTime(14, 15, 1)
      },
      {
        start_time: setTime(16, 0, 1),
        end_time: setTime(16, 30, 1)
      },
      {
        start_time: setTime(18, 0, 1),
        end_time: setTime(18, 45, 1)
      },
      {
        start_time: setTime(17, 45),
        end_time: setTime(18, 0)
      },
      {
        start_time: setTime(18, 0),
        end_time: setTime(18, 15)
      },
      {
        start_time: setTime(18, 15),
        end_time: setTime(18, 30)
      },
      {
        start_time: setTime(18, 30),
        end_time: setTime(20, 0)
      },
      {
        start_time: setTime(20, 0),
        end_time: setTime(20, 45)
      },
      {
        start_time: setTime(20, 45),
        end_time: setTime(23, 0)
      },
      {
        start_time: setTime(4, 0),
        end_time: setTime(5, 0)
      },
      {
        start_time: setTime(12, 0),
        end_time: setTime(12, 30)
      },
      {
        start_time: setTime(13, 30),
        end_time: setTime(14, 0)
      },
      {
        start_time: setTime(14, 0),
        end_time: setTime(16, 0)
      }
    ],
    busy: [
      {
        start_time: setTime(9, 0),
        end_time: setTime(11, 0)
      },
      {
        start_time: setTime(3, 0, 1),
        end_time: setTime(9, 0, 1)
      }
    ]
  }
];
