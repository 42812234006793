import type { Search } from 'history';
import type { Params } from 'react-router';

export interface IRouteValidator
{
  validate:(path:string, params:Params, search:Search) => boolean;
}

export const numericParamValidator = (param:string):IRouteValidator =>
{
  return {
    validate: (path:string, params:Params = {}/*, search:Search = '' */):boolean =>
    {
      return !!params[param]?.match(/^\d+$/);
    }
  };
};
