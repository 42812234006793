import { CommonController } from '@flow/common/CommonController';
import { Button, ButtonSize } from '@flow/common/components/form/Button';
import { Intent } from '@flow/common/components/types/Types';
import { RouteName } from '@flow/common/models/routing/RouteName';
import { component, di } from '@flow/dependency-injection';
import React from 'react';
import { RoutesConfig } from '../../../pages/RoutesConfig';
import styles from './MessagePage.module.less';

interface Props
{
  imgUrl:string;
  title:string;
  message?:string;
}

@component
export class MessagePage extends React.Component<Props>
{
  @di private _commonController!:CommonController;

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { imgUrl, title, message } = this.props;

    return (
      <div className={styles.wrapper}>

        <img
          className={styles.image}
          src={imgUrl}
          alt={title}
        />

        <div className={styles.title}>{title}</div>

        {
          message &&
          <div className={styles.message}>
            {message}
          </div>
        }

        <Button
          className={styles.button}
          intent={Intent.PRIMARY}
          text={'Go to dashboard'}
          size={ButtonSize.LARGE}
          onClick={():void =>
          {
            this._commonController.navigate(String(RoutesConfig.getRoutePath(RouteName.HOME)));
          }}
        />

      </div>
    );
  }

  // ----------------------------------------------------
}
