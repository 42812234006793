/* eslint-disable @typescript-eslint/no-explicit-any */
type LocalStorageItem = Record<string, any>;

export class LocalStorageUtil
{
  // ----------------------------------------------------

  private static readonly ROOT_KEY = 'flow';

  // ----------------------------------------------------

  private static getRootItem():LocalStorageItem
  {
    const rootItem:string | null = window.localStorage.getItem(LocalStorageUtil.ROOT_KEY);

    let rootObject:LocalStorageItem;

    try
    {
      rootObject = JSON.parse(rootItem || '{}');
    }
    catch( e )
    {
      rootObject = {};
    }

    return rootObject;
  }

  public static setRootItem(rootObject:LocalStorageItem):void
  {
    window.localStorage.setItem(LocalStorageUtil.ROOT_KEY, JSON.stringify(rootObject));
  }

  // ----------------------------------------------------

  public static getItem(key:string):any
  {
    const rootObject:any = LocalStorageUtil.getRootItem();

    return rootObject[key];
  }

  public static setItem(key:string, value:any):void
  {
    const rootObject:LocalStorageItem = LocalStorageUtil.getRootItem();

    rootObject[key] = value;

    LocalStorageUtil.setRootItem(rootObject);
  }

  public static removeItem(key:string):void
  {
    const rootObject:LocalStorageItem = LocalStorageUtil.getRootItem();

    delete rootObject[key];

    LocalStorageUtil.setRootItem(rootObject);
  }

  // ----------------------------------------------------
}
