import type { IInlineEditorMultiSelectValue } from '@flow/common/components/form/InlineEditor';
import { Maybe } from '@flow/common/models/Types';
import type { Common_City, Common_Country, Recruiting_Candidate } from '@flow/data-access/lib/types/graphql.generated';
import { state } from '@flow/dependency-injection';
import { computed, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

@state
export class CandidateSiteSubsiteState
{
  @observable public countries:Array<Common_Country> = [];
  @observable public cities:Array<Common_City> = [];

  // ----------------------------------------------------

  @computed
  public get siteSubsiteValuesForSelect():Array<IInlineEditorMultiSelectValue>
  {
    const countriesWithCitiesObj:Record<number, Array<IInlineEditorMultiSelectValue>> = {};

    this.cities.forEach((city:Common_City) =>
    {
      if( !countriesWithCitiesObj[city.country_id] )
      {
        countriesWithCitiesObj[city.country_id] = [];
      }
      countriesWithCitiesObj[city.country_id].push({
        title: city.name,
        value: `${city.country_id}:${city.id}`
      });
    });

    const values:Array<IInlineEditorMultiSelectValue> = [];

    this.countries
      .slice()
      .sort((a, b) => a.name > b.name ? 1 : -1)
      .forEach((country:Common_Country) =>
      {
        values.push({
          title: country.name,
          value: `${country.id}:0`,
          isGroupHeader: true
        });

        countriesWithCitiesObj[country.id]
          .slice()
          .sort((a, b) => (a.title || '') > (b.title || '') ? 1 : -1)
          .forEach((city:IInlineEditorMultiSelectValue) =>
          {
            values.push({
              ...city,
              isGroupItem: true
            });
          });
      });

    // example:
    // siseSubsite values = [ { title, value, isGroupItem? | isGroupHeader? }, ... ]

    // title   | value |

    // Belarus | 1:0   | isGroupHeader = true
    // Minsk   | 1:1   | isGroupItem   = true
    // Brest   | 1:2   | isGroupItem   = true
    // Ukraine | 4:0   | isGroupHeader = true
    // Kyiv    | 4:7   | isGroupItem   = true
    // Lviv    | 4:8   | isGroupItem   = true

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return values;
  }

  // ----------------------------------------------------

  // example: value = '2:5' => country = 2, city = 5
  public getSiteSubsiteStringBySelectValue:(value:string) => string =
    computedFn((value:string):string =>
    {
      const [countryId, cityId] = value.split(':');

      const countryStr:string = countryId ? this.countryById(parseInt(countryId))?.name || '' : '';
      const cityStr:string = cityId ? this.cityById(parseInt(cityId))?.name || '' : '';

      return `${countryStr}${countryStr ? (cityStr ? ' • ' : '') + cityStr : ''}`;
    }, true);

  // ----------------------------------------------------

  public countryById:(id:Maybe<number>) => Maybe<Common_Country> =
    computedFn((id:Maybe<number>):Maybe<Common_Country> =>
    {
      return this.countries.find((country:Common_Country) => country.id === id);
    });

  public cityById:(id:Maybe<number>) => Maybe<Common_City> =
    computedFn((id:Maybe<number>):Maybe<Common_City> =>
    {
      return this.cities.find((city:Common_City) => city.id === id);
    }, true);

  // ----------------------------------------------------

  public siteSubsiteValueForSelect:(candidate:Recruiting_Candidate | null) => string =
    computedFn((candidate:Recruiting_Candidate | null):string =>
    {
      if( !candidate ) return '';

      return `${candidate.country_id ?? 0}:${candidate.city_id ?? 0}`;
    }, true);

  public siteSubsiteString:(candidate:Recruiting_Candidate) => string =
    computedFn((candidate:Recruiting_Candidate):string =>
    {
      if( !candidate ) return '';

      const countryStr:string = candidate.country_id ? this.countryById(candidate.country_id)?.name || '' : '';
      const cityStr:string = candidate.city_id ? this.cityById(candidate.city_id)?.name || '' : '';

      return `${countryStr}${countryStr ? (cityStr ? ' • ' : '') + cityStr : ''}`;
    }, true);

  // ----------------------------------------------------
}
