import { Intent } from '@blueprintjs/core';
import { controller, di } from '@flow/dependency-injection';
import { AppErrorToaster, AppToaster } from '../../pages/App';
import type { IconName } from '../components/form/Icon';
import { Icon, IconNames } from '../components/form/Icon';
import classNames from 'classnames/bind';
import { ErrorToast } from './ErrorToast';
import bind from 'bind-decorator';
import styles from './Toasts.module.less';
import type { ToastData } from './ToastsState';
import { ToastsState } from './ToastsState';
import { runInAction } from 'mobx';

const cx = classNames.bind(styles);

@controller
export class ToastsController
{
  @di private readonly _toastsState!:ToastsState;

  public showSuccess(message:string | JSX.Element, timeout?:number):string
  {
    return this._showToast(message, Intent.SUCCESS, IconNames.TickCircle, timeout)
  }

  public showWarning(message:string):string
  {
    return this._showToast(message, Intent.WARNING, IconNames.WarningSign)
  }

  @bind
  public showError(message:string, stackTrace:string):void
  {
    const toastKey = `${message}_${stackTrace}`;

    const toastData:ToastData = { toastCount: 0 };
    const toast = this._toastsState.errorToasts.get(toastKey) || toastData;

    if( toast.toastId )
      AppErrorToaster.dismiss(toast.toastId)

    toast.toastCount = toast.toastCount + 1;

    const toastId = this._showErrorToast(<ErrorToast
      message={message}
      stackTrace={stackTrace}
      controller={this}
      errorCount={toast.toastCount}
    />, toastKey, toast.toastCount);
    toast.toastId = toastId;

    runInAction(() => this._toastsState.errorToasts.set(`${message}_${stackTrace}`, toast));
  }

  private _showToast(message:string | JSX.Element, intent:Intent, iconName:IconName, timeout?:number):string
  {
    return String(AppToaster.show({
      className: styles.appToaster,
      intent,
      message: (
        <div className={styles.toastContent}>
          <Icon className={styles.icon} icon={iconName} size={16}/>
          {message}
        </div>
      ),
      timeout,
    }));
  }

  @bind
  private _onToastDismiss():void
  {
    setTimeout(() =>
    {
      for( const [toastKey, toastData] of this._toastsState.errorToasts.entries() )
      {
        if( !toastData )
          continue;

        if( !AppErrorToaster.getToasts().find(toast => toast.key == toastKey) )
        {
          toastData.toastCount = 0;
          runInAction(() =>
          {
            this._toastsState.errorToasts.set(toastKey, toastData);
          })
        }
      }
    }, 1000);

  }

  @bind
  private _showErrorToast(message:string | JSX.Element, toastKey:string, toastCount:number):string
  {
    return AppErrorToaster.show({
      className: cx(styles.appErrorToaster),
      intent: Intent.NONE,
      message: (
        <div>
          <div className={styles.iconContainer}>
            <Icon className={styles.icon} icon={IconNames.ERROR} size={32}/>
            {
              toastCount > 1 &&
              <div className={styles.toastCountIcon}>
                <span className={styles.toastCount}>
                  {toastCount}
                </span>
              </div>
            }
          </div>
          {message}
        </div>
      ),
      onDismiss: this._onToastDismiss,
      timeout: 0,
    }, toastKey) as string;
  }
}
