import { Label, Radio, RadioGroup } from '@blueprintjs/core';
import { FiltersPopover } from '@flow/common/components/filters/components/FiltersPopover';
import type { IFilterComponentProps } from '@flow/common/components/filters/Filter';
import type { IFilterTimeItem, IFilterTimeValue } from '@flow/common/components/filters/time/FilterTime';
import {
  FilterTimeFormatDate,
  FilterTimeItems,
  FilterTimeNames,
  FilterTimeValueType
} from '@flow/common/components/filters/time/FilterTime';
import { FilterTimeDateInput } from '@flow/common/components/filters/time/FilterTimeDateInput';
import { DateUtil } from '@flow/common/utils/DateUtil';
import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';
// import classNames from 'classnames/bind';
import React, { Component } from 'react';

import styles from './FilterTimeSelector.module.less';

// const cx = classNames.bind(styles);

const FILTER_TIME_RADIO_GROUP_NAME:string = 'FILTER_TIME_RADIO_GROUP_NAME';

@component
export class FilterTimeSelector extends Component<IFilterComponentProps<IFilterTimeValue>>
{
  // ---------------------------------------------------------

  @bind
  private _onChangeRadio(event:React.FormEvent<HTMLInputElement>):void
  {
    const { filter, onChangeFilter } = this.props;
    const value:string = (event.target as HTMLInputElement).value;

    console.log('%c _onChangeRadio e =', 'background:#0f0;color:#000;', value, event);
    if( value !== FilterTimeValueType.RANGE )
    {
      onChangeFilter({ type: value as FilterTimeValueType });
    }
    else
    {
      onChangeFilter({ ...filter.value, type: value });
    }
  }

  // ---------------------------------------------------------

  @bind
  private _onChangeDate(isFrom:boolean)
  {
    return (selectedDate:Date, isUserChange:boolean):void =>
    {
      const { filter, onChangeFilter } = this.props;

      console.log('%c selectedDate =', 'background:#0f0;color:#000;', selectedDate, isUserChange);

      onChangeFilter({
        type: FilterTimeValueType.RANGE,
        rangeFrom: isFrom ? FilterTimeFormatDate(selectedDate) : filter.value.rangeFrom,
        rangeTo: !isFrom ? FilterTimeFormatDate(selectedDate) : filter.value.rangeTo
      });
    };
  }

  @bind
  private _onOpeningDatePicker():void
  {
    const { filter } = this.props;

    this.props.onChangeFilter({ ...filter.value, type: FilterTimeValueType.RANGE });
  }

  // ---------------------------------------------------------

  @bind
  private _renderTypeRange():React.ReactNode
  {
    const { filter } = this.props;

    const { type, rangeFrom, rangeTo } = filter.value;

    return (
      <div className={styles.radioRangeWrapper}>
        <Radio
          className={styles.radioRangeRadio}
          key={FilterTimeValueType.RANGE}
          id={FILTER_TIME_RADIO_GROUP_NAME}
          name={FILTER_TIME_RADIO_GROUP_NAME}
          label={''}
          value={FilterTimeValueType.RANGE}
          checked={type === FilterTimeValueType.RANGE}
          onChange={this._onChangeRadio}
        />
        <Label
          className={styles.radioRangeInputs}
          htmlFor={FILTER_TIME_RADIO_GROUP_NAME}
        >
          <div className={styles.row}>
            <div className={styles.rowLabel}>
              From
            </div>
            <FilterTimeDateInput
              value={rangeFrom || ''}
              onChange={this._onChangeDate(true)}
              onOpening={this._onOpeningDatePicker}
            />
          </div>

          <div className={styles.row}>
            <div className={styles.rowLabel}>
              To
            </div>
            <FilterTimeDateInput
              value={rangeTo || ''}
              onChange={this._onChangeDate(false)}
              onOpening={this._onOpeningDatePicker}
            />
          </div>

        </Label>
      </div>
    );
  }

  @bind
  private _renderFilterContent():React.ReactElement
  {
    const { filter } = this.props;
    const { type } = filter.value;

    return (
      <div className={styles.filterTimeContent}>

        <RadioGroup
          selectedValue={type}
          onChange={this._onChangeRadio}
        >
          {FilterTimeItems.map((filterItem:IFilterTimeItem, index:number) =>
          {
            const { type: itemType } = filterItem;
            const checked:boolean = itemType === type;
            const name:string = FilterTimeNames[itemType];

            return (
              <React.Fragment key={index}>
                {
                  itemType !== FilterTimeValueType.RANGE &&
                  <Radio
                    key={name}
                    name={FILTER_TIME_RADIO_GROUP_NAME}
                    label={name}
                    value={itemType}
                    checked={checked}
                    onChange={this._onChangeRadio}
                  />
                }
                {
                  itemType === FilterTimeValueType.RANGE &&
                  this._renderTypeRange()
                }
              </React.Fragment>
            );
          })}
        </RadioGroup>
      </div>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { filter, onRemoveFilter, wrapperClassName, buttonClearText } = this.props;
    const { type, rangeFrom, rangeTo } = filter.value;
    const isValueSelected:boolean = type !== FilterTimeValueType.ANY;
    let valueText:string = '';

    if( type !== FilterTimeValueType.RANGE )
    {
      valueText = FilterTimeNames[type];
    }
    else
    {
      if( rangeFrom )
      {
        // valueText += `From: ${moment(rangeFrom).format('MMM D, YYYY')} `;
        valueText += `From: ${DateUtil.formattedDate(rangeFrom)} `;
      }
      if( rangeTo )
      {
        // valueText += `To: ${moment(rangeTo).format('MMM D, YYYY')}`;
        valueText += `To: ${DateUtil.formattedDate(rangeTo)}`;
      }
    }

    const buttonText:React.ReactNode = isValueSelected
      ? <span><b>{filter.name}</b>: {valueText}</span>
      : <span>{filter.name}</span>;

    return (
      <FiltersPopover
        wrapperClassName={wrapperClassName}
        title={filter.name}
        content={this._renderFilterContent()}
        contentButtonClearText={buttonClearText}
        onClickButtonClear={onRemoveFilter}
        buttonText={buttonText}
        isValueSelected={isValueSelected}
      />
    );
  }
}
