import { InfoPanel } from '@flow/common/components/elements/InfoPanel';
import { SvgIconName } from '@flow/common/components/elements/SvgIcon';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { InlineEditor, InlineEditorType } from '@flow/common/components/form/InlineEditor';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { ToastsController } from '@flow/common/toasts/ToastsController';
import { StringUtil } from '@flow/common/utils/StringUtil';
import { component, di } from '@flow/dependency-injection';
import { StaffMemberController, StaffMemberFieldName } from '@flow/modules/staffing/staff/StaffMemberController';
import { StaffMemberState } from '@flow/modules/staffing/staff/StaffMemberState';
import { StaffSiteSubsiteState } from '@flow/modules/staffing/staff/StaffSiteSubsiteState';
import bind from 'bind-decorator';
import moment from 'moment';
// import classNames from 'classnames/bind';
import React from 'react';

import styles from './StaffMemberPanelInfo.module.less';

// const cx = classNames.bind(styles);

@component
export class StaffMemberPanelInfo extends React.Component
{
  @di private _staffMemberState!:StaffMemberState;
  @di private _staffSiteSubsiteState!:StaffSiteSubsiteState;

  @di private _staffMemberController!:StaffMemberController;
  @di private _toastsController!:ToastsController;
  @di private _roleController!:RoleController;

  // ----------------------------------------------------

  // private async _onCVUploaded(files:any):Promise<void>
  // {
  //   const result = await this._candidateController.onCVUploaded(files);

  //   if( result )
  //   {
  //     this._toastsController.showSuccess('CV document uploaded successfully');
  //   }
  //   else
  //   {
  //     this._toastsController.showWarning('Failed to upload');
  //   }
  // }

  // ----------------------------------------------------

  @bind
  private _renderFields():React.ReactNode
  {
    const { staffMember } = this._staffMemberState;

    if( !staffMember ) return null;

    const {
      siteSubsiteValuesForSelect,
      siteSubsiteValueForSelect,
      getSiteSubsiteStringBySelectValue
    } = this._staffSiteSubsiteState;

    const { onChangeStaffMemberField } = this._staffMemberController;

    const canEditStaff = this._roleController.hasPermission(FlowPermissions.EditStaff);

    const {
      user: { first_name, last_name, email },
      first_name_i18n, last_name_i18n, middle_name_i18n,
      phone, linkedin_profile_url,
      hire_date
    } = staffMember;

    return (
      <div className={styles.left}>

        <InlineEditor
          wrapperClassName={styles.phoneWrapper}
          icon={IconNames.OFFICE}
          text={'Hiring date'}
          viewText={(
             <span>
               Hiring date: {hire_date && moment(hire_date).format('MMMM D, YYYY')}
               {
                 !hire_date &&
                 <Icon
                   className={styles.errorIcon}
                   icon={IconNames.WARNING_SIGN}
                   size={13}
                 />
               }
             </span>
          )}
          fields={[{ name: StaffMemberFieldName.HIRE_DATE, value: hire_date, placeholder: 'Hiring date' }]}
          onChange={onChangeStaffMemberField}
          readonly={true}
        />

        <InlineEditor
          icon={IconNames.PERSON}
          text={`${first_name} ${last_name}`}
          fields={[
            {
              name: StaffMemberFieldName.FIRST_NAME,
              value: first_name ?? '',
              label: 'First Name',
              isRequired: true
            },
            { name: StaffMemberFieldName.LAST_NAME, value: last_name ?? '', label: 'Last Name', isRequired: true }
          ]}
          onChange={onChangeStaffMemberField}
          readonly={!canEditStaff}
        />

        <InlineEditor
          icon={IconNames.PERSON}
          iconClassName={styles.secondNameIcon}
          text={`${first_name_i18n || ''} ${middle_name_i18n || ''} ${last_name_i18n || ''}`}
          fields={[
            {
              name: StaffMemberFieldName.FIRST_NAME_I18N,
              value: first_name_i18n ?? '',
              label: 'Имя',
              placeholder: 'Имя',
              isRequired: true
            },
            {
              name: StaffMemberFieldName.MIDDLE_NAME_I18N,
              value: middle_name_i18n ?? '',
              label: 'Отчество',
              placeholder: 'Отчество'
            },
            {
              name: StaffMemberFieldName.LAST_NAME_I18N,
              value: last_name_i18n ?? '',
              label: 'Фамилия',
              placeholder: 'Фамилия',
              isRequired: true
            }
          ]}
          onChange={onChangeStaffMemberField}
          readonly={!canEditStaff}
        />

        <InlineEditor
          svgIcon={SvgIconName.LinkedIn}
          iconClassName={styles.iconLinkedIn}
          text={linkedin_profile_url ?? ''}
          fields={[
            {
              name: StaffMemberFieldName.LINKED_IN,
              value: linkedin_profile_url ?? '',
              placeholder: 'LinkedIn profile link'
            }
          ]}
          onChange={onChangeStaffMemberField}
          readonly={!canEditStaff}
          viewAsLink={StringUtil.isStringALinkedinUrl(linkedin_profile_url)}
        />

        <InlineEditor
          icon={IconNames.ENVELOPE}
          text={email ?? ''}
          fields={[{ name: StaffMemberFieldName.EMAIL, value: email ?? '', placeholder: 'Email' }]}
          onChange={onChangeStaffMemberField}
          readonly={!canEditStaff}
        />

        {/*<div className={styles.phoneAndSite}>*/}
        {/*</div>*/}

        <InlineEditor
          wrapperClassName={styles.phoneWrapper}
          icon={IconNames.PHONE}
          text={phone ?? ''}
          fields={[{ name: StaffMemberFieldName.PHONE, value: phone ?? '', placeholder: 'Phone' }]}
          onChange={onChangeStaffMemberField}
          readonly={!canEditStaff}
        />

        <InlineEditor
          type={InlineEditorType.SELECT}
          wrapperClassName={styles.siteWrapper}
          icon={IconNames.GLOBE}
          // text={siteSubsiteString}
          text={''}
          getStringForNewValue={getSiteSubsiteStringBySelectValue}
          fields={[{
            name: StaffMemberFieldName.SITE_SUBSITE,
            value: siteSubsiteValueForSelect(staffMember),
            values: siteSubsiteValuesForSelect,
            placeholder: 'Site • Subsite'
          }]}
          onChange={onChangeStaffMemberField}
          readonly={!canEditStaff}
          applyWhenSelect
        />

      </div>
    );
  }

  // ----------------------------------------------------

  @bind
  private _renderPhoto():React.ReactNode
  {
    const { staffMember } = this._staffMemberState;

    if( !staffMember ) return null;

    const { user: { avatar, first_name }, avatar_url } = staffMember;

    return (
      <div className={styles.right}>

        <div className={styles.photo}>
          {
            (avatar_url || avatar) &&
            <img
              className={styles.photoImg}
              src={avatar_url ? avatar_url : avatar ? avatar : ''}
              alt={first_name}
            />
          }
        </div>

        {/*<NeedsPermission name={FlowPermissions.EditStaff}>*/}
        {/*  <div className={styles.btnAddWrapper}>*/}
        {/*    <Button*/}
        {/*      className={styles.btnAdd}*/}
        {/*      text={'Add info'}*/}
        {/*      icon={IconNames.ADD}*/}
        {/*      minimal={true}*/}
        {/*      // onClick={this._staffController.showNewStaffUserDialog}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</NeedsPermission>*/}

        {/*<UploadInput*/}
        {/*  icon={cv_file_url ? IconNames.PAPERCLIP : undefined}*/}
        {/*  content={cv_file_url*/}
        {/*    ? <a className={styles.viewCV} href={cv_file_url} target="_blank">View CV</a>*/}
        {/*    : <span className={styles.attachCV}>Add CV...</span>*/}
        {/*  }*/}
        {/*  onUploaded={(files:any) => this._onCVUploaded(files)}*/}
        {/*  isLoading={this._candidateState.cvUploadInProgress}*/}
        {/*  wrapperClassName={styles.cvInlineEditor}*/}
        {/*  iconClassName={styles.cvIconClassName}*/}
        {/*  readonly={!canEditCandidate}*/}
        {/*/>*/}

      </div>
    );
  }

  // ----------------------------------------------------

  public render():React.ReactNode | null
  {
    const { staffMember } = this._staffMemberState;

    if( !staffMember ) return null;

    return (
      <InfoPanel
        className={styles.panel}
        // headerTitle={'Info'}
        // headerContent={
        //   <div className={styles.buttons}>
        //     <Button
        //       className={styles.btnAdd}
        //       text={'Add info'}
        //       icon={IconNames.ADD}
        //       minimal={true}
        //       // onClick={this._staffController.showNewStaffUserDialog}
        //     />
        //   </div>
        // }
      >

        <div className={styles.userInfo}>

          {this._renderFields()}
          {this._renderPhoto()}

        </div>

      </InfoPanel>
    );
  }

  // ----------------------------------------------------
}
