import { MenuDivider, MenuItem } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { DialogSelectButton } from '@flow/common/components/page/DialogSelectButton';
import { component, di } from '@flow/dependency-injection';
import { CandidatesController } from '@flow/modules/recruiting/candidates/CandidatesController';
import { CandidatesState } from '@flow/modules/recruiting/candidates/CandidatesState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './SelectRecruiter.module.less';

const cx = classNames.bind(styles);

export interface RecruiterMenuItem
{
  id?:number | null;
  name?:string;
  isMenuDivider?:boolean;
}

@component
export class SelectRecruiter extends React.Component
{
  @di private _candidatesState!:CandidatesState;
  @di private _candidatesController!:CandidatesController;

  // ----------------------------------------------------

  @bind
  private _itemRenderer(
    userItem:RecruiterMenuItem,
    { handleClick, modifiers }:IItemRendererProps):JSX.Element | null
  {
    if( !modifiers.matchesPredicate ) return null;

    const { id, name, isMenuDivider } = userItem;

    // if( isMenuDivider ) return <MenuDivider />;

    // const { newCandidateAssignedUserId } = this._candidatesState;
    // const isSelected:boolean = id === newCandidateAssignedUserId;

    return (
      <MenuItem
        key={id}
        className={cx(styles.menuItem, { isMenuDivider })}
        text={name}
        active={modifiers.active}
        onClick={handleClick}
        // selected={id === newCandidateAssignedUserId}

        // right icon
        // labelElement={<Icon icon={isSelected ? IconNames.TICK : null} />}
      />
    );
  }

  // ----------------------------------------------------

  @bind
  private _onItemSelect(userItem:RecruiterMenuItem):void
  {
    this._candidatesController.setNewCandidateAssignedUserId(userItem.id || null);
  }

  // ----------------------------------------------------

  public render():ReactNode
  {
    const { recruiterMenuItems, selectedRecruiter } = this._candidatesState;

    const BPSelect = Select.ofType<RecruiterMenuItem>();

    return (
      <BPSelect
        className={styles.select}
        items={recruiterMenuItems}
        itemRenderer={this._itemRenderer}
        onItemSelect={this._onItemSelect}
        filterable={false}
        popoverProps={{
          usePortal: false,
          minimal: true
        }}
      >
        <DialogSelectButton
          className={cx({ unassigned: !selectedRecruiter?.id })}
          text={selectedRecruiter?.name}
        />
      </BPSelect>
    );
  }

  // ----------------------------------------------------
}
