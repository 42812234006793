import { PageTitle } from '@flow/common/components/page/PageTitle';
import { component, di } from '@flow/dependency-injection';
import { StaffMemberPanelAllocation } from '@flow/modules/staffing/staff/components/member/allocation/StaffMemberPanelAllocation';
import { StaffMemberActions } from '@flow/modules/staffing/staff/components/member/StaffMemberActions';
import { StaffMemberProfileTabs } from '@flow/modules/staffing/staff/components/member/StaffMemberProfileTabs';
import { StaffMemberToolbar } from '@flow/modules/staffing/staff/components/member/StaffMemberToolbar';
import { StaffMemberState } from '@flow/modules/staffing/staff/StaffMemberState';
// import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';
import styles from './StaffMemberLayout.module.less';

// const cx = classNames.bind(styles);

@component
export class StaffMemberLayout extends React.Component
{
  @di private _staffMemberState!:StaffMemberState;

  // ---------------------------------------------------------

  public render():ReactNode | null
  {
    const { staffMemberName } = this._staffMemberState;

    if( !staffMemberName ) return null;

    return (
      <div className={styles.layout}>

        <PageTitle title={staffMemberName} />

        <div className={styles.left}>

          <StaffMemberToolbar />

          <StaffMemberProfileTabs />

        </div>

        <div className={styles.right}>

          <StaffMemberActions />

          <StaffMemberPanelAllocation />

        </div>

      </div>
    );
  }
}
