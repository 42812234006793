import type { IFilter } from '@flow/common/components/filters/Filter';
import { FilterType } from '@flow/common/components/filters/Filter';
import { FilterMultiSelectPopover } from '@flow/common/components/filters/multiSelect/FilterMultiSelectPopover';

// ---------------------------------------------------------

export type IFilterMultiSelectItemValueType = number | string;

export interface IFilterMultiSelectValue
{
  items:Array<IFilterMultiSelectItemValueType>;
}

export interface IFilterMultiSelectDataItem
{
  name:string;
  value:IFilterMultiSelectItemValueType;
  isMenuDivider?:boolean;
}

export interface IFilterMultiSelectDataItems
{
  items:Array<IFilterMultiSelectDataItem>;
}

// ---------------------------------------------------------

export const MULTI_SELECT_FILTER_EMPTY_VALUE = { items: [] };

// ---------------------------------------------------------

export const FilterMultiSelectSettings:IFilter<IFilterMultiSelectValue> = {
  type: FilterType.MULTI_SELECT,
  name: 'Multi Select',
  isSelected: false,
  graphQLTemplate: '',
  value: MULTI_SELECT_FILTER_EMPTY_VALUE,
  component: FilterMultiSelectPopover,
  filterFunction: filterFunction,
  graphQLFilter: graphQLFilter
};

// ---------------------------------------------------------

function filterFunction(this:IFilter<IFilterMultiSelectValue>, items:Array<IFilterMultiSelectItemValueType>):boolean
{
  if( !this.value.items?.length ) return true;

  return this.value.items.some((value:IFilterMultiSelectItemValueType) => items.includes(value));
}

// ---------------------------------------------------------

function graphQLFilter(this:IFilter<IFilterMultiSelectValue>):string
{
  if( !this.value.items?.length ) return '';

  if( !this.graphQLTemplate )
    throw new Error(`Cannot apply filter ${this.name}: graphQLTemplate is not defined`);

  return this.graphQLTemplate.replace('{{items}}', JSON.stringify(this.value.items));
}
