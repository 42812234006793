import { CustomerAndTeam } from '@flow/common/components/elements/CustomerAndTeam';
import { IPositionsCount, PositionsUtil } from '@flow/common/utils/PositionsUtil';
import type { Common_Customer, Staffing_Customer_Team } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import type {
  Recruiting_Position_Ex,
  Staffing_Customer_Team_Ex
} from '@flow/modules/customers/teams/models/CustomersTypes';
import { PositionsListTeamPosition } from '@flow/modules/recruiting/positions/components/PositionsListTeamPosition';
import bind from 'bind-decorator';
// import classNames from 'classnames/bind';
import { computed } from 'mobx';
import React from 'react';
import { PositionsController } from '../PositionsController';
import { PositionsState } from '../PositionsState';

import styles from './PositionsListTeam.module.less';

// const cx = classNames.bind(styles);

interface Props
{
  customer:Common_Customer;
  team:Staffing_Customer_Team_Ex;

  index:number;
  teams:Array<Staffing_Customer_Team>;
}

@component
export class PositionsListTeam extends React.Component<Props>
{
  @di private _positionsState!:PositionsState;
  @di private _positionsController!:PositionsController;

  // ---------------------------------------------------------

  @computed
  private get _positionsCount():IPositionsCount
  {
    const { team: { positions } } = this.props;

    return PositionsUtil.getPositionsCount(positions);
  }

  // ---------------------------------------------------------

  @bind
  private _renderPosition(position:Recruiting_Position_Ex, index:number, positions:Array<Recruiting_Position_Ex>):React.ReactNode
  {
    return (
      <PositionsListTeamPosition
        key={position.id}
        position={position}
        index={index}
        positions={positions}
      />
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode | null
  {
    const { open } = this._positionsCount;

    if( !open ) return null;

    // ----------------------

    const { customer, team } = this.props;

    if( !team.positions.length ) return null;

    return (
      <div className={styles.teamWrapper}>

        <CustomerAndTeam items={[customer.alias, team.name]} />

        <div className={styles.positions}>
          {team.positions.map(this._renderPosition)}
        </div>

      </div>
    );
  }

  // ---------------------------------------------------------
}
