// import { Menu, MenuItem, Position } from '@blueprintjs/core';
// import { Classes, Popover2 } from '@blueprintjs/popover2';
// import { Button } from '@flow/common/components/form/Button';
// import { Icon, IconNames } from '@flow/common/components/form/Icon';
// import { NeedsPermission } from '@flow/common/components/NeedsPermission';
// import { ButtonGroupFilter } from '@flow/common/components/page/ButtonGroupFilter';
import { Toolbar } from '@flow/common/components/page/Toolbar';
// import { Intent } from '@flow/common/components/types/Types';
// import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { component } from '@flow/dependency-injection';
// import { PositionsController } from '@flow/modules/recruiting/positions/PositionsController';
// import {
//   PositionsFilter,
//   PositionsFilterTitles,
//   PositionsState
// } from '@flow/modules/recruiting/positions/PositionsState';
// import { PrioritiesController } from '@flow/modules/recruiting/priorities/PrioritiesController';
// import { PrioritiesState } from '@flow/modules/recruiting/priorities/PrioritiesState';
// import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './PositionsToolbar.module.less';

const cx = classNames.bind(styles);

@component
export class PositionsToolbar extends React.Component
{
  // @di private _positionsState!:PositionsState;
  // @di private _positionsController!:PositionsController;

  // @di private _prioritiesState!:PrioritiesState;
  // @di private _prioritiesController!:PrioritiesController;

  // ---------------------------------------------------------

  // @bind
  // private _renderMenu():React.ReactElement
  // {
  //   const { positionsFilterValue } = this._positionsState;

  //   return (
  //     <Menu>
  //       <MenuItem
  //         text={'Positions'}
  //         selected={positionsFilterValue === PositionsFilter.POSITIONS}
  //         onClick={():void => this._positionsController.setPositionsFilter(PositionsFilter.POSITIONS)}
  //       />
  //       <MenuItem
  //         text={'Priorities'}
  //         selected={positionsFilterValue === PositionsFilter.PRIORITIES}
  //         onClick={():void => this._positionsController.setPositionsFilter(PositionsFilter.PRIORITIES)}
  //       />
  //     </Menu>
  //   );
  // }

  // ---------------------------------------------------------

  // @bind
  // private _renderMobilePositionsFilter():JSX.Element | null
  // {
  //   const { positionsFilterValue } = this._positionsState;
  //   const { isEditMode } = this._prioritiesState;

  //   if( isEditMode ) return null;

  //   const title:string = positionsFilterValue === PositionsFilter.PRIORITIES ? 'Priorities' : 'Positions';

  //   return (
  //     <Popover2
  //       className={styles.priorityCellViewPopoverPlace}
  //       popoverClassName={cx(Classes.POPOVER2_DISMISS, styles.priorityCellViewPopoverWrapper)}
  //       content={this._renderMenu()}
  //       position={Position.BOTTOM_LEFT}
  //       modifiers={{ arrow: { enabled: false } }}
  //     >
  //       <div className={cx(styles.toolbarTitle, styles.mobile)}>
  //         {title}
  //         <Icon
  //           className={styles.toolbarTitleIcon}
  //           icon={IconNames.CHEVRON_DOWN}
  //         />

  //       </div>
  //     </Popover2>
  //   );
  // }

  // // ---------------------------------------------------------

  // @bind
  // private _renderDesktopPositionsFilter():JSX.Element
  // {
  //   const { positionsFilterValue } = this._positionsState;

  //   return (
  //     <div className={cx(styles.positionsFilter)}>
  //       <ButtonGroupFilter
  //         buttonClassName={styles.buttonGroupBtn}
  //         buttonGroupClassName={styles.buttonGroup}
  //         titles={PositionsFilterTitles}
  //         values={[
  //           PositionsFilter.POSITIONS,
  //           PositionsFilter.PRIORITIES
  //         ]}
  //         value={positionsFilterValue || PositionsFilter.POSITIONS}
  //         onClick={this._positionsController.setPositionsFilter}
  //       />
  //     </div>
  //   );
  // }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    // const { positionsFilterValue } = this._positionsState;

    // const { isEditMode, isViewMode, mobileViewMode } = this._prioritiesState;

    // const mobileViewByUsers:boolean = mobileViewMode == PrioritiesMobileViewMode.BY_USERS;

    // const positionStatusIsActive:boolean = filterByPositionGroupStatus === PositionGroupStatus.ACTIVE;

    return (
      <>
        <Toolbar
          className={styles.toolbar}
          title={''}

          leftClassName={styles.positionLeft}
          leftElements={
            <>
              <div className={cx(styles.toolbarTitle)}>
                Positions
              </div>
              {/* {this._renderMobilePositionsFilter()} */}
            </>
          }

          // centerClassName={styles.positionCenter}
          // centerElements={
          //   <>
          //     {/* {this._renderDesktopPositionsFilter()} */}
          //     {
          //       positionsFilterValue === PositionsFilter.PRIORITIES &&
          //       <ButtonGroupFilter
          //         values={[
          //           PrioritiesMobileViewMode.BY_PRIORITIES,
          //           PrioritiesMobileViewMode.BY_USERS
          //         ]}
          //         icons={{
          //           [PrioritiesMobileViewMode.BY_PRIORITIES]: IconNames.TH_LIST,
          //           [PrioritiesMobileViewMode.BY_USERS]: IconNames.PEOPLE
          //         }}
          //         value={mobileViewMode}
          //         onClick={this._prioritiesController.setFilterMobileViewMode}
          //         buttonClassName={cx(styles.mobileFilterBtn, { isEditMode })}
          //       />
          //     }
          //   </>
          // }

          // rightClassName={styles.positionRight}
          // rightElements={
          //   <>
          //     {
          //       positionsFilterValue === PositionsFilter.PRIORITIES &&
          //       <>
          //         {
          //           isViewMode &&
          //           <NeedsPermission name={FlowPermissions.EditPositionGroupPriorities}>
          //             <Button
          //               className={cx(styles.toolbarBtn, { mobileViewByUsers })}
          //               text={'Edit priorities'}
          //               intent={Intent.PRIMARY}
          //               onClick={this._prioritiesController.goToEditMode}
          //             />
          //           </NeedsPermission>
          //         }
          //         {
          //           isEditMode &&
          //           <>
          //             <Button
          //               className={cx(styles.toolbarBtn, { mobileViewByUsers })}
          //               text={'Cancel'}
          //               intent={Intent.PRIMARY}
          //               outlined={true}
          //               onClick={this._prioritiesController.cancelEditMode}
          //             />
          //             <Button
          //               className={cx(styles.toolbarBtn, { mobileViewByUsers })}
          //               text={'Apply'}
          //               intent={Intent.PRIMARY}
          //               onClick={this._prioritiesController.applyChanges}
          //               disabled={this._prioritiesController.hasDuplicates()}
          //             />
          //           </>
          //         }
          //       </>
          //     }
          //   </>
          // }
        />
      </>
    );
  }

  // ---------------------------------------------------------
}
