import { Intent, Menu, MenuDivider, MenuItem, ProgressBar } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { ButtonMore } from '@flow/common/components/elements/ButtonMore';
import { InfoPanel } from '@flow/common/components/elements/InfoPanel';
import { Button } from '@flow/common/components/form/Button';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { Spinner } from '@flow/common/components/form/Spinner';
import { ConfirmationDialog } from '@flow/common/components/page/ConfirmationDialog';
import { DialogWarningMessage } from '@flow/common/components/page/DialogWarningMessage';
import { List } from '@flow/common/components/page/List';
import { Prompt } from '@flow/common/components/routing/Prompt';
import { FilesController } from '@flow/common/controllers/FilesController';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { ToastsController } from '@flow/common/toasts/ToastsController';
import { FilesUtil } from '@flow/common/utils/FilesUtil';
import { StringUtil } from '@flow/common/utils/StringUtil';
import type { Common_Attachment, Recruiting_Candidate_Attachment } from '@flow/data-access/lib/types/graphql.generated';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { component, di } from '@flow/dependency-injection';
import { environment } from 'apps/flow/src/environments/environment';
import { bind } from 'bind-decorator';
import classNames from 'classnames/bind';
import { runInAction } from 'mobx';
import React from 'react';
import { v4 } from 'uuid';
import { CandidateAttachmentTitles, CandidateController } from '../../CandidateController';
import { CandidateState } from '../../CandidateState';
import styles from './CandidatePanelAttachments.module.less';
import { UploadAttachmentDialog } from './dialogs/UploadAttachmentDialog';

const cx = classNames.bind(styles);

@component
export class CandidatePanelAttachments extends React.Component<IWithRouterProps>
{
  @di private readonly _candidateState!:CandidateState;
  @di private readonly _candidateController!:CandidateController;
  @di private readonly _filesController!:FilesController;
  @di private readonly _roleController!:RoleController;
  @di private readonly _toastsController!:ToastsController;

  private _uploadInputRef:HTMLInputElement | null = null;

  @bind
  private _fileInputChangeHandler():void
  {
    if( this._uploadInputRef?.files?.length == 1 )
    {
      const file = this._uploadInputRef?.files.item(0);

      if( file )
        this._candidateController.prepareFileForUpload(file);
    }
  }

  @bind
  private _openChooseFileDialog():void
  {
    if( this._uploadInputRef )
    {
      this._uploadInputRef.value = '';
      this._uploadInputRef.click();
    }
  }

  @bind
  private _uploadProgressCallback(e:Event):void
  {
    runInAction(() =>
    {
      this._candidateState.uploadProgressPercent = (e as ProgressEvent).loaded / (e as ProgressEvent).total;
    });
  }

  @bind
  private async _onUpload():Promise<void>
  {
    const { candidate, fileToUpload, uploadFileName, uploadFileType } = this._candidateState;

    if( !candidate )
      return;

    if( !fileToUpload || !uploadFileName || !uploadFileType )
      return;

    const uniqueName = `${v4().replace(/-/g, '')}_${uploadFileName}`;

    const path = `candidate/attachment/${candidate.id}`;

    runInAction(() =>
    {
      this._candidateState.isUploadInProgress = true;
      this._candidateState.isAddAttachmentDialogOpen = false;
    });

    const url = await this._filesController.uploadFile(fileToUpload, uniqueName, path, this._uploadProgressCallback);
    const cdnPath = url.replace(environment.cdnBaseUrl, '').replace(/^\//, '');

    const attachment:Partial<Common_Attachment> = {
      name: uploadFileName,
      type: uploadFileType,
      size: fileToUpload.size,
      mime_type: fileToUpload.type.length > 0 ? fileToUpload.type : 'application/octet-stream',
      cdn_path: cdnPath
    };

    await this._candidateController.addAttachment(attachment, candidate.id);

    runInAction(() => this._candidateState.isUploadInProgress = false);
  }

  @bind
  private async _onRename():Promise<void>
  {
    const { renameAttachmentId, uploadFileName } = this._candidateState;

    if( !renameAttachmentId || !uploadFileName )
      return;

    const result = await this._candidateController.renameAttachment(renameAttachmentId, uploadFileName);

    if( !result )
      this._toastsController.showWarning('No permissions to rename this attachment');

    runInAction(() =>
    {
      this._candidateState.isAddAttachmentDialogOpen = false;
      this._candidateState.isAttachmentDialogInEditMode = false;
    });
  }

  private _onDelete(attachmentId:number):void
  {
    runInAction(() =>
    {
      this._candidateState.deletingAttachmentId = attachmentId;
      this._candidateState.isDeleteAttachmentDialogOpen = true;
    });
  }

  @bind
  private async _onDeleteConfirm():Promise<void>
  {
    if( !this._candidateState.deletingAttachmentId )
      return;

    const result = await this._candidateController.deleteAttachment(this._candidateState.deletingAttachmentId);

    runInAction(() => this._candidateState.isDeleteAttachmentDialogOpen = false);

    if( !result )
    {
      runInAction(() => this._candidateState.deletingAttachmentId = null);
      this._toastsController.showWarning('No permissions to delete this attachment');
    }
  }

  @bind
  private async _downloadAttachment(item:Common_Attachment):Promise<void>
  {
    await this._filesController.downloadFile(String(item.cdn_path), item.name);
  }

  private _renderHeader():JSX.Element
  {
    return (
      <div className={styles.colHeaders}>

        <div className={cx(styles.col, styles.file)}>
          File
        </div>

        {/* <div className={cx(styles.col, styles.user)}>
          Uploaded by
        </div> */}

        <div className={cx(styles.col, styles.type)}>
          Type
        </div>

        <div className={cx(styles.col, styles.upload)}>
          <Button
            className={cx(styles.col, styles.uploadBtn, 'test-new-attach-btn')}
            icon={IconNames.ADD}
            text={'New attachment'}
            minimal
            onClick={():unknown => this._candidateController.openUploadAttachmentDialog()}
          />

          <input
            type="file"
            ref={(ref):unknown => this._uploadInputRef = ref}
            style={{ display: 'none' }}
            onChange={this._fileInputChangeHandler}
          />

          <UploadAttachmentDialog
            file={this._candidateState.fileToUpload}
            onChooseFile={this._openChooseFileDialog}
            onUpload={this._onUpload}
            onRename={this._onRename}
            onDropFile={this._candidateController.prepareFileForUpload}
          />
        </div>

      </div>
    );
  }

  @bind
  private _renderAttachmentItem(item:Recruiting_Candidate_Attachment):JSX.Element | null
  {
    if( !item.attachment )
      return null;

    const isDeleting = item.attachment.id === this._candidateState.deletingAttachmentId;

    return (
      <div className={cx(styles.row, { isDeleting: isDeleting })}>
        {
          isDeleting &&
          <Spinner
            noIcon
            withWave
            withWrapper
            wrapperClassName={styles.spinnerWrapper}
          />
        }
        <div className={cx(styles.colData, 'test-attaches-list')}>
          <div className={cx(styles.col, styles.attachmentNameBlock)}>
            <div className={cx(styles.attachmentNameContainer)}>
              <Icon
                className={styles.fileTypeIcon}
                icon={FilesUtil.getIconNameForMimeType(String(item.attachment.mime_type))}
              />
              <Tooltip2
                content={<div>
                  <div>
                    Name: {item.attachment.name}
                  </div>
                  <div>
                    Uploaded
                    by: {StringUtil.getUserName(item.attachment.user)}
                  </div>
                  <div>
                    Size: {FilesUtil.formatBytes(item.attachment.size)}
                  </div>
                  {
                    item.attachment.mime_type && item.attachment.mime_type.length &&
                    <div>
                      Mime type: {item.attachment.mime_type}
                    </div>
                  }
                </div>}
                className={styles.tooltip}
              >
                <div className={styles.attachmentName}>
                  {item.attachment.name}
                </div>
              </Tooltip2>
            </div>
          </div>

          {/* <div className={cx(styles.col, styles.user)}>
            {`${String(item.attachment.user.first_name)} ${String(item.attachment.user.last_name.charAt(0))}`}
          </div> */}

          <div className={cx(styles.col, styles.type)}>
            {CandidateAttachmentTitles.get(item.attachment.type)}
          </div>

          <div className={cx(styles.col, styles.menu)}>
            <ButtonMore
              usePortal={true}
              menuContent={(
                <Menu>
                  <MenuItem
                    text={'Download'}
                    onClick={():unknown => this._downloadAttachment(item.attachment)}
                  />
                  <MenuItem
                    text={'Rename'}
                    disabled={!this._roleController.hasPermission(FlowPermissions.RenameCandidateAttachment)}
                    onClick={():unknown => this._candidateController.openUploadAttachmentDialogInEditMode(item.attachment)}
                  />
                  <MenuDivider />
                  <MenuItem
                    text={'Delete'}
                    disabled={!this._roleController.hasPermission(FlowPermissions.DeleteCandidateAttachment)}
                    onClick={():unknown => this._onDelete(item.attachment.id)}
                  />
                </Menu>
              )}
            />
          </div>
          <ConfirmationDialog
            title={'Delete attachement?'}
            confirmButtonText={'Delete'}
            onConfirm={this._onDeleteConfirm}
            isOpen={this._candidateState.isDeleteAttachmentDialogOpen}
            onClose={():void =>
            {
              runInAction(() =>
              {
                this._candidateState.isDeleteAttachmentDialogOpen = false;
                this._candidateState.deletingAttachmentId = null;
              });
            }}
          >
            <DialogWarningMessage
              text={'This attachment will be deleted permanently'}
            />
          </ConfirmationDialog>
        </div>
      </div>
    );
  }

  public render():React.ReactNode
  {
    const { candidate, isUploadInProgress, uploadFileName, uploadProgressPercent } = this._candidateState;

    if( !candidate )
      return <Spinner
        // size={20}
        noIcon
        withWave
        withWrapper
        wrapperClassName={styles.spinnerWrapper}
      />;

    const availableAttachments = candidate.candidate_attachments.filter(item => !!item.attachment);

    return (
      <InfoPanel
        className={styles.panel}
      >

        {
          !isUploadInProgress &&
          <div className={styles.content}>

            <List
              header={this._renderHeader()}
              headerClassName={styles.headerWrapper}
              items={availableAttachments}
              itemRenderer={this._renderAttachmentItem}
              itemClassName={styles.itemWrapper}
              noItemsTitle={'No attachments'}
              noItemsClassName={styles.noAttachments}
              className={styles.listWrapper}
            />

          </div>
        }

        {
          isUploadInProgress && uploadFileName &&
          <>
            <Prompt
              message={`The file "${uploadFileName}" is still uploading. Cancel and navigate away?`}
              when={isUploadInProgress}
            />

            <Spinner
              noIcon
              withWave
              withWrapper
              wrapperClassName={styles.spinnerWrapper}
            />

            <div className={styles.uploadingWrapper}>

              <span>
                Uploading: {uploadFileName}
              </span>

              <ProgressBar
                intent={Intent.PRIMARY}
                value={uploadProgressPercent}
              />

            </div>

          </>
        }

      </InfoPanel>
    );
  }
}
