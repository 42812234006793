import type { IconName } from '@flow/common/components/form/Icon';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import classNames from 'classnames/bind';
import * as React from 'react';

import styles from './BlankState.module.less';

const cx = classNames.bind(styles);

export interface BlankStateProps
{
  className?:string;
  icon?:IconName;
  title?:string;
  withBorder?:boolean;
  content?:string | JSX.Element;
}

export class BlankState extends React.PureComponent<BlankStateProps>
{
  public render():React.ReactNode
  {
    const { className, icon = IconNames.INFO_SIGN, title, withBorder, content } = this.props;

    return (
      <div className={cx(styles.wrapper, className, { withBorder })}>
        <Icon
          icon={icon}
          size={20}
          className={styles.icon}
        />
        {
          title &&
          <div className={styles.title}>{title}</div>
        }
        {
          content &&
          <div className={styles.content}>{content}</div>
        }
      </div>
    );
  }
}
