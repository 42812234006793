import { Maybe } from '@flow/common/models/Types';
import type {
  Recruiting_Candidate_Status_Reason,
  Recruiting_Interview_Flow,
  Recruiting_Interview_Flow_Stage
} from '@flow/data-access/lib/types/graphql.generated';
import { Recruiting_Candidate_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { di, state } from '@flow/dependency-injection';
import { CandidateState } from '@flow/modules/recruiting/candidates/CandidateState';
import { computed, observable, toJS } from 'mobx';
import { computedFn } from 'mobx-utils';

@state
export class CandidateFlowState
{
  @di private _candidateState!:CandidateState;

  @observable public flows:Array<Recruiting_Interview_Flow> = [];
  @observable public statusReasons:Array<Recruiting_Candidate_Status_Reason> = [];

  @observable public selectedFlowId:number | null = null;

  // ----------------------------------------------------

  @observable public isShowMoveToInitialDialog:boolean = false;
  @observable public isShowStartPipelineDialog:boolean = false;

  // ----------------------------------------------------

  @observable public isShowArchiveDialog:boolean = false;
  @observable public selectedArchiveStatus:Recruiting_Candidate_Status_Enum | undefined = undefined;
  @observable public selectedArchiveReasonId:number | undefined = undefined;
  @observable public selectedArchiveNotes:string = '';

  // ----------------------------------------------------

  @computed
  public get isBtnArchiveDisabled():boolean
  {
    return !this.selectedArchiveStatus || !this.selectedArchiveReasonId;
  }

  // ----------------------------------------------------

  @computed
  public get candidateId():number
  {
    return this._candidateState.candidate?.id || -1;
  }

  @computed
  public get candidateFlowId():number
  {
    console.log('%c candidateFlowId          =', 'background:#808;color:#ff0;');

    if( this.candidateFlowStageId === -1 ) return -1;

    console.log('%c --- candidateFlowStageId =', 'background:#808;color:#ff0;', this.candidateFlowStageId);

    const flow:Recruiting_Interview_Flow | undefined = this.getFlowByStageId(this.candidateFlowStageId);

    console.log('%c --- flow =', 'background:#808;color:#ff0;', toJS(flow));

    return flow ? flow.id : -1;
  }

  // ----------------------------------------------------

  @computed
  public get isCandidateOnFlow():boolean
  {
    return this._candidateState.candidate?.status === Recruiting_Candidate_Status_Enum.Active;
  }

  @computed
  public get isCandidateInInitialStatus():boolean
  {
    return this._candidateState.candidate?.status === Recruiting_Candidate_Status_Enum.Initial;
  }

  @computed
  public get isCandidateInArchivedStatus():boolean
  {
    const { candidate } = this._candidateState;

    if( !candidate ) return false;

    const { status } = candidate;

    return status === Recruiting_Candidate_Status_Enum.Archived ||
      status === Recruiting_Candidate_Status_Enum.Declined ||
      status === Recruiting_Candidate_Status_Enum.Refused ||
      status === Recruiting_Candidate_Status_Enum.Staffed;
  }

  @computed
  public get isCandidateInDeclinedStatus():boolean
  {
    return this._candidateState.candidate?.status === Recruiting_Candidate_Status_Enum.Declined;
  }

  @computed
  public get isCandidateInRefusedStatus():boolean
  {
    return this._candidateState.candidate?.status === Recruiting_Candidate_Status_Enum.Refused;
  }

  @computed
  public get isCandidateInStaffedStatus():boolean
  {
    return this._candidateState.candidate?.status === Recruiting_Candidate_Status_Enum.Staffed;
  }

  // ----------------------------------------------------

  @computed
  public get candidateFlowStageId():number
  {
    return this.isCandidateOnFlow
      ? this._candidateState.candidate?.interview_flow_stage_id || -1
      : -1;
  }

  @computed
  public get candidateFlow():Recruiting_Interview_Flow | undefined
  {
    return this.flows?.find(
      (flow:Recruiting_Interview_Flow) => flow.id === this.candidateFlowId
    );
  }

  @computed
  public get candidateFlowStages():Array<Recruiting_Interview_Flow_Stage>
  {
    return this.candidateFlow ? [...this.candidateFlow.stages] : [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    // return [
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   { id: -1, name: 'Initial' } as any,
    //   ...(this.candidateFlow?.stages || [{ id: null, name: 'Flow is not assigned' }])
    // ];
  }

  @computed
  public get candidateFlowStage():Recruiting_Interview_Flow_Stage | undefined
  {
    return this.candidateFlowStages?.find(
      (flowStage:Recruiting_Interview_Flow_Stage) => flowStage.id === this.candidateFlowStageId
    );
  }

  // ----------------------------------------------------

  @computed
  public get selectedFlow():Recruiting_Interview_Flow | undefined
  {
    return this.flows?.find((flow:Recruiting_Interview_Flow) => flow.id === this.selectedFlowId);
  }

  // ----------------------------------------------------

  public getStageById:(stageId:number) => Recruiting_Interview_Flow_Stage | undefined =
    computedFn((stageId:number):Recruiting_Interview_Flow_Stage | undefined =>
    {
      let result:Recruiting_Interview_Flow_Stage | undefined = undefined;

      this.flows?.forEach((flow:Recruiting_Interview_Flow) =>
      {
        if( result ) return;

        flow.stages.forEach((stage:Recruiting_Interview_Flow_Stage) =>
        {
          if( result ) return;

          if( stage.id === stageId ) result = stage;
        });
      });

      return result;
    });

  // ----------------------------------------------------

  public getFlowByStageId:(stageId:number) => Recruiting_Interview_Flow | undefined =
    computedFn((stageId:number):Recruiting_Interview_Flow | undefined =>
    {
      return this.flows?.find((flow:Recruiting_Interview_Flow) =>
      {
        return flow.stages.find((stage:Recruiting_Interview_Flow_Stage) => stage.id === stageId);
      });
    });

  // ----------------------------------------------------

  public getReasonsByStatus:(status:Recruiting_Candidate_Status_Enum | undefined) => Array<Recruiting_Candidate_Status_Reason> | undefined =
    computedFn((status:Recruiting_Candidate_Status_Enum | undefined):Array<Recruiting_Candidate_Status_Reason> | undefined =>
    {
      return this.statusReasons?.filter((reason:Recruiting_Candidate_Status_Reason) =>
      {
        return reason.status === status;
      });
    });

  // ----------------------------------------------------

  public getReasonsById:(reasonId:Maybe<number>) => Recruiting_Candidate_Status_Reason | undefined =
    computedFn((reasonId:Maybe<number>):Recruiting_Candidate_Status_Reason | undefined =>
    {
      return this.statusReasons?.find((reason:Recruiting_Candidate_Status_Reason) =>
      {
        return reason.id === reasonId;
      });
    });

  // ----------------------------------------------------
}
