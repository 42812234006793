import { MenuItem } from '@blueprintjs/core';
import { type IItemRendererProps } from '@blueprintjs/select';
import { MultiSelect } from '@blueprintjs/select';
import {
  type Common_Skill_Tag
} from '@flow/data-access/lib/types/graphql.generated';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import * as React from 'react';

import styles from './SelectSkillTags.module.less';

const cx = classNames.bind(styles);

interface Props
{
  skillTags:Array<Common_Skill_Tag>;
  selectedSkillTags:Array<Common_Skill_Tag>;
  onSelect:(skillTags:Array<Common_Skill_Tag>) => void;
}

export class SelectSkillTags extends React.PureComponent<Props>
{
  private _itemRenderer(skillTag:Common_Skill_Tag, {
    handleClick,
    modifiers
  }:IItemRendererProps):JSX.Element | null
  {
    if( !modifiers.matchesPredicate )
    {
      return null;
    }

    return (
      <MenuItem
        key={skillTag.name}
        text={skillTag.name}
        onClick={handleClick}
        selected={modifiers.active}
      />
    );
  }

  private _tagRenderer(skillTag:Common_Skill_Tag):JSX.Element
  {
    return (
      <span>
        {skillTag.name}
      </span>
    );
  }

  @bind
  private _onItemSelect(skillTag:Common_Skill_Tag):void
  {
    const { onSelect, selectedSkillTags } = this.props;

    onSelect([...selectedSkillTags, skillTag]);
  }

  @bind
  private _onItemRemove(deletedSkillTag:Common_Skill_Tag):void
  {
    const { onSelect, selectedSkillTags } = this.props;

    console.log('%c _onItemRemove deletedSkillTag =', 'background:#0f0;color:#000;', deletedSkillTag);

    onSelect(selectedSkillTags.filter((tag:Common_Skill_Tag) => tag.id !== deletedSkillTag.id));
  }

  public render():React.ReactNode
  {
    const { skillTags, selectedSkillTags } = this.props;

    const SkillTagSelect = MultiSelect.ofType<Common_Skill_Tag>();

    const items:Array<Common_Skill_Tag> = skillTags.filter((tag1:Common_Skill_Tag) =>
    {
      return !selectedSkillTags.find((tag2:Common_Skill_Tag) => tag1.id === tag2.id);
    });

    return (
      <SkillTagSelect
        className={styles.select}
        items={items}
        itemRenderer={this._itemRenderer}
        onItemSelect={this._onItemSelect}
        onRemove={this._onItemRemove}
        tagRenderer={this._tagRenderer}
        selectedItems={selectedSkillTags}
        // itemPredicate={} // TODO
        placeholder={'Add skill...'}

        popoverProps={{
          minimal: true,
          popoverClassName: cx(styles.popover, styles.selectPopover)
        }}
        tagInputProps={{
          tagProps: { minimal: true, large: true, round: true }
        }}
      >
        {/*{*/}
        {/*  !selectedPositionGroup &&*/}
        {/*  <Button*/}
        {/*    className={cx(styles.btn, styles.btnPlus)}*/}
        {/*    icon={IconNames.PLUS}*/}
        {/*    minimal={true}*/}
        {/*  />*/}
        {/*}*/}
        {/*{*/}
        {/*  selectedPositionGroup &&*/}
        {/*  <Button*/}
        {/*    className={cx(styles.btn, styles.btnSelected)}*/}
        {/*    minimal={true}*/}
        {/*    text={selectedPositionGroup.name}*/}
        {/*  />*/}
        {/*}*/}
      </SkillTagSelect>
    );
  }
}
