import { CommonController } from '@flow/common/CommonController';
import { MessagePage } from '@flow/common/components/page/MessagePage';
import { Roles } from '@flow/common/models/auth/Roles';
import { RouteName } from '@flow/common/models/routing/RouteName';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di } from '@flow/dependency-injection';
import { action } from 'mobx';
import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { RoutesConfig } from '../../../pages/RoutesConfig';
import { AuthState } from '../../controllers/AuthState';

export interface IProtectedRouteProps extends IWithRouterProps
{
  routeName?:string;
  hasAccessFn?:() => boolean;
  roles?:Array<string>;
}

@componentWithRouter
export class ProtectedRoute extends Component<IProtectedRouteProps>
{
  @di private _authState!:AuthState;
  @di private _commonController!:CommonController;

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { routeName, roles, children, hasAccessFn } = this.props;

    console.log(`%cRender protected route: ${routeName}`, 'background: coral; color: black');

    const isPageAnonymous:boolean = !!roles?.includes(Roles.Anonymous);

    if( !isPageAnonymous )
    {
      // Unknown state: authorization not checked yet
      if( this._authState.isLoggedIn === null )
        return null;

      // Checked: not logged in
      if( !this._authState.isLoggedIn )
        return this._fallbackToLoginPage();

      const { user } = this._authState;

      // Call access checking function, if present
      if( hasAccessFn && !hasAccessFn() )
        return this._fallbackToNotAuthorizedPage(user?.activeRole, 'You have no permissions to view this page');

      // Is access granted by a hardcoded role list?
      if( !user?.activeRole || roles && roles.length && !roles.includes(user?.activeRole) )
        return this._fallbackToNotAuthorizedPage(user?.activeRole);
    }
    else
    {
      console.log(`%cRoute ${routeName} is Anonymous`, 'background: yellowGreen; color: black');
    }

    return <>{children}</>;
  }

  // ----------------------------------------------------

  @action.bound
  private _fallbackToLoginPage():React.ReactNode
  {
    const { location: { pathname } = {} } = this.props;

    const loginPath:string | null = RoutesConfig.getRoutePath(RouteName.LOGIN);

    return loginPath && <Navigate to={loginPath} state={{ path: pathname }} />;
  }

  // ----------------------------------------------------

  // TODO: Show Access Denied page? Or suggest a role switcher based on all user roles?
  private _fallbackToNotAuthorizedPage(role?:string, message?:string):React.ReactNode
  {
    const defaultMessage = `Users with role [${role}] are not allowed to view this page.`;

    return (
      <MessagePage
        imgUrl={'assets/images/pageNotFound/notAuthorized.svg'}
        title={'Not authorized...'}
        message={role ? message || defaultMessage : ''}
      />
    );
  }

  // ----------------------------------------------------
}
