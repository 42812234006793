import type { ApolloError, ApolloQueryResult, FetchResult } from '@apollo/client';
import type { User } from '@flow/common/models/auth/User';
import { ConfigUtil } from '@flow/common/utils/ConfigUtil';
import type {
  GetUserProfileQuery,
  GetUserProfileQueryVariables,
  LoginMutation,
  LoginMutationVariables,
  TestLoginMutation,
  TestLoginMutationVariables
} from '@flow/data-access/lib/actions.generated';
import { GetUserProfileDocument, LoginDocument, TestLoginDocument } from '@flow/data-access/lib/actions.generated';
import type { Staffing_Staff } from '@flow/data-access/lib/types/graphql.generated';
import type {
  GetRoleDisplayNameQuery,
  GetRoleDisplayNameQueryVariables,
  GetUserAsStaffQuery,
  GetUserAsStaffQueryVariables
} from '@flow/data-access/lib/user.generated';
import { GetRoleDisplayNameDocument, GetUserAsStaffDocument } from '@flow/data-access/lib/user.generated';
import { controller, di } from '@flow/dependency-injection';
import { CookieUtil } from 'apps/flow/src/common/utils/CookieUtil';
import { action, runInAction } from 'mobx';
import { CommonController } from '../CommonController';
import { WindowLocationUtil } from '../utils/WindowLocationUtil';
import { AuthState } from './AuthState';
import { RoleController } from './RoleController';

@controller
export class AuthController
{
  @di private _authState!:AuthState;
  @di private _commonController!:CommonController;
  @di private _roleController!:RoleController;

  public async login(code:string):Promise<void>
  {
    runInAction(() =>
    {
      this._authState.isLoginInProgress = true;
      this._authState.isLoginFailed = false;
    });

    const result:FetchResult<LoginMutation> = await this._commonController.mutate<LoginMutation, LoginMutationVariables>({
      mutation: LoginDocument,
      variables: { code }
    });

    if( result.data )
    {
      runInAction(() => this._authState.isLoginInProgress = false);
      this._setUserAndCookie(result.data);
      this._getCurrentRoleDisplayName();
      this._roleController.initPermissions();
    }
    else if( result.errors )
    {
      runInAction(() =>
      {
        this._authState.isLoginInProgress = false;
        this._authState.isLoginFailed = true;
      });

      throw new Error('Failed to login: ' + result.errors[0].message);
    }
  }

  // testLogin action can be paired with Ngrok.io for testing with local backend
  public async testLogin(code:string):Promise<void>
  {
    runInAction(() =>
    {
      this._authState.isLoginInProgress = true;
      this._authState.isLoginFailed = false;
    });

    try
    {
      const result:FetchResult<TestLoginMutation> = await this._commonController.mutate<TestLoginMutation, TestLoginMutationVariables>({
        mutation: TestLoginDocument,
        variables: { code: code }
      });

      if( result.data )
      {
        runInAction(() => this._authState.isLoginInProgress = false);
        this._setUserAndCookie(result.data);
        this._getCurrentRoleDisplayName();
        this._roleController.initPermissions();
      }
      else if( result.errors )
      {
        throw new Error(result.errors[0].message);
      }
    }
    catch( e:unknown )
    {
      runInAction(() =>
      {
        this._authState.isLoginInProgress = false;
        this._authState.isLoginFailed = true;
        this._authState.loginFailureReason = (e as Error).message;
      });

      throw e;
    }
  }

  @action.bound
  private _setUserAndCookie(mutation:LoginMutation | TestLoginMutation):void
  {
    let loginResponse;

    if( Object.keys(mutation).includes('action_login') )
      loginResponse = (mutation as LoginMutation).action_login;
    else
      loginResponse = (mutation as TestLoginMutation).action_login_test;

    if( loginResponse )
    {
      const user:User = {
        id: loginResponse.id,
        accessToken: loginResponse.accessToken || undefined,
        email: loginResponse.email,
        firstName: loginResponse.firstName,
        lastName: loginResponse.lastName,
        avatar: loginResponse.avatar || undefined,
        roles: loginResponse.roles,
        activeRole: loginResponse.roles[0]
        // hasRefreshToken: loginResponse.hasRefreshToken,
      };

      this._authState.user = user;
      this._authState.isLoggedIn = true;

      CookieUtil.setCookie(WindowLocationUtil.getDomainName(), CookieUtil.FLOW_AUTH_COOKIE_KEY, JSON.stringify(user));

      // if( !user.hasRefreshToken )
      // CookieUtil.setCookie(WindowLocationUtil.getRootDomain(), CookieUtil.FLOW_NEEDS_REFRESH_TOKEN, '1');
      // else
      // CookieUtil.clearCookie(WindowLocationUtil.getRootDomain(), CookieUtil.FLOW_NEEDS_REFRESH_TOKEN);
    }
    else
    {
      // TODO: error handling
      throw new Error('No login response');
    }
  }

  public setUserAndCookieFromToken(token:string):void
  {
    const user:User = {
      email: '?',
      accessToken: token
    }

    CookieUtil.setCookie(WindowLocationUtil.getDomainName(), CookieUtil.FLOW_AUTH_COOKIE_KEY, JSON.stringify(user));
  }

  public async tryLoginWithCookie():Promise<boolean>
  {
    if( ConfigUtil.isGoogleLoginDisabled() )
    {
      runInAction(() =>
      {
        this._authState.user = {
          id: ConfigUtil.getLocalUserId(),
          email: 'dev-flow@siliconmint.com',
          firstName: 'Developer',
          lastName: 'Local',
          avatar: 'assets/images/favicon.ico',
          activeRole: ConfigUtil.getHasuraRole(),
          accessToken: ConfigUtil.getDevJWTToken(),
          hasRefreshToken: true
        };

        this._authState.isLoggedIn = true;
      });

      await this._initRoleAndPermissions();
    }

    const { user, isLoggedIn } = this._authState;

    if( user && user.accessToken && isLoggedIn )
      return true;

    if( !user || !user.accessToken )
    {
      const userData = CookieUtil.getCookie(CookieUtil.FLOW_AUTH_COOKIE_KEY);
      runInAction(() =>
      {
        this._authState.user = userData ? JSON.parse(userData) : null;
      });
    }

    await this._initRoleAndPermissions();

    return this._authState.user !== null && this._authState.user.accessToken !== null;
  }

  private async _initRoleAndPermissions():Promise<void>
  {
    try
    {
      const profile = await this._commonController.query<GetUserProfileQuery, GetUserProfileQueryVariables>({
        query: GetUserProfileDocument,
        variables: {}
      });

      const { user } = this._authState;
      const { action_profile } = profile.data;

      if( action_profile )
        runInAction(() =>
        {
          if( this._authState.user )
          {
            this._authState.user.avatar = action_profile.avatar ?? undefined;
            this._authState.user.email = action_profile.email;
            this._authState.user.firstName = action_profile.firstName;
            this._authState.user.lastName = action_profile.lastName;
            this._authState.user.roles = action_profile.roles;
            this._authState.user.id = action_profile.id;
            this._authState.user.activeRole = action_profile.roles[0];
          }

          this._authState.isLoggedIn = true;
        });

      CookieUtil.setCookie(WindowLocationUtil.getDomainName(), CookieUtil.FLOW_AUTH_COOKIE_KEY, JSON.stringify(user));

      await this._roleController.initPermissions();
      this._getCurrentRoleDisplayName();
    }
    catch( e )
    {
      // ignore network errors, etc.
      if( (e as ApolloError)?.graphQLErrors?.length > 0 )
        this.logout();
    }
  }

  private async _getCurrentRoleDisplayName():Promise<void>
  {
    const result = await this._commonController.query<GetRoleDisplayNameQuery, GetRoleDisplayNameQueryVariables>({
      query: GetRoleDisplayNameDocument,
      variables: {
        name: this._authState.user?.activeRole
      }
    });

    if( result.error )
      throw result.error;

    runInAction(() =>
    {
      this._authState.currentRoleDisplayName = String(result.data.common_user_role[0].display_name);
    });
  }

  @action.bound
  public logout():void
  {
    CookieUtil.clearCookie(WindowLocationUtil.getDomainName(), CookieUtil.FLOW_AUTH_COOKIE_KEY);
    this._authState.user = null;
    this._authState.isLoggedIn = false;
  }

  // ----------------------------------------------------

  @action.bound
  public async getUserAsStaff():Promise<void>
  {
    const { user } = this._authState;

    if( !user || !user.id ) return;

    const result:ApolloQueryResult<GetUserAsStaffQuery> =
      await this._commonController.query<GetUserAsStaffQuery,
        GetUserAsStaffQueryVariables>({
        query: GetUserAsStaffDocument,
        variables: { userId: user.id }
      });

    console.log('%c getUserAsStaff result =', 'background:#0f0;color:#000;', result);

    runInAction(() =>
    {
      const { staffing_staff } = result.data;

      this._authState.userAsStaff = staffing_staff[0] as unknown as Staffing_Staff;
    });
  }

  // ----------------------------------------------------
}
