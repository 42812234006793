import { CommonController } from '@flow/common/CommonController';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { InlineEditor, InlineEditorType } from '@flow/common/components/form/InlineEditor';
import { Toolbar } from '@flow/common/components/page/Toolbar';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { RouteName } from '@flow/common/models/routing/RouteName';
import { StringUtil } from '@flow/common/utils/StringUtil';
import { component, di } from '@flow/dependency-injection';
// import classNames from 'classnames/bind';
import React from 'react';
import { RoutesConfig } from '../../../../../pages/RoutesConfig';
import { CandidateController, CandidateFieldName } from '../../CandidateController';
import { CandidateState } from '../../CandidateState';

import styles from './CandidateToolbar.module.less';

// const cx = classNames.bind(styles);

@component
export class CandidateToolbar extends React.Component
{
  @di private _commonController!:CommonController;
  @di private _candidateState!:CandidateState;
  @di private _candidateController!:CandidateController;
  @di private _roleController!:RoleController;

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { candidate, assignedToValuesForSelect, candidateName } = this._candidateState;

    if( !candidate )
    {
      return (
        <div className={styles.toolbarPlaceholder} />
      );
    }

    const { avatar_url, assigned_to } = candidate;

    const assignedName:string = StringUtil.getUserName(assigned_to);

    return (
      <Toolbar
        className={styles.toolbar}
        title={''}

        leftClassName={styles.positionLeft}
        leftElements={
          <>
            <Button
              className={styles.btnArrowLeft}
              icon={IconNames.ARROW_LEFT}
              minimal={true}
              onClick={():void =>
              {
                if( window.history.length > 1 )
                {
                  window.history.back();
                }
                else
                {
                  this._commonController.navigate(RoutesConfig.getRoutePath(RouteName.RECRUITING_CANDIDATES) as string);
                }
              }}
            />
            <Avatar
              className={styles.avatar}
              size={30}
              url={avatar_url}
            />
            {
              candidateName &&
              <div className={styles.name}>
                {candidateName}
              </div>
            }
          </>
        }

        // centerClassName={styles.positionCenter}
        // centerElements={
        //   <>
        //   </>
        // }

        rightClassName={styles.positionRight}
        rightElements={
          <>
            <InlineEditor
              viewClassName={styles.assignedName}
              type={InlineEditorType.SELECT}
              icon={IconNames.USER}
              text={assignedName}
              fields={[
                {
                  name: CandidateFieldName.ASSIGNED_TO,
                  value: (assigned_to && assigned_to.id) || null,
                  values: assignedToValuesForSelect,
                  resetValue: { title: 'Unassigned', value: null }, // first in menu
                  placeholder: 'Unassigned'
                }
              ]}
              noButtons={true}
              applyWhenSelect={true}
              onChange={this._candidateController.onChangeCandidateField}
              readonly={!this._roleController.hasPermission(FlowPermissions.ChangeCandidateAssignee)}
            />
            {/*{*/}
            {/*  assignedName &&*/}
            {/*  <div className={styles.assignedTo}>*/}
            {/*    <Icon*/}
            {/*      className={styles.assignedToIcon}*/}
            {/*      icon={IconNames.USER}*/}
            {/*      size={14}*/}
            {/*    />*/}
            {/*    {assignedName}*/}
            {/*  </div>*/}
            {/*}*/}

            {/*<Button*/}
            {/*  icon={IconNames.SHARE}*/}
            {/*  minimal={true}*/}
            {/*/>*/}

            {/*<Button*/}
            {/*  className={styles.btnMore}*/}
            {/*  icon={IconNames.MORE}*/}
            {/*  minimal={true}*/}
            {/*/>*/}
          </>
        }
      />
    );
  }
}
