import { OrderByType } from '@flow/common/components/elements/ButtonOrderBy';
import { observable, toJS } from 'mobx';

export const QUERY_STRING_COLUMNS_NAME = 'columns';
export const QUERY_STRING_ORDER_BY_NAME = 'orderBy';

export enum CandidatesColumnType
{
  NAME = 1,
  DATE_CREATED = 2,
  POSITION_GROUPS = 3,
  STATUS = 4, // interview_flow_stage ???
  ASSIGNED_TO = 5,
  CREATED_BY = 6,
  FLOW_STAGE = 7,
  SITE_SUBSITE = 8,

  // STAFF_ROLE = 'xx',
  // POSITION = 'xx',
  // CUSTOMER = 'xx',
  // CUSTOMER_TEAM = 'xx',
  // MODIFIED_BY = 'xx',
}

export interface ICandidatesColumn
{
  type:CandidatesColumnType;
  name:string;
  isVisible:boolean;
  isOrderable:boolean;
  orderBy:OrderByType | null;
  alwaysVisible?:boolean;
  graphQLTemplate:string;
  graphQLOrderBy:() => string;
}

// ----------------------------------------------------

export const CandidatesColumnsState:Array<ICandidatesColumn> = observable([
  {
    type: CandidatesColumnType.NAME,
    name: 'Name',
    isVisible: true,
    isOrderable: true,
    orderBy: OrderByType.ASC,
    alwaysVisible: true,
    graphQLTemplate: `{
      "first_name": "{{direction}}",
      "last_name": "{{direction}}"
    }`,
    graphQLOrderBy: graphQLOrderBy
  },
  {
    type: CandidatesColumnType.DATE_CREATED,
    name: 'Date Created',
    isVisible: false,
    isOrderable: true,
    orderBy: null,
    graphQLTemplate: `{
      "created_at": "{{direction}}"
    }`,
    graphQLOrderBy: graphQLOrderBy
  },
  {
    type: CandidatesColumnType.POSITION_GROUPS,
    name: 'Position Groups',
    isVisible: true,
    isOrderable: false, // !!!
    orderBy: null,
    graphQLTemplate: `{
      "created_at": "{{direction}}"
    }`,
    graphQLOrderBy: graphQLOrderBy
  },
  {
    type: CandidatesColumnType.STATUS,
    name: 'Status',
    isVisible: true,
    isOrderable: true,
    orderBy: null,
    graphQLTemplate: `{
      "status": "{{direction}}"
    }`,
    graphQLOrderBy: graphQLOrderBy
  },
  {
    type: CandidatesColumnType.FLOW_STAGE,
    name: 'Flow Stage',
    isVisible: true,
    isOrderable: true,
    orderBy: null,
    graphQLTemplate: `{
      "interview_flow_stage": {
        "name": "{{direction}}"
      }
    }`,
    graphQLOrderBy: graphQLOrderBy
  },
  {
    type: CandidatesColumnType.ASSIGNED_TO,
    name: 'Assigned To',
    isVisible: true,
    isOrderable: true,
    orderBy: null,
    graphQLTemplate: `{
      "assigned_to": {
        "first_name": "{{direction}}",
        "last_name": "{{direction}}"
      }
    }`,
    graphQLOrderBy: graphQLOrderBy
  },
  {
    type: CandidatesColumnType.CREATED_BY,
    name: 'Created By',
    isVisible: false,
    isOrderable: true,
    orderBy: null,
    graphQLTemplate: `{
      "created_by": {
        "first_name": "{{direction}}",
        "last_name": "{{direction}}"
      }
    }`,
    graphQLOrderBy: graphQLOrderBy
  },
  {
    type: CandidatesColumnType.SITE_SUBSITE,
    name: 'Site/Subsite',
    isVisible: true,
    orderBy: null,

    // TODO:
    isOrderable: false, // !!!
    graphQLTemplate: `{
      "country_id": "{{direction}}",
      "city_id": "{{direction}}"
    }`,
    graphQLOrderBy: graphQLOrderBy
  }
]);

export const CandidatesColumnsDefaultState:Array<ICandidatesColumn> = CandidatesColumnsState.map(f => toJS(f));

// ----------------------------------------------------

function graphQLOrderBy(this:ICandidatesColumn):string
{
  if( !this.orderBy )
    return '';

  return this.graphQLTemplate.replace(/{{direction}}/g, this.orderBy.toLowerCase() as string);
}
