import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di, diModule } from '@flow/dependency-injection';
import { StaffFilters } from '@flow/modules/staffing/staff/components/list/filters/StaffFilters';
import { StaffList } from '@flow/modules/staffing/staff/components/list/StaffList';
import { StaffToolbar } from '@flow/modules/staffing/staff/components/list/StaffToolbar';
import { StaffController } from '@flow/modules/staffing/staff/StaffController';
import { StaffFiltersController } from '@flow/modules/staffing/staff/StaffFiltersController';
import { StaffFiltersState } from '@flow/modules/staffing/staff/StaffFiltersState';
import { StaffSiteSubsiteState } from '@flow/modules/staffing/staff/StaffSiteSubsiteState';
import { StaffState } from '@flow/modules/staffing/staff/StaffState';
import bind from 'bind-decorator';
import React from 'react';
import type { Params } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

// TODO: remove
const __DEBUG:boolean = false;

@diModule({
  providers: [
    StaffState,
    StaffSiteSubsiteState,
    StaffFiltersState,

    StaffController,
    StaffFiltersController
  ]
})
@componentWithRouter
export class StaffListPage extends React.Component<IWithRouterProps>
{
  @di private _staffState!:StaffState;

  @di private _staffController!:StaffController;

  // ---------------------------------------------------------

  @bind
  public componentWillUnmount():void
  {
    this._staffController.disposeReactions();
  }

  // ---------------------------------------------------------

  @bind
  public componentDidMount():void
  {
    // this._commonController.setContentBackground(''); // reset to default (light-light-green)
    console.log('%c !!! componentDidMount =', 'background:#f00;color:#ff0;', this.props.params);
    this._staffController.initLocalStorageReaction();
    this.checkIfStaffPage(this.props.params as Params<string>);
  }

  @bind
  public componentDidUpdate(prevProps:Readonly<IWithRouterProps>):void
  {
    __DEBUG && console.log('%c !!! componentDidUpdate =', 'background:#f00;color:#ff0;', this.props.params);
    __DEBUG && console.log('------------------------------------ =');

    if( prevProps.params?.staffId !== this.props.params?.staffId )
    {
      __DEBUG && console.log('%c ---  prevProps.params?.staffId =', 'background:#a00;color:#ff0;', prevProps.params?.staffId);
      __DEBUG && console.log('%c --- this.props.params?.staffId =', 'background:#a00;color:#ff0;', this.props.params?.staffId);
      __DEBUG && console.log('%c prevProps =', 'background:#a00;color:#aa0;', prevProps);
      __DEBUG && console.log('%c thisProps =', 'background:#a00;color:#aa0;', this.props);
      __DEBUG && console.log('------------------------------------ =');

      if( this.props.params )
        this.checkIfStaffPage(this.props.params as Params<string>);
    }
  }

  @bind
  public checkIfStaffPage(params:Params<string>):void
  {
    __DEBUG && console.log('%c checkIfStaffPage Staff params =', 'background:#00f;color:#ff0;', params);

    if( params.staffId )
    {
      const staffId:number = parseInt(params.staffId);
      __DEBUG && console.log('%c --- staffId =', 'background:#080;color:#000;', staffId);

      this._staffController.setViewStaffId(staffId);
    }
    else
    {
      this._staffController.setViewStaffId(null);
      this._staffController.initStaffList();
    }
  }

  // --------------------------а-------------------------------

  public render():React.ReactNode
  {
    console.log('%c --- PAGE: Staff List =', 'background:#080;color:#000;');

    const { isViewStaffListPageContent } = this._staffState;

    return (
      <>
        {
          isViewStaffListPageContent &&
          <>
            <StaffToolbar />
            <StaffFilters />
            <StaffList />
            {/*<StaffForm />*/}
          </>
        }
        <Outlet />
      </>
    );
  }
}
