import { Alignment, Menu, MenuItem } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { ButtonMore } from '@flow/common/components/elements/ButtonMore';
import { SvgIcon, SvgIconName } from '@flow/common/components/elements/SvgIcon';
import { Switch } from '@flow/common/components/form/Switch';
import { CollapseList } from '@flow/common/components/page/CollapseList';
import { AuthState } from '@flow/common/controllers/AuthState';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import type { Maybe } from '@flow/common/models/Types';
import { DebugUtil } from '@flow/common/utils/DebugUtil';
import type { Common_Scheduled_Event, GoogleCalendarEvent } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { CandidateController } from '@flow/modules/recruiting/candidates/CandidateController';
import { CandidateEventsCollapseListId, CandidateState } from '@flow/modules/recruiting/candidates/CandidateState';
import { ScheduleController } from '@flow/modules/recruiting/schedule/ScheduleController';
import { ScheduleState } from '@flow/modules/recruiting/schedule/ScheduleState';
import { environment } from 'apps/flow/src/environments/environment';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { computed, runInAction } from 'mobx';
import moment from 'moment';
// import classNames from 'classnames/bind';
import React from 'react';
import { FeedbackLink } from '../feedback/FeedbackLink';

import styles from './CommunicationEventView.module.less';
import { CommunicationEventViewContent } from './CommunicationEventViewContent';

const cx = classNames.bind(styles);

interface ICandidatePanelEventFormProps
{
  event:Common_Scheduled_Event;

  scrollTop:() => void;
}

@component
export class CommunicationEventView extends React.Component<ICandidatePanelEventFormProps>
{
  @di private readonly _candidateState!:CandidateState;
  @di private readonly _candidateController!:CandidateController;
  @di private readonly _scheduleState!:ScheduleState;
  @di private readonly _scheduleController!:ScheduleController;

  @di private readonly _authState!:AuthState;
  @di private readonly _roleController!:RoleController;

  private _responseCheckInterval:NodeJS.Timeout | null = null;

  // ---------------------------------------------------------

  @computed
  private get _isUpcoming():boolean
  {
    return this.props.event.is_scheduled && !this.props.event.is_done;
  }

  @computed
  private get _isFeedback():boolean
  {
    return this.props.event.is_done && !this.props.event.is_feedback_done;
  }

  @computed
  private get _isCompleted():boolean
  {
    return this.props.event.is_done && this.props.event.is_feedback_done;
  }

  @computed
  private get _hasPermissions():boolean
  {
    return this._roleController.hasPermission(FlowPermissions.CompleteEvent);
     // && this.props.event.user_id == this._authState.user?.id;
  }

  // ---------------------------------------------------------

  private _renderConfirmationIcon():JSX.Element
  {
    const status = this.props.event.google_event_id
    && this._candidateState.eventResponses.get(this.props.event.google_event_id)?.attendees
      .filter(a => a.email == this._candidateState.candidate?.email)[0]?.responseStatus;

    let iconName;
    let iconStyle;

    switch( status )
    {
      case 'accepted':
        iconName = SvgIconName.SmallTick2Selected;
        iconStyle = cx(styles.confirmationIcon, styles.blue);
        break;
      case 'declined':
        iconName = SvgIconName.SmallDelete2;
        iconStyle = cx(styles.confirmationIcon, styles.red);
        break;
      default:
        iconName = SvgIconName.Question;
        iconStyle = cx(styles.confirmationIcon, styles.gray);
    }

    return <Tooltip2 content={`Candidate response: ${status || 'unknown'}`}>
      <SvgIcon className={iconStyle} size={16} icon={iconName}/>;
    </Tooltip2>
  }

  @bind
  private _renderFormHeader(item:Common_Scheduled_Event, index:number, items:Array<Common_Scheduled_Event>, isCollapsed:Maybe<boolean>):React.ReactNode | null
  {
    const { event } = this.props;

    const title:string = event.summary;

    const titleDate:string = moment(event.start_time).format('MMM DD');

    let pillText:string = '';
    let pillStyle:string = '';

    switch( true )
    {
      case this._isUpcoming:
        pillText = 'Upcoming';
        pillStyle = styles.upcoming;
        break;

      case this._isFeedback:
        pillText = 'Feedback';
        pillStyle = styles.feedback;
        break;

      case this._isCompleted:
        pillText = 'Completed';
        pillStyle = styles.completed;
        break;
    }

    return (
      <div className={styles.header}>

        <div className={cx(styles.title, { isCollapsed })}>
          {DebugUtil.id(event.id as number)}
          {title}
        </div>

        {/* TODO: fix placement and styles */}
        {this._renderConfirmationIcon()}

        <div className={styles.datePillWrapper}>

          <span className={cx(styles.titleDate, { hide: !isCollapsed })}>{titleDate}</span>

          <div className={cx(styles.pill, pillStyle)}>
            {pillText}
          </div>

        </div>

        <ButtonMore
          className={cx(styles.btnMore, { isVisible: this._isUpcoming && this._hasPermissions })}
          menuContent={(
            <Menu>
              <MenuItem
                text={'Edit call'}
                onClick={():void =>
                {
                  this._scheduleController.addEventForEdit(event);
                  this.props.scrollTop();
                  this._candidateController.collapseEvents(event.id);
                  this._candidateController.expandEvents([event.id]);
                }}
              />
              <MenuItem
                text={'Cancel call'}
                onClick={():void =>
                {
                  this._candidateState.isDeleteCallDialogOpen = true;
                  this._candidateState.deletingEventId = event.id;
                }}
              />
            </Menu>
          )}
        />

      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderFormBody():React.ReactNode
  {
    const { event } = this.props;

    // const isCompletedCheckboxDisabled:boolean = !this._roleController.hasPermission(FlowPermissions.CompleteEvent)
    //   || event.user_id != this._authState.user?.id
    //   || event.is_feedback_done;
    // // || event.is_done // TODO: ???

    return (
      <div className={styles.collapseListItemContentWrapper}>
        <div className={styles.collapseListItemContent}>

          <CommunicationEventViewContent
            event={event}
            attendees={this._candidateController.getEventAttendeesAsUsers(event)}
            showConferenceLink={!event.is_done}
            showTitle={false}
          />

          <div className={styles.footer}>

            <FeedbackLink event={event} />

            {
              !this._hasPermissions &&
              <span className={styles.noPermissions}>
                No organizer permissions
              </span>
            }

            {
              this._hasPermissions &&
              <Switch
                label="Recruiter call is completed"
                checked={event.is_done}
                disabled={!this._hasPermissions || (event.is_done && event.is_feedback_done)}
                alignIndicator={Alignment.RIGHT}
                className={styles.checkCallCompleted}
                onChange={(value:boolean):void =>
                {
                  this._candidateController.updateScheduledEventIsDoneStatus(event.id as number, value);
                }}
              />
            }

          </div>

        </div>
      </div>
    );
  }

  // ---------------------------------------------------------

  public _fetchEventsInfo():void
  {
    const googleEventId:string | null = this.props.event.google_event_id ?? null;

    if( !googleEventId )
      return;

    this._scheduleController.fetchEventInfo(environment.recruiting.careersEmail, googleEventId).then((result:GoogleCalendarEvent) =>
    {
      if( result )
      runInAction(() =>
      {
        this._candidateState.eventResponses.set(googleEventId, result);
      });
    }).catch(() =>
    {
      // ignored: if case there is no such event
    });
  }

  public componentDidMount():void
  {
    this._fetchEventsInfo();

    this._responseCheckInterval = setInterval(() =>
    {
      this._fetchEventsInfo();
    }, 10000);
  }

  public componentWillUnmount():void
  {
    if( this._responseCheckInterval )
      clearInterval(this._responseCheckInterval);
  }

  public render():React.ReactNode | null
  {
    const { event } = this.props;

    if( !event ) return null;

    const { deletingEventId } = this._candidateState;

    const isEventDeleting:boolean = deletingEventId === event.id;

    return (
      <CollapseList
        id={CandidateEventsCollapseListId}
        items={[event as Common_Scheduled_Event]}
        itemRenderer={this._renderFormHeader}
        customSubItemsRenderer={this._renderFormBody}
        wrapperClassName={styles.collapseListWrapper}
        itemWrapperClassName={cx(styles.collapseListItemWrapper, { isEventDeleting })}
        itemClassName={styles.collapseListItem}
        defaultCollapsed
        chevronClassName={styles.collapseListBtn}
      />
    );
  }

  // ---------------------------------------------------------
}
