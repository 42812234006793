import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { Toolbar } from '@flow/common/components/page/Toolbar';
import { Intent } from '@flow/common/components/types/Types';
import { component, di } from '@flow/dependency-injection';
import { ScheduleController } from '@flow/modules/_demo/schedule/ScheduleController';
import { ScheduleState } from '@flow/modules/_demo/schedule/ScheduleState';
// import classNames from 'classnames/bind';
import moment, { type Moment } from 'moment';
import React from 'react';

import styles from './ScheduleToolbar.module.less';

// const cx = classNames.bind(styles);

@component
export class ScheduleToolbar extends React.Component
{
  @di private _scheduleState!:ScheduleState;
  @di private _scheduleController!:ScheduleController;

  // ----------------------------------------------------

  public render():React.ReactNode | null
  {
    const { viewDate, isScheduleLoaded } = this._scheduleState;

    if( !isScheduleLoaded ) return null;

    return (
      <Toolbar
        className={styles.wrapper}
        title={''}

        leftClassName={styles.positionLeft}
        leftElements={
          <>
            <Button
              className={styles.iconBtn}
              icon={IconNames.CHEVRON_LEFT}
              minimal={true}
              onClick={():void => this._scheduleController.setViewDate(moment(viewDate).subtract(1, 'd') as Moment)}
            />
            <Button
              className={styles.iconBtn}
              icon={IconNames.CHEVRON_RIGHT}
              minimal={true}
              onClick={():void => this._scheduleController.setViewDate(moment(viewDate).add(1, 'd') as Moment)}
            />
            <div className={styles.title}>
              {moment(viewDate).format('ddd, MMM D, YYYY')}
            </div>
          </>
        }

        // centerClassName={styles.positionCenter}
        // centerElements={}

        rightClassName={styles.positionRight}
        rightElements={
          <>
            <Button
              className={styles.btn}
              text={'Today'}
              minimal={true}
              outlined={true}
              intent={Intent.PRIMARY}
              onClick={():void => this._scheduleController.setViewDate(moment() as Moment)}
            />
            <Button
              className={styles.btn}
              text={'Scheduled day'}
              minimal={true}
              outlined={true}
              intent={Intent.PRIMARY}
            />
          </>
        }
      />
    );
  }
}
