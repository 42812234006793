import type { User } from '@flow/common/models/auth/User';
import type { Staffing_Staff } from '@flow/data-access/lib/types/graphql.generated';
import { state } from '@flow/dependency-injection';
import { observable } from 'mobx';

@state
export class AuthState
{
  @observable public user:User | null = null;
  @observable public isLoggedIn:boolean | null = null;
  @observable public isLoginInProgress:boolean = false;
  @observable public isLoginFailed:boolean = false;
  @observable public loginFailureReason:string = '';

  @observable public currentRoleDisplayName:string = '';

  @observable public userAsStaff:Staffing_Staff | null = null;
}
