import { Menu, MenuDivider, MenuItem, Position } from '@blueprintjs/core';
import { Classes, Popover2 } from '@blueprintjs/popover2';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import type {
  Common_User,
  Recruiting_Position_Group,
  Recruiting_Position_Group_Priority
} from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { PrioritiesController } from '@flow/modules/recruiting/priorities/PrioritiesController';
import { PrioritiesState } from '@flow/modules/recruiting/priorities/PrioritiesState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';
import type { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

import styles from './PositionGroupSelector.module.less';

const cx = classNames.bind(styles);

// isViewMode: priority && count
// isEditMode: priority && count || isAddButton && user

interface Props
{
  className?:string;

  isEditMode?:boolean;

  priority?:Recruiting_Position_Group_Priority;
  count?:number;

  user?:Common_User;
  isAddButton?:boolean;

  provided?:DraggableProvided;
  snapshot?:DraggableStateSnapshot;
}

@component
export class PositionGroupSelector extends React.Component<Props>
{
  @di private _prioritiesState!:PrioritiesState;
  @di private _prioritiesController!:PrioritiesController;

  // ---------------------------------------------------------

  @bind
  private _renderPill():React.ReactNode | null
  {
    const {
      isEditMode, className, isAddButton, priority, count,
      provided, snapshot
    } = this.props;

    if( isEditMode && isAddButton )
    {
      // button (+)
      return (
        <div className={cx(styles.priorityPill, styles.btnAdd, className, { isEditMode })}>
          <Icon
            className={styles.btnAddIcon}
            icon={IconNames.PLUS}
            size={15}
          />
        </div>
      );
    }

    // ---------------------------

    if( !priority ) return null;

    // ---------------------------

    const { positionGroupById } = this._prioritiesState;

    const positionGroup:Recruiting_Position_Group | undefined = positionGroupById(Number(priority.position_group_id));

    if( !positionGroup ) return null;

    // ---------------------------

    const isDisabled:boolean = count === 0 && !isEditMode;

    const isDuplicate:boolean = this._prioritiesController.isDuplicate(priority as Recruiting_Position_Group_Priority);

    const isDragging:boolean = snapshot?.isDragging ?? false;

    return (
      <div
        className={cx(styles.priorityPill, className, { isEditMode, isDisabled, isDuplicate, isDragging })}
        {...provided?.dragHandleProps}
      >
        <div className={cx(styles.positionGroupName)}>
          {positionGroup.name}
        </div>
        {
          isEditMode &&
          <Icon
            className={styles.icon}
            icon={IconNames.CARET_DOWN}
          />
        }
        <div className={styles.groupCount}>
          {count}
        </div>

      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderMenu():React.ReactElement | undefined
  {
    const { isEditMode, isAddButton, priority, user } = this.props;

    const userId:number | undefined = priority?.user_id || user?.id;

    if( !isEditMode || !userId ) return undefined;

    return (
      <Menu>
        {
          !isAddButton &&
          <>
            <MenuItem
              text={'Unassigned'}
              onClick={():void =>
              {
                priority && this._prioritiesController.inStateDeletePriority(priority as Recruiting_Position_Group_Priority);
              }}
            />
            <MenuDivider />
          </>
        }
        {this._prioritiesState.positionGroups.map((positionGroup:Recruiting_Position_Group) =>
        {
          const { id, name } = positionGroup;

          return (
            <MenuItem
              key={id}
              text={name}
              className={styles.dialogMenuItem}
              selected={isAddButton ? false : id === priority?.position_group_id}
              onClick={():void =>
              {
                if( isAddButton )
                {
                  this._prioritiesController.inStateAddPriority(id, userId);
                }
                else
                {
                  priority && this._prioritiesController.inStateReplacePriority(priority as Recruiting_Position_Group_Priority, id);
                }
              }}
            />
          );
        })}
      </Menu>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode | null
  {
    const { isEditMode } = this.props;

    if( !isEditMode ) return this._renderPill();

    // isEditMode
    return (
      <Popover2
        className={styles.priorityCellViewPopoverPlace}
        popoverClassName={cx(Classes.POPOVER2_DISMISS, styles.priorityCellViewPopoverWrapper)}
        content={this._renderMenu()}
        position={Position.BOTTOM_LEFT}
        modifiers={{ arrow: { enabled: false } }}
      >
        {this._renderPill()}
      </Popover2>
    );
  }

  // ---------------------------------------------------------
}
