import { Position } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import { FilterTimeFormatDate, FilterTimeParseDate } from '@flow/common/components/filters/time/FilterTime';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { component } from '@flow/dependency-injection';
// import classNames from 'classnames/bind';
import type React from 'react';
import { Component } from 'react';

import styles from './FilterTimeSelector.module.less';

// const cx = classNames.bind(styles);

interface Props
{
  value:string;
  onChange:(selectedDate:Date, isUserChange:boolean) => void;

  onOpening:() => void;
}

@component
export class FilterTimeDateInput extends Component<Props>
{
  // ---------------------------------------------------------

  private _isValidDate(d:Date):boolean
  {
    return isFinite(d.getTime());
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { onOpening, onChange, value } = this.props;

    const dateValue:Date = FilterTimeParseDate(value);

    console.log('%c dateValue =', 'background:#0f0;color:#000;', dateValue);

    return (
      <DateInput
        className={styles.dateInput}
        parseDate={FilterTimeParseDate}
        formatDate={FilterTimeFormatDate}
        onChange={onChange}
        value={this._isValidDate(dateValue) ? dateValue : null}
        popoverProps={
          {
            position: Position.BOTTOM,
            captureDismiss: true,
            usePortal: false,
            onOpening: onOpening
          }
        }
        inputProps={
          {
            small: true,
            rightElement: (
              <Button
                className={styles.btnWithIcon}
                iconClassName={styles.btnIcon}
                icon={IconNames.CALENDAR}
                iconSize={12}
                minimal={true}
              />
            )
          }
        }
      />
    );
  }

  // ---------------------------------------------------------
}
