import { Position } from '@blueprintjs/core';
import { CommonController } from '@flow/common/CommonController';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { SvgIcon, SvgIconName } from '@flow/common/components/elements/SvgIcon';
import { Button } from '@flow/common/components/form/Button';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { InlineEditor } from '@flow/common/components/form/InlineEditor';
import { Intent } from '@flow/common/components/types/Types';
import { AuthState } from '@flow/common/controllers/AuthState';
import type { Maybe } from '@flow/common/models/Types';
import type { DateStrOrNum } from '@flow/common/utils/DateUtil';
import { DateUtil } from '@flow/common/utils/DateUtil';
import type { Common_Scheduled_Event } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { CandidateController } from '@flow/modules/recruiting/candidates/CandidateController';
import { CandidateState } from '@flow/modules/recruiting/candidates/CandidateState';
import { ScheduleController } from '@flow/modules/recruiting/schedule/ScheduleController';
import { ScheduleState } from '@flow/modules/recruiting/schedule/ScheduleState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { computed, runInAction } from 'mobx';
import { computedFn } from 'mobx-utils';
import type { Duration, Moment } from 'moment';
import moment from 'moment';
import React from 'react';
import styles from '../../../../../schedule/components/ScheduleSidebar.module.less';
import { EventAttendeesListEditor } from './EventAttendeesListEditor';
import { EventDateEditor } from './EventDateEditor';
import { EventEndTimeEditor } from './EventEndTimeEditor';
import { EventStartTimeEditor } from './EventStartTimeEditor';

const cx = classNames.bind(styles);

@component
export class CandidateScheduleSidebar extends React.Component
{
  @di private _authState!:AuthState;
  @di private _scheduleState!:ScheduleState;
  @di private _scheduleController!:ScheduleController;
  @di private _candidateState!:CandidateState;
  @di private _candidateController!:CandidateController;
  @di private _commonController!:CommonController;

  // ----------------------------------------------------

  @computed
  private get _event():Maybe<Partial<Common_Scheduled_Event>>
  {
    const { eventsForEdit, callDetailsEventId } = this._scheduleState;

    return eventsForEdit.get(callDetailsEventId || -1)?.event;
  }

  @computed
  private get _snapshot():Maybe<Partial<Common_Scheduled_Event>>
  {
    const { eventsForEdit, callDetailsEventId } = this._scheduleState;

    return eventsForEdit.get(callDetailsEventId || -1)?.snapshot;
  }

  @computed
  private get _isUpcoming():boolean
  {
    return (this._event as Common_Scheduled_Event).is_scheduled && !(this._event as Common_Scheduled_Event).is_done;
  }

  private _isChanged = computedFn((fieldName:keyof Common_Scheduled_Event, params:Maybe<string>):boolean =>
  {
    if( !this._isUpcoming || !this._event || !this._snapshot ) return false;

    if( fieldName === 'start_time' && params === 'day' )
    {
      return !DateUtil.isSameDay(this._event[fieldName] as DateStrOrNum, this._snapshot[fieldName] as DateStrOrNum);
    }

    if( fieldName === 'start_time' || fieldName === 'end_time' )
    {
      return !DateUtil.isSameTimeIgnoringDate(this._event[fieldName] as DateStrOrNum, this._snapshot[fieldName] as DateStrOrNum);
    }

    return this._event[fieldName] !== this._snapshot[fieldName];
  });

  // ----------------------------------------------------

  @bind
  private _onUpdate():void
  {
    if( !this._event || this._event.is_scheduled ) return;

    // isDraft
    this._candidateController.updateDraftEvent();
  }

  // ---------------------------------------------------------

  @bind
  private _startTimeChangeHandler(newStartTime:Moment, duration:Duration):void
  {
    console.log('%c _startTimeChangeHandler =', 'background:#0f0;color:#000;', newStartTime, duration);
    runInAction(() =>
    {
      if( !this._event ) return;

      this._event.start_time = newStartTime.toDate();
      this._event.end_time = moment(newStartTime).add(duration).toDate();

      console.log('%c --> =', 'background:#080;color:#000;', this._event.start_time, this._event.end_time);
      this._scheduleController.setViewDate(moment(this._event.start_time));
      this._onUpdate();
      this._scheduleController.fetchFreeBusyInfo(this._event);
    });
  }

  @bind
  private _endTimeChangeHandler(newEndTime:Moment):void
  {
    console.log('%c _endTimeChangeHandler =', 'background:#0f0;color:#000;', newEndTime);
    runInAction(() =>
    {
      if( !this._event ) return;

      this._event.end_time = moment(newEndTime).toDate();

      console.log('%c --> =', 'background:#080;color:#000;', this._event.end_time);
      this._onUpdate();
    });
  }

  // ----------------------------------------------------

  public render():React.ReactNode | null
  {
    const {
      allUsers,
      scheduledEventStartTimeQuery,
      scheduledEventEndTimeQuery,
      isScheduleLoaded
    } = this._scheduleState;

    if( !isScheduleLoaded ) return null;

    const { candidate, isSchedulingEvent } = this._candidateState;

    if( !candidate || !this._event || !this._snapshot ) return null;

    if( !candidate?.email ) return null;

    const { user } = this._authState;

    const userId = user?.id;

    const title = this._event.summary || `Recruiter call with ${candidate?.first_name} ${candidate?.last_name}`;

    if( !user || !userId ) return;

    const startTime:Moment = moment(this._event.start_time);
    const endTime:Moment = moment(this._event.end_time);

    const startDateStr = startTime.format('MMMM DD, YYYY');
    const startTimeStr = startTime.format('h:mm A');
    const endTimeStr = String(endTime.format('h:mm A'));
    const duration = moment.duration(endTime.diff(startTime));

    const isChangedSummary:boolean = this._isChanged('summary', '');
    const isChangedDay:boolean = this._isChanged('start_time', 'day');
    const isChangedStartTime:boolean = this._isChanged('start_time', '');
    const isChangedEndTime:boolean = this._isChanged('end_time', '');
    const isChangedAttList:boolean = this._isChanged('attendees_list', '');
    const isChangedDescription:boolean = this._isChanged('description', '');

    const isScheduleCallBtnDisabled:boolean = this._isUpcoming && !(
      isChangedSummary || isChangedAttList || isChangedDescription ||
      isChangedDay || isChangedStartTime || isChangedEndTime
    );

    return (
      <>
        <div className={styles.selectedUser}>

          <Avatar size={30} className={styles.avatar} url={candidate.avatar_url} />

          {`${candidate?.first_name} ${candidate?.last_name}`}

        </div>

        {/* ------------------------------- */}

        <div className={styles.bgGray}>
          <div className={styles.inlineEditorWrapper}>

            <InlineEditor
              textArea
              wrap
              text={title}
              isChanged={isChangedSummary}
              fields={[
                {
                  name: 'title',
                  value: title,
                  placeholder: 'Enter title'
                }
              ]}
              onChange={(value):void =>
              {
                runInAction(() =>
                {
                  if( this._event )
                  {
                    this._event.summary = String(value['title'].value);
                  }
                  this._onUpdate();
                });
              }}
            />

          </div>
        </div>

        {/* ------------------------------- */}

        <div className={cx(styles.dateTime, styles.inlineEditorWrapper)}>

          <EventDateEditor
            startDateStr={startDateStr}
            scheduledEventStartTime={moment(this._event.start_time).toDate()}
            isChanged={isChangedDay || isChangedStartTime || isChangedEndTime}
            isChangedDay={isChangedDay}
            onChange={(newDate):void => this._startTimeChangeHandler(moment(newDate) as Moment, duration as Duration)}
            popoverProps={{
              usePortal: true,
              position: Position.LEFT,
              modifiers: {
                preventOverflow: {
                  enabled: false
                }
              }
            }}
          />

        </div>

        <div className={cx(styles.dateTime, styles.inlineEditorWrapper)}>

          <div>
            <EventStartTimeEditor
              time={startTime.toDate()}
              timeStr={startTimeStr}
              timeQuery={scheduledEventStartTimeQuery}
              isChanged={isChangedStartTime}
              onChange={(item):void => this._startTimeChangeHandler(item as Moment, duration as Duration)}
              onQueryChange={(query):unknown => runInAction(():unknown => this._scheduleState.scheduledEventStartTimeQuery = query)}
              onTimeUpDown={(date):void => this._startTimeChangeHandler(moment(date) as Moment, duration as Duration)}
            />
          </div>

          <div className={styles.timeDivider}>–</div>

          <div>
            <EventEndTimeEditor
              startTime={startTime.toDate()}
              time={endTime.toDate()}
              timeStr={endTimeStr}
              timeQuery={scheduledEventEndTimeQuery}
              isChanged={isChangedEndTime}
              onChange={(item):void => this._endTimeChangeHandler(item as Moment)}
              onQueryChange={(query):unknown => runInAction(():unknown => this._scheduleState.scheduledEventEndTimeQuery = query)}
              onTimeUpDown={(date):void => this._endTimeChangeHandler(moment(date) as Moment)}
            />
          </div>

        </div>

        {/* ------------------------------- */}

        <div className={cx(styles.users, styles.inlineEditorWrapper)}>

          <EventAttendeesListEditor
            scheduledEventAttendeesList={this._candidateController.getEventAttendeesAsUsers(this._event)}
            candidateEmail={candidate.email}
            userId={userId}
            allUsers={allUsers}
            popoverProps={{
              usePortal: true,
              position: Position.LEFT,
              modifiers: {
                preventOverflow: {
                  enabled: false
                }
              }
            }}
            isChanged={isChangedAttList}
            onChange={(userEmails):void =>
            {
              runInAction(() =>
              {
                if( this._event )
                {
                  this._event.attendees_list = userEmails.join(',');
                }
                this._onUpdate();
                this._scheduleController.fetchFreeBusyInfo(this._event);
              });
            }}
          />

        </div>

        {/* ------------------------------- */}

        {/* {
          scheduledEventGoogleMeetLink &&
          <a
            href={`https://calendar.google.com/calendar/???`}
            target="_new"
            className={styles.link}
          >
            <SvgIcon className={cx(styles.icon, styles.drive)} icon={SvgIconName.GoogleMeet} />
            Google Meet link
          </a>
        } */}

        {/* ------------------------------- */}

        {
          !this._event.conference_link &&
          <div className={cx(styles.link, { isDisabled: true })}>
            <SvgIcon className={cx(styles.icon, styles.drive)} icon={SvgIconName.GoogleMeet} />
            Google Meet link
          </div>
        }

        {
          !this._event.conference_link &&
          <div className={styles.smallTextBg}>
            Link will appear after scheduling the call
          </div>
        }

        {
          this._event.conference_link && (this._event.conference_link.length > 3) &&
          <div className={cx(styles.textWithIcon, styles.joinGoogleMeet)}>
            <Icon icon={IconNames.CALENDAR} className={styles.textIcon} />
            <div className={styles.text}>
              <Button
                className={styles.btnJoinGoogleMeet}
                text={'Join with Google Meet'}
                intent={Intent.PRIMARY}
                onClick={():void => this._commonController.openInNewTab(String(this._event?.conference_link))}
              />
              <div className={styles.smallText}>
                {this._event.conference_link}
              </div>
            </div>
          </div>
        }

        {/* ------------------------------- */}

        <div className={styles.inlineEditorWrapper}>

          <InlineEditor
            svgIcon={SvgIconName.TableDescription}
            text={this._event.description || ''}
            fields={[
              {
                name: 'description',
                value: this._event.description || '',
                placeholder: 'Enter description'
              }
            ]}
            iconClassName={cx(styles.iconField, { isChanged: isChangedDescription })}
            // isChanged={isChangedDescription}
            onChange={(value):void =>
            {
              runInAction(():void =>
              {
                if( this._event )
                {
                  this._event.description = String(value['description'].value);
                }
                this._onUpdate();
              });
            }}
            textArea
          />

        </div>

        {/* ------------------------------- */}

        <div className={styles.divider} />

        {/* //TODO: Build and display a calendar link */}

        {/* <a
          href={`https://calendar.google.com/calendar/???`}
          target="_new"
          className={styles.link}
        >
          <SvgIcon className={cx(styles.icon, styles.drive)} icon={SvgIconName.GoogleCalendar} />
          Open in Calendar
        </a> */}

        {/* ------------------------------- */}

        <div className={styles.spacer} />

        <Button
          className={styles.btnScheduleCall}
          text={this._isUpcoming ? 'Reschedule call' : 'Schedule a call'}
          intent={this._isUpcoming ? Intent.WARNING : Intent.PRIMARY}
          disabled={isScheduleCallBtnDisabled || isSchedulingEvent}
          loading={isSchedulingEvent}
          onClick={():void =>
          {
            this._candidateController.scheduleEvent(this._event?.id, this._event?.is_scheduled || false);
          }}
        />

        <div className={styles.smallText}>
          {`An event will be ${this._event.is_scheduled ? 'updated' : 'created'} via Google Calendar. All participants will receive email notifications.`}
        </div>
      </>
    );
  }

  // ----------------------------------------------------
}
