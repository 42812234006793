import { state } from '@flow/dependency-injection';
import { observable } from 'mobx';

export interface IHasuraPermission
{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter?:any;           // filter rows
  columns?:Array<string>;    // filter columns
}

export interface IHasuraDeletePermission
{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter?:any;           // filter rows
  allowed?:boolean;
}

export interface IHasuraPermissions
{
  role:string;
  permission:IHasuraPermission;
}

export interface IHasuraDeletePermissions
{
  role:string;
  permission:IHasuraDeletePermission;
}
export interface IHasuraTablePermissions
{
  schema_name:string;
  table_name:string;
  select_permissions:Array<IHasuraPermissions> | null;
  update_permissions:Array<IHasuraPermissions> | null;
  insert_permissions:Array<IHasuraPermissions> | null;
  delete_permissions:Array<IHasuraDeletePermissions> | null;
}

@state
export class RoleState
{
  @observable public permissions:Array<IHasuraTablePermissions> = [];
  @observable public allowedActionNames:Array<string> = [];

  @observable public isLoaded:boolean = false;

  @observable public selectPermissions:Map<string, IHasuraPermission | null> = new Map();
  @observable public updatePermissions:Map<string, IHasuraPermission | null> = new Map();
  @observable public insertPermissions:Map<string, IHasuraPermission | null> = new Map();
  @observable public deletePermissions:Map<string, IHasuraDeletePermission | null> = new Map();
}
