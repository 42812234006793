import { Menu, MenuItem } from '@blueprintjs/core';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { ButtonMore } from '@flow/common/components/elements/ButtonMore';
import { PositionItem } from '@flow/common/components/elements/PositionItem';
import { Button } from '@flow/common/components/form/Button';
import { InlineEditor } from '@flow/common/components/form/InlineEditor';
import { Intent } from '@flow/common/components/types/Types';
import { AuthState } from '@flow/common/controllers/AuthState';
import { SlotUtil } from '@flow/common/utils/SlotUtil';
import { StringUtil } from '@flow/common/utils/StringUtil';
import type { Recruiting_Candidate_Allocation } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { CandidateAllocationController } from '@flow/modules/recruiting/candidates/CandidateAllocationController';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { runInAction } from 'mobx';
import React from 'react';
import { CandidateAllocationState } from '../../../CandidateAllocationState';

import styles from './AllocationPositionInPanel.module.less';

const cx = classNames.bind(styles);

interface Props
{
  className:string;
  position:Recruiting_Position_Ex;
  isProposeDisabled:boolean;
}

interface State
{
  isInlineEditorInEditMode:boolean;
}

@component
export class AllocationPositionInPanel extends React.Component<Props, State>
{
  @di private _authState!:AuthState;
  @di private _candidateAllocationState!:CandidateAllocationState;
  @di private _candidateAllocationController!:CandidateAllocationController;

  private _notesInlineEditorRef:React.RefObject<InlineEditor> = React.createRef();
  private _positionItemRef:React.RefObject<PositionItem> = React.createRef();

  // ---------------------------------------------------------

  public constructor(props:Props)
  {
    super(props);

    this.state = {
      isInlineEditorInEditMode: false
    };
  }

  // ---------------------------------------------------------

  @bind
  private _setNotesInlineEditorEditMode():void
  {
    this.setState({ isInlineEditorInEditMode: true }, () =>
    {
      this._notesInlineEditorRef.current?.setEditMode();
    });
  }

  // ---------------------------------------------------------

  @bind
  private _renderProposedNotes(position:Recruiting_Position_Ex):React.ReactNode | null
  {
    const { isInlineEditorInEditMode } = this.state;
    const { sortedProposedAllocations, positionById } = this._candidateAllocationState;

    const myId:number = this._authState.user?.id || 0;

    return (
      <>
        {sortedProposedAllocations.map((allocation:Recruiting_Candidate_Allocation) =>
        {
          const { user, allocation_positions, notes } = allocation;
          const { avatar, staffs } = user;

          const renderedPositions = allocation_positions
            .filter(allocation_position =>
            {
              if( allocation_position.position_id !== position.id ) return false;

              const fullPosition = positionById(Number(allocation_position.position_id));

              const isPositionVisible = fullPosition && fullPosition.customer_team_slots.every(slot =>
              {
                if( !slot.position ) runInAction(() => slot.position = fullPosition);

                return SlotUtil.isSlotVisible(slot);
              });

              return isPositionVisible;
            });

          if( !renderedPositions.length ) return null;

          const myNote:boolean = user.id === myId;

          return (
            <div
              key={allocation.id}
              className={styles.note}
            >
              <div className={styles.noteHeader}>
                <Avatar
                  className={styles.noteAvatar}
                  size={16}
                  url={avatar || staffs?.[0].avatar_url}
                />
                <div className={styles.proposedBy}>
                  Proposed by {myNote ? 'you' : StringUtil.getUserName(user)}
                </div>
                {
                  myNote &&
                  <>
                    {
                      !isInlineEditorInEditMode &&
                      <Button
                        className={cx(styles.btnAddNote, { editNote: notes })}
                        text={notes ? 'Edit note' : 'Add note'}
                        minimal={true}
                        intent={Intent.PRIMARY}
                        onClick={(e):void =>
                        {
                          e.stopPropagation();
                          this._setNotesInlineEditorEditMode();
                        }}
                      />
                    }
                    <ButtonMore
                      className={styles.btnMoreNote}
                      menuContent={(
                        <Menu>
                          <MenuItem
                            text={'Remove proposal'}
                            onClick={():void =>
                            {
                              this._candidateAllocationController.deleteProposedAllocation(position.id).then(() =>
                              {
                                this.forceUpdate(); // TODO: !!!
                              });
                            }}
                          />
                        </Menu>
                      )}
                    />
                  </>
                }
              </div>
              {
                (notes && !myNote) &&
                <div className={styles.noteContent}>
                  {notes}
                </div>
              }
              {
                (myNote && (notes || isInlineEditorInEditMode)) &&
                <div className={styles.noteContent}>
                  <InlineEditor
                    wrapperClassName={styles.notesEditor}
                    editClassName={styles.notesEditorEdit}
                    viewClassName={styles.notesEditorView}
                    inputClassName={styles.notesEditorInput}
                    ref={this._notesInlineEditorRef}
                    textArea={true}
                    text={notes || ''}
                    readonly={!isInlineEditorInEditMode}
                    fields={[
                      {
                        name: 'notes',
                        value: notes || '',
                        placeholder: 'Enter note'
                      }
                    ]}
                    onChange={async (value):Promise<void> =>
                    {
                      console.log('%c NOTES: new value =', 'background:#0f0;color:#000;', value);
                      const newNotes:string = (value['notes'].value as string || '').trim();

                      if( newNotes === notes ) return;

                      await this._candidateAllocationController.proposePositionOrUpdateNotes(position.id, newNotes);
                      this.setState({ isInlineEditorInEditMode: false });
                      this.forceUpdate(); // TODO: !!!
                    }}
                    onCancel={():void =>
                    {
                      this.setState({ isInlineEditorInEditMode: false });
                    }}
                  />
                </div>
              }
            </div>
          );
        })
        }
      </>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderProposedUserAvatars(position:Recruiting_Position_Ex):React.ReactNode | null
  {
    const { sortedProposedAllocations, positionById } = this._candidateAllocationState;

    let avatarsCount:number = 0;
    let lastAvatar:React.ReactNode | null = null;

    return (
      <>
        {sortedProposedAllocations.map((allocation:Recruiting_Candidate_Allocation) =>
        {
          const { user, allocation_positions } = allocation;
          const { avatar, staffs } = user;

          const renderedPositions = allocation_positions
            .filter(allocation_position =>
            {
              if( allocation_position.position_id !== position.id ) return false;

              const fullPosition = positionById(Number(allocation_position.position_id));

              const isPositionVisible = fullPosition && fullPosition.customer_team_slots.every(slot =>
              {
                if( !slot.position ) runInAction(() => slot.position = fullPosition);

                return SlotUtil.isSlotVisible(slot);
              });

              return isPositionVisible;
            });

          if( !renderedPositions.length ) return null;

          lastAvatar = (
            <Avatar
              key={allocation.id}
              className={styles.proposedUserAvatar}
              size={25}
              url={avatar || staffs?.[0].avatar_url}
            />
          );

          if( ++avatarsCount > 2 ) return null;

          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return lastAvatar;
        })
        }
        {avatarsCount === 3 && lastAvatar}
        {
          avatarsCount > 3 &&
          <Avatar
            key={'text'}
            className={styles.proposedUserAvatar}
            size={25}
            text={`+${avatarsCount - 2}`}
          />
        }
      </>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderHiddenBlock(position:Recruiting_Position_Ex/* , isOpened?:boolean */):React.ReactNode | null
  {
    const isProposed:boolean = this._candidateAllocationController.isPositionProposed(position.id);

    if( !isProposed ) return null;

    const proposedNotes = this._renderProposedNotes(position);

    if( !proposedNotes ) return null;

    return (
      <div className={styles.hiddenBlock}>
        {proposedNotes}
      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderActionsBlock(position:Recruiting_Position_Ex, isOpened?:boolean):React.ReactNode | null
  {
    const { isProposeDisabled } = this.props;

    if( isProposeDisabled ) return null;

    const isProposed:boolean = this._candidateAllocationController.isPositionProposed(position.id);
    const isProposedByMe:boolean = this._candidateAllocationController.isPositionProposedByMe(position.id);

    return (
      <div className={cx(styles.actionsBlock, { isProposed, isProposedByMe, isOpened })}>
        {
          !isProposedByMe &&
          <Button
            className={cx(styles.btnPropose)}
            text={'Propose'}
            minimal={true}
            outlined={true}
            intent={Intent.PRIMARY}
            onClick={async (e):Promise<void> =>
            {
              e.stopPropagation();
              await this._candidateAllocationController.proposePositionOrUpdateNotes(position.id);
              this.forceUpdate(); // TODO: !!!
              this._positionItemRef.current?.setHiddenBlockVisibility(true);
            }}
          />
        }
        {
          isProposed &&
          <div className={styles.proposedUserAvatars}>
            {this._renderProposedUserAvatars(position)}
          </div>
        }
      </div>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { position, className } = this.props;

    return (
      <PositionItem
        className={cx(styles.positionItem, className)}
        position={position}
        actionsBlockFn={this._renderActionsBlock}
        hiddenBlock={this._renderHiddenBlock(position as Recruiting_Position_Ex)}
        ref={this._positionItemRef}
      />
    );
  }

  // ---------------------------------------------------------
}
