import { MenuDivider, MenuItem, Position } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { PositionGroupPill } from '@flow/common/components/elements/PositionGroupPill';
import { IconNames } from '@flow/common/components/form/Icon';
import { component, di } from '@flow/dependency-injection';
import { PositionsController } from '@flow/modules/recruiting/positions/PositionsController';
import { PositionsState } from '@flow/modules/recruiting/positions/PositionsState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './PositionsListPositionGroupSelector.module.less';
import { type IItemRendererProps } from '@blueprintjs/select';
import {
  type Recruiting_Position,
  type Recruiting_Position_Group
} from '@flow/data-access/lib/types/graphql.generated';

const cx = classNames.bind(styles);

interface Props
{
  position:Recruiting_Position;
  positionGroup?:Recruiting_Position_Group;
  disabled:boolean;
}

@component
export class PositionsListPositionGroupSelector extends React.Component<Props>
{
  @di private _positionsState!:PositionsState;
  @di private _positionsController!:PositionsController;

  // ---------------------------------------------------------

  @bind
  private _renderPositionGroupItem(
    positionGroup:Partial<Recruiting_Position_Group> | null,
    { index, handleClick, modifiers }:IItemRendererProps):JSX.Element | null
  {
    if( !modifiers.matchesPredicate )
    {
      return null;
    }

    if( !positionGroup )
    {
      return <MenuDivider key={`divider #${index}`} />;
    }

    const { positionGroup: selectedPositionGroup } = this.props;

    const isMenuDivider:boolean = positionGroup.id === undefined;
    const title:string = isMenuDivider ? 'Unassigned' : positionGroup?.name ?? '???';

    return (
      <MenuItem
        key={title}
        text={title}
        className={cx(styles.dialogMenuItem, { isMenuDivider })}
        // selected={positionGroup.id === selectedPositionGroup?.id}
        onClick={handleClick}
        active={modifiers.active}
      />
    );
  }

  @bind
  private _onItemSelect(positionGroup:Partial<Recruiting_Position_Group> | null):void
  {
    const { position } = this.props;

    this._positionsController.applyPositionGroupForPosition(position as Recruiting_Position, positionGroup?.id);
  }

  // ---------------------------------------------------------
  public render():React.ReactNode | null
  {
    const { positionGroup, disabled } = this.props;

    const { positionGroups } = this._positionsState;

    const unassigned:boolean = !positionGroup;

    const pillTitle:string = positionGroup?.name || 'Unassigned';

    const PositionGroupDropdown = Select.ofType<Recruiting_Position_Group | null>();

    const noGroup:Partial<Recruiting_Position_Group> = { id: undefined };

    return (
      <PositionGroupDropdown
        className={styles.positionGroupDropdown}
        items={[noGroup as Recruiting_Position_Group, ...positionGroups]}
        itemRenderer={this._renderPositionGroupItem}
        onItemSelect={this._onItemSelect}
        filterable={false}
        disabled={disabled}
        popoverProps={{
          minimal: true,
          usePortal: false,
          position: Position.BOTTOM_RIGHT
        }}
      >
        <PositionGroupPill
          className={cx(styles.pill, { disabled, unassigned }, 'test-position-group-dropdown')}
          name={pillTitle}
          icon={IconNames.CARET_DOWN}
          iconClassName={styles.pillIcon}
        />
      </PositionGroupDropdown>
    );
  }

  // ---------------------------------------------------------
}
