import { CommonController } from '@flow/common/CommonController';
import { Button } from '@flow/common/components/form/Button';
import { Intent } from '@flow/common/components/types/Types';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';

import classNames from 'classnames/bind';
import * as React from 'react';
import { IconNames } from '../form/Icon';
import styles from './FullscreenPage.module.less';

const cx = classNames.bind(styles);

interface Props
{
  btnBackTitle?:string;
  btnBackUrl?:string | null;

  title?:string;

  sidebarElements:JSX.Element | React.ReactNode | (() => React.ReactNode);
  contentElements:JSX.Element;
}

@component
export class FullscreenPage extends React.Component<Props>
{
  @di private _commonController!:CommonController;

  // ----------------------------------------------------

  @bind
  private _renderBtnBack():React.ReactNode | null
  {
    const { btnBackTitle, btnBackUrl } = this.props;

    if( !btnBackTitle && !btnBackUrl ) return null;

    return (
      <div className={cx(styles.btnBackWrapper, 'test-fullscreen-back')}>
        <Button
          className={styles.btnBack}
          iconClassName={styles.btnBackIcon}
          icon={IconNames.ARROW_LEFT}
          text={btnBackTitle}
          minimal={true}
          intent={Intent.PRIMARY}
          onClick={():void =>
          {
            btnBackUrl && this._commonController.navigate(btnBackUrl);
          }}
        />
      </div>
    );
  }

  // ----------------------------------------------------

  private _renderSidebar():React.ReactNode
  {
    const { sidebarElements } = this.props;

    if( typeof(sidebarElements) == 'function' )
      return sidebarElements() as React.ReactNode;
    else
      return sidebarElements;
  }

  public render():React.ReactNode
  {
    const {
      title, contentElements
    } = this.props;

    return (
      <div className={styles.wrapper}>

        {/* ------------------------------- */}

        <div className={styles.sidebar}>

          {this._renderBtnBack()}

          <div className={styles.sidebarInner}>
            {
              title &&
              <div className={styles.title}>
                {title}
              </div>
            }
            {this._renderSidebar()}
          </div>

        </div>

        {/* ------------------------------- */}

        <div className={cx(styles.contentWrapper, 'test-fullscreen-content')}>
          {contentElements}
        </div>

        {/* ------------------------------- */}

      </div>
    );
  }

  // ----------------------------------------------------
}
