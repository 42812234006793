import { FilterMultiSelectInput } from '@flow/common/components/filters/components/FilterMultiSelectInput';
import { FiltersPopover } from '@flow/common/components/filters/components/FiltersPopover';
import type { IFilterComponentProps } from '@flow/common/components/filters/Filter';
import type {
  IFilterMultiSelectDataItems,
  IFilterMultiSelectItemValueType,
  IFilterMultiSelectValue
} from '@flow/common/components/filters/multiSelect/FilterMultiSelect';
import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';
// import classNames from 'classnames/bind';
import type React from 'react';
import { Component } from 'react';

import styles from './FilterMultiSelectPopover.module.less';

// const cx = classNames.bind(styles);

@component
export class FilterMultiSelectPopover extends Component<IFilterComponentProps<IFilterMultiSelectValue>>
{
  // ---------------------------------------------------------

  @bind
  private _onSelect(value:Array<IFilterMultiSelectItemValueType>):void
  {
    const { onChangeFilter } = this.props;

    onChangeFilter({ items: value });
  }

  // ---------------------------------------------------------

  @bind
  private _renderFilterContent():React.ReactElement | null
  {
    const { filter, getFilterData } = this.props;

    const { items: selectedIds } = filter.value;

    const filterData:IFilterMultiSelectDataItems = getFilterData?.();

    if( !filterData || !filterData.items ) return null;

    return (
      <div className={styles.content}>

        <FilterMultiSelectInput
          items={filterData.items}
          selectedIds={selectedIds}
          onSelect={this._onSelect}
        />

      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _getValuesStr():string
  {
    const { filter, getFilterData } = this.props;

    const { items } = filter.value;

    const filterData:IFilterMultiSelectDataItems = getFilterData?.();

    const isValueSelected:boolean = items && !!items.length;

    if( !filterData || !filterData.items || !isValueSelected )
    {
      return '';
    }

    const names:string = filterData.items
      .filter((item) => items.includes(item.value))
      .map((item) => item.name)
      .join(', ');

    return names;
  }

  @bind
  private _getButtonText():JSX.Element
  {
    const { filter } = this.props;
    const { selectedTitle, unselectedTitle } = filter;

    const values:string = this._getValuesStr();

    const showColon:boolean = !!(selectedTitle || filter.name);

    return values
      ? <span><b>{selectedTitle || filter.name}{showColon && ': '}</b>{values}</span>
      : <span>{unselectedTitle || filter.name}</span>;
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { filter, onRemoveFilter, wrapperClassName, buttonClearText } = this.props;

    const { items } = filter.value;

    const isValueSelected:boolean = items && !!items.length;

    return (
      <FiltersPopover
        wrapperClassName={wrapperClassName}
        title={filter.name}
        content={this._renderFilterContent()}
        contentButtonClearText={buttonClearText}
        onClickButtonClear={onRemoveFilter}
        buttonText={this._getButtonText()}
        isValueSelected={isValueSelected}
      />
    );
  }

  // ----------------------------------------------------
}
