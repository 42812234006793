import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import type { IReactComponent } from 'mobx-react/dist/types/IReactComponent';
import type { IDeletedProvider } from './registry';
import { deletedInstanceSymbol } from './registry';
import { hasMobXDecorators, isClassComponent, isFunctionComponent } from './utils';

export function component<T extends IReactComponent<Props>, Props = any>(component:T):T
{
  if( isClassComponent(component) )
  {
    const WrappedComponent:any = component as any;
    const name = WrappedComponent.name;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const observerObject = observer(class extends WrappedComponent
    {
      public constructor(...args:Array<unknown>)
      {
        super(...args);

        if( hasMobXDecorators(this) )
          makeObservable(this);
      }

      public componentWillUnmount():void
      {
        if( super.componentWillUnmount )
        {
          super.componentWillUnmount();
        }

        (this as IDeletedProvider)[deletedInstanceSymbol] = true;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);

    observerObject.displayName = name;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return observerObject;
  }
  else if( isFunctionComponent(component) ) return observer(component);
  else
    throw new Error(
      '@component decorator should be used on React components only'
    );
}
