import moment, { duration, Moment } from 'moment';

export type DateStrOrNum = string | number | null;

export class DateUtil
{
  // dateStrOrNum = '2022-06-21T00:00:00+00:00' || 1654473600000 || null

  public static getDate(dateStrOrNum:DateStrOrNum):number | null
  {
    return dateStrOrNum ? Date.parse(String(dateStrOrNum)) || dateStrOrNum as number : null;
  }

  public static formattedDate(dateStrOrNum:DateStrOrNum):string
  {
    const date:number | null = DateUtil.getDate(dateStrOrNum);

    if( !date ) return '';

    const yearNow:string = new Date().getFullYear().toString();
    const yearStr:string = new Date(date).getFullYear().toString();

    const withYear:boolean = yearNow !== yearStr;

    return moment(date).format(`MMM DD${withYear ? ', YYYY' : ''}`) as string;
  }

  public static daysWorked(dateStrOrNum:DateStrOrNum):string
  {
    const date:number | null = DateUtil.getDate(dateStrOrNum);

    if( !date ) return '';

    const dateNow:number = Date.now();
    const dateFrom:number = new Date(date).getTime();

    return dateFrom > dateNow ? '' : moment(dateFrom).fromNow(true) as string;
  }

  public static roundToNearest15(date = new Date()):Date
  {
    const minutes = 15;
    const ms = 1000 * 60 * minutes;

    return new Date(Math.ceil(date.getTime() / ms) * ms);
  }

  /**
   *
   * @param startingTime a Date with time to start generating intervals from
   */
  public static generateTimeList(intervalMinutes:number = 15, startingTime?:Date, useDaySep:boolean = false):Array<Date | null>
  {
    let currentDate;

    if( !startingTime )
    {
      currentDate = moment(DateUtil.roundToNearest15(new Date()));
      currentDate.hours(0);
      currentDate.minutes(0);
    }
    else
    {
      currentDate = moment(DateUtil.roundToNearest15(startingTime));
    }

    const datesList:Array<Date | null> = [];

    for( let i = 0; i < 24 * 60 / intervalMinutes; i++ )
    {
      datesList.push(currentDate.toDate() as Date);
      currentDate = currentDate.add(duration({ minutes: intervalMinutes }));

      if( useDaySep && currentDate.day() !== datesList[datesList.length - 1]?.getDay() )
        datesList.push(null);
    }

    return datesList;
  }

  public static compareDates(date1StrOrNum:DateStrOrNum, date2StrOrNum:DateStrOrNum):number
  {
    const d1:number = DateUtil.getDate(date1StrOrNum) || 0;
    const d2:number = DateUtil.getDate(date2StrOrNum) || 0;

    return d2 - d1;
  }

  public static isSameDay(date1StrOrNum:DateStrOrNum, date2StrOrNum:DateStrOrNum):boolean
  {
    return moment(date1StrOrNum).isSame(moment(date2StrOrNum), 'day') as boolean;
  }

  public static isSameTimeIgnoringDate(date1StrOrNum:DateStrOrNum, date2StrOrNum:DateStrOrNum):boolean
  {
    return moment(date1StrOrNum).format('HH:mm') == moment(date2StrOrNum).format('HH:mm');
  }

  // ---------------------------------------------------------

  public static getISODay(date?:Date | string | null):string | null
  {
    const mDate:Moment = moment(date);

    if( !mDate.isValid() ) return null;

    return mDate
      .hours(moment().utcOffset() / 60)
      .minutes(0).seconds(0).milliseconds(0)
      .toISOString();
  }

  // ---------------------------------------------------------

  public static formatDay(date?:Date | string | null, isShortMonth?:boolean):string
  {
    if( !date ) return '';

    const mDate:Moment = moment(date);

    if( !mDate.isValid() ) return '';

    return mDate.utc().format(`MMM${isShortMonth ? '' : 'M'} D, YYYY`)
  }

  // ---------------------------------------------------------

  public static isAfterToday(date?:Date | string | null):boolean
  {
    const mDate:Moment = moment(date);

    if( !mDate.isValid() ) return false;

    return moment(date).isAfter(moment(), 'day');
  }

  // ---------------------------------------------------------

  public static isSameOrBeforeToday(date?:Date | string | null):boolean
  {
    const mDate:Moment = moment(date);

    if( !mDate.isValid() ) return false;

    return moment(date).isSameOrBefore(moment(), 'day');
  }

  // ---------------------------------------------------------
}
