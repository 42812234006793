import { BlankState } from '@flow/common/components/form/BlankState';
import { Spinner } from '@flow/common/components/form/Spinner';
import { CollapseList } from '@flow/common/components/page/CollapseList';
import { ConfirmationDialog } from '@flow/common/components/page/ConfirmationDialog';
import { ListWrapper } from '@flow/common/components/page/ListWrapper';
import { component, di } from '@flow/dependency-injection';
import { PositionDialog } from '@flow/modules/customers/teams/components/positions/PositionDialog';
import { TeamsListHeader } from '@flow/modules/customers/teams/components/teams/TeamsListHeader';
import { NewPositionState } from '@flow/modules/customers/teams/NewPositionState';
import { TeamController, TEAMS_LIST_COLLAPSE_ID } from '@flow/modules/customers/teams/TeamController';
import { TeamState } from '@flow/modules/customers/teams/TeamState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { runInAction } from 'mobx';
import React, { Component } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { CustomerController } from '../../CustomerController';
import { CustomerState } from '../../CustomerState';
import { PositionsListItem } from './PositionsListItem';
import styles from './TeamsList.module.less';
import { TeamsListItem } from './TeamsListItem';
import { type ReactNode } from 'react';
import {
  type Recruiting_Position_Ex,
  type Staffing_Customer_Team_Ex
} from '../../models/CustomersTypes';

const cx = classNames.bind(styles);

@component
export class TeamsList extends Component
{
  @di private _customerState!:CustomerState;
  @di private _customerController!:CustomerController;

  @di private _teamState!:TeamState;
  @di private _teamController!:TeamController;
  @di private _positionState!:NewPositionState;

  // ---------------------------------------------------------

  @bind
  private _renderTeam(team:Staffing_Customer_Team_Ex, index:number, items:Array<Staffing_Customer_Team_Ex>, isCollapsed?:boolean):ReactNode
  {
    return (
      <TeamsListItem
        team={team}
        itemIndex={index}
        itemsLength={items.length}
        isCollapsed={isCollapsed}
      />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderSubItemsHeader():React.ReactElement
  {
    return (
      <div className={styles.subItemsHeader}>
        <div className={styles.colTemplate}>
          Position
        </div>
        <div className={styles.colStaffRole}>
          Staff Role
        </div>
      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderPositions(team:Staffing_Customer_Team_Ex /*, index:number, items:Array<Staffing_Customer_Team_Ex> */):ReactNode
  {
    const { positions } = team;

    const { isPositionsLoading } = this._teamState;

    if( !positions?.length )
    {
      return this._renderEmptyTeamList(/*team*/);
    }
    else
    {
      return (
        <>
          <div className={cx(styles.positionsWrapper, 'test-positions-list')}>
            {this._renderSubItemsHeader()}

            <div className={styles.subItemsWrapper}>
              {positions.map(this._renderPosition)}
            </div>
          </div>

          {
            isPositionsLoading &&
            <Spinner
              noIcon
              withWave
              withWrapper
              wrapperClassName={styles.spinnerWrapper}
            />
          }
        </>
      );
    }
  }

  // ---------------------------------------------------------

  @bind
  private _renderPosition(position:Recruiting_Position_Ex, index:number, items:Array<Recruiting_Position_Ex>):ReactNode
  {
    const managedKey:string = `${index}:${position.id}`;

    const { moveUpId, moveDownId, movingId } = this._positionState;

    let classNames:string = position.id === moveUpId ? 'move-up' : position.id === moveDownId ? 'move-down' : '';
    classNames += position.id === movingId ? '-bg' : '';

    const { team: managedTeam } = this._teamState;
    const isManagedTeam:boolean = managedTeam?.id === position.customer_team_id;

    return (
      <SwitchTransition mode={'out-in'} key={isManagedTeam ? managedKey : position.id}>
        <CSSTransition
          key={managedKey} // !!!
          // key={position.id}
          classNames={classNames}
          addEndListener={(node, done):void =>
          {
            node.addEventListener('transitionend', done, false);
          }}
        >
          <PositionsListItem
            position={position}
            itemIndex={index}
            itemsLength={items.length}
          />
        </CSSTransition>
      </SwitchTransition>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderEmptyTeamList(/* team:Staffing_Customer_Team */):ReactNode
  {
    return (
      <BlankState title={'No positions'} className={styles.noPositions} />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _onScroll(event:React.UIEvent<HTMLDivElement>):void
  {
    // const { isPositionsLoaded } = this._positionsState;

    // if( !isPositionsLoaded ) return;

    if( this._teamState.teamPositionsListScrollYPosition != Number(event.currentTarget.scrollTop) )
      runInAction(() => this._teamState.teamPositionsListScrollYPosition = Number(event.currentTarget.scrollTop));
  }

  // ---------------------------------------------------------
  public render():ReactNode
  {
    const { customer } = this._customerState;

    if( !customer )
      return null;

    const customer_teams = this._customerController.orderedTeams;

    const { team: managedTeam, teamPositionsListScrollYPosition } = this._teamState;

    return (
      <>
        <ListWrapper
          header={<TeamsListHeader />}
          headerClassName={styles.header}
          content={(
            <CollapseList
              id={TEAMS_LIST_COLLAPSE_ID}

              items={customer_teams}
              itemClassName={styles.item}
              itemRenderer={this._renderTeam}

              isCanCollapseSelected={false}
              selectedItemIds={managedTeam ? [managedTeam.id] : []}
              selectedItemClassName={styles.isManagedTeam}
              // selectedChevronClassName={styles.isManagedTeamChevron}
              selectedChevronIconClassName={styles.isManagedTeamChevronIcon}

              customSubItemsRenderer={this._renderPositions}

              // subItemsClassName={styles.subItemsWrapper}
              // subItemsProp="positions"
              // subItemsHeader={this._renderSubItemsHeader()}
              // subItemRenderer={this._renderPosition}

              emptyListElement={<BlankState title={'No teams'} className={styles.noTeams} />}
              // emptySubListRenderer={this._renderEmptyTeamList}
            />
          )}
          onScroll={this._onScroll}
          scrollPosition={teamPositionsListScrollYPosition}
        />

        <PositionDialog />

        <ConfirmationDialog
          title={'Delete team?'}
          confirmButtonText={'Delete'}

          isOpen={this._teamState.isDeleteTeamDialogOpen}
          isLoading={this._teamState.isDeleteTeamInProgress}

          onConfirm={this._teamController.deleteTeam}
          onClose={this._teamController.toggleDeleteTeamDialog}
        >
          {/* TODO: */}
          {/*All proposed and finalized positions will be deleted.*/}
        </ConfirmationDialog>
      </>
    );
  }

  // ---------------------------------------------------------
}
