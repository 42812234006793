import { MenuItem, Position } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { Button, ButtonSize } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { Alignment } from '@flow/common/components/types/Types';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import type { Recruiting_Interview_Flow } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { CandidateFlowController } from '@flow/modules/recruiting/candidates/CandidateFlowController';
import { CandidateFlowState } from '@flow/modules/recruiting/candidates/CandidateFlowState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './PipelineSelector.module.less';

const cx = classNames.bind(styles);

@component
export class PipelineSelector extends React.Component
{
  @di private _candidateFlowState!:CandidateFlowState;
  @di private _candidateFlowsController!:CandidateFlowController;
  @di private _roleController!:RoleController;

  // ---------------------------------------------------------

  @bind
  private _itemRenderer(flowItem:Recruiting_Interview_Flow,
                        { handleClick, modifiers }:IItemRendererProps):JSX.Element | null
  {
    if( !modifiers.matchesPredicate )
    {
      return null;
    }

    const { candidateFlowStageId } = this._candidateFlowState;

    return (
      <MenuItem
        key={flowItem.id}
        text={flowItem.name}
        onClick={handleClick}
        // selected={flowItem.id === candidateFlowStageId}
        disabled={flowItem.id == null}
        active={modifiers.active}
      />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _onItemSelect(flow:Recruiting_Interview_Flow):void
  {
    this._candidateFlowsController.selectCandidateFlow(flow.id);
  }

  // ---------------------------------------------------------

  public render():ReactNode | null
  {
    const {
      flows, selectedFlowId, selectedFlow, isCandidateInInitialStatus
    } = this._candidateFlowState;

    if( !isCandidateInInitialStatus || !selectedFlow ) return null;

    // const isDisabled = true;
    const isDisabled:boolean = !this._roleController.hasPermission(FlowPermissions.ChangeCandidateFlowStage);

    const btnSelectPipelineText:string = selectedFlowId ? selectedFlow?.name || '???' : '';

    return (
      <Select
        className={styles.selectPipeline}
        items={flows}
        itemRenderer={this._itemRenderer}
        onItemSelect={this._onItemSelect}
        filterable={false}
        disabled={isDisabled}
        popoverProps={{
          minimal: true,
          usePortal: false,
          position: Position.BOTTOM_LEFT
        }}
      >
        <Button
          className={cx(styles.btnSelectPipeline, { isDisabled })}
          text={btnSelectPipelineText}
          disabled={isDisabled}
          rightIcon={isDisabled ? null : IconNames.CARET_DOWN}
          rightIconClassName={styles.iconCaret}
          icon={IconNames.HeatGrid}
          iconClassName={styles.iconLeft}
          minimal={true}
          alignText={Alignment.LEFT}
          size={ButtonSize.LARGE}
        />
      </Select>
    );
  }

  // ---------------------------------------------------------
}
