import { component } from '@flow/dependency-injection';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './ConstraintsWarnings.module.less';

const cx = classNames.bind(styles);

@component
export class ConstraintsWarnings extends React.Component
{
  // @di private _commonController!:CommonController;
  // @di private _roleController!:RoleController;

  // @di private _candidateState!:CandidateState;
  // @di private _candidateFlowState!:CandidateFlowState;
  // @di private _candidateFlowsController!:CandidateFlowController;

  // ---------------------------------------------------------

  public render():ReactNode | null
  {
    return null;

    return (
      <div className={styles.constraintsWarningsWrapper}>

        {/* TODO: ... FLOW-276 */}

      </div>
    );
  }

  // ---------------------------------------------------------
}
