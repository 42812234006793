import type { IFilter, IFilterValue } from '@flow/common/components/filters/Filter';
import { Spinner } from '@flow/common/components/form/Spinner';
import { component, di } from '@flow/dependency-injection';
import { StaffFiltersSelector } from '@flow/modules/staffing/staff/components/list/filters/StaffFiltersSelector';
import { StaffSavedFiltersSelector } from '@flow/modules/staffing/staff/components/list/filters/StaffSavedFiltersSelector';
import { StaffFiltersController } from '@flow/modules/staffing/staff/StaffFiltersController';
import { StaffFiltersState } from '@flow/modules/staffing/staff/StaffFiltersState';
import { StaffState } from '@flow/modules/staffing/staff/StaffState';
import bind from 'bind-decorator';
// import classNames from 'classnames/bind';
import React from 'react';

import styles from './StaffFilters.module.less';

// const cx = classNames.bind(styles);

@component
export class StaffFilters extends React.Component
{
  @di private _staffState!:StaffState;
  @di private _staffFiltersState!:StaffFiltersState;
  @di private _staffFiltersController!:StaffFiltersController;

  // ---------------------------------------------------------

  @bind
  private _onRemoveFilter(filter:IFilter<IFilterValue>):() => void
  {
    return ():void =>
    {
      this._staffFiltersController.removeFilter(filter.type);
    };
  }

  @bind
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _onChangeFilter(filter:IFilter<IFilterValue>):(newValue:any) => void
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (newValue:any):void =>
    {
      console.log('%c !!! _onChangeFilter newValue =', 'background:#ff0;color:#000;', newValue);
      this._staffFiltersController.changeFilterValue(filter.type, newValue);
    };
  }

  @bind
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _getFilterData(filter:IFilter<IFilterValue>):() => any
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return ():any =>
    {
      console.log('%c !!! _getFilterData filter =', 'background:#ff0;color:#000;', filter.type);
      return this._staffFiltersController.getFilterData(filter.type);
    };
  }

  // ---------------------------------------------------------

  public render():React.ReactNode | null
  {
    const { isStaffUsersLoaded, isShowArchived } = this._staffState;

    if( isShowArchived ) return null;

    if( !isStaffUsersLoaded )
    {
      return (
        <Spinner
          // size={20}
          noIcon
          withWave
          withWrapper
          wrapperClassName={styles.spinnerWrapper}
        />
      );
    }

    return (
      <div className={styles.filters}>

        <div className={styles.positionLeft}>

          <StaffSavedFiltersSelector />

          {/*---------------------------------*/}

          {this._staffFiltersState.filters.map((filter:IFilter<IFilterValue>):React.ReactNode | null =>
          {
            if( !filter.isSelected ) return null;
            console.log('%c filter =', 'background:#0f0;color:#000;', filter);
            const Component = filter.component;

            return (
              <React.Fragment key={filter.name + filter.type}>
                <Component
                  filter={filter}
                  onRemoveFilter={this._onRemoveFilter(filter)}
                  onChangeFilter={this._onChangeFilter(filter)}
                  getFilterData={this._getFilterData(filter)}
                />
              </React.Fragment>
            );
          })}

        </div>

        {/*---------------------------------*/}

        <div className={styles.positionRight}>
          <StaffFiltersSelector />
        </div>

      </div>
    );
  }
}
