import type * as Types from './types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FetchFreeBusyInfoQueryVariables = Types.Exact<{
  calendarIds:Array<Types.Scalars['String']> | Types.Scalars['String'];
  timeMin:Types.Scalars['timestamptz'];
  timeMax:Types.Scalars['timestamptz'];
}>;


export type FetchFreeBusyInfoQuery = { action_calendar_freebusy?:{ calendars?:any | null; } | null; };

export type FetchEventsInfoQueryVariables = Types.Exact<{
  calendarId:Types.Scalars['String'];
  timeMin:Types.Scalars['timestamptz'];
  timeMax:Types.Scalars['timestamptz'];
}>;


export type FetchEventsInfoQuery = { action_calendar_events?:{ events:Array<{ id:string; summary?:string | null; created:any; organizer:{ email?:string | null; }; start:{ dateTime?:string | null; }; attendees:Array<{ email:string; responseStatus:string; }>; }>; } | null; };

export type FetchEventInfoQueryVariables = Types.Exact<{
  calendarId:Types.Scalars['String'];
  eventId:Types.Scalars['String'];
}>;


export type FetchEventInfoQuery = { action_calendar_event?:{ id:string; created:any; summary?:string | null; organizer:{ email?:string | null; }; attendees:Array<{ responseStatus:string; email:string; }>; start:{ dateTime?:string | null; }; } | null; };


export const FetchFreeBusyInfoDocument = gql`
    query FetchFreeBusyInfo($calendarIds: [String!]!, $timeMin: timestamptz!, $timeMax: timestamptz!) {
  action_calendar_freebusy(
    calendarIds: $calendarIds
    onlyAccepted: true
    timeMin: $timeMin
    timeMax: $timeMax
  ) {
    calendars
  }
}
    `;
export type FetchFreeBusyInfoQueryResult = Apollo.QueryResult<FetchFreeBusyInfoQuery, FetchFreeBusyInfoQueryVariables>;
export const FetchEventsInfoDocument = gql`
    query FetchEventsInfo($calendarId: String!, $timeMin: timestamptz!, $timeMax: timestamptz!) {
  action_calendar_events(
    calendarId: $calendarId
    timeMin: $timeMin
    timeMax: $timeMax
  ) {
    events {
      id
      organizer {
        email
      }
      summary
      created
      start {
        dateTime
      }
      attendees {
        email
        responseStatus
      }
    }
  }
}
    `;
export type FetchEventsInfoQueryResult = Apollo.QueryResult<FetchEventsInfoQuery, FetchEventsInfoQueryVariables>;
export const FetchEventInfoDocument = gql`
    query FetchEventInfo($calendarId: String!, $eventId: String!) {
  action_calendar_event(calendarId: $calendarId, eventId: $eventId) {
    id
    created
    summary
    organizer {
      email
    }
    attendees {
      responseStatus
      email
    }
    start {
      dateTime
    }
  }
}
    `;
export type FetchEventInfoQueryResult = Apollo.QueryResult<FetchEventInfoQuery, FetchEventInfoQueryVariables>;
export const namedOperations = {
  Query: {
    FetchFreeBusyInfo: 'FetchFreeBusyInfo',
    FetchEventsInfo: 'FetchEventsInfo',
    FetchEventInfo: 'FetchEventInfo'
  }
}