import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { ButtonGroupFilter } from '@flow/common/components/page/ButtonGroupFilter';
import { Toolbar } from '@flow/common/components/page/Toolbar';
import { NeedsPermission } from '@flow/common/components/NeedsPermission';
import { Intent } from '@flow/common/components/types/Types';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { component, di } from '@flow/dependency-injection';
import classNames from 'classnames/bind';
import React from 'react';
import { PrioritiesController } from '../PrioritiesController';
import { PrioritiesMobileViewMode, PrioritiesState } from '../PrioritiesState';

import styles from './PrioritiesToolbar.module.less';

const cx = classNames.bind(styles);

@component
export class PrioritiesToolbar extends React.Component
{
  @di private _prioritiesState!:PrioritiesState;
  @di private _prioritiesController!:PrioritiesController;

  public render():React.ReactNode
  {
    const { isEditMode, isViewMode, mobileViewMode } = this._prioritiesState;

    const mobileViewByUsers:boolean = mobileViewMode == PrioritiesMobileViewMode.BY_USERS;

    return (
      <Toolbar
        className={styles.toolbar}
        title={'Priorities'}

        leftClassName={styles.positionLeft}
        leftElements={
          <>
          </>
        }

        centerClassName={cx(styles.positionCenter, { isEditMode })}
        centerElements={
          <>
            <ButtonGroupFilter
              values={[
                PrioritiesMobileViewMode.BY_PRIORITIES,
                PrioritiesMobileViewMode.BY_USERS
              ]}
              icons={{
                [PrioritiesMobileViewMode.BY_PRIORITIES]: IconNames.TH_LIST,
                [PrioritiesMobileViewMode.BY_USERS]: IconNames.PEOPLE
              }}
              value={mobileViewMode}
              onClick={this._prioritiesController.setFilterMobileViewMode}
              buttonClassName={styles.mobileFilterBtn}
            />
          </>
        }

        rightClassName={styles.positionRight}
        rightElements={
          <>
            {
              isViewMode &&
              <NeedsPermission name={FlowPermissions.EditPositionGroupPriorities}>
                <Button
                  className={cx(styles.toolbarBtn, { mobileViewByUsers })}
                  text={'Edit priorities'}
                  intent={Intent.PRIMARY}
                  onClick={this._prioritiesController.goToEditMode}
                />
              </NeedsPermission>
            }
            {
              isEditMode &&
              <>
                <Button
                  className={cx(styles.toolbarBtn, { mobileViewByUsers })}
                  text={'Cancel'}
                  intent={Intent.NONE}
                  onClick={this._prioritiesController.cancelEditMode}
                />
                <Button
                  className={cx(styles.toolbarBtn, { mobileViewByUsers })}
                  text={'Apply'}
                  intent={Intent.PRIMARY}
                  onClick={this._prioritiesController.applyChanges}
                />
              </>
            }
          </>
        }
      />
    );
  }
}
