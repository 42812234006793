import { ButtonMore } from '@flow/common/components/elements/ButtonMore';
import { IPositionsCount, PositionsUtil } from '@flow/common/utils/PositionsUtil';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { computed } from 'mobx';
import type { ReactNode } from 'react';
import React from 'react';
import { CustomersController } from '../../CustomersController';
import { CustomersState } from '../../CustomersState';
import type { Common_Customer_Ex, Staffing_Customer_Team_Ex } from '../../models/CustomersTypes';
import { PositionsCount } from '../team/PositionsCount';
import styles from './CustomersListItem.module.less';
import { CustomersListItemMenu } from './CustomersListItemMenu';

const cx = classNames.bind(styles);

export interface ICustomersListItemProps
{
  customer:Common_Customer_Ex;
}

@component
export class CustomersListItem extends React.Component<ICustomersListItemProps>
{
  @di private _customersState!:CustomersState;
  @di private _customersController!:CustomersController;

  @bind
  private _onCustomerClick():void
  {
    const { customer } = this.props;

    this._customersController.viewCustomer(customer as Common_Customer_Ex);
  }

  @computed
  private get _positionsCount():IPositionsCount
  {
    const { customer: { customer_teams } } = this.props;

    let open:number = 0;
    let total:number = 0;

    customer_teams.forEach((team:Staffing_Customer_Team_Ex) =>
    {
      const teamPositionsCount:IPositionsCount = PositionsUtil.getPositionsCount(team.positions);

      open += teamPositionsCount.open;
      total += teamPositionsCount.total;
    });

    return {
      open,
      total,
      staffed: total - open
    };
  }

  public render():ReactNode
  {
    const { customer, customer: { id, alias } } = this.props;

    const orderedTeams = this._customersController.getOrderedTeams(Number(id));

    // const activeTeamsCount = orderedTeams.filter(team => team.is_active).length;

    const name = customer.name;

    const customerName:string = name || '';
    const customerAlias:string = alias || '';

    return (
      <div
        key={alias}
        className={styles.customer}
        onClick={this._onCustomerClick}
      >
        <div className={cx(styles.customerName, styles.desktop)}>
          {customerName}
        </div>

        <div className={cx(styles.customerAlias, styles.desktop)}>
          {customerAlias}
        </div>

        <PositionsCount
          className={styles.positions}
          count={this._positionsCount.staffed}
          totalCount={this._positionsCount.total}
        />

        {/*<div className={styles.teamsWrapper}>*/}
        {/*<Pill*/}
        {/*  className={styles.pill}*/}
        {/*  type={PillType.Outlined}*/}
        {/*  text={`${activeTeamsCount} Team${activeTeamsCount !== 1 ? 's' : ''}`}*/}
        {/*/>*/}
        {/*</div>*/}

        <div className={styles.teamsWrapper}>
          <div className={cx(styles.customerName, styles.mobile)}>
            {customerName} <br />
            {customerAlias}
          </div>
          {
            orderedTeams?.length !== 0 &&
            <div className={styles.teams}>
              {orderedTeams.map((team:Staffing_Customer_Team_Ex, index:number) => (
                <div key={index} className={cx(styles.team, { prospective: !team.is_active })}>
                  {team.name}
                </div>
              ))}
            </div>
          }
        </div>

        <ButtonMore
          className={styles.menu}
          buttonClassName={styles.menuBtn}
          menuContent={<CustomersListItemMenu customer={customer} />}
        />

      </div>
    );
  }
}
