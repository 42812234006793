import { Position } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { ButtonWithMenu } from '@flow/common/components/elements/ButtonWithMenu';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { Alignment, Intent } from '@flow/common/components/types/Types';
import { component, di } from '@flow/dependency-injection';
import { StaffSavedFiltersMenu } from '@flow/modules/staffing/staff/components/list/filters/StaffSavedFiltersMenu';
import { StaffSaveFilterDialog } from '@flow/modules/staffing/staff/components/list/filters/StaffSaveFilterDialog';
import { StaffFiltersController } from '@flow/modules/staffing/staff/StaffFiltersController';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';
import { StaffState } from '../../../StaffState';

import styles from './StaffSavedFiltersSelector.module.less';

const cx = classNames.bind(styles);

@component
export class StaffSavedFiltersSelector extends React.Component
{
  @di private _staffState!:StaffState;
  @di private _staffFiltersController!:StaffFiltersController;

  // ---------------------------------------------------------

  @bind
  private _onSelectFilter(filterId:number | null):void
  {
    this._staffFiltersController.selectUserFilter(filterId);
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const {
      selectedFilterId, userFilterById,
      someFilterIsSelected, isFiltersDirty
    } = this._staffState;

    const selectedFilterName:string = selectedFilterId
      ? userFilterById(selectedFilterId)?.name || 'No filter'
      : isFiltersDirty ? 'Untitled filter' : 'No filter';

    return (
      <>
        <ButtonWithMenu
          menuContent={
            <StaffSavedFiltersMenu
              onSelect={this._onSelectFilter}
              selectedFilterId={selectedFilterId}
              fromDialog={false}
              isDirty={isFiltersDirty}
            />}
          className={cx(styles.btnFilter)}
          button={
            <Button
              className={cx(styles.filtersList, { isFiltersDirty })}
              rightIcon={IconNames.CARET_DOWN}
              text={selectedFilterName}
              // intent={isFiltersDirty ? Intent.WARNING : Intent.NONE}
              intent={Intent.NONE}
              outlined={true}
              alignText={Alignment.LEFT}
            />
          }
          position={Position.BOTTOM_LEFT}
        />

        <Tooltip2
          content={'Save filter'}
          placement={'top'}
        >
          <Button
            className={styles.btnSave}
            icon={IconNames.FLOPPY_DISK}
            iconClassName={styles.btnSaveIcon}
            minimal={true}
            onClick={this._staffFiltersController.showSaveFilterDialog}
            disabled={!someFilterIsSelected}
          />
        </Tooltip2>
        <StaffSaveFilterDialog
          onSave={this._staffFiltersController.onFilterSaved}
        />
      </>
    );
  }
}
