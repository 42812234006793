import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../form/Button';
import { IconNames } from '../form/Icon';
import { Intent } from '../types/Types';
import styles from './PageHeader.module.less';
import { UserProfileButton } from './UserProfileButton';

interface IPageSidebarProps
{
  isMobileMenuVisible:boolean;
  setIsMobileMenuVisible:(value:boolean) => void;
}

export class PageHeader extends React.Component<IPageSidebarProps>
{
  public render():React.ReactNode
  {
    const { isMobileMenuVisible, setIsMobileMenuVisible } = this.props;

    console.log('%c --- PageHeader =', 'background:#080;color:#000;');

    return (
      <header className={styles.wrapper}>
        <Button
          className={styles.btnMobileMenu}
          icon={isMobileMenuVisible ? IconNames.CROSS : IconNames.MENU}
          // outlined={true}
          minimal={true}
          intent={Intent.PRIMARY}
          onClick={():void =>
          {
            setIsMobileMenuVisible(!isMobileMenuVisible);
          }}
        />

        <Link to="/" className={styles.logo}>
          <img
            className={styles.logoImage}
            src="assets/images/logo/logo-flow.svg"
            alt="SiliconMint Logo"
          />
        </Link>

        <div className={styles.profileWrapper}>
          <UserProfileButton />
        </div>
      </header>
    );
  }
}
