import { Toolbar } from '@flow/common/components/page/Toolbar';
import { component, di } from '@flow/dependency-injection';
import { SettingsPageController } from '@flow/modules/settings/settingsTemplatePage/SettingsPageController';
import { SettingsPageState } from '@flow/modules/settings/settingsTemplatePage/SettingsPageState';
// import classNames from 'classnames/bind';
import React from 'react';

import styles from './SettingsPageToolbar.module.less';

// const cx = classNames.bind(styles);

@component
export class SettingsPageToolbar extends React.Component
{
  @di private _settingsPageState!:SettingsPageState;
  @di private _settingsPageController!:SettingsPageController;

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    return (
      <>
        <Toolbar
          className={styles.toolbar}
          title={'Page Title'}

          leftClassName={styles.positionLeft}
          leftElements={
            <>

            </>
          }

          centerClassName={styles.positionCenter}
          centerElements={
            <>

            </>
          }

          rightClassName={styles.positionRight}
          rightElements={
            <>

            </>
          }
        />
      </>
    );
  }

  // ---------------------------------------------------------
}
