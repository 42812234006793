import { CommonController } from '@flow/common/CommonController';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { Toolbar } from '@flow/common/components/page/Toolbar';
import { RouteName } from '@flow/common/models/routing/RouteName';
import { component, di } from '@flow/dependency-injection';
import { StaffMemberState } from '@flow/modules/staffing/staff/StaffMemberState';
// import classNames from 'classnames/bind';
import React from 'react';
import { RoutesConfig } from '../../../../../pages/RoutesConfig';

import styles from './StaffMemberToolbar.module.less';

// const cx = classNames.bind(styles);

@component
export class StaffMemberToolbar extends React.Component
{
  @di private _commonController!:CommonController;
  @di private _staffMemberState!:StaffMemberState;

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { staffMember, staffMemberName } = this._staffMemberState;

    if( !staffMember )
    {
      return (
        <div className={styles.toolbarPlaceholder} />
      );
    }

    const { user: { avatar }, avatar_url } = staffMember;

    return (
      <Toolbar
        className={styles.toolbar}
        title={''}

        leftClassName={styles.positionLeft}
        leftElements={
          <>
            <Button
              className={styles.btnArrowLeft}
              icon={IconNames.ARROW_LEFT}
              minimal={true}
              onClick={():void =>
              {
                if( window.history.length > 1 )
                {
                  window.history.back();
                }
                else
                {
                  this._commonController.navigate(RoutesConfig.getRoutePath(RouteName.STAFFING_STAFF) as string);
                }
              }}
            />
            <Avatar
              className={styles.avatar}
              size={30}
              url={avatar_url || avatar}
            />
            {
              staffMemberName &&
              <div className={styles.name}>
                {staffMemberName}
              </div>
            }
          </>
        }

        // centerClassName={styles.positionCenter}
        // centerElements={
        //   <>
        //   </>
        // }

        rightClassName={styles.positionRight}
        rightElements={
          <>
            {/*<Button*/}
            {/*  icon={IconNames.SHARE}*/}
            {/*  minimal={true}*/}
            {/*/>*/}

            {/*<Button*/}
            {/*  className={styles.btnMore}*/}
            {/*  icon={IconNames.MORE}*/}
            {/*  minimal={true}*/}
            {/*/>*/}
          </>
        }
      />
    );
  }
}
