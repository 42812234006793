import { FiltersPopover } from '@flow/common/components/filters/components/FiltersPopover';
import type { IFilterComponentProps } from '@flow/common/components/filters/Filter';
import type { IFilterStatusData, IFilterStatusValue } from '@flow/common/components/filters/staffed/FilterStatus';
import { Checkbox } from '@flow/common/components/form/Checkbox';
import type { Recruiting_Interview_Flow_Stage } from '@flow/data-access/lib/types/graphql.generated';
import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';
// import classNames from 'classnames/bind';
import type React from 'react';
import { Component } from 'react';

import styles from './FilterStatusSelector.module.less';

// const cx = classNames.bind(styles);

@component
export class FilterStatusSelector extends Component<IFilterComponentProps<IFilterStatusValue>>
{
  // ---------------------------------------------------------

  @bind
  private _onChangeFilter(status:string):(isSelected:boolean) => void
  {
    return (isSelected:boolean):void =>
    {
      const { filter, onChangeFilter } = this.props;

      if( !filter.value.statuses )
        filter.value = { statuses: [] };

      if( isSelected )
      {
        onChangeFilter({ statuses: [...filter.value.statuses, status] });
      }
      else
      {
        onChangeFilter({ statuses: filter.value.statuses.filter(s => s !== status) });
      }
    };
  }

  @bind
  private _renderFilterContent():React.ReactElement | null
  {
    const { filter, getFilterData } = this.props;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterData:any = getFilterData?.();

    const statuses:Array<IFilterStatusData> = filterData?.statuses;

    if( !filterData || !statuses ) return null;

    const statusesValues = filter.value.statuses;

    return (
      <div className={styles.content}>

        {statuses.map((status:IFilterStatusData) =>
        {
          const { title, value } = status;

          const isSelected:boolean = statusesValues && statusesValues.includes(value);

          return (
            <Checkbox
              key={value}
              className={styles.checkbox}
              label={title}
              checked={isSelected}
              onChange={this._onChangeFilter(value)}
            />
          );
        })}

      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _getButtonText():React.ReactElement | null
  {
    const { filter, getFilterData } = this.props;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterData:any = getFilterData?.();

    const isValueSelected:boolean = true;

    const interviewFlowStages:Array<Recruiting_Interview_Flow_Stage> = filterData?.interviewFlowStages;

    if( !filterData || !interviewFlowStages || !isValueSelected )
    {
      return <span>{filter.name}</span>;
    }

    return <span><b>{filter.name}</b>: {'Staffed'}</span>;
  }

  // ---------------------------------------------------------
  public render():React.ReactNode
  {
    const { filter, onRemoveFilter, wrapperClassName, buttonClearText } = this.props;

    const isValueSelected:boolean = filter.value.statuses && !!filter.value.statuses.length;

    return (
      <FiltersPopover
        wrapperClassName={wrapperClassName}
        title={filter.name}
        content={this._renderFilterContent()}
        contentButtonClearText={buttonClearText}
        onClickButtonClear={onRemoveFilter}
        buttonText={this._getButtonText()}
        isValueSelected={isValueSelected}
        // customButton={(<div>custom button</div>)}
      />
    );
  }
}
