import { Position } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { CommonController, StaffOrCandidateUrl } from '@flow/common/CommonController';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { AnchorButton } from '@flow/common/components/form/AnchorButton';
import { Button } from '@flow/common/components/form/Button';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { AuthController } from '@flow/common/controllers/AuthController';
import { AuthState } from '@flow/common/controllers/AuthState';
import type { User } from '@flow/common/models/auth/User';
import { component, di } from '@flow/dependency-injection';
import classNames from 'classnames/bind';
import React from 'react';
import styles from './UserProfileButton.module.less';

const cx = classNames.bind(styles);

@component
export class UserProfileButton extends React.Component
{
  @di private _authState!:AuthState;
  @di private _authController!:AuthController;
  @di private _commonController!:CommonController;

  private _renderPopup(user:User, roleDisplayName:string):React.ReactElement
  {
    const myProfilePath:StaffOrCandidateUrl = this._commonController.getStaffMemberUrl(this._authState.userAsStaff?.id);

    return (
      <div className={styles.popup}>

        <div className={styles.profile}>

          <Avatar
            className={styles.userAvatarBig}
            url={user.avatar}
            size={50}
          />

          <div className={styles.userInfo}>

            <div className={styles.userName}>
              {user.firstName} {user.lastName}
            </div>

            <div className={styles.userRole}>
              {roleDisplayName}
            </div>

            <div className={styles.userEmail}>
              {user.email}
            </div>

          </div>

        </div>
        <div className={styles.actions}>

          <div className={styles.account}>

            <AnchorButton
              className={styles.button}
              icon={IconNames.USER}
              iconClassName={styles.icon}
              minimal={true}
              text={'My Profile'}
              href={myProfilePath}
            />

          </div>

          <div
            className={cx(styles.logout, 'test-logout')}
            onClick={():void => this._authController.logout()}
          >
            <Tooltip2
              content={'Logout'}
              placement={'top'}
            >
              <Button
                className={styles.button}
                icon={IconNames.LOG_OUT}
                iconClassName={styles.icon}
                minimal={true}
              />
            </Tooltip2>

          </div>

        </div>
      </div>
    );
  }

  public render():React.ReactNode
  {
    const { user, currentRoleDisplayName } = this._authState;

    if( user )
    {
      return (
        <>
          <div className={cx(styles.profileButton, 'test-user-profile')}>
            <Popover2
              hasBackdrop
              modifiers={{
                arrow: {
                  enabled: false
                }
              }}
              portalClassName={styles.profilePortal}
              content={this._renderPopup(user, currentRoleDisplayName)}
              position={Position.BOTTOM_LEFT}
              popoverClassName={styles.profilePopover}

            >
              <Avatar
                className={styles.userAvatar}
                url={user.avatar}
              />
            </Popover2>
          </div>
        </>
      );
    }
    else
    {
      return (
        <>
          <div key={'loggedout'} className={styles.loggedIn}>Not logged in</div>
        </>
      );
    }
  }
}
