import { FullscreenPage } from '@flow/common/components/page/FullscreenPage';
import { PageTitle } from '@flow/common/components/page/PageTitle';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di } from '@flow/dependency-injection';
import { FeedbackFormContent } from '@flow/modules/recruiting/feedbackForm/components/FeedbackFormContent';
import React from 'react';
import { CandidateFeedbackFormSidebar } from '../candidates/components/candidate/communication/feedback/CandidateFeedbackFormSidebar';
import { FeedbackFormController } from './FeedbackFormController';
import { FeedbackFormState } from './FeedbackFormState';

@componentWithRouter
export class FeedbackForm extends React.Component<IWithRouterProps>
{
  @di private _feedbackFormState!:FeedbackFormState;
  @di private _feedbackFormController!:FeedbackFormController;

  // ----------------------------------------------------

  public componentDidMount():void
  {
    const { params } = this.props;

    console.log('%c FeedbackForm params =', 'background:#0f0;color:#000;', params);

    if( !params || !params.eventId )
      return;

    this._feedbackFormController.initFeedbackFromEventId(parseInt(params.eventId as string));
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { feedbackFormEvent, btnBackUrl } = this._feedbackFormState;

    if( !feedbackFormEvent )
      return null;

    return (
      <>
        <PageTitle title={'Feedback form'} />

        <FullscreenPage
          title={'Feedback Form'}
          btnBackTitle={'Back to Candidate'}
          btnBackUrl={btnBackUrl}
          sidebarElements={<CandidateFeedbackFormSidebar />}
          contentElements={<FeedbackFormContent />}
        />
      </>
    );
  }

  // ----------------------------------------------------
}
