import type * as Types from './types/graphql.generated';

import { gql } from '@apollo/client';
import { Recruiting_Candidate_Allocation_FragmentFragmentDoc } from './candidate.generated';
import * as Apollo from '@apollo/client';
export type AllCustomersQueryVariables = Types.Exact<Record<string, never>>;


export type AllCustomersQuery = { common_customer:Array<{ id:number; name:string; alias:string; status:Types.Common_Customer_Status_Enum; }>; };

export type AllCandidatesQueryVariables = Types.Exact<{
  userFiltersType:Types.Common_Filter_Type_Enum;
  userId:Types.Scalars['Int'];
}>;


export type AllCandidatesQuery = { recruiting_position_group:Array<{ id:number; name:string; interview_flow_id?:number | null; }>; common_skill_tag:Array<{ id:number; name:string; }>; common_filter:Array<{ id:number; name:string; content:any; }>; recruiting_interview_flow_stage:Array<{ id:number; name:string; }>; common_user:Array<{ id:number; first_name:string; last_name:string; role_names?:string | null; }>; common_country:Array<{ id:number; name:string; }>; common_city:Array<{ id:number; name:string; country_id:number; }>; };

export type AddCandidateMutationVariables = Types.Exact<{
  first_name:Types.Scalars['String'];
  last_name:Types.Scalars['String'];
  phone:Types.Scalars['String'];
  email:Types.Scalars['String'];
  linkedin_profile_url?:Types.InputMaybe<Types.Scalars['String']>;
  avatar_url?:Types.InputMaybe<Types.Scalars['String']>;
  position_group_id?:Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type AddCandidateMutation = { insert_recruiting_candidate_one?:{ id:number; first_name:string; last_name:string; phone?:string | null; email?:string | null; avatar_url?:string | null; linkedin_profile_url?:string | null; skill_tags:Array<{ skill_tag:{ id:number; name:string; }; }>; } | null; };

export type NewCandidateMutationVariables = Types.Exact<{
  first_name:Types.Scalars['String'];
  last_name:Types.Scalars['String'];
  linkedin_profile_url?:Types.InputMaybe<Types.Scalars['String']>;
  created_by_user_id:Types.Scalars['Int'];
  sourced_by_user_id:Types.Scalars['Int'];
  assigned_user_id?:Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type NewCandidateMutation = { insert_recruiting_candidate_one?:{ id:number; first_name:string; last_name:string; avatar_url?:string | null; linkedin_profile_url?:string | null; created_at:any; created_by:{ id:number; }; } | null; };

export type NewCandidatePositionGroupsMutationVariables = Types.Exact<{
  positionGroups:Array<Types.Recruiting_Candidate_Position_Group_Insert_Input> | Types.Recruiting_Candidate_Position_Group_Insert_Input;
}>;


export type NewCandidatePositionGroupsMutation = { insert_recruiting_candidate_position_group?:{ affected_rows:number; } | null; };

export type NewFilterMutationVariables = Types.Exact<{
  name:Types.Scalars['String'];
  type:Types.Common_Filter_Type_Enum;
  user_id:Types.Scalars['Int'];
  content:Types.Scalars['json'];
}>;


export type NewFilterMutation = { insert_common_filter_one?:{ id:number; name:string; content:any; } | null; };

export type UpdateFilterMutationVariables = Types.Exact<{
  content:Types.Scalars['json'];
  id:Types.Scalars['Int'];
}>;


export type UpdateFilterMutation = { update_common_filter_by_pk?:{ id:number; content:any; } | null; };

export type QueryCandidatesQueryVariables = Types.Exact<{
  where?:Types.InputMaybe<Types.Recruiting_Candidate_Bool_Exp>;
  offset:Types.Scalars['Int'];
  limit:Types.Scalars['Int'];
  order_by?:Types.InputMaybe<Array<Types.Recruiting_Candidate_Order_By> | Types.Recruiting_Candidate_Order_By>;
}>;


export type QueryCandidatesQuery = { recruiting_candidate:Array<{ id:number; first_name:string; last_name:string; phone?:string | null; email?:string | null; avatar_url?:string | null; linkedin_profile_url?:string | null; created_at:any; interview_flow_stage_id?:number | null; status?:Types.Recruiting_Candidate_Status_Enum | null; staff_id?:number | null; country_id?:number | null; city_id?:number | null; candidate_position_groups:Array<{ position_group:{ id:number; name:string; interview_flow_id?:number | null; ui_order:number; }; }>; skill_tags:Array<{ skill_tag:{ id:number; name:string; }; }>; interview_flow_stage?:{ id:number; name:string; } | null; assigned_to?:{ id:number; first_name:string; last_name:string; } | null; created_by:{ id:number; first_name:string; last_name:string; }; candidate_allocations:Array<{ id:number; notes?:string | null; is_final:boolean; user:{ id:number; first_name:string; last_name:string; avatar?:string | null; staffs:Array<{ avatar_url?:string | null; }>; }; allocation_positions:Array<{ position_id:number; }>; }>; }>; recruiting_candidate_aggregate:{ aggregate?:{ count:number; } | null; }; };

export type SearchCandidatesQueryVariables = Types.Exact<{
  where:Types.Recruiting_Candidate_Bool_Exp;
  limit?:Types.Scalars['Int'];
  order_by?:Types.InputMaybe<Array<Types.Recruiting_Candidate_Order_By> | Types.Recruiting_Candidate_Order_By>;
}>;


export type SearchCandidatesQuery = { recruiting_candidate:Array<{ id:number; first_name:string; last_name:string; phone?:string | null; email?:string | null; avatar_url?:string | null; linkedin_profile_url?:string | null; created_at:any; candidate_position_groups:Array<{ position_group:{ id:number; name:string; }; }>; }>; };


export const AllCustomersDocument = gql`
    query AllCustomers {
  common_customer {
    id
    name
    alias
    status
  }
}
    `;
export type AllCustomersQueryResult = Apollo.QueryResult<AllCustomersQuery, AllCustomersQueryVariables>;
export const AllCandidatesDocument = gql`
    query AllCandidates($userFiltersType: common_filter_type_enum!, $userId: Int!) {
  recruiting_position_group(
    where: {deleted_at: {_is_null: true}, is_active: {_eq: true}}
    order_by: {ui_order: asc}
  ) {
    id
    name
    interview_flow_id
  }
  common_skill_tag {
    id
    name
  }
  common_filter(where: {type: {_eq: $userFiltersType}, user_id: {_eq: $userId}}) {
    id
    name
    content
  }
  recruiting_interview_flow_stage {
    id
    name
  }
  common_user {
    id
    first_name
    last_name
    role_names
  }
  common_country {
    id
    name
  }
  common_city {
    id
    name
    country_id
  }
}
    `;
export type AllCandidatesQueryResult = Apollo.QueryResult<AllCandidatesQuery, AllCandidatesQueryVariables>;
export const AddCandidateDocument = gql`
    mutation AddCandidate($first_name: String!, $last_name: String!, $phone: String!, $email: String!, $linkedin_profile_url: String = "", $avatar_url: String = "", $position_group_id: Int = "") {
  insert_recruiting_candidate_one(
    object: {first_name: $first_name, last_name: $last_name, phone: $phone, email: $email, linkedin_profile_url: $linkedin_profile_url, avatar_url: $avatar_url, position_group_id: $position_group_id}
  ) {
    id
    first_name
    last_name
    phone
    email
    avatar_url
    linkedin_profile_url
    skill_tags {
      skill_tag {
        id
        name
      }
    }
  }
}
    `;
export type AddCandidateMutationFn = Apollo.MutationFunction<AddCandidateMutation, AddCandidateMutationVariables>;
export type AddCandidateMutationResult = Apollo.MutationResult<AddCandidateMutation>;
export type AddCandidateMutationOptions = Apollo.BaseMutationOptions<AddCandidateMutation, AddCandidateMutationVariables>;
export const NewCandidateDocument = gql`
    mutation NewCandidate($first_name: String!, $last_name: String!, $linkedin_profile_url: String = "", $created_by_user_id: Int!, $sourced_by_user_id: Int!, $assigned_user_id: Int) {
  insert_recruiting_candidate_one(
    object: {first_name: $first_name, last_name: $last_name, linkedin_profile_url: $linkedin_profile_url, created_by_user_id: $created_by_user_id, sourced_by_user_id: $sourced_by_user_id, assigned_user_id: $assigned_user_id}
  ) {
    id
    first_name
    last_name
    avatar_url
    linkedin_profile_url
    created_at
    created_by {
      id
    }
  }
}
    `;
export type NewCandidateMutationFn = Apollo.MutationFunction<NewCandidateMutation, NewCandidateMutationVariables>;
export type NewCandidateMutationResult = Apollo.MutationResult<NewCandidateMutation>;
export type NewCandidateMutationOptions = Apollo.BaseMutationOptions<NewCandidateMutation, NewCandidateMutationVariables>;
export const NewCandidatePositionGroupsDocument = gql`
    mutation NewCandidatePositionGroups($positionGroups: [recruiting_candidate_position_group_insert_input!]!) {
  insert_recruiting_candidate_position_group(objects: $positionGroups) {
    affected_rows
  }
}
    `;
export type NewCandidatePositionGroupsMutationFn = Apollo.MutationFunction<NewCandidatePositionGroupsMutation, NewCandidatePositionGroupsMutationVariables>;
export type NewCandidatePositionGroupsMutationResult = Apollo.MutationResult<NewCandidatePositionGroupsMutation>;
export type NewCandidatePositionGroupsMutationOptions = Apollo.BaseMutationOptions<NewCandidatePositionGroupsMutation, NewCandidatePositionGroupsMutationVariables>;
export const NewFilterDocument = gql`
    mutation NewFilter($name: String!, $type: common_filter_type_enum!, $user_id: Int!, $content: json!) {
  insert_common_filter_one(
    object: {name: $name, user_id: $user_id, content: $content, type: $type}
  ) {
    id
    name
    content
  }
}
    `;
export type NewFilterMutationFn = Apollo.MutationFunction<NewFilterMutation, NewFilterMutationVariables>;
export type NewFilterMutationResult = Apollo.MutationResult<NewFilterMutation>;
export type NewFilterMutationOptions = Apollo.BaseMutationOptions<NewFilterMutation, NewFilterMutationVariables>;
export const UpdateFilterDocument = gql`
    mutation UpdateFilter($content: json!, $id: Int!) {
  update_common_filter_by_pk(_set: {content: $content}, pk_columns: {id: $id}) {
    id
    content
  }
}
    `;
export type UpdateFilterMutationFn = Apollo.MutationFunction<UpdateFilterMutation, UpdateFilterMutationVariables>;
export type UpdateFilterMutationResult = Apollo.MutationResult<UpdateFilterMutation>;
export type UpdateFilterMutationOptions = Apollo.BaseMutationOptions<UpdateFilterMutation, UpdateFilterMutationVariables>;
export const QueryCandidatesDocument = gql`
    query QueryCandidates($where: recruiting_candidate_bool_exp, $offset: Int!, $limit: Int!, $order_by: [recruiting_candidate_order_by!] = {created_at: asc}) {
  recruiting_candidate(
    where: $where
    offset: $offset
    limit: $limit
    order_by: $order_by
  ) {
    id
    first_name
    last_name
    phone
    email
    avatar_url
    linkedin_profile_url
    created_at
    interview_flow_stage_id
    status
    staff_id
    country_id
    city_id
    candidate_position_groups {
      position_group {
        id
        name
        interview_flow_id
        ui_order
      }
    }
    skill_tags {
      skill_tag {
        id
        name
      }
    }
    interview_flow_stage {
      id
      name
    }
    assigned_to {
      id
      first_name
      last_name
    }
    created_by {
      id
      first_name
      last_name
    }
    candidate_allocations {
      ...recruiting_candidate_allocation_fragment
    }
  }
  recruiting_candidate_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${Recruiting_Candidate_Allocation_FragmentFragmentDoc}`;
export type QueryCandidatesQueryResult = Apollo.QueryResult<QueryCandidatesQuery, QueryCandidatesQueryVariables>;
export const SearchCandidatesDocument = gql`
    query SearchCandidates($where: recruiting_candidate_bool_exp!, $limit: Int! = 20, $order_by: [recruiting_candidate_order_by!] = {created_at: asc}) {
  recruiting_candidate(where: $where, limit: $limit, order_by: $order_by) {
    id
    first_name
    last_name
    phone
    email
    avatar_url
    linkedin_profile_url
    created_at
    candidate_position_groups {
      position_group {
        id
        name
      }
    }
  }
}
    `;
export type SearchCandidatesQueryResult = Apollo.QueryResult<SearchCandidatesQuery, SearchCandidatesQueryVariables>;
export const namedOperations = {
  Query: {
    AllCustomers: 'AllCustomers',
    AllCandidates: 'AllCandidates',
    QueryCandidates: 'QueryCandidates',
    SearchCandidates: 'SearchCandidates'
  },
  Mutation: {
    AddCandidate: 'AddCandidate',
    NewCandidate: 'NewCandidate',
    NewCandidatePositionGroups: 'NewCandidatePositionGroups',
    NewFilter: 'NewFilter',
    UpdateFilter: 'UpdateFilter'
  }
}