import { ButtonSize } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { TextInput } from '@flow/common/components/form/TextInput';
import { Dialog } from '@flow/common/components/page/Dialog';
import { Intent } from '@flow/common/components/types/Types';
import type { Recruiting_Position_Template } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { PositionDialogItem } from '@flow/modules/customers/teams/components/positions/PositionDialogItem';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';
import { NewPositionController } from '../../NewPositionController';
import { NewPositionState } from '../../NewPositionState';
import { TeamController } from '../../TeamController';
import styles from './PositionDialog.module.less';

const cx = classNames.bind(styles);

interface State
{
  inputIsFocused:boolean;
}

@component
export class PositionDialog extends React.Component<any, State>
{
  @di private _positionState!:NewPositionState;
  @di private _positionController!:NewPositionController;
  @di private _teamController!:TeamController;

  // ----------------------------------------------------

  public constructor(props:any)
  {
    super(props);

    this.state = {
      inputIsFocused: false
    };
  }

  // ----------------------------------------------------
  public render():ReactNode
  {
    const {
      isPositionDialogOpen,
      isPositionLoading,
      search,
      filteredAndOrderedPositionTemplates,
      isPositionValid
    } = this._positionState;

    const { inputIsFocused } = this.state;

    const inputIsOpened:boolean = inputIsFocused || search !== '';

    return (
      <Dialog
        title="Add position"
        isOpen={isPositionDialogOpen}
        isLoading={isPositionLoading}
        onClose={this._positionController.hidePositionDialog}
        className={styles.newPositionDialog}

        buttonsSize={ButtonSize.LARGE}

        primaryButtonText="Add"
        primaryButtonIntent={Intent.PRIMARY}
        primaryButtonOnClick={this._teamController.addPosition}
        isPrimaryButtonDisabled={!isPositionValid}

        secondaryButtonText={'Cancel'}
        secondaryButtonIntent={Intent.PRIMARY}
        isSecondaryButtonOutlined={true}
      >
        <div className={styles.searchInputWrapper}>
          <TextInput
            placeholder={'Search ...'}
            className={cx(styles.positionSearchInput, { inputIsOpened })}
            value={search}
            large={true}
            leftIcon={IconNames.SEARCH}
            onChange={this._positionController.setSearch}
            autoFocus={true}
            onFocus={():unknown => this.setState({ inputIsFocused: true })}
            onBlur={():unknown => this.setState({ inputIsFocused: false })}
          />
        </div>

        <div className={cx(styles.positionDialogContentWrapper)}>
          {Object.keys(filteredAndOrderedPositionTemplates).map((staffRoleName:any) => (
            <div key={staffRoleName}>
              <div className={cx(styles.staffRoleName)}>{staffRoleName}</div>
              {filteredAndOrderedPositionTemplates[staffRoleName].map((recruitingPositionTemplate:Recruiting_Position_Template, index) =>
                (
                  <PositionDialogItem
                    key={recruitingPositionTemplate.id}
                    search={search}
                    id={recruitingPositionTemplate.id}
                    isNextSelected={index + 1 <= filteredAndOrderedPositionTemplates[staffRoleName].length - 1 ?
                      filteredAndOrderedPositionTemplates[staffRoleName][index + 1].id == this._positionState.clickedPositionDialogItemId : false}
                    isSelected={this._positionState.clickedPositionDialogItemId == recruitingPositionTemplate.id}
                    jobTitle={recruitingPositionTemplate.name}
                    onClick={():void => this._positionController.setSelectedPositionTemplate(recruitingPositionTemplate)}
                  />
                ))}
            </div>
          ))}
        </div>
      </Dialog>
    );
  }

  // ----------------------------------------------------
}
