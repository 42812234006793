import { PageTitle } from '@flow/common/components/page/PageTitle';
import { PageUtil } from '@flow/common/utils/PageUtil';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di, diModule } from '@flow/dependency-injection';
import { CustomerState } from '@flow/modules/customers/teams/CustomerState';
import bind from 'bind-decorator';
import type { ReactNode } from 'react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { PageNotFound } from '../../../pages/404';
import { CustomerToolbar } from './components/customer/CustomerToolbar';
import { TeamsList } from './components/teams/TeamsList';
import { CustomerController } from './CustomerController';
import { NewPositionController } from './NewPositionController';
import { NewPositionState } from './NewPositionState';
import { TeamController } from './TeamController';
import { TeamState } from './TeamState';

@diModule({
  providers: [
    TeamState,
    TeamController,
    NewPositionState,
    NewPositionController
    // PositionState,
    // PositionController
  ]
})
@componentWithRouter
export class CustomerTeams extends React.Component<IWithRouterProps>
{
  @di private _customerState!:CustomerState;
  @di private _customerController!:CustomerController;

  // ----------------------------------------------------

  public componentDidMount():void
  {
    this._customerController.initFromLocalStorage();
    this._checkPageProps();
  }

  // ----------------------------------------------------

  public componentDidUpdate(prevProps:Readonly<IWithRouterProps>):void
  {
    if( prevProps.params?.positionId !== this.props.params?.positionId )
    {
      this._checkPageProps();
    }
  }

  // ----------------------------------------------------

  @bind
  private _checkPageProps():void
  {
    const { params } = this.props;

    if( !params || !params.customerId )
      return;

    const isPositionDescriptionPage:boolean = !!(params && params.positionId);

    if( isPositionDescriptionPage ) return;

    const customerId:number = parseFloat(String(params.customerId));

    this._customerController.initCustomer(customerId);
  }

  // ----------------------------------------------------

  public componentWillUnmount():void
  {
    PageUtil.disposeReactions(this._customerState.disposers);
  }

  // ----------------------------------------------------

  public render():ReactNode
  {
    const { params } = this.props;
    const isPositionDescriptionPage:boolean = !!(params && params.positionId);

    const { customer, pageNotFound } = this._customerState;

    if( pageNotFound ) return <PageNotFound />;

    return (
      <>
        {
          isPositionDescriptionPage &&
          <Outlet />
        }
        {
          !isPositionDescriptionPage &&
          <>
            <PageTitle title={customer?.alias} />
            <CustomerToolbar />
            <TeamsList />
          </>
        }
      </>
    );
  }
}
