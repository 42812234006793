import { BlankState } from '@flow/common/components/form/BlankState';
import { DebugUtil } from '@flow/common/utils/DebugUtil';
import { SlotUtil } from '@flow/common/utils/SlotUtil';
import type { Recruiting_Position, Staffing_Customer_Team } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';
import { StaffBoardController } from '../StaffBoardController';
import { PositionCandidates } from './PositionCandidates';

import styles from './TeamCardPositions.module.less';
import type { IUnitedTeamLead } from './TeamCardStaffedPositions';
import { TeamCardStaffedPositions } from './TeamCardStaffedPositions';

const cx = classNames.bind(styles);

interface ITeamCardPositionsProps
{
  team:Staffing_Customer_Team;
  fullWidth?:boolean;
  unitedTeamLead?:IUnitedTeamLead;
}

// TODO: remove
const __DEBUG:boolean = false;

@component
export class TeamCardPositions extends React.Component<ITeamCardPositionsProps>
{
  @di private _staffBoardController!:StaffBoardController;

  // ---------------------------------------------------------

  @bind
  private _renderPosition(position:Recruiting_Position, isTeamLead:boolean, isFirstUnitedTeamLead:boolean):React.ReactNode | null
  {
    const { fullWidth, unitedTeamLead } = this.props;

    if( isTeamLead && isFirstUnitedTeamLead && (!unitedTeamLead || (unitedTeamLead && unitedTeamLead.index > 0)) ) return null;

    const staffedSlots = SlotUtil.getStaffedSlots(position.customer_team_slots);
    const openSlotCount = position.customer_team_slots.length - staffedSlots.length;

    const hiddenTeamLead:boolean = isTeamLead && !isFirstUnitedTeamLead && !!unitedTeamLead;
    const firstUnitedTeamLead:boolean = isTeamLead && isFirstUnitedTeamLead && !!unitedTeamLead && unitedTeamLead.index === 0;

    // const style:React.CSSProperties | undefined = unitedTeamLead && isFirstUnitedTeamLead
    const style:React.CSSProperties | undefined = isTeamLead && unitedTeamLead
      ? {
        width: `calc(${100 * (unitedTeamLead.length || 1)}% + ${10 * (unitedTeamLead.length - 1)}px)`
      } : undefined;

    return (
      <div
        key={position.id}
        className={cx(styles.positionWrapper, {
          isTeamLead: isTeamLead && !fullWidth,
          hiddenTeamLead,
          firstUnitedTeamLead
        })}
        style={style}
      >
        <div
          className={styles.content}
          style={hiddenTeamLead ? style : undefined}
        >
          <div className={styles.positionHeader}>
            {__DEBUG && DebugUtil.id(position.id)}
            {position.position_template?.staff_role.name || '?????'}
            {
              __DEBUG && DebugUtil.isEnabled &&
              <span style={{ color: '#f00' }}> {position.position_template?.name}</span>
            }
          </div>

          <div className={cx(styles.allSlotsWrapper, { leadership: fullWidth })}>

            <TeamCardStaffedPositions
              allSlots={position.customer_team_slots}
              staffedSlots={staffedSlots}
              fullWidth={fullWidth}
              unitedTeamLead={isTeamLead ? unitedTeamLead : undefined}
            />
            {
              openSlotCount > 0 &&
              <PositionCandidates
                position={position}
                openSlotsCount={openSlotCount}
                totalSlotsCount={position.customer_team_slots.length}
                fullWidth={fullWidth}
              />
            }
          </div>

        </div>
      </div>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { team } = this.props;

    if( !Array.isArray(team.positions) || team.positions.length == 0 )
      return (
        <div key={team.id} className={styles.positionsWrapper}>
          <div className={styles.otherPositionsWrapper}>
            <BlankState
              className={styles.blankState}
              title={'No positions'}
            />
          </div>
        </div>
      );

    const leadsPositions:Array<Recruiting_Position> = team.positions.filter((position:Recruiting_Position) =>
    {
      const staffRoleName:string = position.position_template?.staff_role.name ?? '';
      return staffRoleName.toLowerCase().indexOf('team lead') !== -1;
    });

    const otherPositions:Array<Recruiting_Position> = team.positions.filter((position:Recruiting_Position) =>
    {
      const staffRoleName:string = position.position_template?.staff_role.name ?? '';
      return staffRoleName.toLowerCase().indexOf('team lead') === -1;
    });

    return (
      <div key={team.id} className={styles.positionsWrapper}>

        {leadsPositions.map((position:Recruiting_Position) => this._renderPosition(position, true, true) as JSX.Element)}
        {leadsPositions.map((position:Recruiting_Position) => this._renderPosition(position, true, false) as JSX.Element)}

        <div className={styles.otherPositionsWrapper}>
          {otherPositions.map((position:Recruiting_Position) => this._renderPosition(position, false, false) as JSX.Element)}
        </div>

      </div>
    );
  }

  // ---------------------------------------------------------
}
