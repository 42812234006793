import type * as Types from './types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AllPositionTemplatesQueryVariables = Types.Exact<Record<string, never>>;


export type AllPositionTemplatesQuery = { recruiting_position_template:Array<{ id:number; name:string; staff_role:{ id:number; name:string; }; }>; };


export const AllPositionTemplatesDocument = gql`
    query AllPositionTemplates {
  recruiting_position_template {
    id
    name
    staff_role {
      id
      name
    }
  }
}
    `;
export type AllPositionTemplatesQueryResult = Apollo.QueryResult<AllPositionTemplatesQuery, AllPositionTemplatesQueryVariables>;
export const namedOperations = {
  Query: {
    AllPositionTemplates: 'AllPositionTemplates'
  }
}