import { ListWrapper } from '@flow/common/components/page/ListWrapper';
import { component, di } from '@flow/dependency-injection';
import { PositionsListCustomers } from '@flow/modules/recruiting/positions/components/PositionsListCustomers';
import { PositionsController } from '@flow/modules/recruiting/positions/PositionsController';
import { PositionsState } from '@flow/modules/recruiting/positions/PositionsState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { runInAction } from 'mobx';
import React from 'react';

import styles from './PositionsList.module.less';

const cx = classNames.bind(styles);

@component
export class PositionsList extends React.Component
{
  @di private _positionsState!:PositionsState;
  @di private _positionsController!:PositionsController;

  // ---------------------------------------------------------

  public componentDidMount():void
  {
    const { isPositionsLoaded } = this._positionsState;

    if( !isPositionsLoaded )
    {
      this._positionsController.initPositions();
    }
  }

  // ---------------------------------------------------------

  @bind
  private _onScroll(event:React.UIEvent<HTMLDivElement>):void
  {
    const { isPositionsLoaded } = this._positionsState;

    if( !isPositionsLoaded ) return;

    if( this._positionsState.positionsListScrollYPosition != Number(event.currentTarget.scrollTop) )
      runInAction(() => this._positionsState.positionsListScrollYPosition = Number(event.currentTarget.scrollTop));
  }

  // ---------------------------------------------------------

  @bind
  private _renderHeader():React.ReactNode
  {
    return (
      <div className={styles.header}>
        <div className={cx(styles.headerTitle, styles.titlePositionTemplate)}>
          Position
        </div>
        <div className={cx(styles.headerTitle, styles.titleStaffRole)}>
          Staff Role
        </div>
        <div className={cx(styles.headerTitle, styles.titleStaffedTotal)}>
          Staffed/Total
        </div>
        <div className={cx(styles.headerTitle, styles.titlePositionGroup)}>
          Position Group
        </div>
      </div>
    );
  }

  public render():React.ReactNode
  {
    const { positionsListScrollYPosition } = this._positionsState;

    return (
      <ListWrapper
        header={this._renderHeader()}
        headerClassName={styles.headerWrapper}
        content={<PositionsListCustomers />}
        onScroll={this._onScroll}
        scrollPosition={positionsListScrollYPosition}
      />
    );
  }

  // ---------------------------------------------------------
}
