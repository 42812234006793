import type { IButtonProps } from '@flow/common/components/form/Button';
import { Button } from '@flow/common/components/form/Button';
import type { IconName } from '@flow/common/components/form/Icon';
import { IconNames } from '@flow/common/components/form/Icon';
import classNames from 'classnames/bind';
import type React from 'react';
import { Component } from 'react';

import styles from './ButtonOrderBy.module.less';

const cx = classNames.bind(styles);

export enum OrderByType
{
  ASC = 'ASC',
  DESC = 'DESC',
}

interface Props extends IButtonProps
{
  isOrderable:boolean;
  orderBy:OrderByType | null;
}

export class ButtonOrderBy extends Component<Props>
{
  public render():React.ReactNode
  {
    const { className, isOrderable, orderBy, text } = this.props;

    const icon:IconName = orderBy
      ? orderBy === OrderByType.ASC ? IconNames.ARROW_DOWN : IconNames.ARROW_UP
      : null;

    return isOrderable
      ? (
        <Button
          {...this.props}
          className={cx(styles.btn, className, { isOrdered: orderBy })}
          minimal={true}
          rightIcon={icon}
          iconClassName={styles.btnIcon}
        />
      )
      : (
        <div className={cx(styles.colName, className)}>
          {text}
        </div>
      );
  }
}
