import { Button, ButtonSize } from '@flow/common/components/form/Button';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { Tag } from '@flow/common/components/form/Tag';
import { TextInput } from '@flow/common/components/form/TextInput';
import { Intent } from '@flow/common/components/types/Types';
import type { Common_Skill_Tag } from '@flow/data-access/lib/types/graphql.generated';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { component, di } from '@flow/dependency-injection';
import classNames from 'classnames/bind';
import { action, toJS } from 'mobx';
import type React from 'react';
import { Component } from 'react';
import { CandidatesController } from '../../../CandidatesController';
import { CandidatesState } from '../../../CandidatesState';

import styles from './CandidateFormContent.module.less';
import { SelectSkillTags } from './SelectSkillTags';

const cx = classNames.bind(styles);

@component
export class CandidateFormContent extends Component<IWithRouterProps>
{
  @di private _candidatesState!:CandidatesState;
  @di private _candidatesController!:CandidatesController;

  @action.bound
  private _onChangeName(newValue:string):void
  {
    this._candidatesState.candidateData.first_name = newValue;
  }

  @action.bound
  private _onChangeLinkedin(newValue:string):void
  {
    this._candidatesState.candidateData.linkedin_profile_url = newValue;
  }

  @action.bound
  private _onChangePhone(newValue:string):void
  {
    this._candidatesState.candidateData.phone = newValue;
  }

  @action.bound
  private _onChangeEmail(newValue:string):void
  {
    this._candidatesState.candidateData.email = newValue;
  }

  // @bind
  // @action
  // private _onSelectPositionGroup(positionGroup:Recruiting_Position_Group):void
  // {
  //   this._candidatesState.candidateData.position_group_id = positionGroup.id;
  // }

  @action.bound
  private _onSelectSkillTag(skillTags:Array<Common_Skill_Tag>):void
  {
    console.log('%c _onSelectSkillTag skillTags =', 'background:#0f0;color:#000;', toJS(skillTags));
    this._candidatesState.selectedSkillTags = skillTags;
  }

  public render():React.ReactNode
  {
    const { isFormModeAdd, isFormModeEdit, candidateData, selectedSkillTags } = this._candidatesState;

    const { first_name, last_name, avatar_url } = candidateData;

    const name:string = `${first_name || ''}${first_name && last_name ? ' ' : ''}${last_name ?? ''}`;
    const linkedin_profile_url:string = candidateData.linkedin_profile_url || '';
    const phone:string = candidateData.phone || '';
    const email:string = candidateData.email || '';

    // const positionGroup:Recruiting_Position_Group | undefined = this._candidatesState.getPositionGroupById(position_group_id);

    return (
      <>
        <div className={styles.formContent}>

          <div className={styles.info}>

            <div className={styles.photo}>
              {
                avatar_url &&
                <img
                  className={styles.photoImg}
                  src={avatar_url}
                  alt={name}
                />
              }
            </div>

            <div className={styles.details}>

              {/* TODO --------------------------------------- */}

              <div className={styles.firstName}>
                <div className={styles.fieldWrapper}>
                  {/*<Tag minimal={true}>Add name...</Tag>*/}
                  {
                    isFormModeAdd &&
                    <TextInput
                      value={name}
                      small={true}
                      placeholder={'Add name...'}
                      onChange={this._onChangeName}
                    />
                  }
                  {
                    isFormModeEdit &&
                    <span>{name}</span>
                  }
                </div>
              </div>

              {/* TODO --------------------------------------- */}

              <div className={styles.role}>
                <div className={styles.fieldWrapper}>
                  <Tag minimal={true}>Add role...</Tag>
                </div>
              </div>

              {/* TODO --------------------------------------- */}

              <div className={styles.row}>
                <div className={styles.left}>

                  {/* TODO --------------------------------------- */}

                  <div className={styles.fieldWrapper}>
                    <Icon
                      className={cx(styles.fieldIcon, styles.linkedin)}
                      icon={IconNames.GLOBE}
                    />
                    {
                      isFormModeAdd &&
                      <TextInput
                        value={linkedin_profile_url}
                        small={true}
                        placeholder={'Add Linkedin profile'}
                        onChange={this._onChangeLinkedin}
                      />
                    }
                    {
                      isFormModeEdit && !linkedin_profile_url &&
                      <Tag minimal={true}>Add Linkedin profile</Tag>
                    }
                    {
                      isFormModeEdit && linkedin_profile_url &&
                      <span>{linkedin_profile_url}</span>
                    }
                  </div>

                  {/* TODO --------------------------------------- */}

                  <div className={styles.fieldWrapper}>
                    <Icon
                      className={styles.fieldIcon}
                      icon={IconNames.PHONE}
                    />
                    {
                      isFormModeAdd &&
                      <TextInput
                        value={phone}
                        small={true}
                        placeholder={'Add phone'}
                        onChange={this._onChangePhone}
                      />
                    }
                    {
                      isFormModeEdit && !phone &&
                      <Tag minimal={true}>Add phone</Tag>
                    }
                    {
                      isFormModeEdit && phone &&
                      <span>{phone}</span>
                    }
                  </div>

                  {/* TODO --------------------------------------- */}

                  <div className={styles.fieldWrapper}>
                    <Icon
                      className={styles.fieldIcon}
                      icon={IconNames.ENVELOPE}
                    />
                    {
                      isFormModeAdd &&
                      <TextInput
                        value={email}
                        small={true}
                        placeholder={'Add email'}
                        onChange={this._onChangeEmail}
                      />
                    }
                    {
                      isFormModeEdit && !email &&
                      <Tag minimal={true}>Add email</Tag>
                    }
                    {
                      isFormModeEdit && email &&
                      <span>{email}</span>
                    }
                  </div>

                  {/* TODO --------------------------------------- */}

                </div>

                <div className={styles.right}>

                  {/* TODO --------------------------------------- */}

                  <div className={styles.fieldWrapper}>
                    <Icon
                      className={styles.fieldIcon}
                      icon={IconNames.GLOBE}
                    />
                    <Tag minimal={true}>Site</Tag>
                  </div>

                  {/* TODO --------------------------------------- */}

                  <div className={styles.fieldWrapper}>
                    <Icon
                      className={styles.fieldIcon}
                      icon={IconNames.OFFICE}
                    />
                    <Tag minimal={true}>Subsite</Tag>
                  </div>

                  {/* TODO --------------------------------------- */}

                </div>
              </div>

            </div>

          </div>


          {/* TODO --------------------------------------- */}

          {/*<div className={styles.selectItems}>*/}
          {/*  <div className={styles.title}>*/}
          {/*    Position Group(s)*/}
          {/*  </div>*/}

          {/*  <SelectPositionGroup*/}
          {/*    positionGroups={this._candidatesState.positionGroups}*/}
          {/*    selectedPositionGroup={positionGroup}*/}
          {/*    onSelect={this._onSelectPositionGroup}*/}
          {/*  />*/}

          {/*</div>*/}

          {/* TODO --------------------------------------- */}

          <div className={styles.selectItems}>
            <div className={styles.title}>
              Skills
            </div>

            {/* TODO */}
            <SelectSkillTags
              skillTags={this._candidatesState.skillTags}
              // selectedSkillTags={skill_tags}
              selectedSkillTags={selectedSkillTags}
              onSelect={this._onSelectSkillTag}
            />

          </div>

          {/* TODO --------------------------------------- */}


        </div>

        <div className={styles.formFooter}>
          {
            isFormModeAdd &&
            <Button
              text={'Add to Prospect'}
              intent={Intent.WARNING}
              size={ButtonSize.LARGE}
              onClick={this._candidatesController.addCandidate}
            />
          }
          {
            isFormModeEdit &&
            <Button
              text={'Save (-> TODO)'}
              intent={Intent.WARNING}
              size={ButtonSize.LARGE}
              onClick={this._candidatesController.saveCandidate}
            />
          }
        </div>

      </>
    );
  }
}
