import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './Pill.module.less';

const cx = classNames.bind(styles);

export enum PillType
{
  Default = 'default',
  Outlined = 'outlined',
  Light = 'light',
  MediumLight = 'mediumLight'
}

export interface IPillProps
{
  className?:string;
  text:string;
  type?:PillType;
}

export class Pill extends React.Component<IPillProps>
{
  public render():ReactNode
  {
    const { className, text, type } = this.props;

    return (
      <div className={cx(styles.pill, className, type || PillType.Default)}>
        {text}
      </div>
    );
  }
}
