import type { SwitchProps } from '@blueprintjs/core';
import { Switch as BGSwitch } from '@blueprintjs/core';
import bind from 'bind-decorator';
import { type ChangeEvent } from 'react';
import type * as React from 'react';
import { PureComponent } from 'react';

export interface ISwitchProps extends Omit<SwitchProps, 'onChange'>
{
  onChange?:(newValue:boolean) => void;
}

export class Switch extends PureComponent<ISwitchProps>
{
  @bind
  private _onChange(event:ChangeEvent<HTMLInputElement>):void
  {
    const { onChange } = this.props;

    if( onChange )
      onChange(event.target.checked);
  }

  public render():React.ReactNode
  {
    return (
      <BGSwitch
        {...this.props}
        onChange={this._onChange}
      />
    );
  }
}
