import { Icon, IconNames, IconSize } from '@flow/common/components/form/Icon';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './PositionsCount.module.less';

const cx = classNames.bind(styles);

export interface ITeamPositionsCountProps
{
  className?:string;
  count:number;
  totalCount:number;
  hideCount?:boolean;
  hideTotalCount?:boolean;
}

export class PositionsCount extends React.PureComponent<ITeamPositionsCountProps>
{
  public render():ReactNode
  {
    const { className, count, totalCount, hideTotalCount, hideCount } = this.props;

    return (
      <div className={cx(styles.positions, className)}>
        <Icon icon={IconNames.PERSON} size={IconSize.SMALL} className={styles.positionsIcon} />
        {
          !hideCount &&
          <div className={styles.positionCount}>
            {count}
          </div>
        }
        {
          (!hideCount && !hideTotalCount) &&
          <div className={styles.positionSeparator}>
            /
          </div>
        }
        {
          !hideTotalCount &&
          <div className={cx(styles.positionTotalCount, { hideCount })}>
            {totalCount}
          </div>
        }
      </div>
    );
  }
}
