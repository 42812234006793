import { ButtonGroup } from '@blueprintjs/core';
import { Button } from '@flow/common/components/form/Button';
import type { IconName } from '@flow/common/components/form/Icon';
import { Intent } from '@flow/common/components/types/Types';
import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type React from 'react';
import { Component, ReactNode } from 'react';

import styles from './ButtonGroupFilter.module.less';

const cx = classNames.bind(styles);

export interface IButtonGroupFilterProps<Enum>
{
  values:Array<Enum>;
  titles?:Record<string, string>;
  value:Enum;
  onClick:(newValue:Enum) => void;
  icons?:Record<string, IconName>;
  buttonGroupClassName?:string;
  buttonClassName?:string;
}

@component
export class ButtonGroupFilter<Enum> extends Component<IButtonGroupFilterProps<Enum>>
{
  @bind
  private _onClickFilter(newValue:Enum):(e:React.MouseEvent<HTMLElement>) => void
  {
    const { onClick } = this.props;

    return (e:React.MouseEvent<HTMLElement>):void =>
    {
      e.preventDefault();
      onClick(newValue);
    };
  }

  @bind
  private _renderFilterItem(filterItem:Enum):ReactNode
  {
    const { titles, value, icons, buttonClassName } = this.props;
    const isActive:boolean = value === filterItem;

    return (
      <Button
        key={filterItem as unknown as string}
        className={cx(styles.buttonGroupBtn, buttonClassName, { isActive })}
        text={titles?.[filterItem as unknown as string]}
        icon={icons?.[filterItem as unknown as string]}
        onClick={this._onClickFilter(filterItem)}
        intent={Intent.PRIMARY}
        outlined={!isActive}
      />
    );
  }

  public render():ReactNode
  {
    const { values, buttonGroupClassName } = this.props;

    return (
      <ButtonGroup className={cx(styles.buttonGroup, buttonGroupClassName)}>
        {values.map(
          (filterItem:unknown) => this._renderFilterItem(filterItem as Enum)
        )}
      </ButtonGroup>
    );
  }
}
