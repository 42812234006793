import type { History, Transition } from 'history';
import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

type ExtendNavigator = Navigator & Pick<History, 'block'>;

export function useBlocker(blocker:(tx:Transition) => void, when = true):void
{
    const { navigator } = useContext(NavigationContext);

    useEffect(() =>
    {
      if( !when ) return;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const unblock = (navigator as any as ExtendNavigator).block((tx) =>
      {
        const autoUnblockingTx = {
          ...tx,
          retry():void
          {
            unblock();
            tx.retry();
          },
        };

        blocker(autoUnblockingTx);
      });

      return unblock;
    }, [navigator, blocker, when]);
}

export function usePrompt(message:string, when = true):void
{
  const blocker = useCallback((tx:Transition) =>
  {
    if( window.confirm(message) ) tx.retry();
  }, [message]);

  useBlocker(blocker, when);
}

interface Props
{
  message:string;
  when:boolean;
}

export const Prompt = (props:Props):JSX.Element | null =>
{
  usePrompt(props.message, props.when);
  return null;
}
