import type { ApolloQueryResult } from '@apollo/client';
import type { FetchResult } from '@apollo/client/link/core';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import type { MovedElement } from '@flow/common/models/MoveTo';
import { MoveTo } from '@flow/common/models/MoveTo';
import { RouteName } from '@flow/common/models/routing/RouteName';
import { ToastsController } from '@flow/common/toasts/ToastsController';
import type {
  UpdateCustomerTeamUiOrderMutation,
  UpdateCustomerTeamUiOrderMutationVariables
} from '@flow/data-access/lib/customers.generated';
import { UpdateCustomerTeamUiOrderDocument } from '@flow/data-access/lib/customers.generated';
import type {
  CreateTeamMutation,
  CreateTeamMutationVariables,
  DeleteTeamMutation,
  DeleteTeamMutationVariables,
  GetTeamQuery,
  GetTeamQueryVariables,
  UpdateTeamMutation,
  UpdateTeamMutationVariables
} from '@flow/data-access/lib/teams.generated';
import {
  CreateTeamDocument,
  DeleteTeamDocument,
  GetTeamDocument,
  UpdateTeamDocument
} from '@flow/data-access/lib/teams.generated';
import type {
  Recruiting_Position,
  Recruiting_Position_Template,
  Staffing_Customer_Team_Tag,
  Staffing_Customer_Team_Tag_Insert_Input
} from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import { action, computed, runInAction, toJS } from 'mobx';
import { RoutesConfig } from '../../../pages/RoutesConfig';
import { CustomerController } from './CustomerController';
import { CustomerState, TeamFilter } from './CustomerState';
import { NewPositionController } from './NewPositionController';
import { NewPositionState } from './NewPositionState';
import { TeamState } from './TeamState';
import {
  type Staffing_Customer_Team
} from '@flow/data-access/lib/types/graphql.generated';
import { type FormEvent } from 'react';
import { type Recruiting_Position_Ex, type Staffing_Customer_Team_Ex } from './models/CustomersTypes';

export const TEAMS_LIST_COLLAPSE_ID = 'teamsList';

@controller
export class TeamController
{
  @di private _commonState!:CommonState;
  @di private _commonController!:CommonController;
  @di private _customerState!:CustomerState;
  @di private _customerController!:CustomerController;
  @di private _teamState!:TeamState;
  @di private _positionState!:NewPositionState;
  @di private _positionController!:NewPositionController;
  @di private _toastsController!:ToastsController;

  // ----------------------------------------------------

  @action.bound
  public async initTeam(teamId:number):Promise<void>
  {
    const result:ApolloQueryResult<GetTeamQuery> =
      await this._commonController.query<GetTeamQuery,
        GetTeamQueryVariables>({
        query: GetTeamDocument,
        variables: {
          teamId
        }
      });

    console.log(`%c initTeam: ${teamId} result =`, 'background:#0f0;color:#000;', result);

    runInAction(() =>
    {
      this._teamState.team = result.data.staffing_customer_team_by_pk as Staffing_Customer_Team_Ex;
    });
  }

  @action.bound
  public showTeamInfo():void
  {
    this._teamState.isTeamInfoOpen = true;
    this._teamState.isTeamInfoEditMode = false;
  }

  @action.bound
  public hideTeamInfo():void
  {
    this._teamState.isTeamInfoOpen = false;
  }

  @action.bound
  public onTeamInfoClosed():void
  {
    const path:string | null = RoutesConfig.getRoutePath(
      RouteName.CUSTOMERS_CUSTOMER,
      {
        customerId: String(this._teamState?.team?.customer_id)
      }
    );

    if( path )
      this._commonController.navigate(path);
  }

  // ----------------------------------------------------

  @action.bound
  public editPositions():void
  {
    if( !this._teamState.isTeamInfoEditMode )
    {
      this._teamState.isTeamInfoEditMode = true;
      this._teamState.oldPositions = this._teamState.team?.positions
        ? toJS(this._teamState.team.positions)
        : [];
      this._teamState.team?.positions.forEach(
        (position:Recruiting_Position_Ex) => position.newQuantity = position.total_slots_count.aggregate.count
      );
    }
  }

  @action.bound
  public finishEditPositions():void
  {
    this._teamState.isTeamInfoEditMode = false;
    if( this._teamState.team )
    {
      this._teamState.team.positions = this._teamState.oldPositions;
    }
    this._teamState.team = null;

    // await this._customerController.initCustomer(this._teamState.team?.customer_id || -1);

    // return this.initTeam(this._teamState.team?.id || -1);
  }

  @action.bound
  public addPosition():void
  {
    if( this._positionState.selectedPositionTemplate )
    {
      this.editPositions();

      const customerTeamId:number = this._teamState.team?.id || -1;
      const selectedPositionTemplate:Recruiting_Position_Template | null = this._positionState.selectedPositionTemplate;
      const positionTemplate:Recruiting_Position_Template = selectedPositionTemplate;
      const alias:string | null = null; // TODO:
      const quantity:number = 1;

      if( this._teamState.team
        && this._teamState.team?.positions.findIndex(position => positionTemplate.id === position.position_template?.id) > -1 )
      {
        this._toastsController.showWarning(`The position "${positionTemplate.name}" is already present in the list`);
      }
      else
      {
        const position:unknown = {
          customer_team_id: customerTeamId,
          alias,
          position_template_id: positionTemplate.id,
          position_template: positionTemplate,
          quantity
        };

        this._teamState.team?.positions.push(position as Recruiting_Position);
      }

      this._positionController.hidePositionDialog();
    }
  }

  @action.bound
  public deletePosition(positionId:number):void
  {
    console.log('---------------------------------- =');
    console.log('%c deletePosition =', 'background:#0f0;color:#000;', positionId);
    console.log('%c this._teamState.team?.positions 1 =', 'background:#0f0;color:#000;', toJS(this._teamState.team?.positions));
    if( this._teamState.team?.positions )
    {
      const indexToDelete:number = this._teamState.team.positions.findIndex(
        (position:Recruiting_Position_Ex) => position.id === positionId
      );
      console.log('%c indexToDelete =', 'background:#0f0;color:#000;', indexToDelete);

      if( indexToDelete !== -1 )
        this._teamState.team.positions.splice(indexToDelete, 1);
    }
    console.log('%c this._teamState.team?.positions 2 =', 'background:#0f0;color:#000;', toJS(this._teamState.team?.positions));
  }

  @action.bound
  public setQuantity(position:Recruiting_Position_Ex, newValue:string):void
  {
    const newQuantity:number = parseFloat(newValue);

    if( newQuantity > 0 )
    {
      position.newQuantity = newQuantity;
    }
    else
    {
      position.newQuantity = null;
    }
  }

  @action.bound
  public async savePositions():Promise<void>
  {
    console.log('-------------------------------------- =');
    console.log('%c savePositions... =', 'background:#0f0;color:#000;');
    this._teamState.isPositionsLoading = true;

    const positionsToCreate:Array<{ customerTeamId:number; positionTemplateId:number; quantity:number; }> = [];
    const positionsToUpdate:Array<{ positionId:number; quantity:number; }> = [];
    const positionsToDelete:Array<{ positionId:number; }> = [];
    const errors:Array<string> = [];

    this._teamState.team?.positions.forEach((position:Recruiting_Position_Ex) =>
    {
      const oldPosition:Recruiting_Position_Ex | null = this._teamState.oldPositions.find(
        (oldPosition:Recruiting_Position_Ex) => oldPosition.id === position.id
      ) || null;

      if( !oldPosition )
      {
        if( this._teamState.team?.id
          && position.position_template_id
          && position.newQuantity
          && position.newQuantity > 0 )
          positionsToCreate.push({
            customerTeamId: this._teamState.team.id,
            positionTemplateId: position.position_template_id,
            quantity: position.newQuantity
          });
      }
      else
      {
        if( position.newQuantity
          && position.newQuantity > 0
          && position.newQuantity !== position.total_slots_count.aggregate.count )
          positionsToUpdate.push({
            positionId: position.id,
            quantity: position.newQuantity
          });
      }
    });

    this._teamState.oldPositions.forEach((oldPosition:Recruiting_Position_Ex) =>
    {
      const position:Recruiting_Position_Ex | null = this._teamState.team?.positions.find(
        (position:Recruiting_Position_Ex) => position.id === oldPosition.id
      ) || null;

      if( !position )
      {
        positionsToDelete.push({
          positionId: oldPosition.id
        });
      }
    });

    for( const posToCreate of positionsToCreate )
    {
      // try
      // {
      console.log('%c --- createPosition =', 'background:#080;color:#000;', posToCreate);
      const positionId:number = await this._positionController.createPosition(
        posToCreate.customerTeamId,
        posToCreate.positionTemplateId,
        posToCreate.quantity
      );

      if( positionId <= 0 )
      {
        errors.push('Can\'t create position');
      }
      // }
      // catch( error )
      // {
      //   //
      // }
    }

    for( const posToUpdate of positionsToUpdate )
    {
      // try
      // {
      console.log('%c --- updatePositionQuantity =', 'background:#080;color:#000;', posToUpdate);
      const success:boolean = await this._positionController.updatePositionQuantity(
        posToUpdate.positionId,
        posToUpdate.quantity
      );

      if( !success )
      {
        errors.push('Can\'t update position');
      }
      // }
      // catch( error )
      // {
      //   //
      // }
    }

    for( const posToDelete of positionsToDelete )
    {
      // try
      // {
      console.log('%c --- deletePosition =', 'background:#080;color:#000;', posToDelete);
      const success:boolean = await this._positionController.deletePosition(
        posToDelete.positionId
      );

      if( !success )
      {
        errors.push('Can\'t delete position');
      }
      // }
      // catch( error )
      // {
      //   //
      // }
    }

    runInAction(async () =>
    {
      const reloadCustomerId:number | undefined = this._teamState.team?.customer_id;

      await this._customerController.initCustomer(reloadCustomerId || -1);

      this.finishEditPositions();

      this._teamState.isPositionsLoading = false;
    });
  }

  // ----------------------------------------------------

  @action.bound
  public showCreateTeamDialog():void
  {
    this._teamState.isTeamDialogOpen = true;
    this._teamState.teamId = -1;
    this._teamState.teamName = '';
    this._teamState.teamTags = [];
    this._teamState.teamNotes = '';
    this._teamState.isTeamActive = false;
  }

  @action.bound
  public hideTeamDialog():void
  {
    this._teamState.isTeamDialogOpen = false;
    this._teamState.isTeamLoading = false;
  }

  @action.bound
  public setTeamName(newTeamName:string):void
  {
    this._teamState.teamName = newTeamName;
  }

  @action.bound
  public setTeamTags(newTeamTags:Array<string>):void
  {
    this._teamState.teamTags = newTeamTags;
  }

  @action.bound
  public setTeamNotes(newTeamNotes:string):void
  {
    this._teamState.teamNotes = newTeamNotes;
  }

  @action.bound
  public setIsTeamActive1(event:FormEvent<HTMLInputElement>):void
  {
    const { value } = event.currentTarget;

    this._teamState.isTeamActive = value === 'Active';
  }

  @action.bound
  public editTeam(team:Staffing_Customer_Team):void
  {
    this._teamState.isTeamDialogOpen = true;
    this._teamState.teamId = team.id;
    this._teamState.teamName = team.name;
    this._teamState.teamTags = (team.customer_team_tags || []).map(
      (teamTag:Staffing_Customer_Team_Tag) => teamTag.name
    );
    this._teamState.teamNotes = team.notes || '';
    this._teamState.isTeamActive = team.is_active;
  }

  @action.bound
  public async saveTeam():Promise<number>
  {
    let teamId:number = this._teamState.teamId;
    const name:string = this._teamState.teamName.trim();
    const notes:string = this._teamState.teamNotes;
    const isActive:boolean = this._teamState.isTeamActive || false;
    const tags:Array<string> = this._teamState.teamTags;

    const customer_teams = this._customerController.orderedTeams;

    const uiOrder:number = customer_teams.length > 0 ? customer_teams.slice(-1)[0]?.ui_order + 1 : 0;

    console.log('%c saveTeam =', 'background:#aaf;color:#000;', name, tags);

    if( !name )
      return -1;

    this._teamState.isTeamLoading = true;

    if( teamId > 0 )
    {
      const result:FetchResult<UpdateTeamMutation> =
        await this._commonController.mutate<UpdateTeamMutation,
          UpdateTeamMutationVariables>({
          mutation: UpdateTeamDocument,
          variables: {
            teamId,
            name,
            notes,
            isActive,
            tags: tags.map(
              (tagName:string):Staffing_Customer_Team_Tag_Insert_Input => ({
                name: tagName,
                customer_team_id: teamId
              })
            )
          }
        });

      console.log('%c updateTeam result =', 'background:#0f0;color:#000;', result);

      teamId = result.data?.update_staffing_customer_team_by_pk?.id || -1;
    }
    else
    {
      const result:FetchResult<CreateTeamMutation> =
        await this._commonController.mutate<CreateTeamMutation,
          CreateTeamMutationVariables>({
          mutation: CreateTeamDocument,
          variables: {
            customerId: this._customerState.customer?.id || -1,
            name,
            notes,
            isActive,
            tags: tags.map(
              (tagName:string):Staffing_Customer_Team_Tag_Insert_Input => ({
                name: tagName
              })
            ),
            uiOrder
          }
        });

      console.log('%c createTeam result =', 'background:#0f0;color:#000;', result);

      teamId = result.data?.insert_staffing_customer_team_one?.id || -1;
    }

    this._customerState.teamFilterActiveProspective = isActive ? TeamFilter.Active : TeamFilter.Prospective;

    this.hideTeamDialog();

    return teamId;
  }

  @bind
  public async onCustomerTeamSave():Promise<void>
  {
    return this._customerController.initCustomer(this._customerState.customer?.id || -1);
  }

  @bind
  public async onTeamSave():Promise<void>
  {
    await this._customerController.initCustomer(this._customerState.customer?.id || -1);
    // return this.initTeam(teamId);
  }

  // ----------------------------------------------------

  @action.bound
  public async deleteTeam():Promise<void>
  {
    console.log('%c deleteTeam teamId =', 'background:#0f0;color:#000;');

    const { deletingTeamtId } = this._teamState;

    if( !deletingTeamtId ) return;

    console.log('%c --> teamId =', 'background:#080;color:#000;', deletingTeamtId);

    runInAction(() => this._teamState.isDeleteTeamInProgress = true);

    const result:FetchResult<DeleteTeamMutation> =
      await this._commonController.mutate<DeleteTeamMutation,
        DeleteTeamMutationVariables>({
        mutation: DeleteTeamDocument,
        variables: {
          id: deletingTeamtId,
          deleted_at: 'now()'
        }
      });

    await this._customerController.initCustomer(this._customerState.customer?.id || -1);

    this.toggleDeleteTeamDialog();

    runInAction(() => this._teamState.isDeleteTeamInProgress = false);

    console.log('%c deleteTeam result =', 'background:#0f0;color:#000;', result);
  }

  // ----------------------------------------------------

  @action.bound
  public toggleDeleteTeamDialog(teamId?:number):void
  {
    this._teamState.deletingTeamtId = teamId;
    this._teamState.isDeleteTeamDialogOpen = !!teamId;
  }

  // ----------------------------------------------------

  @bind
  public viewPositions(team:Staffing_Customer_Team):void
  {
    const path:string | null = RoutesConfig.getRoutePath(
      RouteName.CUSTOMERS_CUSTOMER_TEAM_POSITIONS,
      {
        customerId: String(team.customer_id),
        teamId: String(team.id)
      }
    );

    this._commonController.toggleCollapseItem(TEAMS_LIST_COLLAPSE_ID, team.id, false);

    this._teamState.team = null;

    if( path )
      this._commonController.navigate(path);
  }

  @action.bound
  public managePositions(team:Staffing_Customer_Team_Ex):void
  {
    this._commonController.toggleCollapseItem(TEAMS_LIST_COLLAPSE_ID, team.id, false);

    this._teamState.team = team;

    // this._teamState.team = null;
    // await this.initTeam(team.id);

    this.editPositions();
    // this.showTeamInfo();
  }

  // ----------------------------------------------------

  @action.bound
  public collapseAll(isCollapse:boolean):void
  {
    const { team: managedTeam } = this._teamState;
    const customer_teams = this._customerController.orderedTeams;

    customer_teams.forEach((team:Staffing_Customer_Team_Ex) =>
    {
      const isManagedTeam:boolean = managedTeam?.id === team.id;

      if( !isManagedTeam )
      {
        this._commonController.toggleCollapseItem(TEAMS_LIST_COLLAPSE_ID, team.id, isCollapse);
      }
    });
  }

  // --------------------------------------------

  @computed
  public get allCollapsed():boolean
  {
    return !this._customerController.orderedTeams.some((team:Staffing_Customer_Team_Ex) =>
    {
      return !this._commonController.isCollapsed(TEAMS_LIST_COLLAPSE_ID, team.id);
    });
  }

  // ----------------------------------------------------

  // @bind
  // public viewPositionTemplate(position:Recruiting_Position_Ex):void
  // {
  //   // TODO:
  //   const path:string | null = RoutesConfig.getRoutePath(
  //     RouteName.CUSTOMERS_CUSTOMER_TEAM_POSITIONS,
  //     {
  //       // customerId: String(position.customer_id),
  //       // teamId: String(team.id)
  //     }
  //   );

  //   this._teamState.team = null;

  //   if( path )
  //     this._commonController.navigate(path);
  // }

  // ----------------------------------------------------

  public changeTeamOrder(team:Staffing_Customer_Team, to:MoveTo):void
  {
    const { orderedTeams, activeTeamIndexById } = this._customerController;

    const teams = orderedTeams;

    if( !teams )
      return;

    const teamIndex = activeTeamIndexById(team.id);

    if( teamIndex === -1 ) return;

    const teamToMove:MovedElement = {
      id: team.id,
      ui_order: team.ui_order || 0
    };

    let secondTeamToMove:MovedElement | null = null;

    switch( to )
    {
      case MoveTo.TOP:
        teamToMove.ui_order = Number(teams[0].ui_order) - 1;
        break;

      case MoveTo.BOTTOM:
        teamToMove.ui_order = Number(teams[teams.length - 1].ui_order) + 1;
        break;

      case MoveTo.UP:
        teamToMove.ui_order = Number(team.ui_order) - 1;
        if( teams[teamIndex - 1] )
        {
          secondTeamToMove = {
            id: teams[teamIndex - 1].id,
            ui_order: team.ui_order || 0
          };
        }
        break;

      case MoveTo.DOWN:
        teamToMove.ui_order = Number(team.ui_order) + 1;
        if( teams[teamIndex + 1] )
        {
          secondTeamToMove = {
            id: teams[teamIndex + 1].id,
            ui_order: team.ui_order || 0
          };
        }
        break;
    }

    this.updateTeamUiOrder(teamToMove);

    if( secondTeamToMove )
      this.updateTeamUiOrder(secondTeamToMove);
  }

  // ----------------------------------------------------

  public async updateTeamUiOrder(team:MovedElement):Promise<void>
  {
    if( !team ) return;

    const { id, ui_order } = team;

    const result:FetchResult<UpdateCustomerTeamUiOrderMutation> =
      await this._commonController.mutate<UpdateCustomerTeamUiOrderMutation,
        UpdateCustomerTeamUiOrderMutationVariables>({
        mutation: UpdateCustomerTeamUiOrderDocument,
        variables: { id, ui_order }
      });

    const returning = result?.data?.update_staffing_customer_team_by_pk;

    if( !returning ) // null
    {
      // TODO ???
      return;
    }

    runInAction(() =>
    {
      const { findCustomerTeamById } = this._customerState;

      const team:Staffing_Customer_Team | null = findCustomerTeamById(id);

      if( team )
        team.ui_order = returning.ui_order;
    });
  }
}
