import { Icon, IconNames } from '@flow/common/components/form/Icon';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './CustomerAndTeam.module.less';

const cx = classNames.bind(styles);

export interface IBreadcrumbsProps
{
  className?:string;
  items:Array<string | JSX.Element>;
  lastTextClassName?:string;
}

export class CustomerAndTeam extends React.Component<IBreadcrumbsProps>
{
  public render():ReactNode
  {
    const { className, items, lastTextClassName } = this.props;

    return (
      <div className={cx(styles.wrapper, className)}>

        {items.map((item:string | JSX.Element, index:number) =>
        {
          return (
            <React.Fragment key={index}>

              <span className={cx(index === items.length - 1 ? lastTextClassName : null)}>
                {item}
              </span>

              {
                (index < items.length - 1) &&
                <Icon
                  className={styles.icon}
                  icon={IconNames.CHEVRON_RIGHT}
                  size={10}
                />
              }

            </React.Fragment>
          );
        })}
      </div>
    );
  }
}
