import type { PositionItem } from '@flow/common/components/elements/PositionItem';
import { AuthState } from '@flow/common/controllers/AuthState';
import { SlotUtil } from '@flow/common/utils/SlotUtil';
import { SortUtil } from '@flow/common/utils/SortUtil';
import type {
  Recruiting_Allocation_Position,
  Recruiting_Candidate_Allocation,
  Staffing_Customer_Team_Slot
} from '@flow/data-access/lib/types/graphql.generated';
import { Common_Customer_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { di, state } from '@flow/dependency-injection';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { CandidatesState } from '@flow/modules/recruiting/candidates/CandidatesState';
import { CandidateState } from '@flow/modules/recruiting/candidates/CandidateState';
import { computed, observable, runInAction, toJS } from 'mobx';
import { computedFn } from 'mobx-utils';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import React from 'react';

@state
export class CandidateAllocationState
{
  @di private _authState!:AuthState;
  @di private _candidateState!:CandidateState;
  @di private _candidatesState!:CandidatesState;

  @observable public positions:Array<Recruiting_Position_Ex> = [];
  @observable public proposedAllocations:Array<Recruiting_Candidate_Allocation> = [];

  // ----------------------------------------------------

  @observable public isFinalizeAllocationDialogOpen:boolean = false;
  @observable public isFinalizeAllocationLoading:boolean = false;

  @observable public isReFinalize:boolean = false;
  @observable public isScrolledToPosition:boolean = false;

  @observable public isShowOnlyOpenPositions:boolean = true; // false = All positions
  @observable public isShowOnlyOpenPositionsInDialog:boolean = true; // false = All positions

  @observable public finalizeNote:string = '';
  @observable public showNoteInput:boolean = false;

  // @observable public finalizePositionValue:string = '';
  @observable public currentFinalSlots:Array<Staffing_Customer_Team_Slot> = [];

  @observable public selectedDate:string | null = null;
  @observable public selectedSlot:Staffing_Customer_Team_Slot | null = null;
  @observable public selectedPositionId:number | null = null;
  @observable public selectedPositionItemRef:React.RefObject<PositionItem> | null = null;

  // ----------------------------------------------------

  @computed
  public get currentFinalSlot():Staffing_Customer_Team_Slot | undefined
  {
    // return this.currentFinalSlots.length ? this.currentFinalSlots[0] : undefined;
    console.log('%c this.currentFinalSlots =', 'background:#ccf;color:#000;', toJS(this.currentFinalSlots));
    return this.currentFinalSlots[0];
  }

  @computed
  public get finalizedAllocation():Recruiting_Candidate_Allocation | undefined
  {
    return this.proposedAllocations
      .filter((allocation:Recruiting_Candidate_Allocation) => allocation.is_final)[0];
  }

  @computed
  public get isFinalized():boolean
  {
    return !!this.finalizedAllocation;
  }

  // @computed
  // public get finalizedPositionId():number | undefined
  // {
  //   return this.finalizedAllocation?.allocation_positions[0].position_id;
  // }

  // @computed
  // public get finalizedAllocationIndex():number
  // {
  //   let allocationIndex:number = -1;
  //
  //   this.proposedAllocations.forEach((allocation:Recruiting_Candidate_Allocation, index:number) =>
  //   {
  //     if( allocation.is_final ) allocationIndex = index;
  //   });
  //
  //   return allocationIndex;
  // }

  // ----------------------------------------------------

  @computed
  public get sortedProposedAllocations():Array<Recruiting_Candidate_Allocation>
  {
    const myId:number = this._authState.user?.id || 0;

    return this.proposedAllocations
      .slice()
      .sort((allocation:Recruiting_Candidate_Allocation) =>
      {
        const myNote:boolean = allocation.user.id === myId;

        return myNote ? -1 : 0;
      });
  }

  // ----------------------------------------------------

  // @computed
  // public get myProposedAllocation():Recruiting_Candidate_Allocation | undefined
  // {
  //   const myId:number = this._authState.user?.id || 0;
  //
  //   return this.proposedAllocations.filter((allocation:Recruiting_Candidate_Allocation) =>
  //   {
  //     return allocation.user.id === myId;
  //   })[0];
  // }

  // @computed
  // public get myProposedAllocationIndex():number
  // {
  //   const myId:number = this._authState.user?.id || 0;
  //
  //   let myAllocationIndex:number = -1;
  //
  //   this.proposedAllocations.forEach((allocation:Recruiting_Candidate_Allocation, index:number) =>
  //   {
  //     if( allocation.user.id === myId ) myAllocationIndex = index;
  //   });
  //
  //   return myAllocationIndex;
  // }

  @computed
  public get myProposedPositionIds():Array<number>
  {
    // old version for ONE allocation per user
    // if( !this.myProposedAllocation ) return [];
    //
    // return this.myProposedAllocation.allocation_positions
    //   .map((position:Recruiting_Allocation_Position) => position.position_id);

    const myId:number = this._authState.user?.id || 0;

    const result:Array<number> = [];

    this.proposedAllocations.forEach((proposedAllocation:Recruiting_Candidate_Allocation) =>
    {
      if( proposedAllocation.user.id !== myId ) return;

      proposedAllocation.allocation_positions.forEach((proposedPosition:Recruiting_Allocation_Position) =>
      {
        result.push(proposedPosition.position_id);
      });
    });

    return result;
  }

  @computed
  public get allProposedPositionIds():Array<number>
  {
    const proposedIds:Array<number> = [];

    this.proposedAllocations.forEach((allocation:Recruiting_Candidate_Allocation) =>
    {
      if( allocation.is_final ) return;

      allocation.allocation_positions.forEach((position:Recruiting_Allocation_Position) =>
      {
        if( !proposedIds.includes(position.position_id) )
        {
          proposedIds.push(position.position_id);
        }
      });
    });

    return proposedIds;
  }

  // ----------------------------------------------------

  @computed
  public get inPanelAutoComputedPositions():Array<Recruiting_Position_Ex>
  {
    if( this.isShowOnlyOpenPositions )
    {
      return this._autoComputedPositions.filter((position:Recruiting_Position_Ex) =>
      {
        return position.open_slots_count.aggregate.count !== 0;
      });
    }
    else
    {
      return this._autoComputedPositions;
    }
  }

  @computed
  public get inDialogAutoComputedPositions():Array<Recruiting_Position_Ex>
  {
    if( this.isShowOnlyOpenPositionsInDialog )
    {
      return this._autoComputedPositions.filter((position:Recruiting_Position_Ex) =>
      {
        return position.open_slots_count.aggregate.count !== 0;
      });
    }
    else
    {
      return this._autoComputedPositions;
    }
  }

  @computed
  private get _autoComputedPositions():Array<Recruiting_Position_Ex>
  {
    const { candidate } = this._candidateState;

    if( !candidate ) return [];

    console.groupCollapsed(' <-- autoComputedPositions');

    const result:Array<Recruiting_Position_Ex> = this.positions
      // .slice()
      .filter((position:Recruiting_Position_Ex) =>
      {
        // moved to graphql
        // if( !position.is_ready ) return false;

        // -------------------------

        const hasFinalizedSlot:boolean = position.customer_team_slots.some((slot:Staffing_Customer_Team_Slot) =>
        {
          return slot.next_candidate_id === candidate.id;
        });

        // console.log('%c --> ', 'background:#080;color:#000;', 'hasFinalizedSlot =', hasFinalizedSlot);

        if( hasFinalizedSlot )
        {
          console.log('%c position =', 'background:#080;color:#000;', position.id, toJS(position));
          return true;
        }

        // -------------------------

        if( !position.customer_team.is_active || position.customer_team.deleted_at ||
          position.customer_team.customer.status !== Common_Customer_Status_Enum.Current )
        {
          console.log('%c position =', 'background:#f00;color:#ff0;', position.id, toJS(position));
          !position.customer_team.is_active &&
          console.log('%c        -->', 'background:#222;color:#fff;', '!position.customer_team.is_active =', !position.customer_team.is_active);

          position.customer_team.deleted_at &&
          console.log('%c        -->', 'background:#222;color:#fff;', 'position.customer_team.deleted_at =', position.customer_team.deleted_at);

          position.customer_team.customer.status !== Common_Customer_Status_Enum.Current &&
          console.log('%c        -->', 'background:#222;color:#fff;', 'position.customer_team.customer.status =', position.customer_team.customer.status);

          return false;
        }

        // -------------------------

        if( position.open_slots_count.aggregate.count === 0 )
        {
          // console.log('%c --> ', 'background:#080;color:#000;', 'open_slots_count =', 0);

          const hasStaffOnlySlot:boolean = position.customer_team_slots.some((slot:Staffing_Customer_Team_Slot) =>
          {
            return slot.staff_id && !slot.next_staff_id && !slot.next_candidate_id;
          });

          if( hasStaffOnlySlot )
          {
            // console.log('%c --> ', 'background:#080;color:#000;', 'hasStaffOnlySlot =', hasStaffOnlySlot);
            console.log('%c position =', 'background:#080;color:#000;', position.id, toJS(position));
            return true;
          }

          // const hasFinalizedSlot:boolean = position.customer_team_slots.some((slot:Staffing_Customer_Team_Slot) =>
          // {
          //   return slot.next_candidate_id === candidate?.id;
          // });
          //
          // console.log('%c --> ', 'background:#080;color:#000;', 'hasFinalizedSlot =', hasFinalizedSlot);
          console.log('%c position =', 'background:#faa;color:#000;', position.id, toJS(position));
          // console.log('%c        -->', 'background:#222;color:#fff;', 'open_slots_count =', position.open_slots_count.aggregate.count);
          return hasFinalizedSlot;
        }

        console.log('%c position =', 'background:#080;color:#000;', position.id, toJS(position));

        // && position.customer_team?.is_active -> moved to graphql
        return position && position.customer_team_slots.every((slot:Staffing_Customer_Team_Slot) =>
        {
          runInAction(() => slot.position = position);
          return SlotUtil.isSlotVisible(slot);
        });
      })
      .sort(SortUtil.byCustomerAndTeam);

    console.groupEnd();
    console.log('%c <-- autoComputedPositions = ', 'background:#ff0;color:#000;', 'length =', result.length);

    return result;
  }

  // ----------------------------------------------------

  public positionById:(id:number) => Recruiting_Position_Ex | undefined =
    computedFn((id:number):Recruiting_Position_Ex | undefined =>
    {
      return this.positions.find((positions:Recruiting_Position_Ex) => positions.id === id);
    });

  // ----------------------------------------------------

  /**
   * For cases where position groups were changed for the candidate,
   * and previously proposed positions are not actual anymore
   */
  // @computed
  // public get actualProposedAllocations():Array<Recruiting_Candidate_Allocation>
  // {
  //   return this.proposedAllocations.filter(allocation =>
  //   {
  //     return allocation.allocation_positions.some(position => this.positionById(position.position_id));
  //   });
  // }
}
