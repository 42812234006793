import { Alignment as BPAlignment, Intent as BPIntent } from '@blueprintjs/core';

// ----------------------------------------------------
// TODO:
// export type ButtonIntent = BPIntent;

export enum Intent
{
  NONE = 'NONE',
  PRIMARY = 'PRIMARY',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
}

export const BPIntents:{ [key in Intent]:BPIntent } = {
  [Intent.NONE]: BPIntent.NONE,
  [Intent.PRIMARY]: BPIntent.PRIMARY,
  [Intent.SUCCESS]: BPIntent.SUCCESS,
  [Intent.WARNING]: BPIntent.WARNING,
  [Intent.DANGER]: BPIntent.DANGER
};

export function getBPIntent(intent:Intent = Intent.NONE):BPIntent
{
  return BPIntents[intent];
}

// ----------------------------------------------------

export enum Alignment
{
  CENTER = 'CENTER',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export const BPAlignments:{ [key in Alignment]:BPAlignment } = {
  [Alignment.CENTER]: BPAlignment.CENTER,
  [Alignment.LEFT]: BPAlignment.LEFT,
  [Alignment.RIGHT]: BPAlignment.RIGHT
};

export function getBPAlignment(alignment:Alignment | undefined):BPAlignment | undefined
{
  return alignment && BPAlignments[alignment];
}

// ----------------------------------------------------
