import { controller, di } from '@flow/dependency-injection';
import { FlowApiController } from '../clients/FlowApiClient';

@controller
export class FilesController
{
  @di private readonly _flowApiClient!:FlowApiController;

  public uploadFile(file:File, name:string, path:string, progressCallback?:EventListenerOrEventListenerObject):Promise<string>
  {
    return this._flowApiClient.filesUpload(file, name, path, progressCallback);
  }

  public async downloadFile(cdnPath:string, asName?:string):Promise<void>
  {
    await this._flowApiClient.filesDownload(cdnPath, asName);
  }
}
