import 'reflect-metadata';
import type { Provider } from './registry';
import { registry } from './registry';
import { log } from './utils';

function dependencyInjector<T>(
  qualifier:string | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target:any,
  propertyKey:PropertyKey
):TypedPropertyDescriptor<T>
{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const typeName:string = Reflect.getMetadata(
    'design:type',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    target,
    propertyKey as string
  )?.name;

  if( !qualifier )
    qualifier = typeName;

  if( !typeName )
    throw new Error(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `typeName for type: ${target.constructor.name} for propertyKey: ${propertyKey as string} not found`
    );

  const instanceId = `${typeName}_${qualifier}`;

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  log(`%cdi decorator descriptor for propertyKey ${propertyKey as string} with type ${typeName} and qualifier ${qualifier} in class ${target.constructor.name}`, 'color: orange');

  const descriptor:TypedPropertyDescriptor<T> = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get(this:any):T
    {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      log(`%cdi inject ${instanceId} from ${this.constructor.name}`, 'color: red', this);

      const instance:Provider = registry.getInstance(
        this,
        typeName,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        qualifier as string
      );

      log(`%cget instance from registry`, 'color: blueviolet');

      this[instanceId] = instance;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return instance;
    },
    enumerable: false,
    configurable: false
  };

  return descriptor;
}

type DiFunc = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target:any,
  propertyKey:PropertyKey
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any;

export function di(qualifier:string):DiFunc;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function di(target:any, propertyKey:PropertyKey):any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/adjacent-overload-signatures
export function di(this:any, ...args:Array<any>):TypedPropertyDescriptor<any> | DiFunc
{
  if( typeof args[0] === 'string' )
  {
    const qualifier:string = args[0];

    return dependencyInjector.bind(this, qualifier);
  }
  else
  {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return dependencyInjector(null, args[0], args[1]);
  }
}
