import type { ApolloQueryResult } from '@apollo/client';
import type { FetchResult } from '@apollo/client/link/core';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import { AuthState } from '@flow/common/controllers/AuthState';
import { RouteName } from '@flow/common/models/routing/RouteName';
import { Maybe } from '@flow/common/models/Types';
import { LocalStorageUtil } from '@flow/common/utils/LocalStorageUtil';
import { WindowLocationUtil } from '@flow/common/utils/WindowLocationUtil';
import type {
  UpdateCandidateInterviewFlowStageMutation,
  UpdateCandidateInterviewFlowStageMutationVariables
} from '@flow/data-access/lib/candidate.generated';
import { UpdateCandidateInterviewFlowStageDocument } from '@flow/data-access/lib/candidate.generated';
import type {
  GetInterviewFlowCandidatesQuery,
  GetInterviewFlowCandidatesQueryVariables,
  GetInterviewFlowsQuery,
  GetInterviewFlowsQueryVariables
} from '@flow/data-access/lib/interviewFlow.generated';
import {
  GetInterviewFlowCandidatesDocument,
  GetInterviewFlowsDocument
} from '@flow/data-access/lib/interviewFlow.generated';
import type {
  Common_Customer,
  Common_Scheduled_Event,
  Common_User,
  Recruiting_Candidate,
  Recruiting_Interview_Flow,
  Recruiting_Position_Group
} from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import { ConvertToStaffController } from '@flow/modules/recruiting/common/convertToStaff/ConvertToStaffController';
import { InterviewFlowsFiltersController } from '@flow/modules/recruiting/status/InterviewFlowsFiltersController';
import type { IInterviewFlowGroupStage } from '@flow/modules/recruiting/status/models/InterviewFlowGroup';
import { environment } from 'apps/flow/src/environments/environment';
import bind from 'bind-decorator';
import { action, reaction, runInAction, toJS } from 'mobx';
import moment from 'moment';
import { RoutesConfig } from '../../../pages/RoutesConfig';
import { ScheduleController } from '../schedule/ScheduleController';
import { InterviewFlowsState, InterviewFlowsState_LS_KEY } from './InterviewFlowsState';

@controller
export class InterviewFlowsController
{
  @di private _commonState!:CommonState;
  @di private _commonController!:CommonController;

  @di private _interviewFlowsState!:InterviewFlowsState;
  @di private _interviewFlowsFiltersController!:InterviewFlowsFiltersController;

  @di private _convertToStaffController!:ConvertToStaffController;
  @di private _scheduleController!:ScheduleController;
  @di private _authState!:AuthState;

  // ----------------------------------------------------

  @bind
  public initFromLocalStorage():void
  {
    const lsState:Partial<InterviewFlowsState> = LocalStorageUtil.getItem(InterviewFlowsState_LS_KEY);

    if( lsState )
    {
      this.selectInterviewFlow(Number(lsState.selectedInterviewFlowId));
    }

    const { disposers } = this._interviewFlowsState;

    Array.isArray(disposers) && disposers.push(reaction(
      () =>
      {
        const {
          selectedInterviewFlowId // flow
        } = this._interviewFlowsState;

        return {
          selectedInterviewFlowId // flow
        };
      },
      (lsValue:any) =>
      {
        console.log('%c InterviewFlowsState: lsValue =', 'background:#0ff;color:#000;', lsValue);
        LocalStorageUtil.setItem(InterviewFlowsState_LS_KEY, lsValue);
      }
    ));
  }

  // ----------------------------------------------------

  @action.bound
  public async initInterviewFlows():Promise<void>
  {
    console.log('%c initInterviewFlows =', 'background:#f0f;color:#000;');
    if( this._interviewFlowsState.interviewFlowsInitPromise !== null )
    {
      console.log('%c WARNING initInterviewFlows =', 'background:#f00;color:#ff0;', 'loading...');
      return;
    }

    this._interviewFlowsState.interviewFlowsInitPromise = new Promise((resolve) =>
    {
      this._commonController.query<GetInterviewFlowsQuery,
        GetInterviewFlowsQueryVariables>({
        query: GetInterviewFlowsDocument,
        variables: {}
      }).then((result:ApolloQueryResult<GetInterviewFlowsQuery>) =>
      {
        console.log('%c initInterviewFlows result =', 'background:#f60;color:#000;', result);

        runInAction(() =>
        {
          this._interviewFlowsState.interviewFlows = result.data.recruiting_interview_flow as Array<Recruiting_Interview_Flow>;
        });

        resolve();
      });
    });

    return this._interviewFlowsState.interviewFlowsInitPromise;
  }

  // ----------------------------------------------------

  @action.bound
  public async initInterviewFlow(interviewFlowId:number):Promise<void>
  {
    console.log('%c initInterviewFlow interviewFlowId 1 =', 'background:#f0f;color:#000;', toJS(interviewFlowId));

    if( this._interviewFlowsState.interviewFlowsInitPromise === null )
    {
      await this.initInterviewFlows();
    }

    const { interviewFlowsInitPromise } = this._interviewFlowsState;

    console.log('%c initInterviewFlow interviewFlowId 2 =', 'background:#f0f;color:#000;', toJS(interviewFlowId));

    interviewFlowsInitPromise !== null && interviewFlowsInitPromise.then(() =>
    {
      console.log('%c initInterviewFlow interviewFlowId 3 =', 'background:#f0f;color:#000;', toJS(interviewFlowId));

      const { interviewFlows } = this._interviewFlowsState;
      console.log('%c --> interviewFlows =', 'background:#080;color:#000;', toJS(interviewFlows));

      const flow:Recruiting_Interview_Flow | undefined = interviewFlows
        .find((flow:Recruiting_Interview_Flow) => flow.id === interviewFlowId);

      console.log('%c --> flow =', 'background:#080;color:#000;', toJS(flow));

      const flowStageIds:Array<number> = flow && flow.stages.map((stage) => stage.id) || [];

      console.log('%c --> flowStageIds =', 'background:#080;color:#000;', flowStageIds);

      this._commonController.query<GetInterviewFlowCandidatesQuery,
        GetInterviewFlowCandidatesQueryVariables>({
        query: GetInterviewFlowCandidatesDocument,
        variables: {
          flowStageIds
        }
      }).then((result:ApolloQueryResult<GetInterviewFlowCandidatesQuery>) =>
      {
        console.log('%c initInterviewFlow result =', 'background:#f60;color:#000;', result);

        runInAction(() =>
        {
          this._interviewFlowsState.selectedInterviewFlowId = interviewFlowId;
          this._interviewFlowsState.interviewFlowCandidates = result.data.recruiting_candidate as any;
          this._interviewFlowsState.customers = result.data.common_customer as Array<Common_Customer>;
          this._interviewFlowsState.positionGroups = result.data.recruiting_position_group as Array<Recruiting_Position_Group>;
          this._interviewFlowsState.users = result.data.common_user as Array<Common_User>;

          this._interviewFlowsFiltersController.applyQueryString();
        });
      });
    });
  }

  // ----------------------------------------------------

  @action.bound
  public async initCandidateEvents():Promise<void>
  {
    const events = await this._scheduleController.fetchEventsInfo(
      environment.recruiting.careersEmail,
      moment().subtract(7, 'days').toDate() as Date,
      moment().add(2, 'months').toDate() as Date
    );
    this._interviewFlowsState.candidateEvents = events.events || [];
  }

  // ----------------------------------------------------

  @action.bound
  public selectInterviewFlow(interviewFlowId:number):void
  {
    const queryObject:any = WindowLocationUtil.getObjectFromQueryString();

    this._interviewFlowsState.selectedInterviewFlowId = interviewFlowId;

    const path:string | null = RoutesConfig.getRoutePath(
      RouteName.RECRUITING_BOARD,
      {
        interviewFlowId: String(interviewFlowId)
      }
    );

    if( path ) this._commonController.navigate(path);

    WindowLocationUtil.setQueryString(queryObject);
  }

  // ----------------------------------------------------

  @action.bound
  public selectInterviewFlowCustomer(customerId:number | undefined):void
  {
    // this._interviewFlowsState.selectedCustomerId = customerId;
    //
    // if( customerId )
    //   WindowLocationUtil.setHashVariable('customerId', String(customerId));
    // else
    //   WindowLocationUtil.removeHashVariable('customerId');
  }

  @action.bound
  public selectInterviewFlowPositionGroup(positionGroupId:number | undefined):void
  {
    // this._interviewFlowsState.selectedPositionGroupId = positionGroupId;
    //
    // if( positionGroupId )
    //   WindowLocationUtil.setHashVariable('positionGroupId', String(positionGroupId));
    // else
    //   WindowLocationUtil.removeHashVariable('positionGroupId');
  }

  @action.bound
  public selectInterviewFlowRecruiter(recruiterId:number | undefined):void
  {
    // this._interviewFlowsState.selectedRecruiterId = recruiterId;
    //
    // if( recruiterId )
    //   WindowLocationUtil.setHashVariable('recruiterId', String(recruiterId));
    // else
    //   WindowLocationUtil.removeHashVariable('recruiterId');
  }

  // ----------------------------------------------------
  // TODO: remove

  @action.bound
  public setGroupByCustomer(groupByCustomer:boolean):void
  {
    // this._interviewFlowsState.groupByCustomer = groupByCustomer;
    //
    // if( groupByCustomer )
    //   WindowLocationUtil.setHashVariable('groupByCustomer', 'true');
    // else
    //   WindowLocationUtil.removeHashVariable('groupByCustomer');
  }

  @action.bound
  public setGroupByPositionGroup(groupByPositionGroup:boolean):void
  {
    // this._interviewFlowsState.groupByPositionGroup = groupByPositionGroup;
    //
    // if( groupByPositionGroup )
    //   WindowLocationUtil.setHashVariable('groupByPositionGroup', 'true');
    // else
    //   WindowLocationUtil.removeHashVariable('groupByPositionGroup');
  }

  // ----------------------------------------------------

  @action.bound
  public async onDropCandidate():Promise<void>
  {
    console.log('%c onDropCandidate =', 'background:#f0f;color:#000;', 'with interview_flow_stage_id =', this._interviewFlowsState.draggingCandidate?.interview_flow_stage_id, toJS(this._interviewFlowsState.draggingCandidate));
    console.log('%c --> TO dropInterviewFlowStageId =', 'background:#080;color:#000;', toJS(this._interviewFlowsState.dropInterviewFlowStageId));

    if( this._interviewFlowsState.draggingCandidate
      && this._interviewFlowsState.draggingCandidate.interview_flow_stage_id !== this._interviewFlowsState.dropInterviewFlowStageId )
    {
      const candidateId:number = this._interviewFlowsState.draggingCandidateId;
      const interviewFlowStageId:number = this._interviewFlowsState.dropInterviewFlowStageId;

      const isLastStage:boolean = this.isLastStage(interviewFlowStageId);

      runInAction(() =>
      {
        if( this._interviewFlowsState.draggingCandidate )
        {
          const candidateStage:IInterviewFlowGroupStage | undefined = this._interviewFlowsState.draggingInterviewFlowGroupStage;
          const newStage:IInterviewFlowGroupStage | undefined = this._interviewFlowsState.dropInterviewFlowGroupStage;
          const candidateIndex:number = this._interviewFlowsState.draggingCandidateIndex;
          const candidate:Recruiting_Candidate | undefined = candidateStage?.candidates[candidateIndex];

          if( candidateStage && newStage && candidate )
          {
            candidateStage.candidates.splice(candidateIndex, 1);
            newStage.candidates.splice(this._interviewFlowsState.dropInterviewFlowIndex, 0, candidate);
            candidate.interview_flow_stage_id = interviewFlowStageId;

            if( !isLastStage )
            {
              this.resetDrag();
            }
          }
        }
      });

      if( isLastStage )
      {
        console.log('%c --> CONVERT to staff =', 'background:#080;color:#000;', 'candidateId =', candidateId);
        this.showConvertToStaffDialog();
        return;
      }

      const result:FetchResult<UpdateCandidateInterviewFlowStageMutation> =
        await this._commonController.mutate<UpdateCandidateInterviewFlowStageMutation,
          UpdateCandidateInterviewFlowStageMutationVariables>({
          mutation: UpdateCandidateInterviewFlowStageDocument,
          variables: {
            candidateId,
            interviewFlowStageId
          }
        });

      console.log('%c onDropCandidate result =', 'background:#f60;color:#000;', result);

      return this.initInterviewFlow(this._interviewFlowsState.selectedInterviewFlowId || -1);
    }
    else
    {
      this.resetDrag();
    }
  }

  // ----------------------------------------------------

  public showConvertToStaffDialog():void
  {
    const { draggingCandidate, interviewFlows } = this._interviewFlowsState;

    this._convertToStaffController.showConvertToStaffDialog(
      true,
      draggingCandidate,
      interviewFlows,
      () =>
      {
        console.log('%c ...onClose CB =', 'background:#0f0;color:#000;');
        this.resetDrag();
        this.initInterviewFlow(this._interviewFlowsState.selectedInterviewFlowId || -1);
      },
      () =>
      {
        console.log('%c ...onConfirm CB =', 'background:#0f0;color:#000;');
        this.resetDrag();
        this.initInterviewFlow(this._interviewFlowsState.selectedInterviewFlowId || -1);
      }
    );
  }

  // ----------------------------------------------------

  public isLastStage(flowStageId:number | null | undefined):boolean
  {
    const flow:Recruiting_Interview_Flow | undefined = this._interviewFlowsState.getFlowByStageId(flowStageId);

    return !!flow && flowStageId === flow.stages[flow.stages.length - 1].id;
  }

  // ----------------------------------------------------

  @action.bound
  public resetDrag():void
  {
    this._interviewFlowsState.draggingInterviewFlowGroupId = '';
    this._interviewFlowsState.draggingCandidateId = -1;
    this._interviewFlowsState.dropInterviewFlowStageId = -1;
  }

  // TODO: remove
  @action.bound
  public unselectAllGroups():void
  {
    // this.setGroupByCustomer(false);
    // this.setGroupByPositionGroup(false);
  }

  @bind
  public getEventAttendeesAsUsers(candidate:Recruiting_Candidate, event?:Partial<Common_Scheduled_Event> | null):Array<Common_User>
  {
    const { users, getUserById } = this._interviewFlowsState;

    const attendees_list = event?.attendees_list || '';

    const attendeesEmails = attendees_list ? attendees_list.split(',').filter(email => email.length > 0) : [];

    const userEmail:Maybe<string> = event?.user_id ? getUserById(event?.user_id)?.email : null;

    if( userEmail && userEmail.length > 0 )
    {
      if( !attendeesEmails.includes(userEmail) )
        attendeesEmails.splice(0, 0, userEmail);
    }

    const candidateEmail = candidate?.email || '';

    return attendeesEmails
      .map(email => users.find(user => user.email == email))
      .filter(user => !!user)
      .sort((user):number => user?.email == candidateEmail ? -1 : 1)
      .sort((user):number => user?.email == userEmail ? -1 : 1) as Array<never>;
  }

  // ----------------------------------------------------
}
