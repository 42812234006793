import { ButtonWithMenu } from '@flow/common/components/elements/ButtonWithMenu';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import classNames from 'classnames/bind';
import type React from 'react';
import { Component } from 'react';

import styles from './ButtonMore.module.less';

const cx = classNames.bind(styles);

interface Props
{
  className?:string;
  buttonClassName?:string;
  iconClassName?:string;

  menuContent:React.ReactElement;

  iconSize?:number;
  isVertical?:boolean;
  title?:string;

  disabled?:boolean;
  usePortal?:boolean;
}

export class ButtonMore extends Component<Props>
{
  public render():React.ReactNode
  {
    const {
      className, buttonClassName, iconClassName,
      menuContent, title, disabled,
      isVertical = true,
      iconSize = 12,
      usePortal = false
    } = this.props;

    return (
      <ButtonWithMenu
        className={cx(styles.wrapper, className)}
        menuContent={menuContent}
        disabled={disabled}
        usePortal={usePortal}
        button={(
          <Button
            className={cx(styles.btnMore, buttonClassName, { isVertical }, 'test-more-btn')}
            minimal={true}
            icon={IconNames.MORE}
            iconSize={iconSize || 16}
            iconClassName={cx(styles.btnMoreIcon, iconClassName)}
            title={title}
            disabled={disabled}
          />
        )}
      />
    );
  }
}
