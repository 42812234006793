import { MenuItem } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { PageTitle } from '@flow/common/components/page/PageTitle';
import { Alignment } from '@flow/common/components/types/Types';
import type { Common_Customer } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { computed, toJS } from 'mobx';
import React from 'react';
import { StaffBoardController } from '../StaffBoardController';
import { StaffBoardState } from '../StaffBoardState';

import styles from './SelectCustomer.module.less';

const cx = classNames.bind(styles);

@component
export class SelectCustomer extends React.Component
{
  @di private _staffBoardState!:StaffBoardState;
  @di private _staffBoardController!:StaffBoardController;

  // ---------------------------------------------------------

  @computed
  private get _menuItems():Array<Common_Customer | null>
  {
    const customers = this._staffBoardState.customers.filter(customer => !customer.is_internal);
    const internalCustomers = this._staffBoardState.customers.filter(customer => customer.is_internal);

    const allCustomers:Array<Common_Customer | null> = [...customers];

    // if( customers.length > 0 && internalCustomers.length > 0 )
    //   allCustomers.push(null);

    allCustomers.push(...internalCustomers);

    return allCustomers;
  }

  // ---------------------------------------------------------

  public componentDidMount():void
  {
    // this._staffBoardController.initCustomers().then(() =>
    // {
    //   if( this._staffBoardState.selectedCustomerId == 0 && this._staffBoardState.customers.length > 0 )
    //   {
    //     const customerId = this._staffBoardState.customers[0].id;
    //     this._staffBoardController.selectCustomer(customerId);
    //   }
    // });
  }

  // ---------------------------------------------------------

  @bind
  private _itemRenderer(customer:Common_Customer | null,
                        { index, handleClick, modifiers }:IItemRendererProps):JSX.Element | null
  {
    if( !customer ) return null;
    // return <MenuDivider key={`div-${index}`} />;

    if( !modifiers.matchesPredicate )
    {
      return null;
    }

    const { selectedCustomerId } = this._staffBoardState;

    const allCustomers:Array<Common_Customer | null> = this._menuItems;

    let isMenuDivider:boolean = false;

    console.log('%c customer =', 'background:#0f0;color:#000;', index, customer.is_internal, toJS(customer));
    if( index )
    {
      isMenuDivider = index < allCustomers.length - 1 && !!allCustomers[index + 1]?.is_internal;
      console.log('%c isMenuDivider =', 'background:#080;color:#000;', isMenuDivider, allCustomers[index + 1]?.is_internal);
    }

    const customerName = customer.alias?.length ? customer.alias : customer.name || '???';

    return (
      <MenuItem
        key={customer.id}
        text={customerName}
        className={cx(styles.menuItem, { isMenuDivider })}
        onClick={handleClick}
        active={modifiers.active}
        // selected={customer.id === selectedCustomerId}
      />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _onItemSelect(customer:Common_Customer | null):void
  {
    if( customer )
      this._staffBoardController.selectCustomer(customer.id);
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { selectedCustomerId } = this._staffBoardState;

    if( !selectedCustomerId )
      return null;

    const customer = this._staffBoardController.findCustomerById(selectedCustomerId);

    const allCustomers:Array<Common_Customer | null> = this._menuItems;

    if( allCustomers.length == 0 )
      return (
        <div className={styles.selectBtn}>
          You are not allocated to a team
        </div>
      );

    return (
      <>
        <PageTitle title={customer?.alias} />

        <Select
          className={styles.select}
          items={allCustomers}
          itemRenderer={this._itemRenderer}
          onItemSelect={this._onItemSelect}
          filterable={false}
          fill={true}
          popoverProps={{
            minimal: true,
            usePortal: false
          }}
        >
          <Button
            className={styles.selectBtn}
            text={customer ? customer.alias : 'Select a customer'}
            rightIcon={IconNames.CARET_DOWN}
            minimal={true}
            alignText={Alignment.LEFT}
          />
        </Select>
      </>
    );
  }

  // ---------------------------------------------------------
}
