import type * as Types from './types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetObjectsHistoryQueryVariables = Types.Exact<{
  expression?:Types.InputMaybe<Array<Types.Audit_Logged_Actions_Bool_Exp> | Types.Audit_Logged_Actions_Bool_Exp>;
  limit:Types.Scalars['Int'];
  offset:Types.Scalars['Int'];
}>;


export type GetObjectsHistoryQuery = { audit_logged_actions:Array<{ event_id:any; schema_name:string; table_name:string; full_qualified_table_name:string; action_tstamp_clk:any; action:string; changed_fields?:any | null; row_data?:any | null; user?:Array<{ id:number; email?:string | null; first_name:string; last_name:string; avatar?:string | null; }> | null; }>; };


export const GetObjectsHistoryDocument = gql`
    query GetObjectsHistory($expression: [audit_logged_actions_bool_exp!], $limit: Int!, $offset: Int!) {
  audit_logged_actions(
    order_by: {action_tstamp_clk: desc}
    where: {_or: $expression}
    limit: $limit
    offset: $offset
  ) {
    event_id
    schema_name
    table_name
    full_qualified_table_name
    action_tstamp_clk
    user {
      id
      email
      first_name
      last_name
      avatar
    }
    action
    changed_fields
    row_data
  }
}
    `;
export type GetObjectsHistoryQueryResult = Apollo.QueryResult<GetObjectsHistoryQuery, GetObjectsHistoryQueryVariables>;
export const namedOperations = {
  Query: {
    GetObjectsHistory: 'GetObjectsHistory'
  }
}