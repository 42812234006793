import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import type { ReactNode } from 'react';
import React from 'react';
import { TeamController } from '../../TeamController';

@component
export class CustomerToolbarMenu extends React.Component
{
  @di private _teamController!:TeamController;

  @bind
  private _newTeam():void
  {
    this._teamController.showCreateTeamDialog();
  }

  public render():ReactNode
  {
    const { allCollapsed } = this._teamController;
    const btnCollapseText = allCollapsed ? 'Expand all' : 'Collapse all';

    return (
      <>
        <Menu>
          <MenuItem
            text="New Team"
            onClick={this._newTeam}
          />
          <MenuDivider />
          <MenuItem
            text={btnCollapseText}
            onClick={():void => this._teamController.collapseAll(!allCollapsed)}
          />
        </Menu>
      </>
    );
  }
}
