import type { Recruiting_Position, Staffing_Customer_Team } from '@flow/data-access/lib/types/graphql.generated';
import { Common_Customer_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { state } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import type { IReactionDisposer } from 'mobx';
import { computed, observable } from 'mobx';
import type { Common_Customer_Ex } from './models/CustomersTypes';

export enum TeamFilter
{
  Active = 'active',
  Prospective = 'prospective'
}

export const CustomerState_LS_KEY:string = 'customerState';

@state
export class CustomerState
{
  // ----------------------------------------------------

  public disposers:Array<IReactionDisposer> = [];

  // ----------------------------------------------------

  @observable public pageNotFound:boolean = false;

  // Customer -----------------------------------
  @observable public customer:Common_Customer_Ex | null = null;

  // Create/Edit Customer Dialog ----------------
  @observable public isCustomerDialogOpen:boolean = false;
  @observable public isCustomerLoading:boolean = false;
  @observable public customerId:number = -1;
  @observable public customerName:string = '';
  @observable public customerAlias:string = '';
  @observable public customerStatus:Common_Customer_Status_Enum = Common_Customer_Status_Enum.Prospective;
  @observable public isCustomerInternal:boolean = false;

  @observable public teamFilterActiveProspective:TeamFilter = TeamFilter.Active;

  @computed
  public get isCustomerValid():boolean
  {
    return (this.customerName || '').trim().length > 0
      && (this.customerAlias || '').trim().length > 0;
  }

  // --------------------------------------------

  @bind
  public findCustomerTeamById(teamId:number):Staffing_Customer_Team | null
  {
    if( !this.customer?.customer_teams ) return null;
    return this.customer?.customer_teams.find((team:Staffing_Customer_Team) => team.id === teamId) || null;
  }

  // --------------------------------------------

  public findCustomerTeamByPositionId(positionId:number):Staffing_Customer_Team | null
  {
    if( !this.customer?.customer_teams ) return null;
    return this.customer?.customer_teams.find((team:Staffing_Customer_Team) => team.positions.findIndex(p => p.id == positionId) >= 0) || null;
  }

  // --------------------------------------------

  public activePositionIndexById(positionId:number):number
  {
    const team = this.findCustomerTeamByPositionId(positionId);

    if( !team || !this.customer?.customer_teams )
      return -1;

    return team.positions.findIndex((position:Recruiting_Position) => position.id === positionId);
  }

  public findPositionById(id:number):Recruiting_Position | null
  {
    if( !this.customer?.customer_teams ) return null;

    for( const team of this.customer.customer_teams )
    {
      const position = team.positions.find(position => position.id == id);

      if( position ) return position;
    }

    return null;
  }

}
