import type { ApolloQueryResult } from '@apollo/client';
import type { FetchResult } from '@apollo/client/link/core';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import { MoveTo } from '@flow/common/models/MoveTo';
import type {
  UpdatePositionUiCustomerTeamOrderMutation,
  UpdatePositionUiCustomerTeamOrderMutationVariables
} from '@flow/data-access/lib/customers.generated';
import {
  UpdatePositionUiCustomerTeamOrderDocument
} from '@flow/data-access/lib/customers.generated';
import type {
  CreatePositionMutation,
  CreatePositionMutationVariables,
  DeletePositionMutation,
  DeletePositionMutationVariables,
  UpdatePositionQuantityMutation,
  UpdatePositionQuantityMutationVariables
} from '@flow/data-access/lib/positions.generated';
import {
  CreatePositionDocument,
  DeletePositionDocument,
  UpdatePositionQuantityDocument
} from '@flow/data-access/lib/positions.generated';
import type {
  AllPositionTemplatesQuery,
  AllPositionTemplatesQueryVariables
} from '@flow/data-access/lib/positionTemplates.generated';
import { AllPositionTemplatesDocument } from '@flow/data-access/lib/positionTemplates.generated';
import {
  type Recruiting_Position_Insert_Input,
  type Recruiting_Position
} from '@flow/data-access/lib/types/graphql.generated';
import type {
  Maybe,
  Recruiting_Position_Template
} from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import { action, runInAction } from 'mobx';
import { CustomerController } from './CustomerController';
import { CustomerState } from './CustomerState';
import { NewPositionState } from './NewPositionState';

@controller
export class NewPositionController
{
  @di private _commonState!:CommonState;
  @di private _commonController!:CommonController;
  @di private _positionState!:NewPositionState;
  @di private _customerState!:CustomerState;
  @di private _customerController!:CustomerController;

  // ----------------------------------------------------

  @action.bound
  public async initPositionTemplates():Promise<void>
  {
    const result:ApolloQueryResult<AllPositionTemplatesQuery> =
      await this._commonController.query<AllPositionTemplatesQuery,
        AllPositionTemplatesQueryVariables>({
        query: AllPositionTemplatesDocument,
        variables: {}
      });

    console.log('%c initPositionTemplates result =', 'background:#0f0;color:#000;', result);

    runInAction(() =>
    {
      this._positionState.positionTemplates = result.data.recruiting_position_template as Array<Recruiting_Position_Template>;
    });
  }

  // ----------------------------------------------------

  @action.bound
  public showCreatePositionDialog():void
  {
    this._positionState.isPositionDialogOpen = true;
    this._positionState.search = '';
    this._positionState.selectedPositionTemplate = null;

    this.initPositionTemplates();
  }

  @action.bound
  public hidePositionDialog():void
  {
    this._positionState.isPositionDialogOpen = false;
    this._positionState.isPositionLoading = false;
    this._positionState.clickedPositionDialogItemId = -1;
  }

  @action.bound
  public setSearch(newSearch:string):void
  {
    this._positionState.search = newSearch;
  }

  @action.bound
  public setSelectedPositionTemplate(selectedPositionTemplate:Recruiting_Position_Template | null):void
  {
    this._positionState.selectedPositionTemplate = selectedPositionTemplate;
  }

  @action.bound
  public async createPosition(customerTeamId:number,
                              positionTemplateId:number,
                              quantity:number):Promise<number>
  {
    const result:FetchResult<CreatePositionMutation> =
      await this._commonController.mutate<CreatePositionMutation,
        CreatePositionMutationVariables>({
        mutation: CreatePositionDocument,
        variables: {
          customerTeamId,
          positionTemplateId,
          alias: null, // TODO:
          quantity
        }
      });

    console.log('%c createPosition result =', 'background:#0f0;color:#000;', result);

    return result.data?.action_position_create?.id || -1;
  }

  // ----------------------------------------------------

  @action.bound
  public async deletePosition(positionId:number):Promise<boolean>
  {
    const result:FetchResult<DeletePositionMutation> =
      await this._commonController.mutate<DeletePositionMutation,
        DeletePositionMutationVariables>({
        mutation: DeletePositionDocument,
        variables: {
          positionId: positionId
        }
      });

    console.log('%c deletePosition result =', 'background:#0f0;color:#000;', result);

    return !!result.data?.action_position_delete?.success;
  }

  // ----------------------------------------------------

  @action.bound
  public async updatePositionQuantity(positionId:number, quantity:number):Promise<boolean>
  {
    const result:FetchResult<UpdatePositionQuantityMutation> =
      await this._commonController.mutate<UpdatePositionQuantityMutation,
        UpdatePositionQuantityMutationVariables>({
        mutation: UpdatePositionQuantityDocument,
        variables: {
          positionId,
          quantity
        }
      });

    console.log('%c updatePositionQuantity result =', 'background:#0f0;color:#000;', result);

    return !!result.data?.action_position_update_quantity?.success;
  }

  @action.bound
  public updateClickedPositionDialogItemId(positionDialogItemId:number):void
  {
    this._positionState.clickedPositionDialogItemId = positionDialogItemId;
  }

  // ----------------------------------------------------

  @action.bound
  public async changePositionOrder(position:Recruiting_Position, to:MoveTo):Promise<void>
  {
    const { orderedTeamPositions } = this._customerController;

    let teamId:Maybe<number> | undefined = position.customer_team_id;

    if( !teamId )
      teamId = this._customerState.findCustomerTeamByPositionId(position.id)?.id;

    if( !teamId )
      return;

    const positions = orderedTeamPositions(teamId);

    if( !positions )
      return;

    const allOrders = positions.map(position => position.ui_customer_team_order);

    const hasDuplicateOrMissingOrder = allOrders
      .some((order, index) => order == null || allOrders.indexOf(order) != index);

    if( hasDuplicateOrMissingOrder )
    {
      // Update order for all positions
      for( let i = 0; i < positions.length; i++ )
      {
        positions[i].ui_customer_team_order = i;
      }
      await this.updatePositionsUiCustomerTeamOrder(positions);
    }

    const positionIndex:number = this._customerState.activePositionIndexById(position.id);

    if( positionIndex === -1 ) return;

    let secondPosition:Recruiting_Position | null = null;

    switch( to )
    {
      case MoveTo.TOP:
        position.ui_customer_team_order = Number(positions[0].ui_customer_team_order) - 1;
        break;

      case MoveTo.BOTTOM:
        position.ui_customer_team_order = Number(positions[positions.length - 1].ui_customer_team_order) + 1;
        break;

      case MoveTo.UP:
        this._positionState.movingId = position.id;
        this._positionState.moveUpId = position.id;
        position.ui_customer_team_order = Number(position.ui_customer_team_order) - 1;
        if( positions[positionIndex - 1] )
        {
          secondPosition = positions[positionIndex - 1];
          secondPosition.ui_customer_team_order = Number(position.ui_customer_team_order + 1);
          this._positionState.moveDownId = secondPosition.id;
        }
        break;

      case MoveTo.DOWN:
        this._positionState.movingId = position.id;
        this._positionState.moveDownId = position.id;
        position.ui_customer_team_order = Number(position.ui_customer_team_order) + 1;
        if( positions[positionIndex + 1] )
        {
          secondPosition = positions[positionIndex + 1];
          secondPosition.ui_customer_team_order = Number(position.ui_customer_team_order - 1);
          this._positionState.moveUpId = secondPosition.id;
        }
        break;
    }

    await this.updatePositionsUiCustomerTeamOrder([position, secondPosition]);

    runInAction(() =>
    {
      if( this._customerState.customer )
        this._customerController.initCustomer(this._customerState.customer.id);

      // const team:Recruiting_Position | null = this._customerState.findPositionById(returning.id);

      // if( team )
      //   team.ui_customer_team_order = returning.ui_customer_team_order;
    });
  }

  // ----------------------------------------------------

  public async updatePositionsUiCustomerTeamOrder(positions:Array<Recruiting_Position | null>):Promise<void>
  {
    const positionsToUpdate = positions
      .filter(position => position != null)
      .map((position:Recruiting_Position | null) =>
      {
        return position ? { id: position.id, ui_customer_team_order: position.ui_customer_team_order, customer_team_id: 0 } : null;
      });

    if( !positionsToUpdate.length ) return;

    await this._commonController.mutate<UpdatePositionUiCustomerTeamOrderMutation,
      UpdatePositionUiCustomerTeamOrderMutationVariables>({
      mutation: UpdatePositionUiCustomerTeamOrderDocument,
      variables: {
        positions: positionsToUpdate.filter(position => !!position) as Array<Recruiting_Position_Insert_Input>
      }
    });
  }
}

