import type { Recruiting_Candidate, Recruiting_Position } from '@flow/data-access/lib/types/graphql.generated';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';

export class SortUtil
{
  // ----------------------------------------------------

  public static byCustomerAndTeam(a:Recruiting_Position | Recruiting_Position_Ex, b:Recruiting_Position | Recruiting_Position_Ex):number
  {
    if( a.customer_team?.customer.id === b.customer_team?.customer.id )
    {
      return (a.customer_team?.ui_order || 0) - (b.customer_team?.ui_order || 0);
    }

    return (a.customer_team?.customer.alias?.toLowerCase() || 0) > (b.customer_team?.customer.alias?.toLowerCase() || 0) ? 1 : -1;
  }

  // ----------------------------------------------------

  public static byCandidateName(a:Recruiting_Candidate, b:Recruiting_Candidate):number
  {
    const aName:string = `${a.first_name.toLowerCase()} ${a.last_name.toLowerCase()}`;
    const bName:string = `${b.first_name.toLowerCase()} ${b.last_name.toLowerCase()}`;

    return aName > bName ? 1 : -1;
  }

  // ----------------------------------------------------
}
