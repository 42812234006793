import type { Common_User } from '@flow/data-access/lib/types/graphql.generated';
import { state } from '@flow/dependency-injection';
import { observable } from 'mobx';

export interface ScheduleEvent
{
  id?:number;
  start_time:string | number;
  end_time:string | number;
  selected?:boolean;
}

export interface ScheduleCallUser
{
  user:Partial<Common_User>;
  time:string; // ???
  events:Array<ScheduleEvent>;
  busy:Array<ScheduleEvent>;
}

@state
export class ScheduleState
{
  // ----------------------------------------------------

  @observable public isScheduleLoaded:boolean = false;

  @observable public users:Array<ScheduleCallUser> = [];

  // ----------------------------------------------------

  @observable public viewDate:number | string | null = null;

  @observable public scheduleDate:number | string | null = null;

  @observable public eventStartTime:number | string | null = null;
  @observable public eventEndTime:number | string | null = null;

  // ----------------------------------------------------

  @observable public scrollBarWidth:number = 0;

  // ----------------------------------------------------
}
