import type { Staffing_Customer_Team_Slot } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import type { Staffing_Customer_Team_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import type { IUnitedTeamLead } from '@flow/modules/staffing/board/components/TeamCardStaffedPositions';
import { StaffBoardController } from '@flow/modules/staffing/board/StaffBoardController';
import { computed } from 'mobx';
import React from 'react';
import { StaffBoardState } from '../StaffBoardState';

import styles from './StaffBoardContent.module.less';
import { StaffBoardTeamCard } from './StaffBoardTeamCard';

interface StaffBoardTeams
{
  leadershipTeams:Array<Staffing_Customer_Team_Ex>;
  infraTeams:Array<Staffing_Customer_Team_Ex>;
  unitedTeamLeads:Array<Array<Staffing_Customer_Team_Ex>>;
}

@component
export class StaffBoardContent extends React.Component
{
  @di private _staffBoardState!:StaffBoardState;
  @di private _staffBoardController!:StaffBoardController;

  // ---------------------------------------------------------

  private _hasTag(team:Staffing_Customer_Team_Ex, tagName:string):boolean
  {
    return team.customer_team_tags.findIndex(tag => tag.name == tagName) >= 0;
  }

  // ---------------------------------------------------------

  @computed
  private get _staffBoardTeams():StaffBoardTeams | null
  {
    const { customer, selectedCustomerId } = this._staffBoardState;

    if( !customer || customer.id !== selectedCustomerId ) return null;

    const teams:Array<Staffing_Customer_Team_Ex> = this._staffBoardState.customer?.customer_teams as Array<Staffing_Customer_Team_Ex>;

    if( !teams || teams.length == 0 ) return null;

    const otherTeams:Array<Staffing_Customer_Team_Ex> = [];

    const result:StaffBoardTeams = {
      leadershipTeams: [],
      infraTeams: [],
      unitedTeamLeads: []
    };

    // -----------------

    teams.forEach(team =>
    {
      // TODO: this tag-based alignment is for testing purpose; it should become a customer_team field
      if( this._hasTag(team, 'Leadership') )
      {
        result.leadershipTeams.push(team);
      }
      else if( this._hasTag(team, 'Infrastructure') )
      {
        result.infraTeams.push(team);
      }
      else
        otherTeams.push(team);
    });

    // -----------------

    let indexInCommon:number = 0;
    const passedIndexes:Array<number> = [];

    otherTeams.forEach((currentTeam:Staffing_Customer_Team_Ex, currentIndex:number) =>
    {
      if( passedIndexes.includes(currentIndex) ) return;

      passedIndexes.push(currentIndex);

      const currentTeamLeadSlot:Staffing_Customer_Team_Slot | null = this._staffBoardController.getTeamLeadSlot(currentTeam);

      result.unitedTeamLeads[indexInCommon] = [];

      if( !currentTeamLeadSlot )
      {
        result.unitedTeamLeads[indexInCommon].push(currentTeam);
        indexInCommon++;
        return;
      }

      otherTeams.forEach((nestedTeam:Staffing_Customer_Team_Ex, nestedIndex:number) =>
      {
        if( nestedIndex < currentIndex || passedIndexes.includes(nestedIndex) ) return;

        const searchTeamLeadSlot:Staffing_Customer_Team_Slot | null = this._staffBoardController.getTeamLeadSlot(nestedTeam);

        if( !searchTeamLeadSlot ) return;

        // TODO: !!! -> check all fields
        // next_staff, next_candidate, leaving_date ... etc.
        if( currentTeamLeadSlot.staff?.id === searchTeamLeadSlot.staff?.id )
        {
          result.unitedTeamLeads[indexInCommon].push(nestedTeam);
          passedIndexes.push(nestedIndex);
        }
      });

      result.unitedTeamLeads[indexInCommon].unshift(currentTeam);
      indexInCommon++;
    });

    return result;
  }

  // ---------------------------------------------------------

  public render():React.ReactNode | null
  {
    if( !this._staffBoardTeams ) return null;

    const { leadershipTeams, infraTeams, unitedTeamLeads } = this._staffBoardTeams;

    return (
      <div className={styles.scrollY}>
        <div className={styles.contentWrapper}>

          {leadershipTeams.map((team) => <StaffBoardTeamCard key={team.id} team={team} fullWidth />)}

          <div className={styles.scrollX}>
            {unitedTeamLeads.map((nestedArray:Array<Staffing_Customer_Team_Ex>) =>
              {
                return nestedArray.map((team:Staffing_Customer_Team_Ex, index:number) =>
                {
                  const unitedTeamLead:IUnitedTeamLead | undefined = nestedArray.length > 1
                    ? {
                      index,
                      length: nestedArray.length
                    }
                    : undefined;

                  return <StaffBoardTeamCard key={team.id} team={team} unitedTeamLead={unitedTeamLead} />;
                });
              }
            )}
          </div>

          {infraTeams.map((team) => <StaffBoardTeamCard key={team.id} team={team} fullWidth />)}

        </div>
      </div>
    );
  }

  // ---------------------------------------------------------
}
