import { Maybe } from '@flow/common/models/Types';
import type { Recruiting_Position } from '@flow/data-access/lib/types/graphql.generated';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';

export interface IPositionsCount
{
  open:number;
  total:number;
  staffed:number;
}

export class PositionsUtil
{
  // ----------------------------------------------------

  public static getPositionsCount(
    positions:Array<Recruiting_Position_Ex>,
    getPosition?:(id:number) => Recruiting_Position_Ex | undefined
  ):IPositionsCount
  {
    let open:number = 0;
    let total:number = 0;

    positions.forEach((_position:Recruiting_Position_Ex) =>
    {
      const position:Recruiting_Position_Ex | undefined = getPosition
        ? getPosition(_position.id)
        : _position;

      if( !position ) return;

      if( position.open_slots_count?.aggregate?.count )
        open += position.open_slots_count.aggregate.count;

      if( position.total_slots_count?.aggregate?.count )
        total += position.total_slots_count.aggregate.count;
    });

    return {
      open,
      total,
      staffed: total - open
    };
  }

  // ----------------------------------------------------

  public static getPositionName(position:Maybe<Recruiting_Position | Recruiting_Position_Ex>):string
  {
    return position ? position.alias || position.position_template?.name || '' : '';
  }

  // ----------------------------------------------------
}
