import { Common_Customer_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { state } from '@flow/dependency-injection';
import type { IReactionDisposer } from 'mobx';
import { observable } from 'mobx';
import type { Common_Customer_Ex } from './models/CustomersTypes';

export const CustomersState_LS_KEY:string = 'customersState';

@state
export class CustomersState
{
  // ----------------------------------------------------

  public disposers:Array<IReactionDisposer> = [];

  // ----------------------------------------------------

  // Customers List -----------------------------
  @observable public filterByCustomerStatus:Common_Customer_Status_Enum = Common_Customer_Status_Enum.Current;
  @observable public customers:Array<Common_Customer_Ex> = [];
  @observable public internalCustomers:Array<Common_Customer_Ex> = [];
}
