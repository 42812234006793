import { component } from '@flow/dependency-injection';
import type { IReactComponent } from 'mobx-react/dist/types/IReactComponent';
import type { ComponentType, FunctionComponent, ReactElement } from 'react';
import React from 'react';
import type { Location, Params, URLSearchParamsInit } from 'react-router-dom';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { isReactComponent } from './utils';

export interface IWithRouterProps<P = Params>
{
  params?:P;
  location?:Location & {state:any;};
  search?:[URLSearchParams, (nextInit:URLSearchParamsInit, navigateOptions?:{
    replace?:boolean | undefined;
    state?:any;
  } | undefined) => void];
}

function withRouter<Props extends IWithRouterProps<P>, P = Params>(
  ComposedComponent:ComponentType<Props>
):IReactComponent<Props>
{
  const WithRouterWrapper:FunctionComponent<Props> = (props:Props):ReactElement =>
  {
    const params = useParams();
    const location = useLocation();
    const search = useSearchParams();

    return <ComposedComponent {...props} params={params} location={location} search={search} />;
  };

  const name = ComposedComponent.displayName || ComposedComponent.name || 'Unknown';
  WithRouterWrapper.displayName = `withRouter(${name})`;

  return WithRouterWrapper;
}

export function componentWithRouter<T extends IReactComponent<Props>, Props extends IWithRouterProps<P>, P = Params>(WrappedComponent:T):T
{
  if( isReactComponent(WrappedComponent) )
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
    return withRouter(component(WrappedComponent) as any) as any as T;
  else
    throw new Error(
      '@componentWithRouter decorator should be used on React components only'
    );
}
