import type { Recruiting_Interview_Flow_Stage } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import type { ReactNode } from 'react';
import React from 'react';
import { InterviewFlowsState } from '../InterviewFlowsState';

import styles from './InterviewFlowColumnsHeader.module.less';

interface Props
{
  scrollBarWidth:number;
}

@component
export class InterviewFlowColumnsHeader extends React.Component<Props>
{
  @di private _interviewFlowsState!:InterviewFlowsState;

  @bind
  private _renderColTitle(stage:Recruiting_Interview_Flow_Stage):JSX.Element
  {
    return (
      <div
        key={stage.name}
        className={styles.col}
      >
        <div className={styles.colTitle}>
          {stage.name}
        </div>
      </div>
    );
  }

  public render():ReactNode
  {
    const { scrollBarWidth } = this.props;
    const { selectedInterviewFlow } = this._interviewFlowsState;

    if( !selectedInterviewFlow )
      return null;

    return (
      <div
        className={styles.colTitles}
        style={scrollBarWidth ? { paddingRight: `${scrollBarWidth as number}px` } : undefined}
      >
        {selectedInterviewFlow.stages.map(this._renderColTitle)}
      </div>
    );
  }
}
