import { PageLayout } from '@flow/common/components/page/PageLayout';
import { RoleController } from '@flow/common/controllers/RoleController';
import { Roles } from '@flow/common/models/auth/Roles';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { RouteName } from '@flow/common/models/routing/RouteName';
import type { IRouteValidator } from '@flow/common/models/routing/RouteValidators';
import { numericParamValidator } from '@flow/common/models/routing/RouteValidators';
import { di } from '@flow/dependency-injection';
import { ScheduleDemo } from '@flow/modules/_demo/schedule/ScheduleDemo';
import { FeedbackForm } from '@flow/modules/recruiting/feedbackForm/FeedbackForm';
import { Position } from '@flow/modules/recruiting/position/Position';
import { Priorities } from '@flow/modules/recruiting/priorities/Priorities';
import { Schedule } from '@flow/modules/recruiting/schedule/Schedule';
import { SettingsRootPage } from '@flow/modules/settings/settingsPage/SettingsRootPage';
import { SettingsPositionGroups } from '@flow/modules/settings/settingsPositionGroups/SettingsPositionGroups';
import { SettingsPage } from '@flow/modules/settings/settingsTemplatePage/SettingsPage';
import { StaffBoardPage } from '@flow/modules/staffing/board/StaffBoardPage';
import { StaffListPage } from '@flow/modules/staffing/staff/StaffListPage';
import { StaffMemberPage } from '@flow/modules/staffing/staff/StaffMemberPage';
import type { IReactComponent } from 'mobx-react/dist/types/IReactComponent';
import type { Params } from 'react-router';
import { generatePath, Outlet } from 'react-router-dom';
import { Customers } from '../modules/customers/Customers';
import { CustomersTeams } from '../modules/customers/teams/CustomersTeams';
import { CustomerTeams } from '../modules/customers/teams/CustomerTeams';
import { HistoryExample } from '../modules/history/HistoryExample';
import { Candidate } from '../modules/recruiting/candidates/Candidate';
import { Candidates } from '../modules/recruiting/candidates/Candidates';
import { Positions } from '../modules/recruiting/positions/Positions';
import { Recruiting } from '../modules/recruiting/Recruiting';
import { InterviewFlow } from '../modules/recruiting/status/InterviewFlow';
import { InterviewFlows } from '../modules/recruiting/status/InterviewFlows';
import { Staffing } from '../modules/staffing/Staffing';
import { Home } from './Home';
import { Login } from './Login';

export interface IRouteConfig
{
  readonly name:RouteName;
  readonly path:string;
  readonly isIndexRoute?:boolean;
  readonly hasPermissionFn?:() => boolean;
  readonly roles?:Array<Roles>;
  readonly component:IReactComponent;
  readonly title?:string;
  readonly validators?:Array<IRouteValidator>;
  readonly routes?:Array<IRouteConfig>;
  readonly redirect?:RouteName;
}

export interface IMatchedRouteRoute extends IRouteConfig
{
  readonly fullPath:string;
}

export class RoutesConfig
{
  @di private static _roleController:RoleController;

  public static getRouteConfig(routeName:RouteName):IMatchedRouteRoute | null
  {
    return this.findRoute(routeName, RoutesConfig.Routes);
  }

  private static findRoute(routeName:RouteName, routes:Array<IRouteConfig>, parentPath:string = ''):IMatchedRouteRoute | null
  {
    for( const routeConfig of routes )
    {
      const fullPath:string = RoutesConfig.combinePath(true, parentPath, routeConfig.path);

      if( routeConfig.name === routeName )
      {
        return {
          ...routeConfig,
          fullPath
        };
      }
      else if( routeConfig.routes )
      {
        const subRouteConfig:IMatchedRouteRoute | null = RoutesConfig.findRoute(routeName, routeConfig.routes, fullPath);

        if( subRouteConfig )
          return subRouteConfig;
      }
    }

    return null;
  }

  public static getRoutePath(routeName:RouteName, params?:Params):string | null
  {
    const routeConfig:IMatchedRouteRoute | null = RoutesConfig.getRouteConfig(routeName);

    if( routeConfig )
      return params ? generatePath(routeConfig.fullPath, params) : routeConfig.fullPath;

    return null;
  }

  public static getAccessFn(routeName:RouteName):(() => boolean) | null
  {
    const routeConfig:IMatchedRouteRoute | null = RoutesConfig.getRouteConfig(routeName);

    return routeConfig?.hasPermissionFn || null;
  }

  public static combinePath(isAbsolute:boolean, ...paths:Array<string>):string
  {
    return ((isAbsolute ? '/' : '') + paths.map(
      (path:string) => path.replace(/^[/*]+|[/*]+$/g, '')
    ).filter((path:string) => path).join('/') + '/').replace('//', '/');
  }

  public static validateRoutesConfig():void
  {
    // TODO:
  }

  public static getCurrentRoute(path?:string, getParent:boolean = false):RouteName | null
  {
    if( !path )
      path = location?.pathname;

    const currentRouteParts = path.split('/').filter(part => part.length);

    if( getParent )
      currentRouteParts.pop();

    let routes = RoutesConfig.Routes;
    let matchingRoute = null;

    for( let i = 0; i < currentRouteParts.length; i++ )
    {
      const currentRoutePart = currentRouteParts[i];

      matchingRoute = routes.find(route => route.path.replace(/^\/|\/$/g, '') == currentRoutePart);

      if( !matchingRoute )
      {
        const anyRoute = routes.find(route => route.path.replace(/^\/|\/$/g, '') == '*');

        if( anyRoute && anyRoute.routes )
        {
          routes = anyRoute.routes;
          i--;
          continue;
        }
      }

      if( !matchingRoute )
        matchingRoute = routes.find(route => route.path.replace(/^\/|\/$/g, '').startsWith(':'));

      if( matchingRoute && matchingRoute.routes )
        routes = matchingRoute.routes;
    }

    return String(matchingRoute?.name) as RouteName || null;
  }

  public static getCurrentRouteParams(path?:string):Record<string, string>
  {
    if( !path )
      path = location?.pathname;

    const currentRouteParts = path.split('/').filter(part => part.length);

    let routes = RoutesConfig.Routes;
    let matchingRoute = null;

    const params:Record<string, string> = {};

    for( let i = 0; i < currentRouteParts.length; i++ )
    {
      const currentRoutePart = currentRouteParts[i];

      matchingRoute = routes.find(route => route.path.replace(/^\/|\/$/g, '') == currentRoutePart);

      if( !matchingRoute )
      {
        const anyRoute = routes.find(route => route.path.replace(/^\/|\/$/g, '') == '*');

        if( anyRoute && anyRoute.routes )
        {
          routes = anyRoute.routes;
          i--;
          continue;
        }
      }

      if( !matchingRoute )
      {
        matchingRoute = routes.find(route => route.path.replace(/^\/|\/$/g, '').startsWith(':'));

        if( matchingRoute?.path )
          params[String(matchingRoute.path.replace(/^[/:]|\/$/g, ''))] = currentRoutePart;
      }

      if( matchingRoute && matchingRoute.routes )
        routes = matchingRoute.routes;
    }

    return params;
  }

  public static isCurrentRoute(routeName:RouteName):boolean
  {
    return RoutesConfig.getCurrentRoute() === routeName;
  }

  public static Routes:Array<IRouteConfig> = [
    {
      name: RouteName.LOGIN,
      path: '/login/',
      title: '',
      component: Login,
      roles: [Roles.Anonymous]
    },
    {
      name: RouteName.ROOT,
      path: '/*',
      component: PageLayout,
      routes: [
        {
          name: RouteName.HOME,
          path: '',
          title: 'Dashboard',
          component: Home,
          isIndexRoute: true
        },
        {
          name: RouteName.CUSTOMERS,
          path: 'customers/',
          component: Customers,
          hasPermissionFn: ():boolean => this._roleController.hasPermission(FlowPermissions.EditCustomer),
          redirect: RouteName.CUSTOMERS_TEAMS,
          routes: [
            // {
            //   name: RouteName.CUSTOMERS_HOME,
            //   path: 'home/',
            //   component: CustomersHome,
            //   hasPermissionFn: ():boolean => this._roleController.hasPermission(FlowPermissions.EditCustomer),
            //   isIndexRoute: true
            // },
            {
              name: RouteName.CUSTOMERS,
              path: '',
              title: 'Customers',
              component: CustomersTeams,
              hasPermissionFn: ():boolean => this._roleController.hasPermission(FlowPermissions.EditCustomer)
            },
            {
              name: RouteName.CUSTOMERS_CUSTOMER,
              path: ':customerId/',
              title: 'Customer',
              validators: [numericParamValidator('customerId')],
              component: CustomerTeams,
              hasPermissionFn: ():boolean => this._roleController.hasPermission(FlowPermissions.EditCustomer),
              routes: [
                {
                  name: RouteName.CUSTOMERS_CUSTOMER_TEAM_POSITION,
                  path: ':positionId/',
                  title: 'Position Description',
                  validators: [numericParamValidator('positionId')],
                  component: Position
                }
              ]
              // routes: [
              //   {
              //     name: RouteName.CUSTOMERS_CUSTOMER_TEAM_POSITIONS,
              //     path: ':teamId/',
              //     title: 'Team',
              //     validators: [numericParamValidator('teamId')],
              //     component: TeamPositions,
              //   }
              // ]
            }
          ]
        },
        {
          name: RouteName.RECRUITING,
          path: 'recruiting/',
          title: 'Recruiting',
          component: Recruiting,
          hasPermissionFn: ():boolean => this._roleController.hasAnyPermission(FlowPermissions.ViewRecruitingPages),
          redirect: RouteName.RECRUITING_BOARDS,
          routes: [
            // {
            //   name: RouteName.RECRUITING_HOME,
            //   path: 'home/',
            //   component: RecruitingHome,
            //   isIndexRoute: true
            // },
            {
              name: RouteName.RECRUITING_BOARDS,
              path: 'board/',
              title: 'Boards',
              component: InterviewFlows,
              hasPermissionFn: ():boolean => this._roleController.hasPermission(FlowPermissions.ViewRecruitingBoardPage),
              routes: [
                {
                  name: RouteName.RECRUITING_BOARD,
                  path: ':interviewFlowId/',
                  validators: [numericParamValidator('interviewFlowId')],
                  component: InterviewFlow
                }
              ]
            },
            {
              name: RouteName.RECRUITING_CANDIDATES,
              path: 'candidates/',
              title: 'Candidates',
              component: Candidates,
              hasPermissionFn: ():boolean => this._roleController.hasAnyPermission(FlowPermissions.ViewCandidatesPage),
              routes: [
                {
                  name: RouteName.RECRUITING_CANDIDATE,
                  path: ':candidateId/',
                  title: 'Candidate',
                  validators: [numericParamValidator('candidateId')],
                  component: Candidate,
                  routes: [
                    {
                      name: RouteName.RECRUITING_CANDIDATE_SCHEDULE_EVENT,
                      path: 'schedule/',
                      title: 'Schedule',
                      component: Outlet,
                      routes: [
                        {
                          name: RouteName.RECRUITING_CANDIDATE_SCHEDULE_EVENT_ID,
                          path: ':eventId/',
                          title: 'Schedule',
                          validators: [numericParamValidator('eventId')],
                          component: Schedule
                        }
                      ]
                    },
                    {
                      name: RouteName.RECRUITING_CANDIDATE_FEEDBACK,
                      path: 'feedback/',
                      title: 'Feedback form',
                      component: Outlet,
                      routes: [
                        {
                          name: RouteName.RECRUITING_CANDIDATE_FEEDBACK_EVENT_ID,
                          path: ':eventId/',
                          title: 'Feedback form',
                          validators: [numericParamValidator('eventId')],
                          component: FeedbackForm
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              name: RouteName.RECRUITING_POSITIONS,
              path: 'positions/',
              title: 'Positions',
              component: Positions,
              hasPermissionFn: ():boolean => this._roleController.hasPermission(FlowPermissions.ViewPositionsPage),
              routes: [
                {
                  name: RouteName.RECRUITING_POSITIONS_POSITION,
                  path: ':positionId/',
                  title: 'Position Description',
                  validators: [numericParamValidator('positionId')],
                  component: Position
                }
              ]
            },
            {
              name: RouteName.RECRUITING_PRIORITIES,
              path: 'priorities/',
              title: 'Priorities',
              component: Priorities,
              hasPermissionFn: ():boolean => this._roleController.hasPermission(FlowPermissions.ViewPrioritiesPage)
            }
          ]
        },
        {
          name: RouteName.STAFFING,
          path: 'staffing/',
          title: 'Staffing',
          component: Staffing,
          hasPermissionFn: ():boolean => this._roleController.hasPermission(FlowPermissions.ViewStaffPages)
            // && this._roleController.hasPermission(FlowPermissions.EditCustomerTeamSlots)
            && this._roleController.hasPermission(FlowPermissions.ViewCustomerTeamSlots),
          redirect: RouteName.STAFFING_STAFF,
          routes: [
            {
              name: RouteName.STAFFING_STAFF,
              path: 'staff/',
              title: 'Staff',
              component: StaffListPage,
              routes: [
                {
                  name: RouteName.STAFFING_STAFF_MEMBER,
                  path: ':staffId/',
                  title: 'Staff Member',
                  validators: [numericParamValidator('staffId')],
                  component: StaffMemberPage
                }
              ]
            },
            {
              name: RouteName.STAFFING_BOARD,
              path: 'board/',
              title: 'Staff Board',
              component: StaffBoardPage,
              routes: [
                {
                  name: RouteName.STAFFING_BOARD_CUSTOMER,
                  path: ':customerId/',
                  validators: [numericParamValidator('customerId')],
                  component: StaffBoardPage
                }
              ]
            }
          ]
        },
        // {
        //   name: RouteName.SCHEDULE,
        //   path: 'schedule/:scheduleId/',
        //   title: 'Schedule',
        //   component: Schedule,
        //   validators: [numericParamValidator('scheduleId')]
        // },
        {
          name: RouteName.DEMO_SCHEDULE,
          path: 'demo/schedule/',
          title: 'DEMO: Schedule',
          component: ScheduleDemo
        },
        // {
        //   name: RouteName.FEEDBACK_FORM,
        //   path: 'feedback/:feedbackId/',
        //   title: 'Feedback From',
        //   component: FeedbackForm,
        //   validators: [numericParamValidator('feedbackId')]
        // },
        {
          name: RouteName.SETTINGS,
          path: 'settings/',
          title: 'Settings',
          component: SettingsRootPage,
          redirect: RouteName.SETTINGS_POSITION_GROUPS,
          routes: [
            {
              name: RouteName.SETTINGS_POSITION_GROUPS,
              path: 'position-groups/',
              title: 'Settings: Position Groups',
              component: SettingsPositionGroups
            },
            {
              name: RouteName.SETTINGS_POSITION_TEMPLATES,
              path: 'position-template/',
              title: 'Settings: Position Templates',
              component: SettingsPage
            },
            {
              name: RouteName.SETTINGS_PIPELINES,
              path: 'pipelines/',
              title: 'Settings: Pipelines',
              component: SettingsPage
            },
            {
              name: RouteName.SETTINGS_USERS,
              path: 'users/',
              title: 'Settings: Users',
              component: SettingsPage
            },
            {
              name: RouteName.SETTINGS_PERMISSIONS,
              path: 'permissions/',
              title: 'Settings: Permissions',
              component: SettingsPage
            },
            {
              name: RouteName.SETTINGS_INTERVIEWERS,
              path: 'interviewers/',
              title: 'Settings: Interviewers',
              component: SettingsPage
            }
          ]
        }
      ]
    },
    // TODO -----------------------------------------
    {
      name: RouteName.HISTORY,
      path: '/history/',
      component: HistoryExample,
      roles: [Roles.Anonymous]
    }
    // TODO -----------------------------------------
  ];
}
