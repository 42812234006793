import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { NeedsPermission } from '@flow/common/components/NeedsPermission';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { type Common_Customer } from '@flow/data-access/lib/types/graphql.generated';
import { Common_Customer_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import type { ReactNode } from 'react';
import React from 'react';
import { CustomerController } from '../../CustomerController';
import { CustomersController } from '../../CustomersController';

export interface ICustomersListItemMenuProps
{
  customer:Common_Customer;
}

@component
export class CustomersListItemMenu extends React.Component<ICustomersListItemMenuProps>
{
  @di private _customerController!:CustomerController;
  @di private _customersController!:CustomersController;

  @bind
  private async _setStatus(newStatus:Common_Customer_Status_Enum):Promise<void>
  {
    const { customer } = this.props;

    await this._customerController.changeCustomerStatus(customer as Common_Customer, newStatus);

    return this._customersController.initCustomers();
  }

  public render():ReactNode
  {
    const { customer } = this.props;

    return (
      <Menu>
        <NeedsPermission name={FlowPermissions.EditCustomer}>
          <MenuItem
            text="Edit customer"
            onClick={():void => this._customerController.editCustomer(customer as Common_Customer)}
          />
        </NeedsPermission>
        {/*<MenuItem*/}
        {/*  text="View Teams"*/}
        {/*  onClick={():void => this._customersController.viewCustomer(customer)}*/}
        {/*/>*/}
        <NeedsPermission name={FlowPermissions.SoftDeleteCustomer}>
          {
            (customer.status === Common_Customer_Status_Enum.Archived ||
              customer.status === Common_Customer_Status_Enum.Current) &&
            <MenuItem
              text={'Make prospective'}
              disabled={customer.is_internal}
              onClick={():unknown => this._setStatus(Common_Customer_Status_Enum.Prospective)}
            />
          }
          {
            (customer.status === Common_Customer_Status_Enum.Archived ||
              customer.status === Common_Customer_Status_Enum.Prospective) &&
            <MenuItem
              text={'Make active'}
              disabled={customer.is_internal}
              onClick={():unknown => this._setStatus(Common_Customer_Status_Enum.Current)}
            />
          }
          {
            (customer.status === Common_Customer_Status_Enum.Current ||
              customer.status === Common_Customer_Status_Enum.Prospective) &&
            <>
              <MenuDivider />
              <MenuItem
                text={'Archive'}
                disabled={customer.is_internal}
                onClick={():unknown => this._setStatus(Common_Customer_Status_Enum.Archived)}
              />
            </>
          }
        </NeedsPermission>
      </Menu>
    );
  }
}
