import type { TextAreaProps } from '@blueprintjs/core';
import { TextArea as BPTextArea } from '@blueprintjs/core';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';
import type { Intent } from '../types/Types';
import { getBPIntent } from '../types/Types';

import styles from './TextArea.module.less';
import { PureComponent, type ChangeEvent } from 'react';

const cx = classNames.bind(styles);

export interface ITextAreaProps extends Omit<TextAreaProps, 'intent' | 'onChange'>
{
  className?:string;
  wrapperClassName?:string;
  intent?:Intent;
  leftElement?:JSX.Element;
  onChange?:(newValue:string) => void;
}

export class TextArea extends PureComponent<ITextAreaProps>
{
  private _leftElementRef = React.createRef<HTMLSpanElement>();

  @bind
  private _onChange(event:ChangeEvent<HTMLTextAreaElement>):void
  {
    const { onChange } = this.props;

    if( onChange )
      onChange(event.target.value);
  }

  private _leftElementWidth(el:HTMLSpanElement | null):number
  {
    return el?.offsetWidth || 0;
  }

  public render():ReactNode
  {
    const {
      className,
      wrapperClassName,
      intent,
      leftElement
    } = this.props;

    const bpTextAreaProps = {
      ...this.props
    };

    // a custom prop, not defined in BP's TextAreaProps
    delete bpTextAreaProps['leftElement'];

    if( leftElement )
    {
      return (
        <div className={cx('bp4-input-group', styles.wrapper, wrapperClassName)}>
          <span ref={this._leftElementRef} className={cx('bp4-input-left-container')}>
            {leftElement}
          </span>
          <BPTextArea
            {...bpTextAreaProps}
            className={className}
            style={{
              padding: '6px',
              paddingLeft: `${this._leftElementWidth(this._leftElementRef.current as HTMLSpanElement) + 2}px`
            }}
            intent={getBPIntent(intent)}
            onChange={this._onChange}
          />
        </div>
      );
    }

    return (
      <BPTextArea
        {...bpTextAreaProps}
        className={className}
        intent={getBPIntent(intent)}
        onChange={this._onChange}
      />
    );
  }
}
