import { Button, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { CommonController } from '@flow/common/CommonController';
import { SvgIcon, SvgIconName } from '@flow/common/components/elements/SvgIcon';
import { InlineEditor } from '@flow/common/components/form/InlineEditor';
import type { Common_Scheduled_Event, Common_User } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { ScheduleState } from '@flow/modules/recruiting/schedule/ScheduleState';
import classNames from 'classnames/bind';
import moment from 'moment';
import React from 'react';
import { EventAttendeesListEditor } from '../event/EventAttendeesListEditor';
import { EventDateEditor } from '../event/EventDateEditor';
import { EventEndTimeEditor } from '../event/EventEndTimeEditor';
import { EventStartTimeEditor } from '../event/EventStartTimeEditor';
import styles from './CommunicationEventView.module.less';

const cx = classNames.bind(styles);

interface Props
{
  event:Common_Scheduled_Event;
  attendees:Array<Common_User>;
  className?:string;
  showConferenceLink?:boolean;
  showTitle?:boolean;
  compact?:boolean;
}

@component
export class CommunicationEventViewContent extends React.Component<Props>
{
  @di private readonly _commonController!:CommonController;
  @di private readonly _scheduleState!:ScheduleState;

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { allUsers } = this._scheduleState;

    const {
      className,
      event,
      attendees,
      showConferenceLink,
      showTitle = true,
      compact
    } = this.props;

    attendees.sort((a1, a2) => a1.id == event.user_id ? -1 : a2.id == event.user_id ? 1 : 0);

    const summary = event?.summary || '(No title)';

    const startTime = moment(event?.start_time);
    const endTime = moment(event?.end_time);

    const startDateStr = startTime.format('MMMM DD, YYYY');
    const startTimeStr = startTime.format('h:mm A');
    const endTimeStr = String(endTime.format('h:mm A'));

    return (
      <div className={cx(styles.content, className)}>

        {
          showTitle &&
          <div className={styles.shrinkTop}>

            <InlineEditor
              readonly
              viewClassName={styles.summary}
              text={summary}
              fields={[
                {
                  name: 'title',
                  value: event.summary || '',
                  placeholder: '(No title)'
                }
              ]}
            />

          </div>
        }

        <div className={cx(styles.timeRow, styles.shrink, { compact })}>

          <div className={styles.dateSelector}>

            <EventDateEditor
              readonly
              startDateStr={startDateStr}
              scheduledEventStartTime={moment(event.start_time).toDate()}
              popoverProps={{
                usePortal: true,
                position: Position.TOP,
                modifiers: {
                  preventOverflow: {
                    enabled: false
                  }
                }
              }}
            />

          </div>

          <div className={styles.divider}>-</div>

          <div className={styles.startTimeSelector}>

            <EventStartTimeEditor
              readonly
              time={startTime.toDate()}
              timeStr={startTimeStr}
            />

          </div>

          <div className={styles.divider}>-</div>

          <div className={styles.endTimeSelector}>

            <EventEndTimeEditor
              readonly
              startTime={startTime.toDate()}
              time={endTime.toDate()}
              timeStr={endTimeStr}
            />

          </div>

        </div>

        <div className={cx(styles.participantsRow, styles.shrink, { compact })}>

          <div className={styles.participants}>

            <EventAttendeesListEditor
              readonly
              scheduledEventAttendeesList={attendees}
              candidateEmail={''}
              userId={event.user_id}
              allUsers={allUsers}
            />

          </div>

        </div>

        <div className={cx(styles.descriptionRow, styles.shrink, { compact })}>

          <div className={styles.description}>

            <InlineEditor
              readonly
              svgIcon={SvgIconName.TableDescription}
              text={event.description || ''}
              fields={[
                {
                  name: 'description',
                  value: event.description || '',
                  placeholder: '(No description)'
                }
              ]}
              textArea
            />

          </div>

        </div>

        {
          showConferenceLink && event.conference_link &&
          <div className={styles.linkRow}>
            <SvgIcon icon={SvgIconName.GoogleMeet} />
            {event.conference_link}
            <Button
              minimal
              icon={IconNames.SHARE}
              className={styles.googleMeetBtn}
              onClick={():void =>
              {
                if( event )
                  this._commonController.openInNewTab(String(event.conference_link));
              }}
            />
          </div>
        }

      </div>
    );
  }

  // ---------------------------------------------------------
}
