import { InfoPanel } from '@flow/common/components/elements/InfoPanel';
import { component } from '@flow/dependency-injection';
// import classNames from 'classnames/bind';
import React from 'react';

import styles from './CandidatePanelJobOffer.module.less';

// const cx = classNames.bind(styles);

@component
export class CandidatePanelJobOffer extends React.Component
{
  // ---------------------------------------------------------

  public render():React.ReactNode
  {

    return (
      <InfoPanel
        className={styles.panel}
      >

        TODO: JobOffer

      </InfoPanel>
    );
  }
}
