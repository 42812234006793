import { MenuItem } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { Button, ButtonSize } from '@flow/common/components/form/Button';
import { ConfirmationDialog } from '@flow/common/components/page/ConfirmationDialog';
import { Intent } from '@flow/common/components/types/Types';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import type { Recruiting_Interview_Flow } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { CandidateFlowController } from '@flow/modules/recruiting/candidates/CandidateFlowController';
import { CandidateFlowState } from '@flow/modules/recruiting/candidates/CandidateFlowState';
import { ConstraintsButton } from '@flow/modules/recruiting/candidates/components/candidate/status/constraints/ConstraintsButton';
import { PipelineSelector } from '@flow/modules/recruiting/candidates/components/candidate/status/tabInitial/PipelineSelector';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './SelectCandidatePipeline.module.less';

const cx = classNames.bind(styles);

@component
export class SelectCandidatePipeline extends React.Component
{
  @di private _candidateFlowState!:CandidateFlowState;
  @di private _candidateFlowsController!:CandidateFlowController;
  @di private _roleController!:RoleController;

  // ---------------------------------------------------------

  @bind
  private _itemRenderer(flowItem:Recruiting_Interview_Flow,
                        { handleClick, modifiers }:IItemRendererProps):JSX.Element | null
  {
    if( !modifiers.matchesPredicate )
    {
      return null;
    }

    const { candidateFlowStageId } = this._candidateFlowState;

    return (
      <MenuItem
        key={flowItem.id}
        text={flowItem.name}
        onClick={handleClick}
        selected={flowItem.id === candidateFlowStageId}
        disabled={flowItem.id == null}
      />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _onItemSelect(flow:Recruiting_Interview_Flow):void
  {
    this._candidateFlowsController.selectCandidateFlow(flow.id);
  }

  // ---------------------------------------------------------

  public render():ReactNode | null
  {
    const {
      selectedFlowId, isCandidateInInitialStatus, isShowStartPipelineDialog
    } = this._candidateFlowState;

    if( !isCandidateInInitialStatus ) return null;

    // const isDisabled = true;
    const isDisabled:boolean = !this._roleController.hasPermission(FlowPermissions.ChangeCandidateFlowStage);

    return (
      <div className={styles.wrapper}>

        <PipelineSelector />

        <ConstraintsButton />

        <Button
          className={cx(styles.btnStartPipeline, { isDisabled })}
          text={'Start pipeline'}
          disabled={isDisabled || !selectedFlowId}
          minimal={true}
          outlined={true}
          intent={isDisabled ? Intent.NONE : Intent.PRIMARY}
          size={ButtonSize.LARGE}
          onClick={():void => this._candidateFlowsController.showStartPipelineDialog(true)}
        />

        <ConfirmationDialog
          title={'Start Pipeline?'}
          confirmButtonText={'Start'}
          onConfirm={this._candidateFlowsController.startPipeline}
          isOpen={isShowStartPipelineDialog}
          onClose={():void => this._candidateFlowsController.showStartPipelineDialog(false)}
          primaryButtonIntent={Intent.PRIMARY}
        />

      </div>
    );
  }

  // ---------------------------------------------------------
}
