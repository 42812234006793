import { BaseState, state } from '@flow/dependency-injection';
import { computed, observable } from 'mobx';
import type { NavigateFunction } from 'react-router-dom';

@state
export class CommonState extends BaseState
{
  public navigateFunction:NavigateFunction | null = null;

  @observable public dialogsContainer:HTMLElement | HTMLDivElement | undefined = undefined;

  @observable public loadersCount:number = 0;

  @observable public collapseState:Map<string, Map<string | number, boolean>> = new Map<string, Map<string | number, boolean>>();

  @computed
  public get isLoading():boolean
  {
    return this.loadersCount > 0;
  }

  @observable public contentBackground:string = '';
  @observable public isContentScrollable:boolean = true;
}
