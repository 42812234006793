import { component, di } from '@flow/dependency-injection';
import { FeedbackFormController } from '@flow/modules/recruiting/feedbackForm/FeedbackFormController';
import { FeedbackFormState } from '@flow/modules/recruiting/feedbackForm/FeedbackFormState';
// import classNames from 'classnames/bind';
import React from 'react';

import styles from './FeedbackFormContent.module.less';

// const cx = classNames.bind(styles);

@component
export class FeedbackFormContent extends React.Component
{
  @di private _feedbackFormState!:FeedbackFormState;
  @di private _feedbackFormController!:FeedbackFormController;

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { isFeedbackFormLoaded, feedbackFormEvent } = this._feedbackFormState;

    if( !feedbackFormEvent?.feedback_form_ref )
    {
      return 'Invalid doc ref';
    }

    const mode = 'edit';

    // There are no offical docs on this!
    // https://webapps.stackexchange.com/questions/130654/all-google-docs-url-parameters-functions-commands
    const flags = 'rm=embedded';

    return (
      <>
        {
          isFeedbackFormLoaded &&
          <iframe
            className={styles.iframe}
            src={`https://docs.google.com/spreadsheets/d/${feedbackFormEvent?.feedback_form_ref}/${mode}?${flags}`}
            frameBorder="0"
          />
        }
      </>
    );
  }

  // ----------------------------------------------------
}
