import { PageUtil } from '@flow/common/utils/PageUtil';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di, diModule } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { PrioritiesState } from '../priorities/PrioritiesState';
import { PositionsList } from './components/PositionsList';
import { PositionsToolbar } from './components/PositionsToolbar';
import { PositionsController } from './PositionsController';
import { PositionsState } from './PositionsState';

@diModule({
  providers: [
    PositionsState,
    PositionsController,

    PrioritiesState,
  ]
})
@componentWithRouter
export class Positions extends React.Component<IWithRouterProps>
{
  @di private _positionsState!:PositionsState;
  @di private _positionsController!:PositionsController;

  // ----------------------------------------------------

  public componentDidMount():void
  {
    this._checkPageProps();
  }

  // ----------------------------------------------------

  public componentDidUpdate(prevProps:Readonly<IWithRouterProps>):void
  {
    if( prevProps.params?.positionId !== this.props.params?.positionId )
    {
      this._checkPageProps();
    }
  }

  // ----------------------------------------------------

  @bind
  private _checkPageProps():void
  {
    const { params } = this.props;
    const isPositionDescriptionPage:boolean = !!(params && params.positionId);

    if( isPositionDescriptionPage ) return;

    this._positionsController.initPositions();
  }

  // ----------------------------------------------------

  public componentWillUnmount():void
  {
    PageUtil.disposeReactions(this._positionsState.disposers);
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { params } = this.props;
    const isPositionDescriptionPage:boolean = !!(params && params.positionId);

    if( isPositionDescriptionPage )
    {
      return <Outlet />;
    }

    return (
      <>
        <PositionsToolbar />
        <PositionsList />
      </>
    );
  }
}
