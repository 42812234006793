import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { AllocationPositionSlot } from '@flow/common/components/elements/AllocationPositionSlot';
import { ButtonMore } from '@flow/common/components/elements/ButtonMore';
import { CustomerAndTeam } from '@flow/common/components/elements/CustomerAndTeam';
import { ConfirmationDialog } from '@flow/common/components/page/ConfirmationDialog';
import { Intent } from '@flow/common/components/types/Types';
import { DebugUtil } from '@flow/common/utils/DebugUtil';
import { PositionsUtil } from '@flow/common/utils/PositionsUtil';
import { SlotUtil } from '@flow/common/utils/SlotUtil';
import type { Staffing_Customer_Team_Slot } from '@flow/data-access/lib/types/graphql.generated';
import { Staffing_Staff_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { StaffMemberAllocationController } from '@flow/modules/staffing/staff/StaffMemberAllocationController';
import { StaffMemberAllocationState } from '@flow/modules/staffing/staff/StaffMemberAllocationState';
import { StaffMemberState } from '@flow/modules/staffing/staff/StaffMemberState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { runInAction } from 'mobx';
import React from 'react';

import styles from './AllocationPositions.module.less';
// import { AllocationPositionHeader } from './AllocationPositionHeader';
// import { Slot } from '@flow/modules/staffing/board/components/Slot';

const cx = classNames.bind(styles);

@component
export class AllocationPositions extends React.Component
{
  @di private _staffMemberState!:StaffMemberState;
  @di private _staffMemberAllocationState!:StaffMemberAllocationState;

  @di private _staffMemberAllocationController!:StaffMemberAllocationController;

// ---------------------------------------------------------

  @bind
  private _menuContent(slot:Staffing_Customer_Team_Slot):React.ReactElement
  {
    const { staffMember } = this._staffMemberState;

    const canSetLeavingDate:boolean = slot.staff?.id === staffMember?.id;

    const canRemoveAndEdit:boolean = this._staffMemberAllocationController.canRemoveAndEditSlot(slot, staffMember?.id);

    const isLeaving:boolean = staffMember?.status === Staffing_Staff_Status_Enum.Leaving;

    return (
      <Menu>
        {
          (!canSetLeavingDate && !canRemoveAndEdit) &&
          <MenuItem
            text={'ups...'}
          />
        }
        {
          canSetLeavingDate &&
          <MenuItem
            text={slot.leaving_date ? 'Edit leaving date' : 'Set leaving date'}
            onClick={():void =>
            {
              this._staffMemberAllocationController.setSelectedLeavingDate(slot.leaving_date);
              this._staffMemberAllocationController.setSelectedLeavingDateSlotId(slot.id);
            }}
          />
        }
        {
          canRemoveAndEdit &&
          <>
            {
              !isLeaving &&
              <>
                <MenuItem
                  text={'Edit position'}
                  onClick={():void =>
                  {
                    this._staffMemberAllocationController.showAddPositionDialog(true, slot);
                  }}
                />
                <MenuDivider />
              </>
            }
            <MenuItem
              text={'Remove position'}
              onClick={():void =>
              {
                this._staffMemberAllocationController.showRemovePositionDialog(slot);
              }}
            />
          </>
        }
      </Menu>
    );
  }

// ---------------------------------------------------------

  @bind
  private _renderSlot(slot:Staffing_Customer_Team_Slot):React.ReactNode | null
  {
    const { staffMember } = this._staffMemberState;
    const { selectedLeavingDateSlotId, selectedLeavingDate } = this._staffMemberAllocationState;

    if( !staffMember ) return null;

    if( !SlotUtil.isSlotVisible(slot) ) return null;

    const { position } = slot;

    const customerAlias:string = position.customer_team?.customer.alias;
    const teamName:string = position.customer_team?.name;
    const positionName:string = PositionsUtil.getPositionName(position);

    const isLeaving:boolean = staffMember.status === Staffing_Staff_Status_Enum.Leaving;

    return (
      <React.Fragment key={slot.id}>

        <div className={styles.customerAndTeamWrapper}>
          <CustomerAndTeam
            className={styles.customerAndTeam}
            lastTextClassName={styles.positionName}
            items={[
              `${DebugUtil.id(position.customer_team?.customer.id)}${customerAlias}`,
              `${DebugUtil.id(position.customer_team?.id)}${teamName}`,
              `${DebugUtil.id(position.id)}${positionName}`
            ]}
          />

          <ButtonMore
            className={styles.btnMore}
            menuContent={this._menuContent(slot)}
          />
        </div>

        <AllocationPositionSlot
          slot={slot}
          staffMember={staffMember}
          isLeaving={isLeaving}
          showLeavingDateInput={selectedLeavingDateSlotId === slot.id}
          selectedLeavingDate={selectedLeavingDateSlotId === slot.id ? selectedLeavingDate : null}
          onChangeLeavingDate={async (newLeavingDate:string | null):Promise<void> =>
          {
            console.log('%c newLeavingDate =', 'background:#0f0;color:#000;', newLeavingDate);
            await this._staffMemberAllocationController.updateSlotLeavingDate(newLeavingDate);

            // TODO: ?? set leaving_date OR loading icon -> then set leaving_date
            runInAction(() =>
            {
              slot.leaving_date = newLeavingDate;
            });
          }}
          onClickSetLeavingDate={():void =>
          {
            this._staffMemberAllocationController.setSelectedLeavingDate(null);
            this._staffMemberAllocationController.setSelectedLeavingDateSlotId(slot.id);
          }}
          onCloseLeavingDateInput={():void =>
          {
            // this._staffMemberAllocationController.setSelectedLeavingDate();
            this._staffMemberAllocationController.setSelectedLeavingDateSlotId(null);
          }}
        />

        {/*<div className={cx(styles.slotWrapper, { renderTwoUsersSlot, isLeaving })}>*/}
        {/*  <Slot*/}
        {/*    slot={slot}*/}
        {/*    className={styles.slot}*/}
        {/*    finalizedCandidate={candidate || undefined}*/}
        {/*    renderFirstStaffMemberWithId={staffId}*/}
        {/*  />*/}
        {/*</div>*/}

      </React.Fragment>
    );
  }

// ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { staffMember, staffMemberSlots, isStaffMemberLoaded } = this._staffMemberState;
    const { isRemovePositionDialogOpen } = this._staffMemberAllocationState;

    if( !isStaffMemberLoaded || !staffMember ) return null;

    return (
      <div className={styles.wrapper}>

        <div className={styles.scrolled}>
          {staffMemberSlots?.map(this._renderSlot)}
        </div>

        <ConfirmationDialog
          confirmButtonText={'Remove'}
          onConfirm={this._staffMemberAllocationController.removePosition}

          title={'Remove position?'}
          isOpen={isRemovePositionDialogOpen}
          primaryButtonIntent={Intent.PRIMARY}
          onClose={this._staffMemberAllocationController.hideRemovePositionDialog}
        />

      </div>
    );
  }
}
