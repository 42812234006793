export enum RouteName
{
  ROOT = 'ROOT',

  LOGIN = 'LOGIN',

  HOME = 'HOME',

  CUSTOMERS = 'CUSTOMERS',
  CUSTOMERS_HOME = 'CUSTOMERS_HOME',
  CUSTOMERS_TEAMS = 'CUSTOMERS_TEAMS',
  CUSTOMERS_CUSTOMER = 'CUSTOMERS_CUSTOMER',
  CUSTOMERS_CUSTOMER_TEAM_POSITIONS = 'CUSTOMERS_CUSTOMER_TEAM_POSITIONS',
  CUSTOMERS_CUSTOMER_TEAM_POSITION = 'CUSTOMERS_CUSTOMER_TEAM_POSITION',

  RECRUITING = 'RECRUITING',
  RECRUITING_HOME = 'RECRUITING_HOME',
  RECRUITING_BOARDS = 'RECRUITING_BOARDS',
  RECRUITING_BOARD = 'RECRUITING_BOARD',
  RECRUITING_CANDIDATES = 'RECRUITING_CANDIDATES',
  RECRUITING_CANDIDATE = 'RECRUITING_CANDIDATE',
  RECRUITING_CANDIDATE_SCHEDULE_EVENT = 'RECRUITING_CANDIDATE_SCHEDULE_EVENT',
  RECRUITING_CANDIDATE_SCHEDULE_EVENT_ID = 'RECRUITING_CANDIDATE_SCHEDULE_EVENT_ID',
  RECRUITING_CANDIDATE_FEEDBACK = 'RECRUITING_CANDIDATE_FEEDBACK',
  RECRUITING_CANDIDATE_FEEDBACK_EVENT_ID = 'RECRUITING_CANDIDATE_FEEDBACK_EVENT_ID',
  RECRUITING_PRIORITIES = 'RECRUITING_PRIORITIES',
  RECRUITING_POSITIONS = 'RECRUITING_POSITIONS',
  RECRUITING_POSITIONS_POSITION = 'RECRUITING_POSITIONS_POSITION',

  SCHEDULE_ROOT = 'SCHEDULE_ROOT',
  SCHEDULE = 'SCHEDULE',
  FEEDBACK_FORM = 'FEEDBACK_FORM',

  STAFFING = 'STAFFING',
  STAFFING_STAFF = 'STAFFING_STAFF',
  STAFFING_STAFF_MEMBER = 'STAFFING_STAFF_MEMBER',
  STAFFING_BOARD = 'STAFFING_BOARD',
  STAFFING_BOARD_CUSTOMER = 'STAFFING_BOARD_CUSTOMER',

  HISTORY = 'HISTORY', // TODO: delete after tests

  SETTINGS = 'SETTINGS',
  SETTINGS_POSITION_GROUPS = 'SETTINGS_POSITION_GROUPS',
  SETTINGS_POSITION_TEMPLATES = 'SETTINGS_POSITION_TEMPLATES',
  SETTINGS_PIPELINES = 'SETTINGS_PIPELINES',
  SETTINGS_USERS = 'SETTINGS_USERS',
  SETTINGS_PERMISSIONS = 'SETTINGS_PERMISSIONS',
  SETTINGS_INTERVIEWERS = 'SETTINGS_INTERVIEWERS',

  DEMO_SCHEDULE = 'DEMO_SCHEDULE',
}
