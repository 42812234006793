import type { TabId } from '@blueprintjs/core';
import type { IInlineEditorMultiSelectValue } from '@flow/common/components/form/InlineEditor';
import type { HistoryItem } from '@flow/common/controllers/ObjectHistoryController';
import { DateStrOrNum, DateUtil } from '@flow/common/utils/DateUtil';
import { StringUtil } from '@flow/common/utils/StringUtil';
import type {
  Common_Scheduled_Event,
  Common_User,
  GoogleCalendarEvent,
  Recruiting_Allocation_Position,
  Recruiting_Candidate,
  Recruiting_Candidate_Note,
  Recruiting_Interview_Flow_Stage,
  Recruiting_Position,
  Recruiting_Position_Group,
  Staffing_Staff,
  Staffing_Staff_Role
} from '@flow/data-access/lib/types/graphql.generated';
import {
  Common_Attachment_Type_Enum,
  Recruiting_Candidate_Status_Enum
} from '@flow/data-access/lib/types/graphql.generated';
import { BaseState, state } from '@flow/dependency-injection';
import { computed, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

export enum CandidateTabName
{
  COMMUNICATION = 'COMMUNICATION',
  EXEC_SUMMARY = 'EXEC_SUMMARY',
  JOB_OFFER = 'JOB_OFFER',
  INFO = 'INFO',
  ATTACHMENTS = 'ATTACHMENTS',
  CALLS = 'CALLS',
  TIMELINE = 'TIMELINE',
  HISTORY = 'HISTORY',
  NOTES = 'NOTES',
}

export enum AdditionalFields
{
  EXPERIENCE_IN_YEARS = 'Experience in years',
  CURRENT_EMPLOYER = 'Current Employer',
  SKYPE_ID = 'Skype ID',
  SOURCE = 'Source',
  SECONDARY_EMAIL = 'Secondary email'
}

export interface InlineEditorListType
{
  key?:string;
  isEditMode?:boolean;
}

export const CandidateEventsCollapseListId = 'candidateEvents';

@state
export class CandidateState extends BaseState
{
  // ----------------------------------------------------

  @observable public candidate:Recruiting_Candidate | null = null;
  @observable public assignedToUsers:Array<Common_User> = [];
  @observable public candidateNotes:Array<Recruiting_Candidate_Note> = [];

  @observable public cvUploadInProgress:boolean = false;

  @observable public pageNotFound:boolean = false;
  @observable public isCandidateLoaded:boolean = false;
  @observable public isCandidateAllocationPositionsLoaded:boolean = false;

  @observable public candidateHistory:Array<HistoryItem> = [];
  @observable public hasMoreHistory:boolean = true;

  @observable public historicalPositionsByAllocation:Array<Recruiting_Allocation_Position> = [];
  @observable public historicalAssignees:Array<Common_User> = [];
  @observable public historicalPositionGroups:Array<Recruiting_Position_Group> = [];
  @observable public historicalInterviewFlowStages:Array<Recruiting_Interview_Flow_Stage> = [];
  @observable public historicalNotes:Array<Recruiting_Candidate_Note> = [];
  @observable public historicalEvents:Array<Common_Scheduled_Event> = [];
  @observable public historicalPositions:Array<Recruiting_Position> = [];

  @observable public isHistoryLoaded:boolean = false;

  // ----------------------------------------------------

  @observable public isAddingNote:boolean = false;

  // ----------------------------------------------------

  @observable public isInitDraftEvent:boolean = false;
  @observable public isDraftEventOpen:boolean = false;
  @observable public isSchedulingEvent:boolean = false;

  @observable public isDeleteCallDialogOpen:boolean = false;
  @observable public deletingEventId:number | null = null;
  @observable public isDeletingCall:boolean = false;

  // ----------------------------------------------------

  @observable public tabId:TabId = CandidateTabName.COMMUNICATION;
  @observable public rightTabId:TabId = CandidateTabName.INFO;
  @observable public communicationTabId:TabId = CandidateTabName.HISTORY;

  // ----------------------------------------------------

  @observable public allStaffRoles:Array<Staffing_Staff_Role> = [];

  @observable public isCopyToStaffDialogOpen:boolean = false;
  @observable public isCopyToStaffDialogLoading:boolean = false;
  @observable public newStaffEmail:string = '';
  @observable public isCheckingEmailInProgress:boolean = false;
  @observable public doesUserEmailExist:boolean = false;
  @observable public existingStaff:Staffing_Staff | null = null;
  @observable public isStaffCreationInProgress:boolean = false;

  @observable public newStaffHireDate:Date | null = null;
  @observable public newStaffRole:Staffing_Staff_Role | null = null;

  @observable public isAddAttachmentDialogOpen:boolean = false;
  @observable public isUploadInProgress:boolean = false;
  @observable public isAttachmentDialogInEditMode:boolean = false;
  @observable public renameAttachmentId:number | null = null;

  @observable public isDeleteAttachmentDialogOpen:boolean = false;
  @observable public deletingAttachmentId:number | null = null;

  @observable public uploadFileName:string | null = null;
  @observable public uploadFileType:Common_Attachment_Type_Enum | null = Common_Attachment_Type_Enum.Other;

  @observable public fileToUpload:File | null = null;

  @observable public uploadProgressPercent:number = 0;
  @observable public isExtended:boolean = false;
  @observable public inlineEditorList:Array<InlineEditorListType> = [];

  // ----------------------------------------------------

  @observable public eventResponses:Map<string, GoogleCalendarEvent> = new Map();

  @computed
  public get assignedToValuesForSelect():Array<IInlineEditorMultiSelectValue>
  {
    return this.assignedToUsers?.map((user:Common_User) =>
    {
      const assignedToName:string = this.userNameByUserId(user.id);

      return { title: assignedToName, value: user.id };
    }) || [];
  }

  @computed
  public get moreInfoSelectFields():Array<IInlineEditorMultiSelectValue>
  {
    const fields = {
      experience_in_years: {
        value: this.candidate?.experience_in_years,
        field_title: AdditionalFields.EXPERIENCE_IN_YEARS
      },
      current_employer: { value: this.candidate?.current_employer, field_title: AdditionalFields.CURRENT_EMPLOYER },
      instant_messenger_id: { value: this.candidate?.instant_messenger_id, field_title: AdditionalFields.SKYPE_ID },
      source: { value: this.candidate?.source, field_title: AdditionalFields.SOURCE },
      secondary_email: { value: this.candidate?.secondary_email, field_title: AdditionalFields.SECONDARY_EMAIL }
    };

    const moreInfoEmptySelectFields:Array<IInlineEditorMultiSelectValue> = new Array<IInlineEditorMultiSelectValue>();
    for( const [key, value] of Object.entries(fields) )
    {
      if( value.value == null || value.value === '' && !this.inlineEditorList.some(e => e.key == key) )
      {
        moreInfoEmptySelectFields.push({ title: value.field_title, value: key });
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return moreInfoEmptySelectFields;
  }

  // ----------------------------------------------------

  public userNameByUserId:(userId:number | null | undefined) => string =
    computedFn((userId:number | null | undefined):string =>
    {
      if( !userId ) return '';

      const user = this.assignedToUsers?.find((user:Common_User) => user.id === userId);

      return StringUtil.getUserName(user);
    });

  // ----------------------------------------------------

  @computed
  public get candidateName():string
  {
    const { first_name, last_name } = this.candidate ?? {};

    return first_name || last_name ? `${first_name}${first_name ? ' ' : ''}${last_name}` : '';
  }

  // ----------------------------------------------------

  @computed
  public get isCandidateOnFlow():boolean
  {
    return this.candidate?.status === Recruiting_Candidate_Status_Enum.Active;
  }

  // ----------------------------------------------------

  @computed
  public get callsToRender():Array<Common_Scheduled_Event>
  {
    if( !this.candidate ) return [];

    return this.candidate.scheduled_calls
      .filter(call =>
      {
        return call.interview_flow_stage_id == this.candidate?.interview_flow_stage_id
          && !call.scheduled_call.deleted_at
          && (call.scheduled_call.is_scheduled || call.scheduled_call.is_done);
      })
      .map(upcomingCall => upcomingCall.scheduled_call)
      .sort((call1, call2) =>
      {
        return DateUtil.compareDates(call2.start_time as DateStrOrNum, call1.start_time as DateStrOrNum);
      }) || [];
  }

  // ----------------------------------------------------
}
