import { Collapse } from '@blueprintjs/core';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactElement } from 'react';
import { Component, ReactNode } from 'react';

import styles from './CollapseList.module.less';

const cx = classNames.bind(styles);

export interface IListItem
{
  id:string | number;
}

export interface ICollapseListProps<Item extends IListItem, SubItem extends IListItem | undefined = undefined>
{
  id:string;
  className?:string;

  items:Array<Item>;
  itemClassName?:string;
  itemRenderer:(item:Item, index:number, items:Array<Item>, isCollapsed?:boolean) => ReactNode | null;

  isCanCollapseSelected?:boolean;
  selectedItemIds?:Array<number>;
  selectedItemClassName?:string;

  subItemsClassName?:string;
  subItemsProp?:string;
  subItemsHeader?:ReactElement;
  subItemRenderer?:(subItem:SubItem, index:number, subItems:Array<SubItem>) => ReactNode | null;

  customSubItemsRenderer?:(item:Item, index:number, items:Array<Item>) => ReactNode | null;

  emptyListElement?:ReactElement;
  emptySubListRenderer?:(item:Item, itemIndex:number) => ReactNode;

  withChevron?:boolean;
  chevronClassName?:string;
  selectedChevronClassName?:string;
  selectedChevronIconClassName?:string;

  wrapperClassName?:string;
  itemWrapperClassName?:string;

  defaultCollapsed?:boolean;
}

@component
export class CollapseList<Item extends IListItem, SubItem extends IListItem | undefined = undefined> extends Component<ICollapseListProps<Item, SubItem>>
{
  @di private _commonState!:CommonState;
  @di private _commonController!:CommonController;

  @bind
  private _collapseItem(itemId:string | number):void
  {
    const { collapseState } = this._commonState;
    const { id, defaultCollapsed } = this.props;

    const collapsedItemState = collapseState?.get(id)?.get(itemId);

    const isCollapsed:boolean = typeof collapsedItemState != 'undefined' ? collapsedItemState : !!defaultCollapsed;

    this._commonController.toggleCollapseItem(id, itemId, !isCollapsed);
  }

  @bind
  private _renderItem(item:Item, index:number, items:Array<Item>):ReactNode
  {
    const { collapseState } = this._commonState;
    const {
      id,
      subItemsProp, subItemsClassName, subItemsHeader,
      itemRenderer, itemClassName,
      selectedItemIds, selectedItemClassName, isCanCollapseSelected = true,
      customSubItemsRenderer,
      emptySubListRenderer,
      withChevron = true,
      chevronClassName, selectedChevronClassName, selectedChevronIconClassName,
      itemWrapperClassName,
      defaultCollapsed
    } = this.props;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const subItems:Array<SubItem> = subItemsProp ? (item as any)[subItemsProp] : [];
    const collapsedItemState = collapseState?.get(id)?.get(item.id);
    const isCollapsed:boolean = typeof collapsedItemState != 'undefined' ? collapsedItemState : !!defaultCollapsed;

    const isNextCollapsed:boolean = index < items.length - 1
      ? collapseState?.get(id)?.get(items[index + 1].id) || false
      : false;

    const isPrevCollapsed:boolean = index > 0
      ? collapseState?.get(id)?.get(items[index - 1].id) || false
      : true;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
    const isSelected:boolean = selectedItemIds?.includes((item as any).id) || false;

    return (
      <div
        key={item.id}
        className={cx(styles.itemWrapper, { isCollapsed }, itemWrapperClassName)}
      >
        <div
          className={cx(styles.item, itemClassName, {
            isCollapsed, isNextCollapsed, isPrevCollapsed,
            [selectedItemClassName ?? 'isSelected']: isSelected
          })}
          onClick={():void =>
          {
            if( isSelected && !isCanCollapseSelected )
            {
              return;
            }
            this._collapseItem(item.id);
          }}
        >
          {
            withChevron &&
            <Button
              className={cx(styles.btnDown, chevronClassName, { [selectedChevronClassName ?? 'isSelected']: isSelected })}
              icon={isCollapsed ? IconNames.CHEVRON_RIGHT : IconNames.CHEVRON_DOWN}
              iconClassName={cx(styles.btnDownIcon, { [selectedChevronIconClassName ?? 'isSelected']: isSelected })}
              minimal={true}
            />
          }
          <div className={styles.itemContent}>
            {itemRenderer(item, index, items, isCollapsed)}
          </div>
        </div>

        <Collapse isOpen={!isCollapsed}>
          {customSubItemsRenderer?.(item, index, items)}
          {
            !customSubItemsRenderer && subItems?.length !== 0 &&
            <>
              <div className={cx(styles.subItemsHeader)}>
                {subItemsHeader}
              </div>
              <div className={cx(styles.subItems, subItemsClassName)}>
                {subItems.map(this._renderSubItem)}
              </div>
            </>
          }
          {
            !customSubItemsRenderer && subItems?.length === 0 &&
            <div className={cx(styles.noItems, { isCollapsed })}>
              {emptySubListRenderer?.(item, index)}
            </div>
          }
        </Collapse>
      </div>
    );
  }

  @bind
  private _renderSubItem(subItem:SubItem, index:number, subItems:Array<SubItem>):ReactNode | null
  {
    const { subItemRenderer } = this.props;

    if( !subItem || !subItemRenderer ) return null;

    return (
      <div
        key={subItem.id}
        // key={`${index}:${subItem.id}`} // !!!
        className={styles.subItem}
      >
        {subItemRenderer?.(subItem, index, subItems)}
      </div>
    );
  }

  public render():ReactNode
  {
    const { className, items, emptyListElement, wrapperClassName } = this.props;

    return (
      <div className={cx(styles.collapseList, className, wrapperClassName)}>
        {
          items && items.length
            ? items.map(this._renderItem)
            : (emptyListElement || null)
        }
      </div>
    );
  }
}
