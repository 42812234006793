import { MenuItem } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { Suggest } from '@blueprintjs/select';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import type { Common_Filter } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { toJS } from 'mobx';
import type { ReactNode } from 'react';
import React from 'react';
import { CandidatesController } from '../../../CandidatesController';
import { CandidatesState, SaveFilterMode } from '../../../CandidatesState';

import styles from './CandidatesSavedFiltersSuggest.module.less';

const cx = classNames.bind(styles);

@component
export class CandidatesSavedFiltersSuggest extends React.Component
{
  @di private _candidatesState!:CandidatesState;
  @di private _candidatesController!:CandidatesController;

  // ----------------------------------------------------

  @bind
  private _renderInputValue(/* item:Common_Filter */):string
  {
    const { userFilterById, overwriteFilterId } = this._candidatesState;

    const selectedFilter:Common_Filter | undefined = userFilterById(overwriteFilterId);

    return selectedFilter?.name || '';
  }

  // ----------------------------------------------------

  @bind
  private _itemRenderer(item:Common_Filter, {
    modifiers,
    handleClick,
    query
  }:IItemRendererProps):JSX.Element | null
  {
    if( !modifiers.matchesPredicate )
    {
      return null;
    }

    const { name } = item;
    const nameEl:ReactNode = this._highlightText(name, query);

    const { selectedFilterId } = this._candidatesState;
    const isSelected:boolean = !!(selectedFilterId && item.id === selectedFilterId);

    return (
      <MenuItem
        key={name}
        text={nameEl}
        active={modifiers.active}
        onClick={handleClick}
        // shouldDismissPopover={true} // ???

        // right icon
        labelElement={<Icon icon={isSelected ? IconNames.TICK : null} />}
      />
    );
  }

  // ----------------------------------------------------

  @bind
  private _onItemSelect(item:Common_Filter):void
  {
    console.log('%c _onItemSelect item =', 'background:#0f0;color:#000;', toJS(item));

    this._candidatesController.selectOverwriteFilter(item.id);
  };

  // ----------------------------------------------------

  @bind
  private _onPopoverOpening():void
  {
    this._candidatesController.setSaveFilterMode(SaveFilterMode.OVERWRITE);
  }

  // ----------------------------------------------------

  private _itemPredicate(query:string, item:Common_Filter/*, _index:any, exactMatch:any*/):boolean
  {
    const { name } = item;

    const searchIn:string = name.toLowerCase();

    const words:Array<string> = query.toLowerCase().split(' ');

    return words.every((word:string) => searchIn.indexOf(word) !== -1);
  };

  // ----------------------------------------------------

  private _escapeRegExpChars(text:string):string
  {
    return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
  }

  private _highlightText(text:string, query:string):Array<React.ReactNode>
  {
    let lastIndex = 0;
    const words = query
      .split(/\s+/)
      .filter(word => word.length > 0)
      .map(this._escapeRegExpChars);

    if( words.length === 0 )
    {
      return [text];
    }

    const regexp = new RegExp(words.join('|'), 'gi');
    const tokens:Array<React.ReactNode> = [];

    // eslint-disable-next-line no-constant-condition
    while( true )
    {
      const match = regexp.exec(text);
      if( !match )
      {
        break;
      }
      const length = match[0].length;
      const before = text.slice(lastIndex, regexp.lastIndex - length);

      if( before.length > 0 )
      {
        tokens.push(before);
      }
      lastIndex = regexp.lastIndex;
      tokens.push(<strong className={styles.token} key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if( rest.length > 0 )
    {
      tokens.push(rest);
    }
    return tokens;
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const SearchInput = Suggest.ofType<Common_Filter>();

    const { userFilters, userFilterById, overwriteFilterId } = this._candidatesState;

    const selectedFilter:Common_Filter | undefined = userFilterById(overwriteFilterId);

    return (
      <SearchInput
        className={cx(styles.searchInput)}
        inputValueRenderer={this._renderInputValue}
        items={userFilters}
        itemRenderer={this._itemRenderer}
        onItemSelect={this._onItemSelect}

        // query={selectedFilter?.name || ''}
        resetOnQuery={true}
        resetOnClose={true}
        resetOnSelect={true}

        itemPredicate={this._itemPredicate}

        noResults={<MenuItem disabled={true} text="No results." />}

        popoverProps={{
          onOpening: this._onPopoverOpening,
          usePortal: false,
          minimal: true
        }}

        inputProps={{
          placeholder: selectedFilter ? selectedFilter?.name || '' : 'No filter selected',
          large: true,
          fill: true,
          onKeyDown: (e):void =>
          {
            if( e.key == 'Enter' ) e.preventDefault();
          }
        }}
      />
    );
  }
}
