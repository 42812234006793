import { CommonController } from '@flow/common/CommonController';
import { RouteName } from '@flow/common/models/routing/RouteName';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di, diModule } from '@flow/dependency-injection';
import React from 'react';
import { Outlet } from 'react-router-dom';
import type { IMatchedRouteRoute } from '../../pages/RoutesConfig';
import { RoutesConfig } from '../../pages/RoutesConfig';
import { CustomerController } from './teams/CustomerController';
import { CustomersController } from './teams/CustomersController';
import { CustomersState } from './teams/CustomersState';
import { CustomerState } from './teams/CustomerState';

@diModule({
  providers: [
    CustomerState,
    CustomerController,
    CustomersState,
    CustomersController
  ]
})
@componentWithRouter
export class Customers extends React.Component<IWithRouterProps>
{
  @di private _commonController!:CommonController;

  public componentWillUnmount():void
  {
    this._commonController.setContentBackground('');
  }

  public componentDidMount():void
  {
    const { params } = this.props;

    console.log('%c -> ROOT params      =', 'background:#0ff;color:#000;', params);

    const routeConfig:IMatchedRouteRoute | null = RoutesConfig.getRouteConfig(RouteName.CUSTOMERS);

    console.log('%c -> ROOT RouteConfig =', 'background:#0ff;color:#000;', routeConfig);

    if( params && routeConfig && params['*'] )
    {
      const paramsPath:string = (params?.['*'] || '').replace(/\/$/, '');
      const routeConfigPath:string = routeConfig.path.replace(/\/$/, '');

      if( paramsPath !== routeConfigPath ) return;

      const redirectTo:RouteName | undefined = routeConfig.redirect;

      if( !redirectTo ) return;

      const path:string | null = RoutesConfig.getRoutePath(redirectTo);

      if( path ) this._commonController.navigate(path);
    }
  }

  public render():React.ReactNode
  {
    return (
      <Outlet />
    );
  }
}
