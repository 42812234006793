import { Button, ButtonSize } from '@flow/common/components/form/Button';
import { TextInput } from '@flow/common/components/form/TextInput';
import { Dialog } from '@flow/common/components/page/Dialog';
import { DialogRow } from '@flow/common/components/page/DialogRow';
import { Intent } from '@flow/common/components/types/Types';
import { component, di } from '@flow/dependency-injection';
import { PositionController } from '@flow/modules/recruiting/position/PositionController';
import { PositionState } from '@flow/modules/recruiting/position/PositionState';
// import classNames from 'classnames/bind';
import React from 'react';

import styles from './PositionRenameDialog.module.less';

// const cx = classNames.bind(styles);

@component
export class PositionRenameDialog extends React.Component
{
  @di private _positionState!:PositionState;
  @di private _positionController!:PositionController;

  public render():React.ReactNode
  {
    const { showRenamePositionDialog, position } = this._positionState;

    if( !showRenamePositionDialog || !position ) return null;

    const { position_template } = position;

    const {
      inputPositionNameValue
    } = this._positionState;

    const {
      hideRenamePositionDialog,
      setInputPositionNameValue,
      savePositionName
    } = this._positionController;

    return (
      <Dialog
        title={'Rename Position Name'}
        isOpen={showRenamePositionDialog}
        onClose={hideRenamePositionDialog}
        className={styles.dialogWrapper}

        buttonsSize={ButtonSize.LARGE}

        primaryButtonText={'Save'}
        primaryButtonOnClick={savePositionName}
        // isPrimaryButtonDisabled={}

        secondaryButtonText={'Cancel'}
        secondaryButtonIntent={Intent.PRIMARY}
        isSecondaryButtonOutlined={true}
      >
        <DialogRow label={'Name'}>
          <div className={styles.resetWrapper}>
            <div className={styles.positionName}>
              {position_template?.name}
            </div>
            <Button
              className={styles.btnReset}
              text={'Reset to default'}
              intent={Intent.PRIMARY}
              minimal={true}
              disabled={inputPositionNameValue.trim() === ''}
              onClick={():void => this._positionController.setInputPositionNameValue('')}
            />
          </div>
        </DialogRow>

        <DialogRow label={'Alias'}>
          <TextInput
            className={styles.inputAlias}
            value={inputPositionNameValue}
            onChange={setInputPositionNameValue}
            autoFocus={true}
            large={true}
            placeholder={'Enter position alias...'}
          />
        </DialogRow>

      </Dialog>
    );
  }
}
