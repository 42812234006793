import { MenuItem } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { Alignment } from '@flow/common/components/types/Types';
import type { Recruiting_Interview_Flow } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';
import { InterviewFlowsController } from '../../InterviewFlowsController';
import { InterviewFlowsState } from '../../InterviewFlowsState';

import styles from './ToolbarSelect.module.less';

const cx = classNames.bind(styles);

@component
export class SelectFlow extends React.Component
{
  @di private _interviewFlowsState!:InterviewFlowsState;
  @di private _interviewFlowsController!:InterviewFlowsController;

  @bind
  private _itemRenderer(flowItem:Recruiting_Interview_Flow,
                        { handleClick, modifiers }:IItemRendererProps):JSX.Element | null
  {
    if( !modifiers.matchesPredicate )
    {
      return null;
    }

    const { selectedInterviewFlowId } = this._interviewFlowsState;

    return (
      <MenuItem
        key={flowItem.id}
        text={flowItem.name}
        onClick={handleClick}
        active={modifiers.active}
        // selected={flowItem.id === selectedInterviewFlowId}
      />
    );
  }

  @bind
  private _onItemSelect(flow:Recruiting_Interview_Flow):void
  {
    this._interviewFlowsController.selectInterviewFlow(flow.id);
  }

  public render():ReactNode
  {
    const { interviewFlows, selectedInterviewFlowId } = this._interviewFlowsState;
    const flow:Recruiting_Interview_Flow | undefined = interviewFlows?.find(
      (flow:Recruiting_Interview_Flow) => flow.id === selectedInterviewFlowId
    );

    return (
      <Select
        className={styles.selectFlow}
        items={interviewFlows}
        itemRenderer={this._itemRenderer}
        onItemSelect={this._onItemSelect}
        filterable={false}
        popoverProps={{
          minimal: true,
          usePortal: false
        }}
      >
        <Button
          className={styles.selectFlowBtn}
          text={flow?.name}
          rightIcon={IconNames.CARET_DOWN}
          minimal={true}
          alignText={Alignment.LEFT}
        />
      </Select>
    );
  }
}
