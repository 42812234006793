import type { TabId } from '@blueprintjs/core';
import type { Maybe } from '@flow/common/models/Types';
import { Staffing_Staff_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import type {
  Recruiting_Candidate_Position_Group,
  Staffing_Customer_Team_Slot,
  Staffing_Staff
} from '@flow/data-access/lib/types/graphql.generated';
import { di, state } from '@flow/dependency-injection';
import { StaffSiteSubsiteState } from '@flow/modules/staffing/staff/StaffSiteSubsiteState';
import { computed, observable } from 'mobx';

// ----------------------------------------------------

export enum StaffMemberTabName
{
  INFO = 'INFO',
  ATTACHMENTS = 'ATTACHMENTS',

  LIFECYCLE = 'LIFECYCLE',
  SALARY = 'SALARY',
}

// ----------------------------------------------------

export const StaffStatusTitle:Record<string, string> = {
  [Staffing_Staff_Status_Enum.Newcomer]: 'Newcomer',
  [Staffing_Staff_Status_Enum.Staffed]: 'Staff',
  [Staffing_Staff_Status_Enum.Leaving]: 'Leaving company',
  [Staffing_Staff_Status_Enum.Archived]: 'Archived'
};

// ----------------------------------------------------

@state
export class StaffMemberState
{
  @di private _staffSiteSubsiteState!:StaffSiteSubsiteState;

  // ----------------------------------------------------

  @observable public staffMember:Staffing_Staff | null = null;

  @observable public staffMemberSlots:Array<Staffing_Customer_Team_Slot> | null = null;

  @observable public pageNotFound:boolean = false;
  @observable public isStaffMemberLoaded:boolean = false;

  // ----------------------------------------------------

  @observable public tabId:TabId = StaffMemberTabName.INFO;

  @observable public isAddingNote:boolean = false;

  // ----------------------------------------------------

  @observable public isShowMoveToStaffDialog:boolean = false;

  @observable public isShowChangeStatusDialog:boolean = false;

  @observable public newStaffStatus:Maybe<Staffing_Staff_Status_Enum> = null;
  @observable public newLeavingDate:Maybe<string> = '';

  // ----------------------------------------------------

  @computed
  public get staffMemberName():string
  {
    if( !this.staffMember ) return '';

    const { user } = this.staffMember;

    return `${user.first_name} ${user.last_name}`;
  }

  // ----------------------------------------------------

  @computed
  public get staffMemberSlotsIds():Array<number>
  {
    if( !this.staffMemberSlots ) return [];

    return this.staffMemberSlots
      .map((slot:Staffing_Customer_Team_Slot) => slot.id);
  }

  // ----------------------------------------------------

  @computed
  public get staffMemberPositionIds():Array<number>
  {
    if( !this.staffMemberSlots ) return [];

    return this.staffMemberSlots
      .map((slot:Staffing_Customer_Team_Slot) => slot.position.id);
  }

  // ----------------------------------------------------

  @computed
  public get staffMemberPositionGroupNames():Array<string>
  {
    // if( !this.staffMember?.candidate || !this.staffMember?.candidate.candidate_position_groups ) return [];

    return this.staffMember?.candidate?.candidate_position_groups.map((cpg:Recruiting_Candidate_Position_Group) =>
    {
      return cpg.position_group.name;
    }) || [];
  }

  @computed
  public get staffMemberPositionGroupIds():Array<number>
  {
    return this.staffMember?.candidate?.candidate_position_groups.map((cpg:Recruiting_Candidate_Position_Group) =>
    {
      return cpg.position_group.id;
    }) || [];
  }

  // ----------------------------------------------------

  @computed
  public get isNewcomer():boolean
  {
    return this.staffMember?.status === Staffing_Staff_Status_Enum.Newcomer;
  }

  @computed
  public get isStaffed():boolean
  {
    return this.staffMember?.status === Staffing_Staff_Status_Enum.Staffed;
  }

  @computed
  public get isLeaving():boolean
  {
    return this.staffMember?.status === Staffing_Staff_Status_Enum.Leaving;
  }

  @computed
  public get isArchived():boolean
  {
    return this.staffMember?.status === Staffing_Staff_Status_Enum.Archived;
  }

  // ----------------------------------------------------
}
