import { component, di } from '@flow/dependency-injection';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';
import Highlighter from 'react-highlight-words';
import styles from './PositionDialogItem.module.less';
import positionItemStyles from '../../../../../common/components/elements/PositionItem.module.less';
import { NewPositionController } from '../../NewPositionController';

const cx = classNames.bind(styles);
const cxPositionItemStyles = classNames.bind(positionItemStyles);

export interface PositionDialogItemProps
{
  id:number;
  jobTitle:string;
  search:string;
  isNextSelected:boolean;
  isSelected:boolean;
  onClick?:() => void;
}

@component
export class PositionDialogItem extends React.Component<PositionDialogItemProps>
{
  @di private _positionController!:NewPositionController;

  public render():ReactNode
  {
    const {
      jobTitle
    } = this.props;

    return (
      <div
        className={cx(styles.positionDialogItemWrapper,
          this.props.isSelected ? styles.isSelected : '',
          this.props.isNextSelected ? styles.isNextSelected : '')}
        onClick={():void =>
        {
          this._positionController.updateClickedPositionDialogItemId(this.props.id);
          this.props.onClick?.();
        }}
      >
        <div
          className={cxPositionItemStyles(positionItemStyles.iconRadio,
            this.props.isSelected ? positionItemStyles.isSelected : '',
            positionItemStyles.iconRadioForPositionDialogItem/*{ isSelected: ch }*/)}
        />
        {this.props.search ? <span className={cx(styles.roleTitle)}><Highlighter
          highlightClassName={styles.highlight}
          searchWords={this.props.search.split(' ').map(item => item.trim())}
          autoEscape={true}
          textToHighlight={jobTitle}
        /> </span> : <span className={cx(styles.roleTitle)}>{jobTitle}</span>}
      </div>
    );
  }
}
