import { Radio, RadioGroup } from '@blueprintjs/core';
import { TextArea } from '@flow/common/components/form/TextArea';
import { ConfirmationDialog } from '@flow/common/components/page/ConfirmationDialog';
import { DialogRow } from '@flow/common/components/page/DialogRow';
import { DialogWarningMessage } from '@flow/common/components/page/DialogWarningMessage';
import { Intent } from '@flow/common/components/types/Types';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { Recruiting_Candidate_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { CandidateFlowController } from '@flow/modules/recruiting/candidates/CandidateFlowController';
import { CandidateFlowState } from '@flow/modules/recruiting/candidates/CandidateFlowState';
import { CandidateState } from '@flow/modules/recruiting/candidates/CandidateState';
import { ArchiveReasonSelector } from '@flow/modules/recruiting/candidates/components/candidate/status/tabInWork/ArchiveReasonSelector';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './DialogArchiveCandidate.module.less';

const cx = classNames.bind(styles);

@component
export class DialogArchiveCandidate extends React.Component
{
  @di private _candidateState!:CandidateState;
  @di private _candidateFlowState!:CandidateFlowState;

  @di private _roleController!:RoleController;
  @di private _candidateFlowsController!:CandidateFlowController;

  // ---------------------------------------------------------

  // ---------------------------------------------------------

  public render():ReactNode | null
  {
    const { candidate } = this._candidateState;
    const {
      isShowArchiveDialog, isBtnArchiveDisabled,
      selectedArchiveStatus, selectedArchiveNotes
    } = this._candidateFlowState;

    const isDisabled:boolean = !this._roleController.hasPermission(FlowPermissions.ChangeCandidateFlowStage);

    if( isDisabled || !candidate ) return null;

    const hasFinalPosition:boolean = candidate.customer_team_slots.length > 0;
    const hasProposedPositions:boolean = candidate.candidate_allocations.length > (hasFinalPosition ? 1 : 0);
    const hasCalls:boolean = candidate.scheduled_calls.length > 0;

    let warningText:string = hasFinalPosition && hasProposedPositions
      ? 'Proposed and finalized positions'
      : hasProposedPositions
        ? 'Proposed positions'
        : hasFinalPosition
          ? 'Finalized position'
          : '';

    hasCalls && (warningText += warningText ? ' and calls' : 'Calls');
    warningText && (warningText += ' will be removed');

    return (
      <ConfirmationDialog
        title={'Archive candidate?'}
        confirmButtonText={'Archive'}
        onConfirm={this._candidateFlowsController.archiveCandidate}
        isOpen={isShowArchiveDialog}
        onClose={():void => this._candidateFlowsController.showArchiveDialog(false)}
        primaryButtonIntent={Intent.PRIMARY}
        isPrimaryButtonDisabled={isBtnArchiveDisabled}
      >
        <DialogRow
          label={'Reason'}
          labelOnSingleLine
        >
          <RadioGroup
            className={styles.radioGroup}
            inline={true}
            selectedValue={selectedArchiveStatus}
            onChange={this._candidateFlowsController.setArchiveStatus}
          >
            <Radio
              className={styles.radio}
              label={'Declined by company'}
              value={Recruiting_Candidate_Status_Enum.Declined}
              name={Recruiting_Candidate_Status_Enum.Declined}
            />
            <Radio
              className={styles.radio}
              label={'Refused by candidate'}
              value={Recruiting_Candidate_Status_Enum.Refused}
              name={Recruiting_Candidate_Status_Enum.Refused}
            />
          </RadioGroup>
        </DialogRow>

        <DialogRow>
          <ArchiveReasonSelector />
        </DialogRow>

        {
          warningText &&
          <DialogWarningMessage text={warningText} />
        }

        <DialogRow>
          <TextArea
            className={cx(styles.textArea)}
            value={selectedArchiveNotes}
            fill={true}
            placeholder={'Explain why...'}
            onChange={this._candidateFlowsController.setArchiveNotes}
          />
        </DialogRow>

      </ConfirmationDialog>
    );
  }

  // ---------------------------------------------------------
}
