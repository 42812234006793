import type { Recruiting_Position_Group } from '@flow/data-access/lib/types/graphql.generated';
import { state } from '@flow/dependency-injection';
import type { Common_Customer_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import type { IReactionDisposer } from 'mobx';
import { observable } from 'mobx';
import { computedFn } from 'mobx-utils';

export const PositionsState_LS_KEY:string = 'positionsState';

export enum PositionsFilter
{
  POSITIONS = 'POSITIONS',
  PRIORITIES = 'PRIORITIES',
}

export const PositionsFilterTitles = {
  [PositionsFilter.POSITIONS]: 'Positions',
  [PositionsFilter.PRIORITIES]: 'Priorities'
};

@state
export class PositionsState
{
  // ----------------------------------------------------

  public disposers:Array<IReactionDisposer> = [];

  // ----------------------------------------------------

  @observable public isPositionsLoaded:boolean = false;

  @observable public positionGroups:Array<Recruiting_Position_Group> = [];
  @observable public customers:Array<Common_Customer_Ex> = [];

  // ----------------------------------------------------

  @observable public positionsFilterValue:PositionsFilter | null = null;

  @observable public positionsListScrollYPosition = 0;

  // ----------------------------------------------------

  public positionGroupById:(id:number) => Recruiting_Position_Group | undefined = computedFn((id:number):Recruiting_Position_Group | undefined =>
  {
    return this.positionGroups.find((positionGroup:Recruiting_Position_Group) => positionGroup.id === id);
  });

  // ----------------------------------------------------
}
