import { Button as BPButton, Icon as BPIcon } from '@blueprintjs/core';
import classNames from 'classnames/bind';
import type { Key } from 'react';
import type React from 'react';
import { PureComponent } from 'react';
import type { Alignment, Intent } from '../types/Types';
import { getBPAlignment, getBPIntent } from '../types/Types';
import styles from './Button.module.less';
import type { IconName, IconSize } from './Icon';

const cx = classNames.bind(styles);

export enum ButtonSize
{
  SMALL = 'SMALL',
  REGULAR = 'REGULAR',
  LARGE = 'LARGE',
}

export interface IButtonProps
{
  key?:Key;
  title?:string;
  text?:string | React.ReactNode;
  type?:'submit' | 'reset' | 'button';

  className?:string;
  size?:ButtonSize;
  intent?:Intent;

  icon?:IconName;
  iconSize?:IconSize;
  iconClassName?:string;
  rightIcon?:IconName;
  rightIconSize?:IconSize;
  rightIconClassName?:string;

  alignText?:Alignment;
  minimal?:boolean;
  disabled?:boolean;
  loading?:boolean;
  outlined?:boolean;
  autoFocus?:boolean;

  onClick?:(event:React.MouseEvent<HTMLElement>) => void;
  onMouseDown?:(event:React.MouseEvent<HTMLElement>) => void;
  onMouseUp?:(event:React.MouseEvent<HTMLElement>) => void;
  onKeyDown?:(event:React.KeyboardEvent<HTMLElement>) => void;

  onBlur?:(event:React.FocusEvent<HTMLButtonElement>) => void;
  onFocus?:(event:React.FocusEvent<HTMLButtonElement>) => void;
}

export class Button extends PureComponent<IButtonProps>
{
  public render():React.ReactNode
  {
    const {
      text,
      title,
      type,
      className,
      size, // = ButtonSize.REGULAR,
      intent, // = Intent.NONE,
      icon,
      iconSize, // = IconSize.STANDARD,
      iconClassName,
      rightIcon,
      rightIconSize, // = IconSize.STANDARD,
      rightIconClassName,
      alignText,
      minimal = false,
      disabled = false,
      loading = false,
      outlined = false,
      autoFocus = false,
      onClick, onMouseDown, onMouseUp, onBlur, onFocus, onKeyDown
    } = this.props;

    return (
      <BPButton
        text={text}
        title={title}
        type={type || 'button'}
        className={cx(styles.btn, styles.btnFlow, intent, className)}
        intent={getBPIntent(intent)}
        small={size == ButtonSize.SMALL}
        large={size == ButtonSize.LARGE}
        autoFocus={autoFocus}
        icon={
          // icon && iconSize != IconSize.STANDARD ? (
          <BPIcon icon={icon} size={iconSize} className={iconClassName} />
          // ) : (
          //   icon
          // )
        }
        rightIcon={
          // rightIcon && rightIconSize != IconSize.STANDARD ? (
          <BPIcon icon={rightIcon} size={rightIconSize} className={rightIconClassName || iconClassName} />
          // ) : (
          //   rightIcon
          // )
        }
        alignText={getBPAlignment(alignText)}
        minimal={minimal}
        outlined={outlined}
        loading={loading}
        disabled={disabled}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />
    );
  }
}
