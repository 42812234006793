import { IconNames } from '@flow/common/components/form/Icon';
import { SpinnerIcon } from '@flow/common/components/form/SpinnerIcon';
import { ConfigUtil } from '@flow/common/utils/ConfigUtil';
import { CookieUtil } from '@flow/common/utils/CookieUtil';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import type { ReactNode } from 'react';
import React from 'react';
import type { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import GoogleLogin from 'react-google-login';
import { Navigate } from 'react-router-dom';
import { AuthController } from '../common/controllers/AuthController';
import { AuthState } from '../common/controllers/AuthState';

import styles from './Login.module.less';

enum ButtonStyle
{
  NORMAL = 'normal',
  PRESSED = 'pressed',
  FOCUS = 'focus',
}

@componentWithRouter
export class Login extends React.Component<IWithRouterProps>
{
  @di private _authState!:AuthState;
  @di private _authController!:AuthController;

  private btnRef:HTMLImageElement | null = null;

  private _getBgImageUrl():string
  {
    return window.innerWidth > 414
      ? 'assets/images/login/waves_bg.svg'
      : 'assets/images/login/waves_mobile_bg.svg';
  }

  public render():ReactNode
  {
    const { location } = this.props;
    const { pathname, state } = location || {};
    const { user, isLoggedIn, isLoginFailed, loginFailureReason } = this._authState;

    if( user && user.accessToken && isLoggedIn )
    {
      if( state?.path && state.path !== pathname )
        return <Navigate to={state.path} />;
      else
        return <Navigate to="/" />;
    }

    return (
      <div className={styles.page}>

        <div className={styles.loginCard}>

          <div className={styles.logoWrapper}>
            <img
              className={styles.logoImage}
              src="assets/images/logo/logo-flow.svg"
              alt="SiliconMint Logo"
            />
          </div>

          <div className={styles.pictureWrapper}>
            <img
              className={styles.picture}
              src={`assets/images/login/picture_${Math.floor(Math.random() * 3) + 1}.svg`}
              alt="Happy guy with a laptop"
            />
          </div>

          <div className={styles.form}>
            {
              this._renderGoogleButton()
            }
            {
              isLoginFailed &&
              <div className={styles.errors}>
                {`Sorry, login failed: ${loginFailureReason}`}
              </div>
            }
          </div>

        </div>

        <div className={styles.version}>
          Version 0.1
        </div>

        <div
          className={styles.wavesBg}
          style={{
            backgroundImage: `url(${this._getBgImageUrl()})`,
          }}
          // src="assets/images/login/waves_bg.svg"
        />
      </div>
    );
  }

  private _renderLoadingButton():React.ReactNode
  {
    return <div className={styles.loadingButton}>
      <img
        className={styles.googleButton}
        srcSet="/assets/images/google/btn_google_signin_dark_loading_web.png 1x, /assets/images/google/btn_google_signin_dark_loading_web@2x.png 2x"
        src={'/assets/images/google/btn_google_signin_dark_loading_web.png'}
      />
      <SpinnerIcon icon={IconNames.REFRESH} className={styles.spinner} size={20} />
      {/* <Spinner className={styles.spinner} /> */}
    </div>
  }

  private _renderGoogleButton():ReactNode
  {
    const { isLoginInProgress } = this._authState;

    return <div className={styles.googleButtonWrapper}>
      {isLoginInProgress
        ? this._renderLoadingButton()
        : <>
          <GoogleLogin
            clientId={'1004367311598-eadev5gp2rvv676cctjn4urpb4vptoh2.apps.googleusercontent.com'}
            render={(renderProps):JSX.Element => (
              renderProps.disabled
                ? <>Looking for bits...</>
                : <img
                  className={styles.googleButton}
                  ref={(ref):unknown => this.btnRef = ref}
                  tabIndex={0}
                  srcSet="/assets/images/google/btn_google_signin_dark_normal_web.png 1x, /assets/images/google/btn_google_signin_dark_normal_web@2x.png 2x"
                  src="/assets/images/google/btn_google_signin_dark_normal_web.png"
                  onClick={():void => this._handleLogin(renderProps.onClick)}
                  onKeyPress={(e:React.KeyboardEvent<HTMLImageElement>):void =>
                  {
                    if( e.code === 'Enter' || e.code === 'Space' )
                      this._handleLogin(renderProps.onClick);
                  }}
                  onMouseOver={():void => this._setButtonAttributes(ButtonStyle.FOCUS)}
                  onMouseLeave={():void => this._setButtonAttributes(ButtonStyle.NORMAL)}
                  alt="Sign in with Google"
                  hidden={renderProps.disabled}
                />
            )}
            buttonText="Sign in with Google"
            onSuccess={(response):unknown => this._onSuccess(response)}
            onFailure={(error):void => console.error(error)}
            scope={'email profile openid'}
            cookiePolicy={'single_host_origin'}
            responseType={'code'}
            prompt={this._getPrompt()}
            accessType={'offline'}
          />
        </>
      }
    </div>;
  }

  private _getPrompt():string | undefined
  {
    return CookieUtil.getCookie(CookieUtil.FLOW_NEEDS_REFRESH_TOKEN) === '1' ? 'consent' : undefined;
  }

  @bind
  private _handleLogin(onClick:() => void):void
  {
    this._setButtonAttributes(ButtonStyle.PRESSED);

    if( onClick )
      onClick();
  }

  @bind
  private async _onSuccess(googleResponse:GoogleLoginResponse | GoogleLoginResponseOffline):Promise<void>
  {
    if( googleResponse.code )
    {
      try
      {
        if( ConfigUtil.isUseLocalGoogleLogin() )
          await this._authController.testLogin(googleResponse.code);
        else
          await this._authController.login(googleResponse.code);
      }
      catch( e:unknown )
      {
        console.debug('Login failed: ', (e as Error).message);
      }
    }
    else
    {
      console.error('No code!');
    }
  }

  private _setButtonAttributes(style:ButtonStyle):void
  {
    if( !this.btnRef )
      return;

    this.btnRef.srcset = `/assets/images/google/btn_google_signin_dark_${style}_web.png 1x,`
      + `/assets/images/google/btn_google_signin_dark_${style}_web@2x.png 2x`;

    this.btnRef.src = `/assets/images/google/btn_google_signin_dark_${style}_web.png`;
  }

}
