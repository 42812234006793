import { HasuraTableColumns, HasuraTables } from './HasuraTables';

export enum HasuraOperation
{
  Select = 'Select',
  Update = 'Update',
  Insert = 'Insert',
  Delete = 'Delete'
}

export enum HasuraActions
{
  GetCalendarFreeBusyInfo = 'action_calendar_freebusy',
  CandidateFetchLinkedInProfilePicture = 'action_candidate_fetch_linkedin_image',
  CandidateUploadCV = 'action_candidate_upload_cv',
}

export interface IFlowTablePermission
{
  kind:'permission';
  operation:HasuraOperation;
  table:HasuraTables;
  column?:HasuraTableColumns | Array<HasuraTableColumns> | string | Array<string>;
}

export interface IFlowActionPermission
{
  kind:'action_permission';
  action:HasuraActions;
}

export type IFlowPermission = IFlowTablePermission | IFlowActionPermission;

export class FlowPermissions
{
  /**
   * permissions
   */
  public static ViewCustomers:IFlowPermission = FlowPermissions.view(HasuraTables.CommonCustomer);
  public static ViewCustomerName:IFlowPermission = FlowPermissions.view(HasuraTables.CommonCustomer, 'name');
  public static EditCustomers:IFlowPermission = FlowPermissions.edit(HasuraTables.CommonCustomer);

  public static CreateCustomer:IFlowPermission = FlowPermissions.create(HasuraTables.CommonCustomer);
  public static EditCustomer:IFlowPermission = FlowPermissions.edit(HasuraTables.CommonCustomer);
  public static SoftDeleteCustomer:IFlowPermission = FlowPermissions.softDelete(HasuraTables.CommonCustomer);

  public static ViewCandidates:IFlowPermission = FlowPermissions.view(HasuraTables.RecruitingCandidate);
  public static CreateCandidate:IFlowPermission = FlowPermissions.create(HasuraTables.RecruitingCandidate);
  public static EditCandidate:IFlowPermission = FlowPermissions.edit(HasuraTables.RecruitingCandidate);

  public static AddCandidateNote:IFlowPermission = FlowPermissions.create(HasuraTables.RecruitingCandidateNote);

  public static ChangeCandidateAssignee:IFlowPermission = FlowPermissions.edit(HasuraTables.RecruitingCandidate, 'assignee_id');
  public static ChangeCandidateFlowStage:IFlowPermission = FlowPermissions.edit(HasuraTables.RecruitingCandidate, 'interview_flow_stage_id');
  public static ChangeCandidatePositionGroups:IFlowPermission = FlowPermissions.create(HasuraTables.RecruitingCandidatePositionGroup);

  public static UploadCandidateCV:IFlowPermission = FlowPermissions.runAction(HasuraActions.CandidateUploadCV);

  public static RenameCandidateAttachment:IFlowPermission = FlowPermissions.edit(HasuraTables.CommonAttachment, 'name');
  public static DeleteCandidateAttachment:IFlowPermission = FlowPermissions.delete(HasuraTables.CommonAttachment);

  public static CreateScheduledEvent:IFlowPermission = FlowPermissions.edit(HasuraTables.CommonScheduledEvent, 'is_scheduled');
  public static RescheduleEvent:IFlowPermission = FlowPermissions.edit(HasuraTables.CommonScheduledEvent, ['summary, description', 'start_time', 'end_time', 'attendees_list']);
  public static CompleteEvent:IFlowPermission = FlowPermissions.edit(HasuraTables.CommonScheduledEvent, 'is_done');
  public static CompleteEventFeedbackForm:IFlowPermission = FlowPermissions.edit(HasuraTables.CommonScheduledEvent, 'is_feedback_done');

  public static ViewPositions:IFlowPermission = FlowPermissions.view(HasuraTables.RecruitingPosition);

  public static ViewCustomerTeams:IFlowPermission = FlowPermissions.view(HasuraTables.StaffingCustomerTeam);
  public static EditCustomerTeams:IFlowPermission = FlowPermissions.edit(HasuraTables.StaffingCustomerTeam);
  public static DeleteCustomerTeams:IFlowPermission = FlowPermissions.edit(HasuraTables.StaffingCustomerTeam, 'deleted_at');
  public static ChangeCustomerTeamsOrder:IFlowPermission = FlowPermissions.edit(HasuraTables.StaffingCustomerTeam, 'ui_order');

  public static ViewCustomerTeamSlots:IFlowPermission = FlowPermissions.view(HasuraTables.StaffingCustomerTeamSlot);
  public static EditCustomerTeamSlots:IFlowPermission = FlowPermissions.edit(HasuraTables.StaffingCustomerTeamSlot);

  public static ViewPositionGroups:IFlowPermission = FlowPermissions.view(HasuraTables.RecruitingPositionGroup, 'id');
  public static ViewCandidatePositionGroup:IFlowPermission = FlowPermissions.view(HasuraTables.RecruitingPositionGroup, 'position_group_id');

  public static CreatePositionGroup:IFlowPermission = FlowPermissions.create(HasuraTables.RecruitingPositionGroup);
  public static AssignPositionToPositionGroup:IFlowPermission = FlowPermissions.edit(HasuraTables.RecruitingPosition, 'position_group_id');
  public static EditPositionGroup:IFlowPermission = FlowPermissions.edit(HasuraTables.RecruitingPositionGroup);

  public static UnassignOrMovePosition = FlowPermissions.edit(HasuraTables.RecruitingPosition, 'position_group_id');
  public static CreatePosition = FlowPermissions.create(HasuraTables.RecruitingPosition);
  public static EditPosition = FlowPermissions.edit(HasuraTables.RecruitingPosition);
  public static ChangePositionReadinessState = FlowPermissions.edit(HasuraTables.RecruitingPosition, 'is_ready');
  public static EditPositionAlias = FlowPermissions.edit(HasuraTables.RecruitingPosition, 'alias');
  public static ChangePositionCustomerTeamOrder = FlowPermissions.edit(HasuraTables.RecruitingPosition, 'ui_customer_team_order');

  public static ViewPositionGroupPriority:IFlowPermission = FlowPermissions.view(HasuraTables.RecruitingPositionGroupPriority, 'priority');
  public static EditPositionGroupPriorities:IFlowPermission = FlowPermissions.edit(HasuraTables.RecruitingPositionGroupPriority);

  public static CreateProposedAllocation:IFlowPermission = FlowPermissions.create(HasuraTables.RecruitingCandidateAllocation);

  public static CreateFinalAllocation:IFlowPermission =
    FlowPermissions.create(HasuraTables.RecruitingCandidateAllocation)
    && FlowPermissions.edit(HasuraTables.RecruitingCandidate, 'finalized_allocation_id');

  public static ViewStaff:IFlowPermission = FlowPermissions.view(HasuraTables.StaffingStaff);
  public static CreateStaff:IFlowPermission = FlowPermissions.create(HasuraTables.StaffingStaff);
  public static EditStaff:IFlowPermission = FlowPermissions.edit(HasuraTables.StaffingStaff);

  public static ViewRecruitingBoardPage:IFlowPermission = FlowPermissions.EditCandidate;

  public static ViewCandidatesPage:Array<IFlowPermission> = [
    FlowPermissions.EditCandidate,
    FlowPermissions.AddCandidateNote
  ];
  public static ViewPrioritiesPage:IFlowPermission = FlowPermissions.ViewPositionGroupPriority;
  public static ViewPositionsPage:IFlowPermission = FlowPermissions.ViewPositions;

  public static ViewRecruitingPages:Array<IFlowPermission> = [
    FlowPermissions.ViewRecruitingBoardPage,
    ...FlowPermissions.ViewCandidatesPage,
    FlowPermissions.ViewPrioritiesPage,
    FlowPermissions.ViewPositionsPage
  ];

  public static ViewStaffPages:IFlowPermission = FlowPermissions.ViewStaff;

  public static ReallocateStaff:IFlowPermission = FlowPermissions.EditCustomerTeamSlots;

  /**
   * helpers
   */
  protected static view(table:HasuraTables, column?:HasuraTableColumns | Array<HasuraTableColumns> | string | Array<string>):IFlowPermission
  {
    return {
      kind: 'permission',
      operation: HasuraOperation.Select,
      table,
      column
    };
  }

  protected static edit(table:HasuraTables, column?:HasuraTableColumns | Array<HasuraTableColumns> | string | Array<string>):IFlowPermission
  {
    return {
      kind: 'permission',
      operation: HasuraOperation.Update,
      table,
      column
    };
  }

  protected static create(table:HasuraTables):IFlowPermission
  {
    return {
      kind: 'permission',
      operation: HasuraOperation.Insert,
      table
    };
  }

  protected static delete(table:HasuraTables):IFlowPermission
  {
    return {
      kind: 'permission',
      operation:HasuraOperation.Delete,
      table
    };
  }

  protected static softDelete(table:HasuraTables):IFlowPermission
  {
    return FlowPermissions.edit(table, HasuraTableColumns.DeletedAt);
  }

  protected static runAction(action:HasuraActions):IFlowPermission
  {
    return {
      kind: 'action_permission',
      action
    };
  }
}
