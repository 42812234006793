import { CommonController } from '@flow/common/CommonController';
import { useDi } from '@flow/dependency-injection';
import type { ReactElement } from 'react';
import { useEffect } from 'react';
import type { NavigateFunction } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export function NavigateInjector():ReactElement | null
{
  const commonController:CommonController = useDi(CommonController);
  const navigate:NavigateFunction = useNavigate();

  useEffect(
    () =>
    {
      commonController.registerNavigate(navigate);

      return ():void =>
      {
        commonController.registerNavigate(null);
      };
    },
    [commonController, navigate]
  );

  return null;
}
