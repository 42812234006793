import type { ApolloQueryResult } from '@apollo/client';
import type { FetchResult } from '@apollo/client/link/core';
import { CommonController } from '@flow/common/CommonController';
import type { PositionItem } from '@flow/common/components/elements/PositionItem';
import { AuthState } from '@flow/common/controllers/AuthState';
import type { Maybe } from '@flow/common/models/Types';
import { DateUtil } from '@flow/common/utils/DateUtil';
import type {
  AddCandidateAllocationMutation,
  AddCandidateAllocationMutationVariables,
  CandidateAllocationPositionsQuery,
  CandidateAllocationPositionsQueryVariables,
  DeleteCandidateAllocationMutation,
  DeleteCandidateAllocationMutationVariables,
  SlotsClearFinalizedCandidateMutation,
  SlotsClearFinalizedCandidateMutationVariables,
  SlotsFinalizeCandidateMutation,
  SlotsFinalizeCandidateMutationVariables,
  UpdateCandidateAllocationFieldsMutation,
  UpdateCandidateAllocationFieldsMutationVariables,
  UpdateCandidateAllocationMutation,
  UpdateCandidateAllocationMutationVariables
} from '@flow/data-access/lib/candidate.generated';
import {
  AddCandidateAllocationDocument,
  CandidateAllocationPositionsDocument,
  DeleteCandidateAllocationDocument,
  SlotsClearFinalizedCandidateDocument,
  SlotsFinalizeCandidateDocument,
  UpdateCandidateAllocationDocument,
  UpdateCandidateAllocationFieldsDocument
} from '@flow/data-access/lib/candidate.generated';
import type {
  Recruiting_Allocation_Position,
  Recruiting_Allocation_Position_Insert_Input,
  Recruiting_Candidate_Allocation,
  Staffing_Customer_Team_Slot,
  Staffing_Customer_Team_Slot_Insert_Input
} from '@flow/data-access/lib/types/graphql.generated';
import { Staffing_Customer_Team_Slot_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import bind from 'bind-decorator';
import { action, runInAction, toJS } from 'mobx';
import React from 'react';
import { CandidateAllocationState } from './CandidateAllocationState';
import { CandidatePositionGroupsState } from './CandidatePositionGroupsState';
import { CandidatesState } from './CandidatesState';
import { CandidateState } from './CandidateState';

@controller
export class CandidateAllocationController
{
  @di private _commonController!:CommonController;
  @di private _authState!:AuthState;
  @di private _candidateState!:CandidateState;
  @di private _candidatesState!:CandidatesState;
  @di private _candidateAllocationState!:CandidateAllocationState;
  @di private _candidatePositionGroupsState!:CandidatePositionGroupsState;

  // ----------------------------------------------------

  @action.bound
  public showFinalizeAllocationDialog():void
  {
    const { finalizedAllocation, currentFinalSlot, isShowOnlyOpenPositions } = this._candidateAllocationState;

    console.log('%c ------------------- =', 'background:#00f;color:#ff0;');
    console.log('%c showFinalizeAllocationDialog  =', 'background:#00f;color:#ff0;');

    console.log('%c finalizedAllocation =', 'background:#080;color:#000;', toJS(finalizedAllocation));
    console.log('%c currentFinalSlots   =', 'background:#080;color:#000;', toJS(currentFinalSlot));
    console.log('%c ------------------- =', 'background:#00f;color:#ff0;');

    if( finalizedAllocation )
    {
      this._candidateAllocationState.isReFinalize = true;
      this._candidateAllocationState.finalizeNote = finalizedAllocation.notes || '';

      if( this._candidateAllocationState.finalizeNote )
      {
        this._candidateAllocationState.showNoteInput = true;
      }

      this._candidateAllocationState.selectedSlot = null;

      if( currentFinalSlot )
      {
        this._candidateAllocationState.selectedPositionId = currentFinalSlot.position_id;
        this._candidateAllocationState.selectedSlot = Object.assign({}, currentFinalSlot);
        this._candidateAllocationState.selectedDate = currentFinalSlot.next_candidate_start_date;
      }
    }
    else
    {
      this._candidateAllocationState.isReFinalize = false;
      this._candidateAllocationState.finalizeNote = '';
      this.proposePositionForFinalize();
    }

    this._candidateAllocationState.isScrolledToPosition = false;

    this._candidateAllocationState.isShowOnlyOpenPositionsInDialog = isShowOnlyOpenPositions;

    this._candidateAllocationState.isFinalizeAllocationDialogOpen = true;
    this._candidateAllocationState.isFinalizeAllocationLoading = false;
  }

  @action.bound
  public hideFinalizeAllocationDialog():void
  {
    this._candidateAllocationState.isFinalizeAllocationDialogOpen = false;
    this._candidateAllocationState.isFinalizeAllocationLoading = false;
  }

  @action.bound
  public setFinalizeNote(newValue:string):void
  {
    this._candidateAllocationState.finalizeNote = newValue;
  }

  @action.bound
  public setNoteInputVisibility(newValue:boolean):void
  {
    this._candidateAllocationState.showNoteInput = newValue;
  }

  @action.bound
  public setSelectedPositionId(positionId:number, positionItemRef:React.RefObject<PositionItem>):void
  {
    const { selectedPositionId, selectedPositionItemRef } = this._candidateAllocationState;

    if( selectedPositionId === positionId ) return;

    selectedPositionItemRef?.current?.setHiddenBlockVisibility(false);

    this._candidateAllocationState.selectedPositionId = positionId;
    this._candidateAllocationState.selectedPositionItemRef = positionItemRef;

    this.proposeSlotForFinalize();
  }

  @action.bound
  public async initCandidateAllocationPositions():Promise<void>
  {
    const { viewCandidateId } = this._candidatesState;

    if( !viewCandidateId ) return;

    const result:ApolloQueryResult<CandidateAllocationPositionsQuery> =
      await this._commonController.query<CandidateAllocationPositionsQuery,
        CandidateAllocationPositionsQueryVariables>({
        query: CandidateAllocationPositionsDocument,
        variables: {
          candidateId: viewCandidateId
        }
      });

    console.log('%c initCandidateAllocationPositions result =', 'background:#f60;color:#000;', result);

    runInAction(() =>
    {
      this._candidateAllocationState.currentFinalSlots.length = 0;

      const {
        recruiting_candidate_allocation,
        recruiting_position
      } = result.data;

      this._candidateAllocationState.proposedAllocations = recruiting_candidate_allocation as Array<Recruiting_Candidate_Allocation>;
      this._candidateAllocationState.positions = recruiting_position as unknown as Array<Recruiting_Position_Ex>;

      this._candidateAllocationState.positions.forEach((position:Recruiting_Position_Ex) =>
      {
        position.customer_team_slots.forEach((slot:Staffing_Customer_Team_Slot) =>
        {
          // if( !slot.position ) slot.position = position;
          slot.position = position;

          if( slot.next_candidate_id === viewCandidateId )
            this._candidateAllocationState.currentFinalSlots.push(slot);
        });
      });

      console.log('%c currentFinalSlots     =', 'background:#080;color:#000;', toJS(this._candidateAllocationState.currentFinalSlots));

      this._candidateState.isCandidateAllocationPositionsLoaded = true;
    });
  }

  // ----------------------------------------------------

  @action.bound
  public async proposePositionOrUpdateNotes(positionId:number, proposedNote?:string):Promise<void>
  {
    console.log('%c ------------------------------ =', 'background:#0f0;color:#000;');
    console.log('%c proposePositionOrUpdateNotes   =', 'background:#0f0;color:#000;');
    console.log('%c positionId   =', 'background:#080;color:#000;', positionId);
    console.log('%c proposedNote =', 'background:#080;color:#000;', proposedNote);

    const { user } = this._authState;
    const { candidate } = this._candidateState;
    const { positions, proposedAllocations } = this._candidateAllocationState;

    if( !candidate || !user || !user.id )
    {
      // TODO: error message ?!
      return;
    }

    console.log('%c positions =', 'background:#080;color:#000;', toJS(positions));
    console.log('%c proposedAllocations =', 'background:#080;color:#000;', toJS(proposedAllocations));

    const myProposedAllocation:Recruiting_Candidate_Allocation | undefined = this.myProposedAllocationForPosition(positionId);
    console.log('%c myProposedAllocation =', 'background:#080;color:#000;', toJS(myProposedAllocation));

    const notes:string = proposedNote?.trim() || '';
    console.log('%c notes               =', 'background:#ccf;color:#000;', toJS(notes));

    // ---------------------
    // insert new allocation
    if( !myProposedAllocation )
    {
      const allocationPositions:Recruiting_Allocation_Position_Insert_Input = { position_id: positionId };
      console.log('%c allocationPositions =', 'background:#ccf;color:#000;', toJS(allocationPositions));

      const result:FetchResult<AddCandidateAllocationMutation> =
        await this._commonController.mutate<AddCandidateAllocationMutation,
          AddCandidateAllocationMutationVariables>({
          mutation: AddCandidateAllocationDocument,
          variables: {
            user_id: Number(user?.id),
            candidate_id: Number(candidate?.id),
            notes,
            allocationPositions
          }
        });

      console.log('%c AddCandidateAllocationMutation result =', 'background:#f60;color:#000;', result);

      runInAction(() =>
      {
        if( result && result.data )
        {
          const {
            insert_recruiting_candidate_allocation_one
          } = result.data;

          this._candidateAllocationState.proposedAllocations.splice(0, 0, insert_recruiting_candidate_allocation_one as Recruiting_Candidate_Allocation);
        }
      });

      return;
    }

    // -----------------
    // update allocation
    const result:FetchResult<UpdateCandidateAllocationFieldsMutation> =
      await this._commonController.mutate<UpdateCandidateAllocationFieldsMutation,
        UpdateCandidateAllocationFieldsMutationVariables>({
        mutation: UpdateCandidateAllocationFieldsDocument,
        variables: {
          allocationId: myProposedAllocation.id,
          user_id: user.id,
          notes
        }
      });

    console.log('%c UpdateCandidateAllocationFieldsMutation result =', 'background:#f60;color:#000;', result);

    runInAction(() =>
    {
      if( result && result.data )
      {
        const {
          update_recruiting_candidate_allocation_by_pk
        } = result.data;

        if( update_recruiting_candidate_allocation_by_pk )
        {

          const oldAllocationIndex:number = this._candidateAllocationState.proposedAllocations.findIndex((a) =>
          {
            return a.id === myProposedAllocation.id;
          });
          // this._candidateAllocationState.proposedAllocations.push(update_recruiting_candidate_allocation_by_pk as Recruiting_Candidate_Allocation);

          if( oldAllocationIndex !== -1 )
          {
            this._candidateAllocationState.proposedAllocations.splice(oldAllocationIndex, 1, update_recruiting_candidate_allocation_by_pk as Recruiting_Candidate_Allocation);
          }
        }
      }
    });
  }

  // ----------------------------------------------------

  @action.bound
  public async deleteProposedAllocation(positionId:number):Promise<void>
  {
    console.log('%c ------------------------ =', 'background:#0f0;color:#000;');
    console.log('%c deleteProposedAllocation =', 'background:#0f0;color:#000;');
    console.log('%c positionId =', 'background:#080;color:#000;', positionId);

    const myProposedAllocation:Recruiting_Candidate_Allocation | undefined = this.myProposedAllocationForPosition(positionId);
    console.log('%c myProposedAllocation =', 'background:#080;color:#000;', toJS(myProposedAllocation));

    if( !myProposedAllocation )
    {
      // TODO: error message ?!
      return;
    }

    const result:FetchResult<DeleteCandidateAllocationMutation> =
      await this._commonController.mutate<DeleteCandidateAllocationMutation,
        DeleteCandidateAllocationMutationVariables>({
        mutation: DeleteCandidateAllocationDocument,
        variables: {
          allocationId: myProposedAllocation.id
        }
      });

    console.log('%c deleteProposedAllocation result =', 'background:#f60;color:#000;', result);

    if( result.errors )
      throw result.errors[0];

    const oldAllocationIndex:number = this._candidateAllocationState.proposedAllocations.findIndex((a) =>
    {
      return a.id === myProposedAllocation.id;
    });

    if( oldAllocationIndex !== -1 )
    {
      this._candidateAllocationState.proposedAllocations.splice(oldAllocationIndex, 1);
    }
  }

  // ----------------------------------------------------

  @action.bound
  public async finalizeAllocation():Promise<void>
  {
    const { user } = this._authState;
    const { candidate } = this._candidateState;
    const {
      finalizedAllocation,
      currentFinalSlot,
      finalizeNote, showNoteInput,
      selectedPositionId, selectedSlot, selectedDate
    } = this._candidateAllocationState;

    if( !candidate || !user || !user.id || !selectedSlot )
    {
      // TODO: error message ?!
      return;
    }

    /**
     TODO:

     1. Finalize
     - select from proposed or auto
     - by default add to empty slot
     - show option to choose slot, if non-empty count > 0

     2. ReFinalize
     - show full list of proposed + auto
     - current final positions+slots are already there (pre-selected)
     - they can be de-selected (need to remove the candidate from these slots!!!)
     - they can be edited (e.g. clear the candidate from co-living, add them to an open slot and vice versa)
     - new positions/slots can be selected

     **/

    const isReFinalized:boolean = !!finalizedAllocation;

    const variables:Partial<UpdateCandidateAllocationMutationVariables | AddCandidateAllocationMutationVariables> = {
      // candidate_id -> for add new
      // allocationId -> for update
      user_id: user.id,
      notes: showNoteInput ? finalizeNote.trim() : '',
      is_final: true,
      allocationPositions: []
    };

    let finalAllocationId:number;

    console.log('%c ------------------- =', 'background:#00f;color:#ff0;');
    console.log('%c finalizeAllocation  =', 'background:#00f;color:#ff0;');

    console.log('%c selectedPositionId  =', 'background:#080;color:#000;', toJS(selectedPositionId));
    console.log('%c selectedSlot        =', 'background:#080;color:#000;', toJS(selectedSlot));
    console.log('%c selectedDate        =', 'background:#080;color:#000;', toJS(selectedDate));
    console.log('%c finalizeNote        =', 'background:#080;color:#000;', toJS(finalizeNote));
    console.log('%c showNoteInput       =', 'background:#080;color:#000;', toJS(showNoteInput));
    console.log('%c ------------------- =', 'background:#00f;color:#ff0;');
    console.log('%c finalizedAllocation =', 'background:#080;color:#000;', toJS(finalizedAllocation));
    console.log('%c currentFinalSlots   =', 'background:#080;color:#000;', toJS(currentFinalSlot));
    console.log('%c ------------- =', 'background:#00f;color:#ff0;');
    console.log('%c isReFinalized =', 'background:#080;color:#000;', toJS(isReFinalized));
    console.log('%c variables     =', 'background:#080;color:#000;', toJS(variables));

    // ------------------------

    if( isReFinalized )
    {
      // update finalized allocation

      (variables as UpdateCandidateAllocationMutationVariables).allocationId = finalizedAllocation?.id || 0;

      const result:FetchResult<UpdateCandidateAllocationMutation> =
        await this._commonController.mutate<UpdateCandidateAllocationMutation, UpdateCandidateAllocationMutationVariables>(
          {
            mutation: UpdateCandidateAllocationDocument,
            variables: variables as UpdateCandidateAllocationMutationVariables
          });

      console.log('%c -- UpdateCandidateAllocation: Update result =', 'background:#f60;color:#000;', result);

      if( result.errors )
      {
        throw result.errors[0];
      }

      finalAllocationId = result.data?.update_recruiting_candidate_allocation_by_pk?.id || 0;
    }
    // ------------------------
    else
    {
      // insert new allocation

      (variables as AddCandidateAllocationMutationVariables).candidate_id = candidate.id;

      const result:FetchResult<AddCandidateAllocationMutation> =
        await this._commonController.mutate<AddCandidateAllocationMutation, AddCandidateAllocationMutationVariables>(
          {
            mutation: AddCandidateAllocationDocument,
            variables: variables as AddCandidateAllocationMutationVariables
          });

      console.log('%c -- AddCandidateAllocation: Add result =', 'background:#f60;color:#000;', result);

      if( result.errors )
      {
        throw result.errors[0];
      }

      finalAllocationId = result.data?.insert_recruiting_candidate_allocation_one?.id || 0;
    }

    // ------------------------

    console.log('%c ----------------- =', 'background:#00f;color:#ff0;');
    console.log('%c finalAllocationId =', 'background:#080;color:#000;', toJS(finalAllocationId));

    if( finalAllocationId > 0 )
    {
      // clear finalization from slots
      if( currentFinalSlot )
      {
        if( currentFinalSlot.id !== selectedSlot.id )
        {
          const clearResult:FetchResult<SlotsClearFinalizedCandidateMutation> =
            await this._commonController.mutate<SlotsClearFinalizedCandidateMutation, SlotsClearFinalizedCandidateMutationVariables>(
              {
                mutation: SlotsClearFinalizedCandidateDocument,
                variables: {
                  slotIds: currentFinalSlot.id
                }
              });

          console.log('%c -- SlotsClearFinalizedCandidate clearResult =', 'background:#f60;color:#000;', clearResult);

          if( clearResult.errors )
          {
            throw clearResult.errors[0];
          }
        }
      }

      // add finalization to slots
      const slotInputs:Array<Staffing_Customer_Team_Slot_Insert_Input> = [
        {
          id: selectedSlot.id,
          position_id: 0,   // non-null violation
          status: Staffing_Customer_Team_Slot_Status_Enum.Open,
          next_candidate_id: candidate.id,
          next_candidate_start_date: DateUtil.getISODay(selectedDate),
          next_candidate_allocation_id: finalAllocationId
        }
      ];

      const updateResult:FetchResult<SlotsFinalizeCandidateMutation> =
        await this._commonController.mutate<SlotsFinalizeCandidateMutation, SlotsFinalizeCandidateMutationVariables>(
          {
            mutation: SlotsFinalizeCandidateDocument,
            variables: {
              slots: slotInputs
            }
          });

      console.log('%c -- SlotsFinalizeCandidate: updateResult =', 'background:#f60;color:#000;', updateResult);

      if( updateResult.errors )
      {
        throw updateResult.errors[0];
      }
    }

    // ------------------------

    this.initCandidateAllocationPositions();

    this.hideFinalizeAllocationDialog();
  }

  // ----------------------------------------------------

  @bind
  public isPositionProposed(positionId:number):boolean
  {
    if( !positionId ) return false;

    const { allProposedPositionIds } = this._candidateAllocationState;

    return allProposedPositionIds.includes(positionId);
  }

  @bind
  public isPositionProposedByMe(positionId:number):boolean
  {
    if( !positionId ) return false;

    const { myProposedPositionIds } = this._candidateAllocationState;

    return myProposedPositionIds.includes(positionId);
  }

  // ----------------------------------------------------

  @bind
  public myProposedAllocationForPosition(positionId:number):Recruiting_Candidate_Allocation | undefined
  {
    if( !positionId ) return;

    const { proposedAllocations } = this._candidateAllocationState;

    const myId:number = this._authState.user?.id || 0;

    return proposedAllocations.find((proposedAllocation:Recruiting_Candidate_Allocation) =>
    {
      if( proposedAllocation.user.id !== myId ) return false;

      return proposedAllocation.allocation_positions.some((proposedPosition:Recruiting_Allocation_Position) =>
      {
        return proposedPosition.position_id === positionId;
      });
    });
  }

  // ----------------------------------------------------

  @action.bound
  public proposePositionForFinalize():void
  {
    const { selectedPositionId, inDialogAutoComputedPositions, proposedAllocations } = this._candidateAllocationState;

    if( selectedPositionId ) return;

    const autoComputedPositionIds:Array<number> = inDialogAutoComputedPositions.map(p => p.id);

    if( !autoComputedPositionIds.length ) return;

    const proposedCounts:Map<number, number> = new Map();

    proposedAllocations.forEach((allocation:Recruiting_Candidate_Allocation) =>
    {
      allocation.allocation_positions.forEach((position:Recruiting_Allocation_Position) =>
      {
        if( !autoComputedPositionIds.includes(position.position_id) ) return;

        proposedCounts.set(position.position_id, (proposedCounts.get(position.position_id) || 0) + 1);
      });
    });

    let newSelectedPositionId:number = 0;

    if( proposedCounts.size )
    {
      newSelectedPositionId = [...proposedCounts.entries()] // = [[id1, count1], [id2, count2]]
        .sort((a, b) => b[1] - a[1])[0][0];
    }
    else
    {
      newSelectedPositionId = autoComputedPositionIds[0];
    }

    this._candidateAllocationState.selectedPositionId = newSelectedPositionId;

    this.proposeSlotForFinalize();
  }

  // ----------------------------------------------------

  @action.bound
  public proposeSlotForFinalize():void
  {
    const { selectedPositionId, currentFinalSlot } = this._candidateAllocationState;

    if( !selectedPositionId ) return;

    const selectedPosition:Recruiting_Position_Ex | undefined = this._candidateAllocationState.positionById(selectedPositionId);

    if( !selectedPosition ) return;

    if( currentFinalSlot ) // ok if = 1, ?? if > 1
    {
      if( selectedPositionId === currentFinalSlot.position_id )
      {
        this._candidateAllocationState.selectedSlot = Object.assign({}, currentFinalSlot);
        return;
      }
    }

    const anyOpenSlot:Staffing_Customer_Team_Slot | undefined = selectedPosition.customer_team_slots
      .filter((slot:Staffing_Customer_Team_Slot) =>
      {
        return !slot.next_staff && !slot.next_candidate_id;
      })
      .sort((a, b) => b.id - a.id)
      .sort((slot:Staffing_Customer_Team_Slot) =>
      {
        return slot.status == Staffing_Customer_Team_Slot_Status_Enum.Open ? -1 : 0;
      })
      .find((slot:Staffing_Customer_Team_Slot) =>
      {
        return slot.status === Staffing_Customer_Team_Slot_Status_Enum.Open;
      });

    if( anyOpenSlot )
    {
      this._candidateAllocationState.selectedSlot = Object.assign({}, anyOpenSlot);
    }
    else
    {
      const availableSlots:Array<Staffing_Customer_Team_Slot> = selectedPosition.customer_team_slots
        .filter((slot:Staffing_Customer_Team_Slot) => !slot.next_staff && !slot.next_candidate_id);

      this._candidateAllocationState.selectedSlot = Object.assign({}, availableSlots[0]);
    }
  }

  // ----------------------------------------------------

  @action.bound
  public setShowOnlyOpenPositions(newValue:boolean, inDialog:Maybe<boolean>):void
  {
    if( inDialog )
    {
      this._candidateAllocationState.isShowOnlyOpenPositionsInDialog = newValue;

      if( newValue && this._candidateAllocationState.selectedPositionId )
      {
        const position:Maybe<Recruiting_Position_Ex> = this._candidateAllocationState.positionById(this._candidateAllocationState.selectedPositionId);

        if( position && position.open_slots_count.aggregate.count === 0 )
        {
          this._candidateAllocationState.selectedPositionId = null;
          this.proposePositionForFinalize();
        }
      }
    }
    else
    {
      this._candidateAllocationState.isShowOnlyOpenPositions = newValue;
    }
  }

  // ----------------------------------------------------
}
