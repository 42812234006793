import type { InputGroupProps2 } from '@blueprintjs/core';
import { InputGroup as BPInputGroup } from '@blueprintjs/core';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import { PureComponent, type ChangeEvent } from 'react';
import type { Intent } from '../types/Types';
import { getBPIntent } from '../types/Types';

import styles from './TextInput.module.less';

const cx = classNames.bind(styles);

export interface ITextInputProps extends Omit<InputGroupProps2, 'intent' | 'onChange'>
{
  className?:string;
  intent?:Intent;
  onChange?:(newValue:string) => void;
}

export class TextInput extends PureComponent<ITextInputProps>
{
  @bind
  private _onChange(event:ChangeEvent<HTMLInputElement>):void
  {
    const { onChange } = this.props;

    if( onChange )
      onChange(event.target.value);
  }

  public render():ReactNode
  {
    const {
      className,
      intent
    } = this.props;

    return (
      <BPInputGroup
        {...this.props}
        className={cx(styles.wrapper, className)}
        intent={getBPIntent(intent)}
        onChange={this._onChange}
      />
    );
  }
}
