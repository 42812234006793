import { MenuDivider, MenuItem } from '@blueprintjs/core';
import { Classes } from '@blueprintjs/popover2';
import type { IItemRendererProps } from '@blueprintjs/select';
import { MultiSelect } from '@blueprintjs/select';
import type {
  IFilterMultiSelectDataItem,
  IFilterMultiSelectItemValueType
} from '@flow/common/components/filters/multiSelect/FilterMultiSelect';
import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import * as React from 'react';

import styles from './FilterMultiSelectInput.module.less';

const cx = classNames.bind(styles);

export interface IFilterMultiSelectProps
{
  items:Array<IFilterMultiSelectDataItem>;
  selectedIds:Array<IFilterMultiSelectItemValueType>;
  onSelect:(value:Array<IFilterMultiSelectItemValueType>) => void;
}

@component
export class FilterMultiSelectInput extends React.Component<IFilterMultiSelectProps>
{
  // ---------------------------------------------------------

  // @bind
  // private _getItem(itemId:number):IFilterMultiSelectDataItem | undefined
  // {
  //   return this.props.items.find((item:IFilterMultiSelectDataItem) => item.id === itemId);
  // }

  // ---------------------------------------------------------

  @bind
  private _itemRenderer(item:IFilterMultiSelectDataItem, {
    handleClick,
    modifiers,
    index
  }:IItemRendererProps):JSX.Element | null
  {
    if( !modifiers.matchesPredicate ) return null;

    if( item.isMenuDivider ) return <MenuDivider key={`${index}-${item.value}`} />;

    return (
      <MenuItem
        key={item.value}
        text={item.name}
        onClick={handleClick}
        // selected={modifiers.active}
        active={modifiers.active}
      />
    );
  }

  @bind
  private _tagRenderer(item:IFilterMultiSelectDataItem):JSX.Element | null
  {
    return (
      <span>
        {item.name}
      </span>
    );
  }

  @bind
  private _onItemSelect(item:IFilterMultiSelectDataItem):void
  {
    const { onSelect, selectedIds } = this.props;
    console.log('%c _onItemSelect item =', 'background:#0f0;color:#000;', item);
    onSelect([...selectedIds, item.value]);
  }

  @bind
  private _onItemRemove(item:IFilterMultiSelectDataItem):void
  {
    const { onSelect, selectedIds } = this.props;

    onSelect(selectedIds.filter((id:IFilterMultiSelectItemValueType) => id !== item.value));
  }

  @bind
  private _itemPredicate(query:string, item:IFilterMultiSelectDataItem):boolean
  {
    // console.log('%c _itemPredicate query, item =', 'background:#0f0;color:#000;', query, item);
    return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  }

  // @bind
  // private _onQueryChange(query:string, event?:React.ChangeEvent<HTMLInputElement>):void
  // {
  //   console.log('%c _onQueryChange query =', 'background:#0f0;color:#000;', query);
  // }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { items, selectedIds } = this.props;

    const BPMultiSelect = MultiSelect.ofType<IFilterMultiSelectDataItem>();

    const viewedItems:Array<IFilterMultiSelectDataItem> = items
      .filter((item:IFilterMultiSelectDataItem) =>
      {
        return !selectedIds.find((selectedId:IFilterMultiSelectItemValueType) => selectedId === item.value);
      });

    const selectedItems:Array<IFilterMultiSelectDataItem> = items
      .filter((item:IFilterMultiSelectDataItem) =>
      {
        return selectedIds.includes(item.value);
      });

    return (
      <BPMultiSelect
        className={styles.selectWrapper}
        items={viewedItems}
        selectedItems={selectedItems}

        tagRenderer={this._tagRenderer}
        itemRenderer={this._itemRenderer}
        onItemSelect={this._onItemSelect}
        onRemove={this._onItemRemove}

        itemPredicate={this._itemPredicate}
        // onQueryChange={this._onQueryChange}
        resetOnSelect={true}

        placeholder={''}
        fill={true}

        noResults={<MenuItem disabled={true} text="No results" />}

        popoverProps={{
          minimal: true,
          usePortal: false,
          // position: Position.RIGHT_TOP,
          // placement: 'right-start',
          popoverClassName: cx(styles.popover, Classes.POPOVER2_DISMISS_OVERRIDE)
        }}
        tagInputProps={{
          tagProps: { minimal: true },
          inputProps: { autoFocus: true }
        }}
      />
    );
  }
}
