import { BlankState } from '@flow/common/components/form/BlankState';
import { component, di } from '@flow/dependency-injection';
import type { ReactNode } from 'react';
import React from 'react';
import { CustomersState } from '../../CustomersState';
import type { Common_Customer_Ex } from '../../models/CustomersTypes';
import styles from './CustomersList.module.less';
import { CustomersListHeader } from './CustomersListHeader';
import { CustomersListItem } from './CustomersListItem';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

@component
export class CustomersList extends React.Component
{
  @di private _customersState!:CustomersState;

  public render():ReactNode
  {
    const { customers, internalCustomers } = this._customersState;

    return (
      <>
        <CustomersListHeader />

        <div className={styles.scrolled}>
          {
            customers && customers.length
              ? (
                <div className={cx(styles.listWrapper,'test-customers-list')}>
                  {customers.map(
                    (customer:Common_Customer_Ex, index:number) => <CustomersListItem key={index} customer={customer} />
                  )}
                </div>
              )
              : <BlankState title={'No Customers'} className={styles.blankState} />
          }
          {
            internalCustomers && internalCustomers.length !== 0 &&
            <>
              <CustomersListHeader isInternal={true} />

              <div className={styles.listWrapper}>
                {internalCustomers?.map(
                  (customer:Common_Customer_Ex, index:number) => <CustomersListItem key={index} customer={customer} />
                )}
              </div>
            </>
          }
        </div>
      </>
    );
  }
}
