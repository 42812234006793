import { ButtonSize } from '@flow/common/components/form/Button';
import { TextInput } from '@flow/common/components/form/TextInput';
import { Dialog } from '@flow/common/components/page/Dialog';
import { DialogRow } from '@flow/common/components/page/DialogRow';
import { Intent } from '@flow/common/components/types/Types';
import { component, di } from '@flow/dependency-injection';
import { SelectRecruiter } from '@flow/modules/recruiting/candidates/components/candidates/newCandidate/SelectRecruiter';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';
import { CandidatesController } from '../../../CandidatesController';
import { CandidatesState } from '../../../CandidatesState';

import styles from './CandidatesCreateDialog.module.less';
import { SelectPositionGroups } from './SelectPositionGroups';

const cx = classNames.bind(styles);

@component
export class CandidatesCreateDialog extends React.Component
{
  @di private _candidatesState!:CandidatesState;
  @di private _candidatesController!:CandidatesController;

  // ---------------------------------------------------------

  @bind
  private async _saveNewCandidate():Promise<void>
  {
    const id:number | null = await this._candidatesController.saveNewCandidate();
  }

  // ---------------------------------------------------------

  public render():ReactNode
  {
    const {
      isNewCandidateDialogOpen,
      isNewCandidateLoading,
      newCandidateName,
      // newCandidateFirstName,
      // newCandidateLastName,
      newCandidateLinkedIn,
      // newCandidateImageUrl,
      newCandidatePositionGroupIds
    } = this._candidatesState;

    const isButtonDisabled:boolean =
      (newCandidateName.trim() === '') ||
      (newCandidateName.trim().indexOf(' ') === -1) ||
      (!newCandidatePositionGroupIds.length);

    return (
      <Dialog
        title={'New Candidate'}
        isOpen={isNewCandidateDialogOpen}
        isLoading={isNewCandidateLoading}
        onClose={this._candidatesController.hideNewCandidateDialog}
        className={styles.newCustomerDialog}

        buttonsSize={ButtonSize.LARGE}

        isPrimaryButtonDisabled={isButtonDisabled}
        primaryButtonText={'Add Candidate'}
        primaryButtonIntent={Intent.PRIMARY}
        primaryButtonOnClick={this._saveNewCandidate}

        secondaryButtonText={'Cancel'}
        secondaryButtonIntent={Intent.PRIMARY}
        isSecondaryButtonOutlined={true}
      >
        <DialogRow label={'Name'} labelClassName={styles.label}>
          <div className={cx('test-candidate-form-input')}>
            <TextInput
              value={newCandidateName}
              large={true}
              onChange={this._candidatesController.setNewCandidateName}
              autoFocus={true}
            />
          </div>
        </DialogRow>

        <DialogRow label={'LinkedIn'} labelClassName={styles.label}>
          <TextInput
            value={newCandidateLinkedIn}
            large={true}
            onChange={this._candidatesController.setNewCandidateLinkedIn}
          />
        </DialogRow>

        <DialogRow label={'Position Groups'} labelClassName={styles.label}>
          <SelectPositionGroups
            positionGroupsIds={this._candidatesState.positionGroupIds}
            selectedPositionGroupsIds={newCandidatePositionGroupIds}
            onSelect={this._candidatesController.setNewCandidatePositionGroupIds}
          />
        </DialogRow>

        <DialogRow label={'Recruiter'} labelClassName={styles.label}>
          <SelectRecruiter />
        </DialogRow>

      </Dialog>
    );
  }
}
