import { CommonController } from '@flow/common/CommonController';
import { RouteName } from '@flow/common/models/routing/RouteName';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di, diModule } from '@flow/dependency-injection';
import type { ReactNode } from 'react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import type { IMatchedRouteRoute } from '../../pages/RoutesConfig';
import { RoutesConfig } from '../../pages/RoutesConfig';

@diModule({
  providers: []
})
@componentWithRouter
export class Recruiting extends React.Component<IWithRouterProps>
{
  @di private _commonController!:CommonController;

  public componentDidMount():void
  {
    const { params } = this.props;

    console.log('%c -> ROOT params      =', 'background:#0ff;color:#000;', params);

    const routeConfig:IMatchedRouteRoute | null = RoutesConfig.getRouteConfig(RouteName.RECRUITING);

    console.log('%c -> ROOT RouteConfig =', 'background:#0ff;color:#000;', routeConfig);

    if( params && routeConfig && params['*'] )
    {
      const paramsPath:string = (params?.['*'] || '').replace(/\/$/, '');
      const routeConfigPath:string = routeConfig.path.replace(/\/$/, '');

      if( paramsPath !== routeConfigPath ) return;

      const redirectTo:RouteName | undefined = routeConfig.redirect;

      if( !redirectTo ) return;

      const path:string | null = RoutesConfig.getRoutePath(redirectTo);

      if( path ) this._commonController.navigate(path);
    }
  }

  public render():ReactNode
  {
    return (
      <Outlet />
    );
  }
}
