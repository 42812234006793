import { Menu, MenuItem } from '@blueprintjs/core';
import { CommonController } from '@flow/common/CommonController';
import { ButtonMore } from '@flow/common/components/elements/ButtonMore';
import { Icon, IconName, IconNames } from '@flow/common/components/form/Icon';
import { ConfirmationDialog } from '@flow/common/components/page/ConfirmationDialog';
import { Intent } from '@flow/common/components/types/Types';
import type { Maybe } from '@flow/common/models/Types';
import type { Staffing_Staff } from '@flow/data-access/lib/types/graphql.generated';
import { Staffing_Staff_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { MoreActionsSelector } from '@flow/modules/staffing/staff/components/member/actions/MoreActionsSelector';
import { ChangeStaffStatusDialog } from '@flow/modules/staffing/staff/components/member/changeStatus/ChangeStaffStatusDialog';
import { StaffMemberController } from '@flow/modules/staffing/staff/StaffMemberController';
import { StaffMemberState, StaffStatusTitle } from '@flow/modules/staffing/staff/StaffMemberState';
import bind from 'bind-decorator';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
// import classNames from 'classnames/bind';
import React from 'react';

import styles from './StaffMemberActions.module.less';

const cx = classNames.bind(styles);

interface IStaffStatusItem
{
  title:string;
  icon:IconName;
}

const StaffStatusItem:Record<string, IStaffStatusItem> = {
  [Staffing_Staff_Status_Enum.Newcomer]: {
    title: StaffStatusTitle[Staffing_Staff_Status_Enum.Newcomer],
    icon: IconNames.OFFICE
  },
  [Staffing_Staff_Status_Enum.Staffed]: {
    title: StaffStatusTitle[Staffing_Staff_Status_Enum.Staffed],
    icon: IconNames.OFFICE
  },
  [Staffing_Staff_Status_Enum.Leaving]: {
    title: StaffStatusTitle[Staffing_Staff_Status_Enum.Leaving],
    icon: IconNames.LOG_OUT
  },
  [Staffing_Staff_Status_Enum.Archived]: {
    title: StaffStatusTitle[Staffing_Staff_Status_Enum.Archived],
    icon: IconNames.PROJECTS
  }
};

@component
export class StaffMemberActions extends React.Component
{
  @di private _commonController!:CommonController;

  @di private _staffMemberState!:StaffMemberState;
  @di private _staffMemberController!:StaffMemberController;

  // ----------------------------------------------------

  @bind
  private _renderMenu():React.ReactNode | null
  {
    const { isArchived, staffMember } = this._staffMemberState;

    if( !isArchived || !staffMember ) return null;

    const { candidate } = staffMember;

    const isProfileLinkDisabled:boolean = !candidate || !candidate.id;

    return (
      <ButtonMore
        className={cx(styles.btnMore)}
        menuContent={(
          <Menu>
            <MenuItem
              text={'Move to staff'}
              onClick={():void =>
              {
                this._staffMemberController.setMoveToStaffDialog(true);
              }}
            />
            {
              !isProfileLinkDisabled &&
              <MenuItem
                text={'Candidate profile'}
                disabled={isProfileLinkDisabled}
                onClick={():void =>
                {
                  this._commonController.goToCandidate(candidate?.id);
                }}
              />
            }
          </Menu>
        )}
      />
    );
  }

  // ----------------------------------------------------

  @bind
  private _renderStatus(staffMember:Staffing_Staff):React.ReactNode | null
  {
    const { status, leaving_date, status_date } = staffMember;

    if( !status ) return null;

    const { isLeaving, isArchived } = this._staffMemberState;

    const icon:IconName = StaffStatusItem[status].icon;
    const title:string = StaffStatusItem[status].title;

    const date:Maybe<string> = isLeaving ? leaving_date : isArchived ? status_date : null;
    const mDate:Moment | null = date ? moment(date) : null;
    const strDate:string = mDate && mDate.isValid() ? mDate.format('MMMM D, YYYY') : '';

    return (
      <div className={styles.status}>
        <Icon
          className={styles.statusIcon}
          icon={icon}
        />
        <span className={styles.statusTitle}>
          {title}
        </span>
        {
          strDate &&
          <>
            <Icon
              className={styles.iconDot}
              icon={IconNames.DOT}
              size={8}
            />
            <span className={styles.hireDate}>
              {strDate}
            </span>
          </>
        }
      </div>
    );
  }

  // ----------------------------------------------------

  public render():React.ReactNode | null
  {
    const { staffMember, isShowMoveToStaffDialog } = this._staffMemberState;

    if( !staffMember ) return null;

    const roleName:string = staffMember.role?.name || '';

    return (
      <div className={styles.actionsWrapper}>

        <div className={styles.role}>
          {roleName}
        </div>

        {this._renderStatus(staffMember)}

        <MoreActionsSelector />

        {this._renderMenu()}

        <ChangeStaffStatusDialog />

        <ConfirmationDialog
          title={'Move to staff?'}
          confirmButtonText={'Confirm'}
          onConfirm={this._staffMemberController.moveToStaff}
          isOpen={isShowMoveToStaffDialog}
          onClose={():void => this._staffMemberController.setMoveToStaffDialog(false)}
          primaryButtonIntent={Intent.PRIMARY}
        />

      </div>
    );
  }

  // ----------------------------------------------------
}
