import { component, di } from '@flow/dependency-injection';
import React from 'react';
import { CommonState } from '../CommonState';

import styles from './AppLoader.module.less';

@component
export class AppLoader extends React.Component
{
  @di private _commonState!:CommonState;

  public render():React.ReactNode
  {
    return this._commonState.loadersCount > 0
      ? (
        <div className={styles.loadingOverlay}>
          <img className={styles.loadingElement}
            src="assets/images/logo/logo-flow.svg"
            alt="SiliconMint Logo"
          />
        </div>
      )
      : (
        null
      )
  }
}
