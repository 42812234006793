import { Position } from '@blueprintjs/core';
import { Classes, Popover2 } from '@blueprintjs/popover2';
import { CommonController } from '@flow/common/CommonController';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { ButtonGroupFilter } from '@flow/common/components/page/ButtonGroupFilter';
import { Toolbar } from '@flow/common/components/page/Toolbar';
import { RouteName } from '@flow/common/models/routing/RouteName';
import { component, di } from '@flow/dependency-injection';
import classNames from 'classnames/bind';
import { runInAction } from 'mobx';
import type { ReactNode } from 'react';
import type React from 'react';
import { Component } from 'react';
import { RoutesConfig } from '../../../../../pages/RoutesConfig';
import { CustomerController } from '../../CustomerController';
import { CustomerState, TeamFilter } from '../../CustomerState';
import { TeamController } from '../../TeamController';
import { TeamDialog } from '../team/TeamDialog';
import { CustomerDialog } from './CustomerDialog';
import styles from './CustomerToolbar.module.less';
import { CustomerToolbarMenu } from './CustomerToolbarMenu';

const cx = classNames.bind(styles);

@component
export class CustomerToolbar extends Component
{
  @di private _commonController!:CommonController;

  @di private _customerState!:CustomerState;
  @di private _customerController!:CustomerController;

  @di private _teamController!:TeamController;

  // ----------------------------------------------------

  private _renderActiveProspectiveSwitcher(isDesktop:boolean):React.ReactElement
  {
    return (
      <div className={cx(styles.activeProspectiveSwitcher, isDesktop ? styles.desktop : styles.mobile)}>
        <ButtonGroupFilter
          values={[
            TeamFilter.Active,
            TeamFilter.Prospective
          ]}
          titles={{
            [TeamFilter.Active]: 'Active',
            [TeamFilter.Prospective]: 'Prospective'
          }}
          value={this._customerState.teamFilterActiveProspective}
          onClick={(value):void =>
          {
            runInAction(() =>
            {
              this._customerState.teamFilterActiveProspective = value;
            });
          }}
        />
      </div>
    );
  }

  // ----------------------------------------------------

  public render():ReactNode
  {
    const { customer } = this._customerState;

    if( !customer )
      return null;

    const { alias } = customer;

    return (
      <>
        <Toolbar
          className={styles.toolbar}
          title={''}

          leftClassName={styles.positionLeft}
          leftElements={
            <>
              <Button
                className={styles.btnArrowLeft}
                icon={IconNames.ARROW_LEFT}
                minimal={true}
                onClick={():void =>
                {
                  const path:string | null = RoutesConfig.getRoutePath(RouteName.CUSTOMERS);

                  if( path )
                    this._commonController.navigate(path);
                }}
              />
              <div className={styles.title}>
                {alias} {'Teams'}
              </div>
              <Button
                className={cx(styles.btnAdd,'test-add-customer-team')}
                text={'New Team'}
                icon={IconNames.ADD}
                minimal={true}
                onClick={this._teamController.showCreateTeamDialog}
              />
              <TeamDialog onSave={this._teamController.onCustomerTeamSave} />
            </>
          }

          rightClassName={styles.positionRight}
          rightElements={
            <>
              {this._renderActiveProspectiveSwitcher(true)}
              <Popover2
                content={<CustomerToolbarMenu />}
                position={Position.BOTTOM_RIGHT}
                popoverClassName={Classes.POPOVER2_DISMISS}
              >
                <Button
                  icon={IconNames.MORE}
                  className={styles.menuButton}
                  minimal={true}
                />
              </Popover2>
              <CustomerDialog onSave={this._customerController.onCustomerSave} />
            </>
          }
        />
        <div className={styles.secondRow}>
          {this._renderActiveProspectiveSwitcher(false)}
        </div>
      </>
    );
  }
}
