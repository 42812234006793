import React from 'react';
import { Helmet } from 'react-helmet';

interface Props
{
  title?:string | null;
}

export class PageTitle extends React.PureComponent<Props>
{
  public render():React.ReactNode
  {
    const { title } = this.props;

    return (
      <Helmet>
        <title>{title ? `${String(title)} | ` : ''}SiliconMint Flow</title>
      </Helmet>
    );
  }

}
