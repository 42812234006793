import type { IconName } from '../components/form/Icon';
import { IconNames } from '../components/form/Icon';

export class FilesUtil
{
  public static formatBytes(bytes:number, decimals = 2):string
  {
    if( bytes === 0 )
      return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  public static getIconNameForMimeType(mimeType:string):IconName
  {
    if( mimeType.startsWith('image') )
      return IconNames.MEDIA as IconName;

    if( [
      'application/pdf',
      'application/msword',
      'application/vnd.ms-word',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.openxmlformats-officedocument.wordprocessingml'
    ].some(type => mimeType.startsWith(type)) )
      return IconNames.DOCUMENT as IconName;

    if( [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml',
      'application/vnd.oasis.opendocument.spreadsheet',
    ].some(type => mimeType.startsWith(type)) )
      return IconNames.DOCUMENT as IconName;

    return IconNames.PAPERCLIP as IconName;
  }

}
