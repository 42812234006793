import { Avatar } from '@flow/common/components/elements/Avatar';
import { ListWrapper } from '@flow/common/components/page/ListWrapper';
import type { Maybe } from '@flow/common/models/Types';
import type { Common_Scheduled_Event } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { ScheduleController } from '@flow/modules/recruiting/schedule/ScheduleController';
import type { ScheduleCallUser, ScheduleEvent } from '@flow/modules/recruiting/schedule/ScheduleState';
import { ScheduleState } from '@flow/modules/recruiting/schedule/ScheduleState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { action, runInAction } from 'mobx';
import moment, { Moment } from 'moment';
import React from 'react';

import styles from './ScheduleCalendar.module.less';

const cx = classNames.bind(styles);

const CALENDAR_START_HOUR:number = 0; // TODO: ???

const CALENDAR_HOURS:Array<number> = [...Array(24).keys()];

@component
export class ScheduleCalendar extends React.Component
{
  @di private _scheduleState!:ScheduleState;
  @di private _scheduleController!:ScheduleController;

  // ----------------------------------------------------

  private _scrollableYref:HTMLDivElement | null = null;
  private _resizeObserver:ResizeObserver | null = null;

  // ----------------------------------------------------

  public componentDidMount():void
  {
    this._checkScrollRefsAndResizeObserver();
  }

  public componentDidUpdate():void
  {
    this._checkScrollRefsAndResizeObserver();
  }

  @bind
  private _checkScrollRefsAndResizeObserver():void
  {
    if( !this._scrollableYref || this._resizeObserver != null ) return;

    this._resizeObserver = new ResizeObserver(() =>
    {
      runInAction(this._setWidth);
    });

    this._resizeObserver.observe(this._scrollableYref as Element);
  }

  @action.bound
  private _setWidth():void
  {
    if( !this._scrollableYref ) return;

    this._scheduleState.scrollBarWidth = this._scrollableYref.offsetWidth - this._scrollableYref.clientWidth;
  }

  // ----------------------------------------------------

  // private static renderBusyCell(isBusy:boolean):React.ReactNode | null
  // {
  //   if( !isBusy ) return null;
  //
  //   return (
  //     <svg
  //       className={styles.isBusy}
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="100%"
  //       height="calc(100%)"
  //     >
  //       <defs>
  //         <pattern
  //           id="pattern_L8iRv" patternUnits="userSpaceOnUse" width="7.25" height="7.25"
  //           patternTransform="rotate(45)"
  //         >
  //           <line x1="0" y="0" x2="0" y2="7.25" stroke="#aaaaaa" strokeWidth="1" />
  //         </pattern>
  //       </defs>
  //       <rect width="100%" height="100%" fill="url(#pattern_L8iRv)" opacity="1" />
  //     </svg>
  //   );
  // }

  // ----------------------------------------------------

  @bind
  private _renderUsersHeader():React.ReactNode | null
  {
    const { scheduledEventFreeBusy } = this._scheduleState;

    return (
      <div className={styles.users}>
        {
          scheduledEventFreeBusy.map((user:ScheduleCallUser, index:number) =>
          {
            if( !user || !user.user )
              return null;

            return (
              <div
                key={index} // TODO: key -> user.id ?
                className={styles.user}
              >
                <Avatar size={20} className={styles.avatar} url={user.user.avatar} />
                <div className={styles.userText}>
                  <div className={styles.userName}>
                    {user.user.first_name} {user.user.last_name}
                  </div>
                  <div className={styles.userTime}>
                    {user.timezone}
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }

  // ----------------------------------------------------

  @bind
  private _renderUserHourEvents(hourIndex:number):React.ReactNode | null
  {
    const { scheduledEventFreeBusy, viewDate, callDetailsEventId, eventsForEdit } = this._scheduleState;

    if( !callDetailsEventId ) return null;

    const eventForEdit:Maybe<Partial<Common_Scheduled_Event>> = eventsForEdit.get(callDetailsEventId)?.event;

    if( !eventForEdit ) return null;

    const dateMoment:Moment = moment(viewDate);
    const currentHour:Moment = moment(dateMoment)
      .set('hour', hourIndex + CALENDAR_START_HOUR)
      .set('minute', 0).set('second', 0).set('millisecond', 0);

    const selectedEvent:ScheduleEvent | null = {
      id: -1,
      start_time: moment(eventForEdit.start_time).toDate().toUTCString(),
      end_time: moment(eventForEdit.end_time).toDate().toUTCString(),
      selected: true,
      isFreeBusy: false
    };

    return (
      <>
        {
          scheduledEventFreeBusy.map((user:ScheduleCallUser, userIndex:number) =>
          {
            const allUserEvents:Array<ScheduleEvent> = selectedEvent && userIndex === 0
              ? [...user.events, selectedEvent] : user.events;

            const events:Array<ScheduleEvent> = allUserEvents.filter((event:ScheduleEvent) =>
            {
              return moment(event.start_time)
                .isBetween(currentHour, moment(currentHour).add(1, 'h'), 'hour', '[)') as boolean;
            });

            // events started before top border of the calendar
            if( hourIndex === 0 )
            {
              allUserEvents.forEach((event:ScheduleEvent) =>
              {
                const isBetween:boolean = currentHour.isBetween(moment(event.start_time) as Moment, moment(event.end_time) as Moment);

                if( isBetween ) events.push(event);
              });
            }

            return (
              <div
                key={userIndex} // TODO: key -> user.id ?
                className={cx(styles.hour)}
              >
                {/*{ScheduleCalendar.renderBusyCell(isBusy)}*/}
                {this._renderEvents(events, userIndex, hourIndex, currentHour)}
              </div>
            );
          })
        }
      </>
    );
  }

  // ----------------------------------------------------

  @bind
  private _renderEvents(events:Array<ScheduleEvent>, userIndex:number, hourIndex:number, currentHour:Moment):React.ReactNode | null
  {
    if( !events.length ) return null;

    const { scheduledEventFreeBusy } = this._scheduleState;

    return (
      <>
        {
          events.map((event:ScheduleEvent, index:number) =>
          {
            const isSelected:boolean = event.selected ?? false;

            const isFreeBusy:boolean = event.isFreeBusy;

            const evStartTime:Moment = moment(event.start_time);
            const evEndTime:Moment = moment(event.end_time);

            let startHour:number = evStartTime.get('hour');
            let endHour:number = evEndTime.get('hour');

            let durationInMinutes:number = evEndTime.diff(evStartTime, 'minutes');
            let durationInHours:number = durationInMinutes / 60;

            let startMinutesInHours:number = evStartTime.get('minutes') / 60;

            let topOut:boolean = false;
            let bottomOut:boolean = false;

            if( hourIndex === 0 && evStartTime.isBefore(currentHour) )
            {
              topOut = true;
              const deltaBeforeInMinutes:number = currentHour.diff(evStartTime, 'minutes');
              durationInMinutes -= deltaBeforeInMinutes;
              durationInHours -= deltaBeforeInMinutes / 60;
              startMinutesInHours = 0;
              startHour = currentHour.get('hour');
            }

            const endIn24Hours:number = hourIndex + startMinutesInHours + durationInHours;

            // console.log('%c hourIndex =', 'background:#0f0;color:#000;', hourIndex, durationInHours, endIn24Hours);

            let endMinutes:number = evEndTime.get('minutes');

            let deltaHeightInHours:number = 0;

            // out of the bottom border of the calendar
            if( endIn24Hours > 24 )
            {
              bottomOut = true;
              deltaHeightInHours = endIn24Hours - 24;
              durationInHours -= deltaHeightInHours;
              durationInMinutes = durationInHours * 60;
              endHour = Math.floor(startHour + durationInHours);
              endMinutes = 0;
            }

            const lessThenHour:boolean = durationInMinutes < 60;
            const lessThenOr30min:boolean = durationInMinutes <= 30;
            const lessThenOr15min:boolean = durationInMinutes <= 15;

            const hourHeightForCalc:number = lessThenHour &&
            (startHour === endHour || (endHour - startHour === 1 && endMinutes === 0)) ? 40 : 41;
            //  (8).30 === (8).45  ||   (9).00 - (8).30    === 1        9.(00) === 0

            // if( endIn24Hours > 24 )
            // {
            //   console.log('%c lessThenHour =', 'background:#0f0;color:#000;', lessThenHour, startHour, endHour);
            //   console.log('%c hourHeightForCalc =', 'background:#0f0;color:#000;', hourHeightForCalc);
            // }

            const intPart:number = Math.floor(durationInHours);
            const fractPart:number = durationInHours - intPart;

            const minHeight:number = (lessThenHour
                ? Math.ceil(hourHeightForCalc * durationInHours)
                : Math.ceil(hourHeightForCalc * intPart + 40 * fractPart)
              ) -
              (endMinutes !== 0 || lessThenHour
                  ? (bottomOut ? 0 : 1)
                  : (bottomOut ? (fractPart ? 0 : 1) : (fractPart ? 1 : 2))
              );

            const top:number = 40 * startMinutesInHours;
            const width:string = isSelected
              ? `calc(${100 * scheduledEventFreeBusy.length}% - 10px + ${scheduledEventFreeBusy.length - 1}px)` : '';

            const style:React.CSSProperties = { top, minHeight, maxHeight: minHeight, width };

            // const isNotValid:boolean = isSelected && this._scheduleController.isSelectedEventNotValid();

            return (
              <div
                key={index} // TODO: key -> event.id ?
                className={cx(styles.event, { isSelected, bottomOut, topOut, isFreeBusy })}
                style={style}
              >
                <div
                  className={cx(styles.eventHours, { lessThenOr30min, lessThenOr15min })}
                >
                  {evStartTime.format('h.mm A')} - {evEndTime.format('h.mm A')}
                </div>
              </div>
            );
          })
        }
      </>
    );
  }

  // ----------------------------------------------------

  @bind
  private _renderHeader():React.ReactNode | null
  {
    const timeZone:number = moment().utcOffset() / 60;
    const timeZoneStr:string = `GMT${timeZone >= 0 ? '+' : '-'}${timeZone}`;

    return (
      <div className={styles.usersRow}>

        <div className={styles.timeWrapper}>
          <div className={styles.time}>
            {timeZoneStr}
          </div>
        </div>

        {this._renderUsersHeader()}

      </div>
    );
  }

  // ----------------------------------------------------

  @bind
  private _renderContent():React.ReactNode | null
  {
    const { viewDate } = this._scheduleState;

    const dateMoment:Moment = moment(viewDate);

    return (
      <div className={styles.hours}>
        {
          CALENDAR_HOURS.map((h:unknown, hourIndex:number) =>
          {
            const hourLabel:Moment = moment(dateMoment).set('hour', hourIndex + CALENDAR_START_HOUR + 1); // !!! -> +1

            return (
              <div
                key={hourIndex}
                className={styles.hourRow}
              >
                <div className={styles.timeWrapper}>
                  <div className={styles.time}>
                    {hourLabel.format('h A')}
                  </div>
                </div>

                {this._renderUserHourEvents(hourIndex)}

              </div>
            );
          })
        }
      </div>
    );
  }

  // ----------------------------------------------------

  public render():React.ReactNode | null
  {
    const { isScheduleLoaded } = this._scheduleState;

    if( !isScheduleLoaded ) return null;

    return (
      <div className={styles.wrapper}>

        <ListWrapper
          header={this._renderHeader()}
          content={this._renderContent()}
        />

      </div>
    );
  }

  // ----------------------------------------------------
}
