import { Checkbox as BPCheckbox } from '@blueprintjs/core';
import type { CheckboxProps } from '@blueprintjs/core/lib/esm/components/forms/controls';
import bind from 'bind-decorator';
import { type ChangeEvent } from 'react';
import type * as React from 'react';
import { PureComponent } from 'react';

// import classNames from 'classnames/bind';
// import styles from './Checkbox.module.less';
// const cx = classNames.bind(styles);

export interface ICheckboxProps extends Omit<CheckboxProps, 'onChange'>
{
  className?:string;
  onChange?:(newValue:boolean) => void;
}

export class Checkbox extends PureComponent<ICheckboxProps>
{
  @bind
  private _onChange(event:ChangeEvent<HTMLInputElement>):void
  {
    const { onChange } = this.props;

    if( onChange )
      onChange(event.target.checked);
  }

  public render():React.ReactNode
  {
    const { className } = this.props;

    return (
      <BPCheckbox
        {...this.props}
        className={className}
        onChange={this._onChange}
      />
    );
  }
}
