import type { Recruiting_Candidate } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { InterviewFlowsController } from '@flow/modules/recruiting/status/InterviewFlowsController';
import classNames from 'classnames/bind';
import { computed } from 'mobx';
import type { ReactNode } from 'react';
import React from 'react';
import type { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { InterviewFlowsState } from '../InterviewFlowsState';
import type { IInterviewFlowGroup, IInterviewFlowGroupStage } from '../models/InterviewFlowGroup';
import { CandidateCard } from './CandidateCard';

import styles from './InterviewFlowStage.module.less';

const cx = classNames.bind(styles);

export interface IInterviewFlowStageProps
{
  interviewFlowGroup:IInterviewFlowGroup;
  stage:IInterviewFlowGroupStage;
  provided:DroppableProvided;
  snapshot:DroppableStateSnapshot;
}

@component
export class InterviewFlowStage extends React.Component<IInterviewFlowStageProps>
{
  @di private _interviewFlowsState!:InterviewFlowsState;
  @di private readonly _interviewFlowsController!:InterviewFlowsController;

  // ----------------------------------------------------

  @computed
  private get _isDragging():boolean
  {
    const { interviewFlowGroup, stage } = this.props;
    const { draggingInterviewFlowGroupId, draggingCandidate } = this._interviewFlowsState;

    const interviewStageId:number = draggingCandidate?.interview_flow_stage_id || -1;

    const isFromLastStage:boolean = this._interviewFlowsController.isLastStage(draggingCandidate?.interview_flow_stage_id);

    return draggingInterviewFlowGroupId === interviewFlowGroup.id
      && stage.id <= (interviewStageId + 1)
      && this._interviewFlowsState.isDragging
      && !isFromLastStage;
  }

  // ----------------------------------------------------

  @computed
  private get _isDraggingOver():boolean
  {
    const { interviewFlowGroup, stage, snapshot } = this.props;
    const interviewStageId:number = this._interviewFlowsState.draggingCandidate?.interview_flow_stage_id || -1;

    return this._interviewFlowsState.draggingInterviewFlowGroupId === interviewFlowGroup.id
      && stage.id <= (interviewStageId + 1)
      && snapshot.isDraggingOver as boolean;
  }

  // ----------------------------------------------------

  public render():ReactNode
  {
    const { interviewFlowGroup, stage, provided } = this.props;

    return (
      <div
        {...provided.droppableProps}
        ref={provided.innerRef}
        className={cx('cardsWrapper', { isDragging: this._isDragging, isDraggingOver: this._isDraggingOver })}
      >
        {stage.candidates.map((candidate:Recruiting_Candidate, index:number) => (
          <React.Fragment key={candidate.id}>
            <CandidateCard
              candidate={candidate}
              index={index}
              interviewFlowGroupId={interviewFlowGroup.id}
            />
          </React.Fragment>
        ))}
        {provided.placeholder}
      </div>
    );
  }

  // ----------------------------------------------------
}
