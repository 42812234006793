import type { IFilter, IFilterValue } from '@flow/common/components/filters/Filter';
import { FilterType } from '@flow/common/components/filters/Filter';
import { StringUtil } from '@flow/common/utils/StringUtil';
import type {
  Common_Filter,
  Common_Skill_Tag,
  Common_User,
  Recruiting_Candidate,
  Recruiting_Interview_Flow_Stage,
  Recruiting_Position_Group
} from '@flow/data-access/lib/types/graphql.generated';
import { Recruiting_Candidate_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { state } from '@flow/dependency-injection';
import type { ICandidateSearchItem } from '@flow/modules/recruiting/candidates/components/candidates/CandidatesSearch';
import type { RecruiterMenuItem } from '@flow/modules/recruiting/candidates/components/candidates/newCandidate/SelectRecruiter';
import type { IReactionDisposer } from 'mobx';
import { computed, observable } from 'mobx';
import { computedFn } from 'mobx-utils';
import type { CandidatesColumnType, ICandidatesColumn } from './CandidatesColumnsState';
import { CandidatesColumnsState } from './CandidatesColumnsState';
import { CandidatesFiltersState } from './CandidatesFiltersState';

export enum CandidateFormMode
{
  NONE = 'NONE',
  ADD = 'ADD',
  EDIT = 'EDIT',
  VIEW = 'VIEW'
}

export enum SaveFilterMode
{
  NEW = 'NEW',
  OVERWRITE = 'OVERWRITE',
}

export enum CandidatesStatusFilter
{
  Initial = 'Initial',
  InWork = 'InWork',
  Archived = 'Archived'
}

export const CandidatesStatusFilterTitles = {
  [CandidatesStatusFilter.Initial]: 'Initial',
  [CandidatesStatusFilter.InWork]: 'In Work',
  [CandidatesStatusFilter.Archived]: 'Archived'
};

export const CandidateStatusTitles:Map<string, string> = new Map([
  [Recruiting_Candidate_Status_Enum.Initial as string, 'Initial'],
  [Recruiting_Candidate_Status_Enum.Active as string, 'In Work'],
  [Recruiting_Candidate_Status_Enum.Staffed as string, 'Staffed'],
  [Recruiting_Candidate_Status_Enum.Archived as string, 'Archived'],
  [Recruiting_Candidate_Status_Enum.Declined as string, 'Declined'],
  [Recruiting_Candidate_Status_Enum.Refused as string, 'Refused']
]);

export const CandidatesState_LS_KEY:string = 'candidatesState';
export const CandidatesUrl_LS_KEY:string = 'candidatesUrl';

@state
export class CandidatesState
{
  // ----------------------------------------------------

  public disposers:Array<IReactionDisposer> = [];
  @observable public isApplyingFromLocalStorage:boolean = false;

  // ----------------------------------------------------

  @observable public candidates:Array<Recruiting_Candidate> = [];

  @observable public currentPage:number = 1;
  @observable public itemsPerPage:number = 20;
  @observable public itemsTotal:number = 0;
  @observable public candidatesListScrollYPosition = 0;

  // ----------------------------------------------------

  @observable public positionGroups:Array<Recruiting_Position_Group> = [];
  @observable public skillTags:Array<Common_Skill_Tag> = [];
  @observable public userFilters:Array<Common_Filter> = [];
  @observable public interviewFlowStages:Array<Recruiting_Interview_Flow_Stage> = [];
  @observable public users:Array<Common_User> = [];
  @observable public statuses:Array<Recruiting_Candidate_Status_Enum> = [];

  @observable public selectedSkillTags:Array<Common_Skill_Tag> = [];

  @observable public candidateData!:Recruiting_Candidate;

  @observable public candidateFormMode:CandidateFormMode = CandidateFormMode.NONE;

  @observable public filters:Array<IFilter<IFilterValue>> = CandidatesFiltersState;
  @observable public columns:Array<ICandidatesColumn> = CandidatesColumnsState;

  @observable public searchQuery:string = '';
  @observable public searchResult:Array<ICandidateSearchItem> = [];
  @observable public isSearching:boolean = false;

  @observable public isCandidatesInitialized = false;
  @observable public isCandidatesLoaded:boolean = false;
  @observable public isCandidatePageLoaded:boolean = false;

  // ----------------------------------------------------

  @observable public viewCandidateId:number | null = null;

  @observable public isViewCandidatesPageContent:boolean = false;

  // ----------------------------------------------------

  @observable public isFiltersDirty:boolean = false;

  @observable public isSaveFilterDialogOpen:boolean = false;
  @observable public isSaveFilterLoading:boolean = false;

  @observable public saveFilterMode:SaveFilterMode = SaveFilterMode.NEW;
  @observable public saveFilterName:string = '';
  @observable public overwriteFilterId:number | null = null;

  @observable public selectedFilterId:number | null = null;

  @observable public candidatesStatusFilterValue:CandidatesStatusFilter = CandidatesStatusFilter.Initial;

  // ----------------------------------------------------

  @computed
  public get someFilterIsSelected():boolean
  {
    return this.filters.some(f => f.isSelected);
  }

  // ----------------------------------------------------

  @computed
  public get orderedBy():ICandidatesColumn | null
  {
    let orderedColumn:ICandidatesColumn | null = null;

    this.columns.forEach((columnItem:ICandidatesColumn) =>
    {
      if( orderedColumn ) return;
      if( columnItem.orderBy ) orderedColumn = columnItem;
    });

    return orderedColumn;
  }

  // @computed
  // public get filteredCandidates():Array<Recruiting_Candidate>
  // {
  //   return this.candidates
  //     .filter((candidate:Recruiting_Candidate) =>
  //     {
  //       switch( this.candidatesStatusFilterValue )
  //       {
  //         case CandidatesStatusFilter.Initial:
  //           return candidate.status == Recruiting_Candidate_Status_Enum.Initial;
  //         case CandidatesStatusFilter.InWork:
  //           return candidate.status == Recruiting_Candidate_Status_Enum.Active;
  //         case CandidatesStatusFilter.Archived:
  //           return candidate.status == Recruiting_Candidate_Status_Enum.Archived
  //             || candidate.status == Recruiting_Candidate_Status_Enum.Staffed
  //             || candidate.status == Recruiting_Candidate_Status_Enum.Declined;
  //       }
  //     })
  //     .filter((candidate:Recruiting_Candidate) =>
  //     {
  //       const isSomeFilterHidesCandidate:boolean = this.filters.some((filter:IFilter<IFilterValue>) =>
  //       {
  //         if( !filter.isSelected || !filter.filterFunction ) return false;

  //         let filterFunctionData:any = null;

  //         switch( filter.type )
  //         {
  //           case FilterType.DATE_CREATED:
  //             filterFunctionData = candidate.created_at;
  //             break;

  //           case FilterType.POSITION_GROUP:
  //             filterFunctionData = candidate.candidate_position_groups.map(pg => pg.position_group_id);
  //             break;

  //           case FilterType.FLOW_STAGE:
  //             filterFunctionData = candidate.interview_flow_stage;
  //             break;

  //           case FilterType.ASSIGNED_TO:
  //             filterFunctionData = candidate.assigned_to?.id ? [candidate.assigned_to.id] : [];
  //             break;

  //           case FilterType.CREATED_BY:
  //             filterFunctionData = [candidate.created_by.id];
  //             break;

  //           case FilterType.STATUS:
  //             filterFunctionData = candidate.status;
  //             break;
  //         }

  //         const isItemVisible:boolean = filter.filterFunction(filterFunctionData);

  //         return !isItemVisible; // some item not visible
  //       });

  //       return !isSomeFilterHidesCandidate;
  //     })
  //     .sort(this._sortCandidates);
  // }

  // @bind
  // private _sortCandidates(a:Recruiting_Candidate, b:Recruiting_Candidate):number
  // {
  //   if( !this.orderedBy ) return 0;

  //   let returnedValue:number;
  //   let strA:string = '';
  //   let strB:string = '';
  //   const numA:number = 0;
  //   const numB:number = 0;
  //   const isSortByString:boolean = true;

  //   switch( this.orderedBy.type )
  //   {
  //     case CandidatesColumnType.NAME:
  //       strA = `${a.first_name} ${a.last_name}`;
  //       strB = `${b.first_name} ${b.last_name}`;
  //       break;

  //     case CandidatesColumnType.DATE_CREATED:
  //       strA = a.created_at;
  //       strB = b.created_at;
  //       break;

  //     case CandidatesColumnType.POSITION_GROUPS:
  //       strA = this.strPositionGroupsByCandidateId(a.id);
  //       strB = this.strPositionGroupsByCandidateId(b.id);
  //       break;

  //     case CandidatesColumnType.STATUS:
  //       strA = this.interviewFlowStageById(a.interview_flow_stage?.id)?.name || '';
  //       strB = this.interviewFlowStageById(b.interview_flow_stage?.id)?.name || '';
  //       break;

  //     case CandidatesColumnType.ASSIGNED_TO:
  //       strA = a.assigned_to ? `${this.userById(a.assigned_to?.id)?.first_name} ${this.userById(a.assigned_to?.id)?.last_name}` : '';
  //       strB = b.assigned_to ? `${this.userById(b.assigned_to?.id)?.first_name} ${this.userById(b.assigned_to?.id)?.last_name}` : '';
  //       break;

  //     case CandidatesColumnType.CREATED_BY:
  //       strA = `${this.userById(a.created_by?.id)?.first_name} ${this.userById(a.created_by?.id)?.last_name}`;
  //       strB = `${this.userById(b.created_by?.id)?.first_name} ${this.userById(b.created_by?.id)?.last_name}`;
  //       break;
  //   }

  //   const asc:boolean = this.orderedBy.orderBy === OrderByType.ASC;

  //   // ASC
  //   if( isSortByString )
  //   {
  //     strA = strA.trim().toLowerCase();
  //     strB = strB.trim().toLowerCase();

  //     if( strA === '' && strB )         // '' | 'str' => first: 'str' second: ''
  //       returnedValue = asc ? 1 : -1;
  //     else if( strA && strB === '' )    // 'str' | '' => first: 'str' second: ''
  //       returnedValue = asc ? -1 : 1;
  //     else
  //       returnedValue = strA > strB ? 1 : -1;
  //   }
  //   else
  //   {
  //     returnedValue = numA - numB;
  //   }

  //   // DESC
  //   if( !asc )
  //   {
  //     returnedValue *= -1;
  //   }

  //   return returnedValue;
  // }

  // @computed
  // public get orderedCandidates():Array<Recruiting_Candidate>
  // {
  //   return this.candidates.slice().sort((a, b) =>
  //   {
  //     if( !this.orderedBy ) return 0;
  //
  //     let returnedValue:number;
  //     let strA:string = '';
  //     let strB:string = '';
  //     const numA:number = 0;
  //     const numB:number = 0;
  //     const isSortByString:boolean = true;
  //
  //     switch( this.orderedBy.type )
  //     {
  //       case CandidatesColumnType.NAME:
  //         strA = `${a.first_name} ${a.last_name}`;
  //         strB = `${b.first_name} ${b.last_name}`;
  //         break;
  //
  //       case CandidatesColumnType.DATE_CREATED:
  //         strA = a.created_at;
  //         strB = b.created_at;
  //         break;
  //
  //       case CandidatesColumnType.POSITION_GROUPS:
  //         strA = this.strPositionGroupsByCandidateId(a.id);
  //         strB = this.strPositionGroupsByCandidateId(b.id);
  //         break;
  //
  //       case CandidatesColumnType.STATUS:
  //         strA = a.interview_flow_stage?.name || '';
  //         strB = b.interview_flow_stage?.name || '';
  //         break;
  //
  //       case CandidatesColumnType.ASSIGNED_TO:
  //         strA = `${a.assigned_to?.first_name} ${a.assigned_to?.last_name}`;
  //         strB = `${b.assigned_to?.first_name} ${b.assigned_to?.last_name}`;
  //         break;
  //     }
  //
  //     // ASC
  //     if( isSortByString )
  //     {
  //       returnedValue = strA.toLowerCase() > strB.toLowerCase() ? 1 : -1;
  //     }
  //     else
  //     {
  //       returnedValue = numA - numB;
  //     }
  //
  //     // DESC
  //     if( this.orderedBy.orderBy === OrderByType.DESC )
  //     {
  //       returnedValue *= -1;
  //     }
  //
  //     return returnedValue;
  //   });
  // }

  // ----------------------------------------------------

  // @computed
  // public get candidatesForSearch():Array<ICandidateSearchItem>
  // {
  //   return this.candidates
  //     .map((candidate:Recruiting_Candidate) =>
  //     {
  //       return {
  //         id: candidate.id,
  //         avatarUrl: candidate.avatar_url || '',
  //         firstName: candidate.first_name,
  //         lastName: candidate.last_name,
  //         email: candidate.email || '',
  //         linkedIn: candidate.linkedin_profile_url || ''
  //         // positionGroups: this._candidatesState.strPositionGroupsByCandidateId(candidate.id)
  //       };
  //     });
  // }

  // ----------------------------------------------------

  @observable public isNewCandidateDialogOpen:boolean = false;

  @observable public isNewCandidateLoading:boolean = false;
  @observable public newCandidateName:string = '';
  // @observable public newCandidateFirstName:string = '';
  // @observable public newCandidateLastName:string = '';
  @observable public newCandidateLinkedIn:string = '';
  // @observable public newCandidateImageUrl:string = '';
  @observable public newCandidatePositionGroupIds:Array<number> = [];
  @observable public newCandidateAssignedUserId:number | null = null;

  // ----------------------------------------------------

  @computed
  public get isFormModeNone():boolean
  {
    return this.candidateFormMode === CandidateFormMode.NONE;
  }

  @computed
  public get isFormModeAdd():boolean
  {
    return this.candidateFormMode === CandidateFormMode.ADD;
  }

  @computed
  public get isFormModeEdit():boolean
  {
    return this.candidateFormMode === CandidateFormMode.EDIT;
  }

  @computed
  public get isFormModeView():boolean
  {
    return this.candidateFormMode === CandidateFormMode.VIEW;
  }

  // ----------------------------------------------------

  public candidateById:(id:number) => Recruiting_Candidate | undefined =
    computedFn((id:number):Recruiting_Candidate | undefined =>
    {
      return this.candidates.find((candidate:Recruiting_Candidate) => candidate.id === id);
    });

  public positionGroupById:(id:number) => Recruiting_Position_Group | undefined =
    computedFn((id:number):Recruiting_Position_Group | undefined =>
    {
      return this.positionGroups.find((positionGroup:Recruiting_Position_Group) => positionGroup.id === id);
    });

  // public strPositionGroupsByCandidateId:(id:number) => string =
  //   computedFn((id:number):string =>
  //   {
  //     const candidate:Recruiting_Candidate | undefined = this.candidateById(id);

  //     if( !candidate || !candidate.candidate_position_groups ) return '';

  //     return candidate.candidate_position_groups.map((cpg:Recruiting_Candidate_Position_Group) =>
  //     {
  //       const positionGroup:Recruiting_Position_Group | undefined = this.positionGroupById(cpg.position_group_id);
  //       return positionGroup?.name || '';
  //     }).join(', ');
  //   });

  // ----------------------------------------------------

  @computed
  public get positionGroupIds():Array<number>
  {
    return this.positionGroups.map((positionGroup:Recruiting_Position_Group) => positionGroup.id);
  }

  // ----------------------------------------------------

  public filterByType:(filterType:FilterType) => IFilter<IFilterValue> | null =
    computedFn((filterType:FilterType):IFilter<IFilterValue> | null =>
    {
      let filter:IFilter<IFilterValue> | null = null;

      this.filters.forEach((f:IFilter<IFilterValue>) =>
      {
        if( f.type === filterType ) filter = f;
      });

      if( !filter )
      {
        // throw new Error(`!!! filterByType ERROR: filter '${filterType}' not found`);
        console.log('%c !!! filterByType ERROR: =', 'background:#f00;color:#ff0;', `filter '${filterType}' not found`);
      }

      return filter;
    });

  // ----------------------------------------------------

  public columnByType:(columnType:CandidatesColumnType) => ICandidatesColumn =
    computedFn((columnType:CandidatesColumnType):ICandidatesColumn =>
    {
      // return this.columns[columnType];
      let column:ICandidatesColumn | null = null;

      this.columns.forEach((columnItem:ICandidatesColumn) =>
      {
        if( columnItem.type === columnType ) column = columnItem;
      });

      if( !column )
      {
        throw new Error(`!!! columnByType ERROR: column '${columnType}' not found`);
      }

      return column;
    });

  // ----------------------------------------------------

  public interviewFlowStageById:(interviewFlowStageId:number | undefined) => Recruiting_Interview_Flow_Stage | undefined =
    computedFn((interviewFlowStageId:number | undefined):Recruiting_Interview_Flow_Stage | undefined =>
    {
      if( !interviewFlowStageId ) return undefined;

      return this.interviewFlowStages.find((interviewFlowStage:Recruiting_Interview_Flow_Stage) => interviewFlowStage.id === interviewFlowStageId);
    });

  // ----------------------------------------------------

  public userFilterById:(userFilterId:number | null | undefined) => Common_Filter | undefined =
    computedFn((userFilterId:number | null | undefined):Common_Filter | undefined =>
    {
      if( !userFilterId ) return undefined;

      return this.userFilters.find((userFilter:Common_Filter) => userFilter.id === userFilterId);
    });

  // ----------------------------------------------------

  public userById:(userId:number | null | undefined) => Common_User | undefined =
    computedFn((userId:number | null | undefined):Common_User | undefined =>
    {
      if( !userId ) return undefined;

      return this.users.find((user:Common_User) => user.id === userId);
    });

  public userNameByUserId:(userId:number | null | undefined) => string =
    computedFn((userId:number | null | undefined):string =>
    {
      if( !userId ) return '';

      const user = this.users.find((user:Common_User) => user.id === userId);

      return StringUtil.getUserName(user);
    });

  // ----------------------------------------------------

  @computed
  public get usersForRecruiterMenu():Array<Common_User>
  {
    return this.users
      .filter((user:Common_User) =>
      {
        return (
          user.role_names?.includes('recruiting-manager') ||
          user.role_names?.includes('recruiter') ||
          user.role_names?.includes('sourcer')
        );
      });
  }

  @computed
  public get recruiterMenuItems():Array<RecruiterMenuItem>
  {
    return [
      { id: null, name: 'Unassigned', isMenuDivider: true },
      ...this.usersForRecruiterMenu.map((user:Common_User) =>
      {
        return { id: user.id, name: this.userNameByUserId(user.id) };
      })
    ];
  }

  @computed
  public get selectedRecruiter():RecruiterMenuItem | undefined
  {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.recruiterMenuItems.find((item:RecruiterMenuItem) => item.id === this.newCandidateAssignedUserId);
  }

  // ----------------------------------------------------
}
