import { Alignment } from '@blueprintjs/core';
import { CommonController } from '@flow/common/CommonController';
import { SvgIcon, SvgIconName } from '@flow/common/components/elements/SvgIcon';
import { Button } from '@flow/common/components/form/Button';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { Switch } from '@flow/common/components/form/Switch';
import { Intent } from '@flow/common/components/types/Types';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { component, di } from '@flow/dependency-injection';
import { PositionInfo } from '@flow/modules/recruiting/position/components/PositionInfo';
import { PositionController } from '@flow/modules/recruiting/position/PositionController';
import { PositionState } from '@flow/modules/recruiting/position/PositionState';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './PositionSidebar.module.less';

const cx = classNames.bind(styles);

@component
export class PositionSidebar extends React.Component
{
  @di private _commonController!:CommonController;
  @di private _roleController!:RoleController;
  @di private _positionState!:PositionState;
  @di private _positionController!:PositionController;

  // ----------------------------------------------------

  public render():React.ReactNode | null
  {
    const { isPositionLoaded, position, isEditMode } = this._positionState;

    if( !isPositionLoaded || !position ) return null;

    const btnEditTitle:string = isEditMode ? 'Back to viewing mode' : 'Edit description';

    // TODO: -> isReady
    // const isReady:boolean = position.isReady;
    const isReady:boolean = position.is_ready;

    const lastModifiedUser:string = 'Denis K';
    const lastModifiedDate:string = 'Dec 2, 2021';

    return (
      <>
        <PositionInfo />

        <a
          href={`https://docs.google.com/document/d/${position.google_doc_ref}`}
          target="_new"
          className={styles.link}
        >
          <SvgIcon className={cx(styles.icon, styles.drive)} icon={SvgIconName.GoogleDrive} />
          Open with Drive
        </a>

        <a
          href={`https://docs.google.com/document/d/${position.google_doc_ref}/export?format=pdf`}
          target="_new"
          className={styles.link}
        >
          <Icon className={styles.icon} icon={IconNames.DOCUMENT} />
          Save as PDF
        </a>

        <div className={styles.divider} />

        <Button
          className={styles.btnEditDescription}
          text={btnEditTitle}
          intent={Intent.PRIMARY}
          disabled={!this._roleController.hasPermission(FlowPermissions.EditPosition)}
          onClick={():void => this._positionController.setEditMode(!isEditMode)}
        />

        <div className={styles.lastModified}>
          Last modified by {lastModifiedUser} <br />
          {lastModifiedDate}
        </div>

        <div className={styles.readyWrapper}>
          <Switch
            className={styles.switchReady}
            label={'Description is ready'}
            checked={isReady}
            alignIndicator={Alignment.RIGHT}
            disabled={!this._roleController.hasPermission(FlowPermissions.ChangePositionReadinessState)}
            onChange={this._positionController.toggleIsReady}
          />
        </div>

      </>
    );
  }

  // ----------------------------------------------------
}
