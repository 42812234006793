import type { Recruiting_Position_Template } from '@flow/data-access/lib/types/graphql.generated';
import { state } from '@flow/dependency-injection';
import { forEach } from 'lodash';
import { computed, observable } from 'mobx';

@state
export class NewPositionState
{
  // Create Position Dialog ----------------
  @observable public isPositionDialogOpen:boolean = false;
  @observable public isPositionLoading:boolean = false;
  @observable public search:string = '';
  @observable public selectedPositionTemplate:Recruiting_Position_Template | null = null;

  @observable public movingId:number = -1;
  @observable public moveUpId:number = -1;
  @observable public moveDownId:number = -1;
  @observable public positionTemplates:Array<Recruiting_Position_Template> = [];
  @observable public clickedPositionDialogItemId?:number = -1;

  @computed
  public get filteredPositionTemplates():Array<Recruiting_Position_Template>
  {
    return !this.search
      ? this.positionTemplates
      : this.positionTemplates.filter(
        (positionTemplate:Recruiting_Position_Template) =>
        {
          return positionTemplate.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase());
        }
      );
  }

  @computed
  public get filteredAndOrderedPositionTemplates():Array<Array<Recruiting_Position_Template>>
  {
    const check = !this.search
      ? this.groupItemByNestedProperty(this.positionTemplates, 'staff_role.name')
      : this.groupItemByNestedProperty(this.positionTemplates.filter(
        (positionTemplate:Recruiting_Position_Template) =>
        {
          return positionTemplate.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase());
        }), 'staff_role.name');

    return check as Array<Array<Recruiting_Position_Template>>;
  }

  public groupItemByNestedProperty(array:Array<object>, property:string):Array<object>
  {
    const hash:Array<any> = [],
      props = property.split('.');
    for( let i = 0; i < array.length; i++ )
    {
      const key = props.reduce((acc:any, prop:any) =>
      {
        return acc && acc[prop];
      }, array[i]);
      if( !hash[key] ) hash[key] = [];
      hash[key].push(array[i]);
    }

    return hash;
  }

  @computed
  public get isPositionValid():boolean
  {
    return !!this.selectedPositionTemplate;
  }

  // --------------------------------------------
}
