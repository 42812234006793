import { PageUtil } from '@flow/common/utils/PageUtil';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di, diModule } from '@flow/dependency-injection';
import { SettingsPageList } from '@flow/modules/settings/settingsTemplatePage/components/SettingsPageList';
import { SettingsPageToolbar } from '@flow/modules/settings/settingsTemplatePage/components/SettingsPageToolbar';
import bind from 'bind-decorator';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { SettingsPageController } from './SettingsPageController';
import { SettingsPageState } from './SettingsPageState';

@diModule({
  providers: [
    SettingsPageState,
    SettingsPageController
  ]
})
@componentWithRouter
export class SettingsPage extends React.Component<IWithRouterProps>
{
  @di private _settingsPageState!:SettingsPageState;
  @di private _settingsPageController!:SettingsPageController;

  // ----------------------------------------------------

  public componentDidMount():void
  {
    this._settingsPageController.initFromLocalStorage();
    this._checkPageProps();
  }

  // ----------------------------------------------------

  public componentDidUpdate(prevProps:Readonly<IWithRouterProps>):void
  {
    if( prevProps.params?.xxx !== this.props.params?.xxx )
    {
      this._checkPageProps();
    }
  }

  // ----------------------------------------------------

  @bind
  private _checkPageProps():void
  {
    const { params } = this.props;
    const isXxxPage:boolean = !!(params && params.xxx);

    if( isXxxPage ) return;

    this._settingsPageController.init();
  }

  // ----------------------------------------------------

  public componentWillUnmount():void
  {
    PageUtil.disposeReactions(this._settingsPageState.disposers);
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { params } = this.props;
    const isXxxPage:boolean = !!(params && params.xxx);

    if( isXxxPage )
    {
      return <Outlet />;
    }

    // const { xxx } = this._settingsPageState;

    return (
      <>
        <SettingsPageToolbar />
        <SettingsPageList />
      </>
    );
  }
}
