import type * as Types from './types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type HasuraInheritedRolesQueryVariables = Types.Exact<{
  role:Types.Scalars['String'];
}>;


export type HasuraInheritedRolesQuery = { hasura_inherited_roles_view:Array<{ role_name?:string | null; role_set?:any | null; }>; };

export type HasuraPermissionsQueryVariables = Types.Exact<{
  roleFilter?:Types.InputMaybe<Array<Types.Hasura_Permissions_View_Bool_Exp> | Types.Hasura_Permissions_View_Bool_Exp>;
}>;


export type HasuraPermissionsQuery = { hasura_permissions_view:Array<{ schema_name?:string | null; table_name?:string | null; select_permissions?:any | null; update_permissions?:any | null; insert_permissions?:any | null; delete_permissions?:any | null; }>; };

export type HasuraActionPermissionsQueryVariables = Types.Exact<{
  roleFilter:Types.Scalars['jsonb'];
}>;


export type HasuraActionPermissionsQuery = { hasura_action_permissions_view:Array<{ action_name?:string | null; permissions?:any | null; }>; };


export const HasuraInheritedRolesDocument = gql`
    query HasuraInheritedRoles($role: String!) {
  hasura_inherited_roles_view(where: {role_name: {_eq: $role}}) {
    role_name
    role_set
  }
}
    `;
export type HasuraInheritedRolesQueryResult = Apollo.QueryResult<HasuraInheritedRolesQuery, HasuraInheritedRolesQueryVariables>;
export const HasuraPermissionsDocument = gql`
    query HasuraPermissions($roleFilter: [hasura_permissions_view_bool_exp!]) {
  hasura_permissions_view(where: {_or: $roleFilter}) {
    schema_name
    table_name
    select_permissions
    update_permissions
    insert_permissions
    delete_permissions
  }
}
    `;
export type HasuraPermissionsQueryResult = Apollo.QueryResult<HasuraPermissionsQuery, HasuraPermissionsQueryVariables>;
export const HasuraActionPermissionsDocument = gql`
    query HasuraActionPermissions($roleFilter: jsonb!) {
  hasura_action_permissions_view(where: {permissions: {_contains: $roleFilter}}) {
    action_name
    permissions
  }
}
    `;
export type HasuraActionPermissionsQueryResult = Apollo.QueryResult<HasuraActionPermissionsQuery, HasuraActionPermissionsQueryVariables>;
export const namedOperations = {
  Query: {
    HasuraInheritedRoles: 'HasuraInheritedRoles',
    HasuraPermissions: 'HasuraPermissions',
    HasuraActionPermissions: 'HasuraActionPermissions'
  }
}