import { Position } from '@blueprintjs/core';
import { Classes, Popover2 } from '@blueprintjs/popover2';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { ButtonGroupFilter } from '@flow/common/components/page/ButtonGroupFilter';
import { Toolbar } from '@flow/common/components/page/Toolbar';
import { NeedsPermission } from '@flow/common/components/NeedsPermission';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { Common_Customer_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import type { ReactNode } from 'react';
import React from 'react';
import { CustomerController } from '../../CustomerController';
import { CustomersController } from '../../CustomersController';
import { CustomersState } from '../../CustomersState';
import { CUSTOMER_GROUP_TITLE } from '../../models/CustomersTypes';
import { CustomerDialog } from '../customer/CustomerDialog';
import styles from './CustomersToolbar.module.less';
import { CustomersToolbarMenu } from './CustomersToolbarMenu';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

@component
export class CustomersToolbar extends React.Component
{
  @di private _customersState!:CustomersState;
  @di private _customerController!:CustomerController;
  @di private _customersController!:CustomersController;

  private _renderStatusSwitcher(isDesktop:boolean):React.ReactElement
  {
    const { filterByCustomerStatus } = this._customersState;

    return (
      <div className={cx(styles.statusSwitcher, isDesktop ? styles.desktop : styles.mobile)}>
        <ButtonGroupFilter
          values={[
            Common_Customer_Status_Enum.Current,
            Common_Customer_Status_Enum.Prospective,
            Common_Customer_Status_Enum.Archived
          ]}
          titles={CUSTOMER_GROUP_TITLE}
          value={filterByCustomerStatus}
          onClick={this._customersController.setFilterByCustomerStatus}
        />
      </div>
    )
  }

  public render():ReactNode
  {
    return (
      <>
        <Toolbar
          className={styles.toolbar}
          title={'Customers'}

          leftClassName={styles.positionLeft}
          leftElements={
            <>
              <NeedsPermission name={FlowPermissions.CreateCustomer}>
                <Button
                  className={cx(styles.btnAdd,'test-add-customer-btn')}
                  text={'New Customer'}
                  icon={IconNames.ADD}
                  minimal={true}
                  onClick={this._customerController.showCreateCustomerDialog}
                />
                <CustomerDialog onSave={this._customersController.onCustomerSave} />
              </NeedsPermission>
            </>
          }

          centerClassName={styles.positionCenter}
          centerElements={this._renderStatusSwitcher(true)}

          rightClassName={styles.positionRight}
          rightElements={
            <>
              <Popover2
                className={styles.viewMobile}
                content={<CustomersToolbarMenu />}
                position={Position.BOTTOM_RIGHT}
                popoverClassName={Classes.POPOVER2_DISMISS}
              >
                <Button
                  icon={IconNames.MORE}
                  minimal={true}
                />
              </Popover2>
            </>
          }
        />
        <div className={styles.secondRow}>
          {this._renderStatusSwitcher(false)}
        </div>
      </>
    );
  }
}
