import type { ApolloQueryResult } from '@apollo/client';
import type { GetObjectsHistoryQuery, GetObjectsHistoryQueryVariables } from '@flow/data-access/lib/audit.generated';
import { GetObjectsHistoryDocument } from '@flow/data-access/lib/audit.generated';
import type {
  Audit_Logged_Actions,
  Audit_Logged_Actions_Bool_Exp
} from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import { CommonController } from '../CommonController';
import type { HasuraTables } from '../models/HasuraTables';

export type HistoryData = GetObjectsHistoryQuery['audit_logged_actions'];
export type HistoryItem = HistoryData[number];
export type HistoryItemUsers = HistoryItem['user'];

// TODO: remove
const __DEBUG:boolean = false;

export const HISTORY_ITEMS_PER_PAGE = 20;

export interface HistoryObject
{
  table:HasuraTables;
  idFieldName:string;
  where?:Audit_Logged_Actions_Bool_Exp;
  hasChangedKeys?:Array<string>;
  ids:Array<number>;
}

@controller
export class ObjectHistoryController
{
  @di private _commonController!:CommonController;

  // ----------------------------------------------------

  public async getObjectsHistory(objects:Array<HistoryObject>, offset:number):Promise<Array<Audit_Logged_Actions>>
  {
    const expressions:Array<Audit_Logged_Actions_Bool_Exp> = [];

    __DEBUG && console.log('%c getObjectsHistory =', 'background:#0f0;color:#000;');
    __DEBUG && console.log('%c --> objects =', 'background:#080;color:#000;', objects);

    objects.forEach(object =>
    {
      const idExpressions:Array<Audit_Logged_Actions_Bool_Exp> = [];

      object.ids.forEach(id =>
      {
        idExpressions.push({
          row_data: {
            _contains: {
              [object.idFieldName]: id
            }
          }
        });
      });

      const expression = {
        _and: [
          {
            full_qualified_table_name: {
              _eq: object.table
            }
          },
          object.where ?? {},
          object.hasChangedKeys ? {
            _or: [
              {
                _and: [
                  { action: { _eq: 'U' } },
                  {
                    changed_fields: {
                      _has_keys_any: object.hasChangedKeys
                    }
                  }
                ]
              },
              {
                action: { _neq: 'U' }
              }
            ]
          } : {},
          {
            _or: idExpressions
          }
        ]
      };

      expressions.push(expression);
    });

    __DEBUG && console.log('%c --> expressions =', 'background:#080;color:#000;', expressions);

    const result:ApolloQueryResult<GetObjectsHistoryQuery> =
      await this._commonController.query<GetObjectsHistoryQuery,
        GetObjectsHistoryQueryVariables>({
        query: GetObjectsHistoryDocument,
        variables: {
          limit: HISTORY_ITEMS_PER_PAGE,
          offset: offset,
          expression: expressions
        }
      });

    __DEBUG && console.log('%c --> getObjectsHistory result =', 'background:#f60;color:#000;', result);

    return result.data.audit_logged_actions as Array<Audit_Logged_Actions>;
  }

  // ----------------------------------------------------

  public async getObjectOptimizedHistory(objects:Array<HistoryObject>, offset:number):Promise<Array<HistoryItem>>
  {
    const history = await this.getObjectsHistory(objects, offset);

    const optimizedHistory:Array<HistoryItem> = [];

    // 1. Split by object type
    const tableMap:Map<string, Array<HistoryItem>> = new Map();

    history.forEach((item:HistoryItem) =>
    {
      if( !tableMap.has(item.full_qualified_table_name) )
        tableMap.set(item.full_qualified_table_name, []);

      tableMap.get(item.full_qualified_table_name)?.push(item);
    });

    // 2. Merge each object type, by operation and user (within 10 seconds diff) + check if there are actual changes by each field
    for( const historyItems of tableMap.values() )
    {
      for( let i = 0; i < historyItems.length; )
      {
        const item = historyItems[i];

        if( i === historyItems.length - 1 )
          break;

        const nextItem = historyItems[i + 1];

        const isTimestampClose:boolean = Date.parse(String(item.action_tstamp_clk)) - Date.parse(String(nextItem.action_tstamp_clk)) <= 10000;
        const isOperationMatch:boolean = item.action === 'U' && nextItem.action === 'U';
        const isUserMatch:boolean = item.user && nextItem.user && (item.user.length === 0 && nextItem.user.length === 0 || item.user[0]?.id === nextItem.user[0]?.id) || false;

        if( isTimestampClose && isOperationMatch && isUserMatch )
        {
          // replace item.changed_fields from nextItem.changed_fields
          const mergedItem = { ...item };

          if( item.changed_fields && nextItem.changed_fields )
          {
            // keep the original field set
            mergedItem.row_data = { ...nextItem.row_data };

            // update changed fields
            mergedItem.changed_fields = {
              ...nextItem.changed_fields,
              ...item.changed_fields
            };
          }

          // remove fields that are not actually changed
          Object.keys(new Object(mergedItem.changed_fields)).forEach(key =>
          {
            if( mergedItem.changed_fields[key] === mergedItem.row_data[key] )
              delete mergedItem.changed_fields[key];
          });

          historyItems[i] = mergedItem;
          historyItems.splice(i + 1, 1);
        }
        else
        {
          i++;
        }
      }

      optimizedHistory.push(...historyItems);
    }

    optimizedHistory.sort((a, b) => Date.parse(String(b.action_tstamp_clk)) - Date.parse(String(a.action_tstamp_clk)));

    return optimizedHistory;
  }

  // ----------------------------------------------------
}
