import { Collapse } from '@blueprintjs/core';
import { type Recruiting_Position_Group_Priority } from '@flow/data-access/lib/types/graphql.generated';
import { type Common_User } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { PositionGroupSelector } from '@flow/modules/recruiting/priorities/components/common/PositionGroupSelector';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';
import type { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { PrioritiesController } from '../../PrioritiesController';
import { PrioritiesState } from '../../PrioritiesState';
import { PrioritiesListPositions } from '../common/PrioritiesListPositions';

import styles from './PrioritiesListByUsersCell.module.less';

const cx = classNames.bind(styles);

interface Props
{
  user:Common_User;
  priorityIndex:number;

  byPriority?:boolean;
  byUser?:boolean;

  isEditMode?:boolean;

  provided?:DraggableProvided;
  snapshot?:DraggableStateSnapshot;
}

@component
export class PrioritiesListByUsersCell extends React.Component<Props>
{
  @di private _prioritiesState!:PrioritiesState;
  @di private _prioritiesController!:PrioritiesController;

  // ---------------------------------------------------------

  @bind
  private _onClickExpand(priority:Recruiting_Position_Group_Priority, isCanExpand:boolean):() => void
  {
    return ():void =>
    {
      isCanExpand && this._prioritiesController.expandToggler(priority);
    };
  }

  // ---------------------------------------------------------

  public render():React.ReactNode | null
  {
    const { user, priorityIndex, provided, snapshot, isEditMode } = this.props;

    const lastPriorityIndex:number = user.position_group_priorities.length - 1;

    const priority:Recruiting_Position_Group_Priority | undefined =
      (priorityIndex <= lastPriorityIndex && user.position_group_priorities[priorityIndex]) || undefined;

    // for drag - empty cell
    // if( !priority && isEditMode ) return (
    if( !priority )
    {
      if( isEditMode )
        return (
          <div
            className={styles.priorityCellWrapper}

            ref={provided?.innerRef}
            {...provided?.draggableProps}
            {...provided?.dragHandleProps}
            // style={{ ...provided?.draggableProps.style }}
          />
        );
      else
        return null;
    }

    // ---------------------------

    const { expanded } = this._prioritiesState;

    const quantityCount:number = this._prioritiesController.getQuantityCountOfPriority(priority);

    const isExpanded:boolean = expanded === this._prioritiesController.getExpandId(priority);
    const isCanExpand:boolean = quantityCount > 0;

    if( isEditMode )
    {
      return (
        <div
          className={styles.priorityCellWrapper}

          ref={provided?.innerRef}
          {...provided?.draggableProps}
          // ...provided.dragHandleProps moved to -> PositionGroupSelector
          style={{ ...provided?.draggableProps.style }}
        >
          <div className={cx(styles.priorityCellView, { isEditMode })}>
            <PositionGroupSelector
              isEditMode={isEditMode}
              priority={priority}
              count={quantityCount}
              provided={provided}
              snapshot={snapshot}
            />
          </div>
        </div>
      );
    }

    return (
      <div
        className={styles.priorityCellWrapper}

        // TODO: to fix error in console
        ref={provided?.innerRef}
      >
        <div
          className={cx(styles.priorityCellView, { isExpanded })}
          onClick={this._onClickExpand(priority, isCanExpand)}
        >
          <PositionGroupSelector
            priority={priority}
            count={quantityCount}
          />
        </div>
        {
          isCanExpand &&
          <Collapse isOpen={isExpanded}>
            <PrioritiesListPositions
              priority={priority}
              mobileByUsers={true}
            />
          </Collapse>
        }
      </div>
    );
  }

  // ---------------------------------------------------------
}
