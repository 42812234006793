import type { IInlineEditorMultiSelectValue } from '@flow/common/components/form/InlineEditor';
import type {
  Recruiting_Candidate_Position_Group,
  Recruiting_Position_Group
} from '@flow/data-access/lib/types/graphql.generated';
import { di, state } from '@flow/dependency-injection';
import { computed, observable } from 'mobx';
import { computedFn } from 'mobx-utils';
import { CandidateState } from './CandidateState';

@state
export class CandidatePositionGroupsState
{
  @di private _candidateState!:CandidateState;

  @observable public positionGroups:Array<Recruiting_Position_Group> = [];

  // ----------------------------------------------------

  @computed
  public get candidatePositionGroupIds():Array<number>
  {
    return this._candidateState.candidate?.candidate_position_groups?.map((positionGroup:Recruiting_Candidate_Position_Group) =>
    {
      return positionGroup.position_group_id;
    }) || [];
  }

  @computed
  public get positionGroupIds():Array<number>
  {
    return this.positionGroups.map((positionGroup:Recruiting_Position_Group) => positionGroup.id);
  }

  @computed
  public get positionGroupsValuesForMultiSelect():Array<IInlineEditorMultiSelectValue>
  {
    return this.positionGroups.map((positionGroup:Recruiting_Position_Group) =>
    {
      return { title: positionGroup.name, value: positionGroup.id };
    });
  }

  @computed
  public get candidatePositionGroupNames():Array<string>
  {
    if( !this._candidateState.candidate || !this._candidateState.candidate.candidate_position_groups ) return [];

    return this._candidateState.candidate.candidate_position_groups.map((cpg:Recruiting_Candidate_Position_Group) =>
    {
      const positionGroup:Recruiting_Position_Group | undefined = this.positionGroupById(cpg.position_group_id);
      return positionGroup?.name || '';
    });
  }

  // ----------------------------------------------------

  public positionGroupById:(id:number) => Recruiting_Position_Group | undefined =
    computedFn((id:number):Recruiting_Position_Group | undefined =>
    {
      return this.positionGroups.find((positionGroup:Recruiting_Position_Group) => positionGroup.id === id);
    });

  // ----------------------------------------------------
}
