import { FiltersPopover } from '@flow/common/components/filters/components/FiltersPopover';
import type { IFilterComponentProps } from '@flow/common/components/filters/Filter';
import type { IFilterFlowStageValue } from '@flow/common/components/filters/status/FilterFlowStage';
import { Checkbox } from '@flow/common/components/form/Checkbox';
import type { Recruiting_Interview_Flow_Stage } from '@flow/data-access/lib/types/graphql.generated';
import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';
// import classNames from 'classnames/bind';
import type React from 'react';
import { Component } from 'react';

import styles from './FilterFlowStageSelector.module.less';

// const cx = classNames.bind(styles);

@component
export class FilterStatusSelector extends Component<IFilterComponentProps<IFilterFlowStageValue>>
{
  // ---------------------------------------------------------

  @bind
  private _onChangeFilter(statusId:number):(isSelected:boolean) => void
  {
    return (isSelected:boolean):void =>
    {
      const { filter, onChangeFilter } = this.props;

      if( isSelected )
      {
        onChangeFilter({ interviewFlowStageIds: [...filter.value.interviewFlowStageIds, statusId] });
      }
      else
      {
        onChangeFilter({ interviewFlowStageIds: filter.value.interviewFlowStageIds.filter(s => s !== statusId) });
      }
    };
  }

  @bind
  private _renderFilterContent():React.ReactElement | null
  {
    const { filter, getFilterData } = this.props;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterData:any = getFilterData?.();

    const interviewFlowStages:Array<Recruiting_Interview_Flow_Stage> = filterData?.interviewFlowStages;

    if( !filterData || !interviewFlowStages ) return null;

    return (
      <div className={styles.content}>

        {interviewFlowStages.map((interviewFlowStage:Recruiting_Interview_Flow_Stage) =>
        {
          const isSelected:boolean = filter.value.interviewFlowStageIds.includes(interviewFlowStage.id);

          return (
            <Checkbox
              key={interviewFlowStage.id}
              className={styles.checkbox}
              label={interviewFlowStage.name}
              checked={isSelected}
              onChange={this._onChangeFilter(interviewFlowStage.id)}
            />
          );
        })}

      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _getButtonText():React.ReactElement | null
  {
    const { filter, getFilterData } = this.props;

    const { interviewFlowStageIds } = filter.value;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterData:any = getFilterData?.();

    const isValueSelected:boolean = !!interviewFlowStageIds.length;

    const interviewFlowStages:Array<Recruiting_Interview_Flow_Stage> = filterData?.interviewFlowStages;

    if( !filterData || !interviewFlowStages || !isValueSelected )
    {
      return <span>{filter.name}</span>;
    }

    const statusNames:string = interviewFlowStageIds
      .map((statusId:number) => interviewFlowStages.find(st => st.id === statusId)?.name || '')
      .join(', ');

    return <span><b>{filter.name}</b>: {statusNames}</span>;
  }

  // ---------------------------------------------------------
  public render():React.ReactNode
  {
    const { filter, onRemoveFilter, wrapperClassName, buttonClearText } = this.props;

    const isValueSelected:boolean = !!filter.value.interviewFlowStageIds.length;

    return (
      <FiltersPopover
        wrapperClassName={wrapperClassName}
        title={filter.name}
        content={this._renderFilterContent()}
        contentButtonClearText={buttonClearText}
        onClickButtonClear={onRemoveFilter}
        buttonText={this._getButtonText()}
        isValueSelected={isValueSelected}
        // customButton={(<div>custom button</div>)}
      />
    );
  }
}
