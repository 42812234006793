import { ButtonMore } from '@flow/common/components/elements/ButtonMore';
import { Pill, PillType } from '@flow/common/components/elements/Pill';
import { Button, ButtonSize } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { Intent } from '@flow/common/components/types/Types';
import { IPositionsCount, PositionsUtil } from '@flow/common/utils/PositionsUtil';
import type { Staffing_Customer_Team_Tag } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { NewPositionController } from '@flow/modules/customers/teams/NewPositionController';
import { TeamController } from '@flow/modules/customers/teams/TeamController';
import { TeamState } from '@flow/modules/customers/teams/TeamState';
import classNames from 'classnames/bind';
import { action, computed, runInAction } from 'mobx';
import type { ReactNode } from 'react';
import React from 'react';
import { CustomersController } from '../../CustomersController';
import { CustomersState } from '../../CustomersState';
import type { Staffing_Customer_Team_Ex } from '../../models/CustomersTypes';
import { PositionsCount } from '../team/PositionsCount';
import { TeamListItemMenu } from './TeamListItemMenu';

import styles from './TeamsListItem.module.less';

const cx = classNames.bind(styles);

export interface ITeamsListItemProps
{
  team:Staffing_Customer_Team_Ex;

  itemIndex:number;
  itemsLength:number;

  isCollapsed?:boolean;
}

@component
export class TeamsListItem extends React.Component<ITeamsListItemProps>
{
  @di private _customersState!:CustomersState;
  @di private _customersController!:CustomersController;

  @di private _teamState!:TeamState;
  @di private _teamController!:TeamController;

  @di private _positionController!:NewPositionController;

  // ---------------------------------------------------------

  private _renderTeamTag(teamTag:Staffing_Customer_Team_Tag, index:number):ReactNode
  {
    return (
      <Pill
        key={index}
        className={styles.pill}
        type={PillType.Outlined}
        text={teamTag.name}
      />
    );
  }

  // ---------------------------------------------------------

  @computed
  private get _positionsCount():IPositionsCount
  {
    const { team: { positions } } = this.props;

    return PositionsUtil.getPositionsCount(positions);
  }

  // ---------------------------------------------------------

  @action.bound
  private _managePositions():void
  {
    const { team } = this.props;
    const { team: managedTeam } = this._teamState;

    const isManagedTeam:boolean = managedTeam?.id === team.id;

    if( managedTeam && !isManagedTeam ) // managed other team
    {
      this._teamController.finishEditPositions();
    }
    return this._teamController.managePositions(team as Staffing_Customer_Team_Ex);
  }

  // ---------------------------------------------------------

  public render():ReactNode
  {
    const {
      team,
      team: {
        name,
        is_active,
        customer_team_tags
      },
      itemIndex, itemsLength, isCollapsed
    } = this.props;

    const isFirst:boolean = itemIndex === 0;
    const isLast:boolean = itemIndex === itemsLength - 1;

    const { team: managedTeam, isTeamPositionsValid } = this._teamState;

    const isManagedTeam:boolean = managedTeam?.id === team.id;

    const isMenuDisabled:boolean = !!managedTeam && !isManagedTeam;

    if( isManagedTeam )
    {
      // console.log('%c toJS(team.positions) =', 'background:#ff0;color:#000;', toJS(team.positions));
    }

    return (
      <div key={name} className={cx(styles.team, { isManagedTeam, isExpanded: !isCollapsed })}>

        <div className={cx(styles.teamName, styles.desktop, { prospective: !is_active })}>
          {name}
        </div>

        <div className={styles.mobileWrapper}>
          <div className={styles.aliases}>

            <div className={cx(styles.teamName, styles.mobile, { prospective: !is_active })}>
              {name}
            </div>

            {customer_team_tags && customer_team_tags.map(this._renderTeamTag)}
          </div>
          {
            isManagedTeam &&
            <ButtonMore
              className={cx(styles.menu, styles.mobile)}
              buttonClassName={styles.menuBtn}
              iconClassName={styles.menuIcon}
              menuContent={<TeamListItemMenu isMobile team={team} positionsCount={this._positionsCount} />}
            />
          }
        </div>

        <PositionsCount
          className={styles.positions}
          count={this._positionsCount.staffed}
          totalCount={this._positionsCount.total}
        />

        <div className={styles.actionBtnsWrapper}>
          <div className={styles.actionBtns}>

            <Button
              className={styles.btnNewPosition}
              iconClassName={styles.btnNewPositionIcon}
              icon={IconNames.ADD}
              text={'New position'}
              size={ButtonSize.SMALL}
              outlined={true}
              intent={Intent.NONE}
              onClick={(e):void =>
              {
                e.stopPropagation();
                this._managePositions();
                this._positionController.showCreatePositionDialog();
              }}
            />

            {
              !isManagedTeam &&
              <>
                <Button
                  className={styles.btnManagePositions}
                  text={'Manage positions'}
                  size={ButtonSize.SMALL}
                  outlined={true}
                  intent={Intent.PRIMARY}
                  onClick={(e):void =>
                  {
                    e.stopPropagation();
                    this._managePositions();
                  }}
                />
              </>
            }
            {
              isManagedTeam &&
              <>
                <Button
                  className={styles.btnCancel}
                  minimal={true}
                  text={'Cancel'}
                  size={ButtonSize.SMALL}
                  // outlined={true}
                  intent={Intent.NONE}
                  onClick={(e):void =>
                  {
                    e.stopPropagation();
                    this._teamController.finishEditPositions();
                    runInAction(() =>
                    {
                      this._teamState.team = null;
                    });
                  }}
                />
                <Button
                  className={styles.btnApply}
                  text={'Apply'}
                  minimal={true}
                  size={ButtonSize.SMALL}
                  // outlined={true}
                  disabled={!isTeamPositionsValid}
                  intent={Intent.PRIMARY}
                  onClick={async (e):Promise<void> =>
                  {
                    e.stopPropagation();
                    return this._teamController.savePositions();
                  }}
                />
              </>
            }
          </div>

        </div>

        {
          !isManagedTeam &&
          <ButtonMore
            className={styles.menu}
            buttonClassName={styles.menuBtn}
            iconClassName={styles.menuIcon}
            disabled={isMenuDisabled}
            menuContent={<TeamListItemMenu
              team={team} isFirst={isFirst} isLast={isLast} positionsCount={this._positionsCount}
            />}
          />
        }

      </div>
    );
  }
}
