import { AllocationPositionInDialog } from '@flow/common/components/elements/AllocationPositionInDialog';
import { CustomerAndTeam } from '@flow/common/components/elements/CustomerAndTeam';
import { PositionItem } from '@flow/common/components/elements/PositionItem';
import { BlankState } from '@flow/common/components/form/BlankState';
import { ButtonSize } from '@flow/common/components/form/Button';
import { Dialog } from '@flow/common/components/page/Dialog';
import { Intent } from '@flow/common/components/types/Types';
import { DebugUtil } from '@flow/common/utils/DebugUtil';
import { Staffing_Customer_Team_Slot } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { StaffMemberAllocationController } from '@flow/modules/staffing/staff/StaffMemberAllocationController';
import { StaffMemberAllocationState } from '@flow/modules/staffing/staff/StaffMemberAllocationState';
import { StaffMemberState } from '@flow/modules/staffing/staff/StaffMemberState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { runInAction } from 'mobx';
import moment from 'moment';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './AddPositionDialog.module.less';

const cx = classNames.bind(styles);

interface Props
{
  onSave?:() => Promise<void>;
}

interface State
{
  expandedPositions:Map<number, boolean>;
}

@component
export class AddPositionDialog extends React.Component<Props, State>
{
  @di private _staffMemberState!:StaffMemberState;

  @di private _staffMemberAllocationState!:StaffMemberAllocationState;
  @di private _staffMemberAllocationController!:StaffMemberAllocationController;

  private _contentWrapperRef:React.RefObject<HTMLDivElement> = React.createRef();

  // ---------------------------------------------------------

  public componentDidUpdate():void
  {
    const { isEditPosition, isScrolledToPosition } = this._staffMemberAllocationState;

    if( !isEditPosition || isScrolledToPosition ) return;

    // wait for positions to render
    setTimeout(() =>
    {
      if( !this._contentWrapperRef.current ) return;

      const positionItemDiv:HTMLDivElement | null = this._contentWrapperRef.current.querySelector('[data-selected-position-item]');

      if( !positionItemDiv ) return;

      positionItemDiv.scrollIntoView({ behavior: 'auto', block: 'center' });

      runInAction(() =>
      {
        this._staffMemberAllocationState.isScrolledToPosition = true;
      });
    }, 10);
  }

  // ---------------------------------------------------------

  @bind
  private _renderPositions():React.ReactNode | null
  {
    const {
      positionsByPositionGroups,
      selectedPositionId,
      // currentFinalSlots,
      selectedDate, selectedSlot,
      // sortedProposedAllocations,
      positionById,
      isEditPosition,
      editedPositionCurrentSlot
    } = this._staffMemberAllocationState;

    const {
      staffMember
    } = this._staffMemberState;

    // const {
    //   isPositionProposed
    // } = this._staffMemberAllocationController;

    const positionDivs:Array<ReactNode> = positionsByPositionGroups
      .map((position:Recruiting_Position_Ex, index:number, positions:Array<Recruiting_Position_Ex>) =>
      {
        const prevPosition:Recruiting_Position_Ex | null = index ? positions[index - 1] : null;
        const nextPosition:Recruiting_Position_Ex | null = index <= positions.length - 1 ? positions[index + 1] : null;

        const isShowCustomerName:boolean = prevPosition?.customer_team?.id !== position.customer_team?.id;
        const isNextCustomerName:boolean = nextPosition?.customer_team?.id !== position.customer_team?.id;
        const isSelectedPosition:boolean = position.id === selectedPositionId;

        return (
          <React.Fragment key={position.id}>
            {
              // customer alias & team name
              isShowCustomerName &&
              <CustomerAndTeam
                className={styles.customerAndTeam}
                items={[
                  `${DebugUtil.id(position.customer_team?.customer.id)}${position.customer_team?.customer.alias}`,
                  `${DebugUtil.id(position.customer_team?.id)}${position.customer_team?.name}`
                ]}
              />
            }
            {/*<div className={styles.tmpPositionItem}>*/}
            {/*  {index}: {DebugUtil.id(position.id)}*/}
            {/*  {PositionsUtil.getPositionName(position)}*/}
            {/*</div>*/}
            <AllocationPositionInDialog
              className={cx(styles.positionItem, { selectedBeforeCustomerName: isSelectedPosition && isNextCustomerName })}
              innerClassName={cx({
                afterCustomerName: isShowCustomerName,
                beforeCustomerName: isNextCustomerName,
                // afterSelectedPosition: prevPosition?.id === selectedPositionId,
                beforeSelectedPosition: nextPosition?.id === selectedPositionId
              })}
              position={position}
              isEditPosition={isEditPosition}
              editedPositionCurrentSlot={editedPositionCurrentSlot}

              staffMember={staffMember}
              // candidate={candidate}
              // proposedAllocations={sortedProposedAllocations}
              // currentFinalSlots={currentFinalSlots}

              selectedPositionId={selectedPositionId}
              selectedSlot={selectedSlot}
              selectedDate={selectedDate}

              positionById={positionById}
              // isPositionProposed={isPositionProposed}

              onSelectSlot={(slot:Staffing_Customer_Team_Slot):void =>
              {
                runInAction(() =>
                {
                  this._staffMemberAllocationState.selectedSlot = Object.assign({}, slot);
                });
              }}
              onSelectDate={(newDate:string | null):void =>
              {
                runInAction(() =>
                {
                  this._staffMemberAllocationState.selectedDate = newDate;
                });
              }}
              onSelectPosition={(positionId:number, positionItemRef:React.RefObject<PositionItem>):void =>
              {
                runInAction(() =>
                {
                  this._staffMemberAllocationController.setSelectedPositionId(position.id, positionItemRef);
                });
              }}
              onSelectPositionItemRef={(selectedPositionItemRef:React.RefObject<PositionItem> | null):void =>
              {
                runInAction(() =>
                {
                  this._staffMemberAllocationState.selectedPositionItemRef = selectedPositionItemRef;
                });
              }}
            />
          </React.Fragment>
        );
      });

    const hasPositionDivs:boolean = positionDivs.length !== 0;

    if( hasPositionDivs ) return positionDivs;

    return (
      <BlankState
        className={styles.noPositions}
        title={'No available positions'}
        withBorder={true}
      />
    );
  }

  // ---------------------------------------------------------

  public render():ReactNode
  {
    const {
      isAddPositionDialogOpen, isAddPositionLoading,
      isEditPosition, editedPositionCurrentSlot, editedPositionId,
      selectedSlot, selectedPositionId, selectedDate
    } = this._staffMemberAllocationState;

    const {
      staffMemberPositionGroupNames, staffMember
    } = this._staffMemberState;

    let isButtonDisabled:boolean = !selectedPositionId || !selectedSlot || !selectedDate
      || !moment(selectedDate).isValid();

    if( isEditPosition && editedPositionCurrentSlot )
    {
      const currentSlotDate:string = editedPositionCurrentSlot.staff?.id === staffMember?.id
        ? editedPositionCurrentSlot.start_date
        : editedPositionCurrentSlot.next_staff_id === staffMember?.id
          ? editedPositionCurrentSlot.next_staff_start_date
          : '';

      isButtonDisabled = isButtonDisabled || (
        editedPositionId === selectedPositionId
        && editedPositionCurrentSlot.id === selectedSlot?.id
        && moment(currentSlotDate).isSame(selectedDate, 'day')
      );
    }

    return (
      <Dialog
        title={isEditPosition ? 'Edit Position' : 'Add Position'}
        titleTextClassName={styles.titleText}
        titleAdditionalContent={(
          <div className={styles.positionGroups}>
            {staffMemberPositionGroupNames.length > 0 && staffMemberPositionGroupNames.join(', ')}
            {
              staffMemberPositionGroupNames.length === 0 &&
              <span>
                All position groups
              </span>
            }
          </div>
        )}

        isOpen={isAddPositionDialogOpen}
        isLoading={isAddPositionLoading}
        // onOpening={this._updateExpandedMap}
        onClose={this._staffMemberAllocationController.hideAddPositionDialog}
        className={styles.dialogClassName}

        isPrimaryButtonDisabled={isButtonDisabled}
        primaryButtonText={isEditPosition ? 'Save' : 'Add'}
        primaryButtonIntent={Intent.PRIMARY}
        primaryButtonClassName={styles.primaryButton}
        primaryButtonOnClick={this._staffMemberAllocationController.addPosition}

        secondaryButtonText={'Cancel'}
        secondaryButtonIntent={Intent.PRIMARY}
        secondaryButtonClassName={styles.secondaryButton}
        isSecondaryButtonOutlined={true}

        buttonsSize={ButtonSize.LARGE}
        canEscapeKeyClose={false}
      >
        <div
          className={styles.contentWrapper}
          ref={this._contentWrapperRef}
        >
          {this._renderPositions()}
        </div>

      </Dialog>
    );
  }
}
