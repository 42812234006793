import { ConfirmationDialog } from '@flow/common/components/page/ConfirmationDialog';
import { DialogWarningMessage } from '@flow/common/components/page/DialogWarningMessage';
import { Intent } from '@flow/common/components/types/Types';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { Staffing_Staff_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { LeavingDateInput } from '@flow/modules/staffing/staff/components/member/changeStatus/LeavingDateInput';
import { StaffStatusSelector } from '@flow/modules/staffing/staff/components/member/changeStatus/StaffStatusSelector';
import { StaffMemberController } from '@flow/modules/staffing/staff/StaffMemberController';
import { StaffMemberState } from '@flow/modules/staffing/staff/StaffMemberState';
import classNames from 'classnames/bind';
import moment from 'moment';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './ChangeStaffStatusDialog.module.less';

const cx = classNames.bind(styles);

@component
export class ChangeStaffStatusDialog extends React.Component
{
  @di private _staffMemberState!:StaffMemberState;
  @di private _staffMemberController!:StaffMemberController;

  @di private _roleController!:RoleController;

  // ---------------------------------------------------------

  public render():ReactNode | null
  {
    const {
      staffMember, staffMemberName,
      isShowChangeStatusDialog, newStaffStatus, newLeavingDate,
      staffMemberSlotsIds
    } = this._staffMemberState;

    if( !staffMemberName || !staffMember ) return null;

    const isDisabled:boolean = !this._roleController.hasPermission(FlowPermissions.EditStaff);

    if( isDisabled ) return null;

    const { status, leaving_date } = staffMember;

    // ---------------------------

    let isPrimaryButtonDisabled:boolean = false;
    let isWarningMessage:boolean = false;

    if( status === newStaffStatus )
    {
      isPrimaryButtonDisabled = true;

      if( status === Staffing_Staff_Status_Enum.Leaving )
      {
        isPrimaryButtonDisabled = !newLeavingDate || moment(newLeavingDate).isSame(leaving_date, 'day');
      }
    }
    else
    {
      if( newStaffStatus === Staffing_Staff_Status_Enum.Archived && staffMemberSlotsIds.length )
      {
        isPrimaryButtonDisabled = true;
        isWarningMessage = true;
      }
      else if( !newLeavingDate &&
        (status === Staffing_Staff_Status_Enum.Leaving || newStaffStatus === Staffing_Staff_Status_Enum.Leaving) )
      {
        isPrimaryButtonDisabled = true;
      }
    }

    // ---------------------------

    return (
      <ConfirmationDialog
        title={`Change status of ${staffMemberName}`}
        confirmButtonText={'Apply'}
        onConfirm={this._staffMemberController.changeStaffStatus}
        isOpen={isShowChangeStatusDialog}
        onClose={():void =>
        {
          this._staffMemberController.setChangeStatusDialog(false);
        }}
        primaryButtonIntent={Intent.PRIMARY}
        isPrimaryButtonDisabled={isPrimaryButtonDisabled}
      >

        <StaffStatusSelector />
        <LeavingDateInput />

        {
          isWarningMessage &&
          <DialogWarningMessage
            text={'Employee has open positions'}
          />
        }

      </ConfirmationDialog>
    );
  }

  // ---------------------------------------------------------
}
