import type { IFilter } from '@flow/common/components/filters/Filter';
import { FilterType } from '@flow/common/components/filters/Filter';
import { FilterSiteSubsiteSelector } from '@flow/common/components/filters/siteSubsite/FilterSiteSubsiteSelector';

// ---------------------------------------------------------

export interface IFilterSiteSubsiteValue
{
  // site:string | null;
  // subsite:string | null;
  value:string | null;
  exceptSelected?:boolean;
}

export interface IFilterSiteSubsiteItem
{
  title?:string;
  value?:number | string | null;

  isGroupHeader?:boolean;
  isGroupItem?:boolean;
  isMenuDivider?:boolean;
}

// ---------------------------------------------------------

export const FilterSiteSubsiteSettings:IFilter<IFilterSiteSubsiteValue> = {
  type: FilterType.SITE_SUBSITE,
  name: 'Site / Subsite',
  isSelected: false,
  // value: { site: null, subsite: null },
  value: { value: '' },
  component: FilterSiteSubsiteSelector,
  filterFunction: filterFunction
};

// ---------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filterFunction(this:IFilter<IFilterSiteSubsiteValue>, filterData:any):boolean
{
  // console.log('%c !!! SITE_SUBSITE filterData =', 'background:#f00;color:#ff0;', filterData, this.value.value);

  if( !this.value.value ) return true;

  const [site, subSite] = this.value.value.split(':');

  const { exceptSelected } = this.value;

  const { site: dataSite, subSite: dataSubSite } = filterData;

  if( subSite === '0' )
  {
    const result = dataSite === parseInt(site);
    // if( result ) console.log('%c --- =', 'background:#0f0;color:#ff0;', 'ok');
    return exceptSelected ? !result : result;
  }
  else
  {
    const result = dataSite === parseInt(site) && dataSubSite === parseInt(subSite);
    // if( result ) console.log('%c --- =', 'background:#0f0;color:#ff0;', 'ok');
    return exceptSelected ? !result : result;
  }

  // return true; // => isItemVisible
}

// ---------------------------------------------------------
