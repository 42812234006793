import { InlineEditor, InlineEditorType } from '@flow/common/components/form/InlineEditor';
import { DateUtil } from '@flow/common/utils/DateUtil';
import classNames from 'classnames/bind';
import type { Moment } from 'moment';
import moment from 'moment';

import styles from '../eventForm/CommunicationEventForm.module.less';

const cx = classNames.bind(styles);

interface Props
{
  timeStr:string;
  time:Date;
  startTime:Date;
  timeQuery?:string;
  onChange?:(value:Moment) => void;
  isChanged?:boolean;
  onQueryChange?:(query:string) => void;
  onTimeUpDown?:(newTime:Date) => void;
  readonly?:boolean;
}

function timeQueryMatch(time:Date, query:string):boolean
{
  const valueMoment = moment(time);
  return Boolean(valueMoment.format('h:mm A').startsWith(query) || valueMoment.format('HH:mm').startsWith(query));
}

export const EventEndTimeEditor = (props:Props):JSX.Element =>
{
  const {
    timeStr,
    time,
    startTime,
    timeQuery,
    onChange,
    isChanged,
    onQueryChange,
    onTimeUpDown,
    readonly
  } = props;

  const values = time
    ? DateUtil.generateTimeList(30, startTime, true)
      .map(date =>
      {
        if( !date )
          return { isMenuDivider: true };

        const itemDuration = moment.duration(moment(date).diff(startTime));

        const durationInHours = Number(itemDuration.hours()) + (itemDuration.minutes() / 60);

        const durationStr = durationInHours < 1
          ? `${durationInHours * 60} mins`
          : `${durationInHours} ${durationInHours === 1 ? 'hr' : 'hrs'}`;

        const itemTimeStr = String(moment(date).format('h:mm A'));
        const itemTimeStr24 = String(moment(date).format('HH:mm'));

        return {
          value: date.toUTCString(),
          element: <div>
            <span>{itemTimeStr}</span>
            <span className={styles.duration}>({durationStr})</span>
          </div>,
          title: itemTimeStr + itemTimeStr24,
          isActive: false
        };
      }).slice(1)
    : [];

  for( const value of values )
  {
    const date = moment(value.value).toDate();

    const compareToTime = String(timeQuery && timeQuery.length > 0 ? timeQuery : timeStr);
    if( timeQueryMatch(date as Date, compareToTime) )
    {
      value.isActive = true;
      break;
    }
  }

  return <InlineEditor
    type={InlineEditorType.TIME}
    applyWhenSelect
    viewClassName={cx(styles.endTimeEditor, { readonly })}
    menuClassName={styles.startTimePopover}
    inputClassName={styles.timeInput}
    text={timeStr}
    query={timeQuery}
    readonly={readonly}
    isChanged={isChanged}
    onKeyDown={(event):void =>
    {
      if( event.key === 'ArrowUp' )
      {
        const tryMoveUpTime = moment(time).subtract(30, 'minutes');

        if( tryMoveUpTime.diff(startTime, 'minutes') >= 20 )
        {
          if( onTimeUpDown )
            onTimeUpDown(tryMoveUpTime.toDate() as Date);

          if( onQueryChange )
            onQueryChange(String(tryMoveUpTime.format('h:mm A')));
        }
      }

      if( event.key === 'ArrowDown' )
      {
        const tryMoveDownTime = moment(time).add(30, 'minutes');

        if( tryMoveDownTime.diff(startTime, 'minutes') <= 24 * 60 )
        {
          if( onTimeUpDown )
            onTimeUpDown(tryMoveDownTime.toDate() as Date);

          if( onQueryChange )
            onQueryChange(String(tryMoveDownTime.format('h:mm A')));
        }
      }
    }}
    fields={[
      {
        name: 'time',
        value: time.toUTCString(),
        values: values,
        placeholder: 'Enter time'
      }
    ]}
    onQueryChange={(query):void =>
    {
      if( onQueryChange )
        onQueryChange(String(query));
    }}
    onChange={(item):void =>
    {
      const timeMoment = moment(item['time'].value) as Moment;
      if( onChange )
        onChange(timeMoment);

      if( onQueryChange )
        onQueryChange(timeMoment.format('h:mm A'));
    }}
    noButtons
  />;
};
