import type { IFilter } from '@flow/common/components/filters/Filter';
import { FilterType } from '@flow/common/components/filters/Filter';
import { FilterStatusSelector as FilterFlowStageSelector } from '@flow/common/components/filters/status/FilterFlowStageSelector';
import type { Recruiting_Interview_Flow_Stage } from '@flow/data-access/lib/types/graphql.generated';

// ---------------------------------------------------------

export interface IFilterFlowStageValue
{
  interviewFlowStageIds:Array<number>;
}

// ---------------------------------------------------------

export const FilterFlowStageSettings:IFilter<IFilterFlowStageValue> = {
  type: FilterType.FLOW_STAGE,
  name: 'Flow Stage',
  isSelected: false,
  value: { interviewFlowStageIds: [] },
  component: FilterFlowStageSelector,
  filterFunction: filterFunction,
  graphQLTemplate: `
  {
    "interview_flow_stage_id": {
      "_in": {{items}}
    }
  }`,
  graphQLFilter: graphQLFilter
};

// ---------------------------------------------------------

function filterFunction(this:IFilter<IFilterFlowStageValue>, filterData:Recruiting_Interview_Flow_Stage):boolean
{
  if( !this.value.interviewFlowStageIds.length ) return true;

  return this.value.interviewFlowStageIds.includes(filterData?.id);
}

// ---------------------------------------------------------

function graphQLFilter(this:IFilter<IFilterFlowStageValue>):string
{
  if( !this.graphQLTemplate )
    throw new Error(`Cannot apply filter ${this.name}: graphQLTemplate is not defined`);

  if( !this.value.interviewFlowStageIds?.length ) return '';

  return this.graphQLTemplate.replace('{{items}}', JSON.stringify(this.value.interviewFlowStageIds));
}
