import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { NeedsPermission } from '@flow/common/components/NeedsPermission';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { MoveTo } from '@flow/common/models/MoveTo';
import { IPositionsCount } from '@flow/common/utils/PositionsUtil';
import { component, di } from '@flow/dependency-injection';
import type { Staffing_Customer_Team_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { TeamState } from '@flow/modules/customers/teams/TeamState';
import bind from 'bind-decorator';
import { action } from 'mobx';
import type { ReactNode } from 'react';
import React from 'react';
import { CustomerController } from '../../CustomerController';
import { CustomerState } from '../../CustomerState';
import { NewPositionController } from '../../NewPositionController';
import { TeamController } from '../../TeamController';

import styles from './TeamListItemMenu.module.less';

export interface ITeamListItemMenuProps
{
  team:Staffing_Customer_Team_Ex;

  isFirst?:boolean;
  isLast?:boolean;

  isMobile?:boolean;
  positionsCount:IPositionsCount;
}

@component
export class TeamListItemMenu extends React.Component<ITeamListItemMenuProps>
{
  @di private _teamState!:TeamState;
  @di private _teamController!:TeamController;

  @di private _customerState!:CustomerState;
  @di private _customerController!:CustomerController;

  @di private _positionController!:NewPositionController;
  @di private _roleController!:RoleController;

  // ---------------------------------------------------------

  @action.bound
  private _managePositions():void
  {
    const { team } = this.props;
    const { team: managedTeam } = this._teamState;

    const isManagedTeam:boolean = managedTeam?.id === team.id;

    if( managedTeam && !isManagedTeam ) // managed other team
    {
      this._teamController.finishEditPositions();
    }
    this._teamController.managePositions(team as Staffing_Customer_Team_Ex);
  }

  // ---------------------------------------------------------

  @bind
  private _deleteHandler():void
  {
    this._teamController.toggleDeleteTeamDialog(this.props.team.id as number);
  }

  // ----------------------------------------------------

  public render():ReactNode
  {
    const { team, isFirst, isLast, isMobile, positionsCount } = this.props;
    console.log('%c positionsCount =', 'background:#0f0;color:#000;', positionsCount);

    if( isMobile )
    {
      return (
        <Menu>
          <MenuItem
            text="New Position"
            disabled={!this._roleController.hasPermission(FlowPermissions.CreatePosition)}
            onClick={():void =>
            {
              this._managePositions();
              this._positionController.showCreatePositionDialog();
            }}
          />
        </Menu>
      );
    }

    return (
      <Menu>
        <MenuItem
          text="Edit Team"
          disabled={!this._roleController.hasPermission(FlowPermissions.EditCustomerTeams)}
          onClick={():void => this._teamController.editTeam(team as Staffing_Customer_Team_Ex)}
        />
        <MenuItem
          text="Manage Positions"
          disabled={!this._roleController.hasPermission(FlowPermissions.EditPosition)}
          className={styles.desktop}
          onClick={(e):void =>
          {
            e.stopPropagation();
            this._managePositions();
          }}
        />
        <MenuItem
          text="New Position"
          disabled={!this._roleController.hasPermission(FlowPermissions.CreatePosition)}
          className={styles.desktop}
          onClick={():void =>
          {
            this._managePositions();
            this._positionController.showCreatePositionDialog();
          }}
        />

        <NeedsPermission name={FlowPermissions.ChangePositionCustomerTeamOrder}>
          <MenuDivider />
          <MenuItem
            text="Move up"
            disabled={isFirst}
            onClick={():void => this._teamController.changeTeamOrder(team as Staffing_Customer_Team_Ex, MoveTo.UP)}
          />
          <MenuItem
            text="Move down"
            disabled={isLast}
            onClick={():void => this._teamController.changeTeamOrder(team as Staffing_Customer_Team_Ex, MoveTo.DOWN)}
          />
          <MenuItem
            text="Move to top"
            disabled={isFirst}
            onClick={():void => this._teamController.changeTeamOrder(team as Staffing_Customer_Team_Ex, MoveTo.TOP)}
          />
          <MenuItem
            text="Move to bottom"
            disabled={isLast}
            onClick={():void => this._teamController.changeTeamOrder(team as Staffing_Customer_Team_Ex, MoveTo.BOTTOM)}
          />
        </NeedsPermission>

        <NeedsPermission name={FlowPermissions.DeleteCustomerTeams}>
          <MenuDivider />
          <MenuItem
            text="Delete Team"
            disabled={!this._roleController.hasPermission(FlowPermissions.DeleteCustomerTeams) || positionsCount.staffed > 0}
            onClick={():unknown => this._deleteHandler()}
          />
        </NeedsPermission>
      </Menu>
    );
  }
}
