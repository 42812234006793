import type { Maybe } from '@flow/common/models/Types';
import { state } from '@flow/dependency-injection';
import { computed, observable } from 'mobx';
import type { Recruiting_Position_Ex, Staffing_Customer_Team_Ex } from './models/CustomersTypes';

@state
export class TeamState
{
  // Team -----------------------------------
  @observable public team:Staffing_Customer_Team_Ex | null = null;
  @observable public isTeamInfoOpen:boolean = false;
  @observable public isTeamInfoEditMode:boolean = false;

  @observable public oldPositions:Array<Recruiting_Position_Ex> = [];
  @observable public isPositionsLoading:boolean = false;

  @observable public teamPositionsListScrollYPosition = 0;

  // Create/Edit Team Dialog ----------------
  @observable public isTeamDialogOpen:boolean = false;
  @observable public isTeamLoading:boolean = false;
  @observable public teamId:number = -1;
  @observable public teamName:string = '';
  @observable public teamTags:Array<string> = [];
  @observable public teamNotes:string = '';
  @observable public isTeamActive:boolean = false;

  // ----------------------------------------------------

  @observable public deletingTeamtId:Maybe<number> = null;
  @observable public isDeleteTeamDialogOpen:boolean = false;
  @observable public isDeleteTeamInProgress:boolean = false;

  // ---------------------------------------------------------

  @computed
  public get isTeamValid():boolean
  {
    return (this.teamName || '').trim().length > 0;
  }

  @computed
  public get isTeamPositionsValid():boolean
  {
    return !!(this.team && this.team.positions?.every(
      (position:Recruiting_Position_Ex) => position.newQuantity
        && position.newQuantity > 0
        && (!position.total_slots_count
          || position.newQuantity >= (position.total_slots_count.aggregate.count - position.open_slots_count.aggregate.count))
    ));
  }

  // --------------------------------------------
}
