import { InfoPanel } from '@flow/common/components/elements/InfoPanel';
import { AnchorButton } from '@flow/common/components/form/AnchorButton';
import { BlankState } from '@flow/common/components/form/BlankState';
import { Button } from '@flow/common/components/form/Button';
import { NeedsPermission } from '@flow/common/components/NeedsPermission';
import { Intent } from '@flow/common/components/types/Types';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { component, di } from '@flow/dependency-injection';
import { AddPositionDialog } from '@flow/modules/staffing/staff/components/member/allocation/AddPositionDialog';
import { AllocationPositions } from '@flow/modules/staffing/staff/components/member/allocation/AllocationPositions';
import { StaffMemberAllocationController } from '@flow/modules/staffing/staff/StaffMemberAllocationController';
import { StaffMemberState } from '@flow/modules/staffing/staff/StaffMemberState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import moment, { Moment } from 'moment';
import React from 'react';

import styles from './StaffMemberPanelAllocation.module.less';

const cx = classNames.bind(styles);

@component
export class StaffMemberPanelAllocation extends React.Component
{
  @di private _staffMemberState!:StaffMemberState;
  @di private _staffMemberAllocationController!:StaffMemberAllocationController;

  // ---------------------------------------------------------

  @bind
  private _renderArchivedMessage():React.ReactNode | null
  {
    const { staffMember } = this._staffMemberState;

    if( !staffMember ) return null;

    const { leaving_date } = staffMember;

    const mDate:Moment | null = leaving_date ? moment(leaving_date) : null;
    const strDate:string = mDate && mDate.isValid() ? mDate.format('MMMM D, YYYY') : '';

    return (
      <BlankState
        className={styles.archivedMessage}
        withBorder
        title={`Left company at ${strDate}`}
        content={(
          <AnchorButton
            className={styles.viewHistoryBtn}
            text={'View history'}
            minimal
            intent={Intent.PRIMARY}
          />
        )}
      />
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { isStaffMemberLoaded, isLeaving, isArchived } = this._staffMemberState;

    const isBtnAddDisabled:boolean = isLeaving || isArchived;

    return (
      <InfoPanel
        className={styles.panel}
        headerTitle={'Positions'}
        headerContent={isStaffMemberLoaded && (!isLeaving && !isArchived) ? (
          <div className={styles.buttons}>
            <NeedsPermission name={FlowPermissions.ReallocateStaff}>
              <Button
                className={cx(styles.btnAllocation, { isDisabled: isBtnAddDisabled })}
                text={'Add position'}
                minimal={true}
                outlined={true}
                intent={Intent.PRIMARY}
                disabled={isBtnAddDisabled}
                onClick={():void =>
                {
                  this._staffMemberAllocationController.showAddPositionDialog();
                }}
              />
            </NeedsPermission>
          </div>
        ) : undefined}
      >
        {
          !isArchived &&
          <>
            <AllocationPositions />
            <AddPositionDialog />
          </>
        }
        {isArchived && this._renderArchivedMessage()}

      </InfoPanel>
    );
  }
}
