import { TextWithLinks } from '@flow/common/components/elements/TextWithLinks';
import { BlankState } from '@flow/common/components/form/BlankState';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { Spinner } from '@flow/common/components/form/Spinner';
import type { HistoryItem } from '@flow/common/controllers/ObjectHistoryController';
import { HasuraTableColumns, HasuraTableNames, HasuraTables } from '@flow/common/models/HasuraTables';
import type { Maybe } from '@flow/common/models/Types';
import { StringUtil } from '@flow/common/utils/StringUtil';
import type {
  Common_Scheduled_Event,
  Common_User,
  Recruiting_Candidate,
  Recruiting_Candidate_Note
} from '@flow/data-access/lib/types/graphql.generated';
import {
  Recruiting_Candidate_Note_Type_Enum,
  Recruiting_Candidate_Status_Enum,
  Recruiting_Candidate_Status_Reason
} from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { CandidateController } from '@flow/modules/recruiting/candidates/CandidateController';
import { CandidateFlowState } from '@flow/modules/recruiting/candidates/CandidateFlowState';
import { CandidateStatusTitles } from '@flow/modules/recruiting/candidates/CandidatesState';
import { CandidateState, CandidateTabName } from '@flow/modules/recruiting/candidates/CandidateState';
import { FeedbackLink } from '@flow/modules/recruiting/candidates/components/candidate/communication/feedback/FeedbackLink';
import { ScheduleState } from '@flow/modules/recruiting/schedule/ScheduleState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { computed, toJS } from 'mobx';
import moment from 'moment';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import styles from './CommunicationContent.module.less';
import { CommunicationContentItem } from './CommunicationContentItem';
import { CommunicationEventViewContent } from './eventForm/CommunicationEventViewContent';

const cx = classNames.bind(styles);

// TODO: remove
const __DEBUG:boolean = false;

export enum HistoryAction
{
  CREATED = 'I',
  UPDATED = 'U',
  DELETED = 'D',
}

@component
export class CommunicationContent extends React.Component<unknown>
{
  @di private _candidateState!:CandidateState;
  @di private _candidateController!:CandidateController;
  @di private _scheduleState!:ScheduleState;
  @di private _candidateFlowState!:CandidateFlowState;

  // ----------------------------------------------------

  private _actionMap:Map<string, string> = new Map([
    [HistoryAction.CREATED, 'created'],
    [HistoryAction.UPDATED, 'updated'],
    [HistoryAction.DELETED, 'deleted']
  ]);

  private _candidateFieldNames:Map<string, string> = new Map([
    ['id', 'ID'],
    ['email', 'Email'],
    ['phone', 'Phone'],
    ['status', 'Status'],
    ['status_date', 'Status date'],
    ['avatar_url', 'Profile picture'],
    ['created_at', ''],
    ['updated_at', ''],
    ['first_name', 'First Name'],
    ['last_name', 'Last Name'],
    ['staff_id', 'Staff'],
    ['cv_file_url', 'CV file'],
    ['assigned_user_id', 'Assigned User'],
    ['interview_flow_stage_id', 'Interview flow stage'],
    ['sourced_by_user_id', 'Sourced by'],
    ['linkedin_profile_url', 'LinkedIn profile'],
    ['created_by_user_id', 'Created by'],
    ['recruited_by_user_id', 'Recruited by'],
    ['recommended_by_staff_id', 'Recommended by'],
    ['current_employer', 'Current employer'],
    ['instant_messenger_id', 'Instant messenger ID'],
    ['source', 'Source'],
    ['city_id', 'City'],
    ['country_id', 'Country'],
    ['current_employer', 'Current employer'],
    ['current_employer', 'Current employer'],
    ['first_name_i18n', 'Имя'],
    ['last_name_i18n', 'Фамилия'],
    ['middle_name_i18n', 'Отчество'],
    ['cv_google_doc_ref', 'CV document ID']
  ]);

  // ----------------------------------------------------

  @computed
  private get _isExecSummary():boolean
  {
    return this._candidateState.tabId == CandidateTabName.EXEC_SUMMARY;
  }

  @computed
  private get _isAllHistory():boolean
  {
    return !this._isExecSummary && this._candidateState.communicationTabId == CandidateTabName.HISTORY;
  }

  @computed
  private get _isNotes():boolean
  {
    return this._isExecSummary || this._candidateState.communicationTabId == CandidateTabName.NOTES;
  }

  @computed
  private get _isCalls():boolean
  {
    return !this._isExecSummary && this._candidateState.communicationTabId == CandidateTabName.CALLS;
  }

  @computed
  private get _isTimeline():boolean
  {
    return !this._isExecSummary && this._candidateState.communicationTabId == CandidateTabName.TIMELINE;
  }

  // ----------------------------------------------------

  private _formatValue(value:unknown, canBeDate?:boolean):string | JSX.Element
  {
    if( value === null )
      return <span className={styles.notSet}>not set</span>;

    if( typeof (value) == 'undefined' )
      return <span className={styles.notSet}>not set</span>;

    if( typeof (value) === 'boolean' )
      return value ? 'true' : 'false';

    if( value === '' )
      return <span className={styles.notSet}>empty</span>;

    if( Number(value) )
      return String(value);

    const date = canBeDate ? moment(value as string) : null;

    // return String(value);
    return date && date.isValid() ? date.format('MMM D, YYYY h:mm A') : String(value);
  }

  // ----------------------------------------------------

  private _getUserNameById(id:Maybe<number>):string
  {
    if( !id ) return '';

    const user:Maybe<Common_User> = this._scheduleState.allUsers.find(user => user.id === id);

    return this._getUserName(user);
  }

  private _getUserName(user:Maybe<Common_User>):string
  {
    return StringUtil.getUserName(user);
  }

  // ----------------------------------------------------

  private _renderRightArrow():React.ReactNode
  {
    return (
      <Icon icon={IconNames.ArrowRight} size={10} className={styles.rightArrow} />
    );
  }

  // ----------------------------------------------------

  // private _renderAllocation(item:HistoryItem):JSX.Element | null
  // {
  //   const allocation = this._candidateState.candidateAllocationsHistory
  //     .find(allocation => HistoryUtil.compareTimestamps(String(allocation.action_tstamp_clk), String(item.action_tstamp_clk), 1));

  //   if( !allocation )
  //     return null;

  //   const deletedPositions = allocation ? allocation?.deletedPositions
  //     .map(positionItem => this._candidateState.historicalPositions.find(position => position.id === positionItem.row_data.position_id))
  //     .filter(position => !!position) : [];

  //   const addedPositions = allocation ? allocation?.addedPositions
  //     .map(positionItem => this._candidateState.historicalPositions.find(position => position.id === positionItem.row_data.position_id))
  //     .filter(position => position !== undefined) : [];

  //   const currentPositions:Array<Recruiting_Position> = [];

  //   addedPositions.forEach((added, i) =>
  //   {
  //     const deletedIndex = deletedPositions.findIndex(deleted => deleted && added && deleted.id == added.id);
  //     if( deletedIndex >= 0 )
  //     {
  //       added && currentPositions.push(added);
  //       addedPositions.splice(i, 1);
  //       deletedPositions.splice(deletedIndex, 1);
  //     }
  //   });

  //   if( addedPositions.length == 0 && deletedPositions.length == 0 )
  //     currentPositions.length = 0;

  //   const notes = item.action === 'U' ? item.changed_fields?.notes : item.row_data.notes;

  //   let actionMessage;
  //   let objectName;

  //   switch( item.action )
  //   {
  //     case 'I':
  //       actionMessage = allocation?.row_data.is_final ? 'finalized the' : 'proposed the';
  //       objectName = 'Allocation';
  //       break;

  //     case 'U':
  //       actionMessage = 'edited the';
  //       objectName = allocation?.row_data.is_final ? 'Final Allocation' : 'Proposed Allocation';
  //       break;

  //     case 'D':
  //       actionMessage = 'deleted the';
  //       objectName = allocation?.row_data.is_final ? 'Final Allocation' : 'Proposed Allocation';
  //       break;

  //     default:
  //       return null;
  //   }

  //   const color = allocation?.row_data.is_final ? UserPillColor.GREEN : UserPillColor.GRAY;

  //   return (
  //     <CommunicationContentItem
  //       key={item.action_tstamp_clk}
  //       item={item}
  //       pillTextBegin={actionMessage}
  //       pillTextEnd={objectName}
  //     >
  //       {
  //         currentPositions.length > 0 &&
  //         <>
  //           <div className={cx(styles.noteRow, styles.noteFieldName)}>
  //             Current
  //           </div>
  //           <ul className={styles.positionList}>
  //             {
  //               currentPositions?.map(position => <li key={position?.id} className={styles.positionListItem}>
  //                 <AllocationPosition position={position} />
  //               </li>)
  //             }
  //           </ul>
  //         </>
  //       }
  //       {
  //         addedPositions && addedPositions.length > 0 &&
  //         <>
  //           <div className={cx(styles.noteRow, styles.noteFieldName)}>
  //             {item.action == 'I' ? 'Positions' : 'Added'}
  //           </div>
  //           <ul className={styles.positionList}>
  //             {
  //               addedPositions?.map(position => <li key={position?.id} className={styles.positionListItem}>
  //                 <AllocationPosition position={position} />
  //               </li>)
  //             }
  //           </ul>
  //         </>
  //       }
  //       {
  //         deletedPositions && deletedPositions.length > 0 &&
  //         <>
  //           <div className={cx(styles.noteRow, styles.noteFieldName)}>
  //             Excluded
  //           </div>
  //           <ul className={styles.positionList}>
  //             {
  //               deletedPositions?.map(position => <li
  //                 key={position?.id} className={cx(styles.positionListItem, 'strikethrough')}
  //               >
  //                 <AllocationPosition position={position} />
  //               </li>)
  //             }
  //           </ul>
  //         </>
  //       }
  //       {
  //         notes &&
  //         <div className={styles.allocationNotes}>
  //           <div className={cx(styles.noteRow, styles.noteFieldName)}>
  //             Note
  //           </div>
  //           <AllocationNotes className={styles.noteContent} notes={notes} />
  //         </div>
  //       }
  //     </CommunicationContentItem>
  //   );
  // }

  // ----------------------------------------------------

  // private _renderPositionGroups(items:Array<HistoryItem>):JSX.Element
  // {
  //   const item = items[0];

  //   return (
  //     <CommunicationContentItem
  //       key={item.action_tstamp_clk}
  //       item={item}
  //       pillTextBegin={item.action == 'I' ? 'added' : 'removed'}
  //       pillTextEnd={'Position Groups'}
  //     >
  //       {
  //         items.map(historicalPositionGroup =>
  //         {
  //           const positionGroup = this._candidateState.historicalPositionGroups
  //             .find(positionGroup => positionGroup.id == historicalPositionGroup.row_data.position_group_id);

  //           return <div key={historicalPositionGroup.action_tstamp_clk}>
  //             {
  //               positionGroup?.name &&
  //               <PositionGroupPill name={positionGroup?.name} />
  //             }
  //           </div>;
  //         })
  //       }
  //     </CommunicationContentItem>
  //   );
  // }

  // ----------------------------------------------------

  @bind
  private _renderFromTo(from:Maybe<string>, to:Maybe<string>):React.ReactNode
  {
    return (
      <span className={styles.fromTo}>
        {this._formatValue(from)}
        {this._renderRightArrow()}
        {this._formatValue(to)}
      </span>
    );
  }

  // ----------------------------------------------------

  private _renderCandidateCreated(item:HistoryItem):JSX.Element
  {
    // const { row_data: fields } = item;

    const fields:Recruiting_Candidate = item.row_data as Recruiting_Candidate;

    // const isAssigned:boolean = !!fields.assigned_user_id;

    const assignedUserName:string = this._getUserNameById(fields.assigned_user_id);

    return (
      <CommunicationContentItem
        key={item.event_id}
        item={item}
        isTimeline={this._isTimeline}
        titleTextBegin={'Candidate'}
        titleTextEnd={'created'}
      >
        {
          (assignedUserName) &&
          <div className={styles.noteRow}>
            Assigned to
            <span className={styles.fromTo}>
              &nbsp;{assignedUserName}
            </span>
          </div>
        }
        {
          (!assignedUserName) &&
          <div className={styles.noteRow}>
            Unassigned
          </div>
        }
      </CommunicationContentItem>
    );
  }

  // ----------------------------------------------------

  private _renderAssignedUser(item:HistoryItem):JSX.Element
  {
    const { row_data: oldFields, changed_fields: newFields } = item;

    const assignedFromId:Maybe<number> = oldFields.assigned_user_id;
    const assignedToId:Maybe<number> = newFields.assigned_user_id;

    const assignedFromName:string = this._getUserNameById(assignedFromId);
    const assignedToName:string = this._getUserNameById(assignedToId);

    const titleEnd:string = assignedFromName && assignedToName ? 'reassigned'
      : assignedFromName ? 'unassigned'
        : assignedToName ? 'assigned to' : '';

    return (
      <CommunicationContentItem
        key={item.event_id}
        item={item}
        titleTextBegin={'Candidate'}
        titleTextEnd={titleEnd}
      >
        {
          // Candidate unassigned -> no render names
          // assignedFromName && !assignedToName &&
        }
        {
          assignedToName &&
          <div className={styles.noteRow}>
            {
              // Candidate assigned
              !assignedFromName &&
              <span className={styles.fromTo}>{assignedToName}</span>
            }
            {
              // Candidate reassigned
              assignedFromName &&
              this._renderFromTo(assignedFromName, assignedToName)
            }
          </div>
        }
      </CommunicationContentItem>
    );
  }

  // ----------------------------------------------------

  private _renderInterviewStage(item:HistoryItem):JSX.Element
  {
    const { getStageById } = this._candidateFlowState;

    const { row_data: oldFields, changed_fields: newFields } = item;

    const fromStage = getStageById(oldFields.interview_flow_stage_id);
    const toStage = getStageById(newFields.interview_flow_stage_id);

    console.log('%c --> =', 'background:#088;color:#000;', fromStage?.name, '->', toStage?.name);
    // console.log('%c --> fromStage =', 'background:#aff;color:#000;', toJS(fromStage));
    // console.log('%c --> toStage   =', 'background:#aff;color:#000;', toJS(toStage));

    const isFlowStageChanged = Object.keys(new Object(newFields))
      .includes(HasuraTableColumns.RecruitingCandidate_InterviewFlowStageId);

    return (
      <CommunicationContentItem
        key={item.event_id}
        item={item}
        isTimeline={this._isTimeline}
        titleTextBegin={'Stage'}
        titleTextEnd={'changed'}
      >
        {
          isFlowStageChanged &&
          <div className={styles.noteRow}>
            {this._renderFromTo(fromStage?.name, toStage?.name)}
          </div>
        }
      </CommunicationContentItem>
    );
  }

  // ----------------------------------------------------

  private _renderStatus(item:HistoryItem):JSX.Element
  {
    const { getStageById, getReasonsById, getFlowByStageId } = this._candidateFlowState;

    const { row_data: oldFields, changed_fields: newFields } = item;

    // const toStage = getStageById(newFields.interview_flow_stage_id);

    // const toStageName:Maybe<string> = toStage?.name;

    // const isFlowStageChanged = Object.keys(new Object(newFields))
    //   .includes(HasuraTableColumns.RecruitingCandidate_InterviewFlowStageId);

    const isStatusChanged = Object.keys(new Object(newFields))
      .includes(HasuraTableColumns.RecruitingCandidate_Status);

    // -----------------

    const statusFrom = oldFields[HasuraTableColumns.RecruitingCandidate_Status];
    const statusTo = newFields[HasuraTableColumns.RecruitingCandidate_Status];

    let strStatusFrom:Maybe<string> = '';
    let strStatusTo:Maybe<string> = '';

    // -----------------

    const showStatusFrom:boolean = statusTo !== Recruiting_Candidate_Status_Enum.Declined &&
      statusTo !== Recruiting_Candidate_Status_Enum.Refused &&
      statusTo !== Recruiting_Candidate_Status_Enum.Archived &&
      statusFrom !== Recruiting_Candidate_Status_Enum.Active;

    if( showStatusFrom )
    {
      const isFromArchived:boolean = statusFrom === Recruiting_Candidate_Status_Enum.Declined ||
        statusFrom === Recruiting_Candidate_Status_Enum.Refused ||
        statusFrom === Recruiting_Candidate_Status_Enum.Archived;

      if( isFromArchived )
        strStatusFrom = 'Archived';
      else
        strStatusFrom = CandidateStatusTitles.get(String(statusFrom));
    }
    else
    {
      const fromStage = getStageById(oldFields.interview_flow_stage_id);
      strStatusFrom = fromStage?.name;
    }

    // -----------------

    const isToArchived:boolean = statusTo === Recruiting_Candidate_Status_Enum.Declined ||
      statusTo === Recruiting_Candidate_Status_Enum.Refused ||
      statusTo === Recruiting_Candidate_Status_Enum.Archived;

    if( isToArchived )
      strStatusTo = 'Archived';
    else
      strStatusTo = CandidateStatusTitles.get(String(statusTo));

    const strArchivedBy:string = statusTo === Recruiting_Candidate_Status_Enum.Declined
      ? 'Declined by company'
      : statusTo === Recruiting_Candidate_Status_Enum.Refused
        ? 'Refused by candidate'
        : '';

    // -----------------

    const reasonId:Maybe<number> = newFields[HasuraTableColumns.RecruitingCandidate_StatusReasonId];
    const reason:Maybe<Recruiting_Candidate_Status_Reason> = getReasonsById(reasonId);

    const reasonNotes:Maybe<string> = newFields[HasuraTableColumns.RecruitingCandidate_StatusReasonNotes];

    console.log('%c --> status =', 'background:#088;color:#000;', strStatusFrom, '->', strStatusTo);

    // -----------------

    const isViewFlowName:boolean = statusFrom === Recruiting_Candidate_Status_Enum.Initial &&
      statusTo === Recruiting_Candidate_Status_Enum.Active;

    let toFlowStageName:Maybe<string> = '';

    if( isViewFlowName )
    {
      toFlowStageName = getFlowByStageId(newFields.interview_flow_stage_id || -1)?.name;
    }

    // -----------------

    return (
      <CommunicationContentItem
        key={item.event_id}
        item={item}
        isTimeline={this._isTimeline}
        titleTextBegin={'Status'}
        titleTextEnd={'changed'}
      >
        {
          isStatusChanged &&
          <div className={styles.noteRow}>
            {this._renderFromTo(strStatusFrom, strStatusTo)}
            {
              // ... -> Archived • Refused by candidate | Declined by company
              //                 ^ ^^^^^^^^^^^^^^^^^^^^   ^^^^^^^^^^^^^^^^^^^
              strArchivedBy &&
              <>
                <span className={styles.titleDot}>•</span>
                {strArchivedBy}
              </>
            }
            {
              // Initial -> In Work • {toFlowStageName}
              //                    ^ ^^^^^^^^^^^^^^^^^
              toFlowStageName &&
              <>
                <span className={styles.titleDot}>•</span>
                {toFlowStageName}
              </>
            }
            {/*{this._formatValue(strStatusTo)}*/}
            {/*<span className={styles.noteFieldName}>*/}
            {/*  {CandidateStatusTitles.get(String(statusTo))}*/}
            {/*</span>*/}
          </div>
        }
        {
          reason &&
          <div className={styles.noteRow}>
            {reason.name}
          </div>
        }
        {
          reasonNotes &&
          <div className={styles.noteRow}>
            <div className={styles.rawContent}>
              {reasonNotes}
            </div>
          </div>
        }
        {
          // isFlowStageChanged &&
          // <div className={styles.noteRow}>
          //   <span className={styles.fromTo}>
          //     {this._formatValue(fromStage?.name ?? '??')}
          //     {this._renderRightArrow()}
          //     {this._formatValue(toStage?.name ?? '??')}
          //   </span>
          // </div>
        }
      </CommunicationContentItem>
    );
  }

  // ----------------------------------------------------

  private _renderCandidateNotes(item:HistoryItem):JSX.Element | null
  {
    const { row_data: oldFields, changed_fields: newFields } = item;

    if( newFields && !newFields.content )
      return null;

    const historicalNote:Recruiting_Candidate_Note = {
      ...oldFields,
      ...newFields
    };

    const currentNote:Maybe<Recruiting_Candidate_Note> = this._candidateState.candidate?.candidate_notes
      .find(note => note.id == oldFields.id);

    if( this._isExecSummary && !currentNote?.is_pinned ) return null;

    const noteType:string = historicalNote.type === Recruiting_Candidate_Note_Type_Enum.General ? ''
      : historicalNote.type === Recruiting_Candidate_Note_Type_Enum.Salary ? 'Salary'
        : historicalNote.type === Recruiting_Candidate_Note_Type_Enum.Desire ? 'Candidate Desire'
          : historicalNote.type === Recruiting_Candidate_Note_Type_Enum.Equipment ? 'Equipment'
            : '';

    return (
      <CommunicationContentItem
        key={item.event_id}
        item={item}
        titleTextBegin={'Note'}
        titleTextEnd={item.action === HistoryAction.CREATED ? 'added' : 'edited'}
        isPinned={currentNote?.is_pinned}
        noBorder={this._isExecSummary}
        onPin={():void =>
        {
          this._candidateController.pinNote(historicalNote.id);
        }}
        onUnpin={():void =>
        {
          this._candidateController.unpinNote(historicalNote.id);
        }}
      >
        <div className={styles.rawContent}>
          <TextWithLinks text={historicalNote.content} />
        </div>
        {
          noteType &&
          <div className={styles.noteType}>
            {noteType}
          </div>
        }
        {
          // historicalNote.content.split('\n').map((line, index) =>
          // {
          //   return (
          //     <div className={styles.noteRow} key={`${index}_${line}`}>
          //       {line.length ? line : <span style={{ height: '18px' }}></span>}
          //     </div>
          //   );
          // })
        }
      </CommunicationContentItem>
    );
  }

  // ----------------------------------------------------

  private _renderCanidateCalls(item:HistoryItem):JSX.Element | null
  {
    const { row_data: oldFields, changed_fields: newFields } = item;

    const historicalEvent:Common_Scheduled_Event = {
      ...oldFields,
      ...newFields
    };

    const { candidate } = this._candidateState;

    const currentEvent = candidate?.scheduled_calls
      .find(candidateCall => candidateCall.scheduled_call.id == oldFields.id)?.scheduled_call;

    const isJustScheduled = !oldFields.is_scheduled && newFields.is_scheduled;
    const isJustCompleted = item.action == HistoryAction.UPDATED && !oldFields.is_done && newFields.is_done;
    const isJustCancelled = item.action == HistoryAction.UPDATED && newFields.deleted_at != null;
    const isJustCompletedFeedback = !oldFields.is_feedback_done && newFields.is_feedback_done;
    const isJustUncompletedFeedback = oldFields.is_feedback_done && !newFields.is_feedback_done;

    let isRescheduled:boolean = false;

    let actionString = '?';
    let titleTextBegin:string = 'Call';

    if( isJustCompleted )
    {
      actionString = 'completed';
    }
    else if( isJustCancelled )
    {
      actionString = 'cancelled';
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    else if( item.action == HistoryAction.UPDATED && Object.keys(newFields).length > 1 )
    {
      if( isJustScheduled )
      {
        actionString = 'scheduled';
      }
      else if( isJustCompletedFeedback )
      {
        titleTextBegin = 'Feedback form';
        actionString = 'completed';
      }
      else if( isJustUncompletedFeedback )
      {
        titleTextBegin = 'Feedback form';
        actionString = 'uncompleted';
      }
      else
      {
        actionString = 'rescheduled';
        isRescheduled = true;
      }
    }

    return (
      <CommunicationContentItem
        key={item.event_id}
        item={item}
        isTimeline={this._isTimeline}
        titleTextBegin={titleTextBegin}
        titleTextEnd={actionString}
      >
        {
          !(isJustScheduled || isRescheduled) &&
          <div className={styles.noteRow}>
            <span className={styles.fromTo}>
              {historicalEvent.summary}
            </span>
          </div>
        }
        {
          (isJustScheduled || isRescheduled) &&
          <CommunicationEventViewContent
            className={styles.scheduledCallContent}
            event={historicalEvent}
            attendees={this._candidateController.getEventAttendeesAsUsers(historicalEvent)}
            compact
          />
        }
        {
          (isJustScheduled || isRescheduled) &&
          <div className={styles.feedbackLinkWrapper}>
            <FeedbackLink historyView event={currentEvent || historicalEvent} />
          </div>
        }
      </CommunicationContentItem>
    );
  }

  // ----------------------------------------------------

  private _renderSpinner():React.ReactNode
  {
    return (
      <Spinner
        withWrapper withWave noIcon
        wrapperClassName={cx(styles.incrementalLoader)}
      />
    );
  }

  // ----------------------------------------------------

  private _getHistoryItems():Array<JSX.Element | null>
  {
    const { candidateHistory } = this._candidateState;

    return candidateHistory.map((item) =>
    {
      const changedFields:Array<string> | null = item.changed_fields
        ? Object.keys(new Object(item.changed_fields)).filter(key => key != 'updated_at' && key != 'created_at')
        : [];

      // -------------------------

      // if( item.full_qualified_table_name == HasuraTables.RecruitingCandidateAllocation )
      //   return this._renderAllocation(item);

      if( changedFields.length == 1 &&
        changedFields.includes(HasuraTableColumns.RecruitingCandidate_AssignedToUserId) &&
        this._isAllHistory )
      {
        console.log('%c H: ASSIGNED =', 'background:#0ff;color:#000;', toJS(item));
        return this._renderAssignedUser(item);
      }

      // if( changedFields.length == 1 && changedFields.includes(HasuraTableColumns.RecruitingCandidate_FinalizedAllocationId) )
      // {
      //   return null;
      // }

      // if( item.full_qualified_table_name == HasuraTables.RecruitingCandidatePositionGroup )
      //   return this._renderPositionGroups(items);

      if( item.full_qualified_table_name == HasuraTables.RecruitingCandidate )
      {
        if( changedFields.includes(HasuraTableColumns.RecruitingCandidate_Status) &&
          (this._isAllHistory || this._isTimeline) )
        {
          console.log('%c H: STATUS =', 'background:#0ff;color:#000;', toJS(item));
          return this._renderStatus(item);
        }

        if( changedFields.includes(HasuraTableColumns.RecruitingCandidate_InterviewFlowStageId) &&
          // changedFields.length >= 1 && changedFields.length <= 2 &&
          (this._isAllHistory || this._isTimeline) )
        {
          console.log('%c H: STAGE =', 'background:#0ff;color:#000;', toJS(item));
          return this._renderInterviewStage(item);
        }

        if( item.action === HistoryAction.CREATED && changedFields.length == 0 &&
          (this._isAllHistory || this._isTimeline) )
        {
          console.log('%c H: CANDIDATE created =', 'background:#0ff;color:#000;', toJS(item));
          return this._renderCandidateCreated(item);
        }

        if( item.action === HistoryAction.UPDATED && changedFields.length == 0 )
        {
          return null;
        }
      }

      if( item.full_qualified_table_name == HasuraTables.RecruitingCandidateNote &&
        (this._isAllHistory || this._isNotes) )
      {
        console.log('%c H: NOTES =', 'background:#0ff;color:#000;', toJS(item));
        return this._renderCandidateNotes(item);
      }

      if( item.full_qualified_table_name == HasuraTables.CommonScheduledEvent &&
        (this._isAllHistory || this._isCalls) )
      {
        console.log('%c H: CALLS =', 'background:#0ff;color:#000;', toJS(item));
        return this._renderCanidateCalls(item);
      }

      // -------------------------

      if( !this._isAllHistory ) return null;

      return null;

      console.log('%c H: ??? =', 'background:#f88;color:#000;', toJS(item));

      return (
        <CommunicationContentItem
          key={item.event_id}
          item={item}
          titleTextBegin={HasuraTableNames.get(item.full_qualified_table_name) || item.full_qualified_table_name}
          titleTextEnd={this._actionMap.get(item.action)}
          isDebug
        >
          {
            item.action === HistoryAction.UPDATED && (
              Object.keys(new Object(item.changed_fields)).filter(key => key !== 'updated_at').map(key =>
              {
                if( ['avatar_url', 'linkedin_profile_url', 'cv_google_doc_ref'].includes(key) )
                {
                  return (
                    <React.Fragment key={key}>
                      <div className={styles.noteRow}>

                        <span className={styles.noteFieldName}>
                          {this._candidateFieldNames.get(key) || key}
                        </span>

                      </div>

                      <div className={styles.noteRow}>
                        {
                          key == 'avatar_url'
                            ? <img src={item.changed_fields[key]} width="100px" height="100px" />
                            : this._formatValue(item.changed_fields[key])
                        }
                      </div>

                    </React.Fragment>
                  );
                }
                else
                {
                  return (
                    <div key={key} className={styles.noteRow}>

                      <span className={styles.noteFieldName}>
                        {this._candidateFieldNames.get(key) || key}:
                      </span>

                      {this._formatValue(item.row_data[key])}
                      {this._renderRightArrow()}
                      {this._formatValue(item.changed_fields[key])}

                    </div>
                  );
                }
              })
            )
          }

          {
            item.action === HistoryAction.CREATED && item.full_qualified_table_name === HasuraTables.RecruitingCandidate && (
              Object.keys(new Object(item.row_data))
                .filter(key => key !== 'created_at'
                  && key !== 'updated_at'
                  && item.row_data[key])
                .map(key =>
                {
                  return <div key={key} className={styles.noteRow}>

                    <span className={styles.noteFieldName}>
                      {this._candidateFieldNames.get(key) || key}:
                    </span>

                    {this._formatValue(item.row_data[key])}

                  </div>;
                })
            )
          }
        </CommunicationContentItem>
      );
    });
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { isHistoryLoaded, candidateHistory } = this._candidateState;

    console.groupCollapsed(' <-- HISTORY');
    const items = this._getHistoryItems().filter(item => !!item);
    console.groupEnd();

    return (
      <div className={cx(styles.content)}>
        {
          (isHistoryLoaded && items.length === 0) &&
          <BlankState title={this._isExecSummary ? 'No notes...' : 'No events...'} withBorder />
        }
        {
          (isHistoryLoaded && items.length > 0) &&
          <InfiniteScroll
            dataLength={items.length}
            next={this._candidateController.fetchNextCandidateHistory}
            hasMore={this._candidateState.hasMoreHistory}
            loader={this._renderSpinner()}

            className={styles.infiniteScroll}
            scrollableTarget={'scrollableDiv'}
          >
            {items}
          </InfiniteScroll>
        }
        {
          !isHistoryLoaded && candidateHistory.length > 0 &&
          this._renderSpinner()
        }
      </div>
    );
  }

  // ----------------------------------------------------
}
