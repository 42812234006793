import { Collapse } from '@blueprintjs/core';
import { CommonController, StaffOrCandidateUrl } from '@flow/common/CommonController';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { BlankState } from '@flow/common/components/form/BlankState';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { DebugUtil } from '@flow/common/utils/DebugUtil';
import type {
  Recruiting_Allocation_Position,
  Recruiting_Candidate,
  Recruiting_Candidate_Position_Group,
  Recruiting_Position
} from '@flow/data-access/lib/types/graphql.generated';
import { di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { computed, toJS } from 'mobx';
import React from 'react';

import styles from './PositionCandidates.module.less';

const cx = classNames.bind(styles);

// TODO: remove
const __DEBUG:boolean = false;

interface IPositionCandidatesProps
{
  position:Recruiting_Position;
  openSlotsCount:number;
  totalSlotsCount:number;
  fullWidth?:boolean;
}

interface IPositionCandidatesState
{
  isProposedCandidateListOpen:boolean;
}

export class PositionCandidates extends React.Component<IPositionCandidatesProps, IPositionCandidatesState>
{
  @di private readonly _commonController!:CommonController;

  // ----------------------------------------------------

  public constructor(props:IPositionCandidatesProps)
  {
    super(props);

    this.state = {
      isProposedCandidateListOpen: false
    };
  }

  // ----------------------------------------------------

  @bind
  private _toggleProposedCollapse():void
  {
    this.setState({
      isProposedCandidateListOpen: !this.state.isProposedCandidateListOpen
    });
  }

  // ----------------------------------------------------

  @computed
  private get _proposedCandidates():Array<Recruiting_Candidate>
  {
    const { position, openSlotsCount } = this.props;

    __DEBUG && console.log('%c position.allocations =', 'background:#f0f;color:#000;', position.id, toJS(position));

    if( openSlotsCount == 0 ) return [];

    const result:Array<Recruiting_Candidate> = [];

    position.allocations.forEach((allocation:Recruiting_Allocation_Position) =>
    {
      if( allocation.candidate_allocation.is_final )
      {
        __DEBUG && console.log('%c --> is_final =', 'background:#f88;color:#000;', allocation.candidate_allocation.candidate.id, toJS(allocation.candidate_allocation));
        return;
      }

      const candidate = allocation.candidate_allocation.candidate;

      if( !candidate )
        return;

      const isCorrectPositionGroup:boolean = candidate.candidate_position_groups.some((pg:Recruiting_Candidate_Position_Group) =>
      {
        return pg.position_group_id === position.position_group_id;
      });
      if( !isCorrectPositionGroup )
      {
        __DEBUG && console.log('%c --> ', 'background:#f88;color:#000;', 'isCorrectPositionGroup =', toJS(isCorrectPositionGroup));
        return;
      }

      // status = (declined || refused) has no allocations
      // check only for staffed
      // if( candidate.status === Recruiting_Candidate_Status_Enum.Staffed )
      // {
      //   __DEBUG && console.log('%c --> staffed =', 'background:#f88;color:#000;', allocation.candidate_allocation.candidate.id, toJS(allocation.candidate_allocation));
      //   return;
      // }

      if( allocation.candidate_allocation.allocation_positions_aggregate.aggregate?.count !== 0 )
      {
        __DEBUG && console.log('%c --> ', 'background:#f88;color:#000;', 'staffed OR finalized =', allocation.candidate_allocation.candidate.id, toJS(allocation.candidate_allocation));
        return;
      }

      if( result.findIndex(existingCandidate => existingCandidate.id == candidate.id) == -1 )
      {
        __DEBUG && console.log('%c --> ', 'background:#080;color:#000;', allocation.candidate_allocation.candidate.id, toJS(allocation.candidate_allocation));
        result.push(allocation.candidate_allocation.candidate);
      }
    });

    return result;
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { openSlotsCount, fullWidth } = this.props;

    if( openSlotsCount == 0 )
      return null;

    const proposedCandidates:Array<Recruiting_Candidate> = this._proposedCandidates;

    const isOpen:boolean = this.state.isProposedCandidateListOpen;

    return (
      <div className={cx(styles.candidatesWrapper, { fullWidth })}>

        <div
          className={cx(styles.header, { isOpen })}
          onClick={this._toggleProposedCollapse}
        >
          <Icon
            className={styles.iconOpen}
            icon={isOpen ? IconNames.CHEVRON_DOWN : IconNames.CHEVRON_RIGHT}
            size={13}
          />

          <div className={styles.headerText}>
            Open
          </div>

          <Icon
            className={styles.iconPerson}
            icon={IconNames.PEOPLE}
            size={12}
          />

          <div className={cx(styles.slotsCount)}>
            {proposedCandidates.length}
          </div>

          <Icon
            className={styles.arrowIcon}
            icon={IconNames.ARROW_RIGHT}
            size={10}
          />

          <div className={cx(styles.slotsCount)}>
            {openSlotsCount}
          </div>

        </div>

        {/* TODO ------------------------ */}

        <Collapse
          isOpen={isOpen}
        >
          <div className={cx(styles.proposedCandidates, { isOpen })}>

            <div className={styles.proposedHeader}>
              Proposed Candidates
            </div>
            {
              proposedCandidates.length === 0 &&
              <BlankState
                className={styles.blankState}
                title={'No candidates'}
              />
            }
            {
              proposedCandidates.map(candidate =>
              {
                const candidatePageUrl:StaffOrCandidateUrl = this._commonController.getCandidateUrl(candidate.id);

                return (
                  <a
                    key={candidate.id}
                    className={cx(styles.candidate, { isLink: candidatePageUrl })}
                    href={candidatePageUrl}
                  >
                    <Avatar
                      className={styles.avatar}
                      url={candidate.staff?.avatar_url || candidate.staff?.user.avatar}
                      size={20}
                    />

                    <div className={cx(styles.candidateName, styles.blue)}>
                      {DebugUtil.id(candidate.id)}
                      {candidate.first_name} {candidate.last_name}
                    </div>

                  </a>
                );
              })
            }
          </div>
        </Collapse>

      </div>
    );
  }

  // ----------------------------------------------------
}
