import { CommonController } from '@flow/common/CommonController';
import type { Maybe } from '@flow/common/models/Types';
import { FetchEventInfoDocument, FetchEventInfoQuery, FetchEventInfoQueryVariables, FetchEventsInfoDocument, FetchEventsInfoQuery, FetchEventsInfoQueryVariables, FetchFreeBusyInfoQuery, FetchFreeBusyInfoQueryVariables } from '@flow/data-access/lib/events.generated';
import { FetchFreeBusyInfoDocument } from '@flow/data-access/lib/events.generated';
import type { Common_Scheduled_Event, Common_User, GoogleCalendarEvent, GoogleCalendarEvents } from '@flow/data-access/lib/types/graphql.generated';
// import { CommonState } from '@flow/common/CommonState';
import { controller, di } from '@flow/dependency-injection';
import type { ScheduleCallUser } from '@flow/modules/recruiting/schedule/ScheduleState';
import { ScheduleState } from '@flow/modules/recruiting/schedule/ScheduleState';
import bind from 'bind-decorator';
import { action, reaction, runInAction, toJS } from 'mobx';
import moment, { Moment } from 'moment';

@controller
export class ScheduleController
{
  @di private _commonController!:CommonController;
  @di private _scheduleState!:ScheduleState;

  // ----------------------------------------------------

  @bind
  public initSchedule(eventId:number):void
  {
    console.log('%c initSchedule eventId =', 'background:#0f0;color:#000;', eventId);

    runInAction(() =>
    {
      this._scheduleState.isScheduleLoaded = false;
      this._scheduleState.callDetailsEventId = eventId;
    });

    const { eventsForEdit } = this._scheduleState;

    const eventForEdit:Maybe<Partial<Common_Scheduled_Event>> = eventsForEdit.get(eventId)?.event;

    // if( !this._scheduleState.viewDate )
    // {
      this.setViewDate(eventForEdit ? moment(eventForEdit.start_time) : moment());
    // }

    // const { draftEvent } = this._scheduleState;

    runInAction(() => this._scheduleState.isScheduleLoaded = true);

    // this._scheduleState.disposers.push(reaction(
    //   () =>
    //   {
    //     // const { draftEvent, eventForReschedule } = this._scheduleState;
    //     // return { ...draftEvent, ...eventForReschedule };
    //     const { draftEvent } = this._scheduleState;
    //     return { ...draftEvent };
    //   },
    //   ({ ...scheduledEvent }) =>
    //   {
    //     if( scheduledEvent )
    //     {
    //       this.setViewDate(moment(scheduledEvent.start_time) as Moment);
    //       this.fetchFreeBusyInfo();
    //     }
    //   }
    // ));

    // this._scheduleState.disposers.push(reaction(
    //   () =>
    //   {
    //     const { viewDate } = this._scheduleState;
    //     return { viewDate };
    //   },
    //   () =>
    //   {
    //     this.fetchFreeBusyInfo(eventForEdit);
    //   }
    // ));

    this.fetchFreeBusyInfo(eventForEdit);
  }

  // ----------------------------------------------------

  @action.bound
  public setBackUrl(backUrl:string | null):void
  {
    this._scheduleState.btnBackUrl = backUrl;
  }

  // ----------------------------------------------------

  @action.bound
  public setViewDate(newDate:Moment):void
  {
    console.log('%c setViewDate =', 'background:#0f0;color:#000;', newDate);
    this._scheduleState.viewDate = newDate.toISOString();
  }

  // ----------------------------------------------------

  public async fetchFreeBusyInfo(eventForEdit:Maybe<Partial<Common_Scheduled_Event>>):Promise<void>
  {
    console.log('%c fetchFreeBusyInfo eventForEdit =', 'background:#0f0;color:#000;', toJS(eventForEdit));

    if( !eventForEdit ) return;

    const { viewDate, allUsers } = this._scheduleState;

    if( !viewDate || !eventForEdit.attendees_list || eventForEdit.attendees_list.length < 3 ) return;

    const response = await this._commonController.query<FetchFreeBusyInfoQuery, FetchFreeBusyInfoQueryVariables>({
      query: FetchFreeBusyInfoDocument,
      variables: {
        calendarIds: eventForEdit.attendees_list?.split(',') || [],
        timeMin: moment(viewDate).set({ hours: 0, minutes: 0 }).subtract(7, 'days').toISOString(),
        timeMax: moment(viewDate).set({ hours: 0, minutes: 0 }).add(7, 'days').toISOString()
      }
    });

    console.log('%c --> fetchFreeBusyInfo response =', 'background:#f60;color:#000;', toJS(response));

    const scheduleCallUsers:Array<ScheduleCallUser> = [];

    if( !response.data.action_calendar_freebusy )
      throw new Error('Failed to fetch Free/Busy info');

    const { calendars } = response.data.action_calendar_freebusy;

    for( const email in calendars )
    {
      scheduleCallUsers.push({
        user: allUsers.find(user => user.email == email) as Partial<Common_User>,
        timezone: '',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        events: calendars[email].busy.map((event:any) =>
        {
          return {
            start_time: event.start,
            end_time: event.end,
            isFreeBusy: calendars[email].isFreeBusy
          };
        })
      });
    }

    runInAction(() =>
    {
      this._scheduleState.scheduledEventFreeBusy = scheduleCallUsers
        .sort((a, b) =>
        {
          return (a.user?.email || '') > (b.user?.email || '') ? 1 : -1;
        });
    });
  }

  public async fetchEventInfo(calendarId:string, eventId:string):Promise<GoogleCalendarEvent>
  {
    const response = await this._commonController.query<FetchEventInfoQuery, FetchEventInfoQueryVariables>({
      query: FetchEventInfoDocument,
      variables: {
        calendarId,
        eventId
      }
    });

    return response.data.action_calendar_event as GoogleCalendarEvent;
  }

  public async fetchEventsInfo(calendarId:string, fromDate:Date, toDate:Date):Promise<GoogleCalendarEvents>
  {
    const response = await this._commonController.query<FetchEventsInfoQuery, FetchEventsInfoQueryVariables>({
      query: FetchEventsInfoDocument,
      variables: {
        calendarId,
        timeMin: fromDate,
        timeMax: toDate,
      }
    });

    return response.data.action_calendar_events as GoogleCalendarEvents;
  }

  // ----------------------------------------------------

  public setBtnBackUrl(btnBackUrl:string | null):void
  {
    this._scheduleState.btnBackUrl = btnBackUrl;
  }

  // ----------------------------------------------------

  @action.bound
  public addEventForEdit(event:Partial<Common_Scheduled_Event>):void
  {
    if( !event || !event.id ) return;

    // if( !this._scheduleState.eventsForEdit.has(event.id) )
    // {
    this._scheduleState.eventsForEdit.set(event.id, {
      event: toJS(event),
      snapshot: event
    });
    // }
  }

  @action.bound
  public removeEventForEdit(eventId:Maybe<number>):void
  {
    if( !eventId ) return;

    if( this._scheduleState.eventsForEdit.has(eventId) )
    {
      this._scheduleState.eventsForEdit.delete(eventId);
    }
  }

  // ----------------------------------------------------
}
