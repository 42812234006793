import type { MutableRefObject } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import type { IDeletedProvider, Provider, ProviderConstructor } from './registry';
import { deletedInstanceSymbol, registry } from './registry';
import { log } from './utils';

export function useDi(providerConstructor:ProviderConstructor, qualifier?:string):Provider
{
  const injectTargetRef:MutableRefObject<IDeletedProvider> = useRef<IDeletedProvider>({});

  useEffect(() =>
  {
    return () =>
    {
      injectTargetRef.current[deletedInstanceSymbol] = true;
    };
  }, []);

  return useMemo<Provider>(
    () =>
    {
      const typeName:string = providerConstructor.prototype.constructor.name;

      if( !qualifier )
        qualifier = typeName;

      log(`%cuseDi with type ${typeName} and qualifier ${qualifier}`, 'color: orange');

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return registry.getInstance(injectTargetRef.current, typeName, qualifier);
    },
    [providerConstructor, qualifier]
  );
}
