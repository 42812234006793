import { PageTitle } from '@flow/common/components/page/PageTitle';
import { routeValidator } from '@flow/common/components/routing/RouteValidator';
import { Roles } from '@flow/common/models/auth/Roles';
import bind from 'bind-decorator';
import { ReactNode } from 'react';
import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import type { IProtectedRouteProps } from '../common/components/routing/ProtectedRoute';
import { ProtectedRoute } from '../common/components/routing/ProtectedRoute';
import { PageNotFound } from './404';
import { type IRouteConfig } from './RoutesConfig';
import { RoutesConfig } from './RoutesConfig';

export class AppRoutes extends React.Component
{
  private _isProtected(routeConfig:IRouteConfig):boolean
  {
    return typeof (routeConfig.hasPermissionFn) !== 'undefined' || !routeConfig.roles?.includes(Roles.Anonymous);
  }

  @bind
  private _renderRoute(routeConfig:IRouteConfig):ReactNode
  {
    const Component = routeConfig.component;

    // if( !routeConfig.roles || routeConfig.roles.length === 0 )
    // {
    //   throw new Error(`There are no defined roles for route ${routeConfig.name}`);
    // }

    const isProtected:boolean = this._isProtected(routeConfig);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let Wrapper:any = isProtected ? ProtectedRoute : Fragment;
    const wrapperProps:IProtectedRouteProps = isProtected ? {
      routeName: routeConfig.name,
      hasAccessFn: routeConfig.hasPermissionFn,
      roles: routeConfig.roles
    } : {};

    if( routeConfig.validators )
      Wrapper = routeValidator(routeConfig.validators)(Wrapper);

    return (
      <Route
        key={routeConfig.path}
        index={routeConfig.isIndexRoute}
        path={routeConfig.path}
        element={(
          <Wrapper key={routeConfig.name} {...wrapperProps}>
            {
              routeConfig.title &&
              <PageTitle title={routeConfig.title} />
            }
            <Component />
          </Wrapper>
        )}
      >
        {routeConfig.routes && routeConfig.routes.map(this._renderRoute)}
        {routeConfig.routes && routeConfig.routes.length > 0 && <Route path="*" element={<PageNotFound />} />}
      </Route>
    );
  }

  public render():React.ReactNode
  {
    return (
      <>
        <Routes>
          {RoutesConfig.Routes.map(this._renderRoute)}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </>
    );
  }
}
