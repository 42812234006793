import type { ApolloQueryResult } from '@apollo/client';
import type { FetchResult } from '@apollo/client/link/core';
import { CommonController } from '@flow/common/CommonController';
import { IconNames } from '@flow/common/components/form/Icon';
import type { IInlineEditorField, IInlineEditorMultiSelectValue } from '@flow/common/components/form/InlineEditor';
import { AuthState } from '@flow/common/controllers/AuthState';
import type { HistoryItem, HistoryObject } from '@flow/common/controllers/ObjectHistoryController';
import { ObjectHistoryController } from '@flow/common/controllers/ObjectHistoryController';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { HasuraTableColumns, HasuraTables } from '@flow/common/models/HasuraTables';
import type { Maybe } from '@flow/common/models/Types';
import { ToastsController } from '@flow/common/toasts/ToastsController';
import type { DateStrOrNum } from '@flow/common/utils/DateUtil';
import { DateUtil } from '@flow/common/utils/DateUtil';
import { StringUtil } from '@flow/common/utils/StringUtil';
import type {
  CandidateUploadCvMutation,
  CandidateUploadCvMutationVariables
} from '@flow/data-access/lib/actions.generated';
import { CandidateUploadCvDocument } from '@flow/data-access/lib/actions.generated';
import type {
  AddAttachmentMutation,
  AddAttachmentMutationVariables,
  AddCandidateNoteMutation,
  AddCandidateNoteMutationVariables,
  AddScheduledEventMutation,
  AddScheduledEventMutationVariables,
  CancelCalendarEventMutation,
  CancelCalendarEventMutationVariables,
  CandidateQuery,
  CandidateQueryVariables,
  DeleteAttachmentMutation,
  DeleteAttachmentMutationVariables,
  GetStaffAndUserByEmailQuery,
  GetStaffAndUserByEmailQueryVariables,
  RenameAttachmentMutation,
  RenameAttachmentMutationVariables,
  ScheduleCalendarEventMutation,
  ScheduleCalendarEventMutationVariables,
  SubscribeCandidateSubscription,
  SubscribeCandidateSubscriptionVariables,
  UpdateCandidateMutation,
  UpdateCandidateMutationVariables,
  UpdateCandidatePositionGroupsMutation,
  UpdateCandidatePositionGroupsMutationVariables,
  UpdateNoteIsPinnedMutation,
  UpdateNoteIsPinnedMutationVariables,
  UpdateScheduledEventMutation,
  UpdateScheduledEventMutationVariables
} from '@flow/data-access/lib/candidate.generated';
import {
  AddAttachmentDocument,
  AddCandidateNoteDocument,
  AddScheduledEventDocument,
  CancelCalendarEventDocument,
  CandidateDocument,
  DeleteAttachmentDocument,
  GetStaffAndUserByEmailDocument,
  RenameAttachmentDocument,
  ScheduleCalendarEventDocument,
  SubscribeCandidateDocument,
  UpdateCandidateDocument,
  UpdateCandidatePositionGroupsDocument,
  UpdateNoteIsPinnedDocument,
  UpdateScheduledEventDocument
} from '@flow/data-access/lib/candidate.generated';
import type { GetAllStaffRolesQuery, GetAllStaffRolesQueryVariables } from '@flow/data-access/lib/staff.generated';
import { GetAllStaffRolesDocument } from '@flow/data-access/lib/staff.generated';
import type {
  Common_Attachment,
  Common_City,
  Common_Country,
  Common_Scheduled_Event,
  Common_User,
  Recruiting_Candidate,
  Recruiting_Candidate_Note,
  Recruiting_Candidate_Position_Group,
  Recruiting_Candidate_Scheduled_Event,
  Recruiting_Candidate_Set_Input,
  Recruiting_Position_Group,
  Staffing_Staff,
  Staffing_Staff_Role
} from '@flow/data-access/lib/types/graphql.generated';
import * as Types from '@flow/data-access/lib/types/graphql.generated';
import {
  Common_Attachment_Type_Enum,
  Recruiting_Candidate_Note_Type_Enum
} from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import { CandidateFlowState } from '@flow/modules/recruiting/candidates/CandidateFlowState';
import { environment } from 'apps/flow/src/environments/environment';
import bind from 'bind-decorator';
import { action, runInAction, toJS } from 'mobx';
import moment from 'moment';
import { FeedbackFormState } from '../feedbackForm/FeedbackFormState';
import { ScheduleController } from '../schedule/ScheduleController';
import { ScheduleEventForEdit, ScheduleState } from '../schedule/ScheduleState';
import type { InterviewFlowRules } from '../status/models/InterviewFlowRules';
import { CandidateAllocationController } from './CandidateAllocationController';
import { CandidateAllocationState } from './CandidateAllocationState';
import { CandidatePositionGroupsState } from './CandidatePositionGroupsState';
import { CandidatesController } from './CandidatesController';
import { CandidateSiteSubsiteState } from './CandidateSiteSubsiteState';
import { CandidatesState } from './CandidatesState';
import type { InlineEditorListType } from './CandidateState';
import { AdditionalFields, CandidateEventsCollapseListId, CandidateState } from './CandidateState';

// TODO: remove
const __DEBUG:boolean = false;

export enum CandidateFieldName
{
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',

  FIRST_NAME_I18N = 'FIRST_NAME_I18N',
  LAST_NAME_I18N = 'LAST_NAME_I18N',
  MIDDLE_NAME_I18N = 'MIDDLE_NAME_I18N',

  LINKED_IN = 'LINKED_IN',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  SITE_SUBSITE = 'SITE_SUBSITE',
  ASSIGNED_TO = 'ASSIGNED_TO',
  POSITION_GROUPS = 'POSITION_GROUPS',
  EXPERIENCE_IN_YEARS = 'EXPERIENCE_IN_YEARS',
  CURRENT_EMPLOYER = 'CURRENT_EMPLOYER',
  INSTANT_MESSENGER_ID = 'SKYPE_ID',
  SOURCE = 'SOURCE',
  SECONDARY_EMAIL = 'SECONDARY_EMAIL',

}

export const CandidateNoteTitles:Map<string, string> = new Map([
  [Recruiting_Candidate_Note_Type_Enum.General, 'General'],
  [Recruiting_Candidate_Note_Type_Enum.Salary, 'Salary'],
  [Recruiting_Candidate_Note_Type_Enum.Equipment, 'Equipment'],
  [Recruiting_Candidate_Note_Type_Enum.Desire, 'Candidate Desire']
]);

export const CandidateAttachmentTitles:Map<string, string> = new Map([
  [Common_Attachment_Type_Enum.Resume, 'Resume'],
  [Common_Attachment_Type_Enum.Offer, 'Offer'],
  [Common_Attachment_Type_Enum.CoverLetter, 'Cover letter'],
  [Common_Attachment_Type_Enum.Contract, 'Contract'],
  [Common_Attachment_Type_Enum.Other, 'Other']
]);

export interface FreeBusyData
{
  busy:Array<{ end:string; start:string; eTag:string | null; }> | null;
  errors:Array<{ domain:string; reason:string; eTag:string | null; }> | null;
}

export const FIELDS_MAP = {
  experience_in_years: {
    icon: IconNames.BRIEFCASE,
    text: ({ experience_in_years }:any):string =>
    {
      return experience_in_years?.toString() ?? '';
    },
    fields: ({ experience_in_years }:any):Array<IInlineEditorField> => [{
      name: CandidateFieldName.EXPERIENCE_IN_YEARS,
      value: experience_in_years?.toString() ?? '',
      label: AdditionalFields.EXPERIENCE_IN_YEARS,
      placeholder: AdditionalFields.EXPERIENCE_IN_YEARS,
      isAdditionalField: true
    }]
  },
  current_employer: {
    icon: IconNames.OFFICE,
    text: ({ current_employer }:any):string => current_employer ?? '',
    fields: ({ current_employer }:any):Array<IInlineEditorField> => [{
      name: CandidateFieldName.CURRENT_EMPLOYER,
      value: current_employer ?? '',
      label: AdditionalFields.CURRENT_EMPLOYER,
      placeholder: AdditionalFields.CURRENT_EMPLOYER,
      isAdditionalField: true
    }]
  },
  instant_messenger_id: {
    icon: IconNames.TEXT_HIGHLIGHT,
    text: ({ instant_messenger_id }:any):string => instant_messenger_id ?? '',
    fields: ({ instant_messenger_id }:any):Array<IInlineEditorField> => [{
      name: CandidateFieldName.INSTANT_MESSENGER_ID,
      value: instant_messenger_id ?? '',
      label: AdditionalFields.SKYPE_ID,
      placeholder: AdditionalFields.SKYPE_ID,
      isAdditionalField: true
    }]
  },
  source: {
    icon: IconNames.BOX,
    text: ({ source }:any):string =>
    {
      return source ?? '';
    },
    fields: ({ source }:any):Array<IInlineEditorField> =>
      [
        {
          name: CandidateFieldName.SOURCE,
          value: source ?? '',
          label: AdditionalFields.SOURCE,
          placeholder: AdditionalFields.SOURCE,
          isAdditionalField: true
        }
      ]
  },
  secondary_email: {
    icon: IconNames.ENVELOPE,
    text: ({ secondary_email }:any):string => secondary_email ?? '',
    fields: ({ secondary_email }:any):Array<IInlineEditorField> => [{
      name: CandidateFieldName.SECONDARY_EMAIL,
      value: secondary_email ?? '',
      label: AdditionalFields.SECONDARY_EMAIL,
      placeholder: AdditionalFields.SECONDARY_EMAIL,
      isAdditionalField: true
    }]
  }
};

@controller
export class CandidateController
{
  @di private _authState!:AuthState;
  @di private _candidateState!:CandidateState;
  @di private _scheduleState!:ScheduleState;
  @di private _scheduleController!:ScheduleController;
  @di private _candidatesState!:CandidatesState;
  @di private _candidateFlowState!:CandidateFlowState;
  @di private _candidateAllocationState!:CandidateAllocationState;
  @di private _candidateAllocationController!:CandidateAllocationController;
  @di private _candidatePositionGroupsState!:CandidatePositionGroupsState;
  @di private _candidateSiteSubsiteState!:CandidateSiteSubsiteState;

  @di private _commonController!:CommonController;
  @di private _candidatesController!:CandidatesController;
  @di private _historyController!:ObjectHistoryController;
  @di private _toastsController!:ToastsController;
  @di private _roleController!:RoleController;
  @di private _feedbackFormState!:FeedbackFormState;

  // ----------------------------------------------------

  @action.bound
  public async initCandidate():Promise<void>
  {
    const { viewCandidateId } = this._candidatesState;

    if( !viewCandidateId ) return;

    console.log('%c initCandidate viewCandidateId =', 'background:#0f0;color:#000;', viewCandidateId);

    this._candidateState.pageNotFound = false;

    const result:ApolloQueryResult<CandidateQuery> =
      await this._commonController.query<CandidateQuery,
        CandidateQueryVariables>({
        query: CandidateDocument,
        variables: {
          candidateId: viewCandidateId
        }
      });

    if( !result.data.recruiting_candidate_by_pk )
    {
      // throw new Error(`Candidate with id: ${viewCandidateId} was not found!`);
      runInAction(() => this._candidateState.pageNotFound = true);
      return;
    }

    const subscription =
      this._commonController.subscribe<SubscribeCandidateSubscription,
        SubscribeCandidateSubscriptionVariables>({
        query: SubscribeCandidateDocument,
        variables: {
          candidateId: viewCandidateId
        }
      });

    if( !subscription )
      return;

    this._candidateState.subscriptions.push(
      subscription.subscribe((result) =>
      {
        runInAction(() =>
        {
          if( result.data )
            this._setCandidateStateFromSubscription(result.data as CandidateQuery);

          this._fetchCandidateHistory(false);
        }),
          (error:Error):void => console.debug('subscription error!', error),
          ():void => console.debug('subscription complete!');
      })
    );

    console.log('%c initCandidate result =', 'background:#f60;color:#000;', result);

    await this._candidateAllocationController.initCandidateAllocationPositions();

    runInAction(() =>
    {
      const {
        recruiting_candidate_by_pk,
        recruiting_position_group,
        common_user,
        all_users,
        common_country,
        common_city,
        // recruiting_allocation_position
        recruiting_candidate_note

        // -> candidateAllocationController.initCandidateAllocationPositions
        // recruiting_candidate_allocation,
        // recruiting_position
      } = result.data;

      this._candidateState.candidate = recruiting_candidate_by_pk as Recruiting_Candidate;
      this._candidateState.candidateNotes = recruiting_candidate_note as Array<Recruiting_Candidate_Note>;
      this._candidateState.assignedToUsers = common_user as Array<Common_User>;
      this._scheduleState.allUsers = all_users as Array<Common_User>;

      this._candidateSiteSubsiteState.countries = common_country as Array<Common_Country>;
      this._candidateSiteSubsiteState.cities = common_city as Array<Common_City>;

      this._candidatePositionGroupsState.positionGroups = recruiting_position_group as Array<Recruiting_Position_Group>;

      // -> candidateAllocationController.initCandidateAllocationPositions
      // this._candidateAllocationState.proposedAllocations = recruiting_candidate_allocation as Array<Recruiting_Candidate_Allocation>;
      // this._candidateAllocationState.proposedPositions = recruiting_allocation_position as Array<Recruiting_Allocation_Position>;

      // this._candidateAllocationState.positions = recruiting_position as Array<Recruiting_Position_Ex>;

      // recruiting_position.forEach(position =>
      // {
      //   position.customer_team_slots.forEach(slot =>
      //   {
      //     if( slot.next_candidate_id === this._candidateState.candidate?.id )
      //       this._candidateAllocationState.currentFinalSlots.push(slot as Staffing_Customer_Team_Slot);
      //   });
      // });

      this._candidateState.isCandidateLoaded = true;
      this._candidateState.isCandidateAllocationPositionsLoaded = true;
      // this._candidateAllocationState.isPositionsLoaded = true;

      // this._candidatePositionsController.initPositions();

      console.log('%c --- initCandidate: isCandidateLoaded =', 'background:#0f0;color:#000;', true);
    });

    // if( this._roleController.hasPermission(FlowPermissions.CreateScheduledEvent) )
    // {
    //   this._candidateState.disposers.push(reaction(
    //     ():Partial<Common_Scheduled_Event> | undefined =>
    //     {
    //       // const { draftEvent, eventForReschedule } = this._scheduleState;
    //       const { draftEvent } = this._scheduleState;
    //
    //       if( !draftEvent ) return;
    //
    //       // const { summary, description, start_time, end_time, attendees_list, is_scheduled } = currentScheduledEvent;
    //       // return { ...draftEvent, ...eventForReschedule };
    //       return { ...draftEvent };
    //     },
    //     (event?:Partial<Common_Scheduled_Event>) =>
    //     {
    //       if( event )
    //       {
    //         if( !event.is_scheduled )
    //         {
    //           this._updateDraftEvent();
    //           // runInAction(() => this._scheduleState.eventForReschedule = null);
    //         }
    //
    //         this._scheduleController.fetchFreeBusyInfo();
    //       }
    //     }
    //   ));
    // }

    console.log('%c --> _initDraftEvent =', 'background:#f0f;color:#000;', 'from initCandidate');
    this.initDraftEvent();
  }

  // ---------------------------------------------------------

  private _setCandidateStateFromSubscription(data:CandidateQuery):void
  {
    if( !data ) return;

    runInAction(() =>
    {
      const {
        recruiting_candidate_by_pk
      } = data;

      const oldInterviewFlowStageId = this._candidateState.candidate?.interview_flow_stage_id;

      this._candidateState.candidate = recruiting_candidate_by_pk as Recruiting_Candidate;

      const { candidate, deletingAttachmentId } = this._candidateState;

      if( deletingAttachmentId && !candidate.candidate_attachments.some(item => item.attachment.id == deletingAttachmentId) )
      {
        this._candidateState.deletingAttachmentId = null;
      }

      // --------------------------

      const newDraftEvent:Maybe<Common_Scheduled_Event> = this._candidateState.candidate.scheduled_calls
        .filter((call:Recruiting_Candidate_Scheduled_Event) =>
        {
          return !call.scheduled_call.deleted_at &&
            call.interview_flow_stage.id == candidate.interview_flow_stage_id;
        })
        .find((candidateScheduledCall:Recruiting_Candidate_Scheduled_Event) =>
        {
          const call:Common_Scheduled_Event = candidateScheduledCall.scheduled_call;

          return !call.is_scheduled && !call.is_done && !call.is_feedback_done;
        })?.scheduled_call;

      if( !newDraftEvent )
      {
        console.log('%c --> _initDraftEvent =', 'background:#f0f;color:#000;', '!newDraftEvent');
        this.initDraftEvent();
      }
      else //if( newDraftEvent )
      {
        const { draftEvent } = this._scheduleState;

        const shouldUpdate = (!newDraftEvent && draftEvent)
          || newDraftEvent.summary !== draftEvent?.summary
          || newDraftEvent.description !== draftEvent?.description
          || newDraftEvent.start_time !== draftEvent?.start_time
          || newDraftEvent.end_time !== draftEvent?.end_time
          || newDraftEvent.attendees_list !== draftEvent?.attendees_list
          || newDraftEvent.is_scheduled !== draftEvent?.is_scheduled
          || newDraftEvent.is_done !== draftEvent?.is_done
          || oldInterviewFlowStageId !== recruiting_candidate_by_pk?.interview_flow_stage_id;

        if( shouldUpdate )
        {
          console.log('%c --> _initDraftEvent =', 'background:#f0f;color:#000;', 'shouldUpdate');
          this.initDraftEvent();
        }
      }

      // --------------------------

      const { feedbackFormEvent } = this._feedbackFormState;

      if( feedbackFormEvent )
      {
        const updatedFeedbackFromEvent = candidate.scheduled_calls.find(call => call.scheduled_call.id == feedbackFormEvent.id);

        if( updatedFeedbackFromEvent )
          runInAction(() =>
          {
            this._feedbackFormState.feedbackFormEvent = updatedFeedbackFromEvent.scheduled_call;
            runInAction(() => this._feedbackFormState.isCompleteFeedbackLoading = false);
          });
      }

      // --------------------------

      // if( this._scheduleState.draftEvent?.is_scheduled && !this._scheduleState.draftEvent.is_done )
      // {
      //   this._scheduleState.eventForReschedule = {};
      //   Object.assign(this._scheduleState.eventForReschedule, this._scheduleState.draftEvent);
      // }
      // else
      // {
      //   this._scheduleState.eventForReschedule = null;
      // }
    });
  }

  // ----------------------------------------------------

  @bind
  private async _fetchCandidateHistory(isIncremental:boolean = false):Promise<void>
  {
    __DEBUG && console.log('%c _fetchCandidateHistory =', 'background:#0f0;color:#000;');
    __DEBUG && console.log('%c --> isIncremental =', 'background:#080;color:#000;', isIncremental);

    const id = this._candidateState.candidate?.id;

    if( !id ) return;

    if( !isIncremental )
      runInAction(() => this._candidateState.isHistoryLoaded = false);

    const historyObjects:Array<HistoryObject> = [];

    historyObjects.push({
      table: HasuraTables.RecruitingCandidate,
      idFieldName: HasuraTableColumns.ID,
      ids: [id]
    });

    const noteIds = this._candidateState.candidate?.candidate_notes.map(note => note.id);
    const scheduledEventIds = this._candidateState.candidate?.scheduled_calls.map(call => call.scheduled_call.id);

    this._appendNoteHistoryObjects(historyObjects, noteIds ?? []);
    this._appendScheduledEventHistoryObjects(historyObjects, scheduledEventIds ?? []);

    if( typeof (this._historyController.getObjectsHistory) !== 'function' ) return;

    const candidateHistory = this._candidateState.candidateHistory;

    __DEBUG && console.log('%c --> historyObjects =', 'background:#080;color:#000;', historyObjects);

    const moreHistory = await this._historyController.getObjectsHistory(historyObjects, isIncremental ? candidateHistory.length : 0);

    __DEBUG && console.log('%c --> moreHistory =', 'background:#080;color:#000;', moreHistory);

    runInAction(() => this._candidateState.hasMoreHistory = moreHistory.length > 0);

    const newCandidateHistory = [
      ...candidateHistory,
      ...moreHistory
    ];

    const uniqueCandidateHistory:Array<HistoryItem> = [];

    newCandidateHistory.forEach(item =>
    {
      if( uniqueCandidateHistory.findIndex(uniqueItem => uniqueItem.event_id === item.event_id) < 0 )
        uniqueCandidateHistory.push(item);
    });

    uniqueCandidateHistory.sort((a, b) => DateUtil.compareDates(a.action_tstamp_clk as DateStrOrNum, b.action_tstamp_clk as DateStrOrNum));

    __DEBUG && console.log('%c --> uniqueCandidateHistory =', 'background:#080;color:#000;', uniqueCandidateHistory);

    runInAction(() =>
    {
      this._candidateState.candidateHistory = uniqueCandidateHistory;
      this._candidateState.isHistoryLoaded = true;
    });
  }

  // ----------------------------------------------------

  private _appendNoteHistoryObjects(historyObjects:Array<HistoryObject>, noteIds:Array<number>):void
  {
    if( noteIds.length == 0 )
      return;

    historyObjects.push({
      table: HasuraTables.RecruitingCandidateNote,
      idFieldName: HasuraTableColumns.ID,
      where: {
        action: { _neq: 'D' }
      },
      // keys to track for history. not included keys are ignored
      hasChangedKeys: ['content', 'type'],
      ids: noteIds
    });
  }

  // ----------------------------------------------------

  private _appendScheduledEventHistoryObjects(historyObjects:Array<HistoryObject>, scheduledEventIds:Array<number>):void
  {
    if( scheduledEventIds.length == 0 )
      return;

    historyObjects.push({
      table: HasuraTables.CommonScheduledEvent,
      idFieldName: HasuraTableColumns.ID,
      where: {
        _and: [
          {
            action: { _neq: 'D' }
          },
          {
            _or: [
              { changed_fields: { _contains: { is_scheduled: true } } },
              { row_data: { _contains: { is_scheduled: true } } }
            ]
          }
        ]
      },
      // keys to track for history. not included keys are ignored
      hasChangedKeys: ['summary', 'description', 'start_time', 'end_time', 'attendees_list', 'is_scheduled', 'is_done', 'is_feedback_done', 'deleted_at'],
      ids: scheduledEventIds
    });
  }

  // ----------------------------------------------------

  @bind
  public async fetchNextCandidateHistory():Promise<void>
  {
    return this._fetchCandidateHistory(true);
  }

  // ----------------------------------------------------

  @action.bound
  public async onChangeCandidateField(newValues:Record<string, IInlineEditorField>):Promise<void>
  {
    const { candidate } = this._candidateState;
    console.log('%c onChangeCandidateField newValue =', 'background:#0f0;color:#000;', newValues);

    if( !candidate ) return;

    const newValuesKeys:Array<string> = Object.keys(newValues);

    if( !newValuesKeys.length ) return; // TODO ERROR ?

    const firstNameInNewValues:string = Object.keys(newValues)[0];

    const candidateFields:Recruiting_Candidate_Set_Input = {};

    const getStrValue = (name:string):string => String(newValues[name]?.value).toString().trim();
    const getNumValue = (name:string):number | null => (newValues[name]?.value as number) || null;
    const getArrayValue = (name:string):Array<number> => (newValues[name]?.value as Array<number>) || [];

    let countryId:string = '';
    let cityId:string = '';

    switch( firstNameInNewValues )
    {
      case CandidateFieldName.FIRST_NAME:
      case CandidateFieldName.LAST_NAME:
        candidateFields.first_name = getStrValue(CandidateFieldName.FIRST_NAME);
        candidateFields.last_name = getStrValue(CandidateFieldName.LAST_NAME);
        break;

      case CandidateFieldName.FIRST_NAME_I18N:
      case CandidateFieldName.LAST_NAME_I18N:
      case CandidateFieldName.MIDDLE_NAME_I18N:
        candidateFields.first_name_i18n = getStrValue(CandidateFieldName.FIRST_NAME_I18N);
        candidateFields.last_name_i18n = getStrValue(CandidateFieldName.LAST_NAME_I18N);
        candidateFields.middle_name_i18n = getStrValue(CandidateFieldName.MIDDLE_NAME_I18N);
        break;

      case CandidateFieldName.LINKED_IN:
        candidateFields.linkedin_profile_url = getStrValue(CandidateFieldName.LINKED_IN);
        break;

      case CandidateFieldName.PHONE:
        candidateFields.phone = getStrValue(CandidateFieldName.PHONE);
        break;

      case CandidateFieldName.EMAIL:
        candidateFields.email = getStrValue(CandidateFieldName.EMAIL);
        if( candidate.email && candidateFields.email )
        {
          const { draftEvent, allUsers } = this._scheduleState;
          if( draftEvent && draftEvent.attendees_list )
          {
            draftEvent.attendees_list = draftEvent.attendees_list.replace(candidate.email, candidateFields.email);
            this._scheduleState.allUsers = allUsers.filter(user => user.email != candidate.email);
            this._addCandidateToSchedulingUsers(candidateFields.email);
          }
        }
        break;

      case CandidateFieldName.SITE_SUBSITE:
        [countryId, cityId] = getStrValue(CandidateFieldName.SITE_SUBSITE).split(':');
        candidateFields.country_id = parseInt(countryId) || null;
        candidateFields.city_id = parseInt(cityId) || null;
        break;

      case CandidateFieldName.ASSIGNED_TO:
        candidateFields.assigned_user_id = getNumValue(CandidateFieldName.ASSIGNED_TO);
        break;

      case CandidateFieldName.POSITION_GROUPS:
      {
        console.log('%c onChangeCandidateField newValue 2 =', 'background:#0f0;color:#000;', newValues);

        await this._updateCandidatePositionGroups(candidate.id, getArrayValue(CandidateFieldName.POSITION_GROUPS));

        return;
      }
      case CandidateFieldName.EXPERIENCE_IN_YEARS:
      {
        candidateFields.experience_in_years = parseInt(getStrValue(CandidateFieldName.EXPERIENCE_IN_YEARS));
        break;
      }
      case CandidateFieldName.CURRENT_EMPLOYER:
      {
        candidateFields.current_employer = getStrValue(CandidateFieldName.CURRENT_EMPLOYER);
        break;
      }
      case CandidateFieldName.INSTANT_MESSENGER_ID:
      {
        candidateFields.instant_messenger_id = getStrValue(CandidateFieldName.INSTANT_MESSENGER_ID);
        break;
      }
      case CandidateFieldName.SOURCE:
      {
        candidateFields.source = getStrValue(CandidateFieldName.SOURCE);
        break;
      }
      case CandidateFieldName.SECONDARY_EMAIL:
      {
        candidateFields.secondary_email = getStrValue(CandidateFieldName.SECONDARY_EMAIL);
      }
    }

    const variables:UpdateCandidateMutationVariables = {
      id: candidate.id,
      candidateFields
    };

    console.log('%c !!! variables =', 'background:#ff0;color:#000;', variables);
    return this._updateCandidate(variables);
  }

  @action.bound
  private async _updateCandidate(variables:UpdateCandidateMutationVariables):Promise<void>
  {
    const result:FetchResult<UpdateCandidateMutation> =
      await this._commonController.mutate<UpdateCandidateMutation,
        UpdateCandidateMutationVariables>({
        mutation: UpdateCandidateDocument,
        variables
      });

    console.log('%c result =', 'background:#f60;color:#000;', result);

    runInAction(async () =>
    {
      if( result && result.data )
      {
        if( this._candidateState.candidate !== null && variables.candidateFields.linkedin_profile_url )
        {
          const newLinkedInUrl = variables.candidateFields.linkedin_profile_url;

          if( this._candidateState.candidate.linkedin_profile_url !== newLinkedInUrl )
          {
            const avatarUrl = await this._candidatesController.fetchCandidateProfilePicture(variables.id, newLinkedInUrl);
            this._candidateState.candidate.avatar_url = avatarUrl;
          }
        }

        this._candidateState.candidate = result.data.update_recruiting_candidate_by_pk as Recruiting_Candidate;
      }
    });
  }

  @action.bound
  private async _updateCandidatePositionGroups(candidateId:number, positionGroups:Array<number>):Promise<void>
  {
    const variables:UpdateCandidatePositionGroupsMutationVariables = {
      candidate_id: candidateId,
      positionGroups: positionGroups.map((pgId:number) =>
      {
        return {
          candidate_id: candidateId,
          position_group_id: pgId
        };
      })
    };

    const result:FetchResult<UpdateCandidatePositionGroupsMutation> =
      await this._commonController.mutate<UpdateCandidatePositionGroupsMutation,
        UpdateCandidatePositionGroupsMutationVariables>({
        mutation: UpdateCandidatePositionGroupsDocument,
        variables
      });

    console.log('%c result =', 'background:#f60;color:#000;', result);

    runInAction(() =>
    {
      if( result && result.data )
      {
        const pgIds:Array<Recruiting_Candidate_Position_Group> = result.data.insert_recruiting_candidate_position_group?.returning as Array<Recruiting_Candidate_Position_Group>;

        if( this._candidateState.candidate )
        {
          this._candidateState.candidate.candidate_position_groups = pgIds;
          this._candidateState.isCandidateAllocationPositionsLoaded = false;

          setTimeout(() =>
          {
            this._candidateAllocationController.initCandidateAllocationPositions();
          }, 10);
        }
      }
    });
  }

  // ----------------------------------------------------

  @bind
  public async onCVUploaded(files:FileList):Promise<boolean>
  {
    const candidate = this._candidateState.candidate;

    function getBase64(file:Blob):Promise<string | null>
    {
      return new Promise((resolve, reject) =>
      {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = ():void | null | string => reader.result && resolve(reader.result?.toString());
        reader.onerror = (error:ProgressEvent<FileReader>):void => reject(error);
      });
    }

    if( !candidate )
      return false;

    if( files.length > 0 )
    {
      const cvFile = files[0];
      const fileContent = await getBase64(cvFile);

      if( !fileContent )
        return false;

      runInAction(() => this._candidateState.cvUploadInProgress = true);

      const uploadResult = await this._commonController.mutate<CandidateUploadCvMutation, CandidateUploadCvMutationVariables>({
        mutation: CandidateUploadCvDocument,
        variables: {
          candidateId: candidate.id,
          mimeType: cvFile.type,
          fileContent: fileContent.split(',')[1]  // trims out "data:[<mediatype>][;base64]," part
        }
      }).catch((error:Error) =>
      {
        runInAction(() => this._candidateState.cvUploadInProgress = false);
        console.error(error);
      });

      runInAction(() => this._candidateState.cvUploadInProgress = false);

      if( !uploadResult || !uploadResult.data?.action_candidate_upload_cv?.cv_google_doc_ref )
      {
        if( uploadResult && uploadResult.errors?.length )
        {
          console.error(uploadResult.errors[0]);
        }
        return false;
      }

      runInAction(() => candidate.cv_google_doc_ref = uploadResult.data?.action_candidate_upload_cv?.cv_google_doc_ref);

      return true;
    }

    return false;
  }

  // ----------------------------------------------------

  public async addNote(type:Recruiting_Candidate_Note_Type_Enum, content:string):Promise<void>
  {
    const userId = this._authState.user?.id;
    const candidateId = this._candidateState.candidate?.id;

    await this._commonController.mutate<AddCandidateNoteMutation, AddCandidateNoteMutationVariables>({
      mutation: AddCandidateNoteDocument,
      variables: {
        object: {
          user_id: userId,
          type: type,
          content: content,
          candidate_id: candidateId
        }
      }
    });

    runInAction(() => this._candidateState.isAddingNote = false);
  }

  // ----------------------------------------------------

  public async initDraftEvent():Promise<void>
  {
    if( this._candidateState.isInitDraftEvent ) return;

    console.groupCollapsed('initDraftEvent');

    runInAction(() => this._candidateState.isInitDraftEvent = true);

    const draftEvent:Maybe<Common_Scheduled_Event> = await this._initDraftEvent();
    console.log('%c initDraftEvent =', 'background:#0f0;color:#000;', toJS(draftEvent));

    runInAction(() =>
    {
      this._scheduleState.draftEvent = draftEvent;

      if( draftEvent )
      {
        this._candidateState.isDraftEventOpen = draftEvent.is_draft;

        if( draftEvent.is_draft )
        {
          this._scheduleController.addEventForEdit(draftEvent);
        }
      }
    });

    runInAction(() => this._candidateState.isInitDraftEvent = false);

    console.groupEnd();
  }

  // ----------------------------------------------------

  private async _initDraftEvent():Promise<Common_Scheduled_Event | null>
  {
    console.log('%c -------------------------- =', 'background:#f0f;color:#000;');
    console.log('%c _initDraftEvent =', 'background:#f0f;color:#000;');
    const { candidate } = this._candidateState;

    if( !candidate ) return null;

    const { scheduled_calls, interview_flow_stage_id } = candidate;

    const currentStageCalls:Array<Recruiting_Candidate_Scheduled_Event> = scheduled_calls
      .filter((call:Recruiting_Candidate_Scheduled_Event) =>
      {
        const isOnStage:boolean = call.interview_flow_stage?.id == interview_flow_stage_id;

        console.log('%c --> call =', `background:${isOnStage ? '#080' : '#faa'} ;color:#000;`, call.scheduled_call.id, toJS(call));

        return isOnStage;
      });

    this._addCandidateToSchedulingUsers();

    const draftEvent:Maybe<Recruiting_Candidate_Scheduled_Event> = currentStageCalls
      .find((candidateScheduledCall:Recruiting_Candidate_Scheduled_Event) =>
      {
        const call:Common_Scheduled_Event = candidateScheduledCall.scheduled_call;

        return !call.is_scheduled && !call.is_done && !call.is_feedback_done;
      });

    console.log('%c --> draftEvent =', 'background:#f0f;color:#fff;', toJS(draftEvent));

    runInAction(() =>
    {
      this._scheduleState.draftEvent = draftEvent?.scheduled_call || null;
      console.log('%c --> this._scheduleState.draftEvent 1 =', 'background:#f0f;color:#fff;', toJS(this._scheduleState.draftEvent));
    });

    console.log('%c --> this._scheduleState.draftEvent 2 =', 'background:#f0f;color:#fff;', toJS(this._scheduleState.draftEvent));

    return draftEvent?.scheduled_call || await this._createScheduledEvent();
  }

  // ----------------------------------------------------

  private _initAttendeesList():Array<string>
  {
    const { user } = this._authState;
    const { candidate } = this._candidateState;

    const attendeesList:Array<string> = [];

    if( user?.email && user.email.length > 0 )
    {
      attendeesList.push(user.email);
    }

    if( candidate?.email && candidate.email.length > 0 )
    {
      attendeesList.push(candidate.email);
    }

    if( !attendeesList.includes(environment.recruiting.careersEmail) )
    {
      attendeesList.push(environment.recruiting.careersEmail);
    }

    return attendeesList;
  }

  // ----------------------------------------------------

  private async _createScheduledEvent():Promise<Common_Scheduled_Event | null>
  {
    console.log('%c --------------------- =', 'background:#f0f;color:#000;');
    console.log('%c _createScheduledEvent 1 =', 'background:#f0f;color:#000;');
    const { user } = this._authState;
    const { candidate } = this._candidateState;

    if( !user?.id || !candidate || !candidate.email )
      return null;

    console.log('%c _createScheduledEvent 2 =', 'background:#f0f;color:#000;');

    const startDate = moment(DateUtil.roundToNearest15());
    const attendeesList = this._initAttendeesList().join(',');

    const rules = candidate.interview_flow_stage?.interview_flow.rules as InterviewFlowRules;
    const rulesByStage = rules?.filter(rule => rule.id == candidate.interview_flow_stage_id);
    const rule = rulesByStage?.length > 0 ? rulesByStage[0] : null;

    const callTitle = StringUtil.format(rule?.scheduledEvent?.titleTemplate || '!!! No title template for current stage', {
      candidate_name: `${candidate.first_name} ${candidate.last_name}`,
      recruiter_name: `${user.firstName} ${user.lastName}`
    });

    const callDescription = StringUtil.format(rule?.scheduledEvent?.descriptionTemplate || '!!! No description template for current stage', {
      candidate_name: `${candidate.first_name} ${candidate.last_name}`,
      recruiter_name: `${user.firstName} ${user.lastName}`
    });

    const tmpEvent:Partial<Common_Scheduled_Event> = {
      user_id: Number(user.id),
      start_time: startDate.toDate(),
      end_time: moment(startDate).add(1, 'hours').toDate(),
      summary: callTitle,
      description: callDescription,
      attendees_list: attendeesList,
      is_draft: false,
      is_scheduled: false,
      is_done: false,
      is_feedback_done: false
    };

    console.log('%c --> tmpEvent =', 'background:#f0f;color:#fff;', toJS(tmpEvent));

    const newEvent:Common_Scheduled_Event | null = await this._addDraftEventTemplate(tmpEvent);

    runInAction(() =>
    {
      this._scheduleState.draftEvent = newEvent;
    });

    return this._scheduleState.draftEvent as Common_Scheduled_Event;
  }

  // ----------------------------------------------------

  private async _addDraftEventTemplate(scheduledEvent:Partial<Common_Scheduled_Event> | null):Promise<Common_Scheduled_Event | null>
  {
    console.log('%c ----------------------- =', 'background:#f0f;color:#000;');
    console.log('%c _addDraftEventTemplate 1 =', 'background:#f0f;color:#000;', toJS(scheduledEvent));
    const { candidate } = this._candidateState;
    const { isCandidateOnFlow } = this._candidateFlowState;

    // if( !candidate || !scheduledEvent || !candidate.interview_flow_stage_id )
    if( !candidate || !scheduledEvent || !isCandidateOnFlow )
      return null;

    console.log('%c _addDraftEventTemplate 2 =', 'background:#f0f;color:#fff;');

    if( !environment.production )
    {
      const attendeeEmails = scheduledEvent.attendees_list?.split(',') || [];

      scheduledEvent.attendees_list = attendeeEmails.join(',');
    }

    const result:FetchResult<AddScheduledEventMutation> =
      await this._commonController.mutate<AddScheduledEventMutation,
        AddScheduledEventMutationVariables>({
        mutation: AddScheduledEventDocument,
        variables: {
          scheduledEvent: {
            ...scheduledEvent,
            user: undefined,  // no need to set user, as we have user_id
            candidate_scheduled_events: {
              data: [
                {
                  candidate_id: candidate.id,
                  interview_flow_stage_id: candidate.interview_flow_stage_id
                }
              ]
            }
          }
        }
      });

    console.log('%c --> result              =', 'background:#f0f;color:#fff;', toJS(result));
    console.log('%c ----------------------- =', 'background:#f0f;color:#fff;');

    if( result.errors )
    {
      console.log('%c ERROR _addDraftEventTemplate =', 'background:#f00;color:#ff0;', toJS(result.errors));
      throw new Error(result.errors[0].message);
    }

    return result.data?.insert_common_scheduled_event_one as Common_Scheduled_Event;
  }

  // ----------------------------------------------------

  public async updateDraftEvent():Promise<void>
  {
    const { draftEvent } = this._scheduleState;
    const { candidate } = this._candidateState;

    const { user } = this._authState;

    console.log('%c ----------------------- =', 'background:#f0f;color:#000;');
    console.log('%c updateDraftEvent (original) draftEvent =', 'background:#f0f;color:#000;', toJS(draftEvent));

    if( !draftEvent?.id || !user?.id || !user?.email || !candidate?.email ) return;

    const { eventsForEdit } = this._scheduleState;
    const eventForEdit:Maybe<Partial<Common_Scheduled_Event>> = eventsForEdit.get(draftEvent.id)?.event;

    console.log('%c updateDraftEvent (new 1) eventForEdit =', 'background:#f0f;color:#000;', toJS(eventForEdit));

    if( !eventForEdit || !eventForEdit.id ) return;

    console.log('%c _updateDraftEvent 3 =', 'background:#f0f;color:#fff;');

    const attendeesArray = eventForEdit.attendees_list?.split(',') || [];

    // FLOW-373 -> maybe reverts in future
    // if( !attendeesArray.includes(candidate?.email) )
    // {
    //   runInAction(() => eventForEdit.attendees_list = `${candidate?.email},${eventForEdit.attendees_list}`);
    // }

    if( !attendeesArray.includes(environment.recruiting.careersEmail) )
    {
      runInAction(() =>
      {
        eventForEdit.attendees_list = `${environment.recruiting.careersEmail}${eventForEdit.attendees_list ? ',' : ''}${eventForEdit.attendees_list}`;
      });
    }

    if( !attendeesArray.includes(user.email) )
    {
      runInAction(() =>
      {
        eventForEdit.attendees_list = `${user.email}${eventForEdit.attendees_list ? ',' : ''}${eventForEdit.attendees_list}`;
      });
    }

    eventForEdit.user_id = user.id;

    console.log('%c updateDraftEvent (new 2) eventForEdit =', 'background:#f0f;color:#000;', toJS(eventForEdit));

    const result:FetchResult<UpdateScheduledEventMutation> =
      await this._commonController.mutate<UpdateScheduledEventMutation,
        UpdateScheduledEventMutationVariables>({
        mutation: UpdateScheduledEventDocument,
        variables: {
          scheduledEventId: eventForEdit.id,
          scheduledEvent: {
            start_time: eventForEdit.start_time,
            end_time: eventForEdit.end_time,
            attendees_list: eventForEdit.attendees_list,
            summary: eventForEdit.summary,
            description: eventForEdit.description,
            user_id: user.id
          }
        }
      });

    console.log('%c --> result              =', 'background:#f0f;color:#fff;', toJS(result));
    console.log('%c ----------------------- =', 'background:#f0f;color:#fff;');

    if( result.errors )
    {
      console.log('%c ERROR _updateDraftEvent =', 'background:#f00;color:#ff0;', toJS(result.errors));
      throw new Error(result.errors[0].message);
    }
  }

  // ----------------------------------------------------

  private async _updateScheduledEventFromRescheduled(scheduledEventId:number):Promise<void>
  {
    console.log('%c _updateScheduledEventFromRescheduled =', 'background:#f0f;color:#000;', toJS(scheduledEventId));

    const eventForEdit:Maybe<ScheduleEventForEdit> = this._scheduleState.eventsForEdit.get(scheduledEventId);

    if( !eventForEdit )
      throw new Error('Failed to update a scheduled event!');

    const { event } = eventForEdit;

    if( !event || !event.id )
      throw new Error('Failed to update a scheduled event!');

    const result:FetchResult<UpdateScheduledEventMutation> =
      await this._commonController.mutate<UpdateScheduledEventMutation,
        UpdateScheduledEventMutationVariables>({
        mutation: UpdateScheduledEventDocument,
        variables: {
          scheduledEventId: event.id,
          scheduledEvent: {
            start_time: event.start_time,
            end_time: event.end_time,
            summary: event.summary,
            description: event.description,

            // remove empty emails = "qwe,,,,asd,,,," -> "qwe,asd"
            attendees_list: event.attendees_list?.replace(/[,]{2,}/g, ',').replace(/,$/, '')
          }
        }
      });

    console.log('%c --> result              =', 'background:#f0f;color:#fff;', toJS(result));
    console.log('%c ----------------------- =', 'background:#f0f;color:#fff;');

    if( result.errors )
    {
      console.log('%c ERROR _updateScheduledEventFromRescheduled =', 'background:#f00;color:#ff0;', toJS(result.errors));
      throw new Error(result.errors[0].message);
    }
  }

  // ----------------------------------------------------

  public async scheduleEvent(scheduledEventId:Maybe<number>, isReschedule:boolean):Promise<void>
  {
    if( !scheduledEventId )
      throw new Error('Failed to create a scheduled event!');

    console.log('%c scheduleEvent =', 'background:#f0f;color:#000;', toJS(scheduledEventId), isReschedule);

    runInAction(() => this._candidateState.isSchedulingEvent = true);

    if( isReschedule )
    {
      await this._updateScheduledEventFromRescheduled(scheduledEventId);
    }

    const result:FetchResult<ScheduleCalendarEventMutation> =
      await this._commonController.mutate<ScheduleCalendarEventMutation,
        ScheduleCalendarEventMutationVariables>({
        mutation: ScheduleCalendarEventDocument,
        variables: {
          scheduledEventId
        }
      });

    console.log('%c --> result              =', 'background:#f0f;color:#fff;', toJS(result));
    console.log('%c ----------------------- =', 'background:#f0f;color:#fff;');

    if( result.errors )
    {
      console.log('%c ERROR scheduleEvent =', 'background:#f00;color:#ff0;', toJS(result.errors));
      throw new Error(result.errors[0].message);
    }

    this._scheduleController.removeEventForEdit(scheduledEventId);

    runInAction(() => this._candidateState.isSchedulingEvent = false);

    this._toastsController.showSuccess(`The call is ${isReschedule ? 'rescheduled' : 'scheduled'}`);

    // back from Manage call details
    this._commonController.navigate(this._scheduleState.btnBackUrl);
  }

  // ----------------------------------------------------

  public async updateScheduledEventIsDoneStatus(eventId:number, isDone:boolean):Promise<void>
  {
    if( !this._roleController.hasPermission(FlowPermissions.CompleteEvent) ) return;

    const updatedFields:Types.InputMaybe<Types.Common_Scheduled_Event_Set_Input> = {
      is_done: isDone
    };

    if( !isDone ) updatedFields.is_feedback_done = false;

    await this._commonController.mutate<UpdateScheduledEventMutation, UpdateScheduledEventMutationVariables>({
      mutation: UpdateScheduledEventDocument,
      variables: {
        scheduledEventId: eventId,
        scheduledEvent: updatedFields
      }
    });
  }

  // ----------------------------------------------------

  @bind
  public async cancelScheduledEvent(eventId:number):Promise<void>
  {
    console.log('%c cancelCurrentScheduledEvent =', 'background:#0f0;color:#000;', toJS(eventId));

    const result:FetchResult<CancelCalendarEventMutation> =
      await this._commonController.mutate<CancelCalendarEventMutation,
        CancelCalendarEventMutationVariables>({
        mutation: CancelCalendarEventDocument,
        variables: {
          scheduledEventId: eventId
        }
      });

    console.log('%c --> result =', 'background:#f60;color:#000;', eventId, result);

    if( result.errors )
    {
      console.log('%c ERROR CancelAllCandidateCalendarEvent =', 'background:#f00;color:#ff0;', toJS(result.errors));
      throw new Error(result.errors[0].message);
    }

    this._toastsController.showSuccess('The call is cancelled');
  }

  // ----------------------------------------------------

  @bind
  public _addCandidateToSchedulingUsers(newCandidateEmail?:string):void
  {
    const { candidate } = this._candidateState;
    const { allUsers } = this._scheduleState;

    // TODO: catch a bad state, where the candidate's email was removed
    const candidateEmail = newCandidateEmail || candidate?.email || '';

    if( candidate && candidateEmail.length > 0 )
    {

      if( newCandidateEmail && allUsers.some(user => user.email === candidate.email) )
      {
        const candidateAsUser = allUsers.find(user => user.email === candidate.email);

        if( candidateAsUser )
        {
          runInAction(() => candidateAsUser.email = newCandidateEmail);
        }
      }
      else
      {
        if( !allUsers.some(user => user.email === candidate.email) )
        {
          const candidateAsUser:Partial<Common_User> = {
            id: -1,
            email: candidateEmail,
            first_name: candidate.first_name,
            last_name: candidate.last_name,
            avatar: candidate.avatar_url,
            staffs: []
          };

          runInAction(() => allUsers.splice(0, 0, candidateAsUser as Common_User));
        }
      }
    }
  }

  // ----------------------------------------------------

  @bind
  public getEventAttendeesAsUsers(event?:Partial<Common_Scheduled_Event> | null):Array<Common_User>
  {
    if( !event ) return [];

    const { candidate } = this._candidateState;
    const { allUsers } = this._scheduleState;

    const attendees_list = event?.attendees_list || '';

    const attendeesEmails = attendees_list ? attendees_list.split(',').filter(email => email.length > 0) : [];

    const userEmail = allUsers.find(user => user.id == event?.user_id)?.email;

    if( userEmail && userEmail.length > 0 )
    {
      if( !attendeesEmails.includes(userEmail) )
        attendeesEmails.splice(0, 0, userEmail);
    }

    // TODO: catch a bad state, where the candidate's email was removed
    const candidateEmail = candidate?.email || '';

    return attendeesEmails
      .filter(email => email !== environment.recruiting.careersEmail)
      .map(email => allUsers.find(user => user.email == email))
      .sort((user):number => user?.email == candidateEmail ? -1 : 1)
      .sort((user):number => user?.email == userEmail ? -1 : 1) as Array<never>;
  }

  private async _updateNoteIsPinned(noteId:number, isPinned:boolean):Promise<void>
  {
    await this._commonController.mutate<UpdateNoteIsPinnedMutation, UpdateNoteIsPinnedMutationVariables>({
      mutation: UpdateNoteIsPinnedDocument,
      variables: {
        id: noteId,
        is_pinned: isPinned
      }
    });
  }

  public async pinNote(noteId:number):Promise<void>
  {
    await this._updateNoteIsPinned(noteId, true);
  }

  public async unpinNote(noteId:number):Promise<void>
  {
    await this._updateNoteIsPinned(noteId, false);
  }

  @bind
  public showCopyToStaffDialog():void
  {
    runInAction(() => this._candidateState.isCopyToStaffDialogOpen = true);
  }

  @bind
  public hideCopyToStaffDialog():void
  {
    runInAction(() => this._candidateState.isCopyToStaffDialogOpen = false);
  }

  @bind
  public setNewStaffEmail(email:string):void
  {
    runInAction(() => this._candidateState.newStaffEmail = email);
  }

  @bind
  public async checkStaffEmail(email:string):Promise<void>
  {
    runInAction(() => this._candidateState.isCheckingEmailInProgress = true);

    const result = await this._commonController.query<GetStaffAndUserByEmailQuery, GetStaffAndUserByEmailQueryVariables>({
      query: GetStaffAndUserByEmailDocument,
      variables: { email }
    });

    if( result && result.data )
    {
      runInAction(() =>
      {
        this._candidateState.isCheckingEmailInProgress = false;

        if( result.data.staffing_staff.length > 0 )
        {
          const staff:Staffing_Staff = result.data.staffing_staff[0] as Staffing_Staff;
          this._candidateState.existingStaff = staff;
          this._candidateState.doesUserEmailExist = true;
        }
        else
        {
          this.initAllStaffRoles();
          this._candidateState.doesUserEmailExist = false;
        }
      });
    }
    else
    {
      // TODO: Error handling?
    }
  }

  @action.bound
  public onChangeAddMoreInfoClick(item:IInlineEditorMultiSelectValue):void
  {
    setTimeout(() =>
    {
      if( !this._candidateState.inlineEditorList.some(e => e.key == item.value) )
      {
        const newInlineEditor:InlineEditorListType = { key: item?.value?.toString(), isEditMode: true };
        this._candidateState.inlineEditorList.push(newInlineEditor);
      }
    }, 100);
  }

  @action.bound
  public onCancelAdditionalInlineEditorClick(inlineEditorFieldName:string):void
  {
    const convertedKey = this.convertFieldNameToKey(inlineEditorFieldName);
    const recruitingCandidateKey = convertedKey as keyof Recruiting_Candidate;
    const value = this._candidateState.candidate?.[recruitingCandidateKey];
    if( !value )
    {
      const index = this._candidateState.inlineEditorList.findIndex(item =>
      {
        return item.key == convertedKey;
      });

      this._candidateState.inlineEditorList.splice(index, 1);
    }
  }

  private convertFieldNameToKey(inlineEditorFieldName:string):string
  {
    switch( inlineEditorFieldName )
    {
      case CandidateFieldName.EXPERIENCE_IN_YEARS:
        return 'experience_in_years';
      case CandidateFieldName.CURRENT_EMPLOYER:
        return 'current_employer';
      case CandidateFieldName.INSTANT_MESSENGER_ID:
        return 'instant_messenger_id';
      case CandidateFieldName.SOURCE:
        return 'source';
      case CandidateFieldName.SECONDARY_EMAIL:
        return 'secondary_email';
    }

    return '';
  }

  @action.bound
  public renderFilledAdditionalFields = ():void =>
  {
    // Reset the inline editor list state
    this._candidateState.inlineEditorList = [];
    const moreInfoSelectFieldsKeys = this._candidateState.moreInfoSelectFields.map(el => el.value as keyof typeof FIELDS_MAP);
    const defaultMoreInfoFieldsKeys = Object.keys(FIELDS_MAP);
    const filledFields = defaultMoreInfoFieldsKeys.filter(defaultField =>
    {
      return !moreInfoSelectFieldsKeys.includes(defaultField as keyof typeof FIELDS_MAP);
    });
    filledFields.forEach((filledField) =>
    {
      this._candidateState.inlineEditorList.push({ key: filledField });
    });
  };

  @action.bound
  public handleMoreInfoClick = ():void =>
  {
    this._candidateState.isExtended = !this._candidateState.isExtended;
    this.renderFilledAdditionalFields();
  };

  // ----------------------------------------------------

  @bind
  public resetCopyToStaffDialog():void
  {
    runInAction(() =>
    {
      this._candidateState.newStaffEmail = '';
      this._candidateState.doesUserEmailExist = false;
      this._candidateState.existingStaff = null;
      this._candidateState.newStaffHireDate = null;
      this._candidateState.newStaffRole = null;
    });
  }

  @bind
  public async initAllStaffRoles():Promise<void>
  {
    const result = await this._commonController.query<GetAllStaffRolesQuery, GetAllStaffRolesQueryVariables>({
      query: GetAllStaffRolesDocument
    });

    if( result && result.data )
      runInAction(() =>
      {
        this._candidateState.allStaffRoles = result.data.staffing_staff_role as Array<Staffing_Staff_Role>;
      });
    else
      throw result.error;
  }

  @bind
  public newStaffHireDate(date:Date):void
  {
    runInAction(() => this._candidateState.newStaffHireDate = date);
  }

  @bind
  public setNewStaffRole(role:Staffing_Staff_Role):void
  {
    runInAction(() => this._candidateState.newStaffRole = role);
  }

  // ----------------------------------------------------

  @action.bound
  public openUploadAttachmentDialog():void
  {
    this._candidateState.isAddAttachmentDialogOpen = true;
    this._candidateState.isAttachmentDialogInEditMode = false;
    this._candidateState.renameAttachmentId = null;

    this._candidateState.uploadFileName = '';
    this._candidateState.uploadFileType = Common_Attachment_Type_Enum.Other;
    this._candidateState.fileToUpload = null;
    this._candidateState.uploadProgressPercent = 0;
  }

  @action.bound
  public openUploadAttachmentDialogInEditMode(attachment:Common_Attachment):void
  {
    this._candidateState.uploadFileName = attachment.name;
    this._candidateState.renameAttachmentId = attachment.id;
    this._candidateState.isAttachmentDialogInEditMode = true;

    this._candidateState.isAddAttachmentDialogOpen = true;
    this._candidateState.uploadFileType = Common_Attachment_Type_Enum.Other;
    this._candidateState.fileToUpload = null;
    this._candidateState.uploadProgressPercent = 0;
  }

  @action.bound
  public setUploadFileName(name:string):void
  {
    this._candidateState.uploadFileName = name;
  }

  @action.bound
  public prepareFileForUpload(file:File):void
  {
    this._candidateState.fileToUpload = file;
    this._candidateState.uploadProgressPercent = 0;
    this._candidateState.uploadFileName = file?.name || '';
  }

  public async addAttachment(attachment:Partial<Common_Attachment>, candidateId:number):Promise<void>
  {
    attachment.created_by = Number(this._authState.user?.id);

    await this._commonController.mutate<AddAttachmentMutation, AddAttachmentMutationVariables>({
      mutation: AddAttachmentDocument,
      variables: {
        attachment: {
          name: attachment.name,
          type: attachment.type,
          cdn_path: attachment.cdn_path,
          size: attachment.size,
          created_by: attachment.created_by,
          mime_type: attachment.mime_type,
          candidate_attachments: {
            data: [
              {
                candidate_id: candidateId
              }
            ]
          }
        }
      }
    });
  }

  public async renameAttachment(attachmentId:number, attachmentName:string):Promise<boolean>
  {
    const response = await this._commonController.mutate<RenameAttachmentMutation, RenameAttachmentMutationVariables>({
      mutation: RenameAttachmentDocument,
      variables: {
        id: attachmentId,
        name: attachmentName
      }
    });

    return !!response.data?.update_common_attachment_by_pk;
  }

  public async deleteAttachment(attachmentId:number):Promise<boolean>
  {
    const response = await this._commonController.mutate<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>({
      mutation: DeleteAttachmentDocument,
      variables: {
        id: attachmentId
      }
    });

    return !!response.data?.delete_common_attachment_by_pk;
  }

  public async completeFeedbackForm(scheduledEventId:number):Promise<void>
  {
    runInAction(() => this._feedbackFormState.isCompleteFeedbackLoading = true);

    await this._commonController.mutate<UpdateScheduledEventMutation, UpdateScheduledEventMutationVariables>({
      mutation: UpdateScheduledEventDocument,
      variables: {
        scheduledEventId,
        scheduledEvent: {
          is_feedback_done: true
        }
      }
    });
  }

  public async uncompleteFeedbackForm(scheduledEventId:number):Promise<void>
  {
    runInAction(() => this._feedbackFormState.isCompleteFeedbackLoading = true);

    await this._commonController.mutate<UpdateScheduledEventMutation, UpdateScheduledEventMutationVariables>({
      mutation: UpdateScheduledEventDocument,
      variables: {
        scheduledEventId,
        scheduledEvent: {
          is_feedback_done: false
        }
      }
    });
  }

  // ----------------------------------------------------

  @action.bound
  public async updateDraftScheduleCall(isDraft:boolean):Promise<void>
  {
    const { draftEvent } = this._scheduleState;

    if( !draftEvent || !draftEvent.id ) return;

    if( draftEvent.is_draft === isDraft ) return;

    const result:FetchResult<UpdateScheduledEventMutation> =
      await this._commonController.mutate<UpdateScheduledEventMutation,
        UpdateScheduledEventMutationVariables>({
        mutation: UpdateScheduledEventDocument,
        variables: {
          scheduledEventId: draftEvent.id,
          scheduledEvent: {
            is_draft: isDraft
          }
        }
      });

    console.log('%c updateDraftScheduleCall result =', 'background:#f60;color:#000;', result);

    if( result.errors )
    {
      console.log('%c ERROR updateDraftScheduleCall =', 'background:#f00;color:#ff0;', toJS(result.errors));
      throw new Error(result.errors[0].message);
    }

    runInAction(() =>
    {
      if( this._scheduleState.draftEvent )
      {
        this._scheduleState.draftEvent.is_draft = isDraft;

        if( isDraft )
        {
          this._scheduleController.addEventForEdit(this._scheduleState.draftEvent);
        }
        else
        {
          this._scheduleController.removeEventForEdit(this._scheduleState.draftEvent.id);
        }
      }

      this._candidateState.isDraftEventOpen = isDraft;
    });

    // click btn [Schedule call]
    // -> expand draft event
    // -> collapse all other events
    if( isDraft && this._scheduleState.draftEvent && this._scheduleState.draftEvent.id )
    {
      this.expandEvents([this._scheduleState.draftEvent.id]);
      this.collapseEvents(this._scheduleState.draftEvent.id || -1);
    }
  }

  // ----------------------------------------------------

  public collapseEvents(excludedEventId?:number):void
  {
    const { draftEvent } = this._scheduleState;

    if( excludedEventId && draftEvent && draftEvent.id && draftEvent.id !== excludedEventId )
    {
      this._commonController.toggleCollapseItem(CandidateEventsCollapseListId, draftEvent.id, true);
    }

    this._candidateState.callsToRender.forEach((call:Common_Scheduled_Event) =>
    {
      if( excludedEventId && call.id === excludedEventId ) return;

      this._commonController.toggleCollapseItem(CandidateEventsCollapseListId, call.id, true);
    });
  }

  // ----------------------------------------------------

  public expandEvents(eventIds:Array<number>):void
  {
    const { draftEvent } = this._scheduleState;

    if( draftEvent && draftEvent.id && eventIds.includes(draftEvent.id) )
    {
      this._commonController.toggleCollapseItem(CandidateEventsCollapseListId, draftEvent.id, false);
    }

    this._candidateState.callsToRender.forEach((call:Common_Scheduled_Event) =>
    {
      if( !eventIds.includes(call.id) ) return;

      this._commonController.toggleCollapseItem(CandidateEventsCollapseListId, call.id, false);
    });
  }

  // ----------------------------------------------------
}
