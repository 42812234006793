import { Menu, MenuItem } from '@blueprintjs/core';
import { MoveTo } from '@flow/common/models/MoveTo';
import type { Recruiting_Position } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import type { ReactNode } from 'react';
import React from 'react';
import { CustomerController } from '../../CustomerController';
import { CustomerState } from '../../CustomerState';
import { NewPositionController } from '../../NewPositionController';

export interface IPositionsListItemMenuProps
{
  position:Recruiting_Position;

  isFirst:boolean;
  isLast:boolean;
}

@component
export class PositionsListItemMenu extends React.Component<IPositionsListItemMenuProps>
{
  @di private _customerState!:CustomerState;
  @di private _customerController!:CustomerController;
  @di private _positionController!:NewPositionController;

  public render():ReactNode
  {
    const { position, isFirst, isLast } = this.props;

    return (
      <Menu>
        {/* <MenuItem
          text="Delete Position"
          onClick={async ():Promise<void> =>
          {
            // await this._positionController.deletePosition(position);
            return this._customerController.initCustomer(this._customerState.customer?.id || -1);
          }}
        /> */}
        <MenuItem
          text="Move up"
          disabled={isFirst}
          onClick={():void =>
          {
            this._positionController.changePositionOrder(position as Recruiting_Position, MoveTo.UP);
          }}
        />
        <MenuItem
          text="Move down"
          disabled={isLast}
          onClick={():void =>
          {
            this._positionController.changePositionOrder(position as Recruiting_Position, MoveTo.DOWN);
          }}
        />
        <MenuItem
          text="Move to top"
          disabled={isFirst}
          onClick={():void =>
          {
            this._positionController.changePositionOrder(position as Recruiting_Position, MoveTo.TOP);
          }}
        />
        <MenuItem
          text="Move to bottom"
          disabled={isLast}
          onClick={():void =>
          {
            this._positionController.changePositionOrder(position as Recruiting_Position, MoveTo.BOTTOM);
          }}
        />
      </Menu>
    );
  }
}
