import { Button } from '@flow/common/components/form/Button';
import { FullscreenPage } from '@flow/common/components/page/FullscreenPage';
import { PageTitle } from '@flow/common/components/page/PageTitle';
import { Intent } from '@flow/common/components/types/Types';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di, diModule } from '@flow/dependency-injection';
import { ScheduleCalendar } from '@flow/modules/_demo/schedule/components/ScheduleCalendar';
import { ScheduleToolbar } from '@flow/modules/_demo/schedule/components/ScheduleToolbar';
import styles from '@flow/modules/_demo/schedule/components/ScheduleToolbar.module.less';
import React from 'react';
import { ScheduleController } from './ScheduleController';
import { ScheduleState } from './ScheduleState';

@diModule({
  providers: [
    ScheduleState,
    ScheduleController
  ]
})
@componentWithRouter
export class ScheduleDemo extends React.Component<IWithRouterProps>
{
  @di private _scheduleState!:ScheduleState;
  @di private _scheduleController!:ScheduleController;

  // ----------------------------------------------------

  public componentDidMount():void
  {
    this._scheduleController.initSchedule(0);
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    return (
      <>
        <PageTitle title={'DEMO: Calendar'} />

        <FullscreenPage
          title={'DEMO: Calendar'}
          btnBackTitle={'Back to Home'}
          btnBackUrl={'/'}
          sidebarElements={
            <>
              <Button
                className={styles.btnDemo}
                text={'Random DEMO event'}
                // minimal={true}
                // outlined={true}
                intent={Intent.WARNING}
                onClick={():void =>
                {
                  this._scheduleController.setRandomEventTime();
                }}
              />
            </>
          }
          contentElements={
            <>
              <ScheduleToolbar />
              <ScheduleCalendar />
            </>
          }
        />
      </>
    );
  }
}
