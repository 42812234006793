import { Collapse } from '@blueprintjs/core';
import { AllocationPositionInDialog } from '@flow/common/components/elements/AllocationPositionInDialog';
import { CustomerAndTeam } from '@flow/common/components/elements/CustomerAndTeam';
import { PositionItem } from '@flow/common/components/elements/PositionItem';
import { BlankState } from '@flow/common/components/form/BlankState';
import { Button, ButtonSize } from '@flow/common/components/form/Button';
import { TextArea } from '@flow/common/components/form/TextArea';
import { Dialog } from '@flow/common/components/page/Dialog';
import { Intent } from '@flow/common/components/types/Types';
import type { Staffing_Customer_Team_Slot } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { AllocationPositionsFilter } from '@flow/modules/recruiting/candidates/components/candidate/allocation/AllocationPositionsFilter';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { runInAction } from 'mobx';
import moment from 'moment';
import type { ReactNode } from 'react';
import React from 'react';
import { CandidateAllocationController } from '../../../CandidateAllocationController';
import { CandidateAllocationState } from '../../../CandidateAllocationState';
import { CandidatePositionGroupsState } from '../../../CandidatePositionGroupsState';
import { CandidateState } from '../../../CandidateState';

import styles from './FinalizeAllocationDialog.module.less';

const cx = classNames.bind(styles);

interface IFinalizeAllocationDialogProps
{
  onSave?:() => Promise<void>;
}

interface IFinalizeAllocationDialogState
{
  expandedPositions:Map<number, boolean>;
}

@component
export class FinalizeAllocationDialog extends React.Component<IFinalizeAllocationDialogProps, IFinalizeAllocationDialogState>
{
  @di private _candidateState!:CandidateState;
  @di private _candidateAllocationState!:CandidateAllocationState;
  @di private _candidatePositionGroupsState!:CandidatePositionGroupsState;

  @di private _candidateAllocationController!:CandidateAllocationController;

  private _contentWrapperRef:React.RefObject<HTMLDivElement> = React.createRef();

  // ---------------------------------------------------------

  public componentDidUpdate():void
  {
    const { isScrolledToPosition } = this._candidateAllocationState;

    if( isScrolledToPosition ) return;

    // wait for positions to render
    setTimeout(() =>
    {
      if( !this._contentWrapperRef.current ) return;

      const positionItemDiv:HTMLDivElement | null = this._contentWrapperRef.current.querySelector('[data-selected-position-item]');

      if( !positionItemDiv ) return;

      positionItemDiv.scrollIntoView({ behavior: 'auto', block: 'center' });

      runInAction(() =>
      {
        this._candidateAllocationState.isScrolledToPosition = true;
      });
    }, 200);
  }

  // ---------------------------------------------------------

  @bind
  private _renderPositions():React.ReactNode | null
  {
    const {
      inDialogAutoComputedPositions,
      selectedPositionId, currentFinalSlots, selectedDate, selectedSlot,
      sortedProposedAllocations, positionById,
      isShowOnlyOpenPositionsInDialog
    } = this._candidateAllocationState;

    const {
      isPositionProposed
    } = this._candidateAllocationController;

    const { candidate } = this._candidateState;

    const autoComputedDivs = inDialogAutoComputedPositions
      .map((position:Recruiting_Position_Ex, index:number, positions:Array<Recruiting_Position_Ex>) =>
      {
        const prevPosition:Recruiting_Position_Ex | null = index ? positions[index - 1] : null;

        return (
          <React.Fragment key={position.id}>
            {
              // customer alias & team name
              prevPosition?.customer_team?.id !== position.customer_team?.id &&
              <CustomerAndTeam
                className={styles.customerAndTeam}
                items={[
                  position.customer_team?.customer.alias,
                  position.customer_team?.name
                ]}
              />
            }
            <AllocationPositionInDialog
              className={styles.positionItem}
              position={position}

              candidate={candidate}
              proposedAllocations={sortedProposedAllocations}
              currentFinalSlots={currentFinalSlots}

              selectedPositionId={selectedPositionId}
              selectedSlot={selectedSlot}
              selectedDate={selectedDate}

              positionById={positionById}
              isPositionProposed={isPositionProposed}

              onSelectSlot={(slot:Staffing_Customer_Team_Slot):void =>
              {
                runInAction(() =>
                {
                  this._candidateAllocationState.selectedSlot = Object.assign({}, slot);
                });
              }}
              onSelectDate={(newDate:string | null):void =>
              {
                runInAction(() =>
                {
                  this._candidateAllocationState.selectedDate = newDate;
                });
              }}
              onSelectPosition={(positionId:number, positionItemRef:React.RefObject<PositionItem>):void =>
              {
                runInAction(() =>
                {
                  this._candidateAllocationController.setSelectedPositionId(position.id, positionItemRef);
                });
              }}
              onSelectPositionItemRef={(selectedPositionItemRef:React.RefObject<PositionItem> | null):void =>
              {
                runInAction(() =>
                {
                  this._candidateAllocationState.selectedPositionItemRef = selectedPositionItemRef;
                });
              }}
            />
          </React.Fragment>
        );
      });

    const hasAutoComputed:boolean = autoComputedDivs.length !== 0;

    if( hasAutoComputed ) return autoComputedDivs;

    return (
      <BlankState
        className={styles.noPositions}
        title={`No ${isShowOnlyOpenPositionsInDialog ? 'open' : 'available'} positions`}
        withBorder={true}
      />
    );
  }

  // ---------------------------------------------------------

  public render():ReactNode
  {
    const {
      isFinalizeAllocationDialogOpen, isFinalizeAllocationLoading,
      currentFinalSlot, isReFinalize, finalizedAllocation,
      finalizeNote, showNoteInput,
      selectedPositionId, selectedSlot, selectedDate
    } = this._candidateAllocationState;

    const { candidatePositionGroupNames } = this._candidatePositionGroupsState;

    let isButtonDisabled:boolean = !selectedPositionId || !selectedSlot || !selectedDate
      || !moment(selectedDate).isValid();

    if( isReFinalize )
    {
      const oldNotes:string = finalizedAllocation?.notes || '';
      const newNotes:string = showNoteInput ? finalizeNote.trim() : '';

      isButtonDisabled = isButtonDisabled || !!currentFinalSlot && (
        currentFinalSlot.position_id === selectedPositionId
        && currentFinalSlot.id === selectedSlot?.id
        && moment(currentFinalSlot.next_candidate_start_date).isSame(selectedDate, 'day')
        && oldNotes === newNotes
      );
    }

    return (
      <Dialog
        title={isReFinalize ? 'Refinalize Position' : 'Finalize Position'}
        titleTextClassName={styles.titleText}
        titleAdditionalContent={(
          <div className={styles.titleAdditionalContent}>
            <div className={styles.positionGroups}>
              {candidatePositionGroupNames.join(', ')}
            </div>
            <AllocationPositionsFilter inDialog={true} />
          </div>
        )}

        isOpen={isFinalizeAllocationDialogOpen}
        isLoading={isFinalizeAllocationLoading}
        // onOpening={this._updateExpandedMap}
        onClose={this._candidateAllocationController.hideFinalizeAllocationDialog}
        className={styles.dialogClassName}

        isPrimaryButtonDisabled={isButtonDisabled}
        primaryButtonText={isReFinalize ? 'Refinalize' : 'Finalize'}
        primaryButtonIntent={Intent.PRIMARY}
        primaryButtonClassName={styles.primaryButton}
        primaryButtonOnClick={this._candidateAllocationController.finalizeAllocation}

        secondaryButtonText={'Cancel'}
        secondaryButtonIntent={Intent.PRIMARY}
        secondaryButtonClassName={styles.secondaryButton}
        isSecondaryButtonOutlined={true}

        buttonsSize={ButtonSize.LARGE}
        canEscapeKeyClose={false}
      >
        <div
          className={styles.contentWrapper}
          ref={this._contentWrapperRef}
        >
          {this._renderPositions()}
        </div>

        <Collapse isOpen={showNoteInput}>
          <div className={styles.notesInputWrapper}>
            <TextArea
              className={cx(styles.notesInput)}
              value={finalizeNote}
              fill={true}
              placeholder={'Explain why...'}
              onChange={this._candidateAllocationController.setFinalizeNote}
            />
          </div>
        </Collapse>

        <div className={styles.noteBtnWrapper}>
          <Button
            className={styles.showNoteBtn}
            text={showNoteInput ? 'Remove note' : 'Add note'}
            minimal={true}
            intent={Intent.PRIMARY}
            onClick={():void => this._candidateAllocationController.setNoteInputVisibility(!showNoteInput)}
          />
        </div>

      </Dialog>
    );
  }
}
