import { Collapse } from '@blueprintjs/core';
import { PositionsCount } from '@flow/common/components/elements/PositionsCount';
import { SvgIcon, SvgIconName } from '@flow/common/components/elements/SvgIcon';
import { Button } from '@flow/common/components/form/Button';
import type { IconName } from '@flow/common/components/form/Icon';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { DebugUtil } from '@flow/common/utils/DebugUtil';
import { IPositionsCount, PositionsUtil } from '@flow/common/utils/PositionsUtil';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './PositionItem.module.less';

const cx = classNames.bind(styles);

interface Props
{
  className:string;
  innerClassName?:string;
  position:Recruiting_Position_Ex;

  actionsBlock?:React.ReactNode | null;
  actionsBlockFn?:(position:Recruiting_Position_Ex, isOpened?:boolean) => React.ReactNode | null;

  hiddenBlock?:React.ReactNode | null;
  hiddenBlockFn?:(position:Recruiting_Position_Ex, isOpened?:boolean) => React.ReactNode | null;

  inDialog?:boolean;

  icon?:IconName;
  svgIcon?:SvgIconName;
  iconSize?:number;
  iconClassName?:string;

  selected?:boolean;
  canSelect?:boolean;
  onClick?:(e:React.MouseEvent<HTMLElement>) => void;
}

interface State
{
  isOpened:boolean;
}

export class PositionItem extends React.Component<Props, State>
{
  // ---------------------------------------------------------

  public constructor(props:Props)
  {
    super(props);

    this.state = {
      isOpened: false
    };
  }

  // ---------------------------------------------------------

  @bind
  public setHiddenBlockVisibility(isOpened:boolean):void
  {
    this.setState({ isOpened: isOpened });
  }

  // ---------------------------------------------------------

  public render():ReactNode
  {
    // const isOpened:boolean = true;
    const { isOpened } = this.state;

    const {
      className, innerClassName,
      position, inDialog,
      actionsBlock, actionsBlockFn,
      hiddenBlock, hiddenBlockFn,
      icon, svgIcon, iconClassName, iconSize,
      canSelect, selected,
      onClick
    } = this.props;

    const positionsCount:IPositionsCount = PositionsUtil.getPositionsCount([position]);

    const isCustomIcon:boolean = (!!icon || !!svgIcon) && !canSelect;

    const isReady:boolean = position.is_ready;
    const isReadyIcon:boolean = !isCustomIcon && !canSelect;

    const renderedActionsBlock:React.ReactNode | null = actionsBlock || actionsBlockFn?.(position, isOpened);
    const renderedHiddenBlock:React.ReactNode | null = hiddenBlock || hiddenBlockFn?.(position, isOpened);

    // !!! after delete last propose
    if( isOpened && !renderedHiddenBlock )
    {
      setTimeout(() =>
      {
        this.setState({ isOpened: false });
      }, 100);
    }

    return (
      <div
        className={cx(styles.wrapper, { isOpened, inDialog, selected }, className)}
        data-selected-position-item={selected ? true : null}
      >

        <div
          className={cx(styles.inner, innerClassName, { canOpen: renderedHiddenBlock })}
          onClick={(e):void =>
          {
            if( !renderedHiddenBlock ) return;

            this.setState({ isOpened: !isOpened });
            onClick?.(e);
          }}
        >
          <div className={styles.templateIconWrapper}>
            {
              isReadyIcon && isReady &&
              <Icon
                className={cx(styles.templateIcon, styles.isReady)}
                icon={IconNames.DOCUMENT}
              />
            }
            {
              isReadyIcon && !isReady &&
              <SvgIcon
                className={styles.templateIcon}
                icon={SvgIconName.DocumentQuestion}
              />
            }
            {
              isCustomIcon && icon &&
              <Icon
                className={cx(styles.customIcon, iconClassName)}
                icon={icon}
                size={iconSize}
              />
            }
            {
              isCustomIcon && svgIcon &&
              <SvgIcon
                className={cx(styles.customIcon, iconClassName)}
                icon={svgIcon}
                size={iconSize}
              />
            }
            {
              canSelect &&
              <div className={cx(styles.iconRadio, { isSelected: selected })} />
            }
          </div>

          <div className={styles.positionName}>
            {DebugUtil.id(position.id)}
            {PositionsUtil.getPositionName(position)}
          </div>

          <PositionsCount
            count={positionsCount.staffed}
            totalCount={positionsCount.total}
          />

          <div className={styles.actionsBlock}>
            {renderedActionsBlock}
          </div>

          {
            renderedHiddenBlock &&
            <div className={styles.arrowWrapper}>
              <Button
                className={cx(styles.collapseButton, { isOpened })}
                icon={isOpened ? IconNames.CARET_UP : IconNames.CARET_DOWN}
                iconClassName={styles.btnDownIcon}
                iconSize={13}
                minimal={true}
              />
            </div>
          }

        </div>

        {
          renderedHiddenBlock &&
          <Collapse isOpen={isOpened}>
            <div className={cx(styles.hiddenBlock, { isOpened })}>
              {renderedHiddenBlock}
            </div>
          </Collapse>
        }

      </div>
    );
  }
}
