import type { IPopoverProps } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { InlineEditor, InlineEditorType } from '@flow/common/components/form/InlineEditor';
import classNames from 'classnames/bind';
import type { Moment } from 'moment';
import moment from 'moment';

import styles from '../eventForm/CommunicationEventForm.module.less';

const cx = classNames.bind(styles);

interface Props
{
  startDateStr:string;
  scheduledEventStartTime:Date;
  onChange?:(value:Moment) => void;
  isChanged?:boolean;
  isChangedDay?:boolean;
  popoverProps?:(Partial<IPopoverProps> & object);
  readonly?:boolean;
}

export const EventDateEditor = (props:Props):JSX.Element =>
{
  const {
    startDateStr,
    scheduledEventStartTime,
    onChange,
    isChanged, isChangedDay,
    popoverProps,
    readonly
  } = props;

  return <InlineEditor
    type={InlineEditorType.DATE}
    popoverProps={popoverProps}
    minDate={new Date()}
    applyWhenSelect
    noButtons
    icon={IconNames.CALENDAR}
    iconClassName={cx(styles.iconField, { isChanged })}
    viewClassName={cx(styles.dateTimeEditor, { readonly })}
    text={startDateStr}
    readonly={readonly}
    isChanged={isChangedDay}
    fields={[
      {
        name: 'date',
        value: scheduledEventStartTime,
        placeholder: 'Choose date'
      }
    ]}
    onChange={(value):void =>
    {
      const newDate = moment(value['date'].value);

      if( newDate.isValid() && onChange )
        onChange(newDate as Moment);
    }}
  />;
};
