import { Radio, RadioGroup } from '@blueprintjs/core';
import { ButtonSize } from '@flow/common/components/form/Button';
import { TextInput } from '@flow/common/components/form/TextInput';
import { Dialog } from '@flow/common/components/page/Dialog';
import { DialogRow } from '@flow/common/components/page/DialogRow';
import { Intent } from '@flow/common/components/types/Types';
import { Common_Customer_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import type { ReactNode } from 'react';
import React from 'react';
import { CustomerController } from '../../CustomerController';
import { CustomerState } from '../../CustomerState';
import classNames from 'classnames/bind';

import styles from './CustomerDialog.module.less';

const cx = classNames.bind(styles);

export interface ICustomerDialogProps
{
  onSave?:(customerId:number) => Promise<void>;
}

@component
export class CustomerDialog extends React.Component<ICustomerDialogProps>
{
  @di private _customerState!:CustomerState;
  @di private _customerController!:CustomerController;

  @bind
  private async _saveCustomer():Promise<void>
  {
    const { onSave } = this.props;

    const id:number = await this._customerController.saveCustomer();

    if( onSave )
      return onSave(id) as Promise<void>;
  }

  public render():ReactNode
  {
    const {
      isCustomerDialogOpen,
      isCustomerLoading,
      customerId,
      customerName,
      customerAlias,
      customerStatus,
      isCustomerInternal,
      isCustomerValid
    } = this._customerState;

    return (
      <Dialog
        title={customerId > 0 ? 'Edit Customer' : 'New Customer'}
        isOpen={isCustomerDialogOpen}
        isLoading={isCustomerLoading}
        onClose={this._customerController.hideCustomerDialog}
        className={cx(styles.newCustomerDialog,'test-customer-form')}

        buttonsSize={ButtonSize.LARGE}

        primaryButtonText={customerId > 0 ? 'Save' : 'Create'}
        primaryButtonIntent={Intent.PRIMARY}
        primaryButtonOnClick={this._saveCustomer}
        isPrimaryButtonDisabled={!isCustomerValid}

        secondaryButtonText={'Cancel'}
        secondaryButtonIntent={Intent.PRIMARY}
        isSecondaryButtonOutlined={true}
      >
        <DialogRow label={'Name'}>
          <TextInput
            value={customerName}
            large={true}
            onChange={this._customerController.setCustomerName}
            autoFocus={true}
          />
        </DialogRow>

        <DialogRow label={'Nickname'}>
          <TextInput
            value={customerAlias}
            large={true}
            onChange={this._customerController.setCustomerAlias}
          />
        </DialogRow>

        <DialogRow label={'Status'}>
          <RadioGroup
            inline={true}
            selectedValue={customerStatus === Common_Customer_Status_Enum.Current ? 'Active' : 'Prospective'}
            onChange={this._customerController.setCustomerStatus}
            disabled={isCustomerInternal}
          >
            <Radio
              className={styles.radio}
              label={'Prospective'}
              value={'Prospective'}
              name={'customerActiveOrProspective'}
            />
            <Radio
              className={styles.radio}
              label={'Active'}
              value={'Active'}
              name={'customerActiveOrProspective'}
            />
          </RadioGroup>
        </DialogRow>

      </Dialog>
    );
  }
}
