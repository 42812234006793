import { CommonController } from '@flow/common/CommonController';
import { ButtonMore } from '@flow/common/components/elements/ButtonMore';
import { SvgIcon, SvgIconName } from '@flow/common/components/elements/SvgIcon';
import { Button } from '@flow/common/components/form/Button';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { TextInput } from '@flow/common/components/form/TextInput';
import { NeedsPermission } from '@flow/common/components/NeedsPermission';
import { Intent } from '@flow/common/components/types/Types';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { MoveTo } from '@flow/common/models/MoveTo';
import { RouteName } from '@flow/common/models/routing/RouteName';
import { IPositionsCount, PositionsUtil } from '@flow/common/utils/PositionsUtil';
import { component, di } from '@flow/dependency-injection';
import { CustomerState } from '@flow/modules/customers/teams/CustomerState';
import { NewPositionController } from '@flow/modules/customers/teams/NewPositionController';
import { NewPositionState } from '@flow/modules/customers/teams/NewPositionState';
import { TeamController } from '@flow/modules/customers/teams/TeamController';
import { TeamState } from '@flow/modules/customers/teams/TeamState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { action, computed } from 'mobx';
import React, { ReactNode } from 'react';
import { RoutesConfig } from '../../../../../pages/RoutesConfig';
import type { Recruiting_Position_Ex } from '../../models/CustomersTypes';
import { PositionsCount } from '../team/PositionsCount';

import styles from './PositionsListItem.module.less';
import { PositionsListItemMenu } from './PositionsListItemMenu';

const cx = classNames.bind(styles);

export interface IPositionsListItemProps
{
  position:Recruiting_Position_Ex;

  itemIndex:number;
  itemsLength:number;
}

@component
export class PositionsListItem extends React.Component<IPositionsListItemProps>
{
  @di private _commonController!:CommonController;

  @di private _teamState!:TeamState;
  @di private _customerState!:CustomerState;
  @di private _teamController!:TeamController;
  @di private _positionState!:NewPositionState;
  @di private _positionController!:NewPositionController;

  // ---------------------------------------------------------

  @computed
  private get _totalCountIntent():Intent
  {
    const { oldPositions } = this._teamState;
    const {
      position: {
        id,
        newQuantity
      }
    } = this.props;

    const oldPosition:Recruiting_Position_Ex | undefined = oldPositions.find(
      (position:Recruiting_Position_Ex) => position.id === id
    );

    const oldCount:number | null = oldPosition?.total_slots_count?.aggregate?.count || null;

    if( !oldCount && newQuantity )
      return Intent.SUCCESS;

    if( !oldCount || !newQuantity )
      return Intent.DANGER;

    if( newQuantity > oldCount )
      return Intent.SUCCESS;

    if( newQuantity < oldCount )
      return Intent.WARNING;

    return Intent.NONE;
  }

  // ---------------------------------------------------------

  @computed
  private get _positionsCount():IPositionsCount
  {
    const { position } = this.props;

    return PositionsUtil.getPositionsCount([position]);
  }

// ---------------------------------------------------------

  @bind
  private _onClickPosition():void
  {
    const { position } = this.props;
    const { customer } = this._customerState;
    const { isTeamInfoEditMode } = this._teamState;

    if( isTeamInfoEditMode || !customer )
    {
      return;
    }

    const path:string | null = RoutesConfig.getRoutePath(
      RouteName.CUSTOMERS_CUSTOMER_TEAM_POSITION,
      {
        customerId: String(customer.id),
        positionId: String(position.id)
      }
    );

    if( path )
      this._commonController.navigate(path);
  }

  // ---------------------------------------------------------

  @bind
  private _renderPositionName():ReactNode
  {
    const { position } = this.props;

    const positionName:string = PositionsUtil.getPositionName(position) || 'N/A';

    const isReady:boolean = position.is_ready;

    return (
      <>
        {
          isReady &&
          <Icon
            className={cx(styles.templateIcon, styles.isReady)}
            icon={IconNames.DOCUMENT}
          />
        }
        {
          !isReady &&
          <SvgIcon
            className={styles.templateIcon}
            icon={SvgIconName.DocumentQuestion}
          />
        }
        {positionName}
      </>
    );
  }

  // ---------------------------------------------------------

  public render():ReactNode
  {
    const {
      position, position: { position_template, newQuantity },
      itemIndex, itemsLength
    } = this.props;

    const staffRoleName:string = position_template?.staff_role?.name || 'N/A';

    const isFirst:boolean = itemIndex === 0;
    const isLast:boolean = itemIndex === itemsLength - 1;

    const { team: managedTeam } = this._teamState;
    const isManagedTeam:boolean = managedTeam?.id === position.customer_team_id;

    const blueBorderTop:boolean = isManagedTeam && isFirst;
    const blueBorderBottom:boolean = isManagedTeam && isLast;
    const blueBorderLeftRight:boolean = isManagedTeam;

    if( isManagedTeam )
    {
      // console.log('%c itemIndex, itemsLength =', 'background:#0ff;color:#000;', itemIndex, itemsLength);
    }

    return (
      <div
        // key={isManagedTeam ? `${itemIndex}:${position.id}` : position.id}
        className={cx(styles.position, { isFirst, isLast, blueBorderTop, blueBorderBottom, blueBorderLeftRight })}
        onClick={this._onClickPosition}
      >
        {
          isManagedTeam &&
          <Button
            className={cx(styles.deleteBtn, 'delete-btn')}
            iconClassName={styles.deleteBtnIcon}
            icon={IconNames.SMALL_CROSS}
            minimal={true}
            onClick={action(():void =>
            {
              this._positionState.movingId = -1;
              this._positionState.moveUpId = -1;
              this._positionState.moveDownId = -1;

              setTimeout(() =>
              {
                this._teamController.deletePosition(Number(position.id));
              }, 0);
            })}
          />
        }
        <div className={cx(styles.positionName, styles.desktop)}>
          {/*[ {position.id} : {position.ui_customer_team_order} ] &nbsp;*/}
          {this._renderPositionName()}
        </div>

        <div className={styles.mobileWrapper}>
          <div className={cx(styles.positionName, styles.mobile)}>
            {this._renderPositionName()}
          </div>
          <div className={styles.staffRoleName}>
            {staffRoleName}
          </div>
        </div>

        <div className={styles.positionsWrapper}>
          <PositionsCount
            className={styles.positions}
            count={this._positionsCount.staffed}
            totalCount={this._positionsCount.total}
            hideCount={isManagedTeam}
            hideTotalCount={isManagedTeam}
          />
          {
            isManagedTeam &&
            <TextInput
              className={cx(styles.quantity, 'test-staff-count')}
              value={String(newQuantity || '')}
              intent={this._totalCountIntent}
              onChange={(newValue:string):void => this._teamController.setQuantity(position as Recruiting_Position_Ex, newValue)}
              leftElement={(
                <div className={styles.inputCount}>
                  <span className={styles.inputCountSpan}>{this._positionsCount.staffed}</span>/
                </div>
              )}
            />
          }

          <NeedsPermission name={FlowPermissions.ChangePositionCustomerTeamOrder}>
            <Button
              className={styles.btnMoveUp}
              iconClassName={styles.btnMoveIcon}
              icon={IconNames.CHEVRON_UP}
              minimal={true}
              disabled={isFirst || isManagedTeam}
              // disabled={isFirst}
              onClick={(e):void =>
              {
                e.stopPropagation();
                this._positionController.changePositionOrder(position as Recruiting_Position_Ex, MoveTo.UP);
              }}
            />

            <Button
              className={styles.btnMoveDown}
              iconClassName={styles.btnMoveIcon}
              icon={IconNames.CHEVRON_DOWN}
              minimal={true}
              disabled={isLast || isManagedTeam}
              // disabled={isLast}
              onClick={(e):void =>
              {
                e.stopPropagation();
                this._positionController.changePositionOrder(position as Recruiting_Position_Ex, MoveTo.DOWN);
              }}
            />
          </NeedsPermission>
        </div>

        <ButtonMore
          className={styles.menu}
          buttonClassName={styles.menuBtn}
          menuContent={<PositionsListItemMenu position={position} isFirst={isFirst} isLast={isLast} />}
        />

      </div>
    );
  }
}
