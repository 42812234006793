import classNames from 'classnames/bind';
import * as React from 'react';

import styles from './SvgIcon.module.less';

const cx = classNames.bind(styles);

export enum SvgIconName
{
  LinkedIn = 'LinkedIn',
  HappyGuyWithLaptop = 'HappyGuyWithLaptop',
  DocumentQuestion = 'DocumentQuestion',
  TableDescription = 'TableDescription',

  GoogleDrive = 'GoogleDrive',
  GoogleMeet = 'GoogleMeet',
  GoogleMeetBlue = 'GoogleMeetBlue',
  GoogleCalendar = 'GoogleCalendar',

  Question = 'Question',
  SmallDelete2 = 'SmallDelete2',
  SmallTick2Empty = 'SmallTick2Empty',
  SmallTick2Selected = 'SmallTick2Selected',
}

export enum SvgIconSize
{
  size16 = 'size16',
  size24 = 'size24',
}

export interface ISvgIconProps
{
  icon:SvgIconName;

  className?:string;
  size?:SvgIconSize | number;
}

export class SvgIcon extends React.PureComponent<ISvgIconProps>
{
  public render():React.ReactNode
  {
    const {
      icon,
      size,
      className
    } = this.props;

    const IconElement = IconsArray[icon];

    let style:React.CSSProperties = {};

    if( size && size !== SvgIconSize.size24 && size !== SvgIconSize.size16 )
    {
      style = {
        width: size,
        height: size,
        minWidth: size,
        minHeight: size
      };
    }

    return (
      <IconElement
        className={cx(styles.icon, { size24: size === SvgIconSize.size24 }, className)}
        style={style}
      />
    );
  }
}

const IconsArray:Record<SvgIconName, (props:React.SVGProps<SVGSVGElement>) => JSX.Element> = {

  [SvgIconName.LinkedIn]: (props) => (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M14.553 0H1.447C.648 0 0 .648 0 1.447v13.106C0 15.352.648 16 1.447 16h13.106c.799 0 1.447-.648 1.447-1.447V1.447C16 .648 15.352 0 14.553 0ZM4.95 13.816a.421.421 0 0 1-.421.42H2.737a.421.421 0 0 1-.421-.42V6.3c0-.233.188-.422.421-.422H4.53c.233 0 .421.189.421.422v7.516ZM3.633 5.17a1.703 1.703 0 1 1 0-3.407 1.703 1.703 0 0 1 0 3.407Zm10.688 8.68a.387.387 0 0 1-.387.387H12.01a.387.387 0 0 1-.388-.388v-3.525c0-.526.155-2.305-1.374-2.305-1.186 0-1.426 1.218-1.475 1.764v4.066a.387.387 0 0 1-.387.388h-1.86a.387.387 0 0 1-.388-.388V6.266c0-.214.174-.388.387-.388h1.861c.214 0 .387.174.387.388v.655c.44-.66 1.094-1.169 2.485-1.169 3.08 0 3.063 2.878 3.063 4.46v3.637Z"
        fill="currentColor"
      />
    </svg>
  ),

  [SvgIconName.HappyGuyWithLaptop]: (props) => (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      {...props}
    >
      <path
        d="M16.524 16.9991V14.638C16.524 13.0731 15.2556 11.8047 13.6906 11.8047H6.13509C4.57062 11.8047 3.30176 13.0731 3.30176 14.638V16.9991H16.524Z"
        fill="#77B255"
      />
      <path
        d="M7.85399 11.8567C7.85399 12.2789 8.96843 12.7978 9.91288 12.7978C10.8573 12.7978 11.9713 12.2789 11.9713 11.8572V10.0547H7.85352V11.8567H7.85399Z"
        fill="#F7DECE"
      />
      <path
        d="M7.85008 10.8477C8.4243 11.4966 9.13641 11.6722 9.91086 11.6722C10.6848 11.6722 11.3965 11.4961 11.9712 10.8477V9.19922H7.84961V10.8477H7.85008Z"
        fill="#EEC2AD"
      />
      <path
        d="M6.81748 6.10973C6.81748 6.79351 6.47086 7.3479 6.0435 7.3479C5.61614 7.3479 5.26953 6.79351 5.26953 6.10973C5.26953 5.42596 5.61614 4.87109 6.0435 4.87109C6.47086 4.87062 6.81748 5.42548 6.81748 6.10973ZM14.5567 6.10973C14.5567 6.79351 14.2106 7.3479 13.7828 7.3479C13.3554 7.3479 13.0088 6.79351 13.0088 6.10973C13.0088 5.42596 13.3549 4.87109 13.7828 4.87109C14.2106 4.87062 14.5567 5.42548 14.5567 6.10973Z"
        fill="#F7DECE"
      />
      <path
        d="M5.88867 6.59186C5.88867 3.95261 7.69067 1.8125 9.91342 1.8125C12.1362 1.8125 13.9377 3.95261 13.9377 6.59186C13.9377 9.23111 12.1362 11.3712 9.91342 11.3712C7.69114 11.3707 5.88867 9.23111 5.88867 6.59186Z"
        fill="#F7DECE"
      />
      <path
        d="M9.91291 9.82456C8.61241 9.82456 8.21291 9.49164 8.14633 9.42411C8.02544 9.30322 8.02544 9.10725 8.14633 8.98636C8.26344 8.86925 8.45139 8.86594 8.57227 8.9755C8.59683 8.99297 8.91275 9.20547 9.91291 9.20547C10.9518 9.20547 11.2521 8.97644 11.255 8.97408C11.3759 8.85367 11.5652 8.85933 11.6861 8.98117C11.807 9.10206 11.8004 9.3037 11.6795 9.42411C11.6129 9.49164 11.2134 9.82456 9.91291 9.82456Z"
        fill="#C1694F"
      />
      <path
        d="M9.91268 0C12.5798 0 14.2467 2.19489 14.2467 3.83492C14.2467 5.47494 13.9134 6.13133 13.58 5.47494L12.9132 4.16311C12.9132 4.16311 10.9133 4.16311 10.2465 3.50672C10.2465 3.50672 11.2467 5.47494 9.24638 3.50672C9.24638 3.50672 9.57977 4.81903 7.57944 3.179C7.57944 3.179 6.57927 3.83492 6.24588 5.47494C6.15332 5.93017 5.5791 5.47494 5.5791 3.83492C5.57863 2.19489 6.91218 0 9.91268 0Z"
        fill="#292F33"
      />
      <path
        d="M8.02398 6.61198C7.76426 6.61198 7.55176 6.39948 7.55176 6.13976V5.66753C7.55176 5.40781 7.76426 5.19531 8.02398 5.19531C8.2837 5.19531 8.4962 5.40781 8.4962 5.66753V6.13976C8.4962 6.39948 8.2837 6.61198 8.02398 6.61198ZM11.8018 6.61198C11.542 6.61198 11.3295 6.39948 11.3295 6.13976V5.66753C11.3295 5.40781 11.542 5.19531 11.8018 5.19531C12.0615 5.19531 12.274 5.40781 12.274 5.66753V6.13976C12.274 6.39948 12.0615 6.61198 11.8018 6.61198Z"
        fill="#662113"
      />
      <path
        d="M10.2676 7.91146H9.55924C9.36422 7.91146 9.20508 7.75232 9.20508 7.55729C9.20508 7.36226 9.36422 7.20312 9.55924 7.20312H10.2676C10.4626 7.20312 10.6217 7.36226 10.6217 7.55729C10.6217 7.75232 10.4626 7.91146 10.2676 7.91146Z"
        fill="#C1694F"
      />
      <path
        d="M15.5798 16.5269C15.5798 16.788 15.3687 16.9991 15.1075 16.9991H10.3853C10.1242 16.9991 9.91309 16.788 9.91309 16.5269C9.91309 16.2658 10.1242 16.0547 10.3853 16.0547H15.1075C15.3687 16.0547 15.5798 16.2658 15.5798 16.5269Z"
        fill="#E1E8ED"
      />
      <path
        d="M9.55457 10.3906H1.77188C1.05221 10.3906 0.129489 10.7547 0.601711 12.058L2.05238 16.0601C2.05285 16.0601 2.23985 17.0017 3.30235 17.0017H12.7468L10.8579 11.694C10.6511 11.0697 10.2747 10.3906 9.55457 10.3906Z"
        fill="#E1E8ED"
      />
      <path
        d="M9.08192 10.3906H1.29922C0.579555 10.3906 -0.343167 10.7547 0.129055 12.058L1.57972 16.0601C1.58019 16.0601 1.76719 17.0017 2.82969 17.0017H12.2741L10.3852 11.694C10.1784 11.0697 9.80206 10.3906 9.08192 10.3906Z"
        fill="#99AAB5"
      />
      <path
        d="M6.61618 13.8294C6.86362 14.5717 6.66293 15.3074 6.16804 15.4722C5.67315 15.6375 5.07154 15.1696 4.82409 14.4272C4.57665 13.6849 4.77734 12.9492 5.27223 12.7844C5.76712 12.6196 6.3692 13.0871 6.61618 13.8294ZM8.96832 11.6888H1.41276C1.21726 11.6888 1.05859 11.5301 1.05859 11.3346C1.05859 11.1391 1.21726 10.9805 1.41276 10.9805H8.96832C9.16382 10.9805 9.32248 11.1391 9.32248 11.3346C9.32248 11.5301 9.16382 11.6888 8.96832 11.6888Z"
        fill="#E1E8ED"
      />
    </svg>
  ),

  [SvgIconName.DocumentQuestion]: (props) => (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M10 0H4C3.45 0 3 0.45 3 1V5H5V2H9V6H13V14H7V16H14C14.55 16 15 15.55 15 15V5L10 0ZM3.1797 9.20705C3.03518 9.3034 3 9.38791 3 9.5C3 10.0523 2.55228 10.5 2 10.5C1.44772 10.5 1 10.0523 1 9.5C1 8.61209 1.46482 7.9466 2.0703 7.54295C2.64614 7.15906 3.35166 7 4 7C4.64834 7 5.35386 7.15906 5.9297 7.54295C6.53518 7.9466 7 8.61209 7 9.5C7 10.758 6.12652 11.4672 5.65055 11.8537L5.63059 11.8699L5.59014 11.9027L5.59013 11.9027C5.33181 12.1125 5.1836 12.2329 5.07741 12.359C4.9983 12.4529 4.99948 12.4851 4.99994 12.4977L5 12.5C5 13.0523 4.55228 13.5 4 13.5C3.44772 13.5 3 13.0523 3 12.5C3 11.8858 3.25701 11.4158 3.54759 11.0707C3.78413 10.7898 4.08406 10.5479 4.29954 10.3741L4.36941 10.3176C4.89838 9.88785 5 9.72424 5 9.5C5 9.38791 4.96482 9.3034 4.8203 9.20705C4.64614 9.09094 4.35166 9 4 9C3.64834 9 3.35386 9.09094 3.1797 9.20705ZM4 16C4.55228 16 5 15.5523 5 15C5 14.4477 4.55228 14 4 14C3.44772 14 3 14.4477 3 15C3 15.5523 3.44772 16 4 16Z"
        fill="currentColor"
      />
    </svg>
  ),

  [SvgIconName.TableDescription]: (props) => (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M1 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3ZM12 13H1C0.45 13 0 13.45 0 14C0 14.55 0.45 15 1 15H12C12.55 15 13 14.55 13 14C13 13.45 12.55 13 12 13ZM15 9H1C0.45 9 0 9.45 0 10C0 10.55 0.45 11 1 11H15C15.55 11 16 10.55 16 10C16 9.45 15.55 9 15 9ZM15 5H1C0.45 5 0 5.45 0 6C0 6.55 0.45 7 1 7H15C15.55 7 16 6.55 16 6C16 5.45 15.55 5 15 5Z"
        fill="currentColor"
      />
    </svg>
  ),

  [SvgIconName.GoogleDrive]: (props) => (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M5.62813 10.9311H5.5375L5.49375 11.0092L3.19688 15.0279H13.6594L13.7031 14.9498L16 10.9342H15.7344L5.62813 10.9311ZM11.2375 9.9748L11.2812 10.0498H11.3719L16 10.0311L15.8687 9.80293L10.7688 0.97168H10.6781L6.05 0.99043L6.18125 1.21855L11.2375 9.9748ZM7.60625 5.52168L7.5625 5.44355L5.23125 1.44668L5.1 1.6748L0 10.5061L0.04375 10.5842L2.375 14.5842L2.50625 14.3561L7.5625 5.5998L7.60625 5.52168Z"
        fill="currentColor"
      />
    </svg>
  ),

  [SvgIconName.GoogleCalendar]: (props) => (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <g clipPath="url(#clip0_158_13487)">
        <path d="M12.2109 16L16.0004 12.2106H12.2109V16Z" fill="#5C7080" />
        <path d="M16.0004 3.78943H12.2109V12.2105H16.0004V3.78943Z" fill="#5C7080" fillOpacity="0.3" />
        <path d="M12.2101 12.2106H3.78906V16H12.2101V12.2106Z" fill="#5C7080" fillOpacity="0.7" />
        <path d="M0 12.2106V14.7369C0 15.4348 0.565263 16 1.26316 16H3.78947V12.2106H0Z" fill="#5C7080" />
        <path
          d="M16.0004 3.78947V1.26316C16.0004 0.565263 15.4351 0 14.7373 0H12.2109V3.78947H16.0004Z" fill="#5C7080"
        />
        <path
          d="M12.2105 0H1.26316C0.565263 0 0 0.565263 0 1.26316V12.2105H3.78947V3.78947H12.2105V0Z" fill="#5C7080"
          fillOpacity="0.7"
        />
        <path
          d="M5.51681 10.3221C5.20208 10.1094 4.98418 9.79891 4.86523 9.38838L5.59576 9.08733C5.66208 9.33996 5.77786 9.53575 5.94313 9.6747C6.10734 9.81364 6.30734 9.88206 6.54103 9.88206C6.77997 9.88206 6.98523 9.80943 7.15681 9.66417C7.32839 9.51891 7.41471 9.33364 7.41471 9.10943C7.41471 8.87996 7.32418 8.69259 7.14313 8.54733C6.96207 8.40206 6.73471 8.32943 6.46313 8.32943H6.04103V7.60627H6.41997C6.65365 7.60627 6.8505 7.54312 7.0105 7.4168C7.1705 7.29049 7.2505 7.11785 7.2505 6.89785C7.2505 6.70206 7.17892 6.54627 7.03576 6.42943C6.8926 6.31259 6.71155 6.25364 6.49155 6.25364C6.27681 6.25364 6.10629 6.31048 5.97997 6.42522C5.85374 6.54026 5.75884 6.68552 5.70418 6.84733L4.98102 6.54627C5.07681 6.2747 5.2526 6.0347 5.5105 5.82733C5.76839 5.61996 6.09787 5.51575 6.49787 5.51575C6.79366 5.51575 7.05997 5.57259 7.29576 5.68733C7.53155 5.80206 7.71681 5.96101 7.8505 6.16312C7.98418 6.36627 8.0505 6.59364 8.0505 6.84627C8.0505 7.10417 7.98839 7.32206 7.86418 7.50101C7.73997 7.67996 7.58734 7.8168 7.40629 7.91259V7.95575C7.64007 8.05213 7.84314 8.21044 7.99366 8.41364C8.14629 8.6189 8.22313 8.86417 8.22313 9.15048C8.22313 9.4368 8.1505 9.69259 8.00523 9.9168C7.85997 10.141 7.65892 10.3179 7.40418 10.4463C7.14839 10.5747 6.86102 10.64 6.54208 10.64C6.1726 10.641 5.83155 10.5347 5.51681 10.3221ZM10.0042 6.6968L9.20208 7.2768L8.80102 6.66838L10.24 5.63049H10.7915V10.5263H10.0042V6.6968Z"
          fill="#5C7080"
        />
      </g>
      <defs>
        <clipPath id="clip0_158_13487">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),

  [SvgIconName.GoogleMeet]: (props) => (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <g clipPath="url(#clip0_158_13219)">
        <path
          d="M9.05127 8.5828L10.611 10.3657L12.7084 11.706L13.0741 8.59377L12.7084 5.55103L10.5708 6.72863L9.05127 8.5828Z"
          fill="currentColor"
        />
        <path
          d="M0 11.4171V14.0685C0 14.6747 0.490971 15.1657 1.09714 15.1657H3.74857L4.29714 13.1616L3.74857 11.4171L1.92914 10.8685L0 11.4171Z"
          fill="currentColor"
        />
        <path d="M3.74857 2L0 5.74857L1.92914 6.29714L3.74857 5.74857L4.288 4.02789L3.74857 2Z" fill="currentColor" />
        <path d="M3.74857 5.74854H0V11.4171H3.74857V5.74854Z" fill="white" />
        <path d="M3.74857 5.74854H0V11.4171H3.74857V5.74854Z" fill="currentColor" fillOpacity="0.7" />
        <path
          d="M15.104 3.58723L12.7085 5.55112V11.7061L15.1149 13.6791C15.4752 13.9607 16.0018 13.7038 16.0018 13.2457V4.01146C16.0018 3.54792 15.4633 3.29375 15.104 3.58723ZM9.05139 8.58289V11.4172H3.74854V15.1657H11.6114C12.2176 15.1657 12.7085 14.6748 12.7085 14.0686V11.7061L9.05139 8.58289Z"
          fill="white"
        />
        <path
          d="M15.104 3.58723L12.7085 5.55112V11.7061L15.1149 13.6791C15.4752 13.9607 16.0018 13.7038 16.0018 13.2457V4.01146C16.0018 3.54792 15.4633 3.29375 15.104 3.58723ZM9.05139 8.58289V11.4172H3.74854V15.1657H11.6114C12.2176 15.1657 12.7085 14.6748 12.7085 14.0686V11.7061L9.05139 8.58289Z"
          fill="currentColor" fillOpacity="0.7"
        />
        <path
          d="M11.6114 2H3.74854V5.74857H9.05139V8.58286L12.7085 5.55291V3.09714C12.7085 2.49097 12.2176 2 11.6114 2Z"
          fill="white"
        />
        <path
          d="M11.6114 2H3.74854V5.74857H9.05139V8.58286L12.7085 5.55291V3.09714C12.7085 2.49097 12.2176 2 11.6114 2Z"
          fill="currentColor" fillOpacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_158_13219">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),

  [SvgIconName.GoogleMeetBlue]: (props) => (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <g clipPath="url(#gGoogleMeetBlue)">
        <path
          d="m9.052 7.916 1.56 1.783 2.097 1.34.366-3.112-.366-3.043-2.138 1.178-1.52 1.854ZM0 10.75v2.652c0 .606.491 1.097 1.097 1.097H3.75l.548-2.004-.548-1.744-1.82-.549-1.93.549Zm3.749-9.417L0 5.082l1.93.548 1.819-.548.539-1.72-.54-2.029Z"
          fill="#5C7080"
        />
        <path d="M3.749 5.082H0v5.668h3.749V5.082Z" fill="#fff" />
        <path d="M3.749 5.082H0v5.668h3.749V5.082Z" fill="#5C7080" fillOpacity=".7" />
        <path
          d="M15.104 2.92 12.71 4.884v6.155l2.406 1.973c.36.282.887.025.887-.433V3.345a.55.55 0 0 0-.897-.425ZM9.053 7.916v2.834H3.749v3.75h7.863c.606 0 1.097-.491 1.097-1.097V11.04L9.052 7.916Z"
          fill="#fff"
        />
        <path
          d="M15.104 2.92 12.71 4.884v6.155l2.406 1.973c.36.282.887.025.887-.433V3.345a.55.55 0 0 0-.897-.425ZM9.053 7.916v2.834H3.749v3.75h7.863c.606 0 1.097-.491 1.097-1.097V11.04L9.052 7.916Z"
          fill="#5C7080" fillOpacity=".7"
        />
        <path d="M11.612 1.333H3.749v3.749h5.303v2.834l3.657-3.03V2.43c0-.606-.49-1.097-1.097-1.097Z" fill="#fff" />
        <path
          d="M11.612 1.333H3.749v3.749h5.303v2.834l3.657-3.03V2.43c0-.606-.49-1.097-1.097-1.097Z" fill="#5C7080"
          fillOpacity=".5"
        />
        <path
          d="m9.052 7.916 1.56 1.783 2.097 1.34.366-3.112-.366-3.043-2.138 1.178-1.52 1.854ZM0 10.75v2.652c0 .606.491 1.097 1.097 1.097H3.75l.548-2.004-.548-1.744-1.82-.549-1.93.549Zm3.749-9.417L0 5.082l1.93.548 1.819-.548.539-1.72-.54-2.029Z"
          fill="#2965CC"
        />
        <path d="M3.749 5.082H0v5.668h3.749V5.082Z" fill="#fff" />
        <path d="M3.749 5.082H0v5.668h3.749V5.082Z" fill="#2965CC" fillOpacity=".7" />
        <path
          d="M15.104 2.92 12.71 4.884v6.155l2.406 1.973c.36.282.887.025.887-.433V3.345a.55.55 0 0 0-.897-.425ZM9.053 7.916v2.834H3.749v3.75h7.863c.606 0 1.097-.491 1.097-1.097V11.04L9.052 7.916Z"
          fill="#fff"
        />
        <path
          d="M15.104 2.92 12.71 4.884v6.155l2.406 1.973c.36.282.887.025.887-.433V3.345a.55.55 0 0 0-.897-.425ZM9.053 7.916v2.834H3.749v3.75h7.863c.606 0 1.097-.491 1.097-1.097V11.04L9.052 7.916Z"
          fill="#2965CC" fillOpacity=".7"
        />
        <path d="M11.612 1.333H3.749v3.749h5.303v2.834l3.657-3.03V2.43c0-.606-.49-1.097-1.097-1.097Z" fill="#fff" />
        <path
          d="M11.612 1.333H3.749v3.749h5.303v2.834l3.657-3.03V2.43c0-.606-.49-1.097-1.097-1.097Z" fill="#2965CC"
          fillOpacity=".5"
        />
      </g>
      <defs>
        <clipPath id="gGoogleMeetBlue">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  ),

  [SvgIconName.Question]: (props) => (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16Zm0-2A6 6 0 1 0 8 2a6 6 0 0 0 0 12Z"
        fill="currentColor"
      />
      <path
        d="M9.132 13h-1.92v-1.92h1.92V13Zm1.852-6.257a4.079 4.079 0 0 1-.595.931L9.372 9c-.115.144-.23.278-.269.403a1.615 1.615 0 0 0-.086.499v.22h-1.69V9.047s.048-.49.202-.682l1.027-1.382c.211-.25.336-.47.422-.653a1.26 1.26 0 0 0 .115-.556c0-.288-.096-.528-.268-.72-.173-.182-.423-.269-.73-.269-.317 0-.566.096-.748.279-.183.182-.317.441-.384.777-.03.106-.096.144-.192.134l-1.632-.24c-.115-.01-.153-.076-.134-.182.115-.787.441-1.41.988-1.862C6.54 3.231 7.26 3 8.153 3c.45 0 .864.067 1.238.211s.69.326.96.566c.268.24.47.528.623.855.144.336.212.69.212 1.075 0 .383-.068.72-.202 1.036Z"
        fill="currentColor"
      />
    </svg>
  ),

  [SvgIconName.SmallDelete2]: (props) => (
    <svg
      width={8}
      height={8}
      viewBox="0 0 8 8"
      {...props}
    >
      <path
        d="M6 2.5a.501.501 0 0 0-.855-.355L4 3.295 2.855 2.15A.483.483 0 0 0 2.5 2a.501.501 0 0 0-.355.855L3.29 4 2.145 5.145a.501.501 0 0 0 .71.71L4 4.71l1.145 1.145a.501.501 0 0 0 .71-.71L4.71 4l1.145-1.145A.498.498 0 0 0 6 2.5ZM4 0C1.79 0 0 1.79 0 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4Zm0 7C2.345 7 1 5.655 1 4s1.345-3 3-3 3 1.345 3 3a3 3 0 0 1-3 3Z"
        fill="currentColor"
      />
    </svg>
  ),

  [SvgIconName.SmallTick2Empty]: (props) => (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        fill="currentColor"
      />
    </svg>
  ),

  [SvgIconName.SmallTick2Selected]: (props) => (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM11 5C10.72 5 10.47 5.11 10.29 5.29L7 8.58L5.71 7.29C5.53 7.11 5.28 7 5 7C4.45 7 4 7.45 4 8C4 8.28 4.11 8.53 4.29 8.71L6.29 10.71C6.47 10.89 6.72 11 7 11C7.28 11 7.53 10.89 7.71 10.71L11.71 6.71C11.89 6.53 12 6.28 12 6C12 5.45 11.55 5 11 5Z"
        fill="currentColor"
      />
    </svg>
  )
};
