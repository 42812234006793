import { Tooltip2 } from '@blueprintjs/popover2';
import { AllocationPositionSlot } from '@flow/common/components/elements/AllocationPositionSlot';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { CustomerAndTeam } from '@flow/common/components/elements/CustomerAndTeam';
import { BlankState } from '@flow/common/components/form/BlankState';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { AuthState } from '@flow/common/controllers/AuthState';
import { RoleController } from '@flow/common/controllers/RoleController';
import { DebugUtil } from '@flow/common/utils/DebugUtil';
import { PositionsUtil } from '@flow/common/utils/PositionsUtil';
import { StringUtil } from '@flow/common/utils/StringUtil';
import { Common_Customer_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { CandidateAllocationController } from '@flow/modules/recruiting/candidates/CandidateAllocationController';
import { CandidateFlowState } from '@flow/modules/recruiting/candidates/CandidateFlowState';
import { AllocationPositionInPanel } from '@flow/modules/recruiting/candidates/components/candidate/allocation/AllocationPositionInPanel';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { toJS } from 'mobx';
import React from 'react';
import { CandidateAllocationState } from '../../../CandidateAllocationState';
import { CandidateController } from '../../../CandidateController';
import { CandidatePositionGroupsState } from '../../../CandidatePositionGroupsState';
import { CandidateState } from '../../../CandidateState';

import styles from './AllocationPositions.module.less';

const cx = classNames.bind(styles);

@component
export class AllocationPositions extends React.Component
{
  @di private _authState!:AuthState;
  @di private _candidateState!:CandidateState;
  @di private _candidateFlowState!:CandidateFlowState;
  @di private _candidateAllocationState!:CandidateAllocationState;
  @di private _candidateAllocationController!:CandidateAllocationController;
  @di private _candidatePositionGroupsState!:CandidatePositionGroupsState;

  @di private _candidateController!:CandidateController;

  @di private _roleController!:RoleController;

  // ---------------------------------------------------------

  @bind
  private _renderPositions():React.ReactNode | null
  {
    const { inPanelAutoComputedPositions, isShowOnlyOpenPositions } = this._candidateAllocationState;
    const { isCandidateInArchivedStatus } = this._candidateFlowState;

    const autoComputedDivs = inPanelAutoComputedPositions
      .map((position:Recruiting_Position_Ex, index:number, positions:Array<Recruiting_Position_Ex>) =>
      {
        const prevPosition:Recruiting_Position_Ex | null = index ? positions[index - 1] : null;

        return (
          <React.Fragment key={position.id}>
            {
              // customer alias & team name
              prevPosition?.customer_team?.id !== position.customer_team?.id &&
              <CustomerAndTeam
                className={styles.customerAndTeam}
                items={[
                  position.customer_team?.customer.alias,
                  position.customer_team?.name
                ]}
              />
            }
            <AllocationPositionInPanel
              className={styles.positionItem}
              position={position}
              isProposeDisabled={isCandidateInArchivedStatus}
            />
          </React.Fragment>
        );
      });

    const hasAutoComputed:boolean = autoComputedDivs.length !== 0;

    if( hasAutoComputed ) return autoComputedDivs;

    return (
      <BlankState
        className={styles.noPositions}
        title={`No ${isShowOnlyOpenPositions ? 'open' : 'available'} positions`}
        withBorder={true}
      />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderFinalAllocation():React.ReactNode | null
  {
    const { user } = this._authState;
    const { candidate } = this._candidateState;
    const { currentFinalSlot, finalizedAllocation } = this._candidateAllocationState;

    if( !candidate || !user || !user.id )
    {
      // TODO: error message ?!
      return null;
    }

    console.log('%c currentFinalSlot =', 'background:#0f0;color:#000;', toJS(currentFinalSlot));
    console.log('%c finalizedAllocation =', 'background:#0f0;color:#000;', toJS(finalizedAllocation));

    if( finalizedAllocation && !currentFinalSlot )
    {
      return (
        <div className={styles.error}>
          <Icon
            className={styles.isTeamErrorIcon}
            icon={IconNames.WARNING_SIGN}
            size={16}
          />
          <div className={styles.errorText}>
            has final allocation <br />
            but NO final slot
          </div>
        </div>
      );
    }

    if( !currentFinalSlot || !finalizedAllocation ) return null;

    const myId:number = this._authState.user?.id || 0;
    const myNote:boolean = finalizedAllocation.user_id === myId;

    const { position } = currentFinalSlot;

    const customerAlias:string = position.customer_team?.customer.alias;
    const teamName:string = position.customer_team?.name;
    const positionName:string = PositionsUtil.getPositionName(position);

    const isTeamError:boolean = !position.customer_team.is_active ||
      position.customer_team.deleted_at ||
      position.customer_team.customer.status !== Common_Customer_Status_Enum.Current;

    const items = [];

    if( isTeamError )
    {
      items.push((
        <Tooltip2
          content={
            <>
              {
                position.customer_team.customer.status !== Common_Customer_Status_Enum.Current &&
                <div>Customer is NOT ACTIVE</div>
              }
              {
                !position.customer_team.is_active &&
                <div>Team is NOT ACTIVE</div>
              }
              {
                position.customer_team.deleted_at &&
                <div>Team DELETED</div>
              }
            </>
          }
          placement={'top'}
          usePortal={true}
        >
          <Icon
            className={styles.isTeamErrorIcon}
            icon={IconNames.WARNING_SIGN}
            size={16}
          />
        </Tooltip2>
      ));
    }

    items.push(`${DebugUtil.id(position.customer_team?.customer.id)}${customerAlias}`);
    items.push(`${DebugUtil.id(position.customer_team?.id)}${teamName}`);
    items.push(`${DebugUtil.id(position.id)}${positionName}`);

    return (
      <>
        <CustomerAndTeam
          className={cx(styles.customerAndTeam, { isTeamError })}
          lastTextClassName={styles.positionName}
          items={items}
        />
        <AllocationPositionSlot
          slot={currentFinalSlot}
          finalizedCandidate={candidate}
        />
        <div className={styles.finalizedBy}>
          <Avatar
            className={styles.finalizedByAvatar}
            size={16}
            url={finalizedAllocation.user.avatar}
          />
          Finalized by {myNote ? 'you' : StringUtil.getUserName(finalizedAllocation.user)}
        </div>
        {
          finalizedAllocation.notes &&
          <div className={cx(styles.allocationNotes)}>
            {finalizedAllocation.notes}
          </div>
        }
      </>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { candidate, isCandidateLoaded } = this._candidateState;

    if( !isCandidateLoaded ) return null;

    const { isFinalized } = this._candidateAllocationState;

    const isStaffCreated:boolean = !!(candidate && candidate.staff_id);

    return (
      <div className={styles.wrapper}>
        {
          !isStaffCreated &&
          <div className={styles.scrolled}>
            {
              isFinalized &&
              this._renderFinalAllocation()
            }
            {
              !isFinalized &&
              this._renderPositions()
            }
          </div>
        }
      </div>
    );
  }

  // ---------------------------------------------------------
}
