export class ConfigUtil
{
  public static isGoogleLoginDisabled():boolean
  {
    return !!process.env.NX_FLOW_DISABLE_GOOGLE_LOGIN && process.env.NX_FLOW_DISABLE_GOOGLE_LOGIN.toLowerCase() === 'true';
  }

  public static getHasuraAdminSecret():string
  {
    return process.env.NX_FLOW_HASURA_ADMIN_SECRET || '';
  }

  public static getHasuraRole():string
  {
    return process.env.NX_FLOW_HASURA_ROLE ? process.env.NX_FLOW_HASURA_ROLE : 'admin';
  }

  public static getDevJWTToken():string
  {
    return process.env.NX_FLOW_DEV_ACCESS_TOKEN || ''
  }

  public static isUseLocalGoogleLogin():boolean
  {
    return !!process.env.NX_USE_LOCAL_GOOGLE_LOGIN && process.env.NX_USE_LOCAL_GOOGLE_LOGIN.toLowerCase() === 'true';
  }

  public static getLocalUserId():number
  {
    return parseInt(process.env.NX_LOCAL_USER_ID || '0') || 0;
  }

  public static getOrganizationDomain():string
  {
    return process.env.NX_FLOW_ORGANIZATION_DOMAIN || 'siliconmint.com';
  }

  public static isDebugMode():boolean
  {
    return !!process.env.NX_DEBUG_MODE && process.env.NX_DEBUG_MODE.toLowerCase() === 'true';
  }
}
