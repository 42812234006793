import { AllocationPositionSlot } from '@flow/common/components/elements/AllocationPositionSlot';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { PositionItem } from '@flow/common/components/elements/PositionItem';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { AuthState } from '@flow/common/controllers/AuthState';
import { SlotUtil } from '@flow/common/utils/SlotUtil';
import { StringUtil } from '@flow/common/utils/StringUtil';
import type {
  Recruiting_Candidate,
  Recruiting_Position,
  Staffing_Customer_Team_Slot
} from '@flow/data-access/lib/types/graphql.generated';
import {
  Recruiting_Allocation_Position,
  Recruiting_Candidate_Allocation,
  Staffing_Customer_Team_Slot_Status_Enum,
  Staffing_Staff
} from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { action, computed, runInAction, toJS } from 'mobx';
import React from 'react';

import styles from './AllocationPositionInDialog.module.less';

const cx = classNames.bind(styles);

interface Props
{
  className:string;
  innerClassName?:string;

  position:Recruiting_Position_Ex;
  isEditPosition?:boolean;
  editedPositionCurrentSlot?:Staffing_Customer_Team_Slot;

  candidate?:Recruiting_Candidate | null;
  staffMember?:Staffing_Staff | null;

  selectedPositionId:number | null;
  selectedSlot:Staffing_Customer_Team_Slot | null;
  selectedDate:string | null;
  proposedAllocations?:Array<Recruiting_Candidate_Allocation>;
  currentFinalSlots?:Array<Staffing_Customer_Team_Slot>;

  positionById:(id:number) => Recruiting_Position_Ex | undefined;
  isPositionProposed?:(positionId:number) => boolean;
  onSelectPosition:(positionId:number, positionItemRef:React.RefObject<PositionItem>) => void;

  onSelectSlot:(slot:Staffing_Customer_Team_Slot) => void;
  onSelectDate:(newDate:string | null) => void;
  onSelectPositionItemRef:(selectedPositionItemRef:React.RefObject<PositionItem> | null) => void;
}

@component
export class AllocationPositionInDialog extends React.Component<Props>
{
  @di private _authState!:AuthState;
  // @di private _candidateState!:CandidateState;
  // @di private _candidateAllocationState!:CandidateAllocationState;
  // @di private _candidateAllocationController!:CandidateAllocationController;

  private _positionItemRef:React.RefObject<PositionItem> = React.createRef();

  // ---------------------------------------------------------

  @computed
  private get isCandidateView():boolean
  {
    return !!this.props.candidate;
  }

  @computed
  private get isStaffView():boolean
  {
    return !!this.props.staffMember;
  }

  // ---------------------------------------------------------

  public componentDidMount():void
  {
    const { position, selectedPositionId, onSelectPositionItemRef } = this.props;

    // console.log('%c -------------------------- =', 'background:#0f0;color:#000;');
    // console.log('%c componentDidMount =', 'background:#0f0;color:#000;');
    // console.log('%c position =', 'background:#080;color:#000;', toJS(position));
    // console.log('%c selectedPositionId =', 'background:#080;color:#000;', toJS(selectedPositionId));
    // console.log('%c this._positionItemRef.current =', 'background:#080;color:#000;', toJS(this._positionItemRef.current));

    if( selectedPositionId === position.id )
    {
      // this._candidateAllocationState.selectedPositionItemRef = this._positionItemRef;
      onSelectPositionItemRef(this._positionItemRef);
      this._positionItemRef.current?.setHiddenBlockVisibility(true);
    }
  }

  // ---------------------------------------------------------

  @action.bound
  private _selectSlot(position:Recruiting_Position, slot:Staffing_Customer_Team_Slot):void
  {
    const { selectedSlot, onSelectSlot } = this.props;

    // if( editedFinalSlots.some(editedSlot => editedSlot.id == slot.id) ) return;
    if( selectedSlot?.id === slot.id ) return;

    // this._candidateAllocationState.editedFinalSlots = [slot];
    // this._candidateAllocationState.selectedSlot = Object.assign({}, slot);
    onSelectSlot(slot);
  }

  // ---------------------------------------------------------

  @bind
  private _renderSlots(position:Recruiting_Position_Ex):React.ReactNode | null
  {
    console.log('%c ---------------------------- =', 'background:#0f0;color:#000;');
    const {
      selectedPositionId, currentFinalSlots, selectedDate,
      candidate, staffMember,
      isEditPosition, editedPositionCurrentSlot
    } = this.props;

    if( !candidate && !staffMember ) return null;

    const isPositionSelected:boolean = selectedPositionId === position.id;

    console.log('%c position =', 'background:#0f0;color:#000;', position.id, toJS(position));
    console.log('%c isPositionSelected =', 'background:#080;color:#000;', isPositionSelected);

    const selectedSlot:Staffing_Customer_Team_Slot | undefined | null = isPositionSelected
      ? this.props.selectedSlot
      : undefined;

    console.log('%c --- selectedSlot =', 'background:#080;color:#000;', toJS(selectedSlot));

    let hasOnlyCurrentStaffSlotWhenEdit:boolean = false;

    const sorted_customer_team_slots:Array<Staffing_Customer_Team_Slot> = position.customer_team_slots
      .filter((slot:Staffing_Customer_Team_Slot) =>
      {
        console.log('%c slot.staff.id !== staffMember?.id =', 'background:#0f0;color:#000;', slot.id, slot.staff?.id !== staffMember?.id);

        if( this.isCandidateView )
        {
          return (!slot.next_staff && !slot.next_candidate_id) ||
            slot.next_candidate_id === candidate?.id;
        }

        if( this.isStaffView )
        {
          // open slot
          if( !slot.staff && !slot.next_staff && !slot.next_candidate_id )
          {
            console.log('%c --> OK 1 =', 'background:#88f;color:#000;');
            return true;
          }

          if( !hasOnlyCurrentStaffSlotWhenEdit )
          {
            hasOnlyCurrentStaffSlotWhenEdit = !!isEditPosition &&
              !slot.next_candidate_id &&
              !slot.next_staff &&
              !!slot.staff && slot.staff?.id === staffMember?.id;

            if( hasOnlyCurrentStaffSlotWhenEdit )
            {
              console.log('%c --> OK 2 =', 'background:#88f;color:#000;');
              return true;
            }
          }

          const ok3:boolean = (!!slot.staff && !slot.next_staff && !slot.next_candidate_id) ||
            (!!isEditPosition && !!slot.staff && !!slot.next_candidate_id && slot.staff?.id === staffMember?.id) ||
            (!!isEditPosition && !!slot.staff && !!slot.next_staff && (slot.staff?.id === staffMember?.id || slot.next_staff_id === staffMember?.id));

          if( ok3 )
          {
            console.log('%c --> OK 3 =', 'background:#88f;color:#000;');
          }

          return ok3;
        }

        console.log('%c --> FALSE 1 =', 'background:#88f;color:#000;');
        return false;
      })
      .sort((a:Staffing_Customer_Team_Slot, b:Staffing_Customer_Team_Slot) =>
      {
        if( editedPositionCurrentSlot && b.id === editedPositionCurrentSlot.id ) return 1;

        return b.id - a.id;
      })
      .sort((slot:Staffing_Customer_Team_Slot) =>
      {
        return slot.status == Staffing_Customer_Team_Slot_Status_Enum.Open ? -1 : 0;
      });

    let anyOpenSlot:Staffing_Customer_Team_Slot | undefined = hasOnlyCurrentStaffSlotWhenEdit
      ? undefined
      : sorted_customer_team_slots //position.customer_team_slots
        .find((slot:Staffing_Customer_Team_Slot) =>
        {
          return slot.status === Staffing_Customer_Team_Slot_Status_Enum.Open;
        });

    const currentFinalSlot:Staffing_Customer_Team_Slot | undefined = currentFinalSlots?.[0];

    if( this.isCandidateView && selectedSlot && anyOpenSlot && currentFinalSlot && selectedSlot.id === currentFinalSlot.id
      && currentFinalSlot.status === Staffing_Customer_Team_Slot_Status_Enum.Open
    )
    {
      anyOpenSlot = currentFinalSlots?.[0];
    }

    const noAvailableSlots:boolean = !anyOpenSlot && sorted_customer_team_slots.length === 0;

    selectedSlot && sorted_customer_team_slots.forEach(slot =>
    {
      console.log('%c --- slot =', 'background:#ff0;color:#000;', toJS(slot));
      // TODO: moved to -> CandidateAllocationController: line: 175
      // if( !slot.position ) slot.position = position;
    });

    console.log('%c hasOnlyStaffSlotWhenEdit =', 'background:#080;color:#000;', toJS(hasOnlyCurrentStaffSlotWhenEdit));
    console.log('%c anyOpenSlot =', 'background:#080;color:#000;', toJS(anyOpenSlot));

    return (
      <>
        {
          noAvailableSlots &&
          <div className={styles.allSlotsAreBusy}>
            <Icon icon={IconNames.INFO_SIGN} size={20} className={styles.icon} />
            All slots are busy
          </div>
        }
        {sorted_customer_team_slots
          .map((slot:Staffing_Customer_Team_Slot) =>
          {
            const isSelected:boolean = selectedSlot?.id === slot.id;

            if( slot.status == Staffing_Customer_Team_Slot_Status_Enum.Open && anyOpenSlot?.id !== slot.id )
              return null;

            // console.log('%c slot        =', 'background:#080;color:#000;', toJS(slot));

            return (
              <React.Fragment key={slot.id}>
                <AllocationPositionSlot
                  slot={slot}
                  inDialog={true}
                  isSelected={isSelected}
                  finalizedCandidate={candidate || undefined}
                  staffMember={staffMember || undefined}
                  selectedDate={selectedDate}

                  onChangeDate={(newDate:string | null):void =>
                  {
                    runInAction(() =>
                    {
                      // this._candidateAllocationState.selectedDate = newDate;
                      this.props.onSelectDate(newDate);
                    });
                  }}
                  // onChange={(changedSlot:Staffing_Customer_Team_Slot):void =>
                  // {
                  //   //   const slotIndex = editedFinalSlots.findIndex(slot => slot.id == changedSlot.id);
                  //   //   if( slotIndex > -1 )
                  //   //   {
                  //   //     runInAction(() => editedFinalSlots.splice(slotIndex, 1, changedSlot));
                  //   //   }
                  //   console.log('%c changedSlot =', 'background:#0f0;color:#000;', toJS(changedSlot));
                  //   runInAction(() => this._candidateAllocationState.selectedSlot = changedSlot);
                  // }}
                  onClick={():void =>
                  {
                    console.log('%c onClick =', 'background:#0f0;color:#000;', toJS(slot));
                    this._selectSlot(position, slot);
                  }}
                />
              </React.Fragment>
            );
          })
        }
      </>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderProposedUserAvatars(position:Recruiting_Position_Ex):React.ReactNode | null
  {
    const { proposedAllocations, positionById } = this.props;

    let avatarsCount:number = 0;
    let lastAvatar:React.ReactNode | null = null;

    const myId:number = this._authState.user?.id || 0;

    return (
      <>
        {proposedAllocations?.map((allocation:Recruiting_Candidate_Allocation/*, index:number */) =>
        {
          const { user, allocation_positions } = allocation;
          const { avatar, staffs } = user;

          const myNote:boolean = user.id === myId;

          const renderedPositions = allocation_positions
            .filter((allocation_position:Recruiting_Allocation_Position) =>
            {
              if( allocation_position.position_id !== position.id ) return false;

              const fullPosition = positionById(Number(allocation_position.position_id));

              const isPositionVisible:boolean | undefined = fullPosition && fullPosition.customer_team_slots.every(slot =>
              {
                // TODO: moved to -> CandidateAllocationController: line: 175
                // if( !slot.position ) runInAction(() => slot.position = fullPosition);
                return SlotUtil.isSlotVisible(slot);
              });

              return isPositionVisible;
            });

          if( !renderedPositions.length ) return null;

          lastAvatar = (
            <Avatar
              key={allocation.id}
              className={styles.proposedUserAvatar}
              size={25}
              url={avatar || staffs?.[0].avatar_url}
              tooltip={`Proposed by ${myNote ? 'you' : StringUtil.getUserName(user)}`}
            />
          );

          if( ++avatarsCount > 2 ) return null;

          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return lastAvatar;
        })
        }
        {avatarsCount === 3 && lastAvatar}
        {
          avatarsCount > 3 &&
          <Avatar
            key={'text'}
            className={styles.proposedUserAvatar}
            size={25}
            text={`+${avatarsCount - 2}`}
          />
        }
      </>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderHiddenBlock(position:Recruiting_Position_Ex/*, isOpened?:boolean */):React.ReactNode | null
  {
    const renderedSlots = this._renderSlots(position);

    if( !renderedSlots ) return null;

    return (
      <div className={styles.hiddenBlock}>
        {renderedSlots}
      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderActionsBlock(position:Recruiting_Position_Ex/*, isOpened?:boolean */):React.ReactNode | null
  {
    if( this.isStaffView ) return null;

    // this.isCandidateView
    const isProposed:boolean = !!this.props.isPositionProposed?.(position.id);

    return (
      <div className={styles.actionsBlock}>
        {
          isProposed &&
          <div className={styles.proposedUserAvatars}>
            {this._renderProposedUserAvatars(position)}
          </div>
        }
      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _onClickPosition(position:Recruiting_Position_Ex, positionItemRef:React.RefObject<PositionItem>):(e:React.MouseEvent<HTMLElement>) => void
  {
    return (/* e:React.MouseEvent<HTMLElement> */):void =>
    {
      this.props.onSelectPosition(position.id, positionItemRef);
      // this._candidateAllocationController.setSelectedPositionId(position.id, positionItemRef);
    };
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { position, className, innerClassName, selectedPositionId } = this.props;

    return (
      <PositionItem
        className={cx(styles.positionItem, className)}
        innerClassName={innerClassName}
        position={position}
        actionsBlock={this._renderActionsBlock(position as Recruiting_Position_Ex)}
        hiddenBlock={this._renderHiddenBlock(position as Recruiting_Position_Ex)}
        ref={this._positionItemRef}

        inDialog={true}

        canSelect={true}
        selected={selectedPositionId === position.id}
        onClick={this._onClickPosition(position as Recruiting_Position_Ex, this._positionItemRef)}
      />
    );
  }

  // ---------------------------------------------------------
}
