import { PageUtil } from '@flow/common/utils/PageUtil';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di, diModule } from '@flow/dependency-injection';
import { NewEditPositionGroupDialog } from '@flow/modules/settings/settingsPositionGroups/components/NewEditPositionGroupDialog';
import { SettingsPositionGroupsList } from '@flow/modules/settings/settingsPositionGroups/components/SettingsPositionGroupsList';
import { SettingsPositionGroupsToolbar } from '@flow/modules/settings/settingsPositionGroups/components/SettingsPositionGroupsToolbar';
import bind from 'bind-decorator';
import React from 'react';
import { SettingsPositionGroupsController } from './SettingsPositionGroupsController';
import { SettingsPositionGroupsState } from './SettingsPositionGroupsState';

@diModule({
  providers: [
    SettingsPositionGroupsState,
    SettingsPositionGroupsController
  ]
})
@componentWithRouter
export class SettingsPositionGroups extends React.Component<IWithRouterProps>
{
  @di private _settingsPositionGroupsState!:SettingsPositionGroupsState;
  @di private _settingsPositionGroupsController!:SettingsPositionGroupsController;

  // ----------------------------------------------------

  public componentDidMount():void
  {
    this._settingsPositionGroupsController.initFromLocalStorage();
    this._checkPageProps();
  }

  // ----------------------------------------------------

  public componentDidUpdate(prevProps:Readonly<IWithRouterProps>):void
  {
    if( prevProps.params?.xxx !== this.props.params?.xxx )
    {
      this._checkPageProps();
    }
  }

  // ----------------------------------------------------

  @bind
  private _checkPageProps():void
  {
    this._settingsPositionGroupsController.initPositionGroupsData();
  }

  // ----------------------------------------------------

  public componentWillUnmount():void
  {
    PageUtil.disposeReactions(this._settingsPositionGroupsState.disposers);
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    return (
      <>
        <SettingsPositionGroupsToolbar />
        <SettingsPositionGroupsList />

        <NewEditPositionGroupDialog />
      </>
    );
  }
}
