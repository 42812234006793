export type CookieKey = string;
export type CookieValue = string;

export class CookieUtil
{
  public static readonly FLOW_AUTH_COOKIE_KEY = 'flow_session_token';
  public static readonly FLOW_NEEDS_REFRESH_TOKEN = 'flow_request_refresh_token';

  public static setCookie(domain:string, key:CookieKey, value:CookieValue):void
  {
    if( typeof (document) === 'undefined' )
      return;

    const cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 1);

    value = encodeURIComponent(value);

    document.cookie = `${key}=${value};expires=${cookieDate.toString()};domain=${domain};path=/`;
  }

  public static clearCookie(domain:string, key:CookieKey):void
  {
    if( typeof (document) === 'undefined' )
      return;

    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${domain};path=/`;
  }

  public static getCookie(key:CookieKey):CookieValue | null
  {
    if( typeof (document) === 'undefined' )
      return null;

    const result = new RegExp('(?:^|; )' + encodeURIComponent(key) + '=([^;]*)').exec(document.cookie);

    return result ? (decodeURIComponent(result[1])) : null;
  }
}
