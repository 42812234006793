import { IconNames } from '@blueprintjs/icons';
import { CommonController } from '@flow/common/CommonController';
import { NeedsPermission } from '@flow/common/components/NeedsPermission';
import { Toolbar } from '@flow/common/components/page/Toolbar';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { RouteName } from '@flow/common/models/routing/RouteName';
import { component, di } from '@flow/dependency-injection';
import { DashboardCard } from '@flow/modules/dashboard/DashboardCard';
import React from 'react';
import styles from './Home.module.less';
import { RoutesConfig } from './RoutesConfig';

@component
export class Home extends React.Component<unknown, unknown>
{
  @di private _commonController!:CommonController;

  private _navigateTo(routeName:RouteName):void
  {
    this._commonController.navigate(String(RoutesConfig.getRoutePath(routeName)))
  }

  public render():React.ReactNode
  {
    return (
      <div>

        <Toolbar
          className={styles.toolbar}
          title={'Dashboard'}
        />

        <div className={styles.cardWrapper}>

          <NeedsPermission name={FlowPermissions.EditCustomer}>
            <DashboardCard
              title={'Customers'}
              iconName={IconNames.BRIEFCASE}
              content={'The customers and the customer teams can be found here'}
              buttonText={'Customers'}
              onButtonClick={():void => this._navigateTo(RouteName.CUSTOMERS)}
            />
          </NeedsPermission>

          <NeedsPermission name={FlowPermissions.ViewRecruitingBoardPage}>
            <DashboardCard
              title={'Recruiting'}
              iconName={IconNames.MUGSHOT}
              content={'Management of the recruiting processes, candidates, priorities and positions'}
              buttonText={'Recruiting'}
              onButtonClick={():void => this._navigateTo(RouteName.RECRUITING_BOARDS)}
            />
          </NeedsPermission>

          <NeedsPermission name={FlowPermissions.ViewStaffPages}>
            <DashboardCard
              title={'Staffing'}
              iconName={IconNames.PEOPLE}
              content={'Staff management and the staff board'}
              buttonText={'Staffing'}
              onButtonClick={():void => this._navigateTo(RouteName.STAFFING_STAFF)}
            />
          </NeedsPermission>

        </div>

      </div>
    );
  }
}
