import type {
  IFilterCheckboxesItemValueType,
  IFilterCheckboxesValue
} from '@flow/common/components/filters/checkboxes/FilterCheckboxes';
import { FilterType, IFilter, IFilterValue } from '@flow/common/components/filters/Filter';
import type { Maybe } from '@flow/common/models/Types';
import { SortUtil } from '@flow/common/utils/SortUtil';
import type {
  Common_Customer,
  Common_User,
  GoogleCalendarEvent,
  Recruiting_Candidate,
  Recruiting_Candidate_Allocation,
  Recruiting_Candidate_Position_Group,
  Recruiting_Interview_Flow,
  Recruiting_Interview_Flow_Stage,
  Recruiting_Position,
  Recruiting_Position_Group,
  Staffing_Staff
} from '@flow/data-access/lib/types/graphql.generated';
import { Staffing_Customer_Team_Slot } from '@flow/data-access/lib/types/graphql.generated';
import { di, state } from '@flow/dependency-injection';
import { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { InterviewFlowsFiltersState } from '@flow/modules/recruiting/status/InterviewFlowsFiltersState';
import type { IReactionDisposer } from 'mobx';
import { computed, observable, toJS } from 'mobx';
import { computedFn } from 'mobx-utils';
import type { Moment } from 'moment';
import moment from 'moment';
import type { IInterviewFlowGroup, IInterviewFlowGroupStage } from './models/InterviewFlowGroup';

export const InterviewFlowsState_LS_KEY:string = 'interviewFlowsState';
export const InterviewFlowsUrl_LS_KEY:string = 'interviewFlowsUrl';

// TODO: remove
const __DEBUG:boolean = false;

@state
export class InterviewFlowsState
{
  @di private _interviewFlowsFiltersState!:InterviewFlowsFiltersState;

  // ----------------------------------------------------

  public disposers:Array<IReactionDisposer> = [];

  // ----------------------------------------------------

  @observable public interviewFlows:Array<Recruiting_Interview_Flow> = [];
  @observable public interviewFlowsInitPromise:Promise<void> | null = null;

  @observable public customers:Array<Common_Customer> = [];
  @observable public positionGroups:Array<Recruiting_Position_Group> = [];
  @observable public users:Array<Common_User> = [];

  @observable public candidateEvents:Array<GoogleCalendarEvent> = [];

  @observable public selectedInterviewFlowId:number | undefined = undefined;

  // TODO: remove
  @observable public selectedCustomerId:number | undefined = undefined;
  @observable public selectedPositionGroupId:number | undefined = undefined;
  @observable public selectedRecruiterId:number | undefined = undefined;

  // TODO: remove
  @observable public groupByCustomer:boolean = false;
  @observable public groupByPositionGroup:boolean = false;

  @observable public interviewFlowCandidates:Array<Recruiting_Candidate> = [];

  @observable public draggingInterviewFlowGroupId:string = '';
  @observable public draggingCandidateId:number = -1;
  @observable public dropInterviewFlowStageId:number = -1;
  @observable public dropInterviewFlowIndex:number = -1;

  @observable public scrollLeftX:number = 0;
  @observable public scrollBarWidth:number = 0;

  // ----------------------------------------------------

  @computed
  public get selectedInterviewFlow():Recruiting_Interview_Flow | undefined
  {
    return this.interviewFlows && this.interviewFlows.find(
      (flow:Recruiting_Interview_Flow) => flow.id === this.selectedInterviewFlowId
    );
  }

  // ----------------------------------------------------

  @computed
  public get customersForFilter():Array<Common_Customer | null>
  {
    const externalCustomers:Array<Common_Customer> = this.customers.filter(
      (customer:Common_Customer) => !customer.is_internal
    );

    const internalCustomers:Array<Common_Customer> = this.customers.filter(
      (customer:Common_Customer) => customer.is_internal
    );

    const customersList:Array<Common_Customer | null> = [...externalCustomers];

    if( internalCustomers.length ) customersList.push(null, ...internalCustomers);

    return customersList;
  }

  // ----------------------------------------------------

  // @computed
  // public get interviewFlowGroups():Array<IInterviewFlowGroup>
  // {
  //   const interviewFlowGroups:Map<string, IInterviewFlowGroup> = new Map<string, IInterviewFlowGroup>();
  //
  //   if( this.interviewFlowCandidates.length )
  //   {
  //     __DEBUG && console.log('%c interviewFlowGroups =', 'background:#0ff;color:#000;');
  //     __DEBUG && console.groupCollapsed('interviewFlowGroups');
  //
  //     this.interviewFlowCandidates.forEach((candidate:Recruiting_Candidate):void =>
  //     {
  //       // __DEBUG && console.log('candidate.staff.created_at', candidate.staff?.created_at);
  //       // __DEBUG && console.log('candidate.customer_team_slots.staff.created_at', candidate.customer_team_slots[0]?.staff?.created_at);
  //
  //       const staff:Maybe<Staffing_Staff> = candidate.staff;
  //
  //       let isUpdatedInTwoWeeks:boolean = false;
  //
  //       if( staff )
  //       {
  //         const staffingDate:Moment | null = staff ? moment(staff.created_at) : null;
  //         const twoWeeksAgo:Moment = moment().subtract(2, 'week');
  //
  //         isUpdatedInTwoWeeks = twoWeeksAgo.isBefore(staffingDate);
  //
  //         if( !isUpdatedInTwoWeeks )
  //         {
  //           __DEBUG && console.log('%c candidate =', 'background:#f66;color:#000;', candidate.id, toJS(candidate));
  //           __DEBUG && console.log('%c --> staff =', 'background:#000;color:#fff;', toJS(staff));
  //         }
  //       }
  //
  //       if( !staff || isUpdatedInTwoWeeks )
  //       {
  //         __DEBUG && console.log('%c candidate =', 'background:#0f0;color:#000;', candidate.id, toJS(candidate));
  //
  //         if( isUpdatedInTwoWeeks )
  //         {
  //           __DEBUG && console.log('%c --> staff =', 'background:#080;color:#000;', toJS(staff));
  //           __DEBUG && console.log('%c --> isUpdatedInTwoWeeks =', 'background:#080;color:#000;', toJS(isUpdatedInTwoWeeks));
  //         }
  //
  //         candidate.candidate_position_groups.forEach((cpg:Recruiting_Candidate_Position_Group) =>
  //         {
  //           cpg.position_group.positions.forEach((position:Recruiting_Position) =>
  //           {
  //             // __DEBUG && console.log('%c --> position =', 'background:#080;color:#000;', toJS(position));
  //
  //             if( (this.selectedCustomerId != null && position.customer_team?.customer?.id !== this.selectedCustomerId)
  //               || (this.selectedPositionGroupId != null && cpg.position_group.id !== this.selectedPositionGroupId)
  //               || (this.selectedRecruiterId != null && candidate.assigned_user_id !== this.selectedRecruiterId) )
  //               return;
  //
  //             const interviewFlowGroupId:string = `${this.groupByCustomer ? position.customer_team?.customer?.id : 0}_${this.groupByPositionGroup ? cpg.position_group.id : 0}`;
  //
  //             // __DEBUG && console.log('%c --> interviewFlowGroupId =', 'background:#080;color:#000;', interviewFlowGroupId);
  //
  //             if( !interviewFlowGroups.has(interviewFlowGroupId) )
  //             {
  //               interviewFlowGroups.set(interviewFlowGroupId, {
  //                 id: interviewFlowGroupId,
  //                 customerName: position.customer_team?.customer?.alias || '',
  //                 positionGroupName: cpg.position_group.name,
  //                 stages: this.selectedInterviewFlow?.stages.map(
  //                   (stage:Recruiting_Interview_Flow_Stage) => ({
  //                     id: stage.id,
  //                     name: stage.name,
  //                     candidates: observable([])
  //                   })
  //                 ) || []
  //               });
  //             }
  //
  //             // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //             const interviewFlowGroup:IInterviewFlowGroup = interviewFlowGroups.get(interviewFlowGroupId)!;
  //
  //             const stage:IInterviewFlowGroupStage | undefined = interviewFlowGroup.stages.find(
  //               (stage:IInterviewFlowGroupStage) => stage.id === candidate.interview_flow_stage_id
  //             );
  //
  //             if( stage && !stage.candidates.includes(candidate) )
  //               stage.candidates.push(candidate);
  //           });
  //         });
  //       }
  //     });
  //     __DEBUG && console.groupEnd();
  //   }
  //
  //   // __DEBUG && console.log('%c interviewFlowGroups =', 'background:#0f0;color:#000;', toJS(interviewFlowGroups));
  //
  //   return Array.from(interviewFlowGroups.values())
  //     .sort(
  //       (ifg1:IInterviewFlowGroup, ifg2:IInterviewFlowGroup):number =>
  //       {
  //         const ifg1Key:string = `${ifg1.customerName}_${ifg1.positionGroupName}`;
  //         const ifg2Key:string = `${ifg2.customerName}_${ifg2.positionGroupName}`;
  //
  //         if( ifg1Key > ifg2Key )
  //           return 1;
  //         if( ifg1Key < ifg2Key )
  //           return -1;
  //
  //         return 0;
  //       }
  //     );
  // }

  @computed
  public get interviewFlowGroups():Array<IInterviewFlowGroup>
  {
    const interviewFlowGroups:Map<string, IInterviewFlowGroup> = new Map<string, IInterviewFlowGroup>();

    if( !this.interviewFlowCandidates.length ) return [];

    __DEBUG && console.log('%c interviewFlowGroups =', 'background:#0ff;color:#000;');
    __DEBUG && console.groupCollapsed('interviewFlowGroups');

    const interviewFlowGroupId:string = '0_0';

    if( !interviewFlowGroups.has(interviewFlowGroupId) )
    {
      interviewFlowGroups.set(interviewFlowGroupId, {
        id: interviewFlowGroupId,
        customerName: '',
        positionGroupName: '',
        stages: this.selectedInterviewFlow?.stages.map(
          (stage:Recruiting_Interview_Flow_Stage) => ({
            id: stage.id,
            name: stage.name,
            candidates: observable([])
          })
        ) || []
      });
    }

    const interviewFlowGroup:IInterviewFlowGroup = interviewFlowGroups.get(interviewFlowGroupId)!;

    // ------------------------------

    let filterCustomerIds:Array<IFilterCheckboxesItemValueType> = [];
    let filterPositionGroupIds:Array<IFilterCheckboxesItemValueType> = [];
    let filterRecruiterIds:Array<IFilterCheckboxesItemValueType> = [];

    this._interviewFlowsFiltersState.filters.forEach((filter:IFilter<IFilterValue>) =>
    {
      switch( filter.type )
      {
        case FilterType.FLOWS_CUSTOMER:

          filterCustomerIds = (filter.value as IFilterCheckboxesValue).items;
          break;

        case FilterType.FLOWS_POSITION_GROUP:

          filterPositionGroupIds = (filter.value as IFilterCheckboxesValue).items;
          break;

        case FilterType.FLOWS_RECRUITER:

          filterRecruiterIds = (filter.value as IFilterCheckboxesValue).items;
          break;
      }
    });

    // console.log('%c ---------------------- =', 'background:#0f0;color:#000;');
    // console.log('%c filterCustomerIds      =', 'background:#0f0;color:#000;', toJS(filterCustomerIds));
    // console.log('%c filterPositionGroupIds =', 'background:#0f0;color:#000;', toJS(filterPositionGroupIds));
    // console.log('%c filterRecruiterIds     =', 'background:#0f0;color:#000;', toJS(filterRecruiterIds));

    // ------------------------------

    this.interviewFlowCandidates.forEach((candidate:Recruiting_Candidate):void =>
    {
      // __DEBUG && console.log('candidate.staff.created_at', candidate.staff?.created_at);
      // __DEBUG && console.log('candidate.customer_team_slots.staff.created_at', candidate.customer_team_slots[0]?.staff?.created_at);

      // filter by recruiter
      if( filterRecruiterIds.length && !filterRecruiterIds.includes(candidate.assigned_user_id || -1) ) return;

      const staff:Maybe<Staffing_Staff> = candidate.staff;

      let isUpdatedInTwoWeeks:boolean = false;

      // ------------------------

      if( staff )
      {
        const staffingDate:Moment | null = staff ? moment(staff.created_at) : null;
        const twoWeeksAgo:Moment = moment().subtract(2, 'week');

        isUpdatedInTwoWeeks = twoWeeksAgo.isBefore(staffingDate);

        if( !isUpdatedInTwoWeeks )
        {
          __DEBUG && console.log('%c candidate =', 'background:#f66;color:#000;', candidate.id, toJS(candidate));
          __DEBUG && console.log('%c --> staff =', 'background:#000;color:#fff;', toJS(staff));
        }
      }

      // ------------------------

      if( !staff || isUpdatedInTwoWeeks )
      {
        __DEBUG && console.log('%c candidate =', 'background:#0f0;color:#000;', candidate.id, toJS(candidate));

        if( isUpdatedInTwoWeeks )
        {
          __DEBUG && console.log('%c --> staff =', 'background:#080;color:#000;', toJS(staff));
          __DEBUG && console.log('%c --> isUpdatedInTwoWeeks =', 'background:#080;color:#000;', toJS(isUpdatedInTwoWeeks));
        }

        const stage:IInterviewFlowGroupStage | undefined = interviewFlowGroup.stages.find(
          (stage:IInterviewFlowGroupStage) => stage.id === candidate.interview_flow_stage_id
        );

        if( !stage || (stage && stage.candidates.includes(candidate)) ) return;

        let candidateAddedToStage:boolean = false;

        // candidate.candidate_position_groups.slice().reverse().forEach((cpg:Recruiting_Candidate_Position_Group) =>
        candidate.candidate_position_groups.forEach((cpg:Recruiting_Candidate_Position_Group) =>
        {
          if( candidateAddedToStage ) return;

          // filter by position groups
          if( filterPositionGroupIds.length && !filterPositionGroupIds.includes(cpg.position_group.id) ) return;

          cpg.position_group.positions.forEach((position:Recruiting_Position) =>
          {
            if( candidateAddedToStage ) return;

            // filter by customer
            if( filterCustomerIds.length )
            {
              if( !filterCustomerIds.includes(position.customer_team?.customer?.id) ) return;

              // console.log('%c ------------------------------------- =', 'background:#f60;color:#000;');
              // console.log('%c candidate      =', 'background:#080;color:#000;', candidate.id, candidate.first_name, candidate.last_name, toJS(candidate));
              // staff && console.log('%c --> staff =', 'background:#f8f;color:#000;', toJS(staff));
              // console.log('%c position_group =', 'background:#080;color:#000;', toJS(cpg.position_group));

              // TODO: filter by auto-computed, proposed ???, finalized
              if( !staff )
              {
                const hasFinalPosition:boolean = this._hasFinalPosition(candidate.candidate_allocations);
                const hasFinalPositionForCustomer:boolean = this._hasFinalPositionForCustomer(candidate.candidate_allocations, filterCustomerIds as Array<number>);
                const hasProposedPositionForCustomer:boolean = this._hasProposedPositionForCustomer(candidate.candidate_allocations, filterCustomerIds as Array<number>, filterPositionGroupIds as Array<number>);

                const finalForOtherCustomer:boolean = hasFinalPosition && !hasFinalPositionForCustomer;

                if( (
                    !(position as Recruiting_Position_Ex).open_slots_count.aggregate.count
                    && !hasFinalPositionForCustomer
                    && !hasProposedPositionForCustomer
                  )
                  // hide if proposed for this customer but finalized for other customer
                  // || (
                  //   hasProposedPositionForCustomer && finalForOtherCustomer
                  // )
                  // hide if finalized for other customer
                  || (
                    finalForOtherCustomer
                  )
                )
                {
                  // console.log('%c position       =', 'background:#f00;color:#ff0;', toJS(position));
                  // console.log('%c customer_team  =', 'background:#fcc;color:#000;', toJS(position.customer_team));
                  // console.log('%c customer       =', 'background:#fcc;color:#000;', toJS(position.customer_team.customer));
                  return;
                }
              }
              else if( staff )
              {
                // TODO: filter by finalized...

                if( !this._checkStaffPositionForCustomer(staff, filterCustomerIds as Array<number>) )
                {
                  // console.log('%c position       =', 'background:#f00;color:#ff0;', toJS(position));
                  // console.log('%c customer_team  =', 'background:#fcc;color:#000;', toJS(position.customer_team));
                  // console.log('%c customer       =', 'background:#fcc;color:#000;', toJS(position.customer_team.customer));
                  return;
                }
              }
            }

            // if( !stage.candidates.includes(candidate) )
            // {
            stage.candidates.push(candidate);
            candidateAddedToStage = true;

            // console.log('%c position       =', 'background:#0f0;color:#000;', toJS(position));
            // console.log('%c customer_team  =', 'background:#080;color:#000;', toJS(position.customer_team));
            // console.log('%c customer       =', 'background:#080;color:#000;', toJS(position.customer_team.customer));
            // }
          });
        });
      }
    });
    __DEBUG && console.groupEnd();

    interviewFlowGroups.forEach((group:IInterviewFlowGroup) =>
    {
      group.stages.forEach((stage:IInterviewFlowGroupStage) =>
      {
        stage.candidates = stage.candidates.slice().sort(SortUtil.byCandidateName);
      });
    });

    // __DEBUG && console.log('%c interviewFlowGroups =', 'background:#0f0;color:#000;', toJS(interviewFlowGroups));

    return Array.from(interviewFlowGroups.values());
  }

  // ----------------------------------------------------

  private _hasFinalPosition(allocations:Array<Recruiting_Candidate_Allocation>):boolean
  {
    return allocations.some((allocation:Recruiting_Candidate_Allocation) =>
    {
      return allocation.is_final;
    });
  }

  private _hasFinalPositionForCustomer(allocations:Array<Recruiting_Candidate_Allocation>, filterCustomerIds:Array<number>):boolean
  {
    return allocations.some((allocation:Recruiting_Candidate_Allocation) =>
    {
      if( !allocation.is_final || !allocation.finalized_slots.length ) return false;

      const position:Recruiting_Position = allocation.finalized_slots[0].position;

      return filterCustomerIds.includes(position.customer_team.customer.id);
    });
  }

  private _hasProposedPositionForCustomer(allocations:Array<Recruiting_Candidate_Allocation>,
                                          filterCustomerIds:Array<number>,
                                          filterPositionGroupIds:Array<number>
  ):boolean
  {
    return allocations.some((allocation:Recruiting_Candidate_Allocation) =>
    {
      if( !allocation.allocation_positions.length ) return false;

      const position:Recruiting_Position = allocation.allocation_positions[0].position;

      const filteredByPositionGroup:boolean = filterPositionGroupIds.length
        ? filterPositionGroupIds.includes(position.position_group_id || -1)
        : true;

      return filterCustomerIds.includes(position.customer_team.customer.id) && filteredByPositionGroup;
    });
  }

  private _checkStaffPositionForCustomer(staff:Staffing_Staff, filterCustomerIds:Array<number>):boolean
  {
    return staff.customer_team_slots.some((slot:Staffing_Customer_Team_Slot) =>
    {
      const position:Recruiting_Position = slot.position;

      return filterCustomerIds.includes(position.customer_team.customer.id);
    });
  }

  // ----------------------------------------------------

  @computed
  public get recruiters():Array<Common_User>
  {
    return this.users.filter(
      (user:Common_User) => user.role_names?.includes('recruiter')
        || user.role_names?.includes('sourcer')
        || user.role_names?.includes('recruiting-manager')
    );
  }

  @computed
  public get isDragging():boolean
  {
    return this.draggingCandidateId > 0;
  }

  @computed
  public get draggingInterviewFlowGroup():IInterviewFlowGroup | undefined
  {
    return this.interviewFlowGroups.find(
      (ifg:IInterviewFlowGroup) => ifg.id === this.draggingInterviewFlowGroupId
    );
  }

  @computed
  public get draggingInterviewFlowGroupStage():IInterviewFlowGroupStage | undefined
  {
    return this.draggingInterviewFlowGroup?.stages.find(
      (ifgStage:IInterviewFlowGroupStage) => ifgStage.id === this.draggingCandidate?.interview_flow_stage_id
    );
  }

  @computed
  public get dropInterviewFlowGroupStage():IInterviewFlowGroupStage | undefined
  {
    return this.draggingInterviewFlowGroup?.stages.find(
      (ifgStage:IInterviewFlowGroupStage) => ifgStage.id === this.dropInterviewFlowStageId
    );
  }

  @computed
  public get draggingCandidate():Recruiting_Candidate | undefined
  {
    return this.interviewFlowCandidates.find(
      (candidate:Recruiting_Candidate) => candidate.id === this.draggingCandidateId
    );
  }

  @computed
  public get draggingCandidateIndex():number
  {
    return this.draggingInterviewFlowGroupStage
      ? this.draggingInterviewFlowGroupStage.candidates.findIndex(
        (candidate:Recruiting_Candidate) =>
        {
          return candidate.id === this.draggingCandidateId;
        }
      )
      : -1;
  }

  @computed
  public get isGrouped():boolean
  {
    return this.groupByCustomer || this.groupByPositionGroup;
  }

  // ----------------------------------------------------

  public getFlowByStageId:(stageId:number | null | undefined) => Recruiting_Interview_Flow | undefined =
    computedFn((stageId:number | null | undefined):Recruiting_Interview_Flow | undefined =>
    {
      if( !stageId ) return undefined;

      return this.interviewFlows.find((flow:Recruiting_Interview_Flow) =>
      {
        return flow.stages.find((stage:Recruiting_Interview_Flow_Stage) => stage.id === stageId);
      });
    });

  // ----------------------------------------------------

  public getUserById:(id:number) => Maybe<Common_User> =
    computedFn((id:number):Maybe<Common_User> =>
    {
      return this.users.find((user:Common_User) => user.id === id);
    });

  // ----------------------------------------------------
}
