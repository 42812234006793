import { Collapse } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import { Tooltip2 } from '@blueprintjs/popover2';
import { CommonController, StaffOrCandidateUrl } from '@flow/common/CommonController';

import styles from '@flow/common/components/elements/AllocationPositionSlot.module.less';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { SvgIcon, SvgIconName } from '@flow/common/components/elements/SvgIcon';
import { AnchorButton } from '@flow/common/components/form/AnchorButton';
import { Button } from '@flow/common/components/form/Button';
import { Icon, IconName, IconNames } from '@flow/common/components/form/Icon';
import { Alignment, Intent } from '@flow/common/components/types/Types';
import type { DateStrOrNum } from '@flow/common/utils/DateUtil';
import { DateUtil } from '@flow/common/utils/DateUtil';
import { DebugUtil } from '@flow/common/utils/DebugUtil';
import { SlotUtil } from '@flow/common/utils/SlotUtil';
import type {
  Recruiting_Allocation_Position,
  Recruiting_Candidate,
  Staffing_Customer_Team_Slot,
  Staffing_Staff
} from '@flow/data-access/lib/types/graphql.generated';
import { Staffing_Customer_Team_Slot_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { action, computed, toJS } from 'mobx';
import moment from 'moment';
import React from 'react';

const cx = classNames.bind(styles);

interface Props
{
  className?:string;
  slot:Staffing_Customer_Team_Slot;

  finalizedCandidate?:Recruiting_Candidate;
  staffMember?:Staffing_Staff;

  selectedDate?:string | null;

  showLeavingDateInput?:boolean;
  selectedLeavingDate?:string | null;

  // isStaff?:boolean;
  inDialog?:boolean;
  isSelected?:boolean;

  onClick?:() => void;
  onClickSetLeavingDate?:() => void;
  // onChange?:(slot:Staffing_Customer_Team_Slot) => void;
  onChangeDate?:(newDate:string | null) => void;
  onChangeLeavingDate?:(newLeavingDate:string | null) => void;
  onCloseLeavingDateInput?:() => void;
  isLeaving?:boolean;
}

interface State
{
  showDateInput:boolean;
  newLeavingDate:string | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  datesBeforeEdit:any;

  isProposedCandidatesOpened:boolean;
}

enum RenderedLineType
{
  OPEN_POSITION = 'OPEN_POSITION',
  STAFF = 'STAFF',
  NEXT_STAFF = 'NEXT_STAFF',
  NEXT_CANDIDATE = 'NEXT_CANDIDATE',
  FINALIZED_CANDIDATE = 'FINALIZED_CANDIDATE',
}

@component
export class AllocationPositionSlot extends React.Component<Props, State>
{
  @di private _commonController!:CommonController;

  // ---------------------------------------------------------

  @computed
  private get isOpenSlot():boolean
  {
    const { inDialog, isSelected, slot, staffMember } = this.props;

    return Boolean(
      (this.props.slot.status === Staffing_Customer_Team_Slot_Status_Enum.Open)
      || (
        inDialog && !isSelected && this.isStaffView &&
        !slot.next_staff_id && !slot.next_candidate_id &&
        slot.staff && staffMember && slot.staff.id === staffMember.id
      )
    );
  }

  @computed
  private get isCandidateView():boolean
  {
    return !!this.props.finalizedCandidate;
  }

  @computed
  private get isColivingFinalizedSlot():boolean
  {
    const { slot } = this.props;

    return this.isCandidateView && !!slot.staff;
  }

  @computed
  private get isStaffView():boolean
  {
    return !!this.props.staffMember;
  }

  @computed
  private get showNextStaffOnLine1():boolean
  {
    const { slot, staffMember } = this.props;

    return !!slot.next_staff && slot.next_staff.id === staffMember?.id;
  }

  @computed
  private get showStaffOnLine1():boolean
  {
    return !this.showNextStaffOnLine1;
  }

  @computed
  private get isStaffJoined():boolean
  {
    const { slot } = this.props;

    return !!slot.staff && DateUtil.isSameOrBeforeToday(slot.start_date); // 'Joined staff';

    // if( slot.staff )
    //   currentState = 'Current staff';

    // if( slot.staff && startDate && startDate > nowDate )
    //   currentState = 'Joining staff';

    // if( slot.staff && leavingDate && leavingDate < nowDate )
    //   currentState = 'Staff left';

    // if( slot.next_staff && nextStaffStartDate && nextStaffStartDate > nowDate )
    //   nextState = 'Joining staff';

    // if( slot.next_staff && nextStaffStartDate && nextStaffStartDate < nowDate )
    //   nextState = 'Joined staff';
  }

  @computed
  private get isStaffJoining():boolean
  {
    const { slot } = this.props;

    return !!slot.staff && DateUtil.isAfterToday(slot.start_date); // 'Joining staff';
  }

  // ---------------------------------------------------------

  @computed
  private get borderColor():string
  {
    const { inDialog, slot } = this.props;

    let borderColor:string = ''; // default = gray

    if( !inDialog && (slot.leaving_date || (this.isStaffView && (slot.next_staff || slot.candidate))) )
    {
      borderColor = styles.orange;
    }
    else if( !inDialog && this.isStaffView && !slot.next_staff && !slot.candidate )
    {
      if( this.isStaffJoining )
      {
        borderColor = styles.blue;
      }
    }

    return borderColor;
  }

  // ---------------------------------------------------------

  public constructor(props:Props)
  {
    super(props);

    this.state = {
      newLeavingDate: null,
      showDateInput: false,
      datesBeforeEdit: {},
      isProposedCandidatesOpened: false
    };
  }

  // ---------------------------------------------------------

  public componentDidMount():void
  {
    const { selectedLeavingDate } = this.props;

    console.log('%c componentDidMount: selectedLeavingDate =', 'background:#0f0;color:#000;', selectedLeavingDate);
    this.setState({ newLeavingDate: selectedLeavingDate || null });
  }

  public componentDidUpdate(prevProps:Readonly<Props>):void
  {
    const { isSelected, selectedLeavingDate } = this.props;

    if( prevProps.isSelected && !isSelected )
    {
      this.setState({ isProposedCandidatesOpened: false });
    }

    if( prevProps.selectedLeavingDate !== selectedLeavingDate )
    {
      console.log('%c componentDidUpdate: selectedLeavingDate =', 'background:#0f0;color:#000;', prevProps.selectedLeavingDate, '->', selectedLeavingDate);
      this.setState({ newLeavingDate: selectedLeavingDate || null });
    }
  }

  // ---------------------------------------------------------

  @computed
  private get _proposedCandidates():Array<Recruiting_Candidate>
  {
    const { slot } = this.props;
    const { position } = slot;

    console.log('%c @computed _proposedCandidates =', 'background:#f0f;color:#000;', toJS(position));

    if( !position )
    {
      console.log('%c ERROR slot position =', 'background:#f00;color:#ff0;', toJS(slot));
      return [];
    }

    const { allocations } = position;

    console.log('%c --> allocations =', 'background:#f0f;color:#000;', toJS(allocations));

    if( !allocations?.length ) return [];

    const result:Map<number, Recruiting_Candidate> = new Map();

    allocations.forEach((allocationPosition:Recruiting_Allocation_Position) =>
    {
      const {
        candidate_allocation: {
          allocation_positions_aggregate,
          candidate: proposedCandidate
        }
      } = allocationPosition;

      // allocation_positions_aggregate( where: {
      //   candidate_allocation: {
      //     candidate: {
      //       _or: [
      //         {status: {_eq: staffed}},
      //         {candidate_allocations: {is_final: {_eq: true}}}
      //       ]
      //     }
      //   }
      // })

      // if count > 0:
      // - proposedCandidate !!! already staffed
      // - OR proposedCandidate in OTHER allocations on OTHER positions has final allocation
      if( allocation_positions_aggregate.aggregate?.count === 0 )
      {
        console.log('%c --> ', 'background:#080;color:#000;', toJS(proposedCandidate));
        result.set(proposedCandidate.id, proposedCandidate);
      }
      else
      {
        console.log('%c --> ', 'background:#f88;color:#000;', toJS(proposedCandidate));
      }
    });

    return Array.from(result.values());
  }

  // ---------------------------------------------------------

  @bind
  private _renderSetLeavingDateBtn():React.ReactNode
  {
    const { slot, staffMember } = this.props;

    if( slot.staff?.id === staffMember?.id )
    {
      return (
        <>
          <Button
            className={cx(styles.btnEditDate)}
            text={'Set leaving date'}
            icon={IconNames.CALENDAR}
            iconClassName={styles.btnEditDateIcon}
            minimal={true}
            intent={Intent.PRIMARY}
            onClick={(e):void =>
            {
              e.stopPropagation();
              this.props.onClickSetLeavingDate?.();
            }}
          />
          <Icon
            className={styles.iconWarning}
            icon={IconNames.WARNING_SIGN}
          />
        </>
      );
    }

    const staffHref:StaffOrCandidateUrl = this._commonController.getStaffMemberUrl(slot.staff?.id);

    return (
      <>
        <AnchorButton
          href={staffHref}
          className={styles.btnEditDateBtn}
          wrapperClassName={cx(styles.btnEditDate, styles.emptyLeavingDate)}
          text={'Set leaving date'}
          openInNewTab
          icon={IconNames.SHARE}
          iconClassName={styles.btnEditDateIcon}
          minimal={true}
          intent={Intent.PRIMARY}
          // rightIcon={IconNames.WARNING_SIGN}
          // rightIconClassName={styles.iconWarning}
          alignText={Alignment.LEFT}
        />
        <Icon
          className={styles.iconWarning}
          icon={IconNames.WARNING_SIGN}
        />
      </>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderAvatar(url:string | undefined | null, isCandidate?:boolean):React.ReactNode
  {
    return (
      <Avatar
        className={styles.avatar}
        size={20}
        url={url}
        isCandidate={isCandidate}
      />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderProposedCandidates():React.ReactNode | null
  {
    const { isProposedCandidatesOpened } = this.state;
    const { isSelected, inDialog } = this.props;

    if( !inDialog || !this.isOpenSlot || !this._proposedCandidates.length ) return null;

    // const { position } = slot;
    // const staffedSlots:Array<Staffing_Customer_Team_Slot> = SlotUtil.getStaffedSlots(position.customer_team_slots);
    // const openSlotsCount:number = position.customer_team_slots.length - staffedSlots.length;
    // if( openSlotsCount == 0 ) return null;

    return (
      <Collapse
        isOpen={isSelected && isProposedCandidatesOpened}
      >
        <div className={cx(styles.proposedCandidates)}>

          <div className={styles.proposedHeader}>
            Proposed Candidates
          </div>
          {
            // proposedCandidates.length === 0 &&
            // <BlankState
            //   className={styles.blankState}
            //   title={'No candidates'}
            // />
          }
          {
            this._proposedCandidates.map((candidate:Recruiting_Candidate) =>
            {
              const candidatePageUrl:StaffOrCandidateUrl = this._commonController.getCandidateUrl(candidate.id);

              return (
                <div
                  key={candidate.id}
                  className={styles.proposedCandidate}
                >
                  <a
                    className={styles.proposedCandidateLink}
                    href={candidatePageUrl}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    {this._renderAvatar(candidate.avatar_url)}

                    <div className={styles.name}>
                      {DebugUtil.id(candidate.id)}
                      {candidate.first_name} {candidate.last_name}
                    </div>
                  </a>
                </div>
              );
            })
          }
        </div>
      </Collapse>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderProposedCount():React.ReactNode | null
  {
    const { inDialog } = this.props;

    if( !inDialog || !this.isOpenSlot || !this._proposedCandidates.length ) return null;

    const { isProposedCandidatesOpened } = this.state;
    const { slot } = this.props;

    const { position } = slot;

    const staffedSlots:Array<Staffing_Customer_Team_Slot> = SlotUtil.getStaffedSlots(position.customer_team_slots);
    const openSlotsCount:number = position.customer_team_slots.length - staffedSlots.length;

    return (
      <Button
        className={styles.proposedCount}
        text={`${this._proposedCandidates.length} to ${openSlotsCount}`}
        minimal={true}
        rightIcon={isProposedCandidatesOpened ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
        rightIconSize={13}
        iconClassName={styles.proposedCountIcon}
        onClick={(e):void =>
        {
          // e.stopPropagation();
          this.setState({ isProposedCandidatesOpened: !this.state.isProposedCandidatesOpened });
        }}
      />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderArrow():React.ReactNode | null
  {
    return (
      <Icon
        className={styles.arrowIcon}
        icon={IconNames.ARROW_LEFT}
      />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderAvatarAndName(nameType:RenderedLineType, isLine1:boolean):React.ReactNode | null
  {
    const { slot, finalizedCandidate, staffMember, inDialog, isSelected } = this.props;

    const isLine2:boolean = !isLine1;

    let isLink:boolean = isLine2 && (!inDialog || (inDialog && !!isSelected));
    const isOpenPosition:boolean = nameType === RenderedLineType.OPEN_POSITION;

    let avatarUrl:string | null | undefined;
    let name:string = '';
    let linkHref:StaffOrCandidateUrl;
    let profile:Recruiting_Candidate | Staffing_Staff | undefined;
    let debugStr:string;

    const isCandidate:boolean = (nameType === RenderedLineType.NEXT_CANDIDATE) ||
      (nameType === RenderedLineType.FINALIZED_CANDIDATE && !staffMember && !!finalizedCandidate);

    // ------------------------------
    if( isOpenPosition )
    {
      isLink = false;
      name = 'Open position';
      debugStr = 'OS';
    }
    // ------------------------------
    else if( nameType === RenderedLineType.STAFF || nameType === RenderedLineType.NEXT_STAFF )
    {
      profile = (nameType === RenderedLineType.STAFF ? slot.staff : slot.next_staff) as Staffing_Staff;
      if( !profile ) return null;

      avatarUrl = profile.avatar_url || profile.user.avatar;
      name = `${profile.user.first_name} ${profile.user.last_name}`;

      if( isLink )
      {
        linkHref = this._commonController.getStaffMemberUrl(profile.id);
      }

      debugStr = nameType === RenderedLineType.STAFF ? 'ST' : 'NS';
    }
    // ------------------------------
    else // if( nameType === NameType.NEXT_CANDIDATE || nameType === NameType.FINALIZED_CANDIDATE )
    {
      if( isCandidate )
      {
        profile = nameType === RenderedLineType.NEXT_CANDIDATE
          ? slot.candidate as Recruiting_Candidate
          : finalizedCandidate as Recruiting_Candidate;

        avatarUrl = profile.avatar_url;
        name = `${profile.first_name} ${profile.last_name}`;
      }
      else
      {
        if( staffMember )
        {
          profile = staffMember as Staffing_Staff;
          avatarUrl = profile.avatar_url || profile.user.avatar;
          name = `${profile.user.first_name} ${profile.user.last_name}`;
        }
      }

      if( !profile ) return null;

      console.log('%c profile =', 'background:#ff0;color:#000;', toJS(profile));

      if( isLink )
      {
        linkHref = this._commonController.getCandidateUrl(profile.id);
      }

      debugStr = inDialog ? this.isOpenSlot ? 'OS_' : 'ST_' : '';
      debugStr += nameType === RenderedLineType.NEXT_CANDIDATE
        ? 'CA'
        : staffMember
          ? (this.isOpenSlot ? 'ST' : 'NS')
          : 'FC';
    }
    // ------------------------------

    const WrapperTag:keyof JSX.IntrinsicElements = isLink ? 'a' : 'div';

    return (
      <WrapperTag
        className={cx(styles.nameAndAvatar, { isLink })}
        href={linkHref}
        target={isLink ? '_blank' : undefined}
        rel={isLink ? 'noreferrer' : undefined}
      >
        {
          !isOpenPosition &&
          this._renderAvatar(avatarUrl, isCandidate)
        }
        <div className={cx(styles.nameText, { textBold: isLine1 && !isOpenPosition, isOpenPosition })}>
          {DebugUtil.id(`${slot.id} ${debugStr}:${profile ? profile?.id : ''}`)}
          {name}
        </div>
      </WrapperTag>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderName(isLine1:boolean):React.ReactNode
  {
    const { slot, isSelected, inDialog, staffMember } = this.props;

    const isLine2:boolean = !isLine1;

    // ------------------------------
    if( isLine1 && !this.isOpenSlot && !slot.staff )
    {
      return (
        <div className={cx(styles.nameWrapper, styles.error)}>
          Error: no Staff assigned. Slot ID: {slot.id}, status: {slot.status}
        </div>
      );
    }

    // ------------------------------
    if( (inDialog && isSelected) || (!inDialog && this.isCandidateView) )
    {
      if( isLine1 && this.isOpenSlot )
      {
        return (
          <div className={styles.nameWrapper}>
            {DebugUtil.text('1.')}
            {this._renderAvatarAndName(RenderedLineType.FINALIZED_CANDIDATE, true)}
            {this._renderProposedCount()}
          </div>
        );
      }
        // line1: staff from page == staff on slot
      // line2: next_staff on slot
      else if( this.isStaffView && isLine1 && slot.staff && slot.staff.id === staffMember?.id )
      {
        return (
          <div className={styles.nameWrapper}>
            {DebugUtil.text('2.')}
            {this._renderAvatarAndName(RenderedLineType.STAFF, true)}
            {this._renderArrow()}
          </div>
        );
      }
      else if( this.isStaffView && isLine2 && slot.next_staff && slot.next_staff.id !== staffMember?.id )
      {
        return (
          <div className={styles.nameWrapper}>
            {DebugUtil.text('31.')}
            {this._renderAvatarAndName(RenderedLineType.NEXT_STAFF, false)}
          </div>
        );
      }
      else if( this.isStaffView && isLine2 && slot.next_candidate_id )
      {
        return (
          <div className={styles.nameWrapper}>
            {DebugUtil.text('32.')}
            {this._renderAvatarAndName(RenderedLineType.NEXT_CANDIDATE, false)}
          </div>
        );
      }
        // line1: staff from page == next_staff on slot
      // line2: staff on slot
      else if( isLine1 && slot.staff )
      {
        return (
          <div className={styles.nameWrapper}>
            {DebugUtil.text('4.')}
            {this._renderAvatarAndName(RenderedLineType.FINALIZED_CANDIDATE, true)}
            {this._renderArrow()}
          </div>
        );
      }
      else if( isLine2 && slot.staff && slot.staff.id !== staffMember?.id )
      {
        return (
          <div className={styles.nameWrapper}>
            {DebugUtil.text('5.')}
            {this._renderAvatarAndName(RenderedLineType.STAFF, false)}
          </div>
        );
      }
    }
    // ------------------------------
    else if( inDialog && !isSelected )
    {
      if( isLine1 && this.isOpenSlot )
      {
        return (
          <div className={styles.nameWrapper}>
            {DebugUtil.text('6.')}
            {this._renderAvatarAndName(RenderedLineType.OPEN_POSITION, true)}
            {this._renderProposedCount()}
          </div>
        );
      }
      else if( isLine1 && slot.staff )
      {
        if( this.isStaffView && slot.staff.id === staffMember?.id )
        {
          return (
            <div className={styles.nameWrapper}>
              {DebugUtil.text('71.')}
              {/* condition moved to this.isOpenSlot */}
              {/*{(!slot.next_staff_id && !slot.next_candidate_id) && this._renderAvatarAndName(RenderedLineType.OPEN_POSITION, false)}*/}
              {slot.next_staff_id && this._renderAvatarAndName(RenderedLineType.NEXT_STAFF, false)}
              {slot.next_candidate_id && this._renderAvatarAndName(RenderedLineType.NEXT_CANDIDATE, false)}
            </div>
          );
        }
        else
        {
          return (
            <div className={styles.nameWrapper}>
              {DebugUtil.text('72.')}
              {this._renderAvatarAndName(RenderedLineType.STAFF, false)}
            </div>
          );
        }
      }
    }
    // ------------------------------
    else if( !inDialog && this.isStaffView )
    {
      if( isLine1 && slot.staff )
      {
        // joining staff
        // next_staff render from line 2 -> line 1
        if( this.showNextStaffOnLine1 )
        {
          return (
            <div className={cx(styles.nameWrapper, styles.reverse11)}>
              {DebugUtil.text('8.')}
              {this._renderAvatarAndName(RenderedLineType.NEXT_STAFF, true)}
              {this._renderArrow()}
            </div>
          );
        }
        else
        {
          return (
            <div className={cx(styles.nameWrapper, styles.reverse22)}>
              {DebugUtil.text('9.')}
              {this._renderAvatarAndName(RenderedLineType.STAFF, true)}
              {(slot.next_staff || slot.candidate) && this._renderArrow()}
            </div>
          );
        }
      }
      else if( isLine2 && slot.next_staff )
      {
        // current staff from line 1 -> line 2
        if( this.showNextStaffOnLine1 ) // -> and staff on line2
        {
          return (
            <div className={cx(styles.nameWrapper, styles.reverse21)}>
              {DebugUtil.text('10.')}
              {this._renderAvatarAndName(RenderedLineType.STAFF, false)}
            </div>
          );
        }
        else
        {
          return (
            <div className={cx(styles.nameWrapper, styles.reverse12)}>
              {DebugUtil.text('11.')}
              {this._renderAvatarAndName(RenderedLineType.NEXT_STAFF, false)}
            </div>
          );
        }
      }
      else if( isLine2 && slot.candidate )
      {
        return (
          <div className={styles.nameWrapper}>
            {DebugUtil.text('12.')}
            {this._renderAvatarAndName(RenderedLineType.NEXT_CANDIDATE, false)}
          </div>
        );
      }
    }

    return null;
  }

  // ---------------------------------------------------------

  @action.bound
  private _onChangeDateInput(newSelectedDate:Date):void
  {
    const newDate:string | null = DateUtil.getISODay(newSelectedDate);

    if( !newDate )
    {
      this.setState({ showDateInput: false });
    }

    const { onChangeDate } = this.props;

    console.log('%c onChangeDate slot =', 'background:#0f0;color:#000;', newDate);
    onChangeDate?.(newDate);
  }

  @action.bound
  private _onChangeLeavingDateInput(newSelectedLeavingDate:Date):void
  {
    const newDate:string | null = DateUtil.getISODay(newSelectedLeavingDate);

    console.log('%c onChangeLeavingDate slot =', 'background:#0f0;color:#000;', newDate);

    // BTN -> clear
    if( !newDate )
    {
      const { onChangeLeavingDate } = this.props;
      onChangeLeavingDate?.(newDate);
      return;
    }

    this.setState({ newLeavingDate: newDate });
  }

  @action.bound
  private _onCloseLeavingDateInput():void
  {
    const { newLeavingDate } = this.state;
    const { selectedLeavingDate, onChangeLeavingDate } = this.props;

    // this.setState({ showDateInput: false });
    console.log('%c onClose =', 'background:#0f0;color:#000;', newLeavingDate);

    if( newLeavingDate !== selectedLeavingDate )
    {
      onChangeLeavingDate?.(newLeavingDate);
    }

    this.props.onCloseLeavingDateInput?.();
  }

  // ---------------------------------------------------------

  @bind
  private _renderLeavingDate(debugStr:string):React.ReactNode | null
  {
    const { slot } = this.props;

    if( !slot.leaving_date ) return this._renderLeavingDateExpected();

    return (
      <>
        <Icon
          className={styles.iconLeavingDate}
          icon={IconNames.LOG_OUT}
        />
        <div className={styles.startOrLeavingDateText}>
          {DebugUtil.text(debugStr)}
          {DateUtil.formatDay(slot.leaving_date)}
        </div>
      </>
    );
  }

  @bind
  private _renderLeavingDateExpected():React.ReactNode | null
  {
    const { slot } = this.props;

    return (
      <>
        <div className={cx(styles.startOrLeavingDateText, styles.isEmpty)}>
          Leaving date expected
        </div>
        <Tooltip2
          className={styles.tooltipWrapper}
          placement={'top'}
          content={(
            <div className={styles.leavingDateTooltip}>
              Set leaving date<br />
              on {slot.staff?.user.first_name} {slot.staff?.user.last_name}&apos;s profile
            </div>
          )}
        >
          <Icon
            className={styles.iconWarning}
            icon={IconNames.WARNING_SIGN}
          />
        </Tooltip2>
      </>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderDate(isLine1:boolean):React.ReactNode | null
  {
    const { slot, isSelected, inDialog, selectedDate, staffMember, isLeaving } = this.props;

    // now show all dates
    if( inDialog && !isSelected ) return null;

    const { showDateInput } = this.state;
    // const { selectedDate } = this._candidateAllocationState;

    const isLine2:boolean = !isLine1;

    // const nowDate = Date.now();
    // const startDate:number | null = DateUtil.getDate(slot.start_date as DateStrOrNum);
    const leavingDate:number | null = DateUtil.getDate(slot.leaving_date as DateStrOrNum);
    // const nextStaffStartDate:number | null = DateUtil.getDate(slot.next_staff_start_date as DateStrOrNum);
    // const nextCandidateStartDate:number | null = DateUtil.getDate(slot.next_candidate_start_date as DateStrOrNum);

    // const isOpenSlot:boolean = slot.status === Staffing_Customer_Team_Slot_Status_Enum.Open;

    // const dateValue:Date | undefined = moment(slot.next_candidate_start_date).toDate();

    // -------------------------------------
    if( inDialog && isLine1 )
    {
      if( !showDateInput )
      {
        const showCalendarIcon:boolean = !selectedDate;
        const showJoiningIcon:boolean = !!selectedDate && DateUtil.isAfterToday(selectedDate);
        const showClockIcon:boolean = !!selectedDate && DateUtil.isSameOrBeforeToday(selectedDate);

        const btnText:string = selectedDate
          ? DebugUtil.text('sd: ') + (DateUtil.formatDay(selectedDate))
          : 'Enter start date';

        const btnIcon:IconName = showCalendarIcon ? IconNames.CALENDAR : (showJoiningIcon ? IconNames.LOG_IN : IconNames.TIME);

        return (
          <div className={cx(styles.dateLines)}>
            <div className={cx(styles.dateLine)}>
              <Button
                className={cx(styles.btnEditDate, { selectedDate })}
                // text={nextCandidateStartDate ? DateUtil.formattedDate(nextCandidateStartDate) : 'Enter start date'}
                text={btnText}
                icon={btnIcon}
                iconClassName={cx(styles.btnEditDateIcon, { showClockIcon })}
                minimal={true}
                intent={Intent.PRIMARY}
                onClick={(e):void =>
                {
                  e.stopPropagation();
                  this.setState({ showDateInput: true });
                }}
              />
              {
                !selectedDate &&
                <Icon
                  className={styles.iconWarning}
                  icon={IconNames.WARNING_SIGN}
                />
              }
            </div>
            {
              (slot.staff && slot.staff?.id === staffMember?.id && (slot.next_staff || slot.candidate)) &&
              <div className={cx(styles.dateLine, styles.dateLine2)}>
                {this._renderLeavingDate('ld_3: ')}
              </div>
            }
          </div>
        );
      }
      else // if( showDateInput )
      {
        return (
          <div
            className={cx(styles.dateLine)}
            onClick={(e):void =>
            {
              e.stopPropagation();
            }}
          >
            <DateInput
              className={styles.dateInput}
              inputProps={{
                leftIcon: IconNames.CALENDAR,
                autoFocus: true,
                readOnly: true
              }}
              popoverProps={{
                // className: styles.dateInputPopover, // = DateInput className
                // popoverClassName: ?,
                captureDismiss: true,
                targetClassName: styles.dateInputPopoverTarget,
                autoFocus: true,
                onClose: ():void =>
                {
                  this.setState({ showDateInput: false });
                }
              }}
              rightElement={(
                <Icon
                  className={cx(styles.dateInputIcon, {
                    dateInputIconOk: selectedDate,
                    dateInputIconWarning: !selectedDate
                  })}
                  icon={selectedDate ? IconNames.TICK_CIRCLE : IconNames.WARNING_SIGN}
                />
              )}
              showActionsBar={true}
              closeOnSelection={true}
              canClearSelection={false}
              // minDate={moment().subtract(1, 'year').toDate()}
              // maxDate={moment().add(1, 'year').toDate()}
              onError={(errorDate):void =>
              {
                console.log('%c onError: errorDate =', 'background:#f00;color:#ff0;', errorDate);
              }}

              parseDate={(date:string):Date => new Date(date)}
              formatDate={(date:Date):string => DateUtil.formatDay(date)}

              placeholder={'Enter start date'}
              value={moment(selectedDate).toDate() || null}
              onChange={this._onChangeDateInput}
            />
          </div>
        );
      }
    }

    // -------------------------------------
    // if( this.isCandidateView && isLine2 && slot.staff && !leavingDate )
    if( isLine2 && !leavingDate && slot.staff && slot.staff?.id !== staffMember?.id )
    {
      if( inDialog )
      {
        return (
          <div className={cx(styles.dateLine)}>
            {this._renderLeavingDateExpected()}
          </div>
        );
      }
      else if( !inDialog && this.isCandidateView )
      {
        return (
          <div className={cx(styles.dateLine)}>
            {this._renderSetLeavingDateBtn()}
          </div>
        );
      }
    }

    // -------------------------------------
    if( inDialog && isLine2 && slot.staff && slot.staff?.id === staffMember?.id )
    {
      if( slot.next_staff_start_date || slot.next_candidate_start_date )
      {
        let viewDate:string = '';
        let debugStr:string = '';
        let maybeShowClockIcon:boolean = true;

        if( slot.next_staff_start_date )
        {
          viewDate = slot.next_staff_start_date;
          debugStr = 'ns_3';
        }
        else if( slot.next_candidate_start_date ) // NEXT_CANDIDATE
        {
          viewDate = slot.next_candidate_start_date;
          debugStr = 'ca_3';
        }

        maybeShowClockIcon &&= DateUtil.isSameOrBeforeToday(viewDate);
        const showJoiningIcon:boolean = !maybeShowClockIcon && DateUtil.isAfterToday(viewDate);

        return (
          <div className={cx(styles.dateLines)}>

            <div className={cx(styles.dateLine)}>
              {
                showJoiningIcon &&
                <Icon
                  className={styles.iconStartDate}
                  icon={IconNames.LOG_IN}
                />
              }
              {
                maybeShowClockIcon &&
                <Icon
                  className={cx(styles.iconStartDate, styles.iconClock)}
                  icon={IconNames.TIME}
                />
              }
              <div className={styles.startOrLeavingDateText}>
                {DebugUtil.text(`${debugStr}: `)}
                {DateUtil.formatDay(viewDate)}
              </div>
            </div>
          </div>
        );
      }
    }

    // -------------------------------------
    if( !inDialog && this.isCandidateView && isLine1 )
    {
      const showJoiningIcon:boolean = DateUtil.isAfterToday(slot.next_candidate_start_date);

      return (
        <div className={cx(styles.dateLine)}>
          {
            showJoiningIcon &&
            <Icon
              className={styles.iconStartDate}
              icon={IconNames.LOG_IN}
            />
          }
          {
            !showJoiningIcon &&
            <Icon
              className={cx(styles.iconStartDate, styles.iconClock)}
              icon={IconNames.TIME}
            />
          }
          <div className={styles.startOrLeavingDateText}>
            {DebugUtil.text('nc: ')}
            {DateUtil.formatDay(slot.next_candidate_start_date)}
          </div>
        </div>
      );
    }

    // -------------------------------------
    if( leavingDate && ((this.isCandidateView && isLine2) || (inDialog && isLine2)) )
    {
      return (
        <div className={cx(styles.dateLine)}>
          {this._renderLeavingDate('ld_1: ')}
        </div>
      );
    }

    // -------------------------------------
    if( !inDialog && this.isStaffView )
    {
      let viewDate:string = '';
      let debugStr:string = '';
      let showLeavingDate:boolean = false;
      let isRenderLeavingDateInput:boolean = false;
      let maybeShowClockIcon:boolean = false;

      if( isLine1 && slot.staff )
      {
        // joining staff
        // next_staff render from line 2 -> line 1
        viewDate = this.showNextStaffOnLine1 ? slot.next_staff_start_date : slot.start_date;
        debugStr = this.showNextStaffOnLine1 ? 'ns_1' : 'st_1';
        showLeavingDate = this.showStaffOnLine1;
        maybeShowClockIcon = this.showNextStaffOnLine1;

        const { showLeavingDateInput } = this.props;

        if( this.showStaffOnLine1 && slot.staff?.id === staffMember?.id && !slot.next_staff && !slot.candidate && !leavingDate )
        {
          showLeavingDate = isLeaving || false;
        }

        if( this.showStaffOnLine1 && showLeavingDateInput )
        {
          showLeavingDate = true;
          isRenderLeavingDateInput = true;
        }
      }
      else if( isLine2 && slot.next_staff )
      {
        viewDate = this.showNextStaffOnLine1 ? slot.start_date : slot.next_staff_start_date;
        debugStr = this.showNextStaffOnLine1 ? 'st_2' : 'ns_2';
        showLeavingDate = this.showNextStaffOnLine1; // -> and staff on line2
        maybeShowClockIcon = !this.showNextStaffOnLine1;
      }
      else if( isLine2 && slot.candidate ) // NEXT_CANDIDATE
      {
        viewDate = slot.next_candidate_start_date;
        debugStr = 'ca';
        maybeShowClockIcon = true;
      }

      maybeShowClockIcon &&= DateUtil.isSameOrBeforeToday(viewDate);

      const showJoiningIcon:boolean = !maybeShowClockIcon &&
        (DateUtil.isAfterToday(viewDate) || (isLine2 && !!slot.candidate));

      let shortMonth:boolean = false;
      let daysWorked:string = '';

      if( !(showJoiningIcon || maybeShowClockIcon) )
      {
        shortMonth = true;
        daysWorked = DateUtil.daysWorked(viewDate);
      }

      const { newLeavingDate } = this.state;
      const { selectedLeavingDate } = this.props;

      return (
        <div className={cx(styles.dateLines)}>

          <div className={cx(styles.dateLine)}>
            {
              showJoiningIcon &&
              <Icon
                className={styles.iconStartDate}
                icon={IconNames.LOG_IN}
              />
            }
            {
              maybeShowClockIcon &&
              <Icon
                className={cx(styles.iconStartDate, styles.iconClock)}
                icon={IconNames.TIME}
              />
            }
            <div className={styles.startOrLeavingDateText}>
              {DebugUtil.text(`${debugStr}: `)}
              {DateUtil.formatDay(viewDate, shortMonth)}
            </div>
            {
              daysWorked &&
              <div className={styles.daysWorked}>
                {daysWorked}
              </div>
            }
          </div>
          {/* TODO: ------------------------- */}
          {
            (showLeavingDate && !isRenderLeavingDateInput) &&
            <div className={cx(styles.dateLine, styles.dateLine2)}>
              {leavingDate && this._renderLeavingDate('ld_2: ')}
              {!leavingDate && this._renderSetLeavingDateBtn()}
            </div>
          }
          {
            (showLeavingDate && isRenderLeavingDateInput) &&
            <div className={cx(styles.dateLine, styles.dateLine2)}>
              <DateInput
                className={styles.dateInput}
                inputProps={{
                  leftIcon: IconNames.CALENDAR,
                  autoFocus: true,
                  readOnly: true
                }}
                popoverProps={{
                  // className: styles.dateInputPopover, // = DateInput className
                  // popoverClassName: ?,
                  captureDismiss: true,
                  targetClassName: styles.dateInputPopoverTarget,
                  autoFocus: true,
                  onClose: this._onCloseLeavingDateInput
                }}
                rightElement={(
                  <Icon
                    className={cx(styles.dateInputIcon, {
                      dateInputIconOk: newLeavingDate,
                      dateInputIconWarning: !newLeavingDate
                    })}
                    icon={newLeavingDate ? IconNames.TICK_CIRCLE : IconNames.WARNING_SIGN}
                  />
                )}
                showActionsBar={true}
                closeOnSelection={true} // !!!
                canClearSelection={false}
                minDate={moment(slot.start_date).add(1, 'day').toDate()}
                // maxDate={moment().add(1, 'year').toDate()}
                onError={(errorDate):void =>
                {
                  console.log('%c onError: errorDate =', 'background:#f00;color:#ff0;', errorDate);
                }}

                parseDate={(date:string):Date => new Date(date)}
                formatDate={(date:Date):string => DateUtil.formatDay(date)}

                placeholder={'Set leaving date'}
                value={moment(newLeavingDate || selectedLeavingDate).toDate() || null}
                onChange={this._onChangeLeavingDateInput}
              />
            </div>
          }
        </div>
      );
    }

    return null;
  }

  // ---------------------------------------------------------

  @bind
  private _onClickSlot():void
  {
    const { isSelected, onClick } = this.props;

    if( isSelected ) return;

    // ???
    // this.setState({ showDateInput: false });

    onClick?.();
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { slot, isSelected, inDialog } = this.props;

    // console.log('%c ----------------------------- =', 'background:#00f;color:#ff0;');
    console.log('%c render AllocationPositionSlot =', 'background:#00f;color:#ff0;', toJS(slot));

    return (
      <div
        className={cx(styles.slotWrapper, this.borderColor, {
          isOpenSlot: this.isOpenSlot,
          isSelected,
          singleSlot: !inDialog && !this.isColivingFinalizedSlot,
          withBorder: !inDialog && (this.isColivingFinalizedSlot || this.isStaffView)
        })}
      >
        <div
          className={cx(styles.line, { isSelected, inDialog })}
          onClick={this._onClickSlot}
        >
          {
            inDialog &&
            <SvgIcon
              className={cx(styles.radioIcon, { isSelected })}
              icon={isSelected ? SvgIconName.SmallTick2Selected : SvgIconName.SmallTick2Empty}
            />
          }
          <div className={styles.slotLinesWrapper}>

            <div className={cx(styles.slotLine, styles.slotLine1)}>
              {/*{DebugUtil.text('Lx') && <div className={styles.lineN}>L1</div>}*/}
              {this._renderName(true)}
              {this._renderDate(true)}
            </div>
            {
              (
                (inDialog && isSelected && slot.staff) ||
                (!inDialog && this.isCandidateView && slot.staff) ||
                (!inDialog && this.isStaffView && (slot.next_staff || slot.candidate))
              ) &&
              <div className={cx(styles.slotLine, styles.slotLine2)}>
                {/*{DebugUtil.text('Lx') && <div className={styles.lineN}>L2</div>}*/}
                {this._renderName(false)}
                {this._renderDate(false)}
              </div>
            }
          </div>

        </div>

        {this._renderProposedCandidates()}

      </div>
    );
  }

  // ---------------------------------------------------------
}
