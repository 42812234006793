import type { FetchResult } from '@apollo/client/link/core';
import { CommonController } from '@flow/common/CommonController';
import { PositionItem } from '@flow/common/components/elements/PositionItem';
import { AuthState } from '@flow/common/controllers/AuthState';
import { DateUtil } from '@flow/common/utils/DateUtil';
import {
  UpdateSlotFieldsDocument,
  UpdateSlotFieldsMutation,
  UpdateSlotFieldsMutationVariables
} from '@flow/data-access/lib/candidate.generated';
import type { UpdateSlotsMutation, UpdateSlotsMutationVariables } from '@flow/data-access/lib/staffMember.generated';
import { UpdateSlotsDocument } from '@flow/data-access/lib/staffMember.generated';
import type {
  Staffing_Customer_Team_Slot,
  Staffing_Customer_Team_Slot_Insert_Input
} from '@flow/data-access/lib/types/graphql.generated';
import { Staffing_Customer_Team_Slot_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';

import { controller, di } from '@flow/dependency-injection';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { StaffMemberController } from '@flow/modules/staffing/staff/StaffMemberController';
import { StaffMemberState } from '@flow/modules/staffing/staff/StaffMemberState';
import { StaffState } from '@flow/modules/staffing/staff/StaffState';
import { action, runInAction, toJS } from 'mobx';
import React from 'react';
import { StaffMemberAllocationState } from './StaffMemberAllocationState';

@controller
export class StaffMemberAllocationController
{
  @di private _authState!:AuthState;
  @di private _staffMemberState!:StaffMemberState;
  @di private _staffMemberController!:StaffMemberController;
  @di private _staffState!:StaffState;
  @di private _staffMemberAllocationState!:StaffMemberAllocationState;

  @di private _commonController!:CommonController;

  // ----------------------------------------------------

  @action.bound
  public showAddPositionDialog(isEdit:boolean = false, slot?:Staffing_Customer_Team_Slot):void
  {
    const { staffMember } = this._staffMemberState;

    this._staffMemberAllocationState.isEditPosition = isEdit;
    this._staffMemberAllocationState.editedPositionId = slot?.position.id || null;
    this._staffMemberAllocationState.isScrolledToPosition = false;

    this._staffMemberAllocationState.selectedPositionId = slot?.position.id || null;
    this._staffMemberAllocationState.selectedPositionItemRef = null;
    this._staffMemberAllocationState.selectedSlot = slot || null;

    this._staffMemberAllocationState.isAddPositionDialogOpen = true;
    this._staffMemberAllocationState.isAddPositionLoading = false;

    if( isEdit && slot )
    {
      this._staffMemberAllocationState.selectedDate = slot.staff?.id === staffMember?.id ? slot.start_date
        : slot.next_staff?.id === staffMember?.id ? slot.next_staff_start_date : '';
    }
    else
    {
      this._staffMemberAllocationState.selectedDate = null;
      // this._staffMemberAllocationState.selectedDate = moment().add(1, 'day').toISOString();
    }
  }

  @action.bound
  public hideAddPositionDialog():void
  {
    this._staffMemberAllocationState.isAddPositionDialogOpen = false;
    this._staffMemberAllocationState.isAddPositionLoading = false;
  }

  // ----------------------------------------------------

  @action.bound
  public async updateSlotLeavingDate(newLeavingDate:string | null):Promise<void>
  {
    const { selectedLeavingDateSlotId } = this._staffMemberAllocationState;

    console.log('%c updateSlotLeavingDate =', 'background:#0f0;color:#000;', newLeavingDate);

    if( !selectedLeavingDateSlotId ) return;

    const updateResult:FetchResult<UpdateSlotFieldsMutation> =
      await this._commonController.mutate<UpdateSlotFieldsMutation,
        UpdateSlotFieldsMutationVariables>({
        mutation: UpdateSlotFieldsDocument,
        variables: {
          slotId: selectedLeavingDateSlotId,
          slot: {
            leaving_date: newLeavingDate
          }
        }
      });

    console.log('%c updateSlotLeavingDate result =', 'background:#f60;color:#000;', updateResult);

    this.setSelectedLeavingDate(null);
    this.setSelectedLeavingDateSlotId(null);

    if( updateResult.errors )
    {
      console.error('%c ERROR: updateSlotLeavingDate =', 'background:#f00;color:#ff0;', updateResult.errors);
      throw updateResult.errors[0];
    }

    // UPDATE leaving_date in position slot until subscribed to changes
    // TODO: REMOVE after the implementation of subscriptions

    const slot:Staffing_Customer_Team_Slot | undefined =
      this._staffMemberController.getStaffMemberSlotById(selectedLeavingDateSlotId);

    if( !slot ) return;

    const slotPosition:Recruiting_Position_Ex | undefined =
      this._staffMemberAllocationState.positionById(slot.position_id);

    if( !slotPosition ) return;

    runInAction(() =>
    {
      slotPosition.customer_team_slots?.forEach((slot:Staffing_Customer_Team_Slot) =>
      {
        if( slot.id === selectedLeavingDateSlotId )
        {
          slot.leaving_date = newLeavingDate;
        }
      });
    });
  }

  // ----------------------------------------------------

  @action.bound
  public setSelectedLeavingDate(newValue:string | null):void
  {
    this._staffMemberAllocationState.selectedLeavingDate = newValue;
  }

  @action.bound
  public setSelectedLeavingDateSlotId(newValue:number | null):void
  {
    this._staffMemberAllocationState.selectedLeavingDateSlotId = newValue;
  }

  // ----------------------------------------------------

  @action.bound
  public setSelectedPositionId(positionId:number, positionItemRef:React.RefObject<PositionItem>):void
  {
    const { selectedPositionId, selectedPositionItemRef } = this._staffMemberAllocationState;

    if( selectedPositionId === positionId ) return;

    selectedPositionItemRef?.current?.setHiddenBlockVisibility(false);

    this._staffMemberAllocationState.selectedPositionId = positionId;
    this._staffMemberAllocationState.selectedPositionItemRef = positionItemRef;

    this.proposeSlotForFinalize();
  }

  // ----------------------------------------------------

  @action.bound
  public proposeSlotForFinalize():void
  {
    const { selectedPositionId, editedPositionCurrentSlot } = this._staffMemberAllocationState;

    if( !selectedPositionId ) return;

    const selectedPosition:Recruiting_Position_Ex | undefined = this._staffMemberAllocationState.positionById(selectedPositionId);

    if( !selectedPosition ) return;

    if( editedPositionCurrentSlot && selectedPositionId === editedPositionCurrentSlot.position.id )
    {
      this._staffMemberAllocationState.selectedSlot = Object.assign({}, editedPositionCurrentSlot);
      return;
    }

    const anyOpenSlot:Staffing_Customer_Team_Slot | undefined = selectedPosition.customer_team_slots
      .filter((slot:Staffing_Customer_Team_Slot) =>
      {
        return !slot.next_staff && !slot.next_candidate_id;
      })
      .sort((a, b) => b.id - a.id)
      .sort((slot:Staffing_Customer_Team_Slot) =>
      {
        return slot.status == Staffing_Customer_Team_Slot_Status_Enum.Open ? -1 : 0;
      })
      .find((slot:Staffing_Customer_Team_Slot) =>
      {
        return slot.status === Staffing_Customer_Team_Slot_Status_Enum.Open;
      });

    if( anyOpenSlot )
    {
      console.log('%c proposeSlotForFinalize anyOpenSlot =', 'background:#ccf;color:#000;', toJS(anyOpenSlot));
      this._staffMemberAllocationState.selectedSlot = Object.assign({}, anyOpenSlot);
    }
    else
    {
      const availableSlots:Array<Staffing_Customer_Team_Slot> = selectedPosition.customer_team_slots
        .filter((slot:Staffing_Customer_Team_Slot) => !slot.next_staff && !slot.next_candidate_id);

      console.log('%c proposeSlotForFinalize availableSlots[0] =', 'background:#ccf;color:#000;', toJS(availableSlots[0]));
      this._staffMemberAllocationState.selectedSlot = Object.assign({}, availableSlots[0]);
    }
  }

  // ----------------------------------------------------

  public canRemoveAndEditSlot(slot:Staffing_Customer_Team_Slot, staffId?:number):boolean
  {
    return (slot.staff?.id === staffId && DateUtil.isAfterToday(slot.start_date)) ||
      (slot.next_staff?.id === staffId); // && DateUtil.isAfterToday(slot.next_staff_start_date)
  }

  // ----------------------------------------------------

  @action.bound
  public showRemovePositionDialog(slot:Staffing_Customer_Team_Slot):void
  {
    this._staffMemberAllocationState.isRemovePositionDialogOpen = true;
    this._staffMemberAllocationState.slotForRemove = slot;
  }

  @action.bound
  public hideRemovePositionDialog():void
  {
    this._staffMemberAllocationState.isRemovePositionDialogOpen = false;
    this._staffMemberAllocationState.slotForRemove = null;
  }

  // ----------------------------------------------------

  private fromSlot(slot:Staffing_Customer_Team_Slot, isEmpty:boolean):Staffing_Customer_Team_Slot_Insert_Input
  {
    return {
      id: slot.id,
      position_id: slot.position_id, // non-null violation

      status: isEmpty ? Staffing_Customer_Team_Slot_Status_Enum.Open : slot.status,

      staff_id: isEmpty ? null : slot.staff_id,
      start_date: isEmpty ? null : DateUtil.getISODay(slot.start_date),
      leaving_date: isEmpty ? null : DateUtil.getISODay(slot.leaving_date),

      next_staff_id: isEmpty ? null : slot.next_staff_id,
      next_staff_start_date: isEmpty ? null : DateUtil.getISODay(slot.next_staff_start_date),

      next_candidate_id: isEmpty ? null : slot.next_candidate_id,
      next_candidate_start_date: isEmpty ? null : DateUtil.getISODay(slot.next_candidate_start_date),
      next_candidate_allocation_id: isEmpty ? null : slot.next_candidate_allocation_id
    };
  }

  // ----------------------------------------------------

  @action.bound
  public async updateSlots(slotInputs:Array<Staffing_Customer_Team_Slot_Insert_Input>):Promise<void>
  {
    const result:FetchResult<UpdateSlotsMutation> =
      await this._commonController.mutate<UpdateSlotsMutation,
        UpdateSlotsMutationVariables>({
        mutation: UpdateSlotsDocument,
        variables: {
          slots: slotInputs
        }
      });

    console.log('%c updateSlots result =', 'background:#f60;color:#000;', result);

    if( result.errors )
    {
      console.error('%c ERROR: updateSlots =', 'background:#f00;color:#ff0;', result.errors);
      throw result.errors[0];
    }

    this._staffMemberController.initStaffMember();
  }

  // ----------------------------------------------------

  @action.bound
  public async addPosition():Promise<void>
  {
    const { staffMember } = this._staffMemberState;
    const {
      isEditPosition,
      selectedSlot, selectedDate: _selectedDate,
      editedPositionCurrentSlot
    } = this._staffMemberAllocationState;

    console.group('addPosition');
    console.log('%c addPosition     =', 'background:#f0f;color:#ff0;');
    console.log('%c slot =', 'background:#0f0;color:#000;', toJS(selectedSlot));

    if( !selectedSlot || !staffMember || !_selectedDate )
    {
      console.error('%c ERROR: addPosition 1 =', 'background:#f00;color:#ff0;');
      console.groupEnd();
      return;
    }
    const isAddPosition:boolean = !isEditPosition;
    let isOtherSlotSelected:boolean = false;

    const slotInputs:Array<Staffing_Customer_Team_Slot_Insert_Input> = [];

    const emptySlotInput:Staffing_Customer_Team_Slot_Insert_Input = this.fromSlot(selectedSlot, true);
    const selectedSlotInput:Staffing_Customer_Team_Slot_Insert_Input = this.fromSlot(selectedSlot, false);

    const selectedDate:string | null = DateUtil.getISODay(_selectedDate);

    // -----------------------------------
    if( isEditPosition && editedPositionCurrentSlot )
    {
      console.log('%c -> [edit] =', 'background:#080;color:#000;');
      // edit current slot
      if( editedPositionCurrentSlot.id === selectedSlot.id )
      {
        console.log('%c -> [edit] & current slot... =', 'background:#080;color:#000;');

        // as staff, ... && next staff, ... && next candidate
        if( selectedSlot.staff?.id === staffMember.id )
        {
          console.log('%c -> [edit] & as staff - edit date only =', 'background:#080;color:#000;');
          slotInputs.push({
            ...selectedSlotInput,
            start_date: selectedDate
          });
        }
        // as next staff
        else if( selectedSlot.next_staff?.id === staffMember.id )
        {
          console.log('%c -> [edit] & as next_staff - edit date only =', 'background:#080;color:#000;');
          slotInputs.push({
            ...selectedSlotInput,
            next_staff_start_date: selectedDate
          });
        }
      }
      // selected other slot
      else
      {
        console.log('%c -> [edit] & OTHER slot selected =', 'background:#080;color:#000;');
        isOtherSlotSelected = true;
        this._staffMemberAllocationState.slotForRemove = editedPositionCurrentSlot;

        const removedSlotInputs:Array<Staffing_Customer_Team_Slot_Insert_Input> =
          await this.removePosition(true) || [];

        if( removedSlotInputs.length )
        {
          slotInputs.push(...removedSlotInputs);
        }
      }
    }

    // -----------------------------------
    if( isAddPosition || isOtherSlotSelected )
    {
      console.log('%c -> [add] =', 'background:#080;color:#000;');

      if( isOtherSlotSelected )
      {
        console.log('%c -> [add] & isOtherSlotSelected =', 'background:#080;color:#000;');
      }

      // as staff
      if( selectedSlot.status === Staffing_Customer_Team_Slot_Status_Enum.Open )
      {
        console.log('%c -> [add] & OPEN slot =', 'background:#080;color:#000;');
        slotInputs.push({
          ...emptySlotInput,
          // or ...selectedSlotInput,
          status: Staffing_Customer_Team_Slot_Status_Enum.CurrentStaff,
          staff_id: staffMember.id,
          start_date: selectedDate
        });
      }
      else // as next_staff
      {
        console.log('%c -> [add] & as next_staff =', 'background:#080;color:#000;');
        slotInputs.push({
          ...selectedSlotInput,
          next_staff_id: staffMember.id,
          next_staff_start_date: selectedDate
        });
      }
    }
    // -----------------------------------
    // else
    // {
    //   console.error('%c ERROR: addPosition 2 =', 'background:#f00;color:#ff0;');
    // }

    console.log('%c from =', 'background:#080;color:#000;');
    console.table([selectedSlotInput]);
    console.log('%c to   =', 'background:#080;color:#000;');
    console.table([...slotInputs]);

    await this.updateSlots(slotInputs);

    this.hideAddPositionDialog();
    console.groupEnd();
  }

  // ----------------------------------------------------

  @action.bound
  public async removePosition(returnOnlySlotInputs?:boolean):Promise<void | Array<Staffing_Customer_Team_Slot_Insert_Input>>
  {
    const { staffMember } = this._staffMemberState;
    const { slotForRemove: slot } = this._staffMemberAllocationState;

    console.group('removePosition');
    console.log('%c -> removePosition     =', 'background:#f0f;color:#ff0;');
    console.log('%c slot =', 'background:#0f0;color:#000;', toJS(slot));

    if( !slot || !staffMember )
    {
      console.error('%c ERROR: removePosition 1 =', 'background:#f00;color:#ff0;');
      console.groupEnd();
      return;
    }

    const slotInputs:Array<Staffing_Customer_Team_Slot_Insert_Input> = [];

    const emptySlot:Staffing_Customer_Team_Slot_Insert_Input = this.fromSlot(slot, true);
    const currentSlot:Staffing_Customer_Team_Slot_Insert_Input = this.fromSlot(slot, false);

    // (v) staff from page == staff on slot
    // (v) start_date in future
    if( slot.staff && slot.staff.id === staffMember.id && DateUtil.isAfterToday(slot.start_date) )
    {
      console.log('%c -> [remove] - as staff... =', 'background:#080;color:#000;');

      if( !slot.next_staff && !slot.candidate ) // clear staff
      {
        console.log('%c -> [remove] - & staff only - clear staff =', 'background:#080;color:#000;');
        slotInputs.push({ ...emptySlot });
      }
      if( slot.next_staff ) // next_staff -> staff
      {
        console.log('%c -> [remove] - & next_staff - move next_staff to staff =', 'background:#080;color:#000;');
        slotInputs.push({
          ...emptySlot,
          status: Staffing_Customer_Team_Slot_Status_Enum.CurrentStaff,
          staff_id: slot.next_staff_id,
          start_date: slot.next_staff_start_date
          // or
          // ...currentSlot,
          // staff_id: slot.next_staff_id,
          // start_date: slot.next_staff_start_date
          // leaving_date: null,
          // next_staff_id: null,
          // next_staff_start_date: null,
        });
      }
      else if( slot.candidate ) // clear staff
      {
        console.log('%c -> [remove] - & candidate - clear staff =', 'background:#080;color:#000;');
        slotInputs.push({
          ...currentSlot,
          status: Staffing_Customer_Team_Slot_Status_Enum.Open,
          staff_id: null,
          start_date: null,
          leaving_date: null
          // or
          // ...emptySlot,
          // next_candidate_id: slot.next_candidate_id,
          // next_candidate_start_date: slot.next_candidate_start_date,
          // next_candidate_allocation_id: slot.next_candidate_allocation_id
        });
      }
    }
      // (v) staff from page == next_staff on slot
      // (v) any date
    // (x) next_staff_start_date in future
    else if( slot.next_staff && slot.next_staff.id === staffMember.id ) // && DateUtil.isAfterToday(slot.next_staff_start_date)
    {
      console.log('%c -> [remove] - as next_staff - clear next_staff =', 'background:#080;color:#000;');
      // clear next_staff
      slotInputs.push({
        ...currentSlot,
        next_staff_id: null,
        next_staff_start_date: null
      });
    }
    else
    {
      console.error('%c ERROR: removePosition 2 =', 'background:#f00;color:#ff0;');
    }

    console.log('%c from[0] -> to[1] =', 'background:#080;color:#000;');
    console.table([currentSlot, ...slotInputs]);
    console.groupEnd();

    if( returnOnlySlotInputs )
    {
      return slotInputs;
    }

    await this.updateSlots(slotInputs);

    this.hideRemovePositionDialog();
  }

  // ----------------------------------------------------
}
