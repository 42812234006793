import { Spinner as BPSpinner, SpinnerSize as BPSpinnerSize } from '@blueprintjs/core';
import type { IconName } from '@flow/common/components/form/Icon';
import { IconNames } from '@flow/common/components/form/Icon';
import { SpinnerIcon } from '@flow/common/components/form/SpinnerIcon';
import classNames from 'classnames/bind';
import * as React from 'react';
import type { Intent } from '../types/Types';
import { getBPIntent } from '../types/Types';

import styles from './Spinner.module.less';

const cx = classNames.bind(styles);

export enum SpinnerSize
{
  SMALL = BPSpinnerSize.SMALL,
  STANDARD = BPSpinnerSize.STANDARD,
  LARGE = BPSpinnerSize.LARGE
}

export interface SpinnerProps
{
  className?:string;
  icon?:IconName;
  size?:SpinnerSize | number;

  useBPSpinner?:boolean;
  value?:number;
  intent?:Intent;

  noIcon?:boolean;
  withWave?:boolean;
  withWrapper?:boolean;
  wrapperClassName?:string;
}

export class Spinner extends React.PureComponent<SpinnerProps>
{
  private _getBPSpinnerOrIcon():React.ReactNode
  {
    const {
      className,
      intent,
      size,
      value,
      useBPSpinner,
      icon = IconNames.REFRESH
    } = this.props;

    if( useBPSpinner )
    {
      return (
        <BPSpinner
          className={cx(styles.icon, className)}
          intent={getBPIntent(intent)}
          size={size}
          value={value}
        />
      );
    }

    return (
      <SpinnerIcon
        className={cx(styles.spinnerIcon, className)}
        icon={icon}
        size={size as number}
      />
    );
  }

  public render():React.ReactNode | null
  {
    const { withWrapper, wrapperClassName, noIcon, withWave } = this.props;

    if( !withWrapper ) return noIcon ? null : this._getBPSpinnerOrIcon();

    return (
      <div className={cx(styles.wrapper, wrapperClassName, { withWave })}>
        {!noIcon && this._getBPSpinnerOrIcon()}
      </div>
    );
  }
}
