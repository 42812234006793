import { Radio, RadioGroup } from '@blueprintjs/core';
import { ButtonSize } from '@flow/common/components/form/Button';
import { TagInput } from '@flow/common/components/form/TagInput';
import { TextArea } from '@flow/common/components/form/TextArea';
import { TextInput } from '@flow/common/components/form/TextInput';
import { Dialog } from '@flow/common/components/page/Dialog';
import { DialogRow } from '@flow/common/components/page/DialogRow';
import { Intent } from '@flow/common/components/types/Types';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import type { ReactNode } from 'react';
import React from 'react';
import { TeamController } from '../../TeamController';
import { TeamState } from '../../TeamState';

import styles from './TeamDialog.module.less';

export interface ITeamDialogProps
{
  onSave?:(teamId:number) => Promise<void>;
}

@component
export class TeamDialog extends React.Component<ITeamDialogProps>
{
  @di private _teamState!:TeamState;
  @di private _teamController!:TeamController;

  @bind
  private async _saveTeam():Promise<void>
  {
    const { onSave } = this.props;

    const id:number = await this._teamController.saveTeam();

    if( onSave )
      return onSave(id) as Promise<void>;
  }

  public render():ReactNode
  {
    const {
      isTeamDialogOpen,
      isTeamLoading,
      teamId,
      teamName,
      teamTags,
      teamNotes,
      isTeamActive,
      isTeamValid
    } = this._teamState;

    return (
      <Dialog
        title={teamId > 0 ? 'Edit Team' : 'New Team'}
        isOpen={isTeamDialogOpen}
        isLoading={isTeamLoading}
        onClose={this._teamController.hideTeamDialog}
        className={styles.newCustomerDialog}

        buttonsSize={ButtonSize.LARGE}

        primaryButtonText={teamId > 0 ? 'Save' : 'Create'}
        primaryButtonIntent={Intent.PRIMARY}
        primaryButtonOnClick={this._saveTeam}
        isPrimaryButtonDisabled={!isTeamValid}

        secondaryButtonText={'Cancel'}
        secondaryButtonIntent={Intent.PRIMARY}
        isSecondaryButtonOutlined={true}
      >
        <DialogRow label={'Name'}>
          <TextInput
            value={teamName}
            large={true}
            onChange={this._teamController.setTeamName}
            autoFocus={true}
          />
        </DialogRow>

        <DialogRow label={'Tags'}>
          <TagInput
            values={teamTags}
            large={true}
            fill={true}
            onChange={this._teamController.setTeamTags}
          />
        </DialogRow>

        <DialogRow label={'Status'}>
          <RadioGroup
            inline={true}
            selectedValue={isTeamActive ? 'Active' : 'Prospective'}
            onChange={this._teamController.setIsTeamActive1}
          >
            <Radio
              className={styles.radio}
              label={'Prospective'}
              value={'Prospective'}
              name={'teamActiveOrProspective'}
            />
            <Radio
              className={styles.radio}
              label={'Active'}
              value={'Active'}
              name={'teamActiveOrProspective'}
            />
          </RadioGroup>
        </DialogRow>

        <DialogRow label={'Notes'}>
          <TextArea
            value={teamNotes}
            large={true}
            fill={true}
            onChange={this._teamController.setTeamNotes}
          />
        </DialogRow>

      </Dialog>
    );
  }
}
