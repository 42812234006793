import type { ApolloQueryResult } from '@apollo/client';
import type { FetchResult } from '@apollo/client/link/core';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { LocalStorageUtil } from '@flow/common/utils/LocalStorageUtil';
import type {
  ChangeCustomerStatusMutation,
  ChangeCustomerStatusMutationVariables,
  CreateCustomerMutation,
  CreateCustomerMutationVariables,
  GetCustomerNoNameQuery,
  GetCustomerNoNameQueryVariables,
  GetCustomerQuery,
  GetCustomerQueryVariables,
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
} from '@flow/data-access/lib/customers.generated';
import {
  ChangeCustomerStatusDocument,
  CreateCustomerDocument,
  GetCustomerDocument,
  GetCustomerNoNameDocument,
  UpdateCustomerDocument
} from '@flow/data-access/lib/customers.generated';
import type { Common_Customer } from '@flow/data-access/lib/types/graphql.generated';
import { Common_Customer_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import { action, computed, reaction, runInAction } from 'mobx';
import type { FormEvent } from 'react';
import { CustomerState, CustomerState_LS_KEY, TeamFilter } from './CustomerState';
import type { Common_Customer_Ex, Recruiting_Position_Ex, Staffing_Customer_Team_Ex } from './models/CustomersTypes';

@controller
export class CustomerController
{
  @di private _commonState!:CommonState;
  @di private _commonController!:CommonController;
  @di private _customerState!:CustomerState;
  @di private _roleController!:RoleController;

  // ----------------------------------------------------

  @action.bound
  public initFromLocalStorage():void
  {
    const lsState:Partial<CustomerState> = LocalStorageUtil.getItem(CustomerState_LS_KEY);

    if( lsState )
    {
      this._customerState.teamFilterActiveProspective = lsState.teamFilterActiveProspective || TeamFilter.Active;
    }

    const { disposers } = this._customerState;

    Array.isArray(disposers) && disposers.push(reaction(
      () =>
      {
        const {
          teamFilterActiveProspective
        } = this._customerState;

        return {
          teamFilterActiveProspective
        };
      },
      (lsValue:Partial<CustomerState>) =>
      {
        console.log('%c CustomerState: lsValue =', 'background:#0ff;color:#000;', lsValue);
        LocalStorageUtil.setItem(CustomerState_LS_KEY, lsValue);
      }
    ));
  }

  // ----------------------------------------------------

  @action.bound
  public async initCustomer(customerId:number):Promise<void>
  {
    this._customerState.pageNotFound = false;

    const result:ApolloQueryResult<GetCustomerQuery | GetCustomerNoNameQuery> =
      this._roleController.hasPermission(FlowPermissions.ViewCustomerName)
        ? await this._commonController.query<GetCustomerQuery,
          GetCustomerQueryVariables>({
          query: GetCustomerDocument,
          variables: {
            customerId
          }
        })
        : await this._commonController.query<GetCustomerNoNameQuery,
          GetCustomerNoNameQueryVariables>({
          query: GetCustomerNoNameDocument,
          variables: {
            customerId
          }
        });

    console.log(`%c initCustomer: ${customerId} result =`, 'background:#0f0;color:#000;', result);

    if( !result.data.common_customer_by_pk )
    {
      runInAction(() => this._customerState.pageNotFound = true);
      return;
    }

    runInAction(() =>
    {
      this._customerState.customer = result.data.common_customer_by_pk as Common_Customer_Ex;
    });
  }

  // ----------------------------------------------------

  @action.bound
  public showCreateCustomerDialog():void
  {
    this._customerState.isCustomerDialogOpen = true;
    this._customerState.customerId = -1;
    this._customerState.customerName = '';
    this._customerState.customerAlias = '';
    this._customerState.customerStatus = Common_Customer_Status_Enum.Prospective;
    this._customerState.isCustomerInternal = false;
  }

  @action.bound
  public hideCustomerDialog():void
  {
    this._customerState.isCustomerDialogOpen = false;
    this._customerState.isCustomerLoading = false;
  }

  @action.bound
  public setCustomerName(newCustomerName:string):void
  {
    this._customerState.customerName = newCustomerName;
  }

  @action.bound
  public setCustomerAlias(newCustomerAlias:string):void
  {
    this._customerState.customerAlias = newCustomerAlias;
  }

  @action.bound
  public setCustomerStatus(event:FormEvent<HTMLInputElement>):void
  {
    const { value } = event.currentTarget;

    this._customerState.customerStatus = value === 'Active'
      ? Common_Customer_Status_Enum.Current
      : Common_Customer_Status_Enum.Prospective;
  }

  @action.bound
  public editCustomer(customer:Common_Customer):void
  {
    this._customerState.isCustomerDialogOpen = true;
    this._customerState.customerId = customer.id;
    this._customerState.customerName = customer.name;
    this._customerState.customerAlias = customer.alias || '';
    this._customerState.customerStatus = customer.status;
    this._customerState.isCustomerInternal = customer.is_internal;
  }

  @action.bound
  public async saveCustomer():Promise<number>
  {
    let customerId:number = this._customerState.customerId;
    const name:string = this._customerState.customerName.trim();
    const alias:string = this._customerState.customerAlias.trim();
    const status:Common_Customer_Status_Enum = this._customerState.customerStatus || Common_Customer_Status_Enum.Prospective;

    console.log('%c saveCustomer =', 'background:#aaf;color:#000;', name, alias);

    if( !name || !alias )
      return -1;

    this._customerState.isCustomerLoading = true;

    if( customerId > 0 )
    {
      const result:FetchResult<UpdateCustomerMutation> =
        await this._commonController.mutate<UpdateCustomerMutation,
          UpdateCustomerMutationVariables>({
          mutation: UpdateCustomerDocument,
          variables: {
            customerId,
            name,
            alias,
            status
          }
        });

      console.log('%c updateCustomer result =', 'background:#0f0;color:#000;', result);

      customerId = result.data?.update_common_customer_by_pk?.id || -1;
    }
    else
    {
      const result:FetchResult<CreateCustomerMutation> =
        await this._commonController.mutate<CreateCustomerMutation,
          CreateCustomerMutationVariables>({
          mutation: CreateCustomerDocument,
          variables: {
            name,
            alias,
            status
          }
        });

      console.log('%c createCustomer result =', 'background:#0f0;color:#000;', result);

      customerId = result.data?.insert_common_customer_one?.id || -1;
    }

    this.hideCustomerDialog();

    return customerId;
  }

  @bind
  public async onCustomerSave(customerId:number):Promise<void>
  {
    return this.initCustomer(customerId);
  }

  @bind
  public async changeCustomerStatus(customer:Common_Customer, status:Common_Customer_Status_Enum):Promise<number>
  {
    const result:FetchResult<ChangeCustomerStatusMutation> =
      await this._commonController.mutate<ChangeCustomerStatusMutation,
        ChangeCustomerStatusMutationVariables>({
        mutation: ChangeCustomerStatusDocument,
        variables: {
          customerId: customer.id,
          status
        }
      });

    console.log('%c changeCustomerStatus result =', 'background:#0f0;color:#000;', result);

    return result?.data?.update_common_customer_by_pk?.id || -1;
  }

  // ----------------------------------------------------

  @computed
  public get orderedTeams():Array<Staffing_Customer_Team_Ex>
  {
    const { customer } = this._customerState;

    if( !customer )
      return [];

    return (customer.customer_teams as Array<Staffing_Customer_Team_Ex>)
      .filter(team => this._customerState.teamFilterActiveProspective === 'active' ? team.is_active : !team.is_active)
      .sort((a, b) => (a.ui_order || 0) - (b.ui_order || 0));
  }

  @bind
  public activeTeamIndexById(teamId:number):number
  {
    const { customer } = this._customerState;

    if( !customer?.customer_teams ) return -1;
    return this.orderedTeams.findIndex((team) => team.id === teamId);
  }

  // ----------------------------------------------------

  @bind
  public orderedTeamPositions(teamId:number):Array<Recruiting_Position_Ex>
  {
    const { customer } = this._customerState;

    if( !customer )
      return [];

    const team = this._customerState.findCustomerTeamById(teamId);

    if( !team )
      return [];

    return (team.positions as Array<Recruiting_Position_Ex>)
      .slice().sort((a, b) => (a.ui_customer_team_order || 0) - (b.ui_customer_team_order || 0));
  }
}
