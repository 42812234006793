import { component } from '@flow/dependency-injection';
import React from 'react';
import { Outlet } from 'react-router-dom';

@component
export class SettingsRootPage extends React.Component
{
  // ----------------------------------------------------

  public render():React.ReactNode
  {
    return (
      <Outlet />
    );
  }

  // ----------------------------------------------------
}
