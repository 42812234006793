import { Tooltip2 } from '@blueprintjs/popover2';
import { CommonController } from '@flow/common/CommonController';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { ShowArrayAsStringPlusX } from '@flow/common/components/elements/ShowArrayAsStringPlusX';
import { SvgIcon, SvgIconName } from '@flow/common/components/elements/SvgIcon';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { Maybe } from '@flow/common/models/Types';
import { DateUtil } from '@flow/common/utils/DateUtil';
import { DebugUtil } from '@flow/common/utils/DebugUtil';
import { PositionsUtil } from '@flow/common/utils/PositionsUtil';
import { StringUtil } from '@flow/common/utils/StringUtil';
import type {
  Common_Scheduled_Event,
  Common_User,
  Recruiting_Candidate,
  Recruiting_Candidate_Allocation,
  Recruiting_Candidate_Scheduled_Event,
  Recruiting_Position,
  Staffing_Customer_Team_Slot,
  Staffing_Staff
} from '@flow/data-access/lib/types/graphql.generated';
import { Common_Customer_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { ConvertToStaffState } from '@flow/modules/recruiting/common/convertToStaff/ConvertToStaffState';
import { InterviewFlowsController } from '@flow/modules/recruiting/status/InterviewFlowsController';
import { InterviewFlowsState } from '@flow/modules/recruiting/status/InterviewFlowsState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { computed, toJS } from 'mobx';
import moment from 'moment';
import type { ReactNode } from 'react';
import React from 'react';
import type { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

import styles from './CandidateCardContent.module.less';

const cx = classNames.bind(styles);

// TODO: remove
const __DEBUG_PROPOSED:boolean = false;
const __DEBUG_OTHER:boolean = false;

export interface ICandidateCardProps
{
  candidate:Recruiting_Candidate;
  index:number;
  interviewFlowGroupId:string;
  provided:DraggableProvided;
  snapshot:DraggableStateSnapshot;
}

@component
export class CandidateCardContent extends React.Component<ICandidateCardProps>
{
  @di private _commonController!:CommonController;
  @di private _interviewFlowsState!:InterviewFlowsState;
  @di private _interviewFlowsController!:InterviewFlowsController;
  @di private _convertToStaffState!:ConvertToStaffState;

  // ----------------------------------------------------

  @computed
  private get _isDraggingInAnotherGroup():boolean
  {
    const { candidate, interviewFlowGroupId } = this.props;
    const { draggingInterviewFlowGroupId, draggingCandidateId } = this._interviewFlowsState;

    return interviewFlowGroupId !== draggingInterviewFlowGroupId
      && candidate.id === draggingCandidateId;
  }

  // ----------------------------------------------------

  @computed
  private get _isNowConvertingToStaff():boolean
  {
    const { candidate } = this.props;
    const { candidate: convertedCandidate } = this._convertToStaffState;

    return !!candidate && !!convertedCandidate && candidate.id === convertedCandidate.id;
  }

  // ----------------------------------------------------

  private _getFinalizedPosition():ReactNode | null
  {
    const { candidate } = this.props;

    const isCandidateOnLastStage:boolean = this._interviewFlowsController.isLastStage(candidate.interview_flow_stage_id);

    let finalizedPosition:Maybe<Recruiting_Position>;

    let colivingSlot:Maybe<Staffing_Customer_Team_Slot>;
    let colivingStaff:Maybe<Staffing_Staff>;
    let colivingCandidate:Maybe<Recruiting_Candidate>;

    // current candidate is...
    let isStaff:boolean = false;
    let isNextStaff:boolean = false;
    let isNextCandidate:boolean = false;

    // __DEBUG_OTHER && console.log('%c _getFinalizedPosition =', 'background:#0f0;color:#000;', candidate.id, toJS(candidate));

    // let userColor:string;
    // let userColorStr:string;

    if( isCandidateOnLastStage && candidate.staff )
    {
      isStaff = candidate.staff.customer_team_slots.length > 0;
      isNextStaff = candidate.staff.next_staff_slots.length > 0;

      if( isStaff )
      {
        colivingSlot = candidate.staff.customer_team_slots?.[0];
        colivingStaff = colivingSlot?.next_staff;
        colivingCandidate = colivingSlot?.candidate;
      }
      else if( isNextStaff )
      {
        colivingSlot = candidate.staff.next_staff_slots?.[0];
        colivingStaff = colivingSlot?.staff;
      }

      finalizedPosition = colivingSlot?.position;

      // userColor = isStaff ? '#0aa' : isNextStaff ? '#aaf' : '#faa';
      // userColorStr = `background: ${userColor}; color: #000`;
      // console.log('%c 11 -------------------------- = has staff || last stage =', userColorStr, !!candidate.staff, isCandidateOnLastStage);
    }
    else
    {
      const finalizedAllocationPosition:Maybe<Recruiting_Candidate_Allocation> =
        candidate.candidate_allocations.find(ca => ca.is_final && ca.finalized_slots.length > 0);

      finalizedPosition = finalizedAllocationPosition?.finalized_slots[0].position;

      colivingSlot = candidate.customer_team_slots.find(slot => !!slot.staff);
      colivingStaff = colivingSlot?.staff;

      isNextCandidate = !!colivingSlot;

      // userColor = isNextCandidate ? '#0a0' : '#faa';
      // userColorStr = `background: ${userColor}; color: #000`;
      // console.log('%c 22 -------------------------- else =', userColorStr);
    }

    // isNextCandidate && console.log('%c --> Next Candidate ', userColorStr);
    // (!isNextCandidate && !isStaff && !isNextStaff) && console.log('%c --> NO coliving... ', 'background: #faa; color: #000');
    // (isStaff || isNextStaff) && console.log('%c %s ', userColorStr, isStaff ? '-->          Staff' : isNextStaff ? '-->     Next Staff' : '--> NO staff');

    // console.log('%c candidate         =', userColorStr, candidate.id, candidate.first_name, candidate.last_name, toJS(candidate));
    // colivingSlot && console.log('%c colivingSlot      =', userColorStr, toJS(colivingSlot));

    // finalizedPosition && console.log('%c finalizedPosition =', userColorStr, toJS(finalizedPosition));

    // colivingStaff && console.log('%c colivingStaff     =', userColorStr, toJS(colivingStaff));
    // colivingCandidate && console.log('%c colivingCandidate =', userColorStr, toJS(colivingCandidate));

    if( !finalizedPosition ) return null;

    let isTeamError:boolean = false;
    if( finalizedPosition )
    {
      const pos = finalizedPosition;

      if( !pos.customer_team.is_active || pos.customer_team.deleted_at ||
        pos.customer_team.customer.status !== Common_Customer_Status_Enum.Current )
      {
        isTeamError = true;
      }
    }

    return (
      <div className={cx(styles.infoRow, { isTeamError })}>
        {
          isTeamError &&
          <Tooltip2
            content={
              <>
                {
                  finalizedPosition.customer_team.customer.status !== Common_Customer_Status_Enum.Current &&
                  <div>Customer is NOT ACTIVE</div>
                }
                {
                  !finalizedPosition.customer_team.is_active &&
                  <div>Team is NOT ACTIVE</div>
                }
                {
                  finalizedPosition.customer_team.deleted_at &&
                  <div>Team DELETED</div>
                }
              </>
            }
            placement={'top'}
            usePortal={true}
          >
            <Icon
              className={styles.isTeamErrorIcon}
              icon={IconNames.WARNING_SIGN}
              size={16}
            />
          </Tooltip2>
        }
        {/* TODO: -------------------------- */}

        <div className={styles.infoIconWrapper}>
          <Icon
            size={10}
            className={cx(styles.positionInfoIcon,
              {
                isBlue: !isStaff ||
                  (isStaff && !(colivingStaff || colivingCandidate) && !colivingSlot?.leaving_date)
              }
            )}
            icon={!isStaff
              ? IconNames.LOG_IN
              : isStaff && !(colivingStaff || colivingCandidate) && !colivingSlot?.leaving_date
                ? IconNames.OFFICE
                : colivingSlot?.leaving_date
                  ? IconNames.LOG_OUT
                  : IconNames.WARNING_SIGN}
          />
        </div>

        {/* TODO: -------------------------- */}

        <div className={styles.infoText}>

          <div className={styles.infoSingleTextLine}>
            {finalizedPosition.customer_team.customer.alias}
            <Icon
              className={styles.arrowIcon}
              icon={IconNames.ARROW_RIGHT}
              size={8}
            />
            {finalizedPosition.customer_team.name}
          </div>

          <div className={styles.positionRow}>

            <div className={cx(styles.infoSingleTextLine, styles.positionInfo)}>
              {PositionsUtil.getPositionName(finalizedPosition)}
            </div>

            {((colivingStaff || colivingCandidate) && colivingSlot) && <>
              <Tooltip2
                className={styles.tooltipWrapper}
                content={<>
                  <div className={styles.tooltipInfo}>
                    {
                      // current  = staff
                      // coliving = next staff | next candidate
                      (isStaff && colivingSlot) &&
                      <>
                        <span className={styles.userName}>
                          {colivingStaff && StringUtil.getUserName(colivingStaff.user)}
                          {colivingCandidate && StringUtil.getUserName(colivingCandidate as unknown as Common_User)}
                        </span>
                        {
                          (colivingSlot.next_staff_start_date || colivingSlot.next_candidate_start_date) &&
                          <> starts on {DateUtil.formatDay(
                            colivingSlot.next_staff_start_date || colivingSlot.next_candidate_start_date,
                            true
                          )}</>
                        }
                      </>
                    }
                    {
                      // current  = next staff | next candidate
                      // coliving = staff
                      ((isNextStaff || isNextCandidate) && colivingStaff) &&
                      <>
                        <span className={styles.userName}>
                          {StringUtil.getUserName(colivingStaff.user)}
                        </span>
                        {
                          colivingSlot.leaving_date &&
                          <> is leaving at {DateUtil.formatDay(colivingSlot.leaving_date)}</>
                        }
                        {
                          !colivingSlot.leaving_date &&
                          <> is going to leave<br />Leaving date expected</>
                        }
                      </>
                    }
                  </div>
                </>}
                placement={'top'}
                usePortal={true}
              >
                <div className={styles.tooltipIcons}>
                  <Avatar
                    className={styles.tooltipAvatar}
                    size={12}
                    url={colivingStaff
                      ? colivingStaff.avatar_url || colivingStaff.user.avatar
                      : colivingCandidate ? colivingCandidate.avatar_url : null}
                  />
                  <Icon
                    className={cx(styles.warnIcon, { isBlue: isStaff })}
                    icon={isStaff ? IconNames.LOG_IN : colivingSlot.leaving_date ? IconNames.LOG_OUT : IconNames.WARNING_SIGN}
                    size={10}
                  />
                </div>
              </Tooltip2>
            </>}

          </div>

        </div>
        {/* TODO: -------------------------- */}
      </div>
    );
  }

  // ----------------------------------------------------

  private _getProposedPositions():ReactNode
  {
    const { candidate } = this.props;

    const isCandidateOnLastStage:boolean = this._interviewFlowsController.isLastStage(candidate.interview_flow_stage_id);

    if( isCandidateOnLastStage ) return null;

    __DEBUG_PROPOSED && console.groupCollapsed('_getProposedPositions');

    const positionIds:Set<number> = new Set();
    const templateIds:Set<string> = new Set();

    const isUnique = (position:Recruiting_Position):boolean =>
    {
      const templateId:string = `${position.position_template_id}_${position.customer_team_id}`;

      if( positionIds.has(position.id)
        || !position.position_template_id
        || templateIds.has(templateId) )
      {
        positionIds.has(position.id) && __DEBUG_PROPOSED && console.log('%c --> position.id =', 'background:#faa;color:#000;', toJS(position.id));
        !position.position_template_id && __DEBUG_PROPOSED && console.log('%c --> position.position_template_id =', 'background:#faa;color:#000;', toJS(position.position_template_id));
        templateIds.has(templateId) && __DEBUG_PROPOSED && console.log('%c --> templateId =', 'background:#faa;color:#000;', toJS(templateId));
        return false;
      }

      __DEBUG_PROPOSED && console.log('%c --> position.id =', 'background:#080;color:#000;', toJS(position.id));
      __DEBUG_PROPOSED && console.log('%c --> templateId =', 'background:#080;color:#000;', toJS(templateId));
      // __DEBUG_PROPOSED && console.log('%c --> isUnique =', 'background:#faa;color:#000;', true);

      positionIds.add(position.id);
      templateIds.add(templateId);

      return true;
    };

    __DEBUG_PROPOSED && console.log('%c --> candidate_allocations =', 'background:#080;color:#000;', toJS(candidate.candidate_allocations));

    const someAllocationIsFinal:boolean = candidate.candidate_allocations.some(ca =>
    {
      __DEBUG_PROPOSED && console.log('%c ca             =', 'background:#f0f;color:#000;', toJS(ca));
      if( ca.is_final )
      {
        __DEBUG_PROPOSED && console.log('%c --> IS_FINAL =', 'background:#faa;color:#000;', toJS(ca));
        return true;
      }
      return false;
    });

    const allocationPositions:Array<Recruiting_Candidate_Allocation> = someAllocationIsFinal ? [] : candidate.candidate_allocations.filter(
      ca => ca.allocation_positions.filter(
        ap =>
        {
          __DEBUG_PROPOSED && console.log('%c ap      =', 'background:#f0f;color:#000;', toJS(ap));
          if( !isUnique(ap.position) ) return;

          if( ap.position.customer_team.customer.status !== Common_Customer_Status_Enum.Current )
          {
            __DEBUG_PROPOSED && console.log('%c --> customer.status =', 'background:#faa;color:#000;', toJS(ap.position.customer_team.customer.status));
            return;
          }

          if( (ap.position as Recruiting_Position_Ex).open_slots_count?.aggregate?.count === 0 )
          {
            const canBeColiving:boolean = ap.position.customer_team_slots.some(s => s.staff_id && !s.next_staff_id && !s.next_candidate_id);
            if( !canBeColiving )
            {
              __DEBUG_PROPOSED && console.log('%c --> open_slots_count =', 'background:#faa;color:#000;', toJS((ap.position as Recruiting_Position_Ex).open_slots_count?.aggregate?.count));
              return;
            }
            __DEBUG_PROPOSED && console.log('%c --> open_slots_count =', 'background:#080;color:#000;', toJS((ap.position as Recruiting_Position_Ex).open_slots_count?.aggregate?.count));
            __DEBUG_PROPOSED && console.log('%c --> canBeColiving =', 'background:#080;color:#000;', toJS(canBeColiving));
          }

          const someIsNextCandidate:boolean = ap.position.customer_team_slots.some(s => s.next_candidate_id === candidate.id);
          if( someIsNextCandidate )
          {
            __DEBUG_PROPOSED && console.log('%c --> someIsNextCandidate =', 'background:#faa;color:#000;', toJS(someIsNextCandidate));
            return;
          }

          const isCorrectPositionGroup:boolean = candidate.candidate_position_groups.some(pg =>
          {
            return pg.position_group_id === ap.position.position_group_id;
          });
          if( !isCorrectPositionGroup )
          {
            __DEBUG_PROPOSED && console.log('%c --> isCorrectPositionGroup =', 'background:#faa;color:#000;', toJS(isCorrectPositionGroup));
            return;
          }

          return true;
        }
      ).length > 0
    );

    __DEBUG_PROPOSED && console.log('%c --> ----------------------- =', 'background:#080;color:#000;');
    __DEBUG_PROPOSED && console.log('%c --> positionIds =', 'background:#080;color:#000;', toJS(positionIds));
    __DEBUG_PROPOSED && console.log('%c --> templateIds =', 'background:#080;color:#000;', toJS(templateIds));

    __DEBUG_PROPOSED && console.log('%c --> allocationPositions =', 'background:#080;color:#000;', toJS(allocationPositions));

    __DEBUG_PROPOSED && console.groupEnd();

    if( candidate.candidate_allocations.length > 0 )
    {
      if( allocationPositions.length )
      {
        if( candidate.candidate_allocations.length === allocationPositions.length )
        {
          __DEBUG_PROPOSED && console.log('%c _getProposedPositions =', 'background:#0f0;color:#000;', candidate.candidate_allocations.length, candidate.id, toJS(candidate));
          __DEBUG_PROPOSED && console.log('%c --> OK =', 'background:#0f0;color:#000;', allocationPositions.length);
        }
        else
        {
          __DEBUG_PROPOSED && console.log('%c _getProposedPositions =', 'background:#0f0;color:#000;', candidate.candidate_allocations.length, candidate.id, toJS(candidate));
          __DEBUG_PROPOSED && console.log('%c --> length !== =', 'background:#080;color:#000;', candidate.candidate_allocations.length, '!==', allocationPositions.length);
        }
      }
      else
      {
        __DEBUG_PROPOSED && console.log('%c _getProposedPositions =', 'background:#faa;color:#000;', candidate.candidate_allocations.length, candidate.id, toJS(candidate));
        __DEBUG_PROPOSED && console.log('%c --> =', 'background:#faa;color:#000;', allocationPositions.length);
      }
    }
    else
    {
      __DEBUG_PROPOSED && console.log('%c _getProposedPositions =', 'background:#faa;color:#000;', candidate.candidate_allocations.length, candidate.id, toJS(candidate));
    }

    const proposedPositionsCount:number | undefined = allocationPositions.length;

    if( !proposedPositionsCount ) return null;

    return (
      <div className={styles.infoRow}>
        <div className={styles.infoIconWrapper}>
          <Icon
            className={cx(styles.infoIcon, styles.iconProposed)}
            icon={IconNames.OFFICE}
            size={10}
          />
        </div>
        <div className={styles.infoText}>
          {proposedPositionsCount} {proposedPositionsCount > 1 ? 'proposed positions' : 'proposed position'}
        </div>
      </div>
    );
  }

  // ----------------------------------------------------

  private _getScheduledDate(scheduledCall:Recruiting_Candidate_Scheduled_Event):string
  {
    return `${moment(scheduledCall.scheduled_call.start_time)
      .format('MMM DD HH:mm')} - ${moment(scheduledCall.scheduled_call.end_time)
      .format('HH:mm')}`;
  }

  // ----------------------------------------------------

  private _getAttendeesList(scheduledCall:Recruiting_Candidate_Scheduled_Event):ReactNode
  {
    const { candidate } = this.props;
    const attendeesUsers:Array<Common_User> = this._interviewFlowsController.getEventAttendeesAsUsers(
      candidate,
      scheduledCall.scheduled_call
    );
    // const attendeesVis:Array<Common_User> = attendeesUsers.length > 2 ? attendeesUsers.slice(0, 2) : attendeesUsers;
    // const additionalCount:number = attendeesUsers.length - attendeesVis.length;

    // return `${attendeesVis.map(au => `${au.first_name} ${au.last_name[0]}`)
    //   .join(', ')}${additionalCount > 0 ? ` +${additionalCount}` : ''}`;

    const names:Array<string> = attendeesUsers.map(au => `${au.first_name} ${au.last_name[0]}`);

    return <ShowArrayAsStringPlusX items={names} />;
  }

  // ----------------------------------------------------

  private _getScheduledCallIcon(scheduledCall:Recruiting_Candidate_Scheduled_Event):ReactNode
  {
    if( scheduledCall.scheduled_call.is_feedback_done )
    {
      return <Icon
        icon={IconNames.DOCUMENT}
        size={12}
        className={cx(styles.infoIcon, styles.feedbackDoneIcon)}
      />;
    }
    else if( scheduledCall.scheduled_call.is_done )
    {
      return <SvgIcon
        className={cx(styles.infoIcon, styles.waitingFeedbackIcon)}
        icon={SvgIconName.DocumentQuestion}
        size={12}
      />;
    }
    else if( scheduledCall.scheduled_call.is_scheduled )
    {
      return <SvgIcon
        className={cx(styles.infoIcon, styles.scheduledCallIcon)}
        icon={SvgIconName.GoogleMeetBlue}
        size={12}
      />;
    }
    else if( scheduledCall.scheduled_call.is_draft )
    {
      return <Icon
        icon={IconNames.Edit}
        size={12}
        className={styles.infoIcon}
      />;
    }
    else
    {
      return <Icon
        icon={IconNames.WarningSign}
        size={12}
        className={cx(styles.infoIcon, styles.warning)}
      />;
    }
  }

  // ----------------------------------------------------

  private _getScheduledConfirmationIcon(scheduledCall:Recruiting_Candidate_Scheduled_Event):ReactNode | null
  {
    if( !this._interviewFlowsState.candidateEvents.length )
      return null;

    const event = this._interviewFlowsState.candidateEvents.find(event => event?.id == scheduledCall.scheduled_call.google_event_id);

    const candidateEmail = this.props.candidate.email;

    const responseStatus = event?.attendees.find(attendee => attendee.email == candidateEmail)?.responseStatus;

    // Possible statuses: needsAction, declined, tentative, accepted

    if( responseStatus == 'accepted' )
    {
      return <SvgIcon
        className={cx(styles.confirmationIcon, styles.blue)}
        size={8}
        icon={SvgIconName.SmallTick2Selected}
      />;
    }
    else if( responseStatus == 'declined' )
    {
      return <SvgIcon
        className={cx(styles.confirmationIcon, styles.red)}
        size={8}
        icon={SvgIconName.SmallDelete2}
      />;
    }
    // else
    // {
    //   // needsAction or tentative
    //   return <SvgIcon
    //     className={cx(styles.confirmationIcon, styles.gray)}
    //     size={8}
    //     icon={SvgIconName.Question}
    //   />;
    // }

    return null;
  }

  // ----------------------------------------------------

  private _getScheduledCalls():Array<ReactNode> | null
  {
    const { candidate } = this.props;

    const isCandidateOnLastStage:boolean = this._interviewFlowsController.isLastStage(candidate.interview_flow_stage_id);

    if( isCandidateOnLastStage ) return null;

    if( !candidate.scheduled_calls?.length ) return null;

    const scheduledCalls:Array<Recruiting_Candidate_Scheduled_Event> = candidate.scheduled_calls
      .filter((scheduledCall:Recruiting_Candidate_Scheduled_Event, index:number) =>
        {
          index === 0 && __DEBUG_OTHER && console.log('%c candidate    %d =', 'background:#0f0;color:#000;', candidate.id, 'stageId =', candidate.interview_flow_stage_id);

          if( scheduledCall.interview_flow_stage_id !== candidate.interview_flow_stage_id )
          {
            __DEBUG_OTHER && console.log('%c --> =', 'background:#faa;color:#000;',
              'stage_id =', scheduledCall.interview_flow_stage_id,
              'call_id =', toJS(scheduledCall.scheduled_call.id),
              toJS(scheduledCall)
            );
            return;
          }

          const evnt:Common_Scheduled_Event = scheduledCall.scheduled_call;
          const showByState:boolean = evnt.is_draft || evnt.is_scheduled || evnt.is_done || evnt.is_feedback_done;

          __DEBUG_OTHER && console.log('%c --> =', 'background:#080;color:#000;',
            'stage_id =', scheduledCall.interview_flow_stage_id,
            'call_id =', toJS(scheduledCall.scheduled_call.id),
            toJS(scheduledCall)
          );

          return showByState;
        }
      )
      .sort((a, b) =>
      {
        const _a:Common_Scheduled_Event = a.scheduled_call;
        const _b:Common_Scheduled_Event = b.scheduled_call;

        if( _a.is_draft && !_a.is_scheduled && !_a.is_done && !_a.is_feedback_done ) return -1; // draft on top

        return moment(_a.start_time).isBefore(_b.start_time) ? -1 : 1;
      });

    return scheduledCalls.map((scheduledCall:Recruiting_Candidate_Scheduled_Event) => (
      <div
        key={scheduledCall.scheduled_call.id}
        className={styles.infoRow}
      >
        <div className={styles.infoIconWrapper}>
          {this._getScheduledCallIcon(scheduledCall)}
        </div>
        <div className={styles.infoText}>

          <div className={cx(styles.infoTextLine, styles.relative)}>
            {/*{DebugUtil.id(scheduledCall.scheduled_call.id)}*/}
            {this._getScheduledDate(scheduledCall)}
            {this._getScheduledConfirmationIcon(scheduledCall)}
          </div>

          <div className={cx(styles.infoTextLine, styles.gray)}>
            {this._getAttendeesList(scheduledCall)}
          </div>

        </div>
      </div>
    ));
  }

  // ----------------------------------------------------

  @bind
  private _onClick():void
  {
    const { candidate } = this.props;

    this._commonController.goToCandidate(Number(candidate.id));
  }

  // ----------------------------------------------------

  @bind
  private _renderPositionGroups():React.ReactNode | null
  {
    const { candidate: { candidate_position_groups } } = this.props;

    return (
      <ShowArrayAsStringPlusX
        className={styles.positionGroups}
        plusXClassName={styles.positionGroupsPlusX}
        items={candidate_position_groups.map(pg => pg.position_group.name)}
      />
    );
  }

  // ----------------------------------------------------

  @bind
  private _renderRecruiter():React.ReactNode | null
  {
    const { candidate: { assigned_user_id } } = this.props;

    if( !assigned_user_id ) return null;

    const assignedName:string = StringUtil.getUserName(this._interviewFlowsState.getUserById(assigned_user_id));

    return (
      <div className={styles.assignedTo}>
        <Icon icon={IconNames.PERSON} size={10} className={styles.assignedToIcon} />
        <span className={styles.assignedName}>
          {assignedName}
        </span>
      </div>
    );
  }

  // ----------------------------------------------------

  public render():ReactNode
  {
    const {
      candidate,
      provided,
      snapshot
    } = this.props;

    if( !candidate )
      return null;

    return (
      <>
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={styles.card}
          onClick={this._onClick}
        >
          <div className={styles.personInfo}>
            <Avatar size={20} className={styles.avatar} url={candidate.avatar_url} />
            <div className={styles.cardTitle}>
              {
                DebugUtil.isEnabled &&
                <span className={styles.debug}>{DebugUtil.id(candidate.id)}</span>
              }
              {candidate.first_name} {candidate.last_name}
            </div>
          </div>
          {this._renderPositionGroups()}
          {this._getFinalizedPosition() || this._getProposedPositions()}
          {this._getScheduledCalls()}
          {candidate?.final_allocation?.is_final && candidate.final_allocation.finalized_slots[0].position.alias}
          {
            (this._isDraggingInAnotherGroup || this._isNowConvertingToStaff) &&
            <div className={styles.draggingOverlay} />
          }
          {this._renderRecruiter()}
        </div>
      </>
    );
  }

  // ----------------------------------------------------
}
