import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';

import classNames from 'classnames/bind';
import React from 'react';
import styles from './Drawer.module.less';

const cx = classNames.bind(styles);

interface Props
{
  isOpen:boolean;
  onClose?:() => void;
  onClosed?:() => void;
}

interface State
{
  contentShow:boolean;
}

@component
export class Drawer extends React.Component<Props, State>
{
  @bind
  private _onClickOverlay(e:React.MouseEvent<HTMLElement>):void
  {
    e.preventDefault();

    const { onClose } = this.props;
    onClose?.();
  }

  public componentDidUpdate(prevProps:Readonly<Props>/*, prevState:Readonly<State>, snapshot?:an */):void
  {
    const { isOpen, onClosed } = this.props;

    if( onClosed && prevProps.isOpen && !isOpen )
      setTimeout(() => onClosed() as void, 300);
  }

  public render():React.ReactNode
  {
    // const { contentShow } = this.state;
    const { isOpen, children } = this.props;

    return (
      <div className={cx(styles.wrapper, { isOpen: isOpen })}>

        <div
          className={styles.overlay}
          onClick={this._onClickOverlay}
        />

        <div className={styles.contentWrapper}>
          {/*{contentShow && children}*/}
          {isOpen && children}
        </div>

      </div>
    );
  }
}
