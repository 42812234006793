import {
  type IFilter,
  type IFilterValue
} from '@flow/common/components/filters/Filter';
import { Spinner } from '@flow/common/components/form/Spinner';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
// import classNames from 'classnames/bind';
import React from 'react';
import { CandidatesController } from '../../../CandidatesController';
import { CandidatesState } from '../../../CandidatesState';

import styles from './CandidatesFilters.module.less';
import { CandidatesFiltersSelector } from './CandidatesFiltersSelector';
import { CandidatesSavedFiltersSelector } from './CandidatesSavedFiltersSelector';

// const cx = classNames.bind(styles);

@component
export class CandidatesFilters extends React.Component
{
  @di private _candidatesState!:CandidatesState;
  @di private _candidatesController!:CandidatesController;

  // ---------------------------------------------------------

  @bind
  private _onRemoveFilter(filter:IFilter<IFilterValue>):() => void
  {
    return ():void =>
    {
      this._candidatesController.removeFilter(filter.type);
    };
  }

  @bind
  private _onChangeFilter(filter:IFilter<IFilterValue>):(newValue:IFilterValue) => void
  {
    return (newValue:IFilterValue):void =>
    {
      console.log('%c !!! _onChangeFilter newValue =', 'background:#ff0;color:#000;', newValue);
      this._candidatesController.changeFilterValue(filter.type, newValue);
    };
  }

  @bind
  private _getFilterData(filter:IFilter<IFilterValue>):() => unknown
  {
    return ():unknown =>
    {
      console.log('%c !!! _getFilterData filter =', 'background:#ff0;color:#000;', filter.type);
      return this._candidatesController.getFilterData(filter.type);
    };
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { isCandidatesLoaded } = this._candidatesState;

    if( !isCandidatesLoaded )
    {
      return (
        <Spinner
          // size={20}
          noIcon
          withWave
          withWrapper
          wrapperClassName={styles.spinnerWrapper}
        />
      );
    }

    return (
      <div className={styles.filters}>

        <div className={styles.positionLeft}>

          <CandidatesSavedFiltersSelector />

          {/*---------------------------------*/}

          {this._candidatesState.filters.map((filter:IFilter<IFilterValue>):React.ReactNode | null =>
          {
            if( !filter.isSelected ) return null;
            console.log('%c filter =', 'background:#0f0;color:#000;', filter);
            const Component = filter.component;

            return (
              <React.Fragment key={filter.name + filter.type}>
                <Component
                  filter={filter}
                  onRemoveFilter={this._onRemoveFilter(filter)}
                  onChangeFilter={this._onChangeFilter(filter)}
                  getFilterData={this._getFilterData(filter)}
                />
              </React.Fragment>
            );
          })}

        </div>

        {/*---------------------------------*/}

        <div className={styles.positionRight}>
          <CandidatesFiltersSelector />
        </div>

      </div>
    );
  }
}
