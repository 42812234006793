import { Menu, MenuDivider, MenuItem, Position } from '@blueprintjs/core';
import { Classes } from '@blueprintjs/popover2';
import type { IItemListRendererProps, IItemRendererProps } from '@blueprintjs/select';
import { Suggest2 } from '@blueprintjs/select';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import type {
  IInlineEditorField,
  IInlineEditorMultiSelectValue,
  IInlineEditorProps
} from '@flow/common/components/form/InlineEditor';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import * as React from 'react';

import styles from './InlineEditor.module.less';

const cx = classNames.bind(styles);

export class InlineEditorSuggest extends React.PureComponent<IInlineEditorProps>
{
  // ---------------------------------------------------------

  @bind
  private _onItemSelect(item:IInlineEditorMultiSelectValue):void
  {
    const { fields, onChangeInput } = this.props;
    const currentField:IInlineEditorField = fields[0];
    const { name } = currentField;

    const newValues:Record<string, IInlineEditorField> = {};
    newValues[name] = { name: name, value: item.value || null };

    onChangeInput?.(name)(item.value as string);
  }

  // ---------------------------------------------------------

  @bind
  private _itemRenderer(item:IInlineEditorMultiSelectValue, {
    modifiers,
    index
  }:IItemRendererProps):JSX.Element | null
  {
    const { fields } = this.props;
    const currentField:IInlineEditorField = fields[0];
    const { value: currentValue } = currentField;

    const { title, element, value, isGroupItem, isMenuDivider, isGroupHeader } = item;

    if( isMenuDivider )
    {
      return <MenuDivider />;
    }

    const isSelected:boolean = value === currentValue;

    return (
      <MenuItem
        key={`${String(title)}:${String(value)}:${index?.toString()}`}
        className={cx(styles.menuItem, { isMenuDivider, isGroupHeader })}
        active={modifiers.active}
        text={element || title}
        icon={isGroupItem ? IconNames.BLANK : null}
        labelElement={<Icon icon={isSelected ? IconNames.TICK : null} />}
        onMouseDown={():void => this._onItemSelect(item)}
      />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _itemListRenderer(itemListProps:IItemListRendererProps<IInlineEditorMultiSelectValue>):JSX.Element
  {
    const {
      filteredItems, itemsParentRef, renderItem, menuProps
    } = itemListProps;

    const { menuClassName } = this.props;

    const renderedItems = filteredItems.map(renderItem);

    return (
      <Menu
        {...menuProps}
        ulRef={itemsParentRef}
        className={cx(styles.selectMenu, menuClassName)}
      >
        {
          renderedItems.length > 0
            ? renderedItems
            : <MenuItem disabled text={this.props.fields[0].placeholder} />
        }
      </Menu>
    );
  }

  @bind
  private _itemListPredicate(query:string, items:Array<IInlineEditorMultiSelectValue>):Array<IInlineEditorMultiSelectValue>
  {
    return items.filter((item) => String(item.title)?.toLowerCase().includes(query.toLowerCase()));
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const {
      onClosed,
      onOpening,
      isOpen,
      onClose,
      fields,
      onQueryChange,
      query,
      onBlur,
      onKeyDown,
      itemListPredicate,
      selectedItem,
      inputClassName
    } = this.props;

    const currentField:IInlineEditorField = fields[0];

    const { values, resetValue } = currentField;

    const viewedItems:Array<IInlineEditorMultiSelectValue> = (values || []).slice();

    if( resetValue )
    {
      viewedItems.splice(0, 0, { ...resetValue, isMenuDivider: true });
    }

    const InlineSuggest = Suggest2.ofType<IInlineEditorMultiSelectValue>();

    return (
      <InlineSuggest
        items={viewedItems}
        inputValueRenderer={():string => String(selectedItem?.value)}
        itemListRenderer={this._itemListRenderer}
        itemRenderer={this._itemRenderer}
        onItemSelect={this._onItemSelect}
        onQueryChange={onQueryChange}
        query={query}
        activeItem={(viewedItems.filter(item => item.isActive)[0])}
        selectedItem={selectedItem}
        itemListPredicate={itemListPredicate}
        popoverProps={{
          isOpen,
          minimal: true,
          className: styles.selectPopoverWrapper,
          popoverClassName: Classes.POPOVER2_DISMISS,
          position: Position.BOTTOM_LEFT,
          onClose: onClose,
          onClosed: onClosed,
          onOpening: onOpening
        }}
        inputProps={{
          className: cx(styles.input, inputClassName),
          autoFocus: true,
          onBlur,
          onKeyDown
        }}
      >
        {this.props.children}
      </InlineSuggest>
    );
  }
}
