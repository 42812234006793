import type { ApolloQueryResult } from '@apollo/client';
import type { FetchResult } from '@apollo/client/link/core';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import { OrderByType } from '@flow/common/components/elements/ButtonOrderBy';
import type { IFilterValue } from '@flow/common/components/filters/Filter';
import { FilterType, IFilter } from '@flow/common/components/filters/Filter';
import type {
  IFilterMultiSelectDataItem,
  IFilterMultiSelectDataItems
} from '@flow/common/components/filters/multiSelect/FilterMultiSelect';
import type { IFilterTimeValue } from '@flow/common/components/filters/time/FilterTime';
import { FilterTimeValueType } from '@flow/common/components/filters/time/FilterTime';
import { AuthState } from '@flow/common/controllers/AuthState';
import { ToastsController } from '@flow/common/toasts/ToastsController';
import { LocalStorageUtil } from '@flow/common/utils/LocalStorageUtil';
import { PageUtil } from '@flow/common/utils/PageUtil';
import type { AnyObject } from '@flow/common/utils/WindowLocationUtil';
import { WindowLocationUtil } from '@flow/common/utils/WindowLocationUtil';
import type {
  CandidateFetchLinkedInImageMutation,
  CandidateFetchLinkedInImageMutationVariables
} from '@flow/data-access/lib/actions.generated';
import { CandidateFetchLinkedInImageDocument } from '@flow/data-access/lib/actions.generated';
import type {
  AddCandidateMutation,
  AddCandidateMutationVariables,
  AllCandidatesQuery,
  AllCandidatesQueryVariables,
  NewCandidateMutation,
  NewCandidateMutationVariables,
  NewCandidatePositionGroupsMutation,
  NewCandidatePositionGroupsMutationVariables,
  NewFilterMutation,
  NewFilterMutationVariables,
  QueryCandidatesQuery,
  QueryCandidatesQueryVariables,
  SearchCandidatesQuery,
  SearchCandidatesQueryVariables,
  UpdateFilterMutation,
  UpdateFilterMutationVariables
} from '@flow/data-access/lib/candidates.generated';
import {
  AddCandidateDocument,
  AllCandidatesDocument,
  NewCandidateDocument,
  NewCandidatePositionGroupsDocument,
  NewFilterDocument,
  QueryCandidatesDocument,
  SearchCandidatesDocument,
  UpdateFilterDocument
} from '@flow/data-access/lib/candidates.generated';
import type {
  Common_Filter,
  Common_Skill_Tag,
  Common_User,
  Recruiting_Candidate,
  Recruiting_Candidate_Bool_Exp,
  Recruiting_Candidate_Order_By,
  Recruiting_Candidate_Skill_Tag,
  Recruiting_Interview_Flow_Stage,
  Recruiting_Position_Group
} from '@flow/data-access/lib/types/graphql.generated';
import {
  Common_City,
  Common_Country,
  Common_Filter_Type_Enum,
  Recruiting_Candidate_Status_Enum
} from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import { CandidateSiteSubsiteState } from '@flow/modules/recruiting/candidates/CandidateSiteSubsiteState';
import { AppToaster } from 'apps/flow/src/pages/App';
import bind from 'bind-decorator';
import debounce from 'lodash/debounce';
import { action, reaction, runInAction, toJS } from 'mobx';
import type { ICandidatesColumn } from './CandidatesColumnsState';
import {
  CandidatesColumnsDefaultState,
  CandidatesColumnType,
  QUERY_STRING_COLUMNS_NAME
} from './CandidatesColumnsState';
import { CandidatesFiltersDefaultState } from './CandidatesFiltersState';
import {
  CandidateFormMode,
  CandidatesState,
  CandidatesState_LS_KEY,
  CandidatesStatusFilter,
  CandidatesUrl_LS_KEY,
  SaveFilterMode
} from './CandidatesState';
import type { ICandidateSearchItem } from './components/candidates/CandidatesSearch';
import { CandidateCreatedToast } from './components/candidates/elements/CandidateCreatedToast';

@controller
export class CandidatesController
{
  @di private _authState!:AuthState;
  @di private _commonState!:CommonState;
  @di private _commonController!:CommonController;
  @di private _toastsConroller!:ToastsController;

  @di private _candidatesState!:CandidatesState;
  @di private _candidateSiteSubsiteState!:CandidateSiteSubsiteState;

  // ----------------------------------------------------

  @action.bound
  public initLocalStorageReaction():void
  {
    const { disposers } = this._candidatesState;

    Array.isArray(disposers) && disposers.push(reaction(
      () =>
      {
        const {
          selectedFilterId, isFiltersDirty, candidatesStatusFilterValue
        } = this._candidatesState;

        return {
          selectedFilterId, isFiltersDirty, candidatesStatusFilterValue
        };
      },
      (lsValue:Partial<CandidatesState>) =>
      {
        console.log('%c SET - CandidatesState: lsValue =', 'background:#0ff;color:#000;', lsValue);
        LocalStorageUtil.setItem(CandidatesState_LS_KEY, lsValue);
      }
    ));
  }

  @action.bound
  public applyFromLocalStorage():void
  {
    console.log('%c applyFromLocalStorage: =', 'background:#0ff;color:#000;');
    const lsState:Partial<CandidatesState> = LocalStorageUtil.getItem(CandidatesState_LS_KEY);
    const lsUrl:object = LocalStorageUtil.getItem(CandidatesUrl_LS_KEY);

    this._candidatesState.isApplyingFromLocalStorage = true;

    if( lsState )
    {
      console.log('%c --- lsState =', 'background:#0ff;color:#000;', lsState);
      // this._candidatesState.selectedFilterId = lsState.selectedFilterId || null;
      this.selectUserFilter(lsState.selectedFilterId || null);
      this._candidatesState.isFiltersDirty = lsState.isFiltersDirty || false;
      this._applyStatusTabsFromLocalStorage();

      // !!! ???
      // this.setQueryString();
    }

    if( lsUrl )
    {
      console.log('%c --- lsUrl =', 'background:#0ff;color:#000;', lsUrl);
      WindowLocationUtil.setQueryString(lsUrl);
      this.applyQueryString();
    }

    this._candidatesState.isApplyingFromLocalStorage = false;
  }

  @action.bound
  private _applyStatusTabsFromLocalStorage():void
  {
    if( WindowLocationUtil.getHashVariable('tab') )
      return;

    const lsState:Partial<CandidatesState> = LocalStorageUtil.getItem(CandidatesState_LS_KEY);

    if( lsState && lsState.candidatesStatusFilterValue )
    {
      this.setFilterByCandidateStatus(lsState.candidatesStatusFilterValue || CandidatesStatusFilter.Initial);
    }
  }

// ----------------------------------------------------

  @action.bound
  public async initCandidates():Promise<void>
  {
    // this.setViewCandidateId(null);
    // this.setUserFilter(null);

    // --------------------

    const { disposers } = this._candidatesState;

    this.disposeReactions();

    Array.isArray(disposers) && disposers.push(reaction(
      () => this.getFiltersJSONString(),
      (filtersJSONString:string) =>
      {
        console.log('%c reaction: filtersJSONString =', 'background:#f0f;color:#fff;', filtersJSONString);
        const { selectedFilterId, userFilterById, isApplyingFromLocalStorage } = this._candidatesState;

        if( isApplyingFromLocalStorage )
        {
          console.log('%c reaction: isApplyingFromLocalStorage =', 'background:#f0f;color:#fff;', isApplyingFromLocalStorage);
          runInAction(() =>
          {
            this._candidatesState.isApplyingFromLocalStorage = false;
          });
          return;
        }

        if( typeof (userFilterById) !== 'function' )
          return;

        let isDirty:boolean = false;

        if( selectedFilterId )
        {
          const userFilter:Common_Filter | undefined = userFilterById(selectedFilterId);

          if( !userFilter ) return; // !!!

          isDirty = filtersJSONString !== userFilter.content;
        }
        else
        {
          isDirty = filtersJSONString !== '{}';
        }

        runInAction(() =>
        {
          this._candidatesState.isFiltersDirty = isDirty;
        });

        this.setQueryString();
      }
    ));

    Array.isArray(disposers) && disposers.push(reaction(
      () => this._candidatesState.columns,
      () =>
      {
        this.setQueryString();
      }
    ));

    // --------------------

    const { user } = this._authState;

    const result:ApolloQueryResult<AllCandidatesQuery> =
      await this._commonController.query<AllCandidatesQuery,
        AllCandidatesQueryVariables>({
        query: AllCandidatesDocument,
        variables: {
          userId: user?.id || -1,
          userFiltersType: Common_Filter_Type_Enum.Recruiting
        }
      });

    console.log('%c initCandidates result =', 'background:#f60;color:#000;', result);

    Array.isArray(disposers) && disposers.push(reaction(() =>
      {
        const {
          currentPage,
          filters,
          columns,
          candidatesStatusFilterValue,
          isCandidatesInitialized
        } = this._candidatesState;

        return [
          candidatesStatusFilterValue,
          currentPage,
          filters.forEach(filter => filter.value),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          columns.forEach(column => column.orderBy),
          isCandidatesInitialized
        ];
      },
      () =>
      {
        this.queryCandidates();
        runInAction(() => this._candidatesState.candidatesListScrollYPosition = 0);
      }));

    runInAction(() =>
    {
      const {
        recruiting_position_group,
        common_skill_tag,
        common_filter,
        recruiting_interview_flow_stage,
        common_user,
        common_country,
        common_city,
      } = result.data;

      this._candidatesState.positionGroups = recruiting_position_group as Array<Recruiting_Position_Group>;
      this._candidatesState.skillTags = common_skill_tag as Array<Common_Skill_Tag>;
      this._candidatesState.interviewFlowStages = recruiting_interview_flow_stage as Array<Recruiting_Interview_Flow_Stage>;
      this._candidatesState.userFilters = common_filter as Array<Common_Filter>;
      this._candidatesState.users = common_user as Array<Common_User>;
      this._candidatesState.statuses = Object.values(Recruiting_Candidate_Status_Enum);

      this._candidateSiteSubsiteState.countries = common_country as Array<Common_Country>;
      this._candidateSiteSubsiteState.cities = common_city as Array<Common_City>;

      this.applyQueryString();
    });

    Array.isArray(disposers) && disposers.push(reaction(() =>
      {
        const { filters } = this._candidatesState;
        return [
          filters.forEach(filter => filter.value)
        ];
      },
      () =>
      {
        this._candidatesState.currentPage = 1;
      }));

    Array.isArray(disposers) && disposers.push(reaction(() =>
      {
        const { searchQuery } = this._candidatesState;
        return [searchQuery];
      },
      ([searchQuery]) =>
      {
        if( searchQuery.length == 0 )
        {
          runInAction(() => this._candidatesState.searchResult = []);
        }
        else
        {
          runInAction(() => this._candidatesState.isSearching = true);
          this.searchCandidatesDebounced();
        }
      }));

    // To trigger the very first reaction
    if( !this._candidatesState.isCandidatesInitialized )
      runInAction(() => this._candidatesState.isCandidatesInitialized = true);

    this.initLocalStorageReaction();
  }

  // ---------------------------------------------------------

  public async queryCandidates():Promise<void>
  {
    const { currentPage, itemsPerPage, filters, candidatesStatusFilterValue } = this._candidatesState;

    const graphQLFilters:Array<Recruiting_Candidate_Bool_Exp | undefined> = filters
      .filter(filter => filter.graphQLTemplate && filter.isSelected && filter.graphQLFilter && filter.graphQLFilter())
      .map(filter =>
      {
        if( !filter.graphQLFilter )
          throw new Error(`Filter ${filter.name} does not have graphQLFilter function`);

        const filterStr = filter.graphQLFilter();

        return filterStr.length > 0
          ? JSON.parse(filterStr) as Recruiting_Candidate_Bool_Exp
          : undefined;
      })
      .filter(filterObj => !!filterObj);

    const candidateStatusFilterValues:Array<string> = [];
    switch( candidatesStatusFilterValue )
    {
      case CandidatesStatusFilter.Initial:
        candidateStatusFilterValues.push(Recruiting_Candidate_Status_Enum.Initial);
        break;
      case CandidatesStatusFilter.InWork:
        candidateStatusFilterValues.push(Recruiting_Candidate_Status_Enum.Active);
        break;
      case CandidatesStatusFilter.Archived:
        candidateStatusFilterValues.push(Recruiting_Candidate_Status_Enum.Archived);
        candidateStatusFilterValues.push(Recruiting_Candidate_Status_Enum.Declined);
        candidateStatusFilterValues.push(Recruiting_Candidate_Status_Enum.Staffed);
        candidateStatusFilterValues.push(Recruiting_Candidate_Status_Enum.Refused);
    }

    const candidateStatusFilterCondition = {
      status: {
        _in: candidateStatusFilterValues
      }
    };

    const whereExp:Recruiting_Candidate_Bool_Exp = {
      _and: [
        candidateStatusFilterCondition,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...graphQLFilters.filter(filter => typeof (filter) !== 'undefined') as any
      ]
    };

    const order_by = this._candidatesState.columns
      .filter(column => column.orderBy && column.graphQLOrderBy().length > 0)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .map(column => JSON.parse(column.graphQLOrderBy()));

    const orderByObj = order_by.reduce((obj, item) =>
    {
      for( const prop in item )
        obj[prop] = item[prop];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return obj;
    }, {}) as Recruiting_Candidate_Order_By;

    runInAction(() => this._candidatesState.isCandidatePageLoaded = false);

    const result:ApolloQueryResult<QueryCandidatesQuery> = await this._commonController
      .query<QueryCandidatesQuery, QueryCandidatesQueryVariables>({
        query: QueryCandidatesDocument,
        variables: {
          where: whereExp,
          offset: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,  // TODO: configurable?
          order_by: orderByObj
        }
      });

    console.log('%c QueryCandidates result =', 'background:#f60;color:#000;', result);

    runInAction(() =>
    {
      this._candidatesState.itemsTotal = result.data.recruiting_candidate_aggregate.aggregate?.count || 0;
      this._candidatesState.candidates = result.data.recruiting_candidate as Array<Recruiting_Candidate>;
      this._candidatesState.isCandidatesLoaded = true;
      this._candidatesState.isCandidatePageLoaded = true;
    });
  }

  // ----------------------------------------------------

  @action.bound
  public setSearchQuery(query:string):void
  {
    this._candidatesState.searchQuery = query;
  }

  private _searchCriteriaByWord($query:string):Recruiting_Candidate_Bool_Exp
  {
    return {
      _or: [
        {
          first_name: {
            _ilike: $query
          }
        },
        {
          last_name: {
            _ilike: $query
          }
        },
        {
          email: {
            _ilike: $query
          }
        },
        {
          phone: {
            _ilike: $query
          }
        },
        {
          linkedin_profile_url: {
            _ilike: $query
          }
        },
        {
          candidate_position_groups: {
            position_group: {
              name: {
                _ilike: $query
              }
            }
          }
        }
      ]
    };
  }

  @bind
  public async searchCandidates():Promise<void>
  {
    const { searchQuery } = this._candidatesState;

    const words = searchQuery.trim().split(' ').map(word => word.trim()).filter(word => word.length > 0)
      .map(word => this._searchCriteriaByWord(`%${word}%`));

    const result:ApolloQueryResult<SearchCandidatesQuery> = await this._commonController
      .query<SearchCandidatesQuery, SearchCandidatesQueryVariables>({
        query: SearchCandidatesDocument,
        variables: {
          where: {
            _and: [
              ...words
            ]
          }
        }
      });

    console.log('%c searchCandidates result =', 'background:#f60;color:#000;', result);

    runInAction(() =>
    {
      this._candidatesState.searchResult = result.data.recruiting_candidate
        .map(candidate =>
        {
          const candidateSearchItem:ICandidateSearchItem = {
            id: candidate.id,
            avatarUrl: candidate.avatar_url || undefined,
            firstName: candidate.first_name,
            lastName: candidate.last_name,
            email: String(candidate.email),
            linkedIn: String(candidate.linkedin_profile_url),
            positionGroups: candidate.candidate_position_groups.map(pg => pg.position_group.name).join(', ')
          };

          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return candidateSearchItem;
        });

      this._candidatesState.isSearching = false;
    });
  }

  public searchCandidatesDebounced = debounce(this.searchCandidates, 200);

  @bind
  public disposeReactions():void
  {
    PageUtil.disposeReactions(this._candidatesState.disposers);
  }

  // ----------------------------------------------------

  @action.bound
  public setViewCandidateId(candidateId:number | null):void
  {
    console.log('%c setViewCandidatePage =', 'background:#00f;color:#ff0;', candidateId);
    this._candidatesState.viewCandidateId = candidateId || null;

    this._candidatesState.isViewCandidatesPageContent = !candidateId;
  }

  // ----------------------------------------------------

  public setQueryString():void
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryObject:any = {};

    // filters
    if( this._candidatesState.someFilterIsSelected )
    {
      this._candidatesState.filters.forEach((filter:IFilter<IFilterValue>) =>
      {
        if( !filter.isSelected ) return;

        if( typeof filter.value === 'object' && !Array.isArray(filter.value) )
        {
          Object.keys(filter.value).forEach((valueKey:string) =>
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let value:any = (filter.value as any)[valueKey];

            // TODO: move to FILTER
            if( filter.type === FilterType.DATE_CREATED
              && (filter.value as IFilterTimeValue).type === FilterTimeValueType.RANGE )
            {
              // 'Jan 1, 2022' -> 'Jan_1__2022'
              value = value?.replace(/[ ,]/g, '_');
            }

            queryObject[`${filter.type}_${valueKey}`] = value;
          });
        }
        else
        {
          queryObject[filter.type] = WindowLocationUtil.makeQueryStringFromObject(filter.value);
        }
      });
    }

    // columns
    if( this.columnsIsChanged() )
    {
      const columns:Array<CandidatesColumnType> = [];

      this._candidatesState.columns.forEach((column:ICandidatesColumn) =>
      {
        if( column.isVisible || column.alwaysVisible )
        {
          columns.push(column.type);
        }
      });

      queryObject['columns'] = columns;

      // orderBy
      if( this._candidatesState.orderedBy )
      {
        const orderDirection:string = this._candidatesState.orderedBy.orderBy === OrderByType.DESC ? '-' : '';
        queryObject['orderBy'] = `${orderDirection}${this._candidatesState.orderedBy.type}`;
      }
    }

    const str:string = WindowLocationUtil.makeQueryStringFromObject(queryObject as AnyObject);
    console.log('%c setQueryString =', 'background:#f0f;color:#000;', str);
    console.log('%c ---     decode =', 'background:#f0f;color:#000;', WindowLocationUtil.getObjectFromQueryString(str));

    WindowLocationUtil.setQueryString(queryObject as AnyObject);

    const { isApplyingFromLocalStorage } = this._candidatesState;

    if( !isApplyingFromLocalStorage )
    {
      LocalStorageUtil.setItem(CandidatesUrl_LS_KEY, queryObject);
    }
  }

  @action.bound
  public applyQueryString():void
  {
    const { someFilterIsSelected, isApplyingFromLocalStorage } = this._candidatesState;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryObject:any = WindowLocationUtil.getObjectFromQueryString();
    console.log('%c applyQueryString =', 'background:#f0f;color:#000;', queryObject);

    const aQSF:boolean = this._applyQueryStringFilters(queryObject);
    const aQSC:boolean = this._applyQueryStringColumns(queryObject);

    if( !aQSF && !aQSC && !isApplyingFromLocalStorage )
    {
      this.applyFromLocalStorage();
    }
    else if( someFilterIsSelected && !isApplyingFromLocalStorage )
    {
      this._candidatesState.isFiltersDirty = true;
    }
  }

  // ----------------------------------------------------

  @action.bound
  public setUserFilter(userFilterId:number | null):void
  {
    this.unselectAllFilters();

    if( !userFilterId ) return;

    const { userFilterById } = this._candidatesState;
    const userFilter:Common_Filter | undefined = userFilterById(userFilterId);

    if( !userFilter ) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userFilters:any = JSON.parse(String(userFilter.content));

    this._applyUserFilter(userFilters);

    this._candidatesState.isFiltersDirty = false;
  }

  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _applyUserFilter(userFilters:any):void
  {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Object.keys(userFilters).forEach((filterType:string) =>
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filter:IFilter<any> | undefined | null = this._candidatesState.filterByType(filterType as FilterType);

      if( !filter )
        return;

      console.log('%c userFilters[filterType] =', 'background:#0f0;color:#000;', userFilters[filterType]);

      this.selectFilter(filter.type, true);
      this.changeFilterValue(filter.type, userFilters[filterType]);
    });

    const { candidatesStatusFilterValue } = this._candidatesState;

    if( userFilters.tab && userFilters.tab !== candidatesStatusFilterValue )
    {
      // check if tab exists
      for( const value of Object.values(CandidatesStatusFilter) )
      {
        if( userFilters.tab === value )
        {
          this.setFilterByCandidateStatus(value as CandidatesStatusFilter);
        }
      }
    }
  }

  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _applyQueryStringFilters(userFilters:any):boolean
  {
    let someDataFromQueryStringIsApplied:boolean = false;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Object.keys(userFilters).forEach((_filterType:string) =>
    {
      // STATUS_status[]=1,2,3
      if( _filterType.indexOf('_') === -1 ) return;

      // POSITION_GROUP_items -> filterType = POSITION_GROUP
      const filterString:Array<string> = _filterType.split('_');

      // POSITION_GROUP_items -> filterValueKey = items
      const filterValueKey:string | undefined = filterString.pop();

      if( !filterValueKey ) return;

      const filterType:string = filterString.join('_');

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filter:IFilter<any> | undefined | null = this._candidatesState.filterByType(filterType as FilterType);

      if( !filter ) return;

      someDataFromQueryStringIsApplied = true;

      console.log('%c userFilters[filterType] =', 'background:#0f0;color:#000;', userFilters[_filterType]);

      this.selectFilter(filter.type, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let value:any = userFilters[_filterType];

      // TODO: move to FILTER
      if( filter.type === FilterType.DATE_CREATED
        && (filterValueKey === 'rangeFrom' || filterValueKey === 'rangeTo') )
      {
        // 'Jan_1__2022' -> 'Jan 1, 2022'
        value = value.replace('__', ', ').replace('_', ' ');
      }

      const newValue = {
        [filterValueKey]: value
      };

      const isValueEqual = JSON.stringify(filter.value) == JSON.stringify(newValue);

      // this.changeFilterValue(filter.type, WindowLocationUtil.getObjectFromQueryString(userFilters[filterType]));
      if( !isValueEqual )
        this.changeFilterValue(filter.type,
          {
            ...filter.value,
            // [filterValueKey]: WindowLocationUtil.getObjectFromQueryString(userFilters[_filterType])
            ...newValue
          });
    });

    return someDataFromQueryStringIsApplied;
  }

  // ----------------------------------------------------

  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _applyQueryStringColumns(queryObject:any):boolean
  {
    let someDataFromQueryStringIsApplied:boolean = false;

    console.log('%c ---_applyQueryStringColumns =', 'background:#f0f;color:#000;', queryObject[QUERY_STRING_COLUMNS_NAME]);
    const columns:Array<number> = queryObject[QUERY_STRING_COLUMNS_NAME];
    console.log('%c columns =', 'background:#0f0;color:#000;', columns);

    if( !columns || !columns.length ) return false;

    someDataFromQueryStringIsApplied = true;

    // const columns:Array<number> = WindowLocationUtil.getObjectFromQueryString(queryObject[QUERY_STRING_COLUMNS_NAME]);

    this._candidatesState.columns.forEach((column:ICandidatesColumn) =>
    {
      if( column.alwaysVisible ) return;

      this.selectColumn(column.type, columns.includes(column.type));
    });

    if( queryObject.orderBy )
    {
      const orderColumn:number = queryObject.orderBy;
      const columnType:number = orderColumn < 0 ? orderColumn * -1 : orderColumn;
      const orderBy:OrderByType = orderColumn < 0 ? OrderByType.DESC : OrderByType.ASC;

      if( columns.includes(columnType)
        && (this._candidatesState.orderedBy?.type != columnType || this._candidatesState.orderedBy?.orderBy != orderBy) )
      {
        this.clearAllOrders();

        const orderBy:OrderByType = orderColumn < 0 ? OrderByType.DESC : OrderByType.ASC;
        this.orderBy(columnType, orderBy);
      }
    }

    return someDataFromQueryStringIsApplied;
  }

  // ----------------------------------------------------

  @action.bound
  public showAddCandidateForm():void
  {
    console.log('%c showAddCandidateForm =', 'background:#0f0;color:#000;');
    this._candidatesState.candidateFormMode = CandidateFormMode.ADD;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._candidatesState.candidateData = {} as any; // Recruiting_Candidate;
    this._candidatesState.selectedSkillTags = [];
  }

  // ----------------------------------------------------

  @action.bound
  public showEditCandidateForm(candidate:Recruiting_Candidate):void
  {
    console.log('%c showEditCandidateForm candidate =', 'background:#0f0;color:#000;', candidate);
    this._candidatesState.candidateFormMode = CandidateFormMode.EDIT;
    this._candidatesState.candidateData = toJS(candidate);
    this._candidatesState.selectedSkillTags = candidate.skill_tags?.length
      ? candidate.skill_tags.map((skillTagItem:Recruiting_Candidate_Skill_Tag) => skillTagItem.skill_tag)
      : [];
  }

  // ----------------------------------------------------

  @action.bound
  public hideCandidateForm():void
  {
    console.log('%c hideCandidateForm  =', 'background:#0f0;color:#000;');
    this._candidatesState.candidateFormMode = CandidateFormMode.NONE;
  }

  // ----------------------------------------------------

  @action.bound
  public async addCandidate():Promise<void>
  {
    const { first_name, phone, email, linkedin_profile_url } = this._candidatesState.candidateData;

    const names:Array<string> = first_name?.trim().split(' ');

    const firstName:string = names?.shift() || '';
    const lastName:string = names?.join(' ') || '';

    console.log('%c firstName =', 'background:#0f0;color:#000;', firstName);
    console.log('%c lastName =', 'background:#0f0;color:#000;', lastName);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const debugVars:any = {
      first_name: firstName,
      last_name: lastName,
      phone: phone?.trim(),
      email: email?.trim(),
      linkedin_profile_url: linkedin_profile_url?.trim()
      // position_group_id
    };

    console.log('%c debugVars =', 'background:#0f0;color:#000;', debugVars);

    // return new Promise(() =>
    // {
    // });
    const result:FetchResult<AddCandidateMutation> =
      await this._commonController.mutate<AddCandidateMutation,
        AddCandidateMutationVariables>({
        mutation: AddCandidateDocument,
        variables: {
          first_name: firstName,
          last_name: lastName,
          phone: phone?.trim() || '',
          email: email?.trim() || '',
          linkedin_profile_url: linkedin_profile_url?.trim()
          // position_group_id
        }
      });

    console.log('%c result =', 'background:#f60;color:#000;', result);

    this.hideCandidateForm();

    if( result && result.data )
    {
      this._candidatesState.candidates.push(result.data.insert_recruiting_candidate_one as Recruiting_Candidate);
    }
  }

  // ----------------------------------------------------

  @action.bound
  public async saveCandidate():Promise<void>
  {
    // const { first_name, phone, email, linkedin_profile_url } = this._candidatesState.candidateData;

    // const names:Array<string> = first_name?.trim().split(' ');

    // const firstName:string = names?.shift() || '';
    // const lastName:string = names?.join(' ') || '';

    return new Promise(() =>
    {
      return;
    });
    // const result:FetchResult<AddCandidateMutation> =
    //   await this._commonController.mutate<AddCandidateMutation,
    //     AddCandidateMutationVariables>({
    //     mutation: AddCandidateDocument,
    //     variables: {
    //       first_name: firstName,
    //       last_name: lastName,
    //       phone: phone?.trim() || '',
    //       email: email?.trim() || '',
    //       linkedin_profile_url: linkedin_profile_url?.trim()
    //     }
    //   });
    //
    // console.log('%c result =', 'background:#0f0;color:#000;', result);
    //
    // this.hideCandidateForm();
    //
    // if( result && result.data )
    // {
    //   this._candidatesState.candidates.push(result.data.insert_recruiting_candidate_one as Recruiting_Candidate);
    // }
  }

  // ----------------------------------------------------

  @action.bound
  public showNewCandidateDialog():void
  {
    this._candidatesState.isNewCandidateDialogOpen = true;
    this._candidatesState.isNewCandidateLoading = false;

    this._candidatesState.newCandidateName = '';
    // this._candidatesState.newCandidateFirstName = '';
    // this._candidatesState.newCandidateLastName = '';
    this._candidatesState.newCandidateLinkedIn = '';
    // this._candidatesState.newCandidateImageUrl = '';
    this._candidatesState.newCandidatePositionGroupIds = [];

    // ----------------

    const { user } = this._authState;
    const { usersForRecruiterMenu } = this._candidatesState;

    const currentUserAsRecruiter:Common_User | undefined = usersForRecruiterMenu
      .find((_user:Common_User) => _user.id === user?.id);

    this._candidatesState.newCandidateAssignedUserId = currentUserAsRecruiter?.id || null;
  }

  @action.bound
  public hideNewCandidateDialog():void
  {
    this._candidatesState.isNewCandidateDialogOpen = false;
    this._candidatesState.isNewCandidateLoading = false;
  }

  @action.bound
  public setNewCandidateName(value:string):void
  {
    this._candidatesState.newCandidateName = value;
  }

  // @action.bound
  // public setNewCandidateFirstName(value:string):void
  // {
  //   this._candidatesState.newCandidateFirstName = value;
  // }

  // @action.bound
  // public setNewCandidateLastName(value:string):void
  // {
  //   this._candidatesState.newCandidateLastName = value;
  // }

  @action.bound
  public setNewCandidateLinkedIn(value:string):void
  {
    this._candidatesState.newCandidateLinkedIn = value;
  }

  // @action.bound
  // public setNewCandidateImageUrl(value:string):void
  // {
  //   this._candidatesState.newCandidateImageUrl = value;
  // }

  @action.bound
  public setNewCandidatePositionGroupIds(value:Array<number>):void
  {
    this._candidatesState.newCandidatePositionGroupIds = value;
  }

  @action.bound
  public setNewCandidateAssignedUserId(value:number | null):void
  {
    this._candidatesState.newCandidateAssignedUserId = value;
  }

  @action.bound
  public async saveNewCandidate():Promise<number | null>
  {
    const { newCandidateAssignedUserId } = this._candidatesState;

    const fullName:string = this._candidatesState.newCandidateName.trim();
    // const firstName:string = this._candidatesState.newCandidateFirstName.trim();
    // const lastName:string = this._candidatesState.newCandidateLastName.trim();
    const linkedIn:string = this._candidatesState.newCandidateLinkedIn.trim();
    // const imageUrl:string = this._candidatesState.newCandidateImageUrl.trim();
    const positionGroupIds:Array<number> = this._candidatesState.newCandidatePositionGroupIds;

    const names:Array<string> = fullName?.trim().split(' ');

    const firstName:string = names?.shift() || '';
    const lastName:string = names?.join(' ') || '';

    console.log('%c saveNewCandidate =', 'background:#aaf;color:#000;');

    if( !firstName || !lastName )
    {
      !firstName && console.log('%c ERROR firstNAme =', 'background:#f00;color:#ff0;', firstName);
      !lastName && console.log('%c ERROR lastName =', 'background:#f00;color:#ff0;', lastName);
      return null;
    }

    console.log('%c ---        firstName =', 'background:#080;color:#000;', firstName);
    console.log('%c ---         lastName =', 'background:#080;color:#000;', lastName);
    console.log('%c ---         linkedIn =', 'background:#080;color:#000;', linkedIn);
    // console.log('%c ---         imageUrl =', 'background:#080;color:#000;', imageUrl);
    console.log('%c --- positionGroupIds =', 'background:#080;color:#000;', toJS(positionGroupIds));

    const { user } = this._authState;

    const result:FetchResult<NewCandidateMutation> =
      await this._commonController.mutate<NewCandidateMutation,
        NewCandidateMutationVariables>({
        mutation: NewCandidateDocument,
        variables: {
          first_name: firstName,
          last_name: lastName,
          linkedin_profile_url: linkedIn,
          created_by_user_id: user?.id || 0,
          sourced_by_user_id: user?.id || 0,
          assigned_user_id: newCandidateAssignedUserId
        }
      });

    console.log('%c NewCandidateMutation result =', 'background:#f60;color:#000;', result);

    let newCandidateId:number | null = null;

    if( result && result.data )
    {
      const newCandidate:Recruiting_Candidate = result.data.insert_recruiting_candidate_one as Recruiting_Candidate;
      newCandidateId = newCandidate.id;
    }

    if( !newCandidateId )
      throw new Error('Failed to add candidate');

    if( newCandidateId && linkedIn )
    {
      this.fetchCandidateProfilePicture(newCandidateId, linkedIn);
    }

    if( newCandidateId && positionGroupIds.length )
    {
      await this._commonController.mutate<NewCandidatePositionGroupsMutation,
        NewCandidatePositionGroupsMutationVariables>({
        mutation: NewCandidatePositionGroupsDocument,
        variables: {
          positionGroups: positionGroupIds.map((positionGroupId:number) =>
          {
            return {
              candidate_id: newCandidateId,
              position_group_id: positionGroupId
            };
          })
        }
      });
    }

    this.hideNewCandidateDialog();

    if( newCandidateId )
    {
      const successToast = this._toastsConroller.showSuccess(CandidateCreatedToast({
        candidateId: newCandidateId,
        onNavigate: () =>
        {
          AppToaster.dismiss(successToast);
          this._commonController.goToCandidate(newCandidateId);
        }
      }), 5000);
    }

    this.queryCandidates();

    return newCandidateId;
  }

  public async fetchCandidateProfilePicture(candidateId:number, linkedInUrl:string):Promise<string | null>
  {
    if( !/https?:\/\/([a-z]{2,3}\.)?linkedin\.com\/in\/.*/.test(linkedInUrl) )
      return null;

    const fetchResult = await this._commonController
      .mutate<CandidateFetchLinkedInImageMutation, CandidateFetchLinkedInImageMutationVariables>({
        mutation: CandidateFetchLinkedInImageDocument,
        variables: { candidateId }
      }).catch(errorResponse =>
      {
        console.debug(errorResponse);
        return null;
      });

    if( !fetchResult )
      return null;

    if( fetchResult.errors )
      throw fetchResult.errors[0];

    if( fetchResult.data?.action_candidate_fetch_linkedin_image )
    {
      return fetchResult.data?.action_candidate_fetch_linkedin_image.avatar_url as string;
    }
    else
    {
      console.debug(fetchResult.data);
    }

    return null;
  }

  // ----------------------------------------------------

  @action.bound
  public selectFilter(filterType:FilterType, isSelected:boolean):void
  {
    console.log('%c selectFilter =', 'background:#0f0;color:#000;', filterType, isSelected);

    const filter:IFilter<IFilterValue> | null = this._candidatesState.filterByType(filterType);

    if( filter && filter.isSelected != isSelected )
      filter.isSelected = isSelected;

    if( !isSelected )
    {
      this.changeFilterValue(filterType, this.defaultFilterByType(filterType).value);

      if( !this._candidatesState.someFilterIsSelected )
      {
        this._candidatesState.selectedFilterId = null;
      }
    }
  }

  @action.bound
  public removeFilter(filterType:FilterType):void
  {
    console.log('%c removeFilter =', 'background:#0f0;color:#000;', filterType);
    this.selectFilter(filterType, false);
  }

  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public changeFilterValue(filterType:FilterType, newValue:any):void
  {
    console.log('%c changeFilter =', 'background:#0f0;color:#000;', filterType, newValue);

    const filter = this._candidatesState.filterByType(filterType);

    if( filter && filter.value != newValue )
      filter.value = newValue;
  }

  @action.bound
  public unselectAllFilters():void
  {
    this._candidatesState.filters.forEach((filter:IFilter<IFilterValue>) =>
    {
      this.selectFilter(filter.type, false);
    });

    this._candidatesState.selectedFilterId = null;

    // Object.keys(this._candidatesState.filters).forEach((filterType:string) =>
    // {
    //   this.selectFilter(filterType as FilterType, false);
    // });
  }

  @bind
  public defaultFilterByType(filterType:FilterType):IFilter<IFilterValue>
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let filter:any = null;

    console.log('%c CandidatesFiltersDefaultState =', 'background:#0f0;color:#000;', CandidatesFiltersDefaultState);

    CandidatesFiltersDefaultState.forEach((f:IFilter<IFilterValue>) =>
    {
      if( f.type === filterType ) filter = f;
    });

    if( !filter )
    {
      throw new Error(`!!! filterByType ERROR: filter '${filterType}' not found`);
    }

    return filter as IFilter<IFilterValue>;
  };

  // ----------------------------------------------------

  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getFilterData(filterType:FilterType):any
  {
    console.log('%c getFilterData =', 'background:#0f0;color:#000;', filterType);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let filterData:IFilterMultiSelectDataItems | any = null;

    switch( filterType )
    {
      case FilterType.POSITION_GROUP:
        filterData = {
          items: this._candidatesState.positionGroups.map((pg:Recruiting_Position_Group) =>
          {
            const ret:IFilterMultiSelectDataItem = { name: pg.name, value: pg.id };
            return ret;
          })
        };
        break;

      case FilterType.FLOW_STAGE:
        filterData = { interviewFlowStages: this._candidatesState.interviewFlowStages };
        break;

      case FilterType.ASSIGNED_TO:
      case FilterType.CREATED_BY:
        filterData = {
          items: this._candidatesState.users.map((user:Common_User) =>
          {
            const ret:IFilterMultiSelectDataItem = {
              name: this._candidatesState.userNameByUserId(user.id),
              value: user.id
            };
            return ret;
          })
        };
        break;

      case FilterType.STATUS:
        filterData = {
          statuses: [
            {
              title: 'Initial',
              value: Recruiting_Candidate_Status_Enum.Initial
            },
            {
              title: 'In Work',
              value: Recruiting_Candidate_Status_Enum.Active
            },
            {
              title: 'Staffed',
              value: Recruiting_Candidate_Status_Enum.Staffed
            },
            {
              title: 'Archived',
              value: Recruiting_Candidate_Status_Enum.Archived
            },
            {
              title: 'Declined',
              value: Recruiting_Candidate_Status_Enum.Declined
            },
            {
              title: 'Refused',
              value: Recruiting_Candidate_Status_Enum.Refused
            }
          ]
        };
        break;
    }

    console.log('%c ---           =', 'background:#080;color:#000;', toJS(filterData));
    return filterData;
  }

  // ----------------------------------------------------

  @action.bound
  public selectColumn(columnType:CandidatesColumnType, isVisible:boolean):void
  {
    console.log('%c selectColumn =', 'background:#0f0;color:#000;', columnType, isVisible);

    const column = this._candidatesState.columnByType(columnType);

    if( column.isVisible != isVisible )
      column.isVisible = isVisible;

    if( !isVisible && columnType === this._candidatesState.orderedBy?.type )
    {
      this.orderBy(columnType, null);
    }

    this.checkOrdersAndSetDefault();
    this.setQueryString();
  }

  @action.bound
  public unselectAllColumns():void
  {
    this._candidatesState.columns.forEach((column:ICandidatesColumn) =>
    {
      if( column.alwaysVisible ) return;

      this.selectColumn(column.type, false);
    });
  }

  // ----------------------------------------------------

  @action.bound
  public orderByNextState(columnType:CandidatesColumnType):void
  {
    const currentOrder = this._candidatesState.columnByType(columnType).orderBy;

    const nextOrderBy:OrderByType | null = !currentOrder
      ? OrderByType.ASC
      : currentOrder === OrderByType.ASC ? OrderByType.DESC : OrderByType.ASC;

    this.clearAllOrders();
    this.orderBy(columnType, nextOrderBy);
  }

  @action.bound
  public orderBy(columnType:CandidatesColumnType, orderBy:OrderByType | null):void
  {
    const column = this._candidatesState.columnByType(columnType);

    if( column.orderBy != orderBy )
      column.orderBy = orderBy;

    this.setQueryString();
  }

  @action.bound
  public clearAllOrders():void
  {
    this._candidatesState.columns.forEach((column:ICandidatesColumn) =>
    {
      this.orderBy(column.type, null);
    });
  }

  @action.bound
  public checkOrdersAndSetDefault():void
  {
    const isSomeOrdered:boolean = this._candidatesState.columns.some((column:ICandidatesColumn) =>
    {
      return column.isVisible && column.orderBy !== null;
    });

    if( isSomeOrdered ) return;

    const defaultOrderedColumn:number = CandidatesColumnsDefaultState.findIndex((defaultColumn:ICandidatesColumn) =>
    {
      return defaultColumn.orderBy !== null;
    }) + 1;

    if( defaultOrderedColumn )
    {
      this.orderBy(defaultOrderedColumn, OrderByType.ASC);
    }
  }

  // ----------------------------------------------------

  @action.bound
  public showSaveFilterDialog():void
  {
    this._candidatesState.isSaveFilterDialogOpen = true;
    this._candidatesState.isSaveFilterLoading = false;

    this._candidatesState.saveFilterMode = SaveFilterMode.NEW;
    this._candidatesState.saveFilterName = '';
    this._candidatesState.overwriteFilterId = this._candidatesState.selectedFilterId;
  }

  @action.bound
  public hideSaveFilterDialog():void
  {
    this._candidatesState.isSaveFilterDialogOpen = false;
    this._candidatesState.isSaveFilterLoading = false;
  }

  @action.bound
  public setSaveFilterName(value:string):void
  {
    this._candidatesState.saveFilterName = value;
  }

  @action.bound
  public setSaveFilterMode(saveFilterMode:SaveFilterMode):void
  {
    this._candidatesState.saveFilterMode = saveFilterMode;
  }

  @action.bound
  public selectUserFilter(filterId:number | null):void
  {
    this.setUserFilter(filterId);

    this._candidatesState.selectedFilterId = filterId;
  }

  @action.bound
  public selectOverwriteFilter(filterId:number | null):void
  {
    this._candidatesState.overwriteFilterId = filterId;
  }

  @action.bound
  public async saveFilter():Promise<number | null>
  {
    const { user } = this._authState;

    if( !user || !user.id )
    {
      console.log('%c user =', 'background:#f00;color:#ff0;', user);
      return null;
    }

    // --------------------------------------------------------------
    if( this._candidatesState.saveFilterMode === SaveFilterMode.NEW )
    {
      const filterName:string = this._candidatesState.saveFilterName.trim();
      console.log('%c ---       filterName =', 'background:#080;color:#000;', filterName);

      if( !filterName )
      {
        !filterName && console.log('%c ERROR filterName =', 'background:#f00;color:#ff0;', filterName);
        return null;
      }

      const content:string = this.getFiltersJSONString();
      console.log('%c getFiltersJSONString 1 =', 'background:#ff0;color:#000;', content);

      const result:FetchResult<NewFilterMutation> =
        await this._commonController.mutate<NewFilterMutation,
          NewFilterMutationVariables>({
          mutation: NewFilterDocument,
          variables: {
            name: filterName,
            type: Common_Filter_Type_Enum.Recruiting,
            user_id: user.id,
            content
          }
        });

      console.log('%c NewFilterMutation result =', 'background:#f60;color:#000;', result);

      let newFilterId:number | null = null;

      if( result && result.data )
      {
        const newFilter:Common_Filter = result.data.insert_common_filter_one as Common_Filter;

        this._candidatesState.userFilters.push(newFilter);

        newFilterId = newFilter.id;

        if( newFilterId )
        {
          this._candidatesState.selectedFilterId = newFilterId;
          this._candidatesState.isFiltersDirty = false;
        }
      }

      if( !newFilterId )
      {
        // TODO: show ERRORS
      }
      this.hideSaveFilterDialog();

      return newFilterId;
    }
    // --------------------------------------------------------------
    else // SaveFilterMode.OVERWRITE
    {
      const { overwriteFilterId } = this._candidatesState;

      if( !overwriteFilterId )
      {
        // TODO error
        return null;
      }

      const content:string = this.getFiltersJSONString();
      console.log('%c getFiltersJSONString 2 =', 'background:#ff0;color:#000;', content);

      const result:FetchResult<UpdateFilterMutation> =
        await this._commonController.mutate<UpdateFilterMutation,
          UpdateFilterMutationVariables>({
          mutation: UpdateFilterDocument,
          variables: {
            id: overwriteFilterId,
            content
          }
        });

      console.log('%c UpdateFilterMutation result =', 'background:#f60;color:#000;', result);

      let newFilterId:number | null = null;

      if( result && result.data )
      {
        const newFilter:Common_Filter = result.data.update_common_filter_by_pk as Common_Filter;

        newFilterId = newFilter.id;

        const updatedFilter:Common_Filter | undefined = this._candidatesState.userFilterById(newFilterId);

        if( newFilterId && updatedFilter )
        {
          updatedFilter.content = newFilter.content;

          this._candidatesState.selectedFilterId = newFilterId;
          this._candidatesState.isFiltersDirty = false;
        }
      }

      if( !newFilterId )
      {
        // TODO: show ERRORS
      }
      this.hideSaveFilterDialog();

      return newFilterId;
    }
  }

  @action.bound
  public async onFilterSaved():Promise<void>
  {
    return Promise.resolve();
  }

  public getFiltersJSONString():string
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const returnedValue:any = {};

    if( !Array.isArray(this._candidatesState.filters) )
      return '"{}"';

    this._candidatesState.filters.forEach((filter:IFilter<IFilterValue>) =>
    {
      if( !filter.isSelected ) return;

      returnedValue[filter.type] = filter.value;
    });

    const tab = WindowLocationUtil.getHashVariable('tab');

    if( tab )
      returnedValue.tab = tab;

    return JSON.stringify(returnedValue);
  }

  // ----------------------------------------------------

  public columnsIsChanged():boolean
  {
    return CandidatesColumnsDefaultState.some((defaultColumn:ICandidatesColumn) =>
    {
      const column:ICandidatesColumn = this._candidatesState.columnByType(defaultColumn.type);

      return column.isVisible !== defaultColumn.isVisible || column.orderBy !== defaultColumn.orderBy;
    });
  }

  // ----------------------------------------------------

  @action.bound
  public setFilterByCandidateStatus(value:CandidatesStatusFilter):void
  {
    this._candidatesState.candidatesStatusFilterValue = value;

    WindowLocationUtil.setHashVariable('tab', value);

    console.debug('Set hash params:', window.location.hash);
  }

  // ----------------------------------------------------

  @action.bound
  public setCurrentPage(newCurrentPage:number):void
  {
    this._candidatesState.currentPage = newCurrentPage;
  }

// ----------------------------------------------------

}
