import { Button, ButtonSize } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { Alignment } from '@flow/common/components/types/Types';
import { component } from '@flow/dependency-injection';

import classNames from 'classnames/bind';
import React from 'react';
import styles from './Dialog.module.less';

const cx = classNames.bind(styles);

interface Props
{
  className?:string;
  text?:string;
  disabled?:boolean;
}

@component
export class DialogSelectButton extends React.Component<Props>
{
  public render():React.ReactNode
  {
    const { className, text, disabled } = this.props;

    return (
      <Button
        className={cx(styles.selectBtn, className, { disabled })}
        text={text}
        rightIcon={IconNames.CARET_DOWN}
        alignText={Alignment.LEFT}
        minimal={true}
        size={ButtonSize.LARGE}
        disabled={disabled}
      />
    );
  }
}

