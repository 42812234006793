import type * as Types from './types/graphql.generated';

import { gql } from '@apollo/client';
import { Open_Slots_CountFragmentDoc, Total_Slots_CountFragmentDoc, Leaving_SlotsFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
export type GetPositionGroupsSettingsDataQueryVariables = Types.Exact<Record<string, never>>;


export type GetPositionGroupsSettingsDataQuery = { recruiting_position_group:Array<{ id:number; name:string; is_active:boolean; ui_order:number; interview_flow_id?:number | null; positions:Array<{ open_slots_count:{ aggregate?:{ count:number; } | null; }; leaving_slots:Array<{ status:Types.Staffing_Customer_Team_Slot_Status_Enum; leaving_date?:any | null; }>; }>; position_group_priorities:Array<{ user:{ first_name:string; last_name:string; }; }>; }>; recruiting_interview_flow:Array<{ id:number; name:string; }>; };

export type AddPositionGroupMutationVariables = Types.Exact<{
  name:Types.Scalars['String'];
  ui_order:Types.Scalars['Int'];
  is_active:Types.Scalars['Boolean'];
  interview_flow_id:Types.Scalars['Int'];
}>;


export type AddPositionGroupMutation = { insert_recruiting_position_group_one?:{ id:number; name:string; is_active:boolean; ui_order:number; interview_flow_id?:number | null; positions:Array<{ id:number; }>; } | null; };

export type EditPositionGroupNameMutationVariables = Types.Exact<{
  id:Types.Scalars['Int'];
  name:Types.Scalars['String'];
  interview_flow_id:Types.Scalars['Int'];
}>;


export type EditPositionGroupNameMutation = { update_recruiting_position_group_by_pk?:{ id:number; name:string; interview_flow_id?:number | null; } | null; };

export type UpdatePositionGroupUiOrderMutationVariables = Types.Exact<{
  id:Types.Scalars['Int'];
  ui_order:Types.Scalars['Int'];
}>;


export type UpdatePositionGroupUiOrderMutation = { update_recruiting_position_group_by_pk?:{ id:number; ui_order:number; } | null; };

export type DeletePositionGroupMutationVariables = Types.Exact<{
  id:Types.Scalars['Int'];
  deleted_at:Types.Scalars['timestamptz'];
}>;


export type DeletePositionGroupMutation = { update_recruiting_position_group_by_pk?:{ id:number; deleted_at?:any | null; } | null; };

export type SetPositionGroupIsActiveMutationVariables = Types.Exact<{
  id:Types.Scalars['Int'];
  is_active:Types.Scalars['Boolean'];
  ui_order:Types.Scalars['Int'];
}>;


export type SetPositionGroupIsActiveMutation = { update_recruiting_position_group_by_pk?:{ id:number; is_active:boolean; ui_order:number; } | null; };

export type SetPositionGroupForPositionMutationVariables = Types.Exact<{
  id:Types.Scalars['Int'];
  position_group_id?:Types.InputMaybe<Types.Scalars['Int']>;
  ui_position_groups_order:Types.Scalars['Int'];
}>;


export type SetPositionGroupForPositionMutation = { update_recruiting_position_by_pk?:{ id:number; position_group_id?:number | null; ui_position_groups_order:number; position_group?:{ id:number; name:string; is_active:boolean; ui_order:number; positions:Array<{ id:number; }>; position_group_priorities:Array<{ user:{ first_name:string; last_name:string; }; }>; } | null; } | null; };

export type SetPositionGroupOfPositionMutationVariables = Types.Exact<{
  id:Types.Scalars['Int'];
  position_group_id?:Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type SetPositionGroupOfPositionMutation = { update_recruiting_position_by_pk?:{ id:number; position_group_id?:number | null; } | null; };


export const GetPositionGroupsSettingsDataDocument = gql`
    query GetPositionGroupsSettingsData {
  recruiting_position_group(where: {deleted_at: {_is_null: true}}) {
    id
    name
    is_active
    ui_order
    positions {
      ...open_slots_count
      ...leaving_slots
    }
    position_group_priorities {
      user {
        first_name
        last_name
      }
    }
    interview_flow_id
  }
  recruiting_interview_flow {
    id
    name
  }
}
    ${Open_Slots_CountFragmentDoc}
${Leaving_SlotsFragmentDoc}`;
export type GetPositionGroupsSettingsDataQueryResult = Apollo.QueryResult<GetPositionGroupsSettingsDataQuery, GetPositionGroupsSettingsDataQueryVariables>;
export const AddPositionGroupDocument = gql`
    mutation AddPositionGroup($name: String!, $ui_order: Int!, $is_active: Boolean!, $interview_flow_id: Int!) {
  insert_recruiting_position_group_one(
    object: {name: $name, ui_order: $ui_order, is_active: $is_active, interview_flow_id: $interview_flow_id}
  ) {
    id
    name
    is_active
    ui_order
    positions {
      id
    }
    interview_flow_id
  }
}
    `;
export type AddPositionGroupMutationFn = Apollo.MutationFunction<AddPositionGroupMutation, AddPositionGroupMutationVariables>;
export type AddPositionGroupMutationResult = Apollo.MutationResult<AddPositionGroupMutation>;
export type AddPositionGroupMutationOptions = Apollo.BaseMutationOptions<AddPositionGroupMutation, AddPositionGroupMutationVariables>;
export const EditPositionGroupNameDocument = gql`
    mutation EditPositionGroupName($id: Int!, $name: String!, $interview_flow_id: Int!) {
  update_recruiting_position_group_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, interview_flow_id: $interview_flow_id}
  ) {
    id
    name
    interview_flow_id
  }
}
    `;
export type EditPositionGroupNameMutationFn = Apollo.MutationFunction<EditPositionGroupNameMutation, EditPositionGroupNameMutationVariables>;
export type EditPositionGroupNameMutationResult = Apollo.MutationResult<EditPositionGroupNameMutation>;
export type EditPositionGroupNameMutationOptions = Apollo.BaseMutationOptions<EditPositionGroupNameMutation, EditPositionGroupNameMutationVariables>;
export const UpdatePositionGroupUiOrderDocument = gql`
    mutation UpdatePositionGroupUiOrder($id: Int!, $ui_order: Int!) {
  update_recruiting_position_group_by_pk(
    pk_columns: {id: $id}
    _set: {ui_order: $ui_order}
  ) {
    id
    ui_order
  }
}
    `;
export type UpdatePositionGroupUiOrderMutationFn = Apollo.MutationFunction<UpdatePositionGroupUiOrderMutation, UpdatePositionGroupUiOrderMutationVariables>;
export type UpdatePositionGroupUiOrderMutationResult = Apollo.MutationResult<UpdatePositionGroupUiOrderMutation>;
export type UpdatePositionGroupUiOrderMutationOptions = Apollo.BaseMutationOptions<UpdatePositionGroupUiOrderMutation, UpdatePositionGroupUiOrderMutationVariables>;
export const DeletePositionGroupDocument = gql`
    mutation DeletePositionGroup($id: Int!, $deleted_at: timestamptz!) {
  update_recruiting_position_group_by_pk(
    pk_columns: {id: $id}
    _set: {deleted_at: $deleted_at}
  ) {
    id
    deleted_at
  }
}
    `;
export type DeletePositionGroupMutationFn = Apollo.MutationFunction<DeletePositionGroupMutation, DeletePositionGroupMutationVariables>;
export type DeletePositionGroupMutationResult = Apollo.MutationResult<DeletePositionGroupMutation>;
export type DeletePositionGroupMutationOptions = Apollo.BaseMutationOptions<DeletePositionGroupMutation, DeletePositionGroupMutationVariables>;
export const SetPositionGroupIsActiveDocument = gql`
    mutation SetPositionGroupIsActive($id: Int!, $is_active: Boolean!, $ui_order: Int!) {
  update_recruiting_position_group_by_pk(
    pk_columns: {id: $id}
    _set: {is_active: $is_active, ui_order: $ui_order}
  ) {
    id
    is_active
    ui_order
  }
}
    `;
export type SetPositionGroupIsActiveMutationFn = Apollo.MutationFunction<SetPositionGroupIsActiveMutation, SetPositionGroupIsActiveMutationVariables>;
export type SetPositionGroupIsActiveMutationResult = Apollo.MutationResult<SetPositionGroupIsActiveMutation>;
export type SetPositionGroupIsActiveMutationOptions = Apollo.BaseMutationOptions<SetPositionGroupIsActiveMutation, SetPositionGroupIsActiveMutationVariables>;
export const SetPositionGroupForPositionDocument = gql`
    mutation SetPositionGroupForPosition($id: Int!, $position_group_id: Int, $ui_position_groups_order: Int!) {
  update_recruiting_position_by_pk(
    pk_columns: {id: $id}
    _set: {position_group_id: $position_group_id, ui_position_groups_order: $ui_position_groups_order}
  ) {
    id
    position_group_id
    ui_position_groups_order
    position_group {
      id
      name
      is_active
      ui_order
      positions {
        id
      }
      position_group_priorities {
        user {
          first_name
          last_name
        }
      }
    }
  }
}
    `;
export type SetPositionGroupForPositionMutationFn = Apollo.MutationFunction<SetPositionGroupForPositionMutation, SetPositionGroupForPositionMutationVariables>;
export type SetPositionGroupForPositionMutationResult = Apollo.MutationResult<SetPositionGroupForPositionMutation>;
export type SetPositionGroupForPositionMutationOptions = Apollo.BaseMutationOptions<SetPositionGroupForPositionMutation, SetPositionGroupForPositionMutationVariables>;
export const SetPositionGroupOfPositionDocument = gql`
    mutation SetPositionGroupOfPosition($id: Int!, $position_group_id: Int) {
  update_recruiting_position_by_pk(
    pk_columns: {id: $id}
    _set: {position_group_id: $position_group_id}
  ) {
    id
    position_group_id
  }
}
    `;
export type SetPositionGroupOfPositionMutationFn = Apollo.MutationFunction<SetPositionGroupOfPositionMutation, SetPositionGroupOfPositionMutationVariables>;
export type SetPositionGroupOfPositionMutationResult = Apollo.MutationResult<SetPositionGroupOfPositionMutation>;
export type SetPositionGroupOfPositionMutationOptions = Apollo.BaseMutationOptions<SetPositionGroupOfPositionMutation, SetPositionGroupOfPositionMutationVariables>;
export const namedOperations = {
  Query: {
    GetPositionGroupsSettingsData: 'GetPositionGroupsSettingsData'
  },
  Mutation: {
    AddPositionGroup: 'AddPositionGroup',
    EditPositionGroupName: 'EditPositionGroupName',
    UpdatePositionGroupUiOrder: 'UpdatePositionGroupUiOrder',
    DeletePositionGroup: 'DeletePositionGroup',
    SetPositionGroupIsActive: 'SetPositionGroupIsActive',
    SetPositionGroupForPosition: 'SetPositionGroupForPosition',
    SetPositionGroupOfPosition: 'SetPositionGroupOfPosition'
  }
}