import { MenuItem } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { Suggest } from '@blueprintjs/select';
import { CommonController } from '@flow/common/CommonController';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { IconNames } from '@flow/common/components/form/Icon';
import { Maybe } from '@flow/common/models/Types';
import type { Staffing_Staff } from '@flow/data-access/lib/types/graphql.generated';
import { Staffing_Staff_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { StaffController } from '@flow/modules/staffing/staff/StaffController';
import { StaffState } from '@flow/modules/staffing/staff/StaffState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './StaffSearch.module.less';

const cx = classNames.bind(styles);

export interface IStaffUserSearchItem
{
  id:number;
  avatarUrl:string;
  firstName:string;
  lastName:string;
  email:string;
  status:Maybe<Staffing_Staff_Status_Enum>;
}

interface IStaffUserSearchFoundIn
{
  inEmail:boolean;
  inName:boolean;
}

interface State
{
  isFocused:boolean;
}

@component
export class StaffSearch extends React.Component<unknown, State>
{
  @di private _commonController!:CommonController;
  @di private _staffState!:StaffState;
  @di private _staffController!:StaffController;

  // ---------------------------------------------------------

  public constructor(props:unknown)
  {
    super(props);

    this.state = {
      isFocused: false
    };
  }

  // ---------------------------------------------------------

  private _renderInputValue(/* item:IStaffUserSearchItem */):string
  {
    // return `${item.firstName} ${item.lastName}`;
    return '';
  }

  @bind
  private _itemRenderer(item:IStaffUserSearchItem, {
    modifiers,
    handleClick,
    query,
    index
  }:IItemRendererProps):JSX.Element | null
  {
    if( query.trim() === '' && index === 0 )
    {
      return (<MenuItem key={'disabled'} disabled={true} text="Start typing to find staff" />);
    }

    if( !modifiers.matchesPredicate || index && index > 20 || query.trim() === '' )
    {
      return null;
    }

    // let moreStaff:number = 0;

    // if( index && index === 20 )
    // {
    //   moreStaff = this._staffState.staff.length - 20;
    // }

    const { firstName, lastName, email } = item;

    const nameStr:string = `${firstName} ${lastName}`;

    const searchResult:IStaffUserSearchFoundIn = this._getSearchResult(query, item, true) as IStaffUserSearchFoundIn;

    const { inName, inEmail } = searchResult;

    return (
      <MenuItem
        active={modifiers.active}
        key={item.firstName + item.lastName + index?.toString()}
        onClick={handleClick}
        shouldDismissPopover={true}
        // disabled={!!moreStaff}
        text={(
          <div className={styles.menuItemText}>
            <>
              <Avatar
                className={styles.avatar}
                url={item.avatarUrl}
              />
              <div className={styles.text}>
                <div className={styles.name}>
                  {inName ? this._highlightText(nameStr, query) : nameStr}
                </div>
                <div className={styles.otherText}>
                  {inEmail && this._highlightText(email, query)}
                </div>
              </div>
            </>
          </div>
        )}
      />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _onItemSelect(item:IStaffUserSearchItem):void
  {
    console.log('%c _onItemSelect item =', 'background:#0f0;color:#000;', item);

    const staffUser:Staffing_Staff | undefined = this._staffState.staffUserById(item.id);

    if( !staffUser ) return;

    // // this._staffController.showEditStaffUserForm(staffUser);
    this._staffController.setViewStaffId(staffUser.id);
    this._commonController.goToStaffMember(staffUser.id);
  };

  // ---------------------------------------------------------

  @bind
  private _itemPredicate(query:string, item:IStaffUserSearchItem /*, _index:any, exactMatch:any */):boolean
  {
    return this._getSearchResult(query, item, false) as boolean;
  };

  @bind
  private _trimLinkedin(linkedIn:string):string
  {
    return linkedIn
      .toLowerCase()
      .replace(/(http[s]?:\/\/)?([w]{3}.)?/, '')
      .replace(/(.*)(\/)$/, '$1');
  };

  @bind
  private _getSearchResult(query:string, item:IStaffUserSearchItem, isMenuItem:boolean):boolean | IStaffUserSearchFoundIn
  {
    const { isShowArchived } = this._staffState;

    const { firstName, lastName, email, status } = item;

    if( status !== Staffing_Staff_Status_Enum.Archived && isShowArchived ) return false;
    if( status === Staffing_Staff_Status_Enum.Archived && !isShowArchived ) return false;

    const inEmail:boolean = email.toLowerCase() === query.toLowerCase();
    const words:Array<string> = query.toLowerCase().split(' ');

    const searchInName:string = `${firstName} ${lastName}`.toLowerCase();
    const inName:boolean = words.every((word:string) => searchInName.indexOf(word) !== -1);

    const isFound:boolean = inEmail || inName;

    if( !isFound ) return false;

    if( isMenuItem )
    {
      return { inEmail, inName };
    }
    else // is Predicate Function
    {
      return isFound;
    }
  };

  // ---------------------------------------------------------

  private _escapeRegExpChars(text:string):string
  {
    return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
  }

  private _highlightText(text:string, query:string):Array<React.ReactNode>
  {
    let lastIndex = 0;
    const words = query
      .split(/\s+/)
      .filter(word => word.length > 0)
      .map(this._escapeRegExpChars);

    if( words.length === 0 )
    {
      return [text];
    }

    const regexp = new RegExp(words.join('|'), 'gi');
    const tokens:Array<React.ReactNode> = [];

    // eslint-disable-next-line no-constant-condition
    while( true )
    {
      const match = regexp.exec(text);
      if( !match )
      {
        break;
      }
      const length = match[0].length;
      const before = text.slice(lastIndex, regexp.lastIndex - length);

      if( before.length > 0 )
      {
        tokens.push(before);
      }
      lastIndex = regexp.lastIndex;
      tokens.push(<strong className={styles.token} key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if( rest.length > 0 )
    {
      tokens.push(rest);
    }
    return tokens;
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { isShowArchived, isStaffUsersLoaded } = this._staffState;

    const SearchInput = Suggest.ofType<IStaffUserSearchItem>();

    const { isFocused } = this.state;

    return (
      <SearchInput
        className={cx(styles.searchInput, { isFocused })}
        inputValueRenderer={this._renderInputValue}
        items={this._staffState.staffUsersForSearch}
        itemRenderer={this._itemRenderer}
        onItemSelect={this._onItemSelect}
        disabled={!isStaffUsersLoaded}

        resetOnQuery={true}
        resetOnClose={true}
        resetOnSelect={true}

        itemPredicate={this._itemPredicate}

        noResults={<MenuItem disabled={true} text="No results" />}

        popoverProps={{
          // className: styles.searchPopover, // => searchInput
          popoverClassName: styles.popoverClassName,
          targetClassName: styles.targetClassName,
          hasBackdrop: true,
          backdropProps: { className: styles.backdrop }
          // usePortal: false
        }}

        inputProps={{
          className: styles.inputClassName,
          placeholder: `Search for ${isShowArchived ? 'archived ' : ''}employees...`,
          leftIcon: IconNames.SEARCH,
          round: true,
          // large: true,
          fill: true,
          onFocus: ():void => this.setState({ isFocused: true }) as void,
          onBlur: ():void => this.setState({ isFocused: false }) as void
        }}
      />
    );
  }
}
