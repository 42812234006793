import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { Toolbar } from '@flow/common/components/page/Toolbar';
import { component, di } from '@flow/dependency-injection';
// import classNames from 'classnames/bind';
import * as React from 'react';
import { CandidatesState } from '../../../CandidatesState';

import styles from './CandidateFormToolbar.module.less';

// const cx = classNames.bind(styles);

interface State
{
  qwe:string;
}

@component
export class CandidateFormToolbar extends React.Component<unknown, State>
{
  @di private _candidatesState!:CandidatesState;

  public constructor(props:unknown)
  {
    super(props);

    this.state = {
      qwe: ''
    };
  }

  public render():React.ReactNode
  {
    const isAddNewCandidate:boolean = this._candidatesState.isFormModeAdd;
    const formTitle = (isAddNewCandidate ? 'New' : '') + ' Candidate';

    return (
      <Toolbar
        className={styles.toolbar}
        icon={IconNames.ARROW_RIGHT}
        title={formTitle}
        rightElements={
          <>
            <Button
              icon={IconNames.SHARE}
              minimal={true}
            />

            <Button
              icon={IconNames.MORE}
              minimal={true}
            />
          </>
        }
      />
    );
  }
}
