import { ConfirmationDialog } from '@flow/common/components/page/ConfirmationDialog';
import { DialogWarningMessage } from '@flow/common/components/page/DialogWarningMessage';
import { Intent } from '@flow/common/components/types/Types';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { component, di } from '@flow/dependency-injection';
import { CandidateFlowController } from '@flow/modules/recruiting/candidates/CandidateFlowController';
import { CandidateFlowState } from '@flow/modules/recruiting/candidates/CandidateFlowState';
import { CandidateState } from '@flow/modules/recruiting/candidates/CandidateState';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './DialogMoveCandidateToInitial.module.less';

const cx = classNames.bind(styles);

@component
export class DialogMoveCandidateToInitial extends React.Component
{
  @di private _candidateState!:CandidateState;
  @di private _candidateFlowState!:CandidateFlowState;

  @di private _roleController!:RoleController;
  @di private _candidateFlowsController!:CandidateFlowController;

  // ---------------------------------------------------------

  public render():ReactNode | null
  {
    const { candidate } = this._candidateState;
    const { isShowMoveToInitialDialog } = this._candidateFlowState;

    const isDisabled:boolean = !this._roleController.hasPermission(FlowPermissions.ChangeCandidateFlowStage);

    if( isDisabled || !candidate ) return null;

    const hasFinalPosition:boolean = candidate.customer_team_slots.length > 0;
    const hasCalls:boolean = candidate.scheduled_calls.length > 0;

    let warningText:string = hasFinalPosition && hasCalls
      ? 'Final position and calls'
      : hasFinalPosition
        ? 'Final position'
        : hasCalls
          ? 'Calls'
          : '';

    warningText && (warningText += ' will be removed');

    return (
      <ConfirmationDialog
        title={'Move candidate to Initial?'}
        confirmButtonText={'Confirm'}
        onConfirm={this._candidateFlowsController.moveCandidateToInitial}
        isOpen={isShowMoveToInitialDialog}
        onClose={():void => this._candidateFlowsController.showToInitialDialog(false)}
        primaryButtonIntent={Intent.PRIMARY}
      >
        {
          warningText &&
          <DialogWarningMessage text={warningText} />
        }
      </ConfirmationDialog>
    );
  }

  // ---------------------------------------------------------
}
