export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends Record<string, unknown>> = { [K in keyof T]:T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?:Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]:Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID:string;
  String:string;
  Boolean:boolean;
  Int:number;
  Float:number;
  bigint:any;
  inet:any;
  json:any;
  jsonb:any;
  name:any;
  oid:any;
  timestamp:any;
  timestamptz:any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?:InputMaybe<Scalars['Boolean']>;
  _gt?:InputMaybe<Scalars['Boolean']>;
  _gte?:InputMaybe<Scalars['Boolean']>;
  _in?:InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _lt?:InputMaybe<Scalars['Boolean']>;
  _lte?:InputMaybe<Scalars['Boolean']>;
  _neq?:InputMaybe<Scalars['Boolean']>;
  _nin?:InputMaybe<Array<Scalars['Boolean']>>;
};

export type CalendarFreeBusyResponse = {
  __typename?:'CalendarFreeBusyResponse';
  calendars?:Maybe<Scalars['jsonb']>;
};

export type CandidateCvUploadResponse = {
  __typename?:'CandidateCvUploadResponse';
  cv_google_doc_ref:Scalars['String'];
  id:Scalars['Int'];
};

export type CandidateFetchLinkedinImageResponse = {
  __typename?:'CandidateFetchLinkedinImageResponse';
  avatar_url:Scalars['String'];
  id:Scalars['Int'];
};

export type CreatePositionInput = {
  customerTeamId:Scalars['Int'];
  positionTemplateId:Scalars['Int'];
  quantity:Scalars['Int'];
};

export type CreatePositionResponse = {
  __typename?:'CreatePositionResponse';
  google_doc_url:Scalars['String'];
  id:Scalars['Int'];
};

export type DeletePositionInput = {
  id:Scalars['Int'];
};

export type DeletePositionRequest = {
  id:Scalars['Int'];
};

export type GoogleCalendarEvent = {
  __typename?:'GoogleCalendarEvent';
  attendees:Array<GoogleCalendarEventAttendees>;
  created:Scalars['timestamptz'];
  id:Scalars['String'];
  organizer:GoogleCalendarEventOrganizer;
  start:GoogleCalendarEventStart;
  summary?:Maybe<Scalars['String']>;
};

export type GoogleCalendarEventAttendees = {
  __typename?:'GoogleCalendarEventAttendees';
  email:Scalars['String'];
  responseStatus:Scalars['String'];
};

export type GoogleCalendarEventOrganizer = {
  __typename?:'GoogleCalendarEventOrganizer';
  email?:Maybe<Scalars['String']>;
};

export type GoogleCalendarEventStart = {
  __typename?:'GoogleCalendarEventStart';
  dateTime?:Maybe<Scalars['String']>;
};

export type GoogleCalendarEvents = {
  __typename?:'GoogleCalendarEvents';
  events:Array<GoogleCalendarEvent>;
};

export type HasuraActionDefaultResponse = {
  __typename?:'HasuraActionDefaultResponse';
  success?:Maybe<Scalars['Boolean']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?:InputMaybe<Scalars['Int']>;
  _gt?:InputMaybe<Scalars['Int']>;
  _gte?:InputMaybe<Scalars['Int']>;
  _in?:InputMaybe<Array<Scalars['Int']>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _lt?:InputMaybe<Scalars['Int']>;
  _lte?:InputMaybe<Scalars['Int']>;
  _neq?:InputMaybe<Scalars['Int']>;
  _nin?:InputMaybe<Array<Scalars['Int']>>;
};

export type LoginResponse = {
  __typename?:'LoginResponse';
  accessToken?:Maybe<Scalars['String']>;
  avatar?:Maybe<Scalars['String']>;
  email:Scalars['String'];
  firstName:Scalars['String'];
  hasRefreshToken:Scalars['Boolean'];
  id?:Maybe<Scalars['Int']>;
  lastName:Scalars['String'];
  roles:Array<Scalars['String']>;
};

export type PositionsBatchCrudRequest = {
  create:Array<CreatePositionInput>;
  delete:Array<DeletePositionInput>;
  updateQuantity:Array<UpdatePositionQuantityInput>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?:InputMaybe<Scalars['String']>;
  _gt?:InputMaybe<Scalars['String']>;
  _gte?:InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?:InputMaybe<Scalars['String']>;
  _in?:InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?:InputMaybe<Scalars['String']>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?:InputMaybe<Scalars['String']>;
  _lt?:InputMaybe<Scalars['String']>;
  _lte?:InputMaybe<Scalars['String']>;
  _neq?:InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?:InputMaybe<Scalars['String']>;
  _nin?:InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?:InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?:InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?:InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?:InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?:InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?:InputMaybe<Scalars['String']>;
};

export type UpdatePositionQuantityInput = {
  id:Scalars['Int'];
  quantity:Scalars['Int'];
};

/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_Actions = {
  __typename?:'audit_logged_actions';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action:Scalars['String'];
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk:Scalars['timestamptz'];
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm:Scalars['timestamptz'];
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx:Scalars['timestamptz'];
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?:Maybe<Scalars['String']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?:Maybe<Scalars['jsonb']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?:Maybe<Scalars['inet']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?:Maybe<Scalars['Int']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?:Maybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id:Scalars['bigint'];
  full_qualified_table_name:Scalars['String'];
  hasura_user?:Maybe<Scalars['jsonb']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid:Scalars['oid'];
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?:Maybe<Scalars['jsonb']>;
  /** Database schema audited table for this event is in */
  schema_name:Scalars['String'];
  /** Login / session user whose statement caused the audited event */
  session_user_name?:Maybe<Scalars['String']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only:Scalars['Boolean'];
  /** Non-schema-qualified table name of table event occured in */
  table_name:Scalars['String'];
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?:Maybe<Scalars['bigint']>;
  user?:Maybe<Array<Common_User>>;
};


/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_ActionsChanged_FieldsArgs = {
  path?:InputMaybe<Scalars['String']>;
};


/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_ActionsHasura_UserArgs = {
  path?:InputMaybe<Scalars['String']>;
};


/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_ActionsRow_DataArgs = {
  path?:InputMaybe<Scalars['String']>;
};


/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_ActionsUserArgs = {
  distinct_on?:InputMaybe<Array<Common_User_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_Order_By>>;
  where?:InputMaybe<Common_User_Bool_Exp>;
};

/** aggregated selection of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate = {
  __typename?:'audit_logged_actions_aggregate';
  aggregate?:Maybe<Audit_Logged_Actions_Aggregate_Fields>;
  nodes:Array<Audit_Logged_Actions>;
};

/** aggregate fields of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate_Fields = {
  __typename?:'audit_logged_actions_aggregate_fields';
  avg?:Maybe<Audit_Logged_Actions_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Audit_Logged_Actions_Max_Fields>;
  min?:Maybe<Audit_Logged_Actions_Min_Fields>;
  stddev?:Maybe<Audit_Logged_Actions_Stddev_Fields>;
  stddev_pop?:Maybe<Audit_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Audit_Logged_Actions_Stddev_Samp_Fields>;
  sum?:Maybe<Audit_Logged_Actions_Sum_Fields>;
  var_pop?:Maybe<Audit_Logged_Actions_Var_Pop_Fields>;
  var_samp?:Maybe<Audit_Logged_Actions_Var_Samp_Fields>;
  variance?:Maybe<Audit_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logged_Actions_Append_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?:InputMaybe<Scalars['jsonb']>;
  hasura_user?:InputMaybe<Scalars['jsonb']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?:InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Audit_Logged_Actions_Avg_Fields = {
  __typename?:'audit_logged_actions_avg_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?:Maybe<Scalars['Float']>;
  /** Unique identifier for each auditable event */
  event_id?:Maybe<Scalars['Float']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?:Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "audit.logged_actions". All fields are combined with a logical 'AND'. */
export type Audit_Logged_Actions_Bool_Exp = {
  _and?:InputMaybe<Array<Audit_Logged_Actions_Bool_Exp>>;
  _not?:InputMaybe<Audit_Logged_Actions_Bool_Exp>;
  _or?:InputMaybe<Array<Audit_Logged_Actions_Bool_Exp>>;
  action?:InputMaybe<String_Comparison_Exp>;
  action_tstamp_clk?:InputMaybe<Timestamptz_Comparison_Exp>;
  action_tstamp_stm?:InputMaybe<Timestamptz_Comparison_Exp>;
  action_tstamp_tx?:InputMaybe<Timestamptz_Comparison_Exp>;
  application_name?:InputMaybe<String_Comparison_Exp>;
  changed_fields?:InputMaybe<Jsonb_Comparison_Exp>;
  client_addr?:InputMaybe<Inet_Comparison_Exp>;
  client_port?:InputMaybe<Int_Comparison_Exp>;
  client_query?:InputMaybe<String_Comparison_Exp>;
  event_id?:InputMaybe<Bigint_Comparison_Exp>;
  full_qualified_table_name?:InputMaybe<String_Comparison_Exp>;
  hasura_user?:InputMaybe<Jsonb_Comparison_Exp>;
  relid?:InputMaybe<Oid_Comparison_Exp>;
  row_data?:InputMaybe<Jsonb_Comparison_Exp>;
  schema_name?:InputMaybe<String_Comparison_Exp>;
  session_user_name?:InputMaybe<String_Comparison_Exp>;
  statement_only?:InputMaybe<Boolean_Comparison_Exp>;
  table_name?:InputMaybe<String_Comparison_Exp>;
  transaction_id?:InputMaybe<Bigint_Comparison_Exp>;
  user?:InputMaybe<Common_User_Bool_Exp>;
};

/** unique or primary key constraints on table "audit.logged_actions" */
export enum Audit_Logged_Actions_Constraint 
{
  /** unique or primary key constraint on columns "event_id" */
  LoggedActionsPkey = 'logged_actions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audit_Logged_Actions_Delete_At_Path_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?:InputMaybe<Array<Scalars['String']>>;
  hasura_user?:InputMaybe<Array<Scalars['String']>>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?:InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audit_Logged_Actions_Delete_Elem_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?:InputMaybe<Scalars['Int']>;
  hasura_user?:InputMaybe<Scalars['Int']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?:InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audit_Logged_Actions_Delete_Key_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?:InputMaybe<Scalars['String']>;
  hasura_user?:InputMaybe<Scalars['String']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?:InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "audit.logged_actions" */
export type Audit_Logged_Actions_Inc_Input = {
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?:InputMaybe<Scalars['Int']>;
  /** Unique identifier for each auditable event */
  event_id?:InputMaybe<Scalars['bigint']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?:InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "audit.logged_actions" */
export type Audit_Logged_Actions_Insert_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?:InputMaybe<Scalars['String']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?:InputMaybe<Scalars['timestamptz']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?:InputMaybe<Scalars['timestamptz']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?:InputMaybe<Scalars['timestamptz']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?:InputMaybe<Scalars['String']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?:InputMaybe<Scalars['jsonb']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?:InputMaybe<Scalars['inet']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?:InputMaybe<Scalars['Int']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?:InputMaybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id?:InputMaybe<Scalars['bigint']>;
  full_qualified_table_name?:InputMaybe<Scalars['String']>;
  hasura_user?:InputMaybe<Scalars['jsonb']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?:InputMaybe<Scalars['oid']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?:InputMaybe<Scalars['jsonb']>;
  /** Database schema audited table for this event is in */
  schema_name?:InputMaybe<Scalars['String']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?:InputMaybe<Scalars['String']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only?:InputMaybe<Scalars['Boolean']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?:InputMaybe<Scalars['String']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?:InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Audit_Logged_Actions_Max_Fields = {
  __typename?:'audit_logged_actions_max_fields';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?:Maybe<Scalars['String']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?:Maybe<Scalars['timestamptz']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?:Maybe<Scalars['timestamptz']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?:Maybe<Scalars['timestamptz']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?:Maybe<Scalars['String']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?:Maybe<Scalars['Int']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?:Maybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id?:Maybe<Scalars['bigint']>;
  full_qualified_table_name?:Maybe<Scalars['String']>;
  /** Database schema audited table for this event is in */
  schema_name?:Maybe<Scalars['String']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?:Maybe<Scalars['String']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?:Maybe<Scalars['String']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?:Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Audit_Logged_Actions_Min_Fields = {
  __typename?:'audit_logged_actions_min_fields';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?:Maybe<Scalars['String']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?:Maybe<Scalars['timestamptz']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?:Maybe<Scalars['timestamptz']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?:Maybe<Scalars['timestamptz']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?:Maybe<Scalars['String']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?:Maybe<Scalars['Int']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?:Maybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id?:Maybe<Scalars['bigint']>;
  full_qualified_table_name?:Maybe<Scalars['String']>;
  /** Database schema audited table for this event is in */
  schema_name?:Maybe<Scalars['String']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?:Maybe<Scalars['String']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?:Maybe<Scalars['String']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?:Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "audit.logged_actions" */
export type Audit_Logged_Actions_Mutation_Response = {
  __typename?:'audit_logged_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Audit_Logged_Actions>;
};

/** on_conflict condition type for table "audit.logged_actions" */
export type Audit_Logged_Actions_On_Conflict = {
  constraint:Audit_Logged_Actions_Constraint;
  update_columns?:Array<Audit_Logged_Actions_Update_Column>;
  where?:InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "audit.logged_actions". */
export type Audit_Logged_Actions_Order_By = {
  action?:InputMaybe<Order_By>;
  action_tstamp_clk?:InputMaybe<Order_By>;
  action_tstamp_stm?:InputMaybe<Order_By>;
  action_tstamp_tx?:InputMaybe<Order_By>;
  application_name?:InputMaybe<Order_By>;
  changed_fields?:InputMaybe<Order_By>;
  client_addr?:InputMaybe<Order_By>;
  client_port?:InputMaybe<Order_By>;
  client_query?:InputMaybe<Order_By>;
  event_id?:InputMaybe<Order_By>;
  full_qualified_table_name?:InputMaybe<Order_By>;
  hasura_user?:InputMaybe<Order_By>;
  relid?:InputMaybe<Order_By>;
  row_data?:InputMaybe<Order_By>;
  schema_name?:InputMaybe<Order_By>;
  session_user_name?:InputMaybe<Order_By>;
  statement_only?:InputMaybe<Order_By>;
  table_name?:InputMaybe<Order_By>;
  transaction_id?:InputMaybe<Order_By>;
  user_aggregate?:InputMaybe<Common_User_Aggregate_Order_By>;
};

/** primary key columns input for table: audit.logged_actions */
export type Audit_Logged_Actions_Pk_Columns_Input = {
  /** Unique identifier for each auditable event */
  event_id:Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logged_Actions_Prepend_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?:InputMaybe<Scalars['jsonb']>;
  hasura_user?:InputMaybe<Scalars['jsonb']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?:InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Select_Column 
{
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  FullQualifiedTableName = 'full_qualified_table_name',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id'
}

/** input type for updating data in table "audit.logged_actions" */
export type Audit_Logged_Actions_Set_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?:InputMaybe<Scalars['String']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?:InputMaybe<Scalars['timestamptz']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?:InputMaybe<Scalars['timestamptz']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?:InputMaybe<Scalars['timestamptz']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?:InputMaybe<Scalars['String']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?:InputMaybe<Scalars['jsonb']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?:InputMaybe<Scalars['inet']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?:InputMaybe<Scalars['Int']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?:InputMaybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id?:InputMaybe<Scalars['bigint']>;
  full_qualified_table_name?:InputMaybe<Scalars['String']>;
  hasura_user?:InputMaybe<Scalars['jsonb']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?:InputMaybe<Scalars['oid']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?:InputMaybe<Scalars['jsonb']>;
  /** Database schema audited table for this event is in */
  schema_name?:InputMaybe<Scalars['String']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?:InputMaybe<Scalars['String']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only?:InputMaybe<Scalars['Boolean']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?:InputMaybe<Scalars['String']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?:InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Audit_Logged_Actions_Stddev_Fields = {
  __typename?:'audit_logged_actions_stddev_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?:Maybe<Scalars['Float']>;
  /** Unique identifier for each auditable event */
  event_id?:Maybe<Scalars['Float']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Audit_Logged_Actions_Stddev_Pop_Fields = {
  __typename?:'audit_logged_actions_stddev_pop_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?:Maybe<Scalars['Float']>;
  /** Unique identifier for each auditable event */
  event_id?:Maybe<Scalars['Float']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Audit_Logged_Actions_Stddev_Samp_Fields = {
  __typename?:'audit_logged_actions_stddev_samp_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?:Maybe<Scalars['Float']>;
  /** Unique identifier for each auditable event */
  event_id?:Maybe<Scalars['Float']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?:Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "audit_logged_actions" */
export type Audit_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Audit_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Logged_Actions_Stream_Cursor_Value_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?:InputMaybe<Scalars['String']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?:InputMaybe<Scalars['timestamptz']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?:InputMaybe<Scalars['timestamptz']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?:InputMaybe<Scalars['timestamptz']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?:InputMaybe<Scalars['String']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?:InputMaybe<Scalars['jsonb']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?:InputMaybe<Scalars['inet']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?:InputMaybe<Scalars['Int']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?:InputMaybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id?:InputMaybe<Scalars['bigint']>;
  full_qualified_table_name?:InputMaybe<Scalars['String']>;
  hasura_user?:InputMaybe<Scalars['jsonb']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?:InputMaybe<Scalars['oid']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?:InputMaybe<Scalars['jsonb']>;
  /** Database schema audited table for this event is in */
  schema_name?:InputMaybe<Scalars['String']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?:InputMaybe<Scalars['String']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only?:InputMaybe<Scalars['Boolean']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?:InputMaybe<Scalars['String']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?:InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Audit_Logged_Actions_Sum_Fields = {
  __typename?:'audit_logged_actions_sum_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?:Maybe<Scalars['Int']>;
  /** Unique identifier for each auditable event */
  event_id?:Maybe<Scalars['bigint']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?:Maybe<Scalars['bigint']>;
};

/** update columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Update_Column 
{
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  FullQualifiedTableName = 'full_qualified_table_name',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id'
}

export type Audit_Logged_Actions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?:InputMaybe<Audit_Logged_Actions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?:InputMaybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?:InputMaybe<Audit_Logged_Actions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?:InputMaybe<Audit_Logged_Actions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Audit_Logged_Actions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?:InputMaybe<Audit_Logged_Actions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Audit_Logged_Actions_Set_Input>;
  where:Audit_Logged_Actions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Audit_Logged_Actions_Var_Pop_Fields = {
  __typename?:'audit_logged_actions_var_pop_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?:Maybe<Scalars['Float']>;
  /** Unique identifier for each auditable event */
  event_id?:Maybe<Scalars['Float']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?:Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Audit_Logged_Actions_Var_Samp_Fields = {
  __typename?:'audit_logged_actions_var_samp_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?:Maybe<Scalars['Float']>;
  /** Unique identifier for each auditable event */
  event_id?:Maybe<Scalars['Float']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?:Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Audit_Logged_Actions_Variance_Fields = {
  __typename?:'audit_logged_actions_variance_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?:Maybe<Scalars['Float']>;
  /** Unique identifier for each auditable event */
  event_id?:Maybe<Scalars['Float']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?:Maybe<Scalars['Float']>;
};

/**
 *
 * View showing all tables with auditing set up. Ordered by schema, then table.
 *
 */
export type Audit_Tableslist = {
  __typename?:'audit_tableslist';
  auditedtable?:Maybe<Scalars['name']>;
  schema?:Maybe<Scalars['name']>;
};

/** aggregated selection of "audit.tableslist" */
export type Audit_Tableslist_Aggregate = {
  __typename?:'audit_tableslist_aggregate';
  aggregate?:Maybe<Audit_Tableslist_Aggregate_Fields>;
  nodes:Array<Audit_Tableslist>;
};

/** aggregate fields of "audit.tableslist" */
export type Audit_Tableslist_Aggregate_Fields = {
  __typename?:'audit_tableslist_aggregate_fields';
  count:Scalars['Int'];
};


/** aggregate fields of "audit.tableslist" */
export type Audit_Tableslist_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Audit_Tableslist_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "audit.tableslist". All fields are combined with a logical 'AND'. */
export type Audit_Tableslist_Bool_Exp = {
  _and?:InputMaybe<Array<Audit_Tableslist_Bool_Exp>>;
  _not?:InputMaybe<Audit_Tableslist_Bool_Exp>;
  _or?:InputMaybe<Array<Audit_Tableslist_Bool_Exp>>;
  auditedtable?:InputMaybe<Name_Comparison_Exp>;
  schema?:InputMaybe<Name_Comparison_Exp>;
};

/** Ordering options when selecting data from "audit.tableslist". */
export type Audit_Tableslist_Order_By = {
  auditedtable?:InputMaybe<Order_By>;
  schema?:InputMaybe<Order_By>;
};

/** select columns of table "audit.tableslist" */
export enum Audit_Tableslist_Select_Column 
{
  /** column name */
  Auditedtable = 'auditedtable',
  /** column name */
  Schema = 'schema'
}

/** Streaming cursor of the table "audit_tableslist" */
export type Audit_Tableslist_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Audit_Tableslist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Tableslist_Stream_Cursor_Value_Input = {
  auditedtable?:InputMaybe<Scalars['name']>;
  schema?:InputMaybe<Scalars['name']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?:InputMaybe<Scalars['bigint']>;
  _gt?:InputMaybe<Scalars['bigint']>;
  _gte?:InputMaybe<Scalars['bigint']>;
  _in?:InputMaybe<Array<Scalars['bigint']>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _lt?:InputMaybe<Scalars['bigint']>;
  _lte?:InputMaybe<Scalars['bigint']>;
  _neq?:InputMaybe<Scalars['bigint']>;
  _nin?:InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "common.api_key" */
export type Common_Api_Key = {
  __typename?:'common_api_key';
  created_at:Scalars['timestamptz'];
  description:Scalars['String'];
  id:Scalars['Int'];
  key:Scalars['String'];
  owner:Scalars['String'];
  updated_at:Scalars['timestamptz'];
  user_role_id?:Maybe<Scalars['Int']>;
};

/** aggregated selection of "common.api_key" */
export type Common_Api_Key_Aggregate = {
  __typename?:'common_api_key_aggregate';
  aggregate?:Maybe<Common_Api_Key_Aggregate_Fields>;
  nodes:Array<Common_Api_Key>;
};

/** aggregate fields of "common.api_key" */
export type Common_Api_Key_Aggregate_Fields = {
  __typename?:'common_api_key_aggregate_fields';
  avg?:Maybe<Common_Api_Key_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Common_Api_Key_Max_Fields>;
  min?:Maybe<Common_Api_Key_Min_Fields>;
  stddev?:Maybe<Common_Api_Key_Stddev_Fields>;
  stddev_pop?:Maybe<Common_Api_Key_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Common_Api_Key_Stddev_Samp_Fields>;
  sum?:Maybe<Common_Api_Key_Sum_Fields>;
  var_pop?:Maybe<Common_Api_Key_Var_Pop_Fields>;
  var_samp?:Maybe<Common_Api_Key_Var_Samp_Fields>;
  variance?:Maybe<Common_Api_Key_Variance_Fields>;
};


/** aggregate fields of "common.api_key" */
export type Common_Api_Key_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Common_Api_Key_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Common_Api_Key_Avg_Fields = {
  __typename?:'common_api_key_avg_fields';
  id?:Maybe<Scalars['Float']>;
  user_role_id?:Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "common.api_key". All fields are combined with a logical 'AND'. */
export type Common_Api_Key_Bool_Exp = {
  _and?:InputMaybe<Array<Common_Api_Key_Bool_Exp>>;
  _not?:InputMaybe<Common_Api_Key_Bool_Exp>;
  _or?:InputMaybe<Array<Common_Api_Key_Bool_Exp>>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  description?:InputMaybe<String_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  key?:InputMaybe<String_Comparison_Exp>;
  owner?:InputMaybe<String_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  user_role_id?:InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "common.api_key" */
export enum Common_Api_Key_Constraint 
{
  /** unique or primary key constraint on columns "key" */
  ApiKeysKeyKey = 'api_keys_key_key',
  /** unique or primary key constraint on columns "id" */
  ApiKeysPkey = 'api_keys_pkey'
}

/** input type for incrementing numeric columns in table "common.api_key" */
export type Common_Api_Key_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
  user_role_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "common.api_key" */
export type Common_Api_Key_Insert_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  description?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  key?:InputMaybe<Scalars['String']>;
  owner?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user_role_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Common_Api_Key_Max_Fields = {
  __typename?:'common_api_key_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  description?:Maybe<Scalars['String']>;
  id?:Maybe<Scalars['Int']>;
  key?:Maybe<Scalars['String']>;
  owner?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
  user_role_id?:Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Common_Api_Key_Min_Fields = {
  __typename?:'common_api_key_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  description?:Maybe<Scalars['String']>;
  id?:Maybe<Scalars['Int']>;
  key?:Maybe<Scalars['String']>;
  owner?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
  user_role_id?:Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "common.api_key" */
export type Common_Api_Key_Mutation_Response = {
  __typename?:'common_api_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Common_Api_Key>;
};

/** on_conflict condition type for table "common.api_key" */
export type Common_Api_Key_On_Conflict = {
  constraint:Common_Api_Key_Constraint;
  update_columns?:Array<Common_Api_Key_Update_Column>;
  where?:InputMaybe<Common_Api_Key_Bool_Exp>;
};

/** Ordering options when selecting data from "common.api_key". */
export type Common_Api_Key_Order_By = {
  created_at?:InputMaybe<Order_By>;
  description?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  key?:InputMaybe<Order_By>;
  owner?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user_role_id?:InputMaybe<Order_By>;
};

/** primary key columns input for table: common.api_key */
export type Common_Api_Key_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "common.api_key" */
export enum Common_Api_Key_Select_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Owner = 'owner',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserRoleId = 'user_role_id'
}

/** input type for updating data in table "common.api_key" */
export type Common_Api_Key_Set_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  description?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  key?:InputMaybe<Scalars['String']>;
  owner?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user_role_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Common_Api_Key_Stddev_Fields = {
  __typename?:'common_api_key_stddev_fields';
  id?:Maybe<Scalars['Float']>;
  user_role_id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Common_Api_Key_Stddev_Pop_Fields = {
  __typename?:'common_api_key_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
  user_role_id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Common_Api_Key_Stddev_Samp_Fields = {
  __typename?:'common_api_key_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
  user_role_id?:Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "common_api_key" */
export type Common_Api_Key_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Common_Api_Key_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Common_Api_Key_Stream_Cursor_Value_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  description?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  key?:InputMaybe<Scalars['String']>;
  owner?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user_role_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Common_Api_Key_Sum_Fields = {
  __typename?:'common_api_key_sum_fields';
  id?:Maybe<Scalars['Int']>;
  user_role_id?:Maybe<Scalars['Int']>;
};

/** update columns of table "common.api_key" */
export enum Common_Api_Key_Update_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Owner = 'owner',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserRoleId = 'user_role_id'
}

export type Common_Api_Key_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Common_Api_Key_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Common_Api_Key_Set_Input>;
  where:Common_Api_Key_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Common_Api_Key_Var_Pop_Fields = {
  __typename?:'common_api_key_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
  user_role_id?:Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Common_Api_Key_Var_Samp_Fields = {
  __typename?:'common_api_key_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
  user_role_id?:Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Common_Api_Key_Variance_Fields = {
  __typename?:'common_api_key_variance_fields';
  id?:Maybe<Scalars['Float']>;
  user_role_id?:Maybe<Scalars['Float']>;
};

/** columns and relationships of "common.attachment" */
export type Common_Attachment = {
  __typename?:'common_attachment';
  /** An object relationship */
  attachment_type:Common_Attachment_Type;
  /** An array relationship */
  candidate_attachments:Array<Recruiting_Candidate_Attachment>;
  /** An aggregate relationship */
  candidate_attachments_aggregate:Recruiting_Candidate_Attachment_Aggregate;
  cdn_path?:Maybe<Scalars['String']>;
  created_at:Scalars['timestamptz'];
  created_by:Scalars['Int'];
  id:Scalars['Int'];
  mime_type?:Maybe<Scalars['String']>;
  name:Scalars['String'];
  size:Scalars['Int'];
  type:Common_Attachment_Type_Enum;
  updated_at:Scalars['timestamptz'];
  /** An object relationship */
  user:Common_User;
};


/** columns and relationships of "common.attachment" */
export type Common_AttachmentCandidate_AttachmentsArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Attachment_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Attachment_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Attachment_Bool_Exp>;
};


/** columns and relationships of "common.attachment" */
export type Common_AttachmentCandidate_Attachments_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Attachment_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Attachment_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Attachment_Bool_Exp>;
};

/** aggregated selection of "common.attachment" */
export type Common_Attachment_Aggregate = {
  __typename?:'common_attachment_aggregate';
  aggregate?:Maybe<Common_Attachment_Aggregate_Fields>;
  nodes:Array<Common_Attachment>;
};

export type Common_Attachment_Aggregate_Bool_Exp = {
  count?:InputMaybe<Common_Attachment_Aggregate_Bool_Exp_Count>;
};

export type Common_Attachment_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Common_Attachment_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Common_Attachment_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "common.attachment" */
export type Common_Attachment_Aggregate_Fields = {
  __typename?:'common_attachment_aggregate_fields';
  avg?:Maybe<Common_Attachment_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Common_Attachment_Max_Fields>;
  min?:Maybe<Common_Attachment_Min_Fields>;
  stddev?:Maybe<Common_Attachment_Stddev_Fields>;
  stddev_pop?:Maybe<Common_Attachment_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Common_Attachment_Stddev_Samp_Fields>;
  sum?:Maybe<Common_Attachment_Sum_Fields>;
  var_pop?:Maybe<Common_Attachment_Var_Pop_Fields>;
  var_samp?:Maybe<Common_Attachment_Var_Samp_Fields>;
  variance?:Maybe<Common_Attachment_Variance_Fields>;
};


/** aggregate fields of "common.attachment" */
export type Common_Attachment_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Common_Attachment_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "common.attachment" */
export type Common_Attachment_Aggregate_Order_By = {
  avg?:InputMaybe<Common_Attachment_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Common_Attachment_Max_Order_By>;
  min?:InputMaybe<Common_Attachment_Min_Order_By>;
  stddev?:InputMaybe<Common_Attachment_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Common_Attachment_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Common_Attachment_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Common_Attachment_Sum_Order_By>;
  var_pop?:InputMaybe<Common_Attachment_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Common_Attachment_Var_Samp_Order_By>;
  variance?:InputMaybe<Common_Attachment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "common.attachment" */
export type Common_Attachment_Arr_Rel_Insert_Input = {
  data:Array<Common_Attachment_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Common_Attachment_On_Conflict>;
};

/** aggregate avg on columns */
export type Common_Attachment_Avg_Fields = {
  __typename?:'common_attachment_avg_fields';
  created_by?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  size?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "common.attachment" */
export type Common_Attachment_Avg_Order_By = {
  created_by?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  size?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "common.attachment". All fields are combined with a logical 'AND'. */
export type Common_Attachment_Bool_Exp = {
  _and?:InputMaybe<Array<Common_Attachment_Bool_Exp>>;
  _not?:InputMaybe<Common_Attachment_Bool_Exp>;
  _or?:InputMaybe<Array<Common_Attachment_Bool_Exp>>;
  attachment_type?:InputMaybe<Common_Attachment_Type_Bool_Exp>;
  candidate_attachments?:InputMaybe<Recruiting_Candidate_Attachment_Bool_Exp>;
  candidate_attachments_aggregate?:InputMaybe<Recruiting_Candidate_Attachment_Aggregate_Bool_Exp>;
  cdn_path?:InputMaybe<String_Comparison_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?:InputMaybe<Int_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  mime_type?:InputMaybe<String_Comparison_Exp>;
  name?:InputMaybe<String_Comparison_Exp>;
  size?:InputMaybe<Int_Comparison_Exp>;
  type?:InputMaybe<Common_Attachment_Type_Enum_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  user?:InputMaybe<Common_User_Bool_Exp>;
};

/** unique or primary key constraints on table "common.attachment" */
export enum Common_Attachment_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  AttachmentPkey = 'attachment_pkey'
}

/** input type for incrementing numeric columns in table "common.attachment" */
export type Common_Attachment_Inc_Input = {
  created_by?:InputMaybe<Scalars['Int']>;
  id?:InputMaybe<Scalars['Int']>;
  size?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "common.attachment" */
export type Common_Attachment_Insert_Input = {
  attachment_type?:InputMaybe<Common_Attachment_Type_Obj_Rel_Insert_Input>;
  candidate_attachments?:InputMaybe<Recruiting_Candidate_Attachment_Arr_Rel_Insert_Input>;
  cdn_path?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  created_by?:InputMaybe<Scalars['Int']>;
  id?:InputMaybe<Scalars['Int']>;
  mime_type?:InputMaybe<Scalars['String']>;
  name?:InputMaybe<Scalars['String']>;
  size?:InputMaybe<Scalars['Int']>;
  type?:InputMaybe<Common_Attachment_Type_Enum>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user?:InputMaybe<Common_User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Common_Attachment_Max_Fields = {
  __typename?:'common_attachment_max_fields';
  cdn_path?:Maybe<Scalars['String']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  created_by?:Maybe<Scalars['Int']>;
  id?:Maybe<Scalars['Int']>;
  mime_type?:Maybe<Scalars['String']>;
  name?:Maybe<Scalars['String']>;
  size?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "common.attachment" */
export type Common_Attachment_Max_Order_By = {
  cdn_path?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  created_by?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  mime_type?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  size?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Common_Attachment_Min_Fields = {
  __typename?:'common_attachment_min_fields';
  cdn_path?:Maybe<Scalars['String']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  created_by?:Maybe<Scalars['Int']>;
  id?:Maybe<Scalars['Int']>;
  mime_type?:Maybe<Scalars['String']>;
  name?:Maybe<Scalars['String']>;
  size?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "common.attachment" */
export type Common_Attachment_Min_Order_By = {
  cdn_path?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  created_by?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  mime_type?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  size?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "common.attachment" */
export type Common_Attachment_Mutation_Response = {
  __typename?:'common_attachment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Common_Attachment>;
};

/** input type for inserting object relation for remote table "common.attachment" */
export type Common_Attachment_Obj_Rel_Insert_Input = {
  data:Common_Attachment_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Common_Attachment_On_Conflict>;
};

/** on_conflict condition type for table "common.attachment" */
export type Common_Attachment_On_Conflict = {
  constraint:Common_Attachment_Constraint;
  update_columns?:Array<Common_Attachment_Update_Column>;
  where?:InputMaybe<Common_Attachment_Bool_Exp>;
};

/** Ordering options when selecting data from "common.attachment". */
export type Common_Attachment_Order_By = {
  attachment_type?:InputMaybe<Common_Attachment_Type_Order_By>;
  candidate_attachments_aggregate?:InputMaybe<Recruiting_Candidate_Attachment_Aggregate_Order_By>;
  cdn_path?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  created_by?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  mime_type?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  size?:InputMaybe<Order_By>;
  type?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user?:InputMaybe<Common_User_Order_By>;
};

/** primary key columns input for table: common.attachment */
export type Common_Attachment_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "common.attachment" */
export enum Common_Attachment_Select_Column 
{
  /** column name */
  CdnPath = 'cdn_path',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  Name = 'name',
  /** column name */
  Size = 'size',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "common.attachment" */
export type Common_Attachment_Set_Input = {
  cdn_path?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  created_by?:InputMaybe<Scalars['Int']>;
  id?:InputMaybe<Scalars['Int']>;
  mime_type?:InputMaybe<Scalars['String']>;
  name?:InputMaybe<Scalars['String']>;
  size?:InputMaybe<Scalars['Int']>;
  type?:InputMaybe<Common_Attachment_Type_Enum>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Common_Attachment_Stddev_Fields = {
  __typename?:'common_attachment_stddev_fields';
  created_by?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  size?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "common.attachment" */
export type Common_Attachment_Stddev_Order_By = {
  created_by?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  size?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Common_Attachment_Stddev_Pop_Fields = {
  __typename?:'common_attachment_stddev_pop_fields';
  created_by?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  size?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "common.attachment" */
export type Common_Attachment_Stddev_Pop_Order_By = {
  created_by?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  size?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Common_Attachment_Stddev_Samp_Fields = {
  __typename?:'common_attachment_stddev_samp_fields';
  created_by?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  size?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "common.attachment" */
export type Common_Attachment_Stddev_Samp_Order_By = {
  created_by?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  size?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "common_attachment" */
export type Common_Attachment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Common_Attachment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Common_Attachment_Stream_Cursor_Value_Input = {
  cdn_path?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  created_by?:InputMaybe<Scalars['Int']>;
  id?:InputMaybe<Scalars['Int']>;
  mime_type?:InputMaybe<Scalars['String']>;
  name?:InputMaybe<Scalars['String']>;
  size?:InputMaybe<Scalars['Int']>;
  type?:InputMaybe<Common_Attachment_Type_Enum>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Common_Attachment_Sum_Fields = {
  __typename?:'common_attachment_sum_fields';
  created_by?:Maybe<Scalars['Int']>;
  id?:Maybe<Scalars['Int']>;
  size?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "common.attachment" */
export type Common_Attachment_Sum_Order_By = {
  created_by?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  size?:InputMaybe<Order_By>;
};

/** columns and relationships of "common.attachment_type" */
export type Common_Attachment_Type = {
  __typename?:'common_attachment_type';
  type:Scalars['String'];
};

/** aggregated selection of "common.attachment_type" */
export type Common_Attachment_Type_Aggregate = {
  __typename?:'common_attachment_type_aggregate';
  aggregate?:Maybe<Common_Attachment_Type_Aggregate_Fields>;
  nodes:Array<Common_Attachment_Type>;
};

/** aggregate fields of "common.attachment_type" */
export type Common_Attachment_Type_Aggregate_Fields = {
  __typename?:'common_attachment_type_aggregate_fields';
  count:Scalars['Int'];
  max?:Maybe<Common_Attachment_Type_Max_Fields>;
  min?:Maybe<Common_Attachment_Type_Min_Fields>;
};


/** aggregate fields of "common.attachment_type" */
export type Common_Attachment_Type_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Common_Attachment_Type_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "common.attachment_type". All fields are combined with a logical 'AND'. */
export type Common_Attachment_Type_Bool_Exp = {
  _and?:InputMaybe<Array<Common_Attachment_Type_Bool_Exp>>;
  _not?:InputMaybe<Common_Attachment_Type_Bool_Exp>;
  _or?:InputMaybe<Array<Common_Attachment_Type_Bool_Exp>>;
  type?:InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "common.attachment_type" */
export enum Common_Attachment_Type_Constraint 
{
  /** unique or primary key constraint on columns "type" */
  AttachmentTypePkey = 'attachment_type_pkey'
}

export enum Common_Attachment_Type_Enum 
{
  Contract = 'contract',
  CoverLetter = 'cover_letter',
  Offer = 'offer',
  Other = 'other',
  Resume = 'resume'
}

/** Boolean expression to compare columns of type "common_attachment_type_enum". All fields are combined with logical 'AND'. */
export type Common_Attachment_Type_Enum_Comparison_Exp = {
  _eq?:InputMaybe<Common_Attachment_Type_Enum>;
  _in?:InputMaybe<Array<Common_Attachment_Type_Enum>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _neq?:InputMaybe<Common_Attachment_Type_Enum>;
  _nin?:InputMaybe<Array<Common_Attachment_Type_Enum>>;
};

/** input type for inserting data into table "common.attachment_type" */
export type Common_Attachment_Type_Insert_Input = {
  type?:InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Common_Attachment_Type_Max_Fields = {
  __typename?:'common_attachment_type_max_fields';
  type?:Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Common_Attachment_Type_Min_Fields = {
  __typename?:'common_attachment_type_min_fields';
  type?:Maybe<Scalars['String']>;
};

/** response of any mutation on the table "common.attachment_type" */
export type Common_Attachment_Type_Mutation_Response = {
  __typename?:'common_attachment_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Common_Attachment_Type>;
};

/** input type for inserting object relation for remote table "common.attachment_type" */
export type Common_Attachment_Type_Obj_Rel_Insert_Input = {
  data:Common_Attachment_Type_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Common_Attachment_Type_On_Conflict>;
};

/** on_conflict condition type for table "common.attachment_type" */
export type Common_Attachment_Type_On_Conflict = {
  constraint:Common_Attachment_Type_Constraint;
  update_columns?:Array<Common_Attachment_Type_Update_Column>;
  where?:InputMaybe<Common_Attachment_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "common.attachment_type". */
export type Common_Attachment_Type_Order_By = {
  type?:InputMaybe<Order_By>;
};

/** primary key columns input for table: common.attachment_type */
export type Common_Attachment_Type_Pk_Columns_Input = {
  type:Scalars['String'];
};

/** select columns of table "common.attachment_type" */
export enum Common_Attachment_Type_Select_Column 
{
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "common.attachment_type" */
export type Common_Attachment_Type_Set_Input = {
  type?:InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "common_attachment_type" */
export type Common_Attachment_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Common_Attachment_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Common_Attachment_Type_Stream_Cursor_Value_Input = {
  type?:InputMaybe<Scalars['String']>;
};

/** update columns of table "common.attachment_type" */
export enum Common_Attachment_Type_Update_Column 
{
  /** column name */
  Type = 'type'
}

export type Common_Attachment_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Common_Attachment_Type_Set_Input>;
  where:Common_Attachment_Type_Bool_Exp;
};

/** update columns of table "common.attachment" */
export enum Common_Attachment_Update_Column 
{
  /** column name */
  CdnPath = 'cdn_path',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  Name = 'name',
  /** column name */
  Size = 'size',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Common_Attachment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Common_Attachment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Common_Attachment_Set_Input>;
  where:Common_Attachment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Common_Attachment_Var_Pop_Fields = {
  __typename?:'common_attachment_var_pop_fields';
  created_by?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  size?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "common.attachment" */
export type Common_Attachment_Var_Pop_Order_By = {
  created_by?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  size?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Common_Attachment_Var_Samp_Fields = {
  __typename?:'common_attachment_var_samp_fields';
  created_by?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  size?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "common.attachment" */
export type Common_Attachment_Var_Samp_Order_By = {
  created_by?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  size?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Common_Attachment_Variance_Fields = {
  __typename?:'common_attachment_variance_fields';
  created_by?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  size?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "common.attachment" */
export type Common_Attachment_Variance_Order_By = {
  created_by?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  size?:InputMaybe<Order_By>;
};

/** columns and relationships of "common.city" */
export type Common_City = {
  __typename?:'common_city';
  code?:Maybe<Scalars['String']>;
  country_id:Scalars['Int'];
  created_at:Scalars['timestamptz'];
  id:Scalars['Int'];
  name:Scalars['String'];
  name_i18n?:Maybe<Scalars['String']>;
  updated_at:Scalars['timestamptz'];
};

/** aggregated selection of "common.city" */
export type Common_City_Aggregate = {
  __typename?:'common_city_aggregate';
  aggregate?:Maybe<Common_City_Aggregate_Fields>;
  nodes:Array<Common_City>;
};

/** aggregate fields of "common.city" */
export type Common_City_Aggregate_Fields = {
  __typename?:'common_city_aggregate_fields';
  avg?:Maybe<Common_City_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Common_City_Max_Fields>;
  min?:Maybe<Common_City_Min_Fields>;
  stddev?:Maybe<Common_City_Stddev_Fields>;
  stddev_pop?:Maybe<Common_City_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Common_City_Stddev_Samp_Fields>;
  sum?:Maybe<Common_City_Sum_Fields>;
  var_pop?:Maybe<Common_City_Var_Pop_Fields>;
  var_samp?:Maybe<Common_City_Var_Samp_Fields>;
  variance?:Maybe<Common_City_Variance_Fields>;
};


/** aggregate fields of "common.city" */
export type Common_City_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Common_City_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Common_City_Avg_Fields = {
  __typename?:'common_city_avg_fields';
  country_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "common.city". All fields are combined with a logical 'AND'. */
export type Common_City_Bool_Exp = {
  _and?:InputMaybe<Array<Common_City_Bool_Exp>>;
  _not?:InputMaybe<Common_City_Bool_Exp>;
  _or?:InputMaybe<Array<Common_City_Bool_Exp>>;
  code?:InputMaybe<String_Comparison_Exp>;
  country_id?:InputMaybe<Int_Comparison_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  name?:InputMaybe<String_Comparison_Exp>;
  name_i18n?:InputMaybe<String_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "common.city" */
export enum Common_City_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  CityPkey = 'city_pkey'
}

/** input type for incrementing numeric columns in table "common.city" */
export type Common_City_Inc_Input = {
  country_id?:InputMaybe<Scalars['Int']>;
  id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "common.city" */
export type Common_City_Insert_Input = {
  code?:InputMaybe<Scalars['String']>;
  country_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  name_i18n?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Common_City_Max_Fields = {
  __typename?:'common_city_max_fields';
  code?:Maybe<Scalars['String']>;
  country_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  name_i18n?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Common_City_Min_Fields = {
  __typename?:'common_city_min_fields';
  code?:Maybe<Scalars['String']>;
  country_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  name_i18n?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "common.city" */
export type Common_City_Mutation_Response = {
  __typename?:'common_city_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Common_City>;
};

/** input type for inserting object relation for remote table "common.city" */
export type Common_City_Obj_Rel_Insert_Input = {
  data:Common_City_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Common_City_On_Conflict>;
};

/** on_conflict condition type for table "common.city" */
export type Common_City_On_Conflict = {
  constraint:Common_City_Constraint;
  update_columns?:Array<Common_City_Update_Column>;
  where?:InputMaybe<Common_City_Bool_Exp>;
};

/** Ordering options when selecting data from "common.city". */
export type Common_City_Order_By = {
  code?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  name_i18n?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: common.city */
export type Common_City_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "common.city" */
export enum Common_City_Select_Column 
{
  /** column name */
  Code = 'code',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameI18n = 'name_i18n',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "common.city" */
export type Common_City_Set_Input = {
  code?:InputMaybe<Scalars['String']>;
  country_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  name_i18n?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Common_City_Stddev_Fields = {
  __typename?:'common_city_stddev_fields';
  country_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Common_City_Stddev_Pop_Fields = {
  __typename?:'common_city_stddev_pop_fields';
  country_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Common_City_Stddev_Samp_Fields = {
  __typename?:'common_city_stddev_samp_fields';
  country_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "common_city" */
export type Common_City_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Common_City_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Common_City_Stream_Cursor_Value_Input = {
  code?:InputMaybe<Scalars['String']>;
  country_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  name_i18n?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Common_City_Sum_Fields = {
  __typename?:'common_city_sum_fields';
  country_id?:Maybe<Scalars['Int']>;
  id?:Maybe<Scalars['Int']>;
};

/** update columns of table "common.city" */
export enum Common_City_Update_Column 
{
  /** column name */
  Code = 'code',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameI18n = 'name_i18n',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Common_City_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Common_City_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Common_City_Set_Input>;
  where:Common_City_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Common_City_Var_Pop_Fields = {
  __typename?:'common_city_var_pop_fields';
  country_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Common_City_Var_Samp_Fields = {
  __typename?:'common_city_var_samp_fields';
  country_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Common_City_Variance_Fields = {
  __typename?:'common_city_variance_fields';
  country_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
};

/** columns and relationships of "common.country" */
export type Common_Country = {
  __typename?:'common_country';
  code?:Maybe<Scalars['String']>;
  created_at:Scalars['timestamptz'];
  id:Scalars['Int'];
  name:Scalars['String'];
  name_i18n?:Maybe<Scalars['String']>;
  updated_at:Scalars['timestamptz'];
};

/** aggregated selection of "common.country" */
export type Common_Country_Aggregate = {
  __typename?:'common_country_aggregate';
  aggregate?:Maybe<Common_Country_Aggregate_Fields>;
  nodes:Array<Common_Country>;
};

/** aggregate fields of "common.country" */
export type Common_Country_Aggregate_Fields = {
  __typename?:'common_country_aggregate_fields';
  avg?:Maybe<Common_Country_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Common_Country_Max_Fields>;
  min?:Maybe<Common_Country_Min_Fields>;
  stddev?:Maybe<Common_Country_Stddev_Fields>;
  stddev_pop?:Maybe<Common_Country_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Common_Country_Stddev_Samp_Fields>;
  sum?:Maybe<Common_Country_Sum_Fields>;
  var_pop?:Maybe<Common_Country_Var_Pop_Fields>;
  var_samp?:Maybe<Common_Country_Var_Samp_Fields>;
  variance?:Maybe<Common_Country_Variance_Fields>;
};


/** aggregate fields of "common.country" */
export type Common_Country_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Common_Country_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Common_Country_Avg_Fields = {
  __typename?:'common_country_avg_fields';
  id?:Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "common.country". All fields are combined with a logical 'AND'. */
export type Common_Country_Bool_Exp = {
  _and?:InputMaybe<Array<Common_Country_Bool_Exp>>;
  _not?:InputMaybe<Common_Country_Bool_Exp>;
  _or?:InputMaybe<Array<Common_Country_Bool_Exp>>;
  code?:InputMaybe<String_Comparison_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  name?:InputMaybe<String_Comparison_Exp>;
  name_i18n?:InputMaybe<String_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "common.country" */
export enum Common_Country_Constraint 
{
  /** unique or primary key constraint on columns "code" */
  CountryCodeKey = 'country_code_key',
  /** unique or primary key constraint on columns "name" */
  CountryNameKey = 'country_name_key',
  /** unique or primary key constraint on columns "id" */
  CountryPkey = 'country_pkey'
}

/** input type for incrementing numeric columns in table "common.country" */
export type Common_Country_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "common.country" */
export type Common_Country_Insert_Input = {
  code?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  name_i18n?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Common_Country_Max_Fields = {
  __typename?:'common_country_max_fields';
  code?:Maybe<Scalars['String']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  name_i18n?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Common_Country_Min_Fields = {
  __typename?:'common_country_min_fields';
  code?:Maybe<Scalars['String']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  name_i18n?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "common.country" */
export type Common_Country_Mutation_Response = {
  __typename?:'common_country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Common_Country>;
};

/** input type for inserting object relation for remote table "common.country" */
export type Common_Country_Obj_Rel_Insert_Input = {
  data:Common_Country_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Common_Country_On_Conflict>;
};

/** on_conflict condition type for table "common.country" */
export type Common_Country_On_Conflict = {
  constraint:Common_Country_Constraint;
  update_columns?:Array<Common_Country_Update_Column>;
  where?:InputMaybe<Common_Country_Bool_Exp>;
};

/** Ordering options when selecting data from "common.country". */
export type Common_Country_Order_By = {
  code?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  name_i18n?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: common.country */
export type Common_Country_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "common.country" */
export enum Common_Country_Select_Column 
{
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameI18n = 'name_i18n',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "common.country" */
export type Common_Country_Set_Input = {
  code?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  name_i18n?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Common_Country_Stddev_Fields = {
  __typename?:'common_country_stddev_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Common_Country_Stddev_Pop_Fields = {
  __typename?:'common_country_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Common_Country_Stddev_Samp_Fields = {
  __typename?:'common_country_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "common_country" */
export type Common_Country_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Common_Country_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Common_Country_Stream_Cursor_Value_Input = {
  code?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  name_i18n?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Common_Country_Sum_Fields = {
  __typename?:'common_country_sum_fields';
  id?:Maybe<Scalars['Int']>;
};

/** update columns of table "common.country" */
export enum Common_Country_Update_Column 
{
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameI18n = 'name_i18n',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Common_Country_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Common_Country_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Common_Country_Set_Input>;
  where:Common_Country_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Common_Country_Var_Pop_Fields = {
  __typename?:'common_country_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Common_Country_Var_Samp_Fields = {
  __typename?:'common_country_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Common_Country_Variance_Fields = {
  __typename?:'common_country_variance_fields';
  id?:Maybe<Scalars['Float']>;
};

/** columns and relationships of "common.customer" */
export type Common_Customer = {
  __typename?:'common_customer';
  alias:Scalars['String'];
  created_at:Scalars['timestamptz'];
  /** An object relationship */
  customer_status:Common_Customer_Status;
  /** An array relationship */
  customer_teams:Array<Staffing_Customer_Team>;
  /** An aggregate relationship */
  customer_teams_aggregate:Staffing_Customer_Team_Aggregate;
  id:Scalars['Int'];
  is_internal:Scalars['Boolean'];
  name:Scalars['String'];
  notes?:Maybe<Scalars['String']>;
  status:Common_Customer_Status_Enum;
  ui_order?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "common.customer" */
export type Common_CustomerCustomer_TeamsArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Bool_Exp>;
};


/** columns and relationships of "common.customer" */
export type Common_CustomerCustomer_Teams_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Bool_Exp>;
};

/** aggregated selection of "common.customer" */
export type Common_Customer_Aggregate = {
  __typename?:'common_customer_aggregate';
  aggregate?:Maybe<Common_Customer_Aggregate_Fields>;
  nodes:Array<Common_Customer>;
};

/** aggregate fields of "common.customer" */
export type Common_Customer_Aggregate_Fields = {
  __typename?:'common_customer_aggregate_fields';
  avg?:Maybe<Common_Customer_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Common_Customer_Max_Fields>;
  min?:Maybe<Common_Customer_Min_Fields>;
  stddev?:Maybe<Common_Customer_Stddev_Fields>;
  stddev_pop?:Maybe<Common_Customer_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Common_Customer_Stddev_Samp_Fields>;
  sum?:Maybe<Common_Customer_Sum_Fields>;
  var_pop?:Maybe<Common_Customer_Var_Pop_Fields>;
  var_samp?:Maybe<Common_Customer_Var_Samp_Fields>;
  variance?:Maybe<Common_Customer_Variance_Fields>;
};


/** aggregate fields of "common.customer" */
export type Common_Customer_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Common_Customer_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Common_Customer_Avg_Fields = {
  __typename?:'common_customer_avg_fields';
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "common.customer". All fields are combined with a logical 'AND'. */
export type Common_Customer_Bool_Exp = {
  _and?:InputMaybe<Array<Common_Customer_Bool_Exp>>;
  _not?:InputMaybe<Common_Customer_Bool_Exp>;
  _or?:InputMaybe<Array<Common_Customer_Bool_Exp>>;
  alias?:InputMaybe<String_Comparison_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  customer_status?:InputMaybe<Common_Customer_Status_Bool_Exp>;
  customer_teams?:InputMaybe<Staffing_Customer_Team_Bool_Exp>;
  customer_teams_aggregate?:InputMaybe<Staffing_Customer_Team_Aggregate_Bool_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  is_internal?:InputMaybe<Boolean_Comparison_Exp>;
  name?:InputMaybe<String_Comparison_Exp>;
  notes?:InputMaybe<String_Comparison_Exp>;
  status?:InputMaybe<Common_Customer_Status_Enum_Comparison_Exp>;
  ui_order?:InputMaybe<Int_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "common.customer" */
export enum Common_Customer_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  CustomerIdKey = 'customer_id_key',
  /** unique or primary key constraint on columns "id" */
  CustomerPkey = 'customer_pkey'
}

/** input type for incrementing numeric columns in table "common.customer" */
export type Common_Customer_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
  ui_order?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "common.customer" */
export type Common_Customer_Insert_Input = {
  alias?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  customer_status?:InputMaybe<Common_Customer_Status_Obj_Rel_Insert_Input>;
  customer_teams?:InputMaybe<Staffing_Customer_Team_Arr_Rel_Insert_Input>;
  id?:InputMaybe<Scalars['Int']>;
  is_internal?:InputMaybe<Scalars['Boolean']>;
  name?:InputMaybe<Scalars['String']>;
  notes?:InputMaybe<Scalars['String']>;
  status?:InputMaybe<Common_Customer_Status_Enum>;
  ui_order?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Common_Customer_Max_Fields = {
  __typename?:'common_customer_max_fields';
  alias?:Maybe<Scalars['String']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  notes?:Maybe<Scalars['String']>;
  ui_order?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Common_Customer_Min_Fields = {
  __typename?:'common_customer_min_fields';
  alias?:Maybe<Scalars['String']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  notes?:Maybe<Scalars['String']>;
  ui_order?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "common.customer" */
export type Common_Customer_Mutation_Response = {
  __typename?:'common_customer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Common_Customer>;
};

/** input type for inserting object relation for remote table "common.customer" */
export type Common_Customer_Obj_Rel_Insert_Input = {
  data:Common_Customer_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Common_Customer_On_Conflict>;
};

/** on_conflict condition type for table "common.customer" */
export type Common_Customer_On_Conflict = {
  constraint:Common_Customer_Constraint;
  update_columns?:Array<Common_Customer_Update_Column>;
  where?:InputMaybe<Common_Customer_Bool_Exp>;
};

/** Ordering options when selecting data from "common.customer". */
export type Common_Customer_Order_By = {
  alias?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  customer_status?:InputMaybe<Common_Customer_Status_Order_By>;
  customer_teams_aggregate?:InputMaybe<Staffing_Customer_Team_Aggregate_Order_By>;
  id?:InputMaybe<Order_By>;
  is_internal?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  notes?:InputMaybe<Order_By>;
  status?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: common.customer */
export type Common_Customer_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "common.customer" */
export enum Common_Customer_Select_Column 
{
  /** column name */
  Alias = 'alias',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsInternal = 'is_internal',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  Status = 'status',
  /** column name */
  UiOrder = 'ui_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "common.customer" */
export type Common_Customer_Set_Input = {
  alias?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  is_internal?:InputMaybe<Scalars['Boolean']>;
  name?:InputMaybe<Scalars['String']>;
  notes?:InputMaybe<Scalars['String']>;
  status?:InputMaybe<Common_Customer_Status_Enum>;
  ui_order?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "common.customer_status" */
export type Common_Customer_Status = {
  __typename?:'common_customer_status';
  status:Scalars['String'];
};

/** aggregated selection of "common.customer_status" */
export type Common_Customer_Status_Aggregate = {
  __typename?:'common_customer_status_aggregate';
  aggregate?:Maybe<Common_Customer_Status_Aggregate_Fields>;
  nodes:Array<Common_Customer_Status>;
};

/** aggregate fields of "common.customer_status" */
export type Common_Customer_Status_Aggregate_Fields = {
  __typename?:'common_customer_status_aggregate_fields';
  count:Scalars['Int'];
  max?:Maybe<Common_Customer_Status_Max_Fields>;
  min?:Maybe<Common_Customer_Status_Min_Fields>;
};


/** aggregate fields of "common.customer_status" */
export type Common_Customer_Status_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Common_Customer_Status_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "common.customer_status". All fields are combined with a logical 'AND'. */
export type Common_Customer_Status_Bool_Exp = {
  _and?:InputMaybe<Array<Common_Customer_Status_Bool_Exp>>;
  _not?:InputMaybe<Common_Customer_Status_Bool_Exp>;
  _or?:InputMaybe<Array<Common_Customer_Status_Bool_Exp>>;
  status?:InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "common.customer_status" */
export enum Common_Customer_Status_Constraint 
{
  /** unique or primary key constraint on columns "status" */
  CustomerStatusPkey = 'customer_status_pkey'
}

export enum Common_Customer_Status_Enum 
{
  Archived = 'archived',
  Current = 'current',
  Prospective = 'prospective'
}

/** Boolean expression to compare columns of type "common_customer_status_enum". All fields are combined with logical 'AND'. */
export type Common_Customer_Status_Enum_Comparison_Exp = {
  _eq?:InputMaybe<Common_Customer_Status_Enum>;
  _in?:InputMaybe<Array<Common_Customer_Status_Enum>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _neq?:InputMaybe<Common_Customer_Status_Enum>;
  _nin?:InputMaybe<Array<Common_Customer_Status_Enum>>;
};

/** input type for inserting data into table "common.customer_status" */
export type Common_Customer_Status_Insert_Input = {
  status?:InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Common_Customer_Status_Max_Fields = {
  __typename?:'common_customer_status_max_fields';
  status?:Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Common_Customer_Status_Min_Fields = {
  __typename?:'common_customer_status_min_fields';
  status?:Maybe<Scalars['String']>;
};

/** response of any mutation on the table "common.customer_status" */
export type Common_Customer_Status_Mutation_Response = {
  __typename?:'common_customer_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Common_Customer_Status>;
};

/** input type for inserting object relation for remote table "common.customer_status" */
export type Common_Customer_Status_Obj_Rel_Insert_Input = {
  data:Common_Customer_Status_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Common_Customer_Status_On_Conflict>;
};

/** on_conflict condition type for table "common.customer_status" */
export type Common_Customer_Status_On_Conflict = {
  constraint:Common_Customer_Status_Constraint;
  update_columns?:Array<Common_Customer_Status_Update_Column>;
  where?:InputMaybe<Common_Customer_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "common.customer_status". */
export type Common_Customer_Status_Order_By = {
  status?:InputMaybe<Order_By>;
};

/** primary key columns input for table: common.customer_status */
export type Common_Customer_Status_Pk_Columns_Input = {
  status:Scalars['String'];
};

/** select columns of table "common.customer_status" */
export enum Common_Customer_Status_Select_Column 
{
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "common.customer_status" */
export type Common_Customer_Status_Set_Input = {
  status?:InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "common_customer_status" */
export type Common_Customer_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Common_Customer_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Common_Customer_Status_Stream_Cursor_Value_Input = {
  status?:InputMaybe<Scalars['String']>;
};

/** update columns of table "common.customer_status" */
export enum Common_Customer_Status_Update_Column 
{
  /** column name */
  Status = 'status'
}

export type Common_Customer_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Common_Customer_Status_Set_Input>;
  where:Common_Customer_Status_Bool_Exp;
};

/** aggregate stddev on columns */
export type Common_Customer_Stddev_Fields = {
  __typename?:'common_customer_stddev_fields';
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Common_Customer_Stddev_Pop_Fields = {
  __typename?:'common_customer_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Common_Customer_Stddev_Samp_Fields = {
  __typename?:'common_customer_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "common_customer" */
export type Common_Customer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Common_Customer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Common_Customer_Stream_Cursor_Value_Input = {
  alias?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  is_internal?:InputMaybe<Scalars['Boolean']>;
  name?:InputMaybe<Scalars['String']>;
  notes?:InputMaybe<Scalars['String']>;
  status?:InputMaybe<Common_Customer_Status_Enum>;
  ui_order?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Common_Customer_Sum_Fields = {
  __typename?:'common_customer_sum_fields';
  id?:Maybe<Scalars['Int']>;
  ui_order?:Maybe<Scalars['Int']>;
};

/** update columns of table "common.customer" */
export enum Common_Customer_Update_Column 
{
  /** column name */
  Alias = 'alias',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsInternal = 'is_internal',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  Status = 'status',
  /** column name */
  UiOrder = 'ui_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Common_Customer_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Common_Customer_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Common_Customer_Set_Input>;
  where:Common_Customer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Common_Customer_Var_Pop_Fields = {
  __typename?:'common_customer_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Common_Customer_Var_Samp_Fields = {
  __typename?:'common_customer_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Common_Customer_Variance_Fields = {
  __typename?:'common_customer_variance_fields';
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** columns and relationships of "common.filter" */
export type Common_Filter = {
  __typename?:'common_filter';
  content:Scalars['json'];
  created_at:Scalars['timestamptz'];
  /** An object relationship */
  filter_type:Common_Filter_Type;
  id:Scalars['Int'];
  name:Scalars['String'];
  type:Common_Filter_Type_Enum;
  updated_at:Scalars['timestamp'];
  /** An object relationship */
  user:Common_User;
  user_id:Scalars['Int'];
};


/** columns and relationships of "common.filter" */
export type Common_FilterContentArgs = {
  path?:InputMaybe<Scalars['String']>;
};

/** aggregated selection of "common.filter" */
export type Common_Filter_Aggregate = {
  __typename?:'common_filter_aggregate';
  aggregate?:Maybe<Common_Filter_Aggregate_Fields>;
  nodes:Array<Common_Filter>;
};

export type Common_Filter_Aggregate_Bool_Exp = {
  count?:InputMaybe<Common_Filter_Aggregate_Bool_Exp_Count>;
};

export type Common_Filter_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Common_Filter_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Common_Filter_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "common.filter" */
export type Common_Filter_Aggregate_Fields = {
  __typename?:'common_filter_aggregate_fields';
  avg?:Maybe<Common_Filter_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Common_Filter_Max_Fields>;
  min?:Maybe<Common_Filter_Min_Fields>;
  stddev?:Maybe<Common_Filter_Stddev_Fields>;
  stddev_pop?:Maybe<Common_Filter_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Common_Filter_Stddev_Samp_Fields>;
  sum?:Maybe<Common_Filter_Sum_Fields>;
  var_pop?:Maybe<Common_Filter_Var_Pop_Fields>;
  var_samp?:Maybe<Common_Filter_Var_Samp_Fields>;
  variance?:Maybe<Common_Filter_Variance_Fields>;
};


/** aggregate fields of "common.filter" */
export type Common_Filter_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Common_Filter_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "common.filter" */
export type Common_Filter_Aggregate_Order_By = {
  avg?:InputMaybe<Common_Filter_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Common_Filter_Max_Order_By>;
  min?:InputMaybe<Common_Filter_Min_Order_By>;
  stddev?:InputMaybe<Common_Filter_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Common_Filter_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Common_Filter_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Common_Filter_Sum_Order_By>;
  var_pop?:InputMaybe<Common_Filter_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Common_Filter_Var_Samp_Order_By>;
  variance?:InputMaybe<Common_Filter_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "common.filter" */
export type Common_Filter_Arr_Rel_Insert_Input = {
  data:Array<Common_Filter_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Common_Filter_On_Conflict>;
};

/** aggregate avg on columns */
export type Common_Filter_Avg_Fields = {
  __typename?:'common_filter_avg_fields';
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "common.filter" */
export type Common_Filter_Avg_Order_By = {
  id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "common.filter". All fields are combined with a logical 'AND'. */
export type Common_Filter_Bool_Exp = {
  _and?:InputMaybe<Array<Common_Filter_Bool_Exp>>;
  _not?:InputMaybe<Common_Filter_Bool_Exp>;
  _or?:InputMaybe<Array<Common_Filter_Bool_Exp>>;
  content?:InputMaybe<Json_Comparison_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  filter_type?:InputMaybe<Common_Filter_Type_Bool_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  name?:InputMaybe<String_Comparison_Exp>;
  type?:InputMaybe<Common_Filter_Type_Enum_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamp_Comparison_Exp>;
  user?:InputMaybe<Common_User_Bool_Exp>;
  user_id?:InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "common.filter" */
export enum Common_Filter_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  FiltersPkey = 'filters_pkey',
  /** unique or primary key constraint on columns "name", "user_id" */
  FiltersUserIdNameKey = 'filters_user_id_name_key'
}

/** input type for incrementing numeric columns in table "common.filter" */
export type Common_Filter_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "common.filter" */
export type Common_Filter_Insert_Input = {
  content?:InputMaybe<Scalars['json']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  filter_type?:InputMaybe<Common_Filter_Type_Obj_Rel_Insert_Input>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  type?:InputMaybe<Common_Filter_Type_Enum>;
  updated_at?:InputMaybe<Scalars['timestamp']>;
  user?:InputMaybe<Common_User_Obj_Rel_Insert_Input>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Common_Filter_Max_Fields = {
  __typename?:'common_filter_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamp']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "common.filter" */
export type Common_Filter_Max_Order_By = {
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Common_Filter_Min_Fields = {
  __typename?:'common_filter_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamp']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "common.filter" */
export type Common_Filter_Min_Order_By = {
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "common.filter" */
export type Common_Filter_Mutation_Response = {
  __typename?:'common_filter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Common_Filter>;
};

/** on_conflict condition type for table "common.filter" */
export type Common_Filter_On_Conflict = {
  constraint:Common_Filter_Constraint;
  update_columns?:Array<Common_Filter_Update_Column>;
  where?:InputMaybe<Common_Filter_Bool_Exp>;
};

/** Ordering options when selecting data from "common.filter". */
export type Common_Filter_Order_By = {
  content?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  filter_type?:InputMaybe<Common_Filter_Type_Order_By>;
  id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  type?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user?:InputMaybe<Common_User_Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** primary key columns input for table: common.filter */
export type Common_Filter_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "common.filter" */
export enum Common_Filter_Select_Column 
{
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "common.filter" */
export type Common_Filter_Set_Input = {
  content?:InputMaybe<Scalars['json']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  type?:InputMaybe<Common_Filter_Type_Enum>;
  updated_at?:InputMaybe<Scalars['timestamp']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Common_Filter_Stddev_Fields = {
  __typename?:'common_filter_stddev_fields';
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "common.filter" */
export type Common_Filter_Stddev_Order_By = {
  id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Common_Filter_Stddev_Pop_Fields = {
  __typename?:'common_filter_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "common.filter" */
export type Common_Filter_Stddev_Pop_Order_By = {
  id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Common_Filter_Stddev_Samp_Fields = {
  __typename?:'common_filter_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "common.filter" */
export type Common_Filter_Stddev_Samp_Order_By = {
  id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "common_filter" */
export type Common_Filter_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Common_Filter_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Common_Filter_Stream_Cursor_Value_Input = {
  content?:InputMaybe<Scalars['json']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  type?:InputMaybe<Common_Filter_Type_Enum>;
  updated_at?:InputMaybe<Scalars['timestamp']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Common_Filter_Sum_Fields = {
  __typename?:'common_filter_sum_fields';
  id?:Maybe<Scalars['Int']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "common.filter" */
export type Common_Filter_Sum_Order_By = {
  id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** columns and relationships of "common.filter_type" */
export type Common_Filter_Type = {
  __typename?:'common_filter_type';
  type:Scalars['String'];
};

/** aggregated selection of "common.filter_type" */
export type Common_Filter_Type_Aggregate = {
  __typename?:'common_filter_type_aggregate';
  aggregate?:Maybe<Common_Filter_Type_Aggregate_Fields>;
  nodes:Array<Common_Filter_Type>;
};

/** aggregate fields of "common.filter_type" */
export type Common_Filter_Type_Aggregate_Fields = {
  __typename?:'common_filter_type_aggregate_fields';
  count:Scalars['Int'];
  max?:Maybe<Common_Filter_Type_Max_Fields>;
  min?:Maybe<Common_Filter_Type_Min_Fields>;
};


/** aggregate fields of "common.filter_type" */
export type Common_Filter_Type_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Common_Filter_Type_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "common.filter_type". All fields are combined with a logical 'AND'. */
export type Common_Filter_Type_Bool_Exp = {
  _and?:InputMaybe<Array<Common_Filter_Type_Bool_Exp>>;
  _not?:InputMaybe<Common_Filter_Type_Bool_Exp>;
  _or?:InputMaybe<Array<Common_Filter_Type_Bool_Exp>>;
  type?:InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "common.filter_type" */
export enum Common_Filter_Type_Constraint 
{
  /** unique or primary key constraint on columns "type" */
  FilterTypePkey = 'filter_type_pkey'
}

export enum Common_Filter_Type_Enum 
{
  Recruiting = 'recruiting',
  Staffing = 'staffing'
}

/** Boolean expression to compare columns of type "common_filter_type_enum". All fields are combined with logical 'AND'. */
export type Common_Filter_Type_Enum_Comparison_Exp = {
  _eq?:InputMaybe<Common_Filter_Type_Enum>;
  _in?:InputMaybe<Array<Common_Filter_Type_Enum>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _neq?:InputMaybe<Common_Filter_Type_Enum>;
  _nin?:InputMaybe<Array<Common_Filter_Type_Enum>>;
};

/** input type for inserting data into table "common.filter_type" */
export type Common_Filter_Type_Insert_Input = {
  type?:InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Common_Filter_Type_Max_Fields = {
  __typename?:'common_filter_type_max_fields';
  type?:Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Common_Filter_Type_Min_Fields = {
  __typename?:'common_filter_type_min_fields';
  type?:Maybe<Scalars['String']>;
};

/** response of any mutation on the table "common.filter_type" */
export type Common_Filter_Type_Mutation_Response = {
  __typename?:'common_filter_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Common_Filter_Type>;
};

/** input type for inserting object relation for remote table "common.filter_type" */
export type Common_Filter_Type_Obj_Rel_Insert_Input = {
  data:Common_Filter_Type_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Common_Filter_Type_On_Conflict>;
};

/** on_conflict condition type for table "common.filter_type" */
export type Common_Filter_Type_On_Conflict = {
  constraint:Common_Filter_Type_Constraint;
  update_columns?:Array<Common_Filter_Type_Update_Column>;
  where?:InputMaybe<Common_Filter_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "common.filter_type". */
export type Common_Filter_Type_Order_By = {
  type?:InputMaybe<Order_By>;
};

/** primary key columns input for table: common.filter_type */
export type Common_Filter_Type_Pk_Columns_Input = {
  type:Scalars['String'];
};

/** select columns of table "common.filter_type" */
export enum Common_Filter_Type_Select_Column 
{
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "common.filter_type" */
export type Common_Filter_Type_Set_Input = {
  type?:InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "common_filter_type" */
export type Common_Filter_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Common_Filter_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Common_Filter_Type_Stream_Cursor_Value_Input = {
  type?:InputMaybe<Scalars['String']>;
};

/** update columns of table "common.filter_type" */
export enum Common_Filter_Type_Update_Column 
{
  /** column name */
  Type = 'type'
}

export type Common_Filter_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Common_Filter_Type_Set_Input>;
  where:Common_Filter_Type_Bool_Exp;
};

/** update columns of table "common.filter" */
export enum Common_Filter_Update_Column 
{
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Common_Filter_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Common_Filter_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Common_Filter_Set_Input>;
  where:Common_Filter_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Common_Filter_Var_Pop_Fields = {
  __typename?:'common_filter_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "common.filter" */
export type Common_Filter_Var_Pop_Order_By = {
  id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Common_Filter_Var_Samp_Fields = {
  __typename?:'common_filter_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "common.filter" */
export type Common_Filter_Var_Samp_Order_By = {
  id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Common_Filter_Variance_Fields = {
  __typename?:'common_filter_variance_fields';
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "common.filter" */
export type Common_Filter_Variance_Order_By = {
  id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** columns and relationships of "common.scheduled_event" */
export type Common_Scheduled_Event = {
  __typename?:'common_scheduled_event';
  attendees_list:Scalars['String'];
  /** An array relationship */
  candidate_scheduled_events:Array<Recruiting_Candidate_Scheduled_Event>;
  /** An aggregate relationship */
  candidate_scheduled_events_aggregate:Recruiting_Candidate_Scheduled_Event_Aggregate;
  conference_link?:Maybe<Scalars['String']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  deleted_at?:Maybe<Scalars['timestamptz']>;
  description:Scalars['String'];
  end_time:Scalars['timestamptz'];
  feedback_form_ref?:Maybe<Scalars['String']>;
  google_event_id?:Maybe<Scalars['String']>;
  id:Scalars['Int'];
  is_done:Scalars['Boolean'];
  is_draft:Scalars['Boolean'];
  is_feedback_done:Scalars['Boolean'];
  is_scheduled:Scalars['Boolean'];
  result?:Maybe<Scalars['String']>;
  start_time:Scalars['timestamptz'];
  summary:Scalars['String'];
  updated_at?:Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user:Common_User;
  user_id:Scalars['Int'];
};


/** columns and relationships of "common.scheduled_event" */
export type Common_Scheduled_EventCandidate_Scheduled_EventsArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Bool_Exp>;
};


/** columns and relationships of "common.scheduled_event" */
export type Common_Scheduled_EventCandidate_Scheduled_Events_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Bool_Exp>;
};

/** aggregated selection of "common.scheduled_event" */
export type Common_Scheduled_Event_Aggregate = {
  __typename?:'common_scheduled_event_aggregate';
  aggregate?:Maybe<Common_Scheduled_Event_Aggregate_Fields>;
  nodes:Array<Common_Scheduled_Event>;
};

/** aggregate fields of "common.scheduled_event" */
export type Common_Scheduled_Event_Aggregate_Fields = {
  __typename?:'common_scheduled_event_aggregate_fields';
  avg?:Maybe<Common_Scheduled_Event_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Common_Scheduled_Event_Max_Fields>;
  min?:Maybe<Common_Scheduled_Event_Min_Fields>;
  stddev?:Maybe<Common_Scheduled_Event_Stddev_Fields>;
  stddev_pop?:Maybe<Common_Scheduled_Event_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Common_Scheduled_Event_Stddev_Samp_Fields>;
  sum?:Maybe<Common_Scheduled_Event_Sum_Fields>;
  var_pop?:Maybe<Common_Scheduled_Event_Var_Pop_Fields>;
  var_samp?:Maybe<Common_Scheduled_Event_Var_Samp_Fields>;
  variance?:Maybe<Common_Scheduled_Event_Variance_Fields>;
};


/** aggregate fields of "common.scheduled_event" */
export type Common_Scheduled_Event_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Common_Scheduled_Event_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Common_Scheduled_Event_Avg_Fields = {
  __typename?:'common_scheduled_event_avg_fields';
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "common.scheduled_event". All fields are combined with a logical 'AND'. */
export type Common_Scheduled_Event_Bool_Exp = {
  _and?:InputMaybe<Array<Common_Scheduled_Event_Bool_Exp>>;
  _not?:InputMaybe<Common_Scheduled_Event_Bool_Exp>;
  _or?:InputMaybe<Array<Common_Scheduled_Event_Bool_Exp>>;
  attendees_list?:InputMaybe<String_Comparison_Exp>;
  candidate_scheduled_events?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Bool_Exp>;
  candidate_scheduled_events_aggregate?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Aggregate_Bool_Exp>;
  conference_link?:InputMaybe<String_Comparison_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  description?:InputMaybe<String_Comparison_Exp>;
  end_time?:InputMaybe<Timestamptz_Comparison_Exp>;
  feedback_form_ref?:InputMaybe<String_Comparison_Exp>;
  google_event_id?:InputMaybe<String_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  is_done?:InputMaybe<Boolean_Comparison_Exp>;
  is_draft?:InputMaybe<Boolean_Comparison_Exp>;
  is_feedback_done?:InputMaybe<Boolean_Comparison_Exp>;
  is_scheduled?:InputMaybe<Boolean_Comparison_Exp>;
  result?:InputMaybe<String_Comparison_Exp>;
  start_time?:InputMaybe<Timestamptz_Comparison_Exp>;
  summary?:InputMaybe<String_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  user?:InputMaybe<Common_User_Bool_Exp>;
  user_id?:InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "common.scheduled_event" */
export enum Common_Scheduled_Event_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  ScheduledCallPkey = 'scheduled_call_pkey'
}

/** input type for incrementing numeric columns in table "common.scheduled_event" */
export type Common_Scheduled_Event_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "common.scheduled_event" */
export type Common_Scheduled_Event_Insert_Input = {
  attendees_list?:InputMaybe<Scalars['String']>;
  candidate_scheduled_events?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Arr_Rel_Insert_Input>;
  conference_link?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  description?:InputMaybe<Scalars['String']>;
  end_time?:InputMaybe<Scalars['timestamptz']>;
  feedback_form_ref?:InputMaybe<Scalars['String']>;
  google_event_id?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  is_done?:InputMaybe<Scalars['Boolean']>;
  is_draft?:InputMaybe<Scalars['Boolean']>;
  is_feedback_done?:InputMaybe<Scalars['Boolean']>;
  is_scheduled?:InputMaybe<Scalars['Boolean']>;
  result?:InputMaybe<Scalars['String']>;
  start_time?:InputMaybe<Scalars['timestamptz']>;
  summary?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user?:InputMaybe<Common_User_Obj_Rel_Insert_Input>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Common_Scheduled_Event_Max_Fields = {
  __typename?:'common_scheduled_event_max_fields';
  attendees_list?:Maybe<Scalars['String']>;
  conference_link?:Maybe<Scalars['String']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  deleted_at?:Maybe<Scalars['timestamptz']>;
  description?:Maybe<Scalars['String']>;
  end_time?:Maybe<Scalars['timestamptz']>;
  feedback_form_ref?:Maybe<Scalars['String']>;
  google_event_id?:Maybe<Scalars['String']>;
  id?:Maybe<Scalars['Int']>;
  result?:Maybe<Scalars['String']>;
  start_time?:Maybe<Scalars['timestamptz']>;
  summary?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Common_Scheduled_Event_Min_Fields = {
  __typename?:'common_scheduled_event_min_fields';
  attendees_list?:Maybe<Scalars['String']>;
  conference_link?:Maybe<Scalars['String']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  deleted_at?:Maybe<Scalars['timestamptz']>;
  description?:Maybe<Scalars['String']>;
  end_time?:Maybe<Scalars['timestamptz']>;
  feedback_form_ref?:Maybe<Scalars['String']>;
  google_event_id?:Maybe<Scalars['String']>;
  id?:Maybe<Scalars['Int']>;
  result?:Maybe<Scalars['String']>;
  start_time?:Maybe<Scalars['timestamptz']>;
  summary?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "common.scheduled_event" */
export type Common_Scheduled_Event_Mutation_Response = {
  __typename?:'common_scheduled_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Common_Scheduled_Event>;
};

/** input type for inserting object relation for remote table "common.scheduled_event" */
export type Common_Scheduled_Event_Obj_Rel_Insert_Input = {
  data:Common_Scheduled_Event_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Common_Scheduled_Event_On_Conflict>;
};

/** on_conflict condition type for table "common.scheduled_event" */
export type Common_Scheduled_Event_On_Conflict = {
  constraint:Common_Scheduled_Event_Constraint;
  update_columns?:Array<Common_Scheduled_Event_Update_Column>;
  where?:InputMaybe<Common_Scheduled_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "common.scheduled_event". */
export type Common_Scheduled_Event_Order_By = {
  attendees_list?:InputMaybe<Order_By>;
  candidate_scheduled_events_aggregate?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Aggregate_Order_By>;
  conference_link?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  deleted_at?:InputMaybe<Order_By>;
  description?:InputMaybe<Order_By>;
  end_time?:InputMaybe<Order_By>;
  feedback_form_ref?:InputMaybe<Order_By>;
  google_event_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  is_done?:InputMaybe<Order_By>;
  is_draft?:InputMaybe<Order_By>;
  is_feedback_done?:InputMaybe<Order_By>;
  is_scheduled?:InputMaybe<Order_By>;
  result?:InputMaybe<Order_By>;
  start_time?:InputMaybe<Order_By>;
  summary?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user?:InputMaybe<Common_User_Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** primary key columns input for table: common.scheduled_event */
export type Common_Scheduled_Event_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "common.scheduled_event" */
export enum Common_Scheduled_Event_Select_Column 
{
  /** column name */
  AttendeesList = 'attendees_list',
  /** column name */
  ConferenceLink = 'conference_link',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  FeedbackFormRef = 'feedback_form_ref',
  /** column name */
  GoogleEventId = 'google_event_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDone = 'is_done',
  /** column name */
  IsDraft = 'is_draft',
  /** column name */
  IsFeedbackDone = 'is_feedback_done',
  /** column name */
  IsScheduled = 'is_scheduled',
  /** column name */
  Result = 'result',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Summary = 'summary',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "common.scheduled_event" */
export type Common_Scheduled_Event_Set_Input = {
  attendees_list?:InputMaybe<Scalars['String']>;
  conference_link?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  description?:InputMaybe<Scalars['String']>;
  end_time?:InputMaybe<Scalars['timestamptz']>;
  feedback_form_ref?:InputMaybe<Scalars['String']>;
  google_event_id?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  is_done?:InputMaybe<Scalars['Boolean']>;
  is_draft?:InputMaybe<Scalars['Boolean']>;
  is_feedback_done?:InputMaybe<Scalars['Boolean']>;
  is_scheduled?:InputMaybe<Scalars['Boolean']>;
  result?:InputMaybe<Scalars['String']>;
  start_time?:InputMaybe<Scalars['timestamptz']>;
  summary?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Common_Scheduled_Event_Stddev_Fields = {
  __typename?:'common_scheduled_event_stddev_fields';
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Common_Scheduled_Event_Stddev_Pop_Fields = {
  __typename?:'common_scheduled_event_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Common_Scheduled_Event_Stddev_Samp_Fields = {
  __typename?:'common_scheduled_event_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "common_scheduled_event" */
export type Common_Scheduled_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Common_Scheduled_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Common_Scheduled_Event_Stream_Cursor_Value_Input = {
  attendees_list?:InputMaybe<Scalars['String']>;
  conference_link?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  description?:InputMaybe<Scalars['String']>;
  end_time?:InputMaybe<Scalars['timestamptz']>;
  feedback_form_ref?:InputMaybe<Scalars['String']>;
  google_event_id?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  is_done?:InputMaybe<Scalars['Boolean']>;
  is_draft?:InputMaybe<Scalars['Boolean']>;
  is_feedback_done?:InputMaybe<Scalars['Boolean']>;
  is_scheduled?:InputMaybe<Scalars['Boolean']>;
  result?:InputMaybe<Scalars['String']>;
  start_time?:InputMaybe<Scalars['timestamptz']>;
  summary?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Common_Scheduled_Event_Sum_Fields = {
  __typename?:'common_scheduled_event_sum_fields';
  id?:Maybe<Scalars['Int']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** update columns of table "common.scheduled_event" */
export enum Common_Scheduled_Event_Update_Column 
{
  /** column name */
  AttendeesList = 'attendees_list',
  /** column name */
  ConferenceLink = 'conference_link',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  FeedbackFormRef = 'feedback_form_ref',
  /** column name */
  GoogleEventId = 'google_event_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsDone = 'is_done',
  /** column name */
  IsDraft = 'is_draft',
  /** column name */
  IsFeedbackDone = 'is_feedback_done',
  /** column name */
  IsScheduled = 'is_scheduled',
  /** column name */
  Result = 'result',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Summary = 'summary',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Common_Scheduled_Event_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Common_Scheduled_Event_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Common_Scheduled_Event_Set_Input>;
  where:Common_Scheduled_Event_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Common_Scheduled_Event_Var_Pop_Fields = {
  __typename?:'common_scheduled_event_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Common_Scheduled_Event_Var_Samp_Fields = {
  __typename?:'common_scheduled_event_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Common_Scheduled_Event_Variance_Fields = {
  __typename?:'common_scheduled_event_variance_fields';
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** columns and relationships of "common.skill_tag" */
export type Common_Skill_Tag = {
  __typename?:'common_skill_tag';
  /** An array relationship */
  candidates:Array<Recruiting_Candidate_Skill_Tag>;
  /** An aggregate relationship */
  candidates_aggregate:Recruiting_Candidate_Skill_Tag_Aggregate;
  created_at:Scalars['timestamptz'];
  id:Scalars['Int'];
  name:Scalars['String'];
  /** An array relationship */
  staff:Array<Staffing_Staff_Skill_Tag>;
  /** An aggregate relationship */
  staff_aggregate:Staffing_Staff_Skill_Tag_Aggregate;
  updated_at?:Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "common.skill_tag" */
export type Common_Skill_TagCandidatesArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Skill_Tag_Bool_Exp>;
};


/** columns and relationships of "common.skill_tag" */
export type Common_Skill_TagCandidates_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Skill_Tag_Bool_Exp>;
};


/** columns and relationships of "common.skill_tag" */
export type Common_Skill_TagStaffArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Skill_Tag_Bool_Exp>;
};


/** columns and relationships of "common.skill_tag" */
export type Common_Skill_TagStaff_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Skill_Tag_Bool_Exp>;
};

/** aggregated selection of "common.skill_tag" */
export type Common_Skill_Tag_Aggregate = {
  __typename?:'common_skill_tag_aggregate';
  aggregate?:Maybe<Common_Skill_Tag_Aggregate_Fields>;
  nodes:Array<Common_Skill_Tag>;
};

/** aggregate fields of "common.skill_tag" */
export type Common_Skill_Tag_Aggregate_Fields = {
  __typename?:'common_skill_tag_aggregate_fields';
  avg?:Maybe<Common_Skill_Tag_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Common_Skill_Tag_Max_Fields>;
  min?:Maybe<Common_Skill_Tag_Min_Fields>;
  stddev?:Maybe<Common_Skill_Tag_Stddev_Fields>;
  stddev_pop?:Maybe<Common_Skill_Tag_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Common_Skill_Tag_Stddev_Samp_Fields>;
  sum?:Maybe<Common_Skill_Tag_Sum_Fields>;
  var_pop?:Maybe<Common_Skill_Tag_Var_Pop_Fields>;
  var_samp?:Maybe<Common_Skill_Tag_Var_Samp_Fields>;
  variance?:Maybe<Common_Skill_Tag_Variance_Fields>;
};


/** aggregate fields of "common.skill_tag" */
export type Common_Skill_Tag_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Common_Skill_Tag_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Common_Skill_Tag_Avg_Fields = {
  __typename?:'common_skill_tag_avg_fields';
  id?:Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "common.skill_tag". All fields are combined with a logical 'AND'. */
export type Common_Skill_Tag_Bool_Exp = {
  _and?:InputMaybe<Array<Common_Skill_Tag_Bool_Exp>>;
  _not?:InputMaybe<Common_Skill_Tag_Bool_Exp>;
  _or?:InputMaybe<Array<Common_Skill_Tag_Bool_Exp>>;
  candidates?:InputMaybe<Recruiting_Candidate_Skill_Tag_Bool_Exp>;
  candidates_aggregate?:InputMaybe<Recruiting_Candidate_Skill_Tag_Aggregate_Bool_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  name?:InputMaybe<String_Comparison_Exp>;
  staff?:InputMaybe<Staffing_Staff_Skill_Tag_Bool_Exp>;
  staff_aggregate?:InputMaybe<Staffing_Staff_Skill_Tag_Aggregate_Bool_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "common.skill_tag" */
export enum Common_Skill_Tag_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  SkillTagsPkey = 'skill_tags_pkey'
}

/** input type for incrementing numeric columns in table "common.skill_tag" */
export type Common_Skill_Tag_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "common.skill_tag" */
export type Common_Skill_Tag_Insert_Input = {
  candidates?:InputMaybe<Recruiting_Candidate_Skill_Tag_Arr_Rel_Insert_Input>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  staff?:InputMaybe<Staffing_Staff_Skill_Tag_Arr_Rel_Insert_Input>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Common_Skill_Tag_Max_Fields = {
  __typename?:'common_skill_tag_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Common_Skill_Tag_Min_Fields = {
  __typename?:'common_skill_tag_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "common.skill_tag" */
export type Common_Skill_Tag_Mutation_Response = {
  __typename?:'common_skill_tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Common_Skill_Tag>;
};

/** input type for inserting object relation for remote table "common.skill_tag" */
export type Common_Skill_Tag_Obj_Rel_Insert_Input = {
  data:Common_Skill_Tag_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Common_Skill_Tag_On_Conflict>;
};

/** on_conflict condition type for table "common.skill_tag" */
export type Common_Skill_Tag_On_Conflict = {
  constraint:Common_Skill_Tag_Constraint;
  update_columns?:Array<Common_Skill_Tag_Update_Column>;
  where?:InputMaybe<Common_Skill_Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "common.skill_tag". */
export type Common_Skill_Tag_Order_By = {
  candidates_aggregate?:InputMaybe<Recruiting_Candidate_Skill_Tag_Aggregate_Order_By>;
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  staff_aggregate?:InputMaybe<Staffing_Staff_Skill_Tag_Aggregate_Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: common.skill_tag */
export type Common_Skill_Tag_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "common.skill_tag" */
export enum Common_Skill_Tag_Select_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "common.skill_tag" */
export type Common_Skill_Tag_Set_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Common_Skill_Tag_Stddev_Fields = {
  __typename?:'common_skill_tag_stddev_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Common_Skill_Tag_Stddev_Pop_Fields = {
  __typename?:'common_skill_tag_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Common_Skill_Tag_Stddev_Samp_Fields = {
  __typename?:'common_skill_tag_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "common_skill_tag" */
export type Common_Skill_Tag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Common_Skill_Tag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Common_Skill_Tag_Stream_Cursor_Value_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Common_Skill_Tag_Sum_Fields = {
  __typename?:'common_skill_tag_sum_fields';
  id?:Maybe<Scalars['Int']>;
};

/** update columns of table "common.skill_tag" */
export enum Common_Skill_Tag_Update_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Common_Skill_Tag_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Common_Skill_Tag_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Common_Skill_Tag_Set_Input>;
  where:Common_Skill_Tag_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Common_Skill_Tag_Var_Pop_Fields = {
  __typename?:'common_skill_tag_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Common_Skill_Tag_Var_Samp_Fields = {
  __typename?:'common_skill_tag_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Common_Skill_Tag_Variance_Fields = {
  __typename?:'common_skill_tag_variance_fields';
  id?:Maybe<Scalars['Float']>;
};

/** columns and relationships of "common.user" */
export type Common_User = {
  __typename?:'common_user';
  /** An array relationship */
  attachments:Array<Common_Attachment>;
  /** An aggregate relationship */
  attachments_aggregate:Common_Attachment_Aggregate;
  avatar?:Maybe<Scalars['String']>;
  created_at:Scalars['timestamptz'];
  email?:Maybe<Scalars['String']>;
  /** An array relationship */
  filters:Array<Common_Filter>;
  /** An aggregate relationship */
  filters_aggregate:Common_Filter_Aggregate;
  first_name:Scalars['String'];
  first_name_i18n?:Maybe<Scalars['String']>;
  id:Scalars['Int'];
  is_active:Scalars['Boolean'];
  last_name:Scalars['String'];
  last_name_i18n?:Maybe<Scalars['String']>;
  middle_name?:Maybe<Scalars['String']>;
  middle_name_i18n?:Maybe<Scalars['String']>;
  /** An array relationship */
  position_group_priorities:Array<Recruiting_Position_Group_Priority>;
  /** An aggregate relationship */
  position_group_priorities_aggregate:Recruiting_Position_Group_Priority_Aggregate;
  refresh_token?:Maybe<Scalars['String']>;
  role_names?:Maybe<Scalars['String']>;
  /** An array relationship */
  roles:Array<Common_User_User_Role>;
  /** An aggregate relationship */
  roles_aggregate:Common_User_User_Role_Aggregate;
  /** An array relationship */
  staffs:Array<Staffing_Staff>;
  /** An aggregate relationship */
  staffs_aggregate:Staffing_Staff_Aggregate;
  updated_at?:Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "common.user" */
export type Common_UserAttachmentsArgs = {
  distinct_on?:InputMaybe<Array<Common_Attachment_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Attachment_Order_By>>;
  where?:InputMaybe<Common_Attachment_Bool_Exp>;
};


/** columns and relationships of "common.user" */
export type Common_UserAttachments_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Attachment_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Attachment_Order_By>>;
  where?:InputMaybe<Common_Attachment_Bool_Exp>;
};


/** columns and relationships of "common.user" */
export type Common_UserFiltersArgs = {
  distinct_on?:InputMaybe<Array<Common_Filter_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Filter_Order_By>>;
  where?:InputMaybe<Common_Filter_Bool_Exp>;
};


/** columns and relationships of "common.user" */
export type Common_UserFilters_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Filter_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Filter_Order_By>>;
  where?:InputMaybe<Common_Filter_Bool_Exp>;
};


/** columns and relationships of "common.user" */
export type Common_UserPosition_Group_PrioritiesArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Group_Priority_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Group_Priority_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Group_Priority_Bool_Exp>;
};


/** columns and relationships of "common.user" */
export type Common_UserPosition_Group_Priorities_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Group_Priority_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Group_Priority_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Group_Priority_Bool_Exp>;
};


/** columns and relationships of "common.user" */
export type Common_UserRolesArgs = {
  distinct_on?:InputMaybe<Array<Common_User_User_Role_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_User_Role_Order_By>>;
  where?:InputMaybe<Common_User_User_Role_Bool_Exp>;
};


/** columns and relationships of "common.user" */
export type Common_UserRoles_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_User_User_Role_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_User_Role_Order_By>>;
  where?:InputMaybe<Common_User_User_Role_Bool_Exp>;
};


/** columns and relationships of "common.user" */
export type Common_UserStaffsArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Bool_Exp>;
};


/** columns and relationships of "common.user" */
export type Common_UserStaffs_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Bool_Exp>;
};

/** aggregated selection of "common.user" */
export type Common_User_Aggregate = {
  __typename?:'common_user_aggregate';
  aggregate?:Maybe<Common_User_Aggregate_Fields>;
  nodes:Array<Common_User>;
};

/** aggregate fields of "common.user" */
export type Common_User_Aggregate_Fields = {
  __typename?:'common_user_aggregate_fields';
  avg?:Maybe<Common_User_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Common_User_Max_Fields>;
  min?:Maybe<Common_User_Min_Fields>;
  stddev?:Maybe<Common_User_Stddev_Fields>;
  stddev_pop?:Maybe<Common_User_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Common_User_Stddev_Samp_Fields>;
  sum?:Maybe<Common_User_Sum_Fields>;
  var_pop?:Maybe<Common_User_Var_Pop_Fields>;
  var_samp?:Maybe<Common_User_Var_Samp_Fields>;
  variance?:Maybe<Common_User_Variance_Fields>;
};


/** aggregate fields of "common.user" */
export type Common_User_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Common_User_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "common.user" */
export type Common_User_Aggregate_Order_By = {
  avg?:InputMaybe<Common_User_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Common_User_Max_Order_By>;
  min?:InputMaybe<Common_User_Min_Order_By>;
  stddev?:InputMaybe<Common_User_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Common_User_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Common_User_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Common_User_Sum_Order_By>;
  var_pop?:InputMaybe<Common_User_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Common_User_Var_Samp_Order_By>;
  variance?:InputMaybe<Common_User_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Common_User_Avg_Fields = {
  __typename?:'common_user_avg_fields';
  id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "common.user" */
export type Common_User_Avg_Order_By = {
  id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "common.user". All fields are combined with a logical 'AND'. */
export type Common_User_Bool_Exp = {
  _and?:InputMaybe<Array<Common_User_Bool_Exp>>;
  _not?:InputMaybe<Common_User_Bool_Exp>;
  _or?:InputMaybe<Array<Common_User_Bool_Exp>>;
  attachments?:InputMaybe<Common_Attachment_Bool_Exp>;
  attachments_aggregate?:InputMaybe<Common_Attachment_Aggregate_Bool_Exp>;
  avatar?:InputMaybe<String_Comparison_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  email?:InputMaybe<String_Comparison_Exp>;
  filters?:InputMaybe<Common_Filter_Bool_Exp>;
  filters_aggregate?:InputMaybe<Common_Filter_Aggregate_Bool_Exp>;
  first_name?:InputMaybe<String_Comparison_Exp>;
  first_name_i18n?:InputMaybe<String_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  is_active?:InputMaybe<Boolean_Comparison_Exp>;
  last_name?:InputMaybe<String_Comparison_Exp>;
  last_name_i18n?:InputMaybe<String_Comparison_Exp>;
  middle_name?:InputMaybe<String_Comparison_Exp>;
  middle_name_i18n?:InputMaybe<String_Comparison_Exp>;
  position_group_priorities?:InputMaybe<Recruiting_Position_Group_Priority_Bool_Exp>;
  position_group_priorities_aggregate?:InputMaybe<Recruiting_Position_Group_Priority_Aggregate_Bool_Exp>;
  refresh_token?:InputMaybe<String_Comparison_Exp>;
  role_names?:InputMaybe<String_Comparison_Exp>;
  roles?:InputMaybe<Common_User_User_Role_Bool_Exp>;
  roles_aggregate?:InputMaybe<Common_User_User_Role_Aggregate_Bool_Exp>;
  staffs?:InputMaybe<Staffing_Staff_Bool_Exp>;
  staffs_aggregate?:InputMaybe<Staffing_Staff_Aggregate_Bool_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "common.user" */
export enum Common_User_Constraint 
{
  /** unique or primary key constraint on columns "email" */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** input type for incrementing numeric columns in table "common.user" */
export type Common_User_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "common.user" */
export type Common_User_Insert_Input = {
  attachments?:InputMaybe<Common_Attachment_Arr_Rel_Insert_Input>;
  avatar?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  email?:InputMaybe<Scalars['String']>;
  filters?:InputMaybe<Common_Filter_Arr_Rel_Insert_Input>;
  first_name?:InputMaybe<Scalars['String']>;
  first_name_i18n?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  is_active?:InputMaybe<Scalars['Boolean']>;
  last_name?:InputMaybe<Scalars['String']>;
  last_name_i18n?:InputMaybe<Scalars['String']>;
  middle_name?:InputMaybe<Scalars['String']>;
  middle_name_i18n?:InputMaybe<Scalars['String']>;
  position_group_priorities?:InputMaybe<Recruiting_Position_Group_Priority_Arr_Rel_Insert_Input>;
  refresh_token?:InputMaybe<Scalars['String']>;
  roles?:InputMaybe<Common_User_User_Role_Arr_Rel_Insert_Input>;
  staffs?:InputMaybe<Staffing_Staff_Arr_Rel_Insert_Input>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Common_User_Max_Fields = {
  __typename?:'common_user_max_fields';
  avatar?:Maybe<Scalars['String']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  email?:Maybe<Scalars['String']>;
  first_name?:Maybe<Scalars['String']>;
  first_name_i18n?:Maybe<Scalars['String']>;
  id?:Maybe<Scalars['Int']>;
  last_name?:Maybe<Scalars['String']>;
  last_name_i18n?:Maybe<Scalars['String']>;
  middle_name?:Maybe<Scalars['String']>;
  middle_name_i18n?:Maybe<Scalars['String']>;
  refresh_token?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "common.user" */
export type Common_User_Max_Order_By = {
  avatar?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  email?:InputMaybe<Order_By>;
  first_name?:InputMaybe<Order_By>;
  first_name_i18n?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  last_name?:InputMaybe<Order_By>;
  last_name_i18n?:InputMaybe<Order_By>;
  middle_name?:InputMaybe<Order_By>;
  middle_name_i18n?:InputMaybe<Order_By>;
  refresh_token?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Common_User_Min_Fields = {
  __typename?:'common_user_min_fields';
  avatar?:Maybe<Scalars['String']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  email?:Maybe<Scalars['String']>;
  first_name?:Maybe<Scalars['String']>;
  first_name_i18n?:Maybe<Scalars['String']>;
  id?:Maybe<Scalars['Int']>;
  last_name?:Maybe<Scalars['String']>;
  last_name_i18n?:Maybe<Scalars['String']>;
  middle_name?:Maybe<Scalars['String']>;
  middle_name_i18n?:Maybe<Scalars['String']>;
  refresh_token?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "common.user" */
export type Common_User_Min_Order_By = {
  avatar?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  email?:InputMaybe<Order_By>;
  first_name?:InputMaybe<Order_By>;
  first_name_i18n?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  last_name?:InputMaybe<Order_By>;
  last_name_i18n?:InputMaybe<Order_By>;
  middle_name?:InputMaybe<Order_By>;
  middle_name_i18n?:InputMaybe<Order_By>;
  refresh_token?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "common.user" */
export type Common_User_Mutation_Response = {
  __typename?:'common_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Common_User>;
};

/** input type for inserting object relation for remote table "common.user" */
export type Common_User_Obj_Rel_Insert_Input = {
  data:Common_User_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Common_User_On_Conflict>;
};

/** on_conflict condition type for table "common.user" */
export type Common_User_On_Conflict = {
  constraint:Common_User_Constraint;
  update_columns?:Array<Common_User_Update_Column>;
  where?:InputMaybe<Common_User_Bool_Exp>;
};

/** Ordering options when selecting data from "common.user". */
export type Common_User_Order_By = {
  attachments_aggregate?:InputMaybe<Common_Attachment_Aggregate_Order_By>;
  avatar?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  email?:InputMaybe<Order_By>;
  filters_aggregate?:InputMaybe<Common_Filter_Aggregate_Order_By>;
  first_name?:InputMaybe<Order_By>;
  first_name_i18n?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  is_active?:InputMaybe<Order_By>;
  last_name?:InputMaybe<Order_By>;
  last_name_i18n?:InputMaybe<Order_By>;
  middle_name?:InputMaybe<Order_By>;
  middle_name_i18n?:InputMaybe<Order_By>;
  position_group_priorities_aggregate?:InputMaybe<Recruiting_Position_Group_Priority_Aggregate_Order_By>;
  refresh_token?:InputMaybe<Order_By>;
  role_names?:InputMaybe<Order_By>;
  roles_aggregate?:InputMaybe<Common_User_User_Role_Aggregate_Order_By>;
  staffs_aggregate?:InputMaybe<Staffing_Staff_Aggregate_Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: common.user */
export type Common_User_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** columns and relationships of "common.user_role" */
export type Common_User_Role = {
  __typename?:'common_user_role';
  created_at:Scalars['timestamptz'];
  display_name?:Maybe<Scalars['String']>;
  id:Scalars['Int'];
  name:Scalars['String'];
  updated_at?:Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  users:Array<Common_User_User_Role>;
  /** An aggregate relationship */
  users_aggregate:Common_User_User_Role_Aggregate;
};


/** columns and relationships of "common.user_role" */
export type Common_User_RoleUsersArgs = {
  distinct_on?:InputMaybe<Array<Common_User_User_Role_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_User_Role_Order_By>>;
  where?:InputMaybe<Common_User_User_Role_Bool_Exp>;
};


/** columns and relationships of "common.user_role" */
export type Common_User_RoleUsers_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_User_User_Role_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_User_Role_Order_By>>;
  where?:InputMaybe<Common_User_User_Role_Bool_Exp>;
};

/** aggregated selection of "common.user_role" */
export type Common_User_Role_Aggregate = {
  __typename?:'common_user_role_aggregate';
  aggregate?:Maybe<Common_User_Role_Aggregate_Fields>;
  nodes:Array<Common_User_Role>;
};

/** aggregate fields of "common.user_role" */
export type Common_User_Role_Aggregate_Fields = {
  __typename?:'common_user_role_aggregate_fields';
  avg?:Maybe<Common_User_Role_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Common_User_Role_Max_Fields>;
  min?:Maybe<Common_User_Role_Min_Fields>;
  stddev?:Maybe<Common_User_Role_Stddev_Fields>;
  stddev_pop?:Maybe<Common_User_Role_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Common_User_Role_Stddev_Samp_Fields>;
  sum?:Maybe<Common_User_Role_Sum_Fields>;
  var_pop?:Maybe<Common_User_Role_Var_Pop_Fields>;
  var_samp?:Maybe<Common_User_Role_Var_Samp_Fields>;
  variance?:Maybe<Common_User_Role_Variance_Fields>;
};


/** aggregate fields of "common.user_role" */
export type Common_User_Role_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Common_User_Role_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Common_User_Role_Avg_Fields = {
  __typename?:'common_user_role_avg_fields';
  id?:Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "common.user_role". All fields are combined with a logical 'AND'. */
export type Common_User_Role_Bool_Exp = {
  _and?:InputMaybe<Array<Common_User_Role_Bool_Exp>>;
  _not?:InputMaybe<Common_User_Role_Bool_Exp>;
  _or?:InputMaybe<Array<Common_User_Role_Bool_Exp>>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  display_name?:InputMaybe<String_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  name?:InputMaybe<String_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  users?:InputMaybe<Common_User_User_Role_Bool_Exp>;
  users_aggregate?:InputMaybe<Common_User_User_Role_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "common.user_role" */
export enum Common_User_Role_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  UserRolePkey = 'user_role_pkey'
}

/** input type for incrementing numeric columns in table "common.user_role" */
export type Common_User_Role_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "common.user_role" */
export type Common_User_Role_Insert_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  display_name?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  users?:InputMaybe<Common_User_User_Role_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Common_User_Role_Max_Fields = {
  __typename?:'common_user_role_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  display_name?:Maybe<Scalars['String']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Common_User_Role_Min_Fields = {
  __typename?:'common_user_role_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  display_name?:Maybe<Scalars['String']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "common.user_role" */
export type Common_User_Role_Mutation_Response = {
  __typename?:'common_user_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Common_User_Role>;
};

/** input type for inserting object relation for remote table "common.user_role" */
export type Common_User_Role_Obj_Rel_Insert_Input = {
  data:Common_User_Role_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Common_User_Role_On_Conflict>;
};

/** on_conflict condition type for table "common.user_role" */
export type Common_User_Role_On_Conflict = {
  constraint:Common_User_Role_Constraint;
  update_columns?:Array<Common_User_Role_Update_Column>;
  where?:InputMaybe<Common_User_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "common.user_role". */
export type Common_User_Role_Order_By = {
  created_at?:InputMaybe<Order_By>;
  display_name?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  users_aggregate?:InputMaybe<Common_User_User_Role_Aggregate_Order_By>;
};

/** primary key columns input for table: common.user_role */
export type Common_User_Role_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "common.user_role" */
export enum Common_User_Role_Select_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "common.user_role" */
export type Common_User_Role_Set_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  display_name?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Common_User_Role_Stddev_Fields = {
  __typename?:'common_user_role_stddev_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Common_User_Role_Stddev_Pop_Fields = {
  __typename?:'common_user_role_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Common_User_Role_Stddev_Samp_Fields = {
  __typename?:'common_user_role_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "common_user_role" */
export type Common_User_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Common_User_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Common_User_Role_Stream_Cursor_Value_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  display_name?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Common_User_Role_Sum_Fields = {
  __typename?:'common_user_role_sum_fields';
  id?:Maybe<Scalars['Int']>;
};

/** update columns of table "common.user_role" */
export enum Common_User_Role_Update_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Common_User_Role_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Common_User_Role_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Common_User_Role_Set_Input>;
  where:Common_User_Role_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Common_User_Role_Var_Pop_Fields = {
  __typename?:'common_user_role_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Common_User_Role_Var_Samp_Fields = {
  __typename?:'common_user_role_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Common_User_Role_Variance_Fields = {
  __typename?:'common_user_role_variance_fields';
  id?:Maybe<Scalars['Float']>;
};

/** select columns of table "common.user" */
export enum Common_User_Select_Column 
{
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FirstNameI18n = 'first_name_i18n',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastNameI18n = 'last_name_i18n',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  MiddleNameI18n = 'middle_name_i18n',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "common.user" */
export type Common_User_Set_Input = {
  avatar?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  email?:InputMaybe<Scalars['String']>;
  first_name?:InputMaybe<Scalars['String']>;
  first_name_i18n?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  is_active?:InputMaybe<Scalars['Boolean']>;
  last_name?:InputMaybe<Scalars['String']>;
  last_name_i18n?:InputMaybe<Scalars['String']>;
  middle_name?:InputMaybe<Scalars['String']>;
  middle_name_i18n?:InputMaybe<Scalars['String']>;
  refresh_token?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Common_User_Stddev_Fields = {
  __typename?:'common_user_stddev_fields';
  id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "common.user" */
export type Common_User_Stddev_Order_By = {
  id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Common_User_Stddev_Pop_Fields = {
  __typename?:'common_user_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "common.user" */
export type Common_User_Stddev_Pop_Order_By = {
  id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Common_User_Stddev_Samp_Fields = {
  __typename?:'common_user_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "common.user" */
export type Common_User_Stddev_Samp_Order_By = {
  id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "common_user" */
export type Common_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Common_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Common_User_Stream_Cursor_Value_Input = {
  avatar?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  email?:InputMaybe<Scalars['String']>;
  first_name?:InputMaybe<Scalars['String']>;
  first_name_i18n?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  is_active?:InputMaybe<Scalars['Boolean']>;
  last_name?:InputMaybe<Scalars['String']>;
  last_name_i18n?:InputMaybe<Scalars['String']>;
  middle_name?:InputMaybe<Scalars['String']>;
  middle_name_i18n?:InputMaybe<Scalars['String']>;
  refresh_token?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Common_User_Sum_Fields = {
  __typename?:'common_user_sum_fields';
  id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "common.user" */
export type Common_User_Sum_Order_By = {
  id?:InputMaybe<Order_By>;
};

/** update columns of table "common.user" */
export enum Common_User_Update_Column 
{
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FirstNameI18n = 'first_name_i18n',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastNameI18n = 'last_name_i18n',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  MiddleNameI18n = 'middle_name_i18n',
  /** column name */
  RefreshToken = 'refresh_token',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Common_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Common_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Common_User_Set_Input>;
  where:Common_User_Bool_Exp;
};

/** columns and relationships of "common.user_user_role" */
export type Common_User_User_Role = {
  __typename?:'common_user_user_role';
  created_at?:Maybe<Scalars['timestamptz']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user:Common_User;
  user_id:Scalars['Int'];
  /** An object relationship */
  user_role:Common_User_Role;
  user_role_id:Scalars['Int'];
};

/** aggregated selection of "common.user_user_role" */
export type Common_User_User_Role_Aggregate = {
  __typename?:'common_user_user_role_aggregate';
  aggregate?:Maybe<Common_User_User_Role_Aggregate_Fields>;
  nodes:Array<Common_User_User_Role>;
};

export type Common_User_User_Role_Aggregate_Bool_Exp = {
  count?:InputMaybe<Common_User_User_Role_Aggregate_Bool_Exp_Count>;
};

export type Common_User_User_Role_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Common_User_User_Role_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Common_User_User_Role_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "common.user_user_role" */
export type Common_User_User_Role_Aggregate_Fields = {
  __typename?:'common_user_user_role_aggregate_fields';
  avg?:Maybe<Common_User_User_Role_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Common_User_User_Role_Max_Fields>;
  min?:Maybe<Common_User_User_Role_Min_Fields>;
  stddev?:Maybe<Common_User_User_Role_Stddev_Fields>;
  stddev_pop?:Maybe<Common_User_User_Role_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Common_User_User_Role_Stddev_Samp_Fields>;
  sum?:Maybe<Common_User_User_Role_Sum_Fields>;
  var_pop?:Maybe<Common_User_User_Role_Var_Pop_Fields>;
  var_samp?:Maybe<Common_User_User_Role_Var_Samp_Fields>;
  variance?:Maybe<Common_User_User_Role_Variance_Fields>;
};


/** aggregate fields of "common.user_user_role" */
export type Common_User_User_Role_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Common_User_User_Role_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "common.user_user_role" */
export type Common_User_User_Role_Aggregate_Order_By = {
  avg?:InputMaybe<Common_User_User_Role_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Common_User_User_Role_Max_Order_By>;
  min?:InputMaybe<Common_User_User_Role_Min_Order_By>;
  stddev?:InputMaybe<Common_User_User_Role_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Common_User_User_Role_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Common_User_User_Role_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Common_User_User_Role_Sum_Order_By>;
  var_pop?:InputMaybe<Common_User_User_Role_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Common_User_User_Role_Var_Samp_Order_By>;
  variance?:InputMaybe<Common_User_User_Role_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "common.user_user_role" */
export type Common_User_User_Role_Arr_Rel_Insert_Input = {
  data:Array<Common_User_User_Role_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Common_User_User_Role_On_Conflict>;
};

/** aggregate avg on columns */
export type Common_User_User_Role_Avg_Fields = {
  __typename?:'common_user_user_role_avg_fields';
  user_id?:Maybe<Scalars['Float']>;
  user_role_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "common.user_user_role" */
export type Common_User_User_Role_Avg_Order_By = {
  user_id?:InputMaybe<Order_By>;
  user_role_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "common.user_user_role". All fields are combined with a logical 'AND'. */
export type Common_User_User_Role_Bool_Exp = {
  _and?:InputMaybe<Array<Common_User_User_Role_Bool_Exp>>;
  _not?:InputMaybe<Common_User_User_Role_Bool_Exp>;
  _or?:InputMaybe<Array<Common_User_User_Role_Bool_Exp>>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  user?:InputMaybe<Common_User_Bool_Exp>;
  user_id?:InputMaybe<Int_Comparison_Exp>;
  user_role?:InputMaybe<Common_User_Role_Bool_Exp>;
  user_role_id?:InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "common.user_user_role" */
export enum Common_User_User_Role_Constraint 
{
  /** unique or primary key constraint on columns "user_id", "user_role_id" */
  UserUserRolePkey = 'user_user_role_pkey'
}

/** input type for incrementing numeric columns in table "common.user_user_role" */
export type Common_User_User_Role_Inc_Input = {
  user_id?:InputMaybe<Scalars['Int']>;
  user_role_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "common.user_user_role" */
export type Common_User_User_Role_Insert_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user?:InputMaybe<Common_User_Obj_Rel_Insert_Input>;
  user_id?:InputMaybe<Scalars['Int']>;
  user_role?:InputMaybe<Common_User_Role_Obj_Rel_Insert_Input>;
  user_role_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Common_User_User_Role_Max_Fields = {
  __typename?:'common_user_user_role_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
  user_id?:Maybe<Scalars['Int']>;
  user_role_id?:Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "common.user_user_role" */
export type Common_User_User_Role_Max_Order_By = {
  created_at?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
  user_role_id?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Common_User_User_Role_Min_Fields = {
  __typename?:'common_user_user_role_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
  user_id?:Maybe<Scalars['Int']>;
  user_role_id?:Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "common.user_user_role" */
export type Common_User_User_Role_Min_Order_By = {
  created_at?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
  user_role_id?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "common.user_user_role" */
export type Common_User_User_Role_Mutation_Response = {
  __typename?:'common_user_user_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Common_User_User_Role>;
};

/** on_conflict condition type for table "common.user_user_role" */
export type Common_User_User_Role_On_Conflict = {
  constraint:Common_User_User_Role_Constraint;
  update_columns?:Array<Common_User_User_Role_Update_Column>;
  where?:InputMaybe<Common_User_User_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "common.user_user_role". */
export type Common_User_User_Role_Order_By = {
  created_at?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user?:InputMaybe<Common_User_Order_By>;
  user_id?:InputMaybe<Order_By>;
  user_role?:InputMaybe<Common_User_Role_Order_By>;
  user_role_id?:InputMaybe<Order_By>;
};

/** primary key columns input for table: common.user_user_role */
export type Common_User_User_Role_Pk_Columns_Input = {
  user_id:Scalars['Int'];
  user_role_id:Scalars['Int'];
};

/** select columns of table "common.user_user_role" */
export enum Common_User_User_Role_Select_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserRoleId = 'user_role_id'
}

/** input type for updating data in table "common.user_user_role" */
export type Common_User_User_Role_Set_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user_id?:InputMaybe<Scalars['Int']>;
  user_role_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Common_User_User_Role_Stddev_Fields = {
  __typename?:'common_user_user_role_stddev_fields';
  user_id?:Maybe<Scalars['Float']>;
  user_role_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "common.user_user_role" */
export type Common_User_User_Role_Stddev_Order_By = {
  user_id?:InputMaybe<Order_By>;
  user_role_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Common_User_User_Role_Stddev_Pop_Fields = {
  __typename?:'common_user_user_role_stddev_pop_fields';
  user_id?:Maybe<Scalars['Float']>;
  user_role_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "common.user_user_role" */
export type Common_User_User_Role_Stddev_Pop_Order_By = {
  user_id?:InputMaybe<Order_By>;
  user_role_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Common_User_User_Role_Stddev_Samp_Fields = {
  __typename?:'common_user_user_role_stddev_samp_fields';
  user_id?:Maybe<Scalars['Float']>;
  user_role_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "common.user_user_role" */
export type Common_User_User_Role_Stddev_Samp_Order_By = {
  user_id?:InputMaybe<Order_By>;
  user_role_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "common_user_user_role" */
export type Common_User_User_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Common_User_User_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Common_User_User_Role_Stream_Cursor_Value_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user_id?:InputMaybe<Scalars['Int']>;
  user_role_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Common_User_User_Role_Sum_Fields = {
  __typename?:'common_user_user_role_sum_fields';
  user_id?:Maybe<Scalars['Int']>;
  user_role_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "common.user_user_role" */
export type Common_User_User_Role_Sum_Order_By = {
  user_id?:InputMaybe<Order_By>;
  user_role_id?:InputMaybe<Order_By>;
};

/** update columns of table "common.user_user_role" */
export enum Common_User_User_Role_Update_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserRoleId = 'user_role_id'
}

export type Common_User_User_Role_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Common_User_User_Role_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Common_User_User_Role_Set_Input>;
  where:Common_User_User_Role_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Common_User_User_Role_Var_Pop_Fields = {
  __typename?:'common_user_user_role_var_pop_fields';
  user_id?:Maybe<Scalars['Float']>;
  user_role_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "common.user_user_role" */
export type Common_User_User_Role_Var_Pop_Order_By = {
  user_id?:InputMaybe<Order_By>;
  user_role_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Common_User_User_Role_Var_Samp_Fields = {
  __typename?:'common_user_user_role_var_samp_fields';
  user_id?:Maybe<Scalars['Float']>;
  user_role_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "common.user_user_role" */
export type Common_User_User_Role_Var_Samp_Order_By = {
  user_id?:InputMaybe<Order_By>;
  user_role_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Common_User_User_Role_Variance_Fields = {
  __typename?:'common_user_user_role_variance_fields';
  user_id?:Maybe<Scalars['Float']>;
  user_role_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "common.user_user_role" */
export type Common_User_User_Role_Variance_Order_By = {
  user_id?:InputMaybe<Order_By>;
  user_role_id?:InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Common_User_Var_Pop_Fields = {
  __typename?:'common_user_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "common.user" */
export type Common_User_Var_Pop_Order_By = {
  id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Common_User_Var_Samp_Fields = {
  __typename?:'common_user_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "common.user" */
export type Common_User_Var_Samp_Order_By = {
  id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Common_User_Variance_Fields = {
  __typename?:'common_user_variance_fields';
  id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "common.user" */
export type Common_User_Variance_Order_By = {
  id?:InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering 
{
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "hasura_action_permissions_view" */
export type Hasura_Action_Permissions_View = {
  __typename?:'hasura_action_permissions_view';
  action_name?:Maybe<Scalars['String']>;
  permissions?:Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "hasura_action_permissions_view" */
export type Hasura_Action_Permissions_ViewPermissionsArgs = {
  path?:InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hasura_action_permissions_view" */
export type Hasura_Action_Permissions_View_Aggregate = {
  __typename?:'hasura_action_permissions_view_aggregate';
  aggregate?:Maybe<Hasura_Action_Permissions_View_Aggregate_Fields>;
  nodes:Array<Hasura_Action_Permissions_View>;
};

/** aggregate fields of "hasura_action_permissions_view" */
export type Hasura_Action_Permissions_View_Aggregate_Fields = {
  __typename?:'hasura_action_permissions_view_aggregate_fields';
  count:Scalars['Int'];
  max?:Maybe<Hasura_Action_Permissions_View_Max_Fields>;
  min?:Maybe<Hasura_Action_Permissions_View_Min_Fields>;
};


/** aggregate fields of "hasura_action_permissions_view" */
export type Hasura_Action_Permissions_View_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Hasura_Action_Permissions_View_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "hasura_action_permissions_view". All fields are combined with a logical 'AND'. */
export type Hasura_Action_Permissions_View_Bool_Exp = {
  _and?:InputMaybe<Array<Hasura_Action_Permissions_View_Bool_Exp>>;
  _not?:InputMaybe<Hasura_Action_Permissions_View_Bool_Exp>;
  _or?:InputMaybe<Array<Hasura_Action_Permissions_View_Bool_Exp>>;
  action_name?:InputMaybe<String_Comparison_Exp>;
  permissions?:InputMaybe<Jsonb_Comparison_Exp>;
};

/** aggregate max on columns */
export type Hasura_Action_Permissions_View_Max_Fields = {
  __typename?:'hasura_action_permissions_view_max_fields';
  action_name?:Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Hasura_Action_Permissions_View_Min_Fields = {
  __typename?:'hasura_action_permissions_view_min_fields';
  action_name?:Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "hasura_action_permissions_view". */
export type Hasura_Action_Permissions_View_Order_By = {
  action_name?:InputMaybe<Order_By>;
  permissions?:InputMaybe<Order_By>;
};

/** select columns of table "hasura_action_permissions_view" */
export enum Hasura_Action_Permissions_View_Select_Column 
{
  /** column name */
  ActionName = 'action_name',
  /** column name */
  Permissions = 'permissions'
}

/** Streaming cursor of the table "hasura_action_permissions_view" */
export type Hasura_Action_Permissions_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Hasura_Action_Permissions_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hasura_Action_Permissions_View_Stream_Cursor_Value_Input = {
  action_name?:InputMaybe<Scalars['String']>;
  permissions?:InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "hasura_inherited_roles_view" */
export type Hasura_Inherited_Roles_View = {
  __typename?:'hasura_inherited_roles_view';
  role_name?:Maybe<Scalars['String']>;
  role_set?:Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "hasura_inherited_roles_view" */
export type Hasura_Inherited_Roles_ViewRole_SetArgs = {
  path?:InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hasura_inherited_roles_view" */
export type Hasura_Inherited_Roles_View_Aggregate = {
  __typename?:'hasura_inherited_roles_view_aggregate';
  aggregate?:Maybe<Hasura_Inherited_Roles_View_Aggregate_Fields>;
  nodes:Array<Hasura_Inherited_Roles_View>;
};

/** aggregate fields of "hasura_inherited_roles_view" */
export type Hasura_Inherited_Roles_View_Aggregate_Fields = {
  __typename?:'hasura_inherited_roles_view_aggregate_fields';
  count:Scalars['Int'];
  max?:Maybe<Hasura_Inherited_Roles_View_Max_Fields>;
  min?:Maybe<Hasura_Inherited_Roles_View_Min_Fields>;
};


/** aggregate fields of "hasura_inherited_roles_view" */
export type Hasura_Inherited_Roles_View_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Hasura_Inherited_Roles_View_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "hasura_inherited_roles_view". All fields are combined with a logical 'AND'. */
export type Hasura_Inherited_Roles_View_Bool_Exp = {
  _and?:InputMaybe<Array<Hasura_Inherited_Roles_View_Bool_Exp>>;
  _not?:InputMaybe<Hasura_Inherited_Roles_View_Bool_Exp>;
  _or?:InputMaybe<Array<Hasura_Inherited_Roles_View_Bool_Exp>>;
  role_name?:InputMaybe<String_Comparison_Exp>;
  role_set?:InputMaybe<Jsonb_Comparison_Exp>;
};

/** aggregate max on columns */
export type Hasura_Inherited_Roles_View_Max_Fields = {
  __typename?:'hasura_inherited_roles_view_max_fields';
  role_name?:Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Hasura_Inherited_Roles_View_Min_Fields = {
  __typename?:'hasura_inherited_roles_view_min_fields';
  role_name?:Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "hasura_inherited_roles_view". */
export type Hasura_Inherited_Roles_View_Order_By = {
  role_name?:InputMaybe<Order_By>;
  role_set?:InputMaybe<Order_By>;
};

/** select columns of table "hasura_inherited_roles_view" */
export enum Hasura_Inherited_Roles_View_Select_Column 
{
  /** column name */
  RoleName = 'role_name',
  /** column name */
  RoleSet = 'role_set'
}

/** Streaming cursor of the table "hasura_inherited_roles_view" */
export type Hasura_Inherited_Roles_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Hasura_Inherited_Roles_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hasura_Inherited_Roles_View_Stream_Cursor_Value_Input = {
  role_name?:InputMaybe<Scalars['String']>;
  role_set?:InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "hasura_metadata_view" */
export type Hasura_Metadata_View = {
  __typename?:'hasura_metadata_view';
  id?:Maybe<Scalars['Int']>;
  metadata?:Maybe<Scalars['json']>;
};


/** columns and relationships of "hasura_metadata_view" */
export type Hasura_Metadata_ViewMetadataArgs = {
  path?:InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hasura_metadata_view" */
export type Hasura_Metadata_View_Aggregate = {
  __typename?:'hasura_metadata_view_aggregate';
  aggregate?:Maybe<Hasura_Metadata_View_Aggregate_Fields>;
  nodes:Array<Hasura_Metadata_View>;
};

/** aggregate fields of "hasura_metadata_view" */
export type Hasura_Metadata_View_Aggregate_Fields = {
  __typename?:'hasura_metadata_view_aggregate_fields';
  avg?:Maybe<Hasura_Metadata_View_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Hasura_Metadata_View_Max_Fields>;
  min?:Maybe<Hasura_Metadata_View_Min_Fields>;
  stddev?:Maybe<Hasura_Metadata_View_Stddev_Fields>;
  stddev_pop?:Maybe<Hasura_Metadata_View_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Hasura_Metadata_View_Stddev_Samp_Fields>;
  sum?:Maybe<Hasura_Metadata_View_Sum_Fields>;
  var_pop?:Maybe<Hasura_Metadata_View_Var_Pop_Fields>;
  var_samp?:Maybe<Hasura_Metadata_View_Var_Samp_Fields>;
  variance?:Maybe<Hasura_Metadata_View_Variance_Fields>;
};


/** aggregate fields of "hasura_metadata_view" */
export type Hasura_Metadata_View_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Hasura_Metadata_View_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Hasura_Metadata_View_Avg_Fields = {
  __typename?:'hasura_metadata_view_avg_fields';
  id?:Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "hasura_metadata_view". All fields are combined with a logical 'AND'. */
export type Hasura_Metadata_View_Bool_Exp = {
  _and?:InputMaybe<Array<Hasura_Metadata_View_Bool_Exp>>;
  _not?:InputMaybe<Hasura_Metadata_View_Bool_Exp>;
  _or?:InputMaybe<Array<Hasura_Metadata_View_Bool_Exp>>;
  id?:InputMaybe<Int_Comparison_Exp>;
  metadata?:InputMaybe<Json_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "hasura_metadata_view" */
export type Hasura_Metadata_View_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "hasura_metadata_view" */
export type Hasura_Metadata_View_Insert_Input = {
  id?:InputMaybe<Scalars['Int']>;
  metadata?:InputMaybe<Scalars['json']>;
};

/** aggregate max on columns */
export type Hasura_Metadata_View_Max_Fields = {
  __typename?:'hasura_metadata_view_max_fields';
  id?:Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Hasura_Metadata_View_Min_Fields = {
  __typename?:'hasura_metadata_view_min_fields';
  id?:Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "hasura_metadata_view" */
export type Hasura_Metadata_View_Mutation_Response = {
  __typename?:'hasura_metadata_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Hasura_Metadata_View>;
};

/** Ordering options when selecting data from "hasura_metadata_view". */
export type Hasura_Metadata_View_Order_By = {
  id?:InputMaybe<Order_By>;
  metadata?:InputMaybe<Order_By>;
};

/** select columns of table "hasura_metadata_view" */
export enum Hasura_Metadata_View_Select_Column 
{
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata'
}

/** input type for updating data in table "hasura_metadata_view" */
export type Hasura_Metadata_View_Set_Input = {
  id?:InputMaybe<Scalars['Int']>;
  metadata?:InputMaybe<Scalars['json']>;
};

/** aggregate stddev on columns */
export type Hasura_Metadata_View_Stddev_Fields = {
  __typename?:'hasura_metadata_view_stddev_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Hasura_Metadata_View_Stddev_Pop_Fields = {
  __typename?:'hasura_metadata_view_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Hasura_Metadata_View_Stddev_Samp_Fields = {
  __typename?:'hasura_metadata_view_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "hasura_metadata_view" */
export type Hasura_Metadata_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Hasura_Metadata_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hasura_Metadata_View_Stream_Cursor_Value_Input = {
  id?:InputMaybe<Scalars['Int']>;
  metadata?:InputMaybe<Scalars['json']>;
};

/** aggregate sum on columns */
export type Hasura_Metadata_View_Sum_Fields = {
  __typename?:'hasura_metadata_view_sum_fields';
  id?:Maybe<Scalars['Int']>;
};

export type Hasura_Metadata_View_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Hasura_Metadata_View_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Hasura_Metadata_View_Set_Input>;
  where:Hasura_Metadata_View_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Hasura_Metadata_View_Var_Pop_Fields = {
  __typename?:'hasura_metadata_view_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Hasura_Metadata_View_Var_Samp_Fields = {
  __typename?:'hasura_metadata_view_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Hasura_Metadata_View_Variance_Fields = {
  __typename?:'hasura_metadata_view_variance_fields';
  id?:Maybe<Scalars['Float']>;
};

/** columns and relationships of "hasura_permissions_view" */
export type Hasura_Permissions_View = {
  __typename?:'hasura_permissions_view';
  delete_permissions?:Maybe<Scalars['jsonb']>;
  insert_permissions?:Maybe<Scalars['jsonb']>;
  schema_name?:Maybe<Scalars['String']>;
  select_permissions?:Maybe<Scalars['jsonb']>;
  sources?:Maybe<Scalars['jsonb']>;
  table_name?:Maybe<Scalars['String']>;
  update_permissions?:Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "hasura_permissions_view" */
export type Hasura_Permissions_ViewDelete_PermissionsArgs = {
  path?:InputMaybe<Scalars['String']>;
};


/** columns and relationships of "hasura_permissions_view" */
export type Hasura_Permissions_ViewInsert_PermissionsArgs = {
  path?:InputMaybe<Scalars['String']>;
};


/** columns and relationships of "hasura_permissions_view" */
export type Hasura_Permissions_ViewSelect_PermissionsArgs = {
  path?:InputMaybe<Scalars['String']>;
};


/** columns and relationships of "hasura_permissions_view" */
export type Hasura_Permissions_ViewSourcesArgs = {
  path?:InputMaybe<Scalars['String']>;
};


/** columns and relationships of "hasura_permissions_view" */
export type Hasura_Permissions_ViewUpdate_PermissionsArgs = {
  path?:InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hasura_permissions_view" */
export type Hasura_Permissions_View_Aggregate = {
  __typename?:'hasura_permissions_view_aggregate';
  aggregate?:Maybe<Hasura_Permissions_View_Aggregate_Fields>;
  nodes:Array<Hasura_Permissions_View>;
};

/** aggregate fields of "hasura_permissions_view" */
export type Hasura_Permissions_View_Aggregate_Fields = {
  __typename?:'hasura_permissions_view_aggregate_fields';
  count:Scalars['Int'];
  max?:Maybe<Hasura_Permissions_View_Max_Fields>;
  min?:Maybe<Hasura_Permissions_View_Min_Fields>;
};


/** aggregate fields of "hasura_permissions_view" */
export type Hasura_Permissions_View_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Hasura_Permissions_View_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "hasura_permissions_view". All fields are combined with a logical 'AND'. */
export type Hasura_Permissions_View_Bool_Exp = {
  _and?:InputMaybe<Array<Hasura_Permissions_View_Bool_Exp>>;
  _not?:InputMaybe<Hasura_Permissions_View_Bool_Exp>;
  _or?:InputMaybe<Array<Hasura_Permissions_View_Bool_Exp>>;
  delete_permissions?:InputMaybe<Jsonb_Comparison_Exp>;
  insert_permissions?:InputMaybe<Jsonb_Comparison_Exp>;
  schema_name?:InputMaybe<String_Comparison_Exp>;
  select_permissions?:InputMaybe<Jsonb_Comparison_Exp>;
  sources?:InputMaybe<Jsonb_Comparison_Exp>;
  table_name?:InputMaybe<String_Comparison_Exp>;
  update_permissions?:InputMaybe<Jsonb_Comparison_Exp>;
};

/** aggregate max on columns */
export type Hasura_Permissions_View_Max_Fields = {
  __typename?:'hasura_permissions_view_max_fields';
  schema_name?:Maybe<Scalars['String']>;
  table_name?:Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Hasura_Permissions_View_Min_Fields = {
  __typename?:'hasura_permissions_view_min_fields';
  schema_name?:Maybe<Scalars['String']>;
  table_name?:Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "hasura_permissions_view". */
export type Hasura_Permissions_View_Order_By = {
  delete_permissions?:InputMaybe<Order_By>;
  insert_permissions?:InputMaybe<Order_By>;
  schema_name?:InputMaybe<Order_By>;
  select_permissions?:InputMaybe<Order_By>;
  sources?:InputMaybe<Order_By>;
  table_name?:InputMaybe<Order_By>;
  update_permissions?:InputMaybe<Order_By>;
};

/** select columns of table "hasura_permissions_view" */
export enum Hasura_Permissions_View_Select_Column 
{
  /** column name */
  DeletePermissions = 'delete_permissions',
  /** column name */
  InsertPermissions = 'insert_permissions',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SelectPermissions = 'select_permissions',
  /** column name */
  Sources = 'sources',
  /** column name */
  TableName = 'table_name',
  /** column name */
  UpdatePermissions = 'update_permissions'
}

/** Streaming cursor of the table "hasura_permissions_view" */
export type Hasura_Permissions_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Hasura_Permissions_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hasura_Permissions_View_Stream_Cursor_Value_Input = {
  delete_permissions?:InputMaybe<Scalars['jsonb']>;
  insert_permissions?:InputMaybe<Scalars['jsonb']>;
  schema_name?:InputMaybe<Scalars['String']>;
  select_permissions?:InputMaybe<Scalars['jsonb']>;
  sources?:InputMaybe<Scalars['jsonb']>;
  table_name?:InputMaybe<Scalars['String']>;
  update_permissions?:InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to compare columns of type "inet". All fields are combined with logical 'AND'. */
export type Inet_Comparison_Exp = {
  _eq?:InputMaybe<Scalars['inet']>;
  _gt?:InputMaybe<Scalars['inet']>;
  _gte?:InputMaybe<Scalars['inet']>;
  _in?:InputMaybe<Array<Scalars['inet']>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _lt?:InputMaybe<Scalars['inet']>;
  _lte?:InputMaybe<Scalars['inet']>;
  _neq?:InputMaybe<Scalars['inet']>;
  _nin?:InputMaybe<Array<Scalars['inet']>>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?:InputMaybe<Scalars['json']>;
  _gt?:InputMaybe<Scalars['json']>;
  _gte?:InputMaybe<Scalars['json']>;
  _in?:InputMaybe<Array<Scalars['json']>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _lt?:InputMaybe<Scalars['json']>;
  _lte?:InputMaybe<Scalars['json']>;
  _neq?:InputMaybe<Scalars['json']>;
  _nin?:InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?:InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?:InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?:InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?:InputMaybe<Scalars['jsonb']>;
  _eq?:InputMaybe<Scalars['jsonb']>;
  _gt?:InputMaybe<Scalars['jsonb']>;
  _gte?:InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?:InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?:InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?:InputMaybe<Array<Scalars['String']>>;
  _in?:InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _lt?:InputMaybe<Scalars['jsonb']>;
  _lte?:InputMaybe<Scalars['jsonb']>;
  _neq?:InputMaybe<Scalars['jsonb']>;
  _nin?:InputMaybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?:'mutation_root';
  /** action_candidate_fetch_linkedin_image */
  action_candidate_fetch_linkedin_image?:Maybe<CandidateFetchLinkedinImageResponse>;
  /** Uploads a CV file (doc, docx or pdf) */
  action_candidate_upload_cv?:Maybe<CandidateCvUploadResponse>;
  /** action_customer_team_slot_close */
  action_customer_team_slot_close?:Maybe<HasuraActionDefaultResponse>;
  /** Action: Sign in with Google using an authorization code. Calls the Flow backend */
  action_login?:Maybe<LoginResponse>;
  /** Action: Sign in with Google using an authorization code. For testing. */
  action_login_test?:Maybe<LoginResponse>;
  /** createPosition */
  action_position_create?:Maybe<CreatePositionResponse>;
  /** deletePosition */
  action_position_delete?:Maybe<HasuraActionDefaultResponse>;
  /** updatePositionQuantity */
  action_position_update_quantity?:Maybe<HasuraActionDefaultResponse>;
  /** batchPositionCRUD */
  action_positions_batch_crud?:Maybe<HasuraActionDefaultResponse>;
  action_positions_create?:Maybe<HasuraActionDefaultResponse>;
  action_positions_delete?:Maybe<HasuraActionDefaultResponse>;
  action_positions_update_quantity?:Maybe<HasuraActionDefaultResponse>;
  action_reimport_sample_data?:Maybe<HasuraActionDefaultResponse>;
  action_schedule_event?:Maybe<HasuraActionDefaultResponse>;
  /** action_schedule_event_cancel */
  action_schedule_event_cancel?:Maybe<HasuraActionDefaultResponse>;
  /** delete data from the table: "audit.logged_actions" */
  delete_audit_logged_actions?:Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** delete single row from the table: "audit.logged_actions" */
  delete_audit_logged_actions_by_pk?:Maybe<Audit_Logged_Actions>;
  /** delete data from the table: "common.api_key" */
  delete_common_api_key?:Maybe<Common_Api_Key_Mutation_Response>;
  /** delete single row from the table: "common.api_key" */
  delete_common_api_key_by_pk?:Maybe<Common_Api_Key>;
  /** delete data from the table: "common.attachment" */
  delete_common_attachment?:Maybe<Common_Attachment_Mutation_Response>;
  /** delete single row from the table: "common.attachment" */
  delete_common_attachment_by_pk?:Maybe<Common_Attachment>;
  /** delete data from the table: "common.attachment_type" */
  delete_common_attachment_type?:Maybe<Common_Attachment_Type_Mutation_Response>;
  /** delete single row from the table: "common.attachment_type" */
  delete_common_attachment_type_by_pk?:Maybe<Common_Attachment_Type>;
  /** delete data from the table: "common.city" */
  delete_common_city?:Maybe<Common_City_Mutation_Response>;
  /** delete single row from the table: "common.city" */
  delete_common_city_by_pk?:Maybe<Common_City>;
  /** delete data from the table: "common.country" */
  delete_common_country?:Maybe<Common_Country_Mutation_Response>;
  /** delete single row from the table: "common.country" */
  delete_common_country_by_pk?:Maybe<Common_Country>;
  /** delete data from the table: "common.customer" */
  delete_common_customer?:Maybe<Common_Customer_Mutation_Response>;
  /** delete single row from the table: "common.customer" */
  delete_common_customer_by_pk?:Maybe<Common_Customer>;
  /** delete data from the table: "common.customer_status" */
  delete_common_customer_status?:Maybe<Common_Customer_Status_Mutation_Response>;
  /** delete single row from the table: "common.customer_status" */
  delete_common_customer_status_by_pk?:Maybe<Common_Customer_Status>;
  /** delete data from the table: "common.filter" */
  delete_common_filter?:Maybe<Common_Filter_Mutation_Response>;
  /** delete single row from the table: "common.filter" */
  delete_common_filter_by_pk?:Maybe<Common_Filter>;
  /** delete data from the table: "common.filter_type" */
  delete_common_filter_type?:Maybe<Common_Filter_Type_Mutation_Response>;
  /** delete single row from the table: "common.filter_type" */
  delete_common_filter_type_by_pk?:Maybe<Common_Filter_Type>;
  /** delete data from the table: "common.scheduled_event" */
  delete_common_scheduled_event?:Maybe<Common_Scheduled_Event_Mutation_Response>;
  /** delete single row from the table: "common.scheduled_event" */
  delete_common_scheduled_event_by_pk?:Maybe<Common_Scheduled_Event>;
  /** delete data from the table: "common.skill_tag" */
  delete_common_skill_tag?:Maybe<Common_Skill_Tag_Mutation_Response>;
  /** delete single row from the table: "common.skill_tag" */
  delete_common_skill_tag_by_pk?:Maybe<Common_Skill_Tag>;
  /** delete data from the table: "common.user" */
  delete_common_user?:Maybe<Common_User_Mutation_Response>;
  /** delete single row from the table: "common.user" */
  delete_common_user_by_pk?:Maybe<Common_User>;
  /** delete data from the table: "common.user_role" */
  delete_common_user_role?:Maybe<Common_User_Role_Mutation_Response>;
  /** delete single row from the table: "common.user_role" */
  delete_common_user_role_by_pk?:Maybe<Common_User_Role>;
  /** delete data from the table: "common.user_user_role" */
  delete_common_user_user_role?:Maybe<Common_User_User_Role_Mutation_Response>;
  /** delete single row from the table: "common.user_user_role" */
  delete_common_user_user_role_by_pk?:Maybe<Common_User_User_Role>;
  /** delete data from the table: "hasura_metadata_view" */
  delete_hasura_metadata_view?:Maybe<Hasura_Metadata_View_Mutation_Response>;
  /** delete data from the table: "recruiting.allocation_position" */
  delete_recruiting_allocation_position?:Maybe<Recruiting_Allocation_Position_Mutation_Response>;
  /** delete single row from the table: "recruiting.allocation_position" */
  delete_recruiting_allocation_position_by_pk?:Maybe<Recruiting_Allocation_Position>;
  /** delete data from the table: "recruiting.candidate" */
  delete_recruiting_candidate?:Maybe<Recruiting_Candidate_Mutation_Response>;
  /** delete data from the table: "recruiting.candidate_allocation" */
  delete_recruiting_candidate_allocation?:Maybe<Recruiting_Candidate_Allocation_Mutation_Response>;
  /** delete single row from the table: "recruiting.candidate_allocation" */
  delete_recruiting_candidate_allocation_by_pk?:Maybe<Recruiting_Candidate_Allocation>;
  /** delete data from the table: "recruiting.candidate_attachment" */
  delete_recruiting_candidate_attachment?:Maybe<Recruiting_Candidate_Attachment_Mutation_Response>;
  /** delete single row from the table: "recruiting.candidate_attachment" */
  delete_recruiting_candidate_attachment_by_pk?:Maybe<Recruiting_Candidate_Attachment>;
  /** delete single row from the table: "recruiting.candidate" */
  delete_recruiting_candidate_by_pk?:Maybe<Recruiting_Candidate>;
  /** delete data from the table: "recruiting.candidate_note" */
  delete_recruiting_candidate_note?:Maybe<Recruiting_Candidate_Note_Mutation_Response>;
  /** delete single row from the table: "recruiting.candidate_note" */
  delete_recruiting_candidate_note_by_pk?:Maybe<Recruiting_Candidate_Note>;
  /** delete data from the table: "recruiting.candidate_note_type" */
  delete_recruiting_candidate_note_type?:Maybe<Recruiting_Candidate_Note_Type_Mutation_Response>;
  /** delete single row from the table: "recruiting.candidate_note_type" */
  delete_recruiting_candidate_note_type_by_pk?:Maybe<Recruiting_Candidate_Note_Type>;
  /** delete data from the table: "recruiting.candidate_position_group" */
  delete_recruiting_candidate_position_group?:Maybe<Recruiting_Candidate_Position_Group_Mutation_Response>;
  /** delete single row from the table: "recruiting.candidate_position_group" */
  delete_recruiting_candidate_position_group_by_pk?:Maybe<Recruiting_Candidate_Position_Group>;
  /** delete data from the table: "recruiting.candidate_scheduled_event" */
  delete_recruiting_candidate_scheduled_event?:Maybe<Recruiting_Candidate_Scheduled_Event_Mutation_Response>;
  /** delete single row from the table: "recruiting.candidate_scheduled_event" */
  delete_recruiting_candidate_scheduled_event_by_pk?:Maybe<Recruiting_Candidate_Scheduled_Event>;
  /** delete data from the table: "recruiting.candidate_skill_tag" */
  delete_recruiting_candidate_skill_tag?:Maybe<Recruiting_Candidate_Skill_Tag_Mutation_Response>;
  /** delete single row from the table: "recruiting.candidate_skill_tag" */
  delete_recruiting_candidate_skill_tag_by_pk?:Maybe<Recruiting_Candidate_Skill_Tag>;
  /** delete data from the table: "recruiting.candidate_status" */
  delete_recruiting_candidate_status?:Maybe<Recruiting_Candidate_Status_Mutation_Response>;
  /** delete single row from the table: "recruiting.candidate_status" */
  delete_recruiting_candidate_status_by_pk?:Maybe<Recruiting_Candidate_Status>;
  /** delete data from the table: "recruiting.candidate_status_reason" */
  delete_recruiting_candidate_status_reason?:Maybe<Recruiting_Candidate_Status_Reason_Mutation_Response>;
  /** delete single row from the table: "recruiting.candidate_status_reason" */
  delete_recruiting_candidate_status_reason_by_pk?:Maybe<Recruiting_Candidate_Status_Reason>;
  /** delete data from the table: "recruiting.candidate_user_interviewer" */
  delete_recruiting_candidate_user_interviewer?:Maybe<Recruiting_Candidate_User_Interviewer_Mutation_Response>;
  /** delete single row from the table: "recruiting.candidate_user_interviewer" */
  delete_recruiting_candidate_user_interviewer_by_pk?:Maybe<Recruiting_Candidate_User_Interviewer>;
  /** delete data from the table: "recruiting.interview_flow" */
  delete_recruiting_interview_flow?:Maybe<Recruiting_Interview_Flow_Mutation_Response>;
  /** delete single row from the table: "recruiting.interview_flow" */
  delete_recruiting_interview_flow_by_pk?:Maybe<Recruiting_Interview_Flow>;
  /** delete data from the table: "recruiting.interview_flow_stage" */
  delete_recruiting_interview_flow_stage?:Maybe<Recruiting_Interview_Flow_Stage_Mutation_Response>;
  /** delete single row from the table: "recruiting.interview_flow_stage" */
  delete_recruiting_interview_flow_stage_by_pk?:Maybe<Recruiting_Interview_Flow_Stage>;
  /** delete data from the table: "recruiting.interview_flow_substage" */
  delete_recruiting_interview_flow_substage?:Maybe<Recruiting_Interview_Flow_Substage_Mutation_Response>;
  /** delete single row from the table: "recruiting.interview_flow_substage" */
  delete_recruiting_interview_flow_substage_by_pk?:Maybe<Recruiting_Interview_Flow_Substage>;
  /** delete data from the table: "recruiting.offer" */
  delete_recruiting_offer?:Maybe<Recruiting_Offer_Mutation_Response>;
  /** delete single row from the table: "recruiting.offer" */
  delete_recruiting_offer_by_pk?:Maybe<Recruiting_Offer>;
  /** delete data from the table: "recruiting.offer_template" */
  delete_recruiting_offer_template?:Maybe<Recruiting_Offer_Template_Mutation_Response>;
  /** delete single row from the table: "recruiting.offer_template" */
  delete_recruiting_offer_template_by_pk?:Maybe<Recruiting_Offer_Template>;
  /** delete data from the table: "recruiting.position" */
  delete_recruiting_position?:Maybe<Recruiting_Position_Mutation_Response>;
  /** delete single row from the table: "recruiting.position" */
  delete_recruiting_position_by_pk?:Maybe<Recruiting_Position>;
  /** delete data from the table: "recruiting.position_group" */
  delete_recruiting_position_group?:Maybe<Recruiting_Position_Group_Mutation_Response>;
  /** delete single row from the table: "recruiting.position_group" */
  delete_recruiting_position_group_by_pk?:Maybe<Recruiting_Position_Group>;
  /** delete data from the table: "recruiting.position_group_priority" */
  delete_recruiting_position_group_priority?:Maybe<Recruiting_Position_Group_Priority_Mutation_Response>;
  /** delete single row from the table: "recruiting.position_group_priority" */
  delete_recruiting_position_group_priority_by_pk?:Maybe<Recruiting_Position_Group_Priority>;
  /** delete data from the table: "recruiting.position_template" */
  delete_recruiting_position_template?:Maybe<Recruiting_Position_Template_Mutation_Response>;
  /** delete single row from the table: "recruiting.position_template" */
  delete_recruiting_position_template_by_pk?:Maybe<Recruiting_Position_Template>;
  /** delete data from the table: "staffing.customer_team" */
  delete_staffing_customer_team?:Maybe<Staffing_Customer_Team_Mutation_Response>;
  /** delete single row from the table: "staffing.customer_team" */
  delete_staffing_customer_team_by_pk?:Maybe<Staffing_Customer_Team>;
  /** delete data from the table: "staffing.customer_team_slot" */
  delete_staffing_customer_team_slot?:Maybe<Staffing_Customer_Team_Slot_Mutation_Response>;
  /** delete single row from the table: "staffing.customer_team_slot" */
  delete_staffing_customer_team_slot_by_pk?:Maybe<Staffing_Customer_Team_Slot>;
  /** delete data from the table: "staffing.customer_team_slot_status" */
  delete_staffing_customer_team_slot_status?:Maybe<Staffing_Customer_Team_Slot_Status_Mutation_Response>;
  /** delete single row from the table: "staffing.customer_team_slot_status" */
  delete_staffing_customer_team_slot_status_by_pk?:Maybe<Staffing_Customer_Team_Slot_Status>;
  /** delete data from the table: "staffing.customer_team_tag" */
  delete_staffing_customer_team_tag?:Maybe<Staffing_Customer_Team_Tag_Mutation_Response>;
  /** delete single row from the table: "staffing.customer_team_tag" */
  delete_staffing_customer_team_tag_by_pk?:Maybe<Staffing_Customer_Team_Tag>;
  /** delete data from the table: "staffing.staff" */
  delete_staffing_staff?:Maybe<Staffing_Staff_Mutation_Response>;
  /** delete single row from the table: "staffing.staff" */
  delete_staffing_staff_by_pk?:Maybe<Staffing_Staff>;
  /** delete data from the table: "staffing.staff_role" */
  delete_staffing_staff_role?:Maybe<Staffing_Staff_Role_Mutation_Response>;
  /** delete single row from the table: "staffing.staff_role" */
  delete_staffing_staff_role_by_pk?:Maybe<Staffing_Staff_Role>;
  /** delete data from the table: "staffing.staff_skill_tag" */
  delete_staffing_staff_skill_tag?:Maybe<Staffing_Staff_Skill_Tag_Mutation_Response>;
  /** delete single row from the table: "staffing.staff_skill_tag" */
  delete_staffing_staff_skill_tag_by_pk?:Maybe<Staffing_Staff_Skill_Tag>;
  /** delete data from the table: "staffing.staff_status" */
  delete_staffing_staff_status?:Maybe<Staffing_Staff_Status_Mutation_Response>;
  /** delete single row from the table: "staffing.staff_status" */
  delete_staffing_staff_status_by_pk?:Maybe<Staffing_Staff_Status>;
  /** insert data into the table: "audit.logged_actions" */
  insert_audit_logged_actions?:Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** insert a single row into the table: "audit.logged_actions" */
  insert_audit_logged_actions_one?:Maybe<Audit_Logged_Actions>;
  /** insert data into the table: "common.api_key" */
  insert_common_api_key?:Maybe<Common_Api_Key_Mutation_Response>;
  /** insert a single row into the table: "common.api_key" */
  insert_common_api_key_one?:Maybe<Common_Api_Key>;
  /** insert data into the table: "common.attachment" */
  insert_common_attachment?:Maybe<Common_Attachment_Mutation_Response>;
  /** insert a single row into the table: "common.attachment" */
  insert_common_attachment_one?:Maybe<Common_Attachment>;
  /** insert data into the table: "common.attachment_type" */
  insert_common_attachment_type?:Maybe<Common_Attachment_Type_Mutation_Response>;
  /** insert a single row into the table: "common.attachment_type" */
  insert_common_attachment_type_one?:Maybe<Common_Attachment_Type>;
  /** insert data into the table: "common.city" */
  insert_common_city?:Maybe<Common_City_Mutation_Response>;
  /** insert a single row into the table: "common.city" */
  insert_common_city_one?:Maybe<Common_City>;
  /** insert data into the table: "common.country" */
  insert_common_country?:Maybe<Common_Country_Mutation_Response>;
  /** insert a single row into the table: "common.country" */
  insert_common_country_one?:Maybe<Common_Country>;
  /** insert data into the table: "common.customer" */
  insert_common_customer?:Maybe<Common_Customer_Mutation_Response>;
  /** insert a single row into the table: "common.customer" */
  insert_common_customer_one?:Maybe<Common_Customer>;
  /** insert data into the table: "common.customer_status" */
  insert_common_customer_status?:Maybe<Common_Customer_Status_Mutation_Response>;
  /** insert a single row into the table: "common.customer_status" */
  insert_common_customer_status_one?:Maybe<Common_Customer_Status>;
  /** insert data into the table: "common.filter" */
  insert_common_filter?:Maybe<Common_Filter_Mutation_Response>;
  /** insert a single row into the table: "common.filter" */
  insert_common_filter_one?:Maybe<Common_Filter>;
  /** insert data into the table: "common.filter_type" */
  insert_common_filter_type?:Maybe<Common_Filter_Type_Mutation_Response>;
  /** insert a single row into the table: "common.filter_type" */
  insert_common_filter_type_one?:Maybe<Common_Filter_Type>;
  /** insert data into the table: "common.scheduled_event" */
  insert_common_scheduled_event?:Maybe<Common_Scheduled_Event_Mutation_Response>;
  /** insert a single row into the table: "common.scheduled_event" */
  insert_common_scheduled_event_one?:Maybe<Common_Scheduled_Event>;
  /** insert data into the table: "common.skill_tag" */
  insert_common_skill_tag?:Maybe<Common_Skill_Tag_Mutation_Response>;
  /** insert a single row into the table: "common.skill_tag" */
  insert_common_skill_tag_one?:Maybe<Common_Skill_Tag>;
  /** insert data into the table: "common.user" */
  insert_common_user?:Maybe<Common_User_Mutation_Response>;
  /** insert a single row into the table: "common.user" */
  insert_common_user_one?:Maybe<Common_User>;
  /** insert data into the table: "common.user_role" */
  insert_common_user_role?:Maybe<Common_User_Role_Mutation_Response>;
  /** insert a single row into the table: "common.user_role" */
  insert_common_user_role_one?:Maybe<Common_User_Role>;
  /** insert data into the table: "common.user_user_role" */
  insert_common_user_user_role?:Maybe<Common_User_User_Role_Mutation_Response>;
  /** insert a single row into the table: "common.user_user_role" */
  insert_common_user_user_role_one?:Maybe<Common_User_User_Role>;
  /** insert data into the table: "hasura_metadata_view" */
  insert_hasura_metadata_view?:Maybe<Hasura_Metadata_View_Mutation_Response>;
  /** insert a single row into the table: "hasura_metadata_view" */
  insert_hasura_metadata_view_one?:Maybe<Hasura_Metadata_View>;
  /** insert data into the table: "recruiting.allocation_position" */
  insert_recruiting_allocation_position?:Maybe<Recruiting_Allocation_Position_Mutation_Response>;
  /** insert a single row into the table: "recruiting.allocation_position" */
  insert_recruiting_allocation_position_one?:Maybe<Recruiting_Allocation_Position>;
  /** insert data into the table: "recruiting.candidate" */
  insert_recruiting_candidate?:Maybe<Recruiting_Candidate_Mutation_Response>;
  /** insert data into the table: "recruiting.candidate_allocation" */
  insert_recruiting_candidate_allocation?:Maybe<Recruiting_Candidate_Allocation_Mutation_Response>;
  /** insert a single row into the table: "recruiting.candidate_allocation" */
  insert_recruiting_candidate_allocation_one?:Maybe<Recruiting_Candidate_Allocation>;
  /** insert data into the table: "recruiting.candidate_attachment" */
  insert_recruiting_candidate_attachment?:Maybe<Recruiting_Candidate_Attachment_Mutation_Response>;
  /** insert a single row into the table: "recruiting.candidate_attachment" */
  insert_recruiting_candidate_attachment_one?:Maybe<Recruiting_Candidate_Attachment>;
  /** insert data into the table: "recruiting.candidate_note" */
  insert_recruiting_candidate_note?:Maybe<Recruiting_Candidate_Note_Mutation_Response>;
  /** insert a single row into the table: "recruiting.candidate_note" */
  insert_recruiting_candidate_note_one?:Maybe<Recruiting_Candidate_Note>;
  /** insert data into the table: "recruiting.candidate_note_type" */
  insert_recruiting_candidate_note_type?:Maybe<Recruiting_Candidate_Note_Type_Mutation_Response>;
  /** insert a single row into the table: "recruiting.candidate_note_type" */
  insert_recruiting_candidate_note_type_one?:Maybe<Recruiting_Candidate_Note_Type>;
  /** insert a single row into the table: "recruiting.candidate" */
  insert_recruiting_candidate_one?:Maybe<Recruiting_Candidate>;
  /** insert data into the table: "recruiting.candidate_position_group" */
  insert_recruiting_candidate_position_group?:Maybe<Recruiting_Candidate_Position_Group_Mutation_Response>;
  /** insert a single row into the table: "recruiting.candidate_position_group" */
  insert_recruiting_candidate_position_group_one?:Maybe<Recruiting_Candidate_Position_Group>;
  /** insert data into the table: "recruiting.candidate_scheduled_event" */
  insert_recruiting_candidate_scheduled_event?:Maybe<Recruiting_Candidate_Scheduled_Event_Mutation_Response>;
  /** insert a single row into the table: "recruiting.candidate_scheduled_event" */
  insert_recruiting_candidate_scheduled_event_one?:Maybe<Recruiting_Candidate_Scheduled_Event>;
  /** insert data into the table: "recruiting.candidate_skill_tag" */
  insert_recruiting_candidate_skill_tag?:Maybe<Recruiting_Candidate_Skill_Tag_Mutation_Response>;
  /** insert a single row into the table: "recruiting.candidate_skill_tag" */
  insert_recruiting_candidate_skill_tag_one?:Maybe<Recruiting_Candidate_Skill_Tag>;
  /** insert data into the table: "recruiting.candidate_status" */
  insert_recruiting_candidate_status?:Maybe<Recruiting_Candidate_Status_Mutation_Response>;
  /** insert a single row into the table: "recruiting.candidate_status" */
  insert_recruiting_candidate_status_one?:Maybe<Recruiting_Candidate_Status>;
  /** insert data into the table: "recruiting.candidate_status_reason" */
  insert_recruiting_candidate_status_reason?:Maybe<Recruiting_Candidate_Status_Reason_Mutation_Response>;
  /** insert a single row into the table: "recruiting.candidate_status_reason" */
  insert_recruiting_candidate_status_reason_one?:Maybe<Recruiting_Candidate_Status_Reason>;
  /** insert data into the table: "recruiting.candidate_user_interviewer" */
  insert_recruiting_candidate_user_interviewer?:Maybe<Recruiting_Candidate_User_Interviewer_Mutation_Response>;
  /** insert a single row into the table: "recruiting.candidate_user_interviewer" */
  insert_recruiting_candidate_user_interviewer_one?:Maybe<Recruiting_Candidate_User_Interviewer>;
  /** insert data into the table: "recruiting.interview_flow" */
  insert_recruiting_interview_flow?:Maybe<Recruiting_Interview_Flow_Mutation_Response>;
  /** insert a single row into the table: "recruiting.interview_flow" */
  insert_recruiting_interview_flow_one?:Maybe<Recruiting_Interview_Flow>;
  /** insert data into the table: "recruiting.interview_flow_stage" */
  insert_recruiting_interview_flow_stage?:Maybe<Recruiting_Interview_Flow_Stage_Mutation_Response>;
  /** insert a single row into the table: "recruiting.interview_flow_stage" */
  insert_recruiting_interview_flow_stage_one?:Maybe<Recruiting_Interview_Flow_Stage>;
  /** insert data into the table: "recruiting.interview_flow_substage" */
  insert_recruiting_interview_flow_substage?:Maybe<Recruiting_Interview_Flow_Substage_Mutation_Response>;
  /** insert a single row into the table: "recruiting.interview_flow_substage" */
  insert_recruiting_interview_flow_substage_one?:Maybe<Recruiting_Interview_Flow_Substage>;
  /** insert data into the table: "recruiting.offer" */
  insert_recruiting_offer?:Maybe<Recruiting_Offer_Mutation_Response>;
  /** insert a single row into the table: "recruiting.offer" */
  insert_recruiting_offer_one?:Maybe<Recruiting_Offer>;
  /** insert data into the table: "recruiting.offer_template" */
  insert_recruiting_offer_template?:Maybe<Recruiting_Offer_Template_Mutation_Response>;
  /** insert a single row into the table: "recruiting.offer_template" */
  insert_recruiting_offer_template_one?:Maybe<Recruiting_Offer_Template>;
  /** insert data into the table: "recruiting.position" */
  insert_recruiting_position?:Maybe<Recruiting_Position_Mutation_Response>;
  /** insert data into the table: "recruiting.position_group" */
  insert_recruiting_position_group?:Maybe<Recruiting_Position_Group_Mutation_Response>;
  /** insert a single row into the table: "recruiting.position_group" */
  insert_recruiting_position_group_one?:Maybe<Recruiting_Position_Group>;
  /** insert data into the table: "recruiting.position_group_priority" */
  insert_recruiting_position_group_priority?:Maybe<Recruiting_Position_Group_Priority_Mutation_Response>;
  /** insert a single row into the table: "recruiting.position_group_priority" */
  insert_recruiting_position_group_priority_one?:Maybe<Recruiting_Position_Group_Priority>;
  /** insert a single row into the table: "recruiting.position" */
  insert_recruiting_position_one?:Maybe<Recruiting_Position>;
  /** insert data into the table: "recruiting.position_template" */
  insert_recruiting_position_template?:Maybe<Recruiting_Position_Template_Mutation_Response>;
  /** insert a single row into the table: "recruiting.position_template" */
  insert_recruiting_position_template_one?:Maybe<Recruiting_Position_Template>;
  /** insert data into the table: "staffing.customer_team" */
  insert_staffing_customer_team?:Maybe<Staffing_Customer_Team_Mutation_Response>;
  /** insert a single row into the table: "staffing.customer_team" */
  insert_staffing_customer_team_one?:Maybe<Staffing_Customer_Team>;
  /** insert data into the table: "staffing.customer_team_slot" */
  insert_staffing_customer_team_slot?:Maybe<Staffing_Customer_Team_Slot_Mutation_Response>;
  /** insert a single row into the table: "staffing.customer_team_slot" */
  insert_staffing_customer_team_slot_one?:Maybe<Staffing_Customer_Team_Slot>;
  /** insert data into the table: "staffing.customer_team_slot_status" */
  insert_staffing_customer_team_slot_status?:Maybe<Staffing_Customer_Team_Slot_Status_Mutation_Response>;
  /** insert a single row into the table: "staffing.customer_team_slot_status" */
  insert_staffing_customer_team_slot_status_one?:Maybe<Staffing_Customer_Team_Slot_Status>;
  /** insert data into the table: "staffing.customer_team_tag" */
  insert_staffing_customer_team_tag?:Maybe<Staffing_Customer_Team_Tag_Mutation_Response>;
  /** insert a single row into the table: "staffing.customer_team_tag" */
  insert_staffing_customer_team_tag_one?:Maybe<Staffing_Customer_Team_Tag>;
  /** insert data into the table: "staffing.staff" */
  insert_staffing_staff?:Maybe<Staffing_Staff_Mutation_Response>;
  /** insert a single row into the table: "staffing.staff" */
  insert_staffing_staff_one?:Maybe<Staffing_Staff>;
  /** insert data into the table: "staffing.staff_role" */
  insert_staffing_staff_role?:Maybe<Staffing_Staff_Role_Mutation_Response>;
  /** insert a single row into the table: "staffing.staff_role" */
  insert_staffing_staff_role_one?:Maybe<Staffing_Staff_Role>;
  /** insert data into the table: "staffing.staff_skill_tag" */
  insert_staffing_staff_skill_tag?:Maybe<Staffing_Staff_Skill_Tag_Mutation_Response>;
  /** insert a single row into the table: "staffing.staff_skill_tag" */
  insert_staffing_staff_skill_tag_one?:Maybe<Staffing_Staff_Skill_Tag>;
  /** insert data into the table: "staffing.staff_status" */
  insert_staffing_staff_status?:Maybe<Staffing_Staff_Status_Mutation_Response>;
  /** insert a single row into the table: "staffing.staff_status" */
  insert_staffing_staff_status_one?:Maybe<Staffing_Staff_Status>;
  /** update data of the table: "audit.logged_actions" */
  update_audit_logged_actions?:Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** update single row of the table: "audit.logged_actions" */
  update_audit_logged_actions_by_pk?:Maybe<Audit_Logged_Actions>;
  /** update multiples rows of table: "audit.logged_actions" */
  update_audit_logged_actions_many?:Maybe<Array<Maybe<Audit_Logged_Actions_Mutation_Response>>>;
  /** update data of the table: "common.api_key" */
  update_common_api_key?:Maybe<Common_Api_Key_Mutation_Response>;
  /** update single row of the table: "common.api_key" */
  update_common_api_key_by_pk?:Maybe<Common_Api_Key>;
  /** update multiples rows of table: "common.api_key" */
  update_common_api_key_many?:Maybe<Array<Maybe<Common_Api_Key_Mutation_Response>>>;
  /** update data of the table: "common.attachment" */
  update_common_attachment?:Maybe<Common_Attachment_Mutation_Response>;
  /** update single row of the table: "common.attachment" */
  update_common_attachment_by_pk?:Maybe<Common_Attachment>;
  /** update multiples rows of table: "common.attachment" */
  update_common_attachment_many?:Maybe<Array<Maybe<Common_Attachment_Mutation_Response>>>;
  /** update data of the table: "common.attachment_type" */
  update_common_attachment_type?:Maybe<Common_Attachment_Type_Mutation_Response>;
  /** update single row of the table: "common.attachment_type" */
  update_common_attachment_type_by_pk?:Maybe<Common_Attachment_Type>;
  /** update multiples rows of table: "common.attachment_type" */
  update_common_attachment_type_many?:Maybe<Array<Maybe<Common_Attachment_Type_Mutation_Response>>>;
  /** update data of the table: "common.city" */
  update_common_city?:Maybe<Common_City_Mutation_Response>;
  /** update single row of the table: "common.city" */
  update_common_city_by_pk?:Maybe<Common_City>;
  /** update multiples rows of table: "common.city" */
  update_common_city_many?:Maybe<Array<Maybe<Common_City_Mutation_Response>>>;
  /** update data of the table: "common.country" */
  update_common_country?:Maybe<Common_Country_Mutation_Response>;
  /** update single row of the table: "common.country" */
  update_common_country_by_pk?:Maybe<Common_Country>;
  /** update multiples rows of table: "common.country" */
  update_common_country_many?:Maybe<Array<Maybe<Common_Country_Mutation_Response>>>;
  /** update data of the table: "common.customer" */
  update_common_customer?:Maybe<Common_Customer_Mutation_Response>;
  /** update single row of the table: "common.customer" */
  update_common_customer_by_pk?:Maybe<Common_Customer>;
  /** update multiples rows of table: "common.customer" */
  update_common_customer_many?:Maybe<Array<Maybe<Common_Customer_Mutation_Response>>>;
  /** update data of the table: "common.customer_status" */
  update_common_customer_status?:Maybe<Common_Customer_Status_Mutation_Response>;
  /** update single row of the table: "common.customer_status" */
  update_common_customer_status_by_pk?:Maybe<Common_Customer_Status>;
  /** update multiples rows of table: "common.customer_status" */
  update_common_customer_status_many?:Maybe<Array<Maybe<Common_Customer_Status_Mutation_Response>>>;
  /** update data of the table: "common.filter" */
  update_common_filter?:Maybe<Common_Filter_Mutation_Response>;
  /** update single row of the table: "common.filter" */
  update_common_filter_by_pk?:Maybe<Common_Filter>;
  /** update multiples rows of table: "common.filter" */
  update_common_filter_many?:Maybe<Array<Maybe<Common_Filter_Mutation_Response>>>;
  /** update data of the table: "common.filter_type" */
  update_common_filter_type?:Maybe<Common_Filter_Type_Mutation_Response>;
  /** update single row of the table: "common.filter_type" */
  update_common_filter_type_by_pk?:Maybe<Common_Filter_Type>;
  /** update multiples rows of table: "common.filter_type" */
  update_common_filter_type_many?:Maybe<Array<Maybe<Common_Filter_Type_Mutation_Response>>>;
  /** update data of the table: "common.scheduled_event" */
  update_common_scheduled_event?:Maybe<Common_Scheduled_Event_Mutation_Response>;
  /** update single row of the table: "common.scheduled_event" */
  update_common_scheduled_event_by_pk?:Maybe<Common_Scheduled_Event>;
  /** update multiples rows of table: "common.scheduled_event" */
  update_common_scheduled_event_many?:Maybe<Array<Maybe<Common_Scheduled_Event_Mutation_Response>>>;
  /** update data of the table: "common.skill_tag" */
  update_common_skill_tag?:Maybe<Common_Skill_Tag_Mutation_Response>;
  /** update single row of the table: "common.skill_tag" */
  update_common_skill_tag_by_pk?:Maybe<Common_Skill_Tag>;
  /** update multiples rows of table: "common.skill_tag" */
  update_common_skill_tag_many?:Maybe<Array<Maybe<Common_Skill_Tag_Mutation_Response>>>;
  /** update data of the table: "common.user" */
  update_common_user?:Maybe<Common_User_Mutation_Response>;
  /** update single row of the table: "common.user" */
  update_common_user_by_pk?:Maybe<Common_User>;
  /** update multiples rows of table: "common.user" */
  update_common_user_many?:Maybe<Array<Maybe<Common_User_Mutation_Response>>>;
  /** update data of the table: "common.user_role" */
  update_common_user_role?:Maybe<Common_User_Role_Mutation_Response>;
  /** update single row of the table: "common.user_role" */
  update_common_user_role_by_pk?:Maybe<Common_User_Role>;
  /** update multiples rows of table: "common.user_role" */
  update_common_user_role_many?:Maybe<Array<Maybe<Common_User_Role_Mutation_Response>>>;
  /** update data of the table: "common.user_user_role" */
  update_common_user_user_role?:Maybe<Common_User_User_Role_Mutation_Response>;
  /** update single row of the table: "common.user_user_role" */
  update_common_user_user_role_by_pk?:Maybe<Common_User_User_Role>;
  /** update multiples rows of table: "common.user_user_role" */
  update_common_user_user_role_many?:Maybe<Array<Maybe<Common_User_User_Role_Mutation_Response>>>;
  /** update data of the table: "hasura_metadata_view" */
  update_hasura_metadata_view?:Maybe<Hasura_Metadata_View_Mutation_Response>;
  /** update multiples rows of table: "hasura_metadata_view" */
  update_hasura_metadata_view_many?:Maybe<Array<Maybe<Hasura_Metadata_View_Mutation_Response>>>;
  /** update data of the table: "recruiting.allocation_position" */
  update_recruiting_allocation_position?:Maybe<Recruiting_Allocation_Position_Mutation_Response>;
  /** update single row of the table: "recruiting.allocation_position" */
  update_recruiting_allocation_position_by_pk?:Maybe<Recruiting_Allocation_Position>;
  /** update multiples rows of table: "recruiting.allocation_position" */
  update_recruiting_allocation_position_many?:Maybe<Array<Maybe<Recruiting_Allocation_Position_Mutation_Response>>>;
  /** update data of the table: "recruiting.candidate" */
  update_recruiting_candidate?:Maybe<Recruiting_Candidate_Mutation_Response>;
  /** update data of the table: "recruiting.candidate_allocation" */
  update_recruiting_candidate_allocation?:Maybe<Recruiting_Candidate_Allocation_Mutation_Response>;
  /** update single row of the table: "recruiting.candidate_allocation" */
  update_recruiting_candidate_allocation_by_pk?:Maybe<Recruiting_Candidate_Allocation>;
  /** update multiples rows of table: "recruiting.candidate_allocation" */
  update_recruiting_candidate_allocation_many?:Maybe<Array<Maybe<Recruiting_Candidate_Allocation_Mutation_Response>>>;
  /** update data of the table: "recruiting.candidate_attachment" */
  update_recruiting_candidate_attachment?:Maybe<Recruiting_Candidate_Attachment_Mutation_Response>;
  /** update single row of the table: "recruiting.candidate_attachment" */
  update_recruiting_candidate_attachment_by_pk?:Maybe<Recruiting_Candidate_Attachment>;
  /** update multiples rows of table: "recruiting.candidate_attachment" */
  update_recruiting_candidate_attachment_many?:Maybe<Array<Maybe<Recruiting_Candidate_Attachment_Mutation_Response>>>;
  /** update single row of the table: "recruiting.candidate" */
  update_recruiting_candidate_by_pk?:Maybe<Recruiting_Candidate>;
  /** update multiples rows of table: "recruiting.candidate" */
  update_recruiting_candidate_many?:Maybe<Array<Maybe<Recruiting_Candidate_Mutation_Response>>>;
  /** update data of the table: "recruiting.candidate_note" */
  update_recruiting_candidate_note?:Maybe<Recruiting_Candidate_Note_Mutation_Response>;
  /** update single row of the table: "recruiting.candidate_note" */
  update_recruiting_candidate_note_by_pk?:Maybe<Recruiting_Candidate_Note>;
  /** update multiples rows of table: "recruiting.candidate_note" */
  update_recruiting_candidate_note_many?:Maybe<Array<Maybe<Recruiting_Candidate_Note_Mutation_Response>>>;
  /** update data of the table: "recruiting.candidate_note_type" */
  update_recruiting_candidate_note_type?:Maybe<Recruiting_Candidate_Note_Type_Mutation_Response>;
  /** update single row of the table: "recruiting.candidate_note_type" */
  update_recruiting_candidate_note_type_by_pk?:Maybe<Recruiting_Candidate_Note_Type>;
  /** update multiples rows of table: "recruiting.candidate_note_type" */
  update_recruiting_candidate_note_type_many?:Maybe<Array<Maybe<Recruiting_Candidate_Note_Type_Mutation_Response>>>;
  /** update data of the table: "recruiting.candidate_position_group" */
  update_recruiting_candidate_position_group?:Maybe<Recruiting_Candidate_Position_Group_Mutation_Response>;
  /** update single row of the table: "recruiting.candidate_position_group" */
  update_recruiting_candidate_position_group_by_pk?:Maybe<Recruiting_Candidate_Position_Group>;
  /** update multiples rows of table: "recruiting.candidate_position_group" */
  update_recruiting_candidate_position_group_many?:Maybe<Array<Maybe<Recruiting_Candidate_Position_Group_Mutation_Response>>>;
  /** update data of the table: "recruiting.candidate_scheduled_event" */
  update_recruiting_candidate_scheduled_event?:Maybe<Recruiting_Candidate_Scheduled_Event_Mutation_Response>;
  /** update single row of the table: "recruiting.candidate_scheduled_event" */
  update_recruiting_candidate_scheduled_event_by_pk?:Maybe<Recruiting_Candidate_Scheduled_Event>;
  /** update multiples rows of table: "recruiting.candidate_scheduled_event" */
  update_recruiting_candidate_scheduled_event_many?:Maybe<Array<Maybe<Recruiting_Candidate_Scheduled_Event_Mutation_Response>>>;
  /** update data of the table: "recruiting.candidate_skill_tag" */
  update_recruiting_candidate_skill_tag?:Maybe<Recruiting_Candidate_Skill_Tag_Mutation_Response>;
  /** update single row of the table: "recruiting.candidate_skill_tag" */
  update_recruiting_candidate_skill_tag_by_pk?:Maybe<Recruiting_Candidate_Skill_Tag>;
  /** update multiples rows of table: "recruiting.candidate_skill_tag" */
  update_recruiting_candidate_skill_tag_many?:Maybe<Array<Maybe<Recruiting_Candidate_Skill_Tag_Mutation_Response>>>;
  /** update data of the table: "recruiting.candidate_status" */
  update_recruiting_candidate_status?:Maybe<Recruiting_Candidate_Status_Mutation_Response>;
  /** update single row of the table: "recruiting.candidate_status" */
  update_recruiting_candidate_status_by_pk?:Maybe<Recruiting_Candidate_Status>;
  /** update multiples rows of table: "recruiting.candidate_status" */
  update_recruiting_candidate_status_many?:Maybe<Array<Maybe<Recruiting_Candidate_Status_Mutation_Response>>>;
  /** update data of the table: "recruiting.candidate_status_reason" */
  update_recruiting_candidate_status_reason?:Maybe<Recruiting_Candidate_Status_Reason_Mutation_Response>;
  /** update single row of the table: "recruiting.candidate_status_reason" */
  update_recruiting_candidate_status_reason_by_pk?:Maybe<Recruiting_Candidate_Status_Reason>;
  /** update multiples rows of table: "recruiting.candidate_status_reason" */
  update_recruiting_candidate_status_reason_many?:Maybe<Array<Maybe<Recruiting_Candidate_Status_Reason_Mutation_Response>>>;
  /** update data of the table: "recruiting.candidate_user_interviewer" */
  update_recruiting_candidate_user_interviewer?:Maybe<Recruiting_Candidate_User_Interviewer_Mutation_Response>;
  /** update single row of the table: "recruiting.candidate_user_interviewer" */
  update_recruiting_candidate_user_interviewer_by_pk?:Maybe<Recruiting_Candidate_User_Interviewer>;
  /** update multiples rows of table: "recruiting.candidate_user_interviewer" */
  update_recruiting_candidate_user_interviewer_many?:Maybe<Array<Maybe<Recruiting_Candidate_User_Interviewer_Mutation_Response>>>;
  /** update data of the table: "recruiting.interview_flow" */
  update_recruiting_interview_flow?:Maybe<Recruiting_Interview_Flow_Mutation_Response>;
  /** update single row of the table: "recruiting.interview_flow" */
  update_recruiting_interview_flow_by_pk?:Maybe<Recruiting_Interview_Flow>;
  /** update multiples rows of table: "recruiting.interview_flow" */
  update_recruiting_interview_flow_many?:Maybe<Array<Maybe<Recruiting_Interview_Flow_Mutation_Response>>>;
  /** update data of the table: "recruiting.interview_flow_stage" */
  update_recruiting_interview_flow_stage?:Maybe<Recruiting_Interview_Flow_Stage_Mutation_Response>;
  /** update single row of the table: "recruiting.interview_flow_stage" */
  update_recruiting_interview_flow_stage_by_pk?:Maybe<Recruiting_Interview_Flow_Stage>;
  /** update multiples rows of table: "recruiting.interview_flow_stage" */
  update_recruiting_interview_flow_stage_many?:Maybe<Array<Maybe<Recruiting_Interview_Flow_Stage_Mutation_Response>>>;
  /** update data of the table: "recruiting.interview_flow_substage" */
  update_recruiting_interview_flow_substage?:Maybe<Recruiting_Interview_Flow_Substage_Mutation_Response>;
  /** update single row of the table: "recruiting.interview_flow_substage" */
  update_recruiting_interview_flow_substage_by_pk?:Maybe<Recruiting_Interview_Flow_Substage>;
  /** update multiples rows of table: "recruiting.interview_flow_substage" */
  update_recruiting_interview_flow_substage_many?:Maybe<Array<Maybe<Recruiting_Interview_Flow_Substage_Mutation_Response>>>;
  /** update data of the table: "recruiting.offer" */
  update_recruiting_offer?:Maybe<Recruiting_Offer_Mutation_Response>;
  /** update single row of the table: "recruiting.offer" */
  update_recruiting_offer_by_pk?:Maybe<Recruiting_Offer>;
  /** update multiples rows of table: "recruiting.offer" */
  update_recruiting_offer_many?:Maybe<Array<Maybe<Recruiting_Offer_Mutation_Response>>>;
  /** update data of the table: "recruiting.offer_template" */
  update_recruiting_offer_template?:Maybe<Recruiting_Offer_Template_Mutation_Response>;
  /** update single row of the table: "recruiting.offer_template" */
  update_recruiting_offer_template_by_pk?:Maybe<Recruiting_Offer_Template>;
  /** update multiples rows of table: "recruiting.offer_template" */
  update_recruiting_offer_template_many?:Maybe<Array<Maybe<Recruiting_Offer_Template_Mutation_Response>>>;
  /** update data of the table: "recruiting.position" */
  update_recruiting_position?:Maybe<Recruiting_Position_Mutation_Response>;
  /** update single row of the table: "recruiting.position" */
  update_recruiting_position_by_pk?:Maybe<Recruiting_Position>;
  /** update data of the table: "recruiting.position_group" */
  update_recruiting_position_group?:Maybe<Recruiting_Position_Group_Mutation_Response>;
  /** update single row of the table: "recruiting.position_group" */
  update_recruiting_position_group_by_pk?:Maybe<Recruiting_Position_Group>;
  /** update multiples rows of table: "recruiting.position_group" */
  update_recruiting_position_group_many?:Maybe<Array<Maybe<Recruiting_Position_Group_Mutation_Response>>>;
  /** update data of the table: "recruiting.position_group_priority" */
  update_recruiting_position_group_priority?:Maybe<Recruiting_Position_Group_Priority_Mutation_Response>;
  /** update single row of the table: "recruiting.position_group_priority" */
  update_recruiting_position_group_priority_by_pk?:Maybe<Recruiting_Position_Group_Priority>;
  /** update multiples rows of table: "recruiting.position_group_priority" */
  update_recruiting_position_group_priority_many?:Maybe<Array<Maybe<Recruiting_Position_Group_Priority_Mutation_Response>>>;
  /** update multiples rows of table: "recruiting.position" */
  update_recruiting_position_many?:Maybe<Array<Maybe<Recruiting_Position_Mutation_Response>>>;
  /** update data of the table: "recruiting.position_template" */
  update_recruiting_position_template?:Maybe<Recruiting_Position_Template_Mutation_Response>;
  /** update single row of the table: "recruiting.position_template" */
  update_recruiting_position_template_by_pk?:Maybe<Recruiting_Position_Template>;
  /** update multiples rows of table: "recruiting.position_template" */
  update_recruiting_position_template_many?:Maybe<Array<Maybe<Recruiting_Position_Template_Mutation_Response>>>;
  /** update data of the table: "staffing.customer_team" */
  update_staffing_customer_team?:Maybe<Staffing_Customer_Team_Mutation_Response>;
  /** update single row of the table: "staffing.customer_team" */
  update_staffing_customer_team_by_pk?:Maybe<Staffing_Customer_Team>;
  /** update multiples rows of table: "staffing.customer_team" */
  update_staffing_customer_team_many?:Maybe<Array<Maybe<Staffing_Customer_Team_Mutation_Response>>>;
  /** update data of the table: "staffing.customer_team_slot" */
  update_staffing_customer_team_slot?:Maybe<Staffing_Customer_Team_Slot_Mutation_Response>;
  /** update single row of the table: "staffing.customer_team_slot" */
  update_staffing_customer_team_slot_by_pk?:Maybe<Staffing_Customer_Team_Slot>;
  /** update multiples rows of table: "staffing.customer_team_slot" */
  update_staffing_customer_team_slot_many?:Maybe<Array<Maybe<Staffing_Customer_Team_Slot_Mutation_Response>>>;
  /** update data of the table: "staffing.customer_team_slot_status" */
  update_staffing_customer_team_slot_status?:Maybe<Staffing_Customer_Team_Slot_Status_Mutation_Response>;
  /** update single row of the table: "staffing.customer_team_slot_status" */
  update_staffing_customer_team_slot_status_by_pk?:Maybe<Staffing_Customer_Team_Slot_Status>;
  /** update multiples rows of table: "staffing.customer_team_slot_status" */
  update_staffing_customer_team_slot_status_many?:Maybe<Array<Maybe<Staffing_Customer_Team_Slot_Status_Mutation_Response>>>;
  /** update data of the table: "staffing.customer_team_tag" */
  update_staffing_customer_team_tag?:Maybe<Staffing_Customer_Team_Tag_Mutation_Response>;
  /** update single row of the table: "staffing.customer_team_tag" */
  update_staffing_customer_team_tag_by_pk?:Maybe<Staffing_Customer_Team_Tag>;
  /** update multiples rows of table: "staffing.customer_team_tag" */
  update_staffing_customer_team_tag_many?:Maybe<Array<Maybe<Staffing_Customer_Team_Tag_Mutation_Response>>>;
  /** update data of the table: "staffing.staff" */
  update_staffing_staff?:Maybe<Staffing_Staff_Mutation_Response>;
  /** update single row of the table: "staffing.staff" */
  update_staffing_staff_by_pk?:Maybe<Staffing_Staff>;
  /** update multiples rows of table: "staffing.staff" */
  update_staffing_staff_many?:Maybe<Array<Maybe<Staffing_Staff_Mutation_Response>>>;
  /** update data of the table: "staffing.staff_role" */
  update_staffing_staff_role?:Maybe<Staffing_Staff_Role_Mutation_Response>;
  /** update single row of the table: "staffing.staff_role" */
  update_staffing_staff_role_by_pk?:Maybe<Staffing_Staff_Role>;
  /** update multiples rows of table: "staffing.staff_role" */
  update_staffing_staff_role_many?:Maybe<Array<Maybe<Staffing_Staff_Role_Mutation_Response>>>;
  /** update data of the table: "staffing.staff_skill_tag" */
  update_staffing_staff_skill_tag?:Maybe<Staffing_Staff_Skill_Tag_Mutation_Response>;
  /** update single row of the table: "staffing.staff_skill_tag" */
  update_staffing_staff_skill_tag_by_pk?:Maybe<Staffing_Staff_Skill_Tag>;
  /** update multiples rows of table: "staffing.staff_skill_tag" */
  update_staffing_staff_skill_tag_many?:Maybe<Array<Maybe<Staffing_Staff_Skill_Tag_Mutation_Response>>>;
  /** update data of the table: "staffing.staff_status" */
  update_staffing_staff_status?:Maybe<Staffing_Staff_Status_Mutation_Response>;
  /** update single row of the table: "staffing.staff_status" */
  update_staffing_staff_status_by_pk?:Maybe<Staffing_Staff_Status>;
  /** update multiples rows of table: "staffing.staff_status" */
  update_staffing_staff_status_many?:Maybe<Array<Maybe<Staffing_Staff_Status_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootAction_Candidate_Fetch_Linkedin_ImageArgs = {
  candidateId:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootAction_Candidate_Upload_CvArgs = {
  candidateId:Scalars['Int'];
  fileContentBase64:Scalars['String'];
  mimeType:Scalars['String'];
};


/** mutation root */
export type Mutation_RootAction_Customer_Team_Slot_CloseArgs = {
  slotId:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootAction_LoginArgs = {
  code:Scalars['String'];
};


/** mutation root */
export type Mutation_RootAction_Login_TestArgs = {
  code:Scalars['String'];
};


/** mutation root */
export type Mutation_RootAction_Position_CreateArgs = {
  alias?:InputMaybe<Scalars['String']>;
  customerTeamId:Scalars['Int'];
  positionGroupId?:InputMaybe<Scalars['Int']>;
  positionTemplateId:Scalars['Int'];
  quantity:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootAction_Position_DeleteArgs = {
  positionId:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootAction_Position_Update_QuantityArgs = {
  positionId:Scalars['Int'];
  quantity:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootAction_Positions_Batch_CrudArgs = {
  request?:InputMaybe<PositionsBatchCrudRequest>;
};


/** mutation root */
export type Mutation_RootAction_Positions_CreateArgs = {
  positions:Array<CreatePositionInput>;
};


/** mutation root */
export type Mutation_RootAction_Positions_DeleteArgs = {
  positions:Array<DeletePositionRequest>;
};


/** mutation root */
export type Mutation_RootAction_Positions_Update_QuantityArgs = {
  positions:Array<UpdatePositionQuantityInput>;
};


/** mutation root */
export type Mutation_RootAction_Schedule_EventArgs = {
  scheduledEventId:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootAction_Schedule_Event_CancelArgs = {
  scheduledEventId:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Audit_Logged_ActionsArgs = {
  where:Audit_Logged_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audit_Logged_Actions_By_PkArgs = {
  event_id:Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Common_Api_KeyArgs = {
  where:Common_Api_Key_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_Api_Key_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Common_AttachmentArgs = {
  where:Common_Attachment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_Attachment_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Common_Attachment_TypeArgs = {
  where:Common_Attachment_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_Attachment_Type_By_PkArgs = {
  type:Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Common_CityArgs = {
  where:Common_City_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_City_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Common_CountryArgs = {
  where:Common_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_Country_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Common_CustomerArgs = {
  where:Common_Customer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_Customer_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Common_Customer_StatusArgs = {
  where:Common_Customer_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_Customer_Status_By_PkArgs = {
  status:Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Common_FilterArgs = {
  where:Common_Filter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_Filter_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Common_Filter_TypeArgs = {
  where:Common_Filter_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_Filter_Type_By_PkArgs = {
  type:Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Common_Scheduled_EventArgs = {
  where:Common_Scheduled_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_Scheduled_Event_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Common_Skill_TagArgs = {
  where:Common_Skill_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_Skill_Tag_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Common_UserArgs = {
  where:Common_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_User_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Common_User_RoleArgs = {
  where:Common_User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_User_Role_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Common_User_User_RoleArgs = {
  where:Common_User_User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Common_User_User_Role_By_PkArgs = {
  user_id:Scalars['Int'];
  user_role_id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Hasura_Metadata_ViewArgs = {
  where:Hasura_Metadata_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Allocation_PositionArgs = {
  where:Recruiting_Allocation_Position_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Allocation_Position_By_PkArgs = {
  allocation_id:Scalars['Int'];
  position_id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_CandidateArgs = {
  where:Recruiting_Candidate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_AllocationArgs = {
  where:Recruiting_Candidate_Allocation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_Allocation_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_AttachmentArgs = {
  where:Recruiting_Candidate_Attachment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_Attachment_By_PkArgs = {
  attachment_id:Scalars['Int'];
  candidate_id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_NoteArgs = {
  where:Recruiting_Candidate_Note_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_Note_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_Note_TypeArgs = {
  where:Recruiting_Candidate_Note_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_Note_Type_By_PkArgs = {
  type:Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_Position_GroupArgs = {
  where:Recruiting_Candidate_Position_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_Position_Group_By_PkArgs = {
  candidate_id:Scalars['Int'];
  position_group_id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_Scheduled_EventArgs = {
  where:Recruiting_Candidate_Scheduled_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_Scheduled_Event_By_PkArgs = {
  candidate_id:Scalars['Int'];
  scheduled_call_id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_Skill_TagArgs = {
  where:Recruiting_Candidate_Skill_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_Skill_Tag_By_PkArgs = {
  candidate_id:Scalars['Int'];
  skill_tag_id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_StatusArgs = {
  where:Recruiting_Candidate_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_Status_By_PkArgs = {
  status:Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_Status_ReasonArgs = {
  where:Recruiting_Candidate_Status_Reason_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_Status_Reason_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_User_InterviewerArgs = {
  where:Recruiting_Candidate_User_Interviewer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Candidate_User_Interviewer_By_PkArgs = {
  candidate_id:Scalars['Int'];
  user_id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Interview_FlowArgs = {
  where:Recruiting_Interview_Flow_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Interview_Flow_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Interview_Flow_StageArgs = {
  where:Recruiting_Interview_Flow_Stage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Interview_Flow_Stage_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Interview_Flow_SubstageArgs = {
  where:Recruiting_Interview_Flow_Substage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Interview_Flow_Substage_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_OfferArgs = {
  where:Recruiting_Offer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Offer_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Offer_TemplateArgs = {
  where:Recruiting_Offer_Template_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Offer_Template_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_PositionArgs = {
  where:Recruiting_Position_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Position_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Position_GroupArgs = {
  where:Recruiting_Position_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Position_Group_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Position_Group_PriorityArgs = {
  where:Recruiting_Position_Group_Priority_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Position_Group_Priority_By_PkArgs = {
  position_group_id:Scalars['Int'];
  user_id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Position_TemplateArgs = {
  where:Recruiting_Position_Template_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recruiting_Position_Template_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Staffing_Customer_TeamArgs = {
  where:Staffing_Customer_Team_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Staffing_Customer_Team_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Staffing_Customer_Team_SlotArgs = {
  where:Staffing_Customer_Team_Slot_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Staffing_Customer_Team_Slot_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Staffing_Customer_Team_Slot_StatusArgs = {
  where:Staffing_Customer_Team_Slot_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Staffing_Customer_Team_Slot_Status_By_PkArgs = {
  status:Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Staffing_Customer_Team_TagArgs = {
  where:Staffing_Customer_Team_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Staffing_Customer_Team_Tag_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Staffing_StaffArgs = {
  where:Staffing_Staff_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Staffing_Staff_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Staffing_Staff_RoleArgs = {
  where:Staffing_Staff_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Staffing_Staff_Role_By_PkArgs = {
  id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Staffing_Staff_Skill_TagArgs = {
  where:Staffing_Staff_Skill_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Staffing_Staff_Skill_Tag_By_PkArgs = {
  skill_tag_id:Scalars['Int'];
  staff_id:Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Staffing_Staff_StatusArgs = {
  where:Staffing_Staff_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Staffing_Staff_Status_By_PkArgs = {
  status:Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Audit_Logged_ActionsArgs = {
  objects:Array<Audit_Logged_Actions_Insert_Input>;
  on_conflict?:InputMaybe<Audit_Logged_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audit_Logged_Actions_OneArgs = {
  object:Audit_Logged_Actions_Insert_Input;
  on_conflict?:InputMaybe<Audit_Logged_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Api_KeyArgs = {
  objects:Array<Common_Api_Key_Insert_Input>;
  on_conflict?:InputMaybe<Common_Api_Key_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Api_Key_OneArgs = {
  object:Common_Api_Key_Insert_Input;
  on_conflict?:InputMaybe<Common_Api_Key_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_AttachmentArgs = {
  objects:Array<Common_Attachment_Insert_Input>;
  on_conflict?:InputMaybe<Common_Attachment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Attachment_OneArgs = {
  object:Common_Attachment_Insert_Input;
  on_conflict?:InputMaybe<Common_Attachment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Attachment_TypeArgs = {
  objects:Array<Common_Attachment_Type_Insert_Input>;
  on_conflict?:InputMaybe<Common_Attachment_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Attachment_Type_OneArgs = {
  object:Common_Attachment_Type_Insert_Input;
  on_conflict?:InputMaybe<Common_Attachment_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_CityArgs = {
  objects:Array<Common_City_Insert_Input>;
  on_conflict?:InputMaybe<Common_City_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_City_OneArgs = {
  object:Common_City_Insert_Input;
  on_conflict?:InputMaybe<Common_City_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_CountryArgs = {
  objects:Array<Common_Country_Insert_Input>;
  on_conflict?:InputMaybe<Common_Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Country_OneArgs = {
  object:Common_Country_Insert_Input;
  on_conflict?:InputMaybe<Common_Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_CustomerArgs = {
  objects:Array<Common_Customer_Insert_Input>;
  on_conflict?:InputMaybe<Common_Customer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Customer_OneArgs = {
  object:Common_Customer_Insert_Input;
  on_conflict?:InputMaybe<Common_Customer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Customer_StatusArgs = {
  objects:Array<Common_Customer_Status_Insert_Input>;
  on_conflict?:InputMaybe<Common_Customer_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Customer_Status_OneArgs = {
  object:Common_Customer_Status_Insert_Input;
  on_conflict?:InputMaybe<Common_Customer_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_FilterArgs = {
  objects:Array<Common_Filter_Insert_Input>;
  on_conflict?:InputMaybe<Common_Filter_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Filter_OneArgs = {
  object:Common_Filter_Insert_Input;
  on_conflict?:InputMaybe<Common_Filter_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Filter_TypeArgs = {
  objects:Array<Common_Filter_Type_Insert_Input>;
  on_conflict?:InputMaybe<Common_Filter_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Filter_Type_OneArgs = {
  object:Common_Filter_Type_Insert_Input;
  on_conflict?:InputMaybe<Common_Filter_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Scheduled_EventArgs = {
  objects:Array<Common_Scheduled_Event_Insert_Input>;
  on_conflict?:InputMaybe<Common_Scheduled_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Scheduled_Event_OneArgs = {
  object:Common_Scheduled_Event_Insert_Input;
  on_conflict?:InputMaybe<Common_Scheduled_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Skill_TagArgs = {
  objects:Array<Common_Skill_Tag_Insert_Input>;
  on_conflict?:InputMaybe<Common_Skill_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_Skill_Tag_OneArgs = {
  object:Common_Skill_Tag_Insert_Input;
  on_conflict?:InputMaybe<Common_Skill_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_UserArgs = {
  objects:Array<Common_User_Insert_Input>;
  on_conflict?:InputMaybe<Common_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_User_OneArgs = {
  object:Common_User_Insert_Input;
  on_conflict?:InputMaybe<Common_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_User_RoleArgs = {
  objects:Array<Common_User_Role_Insert_Input>;
  on_conflict?:InputMaybe<Common_User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_User_Role_OneArgs = {
  object:Common_User_Role_Insert_Input;
  on_conflict?:InputMaybe<Common_User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_User_User_RoleArgs = {
  objects:Array<Common_User_User_Role_Insert_Input>;
  on_conflict?:InputMaybe<Common_User_User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Common_User_User_Role_OneArgs = {
  object:Common_User_User_Role_Insert_Input;
  on_conflict?:InputMaybe<Common_User_User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hasura_Metadata_ViewArgs = {
  objects:Array<Hasura_Metadata_View_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Hasura_Metadata_View_OneArgs = {
  object:Hasura_Metadata_View_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Allocation_PositionArgs = {
  objects:Array<Recruiting_Allocation_Position_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Allocation_Position_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Allocation_Position_OneArgs = {
  object:Recruiting_Allocation_Position_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Allocation_Position_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_CandidateArgs = {
  objects:Array<Recruiting_Candidate_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Candidate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_AllocationArgs = {
  objects:Array<Recruiting_Candidate_Allocation_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Candidate_Allocation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_Allocation_OneArgs = {
  object:Recruiting_Candidate_Allocation_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Candidate_Allocation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_AttachmentArgs = {
  objects:Array<Recruiting_Candidate_Attachment_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Candidate_Attachment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_Attachment_OneArgs = {
  object:Recruiting_Candidate_Attachment_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Candidate_Attachment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_NoteArgs = {
  objects:Array<Recruiting_Candidate_Note_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Candidate_Note_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_Note_OneArgs = {
  object:Recruiting_Candidate_Note_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Candidate_Note_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_Note_TypeArgs = {
  objects:Array<Recruiting_Candidate_Note_Type_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Candidate_Note_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_Note_Type_OneArgs = {
  object:Recruiting_Candidate_Note_Type_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Candidate_Note_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_OneArgs = {
  object:Recruiting_Candidate_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Candidate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_Position_GroupArgs = {
  objects:Array<Recruiting_Candidate_Position_Group_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Candidate_Position_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_Position_Group_OneArgs = {
  object:Recruiting_Candidate_Position_Group_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Candidate_Position_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_Scheduled_EventArgs = {
  objects:Array<Recruiting_Candidate_Scheduled_Event_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Candidate_Scheduled_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_Scheduled_Event_OneArgs = {
  object:Recruiting_Candidate_Scheduled_Event_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Candidate_Scheduled_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_Skill_TagArgs = {
  objects:Array<Recruiting_Candidate_Skill_Tag_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Candidate_Skill_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_Skill_Tag_OneArgs = {
  object:Recruiting_Candidate_Skill_Tag_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Candidate_Skill_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_StatusArgs = {
  objects:Array<Recruiting_Candidate_Status_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Candidate_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_Status_OneArgs = {
  object:Recruiting_Candidate_Status_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Candidate_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_Status_ReasonArgs = {
  objects:Array<Recruiting_Candidate_Status_Reason_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Candidate_Status_Reason_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_Status_Reason_OneArgs = {
  object:Recruiting_Candidate_Status_Reason_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Candidate_Status_Reason_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_User_InterviewerArgs = {
  objects:Array<Recruiting_Candidate_User_Interviewer_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Candidate_User_Interviewer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Candidate_User_Interviewer_OneArgs = {
  object:Recruiting_Candidate_User_Interviewer_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Candidate_User_Interviewer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Interview_FlowArgs = {
  objects:Array<Recruiting_Interview_Flow_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Interview_Flow_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Interview_Flow_OneArgs = {
  object:Recruiting_Interview_Flow_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Interview_Flow_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Interview_Flow_StageArgs = {
  objects:Array<Recruiting_Interview_Flow_Stage_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Interview_Flow_Stage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Interview_Flow_Stage_OneArgs = {
  object:Recruiting_Interview_Flow_Stage_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Interview_Flow_Stage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Interview_Flow_SubstageArgs = {
  objects:Array<Recruiting_Interview_Flow_Substage_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Interview_Flow_Substage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Interview_Flow_Substage_OneArgs = {
  object:Recruiting_Interview_Flow_Substage_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Interview_Flow_Substage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_OfferArgs = {
  objects:Array<Recruiting_Offer_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Offer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Offer_OneArgs = {
  object:Recruiting_Offer_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Offer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Offer_TemplateArgs = {
  objects:Array<Recruiting_Offer_Template_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Offer_Template_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Offer_Template_OneArgs = {
  object:Recruiting_Offer_Template_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Offer_Template_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_PositionArgs = {
  objects:Array<Recruiting_Position_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Position_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Position_GroupArgs = {
  objects:Array<Recruiting_Position_Group_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Position_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Position_Group_OneArgs = {
  object:Recruiting_Position_Group_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Position_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Position_Group_PriorityArgs = {
  objects:Array<Recruiting_Position_Group_Priority_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Position_Group_Priority_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Position_Group_Priority_OneArgs = {
  object:Recruiting_Position_Group_Priority_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Position_Group_Priority_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Position_OneArgs = {
  object:Recruiting_Position_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Position_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Position_TemplateArgs = {
  objects:Array<Recruiting_Position_Template_Insert_Input>;
  on_conflict?:InputMaybe<Recruiting_Position_Template_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recruiting_Position_Template_OneArgs = {
  object:Recruiting_Position_Template_Insert_Input;
  on_conflict?:InputMaybe<Recruiting_Position_Template_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_Customer_TeamArgs = {
  objects:Array<Staffing_Customer_Team_Insert_Input>;
  on_conflict?:InputMaybe<Staffing_Customer_Team_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_Customer_Team_OneArgs = {
  object:Staffing_Customer_Team_Insert_Input;
  on_conflict?:InputMaybe<Staffing_Customer_Team_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_Customer_Team_SlotArgs = {
  objects:Array<Staffing_Customer_Team_Slot_Insert_Input>;
  on_conflict?:InputMaybe<Staffing_Customer_Team_Slot_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_Customer_Team_Slot_OneArgs = {
  object:Staffing_Customer_Team_Slot_Insert_Input;
  on_conflict?:InputMaybe<Staffing_Customer_Team_Slot_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_Customer_Team_Slot_StatusArgs = {
  objects:Array<Staffing_Customer_Team_Slot_Status_Insert_Input>;
  on_conflict?:InputMaybe<Staffing_Customer_Team_Slot_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_Customer_Team_Slot_Status_OneArgs = {
  object:Staffing_Customer_Team_Slot_Status_Insert_Input;
  on_conflict?:InputMaybe<Staffing_Customer_Team_Slot_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_Customer_Team_TagArgs = {
  objects:Array<Staffing_Customer_Team_Tag_Insert_Input>;
  on_conflict?:InputMaybe<Staffing_Customer_Team_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_Customer_Team_Tag_OneArgs = {
  object:Staffing_Customer_Team_Tag_Insert_Input;
  on_conflict?:InputMaybe<Staffing_Customer_Team_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_StaffArgs = {
  objects:Array<Staffing_Staff_Insert_Input>;
  on_conflict?:InputMaybe<Staffing_Staff_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_Staff_OneArgs = {
  object:Staffing_Staff_Insert_Input;
  on_conflict?:InputMaybe<Staffing_Staff_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_Staff_RoleArgs = {
  objects:Array<Staffing_Staff_Role_Insert_Input>;
  on_conflict?:InputMaybe<Staffing_Staff_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_Staff_Role_OneArgs = {
  object:Staffing_Staff_Role_Insert_Input;
  on_conflict?:InputMaybe<Staffing_Staff_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_Staff_Skill_TagArgs = {
  objects:Array<Staffing_Staff_Skill_Tag_Insert_Input>;
  on_conflict?:InputMaybe<Staffing_Staff_Skill_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_Staff_Skill_Tag_OneArgs = {
  object:Staffing_Staff_Skill_Tag_Insert_Input;
  on_conflict?:InputMaybe<Staffing_Staff_Skill_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_Staff_StatusArgs = {
  objects:Array<Staffing_Staff_Status_Insert_Input>;
  on_conflict?:InputMaybe<Staffing_Staff_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Staffing_Staff_Status_OneArgs = {
  object:Staffing_Staff_Status_Insert_Input;
  on_conflict?:InputMaybe<Staffing_Staff_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_ActionsArgs = {
  _append?:InputMaybe<Audit_Logged_Actions_Append_Input>;
  _delete_at_path?:InputMaybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  _delete_elem?:InputMaybe<Audit_Logged_Actions_Delete_Elem_Input>;
  _delete_key?:InputMaybe<Audit_Logged_Actions_Delete_Key_Input>;
  _inc?:InputMaybe<Audit_Logged_Actions_Inc_Input>;
  _prepend?:InputMaybe<Audit_Logged_Actions_Prepend_Input>;
  _set?:InputMaybe<Audit_Logged_Actions_Set_Input>;
  where:Audit_Logged_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_Actions_By_PkArgs = {
  _append?:InputMaybe<Audit_Logged_Actions_Append_Input>;
  _delete_at_path?:InputMaybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  _delete_elem?:InputMaybe<Audit_Logged_Actions_Delete_Elem_Input>;
  _delete_key?:InputMaybe<Audit_Logged_Actions_Delete_Key_Input>;
  _inc?:InputMaybe<Audit_Logged_Actions_Inc_Input>;
  _prepend?:InputMaybe<Audit_Logged_Actions_Prepend_Input>;
  _set?:InputMaybe<Audit_Logged_Actions_Set_Input>;
  pk_columns:Audit_Logged_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_Actions_ManyArgs = {
  updates:Array<Audit_Logged_Actions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Api_KeyArgs = {
  _inc?:InputMaybe<Common_Api_Key_Inc_Input>;
  _set?:InputMaybe<Common_Api_Key_Set_Input>;
  where:Common_Api_Key_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Api_Key_By_PkArgs = {
  _inc?:InputMaybe<Common_Api_Key_Inc_Input>;
  _set?:InputMaybe<Common_Api_Key_Set_Input>;
  pk_columns:Common_Api_Key_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Api_Key_ManyArgs = {
  updates:Array<Common_Api_Key_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Common_AttachmentArgs = {
  _inc?:InputMaybe<Common_Attachment_Inc_Input>;
  _set?:InputMaybe<Common_Attachment_Set_Input>;
  where:Common_Attachment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Attachment_By_PkArgs = {
  _inc?:InputMaybe<Common_Attachment_Inc_Input>;
  _set?:InputMaybe<Common_Attachment_Set_Input>;
  pk_columns:Common_Attachment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Attachment_ManyArgs = {
  updates:Array<Common_Attachment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Attachment_TypeArgs = {
  _set?:InputMaybe<Common_Attachment_Type_Set_Input>;
  where:Common_Attachment_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Attachment_Type_By_PkArgs = {
  _set?:InputMaybe<Common_Attachment_Type_Set_Input>;
  pk_columns:Common_Attachment_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Attachment_Type_ManyArgs = {
  updates:Array<Common_Attachment_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Common_CityArgs = {
  _inc?:InputMaybe<Common_City_Inc_Input>;
  _set?:InputMaybe<Common_City_Set_Input>;
  where:Common_City_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_City_By_PkArgs = {
  _inc?:InputMaybe<Common_City_Inc_Input>;
  _set?:InputMaybe<Common_City_Set_Input>;
  pk_columns:Common_City_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_City_ManyArgs = {
  updates:Array<Common_City_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Common_CountryArgs = {
  _inc?:InputMaybe<Common_Country_Inc_Input>;
  _set?:InputMaybe<Common_Country_Set_Input>;
  where:Common_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Country_By_PkArgs = {
  _inc?:InputMaybe<Common_Country_Inc_Input>;
  _set?:InputMaybe<Common_Country_Set_Input>;
  pk_columns:Common_Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Country_ManyArgs = {
  updates:Array<Common_Country_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Common_CustomerArgs = {
  _inc?:InputMaybe<Common_Customer_Inc_Input>;
  _set?:InputMaybe<Common_Customer_Set_Input>;
  where:Common_Customer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Customer_By_PkArgs = {
  _inc?:InputMaybe<Common_Customer_Inc_Input>;
  _set?:InputMaybe<Common_Customer_Set_Input>;
  pk_columns:Common_Customer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Customer_ManyArgs = {
  updates:Array<Common_Customer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Customer_StatusArgs = {
  _set?:InputMaybe<Common_Customer_Status_Set_Input>;
  where:Common_Customer_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Customer_Status_By_PkArgs = {
  _set?:InputMaybe<Common_Customer_Status_Set_Input>;
  pk_columns:Common_Customer_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Customer_Status_ManyArgs = {
  updates:Array<Common_Customer_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Common_FilterArgs = {
  _inc?:InputMaybe<Common_Filter_Inc_Input>;
  _set?:InputMaybe<Common_Filter_Set_Input>;
  where:Common_Filter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Filter_By_PkArgs = {
  _inc?:InputMaybe<Common_Filter_Inc_Input>;
  _set?:InputMaybe<Common_Filter_Set_Input>;
  pk_columns:Common_Filter_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Filter_ManyArgs = {
  updates:Array<Common_Filter_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Filter_TypeArgs = {
  _set?:InputMaybe<Common_Filter_Type_Set_Input>;
  where:Common_Filter_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Filter_Type_By_PkArgs = {
  _set?:InputMaybe<Common_Filter_Type_Set_Input>;
  pk_columns:Common_Filter_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Filter_Type_ManyArgs = {
  updates:Array<Common_Filter_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Scheduled_EventArgs = {
  _inc?:InputMaybe<Common_Scheduled_Event_Inc_Input>;
  _set?:InputMaybe<Common_Scheduled_Event_Set_Input>;
  where:Common_Scheduled_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Scheduled_Event_By_PkArgs = {
  _inc?:InputMaybe<Common_Scheduled_Event_Inc_Input>;
  _set?:InputMaybe<Common_Scheduled_Event_Set_Input>;
  pk_columns:Common_Scheduled_Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Scheduled_Event_ManyArgs = {
  updates:Array<Common_Scheduled_Event_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Skill_TagArgs = {
  _inc?:InputMaybe<Common_Skill_Tag_Inc_Input>;
  _set?:InputMaybe<Common_Skill_Tag_Set_Input>;
  where:Common_Skill_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Skill_Tag_By_PkArgs = {
  _inc?:InputMaybe<Common_Skill_Tag_Inc_Input>;
  _set?:InputMaybe<Common_Skill_Tag_Set_Input>;
  pk_columns:Common_Skill_Tag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_Skill_Tag_ManyArgs = {
  updates:Array<Common_Skill_Tag_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Common_UserArgs = {
  _inc?:InputMaybe<Common_User_Inc_Input>;
  _set?:InputMaybe<Common_User_Set_Input>;
  where:Common_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_User_By_PkArgs = {
  _inc?:InputMaybe<Common_User_Inc_Input>;
  _set?:InputMaybe<Common_User_Set_Input>;
  pk_columns:Common_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_User_ManyArgs = {
  updates:Array<Common_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Common_User_RoleArgs = {
  _inc?:InputMaybe<Common_User_Role_Inc_Input>;
  _set?:InputMaybe<Common_User_Role_Set_Input>;
  where:Common_User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_User_Role_By_PkArgs = {
  _inc?:InputMaybe<Common_User_Role_Inc_Input>;
  _set?:InputMaybe<Common_User_Role_Set_Input>;
  pk_columns:Common_User_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_User_Role_ManyArgs = {
  updates:Array<Common_User_Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Common_User_User_RoleArgs = {
  _inc?:InputMaybe<Common_User_User_Role_Inc_Input>;
  _set?:InputMaybe<Common_User_User_Role_Set_Input>;
  where:Common_User_User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Common_User_User_Role_By_PkArgs = {
  _inc?:InputMaybe<Common_User_User_Role_Inc_Input>;
  _set?:InputMaybe<Common_User_User_Role_Set_Input>;
  pk_columns:Common_User_User_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Common_User_User_Role_ManyArgs = {
  updates:Array<Common_User_User_Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Hasura_Metadata_ViewArgs = {
  _inc?:InputMaybe<Hasura_Metadata_View_Inc_Input>;
  _set?:InputMaybe<Hasura_Metadata_View_Set_Input>;
  where:Hasura_Metadata_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hasura_Metadata_View_ManyArgs = {
  updates:Array<Hasura_Metadata_View_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Allocation_PositionArgs = {
  _inc?:InputMaybe<Recruiting_Allocation_Position_Inc_Input>;
  _set?:InputMaybe<Recruiting_Allocation_Position_Set_Input>;
  where:Recruiting_Allocation_Position_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Allocation_Position_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Allocation_Position_Inc_Input>;
  _set?:InputMaybe<Recruiting_Allocation_Position_Set_Input>;
  pk_columns:Recruiting_Allocation_Position_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Allocation_Position_ManyArgs = {
  updates:Array<Recruiting_Allocation_Position_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_CandidateArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_Set_Input>;
  where:Recruiting_Candidate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_AllocationArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_Allocation_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_Allocation_Set_Input>;
  where:Recruiting_Candidate_Allocation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Allocation_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_Allocation_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_Allocation_Set_Input>;
  pk_columns:Recruiting_Candidate_Allocation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Allocation_ManyArgs = {
  updates:Array<Recruiting_Candidate_Allocation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_AttachmentArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_Attachment_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_Attachment_Set_Input>;
  where:Recruiting_Candidate_Attachment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Attachment_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_Attachment_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_Attachment_Set_Input>;
  pk_columns:Recruiting_Candidate_Attachment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Attachment_ManyArgs = {
  updates:Array<Recruiting_Candidate_Attachment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_Set_Input>;
  pk_columns:Recruiting_Candidate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_ManyArgs = {
  updates:Array<Recruiting_Candidate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_NoteArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_Note_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_Note_Set_Input>;
  where:Recruiting_Candidate_Note_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Note_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_Note_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_Note_Set_Input>;
  pk_columns:Recruiting_Candidate_Note_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Note_ManyArgs = {
  updates:Array<Recruiting_Candidate_Note_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Note_TypeArgs = {
  _set?:InputMaybe<Recruiting_Candidate_Note_Type_Set_Input>;
  where:Recruiting_Candidate_Note_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Note_Type_By_PkArgs = {
  _set?:InputMaybe<Recruiting_Candidate_Note_Type_Set_Input>;
  pk_columns:Recruiting_Candidate_Note_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Note_Type_ManyArgs = {
  updates:Array<Recruiting_Candidate_Note_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Position_GroupArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_Position_Group_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_Position_Group_Set_Input>;
  where:Recruiting_Candidate_Position_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Position_Group_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_Position_Group_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_Position_Group_Set_Input>;
  pk_columns:Recruiting_Candidate_Position_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Position_Group_ManyArgs = {
  updates:Array<Recruiting_Candidate_Position_Group_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Scheduled_EventArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Set_Input>;
  where:Recruiting_Candidate_Scheduled_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Scheduled_Event_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Set_Input>;
  pk_columns:Recruiting_Candidate_Scheduled_Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Scheduled_Event_ManyArgs = {
  updates:Array<Recruiting_Candidate_Scheduled_Event_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Skill_TagArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_Skill_Tag_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_Skill_Tag_Set_Input>;
  where:Recruiting_Candidate_Skill_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Skill_Tag_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_Skill_Tag_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_Skill_Tag_Set_Input>;
  pk_columns:Recruiting_Candidate_Skill_Tag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Skill_Tag_ManyArgs = {
  updates:Array<Recruiting_Candidate_Skill_Tag_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_StatusArgs = {
  _set?:InputMaybe<Recruiting_Candidate_Status_Set_Input>;
  where:Recruiting_Candidate_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Status_By_PkArgs = {
  _set?:InputMaybe<Recruiting_Candidate_Status_Set_Input>;
  pk_columns:Recruiting_Candidate_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Status_ManyArgs = {
  updates:Array<Recruiting_Candidate_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Status_ReasonArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_Status_Reason_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_Status_Reason_Set_Input>;
  where:Recruiting_Candidate_Status_Reason_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Status_Reason_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_Status_Reason_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_Status_Reason_Set_Input>;
  pk_columns:Recruiting_Candidate_Status_Reason_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_Status_Reason_ManyArgs = {
  updates:Array<Recruiting_Candidate_Status_Reason_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_User_InterviewerArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_User_Interviewer_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_User_Interviewer_Set_Input>;
  where:Recruiting_Candidate_User_Interviewer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_User_Interviewer_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Candidate_User_Interviewer_Inc_Input>;
  _set?:InputMaybe<Recruiting_Candidate_User_Interviewer_Set_Input>;
  pk_columns:Recruiting_Candidate_User_Interviewer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Candidate_User_Interviewer_ManyArgs = {
  updates:Array<Recruiting_Candidate_User_Interviewer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Interview_FlowArgs = {
  _append?:InputMaybe<Recruiting_Interview_Flow_Append_Input>;
  _delete_at_path?:InputMaybe<Recruiting_Interview_Flow_Delete_At_Path_Input>;
  _delete_elem?:InputMaybe<Recruiting_Interview_Flow_Delete_Elem_Input>;
  _delete_key?:InputMaybe<Recruiting_Interview_Flow_Delete_Key_Input>;
  _inc?:InputMaybe<Recruiting_Interview_Flow_Inc_Input>;
  _prepend?:InputMaybe<Recruiting_Interview_Flow_Prepend_Input>;
  _set?:InputMaybe<Recruiting_Interview_Flow_Set_Input>;
  where:Recruiting_Interview_Flow_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Interview_Flow_By_PkArgs = {
  _append?:InputMaybe<Recruiting_Interview_Flow_Append_Input>;
  _delete_at_path?:InputMaybe<Recruiting_Interview_Flow_Delete_At_Path_Input>;
  _delete_elem?:InputMaybe<Recruiting_Interview_Flow_Delete_Elem_Input>;
  _delete_key?:InputMaybe<Recruiting_Interview_Flow_Delete_Key_Input>;
  _inc?:InputMaybe<Recruiting_Interview_Flow_Inc_Input>;
  _prepend?:InputMaybe<Recruiting_Interview_Flow_Prepend_Input>;
  _set?:InputMaybe<Recruiting_Interview_Flow_Set_Input>;
  pk_columns:Recruiting_Interview_Flow_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Interview_Flow_ManyArgs = {
  updates:Array<Recruiting_Interview_Flow_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Interview_Flow_StageArgs = {
  _inc?:InputMaybe<Recruiting_Interview_Flow_Stage_Inc_Input>;
  _set?:InputMaybe<Recruiting_Interview_Flow_Stage_Set_Input>;
  where:Recruiting_Interview_Flow_Stage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Interview_Flow_Stage_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Interview_Flow_Stage_Inc_Input>;
  _set?:InputMaybe<Recruiting_Interview_Flow_Stage_Set_Input>;
  pk_columns:Recruiting_Interview_Flow_Stage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Interview_Flow_Stage_ManyArgs = {
  updates:Array<Recruiting_Interview_Flow_Stage_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Interview_Flow_SubstageArgs = {
  _inc?:InputMaybe<Recruiting_Interview_Flow_Substage_Inc_Input>;
  _set?:InputMaybe<Recruiting_Interview_Flow_Substage_Set_Input>;
  where:Recruiting_Interview_Flow_Substage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Interview_Flow_Substage_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Interview_Flow_Substage_Inc_Input>;
  _set?:InputMaybe<Recruiting_Interview_Flow_Substage_Set_Input>;
  pk_columns:Recruiting_Interview_Flow_Substage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Interview_Flow_Substage_ManyArgs = {
  updates:Array<Recruiting_Interview_Flow_Substage_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_OfferArgs = {
  _inc?:InputMaybe<Recruiting_Offer_Inc_Input>;
  _set?:InputMaybe<Recruiting_Offer_Set_Input>;
  where:Recruiting_Offer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Offer_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Offer_Inc_Input>;
  _set?:InputMaybe<Recruiting_Offer_Set_Input>;
  pk_columns:Recruiting_Offer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Offer_ManyArgs = {
  updates:Array<Recruiting_Offer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Offer_TemplateArgs = {
  _inc?:InputMaybe<Recruiting_Offer_Template_Inc_Input>;
  _set?:InputMaybe<Recruiting_Offer_Template_Set_Input>;
  where:Recruiting_Offer_Template_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Offer_Template_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Offer_Template_Inc_Input>;
  _set?:InputMaybe<Recruiting_Offer_Template_Set_Input>;
  pk_columns:Recruiting_Offer_Template_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Offer_Template_ManyArgs = {
  updates:Array<Recruiting_Offer_Template_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_PositionArgs = {
  _inc?:InputMaybe<Recruiting_Position_Inc_Input>;
  _set?:InputMaybe<Recruiting_Position_Set_Input>;
  where:Recruiting_Position_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Position_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Position_Inc_Input>;
  _set?:InputMaybe<Recruiting_Position_Set_Input>;
  pk_columns:Recruiting_Position_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Position_GroupArgs = {
  _inc?:InputMaybe<Recruiting_Position_Group_Inc_Input>;
  _set?:InputMaybe<Recruiting_Position_Group_Set_Input>;
  where:Recruiting_Position_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Position_Group_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Position_Group_Inc_Input>;
  _set?:InputMaybe<Recruiting_Position_Group_Set_Input>;
  pk_columns:Recruiting_Position_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Position_Group_ManyArgs = {
  updates:Array<Recruiting_Position_Group_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Position_Group_PriorityArgs = {
  _inc?:InputMaybe<Recruiting_Position_Group_Priority_Inc_Input>;
  _set?:InputMaybe<Recruiting_Position_Group_Priority_Set_Input>;
  where:Recruiting_Position_Group_Priority_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Position_Group_Priority_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Position_Group_Priority_Inc_Input>;
  _set?:InputMaybe<Recruiting_Position_Group_Priority_Set_Input>;
  pk_columns:Recruiting_Position_Group_Priority_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Position_Group_Priority_ManyArgs = {
  updates:Array<Recruiting_Position_Group_Priority_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Position_ManyArgs = {
  updates:Array<Recruiting_Position_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Position_TemplateArgs = {
  _inc?:InputMaybe<Recruiting_Position_Template_Inc_Input>;
  _set?:InputMaybe<Recruiting_Position_Template_Set_Input>;
  where:Recruiting_Position_Template_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Position_Template_By_PkArgs = {
  _inc?:InputMaybe<Recruiting_Position_Template_Inc_Input>;
  _set?:InputMaybe<Recruiting_Position_Template_Set_Input>;
  pk_columns:Recruiting_Position_Template_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recruiting_Position_Template_ManyArgs = {
  updates:Array<Recruiting_Position_Template_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Customer_TeamArgs = {
  _inc?:InputMaybe<Staffing_Customer_Team_Inc_Input>;
  _set?:InputMaybe<Staffing_Customer_Team_Set_Input>;
  where:Staffing_Customer_Team_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Customer_Team_By_PkArgs = {
  _inc?:InputMaybe<Staffing_Customer_Team_Inc_Input>;
  _set?:InputMaybe<Staffing_Customer_Team_Set_Input>;
  pk_columns:Staffing_Customer_Team_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Customer_Team_ManyArgs = {
  updates:Array<Staffing_Customer_Team_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Customer_Team_SlotArgs = {
  _inc?:InputMaybe<Staffing_Customer_Team_Slot_Inc_Input>;
  _set?:InputMaybe<Staffing_Customer_Team_Slot_Set_Input>;
  where:Staffing_Customer_Team_Slot_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Customer_Team_Slot_By_PkArgs = {
  _inc?:InputMaybe<Staffing_Customer_Team_Slot_Inc_Input>;
  _set?:InputMaybe<Staffing_Customer_Team_Slot_Set_Input>;
  pk_columns:Staffing_Customer_Team_Slot_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Customer_Team_Slot_ManyArgs = {
  updates:Array<Staffing_Customer_Team_Slot_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Customer_Team_Slot_StatusArgs = {
  _set?:InputMaybe<Staffing_Customer_Team_Slot_Status_Set_Input>;
  where:Staffing_Customer_Team_Slot_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Customer_Team_Slot_Status_By_PkArgs = {
  _set?:InputMaybe<Staffing_Customer_Team_Slot_Status_Set_Input>;
  pk_columns:Staffing_Customer_Team_Slot_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Customer_Team_Slot_Status_ManyArgs = {
  updates:Array<Staffing_Customer_Team_Slot_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Customer_Team_TagArgs = {
  _inc?:InputMaybe<Staffing_Customer_Team_Tag_Inc_Input>;
  _set?:InputMaybe<Staffing_Customer_Team_Tag_Set_Input>;
  where:Staffing_Customer_Team_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Customer_Team_Tag_By_PkArgs = {
  _inc?:InputMaybe<Staffing_Customer_Team_Tag_Inc_Input>;
  _set?:InputMaybe<Staffing_Customer_Team_Tag_Set_Input>;
  pk_columns:Staffing_Customer_Team_Tag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Customer_Team_Tag_ManyArgs = {
  updates:Array<Staffing_Customer_Team_Tag_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_StaffArgs = {
  _inc?:InputMaybe<Staffing_Staff_Inc_Input>;
  _set?:InputMaybe<Staffing_Staff_Set_Input>;
  where:Staffing_Staff_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Staff_By_PkArgs = {
  _inc?:InputMaybe<Staffing_Staff_Inc_Input>;
  _set?:InputMaybe<Staffing_Staff_Set_Input>;
  pk_columns:Staffing_Staff_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Staff_ManyArgs = {
  updates:Array<Staffing_Staff_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Staff_RoleArgs = {
  _inc?:InputMaybe<Staffing_Staff_Role_Inc_Input>;
  _set?:InputMaybe<Staffing_Staff_Role_Set_Input>;
  where:Staffing_Staff_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Staff_Role_By_PkArgs = {
  _inc?:InputMaybe<Staffing_Staff_Role_Inc_Input>;
  _set?:InputMaybe<Staffing_Staff_Role_Set_Input>;
  pk_columns:Staffing_Staff_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Staff_Role_ManyArgs = {
  updates:Array<Staffing_Staff_Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Staff_Skill_TagArgs = {
  _inc?:InputMaybe<Staffing_Staff_Skill_Tag_Inc_Input>;
  _set?:InputMaybe<Staffing_Staff_Skill_Tag_Set_Input>;
  where:Staffing_Staff_Skill_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Staff_Skill_Tag_By_PkArgs = {
  _inc?:InputMaybe<Staffing_Staff_Skill_Tag_Inc_Input>;
  _set?:InputMaybe<Staffing_Staff_Skill_Tag_Set_Input>;
  pk_columns:Staffing_Staff_Skill_Tag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Staff_Skill_Tag_ManyArgs = {
  updates:Array<Staffing_Staff_Skill_Tag_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Staff_StatusArgs = {
  _set?:InputMaybe<Staffing_Staff_Status_Set_Input>;
  where:Staffing_Staff_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Staff_Status_By_PkArgs = {
  _set?:InputMaybe<Staffing_Staff_Status_Set_Input>;
  pk_columns:Staffing_Staff_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Staffing_Staff_Status_ManyArgs = {
  updates:Array<Staffing_Staff_Status_Updates>;
};

/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type Name_Comparison_Exp = {
  _eq?:InputMaybe<Scalars['name']>;
  _gt?:InputMaybe<Scalars['name']>;
  _gte?:InputMaybe<Scalars['name']>;
  _in?:InputMaybe<Array<Scalars['name']>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _lt?:InputMaybe<Scalars['name']>;
  _lte?:InputMaybe<Scalars['name']>;
  _neq?:InputMaybe<Scalars['name']>;
  _nin?:InputMaybe<Array<Scalars['name']>>;
};

/** Boolean expression to compare columns of type "oid". All fields are combined with logical 'AND'. */
export type Oid_Comparison_Exp = {
  _eq?:InputMaybe<Scalars['oid']>;
  _gt?:InputMaybe<Scalars['oid']>;
  _gte?:InputMaybe<Scalars['oid']>;
  _in?:InputMaybe<Array<Scalars['oid']>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _lt?:InputMaybe<Scalars['oid']>;
  _lte?:InputMaybe<Scalars['oid']>;
  _neq?:InputMaybe<Scalars['oid']>;
  _nin?:InputMaybe<Array<Scalars['oid']>>;
};

/** column ordering options */
export enum Order_By 
{
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?:'query_root';
  /** action_calendar_event */
  action_calendar_event?:Maybe<GoogleCalendarEvent>;
  /** action_calendar_events */
  action_calendar_events?:Maybe<GoogleCalendarEvents>;
  action_calendar_freebusy?:Maybe<CalendarFreeBusyResponse>;
  /** Action: Get user profile / Check authorization */
  action_profile?:Maybe<LoginResponse>;
  /** fetch data from the table: "audit.logged_actions" */
  audit_logged_actions:Array<Audit_Logged_Actions>;
  /** fetch aggregated fields from the table: "audit.logged_actions" */
  audit_logged_actions_aggregate:Audit_Logged_Actions_Aggregate;
  /** fetch data from the table: "audit.logged_actions" using primary key columns */
  audit_logged_actions_by_pk?:Maybe<Audit_Logged_Actions>;
  /** fetch data from the table: "audit.tableslist" */
  audit_tableslist:Array<Audit_Tableslist>;
  /** fetch aggregated fields from the table: "audit.tableslist" */
  audit_tableslist_aggregate:Audit_Tableslist_Aggregate;
  /** fetch data from the table: "common.api_key" */
  common_api_key:Array<Common_Api_Key>;
  /** fetch aggregated fields from the table: "common.api_key" */
  common_api_key_aggregate:Common_Api_Key_Aggregate;
  /** fetch data from the table: "common.api_key" using primary key columns */
  common_api_key_by_pk?:Maybe<Common_Api_Key>;
  /** fetch data from the table: "common.attachment" */
  common_attachment:Array<Common_Attachment>;
  /** fetch aggregated fields from the table: "common.attachment" */
  common_attachment_aggregate:Common_Attachment_Aggregate;
  /** fetch data from the table: "common.attachment" using primary key columns */
  common_attachment_by_pk?:Maybe<Common_Attachment>;
  /** fetch data from the table: "common.attachment_type" */
  common_attachment_type:Array<Common_Attachment_Type>;
  /** fetch aggregated fields from the table: "common.attachment_type" */
  common_attachment_type_aggregate:Common_Attachment_Type_Aggregate;
  /** fetch data from the table: "common.attachment_type" using primary key columns */
  common_attachment_type_by_pk?:Maybe<Common_Attachment_Type>;
  /** fetch data from the table: "common.city" */
  common_city:Array<Common_City>;
  /** fetch aggregated fields from the table: "common.city" */
  common_city_aggregate:Common_City_Aggregate;
  /** fetch data from the table: "common.city" using primary key columns */
  common_city_by_pk?:Maybe<Common_City>;
  /** fetch data from the table: "common.country" */
  common_country:Array<Common_Country>;
  /** fetch aggregated fields from the table: "common.country" */
  common_country_aggregate:Common_Country_Aggregate;
  /** fetch data from the table: "common.country" using primary key columns */
  common_country_by_pk?:Maybe<Common_Country>;
  /** fetch data from the table: "common.customer" */
  common_customer:Array<Common_Customer>;
  /** fetch aggregated fields from the table: "common.customer" */
  common_customer_aggregate:Common_Customer_Aggregate;
  /** fetch data from the table: "common.customer" using primary key columns */
  common_customer_by_pk?:Maybe<Common_Customer>;
  /** fetch data from the table: "common.customer_status" */
  common_customer_status:Array<Common_Customer_Status>;
  /** fetch aggregated fields from the table: "common.customer_status" */
  common_customer_status_aggregate:Common_Customer_Status_Aggregate;
  /** fetch data from the table: "common.customer_status" using primary key columns */
  common_customer_status_by_pk?:Maybe<Common_Customer_Status>;
  /** fetch data from the table: "common.filter" */
  common_filter:Array<Common_Filter>;
  /** fetch aggregated fields from the table: "common.filter" */
  common_filter_aggregate:Common_Filter_Aggregate;
  /** fetch data from the table: "common.filter" using primary key columns */
  common_filter_by_pk?:Maybe<Common_Filter>;
  /** fetch data from the table: "common.filter_type" */
  common_filter_type:Array<Common_Filter_Type>;
  /** fetch aggregated fields from the table: "common.filter_type" */
  common_filter_type_aggregate:Common_Filter_Type_Aggregate;
  /** fetch data from the table: "common.filter_type" using primary key columns */
  common_filter_type_by_pk?:Maybe<Common_Filter_Type>;
  /** fetch data from the table: "common.scheduled_event" */
  common_scheduled_event:Array<Common_Scheduled_Event>;
  /** fetch aggregated fields from the table: "common.scheduled_event" */
  common_scheduled_event_aggregate:Common_Scheduled_Event_Aggregate;
  /** fetch data from the table: "common.scheduled_event" using primary key columns */
  common_scheduled_event_by_pk?:Maybe<Common_Scheduled_Event>;
  /** fetch data from the table: "common.skill_tag" */
  common_skill_tag:Array<Common_Skill_Tag>;
  /** fetch aggregated fields from the table: "common.skill_tag" */
  common_skill_tag_aggregate:Common_Skill_Tag_Aggregate;
  /** fetch data from the table: "common.skill_tag" using primary key columns */
  common_skill_tag_by_pk?:Maybe<Common_Skill_Tag>;
  /** fetch data from the table: "common.user" */
  common_user:Array<Common_User>;
  /** fetch aggregated fields from the table: "common.user" */
  common_user_aggregate:Common_User_Aggregate;
  /** fetch data from the table: "common.user" using primary key columns */
  common_user_by_pk?:Maybe<Common_User>;
  /** fetch data from the table: "common.user_role" */
  common_user_role:Array<Common_User_Role>;
  /** fetch aggregated fields from the table: "common.user_role" */
  common_user_role_aggregate:Common_User_Role_Aggregate;
  /** fetch data from the table: "common.user_role" using primary key columns */
  common_user_role_by_pk?:Maybe<Common_User_Role>;
  /** fetch data from the table: "common.user_user_role" */
  common_user_user_role:Array<Common_User_User_Role>;
  /** fetch aggregated fields from the table: "common.user_user_role" */
  common_user_user_role_aggregate:Common_User_User_Role_Aggregate;
  /** fetch data from the table: "common.user_user_role" using primary key columns */
  common_user_user_role_by_pk?:Maybe<Common_User_User_Role>;
  /** fetch data from the table: "hasura_action_permissions_view" */
  hasura_action_permissions_view:Array<Hasura_Action_Permissions_View>;
  /** fetch aggregated fields from the table: "hasura_action_permissions_view" */
  hasura_action_permissions_view_aggregate:Hasura_Action_Permissions_View_Aggregate;
  /** fetch data from the table: "hasura_inherited_roles_view" */
  hasura_inherited_roles_view:Array<Hasura_Inherited_Roles_View>;
  /** fetch aggregated fields from the table: "hasura_inherited_roles_view" */
  hasura_inherited_roles_view_aggregate:Hasura_Inherited_Roles_View_Aggregate;
  /** fetch data from the table: "hasura_metadata_view" */
  hasura_metadata_view:Array<Hasura_Metadata_View>;
  /** fetch aggregated fields from the table: "hasura_metadata_view" */
  hasura_metadata_view_aggregate:Hasura_Metadata_View_Aggregate;
  /** fetch data from the table: "hasura_permissions_view" */
  hasura_permissions_view:Array<Hasura_Permissions_View>;
  /** fetch aggregated fields from the table: "hasura_permissions_view" */
  hasura_permissions_view_aggregate:Hasura_Permissions_View_Aggregate;
  /** fetch data from the table: "recruiting.allocation_position" */
  recruiting_allocation_position:Array<Recruiting_Allocation_Position>;
  /** fetch aggregated fields from the table: "recruiting.allocation_position" */
  recruiting_allocation_position_aggregate:Recruiting_Allocation_Position_Aggregate;
  /** fetch data from the table: "recruiting.allocation_position" using primary key columns */
  recruiting_allocation_position_by_pk?:Maybe<Recruiting_Allocation_Position>;
  /** fetch data from the table: "recruiting.candidate" */
  recruiting_candidate:Array<Recruiting_Candidate>;
  /** fetch aggregated fields from the table: "recruiting.candidate" */
  recruiting_candidate_aggregate:Recruiting_Candidate_Aggregate;
  /** fetch data from the table: "recruiting.candidate_allocation" */
  recruiting_candidate_allocation:Array<Recruiting_Candidate_Allocation>;
  /** fetch aggregated fields from the table: "recruiting.candidate_allocation" */
  recruiting_candidate_allocation_aggregate:Recruiting_Candidate_Allocation_Aggregate;
  /** fetch data from the table: "recruiting.candidate_allocation" using primary key columns */
  recruiting_candidate_allocation_by_pk?:Maybe<Recruiting_Candidate_Allocation>;
  /** fetch data from the table: "recruiting.candidate_attachment" */
  recruiting_candidate_attachment:Array<Recruiting_Candidate_Attachment>;
  /** fetch aggregated fields from the table: "recruiting.candidate_attachment" */
  recruiting_candidate_attachment_aggregate:Recruiting_Candidate_Attachment_Aggregate;
  /** fetch data from the table: "recruiting.candidate_attachment" using primary key columns */
  recruiting_candidate_attachment_by_pk?:Maybe<Recruiting_Candidate_Attachment>;
  /** fetch data from the table: "recruiting.candidate" using primary key columns */
  recruiting_candidate_by_pk?:Maybe<Recruiting_Candidate>;
  /** fetch data from the table: "recruiting.candidate_note" */
  recruiting_candidate_note:Array<Recruiting_Candidate_Note>;
  /** fetch aggregated fields from the table: "recruiting.candidate_note" */
  recruiting_candidate_note_aggregate:Recruiting_Candidate_Note_Aggregate;
  /** fetch data from the table: "recruiting.candidate_note" using primary key columns */
  recruiting_candidate_note_by_pk?:Maybe<Recruiting_Candidate_Note>;
  /** fetch data from the table: "recruiting.candidate_note_type" */
  recruiting_candidate_note_type:Array<Recruiting_Candidate_Note_Type>;
  /** fetch aggregated fields from the table: "recruiting.candidate_note_type" */
  recruiting_candidate_note_type_aggregate:Recruiting_Candidate_Note_Type_Aggregate;
  /** fetch data from the table: "recruiting.candidate_note_type" using primary key columns */
  recruiting_candidate_note_type_by_pk?:Maybe<Recruiting_Candidate_Note_Type>;
  /** fetch data from the table: "recruiting.candidate_position_group" */
  recruiting_candidate_position_group:Array<Recruiting_Candidate_Position_Group>;
  /** fetch aggregated fields from the table: "recruiting.candidate_position_group" */
  recruiting_candidate_position_group_aggregate:Recruiting_Candidate_Position_Group_Aggregate;
  /** fetch data from the table: "recruiting.candidate_position_group" using primary key columns */
  recruiting_candidate_position_group_by_pk?:Maybe<Recruiting_Candidate_Position_Group>;
  /** fetch data from the table: "recruiting.candidate_scheduled_event" */
  recruiting_candidate_scheduled_event:Array<Recruiting_Candidate_Scheduled_Event>;
  /** fetch aggregated fields from the table: "recruiting.candidate_scheduled_event" */
  recruiting_candidate_scheduled_event_aggregate:Recruiting_Candidate_Scheduled_Event_Aggregate;
  /** fetch data from the table: "recruiting.candidate_scheduled_event" using primary key columns */
  recruiting_candidate_scheduled_event_by_pk?:Maybe<Recruiting_Candidate_Scheduled_Event>;
  /** fetch data from the table: "recruiting.candidate_skill_tag" */
  recruiting_candidate_skill_tag:Array<Recruiting_Candidate_Skill_Tag>;
  /** fetch aggregated fields from the table: "recruiting.candidate_skill_tag" */
  recruiting_candidate_skill_tag_aggregate:Recruiting_Candidate_Skill_Tag_Aggregate;
  /** fetch data from the table: "recruiting.candidate_skill_tag" using primary key columns */
  recruiting_candidate_skill_tag_by_pk?:Maybe<Recruiting_Candidate_Skill_Tag>;
  /** fetch data from the table: "recruiting.candidate_status" */
  recruiting_candidate_status:Array<Recruiting_Candidate_Status>;
  /** fetch aggregated fields from the table: "recruiting.candidate_status" */
  recruiting_candidate_status_aggregate:Recruiting_Candidate_Status_Aggregate;
  /** fetch data from the table: "recruiting.candidate_status" using primary key columns */
  recruiting_candidate_status_by_pk?:Maybe<Recruiting_Candidate_Status>;
  /** fetch data from the table: "recruiting.candidate_status_reason" */
  recruiting_candidate_status_reason:Array<Recruiting_Candidate_Status_Reason>;
  /** fetch aggregated fields from the table: "recruiting.candidate_status_reason" */
  recruiting_candidate_status_reason_aggregate:Recruiting_Candidate_Status_Reason_Aggregate;
  /** fetch data from the table: "recruiting.candidate_status_reason" using primary key columns */
  recruiting_candidate_status_reason_by_pk?:Maybe<Recruiting_Candidate_Status_Reason>;
  /** fetch data from the table: "recruiting.candidate_user_interviewer" */
  recruiting_candidate_user_interviewer:Array<Recruiting_Candidate_User_Interviewer>;
  /** fetch aggregated fields from the table: "recruiting.candidate_user_interviewer" */
  recruiting_candidate_user_interviewer_aggregate:Recruiting_Candidate_User_Interviewer_Aggregate;
  /** fetch data from the table: "recruiting.candidate_user_interviewer" using primary key columns */
  recruiting_candidate_user_interviewer_by_pk?:Maybe<Recruiting_Candidate_User_Interviewer>;
  /** fetch data from the table: "recruiting.interview_flow" */
  recruiting_interview_flow:Array<Recruiting_Interview_Flow>;
  /** fetch aggregated fields from the table: "recruiting.interview_flow" */
  recruiting_interview_flow_aggregate:Recruiting_Interview_Flow_Aggregate;
  /** fetch data from the table: "recruiting.interview_flow" using primary key columns */
  recruiting_interview_flow_by_pk?:Maybe<Recruiting_Interview_Flow>;
  /** fetch data from the table: "recruiting.interview_flow_stage" */
  recruiting_interview_flow_stage:Array<Recruiting_Interview_Flow_Stage>;
  /** fetch aggregated fields from the table: "recruiting.interview_flow_stage" */
  recruiting_interview_flow_stage_aggregate:Recruiting_Interview_Flow_Stage_Aggregate;
  /** fetch data from the table: "recruiting.interview_flow_stage" using primary key columns */
  recruiting_interview_flow_stage_by_pk?:Maybe<Recruiting_Interview_Flow_Stage>;
  /** fetch data from the table: "recruiting.interview_flow_substage" */
  recruiting_interview_flow_substage:Array<Recruiting_Interview_Flow_Substage>;
  /** fetch aggregated fields from the table: "recruiting.interview_flow_substage" */
  recruiting_interview_flow_substage_aggregate:Recruiting_Interview_Flow_Substage_Aggregate;
  /** fetch data from the table: "recruiting.interview_flow_substage" using primary key columns */
  recruiting_interview_flow_substage_by_pk?:Maybe<Recruiting_Interview_Flow_Substage>;
  /** fetch data from the table: "recruiting.offer" */
  recruiting_offer:Array<Recruiting_Offer>;
  /** fetch aggregated fields from the table: "recruiting.offer" */
  recruiting_offer_aggregate:Recruiting_Offer_Aggregate;
  /** fetch data from the table: "recruiting.offer" using primary key columns */
  recruiting_offer_by_pk?:Maybe<Recruiting_Offer>;
  /** fetch data from the table: "recruiting.offer_template" */
  recruiting_offer_template:Array<Recruiting_Offer_Template>;
  /** fetch aggregated fields from the table: "recruiting.offer_template" */
  recruiting_offer_template_aggregate:Recruiting_Offer_Template_Aggregate;
  /** fetch data from the table: "recruiting.offer_template" using primary key columns */
  recruiting_offer_template_by_pk?:Maybe<Recruiting_Offer_Template>;
  /** fetch data from the table: "recruiting.position" */
  recruiting_position:Array<Recruiting_Position>;
  /** fetch aggregated fields from the table: "recruiting.position" */
  recruiting_position_aggregate:Recruiting_Position_Aggregate;
  /** fetch data from the table: "recruiting.position" using primary key columns */
  recruiting_position_by_pk?:Maybe<Recruiting_Position>;
  /** fetch data from the table: "recruiting.position_group" */
  recruiting_position_group:Array<Recruiting_Position_Group>;
  /** fetch aggregated fields from the table: "recruiting.position_group" */
  recruiting_position_group_aggregate:Recruiting_Position_Group_Aggregate;
  /** fetch data from the table: "recruiting.position_group" using primary key columns */
  recruiting_position_group_by_pk?:Maybe<Recruiting_Position_Group>;
  /** fetch data from the table: "recruiting.position_group_priority" */
  recruiting_position_group_priority:Array<Recruiting_Position_Group_Priority>;
  /** fetch aggregated fields from the table: "recruiting.position_group_priority" */
  recruiting_position_group_priority_aggregate:Recruiting_Position_Group_Priority_Aggregate;
  /** fetch data from the table: "recruiting.position_group_priority" using primary key columns */
  recruiting_position_group_priority_by_pk?:Maybe<Recruiting_Position_Group_Priority>;
  /** fetch data from the table: "recruiting.position_template" */
  recruiting_position_template:Array<Recruiting_Position_Template>;
  /** fetch aggregated fields from the table: "recruiting.position_template" */
  recruiting_position_template_aggregate:Recruiting_Position_Template_Aggregate;
  /** fetch data from the table: "recruiting.position_template" using primary key columns */
  recruiting_position_template_by_pk?:Maybe<Recruiting_Position_Template>;
  /** fetch data from the table: "staffing.customer_team" */
  staffing_customer_team:Array<Staffing_Customer_Team>;
  /** fetch aggregated fields from the table: "staffing.customer_team" */
  staffing_customer_team_aggregate:Staffing_Customer_Team_Aggregate;
  /** fetch data from the table: "staffing.customer_team" using primary key columns */
  staffing_customer_team_by_pk?:Maybe<Staffing_Customer_Team>;
  /** fetch data from the table: "staffing.customer_team_slot" */
  staffing_customer_team_slot:Array<Staffing_Customer_Team_Slot>;
  /** fetch aggregated fields from the table: "staffing.customer_team_slot" */
  staffing_customer_team_slot_aggregate:Staffing_Customer_Team_Slot_Aggregate;
  /** fetch data from the table: "staffing.customer_team_slot" using primary key columns */
  staffing_customer_team_slot_by_pk?:Maybe<Staffing_Customer_Team_Slot>;
  /** fetch data from the table: "staffing.customer_team_slot_status" */
  staffing_customer_team_slot_status:Array<Staffing_Customer_Team_Slot_Status>;
  /** fetch aggregated fields from the table: "staffing.customer_team_slot_status" */
  staffing_customer_team_slot_status_aggregate:Staffing_Customer_Team_Slot_Status_Aggregate;
  /** fetch data from the table: "staffing.customer_team_slot_status" using primary key columns */
  staffing_customer_team_slot_status_by_pk?:Maybe<Staffing_Customer_Team_Slot_Status>;
  /** fetch data from the table: "staffing.customer_team_tag" */
  staffing_customer_team_tag:Array<Staffing_Customer_Team_Tag>;
  /** fetch aggregated fields from the table: "staffing.customer_team_tag" */
  staffing_customer_team_tag_aggregate:Staffing_Customer_Team_Tag_Aggregate;
  /** fetch data from the table: "staffing.customer_team_tag" using primary key columns */
  staffing_customer_team_tag_by_pk?:Maybe<Staffing_Customer_Team_Tag>;
  /** fetch data from the table: "staffing.staff" */
  staffing_staff:Array<Staffing_Staff>;
  /** fetch aggregated fields from the table: "staffing.staff" */
  staffing_staff_aggregate:Staffing_Staff_Aggregate;
  /** fetch data from the table: "staffing.staff" using primary key columns */
  staffing_staff_by_pk?:Maybe<Staffing_Staff>;
  /** fetch data from the table: "staffing.staff_role" */
  staffing_staff_role:Array<Staffing_Staff_Role>;
  /** fetch aggregated fields from the table: "staffing.staff_role" */
  staffing_staff_role_aggregate:Staffing_Staff_Role_Aggregate;
  /** fetch data from the table: "staffing.staff_role" using primary key columns */
  staffing_staff_role_by_pk?:Maybe<Staffing_Staff_Role>;
  /** fetch data from the table: "staffing.staff_skill_tag" */
  staffing_staff_skill_tag:Array<Staffing_Staff_Skill_Tag>;
  /** fetch aggregated fields from the table: "staffing.staff_skill_tag" */
  staffing_staff_skill_tag_aggregate:Staffing_Staff_Skill_Tag_Aggregate;
  /** fetch data from the table: "staffing.staff_skill_tag" using primary key columns */
  staffing_staff_skill_tag_by_pk?:Maybe<Staffing_Staff_Skill_Tag>;
  /** fetch data from the table: "staffing.staff_status" */
  staffing_staff_status:Array<Staffing_Staff_Status>;
  /** fetch aggregated fields from the table: "staffing.staff_status" */
  staffing_staff_status_aggregate:Staffing_Staff_Status_Aggregate;
  /** fetch data from the table: "staffing.staff_status" using primary key columns */
  staffing_staff_status_by_pk?:Maybe<Staffing_Staff_Status>;
};


export type Query_RootAction_Calendar_EventArgs = {
  calendarId:Scalars['String'];
  eventId:Scalars['String'];
};


export type Query_RootAction_Calendar_EventsArgs = {
  calendarId:Scalars['String'];
  timeMax:Scalars['timestamptz'];
  timeMin:Scalars['timestamptz'];
};


export type Query_RootAction_Calendar_FreebusyArgs = {
  calendarIds:Array<Scalars['String']>;
  onlyAccepted:Scalars['Boolean'];
  timeMax:Scalars['timestamptz'];
  timeMin:Scalars['timestamptz'];
};


export type Query_RootAudit_Logged_ActionsArgs = {
  distinct_on?:InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Audit_Logged_Actions_Order_By>>;
  where?:InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};


export type Query_RootAudit_Logged_Actions_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Audit_Logged_Actions_Order_By>>;
  where?:InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};


export type Query_RootAudit_Logged_Actions_By_PkArgs = {
  event_id:Scalars['bigint'];
};


export type Query_RootAudit_TableslistArgs = {
  distinct_on?:InputMaybe<Array<Audit_Tableslist_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Audit_Tableslist_Order_By>>;
  where?:InputMaybe<Audit_Tableslist_Bool_Exp>;
};


export type Query_RootAudit_Tableslist_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Audit_Tableslist_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Audit_Tableslist_Order_By>>;
  where?:InputMaybe<Audit_Tableslist_Bool_Exp>;
};


export type Query_RootCommon_Api_KeyArgs = {
  distinct_on?:InputMaybe<Array<Common_Api_Key_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Api_Key_Order_By>>;
  where?:InputMaybe<Common_Api_Key_Bool_Exp>;
};


export type Query_RootCommon_Api_Key_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Api_Key_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Api_Key_Order_By>>;
  where?:InputMaybe<Common_Api_Key_Bool_Exp>;
};


export type Query_RootCommon_Api_Key_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootCommon_AttachmentArgs = {
  distinct_on?:InputMaybe<Array<Common_Attachment_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Attachment_Order_By>>;
  where?:InputMaybe<Common_Attachment_Bool_Exp>;
};


export type Query_RootCommon_Attachment_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Attachment_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Attachment_Order_By>>;
  where?:InputMaybe<Common_Attachment_Bool_Exp>;
};


export type Query_RootCommon_Attachment_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootCommon_Attachment_TypeArgs = {
  distinct_on?:InputMaybe<Array<Common_Attachment_Type_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Attachment_Type_Order_By>>;
  where?:InputMaybe<Common_Attachment_Type_Bool_Exp>;
};


export type Query_RootCommon_Attachment_Type_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Attachment_Type_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Attachment_Type_Order_By>>;
  where?:InputMaybe<Common_Attachment_Type_Bool_Exp>;
};


export type Query_RootCommon_Attachment_Type_By_PkArgs = {
  type:Scalars['String'];
};


export type Query_RootCommon_CityArgs = {
  distinct_on?:InputMaybe<Array<Common_City_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_City_Order_By>>;
  where?:InputMaybe<Common_City_Bool_Exp>;
};


export type Query_RootCommon_City_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_City_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_City_Order_By>>;
  where?:InputMaybe<Common_City_Bool_Exp>;
};


export type Query_RootCommon_City_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootCommon_CountryArgs = {
  distinct_on?:InputMaybe<Array<Common_Country_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Country_Order_By>>;
  where?:InputMaybe<Common_Country_Bool_Exp>;
};


export type Query_RootCommon_Country_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Country_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Country_Order_By>>;
  where?:InputMaybe<Common_Country_Bool_Exp>;
};


export type Query_RootCommon_Country_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootCommon_CustomerArgs = {
  distinct_on?:InputMaybe<Array<Common_Customer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Customer_Order_By>>;
  where?:InputMaybe<Common_Customer_Bool_Exp>;
};


export type Query_RootCommon_Customer_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Customer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Customer_Order_By>>;
  where?:InputMaybe<Common_Customer_Bool_Exp>;
};


export type Query_RootCommon_Customer_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootCommon_Customer_StatusArgs = {
  distinct_on?:InputMaybe<Array<Common_Customer_Status_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Customer_Status_Order_By>>;
  where?:InputMaybe<Common_Customer_Status_Bool_Exp>;
};


export type Query_RootCommon_Customer_Status_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Customer_Status_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Customer_Status_Order_By>>;
  where?:InputMaybe<Common_Customer_Status_Bool_Exp>;
};


export type Query_RootCommon_Customer_Status_By_PkArgs = {
  status:Scalars['String'];
};


export type Query_RootCommon_FilterArgs = {
  distinct_on?:InputMaybe<Array<Common_Filter_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Filter_Order_By>>;
  where?:InputMaybe<Common_Filter_Bool_Exp>;
};


export type Query_RootCommon_Filter_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Filter_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Filter_Order_By>>;
  where?:InputMaybe<Common_Filter_Bool_Exp>;
};


export type Query_RootCommon_Filter_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootCommon_Filter_TypeArgs = {
  distinct_on?:InputMaybe<Array<Common_Filter_Type_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Filter_Type_Order_By>>;
  where?:InputMaybe<Common_Filter_Type_Bool_Exp>;
};


export type Query_RootCommon_Filter_Type_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Filter_Type_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Filter_Type_Order_By>>;
  where?:InputMaybe<Common_Filter_Type_Bool_Exp>;
};


export type Query_RootCommon_Filter_Type_By_PkArgs = {
  type:Scalars['String'];
};


export type Query_RootCommon_Scheduled_EventArgs = {
  distinct_on?:InputMaybe<Array<Common_Scheduled_Event_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Scheduled_Event_Order_By>>;
  where?:InputMaybe<Common_Scheduled_Event_Bool_Exp>;
};


export type Query_RootCommon_Scheduled_Event_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Scheduled_Event_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Scheduled_Event_Order_By>>;
  where?:InputMaybe<Common_Scheduled_Event_Bool_Exp>;
};


export type Query_RootCommon_Scheduled_Event_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootCommon_Skill_TagArgs = {
  distinct_on?:InputMaybe<Array<Common_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Skill_Tag_Order_By>>;
  where?:InputMaybe<Common_Skill_Tag_Bool_Exp>;
};


export type Query_RootCommon_Skill_Tag_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Skill_Tag_Order_By>>;
  where?:InputMaybe<Common_Skill_Tag_Bool_Exp>;
};


export type Query_RootCommon_Skill_Tag_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootCommon_UserArgs = {
  distinct_on?:InputMaybe<Array<Common_User_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_Order_By>>;
  where?:InputMaybe<Common_User_Bool_Exp>;
};


export type Query_RootCommon_User_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_User_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_Order_By>>;
  where?:InputMaybe<Common_User_Bool_Exp>;
};


export type Query_RootCommon_User_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootCommon_User_RoleArgs = {
  distinct_on?:InputMaybe<Array<Common_User_Role_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_Role_Order_By>>;
  where?:InputMaybe<Common_User_Role_Bool_Exp>;
};


export type Query_RootCommon_User_Role_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_User_Role_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_Role_Order_By>>;
  where?:InputMaybe<Common_User_Role_Bool_Exp>;
};


export type Query_RootCommon_User_Role_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootCommon_User_User_RoleArgs = {
  distinct_on?:InputMaybe<Array<Common_User_User_Role_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_User_Role_Order_By>>;
  where?:InputMaybe<Common_User_User_Role_Bool_Exp>;
};


export type Query_RootCommon_User_User_Role_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_User_User_Role_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_User_Role_Order_By>>;
  where?:InputMaybe<Common_User_User_Role_Bool_Exp>;
};


export type Query_RootCommon_User_User_Role_By_PkArgs = {
  user_id:Scalars['Int'];
  user_role_id:Scalars['Int'];
};


export type Query_RootHasura_Action_Permissions_ViewArgs = {
  distinct_on?:InputMaybe<Array<Hasura_Action_Permissions_View_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Hasura_Action_Permissions_View_Order_By>>;
  where?:InputMaybe<Hasura_Action_Permissions_View_Bool_Exp>;
};


export type Query_RootHasura_Action_Permissions_View_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Hasura_Action_Permissions_View_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Hasura_Action_Permissions_View_Order_By>>;
  where?:InputMaybe<Hasura_Action_Permissions_View_Bool_Exp>;
};


export type Query_RootHasura_Inherited_Roles_ViewArgs = {
  distinct_on?:InputMaybe<Array<Hasura_Inherited_Roles_View_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Hasura_Inherited_Roles_View_Order_By>>;
  where?:InputMaybe<Hasura_Inherited_Roles_View_Bool_Exp>;
};


export type Query_RootHasura_Inherited_Roles_View_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Hasura_Inherited_Roles_View_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Hasura_Inherited_Roles_View_Order_By>>;
  where?:InputMaybe<Hasura_Inherited_Roles_View_Bool_Exp>;
};


export type Query_RootHasura_Metadata_ViewArgs = {
  distinct_on?:InputMaybe<Array<Hasura_Metadata_View_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Hasura_Metadata_View_Order_By>>;
  where?:InputMaybe<Hasura_Metadata_View_Bool_Exp>;
};


export type Query_RootHasura_Metadata_View_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Hasura_Metadata_View_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Hasura_Metadata_View_Order_By>>;
  where?:InputMaybe<Hasura_Metadata_View_Bool_Exp>;
};


export type Query_RootHasura_Permissions_ViewArgs = {
  distinct_on?:InputMaybe<Array<Hasura_Permissions_View_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Hasura_Permissions_View_Order_By>>;
  where?:InputMaybe<Hasura_Permissions_View_Bool_Exp>;
};


export type Query_RootHasura_Permissions_View_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Hasura_Permissions_View_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Hasura_Permissions_View_Order_By>>;
  where?:InputMaybe<Hasura_Permissions_View_Bool_Exp>;
};


export type Query_RootRecruiting_Allocation_PositionArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Allocation_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Allocation_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Allocation_Position_Bool_Exp>;
};


export type Query_RootRecruiting_Allocation_Position_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Allocation_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Allocation_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Allocation_Position_Bool_Exp>;
};


export type Query_RootRecruiting_Allocation_Position_By_PkArgs = {
  allocation_id:Scalars['Int'];
  position_id:Scalars['Int'];
};


export type Query_RootRecruiting_CandidateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_AllocationArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Allocation_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Allocation_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Allocation_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Allocation_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Allocation_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Allocation_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Allocation_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Allocation_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootRecruiting_Candidate_AttachmentArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Attachment_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Attachment_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Attachment_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Attachment_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Attachment_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Attachment_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Attachment_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Attachment_By_PkArgs = {
  attachment_id:Scalars['Int'];
  candidate_id:Scalars['Int'];
};


export type Query_RootRecruiting_Candidate_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootRecruiting_Candidate_NoteArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Note_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Note_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Note_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Note_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Note_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Note_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Note_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Note_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootRecruiting_Candidate_Note_TypeArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Note_Type_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Note_Type_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Note_Type_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Note_Type_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Note_Type_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Note_Type_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Note_Type_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Note_Type_By_PkArgs = {
  type:Scalars['String'];
};


export type Query_RootRecruiting_Candidate_Position_GroupArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Position_Group_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Position_Group_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Position_Group_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Position_Group_By_PkArgs = {
  candidate_id:Scalars['Int'];
  position_group_id:Scalars['Int'];
};


export type Query_RootRecruiting_Candidate_Scheduled_EventArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Scheduled_Event_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Scheduled_Event_By_PkArgs = {
  candidate_id:Scalars['Int'];
  scheduled_call_id:Scalars['Int'];
};


export type Query_RootRecruiting_Candidate_Skill_TagArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Skill_Tag_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Skill_Tag_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Skill_Tag_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Skill_Tag_By_PkArgs = {
  candidate_id:Scalars['Int'];
  skill_tag_id:Scalars['Int'];
};


export type Query_RootRecruiting_Candidate_StatusArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Status_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Status_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Status_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Status_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Status_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Status_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Status_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Status_By_PkArgs = {
  status:Scalars['String'];
};


export type Query_RootRecruiting_Candidate_Status_ReasonArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Status_Reason_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Status_Reason_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Status_Reason_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Status_Reason_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Status_Reason_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Status_Reason_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Status_Reason_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_Status_Reason_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootRecruiting_Candidate_User_InterviewerArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_User_Interviewer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_User_Interviewer_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_User_Interviewer_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_User_Interviewer_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_User_Interviewer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_User_Interviewer_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_User_Interviewer_Bool_Exp>;
};


export type Query_RootRecruiting_Candidate_User_Interviewer_By_PkArgs = {
  candidate_id:Scalars['Int'];
  user_id:Scalars['Int'];
};


export type Query_RootRecruiting_Interview_FlowArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Interview_Flow_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Interview_Flow_Order_By>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Bool_Exp>;
};


export type Query_RootRecruiting_Interview_Flow_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Interview_Flow_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Interview_Flow_Order_By>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Bool_Exp>;
};


export type Query_RootRecruiting_Interview_Flow_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootRecruiting_Interview_Flow_StageArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Interview_Flow_Stage_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Interview_Flow_Stage_Order_By>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Stage_Bool_Exp>;
};


export type Query_RootRecruiting_Interview_Flow_Stage_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Interview_Flow_Stage_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Interview_Flow_Stage_Order_By>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Stage_Bool_Exp>;
};


export type Query_RootRecruiting_Interview_Flow_Stage_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootRecruiting_Interview_Flow_SubstageArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Interview_Flow_Substage_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Interview_Flow_Substage_Order_By>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Substage_Bool_Exp>;
};


export type Query_RootRecruiting_Interview_Flow_Substage_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Interview_Flow_Substage_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Interview_Flow_Substage_Order_By>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Substage_Bool_Exp>;
};


export type Query_RootRecruiting_Interview_Flow_Substage_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootRecruiting_OfferArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Offer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Offer_Order_By>>;
  where?:InputMaybe<Recruiting_Offer_Bool_Exp>;
};


export type Query_RootRecruiting_Offer_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Offer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Offer_Order_By>>;
  where?:InputMaybe<Recruiting_Offer_Bool_Exp>;
};


export type Query_RootRecruiting_Offer_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootRecruiting_Offer_TemplateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Offer_Template_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Offer_Template_Order_By>>;
  where?:InputMaybe<Recruiting_Offer_Template_Bool_Exp>;
};


export type Query_RootRecruiting_Offer_Template_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Offer_Template_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Offer_Template_Order_By>>;
  where?:InputMaybe<Recruiting_Offer_Template_Bool_Exp>;
};


export type Query_RootRecruiting_Offer_Template_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootRecruiting_PositionArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Bool_Exp>;
};


export type Query_RootRecruiting_Position_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Bool_Exp>;
};


export type Query_RootRecruiting_Position_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootRecruiting_Position_GroupArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Group_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Group_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
};


export type Query_RootRecruiting_Position_Group_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Group_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Group_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
};


export type Query_RootRecruiting_Position_Group_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootRecruiting_Position_Group_PriorityArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Group_Priority_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Group_Priority_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Group_Priority_Bool_Exp>;
};


export type Query_RootRecruiting_Position_Group_Priority_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Group_Priority_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Group_Priority_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Group_Priority_Bool_Exp>;
};


export type Query_RootRecruiting_Position_Group_Priority_By_PkArgs = {
  position_group_id:Scalars['Int'];
  user_id:Scalars['Int'];
};


export type Query_RootRecruiting_Position_TemplateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Template_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Template_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Template_Bool_Exp>;
};


export type Query_RootRecruiting_Position_Template_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Template_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Template_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Template_Bool_Exp>;
};


export type Query_RootRecruiting_Position_Template_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootStaffing_Customer_TeamArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Bool_Exp>;
};


export type Query_RootStaffing_Customer_Team_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Bool_Exp>;
};


export type Query_RootStaffing_Customer_Team_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootStaffing_Customer_Team_SlotArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
};


export type Query_RootStaffing_Customer_Team_Slot_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
};


export type Query_RootStaffing_Customer_Team_Slot_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootStaffing_Customer_Team_Slot_StatusArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Status_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Status_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Status_Bool_Exp>;
};


export type Query_RootStaffing_Customer_Team_Slot_Status_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Status_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Status_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Status_Bool_Exp>;
};


export type Query_RootStaffing_Customer_Team_Slot_Status_By_PkArgs = {
  status:Scalars['String'];
};


export type Query_RootStaffing_Customer_Team_TagArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Tag_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Tag_Bool_Exp>;
};


export type Query_RootStaffing_Customer_Team_Tag_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Tag_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Tag_Bool_Exp>;
};


export type Query_RootStaffing_Customer_Team_Tag_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootStaffing_StaffArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Bool_Exp>;
};


export type Query_RootStaffing_Staff_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Bool_Exp>;
};


export type Query_RootStaffing_Staff_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootStaffing_Staff_RoleArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Role_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Role_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Role_Bool_Exp>;
};


export type Query_RootStaffing_Staff_Role_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Role_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Role_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Role_Bool_Exp>;
};


export type Query_RootStaffing_Staff_Role_By_PkArgs = {
  id:Scalars['Int'];
};


export type Query_RootStaffing_Staff_Skill_TagArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Skill_Tag_Bool_Exp>;
};


export type Query_RootStaffing_Staff_Skill_Tag_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Skill_Tag_Bool_Exp>;
};


export type Query_RootStaffing_Staff_Skill_Tag_By_PkArgs = {
  skill_tag_id:Scalars['Int'];
  staff_id:Scalars['Int'];
};


export type Query_RootStaffing_Staff_StatusArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Status_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Status_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Status_Bool_Exp>;
};


export type Query_RootStaffing_Staff_Status_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Status_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Status_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Status_Bool_Exp>;
};


export type Query_RootStaffing_Staff_Status_By_PkArgs = {
  status:Scalars['String'];
};

/** columns and relationships of "recruiting.allocation_position" */
export type Recruiting_Allocation_Position = {
  __typename?:'recruiting_allocation_position';
  allocation_id:Scalars['Int'];
  /** An object relationship */
  candidate_allocation:Recruiting_Candidate_Allocation;
  created_at?:Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  position:Recruiting_Position;
  position_id:Scalars['Int'];
};

/** aggregated selection of "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Aggregate = {
  __typename?:'recruiting_allocation_position_aggregate';
  aggregate?:Maybe<Recruiting_Allocation_Position_Aggregate_Fields>;
  nodes:Array<Recruiting_Allocation_Position>;
};

export type Recruiting_Allocation_Position_Aggregate_Bool_Exp = {
  count?:InputMaybe<Recruiting_Allocation_Position_Aggregate_Bool_Exp_Count>;
};

export type Recruiting_Allocation_Position_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Recruiting_Allocation_Position_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Allocation_Position_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Aggregate_Fields = {
  __typename?:'recruiting_allocation_position_aggregate_fields';
  avg?:Maybe<Recruiting_Allocation_Position_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Allocation_Position_Max_Fields>;
  min?:Maybe<Recruiting_Allocation_Position_Min_Fields>;
  stddev?:Maybe<Recruiting_Allocation_Position_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Allocation_Position_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Allocation_Position_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Allocation_Position_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Allocation_Position_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Allocation_Position_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Allocation_Position_Variance_Fields>;
};


/** aggregate fields of "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Allocation_Position_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Aggregate_Order_By = {
  avg?:InputMaybe<Recruiting_Allocation_Position_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Recruiting_Allocation_Position_Max_Order_By>;
  min?:InputMaybe<Recruiting_Allocation_Position_Min_Order_By>;
  stddev?:InputMaybe<Recruiting_Allocation_Position_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Recruiting_Allocation_Position_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Recruiting_Allocation_Position_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Recruiting_Allocation_Position_Sum_Order_By>;
  var_pop?:InputMaybe<Recruiting_Allocation_Position_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Recruiting_Allocation_Position_Var_Samp_Order_By>;
  variance?:InputMaybe<Recruiting_Allocation_Position_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Arr_Rel_Insert_Input = {
  data:Array<Recruiting_Allocation_Position_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Allocation_Position_On_Conflict>;
};

/** aggregate avg on columns */
export type Recruiting_Allocation_Position_Avg_Fields = {
  __typename?:'recruiting_allocation_position_avg_fields';
  allocation_id?:Maybe<Scalars['Float']>;
  position_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Avg_Order_By = {
  allocation_id?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recruiting.allocation_position". All fields are combined with a logical 'AND'. */
export type Recruiting_Allocation_Position_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Allocation_Position_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Allocation_Position_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Allocation_Position_Bool_Exp>>;
  allocation_id?:InputMaybe<Int_Comparison_Exp>;
  candidate_allocation?:InputMaybe<Recruiting_Candidate_Allocation_Bool_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  position?:InputMaybe<Recruiting_Position_Bool_Exp>;
  position_id?:InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.allocation_position" */
export enum Recruiting_Allocation_Position_Constraint 
{
  /** unique or primary key constraint on columns "position_id", "allocation_id" */
  ProposedAllocationPositionPkey = 'proposed_allocation_position_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Inc_Input = {
  allocation_id?:InputMaybe<Scalars['Int']>;
  position_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Insert_Input = {
  allocation_id?:InputMaybe<Scalars['Int']>;
  candidate_allocation?:InputMaybe<Recruiting_Candidate_Allocation_Obj_Rel_Insert_Input>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  position?:InputMaybe<Recruiting_Position_Obj_Rel_Insert_Input>;
  position_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Recruiting_Allocation_Position_Max_Fields = {
  __typename?:'recruiting_allocation_position_max_fields';
  allocation_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  position_id?:Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Max_Order_By = {
  allocation_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recruiting_Allocation_Position_Min_Fields = {
  __typename?:'recruiting_allocation_position_min_fields';
  allocation_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  position_id?:Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Min_Order_By = {
  allocation_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Mutation_Response = {
  __typename?:'recruiting_allocation_position_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Allocation_Position>;
};

/** on_conflict condition type for table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_On_Conflict = {
  constraint:Recruiting_Allocation_Position_Constraint;
  update_columns?:Array<Recruiting_Allocation_Position_Update_Column>;
  where?:InputMaybe<Recruiting_Allocation_Position_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.allocation_position". */
export type Recruiting_Allocation_Position_Order_By = {
  allocation_id?:InputMaybe<Order_By>;
  candidate_allocation?:InputMaybe<Recruiting_Candidate_Allocation_Order_By>;
  created_at?:InputMaybe<Order_By>;
  position?:InputMaybe<Recruiting_Position_Order_By>;
  position_id?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.allocation_position */
export type Recruiting_Allocation_Position_Pk_Columns_Input = {
  allocation_id:Scalars['Int'];
  position_id:Scalars['Int'];
};

/** select columns of table "recruiting.allocation_position" */
export enum Recruiting_Allocation_Position_Select_Column 
{
  /** column name */
  AllocationId = 'allocation_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PositionId = 'position_id'
}

/** input type for updating data in table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Set_Input = {
  allocation_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  position_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Recruiting_Allocation_Position_Stddev_Fields = {
  __typename?:'recruiting_allocation_position_stddev_fields';
  allocation_id?:Maybe<Scalars['Float']>;
  position_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Stddev_Order_By = {
  allocation_id?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Allocation_Position_Stddev_Pop_Fields = {
  __typename?:'recruiting_allocation_position_stddev_pop_fields';
  allocation_id?:Maybe<Scalars['Float']>;
  position_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Stddev_Pop_Order_By = {
  allocation_id?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Allocation_Position_Stddev_Samp_Fields = {
  __typename?:'recruiting_allocation_position_stddev_samp_fields';
  allocation_id?:Maybe<Scalars['Float']>;
  position_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Stddev_Samp_Order_By = {
  allocation_id?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recruiting_allocation_position" */
export type Recruiting_Allocation_Position_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Allocation_Position_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Allocation_Position_Stream_Cursor_Value_Input = {
  allocation_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  position_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Recruiting_Allocation_Position_Sum_Fields = {
  __typename?:'recruiting_allocation_position_sum_fields';
  allocation_id?:Maybe<Scalars['Int']>;
  position_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Sum_Order_By = {
  allocation_id?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
};

/** update columns of table "recruiting.allocation_position" */
export enum Recruiting_Allocation_Position_Update_Column 
{
  /** column name */
  AllocationId = 'allocation_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PositionId = 'position_id'
}

export type Recruiting_Allocation_Position_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Allocation_Position_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Allocation_Position_Set_Input>;
  where:Recruiting_Allocation_Position_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Allocation_Position_Var_Pop_Fields = {
  __typename?:'recruiting_allocation_position_var_pop_fields';
  allocation_id?:Maybe<Scalars['Float']>;
  position_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Var_Pop_Order_By = {
  allocation_id?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recruiting_Allocation_Position_Var_Samp_Fields = {
  __typename?:'recruiting_allocation_position_var_samp_fields';
  allocation_id?:Maybe<Scalars['Float']>;
  position_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Var_Samp_Order_By = {
  allocation_id?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recruiting_Allocation_Position_Variance_Fields = {
  __typename?:'recruiting_allocation_position_variance_fields';
  allocation_id?:Maybe<Scalars['Float']>;
  position_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recruiting.allocation_position" */
export type Recruiting_Allocation_Position_Variance_Order_By = {
  allocation_id?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
};

/** columns and relationships of "recruiting.candidate" */
export type Recruiting_Candidate = {
  __typename?:'recruiting_candidate';
  /** An object relationship */
  assigned_to?:Maybe<Common_User>;
  assigned_user_id?:Maybe<Scalars['Int']>;
  avatar_url?:Maybe<Scalars['String']>;
  /** An array relationship */
  candidate_allocations:Array<Recruiting_Candidate_Allocation>;
  /** An aggregate relationship */
  candidate_allocations_aggregate:Recruiting_Candidate_Allocation_Aggregate;
  /** An array relationship */
  candidate_attachments:Array<Recruiting_Candidate_Attachment>;
  /** An aggregate relationship */
  candidate_attachments_aggregate:Recruiting_Candidate_Attachment_Aggregate;
  /** An array relationship */
  candidate_notes:Array<Recruiting_Candidate_Note>;
  /** An aggregate relationship */
  candidate_notes_aggregate:Recruiting_Candidate_Note_Aggregate;
  /** An array relationship */
  candidate_position_groups:Array<Recruiting_Candidate_Position_Group>;
  /** An aggregate relationship */
  candidate_position_groups_aggregate:Recruiting_Candidate_Position_Group_Aggregate;
  /** An array relationship */
  candidate_user_interviewers:Array<Recruiting_Candidate_User_Interviewer>;
  /** An aggregate relationship */
  candidate_user_interviewers_aggregate:Recruiting_Candidate_User_Interviewer_Aggregate;
  /** An object relationship */
  city?:Maybe<Common_City>;
  city_id?:Maybe<Scalars['Int']>;
  /** An object relationship */
  country?:Maybe<Common_Country>;
  country_id?:Maybe<Scalars['Int']>;
  created_at:Scalars['timestamptz'];
  /** An object relationship */
  created_by:Common_User;
  created_by_user_id:Scalars['Int'];
  current_employer?:Maybe<Scalars['String']>;
  /** An array relationship */
  customer_team_slots:Array<Staffing_Customer_Team_Slot>;
  /** An aggregate relationship */
  customer_team_slots_aggregate:Staffing_Customer_Team_Slot_Aggregate;
  /** A computed field, executes function "recruiting.get_candidate_cv_file_url" */
  cv_file_url?:Maybe<Scalars['String']>;
  cv_google_doc_ref?:Maybe<Scalars['String']>;
  email?:Maybe<Scalars['String']>;
  experience_in_years?:Maybe<Scalars['Int']>;
  /** An object relationship */
  final_allocation?:Maybe<Recruiting_Candidate_Allocation>;
  finalized_allocation_id?:Maybe<Scalars['Int']>;
  first_name:Scalars['String'];
  first_name_i18n?:Maybe<Scalars['String']>;
  id:Scalars['Int'];
  instant_messenger_id?:Maybe<Scalars['String']>;
  interview_flow_id_deprecated?:Maybe<Scalars['Int']>;
  /** An object relationship */
  interview_flow_stage?:Maybe<Recruiting_Interview_Flow_Stage>;
  interview_flow_stage_id?:Maybe<Scalars['Int']>;
  /** An object relationship */
  interview_flow_substage?:Maybe<Recruiting_Interview_Flow_Substage>;
  interview_flow_substage_id?:Maybe<Scalars['Int']>;
  is_on_flow_deprecated:Scalars['Boolean'];
  last_name:Scalars['String'];
  last_name_i18n?:Maybe<Scalars['String']>;
  linkedin_profile_url?:Maybe<Scalars['String']>;
  middle_name_i18n?:Maybe<Scalars['String']>;
  /** An array relationship */
  offers:Array<Recruiting_Offer>;
  /** An aggregate relationship */
  offers_aggregate:Recruiting_Offer_Aggregate;
  phone?:Maybe<Scalars['String']>;
  /** An object relationship */
  recommended_by?:Maybe<Staffing_Staff>;
  recommended_by_staff_id?:Maybe<Scalars['Int']>;
  /** An object relationship */
  recruited_by?:Maybe<Common_User>;
  recruited_by_user_id?:Maybe<Scalars['Int']>;
  /** An object relationship */
  scheduled_call?:Maybe<Common_Scheduled_Event>;
  scheduled_call_id?:Maybe<Scalars['Int']>;
  /** An array relationship */
  scheduled_calls:Array<Recruiting_Candidate_Scheduled_Event>;
  /** An aggregate relationship */
  scheduled_calls_aggregate:Recruiting_Candidate_Scheduled_Event_Aggregate;
  secondary_email?:Maybe<Scalars['String']>;
  /** An array relationship */
  skill_tags:Array<Recruiting_Candidate_Skill_Tag>;
  /** An aggregate relationship */
  skill_tags_aggregate:Recruiting_Candidate_Skill_Tag_Aggregate;
  source?:Maybe<Scalars['String']>;
  /** An object relationship */
  sourced_by?:Maybe<Common_User>;
  sourced_by_user_id?:Maybe<Scalars['Int']>;
  /** An object relationship */
  staff?:Maybe<Staffing_Staff>;
  staff_id?:Maybe<Scalars['Int']>;
  status?:Maybe<Recruiting_Candidate_Status_Enum>;
  status_date?:Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  status_reason?:Maybe<Recruiting_Candidate_Status_Reason>;
  status_reason_id?:Maybe<Scalars['Int']>;
  status_reason_notes?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateCandidate_AllocationsArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Allocation_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Allocation_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Allocation_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateCandidate_Allocations_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Allocation_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Allocation_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Allocation_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateCandidate_AttachmentsArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Attachment_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Attachment_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Attachment_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateCandidate_Attachments_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Attachment_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Attachment_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Attachment_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateCandidate_NotesArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Note_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Note_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Note_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateCandidate_Notes_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Note_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Note_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Note_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateCandidate_Position_GroupsArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Position_Group_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateCandidate_Position_Groups_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Position_Group_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateCandidate_User_InterviewersArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_User_Interviewer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_User_Interviewer_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_User_Interviewer_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateCandidate_User_Interviewers_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_User_Interviewer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_User_Interviewer_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_User_Interviewer_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateCustomer_Team_SlotsArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateCustomer_Team_Slots_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateOffersArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Offer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Offer_Order_By>>;
  where?:InputMaybe<Recruiting_Offer_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateOffers_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Offer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Offer_Order_By>>;
  where?:InputMaybe<Recruiting_Offer_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateScheduled_CallsArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateScheduled_Calls_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateSkill_TagsArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Skill_Tag_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate" */
export type Recruiting_CandidateSkill_Tags_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Skill_Tag_Bool_Exp>;
};

/** aggregated selection of "recruiting.candidate" */
export type Recruiting_Candidate_Aggregate = {
  __typename?:'recruiting_candidate_aggregate';
  aggregate?:Maybe<Recruiting_Candidate_Aggregate_Fields>;
  nodes:Array<Recruiting_Candidate>;
};

export type Recruiting_Candidate_Aggregate_Bool_Exp = {
  bool_and?:InputMaybe<Recruiting_Candidate_Aggregate_Bool_Exp_Bool_And>;
  bool_or?:InputMaybe<Recruiting_Candidate_Aggregate_Bool_Exp_Bool_Or>;
  count?:InputMaybe<Recruiting_Candidate_Aggregate_Bool_Exp_Count>;
};

export type Recruiting_Candidate_Aggregate_Bool_Exp_Bool_And = {
  arguments:Recruiting_Candidate_Select_Column_Recruiting_Candidate_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
  predicate:Boolean_Comparison_Exp;
};

export type Recruiting_Candidate_Aggregate_Bool_Exp_Bool_Or = {
  arguments:Recruiting_Candidate_Select_Column_Recruiting_Candidate_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
  predicate:Boolean_Comparison_Exp;
};

export type Recruiting_Candidate_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Recruiting_Candidate_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "recruiting.candidate" */
export type Recruiting_Candidate_Aggregate_Fields = {
  __typename?:'recruiting_candidate_aggregate_fields';
  avg?:Maybe<Recruiting_Candidate_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Candidate_Max_Fields>;
  min?:Maybe<Recruiting_Candidate_Min_Fields>;
  stddev?:Maybe<Recruiting_Candidate_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Candidate_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Candidate_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Candidate_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Candidate_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Candidate_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Candidate_Variance_Fields>;
};


/** aggregate fields of "recruiting.candidate" */
export type Recruiting_Candidate_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Candidate_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recruiting.candidate" */
export type Recruiting_Candidate_Aggregate_Order_By = {
  avg?:InputMaybe<Recruiting_Candidate_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Recruiting_Candidate_Max_Order_By>;
  min?:InputMaybe<Recruiting_Candidate_Min_Order_By>;
  stddev?:InputMaybe<Recruiting_Candidate_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Recruiting_Candidate_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Recruiting_Candidate_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Recruiting_Candidate_Sum_Order_By>;
  var_pop?:InputMaybe<Recruiting_Candidate_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Recruiting_Candidate_Var_Samp_Order_By>;
  variance?:InputMaybe<Recruiting_Candidate_Variance_Order_By>;
};

/** columns and relationships of "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation = {
  __typename?:'recruiting_candidate_allocation';
  /** An array relationship */
  allocation_positions:Array<Recruiting_Allocation_Position>;
  /** An aggregate relationship */
  allocation_positions_aggregate:Recruiting_Allocation_Position_Aggregate;
  /** An object relationship */
  candidate:Recruiting_Candidate;
  candidate_id:Scalars['Int'];
  created_at:Scalars['timestamptz'];
  /** An array relationship */
  finalized_slots:Array<Staffing_Customer_Team_Slot>;
  /** An aggregate relationship */
  finalized_slots_aggregate:Staffing_Customer_Team_Slot_Aggregate;
  id:Scalars['Int'];
  is_final:Scalars['Boolean'];
  notes?:Maybe<Scalars['String']>;
  updated_at:Scalars['timestamptz'];
  /** An object relationship */
  user:Common_User;
  /** // proposed by | finalized by */
  user_id:Scalars['Int'];
};


/** columns and relationships of "recruiting.candidate_allocation" */
export type Recruiting_Candidate_AllocationAllocation_PositionsArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Allocation_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Allocation_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Allocation_Position_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate_allocation" */
export type Recruiting_Candidate_AllocationAllocation_Positions_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Allocation_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Allocation_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Allocation_Position_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate_allocation" */
export type Recruiting_Candidate_AllocationFinalized_SlotsArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
};


/** columns and relationships of "recruiting.candidate_allocation" */
export type Recruiting_Candidate_AllocationFinalized_Slots_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
};

/** aggregated selection of "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Aggregate = {
  __typename?:'recruiting_candidate_allocation_aggregate';
  aggregate?:Maybe<Recruiting_Candidate_Allocation_Aggregate_Fields>;
  nodes:Array<Recruiting_Candidate_Allocation>;
};

export type Recruiting_Candidate_Allocation_Aggregate_Bool_Exp = {
  bool_and?:InputMaybe<Recruiting_Candidate_Allocation_Aggregate_Bool_Exp_Bool_And>;
  bool_or?:InputMaybe<Recruiting_Candidate_Allocation_Aggregate_Bool_Exp_Bool_Or>;
  count?:InputMaybe<Recruiting_Candidate_Allocation_Aggregate_Bool_Exp_Count>;
};

export type Recruiting_Candidate_Allocation_Aggregate_Bool_Exp_Bool_And = {
  arguments:Recruiting_Candidate_Allocation_Select_Column_Recruiting_Candidate_Allocation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Candidate_Allocation_Bool_Exp>;
  predicate:Boolean_Comparison_Exp;
};

export type Recruiting_Candidate_Allocation_Aggregate_Bool_Exp_Bool_Or = {
  arguments:Recruiting_Candidate_Allocation_Select_Column_Recruiting_Candidate_Allocation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Candidate_Allocation_Bool_Exp>;
  predicate:Boolean_Comparison_Exp;
};

export type Recruiting_Candidate_Allocation_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Recruiting_Candidate_Allocation_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Candidate_Allocation_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Aggregate_Fields = {
  __typename?:'recruiting_candidate_allocation_aggregate_fields';
  avg?:Maybe<Recruiting_Candidate_Allocation_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Candidate_Allocation_Max_Fields>;
  min?:Maybe<Recruiting_Candidate_Allocation_Min_Fields>;
  stddev?:Maybe<Recruiting_Candidate_Allocation_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Candidate_Allocation_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Candidate_Allocation_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Candidate_Allocation_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Candidate_Allocation_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Candidate_Allocation_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Candidate_Allocation_Variance_Fields>;
};


/** aggregate fields of "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Candidate_Allocation_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Aggregate_Order_By = {
  avg?:InputMaybe<Recruiting_Candidate_Allocation_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Recruiting_Candidate_Allocation_Max_Order_By>;
  min?:InputMaybe<Recruiting_Candidate_Allocation_Min_Order_By>;
  stddev?:InputMaybe<Recruiting_Candidate_Allocation_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Recruiting_Candidate_Allocation_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Recruiting_Candidate_Allocation_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Recruiting_Candidate_Allocation_Sum_Order_By>;
  var_pop?:InputMaybe<Recruiting_Candidate_Allocation_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Recruiting_Candidate_Allocation_Var_Samp_Order_By>;
  variance?:InputMaybe<Recruiting_Candidate_Allocation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Arr_Rel_Insert_Input = {
  data:Array<Recruiting_Candidate_Allocation_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Candidate_Allocation_On_Conflict>;
};

/** aggregate avg on columns */
export type Recruiting_Candidate_Allocation_Avg_Fields = {
  __typename?:'recruiting_candidate_allocation_avg_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  /** // proposed by | finalized by */
  user_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Avg_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  /** // proposed by | finalized by */
  user_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recruiting.candidate_allocation". All fields are combined with a logical 'AND'. */
export type Recruiting_Candidate_Allocation_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Candidate_Allocation_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Candidate_Allocation_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Candidate_Allocation_Bool_Exp>>;
  allocation_positions?:InputMaybe<Recruiting_Allocation_Position_Bool_Exp>;
  allocation_positions_aggregate?:InputMaybe<Recruiting_Allocation_Position_Aggregate_Bool_Exp>;
  candidate?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
  candidate_id?:InputMaybe<Int_Comparison_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  finalized_slots?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
  finalized_slots_aggregate?:InputMaybe<Staffing_Customer_Team_Slot_Aggregate_Bool_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  is_final?:InputMaybe<Boolean_Comparison_Exp>;
  notes?:InputMaybe<String_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  user?:InputMaybe<Common_User_Bool_Exp>;
  user_id?:InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.candidate_allocation" */
export enum Recruiting_Candidate_Allocation_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  CandidateProposedAllocationIdKey = 'candidate_proposed_allocation_id_key',
  /** unique or primary key constraint on columns "id" */
  CandidateProposedAllocationPkey = 'candidate_proposed_allocation_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Inc_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  id?:InputMaybe<Scalars['Int']>;
  /** // proposed by | finalized by */
  user_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Insert_Input = {
  allocation_positions?:InputMaybe<Recruiting_Allocation_Position_Arr_Rel_Insert_Input>;
  candidate?:InputMaybe<Recruiting_Candidate_Obj_Rel_Insert_Input>;
  candidate_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  finalized_slots?:InputMaybe<Staffing_Customer_Team_Slot_Arr_Rel_Insert_Input>;
  id?:InputMaybe<Scalars['Int']>;
  is_final?:InputMaybe<Scalars['Boolean']>;
  notes?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user?:InputMaybe<Common_User_Obj_Rel_Insert_Input>;
  /** // proposed by | finalized by */
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Recruiting_Candidate_Allocation_Max_Fields = {
  __typename?:'recruiting_candidate_allocation_max_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  notes?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
  /** // proposed by | finalized by */
  user_id?:Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Max_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  notes?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  /** // proposed by | finalized by */
  user_id?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recruiting_Candidate_Allocation_Min_Fields = {
  __typename?:'recruiting_candidate_allocation_min_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  notes?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
  /** // proposed by | finalized by */
  user_id?:Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Min_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  notes?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  /** // proposed by | finalized by */
  user_id?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Mutation_Response = {
  __typename?:'recruiting_candidate_allocation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Candidate_Allocation>;
};

/** input type for inserting object relation for remote table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Obj_Rel_Insert_Input = {
  data:Recruiting_Candidate_Allocation_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Candidate_Allocation_On_Conflict>;
};

/** on_conflict condition type for table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_On_Conflict = {
  constraint:Recruiting_Candidate_Allocation_Constraint;
  update_columns?:Array<Recruiting_Candidate_Allocation_Update_Column>;
  where?:InputMaybe<Recruiting_Candidate_Allocation_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.candidate_allocation". */
export type Recruiting_Candidate_Allocation_Order_By = {
  allocation_positions_aggregate?:InputMaybe<Recruiting_Allocation_Position_Aggregate_Order_By>;
  candidate?:InputMaybe<Recruiting_Candidate_Order_By>;
  candidate_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  finalized_slots_aggregate?:InputMaybe<Staffing_Customer_Team_Slot_Aggregate_Order_By>;
  id?:InputMaybe<Order_By>;
  is_final?:InputMaybe<Order_By>;
  notes?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user?:InputMaybe<Common_User_Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.candidate_allocation */
export type Recruiting_Candidate_Allocation_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "recruiting.candidate_allocation" */
export enum Recruiting_Candidate_Allocation_Select_Column 
{
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsFinal = 'is_final',
  /** column name */
  Notes = 'notes',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "recruiting_candidate_allocation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "recruiting.candidate_allocation" */
export enum Recruiting_Candidate_Allocation_Select_Column_Recruiting_Candidate_Allocation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns 
{
  /** column name */
  IsFinal = 'is_final'
}

/** select "recruiting_candidate_allocation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "recruiting.candidate_allocation" */
export enum Recruiting_Candidate_Allocation_Select_Column_Recruiting_Candidate_Allocation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns 
{
  /** column name */
  IsFinal = 'is_final'
}

/** input type for updating data in table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Set_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  is_final?:InputMaybe<Scalars['Boolean']>;
  notes?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  /** // proposed by | finalized by */
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Recruiting_Candidate_Allocation_Stddev_Fields = {
  __typename?:'recruiting_candidate_allocation_stddev_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  /** // proposed by | finalized by */
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Stddev_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  /** // proposed by | finalized by */
  user_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Candidate_Allocation_Stddev_Pop_Fields = {
  __typename?:'recruiting_candidate_allocation_stddev_pop_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  /** // proposed by | finalized by */
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Stddev_Pop_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  /** // proposed by | finalized by */
  user_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Candidate_Allocation_Stddev_Samp_Fields = {
  __typename?:'recruiting_candidate_allocation_stddev_samp_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  /** // proposed by | finalized by */
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Stddev_Samp_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  /** // proposed by | finalized by */
  user_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recruiting_candidate_allocation" */
export type Recruiting_Candidate_Allocation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Candidate_Allocation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Candidate_Allocation_Stream_Cursor_Value_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  is_final?:InputMaybe<Scalars['Boolean']>;
  notes?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  /** // proposed by | finalized by */
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Recruiting_Candidate_Allocation_Sum_Fields = {
  __typename?:'recruiting_candidate_allocation_sum_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  id?:Maybe<Scalars['Int']>;
  /** // proposed by | finalized by */
  user_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Sum_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  /** // proposed by | finalized by */
  user_id?:InputMaybe<Order_By>;
};

/** update columns of table "recruiting.candidate_allocation" */
export enum Recruiting_Candidate_Allocation_Update_Column 
{
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsFinal = 'is_final',
  /** column name */
  Notes = 'notes',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Recruiting_Candidate_Allocation_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Candidate_Allocation_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Candidate_Allocation_Set_Input>;
  where:Recruiting_Candidate_Allocation_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Candidate_Allocation_Var_Pop_Fields = {
  __typename?:'recruiting_candidate_allocation_var_pop_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  /** // proposed by | finalized by */
  user_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Var_Pop_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  /** // proposed by | finalized by */
  user_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recruiting_Candidate_Allocation_Var_Samp_Fields = {
  __typename?:'recruiting_candidate_allocation_var_samp_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  /** // proposed by | finalized by */
  user_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Var_Samp_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  /** // proposed by | finalized by */
  user_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recruiting_Candidate_Allocation_Variance_Fields = {
  __typename?:'recruiting_candidate_allocation_variance_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  /** // proposed by | finalized by */
  user_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recruiting.candidate_allocation" */
export type Recruiting_Candidate_Allocation_Variance_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  /** // proposed by | finalized by */
  user_id?:InputMaybe<Order_By>;
};

/** input type for inserting array relation for remote table "recruiting.candidate" */
export type Recruiting_Candidate_Arr_Rel_Insert_Input = {
  data:Array<Recruiting_Candidate_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Candidate_On_Conflict>;
};

/** columns and relationships of "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment = {
  __typename?:'recruiting_candidate_attachment';
  /** An object relationship */
  attachment:Common_Attachment;
  attachment_id:Scalars['Int'];
  /** An object relationship */
  candidate:Recruiting_Candidate;
  candidate_id:Scalars['Int'];
};

/** aggregated selection of "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Aggregate = {
  __typename?:'recruiting_candidate_attachment_aggregate';
  aggregate?:Maybe<Recruiting_Candidate_Attachment_Aggregate_Fields>;
  nodes:Array<Recruiting_Candidate_Attachment>;
};

export type Recruiting_Candidate_Attachment_Aggregate_Bool_Exp = {
  count?:InputMaybe<Recruiting_Candidate_Attachment_Aggregate_Bool_Exp_Count>;
};

export type Recruiting_Candidate_Attachment_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Recruiting_Candidate_Attachment_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Candidate_Attachment_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Aggregate_Fields = {
  __typename?:'recruiting_candidate_attachment_aggregate_fields';
  avg?:Maybe<Recruiting_Candidate_Attachment_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Candidate_Attachment_Max_Fields>;
  min?:Maybe<Recruiting_Candidate_Attachment_Min_Fields>;
  stddev?:Maybe<Recruiting_Candidate_Attachment_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Candidate_Attachment_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Candidate_Attachment_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Candidate_Attachment_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Candidate_Attachment_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Candidate_Attachment_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Candidate_Attachment_Variance_Fields>;
};


/** aggregate fields of "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Candidate_Attachment_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Aggregate_Order_By = {
  avg?:InputMaybe<Recruiting_Candidate_Attachment_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Recruiting_Candidate_Attachment_Max_Order_By>;
  min?:InputMaybe<Recruiting_Candidate_Attachment_Min_Order_By>;
  stddev?:InputMaybe<Recruiting_Candidate_Attachment_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Recruiting_Candidate_Attachment_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Recruiting_Candidate_Attachment_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Recruiting_Candidate_Attachment_Sum_Order_By>;
  var_pop?:InputMaybe<Recruiting_Candidate_Attachment_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Recruiting_Candidate_Attachment_Var_Samp_Order_By>;
  variance?:InputMaybe<Recruiting_Candidate_Attachment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Arr_Rel_Insert_Input = {
  data:Array<Recruiting_Candidate_Attachment_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Candidate_Attachment_On_Conflict>;
};

/** aggregate avg on columns */
export type Recruiting_Candidate_Attachment_Avg_Fields = {
  __typename?:'recruiting_candidate_attachment_avg_fields';
  attachment_id?:Maybe<Scalars['Float']>;
  candidate_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Avg_Order_By = {
  attachment_id?:InputMaybe<Order_By>;
  candidate_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recruiting.candidate_attachment". All fields are combined with a logical 'AND'. */
export type Recruiting_Candidate_Attachment_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Candidate_Attachment_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Candidate_Attachment_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Candidate_Attachment_Bool_Exp>>;
  attachment?:InputMaybe<Common_Attachment_Bool_Exp>;
  attachment_id?:InputMaybe<Int_Comparison_Exp>;
  candidate?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
  candidate_id?:InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.candidate_attachment" */
export enum Recruiting_Candidate_Attachment_Constraint 
{
  /** unique or primary key constraint on columns "candidate_id", "attachment_id" */
  CandidateAttachmentPkey = 'candidate_attachment_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Inc_Input = {
  attachment_id?:InputMaybe<Scalars['Int']>;
  candidate_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Insert_Input = {
  attachment?:InputMaybe<Common_Attachment_Obj_Rel_Insert_Input>;
  attachment_id?:InputMaybe<Scalars['Int']>;
  candidate?:InputMaybe<Recruiting_Candidate_Obj_Rel_Insert_Input>;
  candidate_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Recruiting_Candidate_Attachment_Max_Fields = {
  __typename?:'recruiting_candidate_attachment_max_fields';
  attachment_id?:Maybe<Scalars['Int']>;
  candidate_id?:Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Max_Order_By = {
  attachment_id?:InputMaybe<Order_By>;
  candidate_id?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recruiting_Candidate_Attachment_Min_Fields = {
  __typename?:'recruiting_candidate_attachment_min_fields';
  attachment_id?:Maybe<Scalars['Int']>;
  candidate_id?:Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Min_Order_By = {
  attachment_id?:InputMaybe<Order_By>;
  candidate_id?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Mutation_Response = {
  __typename?:'recruiting_candidate_attachment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Candidate_Attachment>;
};

/** on_conflict condition type for table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_On_Conflict = {
  constraint:Recruiting_Candidate_Attachment_Constraint;
  update_columns?:Array<Recruiting_Candidate_Attachment_Update_Column>;
  where?:InputMaybe<Recruiting_Candidate_Attachment_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.candidate_attachment". */
export type Recruiting_Candidate_Attachment_Order_By = {
  attachment?:InputMaybe<Common_Attachment_Order_By>;
  attachment_id?:InputMaybe<Order_By>;
  candidate?:InputMaybe<Recruiting_Candidate_Order_By>;
  candidate_id?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.candidate_attachment */
export type Recruiting_Candidate_Attachment_Pk_Columns_Input = {
  attachment_id:Scalars['Int'];
  candidate_id:Scalars['Int'];
};

/** select columns of table "recruiting.candidate_attachment" */
export enum Recruiting_Candidate_Attachment_Select_Column 
{
  /** column name */
  AttachmentId = 'attachment_id',
  /** column name */
  CandidateId = 'candidate_id'
}

/** input type for updating data in table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Set_Input = {
  attachment_id?:InputMaybe<Scalars['Int']>;
  candidate_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Recruiting_Candidate_Attachment_Stddev_Fields = {
  __typename?:'recruiting_candidate_attachment_stddev_fields';
  attachment_id?:Maybe<Scalars['Float']>;
  candidate_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Stddev_Order_By = {
  attachment_id?:InputMaybe<Order_By>;
  candidate_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Candidate_Attachment_Stddev_Pop_Fields = {
  __typename?:'recruiting_candidate_attachment_stddev_pop_fields';
  attachment_id?:Maybe<Scalars['Float']>;
  candidate_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Stddev_Pop_Order_By = {
  attachment_id?:InputMaybe<Order_By>;
  candidate_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Candidate_Attachment_Stddev_Samp_Fields = {
  __typename?:'recruiting_candidate_attachment_stddev_samp_fields';
  attachment_id?:Maybe<Scalars['Float']>;
  candidate_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Stddev_Samp_Order_By = {
  attachment_id?:InputMaybe<Order_By>;
  candidate_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recruiting_candidate_attachment" */
export type Recruiting_Candidate_Attachment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Candidate_Attachment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Candidate_Attachment_Stream_Cursor_Value_Input = {
  attachment_id?:InputMaybe<Scalars['Int']>;
  candidate_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Recruiting_Candidate_Attachment_Sum_Fields = {
  __typename?:'recruiting_candidate_attachment_sum_fields';
  attachment_id?:Maybe<Scalars['Int']>;
  candidate_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Sum_Order_By = {
  attachment_id?:InputMaybe<Order_By>;
  candidate_id?:InputMaybe<Order_By>;
};

/** update columns of table "recruiting.candidate_attachment" */
export enum Recruiting_Candidate_Attachment_Update_Column 
{
  /** column name */
  AttachmentId = 'attachment_id',
  /** column name */
  CandidateId = 'candidate_id'
}

export type Recruiting_Candidate_Attachment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Candidate_Attachment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Candidate_Attachment_Set_Input>;
  where:Recruiting_Candidate_Attachment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Candidate_Attachment_Var_Pop_Fields = {
  __typename?:'recruiting_candidate_attachment_var_pop_fields';
  attachment_id?:Maybe<Scalars['Float']>;
  candidate_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Var_Pop_Order_By = {
  attachment_id?:InputMaybe<Order_By>;
  candidate_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recruiting_Candidate_Attachment_Var_Samp_Fields = {
  __typename?:'recruiting_candidate_attachment_var_samp_fields';
  attachment_id?:Maybe<Scalars['Float']>;
  candidate_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Var_Samp_Order_By = {
  attachment_id?:InputMaybe<Order_By>;
  candidate_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recruiting_Candidate_Attachment_Variance_Fields = {
  __typename?:'recruiting_candidate_attachment_variance_fields';
  attachment_id?:Maybe<Scalars['Float']>;
  candidate_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recruiting.candidate_attachment" */
export type Recruiting_Candidate_Attachment_Variance_Order_By = {
  attachment_id?:InputMaybe<Order_By>;
  candidate_id?:InputMaybe<Order_By>;
};

/** aggregate avg on columns */
export type Recruiting_Candidate_Avg_Fields = {
  __typename?:'recruiting_candidate_avg_fields';
  assigned_user_id?:Maybe<Scalars['Float']>;
  city_id?:Maybe<Scalars['Float']>;
  country_id?:Maybe<Scalars['Float']>;
  created_by_user_id?:Maybe<Scalars['Float']>;
  experience_in_years?:Maybe<Scalars['Float']>;
  finalized_allocation_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  interview_flow_id_deprecated?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
  interview_flow_substage_id?:Maybe<Scalars['Float']>;
  recommended_by_staff_id?:Maybe<Scalars['Float']>;
  recruited_by_user_id?:Maybe<Scalars['Float']>;
  scheduled_call_id?:Maybe<Scalars['Float']>;
  sourced_by_user_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
  status_reason_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recruiting.candidate" */
export type Recruiting_Candidate_Avg_Order_By = {
  assigned_user_id?:InputMaybe<Order_By>;
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  created_by_user_id?:InputMaybe<Order_By>;
  experience_in_years?:InputMaybe<Order_By>;
  finalized_allocation_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow_id_deprecated?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  interview_flow_substage_id?:InputMaybe<Order_By>;
  recommended_by_staff_id?:InputMaybe<Order_By>;
  recruited_by_user_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
  sourced_by_user_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
  status_reason_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recruiting.candidate". All fields are combined with a logical 'AND'. */
export type Recruiting_Candidate_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Candidate_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Candidate_Bool_Exp>>;
  assigned_to?:InputMaybe<Common_User_Bool_Exp>;
  assigned_user_id?:InputMaybe<Int_Comparison_Exp>;
  avatar_url?:InputMaybe<String_Comparison_Exp>;
  candidate_allocations?:InputMaybe<Recruiting_Candidate_Allocation_Bool_Exp>;
  candidate_allocations_aggregate?:InputMaybe<Recruiting_Candidate_Allocation_Aggregate_Bool_Exp>;
  candidate_attachments?:InputMaybe<Recruiting_Candidate_Attachment_Bool_Exp>;
  candidate_attachments_aggregate?:InputMaybe<Recruiting_Candidate_Attachment_Aggregate_Bool_Exp>;
  candidate_notes?:InputMaybe<Recruiting_Candidate_Note_Bool_Exp>;
  candidate_notes_aggregate?:InputMaybe<Recruiting_Candidate_Note_Aggregate_Bool_Exp>;
  candidate_position_groups?:InputMaybe<Recruiting_Candidate_Position_Group_Bool_Exp>;
  candidate_position_groups_aggregate?:InputMaybe<Recruiting_Candidate_Position_Group_Aggregate_Bool_Exp>;
  candidate_user_interviewers?:InputMaybe<Recruiting_Candidate_User_Interviewer_Bool_Exp>;
  candidate_user_interviewers_aggregate?:InputMaybe<Recruiting_Candidate_User_Interviewer_Aggregate_Bool_Exp>;
  city?:InputMaybe<Common_City_Bool_Exp>;
  city_id?:InputMaybe<Int_Comparison_Exp>;
  country?:InputMaybe<Common_Country_Bool_Exp>;
  country_id?:InputMaybe<Int_Comparison_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?:InputMaybe<Common_User_Bool_Exp>;
  created_by_user_id?:InputMaybe<Int_Comparison_Exp>;
  current_employer?:InputMaybe<String_Comparison_Exp>;
  customer_team_slots?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
  customer_team_slots_aggregate?:InputMaybe<Staffing_Customer_Team_Slot_Aggregate_Bool_Exp>;
  cv_file_url?:InputMaybe<String_Comparison_Exp>;
  cv_google_doc_ref?:InputMaybe<String_Comparison_Exp>;
  email?:InputMaybe<String_Comparison_Exp>;
  experience_in_years?:InputMaybe<Int_Comparison_Exp>;
  final_allocation?:InputMaybe<Recruiting_Candidate_Allocation_Bool_Exp>;
  finalized_allocation_id?:InputMaybe<Int_Comparison_Exp>;
  first_name?:InputMaybe<String_Comparison_Exp>;
  first_name_i18n?:InputMaybe<String_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  instant_messenger_id?:InputMaybe<String_Comparison_Exp>;
  interview_flow_id_deprecated?:InputMaybe<Int_Comparison_Exp>;
  interview_flow_stage?:InputMaybe<Recruiting_Interview_Flow_Stage_Bool_Exp>;
  interview_flow_stage_id?:InputMaybe<Int_Comparison_Exp>;
  interview_flow_substage?:InputMaybe<Recruiting_Interview_Flow_Substage_Bool_Exp>;
  interview_flow_substage_id?:InputMaybe<Int_Comparison_Exp>;
  is_on_flow_deprecated?:InputMaybe<Boolean_Comparison_Exp>;
  last_name?:InputMaybe<String_Comparison_Exp>;
  last_name_i18n?:InputMaybe<String_Comparison_Exp>;
  linkedin_profile_url?:InputMaybe<String_Comparison_Exp>;
  middle_name_i18n?:InputMaybe<String_Comparison_Exp>;
  offers?:InputMaybe<Recruiting_Offer_Bool_Exp>;
  offers_aggregate?:InputMaybe<Recruiting_Offer_Aggregate_Bool_Exp>;
  phone?:InputMaybe<String_Comparison_Exp>;
  recommended_by?:InputMaybe<Staffing_Staff_Bool_Exp>;
  recommended_by_staff_id?:InputMaybe<Int_Comparison_Exp>;
  recruited_by?:InputMaybe<Common_User_Bool_Exp>;
  recruited_by_user_id?:InputMaybe<Int_Comparison_Exp>;
  scheduled_call?:InputMaybe<Common_Scheduled_Event_Bool_Exp>;
  scheduled_call_id?:InputMaybe<Int_Comparison_Exp>;
  scheduled_calls?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Bool_Exp>;
  scheduled_calls_aggregate?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Aggregate_Bool_Exp>;
  secondary_email?:InputMaybe<String_Comparison_Exp>;
  skill_tags?:InputMaybe<Recruiting_Candidate_Skill_Tag_Bool_Exp>;
  skill_tags_aggregate?:InputMaybe<Recruiting_Candidate_Skill_Tag_Aggregate_Bool_Exp>;
  source?:InputMaybe<String_Comparison_Exp>;
  sourced_by?:InputMaybe<Common_User_Bool_Exp>;
  sourced_by_user_id?:InputMaybe<Int_Comparison_Exp>;
  staff?:InputMaybe<Staffing_Staff_Bool_Exp>;
  staff_id?:InputMaybe<Int_Comparison_Exp>;
  status?:InputMaybe<Recruiting_Candidate_Status_Enum_Comparison_Exp>;
  status_date?:InputMaybe<Timestamptz_Comparison_Exp>;
  status_reason?:InputMaybe<Recruiting_Candidate_Status_Reason_Bool_Exp>;
  status_reason_id?:InputMaybe<Int_Comparison_Exp>;
  status_reason_notes?:InputMaybe<String_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.candidate" */
export enum Recruiting_Candidate_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  CandidatesPkey = 'candidates_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.candidate" */
export type Recruiting_Candidate_Inc_Input = {
  assigned_user_id?:InputMaybe<Scalars['Int']>;
  city_id?:InputMaybe<Scalars['Int']>;
  country_id?:InputMaybe<Scalars['Int']>;
  created_by_user_id?:InputMaybe<Scalars['Int']>;
  experience_in_years?:InputMaybe<Scalars['Int']>;
  finalized_allocation_id?:InputMaybe<Scalars['Int']>;
  id?:InputMaybe<Scalars['Int']>;
  interview_flow_id_deprecated?:InputMaybe<Scalars['Int']>;
  interview_flow_stage_id?:InputMaybe<Scalars['Int']>;
  interview_flow_substage_id?:InputMaybe<Scalars['Int']>;
  recommended_by_staff_id?:InputMaybe<Scalars['Int']>;
  recruited_by_user_id?:InputMaybe<Scalars['Int']>;
  scheduled_call_id?:InputMaybe<Scalars['Int']>;
  sourced_by_user_id?:InputMaybe<Scalars['Int']>;
  staff_id?:InputMaybe<Scalars['Int']>;
  status_reason_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.candidate" */
export type Recruiting_Candidate_Insert_Input = {
  assigned_to?:InputMaybe<Common_User_Obj_Rel_Insert_Input>;
  assigned_user_id?:InputMaybe<Scalars['Int']>;
  avatar_url?:InputMaybe<Scalars['String']>;
  candidate_allocations?:InputMaybe<Recruiting_Candidate_Allocation_Arr_Rel_Insert_Input>;
  candidate_attachments?:InputMaybe<Recruiting_Candidate_Attachment_Arr_Rel_Insert_Input>;
  candidate_notes?:InputMaybe<Recruiting_Candidate_Note_Arr_Rel_Insert_Input>;
  candidate_position_groups?:InputMaybe<Recruiting_Candidate_Position_Group_Arr_Rel_Insert_Input>;
  candidate_user_interviewers?:InputMaybe<Recruiting_Candidate_User_Interviewer_Arr_Rel_Insert_Input>;
  city?:InputMaybe<Common_City_Obj_Rel_Insert_Input>;
  city_id?:InputMaybe<Scalars['Int']>;
  country?:InputMaybe<Common_Country_Obj_Rel_Insert_Input>;
  country_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  created_by?:InputMaybe<Common_User_Obj_Rel_Insert_Input>;
  created_by_user_id?:InputMaybe<Scalars['Int']>;
  current_employer?:InputMaybe<Scalars['String']>;
  customer_team_slots?:InputMaybe<Staffing_Customer_Team_Slot_Arr_Rel_Insert_Input>;
  cv_google_doc_ref?:InputMaybe<Scalars['String']>;
  email?:InputMaybe<Scalars['String']>;
  experience_in_years?:InputMaybe<Scalars['Int']>;
  final_allocation?:InputMaybe<Recruiting_Candidate_Allocation_Obj_Rel_Insert_Input>;
  finalized_allocation_id?:InputMaybe<Scalars['Int']>;
  first_name?:InputMaybe<Scalars['String']>;
  first_name_i18n?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  instant_messenger_id?:InputMaybe<Scalars['String']>;
  interview_flow_id_deprecated?:InputMaybe<Scalars['Int']>;
  interview_flow_stage?:InputMaybe<Recruiting_Interview_Flow_Stage_Obj_Rel_Insert_Input>;
  interview_flow_stage_id?:InputMaybe<Scalars['Int']>;
  interview_flow_substage?:InputMaybe<Recruiting_Interview_Flow_Substage_Obj_Rel_Insert_Input>;
  interview_flow_substage_id?:InputMaybe<Scalars['Int']>;
  is_on_flow_deprecated?:InputMaybe<Scalars['Boolean']>;
  last_name?:InputMaybe<Scalars['String']>;
  last_name_i18n?:InputMaybe<Scalars['String']>;
  linkedin_profile_url?:InputMaybe<Scalars['String']>;
  middle_name_i18n?:InputMaybe<Scalars['String']>;
  offers?:InputMaybe<Recruiting_Offer_Arr_Rel_Insert_Input>;
  phone?:InputMaybe<Scalars['String']>;
  recommended_by?:InputMaybe<Staffing_Staff_Obj_Rel_Insert_Input>;
  recommended_by_staff_id?:InputMaybe<Scalars['Int']>;
  recruited_by?:InputMaybe<Common_User_Obj_Rel_Insert_Input>;
  recruited_by_user_id?:InputMaybe<Scalars['Int']>;
  scheduled_call?:InputMaybe<Common_Scheduled_Event_Obj_Rel_Insert_Input>;
  scheduled_call_id?:InputMaybe<Scalars['Int']>;
  scheduled_calls?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Arr_Rel_Insert_Input>;
  secondary_email?:InputMaybe<Scalars['String']>;
  skill_tags?:InputMaybe<Recruiting_Candidate_Skill_Tag_Arr_Rel_Insert_Input>;
  source?:InputMaybe<Scalars['String']>;
  sourced_by?:InputMaybe<Common_User_Obj_Rel_Insert_Input>;
  sourced_by_user_id?:InputMaybe<Scalars['Int']>;
  staff?:InputMaybe<Staffing_Staff_Obj_Rel_Insert_Input>;
  staff_id?:InputMaybe<Scalars['Int']>;
  status?:InputMaybe<Recruiting_Candidate_Status_Enum>;
  status_date?:InputMaybe<Scalars['timestamptz']>;
  status_reason?:InputMaybe<Recruiting_Candidate_Status_Reason_Obj_Rel_Insert_Input>;
  status_reason_id?:InputMaybe<Scalars['Int']>;
  status_reason_notes?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Recruiting_Candidate_Max_Fields = {
  __typename?:'recruiting_candidate_max_fields';
  assigned_user_id?:Maybe<Scalars['Int']>;
  avatar_url?:Maybe<Scalars['String']>;
  city_id?:Maybe<Scalars['Int']>;
  country_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  created_by_user_id?:Maybe<Scalars['Int']>;
  current_employer?:Maybe<Scalars['String']>;
  cv_google_doc_ref?:Maybe<Scalars['String']>;
  email?:Maybe<Scalars['String']>;
  experience_in_years?:Maybe<Scalars['Int']>;
  finalized_allocation_id?:Maybe<Scalars['Int']>;
  first_name?:Maybe<Scalars['String']>;
  first_name_i18n?:Maybe<Scalars['String']>;
  id?:Maybe<Scalars['Int']>;
  instant_messenger_id?:Maybe<Scalars['String']>;
  interview_flow_id_deprecated?:Maybe<Scalars['Int']>;
  interview_flow_stage_id?:Maybe<Scalars['Int']>;
  interview_flow_substage_id?:Maybe<Scalars['Int']>;
  last_name?:Maybe<Scalars['String']>;
  last_name_i18n?:Maybe<Scalars['String']>;
  linkedin_profile_url?:Maybe<Scalars['String']>;
  middle_name_i18n?:Maybe<Scalars['String']>;
  phone?:Maybe<Scalars['String']>;
  recommended_by_staff_id?:Maybe<Scalars['Int']>;
  recruited_by_user_id?:Maybe<Scalars['Int']>;
  scheduled_call_id?:Maybe<Scalars['Int']>;
  secondary_email?:Maybe<Scalars['String']>;
  source?:Maybe<Scalars['String']>;
  sourced_by_user_id?:Maybe<Scalars['Int']>;
  staff_id?:Maybe<Scalars['Int']>;
  status_date?:Maybe<Scalars['timestamptz']>;
  status_reason_id?:Maybe<Scalars['Int']>;
  status_reason_notes?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "recruiting.candidate" */
export type Recruiting_Candidate_Max_Order_By = {
  assigned_user_id?:InputMaybe<Order_By>;
  avatar_url?:InputMaybe<Order_By>;
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  created_by_user_id?:InputMaybe<Order_By>;
  current_employer?:InputMaybe<Order_By>;
  cv_google_doc_ref?:InputMaybe<Order_By>;
  email?:InputMaybe<Order_By>;
  experience_in_years?:InputMaybe<Order_By>;
  finalized_allocation_id?:InputMaybe<Order_By>;
  first_name?:InputMaybe<Order_By>;
  first_name_i18n?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  instant_messenger_id?:InputMaybe<Order_By>;
  interview_flow_id_deprecated?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  interview_flow_substage_id?:InputMaybe<Order_By>;
  last_name?:InputMaybe<Order_By>;
  last_name_i18n?:InputMaybe<Order_By>;
  linkedin_profile_url?:InputMaybe<Order_By>;
  middle_name_i18n?:InputMaybe<Order_By>;
  phone?:InputMaybe<Order_By>;
  recommended_by_staff_id?:InputMaybe<Order_By>;
  recruited_by_user_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
  secondary_email?:InputMaybe<Order_By>;
  source?:InputMaybe<Order_By>;
  sourced_by_user_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
  status_date?:InputMaybe<Order_By>;
  status_reason_id?:InputMaybe<Order_By>;
  status_reason_notes?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recruiting_Candidate_Min_Fields = {
  __typename?:'recruiting_candidate_min_fields';
  assigned_user_id?:Maybe<Scalars['Int']>;
  avatar_url?:Maybe<Scalars['String']>;
  city_id?:Maybe<Scalars['Int']>;
  country_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  created_by_user_id?:Maybe<Scalars['Int']>;
  current_employer?:Maybe<Scalars['String']>;
  cv_google_doc_ref?:Maybe<Scalars['String']>;
  email?:Maybe<Scalars['String']>;
  experience_in_years?:Maybe<Scalars['Int']>;
  finalized_allocation_id?:Maybe<Scalars['Int']>;
  first_name?:Maybe<Scalars['String']>;
  first_name_i18n?:Maybe<Scalars['String']>;
  id?:Maybe<Scalars['Int']>;
  instant_messenger_id?:Maybe<Scalars['String']>;
  interview_flow_id_deprecated?:Maybe<Scalars['Int']>;
  interview_flow_stage_id?:Maybe<Scalars['Int']>;
  interview_flow_substage_id?:Maybe<Scalars['Int']>;
  last_name?:Maybe<Scalars['String']>;
  last_name_i18n?:Maybe<Scalars['String']>;
  linkedin_profile_url?:Maybe<Scalars['String']>;
  middle_name_i18n?:Maybe<Scalars['String']>;
  phone?:Maybe<Scalars['String']>;
  recommended_by_staff_id?:Maybe<Scalars['Int']>;
  recruited_by_user_id?:Maybe<Scalars['Int']>;
  scheduled_call_id?:Maybe<Scalars['Int']>;
  secondary_email?:Maybe<Scalars['String']>;
  source?:Maybe<Scalars['String']>;
  sourced_by_user_id?:Maybe<Scalars['Int']>;
  staff_id?:Maybe<Scalars['Int']>;
  status_date?:Maybe<Scalars['timestamptz']>;
  status_reason_id?:Maybe<Scalars['Int']>;
  status_reason_notes?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "recruiting.candidate" */
export type Recruiting_Candidate_Min_Order_By = {
  assigned_user_id?:InputMaybe<Order_By>;
  avatar_url?:InputMaybe<Order_By>;
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  created_by_user_id?:InputMaybe<Order_By>;
  current_employer?:InputMaybe<Order_By>;
  cv_google_doc_ref?:InputMaybe<Order_By>;
  email?:InputMaybe<Order_By>;
  experience_in_years?:InputMaybe<Order_By>;
  finalized_allocation_id?:InputMaybe<Order_By>;
  first_name?:InputMaybe<Order_By>;
  first_name_i18n?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  instant_messenger_id?:InputMaybe<Order_By>;
  interview_flow_id_deprecated?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  interview_flow_substage_id?:InputMaybe<Order_By>;
  last_name?:InputMaybe<Order_By>;
  last_name_i18n?:InputMaybe<Order_By>;
  linkedin_profile_url?:InputMaybe<Order_By>;
  middle_name_i18n?:InputMaybe<Order_By>;
  phone?:InputMaybe<Order_By>;
  recommended_by_staff_id?:InputMaybe<Order_By>;
  recruited_by_user_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
  secondary_email?:InputMaybe<Order_By>;
  source?:InputMaybe<Order_By>;
  sourced_by_user_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
  status_date?:InputMaybe<Order_By>;
  status_reason_id?:InputMaybe<Order_By>;
  status_reason_notes?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "recruiting.candidate" */
export type Recruiting_Candidate_Mutation_Response = {
  __typename?:'recruiting_candidate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Candidate>;
};

/** columns and relationships of "recruiting.candidate_note" */
export type Recruiting_Candidate_Note = {
  __typename?:'recruiting_candidate_note';
  candidate_id?:Maybe<Scalars['Int']>;
  content:Scalars['String'];
  created_at:Scalars['timestamptz'];
  deleted_at?:Maybe<Scalars['timestamptz']>;
  id:Scalars['Int'];
  is_pinned:Scalars['Boolean'];
  type?:Maybe<Recruiting_Candidate_Note_Type_Enum>;
  updated_at:Scalars['timestamptz'];
  /** An object relationship */
  user:Common_User;
  user_id:Scalars['Int'];
};

/** aggregated selection of "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Aggregate = {
  __typename?:'recruiting_candidate_note_aggregate';
  aggregate?:Maybe<Recruiting_Candidate_Note_Aggregate_Fields>;
  nodes:Array<Recruiting_Candidate_Note>;
};

export type Recruiting_Candidate_Note_Aggregate_Bool_Exp = {
  bool_and?:InputMaybe<Recruiting_Candidate_Note_Aggregate_Bool_Exp_Bool_And>;
  bool_or?:InputMaybe<Recruiting_Candidate_Note_Aggregate_Bool_Exp_Bool_Or>;
  count?:InputMaybe<Recruiting_Candidate_Note_Aggregate_Bool_Exp_Count>;
};

export type Recruiting_Candidate_Note_Aggregate_Bool_Exp_Bool_And = {
  arguments:Recruiting_Candidate_Note_Select_Column_Recruiting_Candidate_Note_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Candidate_Note_Bool_Exp>;
  predicate:Boolean_Comparison_Exp;
};

export type Recruiting_Candidate_Note_Aggregate_Bool_Exp_Bool_Or = {
  arguments:Recruiting_Candidate_Note_Select_Column_Recruiting_Candidate_Note_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Candidate_Note_Bool_Exp>;
  predicate:Boolean_Comparison_Exp;
};

export type Recruiting_Candidate_Note_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Recruiting_Candidate_Note_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Candidate_Note_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Aggregate_Fields = {
  __typename?:'recruiting_candidate_note_aggregate_fields';
  avg?:Maybe<Recruiting_Candidate_Note_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Candidate_Note_Max_Fields>;
  min?:Maybe<Recruiting_Candidate_Note_Min_Fields>;
  stddev?:Maybe<Recruiting_Candidate_Note_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Candidate_Note_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Candidate_Note_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Candidate_Note_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Candidate_Note_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Candidate_Note_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Candidate_Note_Variance_Fields>;
};


/** aggregate fields of "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Candidate_Note_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Aggregate_Order_By = {
  avg?:InputMaybe<Recruiting_Candidate_Note_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Recruiting_Candidate_Note_Max_Order_By>;
  min?:InputMaybe<Recruiting_Candidate_Note_Min_Order_By>;
  stddev?:InputMaybe<Recruiting_Candidate_Note_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Recruiting_Candidate_Note_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Recruiting_Candidate_Note_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Recruiting_Candidate_Note_Sum_Order_By>;
  var_pop?:InputMaybe<Recruiting_Candidate_Note_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Recruiting_Candidate_Note_Var_Samp_Order_By>;
  variance?:InputMaybe<Recruiting_Candidate_Note_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Arr_Rel_Insert_Input = {
  data:Array<Recruiting_Candidate_Note_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Candidate_Note_On_Conflict>;
};

/** aggregate avg on columns */
export type Recruiting_Candidate_Note_Avg_Fields = {
  __typename?:'recruiting_candidate_note_avg_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Avg_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recruiting.candidate_note". All fields are combined with a logical 'AND'. */
export type Recruiting_Candidate_Note_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Candidate_Note_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Candidate_Note_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Candidate_Note_Bool_Exp>>;
  candidate_id?:InputMaybe<Int_Comparison_Exp>;
  content?:InputMaybe<String_Comparison_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  is_pinned?:InputMaybe<Boolean_Comparison_Exp>;
  type?:InputMaybe<Recruiting_Candidate_Note_Type_Enum_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  user?:InputMaybe<Common_User_Bool_Exp>;
  user_id?:InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.candidate_note" */
export enum Recruiting_Candidate_Note_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  CandidateNotesPkey = 'candidate_notes_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Inc_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  id?:InputMaybe<Scalars['Int']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Insert_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  content?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  is_pinned?:InputMaybe<Scalars['Boolean']>;
  type?:InputMaybe<Recruiting_Candidate_Note_Type_Enum>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user?:InputMaybe<Common_User_Obj_Rel_Insert_Input>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Recruiting_Candidate_Note_Max_Fields = {
  __typename?:'recruiting_candidate_note_max_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  content?:Maybe<Scalars['String']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  deleted_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Max_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  content?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  deleted_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recruiting_Candidate_Note_Min_Fields = {
  __typename?:'recruiting_candidate_note_min_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  content?:Maybe<Scalars['String']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  deleted_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Min_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  content?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  deleted_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Mutation_Response = {
  __typename?:'recruiting_candidate_note_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Candidate_Note>;
};

/** on_conflict condition type for table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_On_Conflict = {
  constraint:Recruiting_Candidate_Note_Constraint;
  update_columns?:Array<Recruiting_Candidate_Note_Update_Column>;
  where?:InputMaybe<Recruiting_Candidate_Note_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.candidate_note". */
export type Recruiting_Candidate_Note_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  content?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  deleted_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  is_pinned?:InputMaybe<Order_By>;
  type?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user?:InputMaybe<Common_User_Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.candidate_note */
export type Recruiting_Candidate_Note_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "recruiting.candidate_note" */
export enum Recruiting_Candidate_Note_Select_Column 
{
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsPinned = 'is_pinned',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "recruiting_candidate_note_aggregate_bool_exp_bool_and_arguments_columns" columns of table "recruiting.candidate_note" */
export enum Recruiting_Candidate_Note_Select_Column_Recruiting_Candidate_Note_Aggregate_Bool_Exp_Bool_And_Arguments_Columns 
{
  /** column name */
  IsPinned = 'is_pinned'
}

/** select "recruiting_candidate_note_aggregate_bool_exp_bool_or_arguments_columns" columns of table "recruiting.candidate_note" */
export enum Recruiting_Candidate_Note_Select_Column_Recruiting_Candidate_Note_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns 
{
  /** column name */
  IsPinned = 'is_pinned'
}

/** input type for updating data in table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Set_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  content?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  is_pinned?:InputMaybe<Scalars['Boolean']>;
  type?:InputMaybe<Recruiting_Candidate_Note_Type_Enum>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Recruiting_Candidate_Note_Stddev_Fields = {
  __typename?:'recruiting_candidate_note_stddev_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Stddev_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Candidate_Note_Stddev_Pop_Fields = {
  __typename?:'recruiting_candidate_note_stddev_pop_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Stddev_Pop_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Candidate_Note_Stddev_Samp_Fields = {
  __typename?:'recruiting_candidate_note_stddev_samp_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Stddev_Samp_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recruiting_candidate_note" */
export type Recruiting_Candidate_Note_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Candidate_Note_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Candidate_Note_Stream_Cursor_Value_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  content?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  is_pinned?:InputMaybe<Scalars['Boolean']>;
  type?:InputMaybe<Recruiting_Candidate_Note_Type_Enum>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Recruiting_Candidate_Note_Sum_Fields = {
  __typename?:'recruiting_candidate_note_sum_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  id?:Maybe<Scalars['Int']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Sum_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** columns and relationships of "recruiting.candidate_note_type" */
export type Recruiting_Candidate_Note_Type = {
  __typename?:'recruiting_candidate_note_type';
  type:Scalars['String'];
};

/** aggregated selection of "recruiting.candidate_note_type" */
export type Recruiting_Candidate_Note_Type_Aggregate = {
  __typename?:'recruiting_candidate_note_type_aggregate';
  aggregate?:Maybe<Recruiting_Candidate_Note_Type_Aggregate_Fields>;
  nodes:Array<Recruiting_Candidate_Note_Type>;
};

/** aggregate fields of "recruiting.candidate_note_type" */
export type Recruiting_Candidate_Note_Type_Aggregate_Fields = {
  __typename?:'recruiting_candidate_note_type_aggregate_fields';
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Candidate_Note_Type_Max_Fields>;
  min?:Maybe<Recruiting_Candidate_Note_Type_Min_Fields>;
};


/** aggregate fields of "recruiting.candidate_note_type" */
export type Recruiting_Candidate_Note_Type_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Candidate_Note_Type_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "recruiting.candidate_note_type". All fields are combined with a logical 'AND'. */
export type Recruiting_Candidate_Note_Type_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Candidate_Note_Type_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Candidate_Note_Type_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Candidate_Note_Type_Bool_Exp>>;
  type?:InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.candidate_note_type" */
export enum Recruiting_Candidate_Note_Type_Constraint 
{
  /** unique or primary key constraint on columns "type" */
  CandidateNoteTypePkey = 'candidate_note_type_pkey'
}

export enum Recruiting_Candidate_Note_Type_Enum 
{
  Desire = 'desire',
  Equipment = 'equipment',
  General = 'general',
  Salary = 'salary'
}

/** Boolean expression to compare columns of type "recruiting_candidate_note_type_enum". All fields are combined with logical 'AND'. */
export type Recruiting_Candidate_Note_Type_Enum_Comparison_Exp = {
  _eq?:InputMaybe<Recruiting_Candidate_Note_Type_Enum>;
  _in?:InputMaybe<Array<Recruiting_Candidate_Note_Type_Enum>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _neq?:InputMaybe<Recruiting_Candidate_Note_Type_Enum>;
  _nin?:InputMaybe<Array<Recruiting_Candidate_Note_Type_Enum>>;
};

/** input type for inserting data into table "recruiting.candidate_note_type" */
export type Recruiting_Candidate_Note_Type_Insert_Input = {
  type?:InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Recruiting_Candidate_Note_Type_Max_Fields = {
  __typename?:'recruiting_candidate_note_type_max_fields';
  type?:Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Recruiting_Candidate_Note_Type_Min_Fields = {
  __typename?:'recruiting_candidate_note_type_min_fields';
  type?:Maybe<Scalars['String']>;
};

/** response of any mutation on the table "recruiting.candidate_note_type" */
export type Recruiting_Candidate_Note_Type_Mutation_Response = {
  __typename?:'recruiting_candidate_note_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Candidate_Note_Type>;
};

/** on_conflict condition type for table "recruiting.candidate_note_type" */
export type Recruiting_Candidate_Note_Type_On_Conflict = {
  constraint:Recruiting_Candidate_Note_Type_Constraint;
  update_columns?:Array<Recruiting_Candidate_Note_Type_Update_Column>;
  where?:InputMaybe<Recruiting_Candidate_Note_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.candidate_note_type". */
export type Recruiting_Candidate_Note_Type_Order_By = {
  type?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.candidate_note_type */
export type Recruiting_Candidate_Note_Type_Pk_Columns_Input = {
  type:Scalars['String'];
};

/** select columns of table "recruiting.candidate_note_type" */
export enum Recruiting_Candidate_Note_Type_Select_Column 
{
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "recruiting.candidate_note_type" */
export type Recruiting_Candidate_Note_Type_Set_Input = {
  type?:InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "recruiting_candidate_note_type" */
export type Recruiting_Candidate_Note_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Candidate_Note_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Candidate_Note_Type_Stream_Cursor_Value_Input = {
  type?:InputMaybe<Scalars['String']>;
};

/** update columns of table "recruiting.candidate_note_type" */
export enum Recruiting_Candidate_Note_Type_Update_Column 
{
  /** column name */
  Type = 'type'
}

export type Recruiting_Candidate_Note_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Candidate_Note_Type_Set_Input>;
  where:Recruiting_Candidate_Note_Type_Bool_Exp;
};

/** update columns of table "recruiting.candidate_note" */
export enum Recruiting_Candidate_Note_Update_Column 
{
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsPinned = 'is_pinned',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Recruiting_Candidate_Note_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Candidate_Note_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Candidate_Note_Set_Input>;
  where:Recruiting_Candidate_Note_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Candidate_Note_Var_Pop_Fields = {
  __typename?:'recruiting_candidate_note_var_pop_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Var_Pop_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recruiting_Candidate_Note_Var_Samp_Fields = {
  __typename?:'recruiting_candidate_note_var_samp_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Var_Samp_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recruiting_Candidate_Note_Variance_Fields = {
  __typename?:'recruiting_candidate_note_variance_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recruiting.candidate_note" */
export type Recruiting_Candidate_Note_Variance_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "recruiting.candidate" */
export type Recruiting_Candidate_Obj_Rel_Insert_Input = {
  data:Recruiting_Candidate_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Candidate_On_Conflict>;
};

/** on_conflict condition type for table "recruiting.candidate" */
export type Recruiting_Candidate_On_Conflict = {
  constraint:Recruiting_Candidate_Constraint;
  update_columns?:Array<Recruiting_Candidate_Update_Column>;
  where?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.candidate". */
export type Recruiting_Candidate_Order_By = {
  assigned_to?:InputMaybe<Common_User_Order_By>;
  assigned_user_id?:InputMaybe<Order_By>;
  avatar_url?:InputMaybe<Order_By>;
  candidate_allocations_aggregate?:InputMaybe<Recruiting_Candidate_Allocation_Aggregate_Order_By>;
  candidate_attachments_aggregate?:InputMaybe<Recruiting_Candidate_Attachment_Aggregate_Order_By>;
  candidate_notes_aggregate?:InputMaybe<Recruiting_Candidate_Note_Aggregate_Order_By>;
  candidate_position_groups_aggregate?:InputMaybe<Recruiting_Candidate_Position_Group_Aggregate_Order_By>;
  candidate_user_interviewers_aggregate?:InputMaybe<Recruiting_Candidate_User_Interviewer_Aggregate_Order_By>;
  city?:InputMaybe<Common_City_Order_By>;
  city_id?:InputMaybe<Order_By>;
  country?:InputMaybe<Common_Country_Order_By>;
  country_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  created_by?:InputMaybe<Common_User_Order_By>;
  created_by_user_id?:InputMaybe<Order_By>;
  current_employer?:InputMaybe<Order_By>;
  customer_team_slots_aggregate?:InputMaybe<Staffing_Customer_Team_Slot_Aggregate_Order_By>;
  cv_file_url?:InputMaybe<Order_By>;
  cv_google_doc_ref?:InputMaybe<Order_By>;
  email?:InputMaybe<Order_By>;
  experience_in_years?:InputMaybe<Order_By>;
  final_allocation?:InputMaybe<Recruiting_Candidate_Allocation_Order_By>;
  finalized_allocation_id?:InputMaybe<Order_By>;
  first_name?:InputMaybe<Order_By>;
  first_name_i18n?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  instant_messenger_id?:InputMaybe<Order_By>;
  interview_flow_id_deprecated?:InputMaybe<Order_By>;
  interview_flow_stage?:InputMaybe<Recruiting_Interview_Flow_Stage_Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  interview_flow_substage?:InputMaybe<Recruiting_Interview_Flow_Substage_Order_By>;
  interview_flow_substage_id?:InputMaybe<Order_By>;
  is_on_flow_deprecated?:InputMaybe<Order_By>;
  last_name?:InputMaybe<Order_By>;
  last_name_i18n?:InputMaybe<Order_By>;
  linkedin_profile_url?:InputMaybe<Order_By>;
  middle_name_i18n?:InputMaybe<Order_By>;
  offers_aggregate?:InputMaybe<Recruiting_Offer_Aggregate_Order_By>;
  phone?:InputMaybe<Order_By>;
  recommended_by?:InputMaybe<Staffing_Staff_Order_By>;
  recommended_by_staff_id?:InputMaybe<Order_By>;
  recruited_by?:InputMaybe<Common_User_Order_By>;
  recruited_by_user_id?:InputMaybe<Order_By>;
  scheduled_call?:InputMaybe<Common_Scheduled_Event_Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
  scheduled_calls_aggregate?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Aggregate_Order_By>;
  secondary_email?:InputMaybe<Order_By>;
  skill_tags_aggregate?:InputMaybe<Recruiting_Candidate_Skill_Tag_Aggregate_Order_By>;
  source?:InputMaybe<Order_By>;
  sourced_by?:InputMaybe<Common_User_Order_By>;
  sourced_by_user_id?:InputMaybe<Order_By>;
  staff?:InputMaybe<Staffing_Staff_Order_By>;
  staff_id?:InputMaybe<Order_By>;
  status?:InputMaybe<Order_By>;
  status_date?:InputMaybe<Order_By>;
  status_reason?:InputMaybe<Recruiting_Candidate_Status_Reason_Order_By>;
  status_reason_id?:InputMaybe<Order_By>;
  status_reason_notes?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.candidate */
export type Recruiting_Candidate_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** columns and relationships of "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group = {
  __typename?:'recruiting_candidate_position_group';
  /** An object relationship */
  candidate:Recruiting_Candidate;
  candidate_id:Scalars['Int'];
  created_at:Scalars['timestamptz'];
  /** An object relationship */
  position_group:Recruiting_Position_Group;
  position_group_id:Scalars['Int'];
  updated_at:Scalars['timestamptz'];
};

/** aggregated selection of "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Aggregate = {
  __typename?:'recruiting_candidate_position_group_aggregate';
  aggregate?:Maybe<Recruiting_Candidate_Position_Group_Aggregate_Fields>;
  nodes:Array<Recruiting_Candidate_Position_Group>;
};

export type Recruiting_Candidate_Position_Group_Aggregate_Bool_Exp = {
  count?:InputMaybe<Recruiting_Candidate_Position_Group_Aggregate_Bool_Exp_Count>;
};

export type Recruiting_Candidate_Position_Group_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Candidate_Position_Group_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Aggregate_Fields = {
  __typename?:'recruiting_candidate_position_group_aggregate_fields';
  avg?:Maybe<Recruiting_Candidate_Position_Group_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Candidate_Position_Group_Max_Fields>;
  min?:Maybe<Recruiting_Candidate_Position_Group_Min_Fields>;
  stddev?:Maybe<Recruiting_Candidate_Position_Group_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Candidate_Position_Group_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Candidate_Position_Group_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Candidate_Position_Group_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Candidate_Position_Group_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Candidate_Position_Group_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Candidate_Position_Group_Variance_Fields>;
};


/** aggregate fields of "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Aggregate_Order_By = {
  avg?:InputMaybe<Recruiting_Candidate_Position_Group_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Recruiting_Candidate_Position_Group_Max_Order_By>;
  min?:InputMaybe<Recruiting_Candidate_Position_Group_Min_Order_By>;
  stddev?:InputMaybe<Recruiting_Candidate_Position_Group_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Recruiting_Candidate_Position_Group_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Recruiting_Candidate_Position_Group_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Recruiting_Candidate_Position_Group_Sum_Order_By>;
  var_pop?:InputMaybe<Recruiting_Candidate_Position_Group_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Recruiting_Candidate_Position_Group_Var_Samp_Order_By>;
  variance?:InputMaybe<Recruiting_Candidate_Position_Group_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Arr_Rel_Insert_Input = {
  data:Array<Recruiting_Candidate_Position_Group_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Candidate_Position_Group_On_Conflict>;
};

/** aggregate avg on columns */
export type Recruiting_Candidate_Position_Group_Avg_Fields = {
  __typename?:'recruiting_candidate_position_group_avg_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  position_group_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Avg_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recruiting.candidate_position_group". All fields are combined with a logical 'AND'. */
export type Recruiting_Candidate_Position_Group_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Candidate_Position_Group_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Bool_Exp>>;
  candidate?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
  candidate_id?:InputMaybe<Int_Comparison_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  position_group?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
  position_group_id?:InputMaybe<Int_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.candidate_position_group" */
export enum Recruiting_Candidate_Position_Group_Constraint 
{
  /** unique or primary key constraint on columns "candidate_id", "position_group_id" */
  CandidatePositionGroupPkey = 'candidate_position_group_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Inc_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  position_group_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Insert_Input = {
  candidate?:InputMaybe<Recruiting_Candidate_Obj_Rel_Insert_Input>;
  candidate_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  position_group?:InputMaybe<Recruiting_Position_Group_Obj_Rel_Insert_Input>;
  position_group_id?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Recruiting_Candidate_Position_Group_Max_Fields = {
  __typename?:'recruiting_candidate_position_group_max_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  position_group_id?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Max_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recruiting_Candidate_Position_Group_Min_Fields = {
  __typename?:'recruiting_candidate_position_group_min_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  position_group_id?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Min_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Mutation_Response = {
  __typename?:'recruiting_candidate_position_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Candidate_Position_Group>;
};

/** on_conflict condition type for table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_On_Conflict = {
  constraint:Recruiting_Candidate_Position_Group_Constraint;
  update_columns?:Array<Recruiting_Candidate_Position_Group_Update_Column>;
  where?:InputMaybe<Recruiting_Candidate_Position_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.candidate_position_group". */
export type Recruiting_Candidate_Position_Group_Order_By = {
  candidate?:InputMaybe<Recruiting_Candidate_Order_By>;
  candidate_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  position_group?:InputMaybe<Recruiting_Position_Group_Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.candidate_position_group */
export type Recruiting_Candidate_Position_Group_Pk_Columns_Input = {
  candidate_id:Scalars['Int'];
  position_group_id:Scalars['Int'];
};

/** select columns of table "recruiting.candidate_position_group" */
export enum Recruiting_Candidate_Position_Group_Select_Column 
{
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PositionGroupId = 'position_group_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Set_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  position_group_id?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Recruiting_Candidate_Position_Group_Stddev_Fields = {
  __typename?:'recruiting_candidate_position_group_stddev_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  position_group_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Stddev_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Candidate_Position_Group_Stddev_Pop_Fields = {
  __typename?:'recruiting_candidate_position_group_stddev_pop_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  position_group_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Stddev_Pop_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Candidate_Position_Group_Stddev_Samp_Fields = {
  __typename?:'recruiting_candidate_position_group_stddev_samp_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  position_group_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Stddev_Samp_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recruiting_candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Candidate_Position_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Candidate_Position_Group_Stream_Cursor_Value_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  position_group_id?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Recruiting_Candidate_Position_Group_Sum_Fields = {
  __typename?:'recruiting_candidate_position_group_sum_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  position_group_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Sum_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
};

/** update columns of table "recruiting.candidate_position_group" */
export enum Recruiting_Candidate_Position_Group_Update_Column 
{
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PositionGroupId = 'position_group_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Recruiting_Candidate_Position_Group_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Candidate_Position_Group_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Candidate_Position_Group_Set_Input>;
  where:Recruiting_Candidate_Position_Group_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Candidate_Position_Group_Var_Pop_Fields = {
  __typename?:'recruiting_candidate_position_group_var_pop_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  position_group_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Var_Pop_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recruiting_Candidate_Position_Group_Var_Samp_Fields = {
  __typename?:'recruiting_candidate_position_group_var_samp_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  position_group_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Var_Samp_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recruiting_Candidate_Position_Group_Variance_Fields = {
  __typename?:'recruiting_candidate_position_group_variance_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  position_group_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recruiting.candidate_position_group" */
export type Recruiting_Candidate_Position_Group_Variance_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
};

/** columns and relationships of "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event = {
  __typename?:'recruiting_candidate_scheduled_event';
  /** An object relationship */
  candidate:Recruiting_Candidate;
  candidate_id:Scalars['Int'];
  /** An object relationship */
  interview_flow_stage:Recruiting_Interview_Flow_Stage;
  interview_flow_stage_id:Scalars['Int'];
  /** An object relationship */
  scheduled_call:Common_Scheduled_Event;
  scheduled_call_id:Scalars['Int'];
};

/** aggregated selection of "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Aggregate = {
  __typename?:'recruiting_candidate_scheduled_event_aggregate';
  aggregate?:Maybe<Recruiting_Candidate_Scheduled_Event_Aggregate_Fields>;
  nodes:Array<Recruiting_Candidate_Scheduled_Event>;
};

export type Recruiting_Candidate_Scheduled_Event_Aggregate_Bool_Exp = {
  count?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Aggregate_Bool_Exp_Count>;
};

export type Recruiting_Candidate_Scheduled_Event_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Aggregate_Fields = {
  __typename?:'recruiting_candidate_scheduled_event_aggregate_fields';
  avg?:Maybe<Recruiting_Candidate_Scheduled_Event_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Candidate_Scheduled_Event_Max_Fields>;
  min?:Maybe<Recruiting_Candidate_Scheduled_Event_Min_Fields>;
  stddev?:Maybe<Recruiting_Candidate_Scheduled_Event_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Candidate_Scheduled_Event_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Candidate_Scheduled_Event_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Candidate_Scheduled_Event_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Candidate_Scheduled_Event_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Candidate_Scheduled_Event_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Candidate_Scheduled_Event_Variance_Fields>;
};


/** aggregate fields of "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Aggregate_Order_By = {
  avg?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Max_Order_By>;
  min?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Min_Order_By>;
  stddev?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Sum_Order_By>;
  var_pop?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Var_Samp_Order_By>;
  variance?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Arr_Rel_Insert_Input = {
  data:Array<Recruiting_Candidate_Scheduled_Event_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Candidate_Scheduled_Event_On_Conflict>;
};

/** aggregate avg on columns */
export type Recruiting_Candidate_Scheduled_Event_Avg_Fields = {
  __typename?:'recruiting_candidate_scheduled_event_avg_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
  scheduled_call_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Avg_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recruiting.candidate_scheduled_event". All fields are combined with a logical 'AND'. */
export type Recruiting_Candidate_Scheduled_Event_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Bool_Exp>>;
  candidate?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
  candidate_id?:InputMaybe<Int_Comparison_Exp>;
  interview_flow_stage?:InputMaybe<Recruiting_Interview_Flow_Stage_Bool_Exp>;
  interview_flow_stage_id?:InputMaybe<Int_Comparison_Exp>;
  scheduled_call?:InputMaybe<Common_Scheduled_Event_Bool_Exp>;
  scheduled_call_id?:InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.candidate_scheduled_event" */
export enum Recruiting_Candidate_Scheduled_Event_Constraint 
{
  /** unique or primary key constraint on columns "candidate_id", "scheduled_call_id" */
  CandidateScheduledCallPkey = 'candidate_scheduled_call_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Inc_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  interview_flow_stage_id?:InputMaybe<Scalars['Int']>;
  scheduled_call_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Insert_Input = {
  candidate?:InputMaybe<Recruiting_Candidate_Obj_Rel_Insert_Input>;
  candidate_id?:InputMaybe<Scalars['Int']>;
  interview_flow_stage?:InputMaybe<Recruiting_Interview_Flow_Stage_Obj_Rel_Insert_Input>;
  interview_flow_stage_id?:InputMaybe<Scalars['Int']>;
  scheduled_call?:InputMaybe<Common_Scheduled_Event_Obj_Rel_Insert_Input>;
  scheduled_call_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Recruiting_Candidate_Scheduled_Event_Max_Fields = {
  __typename?:'recruiting_candidate_scheduled_event_max_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  interview_flow_stage_id?:Maybe<Scalars['Int']>;
  scheduled_call_id?:Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Max_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recruiting_Candidate_Scheduled_Event_Min_Fields = {
  __typename?:'recruiting_candidate_scheduled_event_min_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  interview_flow_stage_id?:Maybe<Scalars['Int']>;
  scheduled_call_id?:Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Min_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Mutation_Response = {
  __typename?:'recruiting_candidate_scheduled_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Candidate_Scheduled_Event>;
};

/** on_conflict condition type for table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_On_Conflict = {
  constraint:Recruiting_Candidate_Scheduled_Event_Constraint;
  update_columns?:Array<Recruiting_Candidate_Scheduled_Event_Update_Column>;
  where?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.candidate_scheduled_event". */
export type Recruiting_Candidate_Scheduled_Event_Order_By = {
  candidate?:InputMaybe<Recruiting_Candidate_Order_By>;
  candidate_id?:InputMaybe<Order_By>;
  interview_flow_stage?:InputMaybe<Recruiting_Interview_Flow_Stage_Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  scheduled_call?:InputMaybe<Common_Scheduled_Event_Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.candidate_scheduled_event */
export type Recruiting_Candidate_Scheduled_Event_Pk_Columns_Input = {
  candidate_id:Scalars['Int'];
  scheduled_call_id:Scalars['Int'];
};

/** select columns of table "recruiting.candidate_scheduled_event" */
export enum Recruiting_Candidate_Scheduled_Event_Select_Column 
{
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  InterviewFlowStageId = 'interview_flow_stage_id',
  /** column name */
  ScheduledCallId = 'scheduled_call_id'
}

/** input type for updating data in table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Set_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  interview_flow_stage_id?:InputMaybe<Scalars['Int']>;
  scheduled_call_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Recruiting_Candidate_Scheduled_Event_Stddev_Fields = {
  __typename?:'recruiting_candidate_scheduled_event_stddev_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
  scheduled_call_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Stddev_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Candidate_Scheduled_Event_Stddev_Pop_Fields = {
  __typename?:'recruiting_candidate_scheduled_event_stddev_pop_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
  scheduled_call_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Stddev_Pop_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Candidate_Scheduled_Event_Stddev_Samp_Fields = {
  __typename?:'recruiting_candidate_scheduled_event_stddev_samp_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
  scheduled_call_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Stddev_Samp_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recruiting_candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Candidate_Scheduled_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Candidate_Scheduled_Event_Stream_Cursor_Value_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  interview_flow_stage_id?:InputMaybe<Scalars['Int']>;
  scheduled_call_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Recruiting_Candidate_Scheduled_Event_Sum_Fields = {
  __typename?:'recruiting_candidate_scheduled_event_sum_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  interview_flow_stage_id?:Maybe<Scalars['Int']>;
  scheduled_call_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Sum_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
};

/** update columns of table "recruiting.candidate_scheduled_event" */
export enum Recruiting_Candidate_Scheduled_Event_Update_Column 
{
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  InterviewFlowStageId = 'interview_flow_stage_id',
  /** column name */
  ScheduledCallId = 'scheduled_call_id'
}

export type Recruiting_Candidate_Scheduled_Event_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Set_Input>;
  where:Recruiting_Candidate_Scheduled_Event_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Candidate_Scheduled_Event_Var_Pop_Fields = {
  __typename?:'recruiting_candidate_scheduled_event_var_pop_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
  scheduled_call_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Var_Pop_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recruiting_Candidate_Scheduled_Event_Var_Samp_Fields = {
  __typename?:'recruiting_candidate_scheduled_event_var_samp_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
  scheduled_call_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Var_Samp_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recruiting_Candidate_Scheduled_Event_Variance_Fields = {
  __typename?:'recruiting_candidate_scheduled_event_variance_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
  scheduled_call_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recruiting.candidate_scheduled_event" */
export type Recruiting_Candidate_Scheduled_Event_Variance_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
};

/** select columns of table "recruiting.candidate" */
export enum Recruiting_Candidate_Select_Column 
{
  /** column name */
  AssignedUserId = 'assigned_user_id',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CityId = 'city_id',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  CurrentEmployer = 'current_employer',
  /** column name */
  CvGoogleDocRef = 'cv_google_doc_ref',
  /** column name */
  Email = 'email',
  /** column name */
  ExperienceInYears = 'experience_in_years',
  /** column name */
  FinalizedAllocationId = 'finalized_allocation_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FirstNameI18n = 'first_name_i18n',
  /** column name */
  Id = 'id',
  /** column name */
  InstantMessengerId = 'instant_messenger_id',
  /** column name */
  InterviewFlowIdDeprecated = 'interview_flow_id_deprecated',
  /** column name */
  InterviewFlowStageId = 'interview_flow_stage_id',
  /** column name */
  InterviewFlowSubstageId = 'interview_flow_substage_id',
  /** column name */
  IsOnFlowDeprecated = 'is_on_flow_deprecated',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastNameI18n = 'last_name_i18n',
  /** column name */
  LinkedinProfileUrl = 'linkedin_profile_url',
  /** column name */
  MiddleNameI18n = 'middle_name_i18n',
  /** column name */
  Phone = 'phone',
  /** column name */
  RecommendedByStaffId = 'recommended_by_staff_id',
  /** column name */
  RecruitedByUserId = 'recruited_by_user_id',
  /** column name */
  ScheduledCallId = 'scheduled_call_id',
  /** column name */
  SecondaryEmail = 'secondary_email',
  /** column name */
  Source = 'source',
  /** column name */
  SourcedByUserId = 'sourced_by_user_id',
  /** column name */
  StaffId = 'staff_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDate = 'status_date',
  /** column name */
  StatusReasonId = 'status_reason_id',
  /** column name */
  StatusReasonNotes = 'status_reason_notes',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "recruiting_candidate_aggregate_bool_exp_bool_and_arguments_columns" columns of table "recruiting.candidate" */
export enum Recruiting_Candidate_Select_Column_Recruiting_Candidate_Aggregate_Bool_Exp_Bool_And_Arguments_Columns 
{
  /** column name */
  IsOnFlowDeprecated = 'is_on_flow_deprecated'
}

/** select "recruiting_candidate_aggregate_bool_exp_bool_or_arguments_columns" columns of table "recruiting.candidate" */
export enum Recruiting_Candidate_Select_Column_Recruiting_Candidate_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns 
{
  /** column name */
  IsOnFlowDeprecated = 'is_on_flow_deprecated'
}

/** input type for updating data in table "recruiting.candidate" */
export type Recruiting_Candidate_Set_Input = {
  assigned_user_id?:InputMaybe<Scalars['Int']>;
  avatar_url?:InputMaybe<Scalars['String']>;
  city_id?:InputMaybe<Scalars['Int']>;
  country_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  created_by_user_id?:InputMaybe<Scalars['Int']>;
  current_employer?:InputMaybe<Scalars['String']>;
  cv_google_doc_ref?:InputMaybe<Scalars['String']>;
  email?:InputMaybe<Scalars['String']>;
  experience_in_years?:InputMaybe<Scalars['Int']>;
  finalized_allocation_id?:InputMaybe<Scalars['Int']>;
  first_name?:InputMaybe<Scalars['String']>;
  first_name_i18n?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  instant_messenger_id?:InputMaybe<Scalars['String']>;
  interview_flow_id_deprecated?:InputMaybe<Scalars['Int']>;
  interview_flow_stage_id?:InputMaybe<Scalars['Int']>;
  interview_flow_substage_id?:InputMaybe<Scalars['Int']>;
  is_on_flow_deprecated?:InputMaybe<Scalars['Boolean']>;
  last_name?:InputMaybe<Scalars['String']>;
  last_name_i18n?:InputMaybe<Scalars['String']>;
  linkedin_profile_url?:InputMaybe<Scalars['String']>;
  middle_name_i18n?:InputMaybe<Scalars['String']>;
  phone?:InputMaybe<Scalars['String']>;
  recommended_by_staff_id?:InputMaybe<Scalars['Int']>;
  recruited_by_user_id?:InputMaybe<Scalars['Int']>;
  scheduled_call_id?:InputMaybe<Scalars['Int']>;
  secondary_email?:InputMaybe<Scalars['String']>;
  source?:InputMaybe<Scalars['String']>;
  sourced_by_user_id?:InputMaybe<Scalars['Int']>;
  staff_id?:InputMaybe<Scalars['Int']>;
  status?:InputMaybe<Recruiting_Candidate_Status_Enum>;
  status_date?:InputMaybe<Scalars['timestamptz']>;
  status_reason_id?:InputMaybe<Scalars['Int']>;
  status_reason_notes?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag = {
  __typename?:'recruiting_candidate_skill_tag';
  /** An object relationship */
  candidate:Recruiting_Candidate;
  candidate_id:Scalars['Int'];
  created_at:Scalars['timestamptz'];
  /** An object relationship */
  skill_tag:Common_Skill_Tag;
  skill_tag_id:Scalars['Int'];
  updated_at:Scalars['timestamptz'];
};

/** aggregated selection of "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Aggregate = {
  __typename?:'recruiting_candidate_skill_tag_aggregate';
  aggregate?:Maybe<Recruiting_Candidate_Skill_Tag_Aggregate_Fields>;
  nodes:Array<Recruiting_Candidate_Skill_Tag>;
};

export type Recruiting_Candidate_Skill_Tag_Aggregate_Bool_Exp = {
  count?:InputMaybe<Recruiting_Candidate_Skill_Tag_Aggregate_Bool_Exp_Count>;
};

export type Recruiting_Candidate_Skill_Tag_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Candidate_Skill_Tag_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Aggregate_Fields = {
  __typename?:'recruiting_candidate_skill_tag_aggregate_fields';
  avg?:Maybe<Recruiting_Candidate_Skill_Tag_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Candidate_Skill_Tag_Max_Fields>;
  min?:Maybe<Recruiting_Candidate_Skill_Tag_Min_Fields>;
  stddev?:Maybe<Recruiting_Candidate_Skill_Tag_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Candidate_Skill_Tag_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Candidate_Skill_Tag_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Candidate_Skill_Tag_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Candidate_Skill_Tag_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Candidate_Skill_Tag_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Candidate_Skill_Tag_Variance_Fields>;
};


/** aggregate fields of "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Aggregate_Order_By = {
  avg?:InputMaybe<Recruiting_Candidate_Skill_Tag_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Recruiting_Candidate_Skill_Tag_Max_Order_By>;
  min?:InputMaybe<Recruiting_Candidate_Skill_Tag_Min_Order_By>;
  stddev?:InputMaybe<Recruiting_Candidate_Skill_Tag_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Recruiting_Candidate_Skill_Tag_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Recruiting_Candidate_Skill_Tag_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Recruiting_Candidate_Skill_Tag_Sum_Order_By>;
  var_pop?:InputMaybe<Recruiting_Candidate_Skill_Tag_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Recruiting_Candidate_Skill_Tag_Var_Samp_Order_By>;
  variance?:InputMaybe<Recruiting_Candidate_Skill_Tag_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Arr_Rel_Insert_Input = {
  data:Array<Recruiting_Candidate_Skill_Tag_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Candidate_Skill_Tag_On_Conflict>;
};

/** aggregate avg on columns */
export type Recruiting_Candidate_Skill_Tag_Avg_Fields = {
  __typename?:'recruiting_candidate_skill_tag_avg_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  skill_tag_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Avg_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  skill_tag_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recruiting.candidate_skill_tag". All fields are combined with a logical 'AND'. */
export type Recruiting_Candidate_Skill_Tag_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Candidate_Skill_Tag_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Bool_Exp>>;
  candidate?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
  candidate_id?:InputMaybe<Int_Comparison_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  skill_tag?:InputMaybe<Common_Skill_Tag_Bool_Exp>;
  skill_tag_id?:InputMaybe<Int_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.candidate_skill_tag" */
export enum Recruiting_Candidate_Skill_Tag_Constraint 
{
  /** unique or primary key constraint on columns "candidate_id", "skill_tag_id" */
  CandidateSkillTagPkey = 'candidate_skill_tag_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Inc_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  skill_tag_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Insert_Input = {
  candidate?:InputMaybe<Recruiting_Candidate_Obj_Rel_Insert_Input>;
  candidate_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  skill_tag?:InputMaybe<Common_Skill_Tag_Obj_Rel_Insert_Input>;
  skill_tag_id?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Recruiting_Candidate_Skill_Tag_Max_Fields = {
  __typename?:'recruiting_candidate_skill_tag_max_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  skill_tag_id?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Max_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  skill_tag_id?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recruiting_Candidate_Skill_Tag_Min_Fields = {
  __typename?:'recruiting_candidate_skill_tag_min_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  skill_tag_id?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Min_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  skill_tag_id?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Mutation_Response = {
  __typename?:'recruiting_candidate_skill_tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Candidate_Skill_Tag>;
};

/** on_conflict condition type for table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_On_Conflict = {
  constraint:Recruiting_Candidate_Skill_Tag_Constraint;
  update_columns?:Array<Recruiting_Candidate_Skill_Tag_Update_Column>;
  where?:InputMaybe<Recruiting_Candidate_Skill_Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.candidate_skill_tag". */
export type Recruiting_Candidate_Skill_Tag_Order_By = {
  candidate?:InputMaybe<Recruiting_Candidate_Order_By>;
  candidate_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  skill_tag?:InputMaybe<Common_Skill_Tag_Order_By>;
  skill_tag_id?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.candidate_skill_tag */
export type Recruiting_Candidate_Skill_Tag_Pk_Columns_Input = {
  candidate_id:Scalars['Int'];
  skill_tag_id:Scalars['Int'];
};

/** select columns of table "recruiting.candidate_skill_tag" */
export enum Recruiting_Candidate_Skill_Tag_Select_Column 
{
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SkillTagId = 'skill_tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Set_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  skill_tag_id?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Recruiting_Candidate_Skill_Tag_Stddev_Fields = {
  __typename?:'recruiting_candidate_skill_tag_stddev_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  skill_tag_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Stddev_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  skill_tag_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Candidate_Skill_Tag_Stddev_Pop_Fields = {
  __typename?:'recruiting_candidate_skill_tag_stddev_pop_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  skill_tag_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Stddev_Pop_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  skill_tag_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Candidate_Skill_Tag_Stddev_Samp_Fields = {
  __typename?:'recruiting_candidate_skill_tag_stddev_samp_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  skill_tag_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Stddev_Samp_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  skill_tag_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recruiting_candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Candidate_Skill_Tag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Candidate_Skill_Tag_Stream_Cursor_Value_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  skill_tag_id?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Recruiting_Candidate_Skill_Tag_Sum_Fields = {
  __typename?:'recruiting_candidate_skill_tag_sum_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  skill_tag_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Sum_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  skill_tag_id?:InputMaybe<Order_By>;
};

/** update columns of table "recruiting.candidate_skill_tag" */
export enum Recruiting_Candidate_Skill_Tag_Update_Column 
{
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SkillTagId = 'skill_tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Recruiting_Candidate_Skill_Tag_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Candidate_Skill_Tag_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Candidate_Skill_Tag_Set_Input>;
  where:Recruiting_Candidate_Skill_Tag_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Candidate_Skill_Tag_Var_Pop_Fields = {
  __typename?:'recruiting_candidate_skill_tag_var_pop_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  skill_tag_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Var_Pop_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  skill_tag_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recruiting_Candidate_Skill_Tag_Var_Samp_Fields = {
  __typename?:'recruiting_candidate_skill_tag_var_samp_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  skill_tag_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Var_Samp_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  skill_tag_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recruiting_Candidate_Skill_Tag_Variance_Fields = {
  __typename?:'recruiting_candidate_skill_tag_variance_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  skill_tag_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recruiting.candidate_skill_tag" */
export type Recruiting_Candidate_Skill_Tag_Variance_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  skill_tag_id?:InputMaybe<Order_By>;
};

/** columns and relationships of "recruiting.candidate_status" */
export type Recruiting_Candidate_Status = {
  __typename?:'recruiting_candidate_status';
  status:Scalars['String'];
};

/** aggregated selection of "recruiting.candidate_status" */
export type Recruiting_Candidate_Status_Aggregate = {
  __typename?:'recruiting_candidate_status_aggregate';
  aggregate?:Maybe<Recruiting_Candidate_Status_Aggregate_Fields>;
  nodes:Array<Recruiting_Candidate_Status>;
};

/** aggregate fields of "recruiting.candidate_status" */
export type Recruiting_Candidate_Status_Aggregate_Fields = {
  __typename?:'recruiting_candidate_status_aggregate_fields';
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Candidate_Status_Max_Fields>;
  min?:Maybe<Recruiting_Candidate_Status_Min_Fields>;
};


/** aggregate fields of "recruiting.candidate_status" */
export type Recruiting_Candidate_Status_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Candidate_Status_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "recruiting.candidate_status". All fields are combined with a logical 'AND'. */
export type Recruiting_Candidate_Status_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Candidate_Status_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Candidate_Status_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Candidate_Status_Bool_Exp>>;
  status?:InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.candidate_status" */
export enum Recruiting_Candidate_Status_Constraint 
{
  /** unique or primary key constraint on columns "status" */
  CandidateStatusPkey = 'candidate_status_pkey'
}

export enum Recruiting_Candidate_Status_Enum 
{
  Active = 'active',
  Archived = 'archived',
  Declined = 'declined',
  Initial = 'initial',
  Refused = 'refused',
  Staffed = 'staffed'
}

/** Boolean expression to compare columns of type "recruiting_candidate_status_enum". All fields are combined with logical 'AND'. */
export type Recruiting_Candidate_Status_Enum_Comparison_Exp = {
  _eq?:InputMaybe<Recruiting_Candidate_Status_Enum>;
  _in?:InputMaybe<Array<Recruiting_Candidate_Status_Enum>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _neq?:InputMaybe<Recruiting_Candidate_Status_Enum>;
  _nin?:InputMaybe<Array<Recruiting_Candidate_Status_Enum>>;
};

/** input type for inserting data into table "recruiting.candidate_status" */
export type Recruiting_Candidate_Status_Insert_Input = {
  status?:InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Recruiting_Candidate_Status_Max_Fields = {
  __typename?:'recruiting_candidate_status_max_fields';
  status?:Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Recruiting_Candidate_Status_Min_Fields = {
  __typename?:'recruiting_candidate_status_min_fields';
  status?:Maybe<Scalars['String']>;
};

/** response of any mutation on the table "recruiting.candidate_status" */
export type Recruiting_Candidate_Status_Mutation_Response = {
  __typename?:'recruiting_candidate_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Candidate_Status>;
};

/** on_conflict condition type for table "recruiting.candidate_status" */
export type Recruiting_Candidate_Status_On_Conflict = {
  constraint:Recruiting_Candidate_Status_Constraint;
  update_columns?:Array<Recruiting_Candidate_Status_Update_Column>;
  where?:InputMaybe<Recruiting_Candidate_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.candidate_status". */
export type Recruiting_Candidate_Status_Order_By = {
  status?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.candidate_status */
export type Recruiting_Candidate_Status_Pk_Columns_Input = {
  status:Scalars['String'];
};

/** columns and relationships of "recruiting.candidate_status_reason" */
export type Recruiting_Candidate_Status_Reason = {
  __typename?:'recruiting_candidate_status_reason';
  id:Scalars['Int'];
  is_active:Scalars['Boolean'];
  name:Scalars['String'];
  status:Recruiting_Candidate_Status_Enum;
  ui_order:Scalars['Int'];
};

/** aggregated selection of "recruiting.candidate_status_reason" */
export type Recruiting_Candidate_Status_Reason_Aggregate = {
  __typename?:'recruiting_candidate_status_reason_aggregate';
  aggregate?:Maybe<Recruiting_Candidate_Status_Reason_Aggregate_Fields>;
  nodes:Array<Recruiting_Candidate_Status_Reason>;
};

/** aggregate fields of "recruiting.candidate_status_reason" */
export type Recruiting_Candidate_Status_Reason_Aggregate_Fields = {
  __typename?:'recruiting_candidate_status_reason_aggregate_fields';
  avg?:Maybe<Recruiting_Candidate_Status_Reason_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Candidate_Status_Reason_Max_Fields>;
  min?:Maybe<Recruiting_Candidate_Status_Reason_Min_Fields>;
  stddev?:Maybe<Recruiting_Candidate_Status_Reason_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Candidate_Status_Reason_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Candidate_Status_Reason_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Candidate_Status_Reason_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Candidate_Status_Reason_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Candidate_Status_Reason_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Candidate_Status_Reason_Variance_Fields>;
};


/** aggregate fields of "recruiting.candidate_status_reason" */
export type Recruiting_Candidate_Status_Reason_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Candidate_Status_Reason_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Recruiting_Candidate_Status_Reason_Avg_Fields = {
  __typename?:'recruiting_candidate_status_reason_avg_fields';
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "recruiting.candidate_status_reason". All fields are combined with a logical 'AND'. */
export type Recruiting_Candidate_Status_Reason_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Candidate_Status_Reason_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Candidate_Status_Reason_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Candidate_Status_Reason_Bool_Exp>>;
  id?:InputMaybe<Int_Comparison_Exp>;
  is_active?:InputMaybe<Boolean_Comparison_Exp>;
  name?:InputMaybe<String_Comparison_Exp>;
  status?:InputMaybe<Recruiting_Candidate_Status_Enum_Comparison_Exp>;
  ui_order?:InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.candidate_status_reason" */
export enum Recruiting_Candidate_Status_Reason_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  CandidateStatusReasonPkey = 'candidate_status_reason_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.candidate_status_reason" */
export type Recruiting_Candidate_Status_Reason_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
  ui_order?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.candidate_status_reason" */
export type Recruiting_Candidate_Status_Reason_Insert_Input = {
  id?:InputMaybe<Scalars['Int']>;
  is_active?:InputMaybe<Scalars['Boolean']>;
  name?:InputMaybe<Scalars['String']>;
  status?:InputMaybe<Recruiting_Candidate_Status_Enum>;
  ui_order?:InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Recruiting_Candidate_Status_Reason_Max_Fields = {
  __typename?:'recruiting_candidate_status_reason_max_fields';
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  ui_order?:Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Recruiting_Candidate_Status_Reason_Min_Fields = {
  __typename?:'recruiting_candidate_status_reason_min_fields';
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  ui_order?:Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "recruiting.candidate_status_reason" */
export type Recruiting_Candidate_Status_Reason_Mutation_Response = {
  __typename?:'recruiting_candidate_status_reason_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Candidate_Status_Reason>;
};

/** input type for inserting object relation for remote table "recruiting.candidate_status_reason" */
export type Recruiting_Candidate_Status_Reason_Obj_Rel_Insert_Input = {
  data:Recruiting_Candidate_Status_Reason_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Candidate_Status_Reason_On_Conflict>;
};

/** on_conflict condition type for table "recruiting.candidate_status_reason" */
export type Recruiting_Candidate_Status_Reason_On_Conflict = {
  constraint:Recruiting_Candidate_Status_Reason_Constraint;
  update_columns?:Array<Recruiting_Candidate_Status_Reason_Update_Column>;
  where?:InputMaybe<Recruiting_Candidate_Status_Reason_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.candidate_status_reason". */
export type Recruiting_Candidate_Status_Reason_Order_By = {
  id?:InputMaybe<Order_By>;
  is_active?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  status?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.candidate_status_reason */
export type Recruiting_Candidate_Status_Reason_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "recruiting.candidate_status_reason" */
export enum Recruiting_Candidate_Status_Reason_Select_Column 
{
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  UiOrder = 'ui_order'
}

/** input type for updating data in table "recruiting.candidate_status_reason" */
export type Recruiting_Candidate_Status_Reason_Set_Input = {
  id?:InputMaybe<Scalars['Int']>;
  is_active?:InputMaybe<Scalars['Boolean']>;
  name?:InputMaybe<Scalars['String']>;
  status?:InputMaybe<Recruiting_Candidate_Status_Enum>;
  ui_order?:InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Recruiting_Candidate_Status_Reason_Stddev_Fields = {
  __typename?:'recruiting_candidate_status_reason_stddev_fields';
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Candidate_Status_Reason_Stddev_Pop_Fields = {
  __typename?:'recruiting_candidate_status_reason_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Candidate_Status_Reason_Stddev_Samp_Fields = {
  __typename?:'recruiting_candidate_status_reason_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "recruiting_candidate_status_reason" */
export type Recruiting_Candidate_Status_Reason_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Candidate_Status_Reason_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Candidate_Status_Reason_Stream_Cursor_Value_Input = {
  id?:InputMaybe<Scalars['Int']>;
  is_active?:InputMaybe<Scalars['Boolean']>;
  name?:InputMaybe<Scalars['String']>;
  status?:InputMaybe<Recruiting_Candidate_Status_Enum>;
  ui_order?:InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Recruiting_Candidate_Status_Reason_Sum_Fields = {
  __typename?:'recruiting_candidate_status_reason_sum_fields';
  id?:Maybe<Scalars['Int']>;
  ui_order?:Maybe<Scalars['Int']>;
};

/** update columns of table "recruiting.candidate_status_reason" */
export enum Recruiting_Candidate_Status_Reason_Update_Column 
{
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  UiOrder = 'ui_order'
}

export type Recruiting_Candidate_Status_Reason_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Candidate_Status_Reason_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Candidate_Status_Reason_Set_Input>;
  where:Recruiting_Candidate_Status_Reason_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Candidate_Status_Reason_Var_Pop_Fields = {
  __typename?:'recruiting_candidate_status_reason_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Recruiting_Candidate_Status_Reason_Var_Samp_Fields = {
  __typename?:'recruiting_candidate_status_reason_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Recruiting_Candidate_Status_Reason_Variance_Fields = {
  __typename?:'recruiting_candidate_status_reason_variance_fields';
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** select columns of table "recruiting.candidate_status" */
export enum Recruiting_Candidate_Status_Select_Column 
{
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "recruiting.candidate_status" */
export type Recruiting_Candidate_Status_Set_Input = {
  status?:InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "recruiting_candidate_status" */
export type Recruiting_Candidate_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Candidate_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Candidate_Status_Stream_Cursor_Value_Input = {
  status?:InputMaybe<Scalars['String']>;
};

/** update columns of table "recruiting.candidate_status" */
export enum Recruiting_Candidate_Status_Update_Column 
{
  /** column name */
  Status = 'status'
}

export type Recruiting_Candidate_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Candidate_Status_Set_Input>;
  where:Recruiting_Candidate_Status_Bool_Exp;
};

/** aggregate stddev on columns */
export type Recruiting_Candidate_Stddev_Fields = {
  __typename?:'recruiting_candidate_stddev_fields';
  assigned_user_id?:Maybe<Scalars['Float']>;
  city_id?:Maybe<Scalars['Float']>;
  country_id?:Maybe<Scalars['Float']>;
  created_by_user_id?:Maybe<Scalars['Float']>;
  experience_in_years?:Maybe<Scalars['Float']>;
  finalized_allocation_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  interview_flow_id_deprecated?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
  interview_flow_substage_id?:Maybe<Scalars['Float']>;
  recommended_by_staff_id?:Maybe<Scalars['Float']>;
  recruited_by_user_id?:Maybe<Scalars['Float']>;
  scheduled_call_id?:Maybe<Scalars['Float']>;
  sourced_by_user_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
  status_reason_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recruiting.candidate" */
export type Recruiting_Candidate_Stddev_Order_By = {
  assigned_user_id?:InputMaybe<Order_By>;
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  created_by_user_id?:InputMaybe<Order_By>;
  experience_in_years?:InputMaybe<Order_By>;
  finalized_allocation_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow_id_deprecated?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  interview_flow_substage_id?:InputMaybe<Order_By>;
  recommended_by_staff_id?:InputMaybe<Order_By>;
  recruited_by_user_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
  sourced_by_user_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
  status_reason_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Candidate_Stddev_Pop_Fields = {
  __typename?:'recruiting_candidate_stddev_pop_fields';
  assigned_user_id?:Maybe<Scalars['Float']>;
  city_id?:Maybe<Scalars['Float']>;
  country_id?:Maybe<Scalars['Float']>;
  created_by_user_id?:Maybe<Scalars['Float']>;
  experience_in_years?:Maybe<Scalars['Float']>;
  finalized_allocation_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  interview_flow_id_deprecated?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
  interview_flow_substage_id?:Maybe<Scalars['Float']>;
  recommended_by_staff_id?:Maybe<Scalars['Float']>;
  recruited_by_user_id?:Maybe<Scalars['Float']>;
  scheduled_call_id?:Maybe<Scalars['Float']>;
  sourced_by_user_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
  status_reason_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recruiting.candidate" */
export type Recruiting_Candidate_Stddev_Pop_Order_By = {
  assigned_user_id?:InputMaybe<Order_By>;
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  created_by_user_id?:InputMaybe<Order_By>;
  experience_in_years?:InputMaybe<Order_By>;
  finalized_allocation_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow_id_deprecated?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  interview_flow_substage_id?:InputMaybe<Order_By>;
  recommended_by_staff_id?:InputMaybe<Order_By>;
  recruited_by_user_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
  sourced_by_user_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
  status_reason_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Candidate_Stddev_Samp_Fields = {
  __typename?:'recruiting_candidate_stddev_samp_fields';
  assigned_user_id?:Maybe<Scalars['Float']>;
  city_id?:Maybe<Scalars['Float']>;
  country_id?:Maybe<Scalars['Float']>;
  created_by_user_id?:Maybe<Scalars['Float']>;
  experience_in_years?:Maybe<Scalars['Float']>;
  finalized_allocation_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  interview_flow_id_deprecated?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
  interview_flow_substage_id?:Maybe<Scalars['Float']>;
  recommended_by_staff_id?:Maybe<Scalars['Float']>;
  recruited_by_user_id?:Maybe<Scalars['Float']>;
  scheduled_call_id?:Maybe<Scalars['Float']>;
  sourced_by_user_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
  status_reason_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recruiting.candidate" */
export type Recruiting_Candidate_Stddev_Samp_Order_By = {
  assigned_user_id?:InputMaybe<Order_By>;
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  created_by_user_id?:InputMaybe<Order_By>;
  experience_in_years?:InputMaybe<Order_By>;
  finalized_allocation_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow_id_deprecated?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  interview_flow_substage_id?:InputMaybe<Order_By>;
  recommended_by_staff_id?:InputMaybe<Order_By>;
  recruited_by_user_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
  sourced_by_user_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
  status_reason_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recruiting_candidate" */
export type Recruiting_Candidate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Candidate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Candidate_Stream_Cursor_Value_Input = {
  assigned_user_id?:InputMaybe<Scalars['Int']>;
  avatar_url?:InputMaybe<Scalars['String']>;
  city_id?:InputMaybe<Scalars['Int']>;
  country_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  created_by_user_id?:InputMaybe<Scalars['Int']>;
  current_employer?:InputMaybe<Scalars['String']>;
  cv_google_doc_ref?:InputMaybe<Scalars['String']>;
  email?:InputMaybe<Scalars['String']>;
  experience_in_years?:InputMaybe<Scalars['Int']>;
  finalized_allocation_id?:InputMaybe<Scalars['Int']>;
  first_name?:InputMaybe<Scalars['String']>;
  first_name_i18n?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  instant_messenger_id?:InputMaybe<Scalars['String']>;
  interview_flow_id_deprecated?:InputMaybe<Scalars['Int']>;
  interview_flow_stage_id?:InputMaybe<Scalars['Int']>;
  interview_flow_substage_id?:InputMaybe<Scalars['Int']>;
  is_on_flow_deprecated?:InputMaybe<Scalars['Boolean']>;
  last_name?:InputMaybe<Scalars['String']>;
  last_name_i18n?:InputMaybe<Scalars['String']>;
  linkedin_profile_url?:InputMaybe<Scalars['String']>;
  middle_name_i18n?:InputMaybe<Scalars['String']>;
  phone?:InputMaybe<Scalars['String']>;
  recommended_by_staff_id?:InputMaybe<Scalars['Int']>;
  recruited_by_user_id?:InputMaybe<Scalars['Int']>;
  scheduled_call_id?:InputMaybe<Scalars['Int']>;
  secondary_email?:InputMaybe<Scalars['String']>;
  source?:InputMaybe<Scalars['String']>;
  sourced_by_user_id?:InputMaybe<Scalars['Int']>;
  staff_id?:InputMaybe<Scalars['Int']>;
  status?:InputMaybe<Recruiting_Candidate_Status_Enum>;
  status_date?:InputMaybe<Scalars['timestamptz']>;
  status_reason_id?:InputMaybe<Scalars['Int']>;
  status_reason_notes?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Recruiting_Candidate_Sum_Fields = {
  __typename?:'recruiting_candidate_sum_fields';
  assigned_user_id?:Maybe<Scalars['Int']>;
  city_id?:Maybe<Scalars['Int']>;
  country_id?:Maybe<Scalars['Int']>;
  created_by_user_id?:Maybe<Scalars['Int']>;
  experience_in_years?:Maybe<Scalars['Int']>;
  finalized_allocation_id?:Maybe<Scalars['Int']>;
  id?:Maybe<Scalars['Int']>;
  interview_flow_id_deprecated?:Maybe<Scalars['Int']>;
  interview_flow_stage_id?:Maybe<Scalars['Int']>;
  interview_flow_substage_id?:Maybe<Scalars['Int']>;
  recommended_by_staff_id?:Maybe<Scalars['Int']>;
  recruited_by_user_id?:Maybe<Scalars['Int']>;
  scheduled_call_id?:Maybe<Scalars['Int']>;
  sourced_by_user_id?:Maybe<Scalars['Int']>;
  staff_id?:Maybe<Scalars['Int']>;
  status_reason_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recruiting.candidate" */
export type Recruiting_Candidate_Sum_Order_By = {
  assigned_user_id?:InputMaybe<Order_By>;
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  created_by_user_id?:InputMaybe<Order_By>;
  experience_in_years?:InputMaybe<Order_By>;
  finalized_allocation_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow_id_deprecated?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  interview_flow_substage_id?:InputMaybe<Order_By>;
  recommended_by_staff_id?:InputMaybe<Order_By>;
  recruited_by_user_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
  sourced_by_user_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
  status_reason_id?:InputMaybe<Order_By>;
};

/** update columns of table "recruiting.candidate" */
export enum Recruiting_Candidate_Update_Column 
{
  /** column name */
  AssignedUserId = 'assigned_user_id',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CityId = 'city_id',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  CurrentEmployer = 'current_employer',
  /** column name */
  CvGoogleDocRef = 'cv_google_doc_ref',
  /** column name */
  Email = 'email',
  /** column name */
  ExperienceInYears = 'experience_in_years',
  /** column name */
  FinalizedAllocationId = 'finalized_allocation_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FirstNameI18n = 'first_name_i18n',
  /** column name */
  Id = 'id',
  /** column name */
  InstantMessengerId = 'instant_messenger_id',
  /** column name */
  InterviewFlowIdDeprecated = 'interview_flow_id_deprecated',
  /** column name */
  InterviewFlowStageId = 'interview_flow_stage_id',
  /** column name */
  InterviewFlowSubstageId = 'interview_flow_substage_id',
  /** column name */
  IsOnFlowDeprecated = 'is_on_flow_deprecated',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastNameI18n = 'last_name_i18n',
  /** column name */
  LinkedinProfileUrl = 'linkedin_profile_url',
  /** column name */
  MiddleNameI18n = 'middle_name_i18n',
  /** column name */
  Phone = 'phone',
  /** column name */
  RecommendedByStaffId = 'recommended_by_staff_id',
  /** column name */
  RecruitedByUserId = 'recruited_by_user_id',
  /** column name */
  ScheduledCallId = 'scheduled_call_id',
  /** column name */
  SecondaryEmail = 'secondary_email',
  /** column name */
  Source = 'source',
  /** column name */
  SourcedByUserId = 'sourced_by_user_id',
  /** column name */
  StaffId = 'staff_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDate = 'status_date',
  /** column name */
  StatusReasonId = 'status_reason_id',
  /** column name */
  StatusReasonNotes = 'status_reason_notes',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Recruiting_Candidate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Candidate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Candidate_Set_Input>;
  where:Recruiting_Candidate_Bool_Exp;
};

/** columns and relationships of "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer = {
  __typename?:'recruiting_candidate_user_interviewer';
  /** An object relationship */
  candidate:Recruiting_Candidate;
  candidate_id:Scalars['Int'];
  is_done:Scalars['Boolean'];
  /** An object relationship */
  scheduled_event?:Maybe<Common_Scheduled_Event>;
  scheduled_event_id?:Maybe<Scalars['Int']>;
  /** An object relationship */
  user:Common_User;
  user_id:Scalars['Int'];
};

/** aggregated selection of "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Aggregate = {
  __typename?:'recruiting_candidate_user_interviewer_aggregate';
  aggregate?:Maybe<Recruiting_Candidate_User_Interviewer_Aggregate_Fields>;
  nodes:Array<Recruiting_Candidate_User_Interviewer>;
};

export type Recruiting_Candidate_User_Interviewer_Aggregate_Bool_Exp = {
  bool_and?:InputMaybe<Recruiting_Candidate_User_Interviewer_Aggregate_Bool_Exp_Bool_And>;
  bool_or?:InputMaybe<Recruiting_Candidate_User_Interviewer_Aggregate_Bool_Exp_Bool_Or>;
  count?:InputMaybe<Recruiting_Candidate_User_Interviewer_Aggregate_Bool_Exp_Count>;
};

export type Recruiting_Candidate_User_Interviewer_Aggregate_Bool_Exp_Bool_And = {
  arguments:Recruiting_Candidate_User_Interviewer_Select_Column_Recruiting_Candidate_User_Interviewer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Candidate_User_Interviewer_Bool_Exp>;
  predicate:Boolean_Comparison_Exp;
};

export type Recruiting_Candidate_User_Interviewer_Aggregate_Bool_Exp_Bool_Or = {
  arguments:Recruiting_Candidate_User_Interviewer_Select_Column_Recruiting_Candidate_User_Interviewer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Candidate_User_Interviewer_Bool_Exp>;
  predicate:Boolean_Comparison_Exp;
};

export type Recruiting_Candidate_User_Interviewer_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Recruiting_Candidate_User_Interviewer_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Candidate_User_Interviewer_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Aggregate_Fields = {
  __typename?:'recruiting_candidate_user_interviewer_aggregate_fields';
  avg?:Maybe<Recruiting_Candidate_User_Interviewer_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Candidate_User_Interviewer_Max_Fields>;
  min?:Maybe<Recruiting_Candidate_User_Interviewer_Min_Fields>;
  stddev?:Maybe<Recruiting_Candidate_User_Interviewer_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Candidate_User_Interviewer_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Candidate_User_Interviewer_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Candidate_User_Interviewer_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Candidate_User_Interviewer_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Candidate_User_Interviewer_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Candidate_User_Interviewer_Variance_Fields>;
};


/** aggregate fields of "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Candidate_User_Interviewer_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Aggregate_Order_By = {
  avg?:InputMaybe<Recruiting_Candidate_User_Interviewer_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Recruiting_Candidate_User_Interviewer_Max_Order_By>;
  min?:InputMaybe<Recruiting_Candidate_User_Interviewer_Min_Order_By>;
  stddev?:InputMaybe<Recruiting_Candidate_User_Interviewer_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Recruiting_Candidate_User_Interviewer_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Recruiting_Candidate_User_Interviewer_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Recruiting_Candidate_User_Interviewer_Sum_Order_By>;
  var_pop?:InputMaybe<Recruiting_Candidate_User_Interviewer_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Recruiting_Candidate_User_Interviewer_Var_Samp_Order_By>;
  variance?:InputMaybe<Recruiting_Candidate_User_Interviewer_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Arr_Rel_Insert_Input = {
  data:Array<Recruiting_Candidate_User_Interviewer_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Candidate_User_Interviewer_On_Conflict>;
};

/** aggregate avg on columns */
export type Recruiting_Candidate_User_Interviewer_Avg_Fields = {
  __typename?:'recruiting_candidate_user_interviewer_avg_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  scheduled_event_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Avg_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  scheduled_event_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recruiting.candidate_user_interviewer". All fields are combined with a logical 'AND'. */
export type Recruiting_Candidate_User_Interviewer_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Candidate_User_Interviewer_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Candidate_User_Interviewer_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Candidate_User_Interviewer_Bool_Exp>>;
  candidate?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
  candidate_id?:InputMaybe<Int_Comparison_Exp>;
  is_done?:InputMaybe<Boolean_Comparison_Exp>;
  scheduled_event?:InputMaybe<Common_Scheduled_Event_Bool_Exp>;
  scheduled_event_id?:InputMaybe<Int_Comparison_Exp>;
  user?:InputMaybe<Common_User_Bool_Exp>;
  user_id?:InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.candidate_user_interviewer" */
export enum Recruiting_Candidate_User_Interviewer_Constraint 
{
  /** unique or primary key constraint on columns "candidate_id", "user_id" */
  CandidateUserInterviewerPkey = 'candidate_user_interviewer_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Inc_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  scheduled_event_id?:InputMaybe<Scalars['Int']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Insert_Input = {
  candidate?:InputMaybe<Recruiting_Candidate_Obj_Rel_Insert_Input>;
  candidate_id?:InputMaybe<Scalars['Int']>;
  is_done?:InputMaybe<Scalars['Boolean']>;
  scheduled_event?:InputMaybe<Common_Scheduled_Event_Obj_Rel_Insert_Input>;
  scheduled_event_id?:InputMaybe<Scalars['Int']>;
  user?:InputMaybe<Common_User_Obj_Rel_Insert_Input>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Recruiting_Candidate_User_Interviewer_Max_Fields = {
  __typename?:'recruiting_candidate_user_interviewer_max_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  scheduled_event_id?:Maybe<Scalars['Int']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Max_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  scheduled_event_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recruiting_Candidate_User_Interviewer_Min_Fields = {
  __typename?:'recruiting_candidate_user_interviewer_min_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  scheduled_event_id?:Maybe<Scalars['Int']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Min_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  scheduled_event_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Mutation_Response = {
  __typename?:'recruiting_candidate_user_interviewer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Candidate_User_Interviewer>;
};

/** on_conflict condition type for table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_On_Conflict = {
  constraint:Recruiting_Candidate_User_Interviewer_Constraint;
  update_columns?:Array<Recruiting_Candidate_User_Interviewer_Update_Column>;
  where?:InputMaybe<Recruiting_Candidate_User_Interviewer_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.candidate_user_interviewer". */
export type Recruiting_Candidate_User_Interviewer_Order_By = {
  candidate?:InputMaybe<Recruiting_Candidate_Order_By>;
  candidate_id?:InputMaybe<Order_By>;
  is_done?:InputMaybe<Order_By>;
  scheduled_event?:InputMaybe<Common_Scheduled_Event_Order_By>;
  scheduled_event_id?:InputMaybe<Order_By>;
  user?:InputMaybe<Common_User_Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.candidate_user_interviewer */
export type Recruiting_Candidate_User_Interviewer_Pk_Columns_Input = {
  candidate_id:Scalars['Int'];
  user_id:Scalars['Int'];
};

/** select columns of table "recruiting.candidate_user_interviewer" */
export enum Recruiting_Candidate_User_Interviewer_Select_Column 
{
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  IsDone = 'is_done',
  /** column name */
  ScheduledEventId = 'scheduled_event_id',
  /** column name */
  UserId = 'user_id'
}

/** select "recruiting_candidate_user_interviewer_aggregate_bool_exp_bool_and_arguments_columns" columns of table "recruiting.candidate_user_interviewer" */
export enum Recruiting_Candidate_User_Interviewer_Select_Column_Recruiting_Candidate_User_Interviewer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns 
{
  /** column name */
  IsDone = 'is_done'
}

/** select "recruiting_candidate_user_interviewer_aggregate_bool_exp_bool_or_arguments_columns" columns of table "recruiting.candidate_user_interviewer" */
export enum Recruiting_Candidate_User_Interviewer_Select_Column_Recruiting_Candidate_User_Interviewer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns 
{
  /** column name */
  IsDone = 'is_done'
}

/** input type for updating data in table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Set_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  is_done?:InputMaybe<Scalars['Boolean']>;
  scheduled_event_id?:InputMaybe<Scalars['Int']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Recruiting_Candidate_User_Interviewer_Stddev_Fields = {
  __typename?:'recruiting_candidate_user_interviewer_stddev_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  scheduled_event_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Stddev_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  scheduled_event_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Candidate_User_Interviewer_Stddev_Pop_Fields = {
  __typename?:'recruiting_candidate_user_interviewer_stddev_pop_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  scheduled_event_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Stddev_Pop_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  scheduled_event_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Candidate_User_Interviewer_Stddev_Samp_Fields = {
  __typename?:'recruiting_candidate_user_interviewer_stddev_samp_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  scheduled_event_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Stddev_Samp_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  scheduled_event_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recruiting_candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Candidate_User_Interviewer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Candidate_User_Interviewer_Stream_Cursor_Value_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  is_done?:InputMaybe<Scalars['Boolean']>;
  scheduled_event_id?:InputMaybe<Scalars['Int']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Recruiting_Candidate_User_Interviewer_Sum_Fields = {
  __typename?:'recruiting_candidate_user_interviewer_sum_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  scheduled_event_id?:Maybe<Scalars['Int']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Sum_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  scheduled_event_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** update columns of table "recruiting.candidate_user_interviewer" */
export enum Recruiting_Candidate_User_Interviewer_Update_Column 
{
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  IsDone = 'is_done',
  /** column name */
  ScheduledEventId = 'scheduled_event_id',
  /** column name */
  UserId = 'user_id'
}

export type Recruiting_Candidate_User_Interviewer_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Candidate_User_Interviewer_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Candidate_User_Interviewer_Set_Input>;
  where:Recruiting_Candidate_User_Interviewer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Candidate_User_Interviewer_Var_Pop_Fields = {
  __typename?:'recruiting_candidate_user_interviewer_var_pop_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  scheduled_event_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Var_Pop_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  scheduled_event_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recruiting_Candidate_User_Interviewer_Var_Samp_Fields = {
  __typename?:'recruiting_candidate_user_interviewer_var_samp_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  scheduled_event_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Var_Samp_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  scheduled_event_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recruiting_Candidate_User_Interviewer_Variance_Fields = {
  __typename?:'recruiting_candidate_user_interviewer_variance_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  scheduled_event_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recruiting.candidate_user_interviewer" */
export type Recruiting_Candidate_User_Interviewer_Variance_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  scheduled_event_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Recruiting_Candidate_Var_Pop_Fields = {
  __typename?:'recruiting_candidate_var_pop_fields';
  assigned_user_id?:Maybe<Scalars['Float']>;
  city_id?:Maybe<Scalars['Float']>;
  country_id?:Maybe<Scalars['Float']>;
  created_by_user_id?:Maybe<Scalars['Float']>;
  experience_in_years?:Maybe<Scalars['Float']>;
  finalized_allocation_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  interview_flow_id_deprecated?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
  interview_flow_substage_id?:Maybe<Scalars['Float']>;
  recommended_by_staff_id?:Maybe<Scalars['Float']>;
  recruited_by_user_id?:Maybe<Scalars['Float']>;
  scheduled_call_id?:Maybe<Scalars['Float']>;
  sourced_by_user_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
  status_reason_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recruiting.candidate" */
export type Recruiting_Candidate_Var_Pop_Order_By = {
  assigned_user_id?:InputMaybe<Order_By>;
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  created_by_user_id?:InputMaybe<Order_By>;
  experience_in_years?:InputMaybe<Order_By>;
  finalized_allocation_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow_id_deprecated?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  interview_flow_substage_id?:InputMaybe<Order_By>;
  recommended_by_staff_id?:InputMaybe<Order_By>;
  recruited_by_user_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
  sourced_by_user_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
  status_reason_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recruiting_Candidate_Var_Samp_Fields = {
  __typename?:'recruiting_candidate_var_samp_fields';
  assigned_user_id?:Maybe<Scalars['Float']>;
  city_id?:Maybe<Scalars['Float']>;
  country_id?:Maybe<Scalars['Float']>;
  created_by_user_id?:Maybe<Scalars['Float']>;
  experience_in_years?:Maybe<Scalars['Float']>;
  finalized_allocation_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  interview_flow_id_deprecated?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
  interview_flow_substage_id?:Maybe<Scalars['Float']>;
  recommended_by_staff_id?:Maybe<Scalars['Float']>;
  recruited_by_user_id?:Maybe<Scalars['Float']>;
  scheduled_call_id?:Maybe<Scalars['Float']>;
  sourced_by_user_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
  status_reason_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recruiting.candidate" */
export type Recruiting_Candidate_Var_Samp_Order_By = {
  assigned_user_id?:InputMaybe<Order_By>;
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  created_by_user_id?:InputMaybe<Order_By>;
  experience_in_years?:InputMaybe<Order_By>;
  finalized_allocation_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow_id_deprecated?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  interview_flow_substage_id?:InputMaybe<Order_By>;
  recommended_by_staff_id?:InputMaybe<Order_By>;
  recruited_by_user_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
  sourced_by_user_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
  status_reason_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recruiting_Candidate_Variance_Fields = {
  __typename?:'recruiting_candidate_variance_fields';
  assigned_user_id?:Maybe<Scalars['Float']>;
  city_id?:Maybe<Scalars['Float']>;
  country_id?:Maybe<Scalars['Float']>;
  created_by_user_id?:Maybe<Scalars['Float']>;
  experience_in_years?:Maybe<Scalars['Float']>;
  finalized_allocation_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  interview_flow_id_deprecated?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
  interview_flow_substage_id?:Maybe<Scalars['Float']>;
  recommended_by_staff_id?:Maybe<Scalars['Float']>;
  recruited_by_user_id?:Maybe<Scalars['Float']>;
  scheduled_call_id?:Maybe<Scalars['Float']>;
  sourced_by_user_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
  status_reason_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recruiting.candidate" */
export type Recruiting_Candidate_Variance_Order_By = {
  assigned_user_id?:InputMaybe<Order_By>;
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  created_by_user_id?:InputMaybe<Order_By>;
  experience_in_years?:InputMaybe<Order_By>;
  finalized_allocation_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow_id_deprecated?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  interview_flow_substage_id?:InputMaybe<Order_By>;
  recommended_by_staff_id?:InputMaybe<Order_By>;
  recruited_by_user_id?:InputMaybe<Order_By>;
  scheduled_call_id?:InputMaybe<Order_By>;
  sourced_by_user_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
  status_reason_id?:InputMaybe<Order_By>;
};

/** columns and relationships of "recruiting.interview_flow" */
export type Recruiting_Interview_Flow = {
  __typename?:'recruiting_interview_flow';
  created_at:Scalars['timestamptz'];
  id:Scalars['Int'];
  name:Scalars['String'];
  /** An array relationship */
  position_groups:Array<Recruiting_Position_Group>;
  /** An aggregate relationship */
  position_groups_aggregate:Recruiting_Position_Group_Aggregate;
  rules?:Maybe<Scalars['jsonb']>;
  /** An array relationship */
  stages:Array<Recruiting_Interview_Flow_Stage>;
  /** An aggregate relationship */
  stages_aggregate:Recruiting_Interview_Flow_Stage_Aggregate;
};


/** columns and relationships of "recruiting.interview_flow" */
export type Recruiting_Interview_FlowPosition_GroupsArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Group_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Group_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
};


/** columns and relationships of "recruiting.interview_flow" */
export type Recruiting_Interview_FlowPosition_Groups_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Group_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Group_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
};


/** columns and relationships of "recruiting.interview_flow" */
export type Recruiting_Interview_FlowRulesArgs = {
  path?:InputMaybe<Scalars['String']>;
};


/** columns and relationships of "recruiting.interview_flow" */
export type Recruiting_Interview_FlowStagesArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Interview_Flow_Stage_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Interview_Flow_Stage_Order_By>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Stage_Bool_Exp>;
};


/** columns and relationships of "recruiting.interview_flow" */
export type Recruiting_Interview_FlowStages_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Interview_Flow_Stage_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Interview_Flow_Stage_Order_By>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Stage_Bool_Exp>;
};

/** aggregated selection of "recruiting.interview_flow" */
export type Recruiting_Interview_Flow_Aggregate = {
  __typename?:'recruiting_interview_flow_aggregate';
  aggregate?:Maybe<Recruiting_Interview_Flow_Aggregate_Fields>;
  nodes:Array<Recruiting_Interview_Flow>;
};

/** aggregate fields of "recruiting.interview_flow" */
export type Recruiting_Interview_Flow_Aggregate_Fields = {
  __typename?:'recruiting_interview_flow_aggregate_fields';
  avg?:Maybe<Recruiting_Interview_Flow_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Interview_Flow_Max_Fields>;
  min?:Maybe<Recruiting_Interview_Flow_Min_Fields>;
  stddev?:Maybe<Recruiting_Interview_Flow_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Interview_Flow_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Interview_Flow_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Interview_Flow_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Interview_Flow_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Interview_Flow_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Interview_Flow_Variance_Fields>;
};


/** aggregate fields of "recruiting.interview_flow" */
export type Recruiting_Interview_Flow_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Interview_Flow_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Recruiting_Interview_Flow_Append_Input = {
  rules?:InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Recruiting_Interview_Flow_Avg_Fields = {
  __typename?:'recruiting_interview_flow_avg_fields';
  id?:Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "recruiting.interview_flow". All fields are combined with a logical 'AND'. */
export type Recruiting_Interview_Flow_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Interview_Flow_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Interview_Flow_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Interview_Flow_Bool_Exp>>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  name?:InputMaybe<String_Comparison_Exp>;
  position_groups?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
  position_groups_aggregate?:InputMaybe<Recruiting_Position_Group_Aggregate_Bool_Exp>;
  rules?:InputMaybe<Jsonb_Comparison_Exp>;
  stages?:InputMaybe<Recruiting_Interview_Flow_Stage_Bool_Exp>;
  stages_aggregate?:InputMaybe<Recruiting_Interview_Flow_Stage_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "recruiting.interview_flow" */
export enum Recruiting_Interview_Flow_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  InterviewFlowPkey = 'interview_flow_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Recruiting_Interview_Flow_Delete_At_Path_Input = {
  rules?:InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Recruiting_Interview_Flow_Delete_Elem_Input = {
  rules?:InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Recruiting_Interview_Flow_Delete_Key_Input = {
  rules?:InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "recruiting.interview_flow" */
export type Recruiting_Interview_Flow_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.interview_flow" */
export type Recruiting_Interview_Flow_Insert_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  position_groups?:InputMaybe<Recruiting_Position_Group_Arr_Rel_Insert_Input>;
  rules?:InputMaybe<Scalars['jsonb']>;
  stages?:InputMaybe<Recruiting_Interview_Flow_Stage_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Recruiting_Interview_Flow_Max_Fields = {
  __typename?:'recruiting_interview_flow_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Recruiting_Interview_Flow_Min_Fields = {
  __typename?:'recruiting_interview_flow_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
};

/** response of any mutation on the table "recruiting.interview_flow" */
export type Recruiting_Interview_Flow_Mutation_Response = {
  __typename?:'recruiting_interview_flow_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Interview_Flow>;
};

/** input type for inserting object relation for remote table "recruiting.interview_flow" */
export type Recruiting_Interview_Flow_Obj_Rel_Insert_Input = {
  data:Recruiting_Interview_Flow_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Interview_Flow_On_Conflict>;
};

/** on_conflict condition type for table "recruiting.interview_flow" */
export type Recruiting_Interview_Flow_On_Conflict = {
  constraint:Recruiting_Interview_Flow_Constraint;
  update_columns?:Array<Recruiting_Interview_Flow_Update_Column>;
  where?:InputMaybe<Recruiting_Interview_Flow_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.interview_flow". */
export type Recruiting_Interview_Flow_Order_By = {
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  position_groups_aggregate?:InputMaybe<Recruiting_Position_Group_Aggregate_Order_By>;
  rules?:InputMaybe<Order_By>;
  stages_aggregate?:InputMaybe<Recruiting_Interview_Flow_Stage_Aggregate_Order_By>;
};

/** primary key columns input for table: recruiting.interview_flow */
export type Recruiting_Interview_Flow_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Recruiting_Interview_Flow_Prepend_Input = {
  rules?:InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "recruiting.interview_flow" */
export enum Recruiting_Interview_Flow_Select_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rules = 'rules'
}

/** input type for updating data in table "recruiting.interview_flow" */
export type Recruiting_Interview_Flow_Set_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  rules?:InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage = {
  __typename?:'recruiting_interview_flow_stage';
  created_at:Scalars['timestamptz'];
  id:Scalars['Int'];
  /** An object relationship */
  interview_flow:Recruiting_Interview_Flow;
  interview_flow_id:Scalars['Int'];
  name:Scalars['String'];
  /** An array relationship */
  substages:Array<Recruiting_Interview_Flow_Substage>;
  /** An aggregate relationship */
  substages_aggregate:Recruiting_Interview_Flow_Substage_Aggregate;
};


/** columns and relationships of "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_StageSubstagesArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Interview_Flow_Substage_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Interview_Flow_Substage_Order_By>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Substage_Bool_Exp>;
};


/** columns and relationships of "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_StageSubstages_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Interview_Flow_Substage_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Interview_Flow_Substage_Order_By>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Substage_Bool_Exp>;
};

/** aggregated selection of "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Aggregate = {
  __typename?:'recruiting_interview_flow_stage_aggregate';
  aggregate?:Maybe<Recruiting_Interview_Flow_Stage_Aggregate_Fields>;
  nodes:Array<Recruiting_Interview_Flow_Stage>;
};

export type Recruiting_Interview_Flow_Stage_Aggregate_Bool_Exp = {
  count?:InputMaybe<Recruiting_Interview_Flow_Stage_Aggregate_Bool_Exp_Count>;
};

export type Recruiting_Interview_Flow_Stage_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Recruiting_Interview_Flow_Stage_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Interview_Flow_Stage_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Aggregate_Fields = {
  __typename?:'recruiting_interview_flow_stage_aggregate_fields';
  avg?:Maybe<Recruiting_Interview_Flow_Stage_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Interview_Flow_Stage_Max_Fields>;
  min?:Maybe<Recruiting_Interview_Flow_Stage_Min_Fields>;
  stddev?:Maybe<Recruiting_Interview_Flow_Stage_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Interview_Flow_Stage_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Interview_Flow_Stage_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Interview_Flow_Stage_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Interview_Flow_Stage_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Interview_Flow_Stage_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Interview_Flow_Stage_Variance_Fields>;
};


/** aggregate fields of "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Interview_Flow_Stage_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Aggregate_Order_By = {
  avg?:InputMaybe<Recruiting_Interview_Flow_Stage_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Recruiting_Interview_Flow_Stage_Max_Order_By>;
  min?:InputMaybe<Recruiting_Interview_Flow_Stage_Min_Order_By>;
  stddev?:InputMaybe<Recruiting_Interview_Flow_Stage_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Recruiting_Interview_Flow_Stage_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Recruiting_Interview_Flow_Stage_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Recruiting_Interview_Flow_Stage_Sum_Order_By>;
  var_pop?:InputMaybe<Recruiting_Interview_Flow_Stage_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Recruiting_Interview_Flow_Stage_Var_Samp_Order_By>;
  variance?:InputMaybe<Recruiting_Interview_Flow_Stage_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Arr_Rel_Insert_Input = {
  data:Array<Recruiting_Interview_Flow_Stage_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Interview_Flow_Stage_On_Conflict>;
};

/** aggregate avg on columns */
export type Recruiting_Interview_Flow_Stage_Avg_Fields = {
  __typename?:'recruiting_interview_flow_stage_avg_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Avg_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recruiting.interview_flow_stage". All fields are combined with a logical 'AND'. */
export type Recruiting_Interview_Flow_Stage_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Interview_Flow_Stage_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Interview_Flow_Stage_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Interview_Flow_Stage_Bool_Exp>>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  interview_flow?:InputMaybe<Recruiting_Interview_Flow_Bool_Exp>;
  interview_flow_id?:InputMaybe<Int_Comparison_Exp>;
  name?:InputMaybe<String_Comparison_Exp>;
  substages?:InputMaybe<Recruiting_Interview_Flow_Substage_Bool_Exp>;
  substages_aggregate?:InputMaybe<Recruiting_Interview_Flow_Substage_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "recruiting.interview_flow_stage" */
export enum Recruiting_Interview_Flow_Stage_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  InterviewFlowStagePkey = 'interview_flow_stage_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
  interview_flow_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Insert_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  interview_flow?:InputMaybe<Recruiting_Interview_Flow_Obj_Rel_Insert_Input>;
  interview_flow_id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  substages?:InputMaybe<Recruiting_Interview_Flow_Substage_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Recruiting_Interview_Flow_Stage_Max_Fields = {
  __typename?:'recruiting_interview_flow_stage_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  interview_flow_id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
};

/** order by max() on columns of table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Max_Order_By = {
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recruiting_Interview_Flow_Stage_Min_Fields = {
  __typename?:'recruiting_interview_flow_stage_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  interview_flow_id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
};

/** order by min() on columns of table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Min_Order_By = {
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Mutation_Response = {
  __typename?:'recruiting_interview_flow_stage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Interview_Flow_Stage>;
};

/** input type for inserting object relation for remote table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Obj_Rel_Insert_Input = {
  data:Recruiting_Interview_Flow_Stage_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Interview_Flow_Stage_On_Conflict>;
};

/** on_conflict condition type for table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_On_Conflict = {
  constraint:Recruiting_Interview_Flow_Stage_Constraint;
  update_columns?:Array<Recruiting_Interview_Flow_Stage_Update_Column>;
  where?:InputMaybe<Recruiting_Interview_Flow_Stage_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.interview_flow_stage". */
export type Recruiting_Interview_Flow_Stage_Order_By = {
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow?:InputMaybe<Recruiting_Interview_Flow_Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  substages_aggregate?:InputMaybe<Recruiting_Interview_Flow_Substage_Aggregate_Order_By>;
};

/** primary key columns input for table: recruiting.interview_flow_stage */
export type Recruiting_Interview_Flow_Stage_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "recruiting.interview_flow_stage" */
export enum Recruiting_Interview_Flow_Stage_Select_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewFlowId = 'interview_flow_id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Set_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  interview_flow_id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Recruiting_Interview_Flow_Stage_Stddev_Fields = {
  __typename?:'recruiting_interview_flow_stage_stddev_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Stddev_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Interview_Flow_Stage_Stddev_Pop_Fields = {
  __typename?:'recruiting_interview_flow_stage_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Stddev_Pop_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Interview_Flow_Stage_Stddev_Samp_Fields = {
  __typename?:'recruiting_interview_flow_stage_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Stddev_Samp_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recruiting_interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Interview_Flow_Stage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Interview_Flow_Stage_Stream_Cursor_Value_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  interview_flow_id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Recruiting_Interview_Flow_Stage_Sum_Fields = {
  __typename?:'recruiting_interview_flow_stage_sum_fields';
  id?:Maybe<Scalars['Int']>;
  interview_flow_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Sum_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
};

/** update columns of table "recruiting.interview_flow_stage" */
export enum Recruiting_Interview_Flow_Stage_Update_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewFlowId = 'interview_flow_id',
  /** column name */
  Name = 'name'
}

export type Recruiting_Interview_Flow_Stage_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Interview_Flow_Stage_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Interview_Flow_Stage_Set_Input>;
  where:Recruiting_Interview_Flow_Stage_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Interview_Flow_Stage_Var_Pop_Fields = {
  __typename?:'recruiting_interview_flow_stage_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Var_Pop_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recruiting_Interview_Flow_Stage_Var_Samp_Fields = {
  __typename?:'recruiting_interview_flow_stage_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Var_Samp_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recruiting_Interview_Flow_Stage_Variance_Fields = {
  __typename?:'recruiting_interview_flow_stage_variance_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recruiting.interview_flow_stage" */
export type Recruiting_Interview_Flow_Stage_Variance_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Recruiting_Interview_Flow_Stddev_Fields = {
  __typename?:'recruiting_interview_flow_stddev_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Interview_Flow_Stddev_Pop_Fields = {
  __typename?:'recruiting_interview_flow_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Interview_Flow_Stddev_Samp_Fields = {
  __typename?:'recruiting_interview_flow_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "recruiting_interview_flow" */
export type Recruiting_Interview_Flow_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Interview_Flow_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Interview_Flow_Stream_Cursor_Value_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  rules?:InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage = {
  __typename?:'recruiting_interview_flow_substage';
  created_at:Scalars['timestamptz'];
  id:Scalars['Int'];
  interview_flow_stage_id:Scalars['Int'];
  name:Scalars['String'];
  /** An object relationship */
  stage:Recruiting_Interview_Flow_Stage;
};

/** aggregated selection of "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Aggregate = {
  __typename?:'recruiting_interview_flow_substage_aggregate';
  aggregate?:Maybe<Recruiting_Interview_Flow_Substage_Aggregate_Fields>;
  nodes:Array<Recruiting_Interview_Flow_Substage>;
};

export type Recruiting_Interview_Flow_Substage_Aggregate_Bool_Exp = {
  count?:InputMaybe<Recruiting_Interview_Flow_Substage_Aggregate_Bool_Exp_Count>;
};

export type Recruiting_Interview_Flow_Substage_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Recruiting_Interview_Flow_Substage_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Interview_Flow_Substage_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Aggregate_Fields = {
  __typename?:'recruiting_interview_flow_substage_aggregate_fields';
  avg?:Maybe<Recruiting_Interview_Flow_Substage_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Interview_Flow_Substage_Max_Fields>;
  min?:Maybe<Recruiting_Interview_Flow_Substage_Min_Fields>;
  stddev?:Maybe<Recruiting_Interview_Flow_Substage_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Interview_Flow_Substage_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Interview_Flow_Substage_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Interview_Flow_Substage_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Interview_Flow_Substage_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Interview_Flow_Substage_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Interview_Flow_Substage_Variance_Fields>;
};


/** aggregate fields of "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Interview_Flow_Substage_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Aggregate_Order_By = {
  avg?:InputMaybe<Recruiting_Interview_Flow_Substage_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Recruiting_Interview_Flow_Substage_Max_Order_By>;
  min?:InputMaybe<Recruiting_Interview_Flow_Substage_Min_Order_By>;
  stddev?:InputMaybe<Recruiting_Interview_Flow_Substage_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Recruiting_Interview_Flow_Substage_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Recruiting_Interview_Flow_Substage_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Recruiting_Interview_Flow_Substage_Sum_Order_By>;
  var_pop?:InputMaybe<Recruiting_Interview_Flow_Substage_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Recruiting_Interview_Flow_Substage_Var_Samp_Order_By>;
  variance?:InputMaybe<Recruiting_Interview_Flow_Substage_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Arr_Rel_Insert_Input = {
  data:Array<Recruiting_Interview_Flow_Substage_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Interview_Flow_Substage_On_Conflict>;
};

/** aggregate avg on columns */
export type Recruiting_Interview_Flow_Substage_Avg_Fields = {
  __typename?:'recruiting_interview_flow_substage_avg_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Avg_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recruiting.interview_flow_substage". All fields are combined with a logical 'AND'. */
export type Recruiting_Interview_Flow_Substage_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Interview_Flow_Substage_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Interview_Flow_Substage_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Interview_Flow_Substage_Bool_Exp>>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  interview_flow_stage_id?:InputMaybe<Int_Comparison_Exp>;
  name?:InputMaybe<String_Comparison_Exp>;
  stage?:InputMaybe<Recruiting_Interview_Flow_Stage_Bool_Exp>;
};

/** unique or primary key constraints on table "recruiting.interview_flow_substage" */
export enum Recruiting_Interview_Flow_Substage_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  InterviewFlowSubstagePkey = 'interview_flow_substage_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
  interview_flow_stage_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Insert_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  interview_flow_stage_id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  stage?:InputMaybe<Recruiting_Interview_Flow_Stage_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Recruiting_Interview_Flow_Substage_Max_Fields = {
  __typename?:'recruiting_interview_flow_substage_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  interview_flow_stage_id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
};

/** order by max() on columns of table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Max_Order_By = {
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recruiting_Interview_Flow_Substage_Min_Fields = {
  __typename?:'recruiting_interview_flow_substage_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  interview_flow_stage_id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
};

/** order by min() on columns of table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Min_Order_By = {
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Mutation_Response = {
  __typename?:'recruiting_interview_flow_substage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Interview_Flow_Substage>;
};

/** input type for inserting object relation for remote table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Obj_Rel_Insert_Input = {
  data:Recruiting_Interview_Flow_Substage_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Interview_Flow_Substage_On_Conflict>;
};

/** on_conflict condition type for table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_On_Conflict = {
  constraint:Recruiting_Interview_Flow_Substage_Constraint;
  update_columns?:Array<Recruiting_Interview_Flow_Substage_Update_Column>;
  where?:InputMaybe<Recruiting_Interview_Flow_Substage_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.interview_flow_substage". */
export type Recruiting_Interview_Flow_Substage_Order_By = {
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  stage?:InputMaybe<Recruiting_Interview_Flow_Stage_Order_By>;
};

/** primary key columns input for table: recruiting.interview_flow_substage */
export type Recruiting_Interview_Flow_Substage_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "recruiting.interview_flow_substage" */
export enum Recruiting_Interview_Flow_Substage_Select_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewFlowStageId = 'interview_flow_stage_id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Set_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  interview_flow_stage_id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Recruiting_Interview_Flow_Substage_Stddev_Fields = {
  __typename?:'recruiting_interview_flow_substage_stddev_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Stddev_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Interview_Flow_Substage_Stddev_Pop_Fields = {
  __typename?:'recruiting_interview_flow_substage_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Stddev_Pop_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Interview_Flow_Substage_Stddev_Samp_Fields = {
  __typename?:'recruiting_interview_flow_substage_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Stddev_Samp_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recruiting_interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Interview_Flow_Substage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Interview_Flow_Substage_Stream_Cursor_Value_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  interview_flow_stage_id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Recruiting_Interview_Flow_Substage_Sum_Fields = {
  __typename?:'recruiting_interview_flow_substage_sum_fields';
  id?:Maybe<Scalars['Int']>;
  interview_flow_stage_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Sum_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
};

/** update columns of table "recruiting.interview_flow_substage" */
export enum Recruiting_Interview_Flow_Substage_Update_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewFlowStageId = 'interview_flow_stage_id',
  /** column name */
  Name = 'name'
}

export type Recruiting_Interview_Flow_Substage_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Interview_Flow_Substage_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Interview_Flow_Substage_Set_Input>;
  where:Recruiting_Interview_Flow_Substage_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Interview_Flow_Substage_Var_Pop_Fields = {
  __typename?:'recruiting_interview_flow_substage_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Var_Pop_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recruiting_Interview_Flow_Substage_Var_Samp_Fields = {
  __typename?:'recruiting_interview_flow_substage_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Var_Samp_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recruiting_Interview_Flow_Substage_Variance_Fields = {
  __typename?:'recruiting_interview_flow_substage_variance_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_stage_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recruiting.interview_flow_substage" */
export type Recruiting_Interview_Flow_Substage_Variance_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_stage_id?:InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Recruiting_Interview_Flow_Sum_Fields = {
  __typename?:'recruiting_interview_flow_sum_fields';
  id?:Maybe<Scalars['Int']>;
};

/** update columns of table "recruiting.interview_flow" */
export enum Recruiting_Interview_Flow_Update_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rules = 'rules'
}

export type Recruiting_Interview_Flow_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?:InputMaybe<Recruiting_Interview_Flow_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?:InputMaybe<Recruiting_Interview_Flow_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?:InputMaybe<Recruiting_Interview_Flow_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?:InputMaybe<Recruiting_Interview_Flow_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Interview_Flow_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?:InputMaybe<Recruiting_Interview_Flow_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Interview_Flow_Set_Input>;
  where:Recruiting_Interview_Flow_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Interview_Flow_Var_Pop_Fields = {
  __typename?:'recruiting_interview_flow_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Recruiting_Interview_Flow_Var_Samp_Fields = {
  __typename?:'recruiting_interview_flow_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Recruiting_Interview_Flow_Variance_Fields = {
  __typename?:'recruiting_interview_flow_variance_fields';
  id?:Maybe<Scalars['Float']>;
};

/** columns and relationships of "recruiting.offer" */
export type Recruiting_Offer = {
  __typename?:'recruiting_offer';
  candidate_id:Scalars['Int'];
  created_at:Scalars['timestamptz'];
  id:Scalars['Int'];
  offer_template_id:Scalars['Int'];
  offer_url:Scalars['String'];
  start_date:Scalars['timestamptz'];
  start_date_full_time:Scalars['timestamptz'];
  status:Scalars['String'];
  updated_at:Scalars['timestamptz'];
};

/** aggregated selection of "recruiting.offer" */
export type Recruiting_Offer_Aggregate = {
  __typename?:'recruiting_offer_aggregate';
  aggregate?:Maybe<Recruiting_Offer_Aggregate_Fields>;
  nodes:Array<Recruiting_Offer>;
};

export type Recruiting_Offer_Aggregate_Bool_Exp = {
  count?:InputMaybe<Recruiting_Offer_Aggregate_Bool_Exp_Count>;
};

export type Recruiting_Offer_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Recruiting_Offer_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Offer_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "recruiting.offer" */
export type Recruiting_Offer_Aggregate_Fields = {
  __typename?:'recruiting_offer_aggregate_fields';
  avg?:Maybe<Recruiting_Offer_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Offer_Max_Fields>;
  min?:Maybe<Recruiting_Offer_Min_Fields>;
  stddev?:Maybe<Recruiting_Offer_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Offer_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Offer_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Offer_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Offer_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Offer_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Offer_Variance_Fields>;
};


/** aggregate fields of "recruiting.offer" */
export type Recruiting_Offer_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Offer_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recruiting.offer" */
export type Recruiting_Offer_Aggregate_Order_By = {
  avg?:InputMaybe<Recruiting_Offer_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Recruiting_Offer_Max_Order_By>;
  min?:InputMaybe<Recruiting_Offer_Min_Order_By>;
  stddev?:InputMaybe<Recruiting_Offer_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Recruiting_Offer_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Recruiting_Offer_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Recruiting_Offer_Sum_Order_By>;
  var_pop?:InputMaybe<Recruiting_Offer_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Recruiting_Offer_Var_Samp_Order_By>;
  variance?:InputMaybe<Recruiting_Offer_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recruiting.offer" */
export type Recruiting_Offer_Arr_Rel_Insert_Input = {
  data:Array<Recruiting_Offer_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Offer_On_Conflict>;
};

/** aggregate avg on columns */
export type Recruiting_Offer_Avg_Fields = {
  __typename?:'recruiting_offer_avg_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  offer_template_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recruiting.offer" */
export type Recruiting_Offer_Avg_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  offer_template_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recruiting.offer". All fields are combined with a logical 'AND'. */
export type Recruiting_Offer_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Offer_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Offer_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Offer_Bool_Exp>>;
  candidate_id?:InputMaybe<Int_Comparison_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  offer_template_id?:InputMaybe<Int_Comparison_Exp>;
  offer_url?:InputMaybe<String_Comparison_Exp>;
  start_date?:InputMaybe<Timestamptz_Comparison_Exp>;
  start_date_full_time?:InputMaybe<Timestamptz_Comparison_Exp>;
  status?:InputMaybe<String_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.offer" */
export enum Recruiting_Offer_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  OfferPkey = 'offer_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.offer" */
export type Recruiting_Offer_Inc_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  id?:InputMaybe<Scalars['Int']>;
  offer_template_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.offer" */
export type Recruiting_Offer_Insert_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  offer_template_id?:InputMaybe<Scalars['Int']>;
  offer_url?:InputMaybe<Scalars['String']>;
  start_date?:InputMaybe<Scalars['timestamptz']>;
  start_date_full_time?:InputMaybe<Scalars['timestamptz']>;
  status?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Recruiting_Offer_Max_Fields = {
  __typename?:'recruiting_offer_max_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  offer_template_id?:Maybe<Scalars['Int']>;
  offer_url?:Maybe<Scalars['String']>;
  start_date?:Maybe<Scalars['timestamptz']>;
  start_date_full_time?:Maybe<Scalars['timestamptz']>;
  status?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "recruiting.offer" */
export type Recruiting_Offer_Max_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  offer_template_id?:InputMaybe<Order_By>;
  offer_url?:InputMaybe<Order_By>;
  start_date?:InputMaybe<Order_By>;
  start_date_full_time?:InputMaybe<Order_By>;
  status?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recruiting_Offer_Min_Fields = {
  __typename?:'recruiting_offer_min_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  offer_template_id?:Maybe<Scalars['Int']>;
  offer_url?:Maybe<Scalars['String']>;
  start_date?:Maybe<Scalars['timestamptz']>;
  start_date_full_time?:Maybe<Scalars['timestamptz']>;
  status?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "recruiting.offer" */
export type Recruiting_Offer_Min_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  offer_template_id?:InputMaybe<Order_By>;
  offer_url?:InputMaybe<Order_By>;
  start_date?:InputMaybe<Order_By>;
  start_date_full_time?:InputMaybe<Order_By>;
  status?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "recruiting.offer" */
export type Recruiting_Offer_Mutation_Response = {
  __typename?:'recruiting_offer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Offer>;
};

/** on_conflict condition type for table "recruiting.offer" */
export type Recruiting_Offer_On_Conflict = {
  constraint:Recruiting_Offer_Constraint;
  update_columns?:Array<Recruiting_Offer_Update_Column>;
  where?:InputMaybe<Recruiting_Offer_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.offer". */
export type Recruiting_Offer_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  offer_template_id?:InputMaybe<Order_By>;
  offer_url?:InputMaybe<Order_By>;
  start_date?:InputMaybe<Order_By>;
  start_date_full_time?:InputMaybe<Order_By>;
  status?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.offer */
export type Recruiting_Offer_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "recruiting.offer" */
export enum Recruiting_Offer_Select_Column 
{
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OfferTemplateId = 'offer_template_id',
  /** column name */
  OfferUrl = 'offer_url',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StartDateFullTime = 'start_date_full_time',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "recruiting.offer" */
export type Recruiting_Offer_Set_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  offer_template_id?:InputMaybe<Scalars['Int']>;
  offer_url?:InputMaybe<Scalars['String']>;
  start_date?:InputMaybe<Scalars['timestamptz']>;
  start_date_full_time?:InputMaybe<Scalars['timestamptz']>;
  status?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Recruiting_Offer_Stddev_Fields = {
  __typename?:'recruiting_offer_stddev_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  offer_template_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recruiting.offer" */
export type Recruiting_Offer_Stddev_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  offer_template_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Offer_Stddev_Pop_Fields = {
  __typename?:'recruiting_offer_stddev_pop_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  offer_template_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recruiting.offer" */
export type Recruiting_Offer_Stddev_Pop_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  offer_template_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Offer_Stddev_Samp_Fields = {
  __typename?:'recruiting_offer_stddev_samp_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  offer_template_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recruiting.offer" */
export type Recruiting_Offer_Stddev_Samp_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  offer_template_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recruiting_offer" */
export type Recruiting_Offer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Offer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Offer_Stream_Cursor_Value_Input = {
  candidate_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  offer_template_id?:InputMaybe<Scalars['Int']>;
  offer_url?:InputMaybe<Scalars['String']>;
  start_date?:InputMaybe<Scalars['timestamptz']>;
  start_date_full_time?:InputMaybe<Scalars['timestamptz']>;
  status?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Recruiting_Offer_Sum_Fields = {
  __typename?:'recruiting_offer_sum_fields';
  candidate_id?:Maybe<Scalars['Int']>;
  id?:Maybe<Scalars['Int']>;
  offer_template_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recruiting.offer" */
export type Recruiting_Offer_Sum_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  offer_template_id?:InputMaybe<Order_By>;
};

/** columns and relationships of "recruiting.offer_template" */
export type Recruiting_Offer_Template = {
  __typename?:'recruiting_offer_template';
  created_at:Scalars['timestamptz'];
  id:Scalars['Int'];
  /** An array relationship */
  offers:Array<Recruiting_Offer>;
  /** An aggregate relationship */
  offers_aggregate:Recruiting_Offer_Aggregate;
  /** An object relationship */
  staff_role:Staffing_Staff_Role;
  staff_role_id:Scalars['Int'];
  template_url:Scalars['String'];
  updated_at:Scalars['timestamptz'];
};


/** columns and relationships of "recruiting.offer_template" */
export type Recruiting_Offer_TemplateOffersArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Offer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Offer_Order_By>>;
  where?:InputMaybe<Recruiting_Offer_Bool_Exp>;
};


/** columns and relationships of "recruiting.offer_template" */
export type Recruiting_Offer_TemplateOffers_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Offer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Offer_Order_By>>;
  where?:InputMaybe<Recruiting_Offer_Bool_Exp>;
};

/** aggregated selection of "recruiting.offer_template" */
export type Recruiting_Offer_Template_Aggregate = {
  __typename?:'recruiting_offer_template_aggregate';
  aggregate?:Maybe<Recruiting_Offer_Template_Aggregate_Fields>;
  nodes:Array<Recruiting_Offer_Template>;
};

/** aggregate fields of "recruiting.offer_template" */
export type Recruiting_Offer_Template_Aggregate_Fields = {
  __typename?:'recruiting_offer_template_aggregate_fields';
  avg?:Maybe<Recruiting_Offer_Template_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Offer_Template_Max_Fields>;
  min?:Maybe<Recruiting_Offer_Template_Min_Fields>;
  stddev?:Maybe<Recruiting_Offer_Template_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Offer_Template_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Offer_Template_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Offer_Template_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Offer_Template_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Offer_Template_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Offer_Template_Variance_Fields>;
};


/** aggregate fields of "recruiting.offer_template" */
export type Recruiting_Offer_Template_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Offer_Template_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Recruiting_Offer_Template_Avg_Fields = {
  __typename?:'recruiting_offer_template_avg_fields';
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "recruiting.offer_template". All fields are combined with a logical 'AND'. */
export type Recruiting_Offer_Template_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Offer_Template_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Offer_Template_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Offer_Template_Bool_Exp>>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  offers?:InputMaybe<Recruiting_Offer_Bool_Exp>;
  offers_aggregate?:InputMaybe<Recruiting_Offer_Aggregate_Bool_Exp>;
  staff_role?:InputMaybe<Staffing_Staff_Role_Bool_Exp>;
  staff_role_id?:InputMaybe<Int_Comparison_Exp>;
  template_url?:InputMaybe<String_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.offer_template" */
export enum Recruiting_Offer_Template_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  OfferTemplatePkey = 'offer_template_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.offer_template" */
export type Recruiting_Offer_Template_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
  staff_role_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.offer_template" */
export type Recruiting_Offer_Template_Insert_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  offers?:InputMaybe<Recruiting_Offer_Arr_Rel_Insert_Input>;
  staff_role?:InputMaybe<Staffing_Staff_Role_Obj_Rel_Insert_Input>;
  staff_role_id?:InputMaybe<Scalars['Int']>;
  template_url?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Recruiting_Offer_Template_Max_Fields = {
  __typename?:'recruiting_offer_template_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  staff_role_id?:Maybe<Scalars['Int']>;
  template_url?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Recruiting_Offer_Template_Min_Fields = {
  __typename?:'recruiting_offer_template_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  staff_role_id?:Maybe<Scalars['Int']>;
  template_url?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "recruiting.offer_template" */
export type Recruiting_Offer_Template_Mutation_Response = {
  __typename?:'recruiting_offer_template_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Offer_Template>;
};

/** on_conflict condition type for table "recruiting.offer_template" */
export type Recruiting_Offer_Template_On_Conflict = {
  constraint:Recruiting_Offer_Template_Constraint;
  update_columns?:Array<Recruiting_Offer_Template_Update_Column>;
  where?:InputMaybe<Recruiting_Offer_Template_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.offer_template". */
export type Recruiting_Offer_Template_Order_By = {
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  offers_aggregate?:InputMaybe<Recruiting_Offer_Aggregate_Order_By>;
  staff_role?:InputMaybe<Staffing_Staff_Role_Order_By>;
  staff_role_id?:InputMaybe<Order_By>;
  template_url?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.offer_template */
export type Recruiting_Offer_Template_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "recruiting.offer_template" */
export enum Recruiting_Offer_Template_Select_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StaffRoleId = 'staff_role_id',
  /** column name */
  TemplateUrl = 'template_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "recruiting.offer_template" */
export type Recruiting_Offer_Template_Set_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  staff_role_id?:InputMaybe<Scalars['Int']>;
  template_url?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Recruiting_Offer_Template_Stddev_Fields = {
  __typename?:'recruiting_offer_template_stddev_fields';
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Offer_Template_Stddev_Pop_Fields = {
  __typename?:'recruiting_offer_template_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Offer_Template_Stddev_Samp_Fields = {
  __typename?:'recruiting_offer_template_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "recruiting_offer_template" */
export type Recruiting_Offer_Template_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Offer_Template_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Offer_Template_Stream_Cursor_Value_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  staff_role_id?:InputMaybe<Scalars['Int']>;
  template_url?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Recruiting_Offer_Template_Sum_Fields = {
  __typename?:'recruiting_offer_template_sum_fields';
  id?:Maybe<Scalars['Int']>;
  staff_role_id?:Maybe<Scalars['Int']>;
};

/** update columns of table "recruiting.offer_template" */
export enum Recruiting_Offer_Template_Update_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StaffRoleId = 'staff_role_id',
  /** column name */
  TemplateUrl = 'template_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Recruiting_Offer_Template_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Offer_Template_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Offer_Template_Set_Input>;
  where:Recruiting_Offer_Template_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Offer_Template_Var_Pop_Fields = {
  __typename?:'recruiting_offer_template_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Recruiting_Offer_Template_Var_Samp_Fields = {
  __typename?:'recruiting_offer_template_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Recruiting_Offer_Template_Variance_Fields = {
  __typename?:'recruiting_offer_template_variance_fields';
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
};

/** update columns of table "recruiting.offer" */
export enum Recruiting_Offer_Update_Column 
{
  /** column name */
  CandidateId = 'candidate_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OfferTemplateId = 'offer_template_id',
  /** column name */
  OfferUrl = 'offer_url',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  StartDateFullTime = 'start_date_full_time',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Recruiting_Offer_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Offer_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Offer_Set_Input>;
  where:Recruiting_Offer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Offer_Var_Pop_Fields = {
  __typename?:'recruiting_offer_var_pop_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  offer_template_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recruiting.offer" */
export type Recruiting_Offer_Var_Pop_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  offer_template_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recruiting_Offer_Var_Samp_Fields = {
  __typename?:'recruiting_offer_var_samp_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  offer_template_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recruiting.offer" */
export type Recruiting_Offer_Var_Samp_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  offer_template_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recruiting_Offer_Variance_Fields = {
  __typename?:'recruiting_offer_variance_fields';
  candidate_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  offer_template_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recruiting.offer" */
export type Recruiting_Offer_Variance_Order_By = {
  candidate_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  offer_template_id?:InputMaybe<Order_By>;
};

/** columns and relationships of "recruiting.position" */
export type Recruiting_Position = {
  __typename?:'recruiting_position';
  alias?:Maybe<Scalars['String']>;
  /** An array relationship */
  allocations:Array<Recruiting_Allocation_Position>;
  /** An aggregate relationship */
  allocations_aggregate:Recruiting_Allocation_Position_Aggregate;
  content_url?:Maybe<Scalars['String']>;
  created_at:Scalars['timestamptz'];
  /** An object relationship */
  customer_team:Staffing_Customer_Team;
  customer_team_id:Scalars['Int'];
  /** An array relationship */
  customer_team_slots:Array<Staffing_Customer_Team_Slot>;
  /** An aggregate relationship */
  customer_team_slots_aggregate:Staffing_Customer_Team_Slot_Aggregate;
  deleted_at?:Maybe<Scalars['timestamptz']>;
  google_doc_ref?:Maybe<Scalars['String']>;
  /** A computed field, executes function "recruiting.get_position_google_doc_url" */
  google_doc_url?:Maybe<Scalars['String']>;
  id:Scalars['Int'];
  is_ready:Scalars['Boolean'];
  notes?:Maybe<Scalars['String']>;
  /** An object relationship */
  position_group?:Maybe<Recruiting_Position_Group>;
  position_group_id?:Maybe<Scalars['Int']>;
  /** An object relationship */
  position_template?:Maybe<Recruiting_Position_Template>;
  position_template_id?:Maybe<Scalars['Int']>;
  quantity?:Maybe<Scalars['Int']>;
  ui_customer_team_order:Scalars['Int'];
  ui_position_groups_order:Scalars['Int'];
  updated_at:Scalars['timestamptz'];
};


/** columns and relationships of "recruiting.position" */
export type Recruiting_PositionAllocationsArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Allocation_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Allocation_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Allocation_Position_Bool_Exp>;
};


/** columns and relationships of "recruiting.position" */
export type Recruiting_PositionAllocations_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Allocation_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Allocation_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Allocation_Position_Bool_Exp>;
};


/** columns and relationships of "recruiting.position" */
export type Recruiting_PositionCustomer_Team_SlotsArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
};


/** columns and relationships of "recruiting.position" */
export type Recruiting_PositionCustomer_Team_Slots_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
};

/** aggregated selection of "recruiting.position" */
export type Recruiting_Position_Aggregate = {
  __typename?:'recruiting_position_aggregate';
  aggregate?:Maybe<Recruiting_Position_Aggregate_Fields>;
  nodes:Array<Recruiting_Position>;
};

export type Recruiting_Position_Aggregate_Bool_Exp = {
  bool_and?:InputMaybe<Recruiting_Position_Aggregate_Bool_Exp_Bool_And>;
  bool_or?:InputMaybe<Recruiting_Position_Aggregate_Bool_Exp_Bool_Or>;
  count?:InputMaybe<Recruiting_Position_Aggregate_Bool_Exp_Count>;
};

export type Recruiting_Position_Aggregate_Bool_Exp_Bool_And = {
  arguments:Recruiting_Position_Select_Column_Recruiting_Position_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Position_Bool_Exp>;
  predicate:Boolean_Comparison_Exp;
};

export type Recruiting_Position_Aggregate_Bool_Exp_Bool_Or = {
  arguments:Recruiting_Position_Select_Column_Recruiting_Position_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Position_Bool_Exp>;
  predicate:Boolean_Comparison_Exp;
};

export type Recruiting_Position_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Recruiting_Position_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Position_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "recruiting.position" */
export type Recruiting_Position_Aggregate_Fields = {
  __typename?:'recruiting_position_aggregate_fields';
  avg?:Maybe<Recruiting_Position_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Position_Max_Fields>;
  min?:Maybe<Recruiting_Position_Min_Fields>;
  stddev?:Maybe<Recruiting_Position_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Position_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Position_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Position_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Position_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Position_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Position_Variance_Fields>;
};


/** aggregate fields of "recruiting.position" */
export type Recruiting_Position_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Position_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recruiting.position" */
export type Recruiting_Position_Aggregate_Order_By = {
  avg?:InputMaybe<Recruiting_Position_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Recruiting_Position_Max_Order_By>;
  min?:InputMaybe<Recruiting_Position_Min_Order_By>;
  stddev?:InputMaybe<Recruiting_Position_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Recruiting_Position_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Recruiting_Position_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Recruiting_Position_Sum_Order_By>;
  var_pop?:InputMaybe<Recruiting_Position_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Recruiting_Position_Var_Samp_Order_By>;
  variance?:InputMaybe<Recruiting_Position_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recruiting.position" */
export type Recruiting_Position_Arr_Rel_Insert_Input = {
  data:Array<Recruiting_Position_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Position_On_Conflict>;
};

/** aggregate avg on columns */
export type Recruiting_Position_Avg_Fields = {
  __typename?:'recruiting_position_avg_fields';
  customer_team_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  position_group_id?:Maybe<Scalars['Float']>;
  position_template_id?:Maybe<Scalars['Float']>;
  quantity?:Maybe<Scalars['Float']>;
  ui_customer_team_order?:Maybe<Scalars['Float']>;
  ui_position_groups_order?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recruiting.position" */
export type Recruiting_Position_Avg_Order_By = {
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  position_template_id?:InputMaybe<Order_By>;
  quantity?:InputMaybe<Order_By>;
  ui_customer_team_order?:InputMaybe<Order_By>;
  ui_position_groups_order?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recruiting.position". All fields are combined with a logical 'AND'. */
export type Recruiting_Position_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Position_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Position_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Position_Bool_Exp>>;
  alias?:InputMaybe<String_Comparison_Exp>;
  allocations?:InputMaybe<Recruiting_Allocation_Position_Bool_Exp>;
  allocations_aggregate?:InputMaybe<Recruiting_Allocation_Position_Aggregate_Bool_Exp>;
  content_url?:InputMaybe<String_Comparison_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  customer_team?:InputMaybe<Staffing_Customer_Team_Bool_Exp>;
  customer_team_id?:InputMaybe<Int_Comparison_Exp>;
  customer_team_slots?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
  customer_team_slots_aggregate?:InputMaybe<Staffing_Customer_Team_Slot_Aggregate_Bool_Exp>;
  deleted_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  google_doc_ref?:InputMaybe<String_Comparison_Exp>;
  google_doc_url?:InputMaybe<String_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  is_ready?:InputMaybe<Boolean_Comparison_Exp>;
  notes?:InputMaybe<String_Comparison_Exp>;
  position_group?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
  position_group_id?:InputMaybe<Int_Comparison_Exp>;
  position_template?:InputMaybe<Recruiting_Position_Template_Bool_Exp>;
  position_template_id?:InputMaybe<Int_Comparison_Exp>;
  quantity?:InputMaybe<Int_Comparison_Exp>;
  ui_customer_team_order?:InputMaybe<Int_Comparison_Exp>;
  ui_position_groups_order?:InputMaybe<Int_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.position" */
export enum Recruiting_Position_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  PositionPkey = 'position_pkey'
}

/** columns and relationships of "recruiting.position_group" */
export type Recruiting_Position_Group = {
  __typename?:'recruiting_position_group';
  /** An array relationship */
  candidate_position_groups:Array<Recruiting_Candidate_Position_Group>;
  /** An aggregate relationship */
  candidate_position_groups_aggregate:Recruiting_Candidate_Position_Group_Aggregate;
  created_at:Scalars['timestamptz'];
  deleted_at?:Maybe<Scalars['timestamptz']>;
  id:Scalars['Int'];
  /** An object relationship */
  interview_flow?:Maybe<Recruiting_Interview_Flow>;
  interview_flow_id?:Maybe<Scalars['Int']>;
  is_active:Scalars['Boolean'];
  name:Scalars['String'];
  /** An array relationship */
  position_group_priorities:Array<Recruiting_Position_Group_Priority>;
  /** An aggregate relationship */
  position_group_priorities_aggregate:Recruiting_Position_Group_Priority_Aggregate;
  /** An array relationship */
  positions:Array<Recruiting_Position>;
  /** An aggregate relationship */
  positions_aggregate:Recruiting_Position_Aggregate;
  ui_order:Scalars['Int'];
  updated_at:Scalars['timestamptz'];
};


/** columns and relationships of "recruiting.position_group" */
export type Recruiting_Position_GroupCandidate_Position_GroupsArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Position_Group_Bool_Exp>;
};


/** columns and relationships of "recruiting.position_group" */
export type Recruiting_Position_GroupCandidate_Position_Groups_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Position_Group_Bool_Exp>;
};


/** columns and relationships of "recruiting.position_group" */
export type Recruiting_Position_GroupPosition_Group_PrioritiesArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Group_Priority_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Group_Priority_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Group_Priority_Bool_Exp>;
};


/** columns and relationships of "recruiting.position_group" */
export type Recruiting_Position_GroupPosition_Group_Priorities_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Group_Priority_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Group_Priority_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Group_Priority_Bool_Exp>;
};


/** columns and relationships of "recruiting.position_group" */
export type Recruiting_Position_GroupPositionsArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Bool_Exp>;
};


/** columns and relationships of "recruiting.position_group" */
export type Recruiting_Position_GroupPositions_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Bool_Exp>;
};

/** aggregated selection of "recruiting.position_group" */
export type Recruiting_Position_Group_Aggregate = {
  __typename?:'recruiting_position_group_aggregate';
  aggregate?:Maybe<Recruiting_Position_Group_Aggregate_Fields>;
  nodes:Array<Recruiting_Position_Group>;
};

export type Recruiting_Position_Group_Aggregate_Bool_Exp = {
  bool_and?:InputMaybe<Recruiting_Position_Group_Aggregate_Bool_Exp_Bool_And>;
  bool_or?:InputMaybe<Recruiting_Position_Group_Aggregate_Bool_Exp_Bool_Or>;
  count?:InputMaybe<Recruiting_Position_Group_Aggregate_Bool_Exp_Count>;
};

export type Recruiting_Position_Group_Aggregate_Bool_Exp_Bool_And = {
  arguments:Recruiting_Position_Group_Select_Column_Recruiting_Position_Group_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
  predicate:Boolean_Comparison_Exp;
};

export type Recruiting_Position_Group_Aggregate_Bool_Exp_Bool_Or = {
  arguments:Recruiting_Position_Group_Select_Column_Recruiting_Position_Group_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
  predicate:Boolean_Comparison_Exp;
};

export type Recruiting_Position_Group_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Recruiting_Position_Group_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "recruiting.position_group" */
export type Recruiting_Position_Group_Aggregate_Fields = {
  __typename?:'recruiting_position_group_aggregate_fields';
  avg?:Maybe<Recruiting_Position_Group_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Position_Group_Max_Fields>;
  min?:Maybe<Recruiting_Position_Group_Min_Fields>;
  stddev?:Maybe<Recruiting_Position_Group_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Position_Group_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Position_Group_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Position_Group_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Position_Group_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Position_Group_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Position_Group_Variance_Fields>;
};


/** aggregate fields of "recruiting.position_group" */
export type Recruiting_Position_Group_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Position_Group_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recruiting.position_group" */
export type Recruiting_Position_Group_Aggregate_Order_By = {
  avg?:InputMaybe<Recruiting_Position_Group_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Recruiting_Position_Group_Max_Order_By>;
  min?:InputMaybe<Recruiting_Position_Group_Min_Order_By>;
  stddev?:InputMaybe<Recruiting_Position_Group_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Recruiting_Position_Group_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Recruiting_Position_Group_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Recruiting_Position_Group_Sum_Order_By>;
  var_pop?:InputMaybe<Recruiting_Position_Group_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Recruiting_Position_Group_Var_Samp_Order_By>;
  variance?:InputMaybe<Recruiting_Position_Group_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recruiting.position_group" */
export type Recruiting_Position_Group_Arr_Rel_Insert_Input = {
  data:Array<Recruiting_Position_Group_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Position_Group_On_Conflict>;
};

/** aggregate avg on columns */
export type Recruiting_Position_Group_Avg_Fields = {
  __typename?:'recruiting_position_group_avg_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recruiting.position_group" */
export type Recruiting_Position_Group_Avg_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recruiting.position_group". All fields are combined with a logical 'AND'. */
export type Recruiting_Position_Group_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Position_Group_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Position_Group_Bool_Exp>>;
  candidate_position_groups?:InputMaybe<Recruiting_Candidate_Position_Group_Bool_Exp>;
  candidate_position_groups_aggregate?:InputMaybe<Recruiting_Candidate_Position_Group_Aggregate_Bool_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  interview_flow?:InputMaybe<Recruiting_Interview_Flow_Bool_Exp>;
  interview_flow_id?:InputMaybe<Int_Comparison_Exp>;
  is_active?:InputMaybe<Boolean_Comparison_Exp>;
  name?:InputMaybe<String_Comparison_Exp>;
  position_group_priorities?:InputMaybe<Recruiting_Position_Group_Priority_Bool_Exp>;
  position_group_priorities_aggregate?:InputMaybe<Recruiting_Position_Group_Priority_Aggregate_Bool_Exp>;
  positions?:InputMaybe<Recruiting_Position_Bool_Exp>;
  positions_aggregate?:InputMaybe<Recruiting_Position_Aggregate_Bool_Exp>;
  ui_order?:InputMaybe<Int_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.position_group" */
export enum Recruiting_Position_Group_Constraint 
{
  /** unique or primary key constraint on columns "name" */
  PositionGroupNameKey = 'position_group_name_key',
  /** unique or primary key constraint on columns "id" */
  PositionGroupPkey = 'position_group_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.position_group" */
export type Recruiting_Position_Group_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
  interview_flow_id?:InputMaybe<Scalars['Int']>;
  ui_order?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.position_group" */
export type Recruiting_Position_Group_Insert_Input = {
  candidate_position_groups?:InputMaybe<Recruiting_Candidate_Position_Group_Arr_Rel_Insert_Input>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  interview_flow?:InputMaybe<Recruiting_Interview_Flow_Obj_Rel_Insert_Input>;
  interview_flow_id?:InputMaybe<Scalars['Int']>;
  is_active?:InputMaybe<Scalars['Boolean']>;
  name?:InputMaybe<Scalars['String']>;
  position_group_priorities?:InputMaybe<Recruiting_Position_Group_Priority_Arr_Rel_Insert_Input>;
  positions?:InputMaybe<Recruiting_Position_Arr_Rel_Insert_Input>;
  ui_order?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Recruiting_Position_Group_Max_Fields = {
  __typename?:'recruiting_position_group_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  deleted_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  interview_flow_id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  ui_order?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "recruiting.position_group" */
export type Recruiting_Position_Group_Max_Order_By = {
  created_at?:InputMaybe<Order_By>;
  deleted_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recruiting_Position_Group_Min_Fields = {
  __typename?:'recruiting_position_group_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  deleted_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  interview_flow_id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  ui_order?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "recruiting.position_group" */
export type Recruiting_Position_Group_Min_Order_By = {
  created_at?:InputMaybe<Order_By>;
  deleted_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "recruiting.position_group" */
export type Recruiting_Position_Group_Mutation_Response = {
  __typename?:'recruiting_position_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Position_Group>;
};

/** input type for inserting object relation for remote table "recruiting.position_group" */
export type Recruiting_Position_Group_Obj_Rel_Insert_Input = {
  data:Recruiting_Position_Group_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Position_Group_On_Conflict>;
};

/** on_conflict condition type for table "recruiting.position_group" */
export type Recruiting_Position_Group_On_Conflict = {
  constraint:Recruiting_Position_Group_Constraint;
  update_columns?:Array<Recruiting_Position_Group_Update_Column>;
  where?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.position_group". */
export type Recruiting_Position_Group_Order_By = {
  candidate_position_groups_aggregate?:InputMaybe<Recruiting_Candidate_Position_Group_Aggregate_Order_By>;
  created_at?:InputMaybe<Order_By>;
  deleted_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  interview_flow?:InputMaybe<Recruiting_Interview_Flow_Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
  is_active?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  position_group_priorities_aggregate?:InputMaybe<Recruiting_Position_Group_Priority_Aggregate_Order_By>;
  positions_aggregate?:InputMaybe<Recruiting_Position_Aggregate_Order_By>;
  ui_order?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.position_group */
export type Recruiting_Position_Group_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** columns and relationships of "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority = {
  __typename?:'recruiting_position_group_priority';
  created_at:Scalars['timestamptz'];
  /** An object relationship */
  position_group:Recruiting_Position_Group;
  position_group_id:Scalars['Int'];
  priority:Scalars['Int'];
  /** An object relationship */
  secondary_position_group?:Maybe<Recruiting_Position_Group>;
  secondary_position_group_id?:Maybe<Scalars['Int']>;
  updated_at:Scalars['timestamptz'];
  /** An object relationship */
  user:Common_User;
  user_id:Scalars['Int'];
};

/** aggregated selection of "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Aggregate = {
  __typename?:'recruiting_position_group_priority_aggregate';
  aggregate?:Maybe<Recruiting_Position_Group_Priority_Aggregate_Fields>;
  nodes:Array<Recruiting_Position_Group_Priority>;
};

export type Recruiting_Position_Group_Priority_Aggregate_Bool_Exp = {
  count?:InputMaybe<Recruiting_Position_Group_Priority_Aggregate_Bool_Exp_Count>;
};

export type Recruiting_Position_Group_Priority_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Recruiting_Position_Group_Priority_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Recruiting_Position_Group_Priority_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Aggregate_Fields = {
  __typename?:'recruiting_position_group_priority_aggregate_fields';
  avg?:Maybe<Recruiting_Position_Group_Priority_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Position_Group_Priority_Max_Fields>;
  min?:Maybe<Recruiting_Position_Group_Priority_Min_Fields>;
  stddev?:Maybe<Recruiting_Position_Group_Priority_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Position_Group_Priority_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Position_Group_Priority_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Position_Group_Priority_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Position_Group_Priority_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Position_Group_Priority_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Position_Group_Priority_Variance_Fields>;
};


/** aggregate fields of "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Position_Group_Priority_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Aggregate_Order_By = {
  avg?:InputMaybe<Recruiting_Position_Group_Priority_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Recruiting_Position_Group_Priority_Max_Order_By>;
  min?:InputMaybe<Recruiting_Position_Group_Priority_Min_Order_By>;
  stddev?:InputMaybe<Recruiting_Position_Group_Priority_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Recruiting_Position_Group_Priority_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Recruiting_Position_Group_Priority_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Recruiting_Position_Group_Priority_Sum_Order_By>;
  var_pop?:InputMaybe<Recruiting_Position_Group_Priority_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Recruiting_Position_Group_Priority_Var_Samp_Order_By>;
  variance?:InputMaybe<Recruiting_Position_Group_Priority_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Arr_Rel_Insert_Input = {
  data:Array<Recruiting_Position_Group_Priority_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Position_Group_Priority_On_Conflict>;
};

/** aggregate avg on columns */
export type Recruiting_Position_Group_Priority_Avg_Fields = {
  __typename?:'recruiting_position_group_priority_avg_fields';
  position_group_id?:Maybe<Scalars['Float']>;
  priority?:Maybe<Scalars['Float']>;
  secondary_position_group_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Avg_Order_By = {
  position_group_id?:InputMaybe<Order_By>;
  priority?:InputMaybe<Order_By>;
  secondary_position_group_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recruiting.position_group_priority". All fields are combined with a logical 'AND'. */
export type Recruiting_Position_Group_Priority_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Position_Group_Priority_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Position_Group_Priority_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Position_Group_Priority_Bool_Exp>>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  position_group?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
  position_group_id?:InputMaybe<Int_Comparison_Exp>;
  priority?:InputMaybe<Int_Comparison_Exp>;
  secondary_position_group?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
  secondary_position_group_id?:InputMaybe<Int_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  user?:InputMaybe<Common_User_Bool_Exp>;
  user_id?:InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.position_group_priority" */
export enum Recruiting_Position_Group_Priority_Constraint 
{
  /** unique or primary key constraint on columns "user_id", "position_group_id" */
  PositionGroupPriorityPkey = 'position_group_priority_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Inc_Input = {
  position_group_id?:InputMaybe<Scalars['Int']>;
  priority?:InputMaybe<Scalars['Int']>;
  secondary_position_group_id?:InputMaybe<Scalars['Int']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Insert_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  position_group?:InputMaybe<Recruiting_Position_Group_Obj_Rel_Insert_Input>;
  position_group_id?:InputMaybe<Scalars['Int']>;
  priority?:InputMaybe<Scalars['Int']>;
  secondary_position_group?:InputMaybe<Recruiting_Position_Group_Obj_Rel_Insert_Input>;
  secondary_position_group_id?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user?:InputMaybe<Common_User_Obj_Rel_Insert_Input>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Recruiting_Position_Group_Priority_Max_Fields = {
  __typename?:'recruiting_position_group_priority_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  position_group_id?:Maybe<Scalars['Int']>;
  priority?:Maybe<Scalars['Int']>;
  secondary_position_group_id?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Max_Order_By = {
  created_at?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  priority?:InputMaybe<Order_By>;
  secondary_position_group_id?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recruiting_Position_Group_Priority_Min_Fields = {
  __typename?:'recruiting_position_group_priority_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  position_group_id?:Maybe<Scalars['Int']>;
  priority?:Maybe<Scalars['Int']>;
  secondary_position_group_id?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Min_Order_By = {
  created_at?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  priority?:InputMaybe<Order_By>;
  secondary_position_group_id?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Mutation_Response = {
  __typename?:'recruiting_position_group_priority_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Position_Group_Priority>;
};

/** on_conflict condition type for table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_On_Conflict = {
  constraint:Recruiting_Position_Group_Priority_Constraint;
  update_columns?:Array<Recruiting_Position_Group_Priority_Update_Column>;
  where?:InputMaybe<Recruiting_Position_Group_Priority_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.position_group_priority". */
export type Recruiting_Position_Group_Priority_Order_By = {
  created_at?:InputMaybe<Order_By>;
  position_group?:InputMaybe<Recruiting_Position_Group_Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  priority?:InputMaybe<Order_By>;
  secondary_position_group?:InputMaybe<Recruiting_Position_Group_Order_By>;
  secondary_position_group_id?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user?:InputMaybe<Common_User_Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.position_group_priority */
export type Recruiting_Position_Group_Priority_Pk_Columns_Input = {
  position_group_id:Scalars['Int'];
  user_id:Scalars['Int'];
};

/** select columns of table "recruiting.position_group_priority" */
export enum Recruiting_Position_Group_Priority_Select_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PositionGroupId = 'position_group_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  SecondaryPositionGroupId = 'secondary_position_group_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Set_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  position_group_id?:InputMaybe<Scalars['Int']>;
  priority?:InputMaybe<Scalars['Int']>;
  secondary_position_group_id?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Recruiting_Position_Group_Priority_Stddev_Fields = {
  __typename?:'recruiting_position_group_priority_stddev_fields';
  position_group_id?:Maybe<Scalars['Float']>;
  priority?:Maybe<Scalars['Float']>;
  secondary_position_group_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Stddev_Order_By = {
  position_group_id?:InputMaybe<Order_By>;
  priority?:InputMaybe<Order_By>;
  secondary_position_group_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Position_Group_Priority_Stddev_Pop_Fields = {
  __typename?:'recruiting_position_group_priority_stddev_pop_fields';
  position_group_id?:Maybe<Scalars['Float']>;
  priority?:Maybe<Scalars['Float']>;
  secondary_position_group_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Stddev_Pop_Order_By = {
  position_group_id?:InputMaybe<Order_By>;
  priority?:InputMaybe<Order_By>;
  secondary_position_group_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Position_Group_Priority_Stddev_Samp_Fields = {
  __typename?:'recruiting_position_group_priority_stddev_samp_fields';
  position_group_id?:Maybe<Scalars['Float']>;
  priority?:Maybe<Scalars['Float']>;
  secondary_position_group_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Stddev_Samp_Order_By = {
  position_group_id?:InputMaybe<Order_By>;
  priority?:InputMaybe<Order_By>;
  secondary_position_group_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recruiting_position_group_priority" */
export type Recruiting_Position_Group_Priority_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Position_Group_Priority_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Position_Group_Priority_Stream_Cursor_Value_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  position_group_id?:InputMaybe<Scalars['Int']>;
  priority?:InputMaybe<Scalars['Int']>;
  secondary_position_group_id?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Recruiting_Position_Group_Priority_Sum_Fields = {
  __typename?:'recruiting_position_group_priority_sum_fields';
  position_group_id?:Maybe<Scalars['Int']>;
  priority?:Maybe<Scalars['Int']>;
  secondary_position_group_id?:Maybe<Scalars['Int']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Sum_Order_By = {
  position_group_id?:InputMaybe<Order_By>;
  priority?:InputMaybe<Order_By>;
  secondary_position_group_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** update columns of table "recruiting.position_group_priority" */
export enum Recruiting_Position_Group_Priority_Update_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PositionGroupId = 'position_group_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  SecondaryPositionGroupId = 'secondary_position_group_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Recruiting_Position_Group_Priority_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Position_Group_Priority_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Position_Group_Priority_Set_Input>;
  where:Recruiting_Position_Group_Priority_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Position_Group_Priority_Var_Pop_Fields = {
  __typename?:'recruiting_position_group_priority_var_pop_fields';
  position_group_id?:Maybe<Scalars['Float']>;
  priority?:Maybe<Scalars['Float']>;
  secondary_position_group_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Var_Pop_Order_By = {
  position_group_id?:InputMaybe<Order_By>;
  priority?:InputMaybe<Order_By>;
  secondary_position_group_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recruiting_Position_Group_Priority_Var_Samp_Fields = {
  __typename?:'recruiting_position_group_priority_var_samp_fields';
  position_group_id?:Maybe<Scalars['Float']>;
  priority?:Maybe<Scalars['Float']>;
  secondary_position_group_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Var_Samp_Order_By = {
  position_group_id?:InputMaybe<Order_By>;
  priority?:InputMaybe<Order_By>;
  secondary_position_group_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recruiting_Position_Group_Priority_Variance_Fields = {
  __typename?:'recruiting_position_group_priority_variance_fields';
  position_group_id?:Maybe<Scalars['Float']>;
  priority?:Maybe<Scalars['Float']>;
  secondary_position_group_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recruiting.position_group_priority" */
export type Recruiting_Position_Group_Priority_Variance_Order_By = {
  position_group_id?:InputMaybe<Order_By>;
  priority?:InputMaybe<Order_By>;
  secondary_position_group_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** select columns of table "recruiting.position_group" */
export enum Recruiting_Position_Group_Select_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewFlowId = 'interview_flow_id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name',
  /** column name */
  UiOrder = 'ui_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "recruiting_position_group_aggregate_bool_exp_bool_and_arguments_columns" columns of table "recruiting.position_group" */
export enum Recruiting_Position_Group_Select_Column_Recruiting_Position_Group_Aggregate_Bool_Exp_Bool_And_Arguments_Columns 
{
  /** column name */
  IsActive = 'is_active'
}

/** select "recruiting_position_group_aggregate_bool_exp_bool_or_arguments_columns" columns of table "recruiting.position_group" */
export enum Recruiting_Position_Group_Select_Column_Recruiting_Position_Group_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns 
{
  /** column name */
  IsActive = 'is_active'
}

/** input type for updating data in table "recruiting.position_group" */
export type Recruiting_Position_Group_Set_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  interview_flow_id?:InputMaybe<Scalars['Int']>;
  is_active?:InputMaybe<Scalars['Boolean']>;
  name?:InputMaybe<Scalars['String']>;
  ui_order?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Recruiting_Position_Group_Stddev_Fields = {
  __typename?:'recruiting_position_group_stddev_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recruiting.position_group" */
export type Recruiting_Position_Group_Stddev_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Position_Group_Stddev_Pop_Fields = {
  __typename?:'recruiting_position_group_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recruiting.position_group" */
export type Recruiting_Position_Group_Stddev_Pop_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Position_Group_Stddev_Samp_Fields = {
  __typename?:'recruiting_position_group_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recruiting.position_group" */
export type Recruiting_Position_Group_Stddev_Samp_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recruiting_position_group" */
export type Recruiting_Position_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Position_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Position_Group_Stream_Cursor_Value_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  interview_flow_id?:InputMaybe<Scalars['Int']>;
  is_active?:InputMaybe<Scalars['Boolean']>;
  name?:InputMaybe<Scalars['String']>;
  ui_order?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Recruiting_Position_Group_Sum_Fields = {
  __typename?:'recruiting_position_group_sum_fields';
  id?:Maybe<Scalars['Int']>;
  interview_flow_id?:Maybe<Scalars['Int']>;
  ui_order?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recruiting.position_group" */
export type Recruiting_Position_Group_Sum_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** update columns of table "recruiting.position_group" */
export enum Recruiting_Position_Group_Update_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterviewFlowId = 'interview_flow_id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name',
  /** column name */
  UiOrder = 'ui_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Recruiting_Position_Group_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Position_Group_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Position_Group_Set_Input>;
  where:Recruiting_Position_Group_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Position_Group_Var_Pop_Fields = {
  __typename?:'recruiting_position_group_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recruiting.position_group" */
export type Recruiting_Position_Group_Var_Pop_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recruiting_Position_Group_Var_Samp_Fields = {
  __typename?:'recruiting_position_group_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recruiting.position_group" */
export type Recruiting_Position_Group_Var_Samp_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recruiting_Position_Group_Variance_Fields = {
  __typename?:'recruiting_position_group_variance_fields';
  id?:Maybe<Scalars['Float']>;
  interview_flow_id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recruiting.position_group" */
export type Recruiting_Position_Group_Variance_Order_By = {
  id?:InputMaybe<Order_By>;
  interview_flow_id?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "recruiting.position" */
export type Recruiting_Position_Inc_Input = {
  customer_team_id?:InputMaybe<Scalars['Int']>;
  id?:InputMaybe<Scalars['Int']>;
  position_group_id?:InputMaybe<Scalars['Int']>;
  position_template_id?:InputMaybe<Scalars['Int']>;
  quantity?:InputMaybe<Scalars['Int']>;
  ui_customer_team_order?:InputMaybe<Scalars['Int']>;
  ui_position_groups_order?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.position" */
export type Recruiting_Position_Insert_Input = {
  alias?:InputMaybe<Scalars['String']>;
  allocations?:InputMaybe<Recruiting_Allocation_Position_Arr_Rel_Insert_Input>;
  content_url?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  customer_team?:InputMaybe<Staffing_Customer_Team_Obj_Rel_Insert_Input>;
  customer_team_id?:InputMaybe<Scalars['Int']>;
  customer_team_slots?:InputMaybe<Staffing_Customer_Team_Slot_Arr_Rel_Insert_Input>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  google_doc_ref?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  is_ready?:InputMaybe<Scalars['Boolean']>;
  notes?:InputMaybe<Scalars['String']>;
  position_group?:InputMaybe<Recruiting_Position_Group_Obj_Rel_Insert_Input>;
  position_group_id?:InputMaybe<Scalars['Int']>;
  position_template?:InputMaybe<Recruiting_Position_Template_Obj_Rel_Insert_Input>;
  position_template_id?:InputMaybe<Scalars['Int']>;
  quantity?:InputMaybe<Scalars['Int']>;
  ui_customer_team_order?:InputMaybe<Scalars['Int']>;
  ui_position_groups_order?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Recruiting_Position_Max_Fields = {
  __typename?:'recruiting_position_max_fields';
  alias?:Maybe<Scalars['String']>;
  content_url?:Maybe<Scalars['String']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  customer_team_id?:Maybe<Scalars['Int']>;
  deleted_at?:Maybe<Scalars['timestamptz']>;
  google_doc_ref?:Maybe<Scalars['String']>;
  id?:Maybe<Scalars['Int']>;
  notes?:Maybe<Scalars['String']>;
  position_group_id?:Maybe<Scalars['Int']>;
  position_template_id?:Maybe<Scalars['Int']>;
  quantity?:Maybe<Scalars['Int']>;
  ui_customer_team_order?:Maybe<Scalars['Int']>;
  ui_position_groups_order?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "recruiting.position" */
export type Recruiting_Position_Max_Order_By = {
  alias?:InputMaybe<Order_By>;
  content_url?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  customer_team_id?:InputMaybe<Order_By>;
  deleted_at?:InputMaybe<Order_By>;
  google_doc_ref?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  notes?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  position_template_id?:InputMaybe<Order_By>;
  quantity?:InputMaybe<Order_By>;
  ui_customer_team_order?:InputMaybe<Order_By>;
  ui_position_groups_order?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Recruiting_Position_Min_Fields = {
  __typename?:'recruiting_position_min_fields';
  alias?:Maybe<Scalars['String']>;
  content_url?:Maybe<Scalars['String']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  customer_team_id?:Maybe<Scalars['Int']>;
  deleted_at?:Maybe<Scalars['timestamptz']>;
  google_doc_ref?:Maybe<Scalars['String']>;
  id?:Maybe<Scalars['Int']>;
  notes?:Maybe<Scalars['String']>;
  position_group_id?:Maybe<Scalars['Int']>;
  position_template_id?:Maybe<Scalars['Int']>;
  quantity?:Maybe<Scalars['Int']>;
  ui_customer_team_order?:Maybe<Scalars['Int']>;
  ui_position_groups_order?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "recruiting.position" */
export type Recruiting_Position_Min_Order_By = {
  alias?:InputMaybe<Order_By>;
  content_url?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  customer_team_id?:InputMaybe<Order_By>;
  deleted_at?:InputMaybe<Order_By>;
  google_doc_ref?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  notes?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  position_template_id?:InputMaybe<Order_By>;
  quantity?:InputMaybe<Order_By>;
  ui_customer_team_order?:InputMaybe<Order_By>;
  ui_position_groups_order?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "recruiting.position" */
export type Recruiting_Position_Mutation_Response = {
  __typename?:'recruiting_position_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Position>;
};

/** input type for inserting object relation for remote table "recruiting.position" */
export type Recruiting_Position_Obj_Rel_Insert_Input = {
  data:Recruiting_Position_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Position_On_Conflict>;
};

/** on_conflict condition type for table "recruiting.position" */
export type Recruiting_Position_On_Conflict = {
  constraint:Recruiting_Position_Constraint;
  update_columns?:Array<Recruiting_Position_Update_Column>;
  where?:InputMaybe<Recruiting_Position_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.position". */
export type Recruiting_Position_Order_By = {
  alias?:InputMaybe<Order_By>;
  allocations_aggregate?:InputMaybe<Recruiting_Allocation_Position_Aggregate_Order_By>;
  content_url?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  customer_team?:InputMaybe<Staffing_Customer_Team_Order_By>;
  customer_team_id?:InputMaybe<Order_By>;
  customer_team_slots_aggregate?:InputMaybe<Staffing_Customer_Team_Slot_Aggregate_Order_By>;
  deleted_at?:InputMaybe<Order_By>;
  google_doc_ref?:InputMaybe<Order_By>;
  google_doc_url?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  is_ready?:InputMaybe<Order_By>;
  notes?:InputMaybe<Order_By>;
  position_group?:InputMaybe<Recruiting_Position_Group_Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  position_template?:InputMaybe<Recruiting_Position_Template_Order_By>;
  position_template_id?:InputMaybe<Order_By>;
  quantity?:InputMaybe<Order_By>;
  ui_customer_team_order?:InputMaybe<Order_By>;
  ui_position_groups_order?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.position */
export type Recruiting_Position_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "recruiting.position" */
export enum Recruiting_Position_Select_Column 
{
  /** column name */
  Alias = 'alias',
  /** column name */
  ContentUrl = 'content_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerTeamId = 'customer_team_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GoogleDocRef = 'google_doc_ref',
  /** column name */
  Id = 'id',
  /** column name */
  IsReady = 'is_ready',
  /** column name */
  Notes = 'notes',
  /** column name */
  PositionGroupId = 'position_group_id',
  /** column name */
  PositionTemplateId = 'position_template_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  UiCustomerTeamOrder = 'ui_customer_team_order',
  /** column name */
  UiPositionGroupsOrder = 'ui_position_groups_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "recruiting_position_aggregate_bool_exp_bool_and_arguments_columns" columns of table "recruiting.position" */
export enum Recruiting_Position_Select_Column_Recruiting_Position_Aggregate_Bool_Exp_Bool_And_Arguments_Columns 
{
  /** column name */
  IsReady = 'is_ready'
}

/** select "recruiting_position_aggregate_bool_exp_bool_or_arguments_columns" columns of table "recruiting.position" */
export enum Recruiting_Position_Select_Column_Recruiting_Position_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns 
{
  /** column name */
  IsReady = 'is_ready'
}

/** input type for updating data in table "recruiting.position" */
export type Recruiting_Position_Set_Input = {
  alias?:InputMaybe<Scalars['String']>;
  content_url?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  customer_team_id?:InputMaybe<Scalars['Int']>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  google_doc_ref?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  is_ready?:InputMaybe<Scalars['Boolean']>;
  notes?:InputMaybe<Scalars['String']>;
  position_group_id?:InputMaybe<Scalars['Int']>;
  position_template_id?:InputMaybe<Scalars['Int']>;
  quantity?:InputMaybe<Scalars['Int']>;
  ui_customer_team_order?:InputMaybe<Scalars['Int']>;
  ui_position_groups_order?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Recruiting_Position_Stddev_Fields = {
  __typename?:'recruiting_position_stddev_fields';
  customer_team_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  position_group_id?:Maybe<Scalars['Float']>;
  position_template_id?:Maybe<Scalars['Float']>;
  quantity?:Maybe<Scalars['Float']>;
  ui_customer_team_order?:Maybe<Scalars['Float']>;
  ui_position_groups_order?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "recruiting.position" */
export type Recruiting_Position_Stddev_Order_By = {
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  position_template_id?:InputMaybe<Order_By>;
  quantity?:InputMaybe<Order_By>;
  ui_customer_team_order?:InputMaybe<Order_By>;
  ui_position_groups_order?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Position_Stddev_Pop_Fields = {
  __typename?:'recruiting_position_stddev_pop_fields';
  customer_team_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  position_group_id?:Maybe<Scalars['Float']>;
  position_template_id?:Maybe<Scalars['Float']>;
  quantity?:Maybe<Scalars['Float']>;
  ui_customer_team_order?:Maybe<Scalars['Float']>;
  ui_position_groups_order?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "recruiting.position" */
export type Recruiting_Position_Stddev_Pop_Order_By = {
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  position_template_id?:InputMaybe<Order_By>;
  quantity?:InputMaybe<Order_By>;
  ui_customer_team_order?:InputMaybe<Order_By>;
  ui_position_groups_order?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Position_Stddev_Samp_Fields = {
  __typename?:'recruiting_position_stddev_samp_fields';
  customer_team_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  position_group_id?:Maybe<Scalars['Float']>;
  position_template_id?:Maybe<Scalars['Float']>;
  quantity?:Maybe<Scalars['Float']>;
  ui_customer_team_order?:Maybe<Scalars['Float']>;
  ui_position_groups_order?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "recruiting.position" */
export type Recruiting_Position_Stddev_Samp_Order_By = {
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  position_template_id?:InputMaybe<Order_By>;
  quantity?:InputMaybe<Order_By>;
  ui_customer_team_order?:InputMaybe<Order_By>;
  ui_position_groups_order?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "recruiting_position" */
export type Recruiting_Position_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Position_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Position_Stream_Cursor_Value_Input = {
  alias?:InputMaybe<Scalars['String']>;
  content_url?:InputMaybe<Scalars['String']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  customer_team_id?:InputMaybe<Scalars['Int']>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  google_doc_ref?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  is_ready?:InputMaybe<Scalars['Boolean']>;
  notes?:InputMaybe<Scalars['String']>;
  position_group_id?:InputMaybe<Scalars['Int']>;
  position_template_id?:InputMaybe<Scalars['Int']>;
  quantity?:InputMaybe<Scalars['Int']>;
  ui_customer_team_order?:InputMaybe<Scalars['Int']>;
  ui_position_groups_order?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Recruiting_Position_Sum_Fields = {
  __typename?:'recruiting_position_sum_fields';
  customer_team_id?:Maybe<Scalars['Int']>;
  id?:Maybe<Scalars['Int']>;
  position_group_id?:Maybe<Scalars['Int']>;
  position_template_id?:Maybe<Scalars['Int']>;
  quantity?:Maybe<Scalars['Int']>;
  ui_customer_team_order?:Maybe<Scalars['Int']>;
  ui_position_groups_order?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "recruiting.position" */
export type Recruiting_Position_Sum_Order_By = {
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  position_template_id?:InputMaybe<Order_By>;
  quantity?:InputMaybe<Order_By>;
  ui_customer_team_order?:InputMaybe<Order_By>;
  ui_position_groups_order?:InputMaybe<Order_By>;
};

/** columns and relationships of "recruiting.position_template" */
export type Recruiting_Position_Template = {
  __typename?:'recruiting_position_template';
  created_at:Scalars['timestamptz'];
  google_doc_ref:Scalars['String'];
  google_doc_url?:Maybe<Scalars['String']>;
  id:Scalars['Int'];
  name:Scalars['String'];
  /** An array relationship */
  positions:Array<Recruiting_Position>;
  /** An aggregate relationship */
  positions_aggregate:Recruiting_Position_Aggregate;
  /** An object relationship */
  staff_role:Staffing_Staff_Role;
  staff_role_id:Scalars['Int'];
  updated_at:Scalars['timestamptz'];
};


/** columns and relationships of "recruiting.position_template" */
export type Recruiting_Position_TemplatePositionsArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Bool_Exp>;
};


/** columns and relationships of "recruiting.position_template" */
export type Recruiting_Position_TemplatePositions_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Bool_Exp>;
};

/** aggregated selection of "recruiting.position_template" */
export type Recruiting_Position_Template_Aggregate = {
  __typename?:'recruiting_position_template_aggregate';
  aggregate?:Maybe<Recruiting_Position_Template_Aggregate_Fields>;
  nodes:Array<Recruiting_Position_Template>;
};

/** aggregate fields of "recruiting.position_template" */
export type Recruiting_Position_Template_Aggregate_Fields = {
  __typename?:'recruiting_position_template_aggregate_fields';
  avg?:Maybe<Recruiting_Position_Template_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Recruiting_Position_Template_Max_Fields>;
  min?:Maybe<Recruiting_Position_Template_Min_Fields>;
  stddev?:Maybe<Recruiting_Position_Template_Stddev_Fields>;
  stddev_pop?:Maybe<Recruiting_Position_Template_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Recruiting_Position_Template_Stddev_Samp_Fields>;
  sum?:Maybe<Recruiting_Position_Template_Sum_Fields>;
  var_pop?:Maybe<Recruiting_Position_Template_Var_Pop_Fields>;
  var_samp?:Maybe<Recruiting_Position_Template_Var_Samp_Fields>;
  variance?:Maybe<Recruiting_Position_Template_Variance_Fields>;
};


/** aggregate fields of "recruiting.position_template" */
export type Recruiting_Position_Template_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Recruiting_Position_Template_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Recruiting_Position_Template_Avg_Fields = {
  __typename?:'recruiting_position_template_avg_fields';
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "recruiting.position_template". All fields are combined with a logical 'AND'. */
export type Recruiting_Position_Template_Bool_Exp = {
  _and?:InputMaybe<Array<Recruiting_Position_Template_Bool_Exp>>;
  _not?:InputMaybe<Recruiting_Position_Template_Bool_Exp>;
  _or?:InputMaybe<Array<Recruiting_Position_Template_Bool_Exp>>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  google_doc_ref?:InputMaybe<String_Comparison_Exp>;
  google_doc_url?:InputMaybe<String_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  name?:InputMaybe<String_Comparison_Exp>;
  positions?:InputMaybe<Recruiting_Position_Bool_Exp>;
  positions_aggregate?:InputMaybe<Recruiting_Position_Aggregate_Bool_Exp>;
  staff_role?:InputMaybe<Staffing_Staff_Role_Bool_Exp>;
  staff_role_id?:InputMaybe<Int_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "recruiting.position_template" */
export enum Recruiting_Position_Template_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  PositionTemplatePkey = 'position_template_pkey'
}

/** input type for incrementing numeric columns in table "recruiting.position_template" */
export type Recruiting_Position_Template_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
  staff_role_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "recruiting.position_template" */
export type Recruiting_Position_Template_Insert_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  google_doc_ref?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  positions?:InputMaybe<Recruiting_Position_Arr_Rel_Insert_Input>;
  staff_role?:InputMaybe<Staffing_Staff_Role_Obj_Rel_Insert_Input>;
  staff_role_id?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Recruiting_Position_Template_Max_Fields = {
  __typename?:'recruiting_position_template_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  google_doc_ref?:Maybe<Scalars['String']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  staff_role_id?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Recruiting_Position_Template_Min_Fields = {
  __typename?:'recruiting_position_template_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  google_doc_ref?:Maybe<Scalars['String']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  staff_role_id?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "recruiting.position_template" */
export type Recruiting_Position_Template_Mutation_Response = {
  __typename?:'recruiting_position_template_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Recruiting_Position_Template>;
};

/** input type for inserting object relation for remote table "recruiting.position_template" */
export type Recruiting_Position_Template_Obj_Rel_Insert_Input = {
  data:Recruiting_Position_Template_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Recruiting_Position_Template_On_Conflict>;
};

/** on_conflict condition type for table "recruiting.position_template" */
export type Recruiting_Position_Template_On_Conflict = {
  constraint:Recruiting_Position_Template_Constraint;
  update_columns?:Array<Recruiting_Position_Template_Update_Column>;
  where?:InputMaybe<Recruiting_Position_Template_Bool_Exp>;
};

/** Ordering options when selecting data from "recruiting.position_template". */
export type Recruiting_Position_Template_Order_By = {
  created_at?:InputMaybe<Order_By>;
  google_doc_ref?:InputMaybe<Order_By>;
  google_doc_url?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  positions_aggregate?:InputMaybe<Recruiting_Position_Aggregate_Order_By>;
  staff_role?:InputMaybe<Staffing_Staff_Role_Order_By>;
  staff_role_id?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: recruiting.position_template */
export type Recruiting_Position_Template_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "recruiting.position_template" */
export enum Recruiting_Position_Template_Select_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GoogleDocRef = 'google_doc_ref',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StaffRoleId = 'staff_role_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "recruiting.position_template" */
export type Recruiting_Position_Template_Set_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  google_doc_ref?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  staff_role_id?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Recruiting_Position_Template_Stddev_Fields = {
  __typename?:'recruiting_position_template_stddev_fields';
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Recruiting_Position_Template_Stddev_Pop_Fields = {
  __typename?:'recruiting_position_template_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Recruiting_Position_Template_Stddev_Samp_Fields = {
  __typename?:'recruiting_position_template_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "recruiting_position_template" */
export type Recruiting_Position_Template_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Recruiting_Position_Template_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recruiting_Position_Template_Stream_Cursor_Value_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  google_doc_ref?:InputMaybe<Scalars['String']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  staff_role_id?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Recruiting_Position_Template_Sum_Fields = {
  __typename?:'recruiting_position_template_sum_fields';
  id?:Maybe<Scalars['Int']>;
  staff_role_id?:Maybe<Scalars['Int']>;
};

/** update columns of table "recruiting.position_template" */
export enum Recruiting_Position_Template_Update_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GoogleDocRef = 'google_doc_ref',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StaffRoleId = 'staff_role_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Recruiting_Position_Template_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Position_Template_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Position_Template_Set_Input>;
  where:Recruiting_Position_Template_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Position_Template_Var_Pop_Fields = {
  __typename?:'recruiting_position_template_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Recruiting_Position_Template_Var_Samp_Fields = {
  __typename?:'recruiting_position_template_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Recruiting_Position_Template_Variance_Fields = {
  __typename?:'recruiting_position_template_variance_fields';
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
};

/** update columns of table "recruiting.position" */
export enum Recruiting_Position_Update_Column 
{
  /** column name */
  Alias = 'alias',
  /** column name */
  ContentUrl = 'content_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerTeamId = 'customer_team_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GoogleDocRef = 'google_doc_ref',
  /** column name */
  Id = 'id',
  /** column name */
  IsReady = 'is_ready',
  /** column name */
  Notes = 'notes',
  /** column name */
  PositionGroupId = 'position_group_id',
  /** column name */
  PositionTemplateId = 'position_template_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  UiCustomerTeamOrder = 'ui_customer_team_order',
  /** column name */
  UiPositionGroupsOrder = 'ui_position_groups_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Recruiting_Position_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Recruiting_Position_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Recruiting_Position_Set_Input>;
  where:Recruiting_Position_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recruiting_Position_Var_Pop_Fields = {
  __typename?:'recruiting_position_var_pop_fields';
  customer_team_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  position_group_id?:Maybe<Scalars['Float']>;
  position_template_id?:Maybe<Scalars['Float']>;
  quantity?:Maybe<Scalars['Float']>;
  ui_customer_team_order?:Maybe<Scalars['Float']>;
  ui_position_groups_order?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "recruiting.position" */
export type Recruiting_Position_Var_Pop_Order_By = {
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  position_template_id?:InputMaybe<Order_By>;
  quantity?:InputMaybe<Order_By>;
  ui_customer_team_order?:InputMaybe<Order_By>;
  ui_position_groups_order?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recruiting_Position_Var_Samp_Fields = {
  __typename?:'recruiting_position_var_samp_fields';
  customer_team_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  position_group_id?:Maybe<Scalars['Float']>;
  position_template_id?:Maybe<Scalars['Float']>;
  quantity?:Maybe<Scalars['Float']>;
  ui_customer_team_order?:Maybe<Scalars['Float']>;
  ui_position_groups_order?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "recruiting.position" */
export type Recruiting_Position_Var_Samp_Order_By = {
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  position_template_id?:InputMaybe<Order_By>;
  quantity?:InputMaybe<Order_By>;
  ui_customer_team_order?:InputMaybe<Order_By>;
  ui_position_groups_order?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Recruiting_Position_Variance_Fields = {
  __typename?:'recruiting_position_variance_fields';
  customer_team_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  position_group_id?:Maybe<Scalars['Float']>;
  position_template_id?:Maybe<Scalars['Float']>;
  quantity?:Maybe<Scalars['Float']>;
  ui_customer_team_order?:Maybe<Scalars['Float']>;
  ui_position_groups_order?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "recruiting.position" */
export type Recruiting_Position_Variance_Order_By = {
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  position_group_id?:InputMaybe<Order_By>;
  position_template_id?:InputMaybe<Order_By>;
  quantity?:InputMaybe<Order_By>;
  ui_customer_team_order?:InputMaybe<Order_By>;
  ui_position_groups_order?:InputMaybe<Order_By>;
};

/** columns and relationships of "staffing.customer_team" */
export type Staffing_Customer_Team = {
  __typename?:'staffing_customer_team';
  created_at:Scalars['timestamptz'];
  /** An object relationship */
  customer:Common_Customer;
  customer_id:Scalars['Int'];
  /** An array relationship */
  customer_team_tags:Array<Staffing_Customer_Team_Tag>;
  /** An aggregate relationship */
  customer_team_tags_aggregate:Staffing_Customer_Team_Tag_Aggregate;
  deleted_at?:Maybe<Scalars['timestamptz']>;
  id:Scalars['Int'];
  is_active:Scalars['Boolean'];
  name:Scalars['String'];
  notes?:Maybe<Scalars['String']>;
  /** An array relationship */
  positions:Array<Recruiting_Position>;
  /** An aggregate relationship */
  positions_aggregate:Recruiting_Position_Aggregate;
  ui_order:Scalars['Int'];
  updated_at?:Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "staffing.customer_team" */
export type Staffing_Customer_TeamCustomer_Team_TagsArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Tag_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Tag_Bool_Exp>;
};


/** columns and relationships of "staffing.customer_team" */
export type Staffing_Customer_TeamCustomer_Team_Tags_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Tag_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Tag_Bool_Exp>;
};


/** columns and relationships of "staffing.customer_team" */
export type Staffing_Customer_TeamPositionsArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Bool_Exp>;
};


/** columns and relationships of "staffing.customer_team" */
export type Staffing_Customer_TeamPositions_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Bool_Exp>;
};

/** aggregated selection of "staffing.customer_team" */
export type Staffing_Customer_Team_Aggregate = {
  __typename?:'staffing_customer_team_aggregate';
  aggregate?:Maybe<Staffing_Customer_Team_Aggregate_Fields>;
  nodes:Array<Staffing_Customer_Team>;
};

export type Staffing_Customer_Team_Aggregate_Bool_Exp = {
  bool_and?:InputMaybe<Staffing_Customer_Team_Aggregate_Bool_Exp_Bool_And>;
  bool_or?:InputMaybe<Staffing_Customer_Team_Aggregate_Bool_Exp_Bool_Or>;
  count?:InputMaybe<Staffing_Customer_Team_Aggregate_Bool_Exp_Count>;
};

export type Staffing_Customer_Team_Aggregate_Bool_Exp_Bool_And = {
  arguments:Staffing_Customer_Team_Select_Column_Staffing_Customer_Team_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Staffing_Customer_Team_Bool_Exp>;
  predicate:Boolean_Comparison_Exp;
};

export type Staffing_Customer_Team_Aggregate_Bool_Exp_Bool_Or = {
  arguments:Staffing_Customer_Team_Select_Column_Staffing_Customer_Team_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Staffing_Customer_Team_Bool_Exp>;
  predicate:Boolean_Comparison_Exp;
};

export type Staffing_Customer_Team_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Staffing_Customer_Team_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Staffing_Customer_Team_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "staffing.customer_team" */
export type Staffing_Customer_Team_Aggregate_Fields = {
  __typename?:'staffing_customer_team_aggregate_fields';
  avg?:Maybe<Staffing_Customer_Team_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Staffing_Customer_Team_Max_Fields>;
  min?:Maybe<Staffing_Customer_Team_Min_Fields>;
  stddev?:Maybe<Staffing_Customer_Team_Stddev_Fields>;
  stddev_pop?:Maybe<Staffing_Customer_Team_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Staffing_Customer_Team_Stddev_Samp_Fields>;
  sum?:Maybe<Staffing_Customer_Team_Sum_Fields>;
  var_pop?:Maybe<Staffing_Customer_Team_Var_Pop_Fields>;
  var_samp?:Maybe<Staffing_Customer_Team_Var_Samp_Fields>;
  variance?:Maybe<Staffing_Customer_Team_Variance_Fields>;
};


/** aggregate fields of "staffing.customer_team" */
export type Staffing_Customer_Team_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Staffing_Customer_Team_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "staffing.customer_team" */
export type Staffing_Customer_Team_Aggregate_Order_By = {
  avg?:InputMaybe<Staffing_Customer_Team_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Staffing_Customer_Team_Max_Order_By>;
  min?:InputMaybe<Staffing_Customer_Team_Min_Order_By>;
  stddev?:InputMaybe<Staffing_Customer_Team_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Staffing_Customer_Team_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Staffing_Customer_Team_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Staffing_Customer_Team_Sum_Order_By>;
  var_pop?:InputMaybe<Staffing_Customer_Team_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Staffing_Customer_Team_Var_Samp_Order_By>;
  variance?:InputMaybe<Staffing_Customer_Team_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "staffing.customer_team" */
export type Staffing_Customer_Team_Arr_Rel_Insert_Input = {
  data:Array<Staffing_Customer_Team_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Staffing_Customer_Team_On_Conflict>;
};

/** aggregate avg on columns */
export type Staffing_Customer_Team_Avg_Fields = {
  __typename?:'staffing_customer_team_avg_fields';
  customer_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "staffing.customer_team" */
export type Staffing_Customer_Team_Avg_Order_By = {
  customer_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "staffing.customer_team". All fields are combined with a logical 'AND'. */
export type Staffing_Customer_Team_Bool_Exp = {
  _and?:InputMaybe<Array<Staffing_Customer_Team_Bool_Exp>>;
  _not?:InputMaybe<Staffing_Customer_Team_Bool_Exp>;
  _or?:InputMaybe<Array<Staffing_Customer_Team_Bool_Exp>>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  customer?:InputMaybe<Common_Customer_Bool_Exp>;
  customer_id?:InputMaybe<Int_Comparison_Exp>;
  customer_team_tags?:InputMaybe<Staffing_Customer_Team_Tag_Bool_Exp>;
  customer_team_tags_aggregate?:InputMaybe<Staffing_Customer_Team_Tag_Aggregate_Bool_Exp>;
  deleted_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  is_active?:InputMaybe<Boolean_Comparison_Exp>;
  name?:InputMaybe<String_Comparison_Exp>;
  notes?:InputMaybe<String_Comparison_Exp>;
  positions?:InputMaybe<Recruiting_Position_Bool_Exp>;
  positions_aggregate?:InputMaybe<Recruiting_Position_Aggregate_Bool_Exp>;
  ui_order?:InputMaybe<Int_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "staffing.customer_team" */
export enum Staffing_Customer_Team_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  CustomerTeamPkey = 'customer_team_pkey'
}

/** input type for incrementing numeric columns in table "staffing.customer_team" */
export type Staffing_Customer_Team_Inc_Input = {
  customer_id?:InputMaybe<Scalars['Int']>;
  id?:InputMaybe<Scalars['Int']>;
  ui_order?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "staffing.customer_team" */
export type Staffing_Customer_Team_Insert_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  customer?:InputMaybe<Common_Customer_Obj_Rel_Insert_Input>;
  customer_id?:InputMaybe<Scalars['Int']>;
  customer_team_tags?:InputMaybe<Staffing_Customer_Team_Tag_Arr_Rel_Insert_Input>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  is_active?:InputMaybe<Scalars['Boolean']>;
  name?:InputMaybe<Scalars['String']>;
  notes?:InputMaybe<Scalars['String']>;
  positions?:InputMaybe<Recruiting_Position_Arr_Rel_Insert_Input>;
  ui_order?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Staffing_Customer_Team_Max_Fields = {
  __typename?:'staffing_customer_team_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  customer_id?:Maybe<Scalars['Int']>;
  deleted_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  notes?:Maybe<Scalars['String']>;
  ui_order?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "staffing.customer_team" */
export type Staffing_Customer_Team_Max_Order_By = {
  created_at?:InputMaybe<Order_By>;
  customer_id?:InputMaybe<Order_By>;
  deleted_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  notes?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Staffing_Customer_Team_Min_Fields = {
  __typename?:'staffing_customer_team_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  customer_id?:Maybe<Scalars['Int']>;
  deleted_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  notes?:Maybe<Scalars['String']>;
  ui_order?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "staffing.customer_team" */
export type Staffing_Customer_Team_Min_Order_By = {
  created_at?:InputMaybe<Order_By>;
  customer_id?:InputMaybe<Order_By>;
  deleted_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  notes?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "staffing.customer_team" */
export type Staffing_Customer_Team_Mutation_Response = {
  __typename?:'staffing_customer_team_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Staffing_Customer_Team>;
};

/** input type for inserting object relation for remote table "staffing.customer_team" */
export type Staffing_Customer_Team_Obj_Rel_Insert_Input = {
  data:Staffing_Customer_Team_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Staffing_Customer_Team_On_Conflict>;
};

/** on_conflict condition type for table "staffing.customer_team" */
export type Staffing_Customer_Team_On_Conflict = {
  constraint:Staffing_Customer_Team_Constraint;
  update_columns?:Array<Staffing_Customer_Team_Update_Column>;
  where?:InputMaybe<Staffing_Customer_Team_Bool_Exp>;
};

/** Ordering options when selecting data from "staffing.customer_team". */
export type Staffing_Customer_Team_Order_By = {
  created_at?:InputMaybe<Order_By>;
  customer?:InputMaybe<Common_Customer_Order_By>;
  customer_id?:InputMaybe<Order_By>;
  customer_team_tags_aggregate?:InputMaybe<Staffing_Customer_Team_Tag_Aggregate_Order_By>;
  deleted_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  is_active?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  notes?:InputMaybe<Order_By>;
  positions_aggregate?:InputMaybe<Recruiting_Position_Aggregate_Order_By>;
  ui_order?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: staffing.customer_team */
export type Staffing_Customer_Team_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "staffing.customer_team" */
export enum Staffing_Customer_Team_Select_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  UiOrder = 'ui_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "staffing_customer_team_aggregate_bool_exp_bool_and_arguments_columns" columns of table "staffing.customer_team" */
export enum Staffing_Customer_Team_Select_Column_Staffing_Customer_Team_Aggregate_Bool_Exp_Bool_And_Arguments_Columns 
{
  /** column name */
  IsActive = 'is_active'
}

/** select "staffing_customer_team_aggregate_bool_exp_bool_or_arguments_columns" columns of table "staffing.customer_team" */
export enum Staffing_Customer_Team_Select_Column_Staffing_Customer_Team_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns 
{
  /** column name */
  IsActive = 'is_active'
}

/** input type for updating data in table "staffing.customer_team" */
export type Staffing_Customer_Team_Set_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  customer_id?:InputMaybe<Scalars['Int']>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  is_active?:InputMaybe<Scalars['Boolean']>;
  name?:InputMaybe<Scalars['String']>;
  notes?:InputMaybe<Scalars['String']>;
  ui_order?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot = {
  __typename?:'staffing_customer_team_slot';
  /** An object relationship */
  candidate?:Maybe<Recruiting_Candidate>;
  /** An object relationship */
  candidate_allocation?:Maybe<Recruiting_Candidate_Allocation>;
  created_at:Scalars['timestamptz'];
  /** An object relationship */
  customer_team_slot_status:Staffing_Customer_Team_Slot_Status;
  deleted_at?:Maybe<Scalars['timestamptz']>;
  id:Scalars['Int'];
  leaving_date?:Maybe<Scalars['timestamptz']>;
  next_candidate_allocation_id?:Maybe<Scalars['Int']>;
  next_candidate_id?:Maybe<Scalars['Int']>;
  next_candidate_start_date?:Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  next_staff?:Maybe<Staffing_Staff>;
  next_staff_id?:Maybe<Scalars['Int']>;
  next_staff_start_date?:Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  position:Recruiting_Position;
  position_id:Scalars['Int'];
  /** An object relationship */
  staff?:Maybe<Staffing_Staff>;
  staff_id?:Maybe<Scalars['Int']>;
  start_date?:Maybe<Scalars['timestamptz']>;
  status:Staffing_Customer_Team_Slot_Status_Enum;
  updated_at:Scalars['timestamptz'];
};

/** aggregated selection of "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Aggregate = {
  __typename?:'staffing_customer_team_slot_aggregate';
  aggregate?:Maybe<Staffing_Customer_Team_Slot_Aggregate_Fields>;
  nodes:Array<Staffing_Customer_Team_Slot>;
};

export type Staffing_Customer_Team_Slot_Aggregate_Bool_Exp = {
  count?:InputMaybe<Staffing_Customer_Team_Slot_Aggregate_Bool_Exp_Count>;
};

export type Staffing_Customer_Team_Slot_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Staffing_Customer_Team_Slot_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Aggregate_Fields = {
  __typename?:'staffing_customer_team_slot_aggregate_fields';
  avg?:Maybe<Staffing_Customer_Team_Slot_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Staffing_Customer_Team_Slot_Max_Fields>;
  min?:Maybe<Staffing_Customer_Team_Slot_Min_Fields>;
  stddev?:Maybe<Staffing_Customer_Team_Slot_Stddev_Fields>;
  stddev_pop?:Maybe<Staffing_Customer_Team_Slot_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Staffing_Customer_Team_Slot_Stddev_Samp_Fields>;
  sum?:Maybe<Staffing_Customer_Team_Slot_Sum_Fields>;
  var_pop?:Maybe<Staffing_Customer_Team_Slot_Var_Pop_Fields>;
  var_samp?:Maybe<Staffing_Customer_Team_Slot_Var_Samp_Fields>;
  variance?:Maybe<Staffing_Customer_Team_Slot_Variance_Fields>;
};


/** aggregate fields of "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Staffing_Customer_Team_Slot_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Aggregate_Order_By = {
  avg?:InputMaybe<Staffing_Customer_Team_Slot_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Staffing_Customer_Team_Slot_Max_Order_By>;
  min?:InputMaybe<Staffing_Customer_Team_Slot_Min_Order_By>;
  stddev?:InputMaybe<Staffing_Customer_Team_Slot_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Staffing_Customer_Team_Slot_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Staffing_Customer_Team_Slot_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Staffing_Customer_Team_Slot_Sum_Order_By>;
  var_pop?:InputMaybe<Staffing_Customer_Team_Slot_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Staffing_Customer_Team_Slot_Var_Samp_Order_By>;
  variance?:InputMaybe<Staffing_Customer_Team_Slot_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Arr_Rel_Insert_Input = {
  data:Array<Staffing_Customer_Team_Slot_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Staffing_Customer_Team_Slot_On_Conflict>;
};

/** aggregate avg on columns */
export type Staffing_Customer_Team_Slot_Avg_Fields = {
  __typename?:'staffing_customer_team_slot_avg_fields';
  id?:Maybe<Scalars['Float']>;
  next_candidate_allocation_id?:Maybe<Scalars['Float']>;
  next_candidate_id?:Maybe<Scalars['Float']>;
  next_staff_id?:Maybe<Scalars['Float']>;
  position_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Avg_Order_By = {
  id?:InputMaybe<Order_By>;
  next_candidate_allocation_id?:InputMaybe<Order_By>;
  next_candidate_id?:InputMaybe<Order_By>;
  next_staff_id?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "staffing.customer_team_slot". All fields are combined with a logical 'AND'. */
export type Staffing_Customer_Team_Slot_Bool_Exp = {
  _and?:InputMaybe<Array<Staffing_Customer_Team_Slot_Bool_Exp>>;
  _not?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
  _or?:InputMaybe<Array<Staffing_Customer_Team_Slot_Bool_Exp>>;
  candidate?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
  candidate_allocation?:InputMaybe<Recruiting_Candidate_Allocation_Bool_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  customer_team_slot_status?:InputMaybe<Staffing_Customer_Team_Slot_Status_Bool_Exp>;
  deleted_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  leaving_date?:InputMaybe<Timestamptz_Comparison_Exp>;
  next_candidate_allocation_id?:InputMaybe<Int_Comparison_Exp>;
  next_candidate_id?:InputMaybe<Int_Comparison_Exp>;
  next_candidate_start_date?:InputMaybe<Timestamptz_Comparison_Exp>;
  next_staff?:InputMaybe<Staffing_Staff_Bool_Exp>;
  next_staff_id?:InputMaybe<Int_Comparison_Exp>;
  next_staff_start_date?:InputMaybe<Timestamptz_Comparison_Exp>;
  position?:InputMaybe<Recruiting_Position_Bool_Exp>;
  position_id?:InputMaybe<Int_Comparison_Exp>;
  staff?:InputMaybe<Staffing_Staff_Bool_Exp>;
  staff_id?:InputMaybe<Int_Comparison_Exp>;
  start_date?:InputMaybe<Timestamptz_Comparison_Exp>;
  status?:InputMaybe<Staffing_Customer_Team_Slot_Status_Enum_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "staffing.customer_team_slot" */
export enum Staffing_Customer_Team_Slot_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  CustomerTeamSlotPkey = 'customer_team_slot_pkey'
}

/** input type for incrementing numeric columns in table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
  next_candidate_allocation_id?:InputMaybe<Scalars['Int']>;
  next_candidate_id?:InputMaybe<Scalars['Int']>;
  next_staff_id?:InputMaybe<Scalars['Int']>;
  position_id?:InputMaybe<Scalars['Int']>;
  staff_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Insert_Input = {
  candidate?:InputMaybe<Recruiting_Candidate_Obj_Rel_Insert_Input>;
  candidate_allocation?:InputMaybe<Recruiting_Candidate_Allocation_Obj_Rel_Insert_Input>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  customer_team_slot_status?:InputMaybe<Staffing_Customer_Team_Slot_Status_Obj_Rel_Insert_Input>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  leaving_date?:InputMaybe<Scalars['timestamptz']>;
  next_candidate_allocation_id?:InputMaybe<Scalars['Int']>;
  next_candidate_id?:InputMaybe<Scalars['Int']>;
  next_candidate_start_date?:InputMaybe<Scalars['timestamptz']>;
  next_staff?:InputMaybe<Staffing_Staff_Obj_Rel_Insert_Input>;
  next_staff_id?:InputMaybe<Scalars['Int']>;
  next_staff_start_date?:InputMaybe<Scalars['timestamptz']>;
  position?:InputMaybe<Recruiting_Position_Obj_Rel_Insert_Input>;
  position_id?:InputMaybe<Scalars['Int']>;
  staff?:InputMaybe<Staffing_Staff_Obj_Rel_Insert_Input>;
  staff_id?:InputMaybe<Scalars['Int']>;
  start_date?:InputMaybe<Scalars['timestamptz']>;
  status?:InputMaybe<Staffing_Customer_Team_Slot_Status_Enum>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Staffing_Customer_Team_Slot_Max_Fields = {
  __typename?:'staffing_customer_team_slot_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  deleted_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  leaving_date?:Maybe<Scalars['timestamptz']>;
  next_candidate_allocation_id?:Maybe<Scalars['Int']>;
  next_candidate_id?:Maybe<Scalars['Int']>;
  next_candidate_start_date?:Maybe<Scalars['timestamptz']>;
  next_staff_id?:Maybe<Scalars['Int']>;
  next_staff_start_date?:Maybe<Scalars['timestamptz']>;
  position_id?:Maybe<Scalars['Int']>;
  staff_id?:Maybe<Scalars['Int']>;
  start_date?:Maybe<Scalars['timestamptz']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Max_Order_By = {
  created_at?:InputMaybe<Order_By>;
  deleted_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  leaving_date?:InputMaybe<Order_By>;
  next_candidate_allocation_id?:InputMaybe<Order_By>;
  next_candidate_id?:InputMaybe<Order_By>;
  next_candidate_start_date?:InputMaybe<Order_By>;
  next_staff_id?:InputMaybe<Order_By>;
  next_staff_start_date?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
  start_date?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Staffing_Customer_Team_Slot_Min_Fields = {
  __typename?:'staffing_customer_team_slot_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  deleted_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  leaving_date?:Maybe<Scalars['timestamptz']>;
  next_candidate_allocation_id?:Maybe<Scalars['Int']>;
  next_candidate_id?:Maybe<Scalars['Int']>;
  next_candidate_start_date?:Maybe<Scalars['timestamptz']>;
  next_staff_id?:Maybe<Scalars['Int']>;
  next_staff_start_date?:Maybe<Scalars['timestamptz']>;
  position_id?:Maybe<Scalars['Int']>;
  staff_id?:Maybe<Scalars['Int']>;
  start_date?:Maybe<Scalars['timestamptz']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Min_Order_By = {
  created_at?:InputMaybe<Order_By>;
  deleted_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  leaving_date?:InputMaybe<Order_By>;
  next_candidate_allocation_id?:InputMaybe<Order_By>;
  next_candidate_id?:InputMaybe<Order_By>;
  next_candidate_start_date?:InputMaybe<Order_By>;
  next_staff_id?:InputMaybe<Order_By>;
  next_staff_start_date?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
  start_date?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Mutation_Response = {
  __typename?:'staffing_customer_team_slot_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Staffing_Customer_Team_Slot>;
};

/** on_conflict condition type for table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_On_Conflict = {
  constraint:Staffing_Customer_Team_Slot_Constraint;
  update_columns?:Array<Staffing_Customer_Team_Slot_Update_Column>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
};

/** Ordering options when selecting data from "staffing.customer_team_slot". */
export type Staffing_Customer_Team_Slot_Order_By = {
  candidate?:InputMaybe<Recruiting_Candidate_Order_By>;
  candidate_allocation?:InputMaybe<Recruiting_Candidate_Allocation_Order_By>;
  created_at?:InputMaybe<Order_By>;
  customer_team_slot_status?:InputMaybe<Staffing_Customer_Team_Slot_Status_Order_By>;
  deleted_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  leaving_date?:InputMaybe<Order_By>;
  next_candidate_allocation_id?:InputMaybe<Order_By>;
  next_candidate_id?:InputMaybe<Order_By>;
  next_candidate_start_date?:InputMaybe<Order_By>;
  next_staff?:InputMaybe<Staffing_Staff_Order_By>;
  next_staff_id?:InputMaybe<Order_By>;
  next_staff_start_date?:InputMaybe<Order_By>;
  position?:InputMaybe<Recruiting_Position_Order_By>;
  position_id?:InputMaybe<Order_By>;
  staff?:InputMaybe<Staffing_Staff_Order_By>;
  staff_id?:InputMaybe<Order_By>;
  start_date?:InputMaybe<Order_By>;
  status?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: staffing.customer_team_slot */
export type Staffing_Customer_Team_Slot_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "staffing.customer_team_slot" */
export enum Staffing_Customer_Team_Slot_Select_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LeavingDate = 'leaving_date',
  /** column name */
  NextCandidateAllocationId = 'next_candidate_allocation_id',
  /** column name */
  NextCandidateId = 'next_candidate_id',
  /** column name */
  NextCandidateStartDate = 'next_candidate_start_date',
  /** column name */
  NextStaffId = 'next_staff_id',
  /** column name */
  NextStaffStartDate = 'next_staff_start_date',
  /** column name */
  PositionId = 'position_id',
  /** column name */
  StaffId = 'staff_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Set_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  leaving_date?:InputMaybe<Scalars['timestamptz']>;
  next_candidate_allocation_id?:InputMaybe<Scalars['Int']>;
  next_candidate_id?:InputMaybe<Scalars['Int']>;
  next_candidate_start_date?:InputMaybe<Scalars['timestamptz']>;
  next_staff_id?:InputMaybe<Scalars['Int']>;
  next_staff_start_date?:InputMaybe<Scalars['timestamptz']>;
  position_id?:InputMaybe<Scalars['Int']>;
  staff_id?:InputMaybe<Scalars['Int']>;
  start_date?:InputMaybe<Scalars['timestamptz']>;
  status?:InputMaybe<Staffing_Customer_Team_Slot_Status_Enum>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "staffing.customer_team_slot_status" */
export type Staffing_Customer_Team_Slot_Status = {
  __typename?:'staffing_customer_team_slot_status';
  status:Scalars['String'];
};

/** aggregated selection of "staffing.customer_team_slot_status" */
export type Staffing_Customer_Team_Slot_Status_Aggregate = {
  __typename?:'staffing_customer_team_slot_status_aggregate';
  aggregate?:Maybe<Staffing_Customer_Team_Slot_Status_Aggregate_Fields>;
  nodes:Array<Staffing_Customer_Team_Slot_Status>;
};

/** aggregate fields of "staffing.customer_team_slot_status" */
export type Staffing_Customer_Team_Slot_Status_Aggregate_Fields = {
  __typename?:'staffing_customer_team_slot_status_aggregate_fields';
  count:Scalars['Int'];
  max?:Maybe<Staffing_Customer_Team_Slot_Status_Max_Fields>;
  min?:Maybe<Staffing_Customer_Team_Slot_Status_Min_Fields>;
};


/** aggregate fields of "staffing.customer_team_slot_status" */
export type Staffing_Customer_Team_Slot_Status_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Staffing_Customer_Team_Slot_Status_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "staffing.customer_team_slot_status". All fields are combined with a logical 'AND'. */
export type Staffing_Customer_Team_Slot_Status_Bool_Exp = {
  _and?:InputMaybe<Array<Staffing_Customer_Team_Slot_Status_Bool_Exp>>;
  _not?:InputMaybe<Staffing_Customer_Team_Slot_Status_Bool_Exp>;
  _or?:InputMaybe<Array<Staffing_Customer_Team_Slot_Status_Bool_Exp>>;
  status?:InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "staffing.customer_team_slot_status" */
export enum Staffing_Customer_Team_Slot_Status_Constraint 
{
  /** unique or primary key constraint on columns "status" */
  CustomerTeamSlotStatusPkey = 'customer_team_slot_status_pkey'
}

export enum Staffing_Customer_Team_Slot_Status_Enum 
{
  ClosedStaffLeft = 'closed_staff_left',
  CurrentStaff = 'current_staff',
  Open = 'open'
}

/** Boolean expression to compare columns of type "staffing_customer_team_slot_status_enum". All fields are combined with logical 'AND'. */
export type Staffing_Customer_Team_Slot_Status_Enum_Comparison_Exp = {
  _eq?:InputMaybe<Staffing_Customer_Team_Slot_Status_Enum>;
  _in?:InputMaybe<Array<Staffing_Customer_Team_Slot_Status_Enum>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _neq?:InputMaybe<Staffing_Customer_Team_Slot_Status_Enum>;
  _nin?:InputMaybe<Array<Staffing_Customer_Team_Slot_Status_Enum>>;
};

/** input type for inserting data into table "staffing.customer_team_slot_status" */
export type Staffing_Customer_Team_Slot_Status_Insert_Input = {
  status?:InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Staffing_Customer_Team_Slot_Status_Max_Fields = {
  __typename?:'staffing_customer_team_slot_status_max_fields';
  status?:Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Staffing_Customer_Team_Slot_Status_Min_Fields = {
  __typename?:'staffing_customer_team_slot_status_min_fields';
  status?:Maybe<Scalars['String']>;
};

/** response of any mutation on the table "staffing.customer_team_slot_status" */
export type Staffing_Customer_Team_Slot_Status_Mutation_Response = {
  __typename?:'staffing_customer_team_slot_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Staffing_Customer_Team_Slot_Status>;
};

/** input type for inserting object relation for remote table "staffing.customer_team_slot_status" */
export type Staffing_Customer_Team_Slot_Status_Obj_Rel_Insert_Input = {
  data:Staffing_Customer_Team_Slot_Status_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Staffing_Customer_Team_Slot_Status_On_Conflict>;
};

/** on_conflict condition type for table "staffing.customer_team_slot_status" */
export type Staffing_Customer_Team_Slot_Status_On_Conflict = {
  constraint:Staffing_Customer_Team_Slot_Status_Constraint;
  update_columns?:Array<Staffing_Customer_Team_Slot_Status_Update_Column>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "staffing.customer_team_slot_status". */
export type Staffing_Customer_Team_Slot_Status_Order_By = {
  status?:InputMaybe<Order_By>;
};

/** primary key columns input for table: staffing.customer_team_slot_status */
export type Staffing_Customer_Team_Slot_Status_Pk_Columns_Input = {
  status:Scalars['String'];
};

/** select columns of table "staffing.customer_team_slot_status" */
export enum Staffing_Customer_Team_Slot_Status_Select_Column 
{
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "staffing.customer_team_slot_status" */
export type Staffing_Customer_Team_Slot_Status_Set_Input = {
  status?:InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "staffing_customer_team_slot_status" */
export type Staffing_Customer_Team_Slot_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Staffing_Customer_Team_Slot_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staffing_Customer_Team_Slot_Status_Stream_Cursor_Value_Input = {
  status?:InputMaybe<Scalars['String']>;
};

/** update columns of table "staffing.customer_team_slot_status" */
export enum Staffing_Customer_Team_Slot_Status_Update_Column 
{
  /** column name */
  Status = 'status'
}

export type Staffing_Customer_Team_Slot_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Staffing_Customer_Team_Slot_Status_Set_Input>;
  where:Staffing_Customer_Team_Slot_Status_Bool_Exp;
};

/** aggregate stddev on columns */
export type Staffing_Customer_Team_Slot_Stddev_Fields = {
  __typename?:'staffing_customer_team_slot_stddev_fields';
  id?:Maybe<Scalars['Float']>;
  next_candidate_allocation_id?:Maybe<Scalars['Float']>;
  next_candidate_id?:Maybe<Scalars['Float']>;
  next_staff_id?:Maybe<Scalars['Float']>;
  position_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Stddev_Order_By = {
  id?:InputMaybe<Order_By>;
  next_candidate_allocation_id?:InputMaybe<Order_By>;
  next_candidate_id?:InputMaybe<Order_By>;
  next_staff_id?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Staffing_Customer_Team_Slot_Stddev_Pop_Fields = {
  __typename?:'staffing_customer_team_slot_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
  next_candidate_allocation_id?:Maybe<Scalars['Float']>;
  next_candidate_id?:Maybe<Scalars['Float']>;
  next_staff_id?:Maybe<Scalars['Float']>;
  position_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Stddev_Pop_Order_By = {
  id?:InputMaybe<Order_By>;
  next_candidate_allocation_id?:InputMaybe<Order_By>;
  next_candidate_id?:InputMaybe<Order_By>;
  next_staff_id?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Staffing_Customer_Team_Slot_Stddev_Samp_Fields = {
  __typename?:'staffing_customer_team_slot_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
  next_candidate_allocation_id?:Maybe<Scalars['Float']>;
  next_candidate_id?:Maybe<Scalars['Float']>;
  next_staff_id?:Maybe<Scalars['Float']>;
  position_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Stddev_Samp_Order_By = {
  id?:InputMaybe<Order_By>;
  next_candidate_allocation_id?:InputMaybe<Order_By>;
  next_candidate_id?:InputMaybe<Order_By>;
  next_staff_id?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "staffing_customer_team_slot" */
export type Staffing_Customer_Team_Slot_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Staffing_Customer_Team_Slot_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staffing_Customer_Team_Slot_Stream_Cursor_Value_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  leaving_date?:InputMaybe<Scalars['timestamptz']>;
  next_candidate_allocation_id?:InputMaybe<Scalars['Int']>;
  next_candidate_id?:InputMaybe<Scalars['Int']>;
  next_candidate_start_date?:InputMaybe<Scalars['timestamptz']>;
  next_staff_id?:InputMaybe<Scalars['Int']>;
  next_staff_start_date?:InputMaybe<Scalars['timestamptz']>;
  position_id?:InputMaybe<Scalars['Int']>;
  staff_id?:InputMaybe<Scalars['Int']>;
  start_date?:InputMaybe<Scalars['timestamptz']>;
  status?:InputMaybe<Staffing_Customer_Team_Slot_Status_Enum>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Staffing_Customer_Team_Slot_Sum_Fields = {
  __typename?:'staffing_customer_team_slot_sum_fields';
  id?:Maybe<Scalars['Int']>;
  next_candidate_allocation_id?:Maybe<Scalars['Int']>;
  next_candidate_id?:Maybe<Scalars['Int']>;
  next_staff_id?:Maybe<Scalars['Int']>;
  position_id?:Maybe<Scalars['Int']>;
  staff_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Sum_Order_By = {
  id?:InputMaybe<Order_By>;
  next_candidate_allocation_id?:InputMaybe<Order_By>;
  next_candidate_id?:InputMaybe<Order_By>;
  next_staff_id?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
};

/** update columns of table "staffing.customer_team_slot" */
export enum Staffing_Customer_Team_Slot_Update_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LeavingDate = 'leaving_date',
  /** column name */
  NextCandidateAllocationId = 'next_candidate_allocation_id',
  /** column name */
  NextCandidateId = 'next_candidate_id',
  /** column name */
  NextCandidateStartDate = 'next_candidate_start_date',
  /** column name */
  NextStaffId = 'next_staff_id',
  /** column name */
  NextStaffStartDate = 'next_staff_start_date',
  /** column name */
  PositionId = 'position_id',
  /** column name */
  StaffId = 'staff_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Staffing_Customer_Team_Slot_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Staffing_Customer_Team_Slot_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Staffing_Customer_Team_Slot_Set_Input>;
  where:Staffing_Customer_Team_Slot_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Staffing_Customer_Team_Slot_Var_Pop_Fields = {
  __typename?:'staffing_customer_team_slot_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
  next_candidate_allocation_id?:Maybe<Scalars['Float']>;
  next_candidate_id?:Maybe<Scalars['Float']>;
  next_staff_id?:Maybe<Scalars['Float']>;
  position_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Var_Pop_Order_By = {
  id?:InputMaybe<Order_By>;
  next_candidate_allocation_id?:InputMaybe<Order_By>;
  next_candidate_id?:InputMaybe<Order_By>;
  next_staff_id?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Staffing_Customer_Team_Slot_Var_Samp_Fields = {
  __typename?:'staffing_customer_team_slot_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
  next_candidate_allocation_id?:Maybe<Scalars['Float']>;
  next_candidate_id?:Maybe<Scalars['Float']>;
  next_staff_id?:Maybe<Scalars['Float']>;
  position_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Var_Samp_Order_By = {
  id?:InputMaybe<Order_By>;
  next_candidate_allocation_id?:InputMaybe<Order_By>;
  next_candidate_id?:InputMaybe<Order_By>;
  next_staff_id?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Staffing_Customer_Team_Slot_Variance_Fields = {
  __typename?:'staffing_customer_team_slot_variance_fields';
  id?:Maybe<Scalars['Float']>;
  next_candidate_allocation_id?:Maybe<Scalars['Float']>;
  next_candidate_id?:Maybe<Scalars['Float']>;
  next_staff_id?:Maybe<Scalars['Float']>;
  position_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "staffing.customer_team_slot" */
export type Staffing_Customer_Team_Slot_Variance_Order_By = {
  id?:InputMaybe<Order_By>;
  next_candidate_allocation_id?:InputMaybe<Order_By>;
  next_candidate_id?:InputMaybe<Order_By>;
  next_staff_id?:InputMaybe<Order_By>;
  position_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Staffing_Customer_Team_Stddev_Fields = {
  __typename?:'staffing_customer_team_stddev_fields';
  customer_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "staffing.customer_team" */
export type Staffing_Customer_Team_Stddev_Order_By = {
  customer_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Staffing_Customer_Team_Stddev_Pop_Fields = {
  __typename?:'staffing_customer_team_stddev_pop_fields';
  customer_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "staffing.customer_team" */
export type Staffing_Customer_Team_Stddev_Pop_Order_By = {
  customer_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Staffing_Customer_Team_Stddev_Samp_Fields = {
  __typename?:'staffing_customer_team_stddev_samp_fields';
  customer_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "staffing.customer_team" */
export type Staffing_Customer_Team_Stddev_Samp_Order_By = {
  customer_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "staffing_customer_team" */
export type Staffing_Customer_Team_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Staffing_Customer_Team_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staffing_Customer_Team_Stream_Cursor_Value_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  customer_id?:InputMaybe<Scalars['Int']>;
  deleted_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  is_active?:InputMaybe<Scalars['Boolean']>;
  name?:InputMaybe<Scalars['String']>;
  notes?:InputMaybe<Scalars['String']>;
  ui_order?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Staffing_Customer_Team_Sum_Fields = {
  __typename?:'staffing_customer_team_sum_fields';
  customer_id?:Maybe<Scalars['Int']>;
  id?:Maybe<Scalars['Int']>;
  ui_order?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "staffing.customer_team" */
export type Staffing_Customer_Team_Sum_Order_By = {
  customer_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** columns and relationships of "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag = {
  __typename?:'staffing_customer_team_tag';
  created_at:Scalars['timestamptz'];
  /** An object relationship */
  customer_team:Staffing_Customer_Team;
  customer_team_id:Scalars['Int'];
  id:Scalars['Int'];
  name:Scalars['String'];
  updated_at:Scalars['timestamptz'];
};

/** aggregated selection of "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Aggregate = {
  __typename?:'staffing_customer_team_tag_aggregate';
  aggregate?:Maybe<Staffing_Customer_Team_Tag_Aggregate_Fields>;
  nodes:Array<Staffing_Customer_Team_Tag>;
};

export type Staffing_Customer_Team_Tag_Aggregate_Bool_Exp = {
  count?:InputMaybe<Staffing_Customer_Team_Tag_Aggregate_Bool_Exp_Count>;
};

export type Staffing_Customer_Team_Tag_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Staffing_Customer_Team_Tag_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Staffing_Customer_Team_Tag_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Aggregate_Fields = {
  __typename?:'staffing_customer_team_tag_aggregate_fields';
  avg?:Maybe<Staffing_Customer_Team_Tag_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Staffing_Customer_Team_Tag_Max_Fields>;
  min?:Maybe<Staffing_Customer_Team_Tag_Min_Fields>;
  stddev?:Maybe<Staffing_Customer_Team_Tag_Stddev_Fields>;
  stddev_pop?:Maybe<Staffing_Customer_Team_Tag_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Staffing_Customer_Team_Tag_Stddev_Samp_Fields>;
  sum?:Maybe<Staffing_Customer_Team_Tag_Sum_Fields>;
  var_pop?:Maybe<Staffing_Customer_Team_Tag_Var_Pop_Fields>;
  var_samp?:Maybe<Staffing_Customer_Team_Tag_Var_Samp_Fields>;
  variance?:Maybe<Staffing_Customer_Team_Tag_Variance_Fields>;
};


/** aggregate fields of "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Staffing_Customer_Team_Tag_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Aggregate_Order_By = {
  avg?:InputMaybe<Staffing_Customer_Team_Tag_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Staffing_Customer_Team_Tag_Max_Order_By>;
  min?:InputMaybe<Staffing_Customer_Team_Tag_Min_Order_By>;
  stddev?:InputMaybe<Staffing_Customer_Team_Tag_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Staffing_Customer_Team_Tag_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Staffing_Customer_Team_Tag_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Staffing_Customer_Team_Tag_Sum_Order_By>;
  var_pop?:InputMaybe<Staffing_Customer_Team_Tag_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Staffing_Customer_Team_Tag_Var_Samp_Order_By>;
  variance?:InputMaybe<Staffing_Customer_Team_Tag_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Arr_Rel_Insert_Input = {
  data:Array<Staffing_Customer_Team_Tag_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Staffing_Customer_Team_Tag_On_Conflict>;
};

/** aggregate avg on columns */
export type Staffing_Customer_Team_Tag_Avg_Fields = {
  __typename?:'staffing_customer_team_tag_avg_fields';
  customer_team_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Avg_Order_By = {
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "staffing.customer_team_tag". All fields are combined with a logical 'AND'. */
export type Staffing_Customer_Team_Tag_Bool_Exp = {
  _and?:InputMaybe<Array<Staffing_Customer_Team_Tag_Bool_Exp>>;
  _not?:InputMaybe<Staffing_Customer_Team_Tag_Bool_Exp>;
  _or?:InputMaybe<Array<Staffing_Customer_Team_Tag_Bool_Exp>>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  customer_team?:InputMaybe<Staffing_Customer_Team_Bool_Exp>;
  customer_team_id?:InputMaybe<Int_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  name?:InputMaybe<String_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "staffing.customer_team_tag" */
export enum Staffing_Customer_Team_Tag_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  CustomerTeamTagPkey = 'customer_team_tag_pkey'
}

/** input type for incrementing numeric columns in table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Inc_Input = {
  customer_team_id?:InputMaybe<Scalars['Int']>;
  id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Insert_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  customer_team?:InputMaybe<Staffing_Customer_Team_Obj_Rel_Insert_Input>;
  customer_team_id?:InputMaybe<Scalars['Int']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Staffing_Customer_Team_Tag_Max_Fields = {
  __typename?:'staffing_customer_team_tag_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  customer_team_id?:Maybe<Scalars['Int']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Max_Order_By = {
  created_at?:InputMaybe<Order_By>;
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Staffing_Customer_Team_Tag_Min_Fields = {
  __typename?:'staffing_customer_team_tag_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  customer_team_id?:Maybe<Scalars['Int']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Min_Order_By = {
  created_at?:InputMaybe<Order_By>;
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Mutation_Response = {
  __typename?:'staffing_customer_team_tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Staffing_Customer_Team_Tag>;
};

/** on_conflict condition type for table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_On_Conflict = {
  constraint:Staffing_Customer_Team_Tag_Constraint;
  update_columns?:Array<Staffing_Customer_Team_Tag_Update_Column>;
  where?:InputMaybe<Staffing_Customer_Team_Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "staffing.customer_team_tag". */
export type Staffing_Customer_Team_Tag_Order_By = {
  created_at?:InputMaybe<Order_By>;
  customer_team?:InputMaybe<Staffing_Customer_Team_Order_By>;
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: staffing.customer_team_tag */
export type Staffing_Customer_Team_Tag_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "staffing.customer_team_tag" */
export enum Staffing_Customer_Team_Tag_Select_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerTeamId = 'customer_team_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Set_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  customer_team_id?:InputMaybe<Scalars['Int']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Staffing_Customer_Team_Tag_Stddev_Fields = {
  __typename?:'staffing_customer_team_tag_stddev_fields';
  customer_team_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Stddev_Order_By = {
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Staffing_Customer_Team_Tag_Stddev_Pop_Fields = {
  __typename?:'staffing_customer_team_tag_stddev_pop_fields';
  customer_team_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Stddev_Pop_Order_By = {
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Staffing_Customer_Team_Tag_Stddev_Samp_Fields = {
  __typename?:'staffing_customer_team_tag_stddev_samp_fields';
  customer_team_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Stddev_Samp_Order_By = {
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "staffing_customer_team_tag" */
export type Staffing_Customer_Team_Tag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Staffing_Customer_Team_Tag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staffing_Customer_Team_Tag_Stream_Cursor_Value_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  customer_team_id?:InputMaybe<Scalars['Int']>;
  id?:InputMaybe<Scalars['Int']>;
  name?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Staffing_Customer_Team_Tag_Sum_Fields = {
  __typename?:'staffing_customer_team_tag_sum_fields';
  customer_team_id?:Maybe<Scalars['Int']>;
  id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Sum_Order_By = {
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
};

/** update columns of table "staffing.customer_team_tag" */
export enum Staffing_Customer_Team_Tag_Update_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerTeamId = 'customer_team_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Staffing_Customer_Team_Tag_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Staffing_Customer_Team_Tag_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Staffing_Customer_Team_Tag_Set_Input>;
  where:Staffing_Customer_Team_Tag_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Staffing_Customer_Team_Tag_Var_Pop_Fields = {
  __typename?:'staffing_customer_team_tag_var_pop_fields';
  customer_team_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Var_Pop_Order_By = {
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Staffing_Customer_Team_Tag_Var_Samp_Fields = {
  __typename?:'staffing_customer_team_tag_var_samp_fields';
  customer_team_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Var_Samp_Order_By = {
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Staffing_Customer_Team_Tag_Variance_Fields = {
  __typename?:'staffing_customer_team_tag_variance_fields';
  customer_team_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "staffing.customer_team_tag" */
export type Staffing_Customer_Team_Tag_Variance_Order_By = {
  customer_team_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
};

/** update columns of table "staffing.customer_team" */
export enum Staffing_Customer_Team_Update_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  UiOrder = 'ui_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Staffing_Customer_Team_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Staffing_Customer_Team_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Staffing_Customer_Team_Set_Input>;
  where:Staffing_Customer_Team_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Staffing_Customer_Team_Var_Pop_Fields = {
  __typename?:'staffing_customer_team_var_pop_fields';
  customer_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "staffing.customer_team" */
export type Staffing_Customer_Team_Var_Pop_Order_By = {
  customer_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Staffing_Customer_Team_Var_Samp_Fields = {
  __typename?:'staffing_customer_team_var_samp_fields';
  customer_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "staffing.customer_team" */
export type Staffing_Customer_Team_Var_Samp_Order_By = {
  customer_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Staffing_Customer_Team_Variance_Fields = {
  __typename?:'staffing_customer_team_variance_fields';
  customer_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  ui_order?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "staffing.customer_team" */
export type Staffing_Customer_Team_Variance_Order_By = {
  customer_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  ui_order?:InputMaybe<Order_By>;
};

/** columns and relationships of "staffing.staff" */
export type Staffing_Staff = {
  __typename?:'staffing_staff';
  avatar_url?:Maybe<Scalars['String']>;
  /** An object relationship */
  candidate?:Maybe<Recruiting_Candidate>;
  city_id?:Maybe<Scalars['Int']>;
  country_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  customer_team_slots:Array<Staffing_Customer_Team_Slot>;
  /** An aggregate relationship */
  customer_team_slots_aggregate:Staffing_Customer_Team_Slot_Aggregate;
  date_of_birth?:Maybe<Scalars['timestamptz']>;
  first_name_i18n?:Maybe<Scalars['String']>;
  hire_date?:Maybe<Scalars['timestamptz']>;
  id:Scalars['Int'];
  is_active?:Maybe<Scalars['Boolean']>;
  last_name_i18n?:Maybe<Scalars['String']>;
  leaving_date?:Maybe<Scalars['timestamptz']>;
  linkedin_profile_url?:Maybe<Scalars['String']>;
  middle_name_i18n?:Maybe<Scalars['String']>;
  /** An array relationship */
  next_staff_slots:Array<Staffing_Customer_Team_Slot>;
  /** An aggregate relationship */
  next_staff_slots_aggregate:Staffing_Customer_Team_Slot_Aggregate;
  phone?:Maybe<Scalars['String']>;
  /** An array relationship */
  recommended_candidates:Array<Recruiting_Candidate>;
  /** An aggregate relationship */
  recommended_candidates_aggregate:Recruiting_Candidate_Aggregate;
  /** An object relationship */
  role?:Maybe<Staffing_Staff_Role>;
  /** An array relationship */
  skill_tags:Array<Staffing_Staff_Skill_Tag>;
  /** An aggregate relationship */
  skill_tags_aggregate:Staffing_Staff_Skill_Tag_Aggregate;
  staff_role_id?:Maybe<Scalars['Int']>;
  status?:Maybe<Staffing_Staff_Status_Enum>;
  status_date?:Maybe<Scalars['timestamptz']>;
  termination_date?:Maybe<Scalars['timestamptz']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user:Common_User;
  user_id:Scalars['Int'];
};


/** columns and relationships of "staffing.staff" */
export type Staffing_StaffCustomer_Team_SlotsArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
};


/** columns and relationships of "staffing.staff" */
export type Staffing_StaffCustomer_Team_Slots_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
};


/** columns and relationships of "staffing.staff" */
export type Staffing_StaffNext_Staff_SlotsArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
};


/** columns and relationships of "staffing.staff" */
export type Staffing_StaffNext_Staff_Slots_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
};


/** columns and relationships of "staffing.staff" */
export type Staffing_StaffRecommended_CandidatesArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
};


/** columns and relationships of "staffing.staff" */
export type Staffing_StaffRecommended_Candidates_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
};


/** columns and relationships of "staffing.staff" */
export type Staffing_StaffSkill_TagsArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Skill_Tag_Bool_Exp>;
};


/** columns and relationships of "staffing.staff" */
export type Staffing_StaffSkill_Tags_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Skill_Tag_Bool_Exp>;
};

/** aggregated selection of "staffing.staff" */
export type Staffing_Staff_Aggregate = {
  __typename?:'staffing_staff_aggregate';
  aggregate?:Maybe<Staffing_Staff_Aggregate_Fields>;
  nodes:Array<Staffing_Staff>;
};

export type Staffing_Staff_Aggregate_Bool_Exp = {
  bool_and?:InputMaybe<Staffing_Staff_Aggregate_Bool_Exp_Bool_And>;
  bool_or?:InputMaybe<Staffing_Staff_Aggregate_Bool_Exp_Bool_Or>;
  count?:InputMaybe<Staffing_Staff_Aggregate_Bool_Exp_Count>;
};

export type Staffing_Staff_Aggregate_Bool_Exp_Bool_And = {
  arguments:Staffing_Staff_Select_Column_Staffing_Staff_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Staffing_Staff_Bool_Exp>;
  predicate:Boolean_Comparison_Exp;
};

export type Staffing_Staff_Aggregate_Bool_Exp_Bool_Or = {
  arguments:Staffing_Staff_Select_Column_Staffing_Staff_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Staffing_Staff_Bool_Exp>;
  predicate:Boolean_Comparison_Exp;
};

export type Staffing_Staff_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Staffing_Staff_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Staffing_Staff_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "staffing.staff" */
export type Staffing_Staff_Aggregate_Fields = {
  __typename?:'staffing_staff_aggregate_fields';
  avg?:Maybe<Staffing_Staff_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Staffing_Staff_Max_Fields>;
  min?:Maybe<Staffing_Staff_Min_Fields>;
  stddev?:Maybe<Staffing_Staff_Stddev_Fields>;
  stddev_pop?:Maybe<Staffing_Staff_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Staffing_Staff_Stddev_Samp_Fields>;
  sum?:Maybe<Staffing_Staff_Sum_Fields>;
  var_pop?:Maybe<Staffing_Staff_Var_Pop_Fields>;
  var_samp?:Maybe<Staffing_Staff_Var_Samp_Fields>;
  variance?:Maybe<Staffing_Staff_Variance_Fields>;
};


/** aggregate fields of "staffing.staff" */
export type Staffing_Staff_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Staffing_Staff_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "staffing.staff" */
export type Staffing_Staff_Aggregate_Order_By = {
  avg?:InputMaybe<Staffing_Staff_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Staffing_Staff_Max_Order_By>;
  min?:InputMaybe<Staffing_Staff_Min_Order_By>;
  stddev?:InputMaybe<Staffing_Staff_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Staffing_Staff_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Staffing_Staff_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Staffing_Staff_Sum_Order_By>;
  var_pop?:InputMaybe<Staffing_Staff_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Staffing_Staff_Var_Samp_Order_By>;
  variance?:InputMaybe<Staffing_Staff_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "staffing.staff" */
export type Staffing_Staff_Arr_Rel_Insert_Input = {
  data:Array<Staffing_Staff_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Staffing_Staff_On_Conflict>;
};

/** aggregate avg on columns */
export type Staffing_Staff_Avg_Fields = {
  __typename?:'staffing_staff_avg_fields';
  city_id?:Maybe<Scalars['Float']>;
  country_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "staffing.staff" */
export type Staffing_Staff_Avg_Order_By = {
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  staff_role_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "staffing.staff". All fields are combined with a logical 'AND'. */
export type Staffing_Staff_Bool_Exp = {
  _and?:InputMaybe<Array<Staffing_Staff_Bool_Exp>>;
  _not?:InputMaybe<Staffing_Staff_Bool_Exp>;
  _or?:InputMaybe<Array<Staffing_Staff_Bool_Exp>>;
  avatar_url?:InputMaybe<String_Comparison_Exp>;
  candidate?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
  city_id?:InputMaybe<Int_Comparison_Exp>;
  country_id?:InputMaybe<Int_Comparison_Exp>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  customer_team_slots?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
  customer_team_slots_aggregate?:InputMaybe<Staffing_Customer_Team_Slot_Aggregate_Bool_Exp>;
  date_of_birth?:InputMaybe<Timestamptz_Comparison_Exp>;
  first_name_i18n?:InputMaybe<String_Comparison_Exp>;
  hire_date?:InputMaybe<Timestamptz_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  is_active?:InputMaybe<Boolean_Comparison_Exp>;
  last_name_i18n?:InputMaybe<String_Comparison_Exp>;
  leaving_date?:InputMaybe<Timestamptz_Comparison_Exp>;
  linkedin_profile_url?:InputMaybe<String_Comparison_Exp>;
  middle_name_i18n?:InputMaybe<String_Comparison_Exp>;
  next_staff_slots?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
  next_staff_slots_aggregate?:InputMaybe<Staffing_Customer_Team_Slot_Aggregate_Bool_Exp>;
  phone?:InputMaybe<String_Comparison_Exp>;
  recommended_candidates?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
  recommended_candidates_aggregate?:InputMaybe<Recruiting_Candidate_Aggregate_Bool_Exp>;
  role?:InputMaybe<Staffing_Staff_Role_Bool_Exp>;
  skill_tags?:InputMaybe<Staffing_Staff_Skill_Tag_Bool_Exp>;
  skill_tags_aggregate?:InputMaybe<Staffing_Staff_Skill_Tag_Aggregate_Bool_Exp>;
  staff_role_id?:InputMaybe<Int_Comparison_Exp>;
  status?:InputMaybe<Staffing_Staff_Status_Enum_Comparison_Exp>;
  status_date?:InputMaybe<Timestamptz_Comparison_Exp>;
  termination_date?:InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  user?:InputMaybe<Common_User_Bool_Exp>;
  user_id?:InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "staffing.staff" */
export enum Staffing_Staff_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  StaffPkey = 'staff_pkey',
  /** unique or primary key constraint on columns "user_id" */
  StaffUserIdKey = 'staff_user_id_key'
}

/** input type for incrementing numeric columns in table "staffing.staff" */
export type Staffing_Staff_Inc_Input = {
  city_id?:InputMaybe<Scalars['Int']>;
  country_id?:InputMaybe<Scalars['Int']>;
  id?:InputMaybe<Scalars['Int']>;
  staff_role_id?:InputMaybe<Scalars['Int']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "staffing.staff" */
export type Staffing_Staff_Insert_Input = {
  avatar_url?:InputMaybe<Scalars['String']>;
  candidate?:InputMaybe<Recruiting_Candidate_Obj_Rel_Insert_Input>;
  city_id?:InputMaybe<Scalars['Int']>;
  country_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  customer_team_slots?:InputMaybe<Staffing_Customer_Team_Slot_Arr_Rel_Insert_Input>;
  date_of_birth?:InputMaybe<Scalars['timestamptz']>;
  first_name_i18n?:InputMaybe<Scalars['String']>;
  hire_date?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  is_active?:InputMaybe<Scalars['Boolean']>;
  last_name_i18n?:InputMaybe<Scalars['String']>;
  leaving_date?:InputMaybe<Scalars['timestamptz']>;
  linkedin_profile_url?:InputMaybe<Scalars['String']>;
  middle_name_i18n?:InputMaybe<Scalars['String']>;
  next_staff_slots?:InputMaybe<Staffing_Customer_Team_Slot_Arr_Rel_Insert_Input>;
  phone?:InputMaybe<Scalars['String']>;
  recommended_candidates?:InputMaybe<Recruiting_Candidate_Arr_Rel_Insert_Input>;
  role?:InputMaybe<Staffing_Staff_Role_Obj_Rel_Insert_Input>;
  skill_tags?:InputMaybe<Staffing_Staff_Skill_Tag_Arr_Rel_Insert_Input>;
  staff_role_id?:InputMaybe<Scalars['Int']>;
  status?:InputMaybe<Staffing_Staff_Status_Enum>;
  status_date?:InputMaybe<Scalars['timestamptz']>;
  termination_date?:InputMaybe<Scalars['timestamptz']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user?:InputMaybe<Common_User_Obj_Rel_Insert_Input>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Staffing_Staff_Max_Fields = {
  __typename?:'staffing_staff_max_fields';
  avatar_url?:Maybe<Scalars['String']>;
  city_id?:Maybe<Scalars['Int']>;
  country_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  date_of_birth?:Maybe<Scalars['timestamptz']>;
  first_name_i18n?:Maybe<Scalars['String']>;
  hire_date?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  last_name_i18n?:Maybe<Scalars['String']>;
  leaving_date?:Maybe<Scalars['timestamptz']>;
  linkedin_profile_url?:Maybe<Scalars['String']>;
  middle_name_i18n?:Maybe<Scalars['String']>;
  phone?:Maybe<Scalars['String']>;
  staff_role_id?:Maybe<Scalars['Int']>;
  status_date?:Maybe<Scalars['timestamptz']>;
  termination_date?:Maybe<Scalars['timestamptz']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "staffing.staff" */
export type Staffing_Staff_Max_Order_By = {
  avatar_url?:InputMaybe<Order_By>;
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  date_of_birth?:InputMaybe<Order_By>;
  first_name_i18n?:InputMaybe<Order_By>;
  hire_date?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  last_name_i18n?:InputMaybe<Order_By>;
  leaving_date?:InputMaybe<Order_By>;
  linkedin_profile_url?:InputMaybe<Order_By>;
  middle_name_i18n?:InputMaybe<Order_By>;
  phone?:InputMaybe<Order_By>;
  staff_role_id?:InputMaybe<Order_By>;
  status_date?:InputMaybe<Order_By>;
  termination_date?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Staffing_Staff_Min_Fields = {
  __typename?:'staffing_staff_min_fields';
  avatar_url?:Maybe<Scalars['String']>;
  city_id?:Maybe<Scalars['Int']>;
  country_id?:Maybe<Scalars['Int']>;
  created_at?:Maybe<Scalars['timestamptz']>;
  date_of_birth?:Maybe<Scalars['timestamptz']>;
  first_name_i18n?:Maybe<Scalars['String']>;
  hire_date?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  last_name_i18n?:Maybe<Scalars['String']>;
  leaving_date?:Maybe<Scalars['timestamptz']>;
  linkedin_profile_url?:Maybe<Scalars['String']>;
  middle_name_i18n?:Maybe<Scalars['String']>;
  phone?:Maybe<Scalars['String']>;
  staff_role_id?:Maybe<Scalars['Int']>;
  status_date?:Maybe<Scalars['timestamptz']>;
  termination_date?:Maybe<Scalars['timestamptz']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "staffing.staff" */
export type Staffing_Staff_Min_Order_By = {
  avatar_url?:InputMaybe<Order_By>;
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  date_of_birth?:InputMaybe<Order_By>;
  first_name_i18n?:InputMaybe<Order_By>;
  hire_date?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  last_name_i18n?:InputMaybe<Order_By>;
  leaving_date?:InputMaybe<Order_By>;
  linkedin_profile_url?:InputMaybe<Order_By>;
  middle_name_i18n?:InputMaybe<Order_By>;
  phone?:InputMaybe<Order_By>;
  staff_role_id?:InputMaybe<Order_By>;
  status_date?:InputMaybe<Order_By>;
  termination_date?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "staffing.staff" */
export type Staffing_Staff_Mutation_Response = {
  __typename?:'staffing_staff_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Staffing_Staff>;
};

/** input type for inserting object relation for remote table "staffing.staff" */
export type Staffing_Staff_Obj_Rel_Insert_Input = {
  data:Staffing_Staff_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Staffing_Staff_On_Conflict>;
};

/** on_conflict condition type for table "staffing.staff" */
export type Staffing_Staff_On_Conflict = {
  constraint:Staffing_Staff_Constraint;
  update_columns?:Array<Staffing_Staff_Update_Column>;
  where?:InputMaybe<Staffing_Staff_Bool_Exp>;
};

/** Ordering options when selecting data from "staffing.staff". */
export type Staffing_Staff_Order_By = {
  avatar_url?:InputMaybe<Order_By>;
  candidate?:InputMaybe<Recruiting_Candidate_Order_By>;
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  created_at?:InputMaybe<Order_By>;
  customer_team_slots_aggregate?:InputMaybe<Staffing_Customer_Team_Slot_Aggregate_Order_By>;
  date_of_birth?:InputMaybe<Order_By>;
  first_name_i18n?:InputMaybe<Order_By>;
  hire_date?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  is_active?:InputMaybe<Order_By>;
  last_name_i18n?:InputMaybe<Order_By>;
  leaving_date?:InputMaybe<Order_By>;
  linkedin_profile_url?:InputMaybe<Order_By>;
  middle_name_i18n?:InputMaybe<Order_By>;
  next_staff_slots_aggregate?:InputMaybe<Staffing_Customer_Team_Slot_Aggregate_Order_By>;
  phone?:InputMaybe<Order_By>;
  recommended_candidates_aggregate?:InputMaybe<Recruiting_Candidate_Aggregate_Order_By>;
  role?:InputMaybe<Staffing_Staff_Role_Order_By>;
  skill_tags_aggregate?:InputMaybe<Staffing_Staff_Skill_Tag_Aggregate_Order_By>;
  staff_role_id?:InputMaybe<Order_By>;
  status?:InputMaybe<Order_By>;
  status_date?:InputMaybe<Order_By>;
  termination_date?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
  user?:InputMaybe<Common_User_Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** primary key columns input for table: staffing.staff */
export type Staffing_Staff_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** columns and relationships of "staffing.staff_role" */
export type Staffing_Staff_Role = {
  __typename?:'staffing_staff_role';
  created_at:Scalars['timestamptz'];
  id:Scalars['Int'];
  is_billable:Scalars['Boolean'];
  name:Scalars['String'];
  /** An array relationship */
  staffs:Array<Staffing_Staff>;
  /** An aggregate relationship */
  staffs_aggregate:Staffing_Staff_Aggregate;
  updated_at?:Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "staffing.staff_role" */
export type Staffing_Staff_RoleStaffsArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Bool_Exp>;
};


/** columns and relationships of "staffing.staff_role" */
export type Staffing_Staff_RoleStaffs_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Bool_Exp>;
};

/** aggregated selection of "staffing.staff_role" */
export type Staffing_Staff_Role_Aggregate = {
  __typename?:'staffing_staff_role_aggregate';
  aggregate?:Maybe<Staffing_Staff_Role_Aggregate_Fields>;
  nodes:Array<Staffing_Staff_Role>;
};

/** aggregate fields of "staffing.staff_role" */
export type Staffing_Staff_Role_Aggregate_Fields = {
  __typename?:'staffing_staff_role_aggregate_fields';
  avg?:Maybe<Staffing_Staff_Role_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Staffing_Staff_Role_Max_Fields>;
  min?:Maybe<Staffing_Staff_Role_Min_Fields>;
  stddev?:Maybe<Staffing_Staff_Role_Stddev_Fields>;
  stddev_pop?:Maybe<Staffing_Staff_Role_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Staffing_Staff_Role_Stddev_Samp_Fields>;
  sum?:Maybe<Staffing_Staff_Role_Sum_Fields>;
  var_pop?:Maybe<Staffing_Staff_Role_Var_Pop_Fields>;
  var_samp?:Maybe<Staffing_Staff_Role_Var_Samp_Fields>;
  variance?:Maybe<Staffing_Staff_Role_Variance_Fields>;
};


/** aggregate fields of "staffing.staff_role" */
export type Staffing_Staff_Role_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Staffing_Staff_Role_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Staffing_Staff_Role_Avg_Fields = {
  __typename?:'staffing_staff_role_avg_fields';
  id?:Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "staffing.staff_role". All fields are combined with a logical 'AND'. */
export type Staffing_Staff_Role_Bool_Exp = {
  _and?:InputMaybe<Array<Staffing_Staff_Role_Bool_Exp>>;
  _not?:InputMaybe<Staffing_Staff_Role_Bool_Exp>;
  _or?:InputMaybe<Array<Staffing_Staff_Role_Bool_Exp>>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  id?:InputMaybe<Int_Comparison_Exp>;
  is_billable?:InputMaybe<Boolean_Comparison_Exp>;
  name?:InputMaybe<String_Comparison_Exp>;
  staffs?:InputMaybe<Staffing_Staff_Bool_Exp>;
  staffs_aggregate?:InputMaybe<Staffing_Staff_Aggregate_Bool_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "staffing.staff_role" */
export enum Staffing_Staff_Role_Constraint 
{
  /** unique or primary key constraint on columns "id" */
  StaffRolePkey = 'staff_role_pkey'
}

/** input type for incrementing numeric columns in table "staffing.staff_role" */
export type Staffing_Staff_Role_Inc_Input = {
  id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "staffing.staff_role" */
export type Staffing_Staff_Role_Insert_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  is_billable?:InputMaybe<Scalars['Boolean']>;
  name?:InputMaybe<Scalars['String']>;
  staffs?:InputMaybe<Staffing_Staff_Arr_Rel_Insert_Input>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Staffing_Staff_Role_Max_Fields = {
  __typename?:'staffing_staff_role_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Staffing_Staff_Role_Min_Fields = {
  __typename?:'staffing_staff_role_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  id?:Maybe<Scalars['Int']>;
  name?:Maybe<Scalars['String']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "staffing.staff_role" */
export type Staffing_Staff_Role_Mutation_Response = {
  __typename?:'staffing_staff_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Staffing_Staff_Role>;
};

/** input type for inserting object relation for remote table "staffing.staff_role" */
export type Staffing_Staff_Role_Obj_Rel_Insert_Input = {
  data:Staffing_Staff_Role_Insert_Input;
  /** upsert condition */
  on_conflict?:InputMaybe<Staffing_Staff_Role_On_Conflict>;
};

/** on_conflict condition type for table "staffing.staff_role" */
export type Staffing_Staff_Role_On_Conflict = {
  constraint:Staffing_Staff_Role_Constraint;
  update_columns?:Array<Staffing_Staff_Role_Update_Column>;
  where?:InputMaybe<Staffing_Staff_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "staffing.staff_role". */
export type Staffing_Staff_Role_Order_By = {
  created_at?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  is_billable?:InputMaybe<Order_By>;
  name?:InputMaybe<Order_By>;
  staffs_aggregate?:InputMaybe<Staffing_Staff_Aggregate_Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: staffing.staff_role */
export type Staffing_Staff_Role_Pk_Columns_Input = {
  id:Scalars['Int'];
};

/** select columns of table "staffing.staff_role" */
export enum Staffing_Staff_Role_Select_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsBillable = 'is_billable',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "staffing.staff_role" */
export type Staffing_Staff_Role_Set_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  is_billable?:InputMaybe<Scalars['Boolean']>;
  name?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Staffing_Staff_Role_Stddev_Fields = {
  __typename?:'staffing_staff_role_stddev_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Staffing_Staff_Role_Stddev_Pop_Fields = {
  __typename?:'staffing_staff_role_stddev_pop_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Staffing_Staff_Role_Stddev_Samp_Fields = {
  __typename?:'staffing_staff_role_stddev_samp_fields';
  id?:Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "staffing_staff_role" */
export type Staffing_Staff_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Staffing_Staff_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staffing_Staff_Role_Stream_Cursor_Value_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  is_billable?:InputMaybe<Scalars['Boolean']>;
  name?:InputMaybe<Scalars['String']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Staffing_Staff_Role_Sum_Fields = {
  __typename?:'staffing_staff_role_sum_fields';
  id?:Maybe<Scalars['Int']>;
};

/** update columns of table "staffing.staff_role" */
export enum Staffing_Staff_Role_Update_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsBillable = 'is_billable',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Staffing_Staff_Role_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Staffing_Staff_Role_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Staffing_Staff_Role_Set_Input>;
  where:Staffing_Staff_Role_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Staffing_Staff_Role_Var_Pop_Fields = {
  __typename?:'staffing_staff_role_var_pop_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Staffing_Staff_Role_Var_Samp_Fields = {
  __typename?:'staffing_staff_role_var_samp_fields';
  id?:Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Staffing_Staff_Role_Variance_Fields = {
  __typename?:'staffing_staff_role_variance_fields';
  id?:Maybe<Scalars['Float']>;
};

/** select columns of table "staffing.staff" */
export enum Staffing_Staff_Select_Column 
{
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CityId = 'city_id',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  FirstNameI18n = 'first_name_i18n',
  /** column name */
  HireDate = 'hire_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastNameI18n = 'last_name_i18n',
  /** column name */
  LeavingDate = 'leaving_date',
  /** column name */
  LinkedinProfileUrl = 'linkedin_profile_url',
  /** column name */
  MiddleNameI18n = 'middle_name_i18n',
  /** column name */
  Phone = 'phone',
  /** column name */
  StaffRoleId = 'staff_role_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDate = 'status_date',
  /** column name */
  TerminationDate = 'termination_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "staffing_staff_aggregate_bool_exp_bool_and_arguments_columns" columns of table "staffing.staff" */
export enum Staffing_Staff_Select_Column_Staffing_Staff_Aggregate_Bool_Exp_Bool_And_Arguments_Columns 
{
  /** column name */
  IsActive = 'is_active'
}

/** select "staffing_staff_aggregate_bool_exp_bool_or_arguments_columns" columns of table "staffing.staff" */
export enum Staffing_Staff_Select_Column_Staffing_Staff_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns 
{
  /** column name */
  IsActive = 'is_active'
}

/** input type for updating data in table "staffing.staff" */
export type Staffing_Staff_Set_Input = {
  avatar_url?:InputMaybe<Scalars['String']>;
  city_id?:InputMaybe<Scalars['Int']>;
  country_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  date_of_birth?:InputMaybe<Scalars['timestamptz']>;
  first_name_i18n?:InputMaybe<Scalars['String']>;
  hire_date?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  is_active?:InputMaybe<Scalars['Boolean']>;
  last_name_i18n?:InputMaybe<Scalars['String']>;
  leaving_date?:InputMaybe<Scalars['timestamptz']>;
  linkedin_profile_url?:InputMaybe<Scalars['String']>;
  middle_name_i18n?:InputMaybe<Scalars['String']>;
  phone?:InputMaybe<Scalars['String']>;
  staff_role_id?:InputMaybe<Scalars['Int']>;
  status?:InputMaybe<Staffing_Staff_Status_Enum>;
  status_date?:InputMaybe<Scalars['timestamptz']>;
  termination_date?:InputMaybe<Scalars['timestamptz']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag = {
  __typename?:'staffing_staff_skill_tag';
  created_at:Scalars['timestamptz'];
  /** An object relationship */
  skill_tag:Common_Skill_Tag;
  skill_tag_id:Scalars['Int'];
  /** An object relationship */
  staff:Staffing_Staff;
  staff_id:Scalars['Int'];
  updated_at:Scalars['timestamptz'];
};

/** aggregated selection of "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Aggregate = {
  __typename?:'staffing_staff_skill_tag_aggregate';
  aggregate?:Maybe<Staffing_Staff_Skill_Tag_Aggregate_Fields>;
  nodes:Array<Staffing_Staff_Skill_Tag>;
};

export type Staffing_Staff_Skill_Tag_Aggregate_Bool_Exp = {
  count?:InputMaybe<Staffing_Staff_Skill_Tag_Aggregate_Bool_Exp_Count>;
};

export type Staffing_Staff_Skill_Tag_Aggregate_Bool_Exp_Count = {
  arguments?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
  filter?:InputMaybe<Staffing_Staff_Skill_Tag_Bool_Exp>;
  predicate:Int_Comparison_Exp;
};

/** aggregate fields of "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Aggregate_Fields = {
  __typename?:'staffing_staff_skill_tag_aggregate_fields';
  avg?:Maybe<Staffing_Staff_Skill_Tag_Avg_Fields>;
  count:Scalars['Int'];
  max?:Maybe<Staffing_Staff_Skill_Tag_Max_Fields>;
  min?:Maybe<Staffing_Staff_Skill_Tag_Min_Fields>;
  stddev?:Maybe<Staffing_Staff_Skill_Tag_Stddev_Fields>;
  stddev_pop?:Maybe<Staffing_Staff_Skill_Tag_Stddev_Pop_Fields>;
  stddev_samp?:Maybe<Staffing_Staff_Skill_Tag_Stddev_Samp_Fields>;
  sum?:Maybe<Staffing_Staff_Skill_Tag_Sum_Fields>;
  var_pop?:Maybe<Staffing_Staff_Skill_Tag_Var_Pop_Fields>;
  var_samp?:Maybe<Staffing_Staff_Skill_Tag_Var_Samp_Fields>;
  variance?:Maybe<Staffing_Staff_Skill_Tag_Variance_Fields>;
};


/** aggregate fields of "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Aggregate_Order_By = {
  avg?:InputMaybe<Staffing_Staff_Skill_Tag_Avg_Order_By>;
  count?:InputMaybe<Order_By>;
  max?:InputMaybe<Staffing_Staff_Skill_Tag_Max_Order_By>;
  min?:InputMaybe<Staffing_Staff_Skill_Tag_Min_Order_By>;
  stddev?:InputMaybe<Staffing_Staff_Skill_Tag_Stddev_Order_By>;
  stddev_pop?:InputMaybe<Staffing_Staff_Skill_Tag_Stddev_Pop_Order_By>;
  stddev_samp?:InputMaybe<Staffing_Staff_Skill_Tag_Stddev_Samp_Order_By>;
  sum?:InputMaybe<Staffing_Staff_Skill_Tag_Sum_Order_By>;
  var_pop?:InputMaybe<Staffing_Staff_Skill_Tag_Var_Pop_Order_By>;
  var_samp?:InputMaybe<Staffing_Staff_Skill_Tag_Var_Samp_Order_By>;
  variance?:InputMaybe<Staffing_Staff_Skill_Tag_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Arr_Rel_Insert_Input = {
  data:Array<Staffing_Staff_Skill_Tag_Insert_Input>;
  /** upsert condition */
  on_conflict?:InputMaybe<Staffing_Staff_Skill_Tag_On_Conflict>;
};

/** aggregate avg on columns */
export type Staffing_Staff_Skill_Tag_Avg_Fields = {
  __typename?:'staffing_staff_skill_tag_avg_fields';
  skill_tag_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Avg_Order_By = {
  skill_tag_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "staffing.staff_skill_tag". All fields are combined with a logical 'AND'. */
export type Staffing_Staff_Skill_Tag_Bool_Exp = {
  _and?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Bool_Exp>>;
  _not?:InputMaybe<Staffing_Staff_Skill_Tag_Bool_Exp>;
  _or?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Bool_Exp>>;
  created_at?:InputMaybe<Timestamptz_Comparison_Exp>;
  skill_tag?:InputMaybe<Common_Skill_Tag_Bool_Exp>;
  skill_tag_id?:InputMaybe<Int_Comparison_Exp>;
  staff?:InputMaybe<Staffing_Staff_Bool_Exp>;
  staff_id?:InputMaybe<Int_Comparison_Exp>;
  updated_at?:InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "staffing.staff_skill_tag" */
export enum Staffing_Staff_Skill_Tag_Constraint 
{
  /** unique or primary key constraint on columns "staff_id", "skill_tag_id" */
  StaffSkillTagPkey = 'staff_skill_tag_pkey'
}

/** input type for incrementing numeric columns in table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Inc_Input = {
  skill_tag_id?:InputMaybe<Scalars['Int']>;
  staff_id?:InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Insert_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  skill_tag?:InputMaybe<Common_Skill_Tag_Obj_Rel_Insert_Input>;
  skill_tag_id?:InputMaybe<Scalars['Int']>;
  staff?:InputMaybe<Staffing_Staff_Obj_Rel_Insert_Input>;
  staff_id?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Staffing_Staff_Skill_Tag_Max_Fields = {
  __typename?:'staffing_staff_skill_tag_max_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  skill_tag_id?:Maybe<Scalars['Int']>;
  staff_id?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Max_Order_By = {
  created_at?:InputMaybe<Order_By>;
  skill_tag_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Staffing_Staff_Skill_Tag_Min_Fields = {
  __typename?:'staffing_staff_skill_tag_min_fields';
  created_at?:Maybe<Scalars['timestamptz']>;
  skill_tag_id?:Maybe<Scalars['Int']>;
  staff_id?:Maybe<Scalars['Int']>;
  updated_at?:Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Min_Order_By = {
  created_at?:InputMaybe<Order_By>;
  skill_tag_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** response of any mutation on the table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Mutation_Response = {
  __typename?:'staffing_staff_skill_tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Staffing_Staff_Skill_Tag>;
};

/** on_conflict condition type for table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_On_Conflict = {
  constraint:Staffing_Staff_Skill_Tag_Constraint;
  update_columns?:Array<Staffing_Staff_Skill_Tag_Update_Column>;
  where?:InputMaybe<Staffing_Staff_Skill_Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "staffing.staff_skill_tag". */
export type Staffing_Staff_Skill_Tag_Order_By = {
  created_at?:InputMaybe<Order_By>;
  skill_tag?:InputMaybe<Common_Skill_Tag_Order_By>;
  skill_tag_id?:InputMaybe<Order_By>;
  staff?:InputMaybe<Staffing_Staff_Order_By>;
  staff_id?:InputMaybe<Order_By>;
  updated_at?:InputMaybe<Order_By>;
};

/** primary key columns input for table: staffing.staff_skill_tag */
export type Staffing_Staff_Skill_Tag_Pk_Columns_Input = {
  skill_tag_id:Scalars['Int'];
  staff_id:Scalars['Int'];
};

/** select columns of table "staffing.staff_skill_tag" */
export enum Staffing_Staff_Skill_Tag_Select_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SkillTagId = 'skill_tag_id',
  /** column name */
  StaffId = 'staff_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Set_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  skill_tag_id?:InputMaybe<Scalars['Int']>;
  staff_id?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Staffing_Staff_Skill_Tag_Stddev_Fields = {
  __typename?:'staffing_staff_skill_tag_stddev_fields';
  skill_tag_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Stddev_Order_By = {
  skill_tag_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Staffing_Staff_Skill_Tag_Stddev_Pop_Fields = {
  __typename?:'staffing_staff_skill_tag_stddev_pop_fields';
  skill_tag_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Stddev_Pop_Order_By = {
  skill_tag_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Staffing_Staff_Skill_Tag_Stddev_Samp_Fields = {
  __typename?:'staffing_staff_skill_tag_stddev_samp_fields';
  skill_tag_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Stddev_Samp_Order_By = {
  skill_tag_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "staffing_staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Staffing_Staff_Skill_Tag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staffing_Staff_Skill_Tag_Stream_Cursor_Value_Input = {
  created_at?:InputMaybe<Scalars['timestamptz']>;
  skill_tag_id?:InputMaybe<Scalars['Int']>;
  staff_id?:InputMaybe<Scalars['Int']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Staffing_Staff_Skill_Tag_Sum_Fields = {
  __typename?:'staffing_staff_skill_tag_sum_fields';
  skill_tag_id?:Maybe<Scalars['Int']>;
  staff_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Sum_Order_By = {
  skill_tag_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
};

/** update columns of table "staffing.staff_skill_tag" */
export enum Staffing_Staff_Skill_Tag_Update_Column 
{
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  SkillTagId = 'skill_tag_id',
  /** column name */
  StaffId = 'staff_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Staffing_Staff_Skill_Tag_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Staffing_Staff_Skill_Tag_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Staffing_Staff_Skill_Tag_Set_Input>;
  where:Staffing_Staff_Skill_Tag_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Staffing_Staff_Skill_Tag_Var_Pop_Fields = {
  __typename?:'staffing_staff_skill_tag_var_pop_fields';
  skill_tag_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Var_Pop_Order_By = {
  skill_tag_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Staffing_Staff_Skill_Tag_Var_Samp_Fields = {
  __typename?:'staffing_staff_skill_tag_var_samp_fields';
  skill_tag_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Var_Samp_Order_By = {
  skill_tag_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Staffing_Staff_Skill_Tag_Variance_Fields = {
  __typename?:'staffing_staff_skill_tag_variance_fields';
  skill_tag_id?:Maybe<Scalars['Float']>;
  staff_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "staffing.staff_skill_tag" */
export type Staffing_Staff_Skill_Tag_Variance_Order_By = {
  skill_tag_id?:InputMaybe<Order_By>;
  staff_id?:InputMaybe<Order_By>;
};

/** columns and relationships of "staffing.staff_status" */
export type Staffing_Staff_Status = {
  __typename?:'staffing_staff_status';
  status:Scalars['String'];
};

/** aggregated selection of "staffing.staff_status" */
export type Staffing_Staff_Status_Aggregate = {
  __typename?:'staffing_staff_status_aggregate';
  aggregate?:Maybe<Staffing_Staff_Status_Aggregate_Fields>;
  nodes:Array<Staffing_Staff_Status>;
};

/** aggregate fields of "staffing.staff_status" */
export type Staffing_Staff_Status_Aggregate_Fields = {
  __typename?:'staffing_staff_status_aggregate_fields';
  count:Scalars['Int'];
  max?:Maybe<Staffing_Staff_Status_Max_Fields>;
  min?:Maybe<Staffing_Staff_Status_Min_Fields>;
};


/** aggregate fields of "staffing.staff_status" */
export type Staffing_Staff_Status_Aggregate_FieldsCountArgs = {
  columns?:InputMaybe<Array<Staffing_Staff_Status_Select_Column>>;
  distinct?:InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "staffing.staff_status". All fields are combined with a logical 'AND'. */
export type Staffing_Staff_Status_Bool_Exp = {
  _and?:InputMaybe<Array<Staffing_Staff_Status_Bool_Exp>>;
  _not?:InputMaybe<Staffing_Staff_Status_Bool_Exp>;
  _or?:InputMaybe<Array<Staffing_Staff_Status_Bool_Exp>>;
  status?:InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "staffing.staff_status" */
export enum Staffing_Staff_Status_Constraint 
{
  /** unique or primary key constraint on columns "status" */
  StaffStatusPkey = 'staff_status_pkey'
}

export enum Staffing_Staff_Status_Enum 
{
  Archived = 'archived',
  Leaving = 'leaving',
  Newcomer = 'newcomer',
  Staffed = 'staffed'
}

/** Boolean expression to compare columns of type "staffing_staff_status_enum". All fields are combined with logical 'AND'. */
export type Staffing_Staff_Status_Enum_Comparison_Exp = {
  _eq?:InputMaybe<Staffing_Staff_Status_Enum>;
  _in?:InputMaybe<Array<Staffing_Staff_Status_Enum>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _neq?:InputMaybe<Staffing_Staff_Status_Enum>;
  _nin?:InputMaybe<Array<Staffing_Staff_Status_Enum>>;
};

/** input type for inserting data into table "staffing.staff_status" */
export type Staffing_Staff_Status_Insert_Input = {
  status?:InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Staffing_Staff_Status_Max_Fields = {
  __typename?:'staffing_staff_status_max_fields';
  status?:Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Staffing_Staff_Status_Min_Fields = {
  __typename?:'staffing_staff_status_min_fields';
  status?:Maybe<Scalars['String']>;
};

/** response of any mutation on the table "staffing.staff_status" */
export type Staffing_Staff_Status_Mutation_Response = {
  __typename?:'staffing_staff_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows:Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning:Array<Staffing_Staff_Status>;
};

/** on_conflict condition type for table "staffing.staff_status" */
export type Staffing_Staff_Status_On_Conflict = {
  constraint:Staffing_Staff_Status_Constraint;
  update_columns?:Array<Staffing_Staff_Status_Update_Column>;
  where?:InputMaybe<Staffing_Staff_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "staffing.staff_status". */
export type Staffing_Staff_Status_Order_By = {
  status?:InputMaybe<Order_By>;
};

/** primary key columns input for table: staffing.staff_status */
export type Staffing_Staff_Status_Pk_Columns_Input = {
  status:Scalars['String'];
};

/** select columns of table "staffing.staff_status" */
export enum Staffing_Staff_Status_Select_Column 
{
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "staffing.staff_status" */
export type Staffing_Staff_Status_Set_Input = {
  status?:InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "staffing_staff_status" */
export type Staffing_Staff_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Staffing_Staff_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staffing_Staff_Status_Stream_Cursor_Value_Input = {
  status?:InputMaybe<Scalars['String']>;
};

/** update columns of table "staffing.staff_status" */
export enum Staffing_Staff_Status_Update_Column 
{
  /** column name */
  Status = 'status'
}

export type Staffing_Staff_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Staffing_Staff_Status_Set_Input>;
  where:Staffing_Staff_Status_Bool_Exp;
};

/** aggregate stddev on columns */
export type Staffing_Staff_Stddev_Fields = {
  __typename?:'staffing_staff_stddev_fields';
  city_id?:Maybe<Scalars['Float']>;
  country_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "staffing.staff" */
export type Staffing_Staff_Stddev_Order_By = {
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  staff_role_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Staffing_Staff_Stddev_Pop_Fields = {
  __typename?:'staffing_staff_stddev_pop_fields';
  city_id?:Maybe<Scalars['Float']>;
  country_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "staffing.staff" */
export type Staffing_Staff_Stddev_Pop_Order_By = {
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  staff_role_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Staffing_Staff_Stddev_Samp_Fields = {
  __typename?:'staffing_staff_stddev_samp_fields';
  city_id?:Maybe<Scalars['Float']>;
  country_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "staffing.staff" */
export type Staffing_Staff_Stddev_Samp_Order_By = {
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  staff_role_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** Streaming cursor of the table "staffing_staff" */
export type Staffing_Staff_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value:Staffing_Staff_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?:InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staffing_Staff_Stream_Cursor_Value_Input = {
  avatar_url?:InputMaybe<Scalars['String']>;
  city_id?:InputMaybe<Scalars['Int']>;
  country_id?:InputMaybe<Scalars['Int']>;
  created_at?:InputMaybe<Scalars['timestamptz']>;
  date_of_birth?:InputMaybe<Scalars['timestamptz']>;
  first_name_i18n?:InputMaybe<Scalars['String']>;
  hire_date?:InputMaybe<Scalars['timestamptz']>;
  id?:InputMaybe<Scalars['Int']>;
  is_active?:InputMaybe<Scalars['Boolean']>;
  last_name_i18n?:InputMaybe<Scalars['String']>;
  leaving_date?:InputMaybe<Scalars['timestamptz']>;
  linkedin_profile_url?:InputMaybe<Scalars['String']>;
  middle_name_i18n?:InputMaybe<Scalars['String']>;
  phone?:InputMaybe<Scalars['String']>;
  staff_role_id?:InputMaybe<Scalars['Int']>;
  status?:InputMaybe<Staffing_Staff_Status_Enum>;
  status_date?:InputMaybe<Scalars['timestamptz']>;
  termination_date?:InputMaybe<Scalars['timestamptz']>;
  updated_at?:InputMaybe<Scalars['timestamptz']>;
  user_id?:InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Staffing_Staff_Sum_Fields = {
  __typename?:'staffing_staff_sum_fields';
  city_id?:Maybe<Scalars['Int']>;
  country_id?:Maybe<Scalars['Int']>;
  id?:Maybe<Scalars['Int']>;
  staff_role_id?:Maybe<Scalars['Int']>;
  user_id?:Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "staffing.staff" */
export type Staffing_Staff_Sum_Order_By = {
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  staff_role_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** update columns of table "staffing.staff" */
export enum Staffing_Staff_Update_Column 
{
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CityId = 'city_id',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  FirstNameI18n = 'first_name_i18n',
  /** column name */
  HireDate = 'hire_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastNameI18n = 'last_name_i18n',
  /** column name */
  LeavingDate = 'leaving_date',
  /** column name */
  LinkedinProfileUrl = 'linkedin_profile_url',
  /** column name */
  MiddleNameI18n = 'middle_name_i18n',
  /** column name */
  Phone = 'phone',
  /** column name */
  StaffRoleId = 'staff_role_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDate = 'status_date',
  /** column name */
  TerminationDate = 'termination_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Staffing_Staff_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?:InputMaybe<Staffing_Staff_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?:InputMaybe<Staffing_Staff_Set_Input>;
  where:Staffing_Staff_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Staffing_Staff_Var_Pop_Fields = {
  __typename?:'staffing_staff_var_pop_fields';
  city_id?:Maybe<Scalars['Float']>;
  country_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "staffing.staff" */
export type Staffing_Staff_Var_Pop_Order_By = {
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  staff_role_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Staffing_Staff_Var_Samp_Fields = {
  __typename?:'staffing_staff_var_samp_fields';
  city_id?:Maybe<Scalars['Float']>;
  country_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "staffing.staff" */
export type Staffing_Staff_Var_Samp_Order_By = {
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  staff_role_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Staffing_Staff_Variance_Fields = {
  __typename?:'staffing_staff_variance_fields';
  city_id?:Maybe<Scalars['Float']>;
  country_id?:Maybe<Scalars['Float']>;
  id?:Maybe<Scalars['Float']>;
  staff_role_id?:Maybe<Scalars['Float']>;
  user_id?:Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "staffing.staff" */
export type Staffing_Staff_Variance_Order_By = {
  city_id?:InputMaybe<Order_By>;
  country_id?:InputMaybe<Order_By>;
  id?:InputMaybe<Order_By>;
  staff_role_id?:InputMaybe<Order_By>;
  user_id?:InputMaybe<Order_By>;
};

export type Subscription_Root = {
  __typename?:'subscription_root';
  /** fetch data from the table: "audit.logged_actions" */
  audit_logged_actions:Array<Audit_Logged_Actions>;
  /** fetch aggregated fields from the table: "audit.logged_actions" */
  audit_logged_actions_aggregate:Audit_Logged_Actions_Aggregate;
  /** fetch data from the table: "audit.logged_actions" using primary key columns */
  audit_logged_actions_by_pk?:Maybe<Audit_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "audit.logged_actions" */
  audit_logged_actions_stream:Array<Audit_Logged_Actions>;
  /** fetch data from the table: "audit.tableslist" */
  audit_tableslist:Array<Audit_Tableslist>;
  /** fetch aggregated fields from the table: "audit.tableslist" */
  audit_tableslist_aggregate:Audit_Tableslist_Aggregate;
  /** fetch data from the table in a streaming manner: "audit.tableslist" */
  audit_tableslist_stream:Array<Audit_Tableslist>;
  /** fetch data from the table: "common.api_key" */
  common_api_key:Array<Common_Api_Key>;
  /** fetch aggregated fields from the table: "common.api_key" */
  common_api_key_aggregate:Common_Api_Key_Aggregate;
  /** fetch data from the table: "common.api_key" using primary key columns */
  common_api_key_by_pk?:Maybe<Common_Api_Key>;
  /** fetch data from the table in a streaming manner: "common.api_key" */
  common_api_key_stream:Array<Common_Api_Key>;
  /** fetch data from the table: "common.attachment" */
  common_attachment:Array<Common_Attachment>;
  /** fetch aggregated fields from the table: "common.attachment" */
  common_attachment_aggregate:Common_Attachment_Aggregate;
  /** fetch data from the table: "common.attachment" using primary key columns */
  common_attachment_by_pk?:Maybe<Common_Attachment>;
  /** fetch data from the table in a streaming manner: "common.attachment" */
  common_attachment_stream:Array<Common_Attachment>;
  /** fetch data from the table: "common.attachment_type" */
  common_attachment_type:Array<Common_Attachment_Type>;
  /** fetch aggregated fields from the table: "common.attachment_type" */
  common_attachment_type_aggregate:Common_Attachment_Type_Aggregate;
  /** fetch data from the table: "common.attachment_type" using primary key columns */
  common_attachment_type_by_pk?:Maybe<Common_Attachment_Type>;
  /** fetch data from the table in a streaming manner: "common.attachment_type" */
  common_attachment_type_stream:Array<Common_Attachment_Type>;
  /** fetch data from the table: "common.city" */
  common_city:Array<Common_City>;
  /** fetch aggregated fields from the table: "common.city" */
  common_city_aggregate:Common_City_Aggregate;
  /** fetch data from the table: "common.city" using primary key columns */
  common_city_by_pk?:Maybe<Common_City>;
  /** fetch data from the table in a streaming manner: "common.city" */
  common_city_stream:Array<Common_City>;
  /** fetch data from the table: "common.country" */
  common_country:Array<Common_Country>;
  /** fetch aggregated fields from the table: "common.country" */
  common_country_aggregate:Common_Country_Aggregate;
  /** fetch data from the table: "common.country" using primary key columns */
  common_country_by_pk?:Maybe<Common_Country>;
  /** fetch data from the table in a streaming manner: "common.country" */
  common_country_stream:Array<Common_Country>;
  /** fetch data from the table: "common.customer" */
  common_customer:Array<Common_Customer>;
  /** fetch aggregated fields from the table: "common.customer" */
  common_customer_aggregate:Common_Customer_Aggregate;
  /** fetch data from the table: "common.customer" using primary key columns */
  common_customer_by_pk?:Maybe<Common_Customer>;
  /** fetch data from the table: "common.customer_status" */
  common_customer_status:Array<Common_Customer_Status>;
  /** fetch aggregated fields from the table: "common.customer_status" */
  common_customer_status_aggregate:Common_Customer_Status_Aggregate;
  /** fetch data from the table: "common.customer_status" using primary key columns */
  common_customer_status_by_pk?:Maybe<Common_Customer_Status>;
  /** fetch data from the table in a streaming manner: "common.customer_status" */
  common_customer_status_stream:Array<Common_Customer_Status>;
  /** fetch data from the table in a streaming manner: "common.customer" */
  common_customer_stream:Array<Common_Customer>;
  /** fetch data from the table: "common.filter" */
  common_filter:Array<Common_Filter>;
  /** fetch aggregated fields from the table: "common.filter" */
  common_filter_aggregate:Common_Filter_Aggregate;
  /** fetch data from the table: "common.filter" using primary key columns */
  common_filter_by_pk?:Maybe<Common_Filter>;
  /** fetch data from the table in a streaming manner: "common.filter" */
  common_filter_stream:Array<Common_Filter>;
  /** fetch data from the table: "common.filter_type" */
  common_filter_type:Array<Common_Filter_Type>;
  /** fetch aggregated fields from the table: "common.filter_type" */
  common_filter_type_aggregate:Common_Filter_Type_Aggregate;
  /** fetch data from the table: "common.filter_type" using primary key columns */
  common_filter_type_by_pk?:Maybe<Common_Filter_Type>;
  /** fetch data from the table in a streaming manner: "common.filter_type" */
  common_filter_type_stream:Array<Common_Filter_Type>;
  /** fetch data from the table: "common.scheduled_event" */
  common_scheduled_event:Array<Common_Scheduled_Event>;
  /** fetch aggregated fields from the table: "common.scheduled_event" */
  common_scheduled_event_aggregate:Common_Scheduled_Event_Aggregate;
  /** fetch data from the table: "common.scheduled_event" using primary key columns */
  common_scheduled_event_by_pk?:Maybe<Common_Scheduled_Event>;
  /** fetch data from the table in a streaming manner: "common.scheduled_event" */
  common_scheduled_event_stream:Array<Common_Scheduled_Event>;
  /** fetch data from the table: "common.skill_tag" */
  common_skill_tag:Array<Common_Skill_Tag>;
  /** fetch aggregated fields from the table: "common.skill_tag" */
  common_skill_tag_aggregate:Common_Skill_Tag_Aggregate;
  /** fetch data from the table: "common.skill_tag" using primary key columns */
  common_skill_tag_by_pk?:Maybe<Common_Skill_Tag>;
  /** fetch data from the table in a streaming manner: "common.skill_tag" */
  common_skill_tag_stream:Array<Common_Skill_Tag>;
  /** fetch data from the table: "common.user" */
  common_user:Array<Common_User>;
  /** fetch aggregated fields from the table: "common.user" */
  common_user_aggregate:Common_User_Aggregate;
  /** fetch data from the table: "common.user" using primary key columns */
  common_user_by_pk?:Maybe<Common_User>;
  /** fetch data from the table: "common.user_role" */
  common_user_role:Array<Common_User_Role>;
  /** fetch aggregated fields from the table: "common.user_role" */
  common_user_role_aggregate:Common_User_Role_Aggregate;
  /** fetch data from the table: "common.user_role" using primary key columns */
  common_user_role_by_pk?:Maybe<Common_User_Role>;
  /** fetch data from the table in a streaming manner: "common.user_role" */
  common_user_role_stream:Array<Common_User_Role>;
  /** fetch data from the table in a streaming manner: "common.user" */
  common_user_stream:Array<Common_User>;
  /** fetch data from the table: "common.user_user_role" */
  common_user_user_role:Array<Common_User_User_Role>;
  /** fetch aggregated fields from the table: "common.user_user_role" */
  common_user_user_role_aggregate:Common_User_User_Role_Aggregate;
  /** fetch data from the table: "common.user_user_role" using primary key columns */
  common_user_user_role_by_pk?:Maybe<Common_User_User_Role>;
  /** fetch data from the table in a streaming manner: "common.user_user_role" */
  common_user_user_role_stream:Array<Common_User_User_Role>;
  /** fetch data from the table: "hasura_action_permissions_view" */
  hasura_action_permissions_view:Array<Hasura_Action_Permissions_View>;
  /** fetch aggregated fields from the table: "hasura_action_permissions_view" */
  hasura_action_permissions_view_aggregate:Hasura_Action_Permissions_View_Aggregate;
  /** fetch data from the table in a streaming manner: "hasura_action_permissions_view" */
  hasura_action_permissions_view_stream:Array<Hasura_Action_Permissions_View>;
  /** fetch data from the table: "hasura_inherited_roles_view" */
  hasura_inherited_roles_view:Array<Hasura_Inherited_Roles_View>;
  /** fetch aggregated fields from the table: "hasura_inherited_roles_view" */
  hasura_inherited_roles_view_aggregate:Hasura_Inherited_Roles_View_Aggregate;
  /** fetch data from the table in a streaming manner: "hasura_inherited_roles_view" */
  hasura_inherited_roles_view_stream:Array<Hasura_Inherited_Roles_View>;
  /** fetch data from the table: "hasura_metadata_view" */
  hasura_metadata_view:Array<Hasura_Metadata_View>;
  /** fetch aggregated fields from the table: "hasura_metadata_view" */
  hasura_metadata_view_aggregate:Hasura_Metadata_View_Aggregate;
  /** fetch data from the table in a streaming manner: "hasura_metadata_view" */
  hasura_metadata_view_stream:Array<Hasura_Metadata_View>;
  /** fetch data from the table: "hasura_permissions_view" */
  hasura_permissions_view:Array<Hasura_Permissions_View>;
  /** fetch aggregated fields from the table: "hasura_permissions_view" */
  hasura_permissions_view_aggregate:Hasura_Permissions_View_Aggregate;
  /** fetch data from the table in a streaming manner: "hasura_permissions_view" */
  hasura_permissions_view_stream:Array<Hasura_Permissions_View>;
  /** fetch data from the table: "recruiting.allocation_position" */
  recruiting_allocation_position:Array<Recruiting_Allocation_Position>;
  /** fetch aggregated fields from the table: "recruiting.allocation_position" */
  recruiting_allocation_position_aggregate:Recruiting_Allocation_Position_Aggregate;
  /** fetch data from the table: "recruiting.allocation_position" using primary key columns */
  recruiting_allocation_position_by_pk?:Maybe<Recruiting_Allocation_Position>;
  /** fetch data from the table in a streaming manner: "recruiting.allocation_position" */
  recruiting_allocation_position_stream:Array<Recruiting_Allocation_Position>;
  /** fetch data from the table: "recruiting.candidate" */
  recruiting_candidate:Array<Recruiting_Candidate>;
  /** fetch aggregated fields from the table: "recruiting.candidate" */
  recruiting_candidate_aggregate:Recruiting_Candidate_Aggregate;
  /** fetch data from the table: "recruiting.candidate_allocation" */
  recruiting_candidate_allocation:Array<Recruiting_Candidate_Allocation>;
  /** fetch aggregated fields from the table: "recruiting.candidate_allocation" */
  recruiting_candidate_allocation_aggregate:Recruiting_Candidate_Allocation_Aggregate;
  /** fetch data from the table: "recruiting.candidate_allocation" using primary key columns */
  recruiting_candidate_allocation_by_pk?:Maybe<Recruiting_Candidate_Allocation>;
  /** fetch data from the table in a streaming manner: "recruiting.candidate_allocation" */
  recruiting_candidate_allocation_stream:Array<Recruiting_Candidate_Allocation>;
  /** fetch data from the table: "recruiting.candidate_attachment" */
  recruiting_candidate_attachment:Array<Recruiting_Candidate_Attachment>;
  /** fetch aggregated fields from the table: "recruiting.candidate_attachment" */
  recruiting_candidate_attachment_aggregate:Recruiting_Candidate_Attachment_Aggregate;
  /** fetch data from the table: "recruiting.candidate_attachment" using primary key columns */
  recruiting_candidate_attachment_by_pk?:Maybe<Recruiting_Candidate_Attachment>;
  /** fetch data from the table in a streaming manner: "recruiting.candidate_attachment" */
  recruiting_candidate_attachment_stream:Array<Recruiting_Candidate_Attachment>;
  /** fetch data from the table: "recruiting.candidate" using primary key columns */
  recruiting_candidate_by_pk?:Maybe<Recruiting_Candidate>;
  /** fetch data from the table: "recruiting.candidate_note" */
  recruiting_candidate_note:Array<Recruiting_Candidate_Note>;
  /** fetch aggregated fields from the table: "recruiting.candidate_note" */
  recruiting_candidate_note_aggregate:Recruiting_Candidate_Note_Aggregate;
  /** fetch data from the table: "recruiting.candidate_note" using primary key columns */
  recruiting_candidate_note_by_pk?:Maybe<Recruiting_Candidate_Note>;
  /** fetch data from the table in a streaming manner: "recruiting.candidate_note" */
  recruiting_candidate_note_stream:Array<Recruiting_Candidate_Note>;
  /** fetch data from the table: "recruiting.candidate_note_type" */
  recruiting_candidate_note_type:Array<Recruiting_Candidate_Note_Type>;
  /** fetch aggregated fields from the table: "recruiting.candidate_note_type" */
  recruiting_candidate_note_type_aggregate:Recruiting_Candidate_Note_Type_Aggregate;
  /** fetch data from the table: "recruiting.candidate_note_type" using primary key columns */
  recruiting_candidate_note_type_by_pk?:Maybe<Recruiting_Candidate_Note_Type>;
  /** fetch data from the table in a streaming manner: "recruiting.candidate_note_type" */
  recruiting_candidate_note_type_stream:Array<Recruiting_Candidate_Note_Type>;
  /** fetch data from the table: "recruiting.candidate_position_group" */
  recruiting_candidate_position_group:Array<Recruiting_Candidate_Position_Group>;
  /** fetch aggregated fields from the table: "recruiting.candidate_position_group" */
  recruiting_candidate_position_group_aggregate:Recruiting_Candidate_Position_Group_Aggregate;
  /** fetch data from the table: "recruiting.candidate_position_group" using primary key columns */
  recruiting_candidate_position_group_by_pk?:Maybe<Recruiting_Candidate_Position_Group>;
  /** fetch data from the table in a streaming manner: "recruiting.candidate_position_group" */
  recruiting_candidate_position_group_stream:Array<Recruiting_Candidate_Position_Group>;
  /** fetch data from the table: "recruiting.candidate_scheduled_event" */
  recruiting_candidate_scheduled_event:Array<Recruiting_Candidate_Scheduled_Event>;
  /** fetch aggregated fields from the table: "recruiting.candidate_scheduled_event" */
  recruiting_candidate_scheduled_event_aggregate:Recruiting_Candidate_Scheduled_Event_Aggregate;
  /** fetch data from the table: "recruiting.candidate_scheduled_event" using primary key columns */
  recruiting_candidate_scheduled_event_by_pk?:Maybe<Recruiting_Candidate_Scheduled_Event>;
  /** fetch data from the table in a streaming manner: "recruiting.candidate_scheduled_event" */
  recruiting_candidate_scheduled_event_stream:Array<Recruiting_Candidate_Scheduled_Event>;
  /** fetch data from the table: "recruiting.candidate_skill_tag" */
  recruiting_candidate_skill_tag:Array<Recruiting_Candidate_Skill_Tag>;
  /** fetch aggregated fields from the table: "recruiting.candidate_skill_tag" */
  recruiting_candidate_skill_tag_aggregate:Recruiting_Candidate_Skill_Tag_Aggregate;
  /** fetch data from the table: "recruiting.candidate_skill_tag" using primary key columns */
  recruiting_candidate_skill_tag_by_pk?:Maybe<Recruiting_Candidate_Skill_Tag>;
  /** fetch data from the table in a streaming manner: "recruiting.candidate_skill_tag" */
  recruiting_candidate_skill_tag_stream:Array<Recruiting_Candidate_Skill_Tag>;
  /** fetch data from the table: "recruiting.candidate_status" */
  recruiting_candidate_status:Array<Recruiting_Candidate_Status>;
  /** fetch aggregated fields from the table: "recruiting.candidate_status" */
  recruiting_candidate_status_aggregate:Recruiting_Candidate_Status_Aggregate;
  /** fetch data from the table: "recruiting.candidate_status" using primary key columns */
  recruiting_candidate_status_by_pk?:Maybe<Recruiting_Candidate_Status>;
  /** fetch data from the table: "recruiting.candidate_status_reason" */
  recruiting_candidate_status_reason:Array<Recruiting_Candidate_Status_Reason>;
  /** fetch aggregated fields from the table: "recruiting.candidate_status_reason" */
  recruiting_candidate_status_reason_aggregate:Recruiting_Candidate_Status_Reason_Aggregate;
  /** fetch data from the table: "recruiting.candidate_status_reason" using primary key columns */
  recruiting_candidate_status_reason_by_pk?:Maybe<Recruiting_Candidate_Status_Reason>;
  /** fetch data from the table in a streaming manner: "recruiting.candidate_status_reason" */
  recruiting_candidate_status_reason_stream:Array<Recruiting_Candidate_Status_Reason>;
  /** fetch data from the table in a streaming manner: "recruiting.candidate_status" */
  recruiting_candidate_status_stream:Array<Recruiting_Candidate_Status>;
  /** fetch data from the table in a streaming manner: "recruiting.candidate" */
  recruiting_candidate_stream:Array<Recruiting_Candidate>;
  /** fetch data from the table: "recruiting.candidate_user_interviewer" */
  recruiting_candidate_user_interviewer:Array<Recruiting_Candidate_User_Interviewer>;
  /** fetch aggregated fields from the table: "recruiting.candidate_user_interviewer" */
  recruiting_candidate_user_interviewer_aggregate:Recruiting_Candidate_User_Interviewer_Aggregate;
  /** fetch data from the table: "recruiting.candidate_user_interviewer" using primary key columns */
  recruiting_candidate_user_interviewer_by_pk?:Maybe<Recruiting_Candidate_User_Interviewer>;
  /** fetch data from the table in a streaming manner: "recruiting.candidate_user_interviewer" */
  recruiting_candidate_user_interviewer_stream:Array<Recruiting_Candidate_User_Interviewer>;
  /** fetch data from the table: "recruiting.interview_flow" */
  recruiting_interview_flow:Array<Recruiting_Interview_Flow>;
  /** fetch aggregated fields from the table: "recruiting.interview_flow" */
  recruiting_interview_flow_aggregate:Recruiting_Interview_Flow_Aggregate;
  /** fetch data from the table: "recruiting.interview_flow" using primary key columns */
  recruiting_interview_flow_by_pk?:Maybe<Recruiting_Interview_Flow>;
  /** fetch data from the table: "recruiting.interview_flow_stage" */
  recruiting_interview_flow_stage:Array<Recruiting_Interview_Flow_Stage>;
  /** fetch aggregated fields from the table: "recruiting.interview_flow_stage" */
  recruiting_interview_flow_stage_aggregate:Recruiting_Interview_Flow_Stage_Aggregate;
  /** fetch data from the table: "recruiting.interview_flow_stage" using primary key columns */
  recruiting_interview_flow_stage_by_pk?:Maybe<Recruiting_Interview_Flow_Stage>;
  /** fetch data from the table in a streaming manner: "recruiting.interview_flow_stage" */
  recruiting_interview_flow_stage_stream:Array<Recruiting_Interview_Flow_Stage>;
  /** fetch data from the table in a streaming manner: "recruiting.interview_flow" */
  recruiting_interview_flow_stream:Array<Recruiting_Interview_Flow>;
  /** fetch data from the table: "recruiting.interview_flow_substage" */
  recruiting_interview_flow_substage:Array<Recruiting_Interview_Flow_Substage>;
  /** fetch aggregated fields from the table: "recruiting.interview_flow_substage" */
  recruiting_interview_flow_substage_aggregate:Recruiting_Interview_Flow_Substage_Aggregate;
  /** fetch data from the table: "recruiting.interview_flow_substage" using primary key columns */
  recruiting_interview_flow_substage_by_pk?:Maybe<Recruiting_Interview_Flow_Substage>;
  /** fetch data from the table in a streaming manner: "recruiting.interview_flow_substage" */
  recruiting_interview_flow_substage_stream:Array<Recruiting_Interview_Flow_Substage>;
  /** fetch data from the table: "recruiting.offer" */
  recruiting_offer:Array<Recruiting_Offer>;
  /** fetch aggregated fields from the table: "recruiting.offer" */
  recruiting_offer_aggregate:Recruiting_Offer_Aggregate;
  /** fetch data from the table: "recruiting.offer" using primary key columns */
  recruiting_offer_by_pk?:Maybe<Recruiting_Offer>;
  /** fetch data from the table in a streaming manner: "recruiting.offer" */
  recruiting_offer_stream:Array<Recruiting_Offer>;
  /** fetch data from the table: "recruiting.offer_template" */
  recruiting_offer_template:Array<Recruiting_Offer_Template>;
  /** fetch aggregated fields from the table: "recruiting.offer_template" */
  recruiting_offer_template_aggregate:Recruiting_Offer_Template_Aggregate;
  /** fetch data from the table: "recruiting.offer_template" using primary key columns */
  recruiting_offer_template_by_pk?:Maybe<Recruiting_Offer_Template>;
  /** fetch data from the table in a streaming manner: "recruiting.offer_template" */
  recruiting_offer_template_stream:Array<Recruiting_Offer_Template>;
  /** fetch data from the table: "recruiting.position" */
  recruiting_position:Array<Recruiting_Position>;
  /** fetch aggregated fields from the table: "recruiting.position" */
  recruiting_position_aggregate:Recruiting_Position_Aggregate;
  /** fetch data from the table: "recruiting.position" using primary key columns */
  recruiting_position_by_pk?:Maybe<Recruiting_Position>;
  /** fetch data from the table: "recruiting.position_group" */
  recruiting_position_group:Array<Recruiting_Position_Group>;
  /** fetch aggregated fields from the table: "recruiting.position_group" */
  recruiting_position_group_aggregate:Recruiting_Position_Group_Aggregate;
  /** fetch data from the table: "recruiting.position_group" using primary key columns */
  recruiting_position_group_by_pk?:Maybe<Recruiting_Position_Group>;
  /** fetch data from the table: "recruiting.position_group_priority" */
  recruiting_position_group_priority:Array<Recruiting_Position_Group_Priority>;
  /** fetch aggregated fields from the table: "recruiting.position_group_priority" */
  recruiting_position_group_priority_aggregate:Recruiting_Position_Group_Priority_Aggregate;
  /** fetch data from the table: "recruiting.position_group_priority" using primary key columns */
  recruiting_position_group_priority_by_pk?:Maybe<Recruiting_Position_Group_Priority>;
  /** fetch data from the table in a streaming manner: "recruiting.position_group_priority" */
  recruiting_position_group_priority_stream:Array<Recruiting_Position_Group_Priority>;
  /** fetch data from the table in a streaming manner: "recruiting.position_group" */
  recruiting_position_group_stream:Array<Recruiting_Position_Group>;
  /** fetch data from the table in a streaming manner: "recruiting.position" */
  recruiting_position_stream:Array<Recruiting_Position>;
  /** fetch data from the table: "recruiting.position_template" */
  recruiting_position_template:Array<Recruiting_Position_Template>;
  /** fetch aggregated fields from the table: "recruiting.position_template" */
  recruiting_position_template_aggregate:Recruiting_Position_Template_Aggregate;
  /** fetch data from the table: "recruiting.position_template" using primary key columns */
  recruiting_position_template_by_pk?:Maybe<Recruiting_Position_Template>;
  /** fetch data from the table in a streaming manner: "recruiting.position_template" */
  recruiting_position_template_stream:Array<Recruiting_Position_Template>;
  /** fetch data from the table: "staffing.customer_team" */
  staffing_customer_team:Array<Staffing_Customer_Team>;
  /** fetch aggregated fields from the table: "staffing.customer_team" */
  staffing_customer_team_aggregate:Staffing_Customer_Team_Aggregate;
  /** fetch data from the table: "staffing.customer_team" using primary key columns */
  staffing_customer_team_by_pk?:Maybe<Staffing_Customer_Team>;
  /** fetch data from the table: "staffing.customer_team_slot" */
  staffing_customer_team_slot:Array<Staffing_Customer_Team_Slot>;
  /** fetch aggregated fields from the table: "staffing.customer_team_slot" */
  staffing_customer_team_slot_aggregate:Staffing_Customer_Team_Slot_Aggregate;
  /** fetch data from the table: "staffing.customer_team_slot" using primary key columns */
  staffing_customer_team_slot_by_pk?:Maybe<Staffing_Customer_Team_Slot>;
  /** fetch data from the table: "staffing.customer_team_slot_status" */
  staffing_customer_team_slot_status:Array<Staffing_Customer_Team_Slot_Status>;
  /** fetch aggregated fields from the table: "staffing.customer_team_slot_status" */
  staffing_customer_team_slot_status_aggregate:Staffing_Customer_Team_Slot_Status_Aggregate;
  /** fetch data from the table: "staffing.customer_team_slot_status" using primary key columns */
  staffing_customer_team_slot_status_by_pk?:Maybe<Staffing_Customer_Team_Slot_Status>;
  /** fetch data from the table in a streaming manner: "staffing.customer_team_slot_status" */
  staffing_customer_team_slot_status_stream:Array<Staffing_Customer_Team_Slot_Status>;
  /** fetch data from the table in a streaming manner: "staffing.customer_team_slot" */
  staffing_customer_team_slot_stream:Array<Staffing_Customer_Team_Slot>;
  /** fetch data from the table in a streaming manner: "staffing.customer_team" */
  staffing_customer_team_stream:Array<Staffing_Customer_Team>;
  /** fetch data from the table: "staffing.customer_team_tag" */
  staffing_customer_team_tag:Array<Staffing_Customer_Team_Tag>;
  /** fetch aggregated fields from the table: "staffing.customer_team_tag" */
  staffing_customer_team_tag_aggregate:Staffing_Customer_Team_Tag_Aggregate;
  /** fetch data from the table: "staffing.customer_team_tag" using primary key columns */
  staffing_customer_team_tag_by_pk?:Maybe<Staffing_Customer_Team_Tag>;
  /** fetch data from the table in a streaming manner: "staffing.customer_team_tag" */
  staffing_customer_team_tag_stream:Array<Staffing_Customer_Team_Tag>;
  /** fetch data from the table: "staffing.staff" */
  staffing_staff:Array<Staffing_Staff>;
  /** fetch aggregated fields from the table: "staffing.staff" */
  staffing_staff_aggregate:Staffing_Staff_Aggregate;
  /** fetch data from the table: "staffing.staff" using primary key columns */
  staffing_staff_by_pk?:Maybe<Staffing_Staff>;
  /** fetch data from the table: "staffing.staff_role" */
  staffing_staff_role:Array<Staffing_Staff_Role>;
  /** fetch aggregated fields from the table: "staffing.staff_role" */
  staffing_staff_role_aggregate:Staffing_Staff_Role_Aggregate;
  /** fetch data from the table: "staffing.staff_role" using primary key columns */
  staffing_staff_role_by_pk?:Maybe<Staffing_Staff_Role>;
  /** fetch data from the table in a streaming manner: "staffing.staff_role" */
  staffing_staff_role_stream:Array<Staffing_Staff_Role>;
  /** fetch data from the table: "staffing.staff_skill_tag" */
  staffing_staff_skill_tag:Array<Staffing_Staff_Skill_Tag>;
  /** fetch aggregated fields from the table: "staffing.staff_skill_tag" */
  staffing_staff_skill_tag_aggregate:Staffing_Staff_Skill_Tag_Aggregate;
  /** fetch data from the table: "staffing.staff_skill_tag" using primary key columns */
  staffing_staff_skill_tag_by_pk?:Maybe<Staffing_Staff_Skill_Tag>;
  /** fetch data from the table in a streaming manner: "staffing.staff_skill_tag" */
  staffing_staff_skill_tag_stream:Array<Staffing_Staff_Skill_Tag>;
  /** fetch data from the table: "staffing.staff_status" */
  staffing_staff_status:Array<Staffing_Staff_Status>;
  /** fetch aggregated fields from the table: "staffing.staff_status" */
  staffing_staff_status_aggregate:Staffing_Staff_Status_Aggregate;
  /** fetch data from the table: "staffing.staff_status" using primary key columns */
  staffing_staff_status_by_pk?:Maybe<Staffing_Staff_Status>;
  /** fetch data from the table in a streaming manner: "staffing.staff_status" */
  staffing_staff_status_stream:Array<Staffing_Staff_Status>;
  /** fetch data from the table in a streaming manner: "staffing.staff" */
  staffing_staff_stream:Array<Staffing_Staff>;
};


export type Subscription_RootAudit_Logged_ActionsArgs = {
  distinct_on?:InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Audit_Logged_Actions_Order_By>>;
  where?:InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootAudit_Logged_Actions_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Audit_Logged_Actions_Order_By>>;
  where?:InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootAudit_Logged_Actions_By_PkArgs = {
  event_id:Scalars['bigint'];
};


export type Subscription_RootAudit_Logged_Actions_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Audit_Logged_Actions_Stream_Cursor_Input>>;
  where?:InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};


export type Subscription_RootAudit_TableslistArgs = {
  distinct_on?:InputMaybe<Array<Audit_Tableslist_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Audit_Tableslist_Order_By>>;
  where?:InputMaybe<Audit_Tableslist_Bool_Exp>;
};


export type Subscription_RootAudit_Tableslist_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Audit_Tableslist_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Audit_Tableslist_Order_By>>;
  where?:InputMaybe<Audit_Tableslist_Bool_Exp>;
};


export type Subscription_RootAudit_Tableslist_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Audit_Tableslist_Stream_Cursor_Input>>;
  where?:InputMaybe<Audit_Tableslist_Bool_Exp>;
};


export type Subscription_RootCommon_Api_KeyArgs = {
  distinct_on?:InputMaybe<Array<Common_Api_Key_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Api_Key_Order_By>>;
  where?:InputMaybe<Common_Api_Key_Bool_Exp>;
};


export type Subscription_RootCommon_Api_Key_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Api_Key_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Api_Key_Order_By>>;
  where?:InputMaybe<Common_Api_Key_Bool_Exp>;
};


export type Subscription_RootCommon_Api_Key_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootCommon_Api_Key_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Common_Api_Key_Stream_Cursor_Input>>;
  where?:InputMaybe<Common_Api_Key_Bool_Exp>;
};


export type Subscription_RootCommon_AttachmentArgs = {
  distinct_on?:InputMaybe<Array<Common_Attachment_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Attachment_Order_By>>;
  where?:InputMaybe<Common_Attachment_Bool_Exp>;
};


export type Subscription_RootCommon_Attachment_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Attachment_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Attachment_Order_By>>;
  where?:InputMaybe<Common_Attachment_Bool_Exp>;
};


export type Subscription_RootCommon_Attachment_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootCommon_Attachment_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Common_Attachment_Stream_Cursor_Input>>;
  where?:InputMaybe<Common_Attachment_Bool_Exp>;
};


export type Subscription_RootCommon_Attachment_TypeArgs = {
  distinct_on?:InputMaybe<Array<Common_Attachment_Type_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Attachment_Type_Order_By>>;
  where?:InputMaybe<Common_Attachment_Type_Bool_Exp>;
};


export type Subscription_RootCommon_Attachment_Type_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Attachment_Type_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Attachment_Type_Order_By>>;
  where?:InputMaybe<Common_Attachment_Type_Bool_Exp>;
};


export type Subscription_RootCommon_Attachment_Type_By_PkArgs = {
  type:Scalars['String'];
};


export type Subscription_RootCommon_Attachment_Type_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Common_Attachment_Type_Stream_Cursor_Input>>;
  where?:InputMaybe<Common_Attachment_Type_Bool_Exp>;
};


export type Subscription_RootCommon_CityArgs = {
  distinct_on?:InputMaybe<Array<Common_City_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_City_Order_By>>;
  where?:InputMaybe<Common_City_Bool_Exp>;
};


export type Subscription_RootCommon_City_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_City_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_City_Order_By>>;
  where?:InputMaybe<Common_City_Bool_Exp>;
};


export type Subscription_RootCommon_City_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootCommon_City_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Common_City_Stream_Cursor_Input>>;
  where?:InputMaybe<Common_City_Bool_Exp>;
};


export type Subscription_RootCommon_CountryArgs = {
  distinct_on?:InputMaybe<Array<Common_Country_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Country_Order_By>>;
  where?:InputMaybe<Common_Country_Bool_Exp>;
};


export type Subscription_RootCommon_Country_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Country_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Country_Order_By>>;
  where?:InputMaybe<Common_Country_Bool_Exp>;
};


export type Subscription_RootCommon_Country_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootCommon_Country_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Common_Country_Stream_Cursor_Input>>;
  where?:InputMaybe<Common_Country_Bool_Exp>;
};


export type Subscription_RootCommon_CustomerArgs = {
  distinct_on?:InputMaybe<Array<Common_Customer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Customer_Order_By>>;
  where?:InputMaybe<Common_Customer_Bool_Exp>;
};


export type Subscription_RootCommon_Customer_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Customer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Customer_Order_By>>;
  where?:InputMaybe<Common_Customer_Bool_Exp>;
};


export type Subscription_RootCommon_Customer_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootCommon_Customer_StatusArgs = {
  distinct_on?:InputMaybe<Array<Common_Customer_Status_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Customer_Status_Order_By>>;
  where?:InputMaybe<Common_Customer_Status_Bool_Exp>;
};


export type Subscription_RootCommon_Customer_Status_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Customer_Status_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Customer_Status_Order_By>>;
  where?:InputMaybe<Common_Customer_Status_Bool_Exp>;
};


export type Subscription_RootCommon_Customer_Status_By_PkArgs = {
  status:Scalars['String'];
};


export type Subscription_RootCommon_Customer_Status_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Common_Customer_Status_Stream_Cursor_Input>>;
  where?:InputMaybe<Common_Customer_Status_Bool_Exp>;
};


export type Subscription_RootCommon_Customer_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Common_Customer_Stream_Cursor_Input>>;
  where?:InputMaybe<Common_Customer_Bool_Exp>;
};


export type Subscription_RootCommon_FilterArgs = {
  distinct_on?:InputMaybe<Array<Common_Filter_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Filter_Order_By>>;
  where?:InputMaybe<Common_Filter_Bool_Exp>;
};


export type Subscription_RootCommon_Filter_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Filter_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Filter_Order_By>>;
  where?:InputMaybe<Common_Filter_Bool_Exp>;
};


export type Subscription_RootCommon_Filter_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootCommon_Filter_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Common_Filter_Stream_Cursor_Input>>;
  where?:InputMaybe<Common_Filter_Bool_Exp>;
};


export type Subscription_RootCommon_Filter_TypeArgs = {
  distinct_on?:InputMaybe<Array<Common_Filter_Type_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Filter_Type_Order_By>>;
  where?:InputMaybe<Common_Filter_Type_Bool_Exp>;
};


export type Subscription_RootCommon_Filter_Type_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Filter_Type_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Filter_Type_Order_By>>;
  where?:InputMaybe<Common_Filter_Type_Bool_Exp>;
};


export type Subscription_RootCommon_Filter_Type_By_PkArgs = {
  type:Scalars['String'];
};


export type Subscription_RootCommon_Filter_Type_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Common_Filter_Type_Stream_Cursor_Input>>;
  where?:InputMaybe<Common_Filter_Type_Bool_Exp>;
};


export type Subscription_RootCommon_Scheduled_EventArgs = {
  distinct_on?:InputMaybe<Array<Common_Scheduled_Event_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Scheduled_Event_Order_By>>;
  where?:InputMaybe<Common_Scheduled_Event_Bool_Exp>;
};


export type Subscription_RootCommon_Scheduled_Event_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Scheduled_Event_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Scheduled_Event_Order_By>>;
  where?:InputMaybe<Common_Scheduled_Event_Bool_Exp>;
};


export type Subscription_RootCommon_Scheduled_Event_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootCommon_Scheduled_Event_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Common_Scheduled_Event_Stream_Cursor_Input>>;
  where?:InputMaybe<Common_Scheduled_Event_Bool_Exp>;
};


export type Subscription_RootCommon_Skill_TagArgs = {
  distinct_on?:InputMaybe<Array<Common_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Skill_Tag_Order_By>>;
  where?:InputMaybe<Common_Skill_Tag_Bool_Exp>;
};


export type Subscription_RootCommon_Skill_Tag_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_Skill_Tag_Order_By>>;
  where?:InputMaybe<Common_Skill_Tag_Bool_Exp>;
};


export type Subscription_RootCommon_Skill_Tag_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootCommon_Skill_Tag_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Common_Skill_Tag_Stream_Cursor_Input>>;
  where?:InputMaybe<Common_Skill_Tag_Bool_Exp>;
};


export type Subscription_RootCommon_UserArgs = {
  distinct_on?:InputMaybe<Array<Common_User_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_Order_By>>;
  where?:InputMaybe<Common_User_Bool_Exp>;
};


export type Subscription_RootCommon_User_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_User_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_Order_By>>;
  where?:InputMaybe<Common_User_Bool_Exp>;
};


export type Subscription_RootCommon_User_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootCommon_User_RoleArgs = {
  distinct_on?:InputMaybe<Array<Common_User_Role_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_Role_Order_By>>;
  where?:InputMaybe<Common_User_Role_Bool_Exp>;
};


export type Subscription_RootCommon_User_Role_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_User_Role_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_Role_Order_By>>;
  where?:InputMaybe<Common_User_Role_Bool_Exp>;
};


export type Subscription_RootCommon_User_Role_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootCommon_User_Role_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Common_User_Role_Stream_Cursor_Input>>;
  where?:InputMaybe<Common_User_Role_Bool_Exp>;
};


export type Subscription_RootCommon_User_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Common_User_Stream_Cursor_Input>>;
  where?:InputMaybe<Common_User_Bool_Exp>;
};


export type Subscription_RootCommon_User_User_RoleArgs = {
  distinct_on?:InputMaybe<Array<Common_User_User_Role_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_User_Role_Order_By>>;
  where?:InputMaybe<Common_User_User_Role_Bool_Exp>;
};


export type Subscription_RootCommon_User_User_Role_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Common_User_User_Role_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Common_User_User_Role_Order_By>>;
  where?:InputMaybe<Common_User_User_Role_Bool_Exp>;
};


export type Subscription_RootCommon_User_User_Role_By_PkArgs = {
  user_id:Scalars['Int'];
  user_role_id:Scalars['Int'];
};


export type Subscription_RootCommon_User_User_Role_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Common_User_User_Role_Stream_Cursor_Input>>;
  where?:InputMaybe<Common_User_User_Role_Bool_Exp>;
};


export type Subscription_RootHasura_Action_Permissions_ViewArgs = {
  distinct_on?:InputMaybe<Array<Hasura_Action_Permissions_View_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Hasura_Action_Permissions_View_Order_By>>;
  where?:InputMaybe<Hasura_Action_Permissions_View_Bool_Exp>;
};


export type Subscription_RootHasura_Action_Permissions_View_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Hasura_Action_Permissions_View_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Hasura_Action_Permissions_View_Order_By>>;
  where?:InputMaybe<Hasura_Action_Permissions_View_Bool_Exp>;
};


export type Subscription_RootHasura_Action_Permissions_View_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Hasura_Action_Permissions_View_Stream_Cursor_Input>>;
  where?:InputMaybe<Hasura_Action_Permissions_View_Bool_Exp>;
};


export type Subscription_RootHasura_Inherited_Roles_ViewArgs = {
  distinct_on?:InputMaybe<Array<Hasura_Inherited_Roles_View_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Hasura_Inherited_Roles_View_Order_By>>;
  where?:InputMaybe<Hasura_Inherited_Roles_View_Bool_Exp>;
};


export type Subscription_RootHasura_Inherited_Roles_View_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Hasura_Inherited_Roles_View_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Hasura_Inherited_Roles_View_Order_By>>;
  where?:InputMaybe<Hasura_Inherited_Roles_View_Bool_Exp>;
};


export type Subscription_RootHasura_Inherited_Roles_View_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Hasura_Inherited_Roles_View_Stream_Cursor_Input>>;
  where?:InputMaybe<Hasura_Inherited_Roles_View_Bool_Exp>;
};


export type Subscription_RootHasura_Metadata_ViewArgs = {
  distinct_on?:InputMaybe<Array<Hasura_Metadata_View_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Hasura_Metadata_View_Order_By>>;
  where?:InputMaybe<Hasura_Metadata_View_Bool_Exp>;
};


export type Subscription_RootHasura_Metadata_View_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Hasura_Metadata_View_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Hasura_Metadata_View_Order_By>>;
  where?:InputMaybe<Hasura_Metadata_View_Bool_Exp>;
};


export type Subscription_RootHasura_Metadata_View_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Hasura_Metadata_View_Stream_Cursor_Input>>;
  where?:InputMaybe<Hasura_Metadata_View_Bool_Exp>;
};


export type Subscription_RootHasura_Permissions_ViewArgs = {
  distinct_on?:InputMaybe<Array<Hasura_Permissions_View_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Hasura_Permissions_View_Order_By>>;
  where?:InputMaybe<Hasura_Permissions_View_Bool_Exp>;
};


export type Subscription_RootHasura_Permissions_View_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Hasura_Permissions_View_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Hasura_Permissions_View_Order_By>>;
  where?:InputMaybe<Hasura_Permissions_View_Bool_Exp>;
};


export type Subscription_RootHasura_Permissions_View_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Hasura_Permissions_View_Stream_Cursor_Input>>;
  where?:InputMaybe<Hasura_Permissions_View_Bool_Exp>;
};


export type Subscription_RootRecruiting_Allocation_PositionArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Allocation_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Allocation_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Allocation_Position_Bool_Exp>;
};


export type Subscription_RootRecruiting_Allocation_Position_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Allocation_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Allocation_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Allocation_Position_Bool_Exp>;
};


export type Subscription_RootRecruiting_Allocation_Position_By_PkArgs = {
  allocation_id:Scalars['Int'];
  position_id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Allocation_Position_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Allocation_Position_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Allocation_Position_Bool_Exp>;
};


export type Subscription_RootRecruiting_CandidateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_AllocationArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Allocation_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Allocation_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Allocation_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Allocation_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Allocation_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Allocation_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Allocation_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Allocation_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Candidate_Allocation_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Candidate_Allocation_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Candidate_Allocation_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_AttachmentArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Attachment_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Attachment_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Attachment_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Attachment_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Attachment_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Attachment_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Attachment_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Attachment_By_PkArgs = {
  attachment_id:Scalars['Int'];
  candidate_id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Candidate_Attachment_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Candidate_Attachment_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Candidate_Attachment_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Candidate_NoteArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Note_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Note_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Note_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Note_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Note_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Note_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Note_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Note_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Candidate_Note_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Candidate_Note_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Candidate_Note_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Note_TypeArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Note_Type_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Note_Type_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Note_Type_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Note_Type_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Note_Type_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Note_Type_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Note_Type_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Note_Type_By_PkArgs = {
  type:Scalars['String'];
};


export type Subscription_RootRecruiting_Candidate_Note_Type_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Candidate_Note_Type_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Candidate_Note_Type_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Position_GroupArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Position_Group_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Position_Group_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Position_Group_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Position_Group_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Position_Group_By_PkArgs = {
  candidate_id:Scalars['Int'];
  position_group_id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Candidate_Position_Group_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Candidate_Position_Group_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Candidate_Position_Group_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Scheduled_EventArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Scheduled_Event_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Scheduled_Event_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Scheduled_Event_By_PkArgs = {
  candidate_id:Scalars['Int'];
  scheduled_call_id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Candidate_Scheduled_Event_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Candidate_Scheduled_Event_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Candidate_Scheduled_Event_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Skill_TagArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Skill_Tag_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Skill_Tag_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Skill_Tag_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Skill_Tag_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Skill_Tag_By_PkArgs = {
  candidate_id:Scalars['Int'];
  skill_tag_id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Candidate_Skill_Tag_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Candidate_Skill_Tag_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Candidate_Skill_Tag_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_StatusArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Status_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Status_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Status_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Status_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Status_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Status_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Status_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Status_By_PkArgs = {
  status:Scalars['String'];
};


export type Subscription_RootRecruiting_Candidate_Status_ReasonArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Status_Reason_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Status_Reason_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Status_Reason_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Status_Reason_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_Status_Reason_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_Status_Reason_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_Status_Reason_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Status_Reason_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Candidate_Status_Reason_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Candidate_Status_Reason_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Candidate_Status_Reason_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_Status_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Candidate_Status_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Candidate_Status_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Candidate_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Candidate_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_User_InterviewerArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_User_Interviewer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_User_Interviewer_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_User_Interviewer_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_User_Interviewer_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Candidate_User_Interviewer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Candidate_User_Interviewer_Order_By>>;
  where?:InputMaybe<Recruiting_Candidate_User_Interviewer_Bool_Exp>;
};


export type Subscription_RootRecruiting_Candidate_User_Interviewer_By_PkArgs = {
  candidate_id:Scalars['Int'];
  user_id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Candidate_User_Interviewer_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Candidate_User_Interviewer_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Candidate_User_Interviewer_Bool_Exp>;
};


export type Subscription_RootRecruiting_Interview_FlowArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Interview_Flow_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Interview_Flow_Order_By>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Bool_Exp>;
};


export type Subscription_RootRecruiting_Interview_Flow_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Interview_Flow_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Interview_Flow_Order_By>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Bool_Exp>;
};


export type Subscription_RootRecruiting_Interview_Flow_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Interview_Flow_StageArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Interview_Flow_Stage_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Interview_Flow_Stage_Order_By>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Stage_Bool_Exp>;
};


export type Subscription_RootRecruiting_Interview_Flow_Stage_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Interview_Flow_Stage_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Interview_Flow_Stage_Order_By>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Stage_Bool_Exp>;
};


export type Subscription_RootRecruiting_Interview_Flow_Stage_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Interview_Flow_Stage_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Interview_Flow_Stage_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Stage_Bool_Exp>;
};


export type Subscription_RootRecruiting_Interview_Flow_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Interview_Flow_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Bool_Exp>;
};


export type Subscription_RootRecruiting_Interview_Flow_SubstageArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Interview_Flow_Substage_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Interview_Flow_Substage_Order_By>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Substage_Bool_Exp>;
};


export type Subscription_RootRecruiting_Interview_Flow_Substage_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Interview_Flow_Substage_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Interview_Flow_Substage_Order_By>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Substage_Bool_Exp>;
};


export type Subscription_RootRecruiting_Interview_Flow_Substage_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Interview_Flow_Substage_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Interview_Flow_Substage_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Interview_Flow_Substage_Bool_Exp>;
};


export type Subscription_RootRecruiting_OfferArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Offer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Offer_Order_By>>;
  where?:InputMaybe<Recruiting_Offer_Bool_Exp>;
};


export type Subscription_RootRecruiting_Offer_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Offer_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Offer_Order_By>>;
  where?:InputMaybe<Recruiting_Offer_Bool_Exp>;
};


export type Subscription_RootRecruiting_Offer_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Offer_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Offer_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Offer_Bool_Exp>;
};


export type Subscription_RootRecruiting_Offer_TemplateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Offer_Template_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Offer_Template_Order_By>>;
  where?:InputMaybe<Recruiting_Offer_Template_Bool_Exp>;
};


export type Subscription_RootRecruiting_Offer_Template_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Offer_Template_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Offer_Template_Order_By>>;
  where?:InputMaybe<Recruiting_Offer_Template_Bool_Exp>;
};


export type Subscription_RootRecruiting_Offer_Template_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Offer_Template_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Offer_Template_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Offer_Template_Bool_Exp>;
};


export type Subscription_RootRecruiting_PositionArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Bool_Exp>;
};


export type Subscription_RootRecruiting_Position_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Bool_Exp>;
};


export type Subscription_RootRecruiting_Position_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Position_GroupArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Group_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Group_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
};


export type Subscription_RootRecruiting_Position_Group_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Group_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Group_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
};


export type Subscription_RootRecruiting_Position_Group_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Position_Group_PriorityArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Group_Priority_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Group_Priority_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Group_Priority_Bool_Exp>;
};


export type Subscription_RootRecruiting_Position_Group_Priority_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Group_Priority_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Group_Priority_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Group_Priority_Bool_Exp>;
};


export type Subscription_RootRecruiting_Position_Group_Priority_By_PkArgs = {
  position_group_id:Scalars['Int'];
  user_id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Position_Group_Priority_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Position_Group_Priority_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Position_Group_Priority_Bool_Exp>;
};


export type Subscription_RootRecruiting_Position_Group_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Position_Group_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Position_Group_Bool_Exp>;
};


export type Subscription_RootRecruiting_Position_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Position_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Position_Bool_Exp>;
};


export type Subscription_RootRecruiting_Position_TemplateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Template_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Template_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Template_Bool_Exp>;
};


export type Subscription_RootRecruiting_Position_Template_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Recruiting_Position_Template_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Recruiting_Position_Template_Order_By>>;
  where?:InputMaybe<Recruiting_Position_Template_Bool_Exp>;
};


export type Subscription_RootRecruiting_Position_Template_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootRecruiting_Position_Template_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Recruiting_Position_Template_Stream_Cursor_Input>>;
  where?:InputMaybe<Recruiting_Position_Template_Bool_Exp>;
};


export type Subscription_RootStaffing_Customer_TeamArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Bool_Exp>;
};


export type Subscription_RootStaffing_Customer_Team_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Bool_Exp>;
};


export type Subscription_RootStaffing_Customer_Team_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootStaffing_Customer_Team_SlotArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
};


export type Subscription_RootStaffing_Customer_Team_Slot_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
};


export type Subscription_RootStaffing_Customer_Team_Slot_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootStaffing_Customer_Team_Slot_StatusArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Status_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Status_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Status_Bool_Exp>;
};


export type Subscription_RootStaffing_Customer_Team_Slot_Status_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Slot_Status_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Slot_Status_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Status_Bool_Exp>;
};


export type Subscription_RootStaffing_Customer_Team_Slot_Status_By_PkArgs = {
  status:Scalars['String'];
};


export type Subscription_RootStaffing_Customer_Team_Slot_Status_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Staffing_Customer_Team_Slot_Status_Stream_Cursor_Input>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Status_Bool_Exp>;
};


export type Subscription_RootStaffing_Customer_Team_Slot_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Staffing_Customer_Team_Slot_Stream_Cursor_Input>>;
  where?:InputMaybe<Staffing_Customer_Team_Slot_Bool_Exp>;
};


export type Subscription_RootStaffing_Customer_Team_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Staffing_Customer_Team_Stream_Cursor_Input>>;
  where?:InputMaybe<Staffing_Customer_Team_Bool_Exp>;
};


export type Subscription_RootStaffing_Customer_Team_TagArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Tag_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Tag_Bool_Exp>;
};


export type Subscription_RootStaffing_Customer_Team_Tag_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Customer_Team_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Customer_Team_Tag_Order_By>>;
  where?:InputMaybe<Staffing_Customer_Team_Tag_Bool_Exp>;
};


export type Subscription_RootStaffing_Customer_Team_Tag_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootStaffing_Customer_Team_Tag_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Staffing_Customer_Team_Tag_Stream_Cursor_Input>>;
  where?:InputMaybe<Staffing_Customer_Team_Tag_Bool_Exp>;
};


export type Subscription_RootStaffing_StaffArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Bool_Exp>;
};


export type Subscription_RootStaffing_Staff_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Bool_Exp>;
};


export type Subscription_RootStaffing_Staff_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootStaffing_Staff_RoleArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Role_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Role_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Role_Bool_Exp>;
};


export type Subscription_RootStaffing_Staff_Role_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Role_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Role_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Role_Bool_Exp>;
};


export type Subscription_RootStaffing_Staff_Role_By_PkArgs = {
  id:Scalars['Int'];
};


export type Subscription_RootStaffing_Staff_Role_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Staffing_Staff_Role_Stream_Cursor_Input>>;
  where?:InputMaybe<Staffing_Staff_Role_Bool_Exp>;
};


export type Subscription_RootStaffing_Staff_Skill_TagArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Skill_Tag_Bool_Exp>;
};


export type Subscription_RootStaffing_Staff_Skill_Tag_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Skill_Tag_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Skill_Tag_Bool_Exp>;
};


export type Subscription_RootStaffing_Staff_Skill_Tag_By_PkArgs = {
  skill_tag_id:Scalars['Int'];
  staff_id:Scalars['Int'];
};


export type Subscription_RootStaffing_Staff_Skill_Tag_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Staffing_Staff_Skill_Tag_Stream_Cursor_Input>>;
  where?:InputMaybe<Staffing_Staff_Skill_Tag_Bool_Exp>;
};


export type Subscription_RootStaffing_Staff_StatusArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Status_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Status_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Status_Bool_Exp>;
};


export type Subscription_RootStaffing_Staff_Status_AggregateArgs = {
  distinct_on?:InputMaybe<Array<Staffing_Staff_Status_Select_Column>>;
  limit?:InputMaybe<Scalars['Int']>;
  offset?:InputMaybe<Scalars['Int']>;
  order_by?:InputMaybe<Array<Staffing_Staff_Status_Order_By>>;
  where?:InputMaybe<Staffing_Staff_Status_Bool_Exp>;
};


export type Subscription_RootStaffing_Staff_Status_By_PkArgs = {
  status:Scalars['String'];
};


export type Subscription_RootStaffing_Staff_Status_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Staffing_Staff_Status_Stream_Cursor_Input>>;
  where?:InputMaybe<Staffing_Staff_Status_Bool_Exp>;
};


export type Subscription_RootStaffing_Staff_StreamArgs = {
  batch_size:Scalars['Int'];
  cursor:Array<InputMaybe<Staffing_Staff_Stream_Cursor_Input>>;
  where?:InputMaybe<Staffing_Staff_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?:InputMaybe<Scalars['timestamp']>;
  _gt?:InputMaybe<Scalars['timestamp']>;
  _gte?:InputMaybe<Scalars['timestamp']>;
  _in?:InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _lt?:InputMaybe<Scalars['timestamp']>;
  _lte?:InputMaybe<Scalars['timestamp']>;
  _neq?:InputMaybe<Scalars['timestamp']>;
  _nin?:InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?:InputMaybe<Scalars['timestamptz']>;
  _gt?:InputMaybe<Scalars['timestamptz']>;
  _gte?:InputMaybe<Scalars['timestamptz']>;
  _in?:InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?:InputMaybe<Scalars['Boolean']>;
  _lt?:InputMaybe<Scalars['timestamptz']>;
  _lte?:InputMaybe<Scalars['timestamptz']>;
  _neq?:InputMaybe<Scalars['timestamptz']>;
  _nin?:InputMaybe<Array<Scalars['timestamptz']>>;
};
