import { MenuItem, Position } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { Button, ButtonSize } from '@flow/common/components/form/Button';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { component, di } from '@flow/dependency-injection';
import { CandidateFlowController } from '@flow/modules/recruiting/candidates/CandidateFlowController';
import { CandidateFlowState } from '@flow/modules/recruiting/candidates/CandidateFlowState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './MoreActionsSelector.module.less';

const cx = classNames.bind(styles);

enum MoreActionType
{
  TO_INITIAL = 'TO_INITIAL',
  ARCHIVE = 'ARCHIVE',
}

interface MoreActionItem
{
  id:MoreActionType;
  title:string;
}

const moreActionItems:Array<MoreActionItem> = [
  {
    id: MoreActionType.TO_INITIAL,
    title: 'To Initial'
  },
  {
    id: MoreActionType.ARCHIVE,
    title: 'Archive...'
  }
];

@component
export class MoreActionsSelector extends React.Component
{
  @di private _candidateFlowState!:CandidateFlowState;
  @di private _candidateFlowController!:CandidateFlowController;
  @di private _roleController!:RoleController;

  // ---------------------------------------------------------

  @bind
  private _itemRenderer(
    moreAcction:MoreActionItem,
    { handleClick, modifiers }:IItemRendererProps):JSX.Element | null
  {
    if( !modifiers.matchesPredicate ) return null;

    return (
      <MenuItem
        key={moreAcction.id}
        className={styles.menuItemMoreActions}
        text={moreAcction.title}
        onClick={handleClick}
        active={modifiers.active}
        // selected={moreAcction.id === candidateFlowStageId}
        // disabled={moreAcction.id == null}
      />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _onItemSelect(moreAcction:MoreActionItem):void
  {
    console.log('%c _onItemSelect =', 'background:#0f0;color:#000;', moreAcction);

    switch( moreAcction.id )
    {
      case MoreActionType.TO_INITIAL:
        this._candidateFlowController.showToInitialDialog(true);
        break;

      case MoreActionType.ARCHIVE:
        this._candidateFlowController.showArchiveDialog(true);
        break;
    }
  }

  // ---------------------------------------------------------

  public render():ReactNode | null
  {
    const {
      isCandidateOnFlow
    } = this._candidateFlowState;

    if( !isCandidateOnFlow ) return null;

    const isDisabled:boolean = !this._roleController.hasPermission(FlowPermissions.ChangeCandidateFlowStage);

    // const buttonText = status == Recruiting_Candidate_Status_Enum.Active
    //   ? candidateFlowStage?.name
    //   : CandidateStatusTitles.get(String(status));

    return (
      <Select
        className={styles.selectMore}
        items={moreActionItems}
        itemRenderer={this._itemRenderer}
        onItemSelect={this._onItemSelect}
        filterable={false}
        disabled={isDisabled}
        popoverProps={{
          minimal: true,
          usePortal: false,
          position: Position.BOTTOM_RIGHT
        }}
      >
        <Button
          className={cx(styles.btnMoreActions, { isDisabled })}
          text={'More actions'}
          disabled={isDisabled}
          minimal={true}
          outlined={true}
          // intent={Intent.NONE}
          // intent={isDisabled ? Intent.NONE : Intent.PRIMARY}
          size={ButtonSize.LARGE}
        />
      </Select>
    );
  }

  // ---------------------------------------------------------
}
