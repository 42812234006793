import type { ApolloQueryResult, FetchResult } from '@apollo/client';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import { AuthState } from '@flow/common/controllers/AuthState';
import { Maybe } from '@flow/common/models/Types';
import { LocalStorageUtil } from '@flow/common/utils/LocalStorageUtil';
import { PageUtil } from '@flow/common/utils/PageUtil';
import { WindowLocationUtil } from '@flow/common/utils/WindowLocationUtil';
import type {
  AddNewStaffUserMutation,
  AddNewStaffUserMutationVariables,
  StaffListQuery,
  StaffListQueryVariables
} from '@flow/data-access/lib/staff.generated';
import { AddNewStaffUserDocument, StaffListDocument } from '@flow/data-access/lib/staff.generated';
import type {
  Common_City,
  Common_Country,
  Common_Filter,
  Staffing_Staff
} from '@flow/data-access/lib/types/graphql.generated';
import { Common_Filter_Type_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import { StaffFiltersController } from '@flow/modules/staffing/staff/StaffFiltersController';
import { StaffSiteSubsiteState } from '@flow/modules/staffing/staff/StaffSiteSubsiteState';
import {
  StaffListState_LS_KEY,
  StaffListUrl_LS_KEY,
  StaffShowArchived_HASK_KEY,
  StaffState
} from '@flow/modules/staffing/staff/StaffState';
import bind from 'bind-decorator';
import { action, reaction, runInAction } from 'mobx';

@controller
export class StaffController
{
  @di private _authState!:AuthState;
  @di private _commonState!:CommonState;
  @di private _staffState!:StaffState;
  @di private _staffSiteSubsiteState!:StaffSiteSubsiteState;

  @di private _commonController!:CommonController;
  @di private _staffFiltersController!:StaffFiltersController;

  // ----------------------------------------------------

  @action.bound
  public initLocalStorageReaction():void
  {
    const { disposers } = this._staffState;

    Array.isArray(disposers) && disposers.push(reaction(
      () =>
      {
        const {
          selectedFilterId, isFiltersDirty, isShowArchived
        } = this._staffState;

        return {
          selectedFilterId, isFiltersDirty, isShowArchived
        };
      },
      (lsValue:Partial<StaffState>) =>
      {
        console.log('%c StaffState: lsValue =', 'background:#0ff;color:#000;', lsValue);
        // LocalStorageUtil.setItem(StaffListState_LS_KEY, lsValue);
        this.setStateToLocalStorage();

        if( this._staffState.isShowArchived )
          WindowLocationUtil.setHashVariable(StaffShowArchived_HASK_KEY, '1');
        else
          WindowLocationUtil.removeHashVariable(StaffShowArchived_HASK_KEY);
      }
    ));
  }

  // ----------------------------------------------------

  public setStateToLocalStorage():void
  {
    const { selectedFilterId, isFiltersDirty, isShowArchived } = this._staffState;

    const lsValue:Partial<StaffState> = {
      selectedFilterId, isFiltersDirty, isShowArchived
    };

    console.log('%c setStateToLocalStorage: lsValue =', 'background:#0ff;color:#000;', lsValue);

    LocalStorageUtil.setItem(StaffListState_LS_KEY, lsValue);
  }

  // ----------------------------------------------------

  @action.bound
  public applyFromLocalStorage():void
  {
    const lsState:Partial<StaffState> = LocalStorageUtil.getItem(StaffListState_LS_KEY);
    const lsUrl:object = LocalStorageUtil.getItem(StaffListUrl_LS_KEY);

    this._staffState.isApplyingFromLocalStorage = true;

    console.log('%c applyFromLocalStorage: lsState =', 'background:#0ff;color:#000;', lsState);
    if( lsState )
    {
      // this._staffState.selectedFilterId = lsState.selectedFilterId || null;
      this._staffFiltersController.selectUserFilter(lsState.selectedFilterId || null);
      this._staffState.isFiltersDirty = lsState.isFiltersDirty || false;
      this._staffState.isShowArchived = lsState.isShowArchived || false;

      // !!! ???
      // this.setQueryString();
    }

    if( this._staffState.isShowArchived )
      WindowLocationUtil.setHashVariable(StaffShowArchived_HASK_KEY, '1');
    else
      WindowLocationUtil.removeHashVariable(StaffShowArchived_HASK_KEY);

    console.log('%c applyFromLocalStorage:   lsUrl =', 'background:#0ff;color:#000;', lsUrl);
    if( lsUrl )
    {
      WindowLocationUtil.setQueryString(lsUrl);
      this.applyQueryString();
    }

    this._staffState.isApplyingFromLocalStorage = false;
  }

  // ----------------------------------------------------

  @action.bound
  public async initStaffList():Promise<void>
  {
    // this.setViewCandidateId(null);
    // this._staffFiltersController.setUserFilter(null);

    const { disposers } = this._staffState;

    Array.isArray(disposers) && disposers.push(reaction(
      () =>
      {
        if( typeof (this._staffFiltersController.getFiltersJSONString) == 'function' )
          return this._staffFiltersController.getFiltersJSONString();

        return '';
      },
      (filtersJSONString:string) =>
      {
        console.log('%c reaction: filtersJSONString =', 'background:#f0f;color:#fff;', filtersJSONString);
        const { selectedFilterId, userFilterById, isApplyingFromLocalStorage } = this._staffState;

        if( isApplyingFromLocalStorage )
        {
          console.log('%c reaction: isApplyingFromLocalStorage =', 'background:#f0f;color:#fff;', isApplyingFromLocalStorage);
          runInAction(() =>
          {
            this._staffState.isApplyingFromLocalStorage = false;
          });
          return;
        }

        let isDirty:boolean = false;

        if( selectedFilterId )
        {
          const userFilter:Common_Filter | undefined = userFilterById(selectedFilterId);

          if( !userFilter ) return; // !!!

          isDirty = filtersJSONString !== userFilter.content;
        }
        else
        {
          isDirty = filtersJSONString !== '{}';
        }

        runInAction(() =>
        {
          this._staffState.isFiltersDirty = isDirty;
        });

        this._staffFiltersController.setQueryString();
      }
    ));

    Array.isArray(disposers) && disposers.push(reaction(
      () => this._staffState.columns,
      (/* columns:Array<IStaffColumn> */) =>
      {
        this._staffFiltersController.setQueryString();
      }
    ));

    // --------------------

    const { user } = this._authState;

    const result:ApolloQueryResult<StaffListQuery> =
      await this._commonController.query<StaffListQuery,
        StaffListQueryVariables>({
        query: StaffListDocument,
        variables: {
          userId: user?.id || -1,
          userFiltersType: Common_Filter_Type_Enum.Staffing // TODO staffing !!!
        }
      });

    console.log('%c initStaffList result =', 'background:#f60;color:#000;', result);

    runInAction(() =>
    {
      const {
        staffing_staff,
        common_country,
        common_city,
        common_filter
      } = result.data;

      this._staffState.staffUsers = staffing_staff as Array<Staffing_Staff>;
      this._staffState.userFilters = common_filter as Array<Common_Filter>;

      this._staffSiteSubsiteState.countries = common_country as Array<Common_Country>;
      this._staffSiteSubsiteState.cities = common_city as Array<Common_City>;

      this.applyQueryString();

      this._staffState.isStaffUsersLoaded = true;
    });
  }

  // ----------------------------------------------------

  @bind
  public disposeReactions():void
  {
    PageUtil.disposeReactions(this._staffState.disposers);
  }

  // ----------------------------------------------------

  @action.bound
  public setViewStaffId(staffId:number | null):void
  {
    console.log('%c setViewStaffId =', 'background:#00f;color:#fff;', staffId);
    this._staffState.viewStaffId = staffId || null;

    this._staffState.isViewStaffListPageContent = !staffId;
  }

  // ----------------------------------------------------

  @action.bound
  public showNewStaffUserDialog():void
  {
    this._staffState.isNewStaffUserDialogOpen = true;
    this._staffState.isNewStaffUserLoading = false;

    this._staffState.newStaffUserName = '';
    this._staffState.newStaffUserEmail = '';
  }

  @action.bound
  public hideNewStaffUserDialog():void
  {
    this._staffState.isNewStaffUserDialogOpen = false;
    this._staffState.isNewStaffUserLoading = false;
  }

  @action.bound
  public setNewStaffUserName(value:string):void
  {
    this._staffState.newStaffUserName = value;
  }

  @action.bound
  public setNewStaffUserEmail(value:string):void
  {
    this._staffState.newStaffUserEmail = value;
  }

  @action.bound
  public async saveNewStaffUser():Promise<number | null>
  {
    const fullName:string = this._staffState.newStaffUserName.trim();
    const email:string = this._staffState.newStaffUserEmail.trim();

    const names:Array<string> = fullName?.trim().split(' ');

    const firstName:string = names?.shift() || '';
    const lastName:string = names?.join(' ') || '';

    console.log('%c saveNewStaffUser =', 'background:#aaf;color:#000;');

    if( !firstName || !lastName )
    {
      !firstName && console.log('%c ERROR firstNAme =', 'background:#f00;color:#ff0;', firstName);
      !lastName && console.log('%c ERROR lastName =', 'background:#f00;color:#ff0;', lastName);
      return null;
    }

    console.log('%c ---        firstName =', 'background:#080;color:#000;', firstName);
    console.log('%c ---         lastName =', 'background:#080;color:#000;', lastName);
    console.log('%c ---            email =', 'background:#080;color:#000;', email);

    // const { user } = this._authState;

    const result:FetchResult<AddNewStaffUserMutation> =
      await this._commonController.mutate<AddNewStaffUserMutation,
        AddNewStaffUserMutationVariables>({
        mutation: AddNewStaffUserDocument,
        variables: {
          first_name: firstName,
          last_name: lastName,
          email
        }
      });

    console.log('%c AddNewStaffUserMutation result =', 'background:#f60;color:#000;', result);

    let newStaffUserId:number | null = null;

    if( result && result.data )
    {
      const newStaffUser:Staffing_Staff = result?.data?.insert_staffing_staff?.returning[0] as Staffing_Staff;

      if( newStaffUser )
      {
        runInAction(() => this._staffState.staffUsers.push(newStaffUser));
        newStaffUserId = newStaffUser.id;
      }
    }

    if( !newStaffUserId )
    {
      // TODO: show ERRORS
    }

    this.hideNewStaffUserDialog();

    return null;
  }

  // ----------------------------------------------------

  @action.bound
  public applyQueryString():void
  {
    const { someFilterIsSelected, isApplyingFromLocalStorage } = this._staffState;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryObject:any = WindowLocationUtil.getObjectFromQueryString();
    console.log('%c applyQueryString =', 'background:#f0f;color:#fff;', queryObject);

    const isShowArchived:Maybe<string> = WindowLocationUtil.getHashVariable(StaffShowArchived_HASK_KEY);
    this._staffState.isShowArchived = !!isShowArchived;
    console.log('%c --> isShowArchived =', 'background:#808;color:#fff;', isShowArchived);

    const aQSF:boolean = this._staffFiltersController.applyQueryStringFilters(queryObject);
    const aQSC:boolean = this._staffFiltersController.applyQueryStringColumns(queryObject);

    if( isApplyingFromLocalStorage ) return;

    if( aQSF || aQSC )
    {
      this.setStateToLocalStorage();
    }
    else if( !aQSF && !aQSC )
    {
      this.applyFromLocalStorage();
    }
    else if( someFilterIsSelected )
    {
      this._staffState.isFiltersDirty = true;
    }
  }

  // ----------------------------------------------------

  @action.bound
  public setCurrentPage(newCurrentPage:number):void
  {
    this._staffState.currentPage = newCurrentPage;
  }

  // ----------------------------------------------------
}
