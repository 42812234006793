import type * as Types from './types/graphql.generated';

import { gql } from '@apollo/client';
import { Open_Slots_CountFragmentDoc, Total_Slots_CountFragmentDoc, Leaving_SlotsFragmentDoc } from './fragments.generated';
import * as Apollo from '@apollo/client';
export type Customer_TeamsFragment = { id:number; name:string; is_active:boolean; notes?:string | null; customer_id:number; ui_order:number; positions:Array<{ id:number; alias?:string | null; quantity?:number | null; notes?:string | null; customer_team_id:number; ui_customer_team_order:number; is_ready:boolean; position_group?:{ id:number; name:string; } | null; position_template?:{ id:number; name:string; staff_role:{ id:number; name:string; }; } | null; customer_team:{ id:number; customer_id:number; }; open_slots_count:{ aggregate?:{ count:number; } | null; }; total_slots_count:{ aggregate?:{ count:number; } | null; }; }>; customer_team_tags:Array<{ id:number; name:string; }>; };

export type CustomersTeamsQueryVariables = Types.Exact<{
  _eq?:Types.InputMaybe<Types.Common_Customer_Status_Enum>;
}>;


export type CustomersTeamsQuery = { common_customer:Array<{ id:number; name:string; alias:string; status:Types.Common_Customer_Status_Enum; is_internal:boolean; customer_teams:Array<{ id:number; name:string; is_active:boolean; notes?:string | null; customer_id:number; ui_order:number; positions:Array<{ id:number; alias?:string | null; quantity?:number | null; notes?:string | null; customer_team_id:number; ui_customer_team_order:number; is_ready:boolean; position_group?:{ id:number; name:string; } | null; position_template?:{ id:number; name:string; staff_role:{ id:number; name:string; }; } | null; customer_team:{ id:number; customer_id:number; }; open_slots_count:{ aggregate?:{ count:number; } | null; }; total_slots_count:{ aggregate?:{ count:number; } | null; }; }>; customer_team_tags:Array<{ id:number; name:string; }>; }>; }>; internal_customer:Array<{ id:number; name:string; alias:string; status:Types.Common_Customer_Status_Enum; is_internal:boolean; customer_teams:Array<{ id:number; name:string; is_active:boolean; notes?:string | null; customer_id:number; ui_order:number; positions:Array<{ id:number; alias?:string | null; quantity?:number | null; notes?:string | null; customer_team_id:number; ui_customer_team_order:number; is_ready:boolean; position_group?:{ id:number; name:string; } | null; position_template?:{ id:number; name:string; staff_role:{ id:number; name:string; }; } | null; customer_team:{ id:number; customer_id:number; }; open_slots_count:{ aggregate?:{ count:number; } | null; }; total_slots_count:{ aggregate?:{ count:number; } | null; }; }>; customer_team_tags:Array<{ id:number; name:string; }>; }>; }>; };

export type CustomersTeamsNoNameQueryVariables = Types.Exact<{
  _eq?:Types.InputMaybe<Types.Common_Customer_Status_Enum>;
}>;


export type CustomersTeamsNoNameQuery = { common_customer:Array<{ id:number; alias:string; status:Types.Common_Customer_Status_Enum; is_internal:boolean; customer_teams:Array<{ id:number; name:string; is_active:boolean; notes?:string | null; customer_id:number; ui_order:number; positions:Array<{ id:number; alias?:string | null; quantity?:number | null; notes?:string | null; customer_team_id:number; ui_customer_team_order:number; is_ready:boolean; position_group?:{ id:number; name:string; } | null; position_template?:{ id:number; name:string; staff_role:{ id:number; name:string; }; } | null; customer_team:{ id:number; customer_id:number; }; open_slots_count:{ aggregate?:{ count:number; } | null; }; total_slots_count:{ aggregate?:{ count:number; } | null; }; }>; customer_team_tags:Array<{ id:number; name:string; }>; }>; }>; internal_customer:Array<{ id:number; alias:string; status:Types.Common_Customer_Status_Enum; is_internal:boolean; customer_teams:Array<{ id:number; name:string; is_active:boolean; notes?:string | null; customer_id:number; ui_order:number; positions:Array<{ id:number; alias?:string | null; quantity?:number | null; notes?:string | null; customer_team_id:number; ui_customer_team_order:number; is_ready:boolean; position_group?:{ id:number; name:string; } | null; position_template?:{ id:number; name:string; staff_role:{ id:number; name:string; }; } | null; customer_team:{ id:number; customer_id:number; }; open_slots_count:{ aggregate?:{ count:number; } | null; }; total_slots_count:{ aggregate?:{ count:number; } | null; }; }>; customer_team_tags:Array<{ id:number; name:string; }>; }>; }>; };

export type CreateCustomerMutationVariables = Types.Exact<{
  name?:Types.InputMaybe<Types.Scalars['String']>;
  alias?:Types.InputMaybe<Types.Scalars['String']>;
  status?:Types.InputMaybe<Types.Common_Customer_Status_Enum>;
}>;


export type CreateCustomerMutation = { insert_common_customer_one?:{ id:number; } | null; };

export type UpdateCustomerMutationVariables = Types.Exact<{
  customerId:Types.Scalars['Int'];
  name?:Types.InputMaybe<Types.Scalars['String']>;
  alias?:Types.InputMaybe<Types.Scalars['String']>;
  status?:Types.InputMaybe<Types.Common_Customer_Status_Enum>;
}>;


export type UpdateCustomerMutation = { update_common_customer_by_pk?:{ id:number; } | null; };

export type ChangeCustomerStatusMutationVariables = Types.Exact<{
  customerId:Types.Scalars['Int'];
  status?:Types.InputMaybe<Types.Common_Customer_Status_Enum>;
}>;


export type ChangeCustomerStatusMutation = { update_common_customer_by_pk?:{ id:number; } | null; };

export type GetCustomerQueryVariables = Types.Exact<{
  customerId:Types.Scalars['Int'];
}>;


export type GetCustomerQuery = { common_customer_by_pk?:{ id:number; name:string; alias:string; status:Types.Common_Customer_Status_Enum; notes?:string | null; customer_teams:Array<{ id:number; name:string; is_active:boolean; notes?:string | null; customer_id:number; ui_order:number; positions:Array<{ id:number; alias?:string | null; quantity?:number | null; notes?:string | null; customer_team_id:number; ui_customer_team_order:number; is_ready:boolean; position_group?:{ id:number; name:string; } | null; position_template?:{ id:number; name:string; staff_role:{ id:number; name:string; }; } | null; customer_team:{ id:number; customer_id:number; }; open_slots_count:{ aggregate?:{ count:number; } | null; }; total_slots_count:{ aggregate?:{ count:number; } | null; }; }>; customer_team_tags:Array<{ id:number; name:string; }>; }>; } | null; };

export type GetCustomerNoNameQueryVariables = Types.Exact<{
  customerId:Types.Scalars['Int'];
}>;


export type GetCustomerNoNameQuery = { common_customer_by_pk?:{ id:number; alias:string; status:Types.Common_Customer_Status_Enum; notes?:string | null; customer_teams:Array<{ id:number; name:string; is_active:boolean; notes?:string | null; customer_id:number; ui_order:number; positions:Array<{ id:number; alias?:string | null; quantity?:number | null; notes?:string | null; customer_team_id:number; ui_customer_team_order:number; is_ready:boolean; position_group?:{ id:number; name:string; } | null; position_template?:{ id:number; name:string; staff_role:{ id:number; name:string; }; } | null; customer_team:{ id:number; customer_id:number; }; open_slots_count:{ aggregate?:{ count:number; } | null; }; total_slots_count:{ aggregate?:{ count:number; } | null; }; }>; customer_team_tags:Array<{ id:number; name:string; }>; }>; } | null; };

export type UpdateCustomerTeamUiOrderMutationVariables = Types.Exact<{
  id:Types.Scalars['Int'];
  ui_order:Types.Scalars['Int'];
}>;


export type UpdateCustomerTeamUiOrderMutation = { update_staffing_customer_team_by_pk?:{ id:number; ui_order:number; } | null; };

export type UpdatePositionUiCustomerTeamOrderMutationVariables = Types.Exact<{
  positions:Array<Types.Recruiting_Position_Insert_Input> | Types.Recruiting_Position_Insert_Input;
}>;


export type UpdatePositionUiCustomerTeamOrderMutation = { insert_recruiting_position?:{ affected_rows:number; } | null; };

export const Customer_TeamsFragmentDoc = gql`
    fragment customer_teams on staffing_customer_team {
  id
  name
  is_active
  notes
  customer_id
  ui_order
  positions(
    where: {deleted_at: {_is_null: true}}
    order_by: {ui_customer_team_order: asc}
  ) {
    id
    alias
    quantity
    notes
    customer_team_id
    ui_customer_team_order
    is_ready
    position_group {
      id
      name
    }
    position_template {
      id
      name
      staff_role {
        id
        name
      }
    }
    customer_team {
      id
      customer_id
    }
    ...open_slots_count
    ...total_slots_count
  }
  customer_team_tags {
    id
    name
  }
}
    ${Open_Slots_CountFragmentDoc}
${Total_Slots_CountFragmentDoc}`;
export const CustomersTeamsDocument = gql`
    query CustomersTeams($_eq: common_customer_status_enum = current) {
  common_customer(
    where: {status: {_eq: $_eq}, _and: {is_internal: {_eq: false}}}
    order_by: {alias: asc}
  ) {
    id
    name
    alias
    status
    is_internal
    customer_teams(where: {deleted_at: {_is_null: true}}) {
      ...customer_teams
    }
  }
  internal_customer: common_customer(
    where: {status: {_eq: $_eq}, _and: {is_internal: {_eq: true}}}
    order_by: {alias: asc}
  ) {
    id
    name
    alias
    status
    is_internal
    customer_teams(where: {deleted_at: {_is_null: true}}) {
      ...customer_teams
    }
  }
}
    ${Customer_TeamsFragmentDoc}`;
export type CustomersTeamsQueryResult = Apollo.QueryResult<CustomersTeamsQuery, CustomersTeamsQueryVariables>;
export const CustomersTeamsNoNameDocument = gql`
    query CustomersTeamsNoName($_eq: common_customer_status_enum = current) {
  common_customer(where: {status: {_eq: $_eq}, _and: {is_internal: {_eq: false}}}) {
    id
    alias
    status
    is_internal
    customer_teams(where: {deleted_at: {_is_null: true}}) {
      ...customer_teams
    }
  }
  internal_customer: common_customer(
    where: {status: {_eq: $_eq}, _and: {is_internal: {_eq: true}}}
  ) {
    id
    alias
    status
    is_internal
    customer_teams(where: {deleted_at: {_is_null: true}}) {
      ...customer_teams
    }
  }
}
    ${Customer_TeamsFragmentDoc}`;
export type CustomersTeamsNoNameQueryResult = Apollo.QueryResult<CustomersTeamsNoNameQuery, CustomersTeamsNoNameQueryVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($name: String, $alias: String, $status: common_customer_status_enum) {
  insert_common_customer_one(
    object: {name: $name, alias: $alias, status: $status}
  ) {
    id
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($customerId: Int!, $name: String, $alias: String, $status: common_customer_status_enum) {
  update_common_customer_by_pk(
    pk_columns: {id: $customerId}
    _set: {name: $name, alias: $alias, status: $status}
  ) {
    id
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const ChangeCustomerStatusDocument = gql`
    mutation ChangeCustomerStatus($customerId: Int!, $status: common_customer_status_enum) {
  update_common_customer_by_pk(
    pk_columns: {id: $customerId}
    _set: {status: $status}
  ) {
    id
  }
}
    `;
export type ChangeCustomerStatusMutationFn = Apollo.MutationFunction<ChangeCustomerStatusMutation, ChangeCustomerStatusMutationVariables>;
export type ChangeCustomerStatusMutationResult = Apollo.MutationResult<ChangeCustomerStatusMutation>;
export type ChangeCustomerStatusMutationOptions = Apollo.BaseMutationOptions<ChangeCustomerStatusMutation, ChangeCustomerStatusMutationVariables>;
export const GetCustomerDocument = gql`
    query GetCustomer($customerId: Int!) {
  common_customer_by_pk(id: $customerId) {
    id
    name
    alias
    status
    notes
    customer_teams(where: {deleted_at: {_is_null: true}}, order_by: {ui_order: asc}) {
      ...customer_teams
    }
  }
}
    ${Customer_TeamsFragmentDoc}`;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const GetCustomerNoNameDocument = gql`
    query GetCustomerNoName($customerId: Int!) {
  common_customer_by_pk(id: $customerId) {
    id
    alias
    status
    notes
    customer_teams(where: {deleted_at: {_is_null: true}}, order_by: {ui_order: asc}) {
      ...customer_teams
    }
  }
}
    ${Customer_TeamsFragmentDoc}`;
export type GetCustomerNoNameQueryResult = Apollo.QueryResult<GetCustomerNoNameQuery, GetCustomerNoNameQueryVariables>;
export const UpdateCustomerTeamUiOrderDocument = gql`
    mutation UpdateCustomerTeamUiOrder($id: Int!, $ui_order: Int!) {
  update_staffing_customer_team_by_pk(
    pk_columns: {id: $id}
    _set: {ui_order: $ui_order}
  ) {
    id
    ui_order
  }
}
    `;
export type UpdateCustomerTeamUiOrderMutationFn = Apollo.MutationFunction<UpdateCustomerTeamUiOrderMutation, UpdateCustomerTeamUiOrderMutationVariables>;
export type UpdateCustomerTeamUiOrderMutationResult = Apollo.MutationResult<UpdateCustomerTeamUiOrderMutation>;
export type UpdateCustomerTeamUiOrderMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerTeamUiOrderMutation, UpdateCustomerTeamUiOrderMutationVariables>;
export const UpdatePositionUiCustomerTeamOrderDocument = gql`
    mutation UpdatePositionUiCustomerTeamOrder($positions: [recruiting_position_insert_input!]!) {
  insert_recruiting_position(
    objects: $positions
    on_conflict: {constraint: position_pkey, update_columns: [ui_customer_team_order]}
  ) {
    affected_rows
  }
}
    `;
export type UpdatePositionUiCustomerTeamOrderMutationFn = Apollo.MutationFunction<UpdatePositionUiCustomerTeamOrderMutation, UpdatePositionUiCustomerTeamOrderMutationVariables>;
export type UpdatePositionUiCustomerTeamOrderMutationResult = Apollo.MutationResult<UpdatePositionUiCustomerTeamOrderMutation>;
export type UpdatePositionUiCustomerTeamOrderMutationOptions = Apollo.BaseMutationOptions<UpdatePositionUiCustomerTeamOrderMutation, UpdatePositionUiCustomerTeamOrderMutationVariables>;
export const namedOperations = {
  Query: {
    CustomersTeams: 'CustomersTeams',
    CustomersTeamsNoName: 'CustomersTeamsNoName',
    GetCustomer: 'GetCustomer',
    GetCustomerNoName: 'GetCustomerNoName'
  },
  Mutation: {
    CreateCustomer: 'CreateCustomer',
    UpdateCustomer: 'UpdateCustomer',
    ChangeCustomerStatus: 'ChangeCustomerStatus',
    UpdateCustomerTeamUiOrder: 'UpdateCustomerTeamUiOrder',
    UpdatePositionUiCustomerTeamOrder: 'UpdatePositionUiCustomerTeamOrder'
  },
  Fragment: {
    customer_teams: 'customer_teams'
  }
}