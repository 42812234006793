import { Menu, MenuItem, Position } from '@blueprintjs/core';
import { ButtonWithMenu } from '@flow/common/components/elements/ButtonWithMenu';
import { Button, ButtonSize } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { Alignment, Intent } from '@flow/common/components/types/Types';
import { component, di } from '@flow/dependency-injection';
import { CandidateAllocationController } from '@flow/modules/recruiting/candidates/CandidateAllocationController';
import { CandidateAllocationState } from '@flow/modules/recruiting/candidates/CandidateAllocationState';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './AllocationPositionsFilter.module.less';

const cx = classNames.bind(styles);

interface Props
{
  inDialog?:boolean;
}

@component
export class AllocationPositionsFilter extends React.Component<Props>
{
  // ---------------------------------------------------------

  @di private _candidateAllocationState!:CandidateAllocationState;
  @di private _candidateAllocationController!:CandidateAllocationController;

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { inDialog } = this.props;

    const { isShowOnlyOpenPositions, isShowOnlyOpenPositionsInDialog } = this._candidateAllocationState;

    const isOpen:boolean = inDialog ? isShowOnlyOpenPositionsInDialog : isShowOnlyOpenPositions;

    return (
      <ButtonWithMenu
        position={inDialog ? Position.BOTTOM_RIGHT : Position.BOTTOM_LEFT}
        className={styles.positionsButtonWrapper}
        portalClassName={styles.positionsButtonPopover} // = bp4-overlay
        button={
          <Button
            className={cx(styles.positionsButton)}
            rightIcon={IconNames.CARET_DOWN}
            rightIconClassName={styles.positionsButtonIcon}
            text={isOpen ? 'Open' : 'All'}
            intent={Intent.NONE}
            minimal={true}
            alignText={Alignment.LEFT}
            size={ButtonSize.SMALL}
          />
        }
        menuContent={
          <Menu className={styles.positionsButtonMenu}>
            <MenuItem
              key={'open'}
              text={'Open positions'}
              selected={isOpen}
              onClick={():void =>
              {
                this._candidateAllocationController.setShowOnlyOpenPositions(true, inDialog);
              }}
            />
            <MenuItem
              key={'all'}
              text={'All positions'}
              selected={!isOpen}
              onClick={():void =>
              {
                this._candidateAllocationController.setShowOnlyOpenPositions(false, inDialog);
              }}
            />
          </Menu>
        }
      />
    );
  }

  // ---------------------------------------------------------
}
