import { MenuDivider, MenuItem } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { TextArea } from '@flow/common/components/form/TextArea';
import { Alignment, Intent } from '@flow/common/components/types/Types';
import { Recruiting_Candidate_Note_Type_Enum } from '@flow/data-access/lib/types/graphql.generated';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';
import { CandidateNoteTitles } from '../../../CandidateController';
import styles from './CommunicationNoteForm.module.less';

const cx = classNames.bind(styles);

interface ICandidatePanelNoteFormProps
{
  content:string;
  type:Recruiting_Candidate_Note_Type_Enum;
  onSave:(type:Recruiting_Candidate_Note_Type_Enum, content:string) => void;
  onCancel?:() => void;
}

interface ICandidatePanelNoteFormState
{
  content:string;
  type:Recruiting_Candidate_Note_Type_Enum;
}

export class CommunicationNoteForm extends React.PureComponent<ICandidatePanelNoteFormProps, ICandidatePanelNoteFormState>
{
  public constructor(props:ICandidatePanelNoteFormProps)
  {
    super(props);

    this.state = {
      content: this.props.content,
      type: this.props.type
    };
  }

  @bind
  private _itemRenderer(noteType:Recruiting_Candidate_Note_Type_Enum,
                        { index, handleClick }:IItemRendererProps):JSX.Element | null
  {
    if( !noteType )
      return <MenuDivider key={index} />;

    return (
      <MenuItem
        key={noteType}
        text={CandidateNoteTitles.get(noteType)}
        onClick={handleClick}
        selected={this.state.type == noteType}
      />
    );
  }

  public render():React.ReactNode
  {
    const { onSave, onCancel } = this.props;

    const { type } = this.state;

    const TypeSelect = Select.ofType<Recruiting_Candidate_Note_Type_Enum>();

    return (
      <div className={styles.form}>

        <div className={styles.header}>
          <div className={styles.title}>
            Add note
          </div>
          <div className={styles.spacer} />
          <Button
            className={styles.btn}
            intent={Intent.NONE}
            icon={IconNames.CROSS}
            minimal={true}
            outlined={false}
            onClick={onCancel}
          />
        </div>

        <div className={styles.content}>
          <TextArea
            className={cx(styles.noteContentInput)}
            value={this.state.content}
            large={true}
            fill={true}
            autoFocus={true}
            placeholder={'Type here...'}
            onChange={(content):void => this.setState({ content }) as void}
          />
        </div>

        <div className={styles.actionBar}>

          <TypeSelect
            className={styles.select}
            items={Object.values(Recruiting_Candidate_Note_Type_Enum) as Array<Recruiting_Candidate_Note_Type_Enum>}
            itemRenderer={this._itemRenderer}
            onItemSelect={(item):void =>
            {
              this.setState({ type: item });
            }}
            filterable={false}
            popoverProps={{ minimal: true, popoverClassName: cx(styles.popover, styles.selectPopover) }}
          >
            <Button
              className={cx(styles.btnSelect)}
              text={CandidateNoteTitles.get(type as string)}
              rightIcon={IconNames.CARET_DOWN}
              minimal={true}
              alignText={Alignment.LEFT}
            />
          </TypeSelect>

          <Button
            className={styles.saveButton}
            text={'Place note'}
            disabled={this.state.content == ''}  // TODO: compare content for edit
            onClick={():void => onSave(this.state.type, this.state.content) as void}
            intent={Intent.PRIMARY}
          />

        </div>
      </div>
    );
  }
}
