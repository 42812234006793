import { Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { Intent } from '@flow/common/components/types/Types';
import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './FiltersPopover.module.less';

const cx = classNames.bind(styles);

interface Props
{
  title?:string;
  wrapperClassName?:string;
  content:React.ReactElement | null;
  position?:Position;

  contentButtonClearText?:string;
  onClickButtonClear:() => void;

  customButton?:React.ReactNode;
  buttonText?:React.ReactNode;
  isValueSelected?:boolean;
}

@component
export class FiltersPopover extends React.Component<Props>
{
  // ---------------------------------------------------------

  @bind
  private _renderFilters():React.ReactElement
  {
    const { content, contentButtonClearText = 'Remove filter', onClickButtonClear, title } = this.props;

    return (
      <div className={styles.wrapper}>

        <div className={styles.content}>
          {
            title &&
            <div className={styles.title}>
              {title}
            </div>
          }

          {content}
        </div>

        <Button
          className={styles.btnClear}
          text={contentButtonClearText}
          minimal={true}
          onClick={onClickButtonClear}
        />

      </div>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const {
      position = Position.BOTTOM_LEFT, wrapperClassName,
      customButton, buttonText, isValueSelected
    } = this.props;

    return (
      <Popover2
        className={cx(styles.popover, wrapperClassName)}
        content={this._renderFilters()}
        position={position}
        // popoverClassName={Classes.POPOVER2_DISMISS}
      >
        <>
          {customButton && customButton}
          {
            !customButton &&
            <Button
              className={cx(styles.btnFilter, { noValueSelected: !isValueSelected })}
              text={buttonText}
              rightIcon={IconNames.CARET_DOWN}
              intent={isValueSelected ? Intent.PRIMARY : Intent.NONE}
              minimal={!isValueSelected}
            />
          }
        </>
      </Popover2>
    );
  }
}
