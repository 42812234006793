import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import type { Common_Filter } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
// import classNames from 'classnames/bind';
import React from 'react';
import { CandidatesController } from '../../../CandidatesController';
import { CandidatesState } from '../../../CandidatesState';

// import styles from './CandidatesSavedFiltersMenu.module.less';

// const cx = classNames.bind(styles);

interface Props
{
  onSelect:(filterId:number | null) => void;
  selectedFilterId:number | null;
  isDirty?:boolean;
  fromDialog:boolean;
}

@component
export class CandidatesSavedFiltersMenu extends React.Component<Props>
{
  @di private _candidatesState!:CandidatesState;
  @di private _candidatesController!:CandidatesController;

  // ---------------------------------------------------------

  @bind
  private _onClickFilter(filterId:number | null):(e:React.MouseEvent<HTMLElement>) => void
  {
    return (/* e:React.MouseEvent<HTMLElement> */):void =>
    {
      const { onSelect } = this.props;

      onSelect(filterId);
      // this._candidatesController.selectUserFilter(filterId);
    };
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { selectedFilterId, fromDialog, isDirty } = this.props;
    const { userFilters } = this._candidatesState;

    return (
      <Menu>
        {
          !fromDialog &&
          <>
            <MenuItem
              key={-1}
              text={'No filter'}
              onClick={this._onClickFilter(null)}
              labelElement={<Icon icon={!selectedFilterId && !isDirty ? IconNames.TICK : null} />}
            />
            {
              !!userFilters.length &&
              <MenuDivider />
            }
          </>
        }
        {userFilters.map((userFilter:Common_Filter) =>
        {
          const isSelected:boolean = !!(selectedFilterId && userFilter.id === selectedFilterId);

          return (
            <MenuItem
              key={userFilter.id}
              text={userFilter.name}
              onClick={this._onClickFilter(userFilter.id)}

              // left icon
              // icon={isSelected ? IconNames.TICK : IconNames.BLANK}

              // right icon
              labelElement={<Icon icon={isSelected ? IconNames.TICK : null} />}
            />
          );
        })}
      </Menu>
    );
  }

}
