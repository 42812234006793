import { Collapse } from '@blueprintjs/core';
import { type Recruiting_Position_Group_Priority } from '@flow/data-access/lib/types/graphql.generated';
import { type Common_User } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { PositionGroupSelector } from '@flow/modules/recruiting/priorities/components/common/PositionGroupSelector';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';
import { PrioritiesController } from '../../PrioritiesController';
import { PrioritiesState } from '../../PrioritiesState';
import { PrioritiesListPositions } from '../common/PrioritiesListPositions';
import { PrioritiesListUser } from '../common/PrioritiesListUser';

import styles from './PrioritiesListByPrioritiesCell.module.less';

const cx = classNames.bind(styles);

interface Props
{
  user:Common_User;
  priorityIndex:number;
}

@component
export class PrioritiesListByPrioritiesCell extends React.Component<Props>
{
  @di private _prioritiesState!:PrioritiesState;
  @di private _prioritiesController!:PrioritiesController;

  // ---------------------------------------------------------

  @bind
  private _onClickExpand(priority:Recruiting_Position_Group_Priority, isCanExpand:boolean):() => void
  {
    return ():void =>
    {
      isCanExpand && this._prioritiesController.expandToggler(priority);
    };
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { user, priorityIndex } = this.props;

    const lastPriorityIndex:number = user.position_group_priorities.length - 1;

    const priority:Recruiting_Position_Group_Priority | undefined =
      (priorityIndex <= lastPriorityIndex && user.position_group_priorities[priorityIndex]) || undefined;

    if( !priority ) return null;

    // ---------------------------

    const { expanded } = this._prioritiesState;
    const { getQuantityCountOfPriority, getExpandId } = this._prioritiesController;

    const quantityCount:number = getQuantityCountOfPriority(priority);

    const isExpanded:boolean = expanded === getExpandId(priority);
    const isCanExpand:boolean = quantityCount > 0;

    return (
      <div className={styles.priorityCellWrapper}>

        <div
          className={cx(styles.priorityCellView, { isExpanded })}
          onClick={this._onClickExpand(priority, isCanExpand)}
        >
          <PrioritiesListUser user={user} className={styles.user} />

          <PositionGroupSelector
            priority={priority}
            count={quantityCount}
          />
        </div>
        {
          isCanExpand &&
          <Collapse isOpen={isExpanded}>
            <PrioritiesListPositions
              priority={priority}
              mobileByPriorities={true}
            />
          </Collapse>
        }
      </div>
    );
  }

  // ---------------------------------------------------------
}
