import { Button } from '@flow/common/components/form/Button';
import { Intent } from '@flow/common/components/types/Types';
import { component, di } from '@flow/dependency-injection';
import { TeamController } from '@flow/modules/customers/teams/TeamController';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './TeamsListHeader.module.less';

const cx = classNames.bind(styles);

@component
export class TeamsListHeader extends React.Component
{
  @di private _teamController!:TeamController;

  public render():ReactNode
  {
    const { allCollapsed } = this._teamController;
    const btnCollapseText = allCollapsed ? 'Expand all' : 'Collapse all';

    return (
      <div className={styles.header}>

        <div className={cx(styles.headerTitle, styles.name)}>
          <span className={styles.desktop}>
            Team Name
          </span>
          <span className={styles.mobile}>
            Team Name
          </span>
        </div>

        <div className={cx(styles.headerTitle, styles.alias)}>
          Alias
        </div>

        <div className={cx(styles.headerTitle, styles.total)}>
          Staffed/Total

          <Button
            className={styles.btnCollapseAll}
            text={btnCollapseText}
            minimal={true}
            intent={Intent.PRIMARY}
            onClick={():void => this._teamController.collapseAll(!allCollapsed)}
          />
        </div>

      </div>
    );
  }
}
