import { DateInput } from '@blueprintjs/datetime';
import { IconNames } from '@flow/common/components/form/Icon';
import { DialogRow } from '@flow/common/components/page/DialogRow';
import { RoleController } from '@flow/common/controllers/RoleController';
import { Staffing_Staff_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { StaffMemberController } from '@flow/modules/staffing/staff/StaffMemberController';
import { StaffMemberState } from '@flow/modules/staffing/staff/StaffMemberState';
import classNames from 'classnames/bind';
import moment from 'moment';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './LeavingDateInput.module.less';

const cx = classNames.bind(styles);

interface State
{
  isDateInputOpened:boolean;
}

@component
export class LeavingDateInput extends React.Component<any, State>
{
  @di private _staffMemberState!:StaffMemberState;
  @di private _staffMemberController!:StaffMemberController;

  @di private _roleController!:RoleController;

  // ---------------------------------------------------------

  public constructor(props:unknown)
  {
    super(props);

    this.state = {
      isDateInputOpened: false
    };
  }

  // ---------------------------------------------------------

  public render():ReactNode | null
  {
    const { staffMemberName, newLeavingDate, staffMember, newStaffStatus } = this._staffMemberState;

    if( !staffMemberName || !staffMember ) return null;

    const showLeavingDate:boolean = newStaffStatus === Staffing_Staff_Status_Enum.Leaving;

    if( !showLeavingDate ) return null;

    const { isDateInputOpened } = this.state;
    // const isDisabled:boolean = !this._roleController.hasPermission(FlowPermissions.EditStaff);

    return (
      <DialogRow
        label={'Set company leaving date'}
        labelOnSingleLine
      >
        {
          // bp4-popover-wrapper  = dateInputWrapper
          // - bp4-popover-target = dateInputPopoverTarget
          //   - bp4-input-group  = dateInput
          //     - bp4-icon
          //     - bp4-input
        }
        <DateInput
          className={styles.dateInputWrapper}
          fill={true}
          popoverProps={{
            autoFocus: true,
            captureDismiss: true,
            onClose: ():void => this.setState({ isDateInputOpened: false }),
            onOpening: ():void => this.setState({ isDateInputOpened: true })
          }}
          inputProps={{
            className: cx(styles.dateInput, { isDateInputOpened }),
            large: true,
            readOnly: true,
            leftIcon: IconNames.CALENDAR
            // leftIcon: <Icon icon={IconNames.CALENDAR} className={styles.iconCalendar} />
          }}

          // disabled={}
          // canClearSelection={false} // ?
          // todayButtonText={'Today'}
          showActionsBar
          closeOnSelection={true} // !!!
          highlightCurrentDay
          dayPickerProps={{
            firstDayOfWeek: 1,
            fixedWeeks: true
          }}

          placeholder={'Choose a leaving date...'}
          // defaultValue={new Date()}
          value={newLeavingDate ? moment(newLeavingDate).toDate() : null}
          // minDate={minDate}
          formatDate={(date:Date):string => String(moment(date).format('MMMM DD, YYYY'))}
          // parseDate={(date:string):Date => new Date(Date.parse(date))}
          parseDate={(date:string):Date => moment(date).toDate() as Date}
          onChange={(date:Date):void => this._staffMemberController.setStaffLeavingDate(date)}
        />

      </DialogRow>
    );
  }

  // ---------------------------------------------------------
}
