import type { IReactComponent } from 'mobx-react/dist/types/IReactComponent';
import type { Context, ReactElement } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import type { IModule, ProviderConstructor } from './registry';
import { registry } from './registry';
import { isReactComponent } from './utils';

export interface IModuleOptions
{
  providers:Array<ProviderConstructor>;
}

const ModuleContext:Context<IModule | null> = createContext<IModule | null>(null);

export function diModule(options:IModuleOptions):<T extends IReactComponent>(component:T) => T
{
  return <T extends IReactComponent>(component:T):T =>
  {
    if( isReactComponent(component) )
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
      return ((props:any):ReactElement =>
      {
        const [module, setModule] = useState<IModule | null>(null);
        const parentModule:IModule | null = useContext<IModule | null>(ModuleContext);

        useEffect(() =>
        {
          const module:IModule = registry.createModule(component, parentModule, options);

          setModule(module);

          return ():void =>
          {
            registry.deleteModule(module);
          };
        }, [parentModule]);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const WrappedComponent:any = component;

        return <ModuleContext.Provider value={module} {...props}>
          {module && <WrappedComponent {...props} />}
        </ModuleContext.Provider>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) as any;
    }
    else
      throw new Error(
        '@module decorator should be used on React components only'
      );
  };
}
