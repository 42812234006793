import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import { RouteName } from '@flow/common/models/routing/RouteName';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import type { IMatchedRouteRoute } from '../../../pages/RoutesConfig';
import { RoutesConfig } from '../../../pages/RoutesConfig';
import { PageHeader } from './PageHeader';
import styles from './PageLayout.module.less';
import { PageSidebar } from './PageSidebar';

const cx = classNames.bind(styles);

interface IState
{
  isMobileMenuVisible:boolean;
  isSettingsRoute:boolean;
}

interface Props extends IWithRouterProps
{
  content?:JSX.Element;
}

@componentWithRouter
export class PageLayout extends React.Component<Props, IState>
{
  @di private _commonState!:CommonState;
  @di private _commonController!:CommonController;

  // ----------------------------------------------------

  public constructor(props:IWithRouterProps)
  {
    super(props);
    this.state = {
      isMobileMenuVisible: false,
      isSettingsRoute: false
    };
  }

  // ----------------------------------------------------

  @bind
  public componentDidMount():void
  {
    this._checkPageProps();
  }

  @bind
  public componentDidUpdate():void
  {
    this._checkPageProps();
  }

  @bind
  private _checkPageProps():void
  {
    const { params } = this.props;
    const { isSettingsRoute } = this.state;

    const settingsRouteConfig:IMatchedRouteRoute | null = RoutesConfig.getRouteConfig(RouteName.SETTINGS);

    const isNextSettingsRoute:boolean = !!settingsRouteConfig && params?.['*']?.startsWith(settingsRouteConfig.path) || false;

    if( isSettingsRoute !== isNextSettingsRoute )
    {
      this.setState({ isSettingsRoute: isNextSettingsRoute });
    }
  }

  // ----------------------------------------------------

  @bind
  private _setIsMobileMenuVisible(isMobileMenuVisible:boolean):void
  {
    this.setState({ isMobileMenuVisible });
  }

  @bind
  private _onRef(ref:HTMLDivElement):void
  {
    this._commonState.dialogsContainer = ref;
  }

  // ----------------------------------------------------

  public render():ReactNode
  {
    const { content } = this.props;
    const { isMobileMenuVisible, isSettingsRoute } = this.state;
    const { contentBackground } = this._commonState;

    return (
      <div className={styles.wrapper}>
        <PageHeader
          isMobileMenuVisible={isMobileMenuVisible}
          setIsMobileMenuVisible={this._setIsMobileMenuVisible}
        />

        <div className={styles.main}>
          <PageSidebar
            isMobileMenuVisible={isMobileMenuVisible}
            setIsMobileMenuVisible={this._setIsMobileMenuVisible}
            isSettingsRoute={isSettingsRoute}
          />

          <div
            className={cx('content', { scrollable: this._commonState.isContentScrollable })}
            style={{ background: contentBackground || 'none' }}
          >
            {content}
            {
              !content &&
              <Outlet />
            }
          </div>
        </div>

        <div
          className={styles.dialogsContainer}
          ref={this._onRef}
        />
      </div>
    );
  }

  // ----------------------------------------------------
}
