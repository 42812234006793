import { Position, Toaster } from '@blueprintjs/core';
import { FlowApiController } from '@flow/common/clients/FlowApiClient';
import { AppLoader } from '@flow/common/components/AppLoader';
import { NavigateInjector } from '@flow/common/components/routing/NavigateInjector';
import { FilesController } from '@flow/common/controllers/FilesController';
import { ObjectHistoryController } from '@flow/common/controllers/ObjectHistoryController';
import { RoleController } from '@flow/common/controllers/RoleController';
import { RoleState } from '@flow/common/controllers/RoleState';
import { GlobalExceptionHandler } from '@flow/common/errorHandling/GlobalExceptionHandler';
import { ToastsController } from '@flow/common/toasts/ToastsController';
import { ToastsState } from '@flow/common/toasts/ToastsState';
import { WindowLocationUtil } from '@flow/common/utils/WindowLocationUtil';
import { di, diModule } from '@flow/dependency-injection';
import { reaction, runInAction } from 'mobx';
import React from 'react';
import { Helmet } from 'react-helmet';
import { CommonController } from '../common/CommonController';
import { CommonState } from '../common/CommonState';
import { AuthController } from '../common/controllers/AuthController';
import { AuthState } from '../common/controllers/AuthState';

import styles from './App.module.less';
import { AppRoutes } from './AppRoutes';

export const AppToaster = Toaster.create({
  position: Position.TOP_RIGHT,
  className: styles.toasterWrapper
});

export const AppErrorToaster = Toaster.create({
  position: Position.BOTTOM_RIGHT,
  className: styles.toasterWrapper
});

@diModule({
  providers: [
    CommonState,
    CommonController,
    AuthState,
    AuthController,
    RoleState,
    RoleController,
    ToastsState,
    ToastsController,
    ObjectHistoryController,
    FlowApiController,
    FilesController
  ]
})
export class App extends React.Component
{
  @di private _authController!:AuthController;
  @di private _authState!:AuthState;
  @di private _commonState!:CommonState;

  // fix mobile scroll
  private _appHeight = ():void =>
  {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };

  public componentDidMount():void
  {
    window.addEventListener('resize', this._appHeight);
    this._appHeight();

    runInAction(() => this._commonState.loadersCount++);

    this._commonState.disposers.push(reaction(
      ():number | null => this._authState.user?.id || null,
      (id) =>
      {
        if( id && !this._authState.userAsStaff )
          this._authController.getUserAsStaff()
      })
    );

    const params = WindowLocationUtil.getObjectFromQueryString();

    const token:string | undefined = params['token'];
    if( token )
    {
      this._authController.logout();
      this._authController.setUserAndCookieFromToken(token);

      delete params['token'];
      WindowLocationUtil.setQueryString(params);
    }

    this._authController.tryLoginWithCookie().then(() => runInAction(() => this._commonState.loadersCount--));

    // Let the document know when the mouse is being used
    document.body.addEventListener('mousedown', function()
    {
      document.body.classList.add('using-mouse');
    });

    // Re-enable focus styling when Tab is pressed
    document.body.addEventListener('keydown', function(event)
    {
      if( event.key === 'Tab' )
      {
        document.body.classList.remove('using-mouse');
      }
    });
  }

  public componentWillUnmount():void
  {
    window.removeEventListener('resize', this._appHeight);
  }

  public render():React.ReactNode
  {
    return (
      <>
        <GlobalExceptionHandler />
        <AppLoader />

        <Helmet>
          <meta name="viewport" content="initial-scale=1.0, width=device-width" />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&family=Roboto+Mono&display=swap"
          />
        </Helmet>

        <NavigateInjector />
        <AppRoutes />
      </>
    );
  }
}
