import { ButtonMore } from '@flow/common/components/elements/ButtonMore';
import { EmptyMenu } from '@flow/common/components/elements/EmptyMenu';
import type { Staffing_Customer_Team_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import type { IUnitedTeamLead } from '@flow/modules/staffing/board/components/TeamCardStaffedPositions';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './StaffBoardTeamCard.module.less';
import { TeamCardPositions } from './TeamCardPositions';

const cx = classNames.bind(styles);

interface IStaffBoardTeamCardProps
{
  team:Staffing_Customer_Team_Ex;
  fullWidth?:boolean;
  unitedTeamLead?:IUnitedTeamLead;
}

export class StaffBoardTeamCard extends React.Component<IStaffBoardTeamCardProps>
{
  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { team, fullWidth, unitedTeamLead } = this.props;

    return (
      <div className={cx(styles.customerTeamCard, { fullWidth })}>

        <div className={styles.header}>

          <div className={styles.headerContent}>
            <div className={styles.teamName}>
              {team.name}
            </div>
          </div>

          <ButtonMore
            className={styles.btnTeamMenu}
            menuContent={<EmptyMenu />}
            disabled={true}
          />

        </div>

        <div className={styles.content}>
          <TeamCardPositions team={team} fullWidth={fullWidth} unitedTeamLead={unitedTeamLead} />
        </div>

      </div>
    );
  }

  // ----------------------------------------------------
}
