import { component, di } from '@flow/dependency-injection';
import { SettingsPageController } from '@flow/modules/settings/settingsTemplatePage/SettingsPageController';
import { SettingsPageState } from '@flow/modules/settings/settingsTemplatePage/SettingsPageState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './SettingsPageList.module.less';

const cx = classNames.bind(styles);

@component
export class SettingsPageList extends React.Component
{
  @di private _settingsPageState!:SettingsPageState;
  @di private _settingsPageController!:SettingsPageController;

  // ---------------------------------------------------------

  public componentDidMount():void
  {
    // const { isLoaded } = this._settingsPageState;
    //
    // if( !isLoaded )
    // {
    //   this._settingsPageController.init();
    // }
  }

  // ---------------------------------------------------------

  @bind
  private _renderHeader():React.ReactNode
  {
    return (
      <div className={styles.header}>
        <div className={cx(styles.headerTitle, styles.title1)}>
          Col 1 Title
        </div>
        <div className={cx(styles.headerTitle, styles.title2)}>
          Col 2 Title
        </div>
        <div className={cx(styles.headerTitle, styles.title3)}>
          Col 3 Title
        </div>
        <div className={cx(styles.headerTitle, styles.title4)}>
          Col 4 Title
        </div>
        <div className={cx(styles.headerTitle, styles.title5)}>
          Col 5 Title
        </div>
      </div>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    return (
      <div className={styles.listWrapper}>
        {this._renderHeader()}
        {/*<XxxList />*/}
      </div>
    );
  }

  // ---------------------------------------------------------
}
