import { Menu, MenuItem } from '@blueprintjs/core';
import { CommonController } from '@flow/common/CommonController';
import { ButtonMore } from '@flow/common/components/elements/ButtonMore';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { NeedsPermission } from '@flow/common/components/NeedsPermission';
import { Toolbar, ToolbarTitle } from '@flow/common/components/page/Toolbar';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { RouteName } from '@flow/common/models/routing/RouteName';
import { component, di } from '@flow/dependency-injection';
import { StaffCreateDialog } from '@flow/modules/staffing/staff/components/list/newStaff/StaffCreateDialog';
import { StaffController } from '@flow/modules/staffing/staff/StaffController';
import { StaffState } from '@flow/modules/staffing/staff/StaffState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { runInAction } from 'mobx';
import React from 'react';
import { RoutesConfig } from '../../../../../pages/RoutesConfig';
import { StaffSearch } from './StaffSearch';

import styles from './StaffToolbar.module.less';
// import { StaffCreateDialog } from './newCandidate/StaffCreateDialog';

const cx = classNames.bind(styles);

@component
export class StaffToolbar extends React.Component
{
  @di private _staffState!:StaffState;
  @di private _staffController!:StaffController;
  @di private _commonController!:CommonController;

  // ----------------------------------------------------

  @bind
  private _renderMenu():React.ReactNode | null
  {
    const { isShowArchived } = this._staffState;

    if( isShowArchived ) return null;

    return (
      <ButtonMore
        className={cx(styles.btnMore)}
        menuContent={(
          <Menu>
            <MenuItem
              text={'View archived staff'}
              onClick={():void =>
              {
                runInAction(() => this._staffState.isShowArchived = true);
              }}
            />
          </Menu>
        )}
      />
    );
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { isShowArchived, isStaffUsersLoaded } = this._staffState;

    return (
      <Toolbar
        className={styles.toolbar}
        title={isShowArchived ? '' : 'Staff'}

        leftClassName={styles.positionLeft}
        leftElements={
          <>
            {
              isShowArchived &&
              <>
                <Button
                  className={styles.btnArrowLeft}
                  icon={IconNames.ARROW_LEFT}
                  minimal={true}
                  onClick={():void =>
                  {
                    runInAction(() => this._staffState.isShowArchived = false);

                    // if( window.history.length > 1 )
                    // {
                    //   window.history.back();
                    // }
                    // else
                    // {
                    //   this._commonController.navigate(RoutesConfig.getRoutePath(RouteName.STAFFING_STAFF) as string);
                    // }
                  }}
                />
                <ToolbarTitle title={'Archived Staff'} />
              </>
            }
            {
              (!isShowArchived && isStaffUsersLoaded) &&
              <NeedsPermission name={FlowPermissions.CreateStaff}>
                <Button
                  className={cx(styles.btnAdd, 'test-add-staff')}
                  text={'New Employee'}
                  icon={IconNames.ADD}
                  minimal={true}
                  onClick={this._staffController.showNewStaffUserDialog}
                />
                <StaffCreateDialog
                  // onSave={this._staffController.onNewCandidateAdded}
                />
              </NeedsPermission>
            }
          </>
        }

        // centerClassName={styles.positionCenter}
        // centerElements={
        //   <>
        //   </>
        // }

        rightClassName={styles.positionRight}
        rightElements={
          <>
            <StaffSearch />
            {this._renderMenu()}
          </>
        }
      />
    );
  }

  // ----------------------------------------------------
}
