import { ButtonSize } from '@flow/common/components/form/Button';
import { TextInput } from '@flow/common/components/form/TextInput';
import { Dialog } from '@flow/common/components/page/Dialog';
import { DialogRow } from '@flow/common/components/page/DialogRow';
import { Intent } from '@flow/common/components/types/Types';
import { component, di } from '@flow/dependency-injection';
import { StaffController } from '@flow/modules/staffing/staff/StaffController';
import { StaffState } from '@flow/modules/staffing/staff/StaffState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './StaffCreateDialog.module.less';

const cx = classNames.bind(styles);

export interface IStaffCreateDialogProps
{
  onSave?:(customerId:number | null) => Promise<void>;
}

@component
export class StaffCreateDialog extends React.Component<IStaffCreateDialogProps>
{
  @di private _staffState!:StaffState;
  @di private _staffController!:StaffController;

  // ---------------------------------------------------------

  @bind
  private async _saveNewStaffUser():Promise<void>
  {
    const { onSave } = this.props;

    const id:number | null = await this._staffController.saveNewStaffUser();

    if( onSave )
      return onSave(id) as Promise<void>;
  }

  // ---------------------------------------------------------

  public render():ReactNode
  {
    const {
      isNewStaffUserDialogOpen,
      isNewStaffUserLoading,
      newStaffUserName,
      newStaffUserEmail
    } = this._staffState;

    const isButtonDisabled:boolean =
      (newStaffUserName.trim() === '') ||
      (newStaffUserName.trim().indexOf(' ') === -1) ||
      (newStaffUserEmail.trim() === '') ||
      (newStaffUserEmail.indexOf('@') === -1);

    return (
      <Dialog
        title={'New Employee'}
        isOpen={isNewStaffUserDialogOpen}
        isLoading={isNewStaffUserLoading}
        onClose={this._staffController.hideNewStaffUserDialog}
        className={styles.dialog}

        buttonsSize={ButtonSize.LARGE}

        isPrimaryButtonDisabled={isButtonDisabled}
        primaryButtonText={'Add Employee'}
        primaryButtonIntent={Intent.PRIMARY}
        primaryButtonOnClick={this._saveNewStaffUser}

        secondaryButtonText={'Cancel'}
        secondaryButtonIntent={Intent.PRIMARY}
        isSecondaryButtonOutlined={true}
      >
        <DialogRow label={'Name'}>
          <div className={cx('test-staff-form-input')}>
            <TextInput
              value={newStaffUserName}
              large={true}
              onChange={this._staffController.setNewStaffUserName}
              autoFocus={true}
            />
          </div>
        </DialogRow>

        <DialogRow label={'Email'}>
          <TextInput
            value={newStaffUserEmail}
            large={true}
            onChange={this._staffController.setNewStaffUserEmail}
          />
        </DialogRow>

      </Dialog>
    );
  }
}
