import type { Common_User } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { PrioritiesListByPrioritiesCell } from '@flow/modules/recruiting/priorities/components/mobile/PrioritiesListByPrioritiesCell';
import { PrioritiesController } from '@flow/modules/recruiting/priorities/PrioritiesController';
import { PRIORITIES_NUM_OF_COLUMNS, PrioritiesState } from '@flow/modules/recruiting/priorities/PrioritiesState';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './PrioritiesListBy.module.less';

const cx = classNames.bind(styles);

@component
export class PrioritiesListByPriorities extends React.Component
{
  @di private _prioritiesState!:PrioritiesState;
  @di private _prioritiesController!:PrioritiesController;

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { users } = this._prioritiesState;

    return (
      <div className={cx(styles.wrapper)}>
        {PRIORITIES_NUM_OF_COLUMNS.map((column:number) =>
        {
          return (
            <div
              key={`column:${column}`}
              className={cx(styles.columnPriority)}
            >
              <div className={cx(styles.priorityHeader)}>
                Priority {column}
              </div>

              {users.map((user:Common_User, index:number) =>
              {
                return (
                  <React.Fragment
                    key={`${column}:${user.id}:${index}`}
                  >
                    <PrioritiesListByPrioritiesCell
                      user={user}
                      priorityIndex={column - 1}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  // ---------------------------------------------------------
}
