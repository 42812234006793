import { Position } from '@blueprintjs/core';
import { FiltersPopover } from '@flow/common/components/filters/components/FiltersPopover';
import { Button } from '@flow/common/components/form/Button';
import { Checkbox } from '@flow/common/components/form/Checkbox';
import { IconNames } from '@flow/common/components/form/Icon';
import { Intent } from '@flow/common/components/types/Types';
import { component, di } from '@flow/dependency-injection';
import type { IStaffColumn } from '@flow/modules/staffing/staff/StaffColumnsState';
import { StaffColumnType } from '@flow/modules/staffing/staff/StaffColumnsState';
import { StaffFiltersController } from '@flow/modules/staffing/staff/StaffFiltersController';
import { StaffState } from '@flow/modules/staffing/staff/StaffState';
import bind from 'bind-decorator';
// import classNames from 'classnames/bind';
import React from 'react';

import styles from './StaffColumnsSelector.module.less';

// const cx = classNames.bind(styles);

@component
export class StaffColumnsSelector extends React.Component
{
  @di private _staffState!:StaffState;
  @di private _staffFiltersController!:StaffFiltersController;

  // ---------------------------------------------------------

  @bind
  private _onChangeFilter(columnType:StaffColumnType):(isVisible:boolean) => void
  {
    return (isVisible:boolean):void =>
    {
      this._staffFiltersController.selectColumn(columnType, isVisible);
    };
  }

  @bind
  private _renderContent():React.ReactElement
  {
    return (
      <div className={styles.content}>
        {this._staffState.columns.map((column:IStaffColumn) =>
        {
          // if( column.alwaysVisible ) return null;

          return (
            <Checkbox
              key={column.name}
              className={styles.checkbox}
              label={column.name}
              checked={column.isVisible}
              disabled={column.alwaysVisible}
              onChange={this._onChangeFilter(column.type)}
            />
          );
        })}
      </div>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    return (
      <FiltersPopover
        wrapperClassName={styles.wrapper}
        content={this._renderContent()}
        contentButtonClearText={'Clear all'}
        onClickButtonClear={this._staffFiltersController.unselectAllColumns}
        position={Position.BOTTOM_RIGHT}
        customButton={(
          <Button
            className={styles.btn}
            // text={'Columns'}
            icon={IconNames.LIST_COLUMNS}
            // rightIcon={IconNames.CARET_DOWN}
            intent={Intent.NONE}
            minimal={true}
          />
        )}
      />
    );
  }
}
