import { Alignment, Position } from '@blueprintjs/core';
import { CommonController } from '@flow/common/CommonController';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { SvgIconName } from '@flow/common/components/elements/SvgIcon';
import { InlineEditor } from '@flow/common/components/form/InlineEditor';
import { Switch } from '@flow/common/components/form/Switch';
import { AuthState } from '@flow/common/controllers/AuthState';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { component, di } from '@flow/dependency-injection';
import { CandidateController } from '@flow/modules/recruiting/candidates/CandidateController';
import { CandidateState } from '@flow/modules/recruiting/candidates/CandidateState';
import { FeedbackFormState } from '@flow/modules/recruiting/feedbackForm/FeedbackFormState';
import { ScheduleState } from '@flow/modules/recruiting/schedule/ScheduleState';
// import classNames from 'classnames/bind';
import type { Moment } from 'moment';
import moment from 'moment';
import React from 'react';
import styles from '../../../../../schedule/components/ScheduleSidebar.module.less';
import { EventAttendeesListEditor } from '../event/EventAttendeesListEditor';
import { EventDateEditor } from '../event/EventDateEditor';
import { EventEndTimeEditor } from '../event/EventEndTimeEditor';
import { EventStartTimeEditor } from '../event/EventStartTimeEditor';
import sidebarStyles from './CandidateFeedbackFormSidebar.module.less';

// const cx = classNames.bind(styles);

@component
export class CandidateFeedbackFormSidebar extends React.Component
{
  @di private _authState!:AuthState;
  @di private _scheduleState!:ScheduleState;
  @di private _candidateState!:CandidateState;
  @di private _candidateController!:CandidateController;
  @di private _commonController!:CommonController;
  @di private _roleController!:RoleController;
  @di private _feedbackFormState!:FeedbackFormState;

  // ----------------------------------------------------

  public render():React.ReactNode | null
  {
    const { candidate } = this._candidateState;
    const { allUsers } = this._scheduleState;
    const { feedbackFormEvent, isCompleteFeedbackLoading } = this._feedbackFormState;

    if( !candidate || !feedbackFormEvent ) return null;

    if( !candidate.email ) return null;

    const { user } = this._authState;

    const formUserId = feedbackFormEvent.user_id;

    if( !user || !formUserId ) return;

    const title = feedbackFormEvent?.summary || `Recruiter call with ${candidate.first_name} ${candidate.last_name}`;

    const startTime:Moment = moment(feedbackFormEvent.start_time);
    const endTime:Moment = moment(feedbackFormEvent.end_time);

    const startDateStr = startTime.format('MMMM DD, YYYY');
    const startTimeStr = startTime.format('h:mm A');
    const endTimeStr = String(endTime.format('h:mm A'));

    const disabledIsCompleted:boolean = isCompleteFeedbackLoading
      || !feedbackFormEvent.is_done
      || formUserId !== user.id
      || !this._roleController.hasPermission(FlowPermissions.CompleteEventFeedbackForm);

    return (
      <>
        {/* // TODO: call type */}
        <div className={styles.subHeader}>
          {/* Recruiter call */}
        </div>

        <div className={styles.selectedUser}>

          <Avatar size={30} className={styles.avatar} url={candidate.avatar_url} />

          {`${candidate.first_name} ${candidate.last_name}`}

        </div>

        {/* ------------------------------- */}

        <div className={styles.bgGray}>

          <InlineEditor
            readonly
            viewClassName={sidebarStyles.bgGray}
            textArea
            wrap
            text={title}
            fields={[
              {
                name: 'title',
                value: title,
                placeholder: 'Enter title'
              }
            ]}
          />

        </div>

        <div className={styles.textWithIcon}>

          <div className={styles.dateTime}>

            <EventDateEditor
              readonly
              startDateStr={startDateStr}
              scheduledEventStartTime={moment(feedbackFormEvent.start_time).toDate()}
              popoverProps={{
                usePortal: true,
                position: Position.LEFT,
                modifiers: {
                  preventOverflow: {
                    enabled: false
                  }
                }
              }}
            />

          </div>

        </div>

        <div className={sidebarStyles.dateTime}>

          <div>
            <EventStartTimeEditor
              readonly
              time={startTime.toDate()}
              timeStr={startTimeStr}
            />
          </div>

          <div className={styles.timeDivider}>
            –
          </div>

          <div>
            <EventEndTimeEditor
              readonly
              startTime={startTime.toDate()}
              time={endTime.toDate()}
              timeStr={endTimeStr}
            />
          </div>

        </div>

        {/* ------------------------------- */}

        <div className={styles.users}>

          <EventAttendeesListEditor
            readonly
            scheduledEventAttendeesList={this._candidateController.getEventAttendeesAsUsers(feedbackFormEvent)}
            candidateEmail={candidate.email}
            userId={formUserId}
            allUsers={allUsers}
            popoverProps={{
              usePortal: true,
              position: Position.LEFT,
              modifiers: {
                preventOverflow: {
                  enabled: false
                }
              }
            }}
          />

        </div>

        {/* ------------------------------- */}

        <InlineEditor
          readonly
          svgIcon={SvgIconName.TableDescription}
          text={feedbackFormEvent.description || ''}
          fields={[
            {
              name: 'description',
              value: feedbackFormEvent.description || '',
              placeholder: 'Enter description'
            }
          ]}
          textArea
        />

        {/* ------------------------------- */}

        <div className={styles.divider} />

        {/* <div className={styles.spacer} /> */}

        <div className={sidebarStyles.formIsCompletedPanel}>
          <Switch
            label="Form is completed"
            disabled={disabledIsCompleted}
            alignIndicator={Alignment.RIGHT}
            className={styles.callCompleted}
            checked={feedbackFormEvent.is_feedback_done}
            onChange={(value):void =>
            {
              if( feedbackFormEvent.id )
                if( value )
                  this._candidateController.completeFeedbackForm(feedbackFormEvent.id);
                else
                  this._candidateController.uncompleteFeedbackForm(feedbackFormEvent.id);
            }}
          />
        </div>
      </>
    );
  }

  // ----------------------------------------------------
}
