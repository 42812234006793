import { Button as BPButton, Icon as BPIcon } from '@blueprintjs/core';
import { WindowLocationUtil } from '@flow/common/utils/WindowLocationUtil';
import classNames from 'classnames/bind';
import React from 'react';
import { NavLink } from 'react-router-dom';
import type { Alignment, Intent } from '../types/Types';
import { getBPAlignment, getBPIntent } from '../types/Types';
import styles from './AnchorButton.module.less';
import { ButtonSize } from './Button';
import type { IconName } from './Icon';
import { IconSize } from './Icon';

const cx = classNames.bind(styles);

export interface AnchorButtonProps
{
  href?:string;
  text?:string;
  openInNewTab?:boolean;

  wrapperClassName?:string;
  className?:string;
  activeClassName?:string;
  size?:ButtonSize;
  intent?:Intent;

  icon?:IconName;
  iconClassName?:string;
  iconSize?:IconSize;
  rightIcon?:IconName;
  rightIconSize?:IconSize;
  rightIconClassName?:string;

  alignText?:Alignment;
  minimal?:boolean;
  disabled?:boolean;
  loading?:boolean;
  outlined?:boolean;
  activePaths?:Array<string>;

  onClick?:(event:React.MouseEvent<HTMLElement>) => void;
}

export class AnchorButton extends React.PureComponent<AnchorButtonProps>
{
  public render():React.ReactNode
  {
    const {
      href = '',
      text,
      openInNewTab,
      wrapperClassName,
      className,
      activeClassName,
      size, // = ButtonSize.REGULAR,
      intent, // = Intent.NONE,
      icon,
      iconClassName,
      iconSize, // = IconSize.STANDARD,
      rightIcon,
      rightIconSize, // = IconSize.STANDARD,
      rightIconClassName,
      alignText,
      minimal = false,
      disabled = false,
      loading = false,
      outlined = false,
      activePaths,
      onClick
    } = this.props;

    return (
      <NavLink
        to={href}
        className={cx(styles.btnWrapper, wrapperClassName, { disabled })}
        target={openInNewTab ? '_blank' : undefined}
      >
        {({ isActive }):React.ReactNode =>
        {
          if( !isActive )
          {
            const path = WindowLocationUtil.getPath();

            // simple hack for /:id/ param
            if( path.replace(/\d+\/$/, '') == href )
              isActive = true;

            if( activePaths && activePaths.includes(path) )
              isActive = true;
          }

          return <BPButton
            text={text}
            type={'button'}
            className={cx(styles.btn, styles.btnFlow, className, activeClassName && { [activeClassName]: isActive })}
            intent={getBPIntent(intent as Intent)}
            small={size === ButtonSize.SMALL}
            large={size === ButtonSize.LARGE}
            icon={
              icon && iconSize !== IconSize.STANDARD ? (
                <BPIcon icon={icon} size={iconSize} className={iconClassName} />
              ) : (
                icon
              )
            }
            rightIcon={
              rightIcon && rightIconSize !== IconSize.STANDARD ? (
                <BPIcon icon={rightIcon} size={rightIconSize} className={rightIconClassName || iconClassName} />
              ) : (
                rightIcon
              )
            }
            alignText={getBPAlignment(alignText)}
            minimal={minimal}
            outlined={outlined}
            loading={loading}
            disabled={disabled}
            onClick={onClick}
          />;
        }}
      </NavLink>
    );
  }
}
