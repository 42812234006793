import { OrderByType } from '@flow/common/components/elements/ButtonOrderBy';
import type { IFilter, IFilterValue } from '@flow/common/components/filters/Filter';
import { FilterType } from '@flow/common/components/filters/Filter';
import type { Maybe } from '@flow/common/models/Types';
import { PositionsUtil } from '@flow/common/utils/PositionsUtil';
import type {
  Common_Filter,
  Staffing_Customer_Team_Slot,
  Staffing_Staff
} from '@flow/data-access/lib/types/graphql.generated';
import { Staffing_Staff_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { di, state } from '@flow/dependency-injection';
import { SaveFilterMode } from '@flow/modules/recruiting/candidates/CandidatesState';
import type { IStaffUserSearchItem } from '@flow/modules/staffing/staff/components/list/StaffSearch';
import type { IStaffColumn } from '@flow/modules/staffing/staff/StaffColumnsState';
import { StaffColumnsState, StaffColumnType } from '@flow/modules/staffing/staff/StaffColumnsState';
import { StaffFiltersState } from '@flow/modules/staffing/staff/StaffFiltersState';
import { StaffSiteSubsiteState } from '@flow/modules/staffing/staff/StaffSiteSubsiteState';
import bind from 'bind-decorator';
import type { IReactionDisposer } from 'mobx';
import { computed, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

export const StaffListState_LS_KEY:string = 'staffListState';
export const StaffListUrl_LS_KEY:string = 'staffListUrl';

export const StaffShowArchived_HASK_KEY:string = 'showArchived';

@state
export class StaffState
{
  @di private _staffFiltersState!:StaffFiltersState;
  @di private _staffSiteSubsiteState!:StaffSiteSubsiteState;

  // ----------------------------------------------------

  public disposers:Array<IReactionDisposer> = [];
  @observable public isApplyingFromLocalStorage:boolean = false;

  // ----------------------------------------------------

  @observable public staffUsers:Array<Staffing_Staff> = [];

  @observable public isShowArchived:boolean = false;

  @observable public currentPage:number = 1;
  @observable public itemsPerPage:number = 20;
  @observable public staffListScrollYPosition = 0;

  // ----------------------------------------------------

  @observable public userFilters:Array<Common_Filter> = [];

  // public filters:Array<IFilter<IFilterValue>> = StaffFiltersState;
  @observable public columns:Array<IStaffColumn> = StaffColumnsState;

  @observable public isStaffUsersLoaded:boolean = false;

  // ----------------------------------------------------

  @observable public viewStaffId:number | null = null;

  @observable public isViewStaffListPageContent:boolean = false;

  // ----------------------------------------------------

  @observable public isNewStaffUserDialogOpen:boolean = false;

  @observable public isNewStaffUserLoading:boolean = false;
  @observable public newStaffUserName:string = '';
  @observable public newStaffUserEmail:string = '';

  // ----------------------------------------------------

  @observable public isFiltersDirty:boolean = false;

  @observable public isSaveFilterDialogOpen:boolean = false;
  @observable public isSaveFilterLoading:boolean = false;

  @observable public saveFilterMode:SaveFilterMode = SaveFilterMode.NEW;
  @observable public saveFilterName:string = '';
  @observable public overwriteFilterId:number | null = null;

  @observable public selectedFilterId:number | null = null;

  // ----------------------------------------------------

  @computed
  public get someFilterIsSelected():boolean
  {
    return this._staffFiltersState.filters.some(f => f.isSelected);
  }

  // ----------------------------------------------------

  public columnByType:(columnType:StaffColumnType) => IStaffColumn =
    computedFn((columnType:StaffColumnType):IStaffColumn =>
    {
      // return this.columns[columnType];
      let column:IStaffColumn | null = null;

      this.columns.forEach((columnItem:IStaffColumn) =>
      {
        if( columnItem.type === columnType ) column = columnItem;
      });

      if( !column )
      {
        throw new Error(`!!! columnByType ERROR: column '${columnType}' not found`);
      }

      return column;
    });

  // ----------------------------------------------------

  @computed
  public get orderedBy():IStaffColumn | null
  {
    let orderedColumn:IStaffColumn | null = null;

    this.columns.forEach((columnItem:IStaffColumn) =>
    {
      if( orderedColumn ) return;
      if( columnItem.orderBy ) orderedColumn = columnItem;
    });

    return orderedColumn;
  }

  @computed
  public get filteredStaffUsers():Array<Staffing_Staff>
  {
    return this.staffUsers
      .filter((staffUser:Staffing_Staff) =>
      {
        const isSomeFilterHidesCandidate:boolean = this._staffFiltersState.filters.some((filter:IFilter<IFilterValue>) =>
        {
          // remove archived from all without archived ||
          // remove NOT archived from list if isShowArchived
          if( staffUser.status !== Staffing_Staff_Status_Enum.Archived && this.isShowArchived ) return true;
          if( staffUser.status === Staffing_Staff_Status_Enum.Archived && !this.isShowArchived ) return true;

          // no filters for archived
          if( staffUser.status === Staffing_Staff_Status_Enum.Archived && this.isShowArchived ) return false;

          if( !filter.isSelected || !filter.filterFunction ) return false;

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let filterFunctionData:any = null;

          switch( filter.type )
          {
            case FilterType.SITE_SUBSITE:
              filterFunctionData = {
                site: staffUser.country_id,
                subSite: staffUser.city_id
              };
              break;

            // case FilterType.DATE_CREATED:
            //   filterFunctionData = staffUser.created_at;
            //   break;

            // case FilterType.POSITION_GROUP:
            //   filterFunctionData = staffUser.staffUser_position_groups.map(pg => pg.position_group_id);
            //   break;

            // case FilterType.STATUS:
            //   filterFunctionData = staffUser.interview_flow_stage;
            //   break;

            case FilterType.STATUS:
              // console.log('%c FILTER staffUser =', 'background:#0f0;color:#000;', staffUser.id, staffUser.status, toJS(staffUser));
              filterFunctionData = staffUser.status;
              break;

            // case FilterType.ASSIGNED_TO:
            //   filterFunctionData = staffUser.assigned_to?.id ? [staffUser.assigned_to.id] : [];
            //   break;

            // case FilterType.CREATED_BY:
            //   filterFunctionData = [staffUser.created_by.id];
            //   break;
          }
          const isItemVisible:boolean = filter.filterFunction(filterFunctionData);

          return !isItemVisible; // some item not visible
        });

        return !isSomeFilterHidesCandidate;
      })
      .sort(this._sortStaff);
  }

  @computed
  public get staffUsersForCurrentPage():Array<Staffing_Staff>
  {
    return this.filteredStaffUsers
      .slice()
      .splice((this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage);
  }

  @bind
  private _sortStaff(a:Staffing_Staff, b:Staffing_Staff):number
  {
    if( !this.orderedBy ) return 0;

    let returnedValue:number;
    let strA:string = '';
    let strB:string = '';
    const numA:number = 0;
    const numB:number = 0;
    const isSortByString:boolean = true;

    let slotA:Maybe<Staffing_Customer_Team_Slot>;
    let slotB:Maybe<Staffing_Customer_Team_Slot>;

    const { countryById, cityById } = this._staffSiteSubsiteState;

    switch( this.orderedBy.type )
    {
      case StaffColumnType.NAME:
        strA = `${a.user.first_name} ${a.user.last_name}`;
        strB = `${b.user.first_name} ${b.user.last_name}`;
        break;

      case StaffColumnType.POSITIONS:
        slotA = a.customer_team_slots.length > 0 ? a.customer_team_slots[0] :
          a.next_staff_slots.length > 0 ? a.next_staff_slots[0] : null;

        slotB = b.customer_team_slots.length > 0 ? b.customer_team_slots[0] :
          b.next_staff_slots.length > 0 ? b.next_staff_slots[0] : null;

        strA = slotA ? `${slotA.position?.customer_team?.customer.alias} ${slotA.position?.customer_team?.name} ${PositionsUtil.getPositionName(slotA.position)}` : '';
        strB = slotB ? `${slotB.position?.customer_team?.customer.alias} ${slotB.position?.customer_team?.name} ${PositionsUtil.getPositionName(slotB.position)}` : '';
        break;

      case StaffColumnType.STATUS:
        strA = a.status || '';
        strB = b.status || '';
        break;

      case StaffColumnType.SITE_SUBSITE:
        strA = (a.country_id || a.city_id) ? `${countryById(a.country_id)?.name} ${cityById(a.city_id)?.name}` : '';
        strB = (b.country_id || b.city_id) ? `${countryById(b.country_id)?.name} ${cityById(b.city_id)?.name}` : '';
        break;
    }

    const asc:boolean = this.orderedBy.orderBy === OrderByType.ASC;

    // ASC
    if( isSortByString )
    {
      strA = strA.trim().toLowerCase();
      strB = strB.trim().toLowerCase();

      if( strA === '' && strB )         // '' | 'str' => first: 'str' second: ''
        returnedValue = asc ? 1 : -1;
      else if( strA && strB === '' )    // 'str' | '' => first: 'str' second: ''
        returnedValue = asc ? -1 : 1;
      else
        returnedValue = strA === strB ? 0 : strA > strB ? 1 : -1;
    }
    else
    {
      returnedValue = numA - numB;
    }

    // DESC
    if( !asc && returnedValue )
    {
      returnedValue *= -1;
    }

    // let aColor:string;
    // let bColor:string;
    //
    // if( returnedValue === 1 )
    // {
    //   aColor = '0f0';
    //   bColor = '060';
    // }
    // else if( returnedValue === -1 )
    // {
    //   aColor = 'faa';
    //   bColor = '060';
    // }
    // else
    // {
    //   aColor = '060';
    //   bColor = '060';
    // }
    //
    // console.log('%c strA =', `background:#${aColor};color:#000;`, a.id, strA);
    // console.log('%c strB =', `background:#${bColor};color:#000;`, b.id, strB);
    // console.log('%c --> returnedValue =', 'background:#000;color:#fff;', returnedValue);

    return returnedValue;
  }

  // ----------------------------------------------------

  @computed
  public get staffUsersForSearch():Array<IStaffUserSearchItem>
  {
    return this.staffUsers
      .map((staffUser:Staffing_Staff) =>
      {
        return {
          id: staffUser.id,
          avatarUrl: staffUser.user.avatar || '',
          firstName: staffUser.user.first_name,
          lastName: staffUser.user.last_name,
          email: staffUser.user.email || '',
          status: staffUser.status
        };
      });
  }

  // ----------------------------------------------------

  // public userNameByStaffId:(staffId:number | null | undefined) => string =
  //   computedFn((staffId:number | null | undefined):string =>
  //   {
  //     if( !staffId ) return '';
  //
  //     const staff = this.staffUsers.find((staff:Staffing_Staff) => staff.id === staffId);
  //
  //     if( !staff ) return '';
  //
  //     const lastNameLetter:string = staff.user.last_name ? `${staff.user.last_name.substring(0, 1)}.` : '';
  //     const returnedString:string = `${staff.user.first_name || ''}${lastNameLetter ? ' ' : ''}${lastNameLetter}`;
  //
  //     return returnedString;
  //   });

  // ----------------------------------------------------

  public userFilterById:(userFilterId:number | null | undefined) => Common_Filter | undefined =
    computedFn((userFilterId:number | null | undefined):Common_Filter | undefined =>
    {
      if( !userFilterId ) return undefined;

      return this.userFilters.find((userFilter:Common_Filter) => userFilter.id === userFilterId);
    });

  // ----------------------------------------------------

  public filterByType:(filterType:FilterType) => IFilter<IFilterValue> =
    computedFn((filterType:FilterType):IFilter<IFilterValue> =>
    {
      let filter:IFilter<IFilterValue> | null = null;

      this._staffFiltersState.filters.forEach((f:IFilter<IFilterValue>) =>
      {
        if( f.type === filterType ) filter = f;
      });

      if( !filter )
      {
        console.log('%c !!! filterByType ERROR: =', 'background:#f00;color:#ff0;', `filter '${filterType}' not found`);
        throw new Error(`!!! filterByType ERROR: filter '${filterType}' not found`);
      }

      return filter;
    });

  // ----------------------------------------------------

  public staffUserById:(id:number) => Staffing_Staff | undefined =
    computedFn((id:number):Staffing_Staff | undefined =>
    {
      return this.staffUsers.find((staffUser:Staffing_Staff) => staffUser.id === id);
    });

  // ----------------------------------------------------

}
