import { state } from '@flow/dependency-injection';
import type { Common_Customer_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import type { IReactionDisposer } from 'mobx';
import { observable } from 'mobx';

export const StaffBoardState_LS_KEY:string = 'staffBoardState';

@state
export class StaffBoardState
{
  // ----------------------------------------------------

  public disposers:Array<IReactionDisposer> = [];

  // ----------------------------------------------------

  @observable public customers:Array<Common_Customer_Ex> = [];
  @observable public selectedCustomerId:number | null = null;
  @observable public customer:Common_Customer_Ex | null = null;

  // ----------------------------------------------------

  @observable public pageNotFound:boolean = false;
  @observable public isCustomersLoaded:boolean = false;
  @observable public isCustomersLoading:boolean = false;

  // ----------------------------------------------------
}
