import type { ApolloQueryResult } from '@apollo/client';
import { CommonController } from '@flow/common/CommonController';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { RouteName } from '@flow/common/models/routing/RouteName';
import { LocalStorageUtil } from '@flow/common/utils/LocalStorageUtil';
import { SlotUtil } from '@flow/common/utils/SlotUtil';
import type {
  StaffingBoardCustomersQuery,
  StaffingBoardCustomersQueryVariables,
  StaffingBoardCustomerTeamsNoNameQuery,
  StaffingBoardCustomerTeamsNoNameQueryVariables,
  StaffingBoardCustomerTeamsQuery,
  StaffingBoardCustomerTeamsQueryVariables
} from '@flow/data-access/lib/staffingBoard.generated';
import {
  StaffingBoardCustomersDocument,
  StaffingBoardCustomerTeamsDocument,
  StaffingBoardCustomerTeamsNoNameDocument
} from '@flow/data-access/lib/staffingBoard.generated';
import type { Recruiting_Position, Staffing_Customer_Team_Slot } from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import type {
  Common_Customer_Ex,
  Staffing_Customer_Team_Ex
} from '@flow/modules/customers/teams/models/CustomersTypes';
import { RoutesConfig } from 'apps/flow/src/pages/RoutesConfig';
import { action, reaction, runInAction } from 'mobx';
import type { Params } from 'react-router-dom';
import { StaffBoardState, StaffBoardState_LS_KEY } from './StaffBoardState';

@controller
export class StaffBoardController
{
  @di private _commonController!:CommonController;
  @di private _staffBoardState!:StaffBoardState;
  @di private _roleController!:RoleController;

  // ----------------------------------------------------

  @action.bound
  public initFromLocalStorage():void
  {
    const lsState:Partial<StaffBoardState> = LocalStorageUtil.getItem(StaffBoardState_LS_KEY);

    console.log('%c StaffBoardState: lsState =', 'background:#0ff;color:#000;', lsState);

    if( lsState )
    {
      this._staffBoardState.selectedCustomerId = lsState.selectedCustomerId || null;
    }

    const { disposers } = this._staffBoardState;

    Array.isArray(disposers) && disposers.push(reaction(
      () =>
      {
        const {
          selectedCustomerId
        } = this._staffBoardState;

        return {
          selectedCustomerId
        };
      },
      (lsValue:Partial<StaffBoardState>) =>
      {
        console.log('%c StaffBoardState: lsValue =', 'background:#0ff;color:#000;', lsValue);
        LocalStorageUtil.setItem(StaffBoardState_LS_KEY, lsValue);
      }
    ));
  }

  // ----------------------------------------------------

  @action
  public async initCustomers(params:Params<string>):Promise<void>
  {
    const { isCustomersLoaded, isCustomersLoading } = this._staffBoardState;

    console.log('%c initCustomers: isCustomersLoaded, isCustomersLoading =', 'background:#0f0;color:#000;', isCustomersLoaded, isCustomersLoading);

    if( isCustomersLoaded || isCustomersLoading ) return;

    this._staffBoardState.pageNotFound = false;
    this._staffBoardState.isCustomersLoading = true;
    this._staffBoardState.isCustomersLoaded = false;

    const customersResponse = await this._commonController.query<StaffingBoardCustomersQuery, StaffingBoardCustomersQueryVariables>({
      query: StaffingBoardCustomersDocument
    });

    console.log('%c initCustomers result =', 'background:#f60;color:#000;', customersResponse);

    if( customersResponse.data.common_customer )
    {
      runInAction(() =>
      {
        this._staffBoardState.customers = customersResponse.data.common_customer as Array<Common_Customer_Ex>;

        this._staffBoardState.isCustomersLoading = false;
        this._staffBoardState.isCustomersLoaded = true;
      });
    }

    // ---------------------------

    const customerId:number = parseInt(params.customerId || '')
      || this._staffBoardState.selectedCustomerId
      || (this._staffBoardState.customers.length > 0 ? this._staffBoardState.customers[0].id : -1);

    const { customers } = this._staffBoardState;

    console.log('%c --> customerId =', 'background:#080;color:#000;', customerId);

    if( !customers.find(customer => customer.id === customerId) )
    {
      runInAction(() =>
      {
        this._staffBoardState.pageNotFound = true;
      });
      LocalStorageUtil.setItem(StaffBoardState_LS_KEY, { selectedCustomerId: null });
      return;
    }

    if( customerId > 0 ) this.selectCustomer(customerId);
  }

  // ----------------------------------------------------

  @action
  public async queryCustomerTeams(customerId:number):Promise<void>
  {
    console.log('%c queryCustomerTeams =', 'background:#0f0;color:#000;', customerId);

    let customerResponse:ApolloQueryResult<StaffingBoardCustomerTeamsQuery | StaffingBoardCustomerTeamsNoNameQuery>;

    if( this._roleController.hasPermission(FlowPermissions.ViewCustomerName) )
      customerResponse = await this._commonController.query<StaffingBoardCustomerTeamsQuery, StaffingBoardCustomerTeamsQueryVariables>({
        query: StaffingBoardCustomerTeamsDocument,
        variables: {
          customerId
        }
      });
    else
      customerResponse = await this._commonController.query<StaffingBoardCustomerTeamsNoNameQuery, StaffingBoardCustomerTeamsNoNameQueryVariables>({
        query: StaffingBoardCustomerTeamsNoNameDocument,
        variables: {
          customerId
        }
      });

    console.log('%c --> queryCustomerTeams result =', 'background:#f60;color:#000;', customerResponse);

    if( customerResponse.data.common_customer_by_pk )
    {
      runInAction(() =>
      {
        this._staffBoardState.customer = customerResponse.data.common_customer_by_pk as Common_Customer_Ex;
      });
    }
  }

  // ----------------------------------------------------

  public selectCustomer(id:number):void
  {
    // if( id == 0 )
    // {
    //   this.initCustomers();
    // }

    console.log('%c selectCustomer =', 'background:#0f0;color:#000;', id);

    runInAction(() => this._staffBoardState.selectedCustomerId = id);

    const path:string | null = RoutesConfig.getRoutePath(
      RouteName.STAFFING_BOARD_CUSTOMER,
      {
        customerId: String(id)
      }
    );

    if( path )
      this._commonController.navigate(path);

    this.queryCustomerTeams(id);
  }

  // ----------------------------------------------------

  public findCustomerById(id:number):Common_Customer_Ex | undefined
  {
    return this._staffBoardState.customers.find(customer => customer.id == id);
  }

  // ----------------------------------------------------

  public getTeamLeadSlot(team:Staffing_Customer_Team_Ex):Staffing_Customer_Team_Slot | null
  {
    const leadPositions:Array<Recruiting_Position> = team.positions.filter((position:Recruiting_Position) =>
    {
      const staffRoleName:string = position.position_template?.staff_role.name ?? '';
      return staffRoleName.toLowerCase().indexOf('team lead') !== -1;
    });

    if( leadPositions.length !== 1 ) return null;

    const staffedSlots:Array<Staffing_Customer_Team_Slot> = SlotUtil.getStaffedSlots(leadPositions[0].customer_team_slots);

    if( staffedSlots.length !== 1 ) return null;

    return staffedSlots[0];
  }

  // ----------------------------------------------------
}
