import { component, di } from '@flow/dependency-injection';
import { InterviewFlowStage } from '@flow/modules/recruiting/status/components/InterviewFlowStage';
import { InterviewFlowsController } from '@flow/modules/recruiting/status/InterviewFlowsController';
import classNames from 'classnames/bind';
import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import type { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { InterviewFlowsState } from '../InterviewFlowsState';
import type { IInterviewFlowGroup, IInterviewFlowGroupStage } from '../models/InterviewFlowGroup';
import styles from './InterviewFlowGroup.module.less';
import { InterviewFlowGroupHeader } from './InterviewFlowGroupHeader';

const cx = classNames.bind(styles);

export interface IInterviewFlowGroupProps
{
  interviewFlowGroup:IInterviewFlowGroup;
}

@component
export class InterviewFlowGroup extends React.Component<IInterviewFlowGroupProps>
{
  @di private _interviewFlowsState!:InterviewFlowsState;
  @di private readonly _interviewFlowsController!:InterviewFlowsController;

  // ----------------------------------------------------

  private _isDropDisabled(stageId:number):boolean
  {
    const { interviewFlowGroup } = this.props;

    const { draggingCandidateId, draggingInterviewFlowGroupId, draggingCandidate } = this._interviewFlowsState;

    if( draggingCandidateId <= 0
      || draggingInterviewFlowGroupId !== interviewFlowGroup.id
      || !draggingCandidate
      || !draggingCandidate.interview_flow_stage_id
    ) return false;

    const isFromLastStage:boolean = this._interviewFlowsController.isLastStage(draggingCandidate?.interview_flow_stage_id);

    const isDraggingToLastStage:boolean = this._interviewFlowsController.isLastStage(stageId);
    const hasFinalizedPosition:boolean = draggingCandidate.customer_team_slots.length > 0;

    return isFromLastStage
      || (isDraggingToLastStage && !hasFinalizedPosition)
      || stageId > (draggingCandidate.interview_flow_stage_id + 1);
  }

  // ----------------------------------------------------

  public render():ReactNode
  {
    const { interviewFlowGroup } = this.props;
    const { selectedInterviewFlow, isGrouped } = this._interviewFlowsState;

    if( !interviewFlowGroup || !selectedInterviewFlow )
      return null;

    return (
      <div className={cx(styles.groupWrapper, { isGrouped })}>

        {/*<InterviewFlowGroupHeader interviewFlowGroup={interviewFlowGroup} />*/}
        
        <div className={styles.stagesWrapper}>
          {interviewFlowGroup.stages.map((stage:IInterviewFlowGroupStage) => (
            <Droppable
              key={stage.id}
              droppableId={`${interviewFlowGroup.id}-${stage.id}`}
              type={interviewFlowGroup.id}
              isDropDisabled={this._isDropDisabled(stage.id)}
            >
              {(provided:DroppableProvided, snapshot:DroppableStateSnapshot):ReactElement<HTMLElement> => (
                <React.Fragment key={stage.id}>
                  <InterviewFlowStage
                    interviewFlowGroup={interviewFlowGroup}
                    stage={stage}
                    provided={provided}
                    snapshot={snapshot}
                  />
                </React.Fragment>
              )}
            </Droppable>
          ))}
        </div>
      </div>
    );
  }

  // ----------------------------------------------------
}
