import { WindowLocationUtil } from '@flow/common/utils/WindowLocationUtil';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di, diModule } from '@flow/dependency-injection';
import { CandidateSiteSubsiteState } from '@flow/modules/recruiting/candidates/CandidateSiteSubsiteState';
import bind from 'bind-decorator';
import type React from 'react';
import { Component } from 'react';
import type { Params } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { CandidatesController } from './CandidatesController';
import { CandidatesState, CandidatesStatusFilter } from './CandidatesState';
import { CandidatesList } from './components/candidates/CandidatesList';
import { CandidatesToolbar } from './components/candidates/CandidatesToolbar';
import { CandidatesFilters } from './components/candidates/filters/CandidatesFilters';
import { CandidateForm } from './components/candidates/form/CandidateForm';

// TODO: remove
const __DEBUG:boolean = false;

@diModule({
  providers: [
    CandidateSiteSubsiteState,
    CandidatesState,
    CandidatesController
  ]
})
@componentWithRouter
export class Candidates extends Component<IWithRouterProps>
{
  @di private _candidatesState!:CandidatesState;
  @di private _candidatesController!:CandidatesController;

  // ----------------------------------------------------

  @bind
  public componentWillUnmount():void
  {
    this._candidatesController.disposeReactions();
  }

  // ----------------------------------------------------

  @bind
  public componentDidMount():void
  {
    // this._commonController.setContentBackground('#b7ceb44d');
    console.log('%c !!! componentDidMount =', 'background:#f00;color:#ff0;', this.props.params);
    this.checkIfCandidatePage(this.props.params as Params<string>);
  }

  // ----------------------------------------------------

  @bind
  public componentDidUpdate(prevProps:Readonly<IWithRouterProps>):void
  {
    console.log('%c !!! componentDidUpdate =', 'background:#f00;color:#ff0;', this.props.params);
    __DEBUG && console.log('------------------------------------ =');

    if( prevProps.params?.candidateId !== this.props.params?.candidateId )
    {
      __DEBUG && console.log('%c ---  prevProps.params?.candidateId =', 'background:#a00;color:#ff0;', prevProps.params?.candidateId);
      __DEBUG && console.log('%c --- this.props.params?.candidateId =', 'background:#a00;color:#ff0;', this.props.params?.candidateId);
      __DEBUG && console.log('%c prevProps =', 'background:#a00;color:#aa0;', prevProps);
      __DEBUG && console.log('%c thisProps =', 'background:#a00;color:#aa0;', this.props);
      __DEBUG && console.log('------------------------------------ =');
      this.checkIfCandidatePage(this.props.params as Params<string>);
    }
  }

  // ----------------------------------------------------

  @bind
  public checkIfCandidatePage(params:Params<string>):void
  {
    __DEBUG && console.log('%c checkIfCandidataPage Candidates params =', 'background:#00f;color:#ff0;', params);

    const candidateId:number = parseInt(String(params?.candidateId ?? '0'));
    __DEBUG && console.log('%c --- candidateId =', 'background:#080;color:#000;', candidateId);

    this._candidatesController.setViewCandidateId(candidateId || null);

    // candidates page
    if( !candidateId )
    {
      this._candidatesController.initCandidates();
    }
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    console.log('%c --- Candidates =', 'background:#080;color:#000;');

    const { isViewCandidatesPageContent, candidatesStatusFilterValue } = this._candidatesState;

    const requestedTab = WindowLocationUtil.getHashVariable('tab');

    if( requestedTab && requestedTab !== candidatesStatusFilterValue )
    {
      // check if requested tab exists
      for( const value of Object.values(CandidatesStatusFilter) )
      {
        if( requestedTab === value )
        {
          this._candidatesController.setFilterByCandidateStatus(value as CandidatesStatusFilter);
        }
      }
    }

    return (
      <>
        {
          isViewCandidatesPageContent &&
          <>
            <CandidatesToolbar />
            <CandidatesFilters />
            <CandidatesList />
            <CandidateForm />
          </>
        }
        <Outlet />
      </>
    );
  }

  // ----------------------------------------------------
}
