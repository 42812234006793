import { MenuDivider, MenuItem, Position } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { InfoPanel } from '@flow/common/components/elements/InfoPanel';
import { SvgIconName } from '@flow/common/components/elements/SvgIcon';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import type { IInlineEditorMultiSelectValue } from '@flow/common/components/form/InlineEditor';
import { InlineEditor, InlineEditorType } from '@flow/common/components/form/InlineEditor';
import { Intent } from '@flow/common/components/types/Types';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { ToastsController } from '@flow/common/toasts/ToastsController';
import { StringUtil } from '@flow/common/utils/StringUtil';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';
import { CandidateController, CandidateFieldName, FIELDS_MAP } from '../../CandidateController';
import { CandidateSiteSubsiteState } from '../../CandidateSiteSubsiteState';
import { CandidateState } from '../../CandidateState';

import styles from './CandidatePanelInfo.module.less';

const cx = classNames.bind(styles);

@component
export class CandidatePanelInfo extends React.Component
{
  @di private _candidateState!:CandidateState;
  @di private _candidateSiteSubsiteState!:CandidateSiteSubsiteState;
  @di private _candidateController!:CandidateController;
  @di private _toastsController!:ToastsController;
  @di private _roleController!:RoleController;

  // ----------------------------------------------------

  public componentDidMount():void
  {
    this._candidateController.renderFilledAdditionalFields();
  }

  // ----------------------------------------------------

  @bind
  private async _onCVUploaded(files:FileList):Promise<void>
  {
    const result = await this._candidateController.onCVUploaded(files);

    if( result )
    {
      this._toastsController.showSuccess('CV document uploaded successfully');
    }
    else
    {
      this._toastsController.showWarning('Failed to upload');
    }
  }

  // ----------------------------------------------------

  @bind
  private _renderAdditionalFieldItem(
    positionGroup:IInlineEditorMultiSelectValue,
    { index, handleClick, modifiers }:IItemRendererProps):JSX.Element | null
  {
    if( !modifiers.matchesPredicate )
    {
      return null;
    }

    if( !positionGroup )
    {
      return <MenuDivider key={`divider #${index}`} />;
    }

    return (
      <MenuItem
        key={positionGroup.title}
        text={positionGroup.title}
        className={styles.dialogMenuItem}
        onClick={handleClick}
        active={modifiers.active}
      />
    );
  }

  // ----------------------------------------------------

  public render():React.ReactNode | null
  {
    const { candidate, moreInfoSelectFields } = this._candidateState;
    const canEditCandidate = this._roleController.hasPermission(FlowPermissions.EditCandidate);
    const defaultMoreInfoFieldsKeys = Object.keys(FIELDS_MAP);

    if( !candidate )
      return null;

    const {
      siteSubsiteValuesForSelect,
      siteSubsiteValueForSelect,
      getSiteSubsiteStringBySelectValue
    } = this._candidateSiteSubsiteState;

    const { onChangeCandidateField, onCancelAdditionalInlineEditorClick } = this._candidateController;
    const {
      avatar_url,
      first_name, last_name,
      first_name_i18n, last_name_i18n, middle_name_i18n,
      linkedin_profile_url, email, phone
    } = candidate || {};

    const AdditionalFieldsDropdown = Select.ofType<IInlineEditorMultiSelectValue>();

    return (
      <InfoPanel
        className={styles.panel}
      >
        <div className={styles.userInfo}>

          <div className={cx(styles.left, 'test-candidate-panel')}>

            <InlineEditor
              icon={IconNames.PERSON}
              text={`${first_name} ${last_name}`}
              fields={[
                { name: CandidateFieldName.FIRST_NAME, value: first_name ?? '', label: 'First Name', isRequired: true },
                { name: CandidateFieldName.LAST_NAME, value: last_name ?? '', label: 'Last Name', isRequired: true }
              ]}
              onChange={onChangeCandidateField}
              readonly={!canEditCandidate}
            />

            <InlineEditor
              icon={IconNames.PERSON}
              iconClassName={styles.secondNameIcon}
              text={`${first_name_i18n || ''} ${middle_name_i18n || ''} ${last_name_i18n || ''}`}
              fields={[
                {
                  name: CandidateFieldName.FIRST_NAME_I18N,
                  value: first_name_i18n ?? '',
                  label: 'Имя',
                  placeholder: 'Имя',
                  isRequired: true
                },
                {
                  name: CandidateFieldName.MIDDLE_NAME_I18N,
                  value: middle_name_i18n ?? '',
                  label: 'Отчество',
                  placeholder: 'Отчество'
                },
                {
                  name: CandidateFieldName.LAST_NAME_I18N,
                  value: last_name_i18n ?? '',
                  label: 'Фамилия',
                  placeholder: 'Фамилия',
                  isRequired: true
                }
              ]}
              onChange={onChangeCandidateField}
              readonly={!canEditCandidate}
            />

            <InlineEditor
              svgIcon={SvgIconName.LinkedIn}
              iconClassName={styles.iconLinkedIn}
              text={linkedin_profile_url ?? ''}
              fields={[
                {
                  name: CandidateFieldName.LINKED_IN,
                  value: linkedin_profile_url ?? '',
                  placeholder: 'LinkedIn profile link'
                }
              ]}
              onChange={onChangeCandidateField}
              readonly={!canEditCandidate}
              viewAsLink={StringUtil.isStringALinkedinUrl(linkedin_profile_url)}
            />

            <InlineEditor
              icon={IconNames.ENVELOPE}
              text={email ?? ''}
              fields={[{ name: CandidateFieldName.EMAIL, value: email ?? '', placeholder: 'Email' }]}
              onChange={onChangeCandidateField}
              readonly={!canEditCandidate}
            />
            <InlineEditor
              wrapperClassName={cx(styles.phoneWrapper, 'test-phone')}
              icon={IconNames.PHONE}
              text={phone ?? ''}
              fields={[{ name: CandidateFieldName.PHONE, value: phone ?? '', placeholder: 'Phone' }]}
              onChange={onChangeCandidateField}
              readonly={!canEditCandidate}
            />
            <InlineEditor
              type={InlineEditorType.SELECT}
              wrapperClassName={cx(styles.siteWrapper, 'test-site')}
              icon={IconNames.GLOBE}
              // text={siteSubsiteString}
              text={''}
              getStringForNewValue={getSiteSubsiteStringBySelectValue}
              fields={[{
                name: CandidateFieldName.SITE_SUBSITE,
                value: siteSubsiteValueForSelect(candidate),
                values: siteSubsiteValuesForSelect,
                placeholder: 'Site • Subsite'
              }]}
              onChange={onChangeCandidateField}
              readonly={!canEditCandidate}
              applyWhenSelect
            />

            {this._candidateState.isExtended &&
            this._candidateState.inlineEditorList &&
            this._candidateState.inlineEditorList.map((inlineEditorType) =>
            {
              const { icon, text, fields } = FIELDS_MAP[inlineEditorType.key as keyof typeof FIELDS_MAP];
              return <InlineEditor
                wrapperClassName={`test-${inlineEditorType.key?.toString()}`}
                key={inlineEditorType.key}
                icon={icon}
                text={text(candidate)}
                fields={fields(candidate)}
                onChange={onChangeCandidateField}
                readonly={!canEditCandidate}
                isEditMode={inlineEditorType.isEditMode}
                onCancel={onCancelAdditionalInlineEditorClick}
              />;
            })
            }

            {
              this._candidateState.isExtended &&
              this._candidateState.inlineEditorList.length != defaultMoreInfoFieldsKeys.length &&
              <AdditionalFieldsDropdown
                className={styles.addInfoDropDown}
                items={[...moreInfoSelectFields]}
                itemRenderer={this._renderAdditionalFieldItem}
                onItemSelect={this._candidateController.onChangeAddMoreInfoClick}
                filterable={false}
                popoverProps={{
                  minimal: true,
                  usePortal: false,
                  position: Position.BOTTOM_LEFT
                }}
              >
                <Button
                  iconClassName={styles.addInfoBtnIcon}
                  className={cx(styles.addInfoBtn)}
                  icon={IconNames.Add}
                  text={'Add info'}
                  minimal={true}
                />
              </AdditionalFieldsDropdown>
            }

          </div>

          {/* ------------------------------- */}

          <div className={styles.right}>

            <div className={styles.photo}>
              {
                avatar_url &&
                <img
                  className={styles.photoImg}
                  src={avatar_url}
                  alt={first_name}
                />
              }
            </div>

          </div>

        </div>

        <div className={cx(styles.moreInfoWrapper)}>
          <Button
            className={styles.btnMoreDetails}
            text={(
              <span>
                {this._candidateState.isExtended ? 'Less details' : 'More details'}
                {
                  (!this._candidateState.isExtended && this._candidateState.inlineEditorList.length !== 0) &&
                  <span className={styles.btnMoreDetailsPlus}> +{this._candidateState.inlineEditorList.length}</span>
                }
              </span>
            )}
            minimal={true}
            intent={Intent.PRIMARY}
            onClick={this._candidateController.handleMoreInfoClick}
          />
        </div>

      </InfoPanel>
    );
  }

  // ----------------------------------------------------
}
