import type { Maybe } from '@flow/common/models/Types';
import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './ShowArrayAsStringPlusX.module.less';

const cx = classNames.bind(styles);

// TODO: remove
const __DEBUG:boolean = false;

interface Props
{
  className?:string;
  items:Array<string>;

  plusXClassName?:string;
}

interface State
{
  showPlusX:boolean;
  showPlusXNumber:number;
}

@component
export class ShowArrayAsStringPlusX extends React.Component<Props, State>
{
  // ----------------------------------------------------

  private _resizeObserver:ResizeObserver | null = null;
  private _itemWidths:Array<number> = [];

  private _wrapperRef:React.RefObject<HTMLDivElement> = React.createRef();
  private _itemRefs:Array<React.RefObject<HTMLDivElement>> = [];

  // ----------------------------------------------------

  public constructor(props:Props)
  {
    super(props);

    const { items } = this.props;

    this._itemRefs = items.map(item => React.createRef());

    this.state = {
      showPlusX: false,
      showPlusXNumber: 0
    };
  }

  // ----------------------------------------------------

  public componentDidMount():void
  {
    __DEBUG && console.log('%c componentDidMount =', 'background:#0f0;color:#000;');

    this._calcItemWidths();
    this._recalcVisibleItems();

    this._resizeObserver = new ResizeObserver(this._recalcVisibleItems);
    this._resizeObserver.observe(this._wrapperRef.current as Element);
  }

  // ----------------------------------------------------

  public componentDidUpdate():void
  {
    __DEBUG && console.log('%c componentDidUpdate =', 'background:#0f0;color:#000;', this._wrapperRef.current);
  }

  // ----------------------------------------------------

  @bind
  private _recalcVisibleItems():void
  {
    const { items } = this.props;

    __DEBUG && console.log('--------------------------------- =');
    __DEBUG && console.log('%c items         =', 'background:#0f0;color:#000;', items);
    __DEBUG && console.log('%c wrapperRef    =', 'background:#080;color:#000;', this._wrapperRef);
    __DEBUG && console.log('%c itemRefs      =', 'background:#080;color:#000;', this._itemRefs);

    const wrapperEl:Maybe<HTMLElement> = this._wrapperRef.current;

    if( !wrapperEl || !this._itemRefs.length ) return;

    let widthSum:number = 0;

    const plusXWidth:number = 20;

    const wrapperWidth:number = wrapperEl.getBoundingClientRect().width;
    __DEBUG && console.log('%c wrapperWidth =', 'background:#0f0;color:#000;', wrapperWidth, wrapperEl);

    let showPlusX:boolean = false;
    let showPlusXNumber:number = 0;

    this._itemRefs.forEach((item, index:number) =>
    {
      const el:Maybe<HTMLElement> = item.current;

      if( showPlusX && el )
      {
        el.style.display = 'none';
        return;
      }

      const elWidth:number = this._itemWidths[index]; // el?.getBoundingClientRect().width || 0;
      __DEBUG && console.log('%c elWidth =', 'background:#0f0;color:#000;', elWidth, el);

      __DEBUG && console.log('%c widthSum + elWidth =', 'background:#0f0;color:#000;', widthSum + elWidth, el);

      if( widthSum + elWidth > wrapperWidth - (index === this._itemRefs.length - 1 ? 0 : plusXWidth) && index )
      {
        showPlusX = true;
        if( el ) el.style.display = 'none';

        showPlusXNumber = this._itemRefs.length - index;

        __DEBUG && console.log('%c itemRefs.length - 1 - index =', 'background:#fcf;color:#000;', this._itemRefs.length - 1 - index, wrapperEl);
        return;
      }

      if( el ) el.style.display = 'initial';

      widthSum += elWidth;
    });

    this.setState({ showPlusX, showPlusXNumber });
  }

  // ----------------------------------------------------

  @bind
  private _calcItemWidths():void
  {
    const wrapperEl:Maybe<HTMLElement> = this._wrapperRef.current;

    if( !wrapperEl || !this._itemRefs.length ) return;

    this._itemRefs.forEach((item, index:number) =>
    {
      const el:Maybe<HTMLElement> = item.current;

      if( el ) this._itemWidths[index] = el?.getBoundingClientRect().width || 0;
    });
  }

  // ----------------------------------------------------

  public render():ReactNode
  {
    const { showPlusX, showPlusXNumber } = this.state;

    const { className, items, plusXClassName } = this.props;

    return (
      <div className={cx(styles.wrapper, className)}>

        <div
          className={cx(styles.inner)}
          ref={this._wrapperRef}
        >
          {
            items.map((item:string, index:number) =>
            {
              return (
                <React.Fragment key={`${index}${item}`}>
                  <span
                    ref={this._itemRefs[index]}
                  >
                    {
                      index > 0 &&
                      <span>,&nbsp;</span>
                    }
                    {item}
                  </span>
                  {
                    index === items.length - 1 && (showPlusX && showPlusXNumber) &&
                    <div className={cx(styles.plusX, plusXClassName)}>
                      &nbsp;+{showPlusXNumber}
                    </div>
                  }
                </React.Fragment>
              );
            })
          }
        </div>

      </div>
    );
  }

  // ----------------------------------------------------
}
