import { Position } from '@blueprintjs/core';
import { CommonController } from '@flow/common/CommonController';
import { SvgIcon, SvgIconName } from '@flow/common/components/elements/SvgIcon';
import { Button } from '@flow/common/components/form/Button';
import { IconNames } from '@flow/common/components/form/Icon';
import { InlineEditor } from '@flow/common/components/form/InlineEditor';
import { CollapseList } from '@flow/common/components/page/CollapseList';
import { Intent } from '@flow/common/components/types/Types';
import { AuthState } from '@flow/common/controllers/AuthState';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { RouteName } from '@flow/common/models/routing/RouteName';
import type { Maybe } from '@flow/common/models/Types';
import { ToastsController } from '@flow/common/toasts/ToastsController';
import type { DateStrOrNum } from '@flow/common/utils/DateUtil';
import { DateUtil } from '@flow/common/utils/DateUtil';
import { DebugUtil } from '@flow/common/utils/DebugUtil';
import type { Common_Scheduled_Event } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { CandidateController } from '@flow/modules/recruiting/candidates/CandidateController';
import { CandidateEventsCollapseListId, CandidateState } from '@flow/modules/recruiting/candidates/CandidateState';
import type { ScheduleEventForEdit } from '@flow/modules/recruiting/schedule/ScheduleState';
import { ScheduleState } from '@flow/modules/recruiting/schedule/ScheduleState';
import { RoutesConfig } from 'apps/flow/src/pages/RoutesConfig';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { computed, runInAction } from 'mobx';
import { computedFn } from 'mobx-utils';
import type { Duration, Moment } from 'moment';
import moment from 'moment';
import React from 'react';
import { EventAttendeesListEditor } from '../event/EventAttendeesListEditor';
import { EventDateEditor } from '../event/EventDateEditor';
import { EventEndTimeEditor } from '../event/EventEndTimeEditor';
import { EventStartTimeEditor } from '../event/EventStartTimeEditor';

import styles from './CommunicationEventForm.module.less';

const cx = classNames.bind(styles);

interface ICandidatePanelEventFormProps
{
  eventForEdit:ScheduleEventForEdit;

  onSave:() => void;
  onUpdate?:() => void;
  onCancel?:() => void;
  onRevertChanges?:() => void;
}

@component
export class CommunicationEventForm extends React.Component<ICandidatePanelEventFormProps>
{
  @di private readonly _candidateState!:CandidateState;
  @di private readonly _candidateController!:CandidateController;
  @di private readonly _authState!:AuthState;
  @di private readonly _toastsController!:ToastsController;
  @di private readonly _commonController!:CommonController;
  @di private readonly _scheduleState!:ScheduleState;
  @di private readonly _roleController!:RoleController;

  // ---------------------------------------------------------

  @computed
  private get _event():Partial<Common_Scheduled_Event>
  {
    return this.props.eventForEdit.event as Partial<Common_Scheduled_Event>;
  }

  @computed
  private get _snapshot():Partial<Common_Scheduled_Event>
  {
    return this.props.eventForEdit.snapshot as Partial<Common_Scheduled_Event>;
  }

  @computed
  private get _hasPermissions():boolean
  {
    return this._roleController.hasPermission(FlowPermissions.CompleteEvent);
    // && this.props.event.user_id == this._authState.user?.id;
  }

  @computed
  private get _isUpcoming():boolean
  {
    return (this._event as Common_Scheduled_Event).is_scheduled && !(this._event as Common_Scheduled_Event).is_done;
  }

  private _isChanged = computedFn((fieldName:keyof Common_Scheduled_Event, params:Maybe<string>):boolean =>
  {
    if( !this._isUpcoming || !this._event || !this._snapshot ) return false;

    if( fieldName === 'start_time' && params === 'day' )
    {
      return !DateUtil.isSameDay(this._event[fieldName] as DateStrOrNum, this._snapshot[fieldName] as DateStrOrNum);
    }

    if( fieldName === 'start_time' || fieldName === 'end_time' )
    {
      return !DateUtil.isSameTimeIgnoringDate(this._event[fieldName] as DateStrOrNum, this._snapshot[fieldName] as DateStrOrNum);
    }

    return this._event[fieldName] !== this._snapshot[fieldName];
  });

  // ---------------------------------------------------------

  @bind
  private _onUpdate():void
  {
    if( this.props.eventForEdit.event.is_scheduled ) return;

    // isDraft
    this.props.onUpdate?.();
  }

  // ---------------------------------------------------------

  private _startTimeChangeHandler(newStartTime:Moment, duration:Duration):void
  {
    runInAction(() =>
    {
      this._scheduleState.scheduledEventStartTimeQuery = newStartTime.format('h:mm A');
      const newEndTime = moment(newStartTime).add(duration);
      this._scheduleState.scheduledEventEndTimeQuery = newEndTime.format('h:mm A');

      this._event.start_time = newStartTime.toDate();
      this._event.end_time = newEndTime.toDate();

      this._onUpdate();
    });
  }

  // ---------------------------------------------------------

  private _endTimeChangeHandler(newEndTime:Moment):void
  {
    runInAction(() =>
    {
      this._event.end_time = moment(newEndTime).toDate();
      this._onUpdate();
    });
  }

  // ---------------------------------------------------------

  public componentDidMount():void
  {
    const { candidate } = this._candidateState;
    const { user } = this._authState;

    const userId = user?.id;

    if( !user || !userId || !candidate )
      return;

    const btnBackUrl:string | null = this._commonController.getCandidateUrl(candidate.id) || null;

    if( !btnBackUrl )
      return;

    runInAction(() =>
    {
      this._scheduleState.btnBackUrl = btnBackUrl;
    });
  }

  // ---------------------------------------------------------

  @bind
  private _renderHeader():React.ReactNode
  {
    const { onCancel } = this.props;

    const { isSchedulingEvent } = this._candidateState;

    return (
      <div className={styles.header}>

        <div className={styles.title}>
          {DebugUtil.id(this._event.id)}
          {this._isUpcoming ? 'Edit recruiter call' : 'Schedule a recruiter call'}
        </div>

        <div className={styles.spacer} />

        {
          (this._hasPermissions || !this._isUpcoming) && // isDraft
          <Button
            className={styles.btn}
            intent={Intent.NONE}
            icon={IconNames.CROSS}
            minimal={true}
            outlined={false}
            disabled={isSchedulingEvent}
            onClick={(e):void =>
            {
              e.stopPropagation();
              onCancel?.();
            }}
          />
        }
      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderFormBody():React.ReactNode
  {
    return (
      <>
        <div className={styles.content}>

          {this._renderSummary()}
          {this._renderEventTimeRow()}
          {this._renderParticipantsRow()}
          {this._renderDescriptionRow()}
          {this._renderLinkRow()}

        </div>

        {this._renderActionBar()}
      </>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderSummary():React.ReactNode
  {
    const { candidate } = this._candidateState;

    const title = this._event.summary || `Recruiter call with ${candidate?.first_name} ${candidate?.last_name}`;

    const isChanged:boolean = this._isChanged('summary', '');

    return (
      <div className={styles.summary}>

        <InlineEditor
          viewClassName={styles.summary}
          text={title}
          isChanged={isChanged}
          fields={[
            {
              name: 'title',
              value: this._event.summary || '',
              placeholder: 'Enter title'
            }
          ]}
          onChange={(value):void =>
          {
            runInAction(() =>
            {
              this._event.summary = String(value['title'].value);
              this._onUpdate();
            });
          }}
        />

      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderEventTimeRow():React.ReactNode
  {
    const {
      scheduledEventStartTimeQuery,
      scheduledEventEndTimeQuery
    } = this._scheduleState;

    const startTime = moment(this._event.start_time);
    const endTime = moment(this._event.end_time);

    const duration = moment.duration(endTime.diff(startTime));
    const durationInHours = Number(duration.hours()) + (duration.minutes() / 60);

    const startDateStr = startTime.format('MMMM DD, YYYY');
    const startTimeStr = startTime.format('h:mm A');
    const endTimeStr = String(endTime.format('h:mm A'));

    const daysDiff = endTime.day() - startTime.day();

    const isChangedDay:boolean = this._isChanged('start_time', 'day');
    const isChangedStartTime:boolean = this._isChanged('start_time', '');
    const isChangedEndTime:boolean = this._isChanged('end_time', '');

    return (
      <div className={styles.timeRow}>

        <div className={styles.dateSelector}>

          <EventDateEditor
            startDateStr={startDateStr}
            scheduledEventStartTime={moment(this._event.start_time).toDate()}
            isChanged={isChangedDay || isChangedStartTime || isChangedEndTime}
            isChangedDay={isChangedDay}
            readonly={!this._hasPermissions}
            onChange={(newDate):void => this._startTimeChangeHandler(moment(newDate) as Moment, duration as Duration)}
            popoverProps={{
              usePortal: true,
              position: Position.TOP,
              modifiers: {
                preventOverflow: {
                  enabled: false
                }
              }
            }}
          />

        </div>

        <div className={styles.divider}>-</div>

        <div className={styles.startTimeSelector}>

          <EventStartTimeEditor
            time={startTime.toDate()}
            timeStr={startTimeStr}
            timeQuery={scheduledEventStartTimeQuery}
            readonly={!this._hasPermissions}
            isChanged={isChangedStartTime}
            onChange={(item):void => this._startTimeChangeHandler(item as Moment, duration as Duration)}
            onQueryChange={(query):unknown => runInAction(():unknown => this._scheduleState.scheduledEventStartTimeQuery = query)}
            onTimeUpDown={(date):void => this._startTimeChangeHandler(moment(date) as Moment, duration as Duration)}
          />

        </div>

        <div className={styles.divider}>-</div>

        <div className={styles.endTimeSelector}>

          <EventEndTimeEditor
            startTime={startTime.toDate()}
            time={endTime.toDate()}
            timeStr={endTimeStr}
            timeQuery={scheduledEventEndTimeQuery}
            readonly={!this._hasPermissions}
            isChanged={isChangedEndTime}
            onChange={(item):void => this._endTimeChangeHandler(item as Moment)}
            onQueryChange={(query):unknown => runInAction(():unknown => this._scheduleState.scheduledEventEndTimeQuery = query)}
            onTimeUpDown={(date):void => this._endTimeChangeHandler(moment(date) as Moment)}
          />

        </div>

        {
          daysDiff !== 0 &&
          <div className={cx(styles.duration, { isNegative: (durationInHours < 0) })}>
            {endTime.format('MMM DD')}
          </div>
        }

      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderParticipantsRow():React.ReactNode | null
  {
    const { candidate } = this._candidateState;

    if( !candidate?.email ) return null;

    const { allUsers } = this._scheduleState;

    const userId:number = this._event.user_id || 0;

    const isChanged:boolean = this._isChanged('attendees_list', '');

    return (
      <div className={styles.participantsRow}>

        <div className={styles.participants}>

          <EventAttendeesListEditor
            scheduledEventAttendeesList={this._candidateController.getEventAttendeesAsUsers(this._event)}
            candidateEmail={candidate.email}
            userId={userId}
            allUsers={allUsers}
            readonly={!this._hasPermissions}
            isChanged={isChanged}
            onChange={(userEmails):void =>
            {
              runInAction(() =>
              {
                this._event.attendees_list = userEmails.join(',');
                this._onUpdate();
              });
            }}
          />

        </div>

      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderDescriptionRow():React.ReactNode
  {
    const isChanged:boolean = this._isChanged('description', '');

    return (
      <div className={styles.descriptionRow}>

        <div className={styles.description}>

          <InlineEditor
            svgIcon={SvgIconName.TableDescription}
            text={this._event.description || ''}
            fields={[
              {
                name: 'description',
                value: this._event.description || '',
                placeholder: 'Enter description'
              }
            ]}
            readonly={!this._hasPermissions}
            iconClassName={cx(styles.iconField, { isChanged })}
            // isChanged={isChanged}
            onChange={(value):void =>
            {
              runInAction(():void =>
              {
                if( this._event )
                {
                  this._event.description = String(value['description'].value);
                }
                this._onUpdate();
              });
            }}
            textArea
          />

        </div>

      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderLinkRow():React.ReactNode | null
  {
    if( !this._event.conference_link ) return null;

    return (
      <div className={styles.linkRow}>
        <SvgIcon icon={SvgIconName.GoogleMeet} />

        {this._event.conference_link}

        <Button
          minimal
          icon={IconNames.SHARE}
          className={styles.googleMeetBtn}
          onClick={():void =>
          {
            if( this._event )
              this._commonController.openInNewTab(String(this._event.conference_link));
          }}
        />
      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderActionBar():React.ReactNode
  {
    const { onRevertChanges, onSave } = this.props;

    const { candidate, isSchedulingEvent } = this._candidateState;

    // const { allUsers } = this._scheduleState;

    // const eventUser:Common_User | undefined = allUsers.find(user => user.id == event.user_id);

    // const updatedMoment = moment(event.updated_at);
    // const updatedDate = updatedMoment.format(moment().year() == updatedMoment.year() ? 'MMM DD' : 'MMM DD, YYYY');

    const isScheduleCallBtnDisabled:boolean = this._isUpcoming && !(
      this._isChanged('summary', '') ||
      this._isChanged('description', '') ||
      this._isChanged('attendees_list', '') ||
      this._isChanged('start_time', 'day') ||
      this._isChanged('start_time', '') ||
      this._isChanged('end_time', '')
    );

    return (
      <div className={styles.actionBar}>

        <Button
          text={'Manage call details'}
          minimal
          disabled={!this._hasPermissions || isSchedulingEvent}
          className={styles.btnManageCall}
          onClick={():void =>
          {
            const routePath = RoutesConfig.getRoutePath(RouteName.RECRUITING_CANDIDATE_SCHEDULE_EVENT_ID, {
              candidateId: String(candidate?.id),
              eventId: String(this._event.id)
            });
            this._commonController.navigate(String(routePath));
          }}
        />

        {/*{*/}
        {/*  this._isUpcoming && !this._hasRescheduleDiffs &&*/}
        {/*  <div*/}
        {/*    className={styles.scheduledByLabel}*/}
        {/*    title={`${eventUser?.first_name || '?'} ${(eventUser?.last_name || '?')}`}*/}
        {/*  >*/}
        {/*    Scheduled*/}
        {/*    by {`${eventUser?.first_name || '?'} ${(eventUser?.last_name || '?').charAt(0)}, on ${updatedDate as string}`}*/}
        {/*  </div>*/}
        {/*}*/}

        {/* {
            !this._isUpcoming &&
            <div className={styles.scheduledByLabel} title={`${eventUser?.first_name || '?'} ${(eventUser?.last_name || '?')}`}>
              Draft by: {`${eventUser?.first_name || '?'} ${(eventUser?.last_name || '?').charAt(0)}, on ${updatedDate as string}`}
            </div>
          } */}

        {
          (this._isUpcoming && !isSchedulingEvent) &&
          <Button
            className={cx(styles.btnRevertChanges, 'test-revert-changes')}
            // minimal
            outlined={true}
            intent={Intent.PRIMARY}
            text={'Cancel'}
            // disabled={readonly || isSchedulingEvent}
            disabled={!this._hasPermissions || isSchedulingEvent}
            loading={isSchedulingEvent}
            onClick={():void =>
            {
              onRevertChanges?.();
            }}
          />
        }

        {/*{*/}
        {/*  (!this._isUpcoming || this._hasRescheduleDiffs) &&*/}
        <Button
          className={cx(styles.btnSave, 'test-schedule-this._event')}
          intent={this._isUpcoming ? Intent.WARNING : Intent.PRIMARY}
          text={this._isUpcoming ? 'Reschedule' : 'Schedule'}
          disabled={!this._hasPermissions || isScheduleCallBtnDisabled || isSchedulingEvent}
          loading={isSchedulingEvent}
          onClick={():void =>
          {
            onSave();
          }}
        />
        {/*}*/}

      </div>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { onCancel } = this.props;

    const { candidate } = this._candidateState;

    const { user } = this._authState;

    if( !candidate?.email )
    {
      this._toastsController.showWarning('Please fill in candidate\'s email before scheduling a call');
      onCancel?.();
      return null;
    }

    if( !candidate || !user || !user.id ) return null;

    return (
      <CollapseList
        id={CandidateEventsCollapseListId}
        items={[this._event as Common_Scheduled_Event]}
        itemRenderer={this._renderHeader}
        customSubItemsRenderer={this._renderFormBody}
        defaultCollapsed={false}

        wrapperClassName={cx(styles.collapseListWrapper, styles.animate)}
        itemWrapperClassName={styles.collapseListItemWrapper}
        itemClassName={styles.collapseListItem}
        chevronClassName={styles.collapseListBtn}
      />
    );
  }

  // ---------------------------------------------------------
}
