import type { Recruiting_Interview_Flow } from '@flow/data-access/lib/types/graphql.generated';
import { state } from '@flow/dependency-injection';
import type {
  Recruiting_Position_Ex,
  Recruiting_Position_Group_Ex
} from '@flow/modules/customers/teams/models/CustomersTypes';
import type { IReactionDisposer } from 'mobx';
import { computed, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

export const SettingsPositionGroupsState_LS_KEY:string = 'settingsPositionGroupsState';

export enum SettingsPositionGroupsStatus
{
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

@state
export class SettingsPositionGroupsState
{
  // ----------------------------------------------------

  public disposers:Array<IReactionDisposer> = [];

  // ----------------------------------------------------

  // @observable public positions:Array<Recruiting_Position_Ex> = [];
  @observable public positionGroups:Array<Recruiting_Position_Group_Ex> = [];
  @observable public flows:Array<Recruiting_Interview_Flow> = [];

  // ----------------------------------------------------

  @observable public showEditPositionGroupDialog:boolean = false;
  @observable public isNewPositionGroupDialog:boolean = false;

  @observable public editPositionGroup:Recruiting_Position_Group_Ex | null = null;
  @observable public editPositionGroupValue:string = '';
  @observable public editPositionGroupInterviewFlowId:number = -1;

  // ----------------------------------------------------

  @observable public isLoaded:boolean = false;

  @observable public filterByPositionGroupStatus:SettingsPositionGroupsStatus = SettingsPositionGroupsStatus.ACTIVE;

  // ----------------------------------------------------

  @computed
  public get filteredPositionGroups():Array<Recruiting_Position_Group_Ex>
  {
    return this.positionGroups
      .filter((positionGroup:Recruiting_Position_Group_Ex) =>
      {
        return positionGroup.is_active && this.filterByPositionGroupStatus === SettingsPositionGroupsStatus.ACTIVE ||
          !positionGroup.is_active && this.filterByPositionGroupStatus === SettingsPositionGroupsStatus.ARCHIVED;
      })
      .sort((a:Recruiting_Position_Group_Ex, b:Recruiting_Position_Group_Ex) =>
      {
        return a.ui_order - b.ui_order;
      });
  }

  @computed
  public get activePositionGroups():Array<Recruiting_Position_Group_Ex>
  {
    return this.positionGroups
      .filter((positionGroup:Recruiting_Position_Group_Ex) => positionGroup.is_active)
      .sort((a:Recruiting_Position_Group_Ex, b:Recruiting_Position_Group_Ex) => a.ui_order - b.ui_order);
  }

  @computed
  public get inactivePositionGroups():Array<Recruiting_Position_Group_Ex>
  {
    return this.positionGroups
      .filter((positionGroup:Recruiting_Position_Group_Ex) => !positionGroup.is_active)
      .sort((a:Recruiting_Position_Group_Ex, b:Recruiting_Position_Group_Ex) => a.ui_order - b.ui_order);
  }

  // ----------------------------------------------------

  public positionGroupById:(id:number) => Recruiting_Position_Group_Ex | undefined = computedFn((id:number):Recruiting_Position_Group_Ex | undefined =>
  {
    return this.positionGroups.find((positionGroup:Recruiting_Position_Group_Ex) => positionGroup.id === id);
  });

  public positionGroupIndexById:(id:number | null | undefined) => number = computedFn((id:number | null | undefined):number =>
  {
    if( id == null ) return -1;
    return this.positionGroups.findIndex((positionGroup:Recruiting_Position_Group_Ex) => positionGroup.id === id);
  });

  public activePositionGroupIndexById:(id:number | null | undefined) => number = computedFn((id:number | null | undefined):number =>
  {
    if( id == null ) return -1;
    return this.activePositionGroups.findIndex((positionGroup:Recruiting_Position_Group_Ex) => positionGroup.id === id);
  });

  // ----------------------------------------------------

  public flowById:(id:number) => Recruiting_Interview_Flow | undefined = computedFn((id:number):Recruiting_Interview_Flow | undefined =>
  {
    return this.flows.find((flow:Recruiting_Interview_Flow) => flow.id === id);
  });

  // ----------------------------------------------------

  public openAndLeavingSlots:(positionGroup:Recruiting_Position_Group_Ex) => number = computedFn((positionGroup:Recruiting_Position_Group_Ex):number =>
  {
    return positionGroup.positions.reduce((sum:number, position:Recruiting_Position_Ex) =>
    {
      const openSlotsCount = position.open_slots_count.aggregate.count + (position.leaving_slots?.length || 0);

      return sum + openSlotsCount;
    }, 0);
  });

  // ----------------------------------------------------
}
