import { CommonController } from '@flow/common/CommonController';
import { InfoPanel } from '@flow/common/components/elements/InfoPanel';
import { BlankState } from '@flow/common/components/form/BlankState';
import { Button } from '@flow/common/components/form/Button';
import { InlineEditor, InlineEditorType } from '@flow/common/components/form/InlineEditor';
import { NeedsPermission } from '@flow/common/components/NeedsPermission';
import { Intent } from '@flow/common/components/types/Types';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { componentWithRouter, di } from '@flow/dependency-injection';
import { CandidateFlowState } from '@flow/modules/recruiting/candidates/CandidateFlowState';
import { CandidatePositionGroupsState } from '@flow/modules/recruiting/candidates/CandidatePositionGroupsState';
import { AllocationPositionsFilter } from '@flow/modules/recruiting/candidates/components/candidate/allocation/AllocationPositionsFilter';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';
import { CandidateAllocationController } from '../../../CandidateAllocationController';
import { CandidateAllocationState } from '../../../CandidateAllocationState';
import { CandidateController, CandidateFieldName } from '../../../CandidateController';
import { CandidateState } from '../../../CandidateState';
import { AllocationPositions } from './AllocationPositions';

import styles from './CandidatePanelAllocation.module.less';
import { FinalizeAllocationDialog } from './FinalizeAllocationDialog';

const cx = classNames.bind(styles);

@componentWithRouter
export class CandidatePanelAllocation extends React.Component<unknown>
{
  @di private readonly _commonController!:CommonController;

  @di private _candidateFlowState!:CandidateFlowState;

  @di private _candidateState!:CandidateState;
  @di private _candidateController!:CandidateController;

  @di private _roleController!:RoleController;

  @di private _candidatePositionGroupsState!:CandidatePositionGroupsState;

  @di private _candidateAllocationState!:CandidateAllocationState;
  @di private _candidateAllocationController!:CandidateAllocationController;

  // ---------------------------------------------------------

  // @bind
  // private _positionGroupsItemPredicate(_:string, item:IInlineEditorMultiSelectValue, newValues?:Record<string, IInlineEditorField>):boolean
  // {
  //   const candidateInterviewFlowId = this._candidateState.candidate?.candidate_position_groups[0]?.position_group?.interview_flow_id;
  //   const isInterviewFlowStageInitial = this._candidateState.candidate?.status == Recruiting_Candidate_Status_Enum.Initial;
  //
  //   const itemPositionGroup = this._candidatePositionGroupsState.positionGroups.find(pg => pg.id === item.value);
  //
  //   if( !newValues || !newValues[CandidateFieldName.POSITION_GROUPS].value
  //     || (newValues[CandidateFieldName.POSITION_GROUPS].value as []).length == 0 )
  //   {
  //     return !candidateInterviewFlowId || isInterviewFlowStageInitial || itemPositionGroup?.interview_flow_id == candidateInterviewFlowId;
  //   }
  //
  //   const values = newValues[CandidateFieldName.POSITION_GROUPS].value as Array<number>;
  //
  //   const anySelectedPositionGroup = this._candidatePositionGroupsState.positionGroups.find(pg => pg.id === values[0]);
  //
  //   return itemPositionGroup?.interview_flow_id == anySelectedPositionGroup?.interview_flow_id;
  // }

  @bind
  private _renderPGEditor(isDisabled:boolean):React.ReactNode | null
  {
    const { isCandidateLoaded } = this._candidateState;

    if( !isCandidateLoaded ) return null;

    const { isCandidateInArchivedStatus } = this._candidateFlowState;

    const {
      candidatePositionGroupIds, positionGroupsValuesForMultiSelect
    } = this._candidatePositionGroupsState;

    const isPositionGroupsReadonly = isCandidateInArchivedStatus ||
      !this._roleController.hasPermission(FlowPermissions.ChangeCandidatePositionGroups);

    return (
      <InlineEditor
        type={InlineEditorType.MULTISELECT}
        wrapperClassName={styles.pgEditor}
        isPills={false}
        viewClassName={styles.viewClassName}
        editClassName={styles.editClassName}
        text={''}
        textBefore={'Positions by'}
        textBeforeClassName={styles.textBefore}
        applyWhenSelect={false}
        fields={[
          {
            name: CandidateFieldName.POSITION_GROUPS,
            value: candidatePositionGroupIds,
            values: positionGroupsValuesForMultiSelect,
            isRequired: true
          }
        ]}
        onChange={this._candidateController.onChangeCandidateField}
        // itemPredicate={this._positionGroupsItemPredicate}
        readonly={isPositionGroupsReadonly}
      />
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { candidate, isCandidateLoaded } = this._candidateState;

    if( !candidate ) return null;

    const { inPanelAutoComputedPositions, isFinalized } = this._candidateAllocationState;
    const { isCandidateInArchivedStatus, isCandidateOnFlow, isCandidateInInitialStatus } = this._candidateFlowState;

    const isStaffLinked:boolean = !!candidate.staff_id;

    const panelTitle:string = !isCandidateLoaded
      ? 'Loading...'
      : isStaffLinked
        ? 'Start Position'
        : isFinalized
          ? 'Final Position'
          : '';

    // const panelTitle:string = !isCandidateLoaded
    //   ? 'Allocation: Loading...'
    //   : isStaffLinked
    //     ? 'Allocation: Converted to staff'
    //     : isFinalized
    //       ? 'Allocation: Final'
    //       : actualProposedAllocations.length > 0
    //         ? 'Allocation: Proposed'
    //         : 'Allocation: None';

    const isBtnFinalizeDisabled:boolean = isFinalized ? false : inPanelAutoComputedPositions.length === 0;

    return (
      <InfoPanel
        className={styles.panel}
        headerTitle={panelTitle}
        headerContent={isCandidateLoaded ? (
          <div className={styles.headerContent}>

            <div className={styles.title}>
              {
                (isCandidateLoaded && !isFinalized && !isStaffLinked) &&
                this._renderPGEditor(isCandidateInArchivedStatus)
              }
            </div>

            {
              // TODO: check isArchivedStatus
              (!isCandidateInInitialStatus) &&
              <div className={styles.buttons}>
                {
                  // !isStaffLinked && !isFinalized &&
                  // <NeedsPermission name={FlowPermissions.CreateProposedAllocation}>
                  //   <Button
                  //     className={cx(styles.btnAllocation, styles.btnTodoRemove, { isDisabled: isBtnProposeDisabled })}
                  //     text={'Propose'}
                  //     // minimal={true}
                  //     // outlined={true}
                  //     intent={Intent.DANGER}
                  //     disabled={isBtnProposeDisabled}
                  //     onClick={this._candidateAllocationController.showProposeAllocationDialog}
                  //   />
                  // </NeedsPermission>
                }
                {
                  (isCandidateLoaded && !isFinalized && !isStaffLinked) &&
                  <AllocationPositionsFilter />
                }
                {(!isStaffLinked && isCandidateOnFlow) &&
                <NeedsPermission name={FlowPermissions.CreateFinalAllocation}>
                  <Button
                    className={cx(styles.btnAllocation, { isDisabled: isBtnFinalizeDisabled })}
                    text={isFinalized ? 'Refinalize' : 'Finalize'}
                    minimal={true}
                    outlined={!isFinalized}
                    intent={Intent.PRIMARY}
                    disabled={isBtnFinalizeDisabled}
                    onClick={this._candidateAllocationController.showFinalizeAllocationDialog}
                  />
                </NeedsPermission>
                }
                {/*{*/}
                {/*  ((isFinalized || isInStaffedStatus) && !isStaffLinked) &&*/}
                {/*  <NeedsPermission name={FlowPermissions.CreateStaff}>*/}
                {/*    <Button*/}
                {/*      className={cx(styles.btnAllocation)}*/}
                {/*      text={'Convert to staff'}*/}
                {/*      // minimal={isStaffLinked}*/}
                {/*      // outlined={isStaffLinked}*/}
                {/*      intent={Intent.WARNING}*/}
                {/*      // disabled={isBtnConvertToStaffDisabled}*/}
                {/*      onClick={():void => this._candidateController.showCopyToStaffDialog()}*/}
                {/*    />*/}
                {/*  </NeedsPermission>*/}
                {/*}*/}
              </div>
            }
          </div>
        ) : undefined}
      >
        {
          isStaffLinked &&
          <BlankState
            className={styles.staffMessage}
            title={'View actual positions on staff profile'}
            withBorder={true}
          />
        }
        {
          !isStaffLinked &&
          <>
            <AllocationPositions />
            <FinalizeAllocationDialog />
          </>
        }
      </InfoPanel>
    );
  }
}
