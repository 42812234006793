import { state } from '@flow/dependency-injection';

export interface ToastData
{
  toastCount:number;
  toastId?:string;
}

@state
export class ToastsState
{
  public errorToasts:Map<string, ToastData> = new Map();
}
