import { ConfigUtil } from '@flow/common/utils/ConfigUtil';

export class DebugUtil
{
  public static get isEnabled():boolean
  {
    return ConfigUtil.isDebugMode();
  }

  public static text(text:string):string
  {
    return ConfigUtil.isDebugMode() ? text : '';
  }

  public static value(value:any, doNotStringify?:boolean):string
  {
    return DebugUtil.text(`${doNotStringify ? value : JSON.stringify(value)}`);
  }

  public static id(id:string | number | null | undefined):string
  {
    return DebugUtil.text(`[ ${DebugUtil.value(id, true)} ] `);
  }
}
