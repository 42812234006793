import { componentWithRouter, di, diModule } from '@flow/dependency-injection';
import { StaffMemberLayout } from '@flow/modules/staffing/staff/components/member/StaffMemberLayout';
import { StaffMemberAllocationController } from '@flow/modules/staffing/staff/StaffMemberAllocationController';
import { StaffMemberAllocationState } from '@flow/modules/staffing/staff/StaffMemberAllocationState';
import { StaffMemberController } from '@flow/modules/staffing/staff/StaffMemberController';
import { StaffMemberState } from '@flow/modules/staffing/staff/StaffMemberState';
import type React from 'react';
import { Component } from 'react';
import { PageNotFound } from '../../../pages/404';

@diModule({
  providers: [
    StaffMemberState,
    StaffMemberController,

    StaffMemberAllocationState,
    StaffMemberAllocationController
  ]
})
@componentWithRouter
export class StaffMemberPage extends Component
{
  @di private _staffMemberState!:StaffMemberState;
  @di private _staffMemberController!:StaffMemberController;

  // ----------------------------------------------------

  public componentDidMount():void
  {
    this._staffMemberController.initStaffMember();
  }

  // ----------------------------------------------------

  public render():React.ReactNode | null
  {
    console.log('%c --- PAGE: Staff =', 'background:#080;color:#000;');

    const { pageNotFound, isStaffMemberLoaded } = this._staffMemberState;

    if( pageNotFound ) return <PageNotFound />;

    if( !isStaffMemberLoaded ) return null;

    return (
      <>
        <StaffMemberLayout />
      </>
    );
  }

  // ----------------------------------------------------
}
