import type { ApolloQueryResult } from '@apollo/client';
import type { FetchResult } from '@apollo/client/link/core';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import { MoveTo } from '@flow/common/models/MoveTo';
import { LocalStorageUtil } from '@flow/common/utils/LocalStorageUtil';
import {
  type AddPositionGroupMutation,
  type AddPositionGroupMutationVariables,
  type DeletePositionGroupMutation,
  type DeletePositionGroupMutationVariables,
  type EditPositionGroupNameMutation,
  type EditPositionGroupNameMutationVariables,
  type GetPositionGroupsSettingsDataQuery,
  type GetPositionGroupsSettingsDataQueryVariables,
  type SetPositionGroupIsActiveMutation,
  type SetPositionGroupIsActiveMutationVariables,
  type UpdatePositionGroupUiOrderMutation,
  type UpdatePositionGroupUiOrderMutationVariables
} from '@flow/data-access/lib/positionGroups.generated';
import {
  AddPositionGroupDocument,
  DeletePositionGroupDocument,
  EditPositionGroupNameDocument,
  GetPositionGroupsSettingsDataDocument,
  SetPositionGroupIsActiveDocument,
  UpdatePositionGroupUiOrderDocument
} from '@flow/data-access/lib/positionGroups.generated';
import { type Recruiting_Interview_Flow } from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import { type Recruiting_Position_Group_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import {
  SettingsPositionGroupsState,
  SettingsPositionGroupsState_LS_KEY,
  SettingsPositionGroupsStatus
} from '@flow/modules/settings/settingsPositionGroups/SettingsPositionGroupsState';
import { action, reaction, runInAction } from 'mobx';

type MovedElement = {
  id:number;
  uiOrder:number;
};

@controller
export class SettingsPositionGroupsController
{
  @di private _commonState!:CommonState;
  @di private _commonController!:CommonController;

  @di private _settingsPositionGroupsState!:SettingsPositionGroupsState;

// ----------------------------------------------------

  @action.bound
  public initFromLocalStorage():void
  {
    const lsState:Partial<SettingsPositionGroupsState> = LocalStorageUtil.getItem(SettingsPositionGroupsState_LS_KEY);

    if( lsState )
    {
      this._settingsPositionGroupsState.filterByPositionGroupStatus = lsState.filterByPositionGroupStatus || SettingsPositionGroupsStatus.ACTIVE;
    }

    const { disposers } = this._settingsPositionGroupsState;

    Array.isArray(disposers) && disposers.push(reaction(
      () =>
      {
        const {
          filterByPositionGroupStatus
        } = this._settingsPositionGroupsState;

        return {
          filterByPositionGroupStatus
        };
      },
      (lsValue:Partial<SettingsPositionGroupsState>) =>
      {
        console.log('%c SettingsPositionGroupsState: lsValue =', 'background:#0ff;color:#000;', lsValue);
        LocalStorageUtil.setItem(SettingsPositionGroupsState_LS_KEY, lsValue);
      }
    ));
  }

  // ----------------------------------------------------

  @action.bound
  public async initPositionGroupsData():Promise<void>
  {
    this._settingsPositionGroupsState.isLoaded = false;

    const result:ApolloQueryResult<GetPositionGroupsSettingsDataQuery> =
      await this._commonController.query<GetPositionGroupsSettingsDataQuery,
        GetPositionGroupsSettingsDataQueryVariables>({
        query: GetPositionGroupsSettingsDataDocument,
        variables: {}
      });

    console.log('%c initPositionGroupsData result =', 'background:#0f0;color:#000;', result);

    runInAction(() =>
    {
      const {
        recruiting_position_group,
        recruiting_interview_flow
      } = result.data;

      this._settingsPositionGroupsState.positionGroups = recruiting_position_group as Array<Recruiting_Position_Group_Ex>;
      this._settingsPositionGroupsState.flows = recruiting_interview_flow as Array<Recruiting_Interview_Flow>;

      this._settingsPositionGroupsState.isLoaded = true;
    });
  }

  // ----------------------------------------------------

  @action.bound
  public setFilterByPositionStatus(newPositionStatus:SettingsPositionGroupsStatus):void
  {
    this._settingsPositionGroupsState.filterByPositionGroupStatus = newPositionStatus;

    // TODO: set url query params
  }

  // ----------------------------------------------------

  @action.bound
  public setEditPositionGroupValue(newValue:string):void
  {
    this._settingsPositionGroupsState.editPositionGroupValue = newValue;
  }

  @action.bound
  public setEditPositionGroupInterviewFlowId(newValue:number):void
  {
    this._settingsPositionGroupsState.editPositionGroupInterviewFlowId = newValue;
  }

  @action.bound
  public showNewEditPositionGroupDialog(isNew:boolean, positionGroup?:Recruiting_Position_Group_Ex):void
  {
    this._settingsPositionGroupsState.isNewPositionGroupDialog = isNew;
    this._settingsPositionGroupsState.showEditPositionGroupDialog = true;

    if( isNew )
    {
      this._settingsPositionGroupsState.editPositionGroup = null;
      this._settingsPositionGroupsState.editPositionGroupValue = '';
      this._settingsPositionGroupsState.editPositionGroupInterviewFlowId = -1;
    }
    else
    {
      if( positionGroup )
      {
        this._settingsPositionGroupsState.editPositionGroup = positionGroup;
        this._settingsPositionGroupsState.editPositionGroupValue = positionGroup.name;
        this._settingsPositionGroupsState.editPositionGroupInterviewFlowId = positionGroup.interview_flow_id || -1;
      }
      else
      {
        this._settingsPositionGroupsState.showEditPositionGroupDialog = false;
      }
    }
  }

  @action.bound
  public hideNewEditPositionGroupDialog():void
  {
    this._settingsPositionGroupsState.isNewPositionGroupDialog = false;
    this._settingsPositionGroupsState.showEditPositionGroupDialog = false;
    this._settingsPositionGroupsState.editPositionGroup = null;
    this._settingsPositionGroupsState.editPositionGroupValue = '';
    this._settingsPositionGroupsState.editPositionGroupInterviewFlowId = -1;
  }

  @action.bound
  public createOrSavePositionGroupDialog():void
  {
    const { isNewPositionGroupDialog } = this._settingsPositionGroupsState;

    if( isNewPositionGroupDialog )
    {
      this.createNewPositionGroup();
    }
    else
    {
      this.savePositionGroupName();
    }
  }

  @action.bound
  public isPositionGroupNameValid():boolean
  {
    const { editPositionGroupValue, editPositionGroup } = this._settingsPositionGroupsState;

    const newName:string = editPositionGroupValue.trim().toLowerCase();
    const editedId:number | undefined = editPositionGroup?.id;

    return !this._settingsPositionGroupsState.positionGroups.some((positionGroup:Recruiting_Position_Group_Ex) =>
    {
      if( editedId === positionGroup.id ) return false;

      return newName === positionGroup.name.toLowerCase();
    });
  }

  // ----------------------------------------------------

  @action.bound
  public async savePositionGroupName():Promise<void>
  {
    const { editPositionGroup } = this._settingsPositionGroupsState;

    if( !editPositionGroup ) return; // TODO ???

    const { editPositionGroupInterviewFlowId, editPositionGroupValue } = this._settingsPositionGroupsState;

    const newValue = editPositionGroupValue.trim();

    console.log('%c TODO saveEditPositionGroup =', 'background:#aaf;color:#000;', newValue);

    if( !newValue ) return;

    if( newValue === editPositionGroup.name )
    {
      this.hideNewEditPositionGroupDialog();
      return;
    }

    const result:FetchResult<EditPositionGroupNameMutation> =
      await this._commonController.mutate<EditPositionGroupNameMutation,
        EditPositionGroupNameMutationVariables>({
        mutation: EditPositionGroupNameDocument,
        variables: {
          id: editPositionGroup.id,
          name: newValue,
          interview_flow_id: editPositionGroupInterviewFlowId
        }
      });

    console.log('%c savePositionGroupName result =', 'background:#0f0;color:#000;', result);
    const returning = result?.data?.update_recruiting_position_group_by_pk;

    if( returning )
    {
      runInAction(() =>
      {
        console.log('%c --- returning =', 'background:#080;color:#000;', returning);
        editPositionGroup.name = returning.name;
      });
    }

    this.hideNewEditPositionGroupDialog();
  }

  // ----------------------------------------------------

  @action.bound
  public async createNewPositionGroup():Promise<void>
  {
    const { editPositionGroupValue, editPositionGroupInterviewFlowId } = this._settingsPositionGroupsState;
    const newValue = editPositionGroupValue.trim();

    console.log('%c createNewPositionGroup =', 'background:#aaf;color:#000;', newValue);

    if( !newValue ) return;

    const uiOrder:number = this._settingsPositionGroupsState.activePositionGroups[0]?.ui_order || 0;

    const result:FetchResult<AddPositionGroupMutation> =
      await this._commonController.mutate<AddPositionGroupMutation,
        AddPositionGroupMutationVariables>({
        mutation: AddPositionGroupDocument,
        variables: {
          name: newValue,
          ui_order: uiOrder - 1,
          is_active: true,
          interview_flow_id: editPositionGroupInterviewFlowId
        }
      });

    console.log('%c createNewPositionGroup result =', 'background:#0f0;color:#000;', result);
    const returning = result?.data?.insert_recruiting_position_group_one;

    if( returning )
    {
      runInAction(() =>
      {
        console.log('%c --- returning =', 'background:#080;color:#000;', returning);
        const newPositionGroup:Partial<Recruiting_Position_Group_Ex> = returning as Partial<Recruiting_Position_Group_Ex>;

        this._settingsPositionGroupsState.positionGroups.push(newPositionGroup as Recruiting_Position_Group_Ex);
      });
    }
    this.hideNewEditPositionGroupDialog();
    this.setFilterByPositionStatus(SettingsPositionGroupsStatus.ACTIVE);
  }

  // ----------------------------------------------------

  @action.bound
  public async activatePositionGroup(positionGroup:Recruiting_Position_Group_Ex):Promise<void>
  {
    let uiOrder:number;
    if( positionGroup.is_active )
    {
      uiOrder = this._settingsPositionGroupsState.inactivePositionGroups[0]?.ui_order || 0;
    }
    else
    {
      uiOrder = this._settingsPositionGroupsState.activePositionGroups[0]?.ui_order || 0;
    }

    const result:FetchResult<SetPositionGroupIsActiveMutation> =
      await this._commonController.mutate<SetPositionGroupIsActiveMutation,
        SetPositionGroupIsActiveMutationVariables>({
        mutation: SetPositionGroupIsActiveDocument,
        variables: {
          id: positionGroup.id,
          is_active: !positionGroup.is_active,
          ui_order: uiOrder - 1
        }
      });

    console.log('%c activatePositionGroup result =', 'background:#0f0;color:#000;', result);
    const returning = result?.data?.update_recruiting_position_group_by_pk;

    if( returning )
    {
      runInAction(() =>
      {
        console.log('%c --- returning =', 'background:#080;color:#000;', returning);
        positionGroup.is_active = returning.is_active;
        positionGroup.ui_order = returning.ui_order;
      });
    }
  }

  // ----------------------------------------------------

  @action.bound
  public movePositionGroup(positionGroup:Recruiting_Position_Group_Ex, to:MoveTo):void
  {
    console.log('%c to =', 'background:#0f0;color:#000;', to);

    const { activePositionGroups: groups, activePositionGroupIndexById } = this._settingsPositionGroupsState;

    if( !groups || !groups.length ) return; // !!! ?

    const pgIndex:number = activePositionGroupIndexById(positionGroup.id);

    if( pgIndex === -1 ) return; // !!! ?

    const originalGroup:MovedElement = {
      id: positionGroup.id,
      uiOrder: 0
    };
    let secondGroup:MovedElement | null = null;

    console.log('%c positionGroup.ui_order =', 'background:#080;color:#000;', positionGroup.ui_order);

    switch( to )
    {
      case MoveTo.TOP:
        originalGroup.uiOrder = Number(groups[0].ui_order) - 1;
        console.log('%c FIRST ui_order =', 'background:#080;color:#000;', groups[0].ui_order);
        break;

      case MoveTo.BOTTOM:
        originalGroup.uiOrder = Number(groups[groups.length - 1].ui_order) + 1;
        console.log('%c LAST ui_order =', 'background:#080;color:#000;', groups[groups.length - 1].ui_order);
        break;

      case MoveTo.UP:
        originalGroup.uiOrder = Number(positionGroup.ui_order) - 1;
        if( groups[pgIndex - 1] )
        {
          secondGroup = {
            id: groups[pgIndex - 1].id,
            uiOrder: positionGroup.ui_order
          };
        }
        break;

      case MoveTo.DOWN:
        originalGroup.uiOrder = Number(positionGroup.ui_order) + 1;
        if( groups[pgIndex + 1] )
        {
          secondGroup = {
            id: groups[pgIndex + 1].id,
            uiOrder: positionGroup.ui_order
          };
        }
        break;
    }

    console.log('%c --- originalGroup =', 'background:#ff0;color:#000;', originalGroup);
    console.log('%c ---   secondGroup =', 'background:#ff0;color:#000;', secondGroup);

    this.updatePositionGroupUiOrder(originalGroup);
    this.updatePositionGroupUiOrder(secondGroup);

    return;
  }

  @action.bound
  public async updatePositionGroupUiOrder(movedPositionGroup:MovedElement | null):Promise<void>
  {
    if( !movedPositionGroup ) return;

    const { id, uiOrder } = movedPositionGroup;

    const result:FetchResult<UpdatePositionGroupUiOrderMutation> =
      await this._commonController.mutate<UpdatePositionGroupUiOrderMutation,
        UpdatePositionGroupUiOrderMutationVariables>({
        mutation: UpdatePositionGroupUiOrderDocument,
        variables: {
          id: id,
          ui_order: uiOrder
        }
      });

    console.log('%c updatePositionGroupUiOrder result =', 'background:#0f0;color:#000;', result);
    const returning = result?.data?.update_recruiting_position_group_by_pk;

    if( !returning ) // null
    {
      // TODO ???
      return;
    }

    runInAction(() =>
    {
      console.log('%c --- returning =', 'background:#080;color:#000;', returning);
      const { positionGroupById } = this._settingsPositionGroupsState;

      const positionGroup:Recruiting_Position_Group_Ex | undefined = positionGroupById(id);

      if( positionGroup )
      {
        positionGroup.ui_order = returning.ui_order;
      }
    });
  }

  // ----------------------------------------------------

  @action.bound
  public async deletePositionGroup(positionGroup:Recruiting_Position_Group_Ex):Promise<void>
  {
    const result:FetchResult<DeletePositionGroupMutation> =
      await this._commonController.mutate<DeletePositionGroupMutation,
        DeletePositionGroupMutationVariables>({
        mutation: DeletePositionGroupDocument,
        variables: {
          id: positionGroup.id,
          deleted_at: 'now()'
        }
      });

    console.log('%c deletePositionGroup result =', 'background:#0f0;color:#000;', result);
    const returning = result?.data?.update_recruiting_position_group_by_pk;

    if( !returning ) // null
    {
      // TODO ???
      return;
    }

    runInAction(() =>
    {
      console.log('%c --- returning =', 'background:#080;color:#000;', returning);
      const { positionGroupIndexById, positionGroups } = this._settingsPositionGroupsState;

      const pgIndex:number = positionGroupIndexById(positionGroup.id);

      if( pgIndex !== -1 )
      {
        positionGroups.splice(pgIndex, 1);
      }
    });
  }

  // ----------------------------------------------------
}
