import type { ApolloQueryResult, FetchResult } from '@apollo/client';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import type {
  GetPositionQuery,
  GetPositionQueryVariables,
  UpdatePositionAliasMutation,
  UpdatePositionAliasMutationVariables,
  UpdatePositionIsReadyMutation,
  UpdatePositionIsReadyMutationVariables
} from '@flow/data-access/lib/positions.generated';
import {
  UpdatePositionIsReadyDocument
} from '@flow/data-access/lib/positions.generated';
import { GetPositionDocument, UpdatePositionAliasDocument } from '@flow/data-access/lib/positions.generated';
import { controller, di } from '@flow/dependency-injection';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { PositionState } from '@flow/modules/recruiting/position/PositionState';
import { action, runInAction } from 'mobx';

@controller
export class PositionController
{
  @di private _commonState!:CommonState;
  @di private _commonController!:CommonController;
  @di private _positionState!:PositionState;

  // ----------------------------------------------------

  @action.bound
  public async initPosition(positionId:number):Promise<void>
  {
    this._positionState.pageNotFound = false;
    this._positionState.isPositionLoaded = false;

    const result:ApolloQueryResult<GetPositionQuery> =
      await this._commonController.query<GetPositionQuery,
        GetPositionQueryVariables>({
        query: GetPositionDocument,
        variables: {
          id: positionId
        }
      });

    console.log(`%c initPosition ${positionId} result =`, 'background:#0f0;color:#000;', result);

    if( !result.data.recruiting_position_by_pk )
    {
      runInAction(() => this._positionState.pageNotFound = true);
      return;
    }

    runInAction(() =>
    {
      const {
        recruiting_position_by_pk
      } = result.data;

      this._positionState.position = recruiting_position_by_pk as unknown as Recruiting_Position_Ex;

      this._positionState.isPositionLoaded = true;
    });
  }

  // ----------------------------------------------------

  @action.bound
  public setEditMode(isEditMode:boolean):void
  {
    this._positionState.isEditMode = isEditMode;
  }

  // ----------------------------------------------------

  @action.bound
  public toggleIsReady():void
  {
    const { position } = this._positionState;

    if( !position )
      return;

    position.is_ready = !position.is_ready;

    this.updatePositionIsReady();
  }

  // ----------------------------------------------------

  @action.bound
  public showRenamePositionDialog():void
  {
    this._positionState.showRenamePositionDialog = true;
    this._positionState.inputPositionNameValue = this._positionState.position?.alias || '';
  }

  @action.bound
  public hideRenamePositionDialog():void
  {
    this._positionState.showRenamePositionDialog = false;
  }

  @action.bound
  public setInputPositionNameValue(newValue:string):void
  {
    this._positionState.inputPositionNameValue = newValue;
  }

  @action.bound
  public async savePositionName():Promise<void>
  {
    const { inputPositionNameValue, position } = this._positionState;

    const newValue = inputPositionNameValue.trim();

    console.log('%c savePositionName =', 'background:#aaf;color:#000;', newValue);

    if( newValue === position?.alias || !position )
    {
      this.hideRenamePositionDialog();
      return;
    }

    const result:FetchResult<UpdatePositionAliasMutation> =
      await this._commonController.mutate<UpdatePositionAliasMutation,
        UpdatePositionAliasMutationVariables>({
        mutation: UpdatePositionAliasDocument,
        variables: {
          id: position.id,
          alias: newValue,
        }
      });

    console.log('%c savePositionName result =', 'background:#0f0;color:#000;', result);
    const returning = result?.data?.update_recruiting_position_by_pk;

    if( returning )
    {
      runInAction(() =>
      {
        if( !position ) return;

        position.alias = returning.alias;
      });
    }

    this.hideRenamePositionDialog();
  }

  // ----------------------------------------------------

  public async updatePositionIsReady():Promise<void>
  {
    const { position } = this._positionState;

    if( !position )
      return;

    await this._commonController.mutate<UpdatePositionIsReadyMutation,
      UpdatePositionIsReadyMutationVariables>({
      mutation: UpdatePositionIsReadyDocument,
      variables: {
        id: position.id,
        isReady: position.is_ready,
      }
    });
  }
}
