import { CommonController, StaffOrCandidateUrl } from '@flow/common/CommonController';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import type { DateStrOrNum } from '@flow/common/utils/DateUtil';
import { DateUtil } from '@flow/common/utils/DateUtil';
import type { Staffing_Customer_Team_Slot } from '@flow/data-access/lib/types/graphql.generated';
import { di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './TeamCardPositions.module.less';

const cx = classNames.bind(styles);

export interface IUnitedTeamLead
{
  index:number;
  length:number;
}

interface Props
{
  allSlots:Array<Staffing_Customer_Team_Slot>;
  staffedSlots:Array<Staffing_Customer_Team_Slot>;
  fullWidth?:boolean;
  unitedTeamLead?:IUnitedTeamLead;
}

export class TeamCardStaffedPositions extends React.Component<Props>
{
  @di private _commonController!:CommonController;

  // ---------------------------------------------------------

  private _renderDate(isLeaving:boolean, date:number | null):React.ReactNode | null
  {
    if( !date ) return null;

    return (
      <div className={styles.staffDate}>
        <Icon
          icon={isLeaving ? IconNames.LOG_OUT : IconNames.LOG_IN}
          size={12}
          className={cx(styles.dateIcon, isLeaving ? styles.orange : styles.blue)}
        />
        {DateUtil.formattedDate(date)}
      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderSlot(slot:Staffing_Customer_Team_Slot):React.ReactNode
  {
    // console.log('%c slot =', 'background:#0f0;color:#000;', toJS(slot));

    const hasStaff:boolean = !!slot.staff;
    const hasNextStaff:boolean = !!slot.next_staff;
    const hasNextCandidate:boolean = !!slot.candidate;

    const hasNext:boolean = hasNextStaff || hasNextCandidate;

    const hasSecondLine:boolean = hasStaff && hasNext;
    const firstLineIsCurrentStaff:boolean = hasStaff || hasSecondLine;

    // ------------------

    const startDate:number | null = DateUtil.getDate(slot.start_date as DateStrOrNum);
    const leavingDate:number | null = DateUtil.getDate(slot.leaving_date as DateStrOrNum);
    const nextStaffStartDate:number | null = DateUtil.getDate(slot.next_staff_start_date as DateStrOrNum);
    const nextCandidateStartDate:number | null = DateUtil.getDate(slot.next_candidate_start_date as DateStrOrNum);

    // const showDate:boolean = !!leavingDate || hasNext; // TODO: ??? (!hasStaff && hasNext);

    const nextDate:number | null = hasNextStaff ? nextStaffStartDate : hasNextCandidate ? nextCandidateStartDate : null;

    const firstLineDate:number | null = firstLineIsCurrentStaff
      ? (leavingDate ? leavingDate : hasSecondLine ? startDate : null)
      : nextDate;

    const firstLineDateIsLeaving:boolean = firstLineIsCurrentStaff && !!leavingDate;

    // ------------------

    const withBorder:boolean = hasNextStaff || hasNextCandidate || (hasStaff && !!leavingDate);
    const borderColor:string = (hasStaff && !!leavingDate) || hasNextStaff ? styles.borderOrange : styles.borderBlue;

    // ------------------

    const staffName:string = `${slot.staff?.user.first_name} ${slot.staff?.user.last_name}`;
    const nextStaffName:string = `${slot.next_staff?.user.first_name} ${slot.next_staff?.user.last_name}`;
    const nextCandidateName:string = `${slot.candidate?.first_name} ${slot.candidate?.last_name}`;

    const nextName:string = hasNextStaff ? nextStaffName : hasNextCandidate ? nextCandidateName : '???';
    const firstLineName:string = firstLineIsCurrentStaff ? staffName : nextName;

    // ------------------

    const nextUrl:StaffOrCandidateUrl = hasNextStaff
      ? this._commonController.getStaffMemberUrl(slot.next_staff?.id)
      : hasNextCandidate
        ? this._commonController.getCandidateUrl(slot.candidate?.id)
        : '???';

    const firstLineUrl:StaffOrCandidateUrl = firstLineIsCurrentStaff
      ? this._commonController.getStaffMemberUrl(slot.staff?.id)
      : nextUrl;

    // ------------------

    const nextAvatar:string | undefined | null = hasNextStaff
      ? slot.next_staff?.avatar_url || slot.next_staff?.user.avatar
      : hasNextCandidate ? slot.candidate?.avatar_url : '';

    const firstLineAvatar:string | undefined | null = firstLineIsCurrentStaff
      ? slot.staff?.avatar_url || slot.staff?.user.avatar
      : nextAvatar;

    // ------------------

    const { unitedTeamLead } = this.props;

    return (
      <div
        key={slot.id}
        className={cx(styles.staffWrapper)}
      >
        <div
          className={cx(styles.staff, borderColor, {
            withBorder: withBorder && !unitedTeamLead,
            unitedTeamLead
          })}
        >
          <a
            className={cx(styles.staffNameWrapper, { isLink: firstLineUrl })}
            href={firstLineUrl}
          >
            <Avatar
              className={styles.avatar}
              url={firstLineAvatar}
              size={20}
              isCandidate={!hasStaff && hasNextCandidate}
            />

            <div className={styles.staffName}>
              {firstLineName}
            </div>

          </a>

          {!unitedTeamLead && this._renderDate(firstLineDateIsLeaving, firstLineDate)}

          {
            hasSecondLine &&
            <>
              <a
                className={cx(styles.staffNameWrapper, { isLink: nextUrl })}
                href={nextUrl}
              >
                <Avatar
                  className={styles.avatar}
                  url={nextAvatar}
                  size={20}
                  isCandidate={hasNextCandidate}
                />

                <div className={styles.staffName}>
                  {nextName}
                </div>

              </a>

              {this._renderDate(false, nextDate)}
            </>
          }
        </div>
      </div>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { staffedSlots } = this.props;

    return (
      <>
        {staffedSlots.map(this._renderSlot)}
      </>
    );
  }

  // ---------------------------------------------------------
}
