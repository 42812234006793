import { MenuItem } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { ButtonSize } from '@flow/common/components/form/Button';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { TextInput } from '@flow/common/components/form/TextInput';
import { Dialog } from '@flow/common/components/page/Dialog';
import { DialogRow } from '@flow/common/components/page/DialogRow';
import { DialogSelectButton } from '@flow/common/components/page/DialogSelectButton';
import { Intent } from '@flow/common/components/types/Types';
import type { Recruiting_Interview_Flow } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { SettingsPositionGroupsController } from '@flow/modules/settings/settingsPositionGroups/SettingsPositionGroupsController';
import { SettingsPositionGroupsState } from '@flow/modules/settings/settingsPositionGroups/SettingsPositionGroupsState';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './NewEditPositionGroupDialog.module.less';

const cx = classNames.bind(styles);

@component
export class NewEditPositionGroupDialog extends React.Component
{
  @di private _settingsPositionGroupsState!:SettingsPositionGroupsState;
  @di private _settingsPositionGroupsController!:SettingsPositionGroupsController;

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { showEditPositionGroupDialog } = this._settingsPositionGroupsState;

    if( !showEditPositionGroupDialog ) return null;

    const {
      flows,
      isNewPositionGroupDialog: isNew,
      editPositionGroupValue, editPositionGroupInterviewFlowId
    } = this._settingsPositionGroupsState;

    const {
      hideNewEditPositionGroupDialog,
      setEditPositionGroupValue,
      setEditPositionGroupInterviewFlowId,
      createOrSavePositionGroupDialog,
      isPositionGroupNameValid
    } = this._settingsPositionGroupsController;

    const selectedFlow:Recruiting_Interview_Flow | undefined = flows.find((flow:Recruiting_Interview_Flow) =>
    {
      return flow.id === editPositionGroupInterviewFlowId;
    });

    const isNameValid:boolean = isPositionGroupNameValid();

    return (
      <Dialog
        title={`${isNew ? 'New' : 'Edit'} Position Group`}
        isOpen={showEditPositionGroupDialog}
        onClose={hideNewEditPositionGroupDialog}
        className={styles.positionGroupDialog}

        buttonsSize={ButtonSize.LARGE}

        primaryButtonText={isNew ? 'Create' : 'Save'}
        primaryButtonOnClick={createOrSavePositionGroupDialog}
        isPrimaryButtonDisabled={
          editPositionGroupInterviewFlowId <= 0 ||
          editPositionGroupValue.trim() === '' ||
          !isNameValid
        }

        secondaryButtonText={'Cancel'}
        secondaryButtonIntent={Intent.PRIMARY}
        isSecondaryButtonOutlined={true}
      >
        <DialogRow label={'Name'}>
          <TextInput
            value={editPositionGroupValue}
            onChange={setEditPositionGroupValue}
            autoFocus={true}
            large={true}
            rightElement={editPositionGroupValue.trim() === '' ? undefined :
              <Icon
                className={cx(styles.inputIcon, { isNotValid: !isNameValid })}
                icon={isNameValid ? IconNames.TICK : IconNames.BAN_CIRCLE}
              />
            }
          />
        </DialogRow>

        <DialogRow label={'Flow'}>
          <Select
            className={styles.positionGroupSelect}
            items={flows}
            itemRenderer={(
              flowItem:Recruiting_Interview_Flow,
              { handleClick, modifiers }:IItemRendererProps):JSX.Element | null =>
            {
              if( !modifiers.matchesPredicate )
              {
                return null;
              }

              const { editPositionGroupInterviewFlowId } = this._settingsPositionGroupsState;

              return (
                <MenuItem
                  key={flowItem.id}
                  className={styles.dialogMenuItem}
                  text={flowItem.name}
                  onClick={handleClick}
                  active={modifiers.active}
                  // selected={flowItem.id === editPositionGroupInterviewFlowId}
                />
              );
            }}
            onItemSelect={(newFlow):void => setEditPositionGroupInterviewFlowId(newFlow.id)}
            filterable={false}
            popoverProps={{
              minimal: true,
              usePortal: false
            }}
          >
            <DialogSelectButton
              className={cx({ unselected: !selectedFlow })}
              text={selectedFlow?.name || 'Select interview flow'}
            />
          </Select>
        </DialogRow>

      </Dialog>
    );
  }

  // ---------------------------------------------------------
}
