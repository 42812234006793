import { MenuItem } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { MultiSelect } from '@blueprintjs/select';
import type { Recruiting_Position_Group } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import * as React from 'react';
import { CandidatesController } from '../../../CandidatesController';
import { CandidatesState } from '../../../CandidatesState';

import styles from './SelectPositionGroups.module.less';

const cx = classNames.bind(styles);

interface Props
{
  positionGroupsIds:Array<number>;
  selectedPositionGroupsIds:Array<number>;
  onSelect:(positionGroupsIds:Array<number>) => void;
}

interface State
{
  query:string;
}

@component
export class SelectPositionGroups extends React.PureComponent<Props, State>
{
  @di private _candidatesState!:CandidatesState;
  @di private _candidatesController!:CandidatesController;

  // ----------------------------------------------------

  public constructor(props:Props)
  {
    super(props);

    this.state = {
      query: ''
    };
  }

  // ----------------------------------------------------

  @bind
  private _itemRenderer(positionGroupId:number | null, {
    handleClick,
    modifiers
  }:IItemRendererProps):JSX.Element | null
  {
    if( !positionGroupId || !modifiers.matchesPredicate ) return null;

    const positionGroup:Recruiting_Position_Group | undefined = this._candidatesState.positionGroupById(positionGroupId);

    if( !positionGroup ) return null;

    return (
      <MenuItem
        key={positionGroup.name}
        text={positionGroup.name}
        onClick={handleClick}
        selected={modifiers.active}
      />
    );
  }

  // ----------------------------------------------------

  // @bind
  // private _itemPredicate(query:string, positionGroupId:number | null):boolean
  // {
  //   if( !positionGroupId )
  //     return false;
  //
  //   const { selectedPositionGroupsIds } = this.props;
  //
  //   const item = this._candidatesState.positionGroupById(positionGroupId);
  //
  //   if( item?.interview_flow_id == null )
  //     return false;
  //
  //   if( selectedPositionGroupsIds.length == 0 )
  //     return true;
  //
  //   const alreadySelectedItem = this._candidatesState.positionGroupById(selectedPositionGroupsIds[0]);
  //
  //   return item?.interview_flow_id == alreadySelectedItem?.interview_flow_id;
  // }

  // ----------------------------------------------------

  @bind
  private _tagRenderer(positionGroupId:number | null):JSX.Element | null
  {
    if( !positionGroupId )
      return null;

    const positionGroup:Recruiting_Position_Group | undefined = this._candidatesState.positionGroupById(positionGroupId);

    if( !positionGroup ) return null;

    return (
      <span>
        {positionGroup.name}
      </span>
    );
  }

  // ----------------------------------------------------

  @bind
  private _onItemSelect(positionGroupId:number | null):void
  {
    if( !positionGroupId )
      return;

    const { onSelect, selectedPositionGroupsIds } = this.props;

    this.setState({ query: '' });

    onSelect([...selectedPositionGroupsIds, positionGroupId]);
  }

  // ----------------------------------------------------

  @bind
  private _onItemRemove(deletedPositionGroupId:number | null):void
  {
    if( !deletedPositionGroupId )
      return;

    const { onSelect, selectedPositionGroupsIds } = this.props;

    onSelect(selectedPositionGroupsIds.filter((id:number) => id !== deletedPositionGroupId));
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { positionGroupsIds, selectedPositionGroupsIds } = this.props;

    const BPMultiSelect = MultiSelect.ofType<number | null>();

    let items:Array<number | null> = positionGroupsIds.filter((id1:number) =>
    {
      return !selectedPositionGroupsIds.find((id2:number) => id1 === id2);
    });

    const filteredItems:Array<Recruiting_Position_Group | undefined> = items.map(id => id ? this._candidatesState.positionGroupById(id) : undefined)
      .filter(item => item !== undefined
        && item.name.toLowerCase().split(' ').some(token => token.startsWith(this.state.query.toLowerCase())));

    items = filteredItems.map(item => item ? item.id : null);

    return (
      <BPMultiSelect
        items={items}
        selectedItems={selectedPositionGroupsIds}
        className={styles.positionGroupSelect}

        tagRenderer={this._tagRenderer}
        itemRenderer={this._itemRenderer}
        onQueryChange={(query:string):void => this.setState({ query: query })}
        onItemSelect={this._onItemSelect}
        onRemove={this._onItemRemove}

        placeholder={''}
        fill={true}

        // itemPredicate={this._itemPredicate}
        query={this.state.query}

        popoverProps={{
          usePortal: false,
          minimal: true
        }}

        tagInputProps={{
          large: true,
          tagProps: { minimal: true, large: true }
        }}
      />
    );
  }

  // ----------------------------------------------------
}
