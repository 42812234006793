import type { ApolloQueryResult } from '@apollo/client';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { RouteName } from '@flow/common/models/routing/RouteName';
import { LocalStorageUtil } from '@flow/common/utils/LocalStorageUtil';
import type {
  CustomersTeamsNoNameQuery,
  CustomersTeamsNoNameQueryVariables,
  CustomersTeamsQuery,
  CustomersTeamsQueryVariables
} from '@flow/data-access/lib/customers.generated';
import { CustomersTeamsDocument, CustomersTeamsNoNameDocument } from '@flow/data-access/lib/customers.generated';
import { type Common_Customer } from '@flow/data-access/lib/types/graphql.generated';
import { Common_Customer_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import { action, reaction, runInAction } from 'mobx';
import { RoutesConfig } from '../../../pages/RoutesConfig';
import { CustomersState, CustomersState_LS_KEY } from './CustomersState';
import { CustomerState } from './CustomerState';
import type {
  Common_Customer_Ex,
  Staffing_Customer_Team_Ex
} from './models/CustomersTypes';

@controller
export class CustomersController
{
  @di private _commonState!:CommonState;
  @di private _commonController!:CommonController;
  @di private _customerState!:CustomerState;
  @di private _customersState!:CustomersState;
  @di private _roleController!:RoleController;

  // ----------------------------------------------------

  @action.bound
  public initFromLocalStorage():void
  {
    const lsState:Partial<CustomersState> = LocalStorageUtil.getItem(CustomersState_LS_KEY);

    if( lsState )
    {
      this._customersState.filterByCustomerStatus = lsState.filterByCustomerStatus || Common_Customer_Status_Enum.Current;
    }

    const { disposers } = this._customersState;

    Array.isArray(disposers) && disposers.push(reaction(
      () =>
      {
        const {
          filterByCustomerStatus
        } = this._customersState;

        return {
          filterByCustomerStatus
        };
      },
      (lsValue:Partial<CustomersState>) =>
      {
        console.log('%c CustomersState: lsValue =', 'background:#0ff;color:#000;', lsValue);
        LocalStorageUtil.setItem(CustomersState_LS_KEY, lsValue);
      }
    ));
  }

  // ----------------------------------------------------

  @action.bound
  public async initCustomers():Promise<void>
  {
    const result:ApolloQueryResult<CustomersTeamsQuery | CustomersTeamsNoNameQuery> =
      this._roleController.hasPermission(FlowPermissions.ViewCustomerName)
        ? await this._commonController.query<CustomersTeamsQuery,
          CustomersTeamsQueryVariables>({
          query: CustomersTeamsDocument,
          variables: {
            _eq: this._customersState.filterByCustomerStatus
          }
        })
        : await this._commonController.query<CustomersTeamsNoNameQuery,
          CustomersTeamsNoNameQueryVariables>({
          query: CustomersTeamsNoNameDocument,
          variables: {
            _eq: this._customersState.filterByCustomerStatus
          }
        });

    console.log('%c initCustomers result =', 'background:#0f0;color:#000;', result);

    runInAction(() =>
    {
      this._customersState.customers = result.data.common_customer as Array<Common_Customer_Ex>;
      this._customersState.internalCustomers = result.data.internal_customer as Array<Common_Customer_Ex>;
    });
  }

  // ----------------------------------------------------

  @action.bound
  public async setFilterByCustomerStatus(newCustomerStatus:Common_Customer_Status_Enum):Promise<void>
  {
    this._customersState.filterByCustomerStatus = newCustomerStatus;
    // TODO: set url query params
    return this.initCustomers();
  }

  // ----------------------------------------------------

  @bind
  public viewCustomer(customer:Common_Customer):void
  {
    const path:string | null = RoutesConfig.getRoutePath(
      RouteName.CUSTOMERS_CUSTOMER,
      {
        customerId: String(customer.id)
      }
    );

    this._customerState.customer = null;

    if( path )
      this._commonController.navigate(path);
  }

  // ----------------------------------------------------

  @bind
  public async onCustomerSave(/* customerId:number */):Promise<void>
  {
    return this.setFilterByCustomerStatus(this._customerState.customerStatus);
  }

  // ----------------------------------------------------

  public getCustomerById(customerId:number):Common_Customer | null
  {
    return this._customersState.customers.find(customer => customer.id == customerId)
      || this._customersState.internalCustomers.find(customer => customer.id == customerId)
      || null;
  }

  public getOrderedTeams(customerId:number):Array<Staffing_Customer_Team_Ex>
  {
    const customer = this.getCustomerById(customerId);

    if( !customer )
      return [];

    return (customer.customer_teams as Array<Staffing_Customer_Team_Ex>)
      .slice()
      .sort((a, b) => (a.ui_order || 0) - (b.ui_order || 0))
      .sort((a, b) => Number(b.is_active) - Number(a.is_active));
  }
}
