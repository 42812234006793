import { Spinner } from '@flow/common/components/form/Spinner';
import { FullscreenPage } from '@flow/common/components/page/FullscreenPage';
import { PageTitle } from '@flow/common/components/page/PageTitle';
import { RouteName } from '@flow/common/models/routing/RouteName';
import { PositionsUtil } from '@flow/common/utils/PositionsUtil';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di, diModule } from '@flow/dependency-injection';
import { PositionRenameDialog } from '@flow/modules/recruiting/position/components/PositionRenameDialog';
import { PositionSidebar } from '@flow/modules/recruiting/position/components/PositionSidebar';
import React from 'react';
import { PageNotFound } from '../../../pages/404';
import { RoutesConfig } from '../../../pages/RoutesConfig';
import { PositionController } from './PositionController';
import { PositionState } from './PositionState';

@diModule({
  providers: [
    PositionState,
    PositionController
  ]
})
@componentWithRouter
export class Position extends React.Component<IWithRouterProps>
{
  @di private _positionState!:PositionState;
  @di private _positionController!:PositionController;

  // ----------------------------------------------------

  public componentDidMount():void
  {
    const { params } = this.props;

    if( !params || !params.positionId )
      return;

    const positionId:number = parseFloat(String(params.positionId));

    this._positionController.initPosition(positionId);
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { position, isEditMode, pageNotFound, isPositionLoaded } = this._positionState;

    const positionName:string = PositionsUtil.getPositionName(position);

    const mode = isEditMode ? 'edit' : 'preview';

    // There are no offical docs on this!
    // https://webapps.stackexchange.com/questions/130654/all-google-docs-url-parameters-functions-commands
    const flags = 'rm=embedded';

    const isFromCustomerTeamsRoute = RoutesConfig.isCurrentRoute(RouteName.CUSTOMERS_CUSTOMER_TEAM_POSITION);
    const customerId = isFromCustomerTeamsRoute ? this.props.params?.customerId : undefined;

    const btnBackUrl:string | null = isFromCustomerTeamsRoute
      ? RoutesConfig.getRoutePath(RouteName.CUSTOMERS_CUSTOMER, { customerId })
      : RoutesConfig.getRoutePath(RouteName.RECRUITING_POSITIONS);

    const headerTitle:string = isEditMode ? 'Editing Description' : 'Position Description';

    return (
      <>
        <PageTitle title={positionName} />

        <FullscreenPage
          title={!isPositionLoaded || pageNotFound ? '' : headerTitle}
          btnBackTitle={`Back to ${isFromCustomerTeamsRoute ? 'Teams' : 'Positions'}`}
          btnBackUrl={btnBackUrl}
          sidebarElements={<PositionSidebar />}
          contentElements={
            <>
              {
                pageNotFound &&
                <PageNotFound />
              }
              {
                (!position && !pageNotFound) &&
                <Spinner withWave withWrapper />
              }
              {
                position &&
                <iframe
                  style={{ flex: 1 }}
                  src={`https://docs.google.com/document/d/${position ? position.google_doc_ref : ''}/${mode}?${flags}`}
                  frameBorder="0"
                />
              }
            </>
          }
        />

        <PositionRenameDialog />
      </>
    );
  }
}
