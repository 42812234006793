import type { Maybe } from '@flow/common/models/Types';
import type { Recruiting_Candidate } from '@flow/data-access/lib/types/graphql.generated';
import {
  Recruiting_Candidate_Status_Enum,
  Recruiting_Interview_Flow,
  Recruiting_Interview_Flow_Stage
} from '@flow/data-access/lib/types/graphql.generated';
import { state } from '@flow/dependency-injection';
import { computed, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

@state
export class ConvertToStaffState
{

  // ----------------------------------------------------

  @observable public candidate:Maybe<Recruiting_Candidate> = null;
  @observable public interviewFlows:Array<Recruiting_Interview_Flow> = [];

  @observable public isShowConvertToStaffDialog:boolean = false;

  @observable public onClose?:() => void = undefined;
  @observable public onConfirm?:() => void = undefined;

  // ----------------------------------------------------

  @computed
  public get candidateFlow():Maybe<Recruiting_Interview_Flow>
  {
    if(
      !this.candidate ||
      !this.candidate.interview_flow_stage_id ||
      this.candidate?.status !== Recruiting_Candidate_Status_Enum.Active
    ) return;

    return this.getFlowByStageId(this.candidate.interview_flow_stage_id);
  }

  // ----------------------------------------------------

  public getFlowByStageId:(stageId:number) => Maybe<Recruiting_Interview_Flow> =
    computedFn((stageId:number):Maybe<Recruiting_Interview_Flow> =>
    {
      return this.interviewFlows?.find((flow:Recruiting_Interview_Flow) =>
      {
        return flow.stages.find((stage:Recruiting_Interview_Flow_Stage) => stage.id === stageId);
      });
    });

  // ----------------------------------------------------
}
