import { CustomerAndTeam } from '@flow/common/components/elements/CustomerAndTeam';
import { IPositionsCount, PositionsUtil } from '@flow/common/utils/PositionsUtil';
import type {
  Common_Customer,
  Recruiting_Position_Group,
  Recruiting_Position_Group_Priority,
  Staffing_Customer_Team
} from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { PrioritiesController } from '@flow/modules/recruiting/priorities/PrioritiesController';
import { PrioritiesState } from '@flow/modules/recruiting/priorities/PrioritiesState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './PrioritiesListPositions.module.less';

const cx = classNames.bind(styles);

interface Props
{
  priority:Recruiting_Position_Group_Priority;

  mobileByPriorities?:boolean;
  mobileByUsers?:boolean;
}

@component
export class PrioritiesListPositions extends React.Component<Props>
{
  @di private _prioritiesState!:PrioritiesState;
  @di private _prioritiesController!:PrioritiesController;

  // ---------------------------------------------------------

  @bind
  private _renderPosition(positionWithIdOnly:Recruiting_Position_Ex):React.ReactNode | null
  {
    const { customerById, customerTeamById, positionById } = this._prioritiesState;

    const position:Recruiting_Position_Ex | undefined = positionById(positionWithIdOnly.id);

    if( !position ) return null;

    // const quantityCount:number = this._prioritiesController.getQuantityCountOfPositions([position]);
    const positionsCount:IPositionsCount = this._prioritiesController.positionsCount([position]);

    if( !positionsCount.open ) return null;

    const customerTeam:Staffing_Customer_Team | undefined = customerTeamById(Number(position?.customer_team_id));
    const customer:Common_Customer | undefined = customerById(Number(customerTeam?.customer_id));

    // const error:boolean = quantityCount !== positionsCount.total - positionsCount.staffed;

    return (
      <div
        key={position.id}
        className={cx(styles.positionWrapper)}
        // className={cx(styles.positionWrapper, { error })}
      >
        <div className={styles.names}>
          <CustomerAndTeam
            className={styles.customerName}
            items={[
              customer?.alias || '',
              customerTeam?.name || ''
            ]}
          />
          <div className={styles.positionName}>
            {/*{DebugUtil.id(position.id)}*/}
            {PositionsUtil.getPositionName(position)}
          </div>
          {
            // error &&
            // <div className={cx(styles.oldCount, { error })}>
            //   open slots = {positionsCount.open}
            //   <br />
            //   with leaving = {quantityCount}
            // </div>
          }
        </div>

        <div className={styles.slots}>
          {positionsCount.open}
        </div>

        {/*<PositionsCount*/}
        {/*  className={styles.slots}*/}
        {/*  count={positionsCount.staffed}*/}
        {/*  totalCount={positionsCount.total}*/}
        {/*  // hideCount={true}*/}
        {/*  // hideTotalCount={true}*/}
        {/*/>*/}

      </div>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { positionGroupById } = this._prioritiesState;
    const { priority, mobileByPriorities, mobileByUsers } = this.props;

    const positionGroup:Recruiting_Position_Group | undefined = positionGroupById(Number(priority.position_group_id));
    const hasPositions:boolean = !!(positionGroup?.positions?.length);

    const positionNodes:Array<React.ReactNode> = (positionGroup?.positions as Array<Recruiting_Position_Ex>)
      .map(this._renderPosition)
      .filter((node) => !!node);

    return (
      <div className={cx(styles.positionsList, { mobileByPriorities, mobileByUsers })}>
        {
          (!hasPositions || positionNodes.length === 0) &&
          <div className={styles.empty}>
            No positions
          </div>
        }
        {positionNodes}
      </div>
    );
  }

  // ---------------------------------------------------------
}
