import { CommonController } from '@flow/common/CommonController';
import { PageUtil } from '@flow/common/utils/PageUtil';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di, diModule } from '@flow/dependency-injection';
import { ConvertToStaffController } from '@flow/modules/recruiting/common/convertToStaff/ConvertToStaffController';
import { ConvertToStaffState } from '@flow/modules/recruiting/common/convertToStaff/ConvertToStaffState';
import { InterviewFlowsFiltersController } from '@flow/modules/recruiting/status/InterviewFlowsFiltersController';
import { InterviewFlowsFiltersState } from '@flow/modules/recruiting/status/InterviewFlowsFiltersState';
import type { ReactNode } from 'react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ScheduleController } from '../schedule/ScheduleController';
import { InterviewFlowsController } from './InterviewFlowsController';
import { InterviewFlowsState } from './InterviewFlowsState';

@diModule({
  providers: [
    InterviewFlowsState,
    InterviewFlowsController,
    InterviewFlowsFiltersState,
    InterviewFlowsFiltersController,
    ConvertToStaffState,
    ConvertToStaffController,
    ScheduleController
  ]
})
@componentWithRouter
export class InterviewFlows extends React.Component<IWithRouterProps>
{
  @di private _commonController!:CommonController;
  @di private _interviewFlowsState!:InterviewFlowsState;
  @di private _interviewFlowsController!:InterviewFlowsController;

  // ----------------------------------------------------

  private _checkSelectedFlow():void
  {
    const { params } = this.props;

    if( (!params || !params.interviewFlowId) && this._interviewFlowsState?.interviewFlows?.length )
    {
      this._interviewFlowsController.selectInterviewFlow(
        this._interviewFlowsState.selectedInterviewFlowId
        || this._interviewFlowsState?.interviewFlows[0].id
      );
    }
  }

  // ----------------------------------------------------

  public async componentDidMount():Promise<void>
  {
    console.log('%c InterviewFlows componentDidMount =', 'background:#00f;color:#ff0;');

    this._interviewFlowsController.initFromLocalStorage();

    await this._interviewFlowsController.initInterviewFlows();

    this._checkSelectedFlow();
  }

  // ----------------------------------------------------

  public componentDidUpdate():void
  {
    console.log('%c InterviewFlows componentDidUpdate =', 'background:#00f;color:#ff0;');
    this._checkSelectedFlow();
  }

  // ----------------------------------------------------

  public componentWillUnmount():void
  {
    PageUtil.disposeReactions(this._interviewFlowsState.disposers);
  }

  // ----------------------------------------------------

  public render():ReactNode
  {
    return (
      <Outlet />
    );
  }

  // ----------------------------------------------------
}
