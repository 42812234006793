import { Spinner } from '@flow/common/components/form/Spinner';
import { component, di } from '@flow/dependency-injection';
import {
  type Common_Customer_Ex,
  type Staffing_Customer_Team_Ex
} from '@flow/modules/customers/teams/models/CustomersTypes';
import { PositionsListTeam } from '@flow/modules/recruiting/positions/components/PositionsListTeam';
import bind from 'bind-decorator';
// import classNames from 'classnames/bind';
import React from 'react';
import { PositionsController } from '../PositionsController';
import { PositionsState } from '../PositionsState';
import styles from './PositionsListCustomers.module.less';

// const cx = classNames.bind(styles);

@component
export class PositionsListCustomers extends React.Component
{
  @di private _positionsState!:PositionsState;
  @di private _positionsController!:PositionsController;

  // ---------------------------------------------------------

  @bind
  private _renderCustomers(customer:Common_Customer_Ex):React.ReactNode
  {
    return customer.customer_teams
      .map((team:Staffing_Customer_Team_Ex, index:number, teams:Array<Staffing_Customer_Team_Ex>) =>
      {
        return (
          <PositionsListTeam
            key={team.id}
            customer={customer}
            team={team}
            index={index}
            teams={teams}
          />
        );
      });
  }

  // ---------------------------------------------------------

  public render():React.ReactNode | Array<React.ReactNode> | null
  {
    const { isPositionsLoaded, customers } = this._positionsState;

    if( !isPositionsLoaded )
    {
      return (
        <Spinner
          // size={20}
          noIcon
          withWave
          withWrapper
          wrapperClassName={styles.spinnerWrapper}
        />
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return  customers.map(this._renderCustomers);
  }

  // ---------------------------------------------------------
}
