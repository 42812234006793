import { ButtonSize } from '../form/Button';
import { Intent } from '../types/Types';
import type { DialogProps } from './Dialog';
import { Dialog } from './Dialog'
import styles from './Dialog.module.less';

interface ConfirmationDialogProps extends DialogProps
{
  confirmButtonText:string;
  onConfirm:() => void;
  children?:React.ReactNode;
  isLoading?:boolean;
  titleDivider?:boolean;
}

export const ConfirmationDialog = (props:ConfirmationDialogProps):JSX.Element =>
{
  const { isLoading, titleDivider = true } = props;

  return (
    <Dialog
      canEscapeKeyClose
      canOutsideClickClose
      buttonsSize={ButtonSize.LARGE}
      titleDivider={titleDivider}
      className={styles.confirmDialog}

      primaryButtonText={props.confirmButtonText}
      primaryButtonOnClick={props.onConfirm}
      primaryButtonIntent={Intent.DANGER}
      isPrimaryButtonDisabled={isLoading}

      secondaryButtonText={isLoading ? '' : 'Cancel'}
      isSecondaryButtonOutlined
      secondaryButtonIntent={Intent.PRIMARY}

      {...props}
    />
  );
}
