import { Drawer } from '@flow/common/components/page/Drawer';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import type React from 'react';
import { Component } from 'react';
import { CandidatesController } from '../../../CandidatesController';
import { CandidatesState } from '../../../CandidatesState';
import { CandidateFormContent } from './CandidateFormContent';

import { CandidateFormToolbar } from './CandidateFormToolbar';

@component
export class CandidateForm extends Component<IWithRouterProps>
{
  @di private _candidatesState!:CandidatesState;
  @di private _candidatesController!:CandidatesController;

  @bind
  private _onHideDrawer():void
  {
    this._candidatesController.hideCandidateForm();
  }

  public render():React.ReactNode
  {
    const { isFormModeNone } = this._candidatesState;

    const showForm:boolean = !isFormModeNone;

    return (
      <Drawer
        isOpen={showForm}
        onClose={this._onHideDrawer}
      >

        <CandidateFormToolbar />
        <CandidateFormContent />

      </Drawer>
    );
  }
}
