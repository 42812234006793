import type { Common_User } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { PositionGroupSelector } from '@flow/modules/recruiting/priorities/components/common/PositionGroupSelector';
import { PrioritiesListUser } from '@flow/modules/recruiting/priorities/components/common/PrioritiesListUser';
import { PrioritiesListByUsersCell } from '@flow/modules/recruiting/priorities/components/mobile/PrioritiesListByUsersCell';
import { PrioritiesController } from '@flow/modules/recruiting/priorities/PrioritiesController';
import { PRIORITIES_NUM_OF_COLUMNS, PrioritiesState } from '@flow/modules/recruiting/priorities/PrioritiesState';
import classNames from 'classnames/bind';
import React from 'react';
import type {
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  // DroppableStateSnapshot
} from 'react-beautiful-dnd';
import {
  DragDropContext,
  Draggable,
  Droppable
} from 'react-beautiful-dnd';

import styles from './PrioritiesListBy.module.less';

const cx = classNames.bind(styles);

@component
export class PrioritiesListByUsers extends React.Component
{
  @di private _prioritiesState!:PrioritiesState;
  @di private _prioritiesController!:PrioritiesController;

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { users, isEditMode, isViewMode, draggableUserId } = this._prioritiesState;

    return (
      <div className={cx(styles.wrapper)}>
        {users.map((user:Common_User) =>
        {
          return (
            <DragDropContext
              key={user.id}
              onDragStart={this._prioritiesController.onDragStart}
              onDragUpdate={this._prioritiesController.onDragUpdate}
              onDragEnd={this._prioritiesController.onDragEnd}
            >
              <Droppable
                direction={'vertical'}
                droppableId={`${user.id}`}
                isDropDisabled={isViewMode}
              >
                {(provided:DroppableProvided /*, snapshot:DroppableStateSnapshot */):React.ReactElement => (
                  <div
                    className={cx(styles.columnPriority)}

                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className={cx(styles.priorityHeader, { isEditMode })}>
                      <PrioritiesListUser
                        className={styles.userInHeader}
                        user={user}
                        isEditMode={isEditMode}
                      />
                    </div>
                    {
                      // TODO:
                      // user.id === draggableUserId &&
                      // this._renderDropPlaceholders(user.position_group_priorities, draggableIndexOver)
                    }
                    {PRIORITIES_NUM_OF_COLUMNS.map((column:number, index:number) =>
                    {
                      const isDragDisabled:boolean = isViewMode;

                      if( isEditMode )
                      {
                        return (
                          <Draggable
                            key={index}
                            draggableId={`${index}`}
                            index={index}
                            isDragDisabled={isDragDisabled}
                          >
                            {(provided:DraggableProvided, snapshot:DraggableStateSnapshot):React.ReactElement => (
                              <PrioritiesListByUsersCell
                                user={user}
                                priorityIndex={column - 1}
                                isEditMode={isEditMode}
                                provided={provided}
                                snapshot={snapshot}
                              />
                            )}
                          </Draggable>
                        );
                      }

                      return (
                        <PrioritiesListByUsersCell
                          key={index}
                          user={user}
                          priorityIndex={column - 1}
                          isEditMode={isEditMode}
                        />
                      );

                    })}
                    {
                      (isEditMode && user.position_group_priorities.length < 5 && draggableUserId !== user.id) &&
                      <div className={styles.priorityCellWrapper}>
                        <div className={cx(styles.priorityCellView, { isEditMode })}>
                          <PositionGroupSelector
                            isEditMode={isEditMode}
                            isAddButton={true}
                            user={user}
                          />
                        </div>
                      </div>
                    }
                    {
                      (isEditMode && draggableUserId === user.id) &&
                      <div className={cx(styles.priorityCellWrapper, styles.empty)}>
                        <div className={cx(styles.priorityCellView, { isEditMode })}>
                        </div>
                      </div>
                    }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          );
        })}
      </div>
    );
  }

  // ---------------------------------------------------------
}
