import type { IReactionDisposer } from 'mobx';
import type { Subscription } from 'zen-observable-ts';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type State = any;

export class BaseState
{
  // mobx reactions
  public disposers:Array<IReactionDisposer> = [];
  // graphql subscriptions
  public subscriptions:Array<Subscription> = [];

  public destroy():void
  {
    this.subscriptions.forEach(subscription => subscription && !subscription.closed && subscription.unsubscribe())
    this.disposers.forEach(disposer => disposer && disposer())
  }
}

export interface StateConstructor extends Function
{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new(...args:Array<any>):State;
}

export function state(stateConstructor:StateConstructor):StateConstructor
{
  if( stateConstructor && stateConstructor.prototype.constructor )
  {
    return stateConstructor;
  }
  else
    throw new Error('@state decorator should be used on class only');
}
