import { Position } from '@blueprintjs/core';
import { Classes, Popover2 } from '@blueprintjs/popover2';
import type { Common_User, Recruiting_Position_Group_Priority } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { PositionGroupSelector } from '@flow/modules/recruiting/priorities/components/common/PositionGroupSelector';
import classNames from 'classnames/bind';
import React from 'react';
import type { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { PrioritiesController } from '../../PrioritiesController';
import { PrioritiesState } from '../../PrioritiesState';
import { PrioritiesListPositions } from '../common/PrioritiesListPositions';

import styles from './PrioritiesListCell.module.less';

const cx = classNames.bind(styles);

interface Props
{
  user:Common_User;
  priorityIndex:number;

  provided?:DraggableProvided;
  snapshot?:DraggableStateSnapshot;
}

@component
export class PrioritiesListCell extends React.Component<Props>
{
  @di private _prioritiesState!:PrioritiesState;
  @di private _prioritiesController!:PrioritiesController;

  // ---------------------------------------------------------

  public render():React.ReactNode | null
  {
    const { user, priorityIndex, provided, snapshot } = this.props;
    const { isEditMode, draggableUserId } = this._prioritiesState;

    const lastPriorityIndex:number = user.position_group_priorities.length - 1;

    // render button (+)
    if( isEditMode && priorityIndex === lastPriorityIndex + 1 )
    {
      if( user.id === draggableUserId ) return null;

      return (
        <PositionGroupSelector
          isEditMode={isEditMode}
          isAddButton={true}
          user={user}
        />
      );
    }

    // ---------------------------

    const priority:Recruiting_Position_Group_Priority | undefined =
      (priorityIndex <= lastPriorityIndex && user.position_group_priorities[priorityIndex]) || undefined;

    if( !priority ) return null;

    // ---------------------------

    const quantityCount:number = this._prioritiesController.getQuantityCountOfPriority(priority);

    if( isEditMode )
    {
      return (
        <PositionGroupSelector
          isEditMode={isEditMode}
          priority={priority}
          count={quantityCount}
          provided={provided}
          snapshot={snapshot}
        />
      );
    }

    // isViewMode
    return (
      <Popover2
        className={styles.priorityCellViewPopoverPlace}
        popoverClassName={cx(Classes.POPOVER2_DISMISS, styles.priorityCellViewPopoverWrapper)}
        content={<PrioritiesListPositions priority={priority} />}
        position={Position.BOTTOM_LEFT}
        disabled={quantityCount === 0}
      >
        <PositionGroupSelector
          priority={priority}
          count={quantityCount}
        />
      </Popover2>
    );
  }

  // ---------------------------------------------------------
}
