import type { Common_Scheduled_Event, Common_User } from '@flow/data-access/lib/types/graphql.generated';
import { state } from '@flow/dependency-injection';
import { observable } from 'mobx';

export interface FeedbackFormUser
{
  user:Partial<Common_User>;
}

@state
export class FeedbackFormState
{
  // ----------------------------------------------------

  @observable public isFeedbackFormLoaded:boolean = false;

  @observable public btnBackUrl:string | null = null;
  @observable public isCompleteFeedbackLoading:boolean = false;

  // ----------------------------------------------------

  @observable public feedbackFormEvent:Partial<Common_Scheduled_Event> | null = null;

  // ----------------------------------------------------
}
