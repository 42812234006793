import type { TabId } from '@blueprintjs/core';
import { Tab, Tabs } from '@blueprintjs/core';

import styles from '@flow/common/styles/TabsPanel.module.less';
import { component, di } from '@flow/dependency-injection';
import classNames from 'classnames/bind';
import { runInAction } from 'mobx';
import React from 'react';
import { CandidateState, CandidateTabName } from '../../CandidateState';
import { CandidatePanelAttachments } from './CandidatePanelAttachments';
import { CandidatePanelInfo } from './CandidatePanelInfo';

const cx = classNames.bind(styles);

@component
export class CandidateRightTabs extends React.Component
{
  @di private _candidateState!:CandidateState;

  public render():React.ReactNode
  {
    const { candidate } = this._candidateState;

    const attachmentCount = candidate
      ? candidate.candidate_attachments.filter(item => !!item.attachment).length
      : 0;

    return (
      <Tabs
        className={cx(styles.tabs, styles.compact)}
        animate={false}
        selectedTabId={this._candidateState.rightTabId}
        defaultSelectedTabId={CandidateTabName.INFO}
        onChange={(rightTabId:TabId):void =>
        {
          runInAction(() => this._candidateState.rightTabId = rightTabId);
        }}
      >

        <Tab
          className={cx({ activeTab: this._candidateState.rightTabId == CandidateTabName.INFO })}
          panelClassName={styles.tabPanel}
          id={CandidateTabName.INFO}
          title={'Info'}
          panel={<CandidatePanelInfo />}
        />

        <Tab
          className={cx({ activeTab: this._candidateState.rightTabId == CandidateTabName.ATTACHMENTS })}
          panelClassName={styles.tabPanel}
          id={CandidateTabName.ATTACHMENTS}
          title={(
            <div className={styles.tabTitle}>
              Attachments
              <span className={styles.titleBadge}>{Number(attachmentCount)}</span>
            </div>
          )}

          panel={<CandidatePanelAttachments />}
        />

      </Tabs>
    );
  }
}
