export enum HasuraTables
{
  CommonUser = 'common.user',
  CommonCustomer = 'common.customer',
  CommonAttachment = 'common.attachment',
  CommonNote = 'common.note',
  CommonScheduledEvent = 'common.scheduled_event',

  RecruitingCandidate = 'recruiting.candidate',
  RecruitingCandidatePositionGroup = 'recruiting.candidate_position_group',
  RecruitingCandidateAllocation = 'recruiting.candidate_allocation',
  RecruitingAllocationPosition = 'recruiting.allocation_position',
  RecruitingCandidateNote = 'recruiting.candidate_note',
  RecruitingCandidateScheduledEvent = 'recruiting.candidate_scheduled_event',
  RecruitingInterviewFlowStage = 'recruiting.interview_flow_stage',
  RecruitingPosition = 'recruiting.position',
  RecruitingPositionGroup = 'recruiting.position_group',
  RecruitingPositionGroupPriority = 'recruiting.position_group_priority',

  StaffingStaff = 'staffing.staff',
  StaffingCustomerTeam = 'staffing.customer_team',
  StaffingCustomerTeamSlot = 'staffing.customer_team_slot',
}

export enum HasuraTableColumns
{
  ID = 'id',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at',
  DeletedAt = 'deleted_at',

  RecruitingCandidate_AssignedToUserId = 'assigned_user_id',
  RecruitingCandidate_FinalizedAllocationId = 'finalized_allocation_id',
  RecruitingCandidate_InterviewFlowStageId = 'interview_flow_stage_id',
  RecruitingCandidate_Status = 'status',
  RecruitingCandidate_StatusReasonId = 'status_reason_id',
  RecruitingCandidate_StatusReasonNotes = 'status_reason_notes',
}

export const HasuraTableNames:Map<string, string> = new Map(
[
  [HasuraTables.RecruitingCandidate, 'Candidate'],
]);
