import { ButtonOrderBy } from '@flow/common/components/elements/ButtonOrderBy';
import { NeedsPermission } from '@flow/common/components/NeedsPermission';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { action } from 'mobx';
import type React from 'react';
import { Component } from 'react';
import type { ICandidatesColumn } from '../../CandidatesColumnsState';
import { CandidatesColumnType } from '../../CandidatesColumnsState';
import { CandidatesController } from '../../CandidatesController';
import { CandidatesState } from '../../CandidatesState';

import styles from './CandidatesList.module.less';
import { CandidatesColumnsSelector } from './filters/CandidatesColumnsSelector';

const cx = classNames.bind(styles);

@component
export class CandidatesListHeader extends Component
{
  @di private _candidatesState!:CandidatesState;
  @di private _candidatesController!:CandidatesController;

  // ---------------------------------------------------------

  @action.bound
  public _onClickOrderBy(columnType:CandidatesColumnType)
  {
    return (/* e:React.MouseEvent<HTMLElement> */):void =>
    {
      this._candidatesController.orderByNextState(columnType);
    };
  }

  // ---------------------------------------------------------

  @bind
  private _renderColumn(columnType:CandidatesColumnType, className:string):React.ReactNode | null
  {
    const { columnByType } = this._candidatesState;

    const column:ICandidatesColumn = columnByType(columnType);

    if( !column.alwaysVisible && !column.isVisible ) return null;

    return (
      <div className={cx(styles.col, className)}>
        <ButtonOrderBy
          className={cx({ notOrderableTitle: !column.isOrderable })}
          text={column.name}
          isOrderable={column.isOrderable}
          orderBy={column.orderBy}
          onClick={this._onClickOrderBy(columnType)}
        />
      </div>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    return (
      <div className={styles.header}>

        {this._renderColumn(CandidatesColumnType.NAME, String(styles.colName))}
        {this._renderColumn(CandidatesColumnType.DATE_CREATED, String(styles.colDateCreated))}
        <NeedsPermission name={FlowPermissions.ViewPositionGroups}>
          {this._renderColumn(CandidatesColumnType.POSITION_GROUPS, String(styles.colPositionGroups))}
        </NeedsPermission>
        {this._renderColumn(CandidatesColumnType.STATUS, String(styles.colStatus))}
        {this._renderColumn(CandidatesColumnType.FLOW_STAGE, String(styles.colStatus))}
        {this._renderColumn(CandidatesColumnType.ASSIGNED_TO, String(styles.colAssignedTo))}
        {this._renderColumn(CandidatesColumnType.CREATED_BY, String(styles.colCreatedBy))}
        {this._renderColumn(CandidatesColumnType.SITE_SUBSITE, String(styles.colSiteSubsite))}

        <div className={cx(styles.col, styles.colMore)}>
          <CandidatesColumnsSelector />
        </div>
      </div>
    );
  }

  // ---------------------------------------------------------
}
