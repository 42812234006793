import classNames from 'classnames/bind';
import React from 'react';

import styles from './TextWithLinks.module.less';

const cx = classNames.bind(styles);

// TODO: remove
const __DEBUG:boolean = false;

interface Props
{
  className?:string;
  text?:string;
}

export class TextWithLinks extends React.PureComponent<Props>
{
  // ----------------------------------------------------

  private _getTextWithLinks():Array<React.ReactNode>
  {
    const { text } = this.props;

    if( !text || text.trim() === '' ) return [];

    const regexp:RegExp = /(?:(https?:\/\/)|(?:www\.))((?:[\w\d-]+[.:])(?:[\w.-])+)([\w[\]&=,?#%./+-]*)/gm;

    const items:Array<React.ReactNode> = [];

    let lastIndex:number = 0;
    let match:RegExpExecArray | null;

    __DEBUG && console.log('%c ----------------------------------------------- =', 'background:#f00;color:#000;');

    while( (match = regexp.exec(text as string)) !== null )
    {
      __DEBUG && console.log('%c match =', 'background:#0f0;color:#000;', match);

      const link:string = match[0];

      link && __DEBUG && console.log('%c link =', 'background:#080;color:#000;', link);

      // This is necessary to avoid infinite loops with zero-width matches
      if( match.index === regexp.lastIndex ) regexp.lastIndex++;

      const length:number = link.length;
      const before:string = text.slice(lastIndex, regexp.lastIndex - length);

      if( before.length > 0 ) items.push(before);

      lastIndex = regexp.lastIndex;

      // match[1] = something like that -> ???????://
      const href:string = `${!match[1] ? '//' : ''}${link}`;

      items.push(
        <a
          key={`item_${lastIndex}`}
          className={styles.link}
          href={href}
          target={'_blank'}
          rel={'noreferrer'}
        >
          {link}
        </a>
      );
    }

    const rest:string = text.slice(lastIndex);
    rest && __DEBUG && console.log('%c rest =', 'background:#f0f;color:#000;', rest);

    if( rest ) items.push(rest);

    __DEBUG && console.log('%c items =', 'background:#f0f;color:#000;', items);

    return items;
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { className } = this.props;

    return (
      <span className={className}>
        {this._getTextWithLinks()}
      </span>
    );
  }

  // ----------------------------------------------------
}
