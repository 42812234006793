import { FilterCheckboxesPopover } from '@flow/common/components/filters/checkboxes/FilterCheckboxesPopover';
import type { IFilter } from '@flow/common/components/filters/Filter';
import { FilterType } from '@flow/common/components/filters/Filter';

// ---------------------------------------------------------

export type IFilterCheckboxesItemValueType = number | string;

export interface IFilterCheckboxesValue
{
  items:Array<IFilterCheckboxesItemValueType>;
}

export interface IFilterCheckboxesDataItem
{
  name:string;
  value:IFilterCheckboxesItemValueType;
  isMenuDivider?:boolean;
}

export interface IFilterCheckboxesDataItems
{
  items:Array<IFilterCheckboxesDataItem>;
}

// ---------------------------------------------------------

export const CHECKBOXES_FILTER_EMPTY_VALUE = { items: [] };

// ---------------------------------------------------------

export const FilterCheckboxesSettings:IFilter<IFilterCheckboxesValue> = {
  type: FilterType.CHECKBOXES,
  name: 'Checkboxes',
  isSelected: false,
  value: CHECKBOXES_FILTER_EMPTY_VALUE,
  component: FilterCheckboxesPopover,
  filterFunction: filterFunction
  // graphQLTemplate: '',
  // graphQLFilter: graphQLFilter
};

// ---------------------------------------------------------

function filterFunction(this:IFilter<IFilterCheckboxesValue>, filteredValue:IFilterCheckboxesItemValueType):boolean
{
  if( !this.value.items?.length ) return true;

  return this.value.items.includes(filteredValue);
}

// ---------------------------------------------------------

// function graphQLFilter(this:IFilter<IFilterCheckboxesValue>):string
// {
//   if( !this.value.items?.length ) return '';
//
//   if( !this.graphQLTemplate )
//     throw new Error(`Cannot apply filter ${this.name}: graphQLTemplate is not defined`);
//
//   return this.graphQLTemplate.replace('{{items}}', JSON.stringify(this.value.items));
// }
