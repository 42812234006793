// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Controller = any;

export interface ControllerConstructor extends Function
{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new(...args:Array<any>):Controller;
}

export function controller(
  controllerConstructor:ControllerConstructor
):ControllerConstructor
{
  if( controllerConstructor && controllerConstructor.prototype.constructor )
  {
    return controllerConstructor;
  }
  else
    throw new Error('@controller decorator should be used on class only');
}
