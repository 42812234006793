import { CommonController } from '@flow/common/CommonController';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { ButtonMore } from '@flow/common/components/elements/ButtonMore';
import { EmptyMenu } from '@flow/common/components/elements/EmptyMenu';
import { Icon, IconName, IconNames } from '@flow/common/components/form/Icon';
import { Spinner } from '@flow/common/components/form/Spinner';
import { ItemsCount } from '@flow/common/components/page/ItemsCount';
import { List } from '@flow/common/components/page/List';
import { Pagination } from '@flow/common/components/page/Pagination';
import { DebugUtil } from '@flow/common/utils/DebugUtil';
import { PositionsUtil } from '@flow/common/utils/PositionsUtil';
import { SlotUtil } from '@flow/common/utils/SlotUtil';
import type {
  Common_City,
  Common_Country,
  Staffing_Customer_Team_Slot,
  Staffing_Staff
} from '@flow/data-access/lib/types/graphql.generated';
import { Staffing_Staff_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { StaffColumnType } from '@flow/modules/staffing/staff/StaffColumnsState';
import { StaffController } from '@flow/modules/staffing/staff/StaffController';
import { StaffStatusTitle } from '@flow/modules/staffing/staff/StaffMemberState';
import { StaffSiteSubsiteState } from '@flow/modules/staffing/staff/StaffSiteSubsiteState';
import { StaffState } from '@flow/modules/staffing/staff/StaffState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { action, runInAction } from 'mobx';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
// import { StaffColumnType } from '../../StaffColumnsState';
import styles from './StaffList.module.less';
import { StaffListHeader } from './StaffListHeader';

const cx = classNames.bind(styles);

interface IStaffStatusItem
{
  title:string;
  className:string;
}

const StaffStatusItem:Record<string, IStaffStatusItem> = {
  [Staffing_Staff_Status_Enum.Newcomer]: {
    title: StaffStatusTitle[Staffing_Staff_Status_Enum.Newcomer],
    className: 'newcomer'
  },
  [Staffing_Staff_Status_Enum.Staffed]: {
    title: StaffStatusTitle[Staffing_Staff_Status_Enum.Staffed],
    className: 'staffed'
  },
  [Staffing_Staff_Status_Enum.Leaving]: {
    title: 'Leaving', // StaffStatusTitle[Staffing_Staff_Status_Enum.Leaving], = 'Leaving company'
    className: 'leaving'
  },
  [Staffing_Staff_Status_Enum.Archived]: {
    title: StaffStatusTitle[Staffing_Staff_Status_Enum.Archived],
    className: 'archived'
  }
};

@component
export class StaffList extends Component
{
  @di private _commonController!:CommonController;
  @di private _staffState!:StaffState;
  @di private _staffSiteSubsiteState!:StaffSiteSubsiteState;

  @di private _staffController!:StaffController;

  // ---------------------------------------------------------

  @action.bound
  public _onClickRow(staffUser:Staffing_Staff)
  {
    return (e:React.MouseEvent<HTMLElement>):void =>
    {
      e.preventDefault();
      // this._staffController.showEditStaffUserForm(staffUser);
      this._staffController.setViewStaffId(staffUser.id);
      this._commonController.goToStaffMember(staffUser.id);
    };
  }

  @action.bound
  public _onClickMoreMenu(e:React.MouseEvent<HTMLElement>):void
  {
    e.stopPropagation();
  }

  // ---------------------------------------------------------

  @bind
  private _renderCustomers(slots:Array<Staffing_Customer_Team_Slot>):React.ReactNode
  {
    const slotsCount:number = slots
      .filter((slot) => SlotUtil.isSlotVisible(slot)).length;

    return (
      <div className={styles.slotWrapper}>
        {slots
          .filter((slot, index) => index < 2)
          .map((slot:Staffing_Customer_Team_Slot, index:number) =>
          {
            if( !slot || !SlotUtil.isSlotVisible(slot) )
              return null;

            const customerAlias = slot.position.customer_team?.customer.alias;
            const teamName = slot.position.customer_team?.name;
            const positionName:string = PositionsUtil.getPositionName(slot.position);

            return (
              <div
                key={slot.id}
                className={styles.slot}
              >
                <span className={styles.slotCustomer}>
                  {customerAlias}
                </span>
                <Icon
                  className={styles.iconChevron}
                  icon={IconNames.CHEVRON_RIGHT}
                  size={12}
                />
                <span className={styles.slotTeam}>
                  {teamName}
                </span>
                <div className={styles.positionName}>
                  <Icon
                    className={styles.iconDot}
                    icon={IconNames.DOT}
                    size={8}
                  />
                  <span className={styles.slotPosition}>
                    {positionName}
                  </span>
                  {
                    (slotsCount > 2 && index === 1) &&
                    <span className={styles.slotsCount}>+{slotsCount - 2}</span>
                  }
                </div>
              </div>
            );
          })}
      </div>
    );
  }

  // ----------------------------------------------------

  @bind
  private _renderStatus(staffUser:Staffing_Staff):React.ReactNode
  {
    const { status, hire_date, leaving_date, status_date } = staffUser;

    const title:string = status ? StaffStatusItem[status].title : '';

    const isStaffed:boolean = status === Staffing_Staff_Status_Enum.Staffed;

    const date:string = status === Staffing_Staff_Status_Enum.Newcomer ? hire_date
      : status === Staffing_Staff_Status_Enum.Leaving ? leaving_date
        : status === Staffing_Staff_Status_Enum.Archived ? status_date : '';

    let icon:IconName | null = status === Staffing_Staff_Status_Enum.Newcomer ? IconNames.LOG_IN
      : status === Staffing_Staff_Status_Enum.Leaving ? IconNames.LOG_OUT
        : status === Staffing_Staff_Status_Enum.Archived ? IconNames.PROJECTS : null;

    const mDate:Moment | null = date ? moment(date) : null;
    const strDate:string = mDate && mDate.isValid() ? mDate.format('MMM D, YYYY') : '';

    let warning:boolean = false;

    if( (icon && strDate === '') || !status )
    {
      warning = true;
      icon = IconNames.WARNING_SIGN;
    }

    const statusClassName:string = status ? StaffStatusItem[status].className : '';

    return (
      <div
        className={cx(styles.status, statusClassName, { warning })}
      >
        <span className={styles.statusTitle}>
          {title}
        </span>
        {
          !isStaffed &&
          <span className={styles.hireDate}>
            <Icon
              className={styles.statusDateIcon}
              icon={icon}
            />
            {strDate}
          </span>
        }
      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _renderStaffUser(staffUser:Staffing_Staff):React.ReactNode
  {
    const {
      user, customer_team_slots, role, next_staff_slots,
      country_id, city_id, avatar_url, status
    } = staffUser;
    const { first_name, last_name, avatar } = user;

    const {
      columnByType
      // strPositionGroupsByStaffUserId, interviewFlowStageById, userNameByUserId
    } = this._staffState;

    // const position:Recruiting_Position_Group | undefined = position_group_id
    //   ? this._staffState.getPositionGroupById(position_group_id)
    //   : undefined;

    // const statusName:string = interviewFlowStageById(staffUser.interview_flow_stage?.id)?.name || '';

    const country:Common_Country | undefined = this._staffSiteSubsiteState.countryById(country_id);
    const city:Common_City | undefined = this._staffSiteSubsiteState.cityById(city_id);
    const staffAvatar:string | undefined | null = avatar_url || avatar;

    const allSlots = customer_team_slots.concat(next_staff_slots);

    return (
      <div
        className={styles.rowWrapper}
        onClick={this._onClickRow(staffUser)}
      >
        <div className={cx(styles.col, styles.colName)}>

          <Avatar
            className={styles.avatar}
            url={staffAvatar}
            size={36}
          />
          <Avatar
            className={styles.avatarMobile}
            url={staffAvatar}
          />

          <div className={styles.name}>
            <div className={styles.userName}>
              {
                DebugUtil.isEnabled &&
                <span style={{ color: '#f00', fontWeight: 400, fontSize: 13 }}>
                  {DebugUtil.id(staffUser.id)}
                </span>
              }
              {first_name} {last_name}
            </div>
            {
              // role?.name &&
              <div className={styles.roleName}>
                {role?.name}
              </div>
            }
          </div>
        </div>

        {
          columnByType(StaffColumnType.POSITIONS).isVisible &&
          <div className={cx(styles.col, styles.colCustomer)}>
            {this._renderCustomers(allSlots)}
          </div>
        }

        {
          columnByType(StaffColumnType.STATUS).isVisible &&
          <div className={cx(styles.col, styles.colStatus)}>
            {/*{this._renderStatus(allSlots)}*/}
            {this._renderStatus(staffUser)}
          </div>
        }

        {
          columnByType(StaffColumnType.SITE_SUBSITE).isVisible &&
          <div className={cx(styles.col, styles.colSiteSubsite)}>
            {country?.name}
            {
              city &&
              <>
                {/*<Icon*/}
                {/*  className={styles.iconDot}*/}
                {/*  icon={IconNames.DOT}*/}
                {/*  size={8}*/}
                {/*/>*/}
                , {city.name}
              </>
            }
          </div>
        }

        <div
          className={cx(styles.col, styles.colMore)}
          onClick={this._onClickMoreMenu}
        >
          <ButtonMore
            className={cx(styles.mobile, styles.btnMore)}
            menuContent={<EmptyMenu />}
            disabled={true}
          />
          <ButtonMore
            className={cx(styles.desktop, styles.btnMore)}
            menuContent={<EmptyMenu />}
            disabled={true}
          />
        </div>

        {/*<div*/}
        {/*  className={styles.positionGroup}*/}
        {/*>*/}
        {/*  {*/}
        {/*    position &&*/}
        {/*    <Tag minimal={true} round={true}>*/}
        {/*      {position?.name}*/}
        {/*    </Tag>*/}
        {/*  }*/}
        {/*</div>*/}
      </div>
    );
  }

  // ---------------------------------------------------------

  @bind
  private _onScroll(event:React.UIEvent<HTMLDivElement>):void
  {
    if( this._staffState.staffListScrollYPosition != Number(event.currentTarget.scrollTop) )
      runInAction(() => this._staffState.staffListScrollYPosition = Number(event.currentTarget.scrollTop));
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const {
      staffUsersForCurrentPage, filteredStaffUsers, isStaffUsersLoaded,
      currentPage, itemsPerPage, staffListScrollYPosition
    } = this._staffState;

    const itemsTotal = filteredStaffUsers.length;

    if( !isStaffUsersLoaded )
    {
      return (
        <Spinner
          // size={36}
          noIcon
          withWave
          withWrapper
          wrapperClassName={styles.spinnerWrapper}
        />
      );
    }

    return (
      <>
        <div className={styles.listWrapper}>
          <List
            header={<StaffListHeader />}
            headerClassName={styles.headerWrapper}
            items={staffUsersForCurrentPage}
            itemRenderer={this._renderStaffUser}
            noItemsTitle={'No staff'}
            onScroll={this._onScroll}
            scrollPosition={staffListScrollYPosition}
          />
        </div>

        <ItemsCount
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          itemsTotal={itemsTotal}
        />

        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          itemsTotal={itemsTotal}
          onChange={this._staffController.setCurrentPage}
        />
      </>
    );
  }

  // ---------------------------------------------------------
}
