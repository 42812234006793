import { state } from '@flow/dependency-injection';
import type { IReactionDisposer } from 'mobx';
import { observable } from 'mobx';

export const SettingsPageState_LS_KEY:string = 'settingsPageState';

@state
export class SettingsPageState
{
  // ----------------------------------------------------

  public disposers:Array<IReactionDisposer> = [];

  // ----------------------------------------------------

  @observable public isLoaded:boolean = false;

  // ----------------------------------------------------
}
