import { CommonController } from '@flow/common/CommonController';
import { PositionsCount } from '@flow/common/components/elements/PositionsCount';
import { SvgIcon, SvgIconName } from '@flow/common/components/elements/SvgIcon';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { RouteName } from '@flow/common/models/routing/RouteName';
import { IPositionsCount, PositionsUtil } from '@flow/common/utils/PositionsUtil';
import type { Recruiting_Position, Recruiting_Position_Group } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { PositionsListPositionGroupSelector } from '@flow/modules/recruiting/positions/components/PositionsListPositionGroupSelector';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import { computed } from 'mobx';
import React from 'react';
import { RoutesConfig } from '../../../../pages/RoutesConfig';
import { PositionsController } from '../PositionsController';
import { PositionsState } from '../PositionsState';

import styles from './PositionsListTeamPosition.module.less';

const cx = classNames.bind(styles);

interface Props
{
  position:Recruiting_Position_Ex;
  index:number;
  positions:Array<Recruiting_Position>;
}

@component
export class PositionsListTeamPosition extends React.Component<Props>
{
  @di private _commonController!:CommonController;
  @di private _roleController!:RoleController;
  @di private _positionsState!:PositionsState;
  @di private _positionsController!:PositionsController;

  // ---------------------------------------------------------

  @computed
  private get _positionsCount():IPositionsCount
  {
    const { position } = this.props;

    return PositionsUtil.getPositionsCount([position]);
  }

  // ---------------------------------------------------------

  @bind
  private _onClickPosition():void
  {
    const { position } = this.props;

    const path:string | null = RoutesConfig.getRoutePath(
      RouteName.RECRUITING_POSITIONS_POSITION,
      {
        positionId: String(position.id)
      }
    );

    if( path )
      this._commonController.navigate(path);
  }

  // ---------------------------------------------------------

  public render():React.ReactNode | null
  {
    const { open, staffed, total } = this._positionsCount;

    if( !open ) return null;

    // ----------------------

    const { position } = this.props;

    const { position_template, position_group_id } = position;

    const name:string = PositionsUtil.getPositionName(position);

    const unassigned:boolean = !position_group_id;

    const positionGroup:Recruiting_Position_Group | undefined = this._positionsState.positionGroupById(Number(position_group_id ?? 0));

    const isReady:boolean = position.is_ready;

    return (
      <div
        className={cx(styles.position, { unassigned })}
        onClick={this._onClickPosition}
      >
        <div className={styles.colPositionTemplate}>
          {
            isReady &&
            <Icon
              className={cx(styles.templateIcon, styles.isReady)}
              icon={IconNames.DOCUMENT}
            />
          }
          {
            !isReady &&
            <SvgIcon
              className={styles.templateIcon}
              icon={SvgIconName.DocumentQuestion}
            />
          }
          {name}
        </div>

        <div className={styles.colStaffRole}>
          {position_template?.staff_role?.name}
        </div>

        <div className={styles.colStaffedTotal}>
          <PositionsCount
            count={staffed}
            totalCount={total}
          />
        </div>

        <div
          className={styles.colPositionGroup}
          onClick={(e):void =>
          {
            isReady && e.stopPropagation();
          }}
        >
          {
            isReady &&
            <PositionsListPositionGroupSelector
              position={position}
              positionGroup={positionGroup}
              disabled={staffed !== 0 || !this._roleController.hasPermission(FlowPermissions.AssignPositionToPositionGroup)}
            />
          }
          {
            !isReady &&
            <div className={styles.confirmText}>
              Confirm description
            </div>
          }
        </div>

      </div>
    );
  }

  // ---------------------------------------------------------
}
