import { Button, Collapse, Intent } from '@blueprintjs/core';
import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import React from 'react';

import styles from './Toasts.module.less';
import type { ToastsController } from './ToastsController';

interface IErrorToastProps
{
  message:string;
  stackTrace?:string;
  controller:ToastsController;
  errorCount:number;
}

interface IErrorToastState
{
  isOpen:boolean;
}

@component
export class ErrorToast extends React.Component<IErrorToastProps, IErrorToastState>
{
  public constructor(props:IErrorToastProps)
  {
    super(props);

    this.state = {
      isOpen: false
    }
  }

  @bind
  private _copyToClipboard():void
  {
    const { message, stackTrace, controller } = this.props;

    navigator.clipboard.writeText(`${String(message)}\n${String(stackTrace)}`).then(():void =>
    {
      controller.showSuccess('Stack trace copied to clipboard!');
    });
  }

  public render():React.ReactNode
  {
    const { message, stackTrace } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        <div className={styles.errorMessageRow}>

          <span className={styles.errorMessage}>
            Error: {message}
          </span>

          <Button
            className={styles.errorDetailsButton}
            text={isOpen ? 'Less...' : 'More...'}
            minimal
            outlined
            onClick={():void =>
            {
              this.setState({ isOpen: !isOpen });
            }}
          />

        </div>

        <Collapse
          isOpen={isOpen}
          className={styles.collapse}
        >

          <pre className={styles.stackTrace}>
            {`${String(message)}\n${String(stackTrace)}`}
          </pre>

          <div className={styles.buttonsRow}>
            <Button text={'Copy'}
              className={styles.copyButton}
              intent={Intent.NONE}
              minimal
              outlined
              onClick={this._copyToClipboard}
            />

            <Button text={'Refresh'}
              className={styles.refreshButton}
              intent={Intent.WARNING}
              onClick={():void => window.location.reload()}
            />

          </div>

        </Collapse>
      </>
    )
  }
}
