import { PositionItem } from '@flow/common/components/elements/PositionItem';
import { AuthState } from '@flow/common/controllers/AuthState';
import { SortUtil } from '@flow/common/utils/SortUtil';
import type { Staffing_Customer_Team_Slot } from '@flow/data-access/lib/types/graphql.generated';
import { di, state } from '@flow/dependency-injection';
import type { Recruiting_Position_Ex } from '@flow/modules/customers/teams/models/CustomersTypes';
import { StaffMemberState } from '@flow/modules/staffing/staff/StaffMemberState';
import { computed, observable, toJS } from 'mobx';
import { computedFn } from 'mobx-utils';
import React from 'react';

// TODO: remove
const __DEBUG:boolean = false;

@state
export class StaffMemberAllocationState
{
  @di private _authState!:AuthState;
  @di private _staffMemberState!:StaffMemberState;

  // ----------------------------------------------------

  @observable public positions:Array<Recruiting_Position_Ex> = [];

  // ----------------------------------------------------

  @observable public selectedLeavingDate:string | null = null;
  @observable public selectedLeavingDateSlotId:number | null = null;

  // ----------------------------------------------------

  @observable public isAddPositionDialogOpen:boolean = false;
  @observable public isAddPositionLoading:boolean = false;
  @observable public scrolledToEditedPosition:boolean = false;

  @observable public isEditPosition:boolean = false;
  @observable public editedPositionId:number | null = null;
  @observable public isScrolledToPosition:boolean = false;

  @observable public selectedPositionId:number | null = null;
  @observable public selectedPositionItemRef:React.RefObject<PositionItem> | null = null;
  @observable public selectedSlot:Staffing_Customer_Team_Slot | null = null;
  @observable public selectedDate:string | null = null;

  // ----------------------------------------------------

  @observable public isRemovePositionDialogOpen:boolean = false;

  @observable public slotForRemove:Staffing_Customer_Team_Slot | null = null;

  // ----------------------------------------------------

  @computed
  public get editedPositionCurrentSlot():Staffing_Customer_Team_Slot | undefined
  {
    const { staffMemberSlots } = this._staffMemberState;

    if( !this.isEditPosition || !this.editedPositionId || !staffMemberSlots ) return;

    return staffMemberSlots.find((slot:Staffing_Customer_Team_Slot) =>
    {
      return slot.position.id === this.editedPositionId;
    });
  }

  // ----------------------------------------------------

  @computed
  public get positionsByPositionGroups():Array<Recruiting_Position_Ex>
  {
    const {
      staffMemberPositionGroupIds, staffMemberPositionIds
    } = this._staffMemberState;

    const isAddPosition:boolean = !this.isEditPosition;

    console.log('%c positionsByPositionGroups isAddPosition =', 'background:#88f;color:#000;', toJS(isAddPosition));

    return this.positions
      .filter((position:Recruiting_Position_Ex) =>
      {
        __DEBUG && console.log('%c position =', 'background:#ccf;color:#000;', toJS(position));

        if( !position.position_group )
        {
          __DEBUG && console.log('%c --> REMOVE 1 =', 'background:#88f;color:#000;');
          return false;
        }

        if( staffMemberPositionGroupIds.length && position.position_group &&
          !staffMemberPositionGroupIds.includes(position.position_group?.id) )
        {
          __DEBUG && console.log('%c --> REMOVE 2 =', 'background:#88f;color:#000;');
          return false;
        }

        // remove current staff positions
        if( isAddPosition && staffMemberPositionIds.includes(position.id) )
        {
          __DEBUG && console.log('%c --> REMOVE 3 =', 'background:#88f;color:#000;');
          return false;
        }

        // remove current staff positions, except edited
        if( this.isEditPosition && staffMemberPositionIds.includes(position.id) && position.id !== this.editedPositionId )
        {
          __DEBUG && console.log('%c --> REMOVE 4 =', 'background:#88f;color:#000;');
          return false;
        }

        if( position.open_slots_count.aggregate.count === 0 )
        {
          if( this.isEditPosition && position.id === this.editedPositionId )
          {
            return true;
          }

          const hasStaffOnlySlots:boolean = position.customer_team_slots.some((slot:Staffing_Customer_Team_Slot) =>
          {
            return !!slot.staff && !slot.next_staff && !slot.candidate;
          });

          if( !hasStaffOnlySlots )
          {
            __DEBUG && console.log('%c --> REMOVE 5 =', 'background:#88f;color:#000;');
          }

          return hasStaffOnlySlots;
        }

        return true;
      })
      .sort(SortUtil.byCustomerAndTeam);
  }

  // ----------------------------------------------------

  public positionById:(id:number) => Recruiting_Position_Ex | undefined =
    computedFn((id:number):Recruiting_Position_Ex | undefined =>
    {
      return this.positions.find((positions:Recruiting_Position_Ex) => positions.id === id);
    });

  // ----------------------------------------------------
}
