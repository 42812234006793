import type { TabId } from '@blueprintjs/core';
import { Tab, Tabs } from '@blueprintjs/core';

import styles from '@flow/common/styles/TabsPanel.module.less';
import { component, di } from '@flow/dependency-injection';
import classNames from 'classnames/bind';
import { runInAction } from 'mobx';
import React from 'react';
import { CandidateState, CandidateTabName } from '../../CandidateState';
import { CandidatePanelCommunication } from './CandidatePanelCommunication';
import { CandidatePanelExecSummary } from './CandidatePanelExecSummary';
import { CandidatePanelJobOffer } from './CandidatePanelJobOffer';

const cx = classNames.bind(styles);

@component
export class CandidateTabs extends React.Component
{
  @di private _candidateState!:CandidateState;

  public render():React.ReactNode
  {
    return (
      <Tabs
        className={styles.tabs}
        animate={false}
        selectedTabId={this._candidateState.tabId}
        defaultSelectedTabId={CandidateTabName.COMMUNICATION}
        onChange={(tabId:TabId):void =>
        {
          runInAction(() => this._candidateState.tabId = tabId);
        }}
      >

        <Tab
          className={cx({ activeTab: this._candidateState.tabId == CandidateTabName.COMMUNICATION })}
          panelClassName={styles.tabPanel}
          id={CandidateTabName.COMMUNICATION}
          title={'Communication'}
          panel={<CandidatePanelCommunication />}
        />

        <Tab
          className={cx({ activeTab: this._candidateState.tabId == CandidateTabName.EXEC_SUMMARY })}
          panelClassName={styles.tabPanel}
          id={CandidateTabName.EXEC_SUMMARY}
          title={'Exec Summary'}
          panel={<CandidatePanelExecSummary />}
        />

        <Tab
          className={cx(styles.disabled, { activeTab: this._candidateState.tabId == CandidateTabName.JOB_OFFER })}
          panelClassName={styles.tabPanel}
          id={CandidateTabName.JOB_OFFER}
          title={'Job Offer'}
          panel={<CandidatePanelJobOffer />}
          disabled
        />

      </Tabs>
    );
  }
}
