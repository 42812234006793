import { Button, ButtonSize } from '@flow/common/components/form/Button';
import { component } from '@flow/dependency-injection';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './ConstraintsButton.module.less';

const cx = classNames.bind(styles);

@component
export class ConstraintsButton extends React.Component
{
  // @di private _commonController!:CommonController;
  // @di private _roleController!:RoleController;

  // @di private _candidateState!:CandidateState;
  // @di private _candidateFlowState!:CandidateFlowState;
  // @di private _candidateFlowsController!:CandidateFlowController;

  // ---------------------------------------------------------

  public render():ReactNode | null
  {
    // TODO: FLOW-276
    return null;

    return (
      <div className={styles.constraintsBtnWrapper}>

        <Button
          className={cx(styles.btnConstraints)}
          text={'!'}
          minimal={true}
          outlined={true}
          size={ButtonSize.LARGE}
        />

      </div>
    );
  }

  // ---------------------------------------------------------
}
