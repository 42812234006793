import type { IRouteValidator } from '@flow/common/models/routing/RouteValidators';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter } from '@flow/dependency-injection';
import type { IReactComponent } from 'mobx-react/dist/types/IReactComponent';
import type { ComponentType, FunctionComponent, ReactElement } from 'react';
import { PageNotFound } from '../../../pages/404';

export function routeValidator<Props>(
  validators:Array<IRouteValidator>
):(ComposedComponent:ComponentType<Props>) => IReactComponent<Props>
{
  return ((ComposedComponent:ComponentType<Props>):IReactComponent<Props> =>
  {
    const WithRouterWrapper:FunctionComponent<IWithRouterProps> = componentWithRouter((
      props:IWithRouterProps
    ):ReactElement =>
    {
      const { location, params, ...restProps } = props;

      for( const validator of validators )
      {
        const isValid:boolean = validator.validate(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          location?.pathname || '',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          params || {},
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          location?.search || ''
        );

        if( !isValid )
        {
          return <PageNotFound />;
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return <ComposedComponent {...restProps as any} />;
    });

    const name = ComposedComponent.displayName || ComposedComponent.name || 'Unknown';
    WithRouterWrapper.displayName = `withRouter(${name})`;

    return WithRouterWrapper as IReactComponent<Props>;
  });
}
