import { Icon, IconNames } from '@flow/common/components/form/Icon';
import type { HistoryItem } from '@flow/common/controllers/ObjectHistoryController';
import { DebugUtil } from '@flow/common/utils/DebugUtil';
import { StringUtil } from '@flow/common/utils/StringUtil';
import { Common_User } from '@flow/data-access/lib/types/graphql.generated';
import classNames from 'classnames/bind';
import moment from 'moment';
import React from 'react';

import styles from './CommunicationContent.module.less';

const cx = classNames.bind(styles);

interface ICommunicationContentProps
{
  item:HistoryItem;
  titleTextBegin?:string | JSX.Element;
  titleTextEnd?:string | JSX.Element;

  isPinned?:boolean;
  onPin?:() => void;
  onUnpin?:() => void;

  isTimeline?:boolean;
  isDebug?:boolean;
  noBorder?:boolean;
}

export class CommunicationContentItem extends React.PureComponent<ICommunicationContentProps>
{
  public render():React.ReactNode
  {
    const {
      item, titleTextBegin, titleTextEnd, isPinned, onPin, onUnpin,
      isTimeline, noBorder,
      isDebug
    } = this.props;

    const user = Array.isArray(item.user) && item.user.length > 0 ? item?.user[0] : undefined;
    const userName:string = user ? StringUtil.getUserName(user as Common_User) : 'Admin';

    // console.log('%c this.props.children =', 'background:#0f0;color:#000;', item.event_id, this.props.children);

    return (
      <div className={cx(styles.contentItem, { isDebug, noBorder })}>

        <div className={styles.title}>
          <span className={styles.textBegin}>
            {
              DebugUtil.isEnabled &&
              <span style={{ color: '#f00', fontWeight: 400, fontSize: 13 }}>{DebugUtil.id(item.event_id)}</span>
            }
            {titleTextBegin}
          </span>
          &nbsp;
          <span className={styles.textEnd}>
            {titleTextEnd}
          </span>
        </div>

        {
          this.props.children &&
          <div className={styles.note}>
            {this.props.children}
          </div>
        }

        <div className={cx(styles.footer, { isTimeline })}>
          {moment(item.action_tstamp_clk).format('MMM D, YYYY h:mm A')}
          <span className={styles.userName}>
            {userName}
          </span>
        </div>

        {
          (onPin && onUnpin) &&
          <div
            className={cx(styles.pinIconWrapper, { isPinned })}
            title={isPinned ? 'Unpin' : 'Pin'}
            onClick={isPinned ? onUnpin : onPin}
          >
            <Icon
              className={styles.pinIcon}
              size={13}
              icon={IconNames.Pin}
            />
          </div>
        }

      </div>
    );
  }
}
