import { Menu, MenuItem } from '@blueprintjs/core';
import {
  type IItemListRendererProps,
  type IItemRendererProps
} from '@blueprintjs/select';
import { Suggest } from '@blueprintjs/select';
import { CommonController } from '@flow/common/CommonController';
import { Avatar } from '@flow/common/components/elements/Avatar';
import { IconNames } from '@flow/common/components/form/Icon';
import { Spinner } from '@flow/common/components/form/Spinner';
import { component, di } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';
import { CandidatesController } from '../../CandidatesController';
import { CandidatesState } from '../../CandidatesState';

import styles from './CandidatesSearch.module.less';

const cx = classNames.bind(styles);

export interface ICandidateSearchItem
{
  id:number;
  avatarUrl?:string;
  firstName:string;
  lastName:string;
  email:string;
  linkedIn:string;
  positionGroups:string;
}

interface ICandidateSearchFoundIn
{
  inEmail:boolean;
  inLinkedIn:boolean;
  inName:boolean;
  inNameStart:boolean;
  inPositionGroups:boolean;
}

interface State
{
  isFocused:boolean;
}

@component
export class CandidatesSearch extends React.Component<unknown, State>
{
  @di private _commonController!:CommonController;
  @di private _candidatesState!:CandidatesState;
  @di private _candidatesController!:CandidatesController;

  // ---------------------------------------------------------

  public constructor(props:unknown)
  {
    super(props);

    this.state = {
      isFocused: false
    };
  }

  // ---------------------------------------------------------

  private _renderInputValue(/* item:ICandidateSearchItem */):string
  {
    // return `${item.firstName} ${item.lastName}`;
    return '';
  }

  private _itemWeight(foundIn:ICandidateSearchFoundIn):number
  {
    return foundIn.inNameStart
      ? 2
      : foundIn.inName || foundIn.inEmail || foundIn.inLinkedIn
        ? 1
        : 0;
  }

  @bind
  private _itemListRenderer(itemListProps:IItemListRendererProps<ICandidateSearchItem>):JSX.Element | null
  {
    const { renderItem, items, itemsParentRef } = itemListProps;
    const { searchQuery, isSearching } = this._candidatesState;

    return (
      <div className={styles.popoverContent}>

        <Menu
          ulRef={itemsParentRef}
        >

          {
            !isSearching && searchQuery.length > 0 && items.length == 0 &&
            <MenuItem disabled={true} text="No results" />
          }

          {
            isSearching && searchQuery.length > 0 &&
            <MenuItem disabled={true} text="Searching..." />
          }

          {
            searchQuery === ''
              ? (
                <MenuItem key={'disabled'} disabled={true} text="Start typing to find candidates" />
              )
              : (
                  items
                    .sort((item1, item2) =>
                    {
                      const item1in:ICandidateSearchFoundIn = this._getSearchResult(searchQuery, item1, true) as ICandidateSearchFoundIn;
                      const item2in:ICandidateSearchFoundIn = this._getSearchResult(searchQuery, item2, true) as ICandidateSearchFoundIn;
                      return this._itemWeight(item2in) - this._itemWeight(item1in);
                    })
                    .map(renderItem)
              )
          }
        </Menu>

        {
          isSearching &&
          <Spinner
            // size={36}
            noIcon
            withWave
            withWrapper
            wrapperClassName={styles.spinnerWrapper}
          />
        }

      </div>
    );
  }

  @bind
  private _itemRenderer(item:ICandidateSearchItem, {
    modifiers,
    handleClick,
    query,
    index
  }:IItemRendererProps):JSX.Element | null
  {
    if( !modifiers.matchesPredicate || index && index > 20 || query.trim() === '' )
    {
      return null;
    }

    // let moreCandidates:number = 0;

    // if( index && index === 20 )
    // {
    //   moreCandidates = this._candidatesState.candidates.length - 20;
    // }

    const { firstName, lastName, linkedIn, email, positionGroups } = item;

    const nameStr:string = `${firstName} ${lastName}`;

    const searchResult:ICandidateSearchFoundIn = this._getSearchResult(query, item, true) as ICandidateSearchFoundIn;

    const { inName, inEmail, inLinkedIn } = searchResult;

    return (
      <MenuItem
        active={modifiers.active}
        key={item.firstName + item.lastName + index?.toString()}
        onClick={handleClick}
        shouldDismissPopover={true}
        // disabled={!!moreCandidates}
        text={(
          <div className={styles.menuItemText}>
            <>
              <Avatar
                className={styles.avatar}
                url={item.avatarUrl}
              />
              <div className={styles.text}>
                <div className={styles.name}>
                  {inName ? this._highlightText(nameStr, query) : nameStr}
                </div>
                <div className={styles.otherText}>
                  {inEmail && this._highlightText(email, query)}
                  {inLinkedIn && this._highlightText(linkedIn, query)}
                  {!inEmail && !inLinkedIn && this._highlightText(positionGroups, query)}
                </div>
              </div>
            </>
          </div>
        )}
      />
    );
  }

  // ---------------------------------------------------------

  @bind
  private _onItemSelect(item:ICandidateSearchItem):void
  {
    console.log('%c _onItemSelect item =', 'background:#0f0;color:#000;', item);

    this._candidatesController.setViewCandidateId(item.id);
    this._commonController.goToCandidate(item.id);
  };

  // ---------------------------------------------------------

  @bind
  private _itemPredicate(query:string, item:ICandidateSearchItem/* , _index:any, exactMatch:any */):boolean
  {
    return this._getSearchResult(query, item, false) as boolean;
  };

  @bind
  private _trimLinkedin(linkedIn:string):string
  {
    return linkedIn
      .toLowerCase()
      .replace(/(http[s]?:\/\/)?([w]{3}.)?/, '')
      .replace(/(.*)(\/)$/, '$1');
  };

  @bind
  private _getSearchResult(query:string, item:ICandidateSearchItem, isMenuItem:boolean):boolean | ICandidateSearchFoundIn
  {
    const { firstName, lastName, email, linkedIn, positionGroups } = item;

    const inEmail:boolean = email.toLowerCase() === query.toLowerCase();
    const inLinkedIn:boolean = this._trimLinkedin(linkedIn) === this._trimLinkedin(query);
    const words:Array<string> = query.toLowerCase().split(' ');

    const searchInName:string = `${firstName} ${lastName}`.toLowerCase();
    const inNameStart:boolean = words.some((word:string) => firstName.toLowerCase().startsWith(word) || lastName.toLowerCase().startsWith(word));

    let inName:boolean = words.every((word:string) => searchInName.indexOf(word) !== -1);
    let inPositionGroups:boolean = words.every((word:string) => positionGroups.toLowerCase().indexOf(word) !== -1);

    const nameWords = words.filter((word:string) => searchInName.indexOf(word) !== -1);
    const notNameWords = words.filter(word => !nameWords.includes(word));
    const positionWords = notNameWords.filter((word:string) => positionGroups.toLowerCase().indexOf(word) !== -1);

    if( nameWords.length + positionWords.length == words.length )
    {
      if( nameWords.length > 0 )
        inName = true;

      if( positionWords.length > 0 )
        inPositionGroups = true;
    }

    const isFound:boolean = inEmail || inLinkedIn || inName || inPositionGroups;

    if( !isFound ) return false;

    if( isMenuItem )
    {
      return { inEmail, inLinkedIn, inNameStart, inName, inPositionGroups };
    }
    else // is Predicate Function
    {
      return isFound;
    }
  };

  // ---------------------------------------------------------

  private _escapeRegExpChars(text:string):string
  {
    return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
  }

  private _highlightText(text:string, query:string):Array<React.ReactNode>
  {
    let lastIndex = 0;
    const words = query
      .split(/\s+/)
      .filter(word => word.length > 0)
      .map(this._escapeRegExpChars);

    if( words.length === 0 )
    {
      return [text];
    }

    const regexp = new RegExp(words.join('|'), 'gi');
    const tokens:Array<React.ReactNode> = [];

    // eslint-disable-next-line no-constant-condition
    while( true )
    {
      const match = regexp.exec(text);
      if( !match )
      {
        break;
      }
      const length = match[0].length;
      const before = text.slice(lastIndex, regexp.lastIndex - length);

      if( before.length > 0 )
      {
        tokens.push(before);
      }
      lastIndex = regexp.lastIndex;
      tokens.push(<strong className={styles.token} key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if( rest.length > 0 )
    {
      tokens.push(rest);
    }
    return tokens;
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const SearchInput = Suggest.ofType<ICandidateSearchItem>();

    const { isFocused } = this.state;

    return (
      <SearchInput
        className={cx(styles.searchInput, { isFocused })}
        inputValueRenderer={this._renderInputValue}
        items={this._candidatesState.searchResult}
        itemListRenderer={this._itemListRenderer}
        itemRenderer={this._itemRenderer}
        onItemSelect={this._onItemSelect}
        query={this._candidatesState.searchQuery}
        onQueryChange={(query):void => this._candidatesController.setSearchQuery(query)}
        resetOnQuery={true}
        resetOnClose={true}
        resetOnSelect={true}

        itemPredicate={this._itemPredicate}

        noResults={<MenuItem disabled={true} text="No results" />}

        popoverProps={{
          // className: styles.searchPopover, // => searchInput
          popoverClassName: styles.popoverClassName,
          targetClassName: styles.targetClassName,
          hasBackdrop: true,
          backdropProps: { className: styles.backdrop }
          // usePortal: false
        }}

        inputProps={{
          className: styles.inputClassName,
          placeholder: 'Search for candidate...',
          leftIcon: IconNames.SEARCH,
          round: true,
          // large: true,
          fill: true,
          onFocus: ():void => this.setState({ isFocused: true }) as void,
          onBlur: ():void => this.setState({ isFocused: false }) as void
        }}
      />
    );
  }
}
