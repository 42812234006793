import { Avatar } from '@flow/common/components/elements/Avatar';
import { StringUtil } from '@flow/common/utils/StringUtil';
import type { Common_User } from '@flow/data-access/lib/types/graphql.generated';
import { component } from '@flow/dependency-injection';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './PrioritiesListUser.module.less';

const cx = classNames.bind(styles);

interface Props
{
  user:Common_User;
  className?:string;
  isEditMode?:boolean;
}

@component
export class PrioritiesListUser extends React.Component<Props>
{
  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { user, className, isEditMode } = this.props;

    const userName:string = StringUtil.getUserName(user);

    return (
      <div className={cx(styles.userWrapper, className, { isEditMode })}>

        <Avatar
          size={36}
          url={user.avatar}
          className={styles.userAvatar}
        />

        <div className={styles.textWrapper}>
          <div className={styles.userName} title={userName}>
            {userName}
          </div>
          <div className={styles.userRole}>
            {user.role_names}
          </div>
        </div>

      </div>
    );
  }

  // ---------------------------------------------------------
}
