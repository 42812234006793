import type { IFilter } from '@flow/common/components/filters/Filter';
import { FilterType } from '@flow/common/components/filters/Filter';
import { FilterStatusSelector } from './FilterStatusSelector';

// ---------------------------------------------------------

export interface IFilterStatusData
{
  title:string;
  value:string;
}

export interface IFilterStatusValue
{
  statuses:Array<string>;
}

// ---------------------------------------------------------

export const FilterStatusSettings:IFilter<IFilterStatusValue> = {
  type: FilterType.STATUS,
  name: 'Status',
  isSelected: false,
  value: { statuses: [] },
  component: FilterStatusSelector,
  filterFunction: filterFunction,
  graphQLTemplate: `{
    "status": {
      "_in": {{items}}
    }
  }`,
  graphQLFilter: graphQLFilter
};

// ---------------------------------------------------------

function filterFunction(this:IFilter<IFilterStatusValue>, status:string):boolean
{
  const { statuses } = this.value;

  if( statuses && !statuses.length ) return true;

  return statuses && statuses.includes(status);
}

// ---------------------------------------------------------

function graphQLFilter(this:IFilter<IFilterStatusValue>):string
{
  if( !this.value.statuses?.length ) return '';

  if( !this.graphQLTemplate )
    throw new Error(`Cannot apply filter ${this.name}: graphQLTemplate is not defined`);

  return this.graphQLTemplate.replace('{{items}}', JSON.stringify(this.value.statuses));
}

