import classNames from 'classnames/bind';
import React from 'react';
import type { IconName } from './Icon';
import { Icon } from './Icon';

import styles from './SpinnerIcon.module.less';

const cx = classNames.bind(styles);

interface Props
{
  icon:IconName;
  size:number;
  className?:string;
}

export class SpinnerIcon extends React.PureComponent<Props>
{
  public render():React.ReactNode
  {
    const { icon, className, size } = this.props;

    return (
      <div
        className={cx(styles.spinner, className)}
      >
        <Icon
          icon={icon}
          size={size || 16}
          className={styles.spinnerIcon}
        />
      </div>
    );
  }
}
