import { Menu, MenuItem } from '@blueprintjs/core';
import { ButtonMore } from '@flow/common/components/elements/ButtonMore';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { NeedsPermission } from '@flow/common/components/NeedsPermission';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { PositionsUtil } from '@flow/common/utils/PositionsUtil';
import { component, di } from '@flow/dependency-injection';
import { PositionController } from '@flow/modules/recruiting/position/PositionController';
import { PositionState } from '@flow/modules/recruiting/position/PositionState';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './PositionInfo.module.less';

const cx = classNames.bind(styles);

@component
export class PositionInfo extends React.Component
{
  @di private _positionState!:PositionState;
  @di private _positionController!:PositionController;

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { isPositionLoaded, position, isEditMode } = this._positionState;

    if( !isPositionLoaded || !position )
    {
      return null;
    }

    const { position_template, customer_team } = position;

    const positionName:string = PositionsUtil.getPositionName(position);

    return (
      <div className={cx(styles.positionInfo, { isEditMode })}>

        <div className={styles.customer}>
          <Icon
            className={styles.icon}
            icon={IconNames.OFFICE}
            size={12}
          />
          <div className={styles.customerNameWrapper}>
            {customer_team?.customer.alias}
            <Icon
              className={styles.teamNameIcon}
              icon={IconNames.CHEVRON_RIGHT}
              size={10}
            />
            {customer_team?.name}
          </div>
        </div>

        <div className={styles.positionNameWrapper}>
          <div className={styles.positionName}>
            {positionName}
          </div>
          <NeedsPermission name={FlowPermissions.EditPositionAlias}>
            <ButtonMore
              className={styles.btnMore}
              menuContent={(
                <Menu>
                  <MenuItem
                    text={'Rename'}
                    onClick={():void =>
                    {
                      this._positionController.showRenamePositionDialog();
                    }}
                  />
                </Menu>
              )}
            />
          </NeedsPermission>
        </div>

        <div className={styles.staffRole}>
          {position_template?.staff_role?.name}
        </div>

      </div>
    );
  }
}
