import { Menu, MenuItem, Position } from '@blueprintjs/core';
import { Classes } from '@blueprintjs/popover2';
import type { IItemListRendererProps, IItemRendererProps } from '@blueprintjs/select';
import { Select } from '@blueprintjs/select';
import { FiltersPopover } from '@flow/common/components/filters/components/FiltersPopover';
import type { IFilterComponentProps } from '@flow/common/components/filters/Filter';
import type {
  IFilterSiteSubsiteItem,
  IFilterSiteSubsiteValue
} from '@flow/common/components/filters/siteSubsite/FilterSiteSubsite';
import { Button, ButtonSize } from '@flow/common/components/form/Button';
import { Checkbox } from '@flow/common/components/form/Checkbox';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { Alignment } from '@flow/common/components/types/Types';
import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import type React from 'react';
import { Component } from 'react';

import styles from './FilterSiteSubsiteSelector.module.less';

const cx = classNames.bind(styles);

@component
export class FilterSiteSubsiteSelector extends Component<IFilterComponentProps<IFilterSiteSubsiteValue>>
{
  // ---------------------------------------------------------

  @bind
  private _getTitle(withNot:boolean):string
  {
    const { filter, getFilterData } = this.props;

    const { value, exceptSelected } = filter.value;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterData:any = getFilterData?.();

    const { siteById, subsiteById } = filterData;

    const [site, subsite] = (value || '').split(':');

    const not:string = withNot && exceptSelected ? 'not ' : '';
    const siteName:string = siteById(parseInt(site))?.name;
    const siteTitle:string = siteName ? `${not}${siteName}` : 'Any';

    const subsiteTitle:string = (subsite && subsite !== '0') && subsiteById(parseInt(subsite))?.name || '';

    return `${siteTitle}${subsiteTitle ? ' • ' + subsiteTitle : ''}`;
  }

  // ---------------------------------------------------------

  @bind
  private _onChangeCheckbox():void
  {
    const { filter, onChangeFilter } = this.props;

    const { value, exceptSelected } = filter.value;

    if( exceptSelected )
    {
      onChangeFilter({ value });
    }
    else
    {
      onChangeFilter({ value, exceptSelected: true });
    }
  }

  // ---------------------------------------------------------

  @bind
  private _onItemSelect(item:IFilterSiteSubsiteItem):void
  {
    console.log('%c _onItemSelect item =', 'background:#0f0;color:#000;', item);

    const { filter, onChangeFilter } = this.props;

    const { exceptSelected } = filter.value;

    if( exceptSelected )
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChangeFilter({ value: item.value as any, exceptSelected });
    }
    else
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChangeFilter({ value: item.value as any });
    }
  }

  // ---------------------------------------------------------

  @bind
  private _itemRenderer(item:IFilterSiteSubsiteItem, {
    modifiers,
    index
  }:IItemRendererProps):JSX.Element | null
  {
    const { filter } = this.props;
    const { value: filterValue } = filter.value;

    const { title, value, isGroupItem, isGroupHeader, isMenuDivider } = item;

    // if( isMenuDivider )
    // {
    //   return <MenuDivider />;
    // }

    const isSelected:boolean = value === filterValue;

    return (
      <MenuItem
        key={`${title}:${value}:${index?.toString()}`}
        className={cx(styles.menuItem, { isMenuDivider, isGroupHeader })}
        active={modifiers.active}
        text={title}
        // shouldDismissPopover={true}
        // disabled={isSelected}
        icon={isGroupItem ? IconNames.BLANK : null}
        labelElement={<Icon icon={isSelected ? IconNames.TICK : null} />}

        // onSelect={():void => this._onItemSelect(item)}
        // onClick={handleClick}
        // onMouseDown={handleClick}
        // onMouseDown={():void => this._onItemSelect(item)}
        onClick={():void => this._onItemSelect(item)}
      />
    );
  }

  // ---------------------------------------------------------

  // @bind
  // private _itemListRenderer(itemListProps:IItemListRendererProps<IFilterSiteSubsiteItem>):JSX.Element
  // {
  //   const {
  //     items, itemsParentRef, renderItem
  //     // activeItem, filteredItems, query, renderCreateItem
  //   } = itemListProps;
  //
  //   const renderedItems = items.map(renderItem).filter(item => item != null);
  //
  //   return (
  //     <Menu
  //       ulRef={itemsParentRef}
  //       className={styles.selectMenu}
  //     >
  //       {renderedItems}
  //     </Menu>
  //   );
  // }

  // ---------------------------------------------------------

  @bind
  private _renderFilterContent():React.ReactElement | null
  {
    const { filter, getFilterData } = this.props;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterData:any = getFilterData?.();

    console.log('%c filterData =', 'background:#0f0;color:#000;', filterData);

    if( !filterData || !filterData.items ) return null;

    const items:Array<IFilterSiteSubsiteItem> = [
      { title: 'Any', value: '', isMenuDivider: true },
      ...filterData.items
    ];

    const BPSelect = Select.ofType<IFilterSiteSubsiteItem>();

    // const { site, subsite } = filter.value;

    // const siteText:string = site ?? 'Any';
    // const subsiteText:string = subsite ?? 'Any';

    const { exceptSelected } = filter.value;

    const siteText:string = this._getTitle(false);

    return (
      <div className={styles.filterSiteContent}>

        <div className={styles.filterSiteRow}>
          <Checkbox
            key={'exceptSelectedCheckbox'}
            className={styles.checkbox}
            label={'Except selected'}
            checked={!!exceptSelected}
            onChange={this._onChangeCheckbox}
          />
        </div>

        <div className={styles.filterSiteRow}>
          <BPSelect
            // className={styles.selectPopoverWrapper}
            items={items}
            filterable={false}
            // itemListRenderer={this._itemListRenderer}
            itemRenderer={this._itemRenderer}
            onItemSelect={this._onItemSelect}
            // onActiveItemChange={this._onActiveItemChange}
            // scrollToActiveItem={false}
            // resetOnClose={true}
            // resetOnSelect={true}
            popoverProps={{
              minimal: true,
              usePortal: false,
              // isOpen,
              // autoFocus: true, // (x) !!!
              className: styles.selectPopoverWrapper,
              popoverClassName: Classes.POPOVER2_DISMISS_OVERRIDE,
              // popoverClassName: Classes.POPOVER2_DISMISS,

              shouldReturnFocusOnClose: true,
              position: Position.BOTTOM_LEFT
              // onClose: onClose,
              // onClosed: onClosed,
              // onOpening: onOpening
            }}
          >
            <Button
              className={styles.filterSiteBtn}
              text={siteText}
              rightIcon={IconNames.CARET_DOWN}
              outlined={true}
              size={ButtonSize.SMALL}
              alignText={Alignment.LEFT}
            />
          </BPSelect>
        </div>

      </div>
    );
  }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { filter, onRemoveFilter, wrapperClassName, buttonClearText } = this.props;

    const { value } = filter.value;

    const isValueSelected:boolean = !!value;

    const siteText:string = this._getTitle(true);

    const buttonText:React.ReactNode = isValueSelected
      ? <span><b>{filter.name}</b>: {siteText}</span>
      : <span>{filter.name}</span>;

    return (
      <FiltersPopover
        wrapperClassName={wrapperClassName}
        title={filter.name}
        content={this._renderFilterContent()}
        contentButtonClearText={buttonClearText}
        onClickButtonClear={onRemoveFilter}
        buttonText={buttonText}
        isValueSelected={isValueSelected}
      />
    );
  }
}
