import type { IPopoverProps } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import type { SvgIconName } from '@flow/common/components/elements/SvgIcon';
import { SvgIcon } from '@flow/common/components/elements/SvgIcon';
import { Button } from '@flow/common/components/form/Button';
import type { IconName } from '@flow/common/components/form/Icon';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { InlineEditorMultiSelect } from '@flow/common/components/form/InlineEditorMultiSelect';
import { InlineEditorSelect } from '@flow/common/components/form/InlineEditorSelect';
import { TextInput } from '@flow/common/components/form/TextInput';
import { Alignment } from '@flow/common/components/types/Types';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import moment from 'moment';
import type { KeyboardEventHandler } from 'react';
import React from 'react';
import styles from './InlineEditor.module.less';
import { InlineEditorSuggest } from './InlineEditorSuggest';
import { TextArea } from './TextArea';

const cx = classNames.bind(styles);

// ---------------------------------------------------------

export enum InlineEditorType
{
  INPUT = 'INPUT',
  SELECT = 'SELECT',
  MULTISELECT = 'MULTISELECT',
  DATE = 'DATE',
  TIME = 'TIME'
}

// ??? for select & multiselect
export type IInlineEditorMultiSelectValue = {
  title?:string;
  element?:JSX.Element;  // if present, will be rendered instead of title in the popover/menu
  value?:number | string | Date | null;

  isMenuDivider?:boolean;
  isGroupHeader?:boolean;
  isGroupItem?:boolean;
  // values?:Array<IInlineEditorMultiSelectValue>;

  isActive?:boolean;
}

export type IInlineEditorFieldValue = string | number | Date | Array<string> | Array<number> | null;

export type IInlineEditorField = {
  name:string; // field name
  value:IInlineEditorFieldValue; // field current value

  values?:Array<IInlineEditorMultiSelectValue>; // ??? for select & multiselect
  resetValue?:IInlineEditorMultiSelectValue;

  isRequired?:boolean;
  label?:string; // label before input
  placeholder?:string;
  isAdditionalField?:boolean;
}

type IMousePosition = {
  x:number;
  y:number;
}

// ---------------------------------------------------------

export interface IInlineEditorSelectProps
{
  applyWhenSelect?:boolean;

  onClose?:() => void;
  onClosed?:() => void;
  onOpening?:() => void;

  // onChange:(newValues:Record<string, IInlineEditorField>) => void;

  onChangeInput?:(name:string) => (newValue:string) => void;

  isOpen?:boolean;

  minDate?:Date;
}

export interface IInlineEditorSuggestProps
{
  query?:string;
  onQueryChange?:(query:string) => void;

  selectedItem?:IInlineEditorMultiSelectValue;

  menuClassName?:string;
  inputClassName?:string;

  itemListPredicate?:(query:string, items:Array<IInlineEditorMultiSelectValue>) => Array<IInlineEditorMultiSelectValue>;

  onKeyDown?:KeyboardEventHandler<HTMLInputElement>;
}

export interface IInlineEditorMultiSelectProps
{
  isPills?:boolean; // multiselect

  onBlur?:() => void;
  onFocus?:() => void;
  onClose?:() => void;
  onClosed?:() => void;
  onOpening?:() => void;

  isOpen?:boolean;

  newValues?:Record<string, IInlineEditorField>;
  isInputChanged?:boolean;

  onChangeMultiSelect?:(name:string) => (newValue:Array<number>) => void;

  tagClassName?:string;

  popoverProps?:(Partial<IPopoverProps> & object);
}

export interface IInlineEditorProps extends IInlineEditorSelectProps, IInlineEditorMultiSelectProps, IInlineEditorSuggestProps
{
  type?:InlineEditorType;

  text:string;
  fields:Array<IInlineEditorField>;
  onChange?:(newValues:Record<string, IInlineEditorField>) => void;
  onCancel?:(inlineEditorFieldName:string) => void;
  itemPredicate?:(query:string, item:IInlineEditorMultiSelectValue, newItems?:Record<string, IInlineEditorField>) => boolean;

  viewText?:string | JSX.Element;

  textBefore?:string;
  textBeforeClassName?:string;

  wrapperClassName?:string;
  viewClassName?:string;
  textClassName?:string;
  editClassName?:string;

  icon?:IconName;
  svgIcon?:SvgIconName;
  iconClassName?:string;

  noButtons?:boolean;

  readonly?:boolean;

  textArea?:boolean;

  wrap?:boolean;
  isEditMode?:boolean;

  isChanged?:boolean;

  viewAsLink?:boolean;

  // ??? for select & multiselect
  // placeholder?:string;
  // isRequired?:boolean; // ???
  getStringForNewValue?:(newValue:string) => string;
}

// ---------------------------------------------------------

interface State
{
  isEditMode:boolean;
  newValues:Record<string, IInlineEditorField>;

  focusedElements:number;

  isMouseDown:boolean;
  isMouseUp:boolean;
  // isMouseMove:boolean;
  // isMouseLeave:boolean;
  // isMouseEnter:boolean;

  mouseDownPosition:IMousePosition | null;
  mouseUpPosition:IMousePosition | null;

  isLoading:boolean;
  isInputChanged:boolean;

  isSelectPopoverOpened:boolean;
}

export class InlineEditor extends React.PureComponent<IInlineEditorProps, State>
{
  // ---------------------------------------------------------

  public constructor(props:IInlineEditorProps)
  {
    super(props);

    this.state = {
      isEditMode: false,
      newValues: {},
      focusedElements: 0,

      isMouseDown: false,
      isMouseUp: false,

      mouseDownPosition: null,
      mouseUpPosition: null,

      isLoading: false,
      isInputChanged: false,
      isSelectPopoverOpened: false
    };
  }

  // ---------------------------------------------------------

  @bind
  public componentWillUnmount():void
  {
    window.removeEventListener('mouseup', this._onWindowMouseUp);
  }

  @bind
  private _onWindowMouseUp(e:MouseEvent):void
  {
    const { isMouseDown, isMouseUp } = this.state;
    console.log('%c _onWindowMouseUp =', 'background:#00f;color:#ff0;', isMouseDown, isMouseUp, e);

    window.removeEventListener('mouseup', this._onWindowMouseUp);

    if( !isMouseUp )
    {
      console.log('%c _onWindowMouseUp MouseUp on OTHER node =', 'background:#f00;color:#ff0;');
      return;
    }

    this._checkOnClick();
  }

  // ---------------------------------------------------------

  @bind
  public setEditMode():void
  {
    this._enterEditMode();
  }

  // ---------------------------------------------------------

  @bind
  private _onMouseDown(e:React.MouseEvent):void
  {
    const { isEditMode } = this.state;
    const { type } = this.props;

    if( isEditMode && (type === InlineEditorType.MULTISELECT || type === InlineEditorType.TIME) )
    {
      console.log('%c _onMouseDown =', 'background:#fa0;color:#000;');
      this._onFocusSomeInputOrButton();
      return;
    }

    if( isEditMode ) return;

    console.log('%c _onMouseDown e.clientX =', 'background:#0f0;color:#000;', e.clientX);
    this.setState({
      isMouseDown: true,
      isMouseUp: false,
      mouseDownPosition: { x: e.clientX, y: e.clientY },
      mouseUpPosition: null
    });

    window.addEventListener('mouseup', this._onWindowMouseUp);
  }

  @bind
  private _onMouseUp(e:React.MouseEvent):void
  {
    const { isMouseDown, isEditMode } = this.state;
    const { type } = this.props;

    if( isEditMode && (type === InlineEditorType.MULTISELECT || type === InlineEditorType.TIME) )
    {
      console.log('%c _onMouseUp =', 'background:#fa0;color:#000;');
      this._onBlurSomeInputOrButton();
      return;
    }

    if( isEditMode ) return;

    if( !isMouseDown )
    {
      console.log('%c _onMouseUp NO MOUSE DOWN =', 'background:#f00;color:#ff0;');
      return;
    }

    console.log('%c _onMouseUp e.clientX =', 'background:#080;color:#000;', e.clientX);
    this.setState({
      isMouseDown: false,
      isMouseUp: true,
      mouseUpPosition: { x: e.clientX, y: e.clientY }
    });
  }

  @bind
  private _checkOnClick():void
  {
    const { mouseDownPosition, mouseUpPosition } = this.state;

    if( !mouseDownPosition || !mouseUpPosition )
    {
      console.log('%c _checkOnClick NO MOUSE DOWN =', 'background:#f00;color:#ff0;');
      return;
    }

    const delta:number = Math.abs(mouseDownPosition.x - mouseUpPosition.x);

    console.log('%c _checkOnClick delta =', 'background:#ff0;color:#000;', delta);

    this.setState({
      isMouseDown: false,
      isMouseUp: false,
      mouseDownPosition: null,
      mouseUpPosition: null
    }, () => delta < 5 && this._enterEditMode());
  }

  // ---------------------------------------------------------

  @bind
  private _exitEditMode():void
  {
    const { onCancel, fields } = this.props;
    this.setState({ isEditMode: false, isLoading: false });

    onCancel?.(fields[0].name);
  }

  // ---------------------------------------------------------

  @bind
  private _enterEditMode():void
  {
    if( this.props.readonly )
      return;

    const { fields, type } = this.props;

    const newValues:Record<string, IInlineEditorField> = {};

    fields.forEach((value:IInlineEditorField) =>
    {
      newValues[value.name] = value;
    });

    this.setState({
      isEditMode: true,
      newValues,
      focusedElements: 0,
      isInputChanged: false,
      isSelectPopoverOpened: type === InlineEditorType.SELECT || type === InlineEditorType.MULTISELECT || type === InlineEditorType.TIME
    });
  }

  @bind
  private _onInputKeyDown(e:React.KeyboardEvent<HTMLElement>):void
  {
    const { isSelectPopoverOpened } = this.state;
    const { applyWhenSelect, textArea } = this.props;

    // console.log('%c e =', 'background:#0f0;color:#000;', isSelectPopoverOpened, e);

    if( e.key == 'Escape' ) // keyCode = 27
    {
      this._exitEditMode();
    }
    else if( e.key == 'Tab' ) // keyCode = 9
    {
      this.setState({ isSelectPopoverOpened: false });

      if( !applyWhenSelect )
        this.setState({ isInputChanged: true });
    }
    else if( e.key == 'Enter' && (!textArea || e.shiftKey) ) // keyCode = 13
    {
      if( isSelectPopoverOpened )
      {
        return;
      }

      this._applyChanges();
    }
  }

  // ---------------------------------------------------------

  @bind
  private _onChangeInput(name:string):(newValue:IInlineEditorFieldValue, isUserChange?:boolean) => void
  {
    return (newValue:IInlineEditorFieldValue, isUserChange?:boolean):void =>
    {
      const { newValues } = this.state;

      const isDateInput = typeof (isUserChange) == 'boolean';

      this.setState(
        {
          newValues: { ...newValues, [name]: { name, value: newValue } },
          isInputChanged: true
        }, () =>
        {
          const { newValues } = this.state;
          console.log('%c newValues =', 'background:#0f0;color:#000;', newValues, name, newValue);

          if( !isDateInput || (isDateInput && isUserChange) )
            this.setState({ isSelectPopoverOpened: false });

          if( this.props.applyWhenSelect && (!isDateInput || isUserChange) )
            this._applyChanges();
        });
    };
  }

  @bind
  private _onChangeMultiSelect(name:string):(newValue:Array<number>) => void
  {
    return (newValue:Array<number>):void =>
    {
      const { newValues } = this.state;
      console.log('%c _onChangeMiltiSelect 1=', 'background:#0f0;color:#000;', newValues, name, newValue);

      this.setState(
        {
          newValues: { ...newValues, [name]: { name, value: newValue } },
          isInputChanged: true
        }, () =>
        {
          const { newValues } = this.state;
          console.log('%c newValues =', 'background:#0f0;color:#000;', newValues, name, newValue);

          if( this.props.applyWhenSelect ) this._applyChanges();
        });
    };
  }

  @bind
  private async _applyChanges():Promise<void>
  {
    const { newValues } = this.state;
    const { type = InlineEditorType.INPUT, onChange, fields } = this.props;

    console.log('%c _applyChanges =', 'background:#0f0;color:#000;', newValues);

    if( type === InlineEditorType.INPUT )
    {
      let everyFieldsIsOk:boolean = true;

      fields.forEach((fieldSettings:IInlineEditorField) =>
      {
        const valueName:string = fieldSettings.name;

        if( fieldSettings.isRequired )
        {
          const newValue:IInlineEditorFieldValue = newValues[valueName]?.value || '';

          if( newValue.toString().trim() === '' ) everyFieldsIsOk = false;
        }
      });

      if( !everyFieldsIsOk )
      {
        return;
      }
    }
    else if( type === InlineEditorType.MULTISELECT )
    {
      const currentField:IInlineEditorField = fields[0];
      const { name, isRequired } = currentField;
      const { value: selectedIds } = newValues[name];

      if( isRequired && !(selectedIds as Array<number>)?.length )
      {
        return;
      }
    }

    this.setState({ isLoading: true });

    console.log('%c before await 1 =', 'background:#0ff;color:#000;');
    if( onChange )
      await onChange(newValues);

    this._exitEditMode();

    console.log('%c -after await 2 =', 'background:#0ff;color:#000;');
  }

  // ---------------------------------------------------------

  @bind
  private _onBlurSomeInputOrButton():void
  {
    // setTimeout is needed for multi-field editor:
    // focusedElements variable should be increased by the "onfocus" handler before "onblur" event happens,
    // otherwise we leave edit mode when the user sets focus on a next field
    setTimeout(() =>
    {
      console.log('%c _onBlurSomeInputOrButton 1 =', 'background:#ff0;color:#000;');

      const { focusedElements } = this.state;
      console.log('%c _onBlurSomeInputOrButton 2=', 'background:#aa0;color:#000;', focusedElements, '->', (focusedElements as number) - 1);

      if( focusedElements <= 1 )
      {
        this._exitEditMode();
      }
      else
      {
        this.setState({ focusedElements: focusedElements - 1 });
      }
    });
  }

  @bind
  private _onFocusSomeInputOrButton():void
  {
    const { focusedElements } = this.state;
    console.log('%c _onFocusSomeInputOrButton =', 'background:#ff0;color:#000;', focusedElements, '->', (focusedElements as number) + 1);

    this.setState({ focusedElements: (focusedElements as number) + 1 });
  }

  // ---------------------------------------------------------

  private _renderViewMode():React.ReactNode
  {
    const {
      type = InlineEditorType.INPUT,
      viewClassName, textClassName, iconClassName,
      svgIcon, icon, text, viewText, textArea, isChanged,
      fields, noButtons, getStringForNewValue, readonly, wrap,
      viewAsLink
    } = this.props;

    const additionalFields = fields.filter(x => !!x.isAdditionalField);
    const textStr:string = text.trim();
    let selectText:string = textStr;

    const selectPlaceholder:string = type === InlineEditorType.SELECT ? fields[0].placeholder || '' : '';

    if( type === InlineEditorType.SELECT && getStringForNewValue )
      selectText = getStringForNewValue((fields[0].value || '').toString());

    return (
      <>
        {
          icon &&
          <Icon
            className={cx(styles.fieldIcon, iconClassName, { alignTop: textArea || type === InlineEditorType.MULTISELECT })}
            icon={icon}
          />
        }
        {
          svgIcon &&
          <SvgIcon
            className={cx(styles.fieldIcon, iconClassName, { alignTop: textArea || type === InlineEditorType.MULTISELECT })}
            icon={svgIcon}
          />
        }
        {/*{*/}
        {/*  textBefore &&*/}
        {/*  <div className={cx(styles.textBefore, textBeforeClassName)}>*/}
        {/*    {textBefore}*/}
        {/*  </div>*/}
        {/*}*/}
        <div className={cx(styles.view, viewClassName, 'test-editor-view')}>

          {
            (type !== InlineEditorType.MULTISELECT) &&
            <div
              className={cx(styles.text, textClassName, { textArea, wrap, isChanged }, 'test-text-area')}
              tabIndex={!readonly && (type === InlineEditorType.DATE || type === InlineEditorType.TIME)
                ? 0
                : undefined}
              onKeyDown={type === InlineEditorType.DATE || type === InlineEditorType.TIME
                ? (event):void =>
                {
                  if( event.key == 'Enter' )
                    this._enterEditMode();
                }
                : undefined}
            >
              {
                (viewText || textStr || selectText) && !viewAsLink &&
                <span>
                  {viewText || textStr || selectText}
                </span>
              }
              {
                (textStr && viewAsLink) &&
                <a
                  className={styles.link}
                  href={textStr}
                  target={'_blank'}
                  rel={'noreferrer'}
                  onMouseDown={(e):void =>
                  {
                    e.stopPropagation();
                  }}
                >
                  {textStr}
                </a>
              }
              {
                !(viewText || textStr || selectText) &&
                <span className={styles.emptyText}>
                {fields.map(v => String(v.placeholder)).join(' ') || selectPlaceholder}
              </span>
              }
              {
                additionalFields.map(v =>
                {
                  return (
                    <span key={v.placeholder} className={styles.additionalFieldLabel}>
                          <span className={styles.labelDot}>•</span>{String(v.placeholder)}</span>
                  );
                })
              }
            </div>
          }

          {
            type === InlineEditorType.MULTISELECT &&
            <InlineEditorMultiSelect.View {...this.props} />
          }

          {
            (type === InlineEditorType.SELECT && noButtons) && // TODO
            <Icon
              className={styles.selectRightIcon}
              icon={IconNames.CARET_DOWN}
            />
          }

          {
            !noButtons && !readonly &&
            <Button
              className={cx(styles.btnEdit, { alignTop: textArea || type === InlineEditorType.MULTISELECT })}
              icon={IconNames.EDIT}
              iconClassName={styles.btnEditIcon}
              minimal={true}
              onClick={readonly ? undefined : this._enterEditMode}
              onMouseUp={(e):void => e.stopPropagation() as void}
            />
          }
        </div>
      </>
    );
  }

  private _renderEditMode():React.ReactNode
  {
    const { type = InlineEditorType.INPUT, editClassName, noButtons } = this.props;

    return (
      <>
        <div className={cx(styles.edit, editClassName)}>

          {type === InlineEditorType.INPUT && this._renderInputEditor()}
          {type === InlineEditorType.SELECT && this._renderSelectEditor()}
          {type === InlineEditorType.MULTISELECT && this._renderMultiselectEditor()}
          {type === InlineEditorType.DATE && this._renderDateEditor()}
          {type === InlineEditorType.TIME && this._renderTimeEditor()}

          {
            !noButtons &&
            <div className={styles.editButtons}>

              <Button
                className={styles.btnCancel}
                text={'Cancel'}
                minimal={true}
                onClick={this._exitEditMode}
                onBlur={this._onBlurSomeInputOrButton}
                onFocus={this._onFocusSomeInputOrButton}
              />

              <Button
                className={styles.btnApply}
                text={'Apply'}
                minimal={true}
                onClick={this._applyChanges}
                onBlur={this._onBlurSomeInputOrButton}
                onFocus={this._onFocusSomeInputOrButton}
              />

            </div>
          }

        </div>
      </>
    );
  }

  private _renderInputEditor():React.ReactNode
  {
    const { newValues } = this.state;
    const { fields, textArea, inputClassName, icon, svgIcon } = this.props;

    return (
      <div className={styles.inputs}>
        {
          fields.map((value:IInlineEditorField, index:number) =>
          {
            const { name, label, placeholder } = value;

            return (
              <div
                key={name}
                className={styles.inputWrapper}
              >
                {
                  label &&
                  <label htmlFor={name} className={styles.inputLabel}>
                    {label}
                  </label>
                }
                {
                  textArea &&
                  <TextArea
                    id={name}
                    className={cx(styles.input, inputClassName)}
                    value={newValues[name].value as string}
                    leftElement={icon ? <Icon icon={icon} /> : svgIcon ?
                      <SvgIcon icon={svgIcon} className={'bp4-icon'} /> : undefined}
                    autoFocus={index === 0}
                    placeholder={placeholder}
                    onChange={this._onChangeInput(name)}
                    onBlur={this._onBlurSomeInputOrButton}
                    onFocus={this._onFocusSomeInputOrButton}
                    onKeyDown={this._onInputKeyDown}
                    growVertically
                    rows={1}
                  />
                }
                {
                  !textArea &&
                  <TextInput
                    id={name}
                    className={cx(styles.input, inputClassName)}
                    value={newValues[name].value as string}
                    // leftElement={icon ? <Icon icon={icon} className={'bp4-icon'}></Icon> : undefined}
                    autoFocus={index === 0}
                    placeholder={placeholder}
                    onChange={this._onChangeInput(name)}
                    onBlur={this._onBlurSomeInputOrButton}
                    onFocus={this._onFocusSomeInputOrButton}
                    onKeyDown={this._onInputKeyDown}
                  />
                }
              </div>
            );
          })
        }
      </div>
    );
  }

  private _renderSelectEditor():React.ReactNode
  {
    const { isSelectPopoverOpened, newValues } = this.state;
    const { fields, applyWhenSelect, text, getStringForNewValue } = this.props;

    const textStr:string = text.trim();
    let selectText:string = textStr;

    if( getStringForNewValue )
      selectText = getStringForNewValue((newValues[fields[0].name]?.value || '').toString());

    const selectPlaceholder:string = fields[0].placeholder || '';

    return (
      <InlineEditorSelect
        {...this.props}
        onOpening={this._onFocusSomeInputOrButton}
        onClosed={applyWhenSelect ? undefined : this._onBlurSomeInputOrButton}
        onClose={():void =>
        {
          setTimeout(() =>
          {
            if( !this.state.isInputChanged ) this._exitEditMode();

            this.setState({ isSelectPopoverOpened: false });
          }, 10);
        }}
        isOpen={isSelectPopoverOpened}
        onChangeInput={this._onChangeInput}
      >
        <Button
          className={cx(styles.btnSelect, { isPlaceholder: !selectText })}
          text={selectText || selectPlaceholder}
          rightIcon={IconNames.CARET_DOWN}
          alignText={Alignment.LEFT}
          outlined={true}
          autoFocus={true}
          onBlur={this._onBlurSomeInputOrButton}
          onFocus={this._onFocusSomeInputOrButton}
          onClick={():void =>
          {
            if( !this.state.isLoading )
            {
              this.setState({ isSelectPopoverOpened: true });
            }
          }}
          onKeyDown={this._onInputKeyDown}
        />
      </InlineEditorSelect>
    );
  }

  @bind
  private _renderMultiselectEditor():React.ReactNode
  {
    const { newValues, isSelectPopoverOpened, isInputChanged } = this.state;

    return (
      <InlineEditorMultiSelect.Edit
        {...this.props}
        newValues={newValues}
        isInputChanged={isInputChanged}
        onChangeMultiSelect={this._onChangeMultiSelect}

        onBlur={this._onBlurSomeInputOrButton}
        onFocus={this._onFocusSomeInputOrButton}
        isOpen={isSelectPopoverOpened}
      />
    );
  }

  @bind
  private _renderDateEditor():React.ReactNode
  {
    const { newValues } = this.state;
    const { fields, minDate, popoverProps } = this.props;

    if( fields.length !== 1 )
      throw new Error('InlineEditor of DATE type must have exactly one field');

    const { name, label, placeholder } = fields[0];

    return (
      <div
        className={styles.inputWrapper}
      >
        {
          label &&
          <label htmlFor={name} className={styles.inputLabel}>
            {label}
          </label>
        }
        <DateInput
          className={styles.input}
          highlightCurrentDay
          minDate={minDate}
          todayButtonText={'Today'}
          dayPickerProps={{
            labels: {
              nextMonth: 'Next',
              previousMonth: 'Prev'
            },
            pagedNavigation: true,
            firstDayOfWeek: 1,
            fixedWeeks: true
          }}
          value={newValues[name].value as Date}
          placeholder={placeholder}
          onChange={this._onChangeInput(String(name))}
          parseDate={(dateStr):Date => moment(dateStr).toDate() as Date}
          formatDate={(date):string => String(moment(date).format('MMMM DD, YYYY'))}
          popoverProps={{
            ...popoverProps,
            onClose: this._exitEditMode
          }}
          inputProps={{
            autoFocus: true,
            readOnly: true
          }}
        />
      </div>
    );
  }

  private _renderTimeEditor():React.ReactNode
  {
    const {
      text,
      fields,
      getStringForNewValue,
      itemListPredicate,
      query,
      menuClassName,
      inputClassName,
      onKeyDown
    } = this.props;
    const { newValues, isSelectPopoverOpened } = this.state;

    const { name, label, placeholder, values } = fields[0];

    const textStr:string = text.trim();
    let selectText:string = textStr;

    if( getStringForNewValue )
      selectText = getStringForNewValue((newValues[fields[0].name]?.value || '').toString());

    return (
      <div
        className={styles.inputWrapper}
      >
        {
          label &&
          <label htmlFor={name} className={styles.inputLabel}>
            {label}
          </label>
        }

        <InlineEditorSuggest
          {...this.props}
          isOpen={isSelectPopoverOpened}
          onChangeInput={this._onChangeInput}
          query={query}
          onBlur={this._exitEditMode}
          selectedItem={values?.filter(value => !!value.isActive)[0]}
          menuClassName={menuClassName}
          inputClassName={inputClassName}
          itemListPredicate={itemListPredicate}
          onKeyDown={onKeyDown}
        >
          <Button
            className={cx(styles.btnSelect, { isPlaceholder: !selectText })}
            text={selectText || placeholder}
            alignText={Alignment.LEFT}
            outlined={true}
            onClick={():void =>
            {
              if( !this.state.isLoading )
              {
                this.setState({ isSelectPopoverOpened: true });
              }
            }}
          />
        </InlineEditorSuggest>
      </div>
    );
  }

  public componentDidMount():void
  {
    if( this.props.isEditMode )
    {
      this.setEditMode();
    }
  }

  public render():React.ReactNode
  {
    const { isEditMode } = this.state;
    const {
      type = InlineEditorType.INPUT,
      wrapperClassName,
      noButtons,
      readonly,
      textArea,
      icon,
      svgIcon
    } = this.props;

    return (
      <div
        className={cx(styles.field, wrapperClassName, {
          isViewMode: !isEditMode,
          isReadOnly: readonly,
          noButtons: noButtons && type == InlineEditorType.INPUT,
          // TODO: Enable the below for all editor types
          isEditWithIcon: isEditMode && textArea && (icon || svgIcon)
        })}
        onMouseDown={this._onMouseDown}
        onMouseUp={this._onMouseUp}
      >

        {
          !isEditMode &&
          this._renderViewMode()
        }

        {
          isEditMode &&
          this._renderEditMode()
        }

      </div>
    );
  }

}
