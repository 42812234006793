import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { component } from '@flow/dependency-injection';

import classNames from 'classnames/bind';
import React from 'react';
import styles from './Dialog.module.less';

const cx = classNames.bind(styles);

interface Props
{
  text?:string;
}

@component
export class DialogWarningMessage extends React.Component<Props>
{
  public render():React.ReactNode
  {
    const { text } = this.props;

    return (
      <div className={styles.warningMessage}>
        <Icon
          className={styles.iconInfo}
          icon={IconNames.INFO_SIGN}
        />
        {text}
      </div>
    );
  }
}

