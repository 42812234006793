import { PositionsUtil } from '@flow/common/utils/PositionsUtil';
import type { Staffing_Customer_Team_Slot } from '@flow/data-access/lib/types/graphql.generated';
import { Staffing_Customer_Team_Slot_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';

export class SlotUtil
{
  public static getStaffedSlots(slots:Array<Staffing_Customer_Team_Slot>):Array<Staffing_Customer_Team_Slot>
  {
    return slots.filter(slot =>
    {
      return slot.staff
        || slot.status == Staffing_Customer_Team_Slot_Status_Enum.CurrentStaff
        || slot.next_staff_id
        || slot.next_candidate_id;
    });
  }

  public static isSlotVisible(slot:Staffing_Customer_Team_Slot):boolean
  {
    const { position } = slot;

    if( !position )
      return false;

    const customerAlias = slot.position.customer_team?.customer.alias || null;
    const teamName = slot.position.customer_team?.name || null;
    const positionName:string = PositionsUtil.getPositionName(slot.position);

    return customerAlias != null && teamName != null && positionName !== '';
  }
}

