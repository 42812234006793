import { CommonController } from '@flow/common/CommonController';
import { MessagePage } from '@flow/common/components/page/MessagePage';
import { component, di } from '@flow/dependency-injection';
import React from 'react';

@component
export class PageNotFound extends React.Component
{
  @di private _commonController!:CommonController;

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    return (
      <MessagePage
        imgUrl={'assets/images/pageNotFound/pageNotFound.svg'}
        title={'Page not found'}
      />
    );
  }

  // ----------------------------------------------------
}
