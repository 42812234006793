import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';

import classNames from 'classnames/bind';
import { action, observable, runInAction } from 'mobx';
import * as React from 'react';
import styles from './ListWrapper.module.less';

const cx = classNames.bind(styles);

interface Props
{
  className?:string;

  header?:React.ReactNode;
  headerClassName?:string;

  content?:React.ReactNode;
  contentClassName?:string;

  onScroll?:React.UIEventHandler<HTMLDivElement>;
  scrollPosition?:number;
}

@component
export class ListWrapper extends React.Component<Props>
{
  private _scrollableYref:HTMLDivElement | null = null;
  private _resizeObserver:ResizeObserver | null = null;

  @observable
  private _scrollBarWidth:number = 0;

  // ----------------------------------------------------

  public componentDidMount():void
  {
    this._checkScrollRefsAndResizeObserver();
    this._applyScrollPosition();
  }

  public componentDidUpdate():void
  {
    this._checkScrollRefsAndResizeObserver();
    this._applyScrollPosition();
  }

  @bind
  private _checkScrollRefsAndResizeObserver():void
  {
    if( !this._scrollableYref || this._resizeObserver != null ) return;

    this._resizeObserver = new ResizeObserver(() =>
    {
      runInAction(this._setWidth);
    });

    this._resizeObserver.observe(this._scrollableYref as Element);
  }

  @action.bound
  private _setWidth():void
  {
    if( !this._scrollableYref ) return;

    this._scrollBarWidth = this._scrollableYref.offsetWidth - this._scrollableYref.clientWidth;
  }

  private _applyScrollPosition():void
  {
    if( this._scrollableYref && typeof (this.props.scrollPosition) != 'undefined' )
      this._scrollableYref.scrollTop = Number(this.props.scrollPosition);
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const {
      className,
      header, headerClassName,
      content, contentClassName,
      onScroll
    } = this.props;

    return (
      <div className={cx(styles.wrapper, className)}>
        {
          header &&
          <div
            className={cx(styles.header, headerClassName)}
            style={{ paddingRight: this._scrollBarWidth }}
          >
            {header}
          </div>
        }
        {
          content &&
          <div
            className={cx(styles.content, contentClassName, 'test-list-content')}
            ref={(ref:HTMLDivElement):void =>
            {
              if( !ref ) return;
              this._scrollableYref = ref;
            }}
            onScroll={onScroll}
          >
            {content}
          </div>
        }
      </div>
    );
  }

  // ----------------------------------------------------
}
