import type * as Types from './types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetRoleDisplayNameQueryVariables = Types.Exact<{
  name?:Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetRoleDisplayNameQuery = { common_user_role:Array<{ display_name?:string | null; }>; };

export type GetUserAsStaffQueryVariables = Types.Exact<{
  userId:Types.Scalars['Int'];
}>;


export type GetUserAsStaffQuery = { staffing_staff:Array<{ id:number; user:{ id:number; }; }>; };


export const GetRoleDisplayNameDocument = gql`
    query GetRoleDisplayName($name: String) {
  common_user_role(where: {name: {_eq: $name}}) {
    display_name
  }
}
    `;
export type GetRoleDisplayNameQueryResult = Apollo.QueryResult<GetRoleDisplayNameQuery, GetRoleDisplayNameQueryVariables>;
export const GetUserAsStaffDocument = gql`
    query GetUserAsStaff($userId: Int!) {
  staffing_staff(where: {user_id: {_eq: $userId}}) {
    id
    user {
      id
    }
  }
}
    `;
export type GetUserAsStaffQueryResult = Apollo.QueryResult<GetUserAsStaffQuery, GetUserAsStaffQueryVariables>;
export const namedOperations = {
  Query: {
    GetRoleDisplayName: 'GetRoleDisplayName',
    GetUserAsStaff: 'GetUserAsStaff'
  }
}