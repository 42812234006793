import type { TabId } from '@blueprintjs/core';
import { Tab, Tabs } from '@blueprintjs/core';
import { CandidateTabName } from '@flow/modules/recruiting/candidates/CandidateState';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './CommunicationHistoryTabs.module.less';

const cx = classNames.bind(styles);

interface Props
{
  communicationTabId:TabId;
  onChange:(id:string) => void;
}

export class CommunicationHistoryTabs extends React.PureComponent<Props>
{
  // ----------------------------------------------------

  @bind
  private _renderTab(title:string, id:CandidateTabName):React.ReactNode
  {
    const { communicationTabId } = this.props;

    return (
      <Tab
        className={cx(styles.historyTab, { active: communicationTabId === id })}
        id={id}
        title={title}
      />
    );
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { communicationTabId, onChange } = this.props;

    return (
      <Tabs
        className={styles.tabs}
        selectedTabId={communicationTabId}
        onChange={onChange}
      >
        {this._renderTab('All', CandidateTabName.HISTORY)}
        {this._renderTab('Notes', CandidateTabName.NOTES)}
        {this._renderTab('Calls', CandidateTabName.CALLS)}
        {this._renderTab('Timeline', CandidateTabName.TIMELINE)}
        {/*<Tabs.Expander />*/}
      </Tabs>
    );
  }

  // ----------------------------------------------------
}
