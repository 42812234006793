import { MenuItem } from '@blueprintjs/core';
import type { IItemRendererProps } from '@blueprintjs/select';
import { MultiSelect } from '@blueprintjs/select';
import type {
  IInlineEditorField,
  IInlineEditorMultiSelectValue,
  IInlineEditorProps
} from '@flow/common/components/form/InlineEditor';
import { InlineEditorType } from '@flow/common/components/form/InlineEditor';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import * as React from 'react';

import styles from './InlineEditor.module.less';

const cx = classNames.bind(styles);

// ---------------------------------------------------------

class View extends React.PureComponent<IInlineEditorProps>
{
  public render():React.ReactNode
  {
    const { type, textClassName, isChanged } = this.props;

    // console.log('%c --- InlineEditorMultiSelectView type =', 'background:#0f0;color:#000;', type);

    if( type !== InlineEditorType.MULTISELECT ) return null;

    const { fields, isPills = true, textBefore, textBeforeClassName } = this.props;
    const currentField:IInlineEditorField = fields[0];
    const { value: currentValue, values } = currentField;

    const allValues:Array<IInlineEditorMultiSelectValue> = values || [];

    if( !isPills )
    {
      const valuesString:string = (currentValue as Array<number>)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        .map(currentValue => allValues.find(value => currentValue === value.value))
        .map((value:IInlineEditorMultiSelectValue | undefined) => String(value?.title))
        .join(', ');

      return (
        <div className={cx(textClassName, styles.multiSelectView, { withTextBefore: !!textBefore, isChanged })}>
          <div className={styles.multiSelectViewText}>
            {
              textBefore &&
              <span className={cx(styles.textBefore, textBeforeClassName)}>
                {textBefore}&nbsp;
              </span>
            }
            {valuesString}
          </div>
        </div>
      );
    }

    return (
      <div className={styles.multiSelectView}>

        {((currentValue || []) as Array<number>).map((currentValueItem:number) =>
        {
          const item = (values || []).find((valueItem) => valueItem.value === currentValueItem);

          if( !item ) return null;

          return (
            <div
              key={String(item.value)}
              className={cx(styles.miltiSelectViewItem, { isPills })}
            >
              {item.title}
            </div>
          );
        })}

      </div>
    );
  }
}

// ---------------------------------------------------------

class Edit extends React.PureComponent<IInlineEditorProps>
{
  // ---------------------------------------------------------

  @bind
  private _itemRenderer(item:IInlineEditorMultiSelectValue, {
    handleClick,
    modifiers
  }:IItemRendererProps):JSX.Element | null
  {
    if( !modifiers.matchesPredicate )
    {
      return null;
    }

    return (
      <MenuItem
        key={String(item.value)}
        text={item.element || item.title}
        onClick={handleClick}
        selected={modifiers.active}
      />
    );
  }

  @bind
  private _tagRenderer(item:IInlineEditorMultiSelectValue):JSX.Element | null
  {
    if( !item )
      return null;

    return (
      <span>
        {item.title}
      </span>
    );
  }

  @bind
  private _onItemSelect(item:IInlineEditorMultiSelectValue):void
  {
    console.log('%c _onItemSelect item =', 'background:#0f0;color:#000;', item);

    const {
      fields, onChangeMultiSelect,
      newValues, isInputChanged
    } = this.props;
    const currentField:IInlineEditorField = fields[0];
    const { name, value: selectedIds } = currentField;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const initialOrChangedSelectedIds:any = isInputChanged
      ? (newValues?.[name].value) || []
      : selectedIds;

    console.log('%c currentField =', 'background:#0f0;color:#000;', currentField);

    const newValuesData:Record<string, IInlineEditorField> = {};
    newValuesData[name] = {
      name: name,
      value: [
        ...initialOrChangedSelectedIds,
        item.value
      ]
    };

    console.log('%c newValues =', 'background:#0f0;color:#000;', newValues);
    // onChangeInput?.(name)(item.value as string);
    onChangeMultiSelect?.(name)(newValuesData[name].value as Array<number>);
  }

  @bind
  private _onItemRemove(item:IInlineEditorMultiSelectValue):void
  {
    console.log('%c _onItemRemove item =', 'background:#0f0;color:#000;', item);

    const {
      fields, onChangeMultiSelect,
      newValues, isInputChanged
    } = this.props;
    const currentField:IInlineEditorField = fields[0];
    const { name, value: selectedIds } = currentField;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const initialOrChangedSelectedIds:any = isInputChanged
      ? (newValues?.[name].value) || []
      : selectedIds;

    const newValuesData:Record<string, IInlineEditorField> = {};
    newValuesData[name] = {
      name: name,
      value: [
        ...((initialOrChangedSelectedIds as Array<number>).filter(v => v !== item.value))
      ]
    };

    console.log('%c newValues =', 'background:#0f0;color:#000;', newValues);
    // onChangeInput?.(name)(item.value as string);
    onChangeMultiSelect?.(name)(newValuesData[name].value as Array<number>);
  }

  @bind
  private _itemPredicate(query:string, item:IInlineEditorMultiSelectValue):boolean
  {
    // console.log('%c _itemPredicate query, item =', 'background:#0f0;color:#000;', query, item);
    if( this.props.itemPredicate && !this.props.itemPredicate(query, item, this.props.newValues) )
      return false;

    return item.title?.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  }

  // @bind
  // private _onQueryChange(query:string, event?:React.ChangeEvent<HTMLInputElement>):void
  // {
  //   console.log('%c _onQueryChange query =', 'background:#0f0;color:#000;', query);
  // }

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const {
      type, isInputChanged, newValues,
      isOpen, tagClassName, onClosed, onOpening,
      onBlur, onFocus, popoverProps
    } = this.props;

    // console.log('%c --- InlineEditorMultiSelectView type =', 'background:#0f0;color:#000;', type);

    if( type !== InlineEditorType.MULTISELECT ) return null;

    const { fields, textBefore, textBeforeClassName } = this.props;
    const currentField:IInlineEditorField = fields[0];
    const { value: selectedIds, values, name } = currentField;

    const Dropdown = MultiSelect.ofType<IInlineEditorMultiSelectValue>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const initialOrChangedSelectedIds:any = isInputChanged
      ? (newValues?.[name].value) || []
      : selectedIds;

    console.log('%c !!! inputOrChangedValues =', 'background:#0f0;color:#000;', initialOrChangedSelectedIds);

    const viewedItems:Array<IInlineEditorMultiSelectValue> = (values || [])
      .filter((item:IInlineEditorMultiSelectValue) =>
      {
        return !((initialOrChangedSelectedIds || []) as Array<number>).includes(item.value as number);
      });

    const selectedItems:Array<IInlineEditorMultiSelectValue> = (initialOrChangedSelectedIds || [])
      .map((selectedId:number) =>
      {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return (values || []).find((item:IInlineEditorMultiSelectValue) => selectedId === item.value);
      });

    return (
      <div className={styles.multiSelectEditWrapper}>
        {
          textBefore &&
          <span className={cx(styles.textBefore, textBeforeClassName)}>
            {textBefore}&nbsp;
          </span>
        }
        <Dropdown
          className={cx(styles.multiSelectEdit)}
          items={viewedItems}
          selectedItems={selectedItems}

          tagRenderer={this._tagRenderer}
          itemRenderer={this._itemRenderer}
          onItemSelect={this._onItemSelect}
          onRemove={this._onItemRemove}

          itemPredicate={this._itemPredicate}
          // onQueryChange={this._onQueryChange}
          resetOnSelect={true}

          placeholder={''}
          fill={true}

          noResults={<MenuItem disabled={true} text="No results" />}

          popoverProps={{
            minimal: true,
            // position: Position.BOTTOM_RIGHT,
            popoverClassName: cx(styles.multiSelectEditPopover, styles.selectPopover),
            usePortal: false,
            shouldReturnFocusOnClose: true,
            isOpen,
            // onClose: onClose,
            onClosed: onClosed,
            onOpening: onOpening,
            ...popoverProps
          }}
          tagInputProps={{
            tagProps: { minimal: true, className: tagClassName },
            inputProps: {
              autoFocus: true,
              onBlur,
              onFocus
            }
          }}
        />
      </div>
    );
  }
}

// ---------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InlineEditorMultiSelect:Record<string, any> = { View, Edit };

