import { CommonController } from '@flow/common/CommonController';
import { Button, ButtonSize } from '@flow/common/components/form/Button';
import { Icon, IconNames } from '@flow/common/components/form/Icon';
import { Intent } from '@flow/common/components/types/Types';
import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import { DateUtil } from '@flow/common/utils/DateUtil';
import { Recruiting_Candidate_Status_Enum } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { CandidateFlowController } from '@flow/modules/recruiting/candidates/CandidateFlowController';
import { CandidateFlowState } from '@flow/modules/recruiting/candidates/CandidateFlowState';
import { CandidateState } from '@flow/modules/recruiting/candidates/CandidateState';
import classNames from 'classnames/bind';
import type { ReactNode } from 'react';
import React from 'react';

import styles from './Archived.module.less';

const cx = classNames.bind(styles);

@component
export class Archived extends React.Component
{
  @di private _roleController!:RoleController;
  @di private _commonController!:CommonController;

  @di private _candidateState!:CandidateState;
  @di private _candidateFlowState!:CandidateFlowState;
  @di private _candidateFlowController!:CandidateFlowController;

  // ---------------------------------------------------------

  public render():ReactNode | null
  {
    const {
      isCandidateInArchivedStatus, // = staffed, declined, refused, archived
      isCandidateInDeclinedStatus,
      isCandidateInRefusedStatus,
      isCandidateInStaffedStatus
    } = this._candidateFlowState;

    const { candidate } = this._candidateState;

    if( !isCandidateInArchivedStatus || !candidate ) return null;

    // const isDisabled = true;
    const isDisabled:boolean = !this._roleController.hasPermission(FlowPermissions.ChangeCandidateFlowStage);

    const statusDate:string = candidate.status_date;

    return (
      <div className={styles.wrapper}>

        <div className={styles.staffedText}>
          <Icon
            className={styles.iconClock}
            icon={isCandidateInStaffedStatus ? IconNames.TIME : IconNames.PROJECTS}
          />
          {isCandidateInStaffedStatus && 'Staffed'}
          {candidate.status === Recruiting_Candidate_Status_Enum.Archived && 'Archived'}
          {isCandidateInRefusedStatus && 'Refused'}
          {isCandidateInDeclinedStatus && 'Declined'}
          {
            statusDate &&
            <span>&nbsp;{DateUtil.formatDay(statusDate)}</span>
          }
        </div>
        {
          isCandidateInStaffedStatus &&
          <Button
            className={cx(styles.btn, { isDisabled })}
            text={'View staff profile'}
            disabled={isDisabled}
            minimal={true}
            outlined={true}
            intent={Intent.PRIMARY}
            size={ButtonSize.LARGE}
            onClick={():void =>
            {
              this._commonController.goToStaffMember(candidate.staff_id);
            }}
          />
        }
        {
          !isCandidateInStaffedStatus &&
          <Button
            className={cx(styles.btn, { isDisabled })}
            text={'Move to Initial'}
            disabled={isDisabled}
            minimal={true}
            outlined={true}
            intent={Intent.PRIMARY}
            size={ButtonSize.LARGE}
            onClick={():void =>
            {
              this._candidateFlowController.showToInitialDialog(true);
            }}
          />
        }

      </div>
    );
  }

  // ---------------------------------------------------------
}
