import { Menu, MenuItem } from '@blueprintjs/core';
import { component, di } from '@flow/dependency-injection';
import type { ReactNode } from 'react';
import React from 'react';
import { CustomerController } from '../../CustomerController';

@component
export class CustomersToolbarMenu extends React.Component
{
  @di private _customerController!:CustomerController;

  public render():ReactNode
  {
    return (
      <Menu>
        <MenuItem
          text="New Customer"
          onClick={this._customerController.showCreateCustomerDialog}
        />
      </Menu>
    );
  }
}
