import { RoleController } from '@flow/common/controllers/RoleController';
import { FlowPermissions } from '@flow/common/models/FlowPermissions';
import type { Recruiting_Candidate } from '@flow/data-access/lib/types/graphql.generated';
import { component, di } from '@flow/dependency-injection';
import { CandidateCardContent } from '@flow/modules/recruiting/status/components/CandidateCardContent';
import { InterviewFlowsController } from '@flow/modules/recruiting/status/InterviewFlowsController';
import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import type { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';

export interface ICandidateCardProps
{
  candidate:Recruiting_Candidate;
  index:number;
  interviewFlowGroupId:string;
}

@component
export class CandidateCard extends React.Component<ICandidateCardProps>
{
  @di private readonly _roleController!:RoleController;
  @di private readonly _interviewFlowsController!:InterviewFlowsController;

  // ----------------------------------------------------

  public render():ReactNode
  {
    const { candidate, index, interviewFlowGroupId } = this.props;

    if( !candidate )
      return null;

    // const isLastStage:boolean = this._interviewFlowsController.isLastStage(candidate.interview_flow_stage_id);

    const isDragDisabled = !this._roleController.hasPermission(FlowPermissions.ChangeCandidateFlowStage);

    return (
      <Draggable
        key={candidate.id}
        draggableId={`${String(interviewFlowGroupId)}-${String(candidate.id)}`}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided:DraggableProvided, snapshot:DraggableStateSnapshot):ReactElement<HTMLElement> => (
          <CandidateCardContent
            candidate={candidate}
            index={index}
            interviewFlowGroupId={interviewFlowGroupId}
            provided={provided}
            snapshot={snapshot}
          />
        )}
      </Draggable>
    );
  }

  // ----------------------------------------------------
}
