import * as querystring from 'query-string';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyObject = Record<string, any>

export class WindowLocationUtil
{
  public static getHostname():string
  {
    return window.location.hostname;
  }

  public static getPath():string
  {
    return window.location.pathname;
  }

  public static getOrigin():string
  {
    return window.location.origin;
  }

  public static fullRedirectTo(host:string, relativePath:string = ''):void
  {
    const location = window.location;
    let newLocation = `${location.protocol}//`;

    newLocation += `${host}${relativePath}`;

    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // Note: this line forces the browser to perform a full page refresh
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    window.location.href = newLocation;
  }

  public static getProtocol():string
  {
    return window.location.protocol;
  }

  public static getDomainName():string
  {
    return window.location.hostname;
  }

  public static getRootDomain():string
  {
    const hostnameParts = this._getHostnameParts();
    const numParts = hostnameParts.length;

    if( numParts >= 2 )
      // return `${hostnameParts[numParts-2]}.${hostnameParts[numParts-1]}`;
      return hostnameParts.slice(1).join('.');
    else
      return hostnameParts[0];
    // throw new Error(`Error: invalid hostname and TLD detected: ${window.location.href}`);
  }

  private static _getHostnameParts():Array<string>
  {
    const location = window.location;
    const hostname = location.hostname;
    const hostnameParts:Array<string> = hostname.split('.');

    return hostnameParts;
  }

  public static setDocumentDomain():void
  {
    const host = window.location.hostname.split('.');

    document.domain = `${host[host.length - 2]}.${host[host.length - 1]}`;
  }

  // ----------------------------------------------------

  public static setQueryString(objOrsStr:AnyObject | string):void
  {
    const url = new URL(window.location.href);

    if( typeof(objOrsStr) == 'object')
    {
      url.search = this.makeQueryStringFromObject(objOrsStr);
    }
    else if( typeof(objOrsStr) == 'string' )
    {
      url.search = objOrsStr;
    }
    else
    {
      throw new Error(`Cannot convert '${typeof(objOrsStr)}' to a query string`)
    }

    window.history.replaceState({ path: url.href }, '', url.href);
  }

  public static getObjectFromQueryString(str?:string):AnyObject
  {
    const query:string = str !== undefined ? str : window.location.search;

    return this.makeObjectFromQueryString(query);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static makeQueryStringFromObject(obj:AnyObject):string
  {
    return querystring.stringify(obj, { arrayFormat: 'bracket-separator' });
  }

  public static makeObjectFromQueryString(str:string):AnyObject
  {
    return querystring.parse(str, { arrayFormat: 'bracket-separator', parseNumbers: true });
  }

  // ----------------------------------------------------

  public static getHashVariable(key:string):string | null
  {
    const hashParams = new URLSearchParams(window.location.hash.slice(1));
    return hashParams.get(key);
  }

  public static setHashVariable(key:string, value:string):void
  {
    const hashParams = new URLSearchParams(window.location.hash.slice(1));
    hashParams.set(key, value);
    window.location.hash = hashParams.toString();
  }

  public static removeHashVariable(key:string):void
  {
    const hashParams = new URLSearchParams(window.location.hash.slice(1));
    hashParams.delete(key);

    if( hashParams.toString().length > 1 )
      window.location.hash = hashParams.toString();
    else
      window.location.hash = '';
  }
}
