import { CommonController } from '@flow/common/CommonController';
import { WindowLocationUtil } from '@flow/common/utils/WindowLocationUtil';
import type { IWithRouterProps } from '@flow/dependency-injection';
import { componentWithRouter, di } from '@flow/dependency-injection';
import { ConvertToStaffDialog } from '@flow/modules/recruiting/common/convertToStaff/components/ConvertToStaffDialog';
import type { ReactNode } from 'react';
import React from 'react';
import { InterviewFlowContent } from './components/InterviewFlowContent';
import { InterviewFlowHeader } from './components/toolbar/InterviewFlowHeader';
import styles from './InterviewFlow.module.less';
import { InterviewFlowsController } from './InterviewFlowsController';
import { InterviewFlowsState } from './InterviewFlowsState';

@componentWithRouter
export class InterviewFlow extends React.Component<IWithRouterProps>
{
  @di private _commonController!:CommonController;
  @di private _interviewFlowsState!:InterviewFlowsState;
  @di private _interviewFlowsController!:InterviewFlowsController;

  // ----------------------------------------------------

  public componentDidMount():void
  {
    console.log('%c InterviewFlow componentDidMount =', 'background:#00f;color:#ff0;');
    const { params } = this.props;

    if( !params || !params.interviewFlowId )
      return;

    const interviewFlowId:number = parseFloat(String(params.interviewFlowId));

    this._commonController.setIsContentScrollable(false);

    this._interviewFlowsController.initInterviewFlow(interviewFlowId);

    this._interviewFlowsController.initCandidateEvents();
  }

  // ----------------------------------------------------

  public componentDidUpdate(prevProps:IWithRouterProps):void
  {
    console.log('%c InterviewFlow componentDidUpdate =', 'background:#00f;color:#ff0;');
    const { params: prevParams } = prevProps;
    const { params } = this.props;

    if( !params || !params.interviewFlowId || params.interviewFlowId === prevParams?.interviewFlowId )
      return;

    const interviewFlowId:number = parseFloat(String(params.interviewFlowId));

    this._interviewFlowsController.initInterviewFlow(interviewFlowId);
  }

  public componentWillUnmount():void
  {
    this._commonController.setIsContentScrollable(true);
  }

  // ----------------------------------------------------

  public render():ReactNode
  {
    const {
      selectedCustomerId,
      selectedPositionGroupId,
      selectedRecruiterId: selectedUserId
    } = this._interviewFlowsState;

    const customerId = WindowLocationUtil.getHashVariable('customerId');
    const positionGroupId = WindowLocationUtil.getHashVariable('positionGroupId');
    const recruiterId = WindowLocationUtil.getHashVariable('recruiterId');
    const dateRange = WindowLocationUtil.getHashVariable('dateRange');

    if( customerId && Number(customerId) !== selectedCustomerId )
    {
      this._interviewFlowsController.selectInterviewFlowCustomer(Number(customerId));
    }

    if( positionGroupId && Number(positionGroupId) !== selectedPositionGroupId )
    {
      this._interviewFlowsController.selectInterviewFlowPositionGroup(Number(positionGroupId));
    }

    if( recruiterId && Number(recruiterId) !== selectedUserId )
    {
      this._interviewFlowsController.selectInterviewFlowRecruiter(Number(recruiterId));
    }

    return (
      <div className={styles.interviewFlow}>
        <InterviewFlowHeader />
        <InterviewFlowContent />
        <ConvertToStaffDialog />
      </div>
    );
  }

  // ----------------------------------------------------
}
