import { component } from '@flow/dependency-injection';
import bind from 'bind-decorator';
import classNames from 'classnames/bind';
import React from 'react';

import styles from './InfoPanel.module.less';

const cx = classNames.bind(styles);

interface Props
{
  headerTitle?:string;
  className?:string;
  panelId?:string;

  headerContent?:JSX.Element;
}

// TODO: ?
// @component
export class InfoPanel extends React.Component<Props>
{
  // ----------------------------------------------------

  private _panelRef:React.RefObject<HTMLDivElement> = React.createRef();

  // ----------------------------------------------------

  @bind
  public scrollTop():void
  {
    this._panelRef.current?.firstElementChild?.scrollIntoView({ behavior: 'auto', block: 'start' });
  }

  // ----------------------------------------------------

  public render():React.ReactNode
  {
    const { headerTitle, children, className, headerContent, panelId } = this.props;
    return (
      <div
        className={cx(styles.panel, className)}
        ref={this._panelRef}
        id={panelId}
      >
        {
          (headerTitle || headerContent) &&
          <div className={styles.panelHeader}>
            {
              headerTitle &&
              <div className={styles.panelHeaderTitle}>
                {headerTitle}
              </div>
            }
            {
              headerContent &&
              <div className={styles.panelHeaderContent}>
                {headerContent}
              </div>
            }
          </div>
        }

        <div className={styles.panelContent}>
          {children}
        </div>

      </div>
    );
  }

  // ----------------------------------------------------
}
