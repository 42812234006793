import type { ApolloQueryResult } from '@apollo/client';
import type { FetchResult } from '@apollo/client/link/core';
import { CommonController } from '@flow/common/CommonController';
import { CommonState } from '@flow/common/CommonState';
import { AuthState } from '@flow/common/controllers/AuthState';
import type {
  UpdateCandidateInterviewFlowStageMutation,
  UpdateCandidateInterviewFlowStageMutationVariables,
  UpdateCandidateOnFlowMutation,
  UpdateCandidateOnFlowMutationVariables
} from '@flow/data-access/lib/candidate.generated';
import {
  CancelCalendarEventDocument,
  CancelCalendarEventMutation,
  CancelCalendarEventMutationVariables,
  UpdateCandidateInterviewFlowStageDocument,
  UpdateCandidateOnFlowDocument
} from '@flow/data-access/lib/candidate.generated';
import type {
  GetInterviewFlowsQuery,
  GetInterviewFlowsQueryVariables,
  UpdateCandidateStatusToInitialOrArchiveMutation,
  UpdateCandidateStatusToInitialOrArchiveMutationVariables
} from '@flow/data-access/lib/interviewFlow.generated';
import {
  GetInterviewFlowsDocument,
  UpdateCandidateStatusToInitialOrArchiveDocument
} from '@flow/data-access/lib/interviewFlow.generated';
import type {
  Recruiting_Candidate_Allocation,
  Recruiting_Candidate_Status_Reason,
  Recruiting_Interview_Flow
} from '@flow/data-access/lib/types/graphql.generated';
import {
  Recruiting_Candidate_Scheduled_Event,
  Recruiting_Candidate_Status_Enum
} from '@flow/data-access/lib/types/graphql.generated';
import { controller, di } from '@flow/dependency-injection';
import { CandidateAllocationState } from '@flow/modules/recruiting/candidates/CandidateAllocationState';
import { CandidateController } from '@flow/modules/recruiting/candidates/CandidateController';
import { CandidateState } from '@flow/modules/recruiting/candidates/CandidateState';
import { ConvertToStaffController } from '@flow/modules/recruiting/common/convertToStaff/ConvertToStaffController';
import { action, runInAction, toJS } from 'mobx';
import type { FormEvent } from 'react';
import { CandidateFlowState } from './CandidateFlowState';

@controller
export class CandidateFlowController
{
  @di private _authState!:AuthState;
  @di private _commonState!:CommonState;
  @di private _candidateState!:CandidateState;
  @di private _candidateFlowState!:CandidateFlowState;
  @di private _candidateAllocationState!:CandidateAllocationState;

  @di private _commonController!:CommonController;
  @di private _candidateController!:CandidateController;

  @di private _convertToStaffController!:ConvertToStaffController;

  // ----------------------------------------------------

  @action.bound
  public async initFlows():Promise<void>
  {
    const result:ApolloQueryResult<GetInterviewFlowsQuery> =
      await this._commonController.query<GetInterviewFlowsQuery,
        GetInterviewFlowsQueryVariables>({
        query: GetInterviewFlowsDocument,
        variables: {}
      });

    console.log('%c initFlows result =', 'background:#f60;color:#000;', result);

    runInAction(() =>
    {
      const {
        recruiting_interview_flow,
        recruiting_candidate_status_reason
      } = result.data;

      this._candidateFlowState.flows = recruiting_interview_flow as Array<Recruiting_Interview_Flow>;
      this._candidateFlowState.statusReasons = recruiting_candidate_status_reason as Array<Recruiting_Candidate_Status_Reason>;
    });

    const { isCandidateInInitialStatus } = this._candidateFlowState;

    console.log('%c !!! isCandidateInInitialStatus =', 'background:#0f0;color:#000;', toJS(isCandidateInInitialStatus));

    if( isCandidateInInitialStatus )
    {
      this.selectCandidateFlow(this._candidateFlowState.flows?.[0].id || null);
    }
  }

  // ----------------------------------------------------

  @action.bound
  public async updateCandidateFlowStage(candidateId:number,
                                        candidateFlowStageId:number,
                                        isCandidateOnFlow:boolean,
                                        flowStageId:number):Promise<void>
  {
    if( flowStageId === -1 || !isCandidateOnFlow )
    {
      const result:FetchResult<UpdateCandidateOnFlowMutation> =
        await this._commonController.mutate<UpdateCandidateOnFlowMutation,
          UpdateCandidateOnFlowMutationVariables>({
          mutation: UpdateCandidateOnFlowDocument,
          variables: {
            candidateId,
            status: flowStageId !== -1 ? Recruiting_Candidate_Status_Enum.Active : Recruiting_Candidate_Status_Enum.Initial,
            interviewFlowStageId: flowStageId !== -1 ? flowStageId : null
          }
        });

      console.log('%c updateCandidateFlowStage result 1 =', 'background:#f60;color:#000;', result);
    }
    else
    {
      const result:FetchResult<UpdateCandidateInterviewFlowStageMutation> =
        await this._commonController.mutate<UpdateCandidateInterviewFlowStageMutation,
          UpdateCandidateInterviewFlowStageMutationVariables>({
          mutation: UpdateCandidateInterviewFlowStageDocument,
          variables: {
            candidateId,
            interviewFlowStageId: flowStageId
          }
        });

      console.log('%c updateCandidateFlowStage result 2 =', 'background:#f60;color:#000;', result);
    }
  }

  // ----------------------------------------------------

  @action.bound
  public async selectCandidateFlowStage(flowStageId:number):Promise<void>
  {
    const { candidate } = this._candidateState;
    const { candidateId, candidateFlowStageId, getFlowByStageId, flows } = this._candidateFlowState;

    if( candidateFlowStageId === flowStageId ) return;

    const flow:Recruiting_Interview_Flow | undefined = getFlowByStageId(flowStageId);

    if( flow && flowStageId === flow.stages[flow.stages.length - 1].id )
    {
      console.log('%c CONVERT to staff =', 'background:#f0f;color:#000;', toJS(flow));
      this._convertToStaffController.showConvertToStaffDialog(true, candidate, flows);
      return;
    }

    await this.updateCandidateFlowStage(
      candidateId,
      candidateFlowStageId,
      this._candidateFlowState.isCandidateOnFlow,
      flowStageId
    );
  }

  // ----------------------------------------------------

  @action.bound
  public selectCandidateFlow(flowId:number | null):void
  {
    console.log('%c selectCandidateFlowId =', 'background:#0f0;color:#000;', flowId);
    this._candidateFlowState.selectedFlowId = flowId;

    console.log('%c --- selectCandidateFlow =', 'background:#080;color:#000;', toJS(this._candidateFlowState.selectedFlow));
  }

  // ----------------------------------------------------

  @action.bound
  public showStartPipelineDialog(isShow:boolean):void
  {
    this._candidateFlowState.isShowStartPipelineDialog = isShow;
  }

  // ----------------------------------------------------

  @action.bound
  public startPipeline():void
  {
    const { selectedFlow } = this._candidateFlowState;
    console.log('%c startPipeline =', 'background:#0f0;color:#000;', toJS(selectedFlow));

    if( !selectedFlow ) return;

    this.selectCandidateFlowStage(selectedFlow.stages[0].id);
    this.showStartPipelineDialog(false);
  }

  // ----------------------------------------------------

  @action.bound
  public async moveCandidateToInitial():Promise<void>
  {
    const { candidate } = this._candidateState;
    console.log('%c moveToInitial =', 'background:#f0f;color:#000;', toJS(candidate));

    if( !candidate )
    {
      console.log('%c ERROR candidate =', 'background:#f00;color:#ff0;', toJS(candidate));
      return;
    }

    const { currentFinalSlot, finalizedAllocation } = this._candidateAllocationState;

    if( currentFinalSlot && finalizedAllocation )
    {
      console.log('%c currentFinalSlot =', 'background:#0f0;color:#000;', toJS(currentFinalSlot));
      console.log('%c finalizedAllocation =', 'background:#0f0;color:#000;', toJS(finalizedAllocation));
    }
    else if( (currentFinalSlot && !finalizedAllocation) || (!currentFinalSlot && finalizedAllocation) )
    {
      currentFinalSlot && !finalizedAllocation && console.log('%c ERROR finalizedAllocation =', 'background:#f00;color:#ff0;', toJS(finalizedAllocation));
      !currentFinalSlot && finalizedAllocation && console.log('%c ERROR currentFinalSlot =', 'background:#f00;color:#ff0;', toJS(currentFinalSlot));
    }

    // (x) remove proposed positions -> NO!

    // (v) remove calls ->

    // (v) remove finalized position
    //     -> update slot
    //        -> next_candidate_id = null,
    //        -> next_candidate_start_date = null
    //        -> next_candidate_allocation_id = null

    // (v) update candidate
    //     -> status = initial
    //     -> status_date = 'now()'
    //     -> status_reason_id = null
    //     -> status_reason_notes = null
    //     -> interview_flow_stage_id = null

    // (v) candidate_allocation
    //     -> delete is_final

    // (X) allocation_position -> NO

    // delete is_final
    const allocationIds:Array<number> = candidate.candidate_allocations
      .filter((al:Recruiting_Candidate_Allocation) => al.is_final)
      .map((al:Recruiting_Candidate_Allocation) => al.id);

    const eventIds:Array<number> = candidate.scheduled_calls
      .map((event:Recruiting_Candidate_Scheduled_Event) => event.scheduled_call.id);

    console.log('%c --- allocationIds =', 'background:#080;color:#000;', toJS(allocationIds));
    console.log('%c --- eventIds      =', 'background:#080;color:#000;', toJS(eventIds));

    await this.CancelAllCandidateCalendarEvents(candidate.scheduled_calls);

    const result:FetchResult<UpdateCandidateStatusToInitialOrArchiveMutation> =
      await this._commonController.mutate<UpdateCandidateStatusToInitialOrArchiveMutation,
        UpdateCandidateStatusToInitialOrArchiveMutationVariables>({
        mutation: UpdateCandidateStatusToInitialOrArchiveDocument,
        variables: {
          candidateId: candidate.id,
          status: Recruiting_Candidate_Status_Enum.Initial,
          statusReasonId: null,
          statusReasonNotes: null,
          interviewFlowStageId: null,
          allocationIds,
          eventIds
        }
      });

    console.log('%c moveCandidateToInitial result =', 'background:#f60;color:#000;', result);

    this.showToInitialDialog(false);

    if( result.errors )
    {
      console.log('%c ERROR moveCandidateToInitial =', 'background:#f00;color:#ff0;', toJS(result.errors));
      throw new Error(result.errors[0].message);
    }

    this._candidateController.initCandidate().then(() =>
    {
      this.initFlows();
    });
  }

  // ----------------------------------------------------

  @action.bound
  public async CancelAllCandidateCalendarEvents(events:Array<Recruiting_Candidate_Scheduled_Event>):Promise<void>
  {
    console.log('%c CancelAllCandidateCalendarEvents =', 'background:#0f0;color:#000;', toJS(events));

    await Promise.all(events.map(async (event:Recruiting_Candidate_Scheduled_Event) =>
    {
      if( event.scheduled_call.user_id != this._authState.user?.id )
      {
        console.log('%c --> ERROR event =', 'background:#f00;color:#ff0;', '(x) wrong user_id', event.scheduled_call.id, toJS(event));
        return;
      }

      if( !event.scheduled_call.google_event_id)
      {
        console.log('%c --> ERROR event =', 'background:#f00;color:#ff0;', '(x) NO google_event_id', event.scheduled_call.id, toJS(event));
        return;
      }

      console.log('%c --> event =', 'background:#080;color:#000;', event.scheduled_call.id, toJS(event));

      const result:FetchResult<CancelCalendarEventMutation> =
        await this._commonController.mutate<CancelCalendarEventMutation,
          CancelCalendarEventMutationVariables>({
          mutation: CancelCalendarEventDocument,
          variables: {
            scheduledEventId: event.scheduled_call.id
          }
        });

      console.log('%c --> result =', 'background:#f60;color:#000;', event.scheduled_call.id, result);

      if( result.errors )
      {
        console.log('%c ERROR CancelAllCandidateCalendarEvent =', 'background:#f00;color:#ff0;', toJS(result.errors));
        // throw new Error(result.errors[0].message);
      }
    }));
  }

  // ----------------------------------------------------

  @action.bound
  public async archiveCandidate():Promise<void>
  {
    const { candidate } = this._candidateState;

    if( !candidate )
    {
      console.log('%c ERROR candidate =', 'background:#f00;color:#ff0;', toJS(candidate));
      return;
    }

    const {
      getReasonsById,
      selectedArchiveStatus, selectedArchiveReasonId, selectedArchiveNotes
    } = this._candidateFlowState;

    console.log('%c archiveCandidate =', 'background:#0f0;color:#000;', toJS(candidate));
    console.log('%c --- selectedArchiveStatus =', 'background:#080;color:#000;', toJS(selectedArchiveStatus));
    console.log('%c --- selectedArchiveReasonId =', 'background:#080;color:#000;', toJS(getReasonsById(selectedArchiveReasonId)));
    console.log('%c --- selectedArchiveNotes =', 'background:#080;color:#000;', toJS(selectedArchiveNotes));

    if( !selectedArchiveStatus || !selectedArchiveReasonId )
    {
      !selectedArchiveStatus && console.log('%c ERROR selectedArchiveStatus =', 'background:#f00;color:#ff0;', toJS(selectedArchiveStatus));
      !selectedArchiveReasonId && console.log('%c ERROR selectedArchiveReasonId =', 'background:#f00;color:#ff0;', toJS(selectedArchiveReasonId));
      return;
    }

    // delete All -> is_final & !is_final
    const allocationIds:Array<number> = candidate.candidate_allocations
      .map((al:Recruiting_Candidate_Allocation) => al.id);

    const eventIds:Array<number> = candidate.scheduled_calls
      .map((event:Recruiting_Candidate_Scheduled_Event) => event.scheduled_call.id);

    console.log('%c --- allocationIds =', 'background:#080;color:#000;', toJS(allocationIds));
    console.log('%c --- eventIds      =', 'background:#080;color:#000;', toJS(eventIds));

    await this.CancelAllCandidateCalendarEvents(candidate.scheduled_calls);

    const result:FetchResult<UpdateCandidateStatusToInitialOrArchiveMutation> =
      await this._commonController.mutate<UpdateCandidateStatusToInitialOrArchiveMutation,
        UpdateCandidateStatusToInitialOrArchiveMutationVariables>({
        mutation: UpdateCandidateStatusToInitialOrArchiveDocument,
        variables: {
          candidateId: candidate.id,
          status: selectedArchiveStatus,
          statusReasonId: selectedArchiveReasonId,
          statusReasonNotes: selectedArchiveNotes.trim(),
          interviewFlowStageId: candidate.interview_flow_stage_id,
          allocationIds,
          eventIds
        }
      });

    console.log('%c archiveCandidate result =', 'background:#f60;color:#000;', result);

    this.showArchiveDialog(false);

    if( result.errors )
    {
      console.log('%c ERROR archiveCandidate =', 'background:#f00;color:#ff0;', toJS(result.errors));
      throw new Error(result.errors[0].message);
    }

    this._candidateController.initCandidate().then(() =>
    {
      this.initFlows();
    });
  }

  // ----------------------------------------------------

  @action.bound
  public showToInitialDialog(isShow:boolean):void
  {
    this._candidateFlowState.isShowMoveToInitialDialog = isShow;
  }

  // ----------------------------------------------------

  @action.bound
  public showArchiveDialog(isShow:boolean):void
  {
    if( isShow )
    {
      this._candidateFlowState.selectedArchiveStatus = undefined;
      this._candidateFlowState.selectedArchiveNotes = '';
      this._candidateFlowState.selectedArchiveReasonId = undefined;
    }

    this._candidateFlowState.isShowArchiveDialog = isShow;
  }

  @action.bound
  public setArchiveNotes(notes:string):void
  {
    this._candidateFlowState.selectedArchiveNotes = notes;
  }

  // ----------------------------------------------------

  @action.bound
  public setArchiveStatus(event:FormEvent<HTMLInputElement>):void
  {
    const { value: newStatus } = event.currentTarget;

    const { selectedArchiveStatus } = this._candidateFlowState;

    if( selectedArchiveStatus === newStatus ) return;

    this._candidateFlowState.selectedArchiveStatus = newStatus as Recruiting_Candidate_Status_Enum;
    this._candidateFlowState.selectedArchiveReasonId = undefined;
  }

  // ----------------------------------------------------

  @action.bound
  public async selectArchiveReasonId(reasonId:number):Promise<void>
  {
    this._candidateFlowState.selectedArchiveReasonId = reasonId;
  }

  // ----------------------------------------------------
}
