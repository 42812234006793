import { Spinner } from '@flow/common/components/form/Spinner';
import { component, di } from '@flow/dependency-injection';
import { PrioritiesListByPriorities } from '@flow/modules/recruiting/priorities/components/mobile/PrioritiesListByPriorities';
import { PrioritiesListByUsers } from '@flow/modules/recruiting/priorities/components/mobile/PrioritiesListByUsers';
import { PrioritiesController } from '@flow/modules/recruiting/priorities/PrioritiesController';
import { PrioritiesMobileViewMode, PrioritiesState } from '@flow/modules/recruiting/priorities/PrioritiesState';
// import classNames from 'classnames/bind';
import React from 'react';

import styles from './PrioritiesListBy.module.less';

// const cx = classNames.bind(styles);

@component
export class PrioritiesListMobile extends React.Component
{
  @di private _prioritiesState!:PrioritiesState;
  @di private _prioritiesController!:PrioritiesController;

  // ---------------------------------------------------------

  public render():React.ReactNode
  {
    const { mobileViewMode, isPrioritiesLoaded } = this._prioritiesState;

    if( !isPrioritiesLoaded )
    {
      return (
        <Spinner
          // size={20}
          noIcon
          withWave
          withWrapper
          wrapperClassName={styles.spinnerWrapper}
        />
      );
    }

    return (
      <>
        {
          mobileViewMode === PrioritiesMobileViewMode.BY_USERS &&
          <PrioritiesListByUsers />
        }
        {
          mobileViewMode === PrioritiesMobileViewMode.BY_PRIORITIES &&
          <PrioritiesListByPriorities />
        }
      </>
    );
  }

  // ---------------------------------------------------------
}
